import { formatDateTime } from '@bas/shared/utils';
import { ErrorResponse } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type LogNewMileageReportMutationProps = {
  materialId: string;
  mileageStart?: number | null;
  mileageEnd?: number | null;
  reportStartDate?: Date | null;
  reportEndDate?: Date | null;
  projectId?: string | null;
  eventId?: string | null;
};

export const LogNewMileageReportMutation = async ({
  materialId,
  reportStartDate,
  reportEndDate,
  ...values
}: LogNewMileageReportMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/{tenantId}/vehicles/${materialId}/mileage-reports`, {
    materialId,
    reportStartDate: reportStartDate ? formatDateTime(reportStartDate) : null,
    reportEndDate: reportEndDate ? formatDateTime(reportEndDate) : null,
    ...values,
  });

export const useLogNewMileageReportMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    LogNewMileageReportMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  LogNewMileageReportMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    LogNewMileageReportMutationProps
  >({
    mutationFn: LogNewMileageReportMutation,
    throwOnError: false,
    ...options,
  });
