import {
  FilledTextField,
  OutlinedTextField,
  StandardTextField,
  TextFieldProps,
  Typography,
} from '@bas/ui/native/base';
import { useNativeTextFieldCalculator } from '@bas/ui/native/hooks';
import React from 'react';
import { View } from 'react-native';

const TextField = ({
  validationMessage,
  helperText,
  variant,
  disabled,
  error,
  light,
  value,
  updateOnBlur,
  inputRef,
  ...props
}: TextFieldProps) => {
  const {
    labelColor,
    textColor,
    borderColor,
    validationMessageStyle,
    handleFocusChange,
    ref,
  } = useNativeTextFieldCalculator({
    variant,
    hasValue: !!value,
    error,
    disabled,
    light,
    inputRef,
  });

  let input: React.ReactElement;
  if (variant === 'outlined') {
    input = (
      <OutlinedTextField
        value={value}
        error={error}
        disabled={disabled}
        light={light}
        labelColor={labelColor}
        textColor={textColor}
        borderColor={borderColor}
        handleFocusChange={handleFocusChange}
        inputRef={ref}
        {...props}
      />
    );
  } else if (variant === 'filled') {
    input = (
      <FilledTextField
        value={value}
        error={error}
        disabled={disabled}
        light={light}
        labelColor={labelColor}
        textColor={textColor}
        borderColor={borderColor}
        handleFocusChange={handleFocusChange}
        inputRef={ref}
        {...props}
      />
    );
  } else {
    input = (
      <StandardTextField
        value={value}
        error={error}
        disabled={disabled}
        light={light}
        labelColor={labelColor}
        textColor={textColor}
        borderColor={borderColor}
        handleFocusChange={handleFocusChange}
        inputRef={ref}
        {...props}
      />
    );
  }
  return (
    <View>
      {input}
      <Typography style={validationMessageStyle}>
        {validationMessage || helperText || ' '}
      </Typography>
    </View>
  );
};

export default TextField;
