import { Money, Uuid } from '@bas/value-objects';
import * as Yup from 'yup';

export interface EditPaymentInputType {
  paymentId: Uuid;
  amount: Money;
  paidAt?: Date | null;
  remarks?: string | null;
}

export const EditPaymentInputTypeDefaultValues = (): EditPaymentInputType => ({
  paymentId: '',
  amount: { amount: 0, currency: 'EUR' },
  paidAt: null,
  remarks: '',
});

export const EditPaymentInputTypeValidationBuilder =
  (): Yup.ObjectSchema<EditPaymentInputType> =>
    Yup.object({
      paymentId: Yup.string().required().label('label.paymentId'),
      amount: Yup.object({
        amount: Yup.number()
          .transform((value, original) =>
            original === '' || original === null ? null : value,
          )
          .nullable()
          .required()
          .label('label.paymentAmount'),
        currency: Yup.string().required(),
      }),
      paidAt: Yup.date().required().label('label.paymentDate'),
      remarks: Yup.string().label('label.remarks'),
    });
