import { StyleSheet } from 'react-native';
import { colors } from '@bas/theme';

export default StyleSheet.create({
  badge: {
    backgroundColor: colors.lila[200],
    height: 28,
    minWidth: 127,
    width: 127,
    flexGrow: 0,
    flexShrink: 1,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
