import * as Yup from 'yup';

export type PasswordInputType = {
  currentPassword: string;
  newPassword: string;
  repeatPassword: string;
};

export const PasswordInputTypeDefaultValues = (): PasswordInputType => ({
  currentPassword: '',
  newPassword: '',
  repeatPassword: '',
});

export const PasswordInputTypeValidationBuilder =
  (): Yup.ObjectSchema<PasswordInputType> =>
    Yup.object({
      currentPassword: Yup.string().required().label('label.currentPassword'),
      newPassword: Yup.string().required().label('label.newPassword'),
      repeatPassword: Yup.string()
        .required()
        .label('label.repeatPassword')
        .oneOf(
          [Yup.ref('newPassword')],
          'Wachtwoord is niet gelijk aan het nieuwe wachtwoord',
        ),
    });
