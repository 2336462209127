import { formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, Money, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type MarkDeclarationAsPaidMutationProps = {
  declarationId: Uuid;
  paidById: Uuid;
  paidOn: Date;
  amount: Money;
  reason: string;
};

export const MarkDeclarationAsPaidMutation = async ({
  declarationId,
  paidOn,
  ...values
}: MarkDeclarationAsPaidMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/hrm/declarations/${declarationId}/mark-as-paid`, {
    declarationId,
    paidOn: formatDateTime(paidOn),
    ...values,
  });

export const useMarkDeclarationAsPaidMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    MarkDeclarationAsPaidMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  MarkDeclarationAsPaidMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    MarkDeclarationAsPaidMutationProps
  >({
    mutationFn: MarkDeclarationAsPaidMutation,
    throwOnError: false,
    ...options,
  });
