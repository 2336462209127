import { RootStackParamList } from '@bas/employee-app/value-objects';
import { useUserStore } from '@bas/shared/state';
import { useUserOpensNewsMutation } from '@bas/tenant-domain/mutations';
import { useNewsByNewsIdRequest } from '@bas/tenant-domain/requests';
import { colors } from '@bas/theme';
import { Skeleton } from '@bas/ui/native/atoms';
import {
  breakLine,
  breakLineRules,
  checkboxRules,
  creator,
  markdownItCheckbox,
  notice,
  noticeRules,
  textRules,
} from '@bas/ui/native/markdown-rules';
import { AppErrorBoundaryScreen } from '@bas/ui/native/templates';
import Markdown from '@jonasmerlin/react-native-markdown-display';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { ErrorBoundary } from '@sentry/react-native';
import { useQueryClient } from '@tanstack/react-query';
import { Stack, useLocalSearchParams } from 'expo-router';
import * as React from 'react';
import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';

const markdownItInstance = creator({
  plugins: [markdownItCheckbox, notice, breakLine],
});

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    flex: 1,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 14,
  },
  flexContainer: {
    flex: 1,
    backgroundColor: colors.white,
  },
  title: {
    paddingBottom: 24,
  },
  newsContainer: {
    marginBottom: 10,
  },
  readTitle: {
    marginTop: 29,
    marginBottom: 8,
  },
});

export type MobileEmployeeNewsItemScreenProps = NativeStackScreenProps<
  RootStackParamList,
  'NewsItem'
>;

const MobileEmployeeNewsItemScreen = (): ReactElement => {
  const queryClient = useQueryClient();
  const userState = useUserStore((state) => state.user);
  const { newsId } = useLocalSearchParams<{
    newsId: string;
  }>();

  const { data: newsItemData, isLoading } = useNewsByNewsIdRequest({
    newsId,
  });

  const newsItem = useMemo(() => newsItemData?.data, [newsItemData?.data]);

  const { mutateAsync: userOpensNews } = useUserOpensNewsMutation({
    onSuccess: async () => {
      await new Promise((r) => {
        setTimeout(r, 300);
      });
      await queryClient.invalidateQueries({
        queryKey: ['news', 'list', newsItem?.tenantId],
      });
    },
  });

  useEffect(() => {
    if (userState && newsItem) {
      userOpensNews({
        userId: userState.userId,
        newsId: newsItem?.newsId,
      });
    }
  }, [newsItem, userOpensNews, userState]);

  const body = newsItem?.body || '';
  const renderAppErrorBoundary = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ({ eventId, error, resetError }: any) => (
      <AppErrorBoundaryScreen
        resetError={resetError}
        eventId={eventId}
        error={error}
      />
    ),
    [],
  );

  return (
    <ErrorBoundary fallback={renderAppErrorBoundary}>
      <Stack.Screen
        options={{
          title: newsItem?.title,
        }}
      />
      <ScrollView style={styles.flexContainer}>
        <View style={styles.container}>
          {isLoading ? (
            <Skeleton height={500} width={500} />
          ) : (
            <Markdown
              markdownit={markdownItInstance}
              rules={{
                ...checkboxRules,
                ...noticeRules,
                ...breakLineRules,
                ...textRules,
              }}
            >
              {body
                .split('\n')
                .map((e) => {
                  if (e === '  ') return '<><><>\n \n<><><>';
                  return e;
                })
                .join('\n') || ''}
            </Markdown>
          )}
        </View>
      </ScrollView>
    </ErrorBoundary>
  );
};

export default MobileEmployeeNewsItemScreen;
