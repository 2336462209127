import {
  Filterable,
  Pagination,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { ContractTemplate } from '@bas/tenant-domain/models';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';

export type ContractTemplatesRequestProps = Pagination & Filterable;

type Response = AxiosResponse<Collection<ContractTemplate>>;

export const ContractTemplatesRequest = async ({
  ...params
}: ContractTemplatesRequestProps): Promise<Response> =>
  axios.get(`api/contract-templates`, {
    params: {
      ...params,
    },
    paramsSerializer(param) {
      return Qs.stringify(param, { arrayFormat: 'brackets' });
    },
  });

export const useContractTemplatesRequest = (
  request: ContractTemplatesRequestProps = {
    perPage: 1000,
  },
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => ContractTemplatesRequest(request),
    queryKey: ['contract-templates', 'list', request],
  });
