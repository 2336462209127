import { formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type SyncTimeEntriesMutationProps = {
  employeeId: Uuid;
  timeEntries: {
    timeEntryId: Uuid;
    start: Date;
    end: Date;
    timeTypeId: Uuid;
    description?: string;
    projectId?: Uuid | null;
    eventId?: Uuid | null;
    relationId?: Uuid | null;
  }[];
};

export const SyncTimeEntriesMutation = async ({
  timeEntries,
  ...values
}: SyncTimeEntriesMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/{tenantId}/hrm/time-entries/sync`, {
    ...values,
    timeEntries: timeEntries.map(({ start, end, ...timeEntry }) => ({
      ...timeEntry,
      start: formatDateTime(start),
      end: formatDateTime(end),
    })),
  });

export const useSyncTimeEntriesMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    SyncTimeEntriesMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  SyncTimeEntriesMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    SyncTimeEntriesMutationProps
  >({
    mutationFn: SyncTimeEntriesMutation,
    throwOnError: false,
    ...options,
  });
