import * as Yup from 'yup';

export type UploadVehicleImageInputType = {
  fileId: string;
};

export const UploadVehicleImageInputTypeDefaultValues =
  (): UploadVehicleImageInputType => ({
    fileId: '',
  });

export const UploadVehicleImageInputTypeValidationBuilder =
  (): Yup.ObjectSchema<UploadVehicleImageInputType> =>
    Yup.object({
      fileId: Yup.string().required().label('label.fileId'),
    });
