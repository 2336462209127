import { Button } from '@bas/ui/native/atoms';
import { Box, Icon, Typography } from '@bas/ui/native/base';
import { faIntercom } from '@fortawesome/free-brands-svg-icons/faIntercom';
import { faCarCrash } from '@fortawesome/pro-light-svg-icons/faCarCrash';
import { ReactElement, useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SafeAreaView } from 'react-native-safe-area-context';
import styles from './styles';

export type AppErrorBoundaryScreenProps = {
  error: Error;
  eventId: string | null;
  resetError(): void;
};

const AppErrorBoundaryScreen = ({
  error,
  eventId,
  resetError,
}: AppErrorBoundaryScreenProps): ReactElement => {
  const { formatMessage } = useIntl();
  const handleBackHome = useCallback(() => {
    // @TODO log error
    // eslint-disable-next-line no-console
    console.log('error', error, formatMessage);
  }, [error, formatMessage]);

  useEffect(() => {
    // @TODO log error
    // eslint-disable-next-line no-console
    console.log('error', error);
  }, [error, eventId]);

  const handleRequestHelp = useCallback(() => {
    // @TODO log error
    // eslint-disable-next-line no-console
    console.log('error', error, formatMessage);
  }, [error, formatMessage]);

  return (
    <Box flex={1} backgroundColor="darkBackground">
      <SafeAreaView style={styles.container}>
        <Box flex={1} alignItems="center" justifyContent="center">
          <Box maxWidth={425} padding={{ phone: 20 }}>
            <Box alignItems="center" marginBottom={2}>
              <Icon icon={faCarCrash} size={104} color="lightText" />
            </Box>
            <Typography
              variant="h3"
              color="lightText"
              marginBottom={4}
              fontWeight={700}
            >
              <FormattedMessage id="errorFallback.oopsSomethingWentWrong" />
            </Typography>
            <Typography variant="h5" color="lightText">
              <FormattedMessage id="errorFallback.errorDescription" />
            </Typography>
            <Box flexDirection="row" flexWrap="wrap" marginTop={2}>
              <Button onPress={resetError}>
                <FormattedMessage id="errorFallback.tryAgain" />
              </Button>
              <Button marginLeft={2} onPress={handleRequestHelp}>
                <Icon icon={faIntercom} color="lightText" />
                &nbsp;
                <FormattedMessage id="errorFallback.iNeedHelp" />
              </Button>
              <Button variant="outlined" marginTop={2} onPress={handleBackHome}>
                <FormattedMessage id="errorFallback.backToHome" />
              </Button>
            </Box>
          </Box>
        </Box>
      </SafeAreaView>
    </Box>
  );
};

export default AppErrorBoundaryScreen;
