import { StyleSheet } from 'react-native';
import { colors } from '@bas/theme';

export default StyleSheet.create({
  container: {
    backgroundColor: colors.blue[900],
    flex: 1,
    alignItems: 'center',
  },
  keyboardContainer: {
    flex: 1,
    maxWidth: 430,
    paddingLeft: 20,
    paddingRight: 20,
    width: '100%',
  },
  formContainer: {
    width: '100%',
    height: '100%',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    width: '100%',
    maxWidth: 390,
    flexDirection: 'column',
  },
  title: {
    marginBottom: 71,
    color: colors.white,
  },
});
