import {
  EnterHoursInputType,
  EventParticularsInputType,
  SignForEventInputType,
} from '@bas/planning-domain/input-types';
import { ProjectEvent } from '@bas/planning-domain/models';
import { useProjectByProjectIdRequest } from '@bas/project-domain/requests';
import { SigningMoment, SigningType } from '@bas/shared/models';
import { colors } from '@bas/theme';
import { BlueSelectButton } from '@bas/ui/native/atoms';
import { Box, Typography } from '@bas/ui/native/base';
import { BlueSelectButtonsGroup } from '@bas/ui/native/molecules';
import { EventTypeSigningData } from '@bas/ui/native/organisms';
import { InventoryMovementInputType } from '@bas/wms-domain/input-types';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { ScrollView } from 'react-native-gesture-handler';
import { SignDocumentForm } from '../SignDocumentForm';
import styles from './styles';

export type SignForEventStepProps = {
  event: ProjectEvent;
  signingMoment?: SigningMoment;
};

const SignForEventStep = ({
  event,
  signingMoment,
}: SignForEventStepProps): ReactElement => {
  const { data: projectData } = useProjectByProjectIdRequest({
    projectId: event.projectId,
  });
  const { setValue } = useFormContext<SignForEventInputType>();
  const isTheCustomerPresent = useWatch<
    SignForEventInputType,
    'isTheCustomerPresent'
  >({
    name: 'isTheCustomerPresent',
  });
  const doYouWantToSignWithTheCustomer = useWatch<
    SignForEventInputType,
    'doYouWantToSignWithTheCustomer'
  >({
    name: 'doYouWantToSignWithTheCustomer',
  });
  const wasTheWorkPerformedSatisfactorily = useWatch<
    SignForEventInputType,
    'wasTheWorkPerformedSatisfactorily'
  >({
    name: 'wasTheWorkPerformedSatisfactorily',
  });
  const particulars = useWatch<EventParticularsInputType, 'particulars'>({
    name: 'particulars',
  });
  const wereThereAnyParticulars = useWatch<
    EventParticularsInputType,
    'wereThereAnyParticulars'
  >({
    name: 'wereThereAnyParticulars',
  });

  const materials = useWatch<
    { materials: InventoryMovementInputType[] },
    'materials'
  >({
    name: 'materials',
  });

  const damagesToProcess = useWatch<EventParticularsInputType, 'damages'>({
    name: 'damages',
  });

  const [startTime, endTime, breakTime] = useWatch<
    EnterHoursInputType,
    ['startDate', 'endDate', 'breakTime']
  >({
    name: ['startDate', 'endDate', 'breakTime'],
  });

  useEffect(() => {
    if (
      !doYouWantToSignWithTheCustomer &&
      signingMoment?.signingType === SigningType.REQUIRED_SIGN_ON_FINALIZE
    ) {
      setValue(
        'doYouWantToSignWithTheCustomer',
        signingMoment?.signingType === SigningType.REQUIRED_SIGN_ON_FINALIZE,
      );
    }
  }, [setValue, doYouWantToSignWithTheCustomer, signingMoment?.signingType]);

  const [scrollEnabled, setScrollEnabled] = useState<boolean>(true);
  const scrollRef = useRef<ScrollView>(null);

  if (!projectData?.data) {
    return <Box />;
  }

  return (
    <ScrollView scrollEnabled={scrollEnabled} ref={scrollRef}>
      <Box paddingHorizontal={20} gap={4}>
        {signingMoment?.signingType !==
          SigningType.REQUIRED_SIGN_ON_FINALIZE && (
          <Box>
            <Typography overrideColor={colors.white} style={styles.question}>
              <FormattedMessage id="label.doYouWantToSignWithTheCustomer" />
            </Typography>
            <Box>
              <BlueSelectButtonsGroup
                buttons={[
                  <BlueSelectButton
                    notSelected={
                      doYouWantToSignWithTheCustomer !== undefined &&
                      doYouWantToSignWithTheCustomer !== true
                    }
                    onSelect={() =>
                      setValue('doYouWantToSignWithTheCustomer', true, {
                        shouldValidate: true,
                      })
                    }
                    icon={faCheckCircle}
                  >
                    <FormattedMessage id="label.yes" />
                  </BlueSelectButton>,
                  <BlueSelectButton
                    notSelected={
                      doYouWantToSignWithTheCustomer !== undefined &&
                      doYouWantToSignWithTheCustomer !== false
                    }
                    onSelect={() =>
                      setValue('doYouWantToSignWithTheCustomer', false, {
                        shouldValidate: true,
                      })
                    }
                    icon={faTimes}
                  >
                    <FormattedMessage id="label.no" />
                  </BlueSelectButton>,
                ]}
              />
            </Box>
          </Box>
        )}
        <Box>
          <Typography overrideColor={colors.white} style={styles.question}>
            <FormattedMessage id="label.isTheCustomerPresent" />
          </Typography>
          <Box>
            <BlueSelectButtonsGroup
              buttons={[
                <BlueSelectButton
                  notSelected={
                    isTheCustomerPresent !== undefined &&
                    isTheCustomerPresent !== true
                  }
                  onSelect={() =>
                    setValue('isTheCustomerPresent', true, {
                      shouldValidate: true,
                    })
                  }
                  icon={faCheckCircle}
                >
                  <FormattedMessage id="label.yes" />
                </BlueSelectButton>,
                <BlueSelectButton
                  notSelected={
                    isTheCustomerPresent !== undefined &&
                    isTheCustomerPresent !== false
                  }
                  onSelect={() =>
                    setValue('isTheCustomerPresent', false, {
                      shouldValidate: true,
                    })
                  }
                  icon={faTimes}
                >
                  <FormattedMessage id="label.no" />
                </BlueSelectButton>,
              ]}
            />
          </Box>
        </Box>
        {doYouWantToSignWithTheCustomer && isTheCustomerPresent && (
          <>
            <Box style={styles.paddingBetweenRows}>
              <EventTypeSigningData
                event={event}
                project={projectData?.data}
                damagesToProcess={damagesToProcess || []}
                particulars={particulars}
                wereThereAnyParticulars={wereThereAnyParticulars}
                startTime={startTime}
                endTime={endTime}
                breakTime={breakTime}
                materialMovements={materials}
              />
            </Box>
            <Box style={styles.paddingBetweenRows}>
              <Typography overrideColor={colors.white} style={styles.question}>
                <FormattedMessage id="label.wasTheWorkPerformedSatisfactorily" />
              </Typography>
              <Box>
                <BlueSelectButtonsGroup
                  buttons={[
                    <BlueSelectButton
                      notSelected={
                        wasTheWorkPerformedSatisfactorily !== undefined &&
                        wasTheWorkPerformedSatisfactorily !== true
                      }
                      onSelect={() =>
                        setValue('wasTheWorkPerformedSatisfactorily', true, {
                          shouldValidate: true,
                        })
                      }
                      icon={faCheckCircle}
                    >
                      <FormattedMessage id="label.yes" />
                    </BlueSelectButton>,
                    <BlueSelectButton
                      notSelected={
                        wasTheWorkPerformedSatisfactorily !== undefined &&
                        wasTheWorkPerformedSatisfactorily !== false
                      }
                      onSelect={() =>
                        setValue('wasTheWorkPerformedSatisfactorily', false, {
                          shouldValidate: true,
                        })
                      }
                      icon={faTimes}
                    >
                      <FormattedMessage id="label.no" />
                    </BlueSelectButton>,
                  ]}
                />
              </Box>
            </Box>
            <Box>
              <SignDocumentForm
                setScrollEnabled={setScrollEnabled}
                scrollEnabled={scrollEnabled}
                scrollRef={scrollRef}
                light
              />
            </Box>
          </>
        )}
      </Box>
    </ScrollView>
  );
};

export default SignForEventStep;
