import { StorageEventType, StorageSealWasRemovedEvent } from '../../types';
import { isStorageEvent } from './StorageEvent';

export function isStorageSealWasRemovedEvent(
  object: unknown,
): object is StorageSealWasRemovedEvent {
  return (
    isStorageEvent(object) &&
    object.eventType === StorageEventType.StorageSealWasRemovedEvent
  );
}
