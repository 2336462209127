import { ReactElement } from 'react';
import { Box } from '@bas/ui/native/base';
import { Skeleton } from '@bas/ui/native/atoms';
import styles from './styles';

const LoadingEventListItem = (): ReactElement => (
  <Box style={styles.container}>
    <Box style={styles.eventColor} />
    <Box style={styles.eventIconContainer}>
      <Skeleton height={24} width={24} />
    </Box>
    <Box style={styles.eventInfoContainer}>
      <Box style={styles.eventNameRow}>
        <Skeleton height={21} width={250} />
      </Box>
      <Box pt={1}>
        <Skeleton height={16} width={250} />
      </Box>
    </Box>
  </Box>
);

export default LoadingEventListItem;
