/* eslint-disable global-require,@typescript-eslint/no-var-requires,no-console,@typescript-eslint/no-unused-vars */
import { Button } from '@bas/ui/native/atoms';
import { Box } from '@bas/ui/native/base';
import { Scanner } from '@yudiel/react-qr-scanner';
import { IDetectedBarcode } from '@yudiel/react-qr-scanner/dist/types';
import { Audio } from 'expo-av';
import { useCameraPermissions } from 'expo-camera';
import { FormattedMessage } from 'react-intl';
import type { Code } from 'react-native-vision-camera';
import { useDebouncedCallback } from 'use-debounce';

type QrCodeScannerProps = {
  onScannedCode: (code: Code) => boolean;
};

const QrCodeScannerWeb = ({ onScannedCode }: QrCodeScannerProps) => {
  const [permission, requestPermission] = useCameraPermissions();

  const handleNotifyUser = useDebouncedCallback(
    () => {
      Audio.Sound.createAsync(require('./confirm.mp3'), {
        shouldPlay: true,
        isLooping: false,
        volume: 0.2,
      });
    },
    250,
    {
      leading: true,
      trailing: false,
    },
  );

  const handleOnScan = (detectedCodes: IDetectedBarcode[]) => {
    detectedCodes.forEach(({ rawValue, cornerPoints }) => {
      const scannedCode = onScannedCode({
        type: 'qr',
        value: rawValue,
        corners: cornerPoints,
        frame: {
          height: 0,
          width: 0,
          x: 0,
          y: 0,
        },
      });
      if (scannedCode) {
        handleNotifyUser();
      }
    });
  };

  return (
    <Box height="100%" width="100%" position="relative">
      {permission?.granted ? (
        <Scanner
          onError={(e) => alert(e.message)}
          onScan={handleOnScan}
          formats={['qr_code']}
          constraints={{
            facingMode: 'environment',
          }}
          components={{
            audio: false,
          }}
          styles={{
            container: {
              position: 'unset',
            },
          }}
        />
      ) : (
        <Button onPress={requestPermission}>
          <FormattedMessage id="button.requestPermission" />
        </Button>
      )}
    </Box>
  );
};

export default QrCodeScannerWeb;
