import { ReactElement } from 'react';
import { Box, Typography } from '@bas/ui/native/base';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { TimeEntry } from '@bas/hrm-domain/models';

export type TimeEntryRejectionInformationContentSection = {
  timeEntry: TimeEntry;
};

const TimeEntryRejectionInformationContentSection = ({
  timeEntry,
}: TimeEntryRejectionInformationContentSection): ReactElement => (
  <Box flexDirection="row" flexWrap="wrap">
    <Box flexBasis="100%">
      <Typography variant="subtitle1" color="white">
        <FormattedMessage id="label.rejectionInformation" />
      </Typography>
    </Box>
    <Box flexBasis="50%">
      {timeEntry.rejectedByName && (
        <Typography color="white">{timeEntry.rejectedByName}</Typography>
      )}
      {timeEntry.rejectedOn && (
        <Typography color="white">
          <FormattedDate value={timeEntry.rejectedOn} dateStyle="short" />
        </Typography>
      )}
      <Typography color="white">{timeEntry.rejectionReaction}</Typography>
    </Box>
  </Box>
);

export default TimeEntryRejectionInformationContentSection;
