import { formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type AddEmployeeOccasionalAvailabilityMutationProps = {
  employeeId: Uuid;
  availabilityId: Uuid;
  startTime?: Date | null;
  endTime?: Date | null;
};

export const AddEmployeeOccasionalAvailabilityMutation = async ({
  employeeId,
  startTime,
  endTime,
  ...values
}: AddEmployeeOccasionalAvailabilityMutationProps): Promise<AxiosResponse> =>
  axios.post(
    `api/{tenantId}/hrm/employees/${employeeId}/occasional-availabilities`,
    {
      employeeId,
      startTime: startTime ? formatDateTime(startTime) : null,
      endTime: endTime ? formatDateTime(endTime) : null,
      ...values,
    },
  );

export const useAddEmployeeOccasionalAvailabilityMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddEmployeeOccasionalAvailabilityMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  AddEmployeeOccasionalAvailabilityMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddEmployeeOccasionalAvailabilityMutationProps
  >({
    mutationFn: AddEmployeeOccasionalAvailabilityMutation,
    throwOnError: false,
    ...options,
  });
