import { MovingJobTaxationWizardInput } from '@bas/taxation-tool-domain/models';
import { colors } from '@bas/theme';
import { Button } from '@bas/ui/native/atoms';
import { BottomSheetMethods, Box, Icon, Typography } from '@bas/ui/native/base';
import { useHandleRequestHelp } from '@bas/ui/native/hooks';
import { ReactHookFormTextField } from '@bas/ui/native/molecules';
import { BottomSheet } from '@bas/ui/native/organisms';
import { InternalServiceType } from '@bas/value-objects';
import { faIntercom } from '@fortawesome/free-brands-svg-icons/faIntercom';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { router } from 'expo-router';
import { ReactElement, RefObject, useCallback, useMemo } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import styles from './styles';

export type HelpAndNotesBottomSheetProps = {
  bottomSheetRef: RefObject<BottomSheetModal & BottomSheetMethods>;
  control: Control<MovingJobTaxationWizardInput>;
};

const Content = ({ bottomSheetRef, control }: HelpAndNotesBottomSheetProps) => {
  const { bottom } = useSafeAreaInsets();
  const { formatMessage } = useIntl();
  const services = useWatch({
    name: 'services',
    control,
  });
  const usesStorage = useMemo(
    () =>
      services.find(
        (service) => service.internalType === InternalServiceType.STORAGE,
      )?.selected,
    [services],
  );

  const handleBackHome = useCallback(() => {
    bottomSheetRef.current?.close();
    router.push('/');
  }, [bottomSheetRef]);

  const handleRequestHelp = useHandleRequestHelp();

  return (
    <Box flex={1} flexDirection="column">
      <Typography
        overrideColor={colors.white}
        variant="h4"
        fontWeight={700}
        style={[styles.textCenter, styles.title]}
      >
        <FormattedMessage id="label.wantToQuicklyWriteSomeNotesOrNeedHelp" />
      </Typography>
      <Box>
        <Box>
          <ReactHookFormTextField
            control={control}
            name="notes"
            autoComplete="off"
            label={formatMessage({ id: 'label.internalNotes' })}
            multiline
            light
          />
        </Box>
        <Box mt={3}>
          <ReactHookFormTextField
            control={control}
            name="notesForEmployees"
            autoComplete="off"
            label={formatMessage({ id: 'label.notesForEmployees' })}
            multiline
            light
          />
        </Box>
        {usesStorage && (
          <Box mt={3}>
            <ReactHookFormTextField
              control={control}
              name="storageNotes"
              autoComplete="off"
              label={formatMessage({ id: 'label.storageNotes' })}
              multiline
              light
            />
          </Box>
        )}
        <Box mt={3}>
          <ReactHookFormTextField
            control={control}
            name="customerNotes"
            autoComplete="off"
            label={formatMessage({ id: 'label.customerNotes' })}
            multiline
            light
          />
        </Box>
      </Box>
      <Box
        flex={1}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="flex-end"
        style={{ paddingBottom: bottom + 10 }}
      >
        <Button variant="outlined" onPress={handleBackHome}>
          <FormattedMessage id="mobileApp.intake.movingJob.goBackToApp" />
        </Button>
        <Button onPress={() => handleRequestHelp()}>
          <Icon icon={faIntercom} color="white" />
          &nbsp;
          <FormattedMessage id="mobileApp.intake.movingJob.needHelp" />
        </Button>
      </Box>
    </Box>
  );
};

const HelpAndNotesBottomSheet = ({
  bottomSheetRef,
  control,
}: HelpAndNotesBottomSheetProps): ReactElement => {
  const content = useMemo(
    () => <Content bottomSheetRef={bottomSheetRef} control={control} />,
    [bottomSheetRef, control],
  );

  return <BottomSheet ref={bottomSheetRef}>{content}</BottomSheet>;
};

export default HelpAndNotesBottomSheet;
