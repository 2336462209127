import { Box } from '@bas/ui/native/base';
import { ReactHookFormTextField } from '@bas/ui/native/molecules';
import { ReactElement } from 'react';
import { useIntl } from 'react-intl';

export type MobileTaxationNotesFormProps = {
  usesStorage: boolean;
};
const MobileTaxationNotesForm = ({
  usesStorage,
}: MobileTaxationNotesFormProps): ReactElement => {
  const { formatMessage } = useIntl();

  return (
    <Box
      flex={1}
      flexDirection="row"
      style={{
        marginTop: -24,
        marginLeft: -24,
      }}
      flexWrap="wrap"
    >
      <Box pl={3} pt={3} flex={1} flexBasis="100%">
        <ReactHookFormTextField
          name="notes"
          autoComplete="off"
          label={formatMessage({ id: 'label.internalNotes' })}
          multiline
        />
      </Box>
      <Box pl={3} pt={3} flex={1} flexBasis="100%">
        <ReactHookFormTextField
          name="notesForEmployees"
          autoComplete="off"
          label={formatMessage({ id: 'label.notesForEmployees' })}
          multiline
        />
      </Box>
      {usesStorage && (
        <Box pl={3} pt={3} flex={1} flexBasis="100%">
          <ReactHookFormTextField
            name="storageNotes"
            autoComplete="off"
            label={formatMessage({ id: 'label.storageNotes' })}
            multiline
          />
        </Box>
      )}
    </Box>
  );
};

export default MobileTaxationNotesForm;
