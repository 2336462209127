import { BlueSelectButton } from '@bas/ui/native/atoms';
import { Box, Typography } from '@bas/ui/native/base';
import * as React from 'react';
import { memo, ReactElement, ReactNode, useMemo } from 'react';
import isEqual from 'react-fast-compare';
import {
  FieldPath,
  FieldPathValue,
  FieldValues,
  useController,
} from 'react-hook-form';
import { BlueSelectButtonsGroup } from '../BlueSelectButtonsGroup';

export type ReactHookFormSelectButtonsProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  label: ReactNode | string;
  options: {
    value: string | boolean | number;
    label: ReactNode | string;
  }[];
  name: TName;
  light?: boolean;
  disableError?: boolean;
};

const ReactHookFormSelectButtons = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  disableError,
  label,
  light,
  options,
}: ReactHookFormSelectButtonsProps<TFieldValues, TName>): ReactElement => {
  const {
    field: { value, onChange },
    fieldState: { error, invalid },
    formState: { isSubmitting },
  } = useController<TFieldValues, TName>({
    name,
  });

  const fieldError = error?.message;
  const showError = !disableError && invalid;

  // eslint-disable-next-line no-console
  console.debug('@todo: fix this', showError, fieldError);
  const buttons = useMemo(
    () =>
      options.map((option) => (
        <BlueSelectButton
          notSelected={value === undefined || value !== option.value}
          height={60}
          whiteInactive
          disabled={isSubmitting}
          onSelect={() =>
            onChange(option.value as FieldPathValue<TFieldValues, TName>)
          }
        >
          {option.label}
        </BlueSelectButton>
      )),
    [options, value, onChange, isSubmitting],
  );

  return (
    <Box width="100%">
      <Box width="100%">
        <Typography variant="h5" mb="line" color={light ? 'white' : undefined}>
          {label}
        </Typography>
      </Box>
      <BlueSelectButtonsGroup buttons={buttons} />
    </Box>
  );
};

const MemoComponent = memo(ReactHookFormSelectButtons, isEqual);
export default MemoComponent;
