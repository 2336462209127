import { Employee } from '@bas/hrm-domain/models';
import {
  DamageInputType,
  DamageInputTypeDefaultValues,
  DamageInputTypeValidationBuilder,
} from '@bas/shared/input-types';
import { colors } from '@bas/theme';
import { BottomSheetMethods, Typography } from '@bas/ui/native/base';
import { BackendErrors } from '@bas/ui/native/organisms';
import { FormBottomSheet } from '@bas/ui/native/templates';
import { Address } from '@bas/value-objects';
import { BottomSheetModal, BottomSheetProps } from '@gorhom/bottom-sheet';
import { ReactElement, RefObject, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { ensuredForwardRef } from 'react-use/lib/useEnsuredForwardedRef';
import { ReportDamageForm } from '../ReportDamageForm';

export type ReportDamageBottomSheetProps = Omit<
  BottomSheetProps,
  'snapPoints' | 'children'
> & {
  onSubmit: (values: DamageInputType) => Promise<void> | void;
  employees: Employee[];
  addresses: Address[];
  greetingName?: string | null;
  directlyFinalize?: boolean;
};

const styles = StyleSheet.create({
  flexContainer: {
    flex: 1,
  },
  container: {
    marginBottom: 15,
    paddingLeft: 20,
    paddingRight: 20,
  },
  description: {
    textAlign: 'center',
  },
  avatar: {
    height: '100%',
    width: '100%',
  },
  niceJob: {
    textAlign: 'center',
  },
  avatarContainer: {
    overflow: 'hidden',
    borderRadius: 269,
    height: 268,
    width: 268,
    marginBottom: 29,
    marginTop: 29,
    alignSelf: 'center',
  },
  header: {
    alignSelf: 'center',
    fontSize: 22,
    lineHeight: 29,
    height: 51,
  },
  paddingTop: {
    paddingTop: 24,
  },
  padding: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 31,
    paddingTop: 31,
  },
});

const ReportDamageBottomSheet = ensuredForwardRef<
  BottomSheetModal & BottomSheetMethods,
  ReportDamageBottomSheetProps
>(
  (
    {
      onSubmit,
      employees,
      addresses,
      greetingName,
      directlyFinalize,
      ...props
    }: ReportDamageBottomSheetProps,
    ref: RefObject<BottomSheetModal & BottomSheetMethods>,
  ): ReactElement => {
    const handleFinalize = useCallback(() => {
      ref?.current?.close();
      ref?.current?.forceClose();
      ref?.current?.dismiss();
    }, [ref]);

    return (
      <FormBottomSheet<DamageInputType>
        bottomSheetRef={ref}
        disablePadding
        onSubmit={onSubmit}
        useProvider
        resetFormOnOpen
        onCancel={handleFinalize}
        defaultValues={{
          ...DamageInputTypeDefaultValues(),
          damagedAt: new Date(),
        }}
        validationSchema={DamageInputTypeValidationBuilder}
        successFullFormViewProps={
          directlyFinalize
            ? undefined
            : {
                gifUrl:
                  'https://media.giphy.com/media/l0MYGB0E1SfY5akLe/giphy.gif',

                title: 'label.reportDamage.finished',
                color: colors.lila[100],
                labelId: 'label.damageIsProcessed',
                greetingName,
              }
        }
        {...props}
      >
        <View style={styles.flexContainer}>
          <Typography
            fontWeight={700}
            overrideColor={colors.white}
            style={styles.header}
            textAlign="center"
          >
            <FormattedMessage id="label.reportDamage" />
          </Typography>
          <View>
            <BackendErrors />

            <View style={styles.container}>
              <ReportDamageForm employees={employees} addresses={addresses} />
            </View>
          </View>
        </View>
      </FormBottomSheet>
    );
  },
);

export default ReportDamageBottomSheet;
