import { ChangeEvent, ReactElement, useMemo } from 'react';
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  PathValue,
  useController,
  UseFormStateReturn,
} from 'react-hook-form';
import { TimeSliderField, TimeSliderFieldProps } from '../TimeSliderField';

export interface ReactHookFormTimeSliderFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<TimeSliderFieldProps, 'name' | 'value' | 'error' | 'onChange'> {
  name: TName;
}

const fieldToTimeSliderField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  disabled,
  helperText,
  field: { onChange, value },
  fieldState: { error, invalid },
  formState: { isSubmitting },
  ...props
}: Omit<ReactHookFormTimeSliderFieldProps<TFieldValues, TName>, 'name'> & {
  field: ControllerRenderProps<TFieldValues, TName>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<FieldValues>;
}): TimeSliderFieldProps => {
  const fieldError = error?.message;
  const showError = invalid;

  return {
    error: showError,
    helperText: showError ? fieldError : helperText,
    disabled: disabled ?? isSubmitting,
    value,
    ...props,
    onChange: (newValue) => {
      onChange(
        newValue as PathValue<TFieldValues, TName> | ChangeEvent<Element>,
      );
    },
  };
};

const ReactHookFormTimeSliderField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  children,
  name,
  ...args
}: ReactHookFormTimeSliderFieldProps<TFieldValues, TName>): ReactElement => {
  const { field, fieldState, formState } = useController<TFieldValues, TName>({
    name,
  });

  const timeSliderProps = useMemo(
    () => fieldToTimeSliderField({ ...args, field, fieldState, formState }),
    [args, field, fieldState, formState],
  );

  return <TimeSliderField {...timeSliderProps}>{children}</TimeSliderField>;
};

export default ReactHookFormTimeSliderField;
