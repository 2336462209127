import {
  CrmAddressInputType,
  CrmContactPersonInputType,
} from '@bas/crm-domain/input-types';
import { Relation } from '@bas/crm-domain/models';
import { Uuid } from '@bas/value-objects';

export interface ProjectCustomerInformationInputType {
  relation?: Relation;
  reference?: string | null;
  projectContactPersonId?: Uuid | 'new' | 'private';
  projectContactPerson: CrmContactPersonInputType;
  quoteContactPersonId?: Uuid | 'new' | 'private';
  quoteContactPerson: CrmContactPersonInputType;
  invoiceContactPersonId?: Uuid | 'new' | 'private';
  invoiceContactPerson: CrmContactPersonInputType;
  invoiceAddress: CrmAddressInputType;
  invoiceAddressId: Uuid | 'new';
  quoteAddress: CrmAddressInputType;
  quoteAddressId: Uuid | 'new';
}
