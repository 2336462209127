import {
  useAuthStore,
  useEmployeeStore,
  useIntercomStore,
  useUserStore,
} from '@bas/shared/state';
import { setUser } from '@sentry/react-native';
import { useCallback } from 'react';

export const useLogout = () => {
  const logoutAuth = useAuthStore((state) => state.logout);
  const logoutIntercom = useIntercomStore((state) => state.logout);
  const logoutUser = useUserStore((state) => state.logout);
  const logoutEmployee = useEmployeeStore((state) => state.logout);

  return useCallback(() => {
    logoutAuth();
    logoutIntercom();
    logoutUser();
    logoutEmployee();
    setUser(null);
  }, [logoutAuth, logoutEmployee, logoutIntercom, logoutUser]);
};
