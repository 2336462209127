import { isObjectResponse } from '@bas/value-objects';
import { CrmContactPerson } from '../types';

export function isCrmContactPerson(
  object: unknown,
): object is CrmContactPerson {
  return (
    isObjectResponse(object) &&
    Object.prototype.hasOwnProperty.call(object, 'contactPersonId') &&
    Object.prototype.hasOwnProperty.call(object, 'contactPersonType')
  );
}
