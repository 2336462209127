import {
  QuoteLineInputType,
  quoteLineInputTypeValidatorBuilder,
} from '@bas/financial-domain/input-types';
import { Language, Money, Uuid } from '@bas/value-objects';
import * as Yup from 'yup';

export type QuoteInputType = {
  identityId?: Uuid | null;
  customer: {
    language: Language;
  };
  quote?: {
    templateId?: Uuid | null;
    includingVat: boolean;
    shouldChargeVat: boolean;
    expiresAt: Date;
    freeText: { [key: string]: string };
    lines: QuoteLineInputType[];
    totalIncludingVat: Money;
  };
};

export const QuoteInputTypeValidatorBuilder = (): Yup.ObjectSchema<{
  quote: {
    templateId: Uuid;
    includingVat: boolean | null;
  };
}> =>
  Yup.object({
    quote: Yup.object({
      templateId: Yup.string().label('label.templateId').required(),
      includingVat: Yup.boolean()
        .nullable()
        .label('label.quoteIncludingVat')
        .required(),
      lines: Yup.array().of(quoteLineInputTypeValidatorBuilder()),
    })
      // eslint-disable-next-line no-empty-pattern
      .when([], ([], schema, { value }) => {
        if (value.ignoreZeroQuantityLines) {
          return schema.concat(
            Yup.object({
              lines: Yup.array()
                .of(quoteLineInputTypeValidatorBuilder())
                .compact(
                  (v) => !v.quantity || v.quantity === '0' || v.quantity === 0,
                )
                .min(1)
                .label('label.lines')
                .required(),
            }),
          );
        }

        return schema.concat(
          Yup.object({
            lines: Yup.array()
              .of(quoteLineInputTypeValidatorBuilder())
              .min(1)
              .label('label.lines')
              .required(),
          }),
        );
      }),
  });
