import type { WhatsAppBusinessParticipant } from '../../types';
import { ParticipantType } from '../../types';
import { isParticipant } from './Participant';

export function isWhatsAppBusinessParticipant(
  object: unknown,
): object is WhatsAppBusinessParticipant {
  return (
    isParticipant(object) &&
    object.participantType === ParticipantType.WHATSAPP_BUSINESS
  );
}
