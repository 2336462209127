import { Uuid } from '@bas/value-objects';
import * as Yup from 'yup';

export type PeopleWithDifferentHoursInputType = {
  areTherePeopleWithDifferentHours?: boolean;
  peopleWithDifferentHours: {
    employeeId: Uuid;
    label: string;
    startDate: Date;
    endDate: Date;
    breakTime: number | null;
    reason?: string | null;
  }[];
};

export const PeopleWithDifferentHoursInputTypeDefaultValues =
  (): PeopleWithDifferentHoursInputType => ({
    areTherePeopleWithDifferentHours: false,
    peopleWithDifferentHours: [],
  });

export const PeopleWithDifferentHoursInputTypeValidationBuilder =
  (): Yup.ObjectSchema<PeopleWithDifferentHoursInputType> =>
    Yup.object({
      areTherePeopleWithDifferentHours: Yup.boolean().required(),
      peopleWithDifferentHours: Yup.array()
        .of(
          Yup.object({
            employeeId: Yup.string().required().label('label.employee'),
            label: Yup.string().required().label('label.label'),
            startDate: Yup.date().required().label('label.startTime'),
            endDate: Yup.date().required().label('label.endTime'),
            breakTime: Yup.number()
              .transform((value, original) =>
                original === '' || original === null ? null : value,
              )
              .nullable()
              .required()
              .label('label.breakTime'),
            reason: Yup.string().nullable(),
          }),
        )
        .required(),
    });
