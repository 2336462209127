import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type RemoveTeamMutationProps = {
  teamId: Uuid;
  replaceByTeamId: Uuid;
};

export const RemoveTeamMutation = async ({
  teamId,
  ...values
}: RemoveTeamMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/hrm/teams/${teamId}/remove`, {
    teamId,
    ...values,
  });

export const useRemoveTeamMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveTeamMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  RemoveTeamMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveTeamMutationProps
  >({
    mutationFn: RemoveTeamMutation,
    throwOnError: false,
    ...options,
  });
