import { NewsListItem } from '@bas/communication-domain/native/molecules';
import { useUserStore } from '@bas/shared/state';
import { News, userHasReadNewsItem } from '@bas/tenant-domain/models';
import { usePublishedNewsRequest } from '@bas/tenant-domain/requests';
import { Typography } from '@bas/ui/native/base';
import { LoadingScreen } from '@bas/ui/native/templates';
import dayjs from 'dayjs';
import { router } from 'expo-router';
import { StatusBar } from 'expo-status-bar';
import { ReactElement, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { RefreshControl, ScrollView, StyleSheet, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 14,
  },
  flexContainer: {
    flex: 1,
  },
  title: {
    paddingBottom: 24,
  },
  newsContainer: {
    marginBottom: 10,
  },
  readTitle: {
    marginTop: 29,
    marginBottom: 8,
  },
});

const MobileEmployeeNewsListScreen = (): ReactElement => {
  const userState = useUserStore((state) => state.user);
  const publishedBefore = useMemo(() => new Date(), []);

  const {
    isFetching,
    isLoading,
    refetch,
    data: newsData,
  } = usePublishedNewsRequest({
    publishedBefore,
  });

  const news = useMemo(
    () =>
      (newsData?.data?.member || []).filter((newsItem) =>
        dayjs(newsItem?.publishedAt).isBefore(dayjs()),
      ),
    [newsData?.data],
  );

  const unreadNews = useMemo(
    () =>
      news.filter(
        (newsItem) => !userHasReadNewsItem(newsItem, userState?.userId || ''),
      ),
    [news, userState?.userId],
  );

  const readNews = useMemo(
    () =>
      news.filter((newsItem) =>
        userHasReadNewsItem(newsItem, userState?.userId || ''),
      ),
    [news, userState?.userId],
  );

  const onRefresh = useCallback(() => {
    refetch();
  }, [refetch]);

  const handleOpenNews = useCallback((newsItem: News) => {
    router.push(`/news/${newsItem.newsId}`);
  }, []);

  if (isLoading) {
    // @todo replace with skeleton screen
    return <LoadingScreen />;
  }

  return (
    <SafeAreaView style={styles.flexContainer}>
      {/* eslint-disable-next-line react/style-prop-object */}
      <StatusBar style="dark" />
      <View style={styles.container}>
        <ScrollView
          style={styles.flexContainer}
          refreshControl={
            <RefreshControl refreshing={isFetching} onRefresh={onRefresh} />
          }
        >
          <Typography variant="h5" fontWeight={700} style={styles.title}>
            <FormattedMessage id="mobileApp.latestUpdates" />
          </Typography>
          {unreadNews.map((newsItem) => (
            <View style={styles.newsContainer} key={newsItem.newsId}>
              <NewsListItem
                newsItem={newsItem}
                onPress={() => handleOpenNews(newsItem)}
              />
            </View>
          ))}
          {unreadNews.length === 0 && (
            <Typography style={styles.newsContainer}>
              <FormattedMessage id="label.noNewsItemsFound" />
            </Typography>
          )}
          {readNews.length > 0 && (
            <Typography style={styles.readTitle}>
              <FormattedMessage id="mobileApp.read" />
            </Typography>
          )}
          {readNews.map((newsItem) => (
            <View style={styles.newsContainer} key={newsItem.newsId}>
              <NewsListItem
                newsItem={newsItem}
                isRead
                onPress={() => handleOpenNews(newsItem)}
              />
            </View>
          ))}
        </ScrollView>
      </View>
    </SafeAreaView>
  );
};
export default MobileEmployeeNewsListScreen;
