import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  contentContainer: {
    flex: 1,
    width: '100%',
  },
  padding: {
    paddingHorizontal: 20,
  },
  scrollContainer: {
    paddingBottom: 52,
  },
  stepsNavigationContainer: {
    width: '100%',
    marginTop: 'auto',
  },
});
