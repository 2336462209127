import * as Yup from 'yup';

export type CustomFurnitureInputType = {
  label: string;
  cubicMeter: number | string;
  servicesPossible: boolean;
};

export const CustomFurnitureInputTypeDefaultValues =
  (): CustomFurnitureInputType => ({
    label: '',
    cubicMeter: 0,
    servicesPossible: false,
  });

export const CustomFurnitureInputTypeValidatorBuilder =
  (): Yup.ObjectSchema<CustomFurnitureInputType> =>
    Yup.object({
      label: Yup.string().max(200).required().label('label.name'),
      cubicMeter: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .nullable()
        .required()
        .positive(),
      servicesPossible: Yup.boolean().required(),
    });
