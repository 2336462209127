import { FinancialDocumentLinesEditorInputType } from '@bas/financial-domain/input-types';
import { Skeleton } from '@bas/ui/native/atoms';
import { Box } from '@bas/ui/native/base';
import * as React from 'react';
import { ReactElement, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { View } from 'react-native';
import { v7 } from 'uuid';
import { MobileFinancialDocumentLinesEditorLineRow } from '../MobileFinancialDocumentLinesEditorLineRow';

export type MobileFinancialDocumentLinesEditorProps = {
  documentType: 'quote' | 'invoice';
  onSorting: (params: { oldIndex: number; newIndex: number }) => void;
  light?: boolean;
};

const mappedLines = new Array(100).fill(null).map((_, index) => ({
  id: v7(),
  formIndex: index,
}));

const LoadingRow = () => <Skeleton height={60} />;

const ActiveMobileFinancialDocumentLinesEditorLineRow = ({
  formIndex,
  documentType,
  light,
}: {
  formIndex: number;
  documentType: 'quote' | 'invoice';
  light?: boolean;
}) => {
  const [loaded, setLoaded] = useState(false);
  const isCreated = useWatch<FinancialDocumentLinesEditorInputType>({
    name: `${documentType}.lines.${formIndex}.created`,
  });
  const lineId = useWatch<FinancialDocumentLinesEditorInputType>({
    name: `${documentType}.lines.${formIndex}.lineId`,
  }) as string;

  if (!isCreated) {
    return null;
  }

  if (!loaded) {
    return (
      <View onLayout={() => setLoaded(true)}>
        <LoadingRow />
      </View>
    );
  }

  return (
    <MobileFinancialDocumentLinesEditorLineRow
      key={lineId}
      index={formIndex}
      documentType={documentType}
    />
  );
};

const MobileFinancialDocumentLinesEditor = ({
  onSorting,
  light,
  documentType,
}: MobileFinancialDocumentLinesEditorProps): ReactElement => (
  <Box
    width="100%"
    flexBasis="100%"
    flex={1}
    flexDirection="row"
    flexWrap="wrap"
    pb={3}
  >
    <Box flex={1} flexBasis="100%" width="100%">
      {mappedLines.length > 0 &&
        mappedLines.map((item, index) => (
          <ActiveMobileFinancialDocumentLinesEditorLineRow
            key={item.formIndex}
            formIndex={item.formIndex}
            documentType={documentType}
            light={light}
          />
        ))}
    </Box>
  </Box>
);

export default MobileFinancialDocumentLinesEditor;
