import { Typography } from '@bas/ui/native/base';
import { colors } from '@bas/theme';
import { ReactElement, useMemo, useState } from 'react';
import { Image, ImageStyle, View, ViewStyle } from 'react-native';
import styles from './styles';

export type AvatarProps = {
  src?: string;
  name: string;
  light?: boolean;
  containerStyle?: ViewStyle;
  imageStyle?: ImageStyle;
  size?: number;
  borderRadius?: number;
};

const stringAvatar = (name: string) => {
  const splittedName = name.split(' ');
  if (splittedName.length === 1) {
    return `${splittedName[0][0]}${splittedName[0][1]}`;
  }

  return `${splittedName[0][0]}${splittedName[1][0]}`;
};

const Avatar = ({
  src,
  name,
  light,
  containerStyle,
  imageStyle,
  size,
  borderRadius,
}: AvatarProps): ReactElement => {
  const [showText, setShowText] = useState<boolean>(true);

  const sizeStyle = useMemo(() => {
    let resultingSize = 40;
    if (size) {
      resultingSize = size;
    }
    return {
      width: resultingSize,
      height: resultingSize,
      borderRadius: borderRadius || resultingSize,
    };
  }, [borderRadius, size]);

  return (
    <View
      style={[
        styles.imageContainer,
        light && styles.lightContainer,
        containerStyle,
        sizeStyle,
      ]}
    >
      {src && (
        <Image
          source={{ uri: src }}
          onLoad={() => setShowText(false)}
          style={[styles.image, imageStyle, sizeStyle]}
        />
      )}
      {showText && (
        <View style={styles.textContainer}>
          <Typography overrideColor={colors.white} variant="h5">
            {stringAvatar(name)}
          </Typography>
        </View>
      )}
    </View>
  );
};

export default Avatar;
