import { MovingJobAddressType } from '@bas/project-domain/models';
import {
  AddressInputType,
  addressInputTypeDefaultValues,
  addressInputTypeValidationBuilder,
} from '@bas/shared/input-types';
import { BuildingType } from '@bas/value-objects';
import * as Yup from 'yup';

export interface MovingJobAddressInputType extends AddressInputType {
  addressType: MovingJobAddressType;
  buildingType?: BuildingType;
  primary: boolean;
  deliverBoxes: boolean;
  retrieveBoxes: boolean;
  distanceFromCar?: number | null;
  distanceToApartment?: number | null;
  floor?: number | null;
  hasElevator?: boolean | null;
  trafficSigns?: boolean | null;
  permitNeeded?: boolean | null;
  unknownAddress?: boolean;
  notes?: string | null;
}

export const MovingJobAddressInputTypeDefaultValues =
  (): MovingJobAddressInputType => ({
    ...addressInputTypeDefaultValues(),
    addressType: MovingJobAddressType.FROM,
    buildingType: BuildingType.UNKNOWN,
    deliverBoxes: true,
    distanceFromCar: undefined,
    distanceToApartment: undefined,
    floor: undefined,
    hasElevator: null,
    houseNumberAddition: '',
    notes: '',
    primary: false,
    retrieveBoxes: true,
    trafficSigns: undefined,
    permitNeeded: undefined,
    unknownAddress: undefined,
  });

export const MovingJobAddressInputTypeValidationBuilder =
  (): Yup.ObjectSchema<MovingJobAddressInputType> =>
    Yup.object({
      addressType: Yup.mixed<MovingJobAddressType>()
        .label('label.addressType')
        .oneOf(Object.values(MovingJobAddressType))
        .required(),
      buildingType: Yup.mixed<BuildingType>()
        .label('label.buildingType')
        .oneOf(Object.values(BuildingType)),
      primary: Yup.boolean().label('label.primary').required(),
      deliverBoxes: Yup.boolean()
        .nullable()
        .label('label.deliverBoxes')
        .required(),
      retrieveBoxes: Yup.boolean()
        .nullable()
        .label('label.retrieveBoxes')
        .required(),
      distanceFromCar: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .label('label.distance')
        .nullable(),
      distanceToApartment: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .label('label.distance')
        .nullable(),
      floor: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .label('label.floor')
        .nullable(),
      hasElevator: Yup.boolean()
        .label('label.hasElevator')
        .notRequired()
        .nullable(),
      trafficSigns: Yup.boolean().label('label.trafficSigns').nullable(),
      permitNeeded: Yup.boolean().label('label.permitNeeded').nullable(),
      unknownAddress: Yup.boolean().label('label.unknownAddress'),
      notes: Yup.string().label('label.notes').nullable(),
    }).concat(addressInputTypeValidationBuilder());
