import { Uuid } from '@bas/value-objects';
import * as Yup from 'yup';

export interface MergeRelationsInputType {
  relationToMergeInto?: {
    relationId: Uuid;
  };
}

export const MergeRelationsInputTypeValidationBuilder =
  (): Yup.ObjectSchema<MergeRelationsInputType> =>
    Yup.object({
      relationToMergeInto: Yup.object({
        relationId: Yup.string().required().label('label.relationToMergeInto'),
      })
        .nullable()
        .required()
        .label('label.relationToMergeInto'),
    });

export const MergeRelationsInputTypeDefaultValues =
  (): MergeRelationsInputType => ({});
