import {
  InventoryMovementInputType,
  InventoryMovementInputTypeValidationBuilder,
} from '@bas/wms-domain/input-types';
import * as Yup from 'yup';

export type WhatInventoryItemsAreNeededStepInputType = {
  neededInventoryItems: InventoryMovementInputType[];
};

export const WhatInventoryItemsAreNeededStepInputTypeValidatorBuilder =
  (): Yup.ObjectSchema<WhatInventoryItemsAreNeededStepInputType> =>
    Yup.object({
      neededInventoryItems: Yup.array()
        .of(InventoryMovementInputTypeValidationBuilder())
        .required(),
    });
