import { colors, fontSizes, lineHeights } from '@bas/theme';
import { createTheme } from '@shopify/restyle';

export const theme = createTheme({
  colors: {
    mainBackground: colors.lila[100],
    darkBackground: colors.blue[900],
    mainPrimary: colors.blue[500],
    lightPrimary: colors.blue[200],
    darkPrimary: colors.blue[700],
    lightText: colors.lila[100],
    subtitle1Color: colors.lila[700],
    darkText: colors.lila[800],
    secondaryTitle: colors.lila[600],
    white: colors.white,
    black: colors.black,
    card: colors.white,
    cardBorder: colors.lila[200],
    titleBorder: colors.lila[400],
    success: colors.green['300'],
    transparentBorder: 'rgba(255, 255, 255, 0.20)',
    deleteBackground: colors.red[600],
    'lila.100': colors.lila[100],
    'lila.200': colors.lila[200],
    'lila.300': colors.lila[300],
    'lila.400': colors.lila[400],
    'lila.600': colors.lila[600],
    'lila.700': colors.lila[700],
    'lila.800': colors.lila[800],
    'gray.400': colors.gray[400],
    'yellow.100': colors.yellow[100],
    'orange.300': colors.orange[300],
    'orange.600': colors.orange[600],
    'orange.700': colors.orange[700],
    'orange.750': colors.orange[750],
    'orange.800': colors.orange[800],
    'orange.900': colors.orange[900],
    'blue.100': colors.blue[100],
    'green.300': colors.green[300],
    'green.500': colors.green[500],
    'green.800': colors.green[800],
    'red.200': colors.red[200],
    'red.300': colors.red[300],
    'red.400': colors.red[400],
    'red.500': colors.red[500],
    'red.600': colors.red[600],
    'red.700': colors.red[700],
    warningButtonBackground: colors.orange[600],
    warningButtonColor: colors.orange[800],
    transparant: 'transparent',
    calendarLine: '#DDD',
  },
  palette: colors,
  spacing: {
    0: 0,
    0.2: 2,
    0.5: 4,
    1: 8,
    2: 16,
    3: 24,
    4: 32,
    5: 48,
    6: 56,
    7: 64,
    8: 72,
    9: 96,
    10: 120,
    20: 20,
    50: 50,
    80: 80,
    line: 5,
    invoiceLines: 12.75,
    signPagePadding: 36,
    negativeSignPagePadding: -36,
    gap: 12,
    stepsBar: 52,
    blocks: 10,
    calendarTimeTop: 10,
    calendarTimeLeft: 28,
  },
  textVariants: {
    body1: {
      fontSize: fontSizes.base,
      lineHeight: lineHeights.base,
      color: 'darkText',
    },
    body2: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.sm,
      color: 'darkText',
    },
    medium: {
      fontSize: 18,
      lineHeight: 23,
      color: 'darkText',
    },
    caption: {
      fontSize: fontSizes.xs,
      lineHeight: lineHeights.xs,
      color: 'darkText',
    },
    h1: {
      fontSize: fontSizes['4xl'],
      lineHeight: lineHeights['4xl'],
      color: 'darkText',
    },
    h2: {
      fontSize: fontSizes['3xl'],
      lineHeight: lineHeights['3xl'],
      color: 'darkText',
    },
    h3: {
      fontSize: fontSizes['2xl'],
      lineHeight: lineHeights['2xl'],
      color: 'darkText',
    },
    h4: {
      fontSize: fontSizes.xl,
      lineHeight: lineHeights.xl,
      color: 'darkText',
    },
    h5: {
      fontSize: fontSizes.lg,
      lineHeight: lineHeights.lg,
      color: 'darkText',
    },
    h6: { color: 'darkText' },
    subtitle1: {
      fontSize: fontSizes.sm,
      lineHeight: 24,
      fontFamily: 'Work_Sans_700_normal',
      fontWeight: '700',
      textTransform: 'uppercase',
      letterSpacing: 1.2,
      color: 'darkText',
    },
    subtitle2: {
      color: 'darkText',
    },
    button: {
      fontSize: fontSizes.base,
    },
    overline: {},
  },
  breakpoints: {
    phone: 0,
    biggerPhone: 414,
    tablet: {
      width: 600,
      height: 600,
    },
    landscapeTablet: {
      width: 600,
      height: 900,
    },
    largerTablet: {
      width: 900,
      height: 900,
    },
    lg: {
      width: 1281,
      height: 1281,
    },
  },
});

export type ShopifyTheme = typeof theme;
