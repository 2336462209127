import {
  CrmAddressInputType,
  crmAddressInputTypeValidator,
  CrmContactPersonInputType,
  crmContactPersonInputTypeTypeValidator,
} from '@bas/crm-domain/input-types';
import { Relation } from '@bas/crm-domain/models';
import { Uuid } from '@bas/value-objects';
import * as Yup from 'yup';

export interface FinancialDocumentCustomerInformationInputType {
  relation?: Relation;
  contactPerson: CrmContactPersonInputType;
  contactPersonId?: Uuid | 'new' | 'private';
  address: CrmAddressInputType;
  addressId?: Uuid | 'new';
  project?:
    | {
        projectId?: Uuid | null;
      }
    | null
    | undefined;
}

export const FinancialDocumentCustomerInformationInputTypeValidationBuilder =
  (): Yup.ObjectSchema<
    Omit<
      FinancialDocumentCustomerInformationInputType,
      'relation' | 'address' | 'contactPerson'
    >
  > =>
    Yup.object({
      relation: Yup.object({
        relationId: Yup.string().required().label('label.customer'),
      })
        .required()
        .label('label.customer'),
      project: Yup.object({
        projectId: Yup.string().label('label.project').nullable(),
      }).nullable(),
      contactPersonId: Yup.string().label('label.contactPerson'),
      addressId: Yup.string().required().label('label.address'),
      contactPerson: Yup.object().when(
        ['contactPersonId'],
        ([value], schema) => {
          if (value === 'new') {
            return schema.concat(crmContactPersonInputTypeTypeValidator());
          }

          return schema.nullable();
        },
      ),
      address: Yup.object().when(['addressId'], ([value], schema) => {
        if (value === 'new') {
          return schema.concat(crmAddressInputTypeValidator());
        }

        return schema.nullable();
      }),
    });
