import { RelationType } from '@bas/crm-domain/models';
import { Quote } from '@bas/financial-domain/models';
import { MovingJobAddressInputTypeDefaultValues } from '@bas/project-domain/input-types';
import {
  MovingJobAddress,
  MovingJobAddressType,
  ProjectEstimate,
} from '@bas/project-domain/models';
import { BillingMoment, PublicServiceType } from '@bas/shared/models';
import {
  EmailAddress,
  Gender,
  Language,
  PricingType,
  Salutation,
  Uuid,
} from '@bas/value-objects';
import { v7 } from 'uuid';
import { MovingJobTaxationCustomer } from './MovingJobTaxationCustomer';
import { MovingJobTaxationRoom } from './MovingJobTaxationRoom';

export type MovingJobTaxationWizardInput = {
  projectId: Uuid;
  tenantId: Uuid;
  identityId?: Uuid | null;
  customer: MovingJobTaxationCustomer;
  packageId: Uuid;
  ignoreZeroQuantityLines: boolean;
  pricingType: PricingType;
  addresses: MovingJobAddress[];
  rooms: MovingJobTaxationRoom[];
  services: (PublicServiceType & { selected: boolean })[];
  notes: string;
  notesForEmployees: string;
  storageNotes: string;
  customerNotes: string;
  billingMoments: BillingMoment[];
  neededInventoryItems: {
    quantity: number;
    quantityWornOut?: number | null;
    inventoryItemId: Uuid;
  }[];
  latestRequestedValues?: MovingJobTaxationWizardInput;
  latestQuoteRequestedValues?: {
    overwrittenEstimateReason?: string;
    projectId: Uuid;
    estimate?: ProjectEstimate;
  };
  overwrittenEstimateReason?: string;
  estimate?: ProjectEstimate;
  quote?: Quote & {
    ignoreZeroQuantityLines: boolean;
  };
  quoteId?: Uuid;
  shouldSendQuote?: boolean;
  customerSignsAtTheTable?: boolean;
  signQuote: {
    name: string;
    emailAddress: EmailAddress;
    remarks?: string | null;
    signature: string;
    acceptedLines: Uuid[];
  };
};

export const MovingJobTaxationWizardInitialValues =
  (): MovingJobTaxationWizardInput => ({
    projectId: v7(),
    tenantId: v7(),
    identityId: '',
    ignoreZeroQuantityLines: false,
    customer: {
      customerId: v7(),
      relationType: RelationType.PRIVATE,
      salutation: Salutation.UNKNOWN,
      gender: Gender.UNKNOWN,
      firstName: '',
      middleName: '',
      lastName: '',
      companyName: '',
      emailAddress: '',
      phoneNumber: '',
      mobileNumber: '',
      vatNumber: '',
      language: Language.NL,
    },
    packageId: '',
    pricingType: PricingType.UNKNOWN,
    addresses: [
      {
        ...MovingJobAddressInputTypeDefaultValues(),
        addressId: v7(),
        addressType: MovingJobAddressType.FROM,
        primary: true,
        deliverBoxes: true,
        unknownAddress: false,
      },
      {
        ...MovingJobAddressInputTypeDefaultValues(),
        addressId: v7(),
        addressType: MovingJobAddressType.TO,
        primary: true,
        deliverBoxes: true,
        unknownAddress: true,
      },
    ],
    rooms: [],
    services: [],
    neededInventoryItems: [],
    notes: '',
    notesForEmployees: '',
    customerNotes: '',
    storageNotes: '',
    billingMoments: [],
    estimate: undefined,
    quote: undefined,
    customerSignsAtTheTable: true,
    signQuote: {
      name: '',
      emailAddress: '',
      signature: '',
      acceptedLines: [],
    },
  });
