import { isObjectResponse } from '@bas/value-objects';
import type { CalculatedQuoteMomentLine } from '../types';
import { isQuoteMomentLine } from './QuoteMomentLine';

export function isCalculatedQuoteMomentLine(
  object: unknown,
): object is CalculatedQuoteMomentLine {
  return (
    !!object &&
    isObjectResponse(object) &&
    isQuoteMomentLine(object) &&
    object['@type'] === 'CalculatedQuoteMomentLine'
  );
}
