import type { Relation } from '@bas/crm-domain/models';
import type { MovingJobStatus } from './MovingJobStatus';
import type { MovingJobAddress } from './MovingJobAddress';
import type { ListedProject } from './ListedProject';

export interface ListedMovingJob extends ListedProject {
  customer: Relation;
  projectStatus: MovingJobStatus;
  addresses: Array<MovingJobAddress>;
}
