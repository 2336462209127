import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type CancelDeclarationMutationProps = {
  declarationId: Uuid;
};

export const CancelDeclarationMutation = async ({
  declarationId,
  ...values
}: CancelDeclarationMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/hrm/declarations/${declarationId}/cancel`, {
    declarationId,
    ...values,
  });

export const useCancelDeclarationMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    CancelDeclarationMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  CancelDeclarationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    CancelDeclarationMutationProps
  >({
    mutationFn: CancelDeclarationMutation,
    throwOnError: false,
    ...options,
  });
