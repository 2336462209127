import { MovingJob, MovingJobAddressType } from '@bas/project-domain/models';
import { AddressBlock } from '@bas/ui/native/atoms';
import { Typography } from '@bas/ui/native/base';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons/faArrowRight';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { FunctionComponent } from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';
import { MovingJobAddressBlockVariant } from './enum';

export type MovingJobAddressBlockProps = ViewProps & {
  project: MovingJob;
  variant?: MovingJobAddressBlockVariant;
  color: string;
};
const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  shortContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  marginLeft: {
    marginLeft: 8,
  },
});

const MovingJobAddressBlock: FunctionComponent<MovingJobAddressBlockProps> = ({
  project,
  variant = MovingJobAddressBlockVariant.SHORT,
  color,
  ...args
}: MovingJobAddressBlockProps) => {
  const primaryAddressFrom = project.addresses.find(
    ({ primary, addressType }) =>
      primary === true && addressType === MovingJobAddressType.FROM,
  );
  const primaryAddressTo = project.addresses.find(
    ({ primary, addressType }) =>
      primary === true && addressType === MovingJobAddressType.TO,
  );

  let content;
  const arrowIcon = (
    <FontAwesomeIcon
      icon={faArrowRight}
      style={{ marginLeft: 8, marginRight: 8 }}
      color={color}
    />
  );

  const longVariant = (
    <View style={styles.container}>
      {primaryAddressFrom && (
        <View>
          <AddressBlock color={color} address={primaryAddressFrom} />
        </View>
      )}
      {primaryAddressFrom && primaryAddressTo && (
        <View style={styles.marginLeft}>{arrowIcon}</View>
      )}
      {primaryAddressTo && (
        <View style={styles.marginLeft}>
          <AddressBlock color={color} address={primaryAddressTo} />
        </View>
      )}
    </View>
  );

  if (variant === MovingJobAddressBlockVariant.SHORT) {
    if (primaryAddressFrom?.city !== primaryAddressTo?.city) {
      content = (
        <View style={styles.shortContainer}>
          <Typography overrideColor={color}>
            {primaryAddressFrom?.city}
          </Typography>
          {arrowIcon}
          <Typography overrideColor={color}>
            {primaryAddressTo?.city}
          </Typography>
        </View>
      );
    } else {
      content = (
        <View style={styles.shortContainer}>
          <Typography overrideColor={color}>
            {primaryAddressFrom?.zipCode}
          </Typography>
          {arrowIcon}
          <Typography overrideColor={color}>
            {primaryAddressTo?.zipCode}
          </Typography>
        </View>
      );
    }

    return <View {...args}>{content}</View>;
  }

  return longVariant;
};

export default MovingJobAddressBlock;
