import { formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import { StoredItemInputType } from '@bas/wms-domain/input-types';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type UpdateStorageInventoryListMutationProps = {
  storageId: Uuid;
  storedItems: StoredItemInputType[];
  isUpdateOnly?: boolean;
};

export const UpdateStorageInventoryListMutation = async ({
  storageId,
  storedItems,
  ...values
}: UpdateStorageInventoryListMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/storages/${storageId}/update-inventory-list`, {
    storageId,
    storedItems: storedItems
      .filter((item) => !item.delete)
      .map((item) => ({
        ...item,
        loadedOn: item.loadedOn ? formatDateTime(item.loadedOn) : null,
        unloadedOn: item.unloadedOn ? formatDateTime(item.unloadedOn) : null,
      })),
    ...values,
  });

export const useUpdateStorageInventoryListMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateStorageInventoryListMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  UpdateStorageInventoryListMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateStorageInventoryListMutationProps
  >({
    mutationFn: UpdateStorageInventoryListMutation,
    throwOnError: false,
    ...options,
  });
