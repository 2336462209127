import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ReceivedDepositForProjectMutationProps = {
  projectId: Uuid;
};

export const ReceivedDepositForProjectMutation = async ({
  projectId,
  ...values
}: ReceivedDepositForProjectMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/projects/${projectId}/received-deposit`, {
    projectId,
    ...values,
  });

export const useReceivedDepositForProjectMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ReceivedDepositForProjectMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ReceivedDepositForProjectMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ReceivedDepositForProjectMutationProps
  >({
    mutationFn: ReceivedDepositForProjectMutation,
    throwOnError: false,
    ...options,
  });
