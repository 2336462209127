import {
  emptyRecurringInformation,
  RecurringInformationType,
} from '@bas/value-objects';
import dayjs, { Dayjs } from 'dayjs';
import * as Yup from 'yup';

export type RecurringInformationInputType = RecurringInformationType;

export const RecurringInformationInputTypeDefaultValues = (
  date?: Date | Dayjs,
): RecurringInformationInputType => emptyRecurringInformation(date);

export const RecurringInformationInputTypeValidationSchema = (
  existingRepeatFromDate: Date | undefined,
): Yup.ObjectSchema<RecurringInformationInputType> =>
  Yup.object({
    rrule: Yup.string().required().label('label.rrule'),
    repeats: Yup.mixed<'daily' | 'weekly' | 'monthly' | 'yearly'>()
      .required()
      .oneOf(['daily', 'weekly', 'monthly', 'yearly'])
      .label('label.repeats'),
    repeatFrom: Yup.date()
      .min(existingRepeatFromDate || dayjs().startOf('day').toDate())
      .required()
      .label('label.repeatFrom'),
    repeatUntil: Yup.date()
      .optional()
      .label('label.repeatUntil')
      .when(['untilType'], ([untilType], schema) => {
        if (untilType === 'date') {
          return schema.required();
        }

        return schema;
      })
      .when(['repeatFrom'], ([repeatFrom], schema) => {
        if (!repeatFrom) {
          return schema.min(new Date());
        }

        const repeatFromDay = dayjs(repeatFrom).add(1, 'day');
        if (repeatFromDay.isBefore(dayjs(), 'day')) {
          return schema.min(dayjs().toDate());
        }

        return schema.min(
          repeatFromDay.toDate(),
          'Herhaling tot moet na de startdatum liggen',
        );
      }),
    repeatCount: Yup.number().optional().label('label.repeatCount'),
    repeatInterval: Yup.number().optional().label('label.repeatEvery'),
    untilType: Yup.mixed<'date' | 'count' | 'infinite'>()
      .oneOf(['date', 'count', 'infinite'])
      .required()
      .label('label.untilType'),
    weekDays: Yup.array()
      .of(
        Yup.mixed<
          | 'monday'
          | 'tuesday'
          | 'wednesday'
          | 'thursday'
          | 'friday'
          | 'saturday'
          | 'sunday'
        >()
          .required()
          .oneOf([
            'monday',
            'tuesday',
            'wednesday',
            'thursday',
            'friday',
            'saturday',
            'sunday',
          ]),
      )
      .optional()
      .when(['repeats'], ([repeats], schema) => {
        if (repeats === 'weekly') {
          return schema.required().min(1);
        }

        return schema;
      })
      .label('label.weekDays'),
    repeatOnType: Yup.mixed<'dayOfMonth' | 'weekDay'>()
      .oneOf(['dayOfMonth', 'weekDay'])
      .required()
      .label('label.repeatOnType'),
    repeatOnDayOfMonth: Yup.number()
      .optional()
      .label('label.repeatOnDayOfMonth'),
    repeatOn: Yup.object({
      which: Yup.mixed<'first' | 'second' | 'third' | 'fourth' | 'last'>()
        .required()
        .oneOf(['first', 'second', 'third', 'fourth', 'last'])
        .label('label.repeatOn.which'),
      day: Yup.mixed<
        | 'day'
        | 'weekday'
        | 'weekend-day'
        | 'monday'
        | 'tuesday'
        | 'wednesday'
        | 'thursday'
        | 'friday'
        | 'saturday'
        | 'sunday'
      >()
        .oneOf([
          'day',
          'weekday',
          'weekend-day',
          'monday',
          'tuesday',
          'wednesday',
          'thursday',
          'friday',
          'saturday',
          'sunday',
        ])
        .required()
        .label('label.repeatOn.day'),
    }).optional(),
  });
