import {
  AddAddressToRelationMutationProps,
  AddContactPersonToRelationMutationProps,
} from '@bas/crm-domain/mutations';
import { CreateQuoteInputType } from '@bas/financial-domain/input-types';
import { Quote } from '@bas/financial-domain/models';
import { CreateQuoteMutationProps } from '@bas/financial-domain/mutations';
import { getDefaultCountry } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { v7 } from 'uuid';

type Values = { quote: CreateQuoteInputType };

export const useCreateQuoteFromInputType = <T extends Values = Values>(
  addAddressToRelationMutation: UseMutateAsyncFunction<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddAddressToRelationMutationProps
  >,
  addContactPersonToRelationMutation: UseMutateAsyncFunction<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddContactPersonToRelationMutationProps
  >,
  createQuote: (
    mutation: CreateQuoteMutationProps,
    values: T,
  ) => Promise<AxiosResponse<Quote>>,
  callback?: (quoteId: Uuid) => Promise<void> | void,
): ((values: T) => Promise<void>) =>
  useCallback(
    async (formValues) => {
      const { quote: values } = formValues;
      let { contactPersonId, addressId } = values;
      if (!values.relation) {
        return;
      }

      const {
        relation: { relationId },
      } = values;

      if (contactPersonId === 'new') {
        contactPersonId = v7();
        await addContactPersonToRelationMutation({
          relationId,
          ...values.contactPerson,
          contactPersonId,
        });
      } else if (contactPersonId === 'private') {
        contactPersonId = '';
      }

      if (addressId === 'new') {
        addressId = v7();
        await addAddressToRelationMutation({
          relationId,
          ...values.address,
          addressId,
        });
      }

      const newQuoteId = v7();
      await createQuote(
        {
          quoteId: newQuoteId,
          relationId,
          addressId: addressId || '',
          contactPersonId,
          freeText: values.freeText,
          identityId: values.identityId || '',
          includingVat: values.includingVat,
          lines: values.lines,
          projectId: values.projectId,
          templateId: values.templateId,
          reference: values.reference || '',
          discount: values.discount,
          region: getDefaultCountry(),
        },
        formValues,
      );

      if (callback) {
        await callback(newQuoteId);
      }
    },
    [
      addAddressToRelationMutation,
      addContactPersonToRelationMutation,
      createQuote,
      callback,
    ],
  );
