import { formatDate } from '@bas/shared/utils';
import { ErrorResponse, Money, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type AddHandlingCostsToStorageMutationProps = {
  handlingCostsId: Uuid;
  storageId: Uuid;
  amount: Money;
  quantity: number;
  handlingDate: Date;
  description: string;
  invoiced: boolean;
  invoicedDate?: Date | null;
};

export const AddHandlingCostsToStorageMutation = async ({
  handlingDate,
  invoicedDate,
  storageId,
  ...values
}: AddHandlingCostsToStorageMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/{tenantId}/storages/${storageId}/handling-costs`, {
    handlingDate: handlingDate ? formatDate(handlingDate) : null,
    invoicedDate: invoicedDate ? formatDate(invoicedDate) : null,
    ...values,
  });

export const useAddHandlingCostsToStorageMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddHandlingCostsToStorageMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  AddHandlingCostsToStorageMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddHandlingCostsToStorageMutationProps
  >({
    mutationFn: AddHandlingCostsToStorageMutation,
    throwOnError: false,
    ...options,
  });
