import { useFormBackendHandler } from '@bas/shared/hooks';
import { colors } from '@bas/theme';
import { Typography } from '@bas/ui/native/base';
import { yupResolver } from '@hookform/resolvers/yup';
import { memo, ReactElement, ReactNode, useEffect, useMemo } from 'react';
import {
  DefaultValues,
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { AnyObjectSchema } from 'yup';
import { FormSubmitContext } from './state';

export type ReactHookFormProps<TFieldValues extends FieldValues> = {
  defaultValues?: DefaultValues<TFieldValues>;
  validationSchema?: AnyObjectSchema | (() => AnyObjectSchema);
  useProvider?: boolean;
  onSubmit: SubmitHandler<TFieldValues>;
  onDirty?: (isDirty: boolean) => void;
  children: ReactNode;
  hideBackendErrors?: boolean;
  style?: StyleProp<ViewStyle>;
};

const styling = StyleSheet.create({
  form: { display: 'flex', flex: 1, width: '100%', flexDirection: 'column' },
  error: {
    color: colors.red[500],
  },
});

const ReactHookForm = <TFieldValues extends FieldValues = FieldValues>({
  defaultValues,
  validationSchema,
  children,
  useProvider,
  onSubmit,
  hideBackendErrors,
  onDirty,
  style,
}: ReactHookFormProps<TFieldValues>): ReactElement => {
  const resolver = useMemo(() => {
    let result;
    if (validationSchema) {
      if (typeof validationSchema === 'function') {
        result = yupResolver(validationSchema());
      } else {
        result = yupResolver(validationSchema);
      }
    }
    return result;
  }, [validationSchema]);

  const form = useForm<TFieldValues>({
    resolver,
    mode: 'all',
    defaultValues,
  });

  const handleSubmitWithError = useFormBackendHandler(onSubmit, form);

  const contextValue = useMemo(
    () => ({
      onSubmit: async () => {
        if (!form.formState.isSubmitting) {
          await form.handleSubmit(handleSubmitWithError)();
        }
      },
    }),
    [form, handleSubmitWithError],
  );

  const content = useMemo(() => {
    let result = (
      <FormSubmitContext.Provider value={contextValue}>
        {children}
      </FormSubmitContext.Provider>
    );

    if (useProvider) {
      result = <FormProvider {...form}>{result}</FormProvider>;
    }

    return result;
  }, [children, contextValue, form, useProvider]);

  useEffect(() => {
    onDirty?.(form.formState.isDirty);
  }, [form.formState.isDirty, onDirty]);

  const backendErrors = useMemo(
    () => form.formState.errors?.backendErrors,
    [form.formState.errors?.backendErrors],
  );

  // eslint-disable-next-line no-console
  console.log(form.formState.errors);

  return (
    <View style={style || styling.form}>
      {backendErrors &&
        backendErrors.message &&
        Array.isArray(backendErrors.message) &&
        !hideBackendErrors &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        backendErrors.message.map((message: any, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <Typography style={styling.error} key={index}>
            {message}
          </Typography>
        ))}
      {content}
    </View>
  );
};

const MemoComponent = memo(
  ReactHookForm,
  (prev, next) =>
    prev.defaultValues === next.defaultValues &&
    prev.validationSchema === next.validationSchema &&
    prev.useProvider === next.useProvider &&
    prev.hideBackendErrors === next.hideBackendErrors &&
    prev.onDirty === next.onDirty &&
    prev.onSubmit === next.onSubmit &&
    prev.children === next.children,
) as typeof ReactHookForm;

export default MemoComponent;
