import { useCallback, useMemo } from 'react';
import { Box } from '@bas/ui/native/base';
import { Skeleton } from '@bas/ui/native/atoms';
import { FlashList } from '@shopify/flash-list';

export type LoadingSquareTilesProps = {
  numberOfColumns: number;
  paddingHorizontal?: number;
  numberOfItems?: number;
};

const LoadingSquareTiles = ({
  numberOfColumns,
  paddingHorizontal,
  numberOfItems = 10,
}: LoadingSquareTilesProps) => {
  const handleRenderLoadingItem = useCallback(
    () => (
      <Box
        style={{
          maxHeight: 258,
          maxWidth: 258,
          alignSelf: 'flex-start',
          aspectRatio: 1,
          paddingLeft: 19,
          paddingTop: 19,
          overflow: 'hidden',
          width: '100%',
        }}
      >
        <Skeleton
          style={{
            backgroundColor: 'white',
            borderRadius: 6,
            height: '100%',
            width: '100%',
          }}
        />
      </Box>
    ),
    [],
  );

  const loadingItems = useMemo(
    () => new Array(numberOfItems).fill(''),
    [numberOfItems],
  );

  return (
    <FlashList
      renderItem={handleRenderLoadingItem}
      data={loadingItems}
      numColumns={numberOfColumns}
      estimatedItemSize={237}
      contentContainerStyle={{
        paddingBottom: 24,
        paddingHorizontal,
      }}
    />
  );
};

export default LoadingSquareTiles;
