import { Maintenance } from '@bas/planning-domain/models';
import * as Yup from 'yup';

export interface MaintenanceInputType {
  dealer?: string | null;
  leasingCompany?: string | null;
  maintenance: Maintenance;
}

export const MaintenanceInputTypeDefaultValues = (): MaintenanceInputType => ({
  dealer: '',
  leasingCompany: '',
  maintenance: {
    maintenanceNeededAt: null,
    motNeededAt: null,
  },
});

export const MaintenanceInputTypeValidationBuilder =
  (): Yup.ObjectSchema<MaintenanceInputType> =>
    Yup.object({
      dealer: Yup.string().label('label.dealer'),
      leasingCompany: Yup.string().label('label.leasingCompany'),
      maintenance: Yup.object({
        maintenanceNeededAt: Yup.date()
          .nullable()
          .label('label.maintenanceNeededAt'),
        motNeededAt: Yup.date().nullable().label('label.motNeededAt'),
      }).required(),
    });
