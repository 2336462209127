import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ChangeEmployeeProfilePictureMutationProps = {
  employeeId: Uuid;
  mediaObjectId: Uuid;
};

export const ChangeEmployeeProfilePictureMutation = async ({
  employeeId,
  ...values
}: ChangeEmployeeProfilePictureMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/hrm/employees/${employeeId}/change-profile-picture`,
    {
      employeeId,
      ...values,
    },
  );

export const useChangeEmployeeProfilePictureMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeEmployeeProfilePictureMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ChangeEmployeeProfilePictureMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeEmployeeProfilePictureMutationProps
  >({
    mutationFn: ChangeEmployeeProfilePictureMutation,
    throwOnError: false,
    ...options,
  });
