import { MediaObject } from '@bas/media-domain/models';
import { colors } from '@bas/theme';
import { Typography } from '@bas/ui/native/base';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons/faCircleCheck';
import { faCircleXmark } from '@fortawesome/pro-regular-svg-icons/faCircleXmark';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { ImagePickerAsset } from 'expo-image-picker';
import * as React from 'react';
import { ReactElement, ReactNode, useMemo } from 'react';
import { Image, View } from 'react-native';
import { AnimatedCircularProgress } from 'react-native-circular-progress';
import styles from './styles';

export type PhotoListItemProps = {
  file?: ImagePickerAsset;
  result?: MediaObject;
  progress?: number;
  reason?: string | ReactNode;
  spacingBetweenColumns?: boolean;
};

const PhotoListItem = ({
  file,
  result,
  progress,
  reason,
  spacingBetweenColumns,
}: PhotoListItemProps): ReactElement => {
  const isFinished = useMemo(
    () => progress === 100 && result,
    [progress, result],
  );

  return (
    <View
      style={[
        styles.container,
        styles.spacingBetweenRows,
        spacingBetweenColumns && styles.spacingBetweenColumns,
      ]}
    >
      <Image
        source={{ uri: file?.uri || result?.contentUrl || result?.url }}
        style={styles.image}
      />
      <View style={styles.bar}>
        <Typography overrideColor={colors.white} style={styles.name}>
          {reason || file?.fileName}
        </Typography>
        {reason && (
          <FontAwesomeIcon
            size={24}
            color={colors.white}
            icon={faCircleXmark}
          />
        )}
        {isFinished && (
          <FontAwesomeIcon
            size={24}
            color={colors.white}
            icon={faCircleCheck}
          />
        )}
        {!reason && !isFinished && (
          <AnimatedCircularProgress
            fill={progress || 0}
            size={32}
            width={2.5}
            rotation={0}
            tintColor={colors.blue[500]}
            backgroundColor={colors.lila[400]}
          />
        )}
      </View>
      {/* <ImageListItemBar */}
      {/*  title={ */}
      {/*    file && ( */}
      {/*      <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}> */}
      {/*        {file.name} */}
      {/*      </Typography> */}
      {/*    ) */}
      {/*  } */}
      {/*  subtitle={ */}
      {/*    reason && ( */}
      {/*      <Typography */}
      {/*        sx={{ */}
      {/*          color: (theme) => theme.palette.colors.red[500], */}
      {/*          fontSize: (theme) => theme.typography.fontSizes.sm, */}
      {/*          whiteSpace: 'pre-wrap', */}
      {/*          wordBreak: 'break-word', */}
      {/*        }} */}
      {/*      > */}
      {/*        {reason} */}
      {/*      </Typography> */}
      {/*    ) */}
      {/*  } */}
      {/*  actionIcon={ */}
      {/*    <Typography */}
      {/*      sx={{ */}
      {/*        color: (theme) => { */}
      {/*          if (reason) { */}
      {/*            return theme.palette.colors.red[500]; */}
      {/*          } */}

      {/*          return isFinished */}
      {/*            ? theme.palette.colors.green[300] */}
      {/*            : theme.palette.colors.blue[500]; */}
      {/*        }, */}
      {/*        fontSize: (theme) => theme.typography.fontSizes.xl, */}
      {/*        paddingRight: 1, */}
      {/*      }} */}
      {/*    > */}
      {/*      {reason && <Icon icon={faCircleXmark} />} */}
      {/*      {isFinished && <Icon icon={faCircleCheck} />} */}
      {/*      {!reason && !isFinished && ( */}
      {/*        <CircularProgress variant="determinate" value={progress} /> */}
      {/*      )} */}
      {/*    </Typography> */}
      {/*  } */}
      {/* /> */}
    </View>
  );
};
export default PhotoListItem;
