import { useTimeTypesRequest } from '@bas/hrm-domain/requests';
import { TimeTrackingItem } from '@bas/shared/state';
import { Box, Icon, Typography } from '@bas/ui/native/base';
import { BackendIcon } from '@bas/ui/native/molecules';
import {
  faClock,
  faFlagCheckered,
  faMugSaucer,
  faQuestionCircle,
} from '@fortawesome/pro-light-svg-icons';
import { FlashList } from '@shopify/flash-list';
import { ListRenderItemInfo } from '@shopify/flash-list/src/FlashListProps';
import dayjs from 'dayjs';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import {
  FormattedDate,
  FormattedList,
  FormattedMessage,
  FormattedNumber,
} from 'react-intl';
import { v7 } from 'uuid';

const WorkingDayOverviewStep = () => {
  const resultingTimeEntries: TimeTrackingItem[] = useWatch({
    name: 'calculatedTimeEntries',
  });
  const dayStartedAt = useWatch({
    name: 'dayStartedAt',
  });
  const dayEndedAt = useWatch({
    name: 'dayEndedAt',
  });
  const requiredBreakTime = useWatch({
    name: 'requiredBreakTime',
  });

  const { data: timeTypesData } = useTimeTypesRequest();

  const timeTypes = useMemo(
    () => timeTypesData?.data?.member || [],
    [timeTypesData?.data],
  );

  const formattedTotalTimes = useMemo(() => {
    const result = [];

    const workedMinutes = dayjs(dayEndedAt).diff(dayjs(dayStartedAt), 'minute');
    const hoursWithoutBreak = workedMinutes - requiredBreakTime;
    const hours = Math.floor(hoursWithoutBreak / 60);
    const minutes = hoursWithoutBreak % 60;

    if (hours > 0) {
      result.push(
        <FormattedNumber
          value={hours}
          unit="hour"
          // eslint-disable-next-line react/style-prop-object
          style="unit"
        />,
      );
    }

    if (minutes > 0) {
      result.push(
        <FormattedNumber
          value={minutes}
          unit="minute"
          // eslint-disable-next-line react/style-prop-object
          style="unit"
        />,
      );
    }

    return result;
  }, [dayEndedAt, dayStartedAt, requiredBreakTime]);

  const handleRenderTimeTrackingItem = useCallback(
    ({ item }: ListRenderItemInfo<TimeTrackingItem>) => {
      const timeType = timeTypes.find(
        (tt) => tt.timeTypeId === item.timeTypeId,
      );
      const formattedTimes = [];
      if (dayjs(item.end).diff(dayjs(item.start), 'hour') > 0) {
        formattedTimes.push(
          <FormattedNumber
            value={dayjs(item.end).diff(dayjs(item.start), 'hour')}
            unit="hour"
            // eslint-disable-next-line react/style-prop-object
            style="unit"
          />,
        );
      }

      if (dayjs(item.end).diff(dayjs(item.start), 'minute') % 60 > 0) {
        formattedTimes.push(
          <FormattedNumber
            value={dayjs(item.end).diff(dayjs(item.start), 'minute') % 60}
            unit="minute"
            // eslint-disable-next-line react/style-prop-object
            style="unit"
          />,
        );
      }

      return (
        <Box
          flexDirection="row"
          width="100%"
          paddingVertical={1}
          paddingHorizontal={2}
          borderBottomColor="cardBorder"
          borderBottomWidth={1}
        >
          <Box height="100%" justifyContent="center" paddingRight={2}>
            {timeType ? (
              <BackendIcon icon={timeType?.icon} size={16} color="white" />
            ) : (
              <Icon icon={faQuestionCircle} size={16} color="white" />
            )}
          </Box>
          <Box flex={1} flexDirection="row" gap={2}>
            <Box flex={0.35}>
              <Typography color="white">{timeType?.name}</Typography>
            </Box>
            <Box flex={0.3}>
              <Typography textAlign="left" color="white">
                <FormattedList value={formattedTimes} />
              </Typography>
            </Box>

            <Box flex={0.35} flexDirection="row" justifyContent="flex-end">
              <Box width={50}>
                <Typography textAlign="left" color="white">
                  <FormattedDate value={item.start} timeStyle="short" />
                </Typography>
              </Box>
              <Box width={20}>
                <Typography textAlign="left" color="white">
                  {' - '}
                </Typography>
              </Box>
              <Box width={50} justifyContent="flex-end">
                <Typography textAlign="left" color="white">
                  <FormattedDate value={item.end} timeStyle="short" />
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      );
    },
    [timeTypes],
  );

  return (
    <Box flex={1} flexDirection="column" paddingHorizontal={20} gap={3}>
      <Box>
        <Typography
          variant="h4"
          fontWeight={700}
          color="white"
          textAlign="center"
        >
          <FormattedMessage id="label.workingDayOverview" />
        </Typography>
      </Box>
      <Box>
        <Typography color="white" variant="h5">
          <FormattedMessage
            id="label.youStartedAtXAndFinishedAtXAndHadXBreakTime"
            values={{
              dayStartedAt: (
                <Typography fontWeight={700} color="white" variant="h5">
                  <FormattedDate value={dayStartedAt} timeStyle="short" />
                </Typography>
              ),
              dayEndedAt: (
                <Typography fontWeight={700} color="white" variant="h5">
                  <FormattedDate value={dayEndedAt} timeStyle="short" />
                </Typography>
              ),
              requiredBreakTime: (
                <Typography fontWeight={700} color="white" variant="h5">
                  <FormattedNumber
                    value={requiredBreakTime}
                    unit="minute"
                    // eslint-disable-next-line react/style-prop-object
                    style="unit"
                  />
                </Typography>
              ),
            }}
          />
        </Typography>
      </Box>
      <Box flexDirection="row" gap="blocks">
        <Box
          height={125}
          width={125}
          backgroundColor="white"
          borderRadius={3}
          alignItems="center"
          justifyContent="center"
        >
          <Icon icon={faClock} size={28} color="darkText" />
          <Typography pt={0.5}>
            <FormattedDate value={dayStartedAt} timeStyle="short" />
          </Typography>
        </Box>
        <Box
          height={125}
          width={125}
          backgroundColor="white"
          borderRadius={3}
          alignItems="center"
          justifyContent="center"
        >
          <Icon icon={faFlagCheckered} size={28} color="darkText" />
          <Typography pt={0.5}>
            <FormattedDate value={dayEndedAt} timeStyle="short" />
          </Typography>
        </Box>
        <Box
          height={125}
          width={125}
          backgroundColor="white"
          borderRadius={3}
          alignItems="center"
          justifyContent="center"
        >
          <Icon icon={faMugSaucer} size={28} color="darkText" />
          <Typography pt={0.5}>
            <FormattedNumber
              value={requiredBreakTime}
              unit="minute"
              // eslint-disable-next-line react/style-prop-object
              style="unit"
            />
          </Typography>
        </Box>
      </Box>
      <Box flex={1}>
        <Typography color="white" variant="subtitle1">
          <FormattedMessage id="label.yourTrackedTimeEntries" />
        </Typography>
        <Box
          flexDirection="row"
          width="100%"
          paddingVertical={1}
          paddingHorizontal={2}
          borderBottomColor="cardBorder"
          borderBottomWidth={1}
          justifyContent="space-between"
        >
          <Box>
            <Typography fontWeight={700} color="white">
              <FormattedMessage id="label.total" />
            </Typography>
          </Box>

          <Box flexDirection="row">
            <Typography fontWeight={700} textAlign="left" color="white">
              <FormattedList value={formattedTotalTimes} />
            </Typography>
          </Box>
        </Box>
        <FlashList<TimeTrackingItem>
          key={v7()}
          renderItem={handleRenderTimeTrackingItem}
          data={resultingTimeEntries}
        />
      </Box>
    </Box>
  );
};

export default WorkingDayOverviewStep;
