import { ShopifyTheme } from '@bas/ui/native/base';
import { LoadingSquareTiles } from '@bas/ui/native/molecules';
import { FlashList, FlashListProps } from '@shopify/flash-list';
import { useResponsiveProp } from '@shopify/restyle';
import { ReactElement } from 'react';
import { ScrollView } from 'react-native-gesture-handler';

export type SquareTilesListProps<TData> = FlashListProps<TData> & {
  isLoading: boolean;
  numberOfLoadingItems?: number;
  maxPadding?: number;
  inBottomSheet?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SquareTilesList = <TData = any,>({
  isLoading,
  numberOfLoadingItems,
  maxPadding,
  inBottomSheet,
  ...props
}: SquareTilesListProps<TData>): ReactElement => {
  const paddingHorizontal = useResponsiveProp({
    largerTablet: 80,
    tablet: 50,
    phone: 20,
  });

  const numberOfColumns = useResponsiveProp<ShopifyTheme, number>({
    lg: 6,
    largerTablet: 5,
    tablet: 4,
    phone: 2,
  });

  const numberOfColumnsInBottomSheet = useResponsiveProp<ShopifyTheme, number>({
    tablet: 3,
    phone: 2,
  });

  if (isLoading) {
    return (
      <LoadingSquareTiles
        numberOfItems={numberOfLoadingItems}
        paddingHorizontal={paddingHorizontal}
        numberOfColumns={
          (inBottomSheet
            ? numberOfColumnsInBottomSheet
            : numberOfColumns) as number
        }
      />
    );
  }

  return (
    <FlashList
      numColumns={
        inBottomSheet ? numberOfColumnsInBottomSheet : numberOfColumns
      }
      estimatedItemSize={237}
      contentContainerStyle={{
        paddingBottom: 24,
        paddingHorizontal:
          maxPadding && (paddingHorizontal || 0) > maxPadding
            ? maxPadding
            : paddingHorizontal,
      }}
      renderScrollComponent={inBottomSheet ? ScrollView : undefined}
      {...props}
    />
  );
};

export default SquareTilesList;
