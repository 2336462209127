import { useRetrieveQrInformation } from '@bas/shared/hooks';
import {
  isQrCode,
  isStorageLocationQrCode,
  isStorageQrCode,
  isStoredItemQrCode,
  QrCode,
} from '@bas/shared/models';
import { Button, Skeleton } from '@bas/ui/native/atoms';
import { Box, Icon, ShopifyTheme, Typography } from '@bas/ui/native/base';
import {
  useStorageByStorageIdRequestQuery,
  useStorageLocationByStorageLocationIdRequestQuery,
  useStoredItemsRequest,
} from '@bas/wms-domain/requests';
import { faCouch } from '@fortawesome/pro-light-svg-icons/faCouch';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle';
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons/faMagnifyingGlass';
import { faMapLocation } from '@fortawesome/pro-light-svg-icons/faMapLocation';
import { faWarehouseFull } from '@fortawesome/pro-light-svg-icons/faWarehouseFull';
import { useResponsiveProp } from '@shopify/restyle';
import { ReactElement, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

type QrCodeItemProps = {
  qrContent: string;
  onShowMore: (qrCode: QrCode) => void;
};

const QrCodeItem = ({
  qrContent,
  onShowMore,
}: QrCodeItemProps): ReactElement => {
  const parseQrCode = useRetrieveQrInformation();
  const parsedQrCode = useMemo(() => {
    try {
      return parseQrCode(qrContent);
    } catch (error) {
      return null;
    }
  }, [parseQrCode, qrContent]);

  const { data: storageData, isPending: isLoadingStorage } =
    useStorageByStorageIdRequestQuery(
      {
        storageId:
          isStorageQrCode(parsedQrCode) || isStoredItemQrCode(parsedQrCode)
            ? parsedQrCode.storageId
            : '',
      },
      {
        enabled:
          isStorageQrCode(parsedQrCode) ||
          (isStoredItemQrCode(parsedQrCode) &&
            parsedQrCode.storageId !== undefined),
      },
    );

  const { data: storageLocationData, isPending: isLoadingStorageLocation } =
    useStorageLocationByStorageLocationIdRequestQuery(
      {
        storageLocationId: isStorageLocationQrCode(parsedQrCode)
          ? parsedQrCode.locationId
          : '',
      },

      {
        enabled: isStorageLocationQrCode(parsedQrCode),
      },
    );

  const { data: storedItemsData } = useStoredItemsRequest(
    {
      storageId:
        isStorageQrCode(parsedQrCode) || isStoredItemQrCode(parsedQrCode)
          ? parsedQrCode.storageId
          : '',
    },
    {
      enabled:
        isStorageQrCode(parsedQrCode) ||
        (isStoredItemQrCode(parsedQrCode) &&
          parsedQrCode.storageId !== undefined),
    },
  );

  const storedItems = useMemo(
    () => storedItemsData?.data?.member || [],
    [storedItemsData?.data],
  );

  const storage = useMemo(() => {
    if (storageData?.data === undefined) {
      return null;
    }

    return storageData?.data;
  }, [storageData?.data]);

  const storageLocation = useMemo(() => {
    if (storageLocationData?.data === undefined) {
      return null;
    }

    return storageLocationData?.data;
  }, [storageLocationData?.data]);

  const storedItem = useMemo(() => {
    if (!isStoredItemQrCode(parsedQrCode)) {
      return null;
    }

    if (!storage || storedItems.length === 0) {
      return null;
    }

    return storedItems.find((s) => s.itemId === parsedQrCode.itemId);
  }, [storedItems, parsedQrCode, storage]);

  const icon = useMemo(() => {
    if (!isQrCode(parsedQrCode)) {
      return faExclamationTriangle;
    }

    if (isStorageLocationQrCode(parsedQrCode)) {
      return faMapLocation;
    }

    if (isStorageQrCode(parsedQrCode)) {
      return faWarehouseFull;
    }

    if (isStoredItemQrCode(parsedQrCode)) {
      return faCouch;
    }

    return null;
  }, [parsedQrCode]);

  const description = useMemo(() => {
    if (!isQrCode(parsedQrCode)) {
      return <FormattedMessage id="label.incorrectQrCode" />;
    }

    if (isStorageLocationQrCode(parsedQrCode)) {
      if (isLoadingStorageLocation) {
        return <Skeleton width={200} height={40} light />;
      }

      if (!storageLocation) {
        return <FormattedMessage id="label.storageLocationNotFound" />;
      }

      return `${storageLocation.name} (${storageLocation.code})`;
    }

    if (isStorageQrCode(parsedQrCode)) {
      if (isLoadingStorage) {
        return <Skeleton width={200} height={40} light />;
      }

      if (!storage) {
        return <FormattedMessage id="label.storageNotFound" />;
      }

      return storage.storageCode;
    }

    if (isStoredItemQrCode(parsedQrCode)) {
      if (isLoadingStorage) {
        return <Skeleton width={200} height={40} light />;
      }

      if (!storage) {
        return <FormattedMessage id="label.storageNotFound" />;
      }

      if (!storedItem) {
        return <FormattedMessage id="label.storedItemNotFound" />;
      }

      return storedItem.description;
    }

    return null;
  }, [
    parsedQrCode,
    isLoadingStorageLocation,
    storageLocation,
    isLoadingStorage,
    storage,
    storedItem,
  ]);

  const title = useMemo(() => {
    if (!isQrCode(parsedQrCode)) {
      return null;
    }

    if (isStorageLocationQrCode(parsedQrCode)) {
      if (isLoadingStorageLocation) {
        return <Skeleton width={200} height={40} light />;
      }

      if (!storageLocation) {
        return <FormattedMessage id="label.storageLocationNotFound" />;
      }

      return storageLocation.warehouseName;
    }

    if (isStorageQrCode(parsedQrCode)) {
      if (isLoadingStorage) {
        return <Skeleton width={200} height={40} light />;
      }

      if (!storage) {
        return <FormattedMessage id="label.storageNotFound" />;
      }

      return <FormattedMessage id={`storageTypes.${storage.storageType}`} />;
    }

    if (isStoredItemQrCode(parsedQrCode)) {
      if (isLoadingStorage) {
        return <Skeleton width={200} height={40} light />;
      }

      if (!storage) {
        return <FormattedMessage id="label.storageNotFound" />;
      }

      if (!storedItem) {
        return <FormattedMessage id="label.storedItemNotFound" />;
      }

      if (storedItem.externalItemCode && storedItem.itemCode) {
        return `${storedItem.externalItemCode} (${storedItem.itemCode})`;
      }

      return storedItem.externalItemCode || storedItem.itemCode;
    }

    return null;
  }, [
    isLoadingStorage,
    isLoadingStorageLocation,
    parsedQrCode,
    storage,
    storageLocation,
    storedItem,
  ]);

  const color = useMemo(() => {
    if (!isQrCode(parsedQrCode)) {
      return 'red.700';
    }

    return 'white';
  }, [parsedQrCode]);

  // @ts-expect-error - workaround for responsive prop
  const isTablet = useResponsiveProp<ShopifyTheme, boolean>({
    lg: true,
    largerTablet: true,
    tablet: true,
    phone: false,
  });

  return (
    <Box
      flexDirection="row"
      flexWrap="nowrap"
      gap={2}
      width="100%"
      alignItems="center"
      paddingVertical={1}
      borderBottomWidth={1}
      borderBottomColor="transparentBorder"
    >
      <Box width={24}>
        {icon && <Icon icon={icon} color={color} size={24} />}
      </Box>
      <Box flex={0.4}>
        <Typography color={color}>{description}</Typography>
      </Box>
      <Box flex={0.4}>
        <Typography color={color}>{title}</Typography>
      </Box>
      <Box flex={0.2}>
        {parsedQrCode !== null && (
          <Button
            disabled={isLoadingStorage && isStorageQrCode(parsedQrCode)}
            onPress={() => {
              onShowMore(parsedQrCode);
            }}
          >
            {isTablet ? (
              <FormattedMessage id="button.showMore" />
            ) : (
              <Icon icon={faMagnifyingGlass} color="white" />
            )}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default QrCodeItem;
