import { colors } from '@bas/theme';
import {
  Box,
  Icon,
  Pressable,
  TouchableOpacity,
  Typography,
} from '@bas/ui/native/base';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { FlashList, ListRenderItemInfo } from '@shopify/flash-list';
import { MutableRefObject, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { StyleSheet } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { Checkbox } from '../Checkbox';
import { TextField } from '../TextField';

type Item = {
  id: string;
  label: string;
};

// Base props common to both modes
type BaseDropdownModalContentProps = {
  label: string | undefined;
  hideSearch?: boolean;
  items: Item[];
  closeOnSelect?: boolean;
  bottomSheetRef: MutableRefObject<BottomSheetModal | null>;
  onSearchChange?: (value: string) => void;
};

// Single selection mode props
export type SingleDropdownModalContentProps = BaseDropdownModalContentProps & {
  multiple?: false | undefined;
  value?: string;
  onChange?: (value: string | undefined, item?: Item) => void;
};

// Multiple selection mode props
export type MultipleDropdownModalContentProps =
  BaseDropdownModalContentProps & {
    multiple: true;
    value?: string[];
    onChange?: (value: string[], item?: Item[]) => void;
  };

// Combined type for the component
export type DropdownModalContentProps =
  | SingleDropdownModalContentProps
  | MultipleDropdownModalContentProps;

const styles = StyleSheet.create({
  option: {
    paddingVertical: 16,
    borderBottomWidth: 1,
    borderBottomColor: colors.lila[400],
  },
});

const DropdownModalContent = ({
  label,
  hideSearch,
  items,
  value,
  multiple,
  closeOnSelect,
  onChange,
  bottomSheetRef,
  onSearchChange,
}: DropdownModalContentProps) => {
  const [selectedValue, setSelectedValue] = useState<
    string | string[] | undefined
  >(value);

  const [search, setSearch] = useState('');
  const { formatMessage } = useIntl();

  const handleResetDropdown = useCallback(() => {
    setSearch('');
    onSearchChange?.('');
    setSelectedValue(undefined);
  }, [onSearchChange]);

  const handleDismissModal = useCallback(() => {
    bottomSheetRef.current?.dismiss();
  }, [bottomSheetRef]);

  const handleSelect = useCallback(
    (item: Item) => {
      if (multiple) {
        setSelectedValue((prev) => {
          if (prev === undefined) {
            return [item.id];
          }

          if (typeof prev === 'string') {
            return [prev, item.id];
          }

          if (prev.includes(item.id)) {
            return prev.filter((id) => id !== item.id);
          }

          return [...prev, item.id];
        });
      } else {
        let newSelectedValue: string | undefined = item.id;
        let newSelectedItem: Item | undefined = item;
        if (selectedValue === item.id) {
          newSelectedValue = '';
          newSelectedItem = undefined;
        }

        setSelectedValue(newSelectedValue);
        if (closeOnSelect) {
          onChange?.(newSelectedValue, newSelectedItem);
          handleDismissModal();
        }
      }
    },
    [closeOnSelect, handleDismissModal, multiple, onChange, selectedValue],
  );
  const filteredItems = useMemo(() => {
    if (hideSearch || !search) {
      return items;
    }

    return items.filter((item: Item) => {
      if (item.label.toLowerCase().includes(search.toLowerCase())) {
        return true;
      }

      return item.id.toLowerCase().includes(search.toLowerCase());
    });
  }, [hideSearch, items, search]);

  const handleIsSelected = useCallback(
    (item: Item) => {
      if (selectedValue === undefined) {
        return false;
      }

      if (!multiple) {
        return selectedValue === item.id;
      }

      if (typeof selectedValue === 'string') {
        return selectedValue === item.id;
      }

      return selectedValue.includes(item.id);
    },
    [multiple, selectedValue],
  );
  const handleConfirm = useCallback(() => {
    if (multiple) {
      const selectedItems = items.filter(handleIsSelected);
      const selectedIds = selectedValue as string[];
      onChange?.(selectedIds, selectedItems);
    } else {
      const selectedItem = items.find(handleIsSelected);
      const selectedId = selectedValue as string | undefined;
      onChange?.(selectedId, selectedItem);
    }

    handleResetDropdown();
    handleDismissModal();
  }, [
    handleDismissModal,
    handleResetDropdown,
    handleIsSelected,
    items,
    multiple,
    onChange,
    selectedValue,
  ]);

  const handleRenderItem = useCallback(
    ({ item }: ListRenderItemInfo<Item>) => {
      const selected = handleIsSelected(item);
      console.log(item.label);

      return (
        <TouchableOpacity
          onPress={() => handleSelect(item)}
          style={styles.option}
        >
          <Box flexDirection="row" alignItems="center" gap={2}>
            {multiple && (
              <Box>
                <Checkbox checked={selected} size={20} />
              </Box>
            )}
            <Box flex={1}>
              <Typography
                color={selected ? 'mainPrimary' : 'white'}
                numberOfLines={1}
                ellipsizeMode="tail"
              >
                {item.label.replaceAll('\n', ',')}
              </Typography>
            </Box>
          </Box>
        </TouchableOpacity>
      );
    },
    [handleIsSelected, handleSelect, multiple],
  );

  const handleCancel = useCallback(() => {
    handleResetDropdown();
    handleDismissModal();
  }, [handleDismissModal, handleResetDropdown]);

  return (
    <Box flex={1} flexDirection="column" flexWrap="wrap" gap={2}>
      <Box width="100%" gap={2} flex={1} paddingHorizontal={20}>
        <Typography
          fontWeight={700}
          color="white"
          textAlign="center"
          variant="h4"
        >
          {label}
        </Typography>
        {!hideSearch && (
          <Box width="100%">
            <TextField
              label={formatMessage({ id: 'label.search' })}
              light
              autoComplete="off"
              autoCapitalize="none"
              value={search}
              onChangeText={setSearch}
            />
            <Typography
              pt={2}
              textAlign="center"
              fontWeight={700}
              color="white"
            >
              <FormattedMessage
                id="label.numberOfOptions"
                values={{ numberOfOptions: filteredItems.length }}
              />
            </Typography>
          </Box>
        )}
        <Box flex={1} borderTopWidth={1} borderColor="lila.400">
          {items.length > 0 ? (
            <FlashList<Item>
              data={filteredItems}
              extraData={selectedValue}
              keyExtractor={(item) => item.id}
              renderItem={handleRenderItem}
              estimatedItemSize={55}
              renderScrollComponent={ScrollView}
            />
          ) : (
            <Box
              flex={1}
              justifyContent="flex-start"
              alignItems="center"
              padding={4}
            >
              <Typography color="white" textAlign="center" variant="h4">
                {formatMessage({ id: 'label.noResults' })}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box
        backgroundColor="darkBackground"
        height={79}
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
        paddingHorizontal={20}
      >
        <Pressable onPress={handleCancel}>
          <Box flex={1} alignItems="center" justifyContent="center">
            <Icon icon={faTimesCircle} color="white" size={23} />
          </Box>
        </Pressable>
        <Pressable onPress={handleConfirm}>
          <Box flex={1} alignItems="center" justifyContent="center">
            <Icon icon={faCheckCircle} color="white" size={23} />
          </Box>
        </Pressable>
      </Box>
    </Box>
  );
};

export default DropdownModalContent;
