import * as Yup from 'yup';
import {
  MileageReportInputType,
  MileageReportInputTypeValidationBuilder,
} from './mileageReportInputType';

export interface StartDrivingInputType {
  realTravelStartTime?: Date | null;
  mileageReports: MileageReportInputType[];
}

export const StartDrivingInputTypeDefaultValues =
  (): StartDrivingInputType => ({
    realTravelStartTime: null,
    mileageReports: [],
  });

export const StartDrivingInputTypeValidationBuilder =
  (): Yup.ObjectSchema<StartDrivingInputType> =>
    Yup.object({
      realTravelStartTime: Yup.date()
        .nullable()
        .required()
        .label('label.realTravelStartTime'),
      mileageReports: Yup.array()
        .of(MileageReportInputTypeValidationBuilder())
        .required(),
    });
