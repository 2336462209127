import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type FinishOrResetFurnitureServiceMutationProps = {
  projectId: Uuid;
  furnitureId: Uuid;
  serviceTypeId: Uuid;
  finished: boolean;
};

export const FinishOrResetFurnitureServiceMutation = async ({
  projectId,
  finished,
  ...values
}: FinishOrResetFurnitureServiceMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/moving-jobs/${projectId}/furniture/${
      finished ? 'finish' : 'reset'
    }-service`,
    {
      projectId,
      ...values,
    },
  );

export const useFinishOrResetFurnitureServiceMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    FinishOrResetFurnitureServiceMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  FinishOrResetFurnitureServiceMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    FinishOrResetFurnitureServiceMutationProps
  >({
    mutationFn: FinishOrResetFurnitureServiceMutation,
    throwOnError: false,
    ...options,
  });
