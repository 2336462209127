import { getDefaultCountry } from '@bas/shared/utils';
import {
  EmailAddress,
  ErrorResponse,
  PhoneNumber,
  Uuid,
} from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type UpdateTenantContactInformationMutationProps = {
  tenantId: Uuid;
  phoneNumber?: PhoneNumber | null;
  mobileNumber?: PhoneNumber | null;
  emailAddress?: EmailAddress | null;
};

export const UpdateTenantContactInformationMutation = async ({
  ...values
}: UpdateTenantContactInformationMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/tenants/{tenantId}/update-contact-information`, {
    region: getDefaultCountry(),
    ...values,
  });

export const useUpdateTenantContactInformationMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateTenantContactInformationMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  UpdateTenantContactInformationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateTenantContactInformationMutationProps
  >({
    mutationFn: UpdateTenantContactInformationMutation,
    throwOnError: false,
    ...options,
  });
