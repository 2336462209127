import { Alert, Button, PdfRender } from '@bas/ui/native/atoms';
import { Box, Typography } from '@bas/ui/native/base';
import { faDownload } from '@fortawesome/pro-light-svg-icons/faDownload';
import { faFilePdf } from '@fortawesome/pro-light-svg-icons/faFilePdf';
import * as Linking from 'expo-linking';
import * as Sharing from 'expo-sharing';
import {
  ReactElement,
  ReactNode,
  RefObject,
  useCallback,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { Platform, ScrollView } from 'react-native';

export type SignDocumentTemplateProps = {
  title: string | ReactNode;
  pdf?: { uri: string; downloadUri: string };
  imageUrl?: string;
  fileName: string;
  attachments: { name: string; contentUrl: string }[];
  children?: ReactNode;
  hidePdf?: boolean;
  scrollRef?: RefObject<ScrollView>;
  scrollEnabled?: boolean;
};

const SignDocumentTemplate = ({
  pdf,
  title,
  fileName,
  imageUrl,
  attachments,
  children,
  hidePdf,
  scrollRef,
  scrollEnabled,
}: SignDocumentTemplateProps): ReactElement => {
  const [numberOfPages, setNumberOfPages] = useState<number>(0);
  const handleDownloadFile = useCallback(() => {
    if (Platform.OS === 'web') {
      const link = document.createElement('a');
      link.href = pdf?.downloadUri || '';
      link.setAttribute('download', fileName || '');
      document.body.appendChild(link);
      link.click();
    } else {
      Sharing.shareAsync(pdf?.downloadUri || '');
    }
  }, [fileName, pdf?.downloadUri]);

  return (
    <ScrollView scrollEnabled={scrollEnabled} ref={scrollRef}>
      <Box
        paddingVertical={{ phone: 'signPagePadding', tablet: 6 }}
        paddingHorizontal={{ phone: 'signPagePadding', tablet: 3 }}
      >
        <Box maxWidth={750} alignSelf="center" width="100%">
          <Box
            flexDirection="row"
            flexWrap="wrap"
            justifyContent={{ phone: 'center', tablet: 'space-between' }}
            alignItems="center"
          >
            <Box
              mt={{ phone: 4, tablet: 0 }}
              flexBasis={{ phone: '100%', tablet: 'auto' }}
            >
              <Typography variant="h4">{title}</Typography>
            </Box>
            {pdf && (
              <Box
                mt={{ phone: 4, tablet: 0 }}
                flexBasis={{ phone: '100%', tablet: 'auto' }}
              >
                <Button
                  variant="outlined"
                  onPress={handleDownloadFile}
                  icon={faFilePdf}
                  buttonColor="secondary"
                >
                  <FormattedMessage id="button.download" />
                </Button>
              </Box>
            )}
          </Box>
          <Box
            marginHorizontal={{ phone: 'negativeSignPagePadding', tablet: 0 }}
            mt={4}
          >
            {numberOfPages > 3 && (
              <Alert severity="info">
                <Typography>
                  <FormattedMessage id="financial.quote.signQuote.toManyPages" />
                </Typography>
              </Alert>
            )}

            {pdf?.uri && !hidePdf && (
              <PdfRender
                pdf={pdf}
                onLoad={setNumberOfPages}
                numberOfPages={numberOfPages}
              />
            )}
          </Box>

          {!!attachments && (
            <Box
              pt={3}
              style={{ marginTop: -8, marginLeft: -8 }}
              flexDirection="row"
              flexWrap="wrap"
            >
              {attachments.map(({ name, contentUrl }) => (
                <Box key={contentUrl} mt={1} ml={1}>
                  <Button
                    variant="outlined"
                    key={contentUrl}
                    onPress={() => Linking.openURL(contentUrl)}
                    icon={faDownload}
                    buttonColor="secondary"
                  >
                    {name}
                  </Button>
                </Box>
              ))}
            </Box>
          )}
          <Box flex={1} pt={4}>
            {children}
          </Box>
        </Box>
      </Box>
    </ScrollView>
  );
};

export default SignDocumentTemplate;
