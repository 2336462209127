import { ErrorResponse } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { CreateStorageLocationMutationProps } from './createStorageLocationMutation';

export type CreateStorageLocationsInBulkMutationProps = {
  locations: CreateStorageLocationMutationProps[];
};

export const CreateStorageLocationsInBulkMutation = async ({
  ...values
}: CreateStorageLocationsInBulkMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/{tenantId}/locations-in-bulk`, {
    ...values,
  });

export const useCreateStorageLocationsInBulkMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    CreateStorageLocationsInBulkMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  CreateStorageLocationsInBulkMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    CreateStorageLocationsInBulkMutationProps
  >({ mutationFn: CreateStorageLocationsInBulkMutation, ...options });
