import { StorageEventType, StorageWasSealedEvent } from '../../types';
import { isStorageEvent } from './StorageEvent';

export function isStorageWasSealedEvent(
  object: unknown,
): object is StorageWasSealedEvent {
  return (
    isStorageEvent(object) &&
    object.eventType === StorageEventType.StorageWasSealedEvent
  );
}
