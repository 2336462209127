import { useRelationByRelationIdRequestQuery } from '@bas/crm-domain/requests';
import { useEventByEventIdRequest } from '@bas/planning-domain/requests';
import { useProjectByProjectIdRequest } from '@bas/project-domain/requests';
import { Box, Typography } from '@bas/ui/native/base';
import { Uuid } from '@bas/value-objects';
import { ReactElement } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

export type TimeEntryRelatedContentSectionProps = {
  eventId: Uuid | undefined;
  relationId: Uuid | undefined;
  projectId: Uuid | undefined;
};

const TimeEntryRelatedContentSection = ({
  eventId,
  relationId,
  projectId,
}: TimeEntryRelatedContentSectionProps): ReactElement => {
  const { data: projectData } = useProjectByProjectIdRequest(
    {
      projectId: projectId || '',
    },
    {
      enabled: !!projectId,
    },
  );

  const { data: eventData } = useEventByEventIdRequest(
    {
      eventId: eventId || '',
    },
    {
      enabled: !!eventId,
    },
  );

  const { data: customerData } = useRelationByRelationIdRequestQuery(
    {
      relationId: relationId || '',
    },
    {
      enabled: !!relationId,
    },
  );

  const project = projectData?.data;
  const event = eventData?.data;
  const customer = customerData?.data;

  return (
    <Box flexDirection="row" flexWrap="wrap">
      <Box flexBasis="100%">
        <Typography variant="subtitle1" color="white">
          <FormattedMessage id="label.relatedInformation" />
        </Typography>
      </Box>
      <Box flexBasis="50%">
        <Typography variant="subtitle2" color="lila.600">
          <FormattedMessage id="label.project" />
        </Typography>
        {!projectId && (
          <Typography color="white">
            <FormattedMessage id="label.notRelatedToAProject" />
          </Typography>
        )}
        {!!projectId && project && (
          <>
            <Typography color="white">{project.projectCode}</Typography>
            <Typography color="white">{project.name}</Typography>
          </>
        )}
      </Box>
      <Box flexBasis="50%">
        <Typography variant="subtitle2" color="lila.600">
          <FormattedMessage id="label.event" />
        </Typography>
        {!eventId && (
          <Typography color="white">
            <FormattedMessage id="label.notRelatedToAEvent" />
          </Typography>
        )}
        {!!eventId && event && (
          <>
            <Typography color="white">
              <FormattedMessage id={`event.eventType.${event.eventType}`} />
            </Typography>
            <Typography color="white">
              <FormattedDate
                value={event.start}
                weekday="long"
                day="numeric"
                month="long"
              />
            </Typography>
          </>
        )}
      </Box>
      <Box flexBasis="50%" paddingTop={2}>
        <Typography variant="subtitle2" color="lila.600">
          <FormattedMessage id="label.customer" />
        </Typography>

        {!relationId && (
          <Typography color="white">
            <FormattedMessage id="label.notRelatedToACustomer" />
          </Typography>
        )}
        {!!relationId && customer && (
          <Typography color="white">
            {customer.name.trim()} (
            {customer.customerInformation.customerNumber})
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default TimeEntryRelatedContentSection;
