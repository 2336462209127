import { StyleSheet } from 'react-native';
import { colors } from '@bas/theme';

export default StyleSheet.create({
  accordion: {
    backgroundColor: 'transparent',
    borderRadius: 0,
    borderBottomWidth: 1,
    borderBottomColor: colors.lila[400],
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
  summary: {
    minHeight: 64,
    alignItems: 'center',
  },
  input: {
    textAlign: 'right',
  },
  price: {
    marginLeft: 'auto',
  },
});
