import { MovingJobEstimateStepTemplateInputType } from '@bas/taxation-tool-domain/input-types';
import { FormattedCurrency } from '@bas/ui/native/atoms';
import { Box, Typography } from '@bas/ui/native/base';
import { ItemGroupAccordion } from '@bas/ui/native/organisms';
import {
  Money,
  ProjectEstimateItemGroupType,
  ProjectEstimateItemType,
} from '@bas/value-objects';
import hash from 'object-hash';
import * as React from 'react';
import { memo, ReactElement, useEffect, useMemo, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { MobileTaxationStepTemplate } from '../MobileTaxationStepTemplate';

type ItemGroup = {
  total: Money;
  groupType: ProjectEstimateItemGroupType;
  items: {
    key: string;
    isLast: boolean;
    isOverridden: boolean;
    itemType: ProjectEstimateItemType;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    extraData?: any;
    hasOverwrittenPricePerUnit: boolean;
    originalIndex: number;
    originalQuantity: number;
    originalPricePerUnitAmount?: number;
  }[];
};

type GroupedItems = {
  [key: string]: ItemGroup;
};

const MovingJobEstimateStepTemplate = (): ReactElement => {
  const [groupedItems, setGroupedItems] = useState<GroupedItems>({});
  const [totalPrice, setTotalPrice] = useState<Money>({
    amount: 0,
    currency: 'EUR',
  });

  const estimate = useWatch<MovingJobEstimateStepTemplateInputType, 'estimate'>(
    {
      name: 'estimate',
    },
  );

  useEffect(() => {
    const groups: GroupedItems = {};

    if (!estimate?.items) {
      return;
    }

    estimate.items.forEach((item, originalIndex) => {
      if (typeof groups[item.groupType] === 'undefined') {
        groups[item.groupType] = {
          groupType: item.groupType,
          total: {
            amount: 0,
            currency: item.overwritten?.pricePerUnit?.currency || '',
          },
          items: [],
        };
      }

      let itemValue = item.calculated;
      if (item.overwritten) {
        itemValue = item.overwritten;
      }

      if (itemValue.pricePerUnit) {
        if (groups[item.groupType].total.currency === '') {
          groups[item.groupType].total.currency =
            itemValue.pricePerUnit.currency;
        }

        groups[item.groupType].total.amount +=
          itemValue.pricePerUnit.amount * (itemValue.quantity || 0);
      }

      const hasOverwrittenPricePerUnit = !!itemValue.pricePerUnit;

      groups[item.groupType].items.push({
        key: (item as unknown as { key: string }).key || hash(item.calculated),
        itemType: item.itemType,
        extraData: item.extraData,
        hasOverwrittenPricePerUnit,
        isOverridden:
          item.overwritten !== undefined &&
          (item.calculated.quantity !== item.overwritten.quantity ||
            item.overwritten.pricePerUnit?.amount !==
              item.calculated.pricePerUnit?.amount),
        originalIndex,
        isLast: false,
        originalQuantity: item.calculated.quantity,
        originalPricePerUnitAmount: item.calculated.pricePerUnit?.amount,
      });
    });

    Object.keys(groups).forEach((key) => {
      groups[key].items[groups[key].items.length - 1].isLast = true;
    });

    if (!isEqual(groups, groupedItems)) {
      setGroupedItems(groups);
    }
  }, [estimate?.items, estimate?.total, groupedItems]);

  useEffect(() => {
    let totalAmount = 0;

    if (
      typeof estimate?.items !== 'object' ||
      typeof estimate?.total !== 'object'
    ) {
      return;
    }

    estimate?.items.forEach((item) => {
      let itemValue = item.calculated;
      if (item.overwritten) {
        itemValue = item.overwritten;
      }

      if (itemValue.pricePerUnit) {
        totalAmount +=
          itemValue.pricePerUnit.amount * (itemValue.quantity || 0);
      }
    });

    const result = { amount: totalAmount, currency: estimate?.total?.currency };

    if (!isEqual(result, totalPrice)) {
      setTotalPrice(result);
    }
  }, [estimate?.items, estimate?.total, totalPrice]);

  const renderedGroupedItems = useMemo(
    () =>
      Object.values(groupedItems).map((itemGroup) => (
        <ItemGroupAccordion key={itemGroup.groupType} itemGroup={itemGroup} />
      )),
    [groupedItems],
  );

  return (
    <MobileTaxationStepTemplate
      primary={
        <FormattedMessage id="mobileApp.intake.movingJob.theEstimation" />
      }
      secondary={<FormattedMessage id="label.quote" />}
    >
      <Box>
        <Box
          borderBottomColor="darkText"
          borderBottomWidth={1}
          style={{ paddingBottom: 21 }}
          alignItems="center"
          flexDirection="row"
        >
          <Typography fontWeight={700}>
            <FormattedMessage id="mobileApp.intake.movingJob.estimation.totalPrice" />
          </Typography>
          <Typography style={{ marginLeft: 'auto' }} fontWeight={700}>
            <FormattedCurrency {...totalPrice} />
          </Typography>
        </Box>

        {renderedGroupedItems}
      </Box>
    </MobileTaxationStepTemplate>
  );
};

const MemoMovingJobEstimateStepTemplate = memo(
  MovingJobEstimateStepTemplate,
  () => true,
);
export default MemoMovingJobEstimateStepTemplate;
