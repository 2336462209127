import { MessageType } from '@bas/communication-domain/models';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type SendMessageToConversationMutationProps = {
  conversationId: Uuid;
  employeeId: Uuid;
  messageId: Uuid;
  content: string;
  messageMetadata?: {
    messageType: MessageType;
  };
};

export const SendMessageToConversationMutation = async ({
  conversationId,
  employeeId,
  messageId,
  content,
  messageMetadata,
  ...params
}: SendMessageToConversationMutationProps): Promise<AxiosResponse> =>
  axios.post(
    `api/{tenantId}/chat/conversations/${conversationId}/messages`,
    {
      conversationId,
      messageId,
      content,
      sender: { employeeId },
      messageMetadata: {
        messageType: MessageType.TEXT_MESSAGE,
        ...(messageMetadata || {}),
      },
    },
    { params },
  );

export const useSendMessageToConversationMutation = (): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  SendMessageToConversationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    SendMessageToConversationMutationProps
  >({
    mutationFn: SendMessageToConversationMutation,
    throwOnError: true,
  });
