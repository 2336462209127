import { MovingJobTaxationRoom } from '@bas/taxation-tool-domain/models';
import * as Yup from 'yup';

export interface RoomWizardInputType {
  rooms: MovingJobTaxationRoom[];
}

export const RoomWizardInputTypeDefaultValues = (): RoomWizardInputType => ({
  rooms: [],
});

const ServiceValidation = (): Yup.ObjectSchema<{
  [key: number]: {
    [key: string]: boolean;
  };
}> => Yup.object();

export const RoomWizardInputTypeValidatorBuilder =
  (): Yup.ObjectSchema<RoomWizardInputType> =>
    Yup.object({
      rooms: Yup.array()
        .of(
          Yup.object({
            roomId: Yup.string().required(),
            roomTypeId: Yup.string().required(),
            name: Yup.string().nullable(),
            notes: Yup.string().nullable(),
            selected: Yup.boolean().required(),
            finished: Yup.boolean().required(),
            photos: Yup.array()
              .of(
                Yup.object({
                  mediaObjectId: Yup.string().required(),
                  url: Yup.string().required(),
                  contentUrl: Yup.string().required(),
                }),
              )
              .required(),
            furniture: Yup.array()
              .of(
                Yup.object({
                  furnitureId: Yup.string().required(),
                  services: ServiceValidation(),
                  selected: Yup.boolean().required(),
                  furnitureType: Yup.string()
                    .oneOf(['furniture', 'custom'])
                    .required(),
                  quantity: Yup.number().required(),
                  servicesPossible: Yup.boolean().required(),
                }),
              )
              .required(),
            inventoryItems: Yup.array()
              .of(
                Yup.object({
                  inventoryItemId: Yup.string().required(),
                  quantity: Yup.number().required(),
                  storageQuantity: Yup.number().required(),
                }),
              )
              .required(),
          }),
        )
        .compact((room) => !room.selected || room.finished)
        .length(0)
        .required()
        .label('label.rooms'),
    });
