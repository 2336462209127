import { CoverSheetType } from '@bas/tenant-domain/models';
import { ErrorResponse, Language, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type AddCoverSheetToDocumentTemplateMutationProps = {
  documentTemplateId: Uuid;
  mediaObjectId: Uuid;
  coverSheetType: CoverSheetType;
  language: Language;
};

export const AddCoverSheetToDocumentTemplateMutation = async ({
  documentTemplateId,
  ...values
}: AddCoverSheetToDocumentTemplateMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/document-templates/${documentTemplateId}/add-cover-sheet`,
    {
      documentTemplateId,
      ...values,
    },
  );

export const useAddCoverSheetToDocumentTemplateMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddCoverSheetToDocumentTemplateMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  AddCoverSheetToDocumentTemplateMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddCoverSheetToDocumentTemplateMutationProps
  >({
    mutationFn: AddCoverSheetToDocumentTemplateMutation,
    throwOnError: false,
    ...options,
  });
