export enum InternalTimeOffType {
  HOLIDAY = 'holiday',
  SICKNESS = 'sickness',
  SPECIAL_LEAVE = 'special_leave',
  PARENTAL_LEAVE = 'parental_leave',
  EDUCATIONAL_LEAVE = 'educational_leave',
  COMPENSATORY_LEAVE = 'compensatory_leave',
  UNPAID = 'unpaid',
  OTHER = 'other',
}

export const timeOffTypeToCalculate = [
  InternalTimeOffType.HOLIDAY,
  InternalTimeOffType.COMPENSATORY_LEAVE,
];
