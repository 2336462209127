import * as Yup from 'yup';
import { Uuid } from '@bas/value-objects';

export interface InventoryMovementInputType {
  quantity: number;
  quantityWornOut?: number | null;
  inventoryItemId: Uuid;
}

export const InventoryMovementInputTypeDefaultValues =
  (): InventoryMovementInputType => ({
    quantity: 0,
    inventoryItemId: '',
  });

export const InventoryMovementInputTypeValidationBuilder =
  (): Yup.ObjectSchema<InventoryMovementInputType> =>
    Yup.object({
      quantity: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .nullable()
        .min(0)
        .required()
        .label('label.quantity'),
      quantityWornOut: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .nullable()
        .min(0)
        .label('label.quantityWornOut'),
      inventoryItemId: Yup.string().required().label('label.quantityWornOut'),
    });
