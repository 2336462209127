import { formatDate } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ChangeEmployeeCertificatesMutationProps = {
  employeeId: Uuid;
  certificates: {
    certificate: string;
    validFrom?: Date | null;
    validUntil?: Date | null;
  }[];
};

export const ChangeEmployeeCertificatesMutation = async ({
  employeeId,
  certificates,
  ...values
}: ChangeEmployeeCertificatesMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/hrm/employees/${employeeId}/update-certificates`, {
    employeeId,
    certificates: certificates.map(
      ({ validFrom, validUntil, ...certificate }) => ({
        ...certificate,
        validFrom: validFrom ? formatDate(validFrom) : null,
        validUntil: validUntil ? formatDate(validUntil) : null,
      }),
    ),
    ...values,
  });

export const useChangeEmployeeCertificatesMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeEmployeeCertificatesMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ChangeEmployeeCertificatesMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeEmployeeCertificatesMutationProps
  >({
    mutationFn: ChangeEmployeeCertificatesMutation,
    throwOnError: false,
    ...options,
  });
