import { formatDate } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ClearStoragesForProjectMutationProps = {
  projectId: Uuid;
  invoicingEndDate?: Date | null;
  createInvoice: boolean;
};

export const ClearStoragesForProjectMutation = async ({
  projectId,
  invoicingEndDate,
  ...values
}: ClearStoragesForProjectMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/moving-jobs/${projectId}/clear-storage`, {
    projectId,
    invoicingEndDate: invoicingEndDate ? formatDate(invoicingEndDate) : null,
    ...values,
  });

export const useClearStoragesForProjectMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ClearStoragesForProjectMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ClearStoragesForProjectMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ClearStoragesForProjectMutationProps
  >({
    mutationFn: ClearStoragesForProjectMutation,
    throwOnError: false,
    ...options,
  });
