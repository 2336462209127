import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type DeleteInvoicesInBulkMutationProps = {
  invoices: { invoiceId: Uuid }[];
};

export const DeleteInvoicesInBulkMutation = async ({
  ...values
}: DeleteInvoicesInBulkMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/{tenantId}/invoices/delete`, {
    ...values,
  });

export const useDeleteInvoicesInBulkMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    DeleteInvoicesInBulkMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  DeleteInvoicesInBulkMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    DeleteInvoicesInBulkMutationProps
  >({
    mutationFn: DeleteInvoicesInBulkMutation,
    throwOnError: false,
    ...options,
  });
