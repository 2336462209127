import { Typography, TypographyProps } from '@bas/ui/native/base';
import { colors } from '@bas/theme';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons/faCircleExclamation';
import { faExclamation } from '@fortawesome/pro-solid-svg-icons/faExclamation';
import {
  FontAwesomeIcon,
  Props as IconProps,
} from '@fortawesome/react-native-fontawesome';
import {
  Children,
  cloneElement,
  isValidElement,
  ReactElement,
  ReactNode,
  useMemo,
} from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';

export type AlertProps = ViewProps & {
  children: ReactNode;
  severity?: 'error' | 'warning' | 'info' | 'success';
  icon?: IconProp;
  iconProps?: Omit<IconProps, 'icon'>;
};

const styling = StyleSheet.create({
  container: {
    borderRadius: 5,
    minHeight: 57,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 16,
    paddingBottom: 16,
  },
  text: {
    flex: 1,
  },
  iconContainer: {
    paddingRight: 15,
  },
});

const mapIcons = {
  success: faCheckCircle,
  info: faInfoCircle,
  warning: faExclamation,
  error: faCircleExclamation,
};

const Alert = ({
  children,
  style,
  icon,
  iconProps,
  severity,
  ...props
}: AlertProps): ReactElement => {
  const alertIcon = useMemo(
    () => icon || mapIcons[severity || 'info'],
    [icon, severity],
  );

  const { textColor, backgroundColor } = useMemo(() => {
    if (severity === 'error') {
      return {
        textColor: '#522D2D',
        backgroundColor: '#FAF0F0',
      };
    }

    if (severity === 'success') {
      return {
        textColor: '#415A44',
        backgroundColor: '#F5FCF6',
      };
    }

    if (severity === 'info') {
      return {
        textColor: '#014361',
        backgroundColor: '#E5F6FD',
      };
    }

    return {
      textColor: colors.orange[800],
      backgroundColor: colors.orange[600],
    };
  }, [severity]);

  const childrenWithProps = useMemo(
    () =>
      Children.map(children, (child) => {
        if (isValidElement(child) && !child.props.overrideColor) {
          return cloneElement(child as ReactElement<TypographyProps>, {
            overrideColor: textColor,
          });
        }

        return child;
      }),
    [children, textColor],
  );

  return (
    <View style={[style, styling.container, { backgroundColor }]} {...props}>
      {alertIcon && (
        <View style={styling.iconContainer}>
          <FontAwesomeIcon
            icon={alertIcon}
            style={[iconProps?.style]}
            color={textColor}
            size={22}
            {...iconProps}
          />
        </View>
      )}
      <Typography style={styling.text} overrideColor={textColor}>
        {childrenWithProps}
      </Typography>
    </View>
  );
};

export default Alert;
