import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type UpdateNotesOfStorageMutationProps = {
  storageId: Uuid;
  notes?: string | null;
};

export const UpdateNotesOfStorageMutation = async ({
  storageId,
  ...values
}: UpdateNotesOfStorageMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/storages/${storageId}/update-notes`, {
    storageId,
    ...values,
  });

export const useUpdateNotesOfStorageMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateNotesOfStorageMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  UpdateNotesOfStorageMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateNotesOfStorageMutationProps
  >({
    mutationFn: UpdateNotesOfStorageMutation,
    throwOnError: false,
    ...options,
  });
