import { DocumentTemplateType } from '@bas/tenant-domain/models';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type CreateDocumentTemplateMutationProps = {
  documentTemplateId: Uuid;
  basedUponDocumentTemplateId: Uuid;
  name: string;
  templateType: DocumentTemplateType;
};

export const CreateDocumentTemplateMutation = async ({
  ...values
}: CreateDocumentTemplateMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/{tenantId}/document-templates`, {
    ...values,
  });

export const useCreateDocumentTemplateMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    CreateDocumentTemplateMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  CreateDocumentTemplateMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    CreateDocumentTemplateMutationProps
  >({
    mutationFn: CreateDocumentTemplateMutation,
    throwOnError: false,
    ...options,
  });
