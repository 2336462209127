import * as Yup from 'yup';

export type DisableTwoFactorInputType = {
  currentPassword: string;
  code: string;
};

export const disableTwoFactorInputTypeDefaultValues =
  (): DisableTwoFactorInputType => ({
    currentPassword: '',
    code: '',
  });

export const disableTwoFactorInputTypeValidationBuilder =
  (): Yup.ObjectSchema<DisableTwoFactorInputType> =>
    Yup.object({
      currentPassword: Yup.string().required().label('label.currentPassword'),
      code: Yup.string().required().label('label.code'),
    });
