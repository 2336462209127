import { EmployeeContractType, Money } from '@bas/value-objects';
import dayjs from 'dayjs';
import * as Yup from 'yup';

export interface EmployeeContractInputType {
  hourlyWage: Money;
  hoursPerWeek: number;
  daysPerWeek: number;
  contractType: EmployeeContractType;
  startDate?: Date | null;
  endDate?: Date | null;
}

export const EmployeeContractInputTypeDefaultValues =
  (): EmployeeContractInputType => ({
    hourlyWage: {
      amount: 0,
      currency: 'EUR',
    },
    hoursPerWeek: 0,
    daysPerWeek: 0,
    contractType: EmployeeContractType.UNKNOWN,
    startDate: null,
    endDate: null,
  });

export const EmployeeContractInputTypeValidatorBuilder =
  (): Yup.ObjectSchema<EmployeeContractInputType> =>
    Yup.object({
      hourlyWage: Yup.object({
        amount: Yup.number()
          .transform((value, original) =>
            original === '' || original === null ? null : value,
          )
          .nullable()
          .required()
          .label('label.hourlyWage'),
        currency: Yup.string().required(),
      }).required(),
      hoursPerWeek: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .nullable()
        .required()
        .label('label.hoursPerWeek'),
      daysPerWeek: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .nullable()
        .required()
        .label('label.daysPerWeek'),
      contractType: Yup.mixed<EmployeeContractType>()
        .label('label.contractType')
        .oneOf(Object.values(EmployeeContractType).filter((option) => !!option))
        .required(),
      startDate: Yup.date().nullable().required().label('label.startDate'),
      endDate: Yup.date()
        .nullable()
        .when(['startDate'], ([startDate], schema) => {
          if (!startDate || !dayjs(startDate).isValid()) {
            return schema;
          }

          return schema.min(startDate);
        })
        .label('label.endDate'),
    });
