import { Button } from '@bas/ui/native/atoms';
import { Box, Icon } from '@bas/ui/native/base';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import dayjs, { Dayjs } from 'dayjs'; // make sure to import dayjs
import { useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';
import { SliderField } from '../SliderField';

type PickersProps = {
  date: Dayjs;
  onClose?: () => void;
  onChange: (date: Dayjs) => void;
  mode?: 'date' | 'time';
  minimumDate?: Date;
  maximumDate?: Date;
  sectionItems: {
    hours: {
      value: number;
    }[];
    minutes: {
      value: number;
    }[];
    days: {
      value: number;
    }[];
    months: {
      value: number;
    }[];
    years: {
      value: number;
    }[];
  };
};

const Pickers = ({
  date,
  onChange,
  onClose,
  sectionItems,
  mode,
  minimumDate,
  maximumDate,
}: PickersProps) => {
  const { formatMessage } = useIntl();
  const currentDate = useRef(date);

  // Helper function to ensure the date stays within the min/max bounds.
  const clampDate = useCallback(
    (newDate: Dayjs) => {
      let clampedDate = newDate;
      if (minimumDate) {
        const minDate = dayjs(minimumDate);
        if (clampedDate.isBefore(minDate)) {
          clampedDate = minDate;
        }
      }
      if (maximumDate) {
        const maxDate = dayjs(maximumDate);
        if (clampedDate.isAfter(maxDate)) {
          clampedDate = maxDate;
        }
      }
      return clampedDate;
    },
    [minimumDate, maximumDate],
  );

  const handleChangeHour = useCallback(
    (val: number) => {
      const newDate = clampDate(currentDate.current.clone().hour(val));
      currentDate.current = newDate;
      onChange(newDate);
    },
    [clampDate, onChange],
  );

  const handleChangeMinute = useCallback(
    (val: number) => {
      const newDate = clampDate(currentDate.current.clone().minute(val));
      currentDate.current = newDate;
      onChange(newDate);
    },
    [clampDate, onChange],
  );

  const handleChangeDay = useCallback(
    (val: number) => {
      const newDate = clampDate(currentDate.current.clone().date(val));
      currentDate.current = newDate;
      onChange(newDate);
    },
    [clampDate, onChange],
  );

  const handleChangeMonth = useCallback(
    (val: number) => {
      const newDate = clampDate(currentDate.current.clone().month(val));
      currentDate.current = newDate;
      onChange(newDate);
    },
    [clampDate, onChange],
  );

  const handleChangeYear = useCallback(
    (val: number) => {
      const newDate = clampDate(currentDate.current.clone().year(val));
      currentDate.current = newDate;
      onChange(newDate);
    },
    [clampDate, onChange],
  );

  const handleConfirm = useCallback(() => {
    const clampedDate = clampDate(currentDate.current);
    onChange(clampedDate);
    onClose?.();
  }, [clampDate, onChange, onClose]);

  return (
    <Box flex={1} maxWidth={450}>
      <Box
        width="100%"
        justifyContent="space-between"
        flexDirection="row"
        paddingHorizontal={3}
        paddingTop={3}
      >
        <Button variant="text" onPress={onClose}>
          <Icon icon={faTimes} size={18} />
        </Button>
        <Button variant="text" onPress={handleConfirm}>
          <Icon icon={faCheck} size={18} />
        </Button>
      </Box>
      <Box flex={1} flexDirection="row" alignItems="center" padding={3} gap={2}>
        {mode === 'time' ? (
          <>
            <Box height="100%" flexBasis="30%" flex={1} pl={2}>
              <SliderField
                label={formatMessage({ id: 'label.hour' })}
                value={date.hour()}
                values={sectionItems.hours}
                onChange={handleChangeHour}
                estimatedItemSize={37}
              />
            </Box>
            <Box height="100%" flexBasis="40%" flex={1}>
              <SliderField
                label={formatMessage({ id: 'label.minute' })}
                value={date.minute()}
                values={sectionItems.minutes}
                onChange={handleChangeMinute}
                estimatedItemSize={38}
              />
            </Box>
          </>
        ) : (
          <>
            <Box height="100%" flexBasis="30%" flex={1} pl={2}>
              <SliderField
                label={formatMessage({ id: 'label.day' })}
                value={date.date()}
                values={sectionItems.days}
                onChange={handleChangeDay}
                estimatedItemSize={37}
              />
            </Box>
            <Box height="100%" flexBasis="40%" flex={1}>
              <SliderField
                label={formatMessage({ id: 'label.month' })}
                value={date.month()}
                values={sectionItems.months}
                onChange={handleChangeMonth}
                estimatedItemSize={38}
              />
            </Box>
            <Box height="100%" flexBasis="30%" flex={1} pr={2}>
              <SliderField
                label={formatMessage({ id: 'label.year' })}
                value={date.year()}
                values={sectionItems.years}
                onChange={handleChangeYear}
                estimatedItemSize={38}
              />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Pickers;
