import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type RemovePicturesFromDamageMutationProps = {
  damageId: Uuid;
  pictures: {
    mediaObjectId: Uuid;
  }[];
};

export const RemovePicturesFromDamageMutation = async ({
  damageId,
  ...values
}: RemovePicturesFromDamageMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/damages/${damageId}/remove-pictures`, {
    damageId,
    ...values,
  });

export const useRemovePicturesFromDamageMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemovePicturesFromDamageMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  RemovePicturesFromDamageMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemovePicturesFromDamageMutationProps
  >({
    mutationFn: RemovePicturesFromDamageMutation,
    throwOnError: false,
    ...options,
  });
