import { isObjectResponse } from '@bas/value-objects';
import type { MovingJobCustomFurniture } from '../types';

export function isMovingJobCustomFurniture(
  object: unknown,
): object is MovingJobCustomFurniture {
  return (
    isObjectResponse(object) &&
    ['CustomFurnitureDto', 'CustomFurniture'].includes(object['@type'])
  );
}
