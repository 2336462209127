import { MediaObject } from '@bas/media-domain/models';
import { Uuid } from '@bas/value-objects';
import { MovingJobTaxationFurniture } from './MovingJobTaxationFurniture';
import { MovingJobTaxationInventoryItem } from './MovingJobTaxationInventoryItem';

export type MovingJobTaxationRoom = {
  roomId: Uuid;
  roomTypeId: Uuid;
  name?: string | null;
  notes?: string | null;
  selected: boolean;
  finished: boolean;
  photos: MediaObject[];
  furniture: MovingJobTaxationFurniture[];
  inventoryItems: MovingJobTaxationInventoryItem[];
};

export const MovingJobTaxationRoomInitialValues = {
  furniture: [],
  inventoryItems: [],
  photos: [],
  selected: true,
  finished: false,
};
