import { Uuid } from '@bas/value-objects';
import {
  isMovingJobTaxationFurniture,
  MovingJobTaxationFurniture,
} from './MovingJobTaxationFurniture';

export type MovingJobTaxationStandardFurniture = MovingJobTaxationFurniture & {
  furnitureTypeId: Uuid;
  furnitureVariantId?: Uuid;
  furnitureType: 'furniture';
  quantity: number;
};

export function isMovingJobTaxationStandardFurniture(
  object: unknown,
): object is MovingJobTaxationStandardFurniture {
  return (
    isMovingJobTaxationFurniture(object) && object.furnitureType === 'furniture'
  );
}
