import { MileageReportInputType } from '@bas/planning-domain/input-types';
import { formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type FinishEventWhenBackMutationProps = {
  eventId: Uuid;
  finishedAt: Date;
  realTravelEnd: Date;
  mileageReports: MileageReportInputType[];
};

export const FinishEventWhenBackMutation = async ({
  eventId,
  finishedAt,
  realTravelEnd,
  mileageReports,
  ...values
}: FinishEventWhenBackMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/events/${eventId}/finish-event-when-back`, {
    ...values,
    eventId,
    finishedAt: formatDateTime(finishedAt),
    realTravelEnd: formatDateTime(realTravelEnd),
    mileageReports: mileageReports.map(
      ({ reportStartDate, reportEndDate, ...mileageReport }) => ({
        ...mileageReport,
        reportStartDate: reportStartDate
          ? formatDateTime(reportStartDate)
          : null,
        reportEndDate: formatDateTime(reportEndDate || realTravelEnd),
      }),
    ),
  });

export const useFinishEventWhenBackMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    FinishEventWhenBackMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  FinishEventWhenBackMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    FinishEventWhenBackMutationProps
  >({
    mutationFn: FinishEventWhenBackMutation,
    throwOnError: false,
    ...options,
  });
