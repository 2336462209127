import * as Yup from 'yup';
import dayjs from 'dayjs';

export type AddNationalHolidayInputType = {
  name: string;
  start?: Date | null;
  end?: Date | null;
};

export const addNationalHolidayInputTypeDefaultValues =
  (): AddNationalHolidayInputType => ({
    name: '',
    start: null,
    end: null,
  });

export const addNationalHolidayInputTypeValidationBuilder =
  (): Yup.ObjectSchema<AddNationalHolidayInputType> =>
    Yup.object({
      name: Yup.string().label('label.name').ensure().required(),
      start: Yup.date().required().label('label.start'),
      end: Yup.date()
        .when(['start'], ([start], schema) => {
          if (!start || !dayjs(start).isValid()) {
            return schema;
          }

          return schema.min(dayjs(start).clone().startOf('day').toDate());
        })
        .label('label.end'),
    });
