import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type FinishProjectMutationProps = {
  projectId: Uuid;
};

export const FinishProjectMutation = async ({
  projectId,
  ...values
}: FinishProjectMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/projects/${projectId}/finish`, {
    projectId,
    ...values,
  });

export const useFinishProjectMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    FinishProjectMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  FinishProjectMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    FinishProjectMutationProps
  >({
    mutationFn: FinishProjectMutation,
    throwOnError: false,
    ...options,
  });
