import * as Yup from 'yup';

export interface BusinessRelationInformationInputType {
  companyName: string;
  cocNumber?: string | null;
  vatNumber?: string | null;
  rsinNumber?: string | null;
}

export const BusinessRelationInformationInputTypeValidationBuilder =
  (): Yup.ObjectSchema<BusinessRelationInformationInputType> =>
    Yup.object({
      companyName: Yup.string().required().label('label.companyName'),
      cocNumber: Yup.string().label('label.cocNumber').nullable(),
      vatNumber: Yup.string().vatNumber().label('label.vatNumber').nullable(),
      rsinNumber: Yup.string().label('label.rsinNumber').nullable(),
    });

export const BusinessRelationInformationInputTypeDefaultValues =
  (): BusinessRelationInformationInputType => ({
    companyName: '',
    cocNumber: '',
    vatNumber: '',
    rsinNumber: '',
  });
