import { VatScenario } from '@bas/financial-domain/models';
import { Uuid } from '@bas/value-objects';
import * as Yup from 'yup';

export interface UpdateFinancialDocumentInformationInputType {
  templateId: Uuid;
  identityId?: Uuid | null;
  relationId?: Uuid | null;
  useIdentities: boolean;
  reference?: string | null;
  vatScenario?: VatScenario | null;
}

export const UpdateFinancialDocumentInformationInputTypeValidatorBuilder =
  (): Yup.ObjectSchema<UpdateFinancialDocumentInformationInputType> =>
    Yup.object({
      templateId: Yup.string().required(),
      relationId: Yup.string().required().nullable(),
      useIdentities: Yup.boolean().required(),
      reference: Yup.string().nullable(),
      identityId: Yup.string().when('useIdentities', {
        is: true,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
      }),
      vatScenario: Yup.mixed<VatScenario>()
        .oneOf(Object.values(VatScenario).filter((option) => !!option))
        .nullable()
        .label('label.vatScenario'),
    });
