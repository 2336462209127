import { formatDate } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type DisableTimeTypeMutationProps = {
  timeTypeId: Uuid;
  disableAfter: Date;
};

export const DisableTimeTypeMutation = async ({
  timeTypeId,
  disableAfter,
  ...values
}: DisableTimeTypeMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/hrm/time-types/${timeTypeId}/disable`, {
    timeTypeId,
    disableAfter: formatDate(disableAfter),
    ...values,
  });

export const useDisableTimeTypeMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    DisableTimeTypeMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  DisableTimeTypeMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    DisableTimeTypeMutationProps
  >({
    mutationFn: DisableTimeTypeMutation,
    throwOnError: false,
    ...options,
  });
