export enum AutomatedEmailType {
  BASE_TEMPLATE = 'base_template',
  CUSTOM = 'custom',
  INVOICE = 'invoice',
  REMINDER_INVOICE = 'reminder_invoice',
  SECOND_REMINDER_INVOICE = 'second_reminder_invoice',
  LAST_REMINDER_INVOICE = 'last_reminder_invoice',
  RECEIVED_PAYMENT = 'received_payment',
  QUOTE = 'quote',
  REMINDER_QUOTE = 'reminder_quote',
  INTAKE_APPOINTMENT_CONFIRMATION = 'intake_appointment_confirmation',
  PICKUP_BOXES_APPOINTMENT_CONFIRMATION = 'pickup_boxes_appointment_confirmation',
  PLANNED_MOVING_APPOINTMENT_CONFIRMATION = 'planned_moving_appointment_confirmation',
  UPDATED_APPOINTMENT_CONFIRMATION = 'updated_appointment_confirmation',
  MOVING_DATE_IS_APPROACHING = 'moving_date_is_approaching',
  NEW_EMPLOYEE = 'new_employee',
  PASSWORD_RESET_REQUEST = 'password_reset_request',
  QUOTE_WAS_ACCEPTED_BY_CUSTOMER = 'quote_was_accepted_by_customer',
}
