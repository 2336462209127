import { ListedEmail } from '@bas/communication-domain/models';
import { Pagination, QueryOptionsWithKey } from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useEffect } from 'react';

export type ListedEmailsByProjectIdRequestProps = Pagination & {
  projectId: Uuid;
};

type Response = AxiosResponse<Collection<ListedEmail>>;

export const ListedEmailsByProjectIdRequest = async ({
  ...params
}: ListedEmailsByProjectIdRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/communication/emails`, {
    params: { ...params },
  });

export const useListedEmailsByProjectIdRequest = (
  { projectId, ...request }: ListedEmailsByProjectIdRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () =>
      ListedEmailsByProjectIdRequest({ ...request, projectId }),
    queryKey: ['emails', 'list', projectId, request],
  });

export const usePrefetchListedEmailsByProjectIdRequest = ({
  projectId,
  ...request
}: ListedEmailsByProjectIdRequestProps): void => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.prefetchQuery<Response, AxiosError<ErrorResponse>, Response>({
      queryKey: [
        'emails',
        'list',
        projectId,
        ...Object.values({ projectId, ...request }),
      ],
      queryFn: async () =>
        ListedEmailsByProjectIdRequest({ projectId, ...request }),
    });
  }, [projectId, queryClient, request]);
};
