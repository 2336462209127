import { formatDate } from '@bas/shared/utils';
import { ErrorResponse, Money, Uuid, VolumeType } from '@bas/value-objects';
import { StorageInvoicingPeriod } from '@bas/wms-domain/models';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type UpdateStorageContractMutationProps = {
  storageId: Uuid;
  identityId?: Uuid | null;
  invoicingPeriod: StorageInvoicingPeriod;
  vatCodeId: Uuid;
  price: Money;
  invoicingStartDate?: Date | null;
  lastInvoicedDate?: Date | null;
  includingVat: boolean;
  storedForProject?: Uuid | null;
  changedByEmployeeId: Uuid;
  invoicePerVolume: boolean | null;
  setVolumeToQuantity: boolean | null;
  volumeType: VolumeType | null;
  invoicableVolume: number | null;
};

export const UpdateStorageContractMutation = async ({
  storageId,
  invoicingStartDate,
  lastInvoicedDate,
  ...values
}: UpdateStorageContractMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/storages/${storageId}/update-invoicing`, {
    storageId,
    invoicingStartDate: invoicingStartDate
      ? formatDate(invoicingStartDate)
      : null,
    lastInvoicedDate: lastInvoicedDate ? formatDate(lastInvoicedDate) : null,
    ...values,
  });

export const useUpdateStorageContractMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateStorageContractMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  UpdateStorageContractMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateStorageContractMutationProps
  >({
    mutationFn: UpdateStorageContractMutation,
    throwOnError: false,
    ...options,
  });
