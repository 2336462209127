import { formatDate } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type RemoveCrewMutationProps = {
  eventId: Uuid;
  crewId: Uuid | null;
  date: Date;
  updateFutureEvents: boolean;
};

export const RemoveCrewMutation = async ({
  eventId,
  date,
  ...values
}: RemoveCrewMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/events/${eventId}/remove-crew`, {
    ...values,
    date: formatDate(date),
    eventId,
  });

export const useRemoveCrewMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveCrewMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  RemoveCrewMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveCrewMutationProps
  >({
    mutationFn: RemoveCrewMutation,
    throwOnError: false,
    ...options,
  });
