import { formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, Money, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ChangeReusableMaterialInformationMutationProps = {
  inventoryItemId: Uuid;
  name: string;
  length?: number | null;
  width?: number | null;
  height?: number | null;
  rentalPrice: Money;
  sellingPrice: Money;
  expiresAt?: Date | null;
};

export const ChangeReusableMaterialInformationMutation = async ({
  inventoryItemId,
  expiresAt,
  ...values
}: ChangeReusableMaterialInformationMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/inventory/reusable-materials/${inventoryItemId}/change`,
    {
      inventoryItemId,
      expiresAt: expiresAt ? formatDateTime(expiresAt) : null,
      ...values,
    },
  );

export const useChangeReusableMaterialInformationMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeReusableMaterialInformationMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ChangeReusableMaterialInformationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeReusableMaterialInformationMutationProps
  >({
    mutationFn: ChangeReusableMaterialInformationMutation,
    throwOnError: false,
    ...options,
  });
