import { FinancialDocumentLinesEditorInputType } from '@bas/financial-domain/input-types';
import { useCalculateTotals } from '@bas/financial-domain/utils';
import { FormattedCurrency } from '@bas/ui/native/atoms';
import { Box, Typography } from '@bas/ui/native/base';
import {
  ReactHookFormNumberTextField,
  ReactHookFormTextField,
} from '@bas/ui/native/molecules';
import * as React from 'react';
import { memo, ReactElement, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

export type FinancialDocumentTotalsProps = {
  documentType: 'quote' | 'invoice';
  light?: boolean;
};

const FinancialDocumentTotals = ({
  documentType,
  light,
}: FinancialDocumentTotalsProps): ReactElement => {
  const { formatMessage } = useIntl();
  const totals = useCalculateTotals(documentType);
  const currency = useWatch<
    FinancialDocumentLinesEditorInputType,
    'quote.totalIncludingVat.currency' | 'invoice.totalIncludingVat.currency'
  >({
    name: `${documentType}.totalIncludingVat.currency`,
  });

  const discount = useMemo(
    () => (
      <Box
        flexDirection="row"
        alignItems="center"
        pt="invoiceLines"
        pb="invoiceLines"
      >
        <Box width={20} />
        <Box mr={2} width="20%" maxWidth={300} />
        <Box mr={2} minWidth={200}>
          <ReactHookFormTextField
            name={`${documentType}.discount.description`}
            label={formatMessage({ id: 'label.discountDescription' })}
            light={light}
          />
        </Box>
        <Box mr={2} width={105}>
          <ReactHookFormNumberTextField
            name={`${documentType}.discount.percentage`}
            autoComplete="off"
            keyboardType="decimal-pad"
            light={light}
            label={formatMessage({ id: 'label.percentage' })}
            suffix="%"
            fractionDigits={2}
            padZero={false}
            showZero
          />
        </Box>
        <Box mr={2} width={82.75} />
        <Box mr={2} width={137.75}>
          <Typography color={light ? 'white' : undefined}>
            <FormattedCurrency
              currency={currency}
              amount={totals.discountAmount}
            />
          </Typography>
        </Box>
        <Box width={84} />
      </Box>
    ),
    [currency, documentType, formatMessage, light, totals.discountAmount],
  );

  return (
    <>
      {discount}
      <Box
        flexDirection="row"
        alignItems="center"
        pt="invoiceLines"
        pb="invoiceLines"
      >
        <Box width={20} />
        <Box mr={2} width="20%" maxWidth={300} />
        <Box mr={2} minWidth={200} />
        <Box mr={2} width={87.75}>
          <Typography color={light ? 'white' : undefined}>
            <FormattedMessage id="label.subTotal" />
          </Typography>
        </Box>
        <Box mr={2} width={82.75} />
        <Box mr={2} width={137.75}>
          <Typography color={light ? 'white' : undefined}>
            <FormattedCurrency
              currency={currency}
              amount={totals.totalExcludingVat}
            />
          </Typography>
        </Box>
        <Box width={84} />
      </Box>

      {Object.values(totals.vats)
        .sort(
          (vatA, vatB) =>
            vatA.vatPercentage.percentage - vatB.vatPercentage.percentage,
        )
        .map((vat, index) => (
          <Box
            flexDirection="row"
            alignItems="center"
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            pt="invoiceLines"
            pb="invoiceLines"
          >
            <Box width={20} />
            <Box mr={2} width="20%" maxWidth={300} />
            <Box mr={2} minWidth={200} />
            <Box mr={2} width={87.75}>
              <Typography
                color={light ? 'white' : undefined}
              >{`${vat.vatPercentage.percentage}%`}</Typography>
            </Box>
            <Box mr={2} width={82.75} />
            <Box mr={2} width={137.75}>
              <Typography color={light ? 'white' : undefined}>
                <FormattedCurrency
                  currency={currency}
                  amount={vat.totalVatAmount}
                />
              </Typography>
            </Box>
            <Box width={84} />
          </Box>
        ))}
      <Box
        flexDirection="row"
        alignItems="center"
        pt="invoiceLines"
        pb="invoiceLines"
      >
        <Box width={20} />
        <Box mr={2} width="20%" maxWidth={300} />
        <Box mr={2} minWidth={200} />
        <Box mr={2} width={87.75}>
          <Typography color={light ? 'white' : undefined} fontWeight={700}>
            <FormattedMessage id="label.total" />
          </Typography>
        </Box>
        <Box mr={2} width={82.75} />
        <Box mr={2} width={137.75}>
          <Typography color={light ? 'white' : undefined} fontWeight={700}>
            <FormattedCurrency
              currency={currency}
              amount={totals.totalIncludingVat}
            />
          </Typography>
        </Box>
        <Box width={84} />
      </Box>
    </>
  );
};

const MemoComponent = memo(
  FinancialDocumentTotals,
  (prevProps, nextProps) =>
    prevProps.light === nextProps.light &&
    prevProps.documentType === nextProps.documentType,
);
export default MemoComponent;
