import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type RemoveHandlingCostsOfStorageProps = {
  handlingCostsId: Uuid;
  storageId: Uuid;
};

export const RemoveHandlingCostsOfStorage = async ({
  handlingCostsId,
  storageId,
  ...values
}: RemoveHandlingCostsOfStorageProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/storages/${storageId}/handling-costs/${handlingCostsId}/remove`,
    {
      handlingCostsId,
      ...values,
    },
  );

export const useRemoveHandlingCostsOfStorage = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveHandlingCostsOfStorageProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  RemoveHandlingCostsOfStorageProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveHandlingCostsOfStorageProps
  >({
    mutationFn: RemoveHandlingCostsOfStorage,
    throwOnError: true,
    ...options,
  });
