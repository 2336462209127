import { DamageStatus, ProjectEstimate } from '@bas/project-domain/models';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ChangeStatusOfDamageProps = {
  damageId: Uuid;
  newStatus: DamageStatus;
};

export const ChangeStatusOfDamage = async ({
  damageId,
  ...values
}: ChangeStatusOfDamageProps): Promise<AxiosResponse<ProjectEstimate>> =>
  axios.put(`api/{tenantId}/damages/${damageId}/change-status`, {
    damageId,
    ...values,
  });

export const useChangeStatusOfDamage = (
  options: UseMutationOptions<
    AxiosResponse<ProjectEstimate>,
    AxiosError<ErrorResponse>,
    ChangeStatusOfDamageProps
  > = {},
): UseMutationResult<
  AxiosResponse<ProjectEstimate>,
  AxiosError<ErrorResponse>,
  ChangeStatusOfDamageProps
> =>
  useMutation<
    AxiosResponse<ProjectEstimate>,
    AxiosError<ErrorResponse>,
    ChangeStatusOfDamageProps
  >({
    mutationFn: ChangeStatusOfDamage,
    throwOnError: true,
    ...options,
  });
