import { isInvoiceEvent } from './InvoiceEvent';
import { InvoiceEventType, InvoiceReminderSentEvent } from '../types';

export function isInvoiceReminderSentEvent(
  object: unknown,
): object is InvoiceReminderSentEvent {
  return (
    isInvoiceEvent(object) &&
    object.eventType === InvoiceEventType.InvoiceReminderSentEvent
  );
}
