import { colors } from '@bas/theme';
import { BottomTabBarButtonProps } from '@react-navigation/bottom-tabs/src/types';
import * as React from 'react';
import { ReactElement } from 'react';
import { Pressable } from 'react-native';

export type CustomTabButtonProps = BottomTabBarButtonProps;

const CustomTabButton = ({
  href,
  ...props
}: CustomTabButtonProps): ReactElement => (
  <Pressable
    {...props}
    style={[
      props.style,
      {
        borderTopColor: props.accessibilityState?.selected
          ? colors.blue[500]
          : colors.lila[300],
        borderTopWidth: 1,
      },
    ]}
  />
);

export default CustomTabButton;
