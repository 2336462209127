import { StyleSheet } from 'react-native';
import { colors } from '@bas/theme';

export default StyleSheet.create({
  container: {
    // flexDirection: 'row',
    // justifyContent: 'space-between',
    // height: 80,
    borderTopWidth: 1,
  },
  dark: {
    backgroundColor: colors.blue[900],
    borderTopColor: colors.blue[900],
  },
  light: {
    backgroundColor: colors.white,
    borderTopColor: colors.lila[400],
  },
  stepIndicators: {
    // width: '100%',
  },
  steps: {
    flexDirection: 'row',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    flexWrap: 'wrap',
  },
  label: {
    alignItems: 'center',
  },
  button: {
    height: 80,
    width: 80,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonPressed: {},
});
