import { QueryOptionsWithKey } from '@bas/shared/requests';
import { Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type StoragePdfByStorageIdRequestProps = {
  storageId: Uuid;
};

export const StoragePdfByStorageIdRequest = async ({
  storageId,
  ...params
}: StoragePdfByStorageIdRequestProps): Promise<AxiosResponse<Blob>> =>
  axios.get(`api/{tenantId}/storages/${storageId}/download-pdf`, {
    responseType: 'blob',
    params,
  });

export const useStoragePdfByStorageIdRequestQuery = (
  request: StoragePdfByStorageIdRequestProps,
  options: QueryOptionsWithKey<
    AxiosResponse<Blob>,
    AxiosError,
    AxiosResponse<Blob>
  > = {},
): UseQueryResult<AxiosResponse<Blob>, AxiosError> =>
  useQuery<AxiosResponse<Blob>, AxiosError, AxiosResponse<Blob>>({
    ...options,
    queryFn: async () => StoragePdfByStorageIdRequest(request),
    queryKey: ['storages', 'pdf', request.storageId],
    gcTime: 0,
    staleTime: 0,
  });
