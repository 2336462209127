import { formatDate } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type RemoveMaterialFromEventMutationProps = {
  eventId: Uuid;
  materialId: Uuid;
  date: Date;
  updateFutureEvents: boolean;
};

export const RemoveMaterialFromEventMutation = async ({
  eventId,
  date,
  ...values
}: RemoveMaterialFromEventMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/events/${eventId}/remove-material`, {
    ...values,
    date: formatDate(date),
    eventId,
  });

export const useRemoveMaterialFromEventMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveMaterialFromEventMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  RemoveMaterialFromEventMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveMaterialFromEventMutationProps
  >({
    mutationFn: RemoveMaterialFromEventMutation,
    throwOnError: false,
    ...options,
  });
