import { Box } from '@bas/ui/native/base';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { LayoutChangeEvent, StyleSheet, ViewProps } from 'react-native';

export type MobileActionButtonsGroupProps = ViewProps & {
  buttons: ReactElement[];
  halfWidthButtons?: boolean;
};

const styles = StyleSheet.create({
  button: {
    flex: 1,
    minWidth: 162,
  },
  container: {
    flex: 1,
    gap: 12,
    flexDirection: 'row',
    flexWrap: 'wrap',
    display: 'flex',
    alignContent: 'flex-start',
  },
  justified: {
    justifyContent: 'center',
  },
});

const MobileActionButtonsGroup = ({
  buttons,
  style,
  halfWidthButtons = true,
  ...props
}: MobileActionButtonsGroupProps): ReactElement => {
  const [width, setWidth] = useState(0);

  const handleOnLayout = useCallback((event: LayoutChangeEvent) => {
    setWidth(event.nativeEvent.layout.width);
  }, []);

  const minWidth = useMemo(() => {
    if (halfWidthButtons) {
      const result = width / 2 - 12;
      return result < 162 ? 162 : result;
    }
    return 162;
  }, [halfWidthButtons, width]);

  const buttonWidth = useMemo(() => {
    if (halfWidthButtons) {
      const result = width / 2 - 12;
      return result < 162 ? 162 : result;
    }
    return undefined;
  }, [halfWidthButtons, width]);

  return (
    <Box
      style={[
        styles.container,
        buttons.length === 1 && styles.justified,
        style,
      ]}
      onLayout={handleOnLayout}
      {...props}
    >
      {buttons.map((button, index) => (
        <Box
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          minWidth={minWidth}
          width={buttonWidth}
          maxWidth={buttonWidth}
        >
          {button}
        </Box>
      ))}
    </Box>
  );
};

export default MobileActionButtonsGroup;
