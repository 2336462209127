import { EventDtoInputType } from '@bas/planning-domain/input-types';
import { ProjectEstimate } from '@bas/project-domain/models';
import { formatDate, formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, PricingType, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type CreateMovingLiftJobMutationProps = {
  projectId: Uuid;
  identityId?: Uuid | null;
  customerId: Uuid;
  packageId: Uuid;
  pricingType: PricingType;
  events: EventDtoInputType[];
};

export const CreateMovingLiftJobMutation = async ({
  projectId,
  events,
  ...values
}: CreateMovingLiftJobMutationProps): Promise<AxiosResponse<ProjectEstimate>> =>
  axios.post(`api/{tenantId}/moving-lift-jobs`, {
    projectId,
    events: events.map(
      ({
        start,
        end,
        travelStart,
        travelEnd,
        recurringInformation,
        ...event
      }) => ({
        ...event,
        date: null,
        start: formatDateTime(start),
        end: formatDateTime(end),
        travelStart: travelStart ? formatDateTime(travelStart) : null,
        travelEnd: travelEnd ? formatDateTime(travelEnd) : null,
        recurringInformation: recurringInformation
          ? {
              ...recurringInformation,
              repeatFrom: formatDate(recurringInformation.repeatFrom),
              repeatUntil: formatDate(recurringInformation.repeatUntil),
            }
          : null,
      }),
    ),
    ...values,
  });

export const useCreateMovingLiftJobMutation = (
  options: UseMutationOptions<
    AxiosResponse<ProjectEstimate>,
    AxiosError<ErrorResponse>,
    CreateMovingLiftJobMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse<ProjectEstimate>,
  AxiosError<ErrorResponse>,
  CreateMovingLiftJobMutationProps
> =>
  useMutation<
    AxiosResponse<ProjectEstimate>,
    AxiosError<ErrorResponse>,
    CreateMovingLiftJobMutationProps
  >({
    mutationFn: CreateMovingLiftJobMutation,
    throwOnError: false,
    ...options,
  });
