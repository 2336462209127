import { MileageReportInputType } from '@bas/planning-domain/input-types';
import { formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type FinishEventMutationProps = {
  startTime: Date;
  finishedAt: Date;
  realTravelStartTime?: Date | null;
  realTravelEnd?: Date | null;
  eventId: Uuid;
  mileageReports: MileageReportInputType[];
  breakTime: number | null;
};

export const FinishEventMutation = async ({
  eventId,
  startTime,
  finishedAt,
  realTravelStartTime,
  realTravelEnd,
  mileageReports,
  ...values
}: FinishEventMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/events/${eventId}/finish`, {
    ...values,
    eventId,
    startTime: formatDateTime(startTime),
    finishedAt: formatDateTime(finishedAt),
    realTravelStartTime: realTravelStartTime
      ? formatDateTime(realTravelStartTime)
      : null,
    realTravelEnd: realTravelEnd ? formatDateTime(realTravelEnd) : null,
    mileageReports: mileageReports.map(
      ({ reportStartDate, reportEndDate, ...mileageReport }) => ({
        ...mileageReport,
        reportStartDate: reportStartDate
          ? formatDateTime(reportStartDate)
          : null,
        reportEndDate:
          reportEndDate || realTravelEnd
            ? formatDateTime((reportEndDate || realTravelEnd) as Date)
            : null,
      }),
    ),
  });

export const useFinishEventMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    FinishEventMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  FinishEventMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    FinishEventMutationProps
  >({
    mutationFn: FinishEventMutation,
    throwOnError: false,
    ...options,
  });
