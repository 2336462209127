/* eslint-disable @typescript-eslint/no-explicit-any */
import { colors } from '@bas/theme';
import {
  BottomSheetBackdrop,
  BottomSheetBackdropProps,
  BottomSheetModal,
  BottomSheetModal as BaseBottomSheetModal,
  BottomSheetModalProps as BaseBottomSheetModalProps,
} from '@gorhom/bottom-sheet';
import { BottomSheetModalMethods } from '@gorhom/bottom-sheet/lib/typescript/types';
import {
  ForwardedRef,
  forwardRef,
  ReactElement,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { StyleSheet, useWindowDimensions } from 'react-native';
import type {
  WithSpringConfig,
  WithTimingConfig,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

export type BottomSheetMethods = BottomSheetModalMethods & {
  toggleBlockingClose: () => void;
  enableBlockingClose: () => void;
  disableBlockingClose: () => void;
};

export type BottomSheetProps<TData> = Omit<
  BaseBottomSheetModalProps<TData>,
  'snapPoints'
> & {
  disablePadding?: boolean;
  disableClose?: boolean;
  fullWidth?: boolean;
  snapPoints?: number[];
};

const styles = StyleSheet.create({
  bottomSheetContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  disablePadding: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  hide: {
    opacity: 0,
  },
  bottomSheetBackground: {
    backgroundColor: colors.lila[800],
    borderTopLeftRadius: 36,
    borderTopRightRadius: 36,
  },
  indicatorStyle: {
    backgroundColor: colors.lila[400],
    borderRadius: 100,
    width: 51,
    height: 4,
    top: 5,
    padding: 0,
    marginBottom: 17,
  },
});

const BottomSheet = forwardRef<BottomSheetMethods, BottomSheetProps<any>>(
  (
    {
      disablePadding,
      disableClose,
      fullWidth,
      snapPoints,
      ...props
    }: BottomSheetProps<any>,
    ref,
  ): ReactElement => {
    const { height, width } = useWindowDimensions();
    const insets = useSafeAreaInsets();
    const [isBlockingClose, setIsBlockingClose] = useState(false);
    const [blockPanningGesture, setBlockPanningGesture] = useState(false);

    const [style, containerStyle] = useMemo(() => {
      if (width < 780 || fullWidth) {
        return [{}, {}];
      }

      return [
        { maxWidth: 780 },
        {
          marginLeft: (width - insets.left - insets.right - 780) / 2,
        },
      ];
    }, [insets.left, insets.right, width, fullWidth]);

    const baseSnapPoints = useMemo(
      () => [height - insets.top - 30],
      [height, insets.top],
    );

    const renderBackdropComponent = useCallback(
      (backdropProps: BottomSheetBackdropProps) => (
        <BottomSheetBackdrop
          {...backdropProps}
          opacity={0.2}
          appearsOnIndex={0}
          disappearsOnIndex={-1}
          pressBehavior={disableClose || isBlockingClose ? 'none' : 'close'}
        />
      ),
      [disableClose, isBlockingClose],
    );

    const styleMemo = useMemo(
      () => [
        style,
        styles.bottomSheetContainer,
        disablePadding && styles.disablePadding,
      ],
      [style, disablePadding],
    );

    const handleIndicatorStyle = useMemo(
      () => [
        styles.indicatorStyle,
        (disableClose || isBlockingClose) && styles.hide,
      ],
      [disableClose, isBlockingClose],
    );

    const bottomSheetRef = useRef<BaseBottomSheetModal>(null);

    // Expose imperative methods (including our custom toggle functions) on the ref.
    useImperativeHandle(
      ref,
      () => ({
        toggleBlockingClose: () => {
          setIsBlockingClose((prev) => !prev);
          setBlockPanningGesture((prev) => !prev);
        },
        enableBlockingClose: () => {
          setIsBlockingClose(true);
          setBlockPanningGesture(true);
        },
        disableBlockingClose: () => {
          setIsBlockingClose(false);
          setBlockPanningGesture(false);
        },
        snapToIndex: (
          index: number,
          animationConfigs?: WithSpringConfig | WithTimingConfig,
        ) => bottomSheetRef.current?.snapToIndex(index, animationConfigs),
        snapToPosition: (
          position: number | string,
          animationConfigs?: WithSpringConfig | WithTimingConfig,
        ) => bottomSheetRef.current?.snapToPosition(position, animationConfigs),
        expand: (animationConfigs?: WithSpringConfig | WithTimingConfig) =>
          bottomSheetRef.current?.expand(animationConfigs),
        collapse: (animationConfigs?: WithSpringConfig | WithTimingConfig) =>
          bottomSheetRef.current?.collapse(animationConfigs),
        close: (animationConfigs?: WithSpringConfig | WithTimingConfig) =>
          bottomSheetRef.current?.close(animationConfigs),
        forceClose: (animationConfigs?: WithSpringConfig | WithTimingConfig) =>
          bottomSheetRef.current?.forceClose(animationConfigs),
        dismiss: (animationConfigs?: WithSpringConfig | WithTimingConfig) =>
          bottomSheetRef.current?.dismiss(animationConfigs),
        present: (data) => bottomSheetRef.current?.present(data),
      }),
      [],
    );

    return (
      <BottomSheetModal
        ref={bottomSheetRef}
        enableOverDrag={false}
        enableHandlePanningGesture={!blockPanningGesture}
        enableContentPanningGesture={false}
        snapPoints={snapPoints || baseSnapPoints}
        enableDismissOnClose
        index={0}
        style={styleMemo}
        backgroundStyle={styles.bottomSheetBackground}
        handleIndicatorStyle={handleIndicatorStyle}
        backdropComponent={renderBackdropComponent}
        enablePanDownToClose={!(disableClose || isBlockingClose)}
        containerStyle={containerStyle}
        enableDynamicSizing={false}
        stackBehavior="push"
        {...props}
      />
    );
  },
);

export default BottomSheet as <T>(
  props: BottomSheetProps<T> & {
    ref?: ForwardedRef<BottomSheetModal<T> & BottomSheetMethods>;
  },
) => ReactElement;
