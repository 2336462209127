import { ErrorResponse, Money, NullableMoney, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ChangeRevenueOfProjectMutationProps = {
  projectId: Uuid;
  expectedRevenue?: Money | NullableMoney;
  realizedRevenue?: Money | NullableMoney;
};

export const ChangeRevenueOfProjectMutation = async ({
  projectId,
  expectedRevenue,
  realizedRevenue,
  ...values
}: ChangeRevenueOfProjectMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/projects/${projectId}/change-revenue`, {
    projectId,
    expectedRevenue: {
      amount: expectedRevenue?.amount || 0,
      currency: expectedRevenue?.currency || 'EUR',
    },
    realizedRevenue: {
      amount: realizedRevenue?.amount || 0,
      currency: realizedRevenue?.currency || 'EUR',
    },
    ...values,
  });

export const useChangeRevenueOfProjectMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeRevenueOfProjectMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ChangeRevenueOfProjectMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeRevenueOfProjectMutationProps
  >({
    mutationFn: ChangeRevenueOfProjectMutation,
    throwOnError: false,
    ...options,
  });
