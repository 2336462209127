import { EventDtoInputType } from '@bas/planning-domain/input-types';
import { TransportJobCargoInputType } from '@bas/project-domain/input-types';
import { ProjectEstimate } from '@bas/project-domain/models';
import { formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, Money, PricingType, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type CreateTransportJobMutationProps = {
  projectId: Uuid;
  identityId?: Uuid | null;
  customerId: Uuid;
  packageId: Uuid;
  pricingType: PricingType;
  hourlyPrice: Money;
  loads: TransportJobCargoInputType[];
  events: EventDtoInputType[];
  intakeEvent?: {
    start: Date;
    end: Date;
    travelStart?: Date | null;
    travelEnd?: Date | null;
    employeeIds: Uuid[];
  };
};

export const CreateTransportJobMutation = async ({
  projectId,
  intakeEvent,
  events,
  ...values
}: CreateTransportJobMutationProps): Promise<AxiosResponse<ProjectEstimate>> =>
  axios.post(`api/{tenantId}/transport-jobs`, {
    projectId,
    events: events.map(({ start, end, travelStart, ...event }) => ({
      ...event,
      start: formatDateTime(start),
      end: formatDateTime(end),

      travelStart: travelStart ? formatDateTime(travelStart) : null,
    })),
    intakeEvent: intakeEvent
      ? {
          ...intakeEvent,
          start: formatDateTime(intakeEvent.start),
          end: formatDateTime(intakeEvent.end),
          travelStart: intakeEvent.travelStart
            ? formatDateTime(intakeEvent.travelStart)
            : null,
          travelEnd: intakeEvent.travelEnd
            ? formatDateTime(intakeEvent.travelEnd)
            : null,
        }
      : undefined,
    ...values,
  });

export const useCreateTransportJobMutation = (
  options: UseMutationOptions<
    AxiosResponse<ProjectEstimate>,
    AxiosError<ErrorResponse>,
    CreateTransportJobMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse<ProjectEstimate>,
  AxiosError<ErrorResponse>,
  CreateTransportJobMutationProps
> =>
  useMutation<
    AxiosResponse<ProjectEstimate>,
    AxiosError<ErrorResponse>,
    CreateTransportJobMutationProps
  >({
    mutationFn: CreateTransportJobMutation,
    throwOnError: false,
    ...options,
  });
