import { Project } from '@bas/project-domain/models';
import {
  ProjectOutstandingInventoryItems,
  ProjectOutstandingInventoryItemsProps,
} from '@bas/project-domain/native/organisms';
import { colors } from '@bas/theme';
import { Box, Typography } from '@bas/ui/native/base';
import { Uuid } from '@bas/value-objects';
import { RetrieveMaterialsForm } from '@bas/wms-domain/inventory/native/organisms';
import { InventoryItem, NeededInventoryItem } from '@bas/wms-domain/models';
import { ReactElement, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { ScrollView } from 'react-native-gesture-handler';

export type RetrieveMaterialsStepProps = {
  title: string;
  projectId?: Uuid;
  neededInventoryItems?: NeededInventoryItem[];
  project?: Project;
  materials: InventoryItem[];
  offSetIndex?: number;
  projectOutstandingInventoryItemsProps?: Partial<ProjectOutstandingInventoryItemsProps>;
  wornLabel?: ReactElement | string;
  wornDescription?: ReactNode;
};

const RetrieveMaterialsStep = ({
  projectId,
  neededInventoryItems,
  project,
  materials,
  title,
  offSetIndex,
  projectOutstandingInventoryItemsProps,
  wornLabel,
  wornDescription,
}: RetrieveMaterialsStepProps) => (
  <ScrollView>
    <Box
      paddingHorizontal={{
        tablet: 50,
        phone: 20,
      }}
    >
      <Typography overrideColor={colors.white}>
        <FormattedMessage id={title} />
      </Typography>
      {projectId && (
        <Box pt={3}>
          <ProjectOutstandingInventoryItems
            light
            projectId={projectId}
            rooms={[]}
            neededInventoryItems={
              neededInventoryItems || project?.neededInventoryItems || []
            }
            {...(projectOutstandingInventoryItemsProps || {})}
          />
        </Box>
      )}
      <Box pt={3}>
        <RetrieveMaterialsForm
          materials={materials}
          offSetIndex={offSetIndex}
          wornLabel={wornLabel}
          wornDescription={wornDescription}
        />
      </Box>
    </Box>
  </ScrollView>
);

export default RetrieveMaterialsStep;
