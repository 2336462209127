import { useEmployeeStore, useTimeTrackingStore } from '@bas/shared/state';
import { colors } from '@bas/theme';
import { Badge, Button } from '@bas/ui/native/atoms';
import { Box } from '@bas/ui/native/base';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCalendarClock } from '@fortawesome/pro-light-svg-icons/faCalendarClock';
import { faChessClock } from '@fortawesome/pro-light-svg-icons/faChessClock';
import { faIslandTropical } from '@fortawesome/pro-light-svg-icons/faIslandTropical';
import { faMoneyCheck } from '@fortawesome/pro-light-svg-icons/faMoneyCheck';
import { ScreenListeners } from '@react-navigation/core';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { router } from 'expo-router';
import * as React from 'react';
import {
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { FlatList, useWindowDimensions } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { DeclarationsScreen } from '../DeclarationsScreen';
import { HoursInsightScreen } from '../HoursInsightScreen';
import { HoursOverviewScreen } from '../HoursOverviewScreen';
import { TimeOffRegistrationsScreen } from '../TimeOffRegistrationsScreen';
import { TrackedTimesCalendarScreen } from '../TrackedTimesCalendarScreen';
import { TrackTimeScreen } from '../TrackTimeScreen';

const Stack = createNativeStackNavigator<{
  TrackTime: undefined;
  TrackedTimesCalendar: {
    date?: string;
  };
  HoursOverview: undefined;
  TimeOffRegistrations: undefined;
  Declarations: undefined;
  HoursInsight: undefined;
}>();

const TopTabButton = ({
  route,
  currentRoute,
  label,
  icon,
  warning,
  hideLabel,
}: {
  route: string;
  currentRoute: string;
  label: string | ReactNode;
  icon: IconProp;
  warning?: boolean;
  hideLabel?: boolean;
}) => {
  const isCurrentRoute = useMemo(
    () => currentRoute === route,
    [currentRoute, route],
  );

  const borderColor = useMemo(() => {
    if (isCurrentRoute) {
      return 'mainPrimary';
    }

    return undefined;
  }, [isCurrentRoute]);

  const overrideColor = useMemo(() => {
    if (isCurrentRoute) {
      return colors.blue[500];
    }

    return undefined;
  }, [isCurrentRoute]);

  return (
    <Button
      variant="text"
      style={{
        paddingVertical: 12,
      }}
      borderBottomColor={borderColor}
      borderBottomWidth={isCurrentRoute ? 1 : 0}
      overrideColor={overrideColor}
      onPress={() => router.push(`/time-tracking/${route}`)}
      fontWeight={isCurrentRoute ? 700 : 400}
      icon={icon}
      mr={3}
      iconProps={{
        size: hideLabel ? 30 : 24,
      }}
      append={warning && <Badge color={colors.orange[700]} />}
    >
      {!hideLabel && label}
    </Button>
  );
};

const MobileEmployeeTimeTrackingScreen = (): ReactElement => {
  const { top } = useSafeAreaInsets();
  const { width } = useWindowDimensions();

  const [currentPage, setCurrentPage] = useState('TrackTimeScreen');
  const [showOnlyIcons, setShowOnlyIcons] = useState(false);
  const [disableScroll, setDisableScroll] = useState(false);

  const checkedIfShowOnlyIconsForWidth = useRef<{
    width: number;
    contentWidth: number;
  }>();

  const lastSync = useTimeTrackingStore((state) => state.lastSync);
  const hasUnsyncedItems = useTimeTrackingStore(
    (state) => state.hasUnsyncedItems,
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const syncColor = useMemo(() => {
    if (lastSync && !lastSync.success) {
      return colors.red[700];
    }
    if (hasUnsyncedItems) {
      return colors.yellow[700];
    }

    return colors.lila[800];
  }, [hasUnsyncedItems, lastSync]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const screenListeners: ScreenListeners<any, any> = useMemo(
    () => ({
      state: (e) => {
        setCurrentPage(
          e.data?.state.routes[e.data?.state.index].name || 'TrackTime',
        );
      },
    }),
    [],
  );

  const handleCheckIfShowOnlyIcons = useCallback(
    (contentWidth: number) => {
      if (
        checkedIfShowOnlyIconsForWidth.current &&
        checkedIfShowOnlyIconsForWidth.current.width === width
      ) {
        return;
      }

      if (contentWidth >= width) {
        setShowOnlyIcons(true);
      } else {
        setShowOnlyIcons(false);
      }

      if (contentWidth <= width) {
        setDisableScroll(true);
      }

      checkedIfShowOnlyIconsForWidth.current = {
        width,
        contentWidth,
      };
    },
    [width],
  );

  const employeeId = useEmployeeStore((state) => state.employee?.employeeId);
  const trackedTimePerDate = useTimeTrackingStore(
    (state) => state.trackedTimePerDate,
  );

  const needsSyncing = useMemo(
    () =>
      Object.keys(trackedTimePerDate).some((date) => {
        const hasOpenItems = trackedTimePerDate[date].some(
          ({ employeeId: itemEmployeeId, end }) =>
            itemEmployeeId === employeeId,
        );

        return hasOpenItems;
      }),
    [employeeId, trackedTimePerDate],
  );

  return (
    <Box flex={1} backgroundColor="cardBorder">
      <Box
        style={{ paddingTop: top }}
        borderBottomWidth={1}
        borderBottomColor="titleBorder"
      >
        <Box
          width="100%"
          flexDirection="row"
          alignItems="center"
          paddingHorizontal={20}
          style={{
            bottom: -1,
          }}
        >
          <FlatList
            scrollEnabled={!disableScroll}
            horizontal
            data={[
              {
                route: 'TrackTime',
                icon: faChessClock,
                label: <FormattedMessage id="button.trackTime" />,
              },
              {
                route: 'Declarations',
                icon: faMoneyCheck,
                label: <FormattedMessage id="button.declarations" />,
              },
              {
                route: 'TrackedTimesCalendar',
                icon: faCalendarClock,
                warning: needsSyncing,
                label: <FormattedMessage id="button.registeredHours" />,
              },
              {
                route: 'HoursInsight',
                icon: faCalendarClock,
                warning: false,
                label: <FormattedMessage id="button.hoursInsight" />,
              },
              {
                route: 'TimeOffRegistrations',
                icon: faIslandTropical,
                label: <FormattedMessage id="button.timeOffRegistrations" />,
              },
            ]}
            renderItem={({ item }) => (
              <TopTabButton
                route={item.route}
                currentRoute={currentPage}
                label={item.label}
                icon={item.icon}
                warning={item.warning}
                hideLabel={showOnlyIcons}
              />
            )}
            onContentSizeChange={handleCheckIfShowOnlyIcons}
          />
        </Box>
      </Box>
      <Box flex={1}>
        <Stack.Navigator
          screenOptions={{
            headerShown: false,
          }}
          screenListeners={screenListeners}
        >
          <Stack.Screen name="TrackTime" component={TrackTimeScreen} />
          <Stack.Screen
            name="TrackedTimesCalendar"
            component={TrackedTimesCalendarScreen}
          />
          <Stack.Screen name="HoursOverview" component={HoursOverviewScreen} />
          <Stack.Screen name="HoursInsight" component={HoursInsightScreen} />
          <Stack.Screen name="Declarations" component={DeclarationsScreen} />
          <Stack.Screen
            name="TimeOffRegistrations"
            component={TimeOffRegistrationsScreen}
          />
        </Stack.Navigator>
      </Box>
    </Box>
  );
};

export default MobileEmployeeTimeTrackingScreen;
