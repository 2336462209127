import { formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type StartWorkingOnEventMutationProps = {
  eventId: Uuid;
  startTime: Date;
};

export const StartWorkingOnEventMutation = async ({
  eventId,
  startTime,
  ...values
}: StartWorkingOnEventMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/events/${eventId}/start-working-on-event`, {
    ...values,
    eventId,
    startTime: formatDateTime(startTime),
  });

export const useStartWorkingOnEventMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    StartWorkingOnEventMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  StartWorkingOnEventMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    StartWorkingOnEventMutationProps
  >({
    mutationFn: StartWorkingOnEventMutation,
    throwOnError: false,
    ...options,
  });
