import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ChangeEmployeesAttributesMutationProps = {
  employeeId: Uuid;
  attributes: {
    employeeAttributeId: Uuid;
  }[];
};

export const ChangeEmployeesAttributesMutation = async ({
  employeeId,
  ...values
}: ChangeEmployeesAttributesMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/hrm/employees/${employeeId}/update-attributes`, {
    employeeId,
    ...values,
  });

export const useChangeEmployeesAttributesMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeEmployeesAttributesMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ChangeEmployeesAttributesMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeEmployeesAttributesMutationProps
  >({
    mutationFn: ChangeEmployeesAttributesMutation,
    throwOnError: false,
    ...options,
  });
