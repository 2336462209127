import { StyleSheet } from 'react-native';
import { colors } from '@bas/theme';

export default StyleSheet.create({
  contentContainer: {
    flexGrow: 1,
  },
  actionsContainer: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 7,
    backgroundColor: colors.lila[200],
  },
  container: {
    flex: 1,
    borderTopWidth: 1,
    borderTopColor: colors.lila[400],
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 28,
  },
  flexContainer: {
    backgroundColor: colors.lila[200],
    flex: 1,
  },
  actions: {
    backgroundColor: colors.lila[200],
    paddingBottom: 26,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  alert: {
    marginBottom: 12,
  },
  whiteBackground: {
    backgroundColor: colors.white,
  },
  eventTypeContainer: {
    paddingBottom: 18,
    flex: 0.1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  eventTypeIcon: {
    marginRight: 12,
  },
  teamTitle: {
    paddingBottom: 8,
  },
  plannedMaterialsTitle: {
    paddingBottom: 8,
  },
  paddingContent: {
    paddingBottom: 24,
  },
  paddingFullWidth: {
    paddingTop: 16,
  },
  addressesTypeContainer: {
    flexBasis: '50%',
    flexGrow: 1,
  },
  fullWidthAddress: {
    flexBasis: '100%',
  },
  addressInformation: {
    flex: 1,
    width: '100%',
  },
  addressesContainer: {
    width: '100%',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
});
