import { GiftedChatIMessage } from '@bas/shared/models';
import { colors } from '@bas/theme';
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons/faLocationDot';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React from 'react';
import Map, { Marker } from 'react-map-gl';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    height: 300,
    width: 300,
  },

  mapView: {
    flex: 1,
    borderRadius: 13,
    margin: 20,
    overflow: 'hidden',
  },
});

const MapView = ({
  currentMessage,
}: {
  currentMessage: GiftedChatIMessage;
}) => {
  if (
    !currentMessage.location ||
    !currentMessage.location.latitude ||
    !currentMessage.location.longitude
  ) {
    return null;
  }

  return (
    <Map
      initialViewState={{
        longitude: currentMessage.location.longitude,
        latitude: currentMessage.location.latitude,
        zoom: 13,
      }}
      style={styles.mapView}
      mapStyle="mapbox://styles/mapbox/streets-v11"
    >
      <Marker
        longitude={currentMessage.location.longitude}
        latitude={currentMessage.location.latitude}
      >
        <FontAwesomeIcon
          size={24}
          icon={faLocationDot}
          color={colors.blue[500]}
        />
      </Marker>
    </Map>
  );
};

export default MapView;
