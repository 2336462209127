import { isObjectResponse } from '@bas/value-objects';
import { QuoteMomentLine } from '../types';

export function isQuoteMomentLine(object: unknown): object is QuoteMomentLine {
  return (
    !!object &&
    isObjectResponse(object) &&
    ['FixedAmountQuoteMomentLine', 'CalculatedQuoteMomentLine'].includes(
      object['@type'],
    )
  );
}
