import { isTask } from './Task';
import type { InvoiceWasNotSyncedTask } from '../types';
import { TaskType } from '../types';

export function isInvoiceWasNotSyncedTask(
  object: unknown,
): object is InvoiceWasNotSyncedTask {
  return (
    !!object &&
    isTask(object) &&
    object.taskType === TaskType.INVOICE_WAS_NOT_SYNCED_TASK
  );
}
