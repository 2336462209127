import { isEvent } from './Event';
import { OtherAbsenceEvent } from '../types';

export function isOtherAbsenceEvent(
  object: unknown,
): object is OtherAbsenceEvent {
  return (
    isEvent(object) && Object.prototype.hasOwnProperty.call(object, 'reason')
  );
}
