import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type RenameVehicleMutationProps = {
  materialId: Uuid;
  name: string;
};

export const RenameVehicleMutation = async ({
  materialId,
  ...values
}: RenameVehicleMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/materials/${materialId}/rename`, {
    materialId,
    ...values,
  });

export const useRenameVehicleMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RenameVehicleMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  RenameVehicleMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RenameVehicleMutationProps
  >({
    mutationFn: RenameVehicleMutation,
    throwOnError: false,
    ...options,
  });
