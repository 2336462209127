import { Button } from '@bas/ui/native/atoms';
import { BottomSheetMethods, Box, Typography } from '@bas/ui/native/base';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import LottieView from 'lottie-react-native';
import { forwardRef, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { ScrollView } from 'react-native-gesture-handler';
import { BottomSheet, BottomSheetProps } from '../BottomSheet';

export type StartWorkingDayBottomSheetProps = Omit<
  BottomSheetProps,
  'snapPoints' | 'children'
> & {
  onStart: () => void;
  onNotNow: () => void;
};

const StartWorkingDayBottomSheet = forwardRef<
  BottomSheetModal & BottomSheetMethods,
  StartWorkingDayBottomSheetProps
>(
  (
    { onStart, onNotNow, ...props }: StartWorkingDayBottomSheetProps,
    ref,
  ): ReactElement => (
    <BottomSheet ref={ref} {...props}>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <Box height="100%" width="100%" paddingBottom={4} gap={3}>
          <Box>
            <Typography
              variant="h4"
              fontWeight={700}
              color="white"
              textAlign="center"
            >
              <FormattedMessage id="label.startWorkingDay" />
            </Typography>
          </Box>
          <Box>
            <LottieView
              autoPlay
              // eslint-disable-next-line global-require
              source={require('./delivery-woman.json')}
              style={{
                height: 400,
              }}
            />
          </Box>
          <Box>
            <Typography color="white">
              <FormattedMessage id="label.startWorkingDay.description" />
            </Typography>
          </Box>
          <Box justifyContent="flex-end" flex={1}>
            <Button onPress={onStart}>
              <FormattedMessage id="label.startWorkingDay.startButton" />
            </Button>
            <Button mt={2} variant="text" color="white" onPress={onNotNow}>
              <FormattedMessage id="button.notNow" />
            </Button>
          </Box>
        </Box>
      </ScrollView>
    </BottomSheet>
  ),
);

export default StartWorkingDayBottomSheet;
