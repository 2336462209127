import { StyleSheet } from 'react-native';
import { colors } from '@bas/theme';

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.lila[100],
  },
  pageHeader: {
    backgroundColor: colors.lila[100],
  },
  avatarContainer: {
    alignSelf: 'center',
  },
  content: {},
  detailsContainer: {
    padding: 20,
    paddingTop: 32,
  },
  link: {
    textDecorationLine: 'underline',
  },
  title: {
    paddingTop: 2,
  },
  label: {
    flexBasis: '41.666667%',
    flexGrow: 1,
  },
  data: {
    flexBasis: '58.333333%',
    flexGrow: 1,
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
});
