import { DocumentType } from '@bas/value-objects';
import * as Yup from 'yup';

export interface UploadDocumentInputType {
  fileId: string;
  documentType: DocumentType;
  fileName: string;
  visibleInApp: boolean;
}

export const uploadDocumentInputTypeDefaultValues =
  (): UploadDocumentInputType => ({
    documentType: DocumentType.CUSTOM,
    fileName: '',
    fileId: '',
    visibleInApp: true,
  });

export const UploadDocumentInputTypeValidationBuilder =
  (): Yup.ObjectSchema<UploadDocumentInputType> =>
    Yup.object({
      fileId: Yup.string().required().label('label.fileId'),
      documentType: Yup.mixed<DocumentType>()
        .oneOf(Object.values(DocumentType))
        .required()
        .label('label.documentType'),
      fileName: Yup.string().label('label.fileName').required(),
      visibleInApp: Yup.boolean().label('label.visibleInApp').required(),
    });
