import { CalculatedTotalsForAcceptanceQuote } from '@bas/financial-domain/models';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type CalculateTotalsForQuoteMutationProps = {
  quoteId: Uuid;
  acceptedLines: Uuid[];
};

type Response = AxiosResponse<CalculatedTotalsForAcceptanceQuote>;

export const CalculateTotalsForQuoteMutation = async ({
  quoteId,
  ...values
}: CalculateTotalsForQuoteMutationProps): Promise<Response> =>
  axios.put(`api/{tenantId}/quotes/${quoteId}/calculate-totals`, {
    quoteId,
    ...values,
  });

export const useCalculateTotalsForQuoteMutation = (
  options: UseMutationOptions<
    Response,
    AxiosError<ErrorResponse>,
    CalculateTotalsForQuoteMutationProps
  > = {},
): UseMutationResult<
  Response,
  AxiosError<ErrorResponse>,
  CalculateTotalsForQuoteMutationProps
> =>
  useMutation<
    Response,
    AxiosError<ErrorResponse>,
    CalculateTotalsForQuoteMutationProps
  >({
    mutationFn: CalculateTotalsForQuoteMutation,
    throwOnError: false,
    ...options,
  });
