import { EmailAddress, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type UpdateAutomatedEmailSettingsMutationProps = {
  automatedEmailId: Uuid;
  name?: string | null;
  enabled: boolean;
  replyEmailAddress: EmailAddress | null | undefined;
  bccEmailAddresses: EmailAddress[];
};

export const UpdateAutomatedEmailSettingsMutation = async ({
  automatedEmailId,
  ...values
}: UpdateAutomatedEmailSettingsMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/communication/automated-emails/${automatedEmailId}/update-settings`,
    {
      automatedEmailId,
      ...values,
    },
  );

export const useUpdateAutomatedEmailSettingsMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateAutomatedEmailSettingsMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  UpdateAutomatedEmailSettingsMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateAutomatedEmailSettingsMutationProps
  >({
    mutationFn: UpdateAutomatedEmailSettingsMutation,
    throwOnError: false,
    ...options,
  });
