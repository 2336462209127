import {
  DayOff,
  isDayOff,
  isTimeEntry,
  TimeEntry,
  TimeOffRegistration,
} from '@bas/hrm-domain/models';
import { useTimeTypesRequest } from '@bas/hrm-domain/requests';
import { TimeTrackingItem, useTimeTrackingStore } from '@bas/shared/state';
import { Box, Typography } from '@bas/ui/native/base';
import { BackendIcon } from '@bas/ui/native/molecules';
import dayjs from 'dayjs';
import * as React from 'react';
import { ReactElement, useMemo } from 'react';

export type TimeTrackingOrEntryEventProps = {
  event: {
    description?: string;
    startDate: Date;
    endDate: Date;
    realEndDate?: Date;
    eventKind?: 'block' | 'standard';
    item?:
      | TimeTrackingItem
      | TimeEntry
      | (DayOff & {
          timeOffRegistration: Omit<TimeOffRegistration, 'daysOff'>;
        });
  };
};

const backgroundColorMapping: { [key: string]: string } = {
  toSubmit: '#f9f4e2',
  open: '#e7ecf3',
  pending: '#e7ecf3',
  rejected: '#F7D8D8',
  approved: '#eaf8ea',
  current: '#F0F5FF',
};

const TimeTrackingOrEntryEvent = ({
  event: { description, startDate, realEndDate, endDate, eventKind, item },
}: TimeTrackingOrEntryEventProps): ReactElement => {
  const { data: timeTypesData } = useTimeTypesRequest();

  const timeTypes = useMemo(
    () => timeTypesData?.data?.member || [],
    [timeTypesData?.data],
  );

  let timeType = null;
  if (item && !isTimeEntry(item) && !isDayOff(item)) {
    timeType = timeTypes.find(
      ({ timeTypeId }) => item.timeTypeId === timeTypeId,
    );
  }

  const getActiveItem = useTimeTrackingStore((state) => state.getActiveItem);

  let status = 'toSubmit';
  if (item && isTimeEntry(item)) {
    status = item.status;
  } else if (item && isDayOff(item)) {
    status = item.timeOffRegistration.status;
  } else if (item) {
    if (item?.start && dayjs(item?.start).isSame(dayjs(), 'day')) {
      if (getActiveItem(item.employeeId)?.timeEntryId === item.timeEntryId) {
        status = 'current';
      }
    }
  }

  if (eventKind === 'block') {
    return (
      <Box
        height="100%"
        width={1000000}
        right={-100}
        left={-500}
        backgroundColor="lila.200"
        opacity={0.4}
      />
    );
  }

  return (
    <Box
      height="100%"
      width="100%"
      style={{
        backgroundColor:
          backgroundColorMapping[status] || backgroundColorMapping.toSubmit,
      }}
      overflow="hidden"
      borderRadius={8}
      paddingHorizontal={2}
      paddingVertical={0.5}
    >
      {isTimeEntry(item) && (
        <Box flexDirection="row" alignItems="center">
          {item.timeTypeIcon && (
            <BackendIcon icon={item.timeTypeIcon} size={16} />
          )}
          <Typography>
            {item.timeTypeIcon && ' '}
            {item.description && `${item.description} - `}
            {item.timeTypeName}
          </Typography>
        </Box>
      )}
      {isDayOff(item) && (
        <Box flexDirection="row" alignItems="center">
          {item.timeOffRegistration.timeOffTypeIcon && (
            <BackendIcon
              icon={item.timeOffRegistration.timeOffTypeIcon}
              size={16}
            />
          )}
          <Typography>
            {item.timeOffRegistration.timeOffTypeIcon && ' '}
            {item.timeOffRegistration.reason &&
              `${item.timeOffRegistration.reason} - `}
            {item.timeOffRegistration.timeOffTypeName}
          </Typography>
        </Box>
      )}
      {!isTimeEntry(item) && !isDayOff(item) && (
        <Box flexDirection="row" alignItems="center">
          {timeType?.icon && <BackendIcon icon={timeType.icon} size={16} />}
          <Typography>
            {timeType?.icon && ' '}
            {timeType?.name || description}
          </Typography>
        </Box>
      )}
      <Typography variant="subtitle2" pt={0.2}>
        {dayjs(startDate).format('LT')}
        {' - '}
        {dayjs(realEndDate || endDate).format('LT')}
      </Typography>
    </Box>
  );
};

export default TimeTrackingOrEntryEvent;
