import { HoursEntryType } from '@bas/hrm-domain/models';
import { formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type EnterHoursForEventMutationProps = {
  employeeId: Uuid;
  reason?: string | null;
  startDate: Date;
  endDate: Date;
  breakTime: number | null;
  projectId?: Uuid | null;
  eventId: Uuid;
  entryId: Uuid;
  approved?: boolean;
  approvedById?: Uuid | null;
  travelStartDate?: Date | null;
  travelEndDate?: Date | null;
};

export const EnterHoursForEventMutation = async ({
  employeeId,
  eventId,
  startDate,
  endDate,
  projectId,
  travelStartDate,
  travelEndDate,
  ...values
}: EnterHoursForEventMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/{tenantId}/hrm/employees/${employeeId}/enter-hours`, {
    employeeId,
    startDate: formatDateTime(startDate),
    endDate: formatDateTime(endDate),
    travelStartDate: travelStartDate ? formatDateTime(travelStartDate) : null,
    travelEndDate: travelEndDate ? formatDateTime(travelEndDate) : null,
    entryData: {
      entryType: projectId
        ? HoursEntryType.PROJECT_HOURS_ENTRY
        : HoursEntryType.EVENT_HOURS_ENTRY,
      eventId,
      projectId,
    },
    ...values,
  });

export const useEnterHoursForEventMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    EnterHoursForEventMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  EnterHoursForEventMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    EnterHoursForEventMutationProps
  >({
    mutationFn: EnterHoursForEventMutation,
    throwOnError: false,
    ...options,
  });
