import { Event } from '@bas/planning-domain/models';
import { useTravelTimeForEventByEventIdRequest } from '@bas/planning-domain/requests';
import { isMovingJob, Project } from '@bas/project-domain/models';
import { MovingJobAddressBlock } from '@bas/project-domain/moving-job/native/molecules';
import { colors } from '@bas/theme';
import { AddressBlock, EventIcon, Skeleton } from '@bas/ui/native/atoms';
import {
  TouchableOpacity,
  TouchableWithoutFeedbackProps,
  Typography,
} from '@bas/ui/native/base';
import { IdentityIndicator } from '@bas/ui/native/molecules';
import {
  EventType,
  showEventLocation,
  showEventName,
} from '@bas/value-objects';
import { faTruckRampCouch } from '@fortawesome/pro-light-svg-icons/faTruckRampCouch';
import { faWarehouseAlt } from '@fortawesome/pro-light-svg-icons/faWarehouseAlt';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import dayjs from 'dayjs';
import { ReactElement, useMemo, useState } from 'react';
import { FormattedMessage, FormattedTime } from 'react-intl';
import { View } from 'react-native';
import styles from './styles';

const ignoreEventTimeEventTypes = [
  EventType.MOVING_EVENT,
  EventType.LOAD_STORAGE_EVENT,
  EventType.UNLOAD_STORAGE_EVENT,
  EventType.MOVING_LOADING_EVENT,
  EventType.MOVING_UNLOADING_EVENT,
];

export type EventListItemProps = TouchableWithoutFeedbackProps & {
  planningEvent: Event;
  project?: Project;
  timeMarginBeforeEventInMinutes?: number;
};

const EventListItem = ({
  planningEvent,
  project,
  timeMarginBeforeEventInMinutes,
  style,
  ...props
}: EventListItemProps): ReactElement => {
  const [isPressing, setIsPressing] = useState<boolean>(false);
  const color = useMemo(
    () => (isPressing ? colors.blue[500] : colors.lila[800]),
    [isPressing],
  );

  const showTime = !ignoreEventTimeEventTypes.includes(planningEvent.eventType);
  const showLocation = showEventLocation(planningEvent.eventType);
  const isLoadingOrUnloadingEvent = [
    EventType.LOAD_STORAGE_EVENT,
    EventType.UNLOAD_STORAGE_EVENT,
    EventType.MOVING_LOADING_EVENT,
    EventType.MOVING_UNLOADING_EVENT,
  ].includes(planningEvent.eventType);
  const { data: travelTimeData, isInitialLoading: isLoading } =
    useTravelTimeForEventByEventIdRequest(
      {
        eventId: planningEvent.eventId,
      },
      {
        enabled: !planningEvent.travelStart,
        staleTime: Infinity,
      },
    );

  const wareHouseTime = useMemo(() => {
    let result = null;
    const calculateTravel = !(
      planningEvent.location.zipCode ===
        planningEvent.departureLocation.zipCode &&
      [EventType.OFFICE_WORK_EVENT, EventType.WAREHOUSE_WORK_EVENT].includes(
        planningEvent.eventType,
      )
    );
    if (planningEvent.travelStart) {
      result = dayjs(planningEvent.travelStart);
    } else if (calculateTravel) {
      result = dayjs(planningEvent.start).subtract(
        travelTimeData?.data?.durationToEventInSeconds || 0,
        'seconds',
      );

      if (planningEvent.eventType !== EventType.INTAKE_EVENT) {
        result = result.subtract(
          timeMarginBeforeEventInMinutes || 0,
          'minutes',
        );
      }

      const remainder = Math.floor(result.minute() / 15) * 15;
      result = result.set('minutes', remainder);
    }
    return result;
  }, [
    planningEvent.departureLocation.zipCode,
    planningEvent.eventType,
    planningEvent.location.zipCode,
    planningEvent.start,
    planningEvent.travelStart,
    timeMarginBeforeEventInMinutes,
    travelTimeData?.data?.durationToEventInSeconds,
  ]);

  return (
    <TouchableOpacity
      onPressIn={() => setIsPressing(true)}
      onPressOut={() => setIsPressing(false)}
      {...props}
    >
      <View style={[styles.container, style]}>
        <View
          style={[
            styles.eventColor,
            planningEvent.finished && styles.finishedEventColor,
          ]}
        />
        <View style={styles.eventIconContainer}>
          <EventIcon
            eventType={planningEvent.eventType}
            size={24}
            color={color}
          />
        </View>
        <View style={styles.eventInfoContainer}>
          <IdentityIndicator
            identityId={project?.identityId}
            size={6}
            disableSpacing
            indicatorStyle={{
              position: 'absolute',
              left: -10,
              top: -3,
            }}
          >
            <View style={styles.eventNameRow}>
              <Typography
                fontWeight={700}
                overrideColor={color}
                style={styles.eventName}
              >
                {showEventName(planningEvent.eventType) ? (
                  planningEvent.name.trim()
                ) : (
                  <FormattedMessage
                    id={`mobileApp.event.eventType.${planningEvent.eventType}`}
                  />
                )}
              </Typography>
              {showTime && (
                <>
                  <Typography
                    style={styles.eventNameTime}
                    overrideColor={color}
                  >
                    <FormattedMessage
                      id="label.startsAt"
                      values={{
                        time: (
                          <FormattedTime
                            value={planningEvent.start}
                            hour="2-digit"
                            minute="2-digit"
                          />
                        ),
                      }}
                    />
                  </Typography>
                  <Typography overrideColor={color}>
                    {isLoading && <Skeleton width={100} />}
                    {!isLoading && wareHouseTime && (
                      <FormattedMessage
                        id="label.leaveAt"
                        values={{
                          time: (
                            <FormattedTime
                              value={wareHouseTime.toDate()}
                              hour="2-digit"
                              minute="2-digit"
                            />
                          ),
                        }}
                      />
                    )}
                  </Typography>
                </>
              )}
            </View>
          </IdentityIndicator>
          <View style={styles.paddingTop2}>
            {!isLoadingOrUnloadingEvent &&
              showLocation &&
              planningEvent.location && (
                <AddressBlock color={color} address={planningEvent.location} />
              )}
            {(!showLocation || isLoadingOrUnloadingEvent) && (
              <View>
                {project &&
                  isMovingJob(project) &&
                  !isLoadingOrUnloadingEvent && (
                    <MovingJobAddressBlock color={color} project={project} />
                  )}
                {project &&
                  isMovingJob(project) &&
                  isLoadingOrUnloadingEvent && (
                    <AddressBlock
                      color={color}
                      address={planningEvent.location}
                    />
                  )}
                <View style={[styles.timeContainer, styles.paddingTop2]}>
                  <FontAwesomeIcon color={color} icon={faWarehouseAlt} />
                  <Typography overrideColor={color} style={styles.firstTime}>
                    {isLoading && <Skeleton width={100} />}
                    {!isLoading && wareHouseTime && (
                      <FormattedTime
                        value={wareHouseTime.toDate()}
                        hour="2-digit"
                        minute="2-digit"
                      />
                    )}
                  </Typography>
                  <FontAwesomeIcon color={color} icon={faTruckRampCouch} />
                  <Typography overrideColor={color} style={styles.secondTime}>
                    <FormattedTime
                      value={dayjs(planningEvent.start).toDate()}
                      hour="2-digit"
                      minute="2-digit"
                    />
                  </Typography>
                </View>
              </View>
            )}
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};
export default EventListItem;
