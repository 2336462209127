import {
  CreateInvoiceInputType,
  CreateInvoiceInputTypeDefaultValues,
} from '@bas/financial-domain/input-types';
import { Invoice } from '@bas/financial-domain/models';
import { PayAtCustomerPaymentInputType } from '@bas/planning-domain/input-types';
import { Project } from '@bas/project-domain/models';
import { BillingMomentType } from '@bas/shared/models';
import { colors } from '@bas/theme';
import { BottomSheetMethods, Typography } from '@bas/ui/native/base';
import {
  BottomSheetProps,
  StepsNavigationProps,
} from '@bas/ui/native/organisms';
import { FormWizardBottomSheet } from '@bas/ui/native/templates';
import { ReactHookWizardStep } from '@bas/value-objects';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { memo, ReactElement, ReactNode, RefObject, useCallback } from 'react';
import isEqual from 'react-fast-compare';
import { FieldValues } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import styles from './styles';

type InputType = {
  invoice: CreateInvoiceInputType;
  billingMomentType: BillingMomentType;
} & PayAtCustomerPaymentInputType &
  FieldValues;

export type PayAtCustomerFormWizardBottomSheetProps = Omit<
  BottomSheetProps,
  'snapPoints' | 'children'
> & {
  onSubmit: (values: InputType) => void;
  onClose?: () => void;
  steps: ReactHookWizardStep<InputType>[];
  bottomSheetRef: RefObject<BottomSheetModal & BottomSheetMethods>;
  project: Project;
  generatedInvoice?: Invoice;
  billingMomentType: BillingMomentType;
};

const PayAtCustomerFormWizardBottomSheet = ({
  project,
  onSubmit,
  onClose,
  steps,
  generatedInvoice,
  billingMomentType,
  bottomSheetRef,
}: PayAtCustomerFormWizardBottomSheetProps): ReactElement => {
  const renderTitle = useCallback(
    (data: unknown, title: ReactNode) => (
      <Typography
        variant="h4"
        fontWeight={700}
        overrideColor={colors.white}
        style={styles.title}
      >
        {typeof title === 'string' ? <FormattedMessage id={title} /> : title}
      </Typography>
    ),
    [],
  );

  const defaultValues = generatedInvoice
    ? {
        invoice: {
          ...CreateInvoiceInputTypeDefaultValues(),
          identityId: generatedInvoice.identityId,
          contactPersonId: generatedInvoice.contactPerson?.contactPersonId,
          addressId: generatedInvoice.address?.addressId,
          templateId: generatedInvoice.templateId,
          includingVat: generatedInvoice.includingVat,
          lines: generatedInvoice.lines.map((line) => ({
            ...line,
            created: true,
          })),
          ignoreZeroQuantityLines: project.package.ignoreZeroQuantityLines,
          totalIncludingVat: generatedInvoice.totalIncludingVat,
          reference: generatedInvoice.reference,
          expiresAt: generatedInvoice.expiresAt,
          invoicedAt: generatedInvoice.invoicedAt,
          shouldChargeVat: generatedInvoice.shouldChargeVat,
          freeText:
            (Array.isArray(generatedInvoice.freeText)
              ? { ...generatedInvoice.freeText }
              : generatedInvoice.freeText) || {},
          relation: project.customer,
        },
        billingMomentType,
      }
    : ({} as InputType);

  return (
    <FormWizardBottomSheet<
      { stepNavigationProps: Partial<StepsNavigationProps> },
      InputType,
      unknown
    >
      name="pay-at-customer"
      wizardSteps={steps}
      initialValues={defaultValues}
      onSubmit={onSubmit}
      initialStepIndex={0}
      resetFormOnOpen
      fullWidth
      successFullFormViewProps={{
        gifUrl:
          'https://media.istockphoto.com/id/1271311350/vector/thank-you-ink-brush-vector-lettering-thank-you-modern-phrase-handwritten-vector-calligraphy.jpg?s=612x612&w=0&k=20&c=k50bI-VIGLno48xtby-RWr4TcFNUp-K38VMkn59_Cfw=',
        title: <FormattedMessage id="label.payedAtCustomer.finished" />,
        color: colors.lila[100],
      }}
      extraTemplateProps={{
        stepNavigationProps: {
          dark: true,
          allowCancel: true,
          onCancel: onClose,
          onClose,
        },
      }}
      renderTitle={renderTitle}
      bottomSheetRef={bottomSheetRef}
    />
  );
};

const MemoComponent = memo(
  PayAtCustomerFormWizardBottomSheet,
  isEqual,
) as typeof PayAtCustomerFormWizardBottomSheet;
export default MemoComponent;
