import { EventDtoInputType } from '@bas/planning-domain/input-types';
import { formatDate, formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type RescheduleProjectMutationProps = {
  projectId: Uuid;
  events: EventDtoInputType[];
};

export const RescheduleProjectMutation = async ({
  projectId,
  events,
  ...values
}: RescheduleProjectMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/projects/${projectId}/reschedule-events`, {
    projectId,
    events: events.map(
      ({
        start,
        end,
        travelStart,
        travelEnd,
        recurringInformation,
        date,
        ...event
      }) => ({
        ...event,
        start: formatDateTime(start),
        end: formatDateTime(end),
        travelStart: travelStart ? formatDateTime(travelStart) : null,
        travelEnd: travelEnd ? formatDateTime(travelEnd) : null,
        date: date ? formatDate(date) : null,
        recurringInformation: recurringInformation
          ? {
              ...recurringInformation,
              repeatFrom: formatDate(recurringInformation.repeatFrom),
              repeatUntil: formatDate(recurringInformation.repeatUntil),
            }
          : null,
      }),
    ),
    ...values,
  });

export const useRescheduleProjectMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RescheduleProjectMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  RescheduleProjectMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RescheduleProjectMutationProps
  >({
    mutationFn: RescheduleProjectMutation,
    throwOnError: false,
    ...options,
  });
