import { isObjectResponse } from '@bas/value-objects';
import { FixedAmountQuoteMomentLine } from '../types';
import { isQuoteMomentLine } from './QuoteMomentLine';

export function isFixedAmountQuoteMomentLine(
  object: unknown,
): object is FixedAmountQuoteMomentLine {
  return (
    !!object &&
    isObjectResponse(object) &&
    isQuoteMomentLine(object) &&
    object['@type'] === 'FixedAmountQuoteMomentLine'
  );
}
