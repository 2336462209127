import { ClaAllowanceRuleType } from '@bas/hrm-domain/models';
import { formatDate } from '@bas/shared/utils';
import { EmployeeContractType, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ChangeClaAllowanceRuleMutationProps = {
  ruleId: Uuid;
  name: string;
  description: string;
  contractType: EmployeeContractType;
  employeeType: 'field-staff' | 'backoffice-staff' | 'all';
  attributeId: Uuid | undefined;
  excludedAttributes: Uuid[];
  percentage: number;
  enabledFrom: Date;
  ruleData:
    | {
        ruleType: ClaAllowanceRuleType.ClaOvertime;
        whichDays: ('mo' | 'tu' | 'we' | 'th' | 'fr' | 'sa' | 'su')[];
        overtimeThresholdHours: number;
        calculatePer: 'day' | 'week';
      }
    | {
        ruleType: ClaAllowanceRuleType.ClaWeekendAndPublicHoliday;
        whichDays: ('mo' | 'tu' | 'we' | 'th' | 'fr' | 'sa' | 'su')[];
        onlyOnPublicHolidays: boolean;
      }
    | {
        ruleType: ClaAllowanceRuleType.ClaNightWork;
        fromTime: number;
        untilTime: number;
      };
};

export const ChangeClaAllowanceRuleMutation = async ({
  enabledFrom,
  ruleId,
  ...values
}: ChangeClaAllowanceRuleMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/hrm/cla/allowance-rules/${ruleId}`, {
    ...values,
    enabledFrom: formatDate(enabledFrom),
  });

export const useChangeClaAllowanceRuleMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeClaAllowanceRuleMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ChangeClaAllowanceRuleMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeClaAllowanceRuleMutationProps
  >({
    mutationFn: ChangeClaAllowanceRuleMutation,
    throwOnError: false,
    ...options,
  });
