import { isQuoteEvent } from './QuoteEvent';
import { QuoteEventType, QuoteReminderSentEvent } from '../types';

export function isQuoteReminderSentEvent(
  object: unknown,
): object is QuoteReminderSentEvent {
  return (
    isQuoteEvent(object) &&
    object.eventType === QuoteEventType.QuoteReminderSentEvent
  );
}
