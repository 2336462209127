import { isObjectResponse } from '@bas/value-objects';
import { SigningMoment } from '../types';

export function isSigningMoment(object: unknown): object is SigningMoment {
  return (
    !!object &&
    isObjectResponse(object) &&
    ['SigningMoment'].includes(object['@type'])
  );
}
