import {
  Checkbox,
  RectangularTile,
  RectangularTileProps,
} from '@bas/ui/native/atoms';
import { Box, Typography, TypographyProps } from '@bas/ui/native/base';
import { cloneElement, isValidElement, ReactElement, ReactNode } from 'react';
import styles from './styles';

export type RectangularCheckboxTileProps = Omit<
  RectangularTileProps,
  'children'
> & {
  selected: boolean;
  label: string | ReactNode;
  onCheck: () => void;
};

const color = 'darkText';

const RectangularCheckboxTile = ({
  selected,
  label,
  onCheck,
  ...props
}: RectangularCheckboxTileProps): ReactElement => (
  <RectangularTile {...props}>
    <Box
      flexDirection="row"
      alignItems="center"
      justifyContent="flex-start"
      flex={1}
    >
      <Checkbox checked={selected} size={30} desktop onCheck={onCheck} />
      <Box style={styles.label} pl={2}>
        {isValidElement(label) ? (
          cloneElement(label as ReactElement<TypographyProps>, {
            color,
            fontWeight: 700,
            variant: 'h5',
          })
        ) : (
          <Typography fontWeight={700} color={color} variant="h5">
            {label}
          </Typography>
        )}
      </Box>
    </Box>
  </RectangularTile>
);

export default RectangularCheckboxTile;
