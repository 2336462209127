import {
  CreateRelationInputType,
  CreateRelationInputTypeDefaultValues,
  CreateRelationInputTypeValidationBuilder,
} from '@bas/crm-domain/input-types';
import { Uuid } from '@bas/value-objects';
import * as Yup from 'yup';

export type NewProjectCustomerInputType = (
  | {
      existingCustomer: 'new' | 'existing' | null;
      newRelation?: Yup.AnyObject;
      existingRelation?: Yup.AnyObject;
    }
  | {
      existingCustomer: 'new' | null;
      newRelation: CreateRelationInputType | null;
      existingRelation?: Yup.AnyObject;
    }
  | {
      existingCustomer: 'existing' | null;
      existingRelation?: { relationId: Uuid } | null;
      newRelation?: Yup.AnyObject;
    }
) & {
  identityId?: Uuid | null;
  useIdentities?: boolean;
};

export const NewProjectCustomerInputTypeDefaultValues =
  (): NewProjectCustomerInputType => ({
    existingRelation: null,
    newRelation: CreateRelationInputTypeDefaultValues(),
    existingCustomer: null,
    identityId: '',
  });

export const NewProjectCustomerInputTypeValidationBuilder =
  (): Yup.ObjectSchema<NewProjectCustomerInputType> =>
    Yup.object({
      identityId: Yup.string().when('useIdentities', {
        is: true,
        then: (schema) => schema.required().label('label.identityId'),
      }),
      useIdentities: Yup.boolean(),
      existingCustomer: Yup.string()
        .oneOf(['existing', 'new'])
        .required()
        .label('label.existingCustomer'),
      existingRelation: Yup.object().when('existingCustomer', {
        is: 'existing',
        then: (schema) =>
          schema
            .shape({
              relationId: Yup.string().required().label('label.customer'),
            })
            .nullable()
            .required()
            .label('label.customer'),
        otherwise: (schema) => schema.nullable(),
      }),
      newRelation: Yup.object().when('existingCustomer', {
        is: 'new',
        then: (schema) =>
          schema.concat(CreateRelationInputTypeValidationBuilder()),
        otherwise: (schema) => schema.nullable(),
      }),
    });
