import { isTask } from './Task';
import type { HandleDamageTask } from '../types';
import { TaskType } from '../types';

export function isHandleDamageTask(
  object: unknown,
): object is HandleDamageTask {
  return (
    !!object &&
    isTask(object) &&
    object.taskType === TaskType.HANDLE_DAMAGE_TASK
  );
}
