import { EmployeeRole } from '@bas/hrm-domain/models';
import * as Yup from 'yup';

export interface EmployeeRolesInputType {
  employeeRoles: EmployeeRole[];
}

export const EmployeeRolesInputTypeDefaultValues =
  (): EmployeeRolesInputType => ({
    employeeRoles: [],
  });

export const EmployeeRolesInputTypeValidatorBuilder =
  (): Yup.ObjectSchema<EmployeeRolesInputType> =>
    Yup.object({
      employeeRoles: Yup.array()
        .of(
          Yup.string()
            .required()
            .oneOf([...Object.values(EmployeeRole)])
            .label('label.role'),
        )
        .required()
        .min(1)
        .label('label.roles'),
    });
