import { StyleSheet } from 'react-native';
import { colors } from '@bas/theme';

export default StyleSheet.create({
  step: {
    height: 18,
    width: 18,
    borderRadius: 18,
    backgroundColor: colors.lila[200],
  },
  progressContainer: {
    marginLeft: -4,
    marginTop: -4,
  },
});
