import { EmailAddress } from '@bas/value-objects';
import * as Yup from 'yup';

export interface SendQuoteInputType {
  expiresAt?: Date;
  extraEmailAddresses: EmailAddress[];
  resend?: boolean;
}

export const SendQuoteInputTypeDefaultValues = (): SendQuoteInputType => ({
  extraEmailAddresses: [],
  resend: false,
});

export const SendQuoteInputTypeValidationBuilder =
  (): Yup.ObjectSchema<SendQuoteInputType> =>
    Yup.object({
      extraEmailAddresses: Yup.array()
        .of(Yup.string().email().required().label('label.emailAddress'))
        .required(),
      expiresAt: Yup.date().label('label.expiresAt'),
      resend: Yup.boolean().label('label.resend'),
    });
