import { ErrorResponse, Language, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type UpdateDocumentTemplateTranslationMutationProps = {
  documentTemplateId: Uuid;
  language: Language;
  body: string;
  html: string;
  css: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  config: any;
};

export const UpdateDocumentTemplateTranslationMutation = async ({
  documentTemplateId,
  ...values
}: UpdateDocumentTemplateTranslationMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/document-templates/${documentTemplateId}/update-translation`,
    {
      documentTemplateId,
      ...values,
    },
  );

export const useUpdateDocumentTemplateTranslationMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateDocumentTemplateTranslationMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  UpdateDocumentTemplateTranslationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateDocumentTemplateTranslationMutationProps
  >({
    mutationFn: UpdateDocumentTemplateTranslationMutation,
    throwOnError: false,
    ...options,
  });
