import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type RemoveNewsMutationProps = {
  newsId: Uuid;
};

export const RemoveNewsMutation = async ({
  newsId,
  ...values
}: RemoveNewsMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/news/${newsId}/remove`, {
    newsId,
    ...values,
  });

export const useRemoveNewsMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveNewsMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  RemoveNewsMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveNewsMutationProps
  >({
    mutationFn: RemoveNewsMutation,
    throwOnError: false,
    ...options,
  });
