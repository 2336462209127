import {
  BillingMomentInputType,
  QuoteMomentInputType,
} from '@bas/tenant-domain/input-types';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

const mapPeriod: {
  [key: string]: string;
} = {
  direct: '1 second',
  fiveMinutes: '5 minutes',
  hour: '1 hour',
  day: '1 day',
  week: '1 week',
  twoWeeks: '2 weeks',
  month: '1 month',
};

export type ChangeBillingMomentsOfPackageMutationProps = {
  packageId: Uuid;
  billingMoments: BillingMomentInputType[];
  quoteMoment: QuoteMomentInputType | undefined;
};

export const ChangeBillingMomentsOfPackageMutation = async ({
  packageId,
  billingMoments,
  ...values
}: ChangeBillingMomentsOfPackageMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/packages/${packageId}/change-billing-moments`, {
    packageId,
    billingMoments: billingMoments.map(
      ({ period, lineType, invoiceLines, ...billingMoment }) => ({
        ...billingMoment,
        period: mapPeriod[period] || period,
        lineType,
        invoiceLines: invoiceLines.map((line) => ({
          ...line,
          lineType,
          translations: [],
        })),
      }),
    ),
    ...values,
  });

export const useChangeBillingMomentsOfPackageMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeBillingMomentsOfPackageMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ChangeBillingMomentsOfPackageMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeBillingMomentsOfPackageMutationProps
  >({
    mutationFn: ChangeBillingMomentsOfPackageMutation,
    throwOnError: false,
    ...options,
  });
