import { ViewProps } from 'react-native';
import Animated, { AnimateProps } from 'react-native-reanimated';
import { ReactElement } from 'react';

const AnimatedView = ({
  children,
  ...props
}: AnimateProps<ViewProps>): ReactElement => (
  <Animated.View {...props}>{children}</Animated.View>
);

export default AnimatedView;
