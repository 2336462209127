import { useTenantStore } from '@bas/shared/state';
import { useIdentitiesRequest } from '@bas/tenant-domain/requests';
import { colors } from '@bas/theme';
import { Tooltip } from '@bas/ui/native/atoms';
import { Box, Typography } from '@bas/ui/native/base';
import { Uuid } from '@bas/value-objects';
import { ReactElement, useMemo } from 'react';
import { StyleProp, View, ViewProps } from 'react-native';
import { ViewStyle } from 'react-native/Libraries/StyleSheet/StyleSheetTypes';

export type IdentityIndicatorProps = ViewProps & {
  identityId?: Uuid;
  full?: boolean;
  disableSpacing?: boolean;
  size?: 'xs' | 'small' | 'medium' | number;
  indicatorStyle?: StyleProp<ViewStyle> | undefined;
};

const IdentityIndicator = ({
  identityId,
  style,
  size = 'small',
  full,
  disableSpacing,
  children,
  indicatorStyle,
  ...args
}: IdentityIndicatorProps): ReactElement | null => {
  const tenantState = useTenantStore((state) => state.tenant);

  const { data: identitiesData } = useIdentitiesRequest(
    {},
    {
      enabled: tenantState?.useIdentities,
    },
  );

  const identities = useMemo(
    () => identitiesData?.data?.member || [],
    [identitiesData?.data],
  );

  const identity = useMemo(() => {
    if (!identityId || !tenantState?.useIdentities) {
      return undefined;
    }

    return identities.find((id) => id.identityId === identityId);
  }, [identities, identityId, tenantState?.useIdentities]);

  const sizePixels = useMemo(() => {
    if (typeof size === 'number' && !Number.isNaN(size)) {
      return size as number;
    }

    if (size === 'xs') {
      return 4;
    }

    if (size === 'small') {
      return 8;
    }

    return 12;
  }, [size]);

  if (!identity) {
    return <View style={style}>{children}</View>;
  }

  return (
    <Box
      flexDirection="row"
      alignItems="center"
      style={[style, { gap: disableSpacing ? 0 : 8 }]}
    >
      <Tooltip
        popover={
          <Typography overrideColor={colors.white}>
            {identity.companyInformation.companyName}
          </Typography>
        }
        disabled={full}
      >
        <Box
          {...args}
          style={[
            indicatorStyle,
            {
              backgroundColor: identity.color,
              borderRadius: sizePixels / 2,
              width: sizePixels,
              height: sizePixels,
            },
          ]}
        />
      </Tooltip>
      <Box flexGrow={1} flexShrink={0} flexBasis="100%">
        {full ? (
          <Typography>{identity.companyInformation.companyName}</Typography>
        ) : (
          children
        )}
      </Box>
    </Box>
  );
};

export default IdentityIndicator;
