import { MovingJobSkill, OfficeSkill } from '@bas/hrm-domain/models';
import * as Yup from 'yup';

export interface EmployeeSkillsInputType {
  employeeSkills: (OfficeSkill | MovingJobSkill)[];
}

export const EmployeeSkillsInputTypeDefaultValues =
  (): EmployeeSkillsInputType => ({
    employeeSkills: [],
  });

export const EmployeeSkillsInputTypeValidatorBuilder =
  (): Yup.ObjectSchema<EmployeeSkillsInputType> =>
    Yup.object({
      employeeSkills: Yup.array()
        .of(
          Yup.string()
            .required()
            .oneOf([
              ...Object.values(OfficeSkill),
              ...Object.values(MovingJobSkill),
            ])
            .label('label.skill'),
        )
        .required()
        .min(1)
        .label('label.skills'),
    });
