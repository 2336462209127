import { ListedEmployee } from '@bas/hrm-domain/models';
import { useEmployeesRequest } from '@bas/hrm-domain/requests';
import { useProjectByProjectIdRequest } from '@bas/project-domain/requests';
import { AddressBlock, Skeleton } from '@bas/ui/native/atoms';
import { BottomSheetMethods, Box, Typography } from '@bas/ui/native/base';
import { BottomSheet, BottomSheetProps } from '@bas/ui/native/organisms';
import { Uuid } from '@bas/value-objects';
import { StoredItem } from '@bas/wms-domain/models';
import {
  useStorageByStorageIdRequestQuery,
  useStoredItemsRequest,
  useWarehouseByWarehouseIdRequestQuery,
} from '@bas/wms-domain/requests';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { FlashList } from '@shopify/flash-list';
import { ListRenderItem } from '@shopify/flash-list/src/FlashListProps';
import dayjs from 'dayjs';
import * as React from 'react';
import { ReactElement, RefObject, useCallback, useMemo } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

export type ShowStorageBottomSheetProps = Omit<
  BottomSheetProps,
  'snapPoints' | 'children'
> & {
  bottomSheetRef?: RefObject<
    BottomSheetModal<{ storageId: Uuid }> & BottomSheetMethods
  >;
};

const ShowStorageBottomSheetContent = ({
  storageId,
}: {
  storageId: Uuid;
}): ReactElement => {
  const { data: storageData } = useStorageByStorageIdRequestQuery({
    storageId,
  });

  const storage = useMemo(() => {
    if (storageData?.data === undefined) {
      return null;
    }

    return storageData?.data;
  }, [storageData?.data]);

  const { data: projectData } = useProjectByProjectIdRequest(
    {
      projectId: storage?.storedForProjectId || '',
    },
    {
      enabled: !!storage?.storedForProjectId,
    },
  );

  const project = useMemo(() => {
    if (projectData?.data === undefined) {
      return null;
    }

    return projectData?.data;
  }, [projectData?.data]);

  const { data: warehouseData, isPending: isLoadingWarehouse } =
    useWarehouseByWarehouseIdRequestQuery(
      {
        warehouseId: storage?.warehouseId || '',
      },
      {
        enabled: !!storage?.warehouseId,
      },
    );

  const warehouse = useMemo(() => {
    if (warehouseData?.data === undefined) {
      return null;
    }

    return warehouseData?.data;
  }, [warehouseData?.data]);

  const {
    data: storedItemsData,
    isLoading,
    isFetching,
    refetch,
  } = useStoredItemsRequest({
    storageId,
    loadedOnly: true,
  });

  const storedItems = useMemo(
    () => storedItemsData?.data?.member || [],
    [storedItemsData?.data],
  );

  const { data: employeesData } = useEmployeesRequest({
    perPage: 9999,
    activeEmploymentOnly: false,
  });

  const employees = useMemo(
    () => employeesData?.data?.member || [],
    [employeesData],
  );

  const loadedItems = useMemo(
    () => storedItems.filter((s) => !!s.loadedOn),
    [storedItems],
  );

  const handleRenderStoredItem: ListRenderItem<StoredItem> = useCallback(
    ({ item, extraData }) => {
      const employee = extraData.employees.find(
        (e: ListedEmployee) => e.employeeId === item.loadedByEmployeeId,
      );
      return (
        <Box
          flexDirection="row"
          flexWrap="nowrap"
          gap={2}
          width="100%"
          alignItems="center"
          paddingVertical={1}
          borderBottomWidth={1}
          borderBottomColor="transparentBorder"
        >
          <Box flex={0.3}>
            <Typography color="white">{`${item.description || ''} ${
              item.externalItemCode || ''
            } ${item.itemCode || ''}`}</Typography>
          </Box>
          <Box flex={0.3}>
            {!item.loadedOn || item.unloadedOn ? (
              <Typography color="white">
                <FormattedMessage id="label.itemIsNotInStorage" />
              </Typography>
            ) : (
              <Typography color="white">
                {item.storageLocationId ? (
                  `${item.storageLocationName} (${item.storageLocationCode})`
                ) : (
                  <FormattedMessage id="label.itemIsInStorage" />
                )}
              </Typography>
            )}
          </Box>
          <Box flex={0.2}>
            {item.loadedByEmployeeId && (
              <Typography color="white">
                {employee?.personName?.fullName}
              </Typography>
            )}
            {item.loadedByCustomer && (
              <Typography color="white">
                <FormattedMessage id="label.loadedByCustomer" />
              </Typography>
            )}
            {!item.loadedByEmployeeId && !item.loadedByCustomer && (
              <Typography color="white">
                <FormattedMessage id="label.unknown" />
              </Typography>
            )}
          </Box>
          <Box flex={0.2}>
            {item.loadedOn && (
              <Typography color="white">
                <FormattedDate
                  value={dayjs(item.loadedOn).toDate()}
                  dateStyle="short"
                  timeStyle="short"
                />
              </Typography>
            )}
          </Box>
        </Box>
      );
    },
    [],
  );

  return (
    <Box flex={1} paddingHorizontal={20} gap={3}>
      <Box flexDirection="row" flexWrap="wrap">
        <Box width="100%" pb={3}>
          <Typography
            color="white"
            variant="h4"
            fontWeight={700}
            textAlign="center"
          >
            {storage?.storageCode}
          </Typography>
          <Typography variant="h5" textAlign="center" color="white">
            {storage?.contentOwnerName}
          </Typography>
        </Box>
        <Box width="50%">
          <Typography variant="subtitle1" color="white">
            <FormattedMessage id="label.storageInformation" />
          </Typography>
          <Typography color="white">{storage?.contentOwnerName}</Typography>
          <Typography color="white">
            <FormattedMessage id={`storageTypes.${storage?.storageType}`} />
          </Typography>
          <Typography color="white">
            <FormattedMessage id="label.sealNumber" />:{' '}
            {storage?.sealNumber || <FormattedMessage id="label.notSealed" />}
          </Typography>
          <Typography color="white">
            {storage?.percentageFull}% <FormattedMessage id="label.full" />
          </Typography>
          <Typography color="white">{storage?.cubicMeter}&#13221;</Typography>
          <Typography color="white">
            <FormattedMessage
              id={`${storage?.selfOwned ? 'label.selfOwned' : 'label.rented'}`}
            />
          </Typography>
          <Typography color="white">
            {storage?.loadedOn ? (
              <FormattedMessage
                id="label.loadedOnDate"
                values={{
                  date: (
                    <FormattedDate
                      value={dayjs(storage?.loadedOn).toDate()}
                      dateStyle="short"
                    />
                  ),
                }}
              />
            ) : (
              <FormattedMessage id="label.notLoaded" />
            )}
          </Typography>
          <Typography color="white">
            {storage?.notes || <FormattedMessage id="label.noNotes" />}
          </Typography>
        </Box>
        <Box width="50%" flexWrap="wrap" gap={3}>
          <Box width="100%">
            <Typography variant="subtitle1" color="white">
              <FormattedMessage id="label.location" />
            </Typography>
            {isLoadingWarehouse && <Skeleton width={500} height={300} light />}
            {storage && !storage.warehouseId && (
              <Typography color="white">
                <FormattedMessage id="label.noLocation" />
              </Typography>
            )}
            {storage?.warehouseId && warehouse && (
              <>
                <Typography color="white">{warehouse.name}</Typography>
                {storage.storageLocationId && (
                  <Typography color="white">
                    {storage.storageLocationName} ({storage.storageLocationCode}
                    )
                  </Typography>
                )}
                <AddressBlock address={warehouse.address} color="white" />
              </>
            )}
          </Box>
          <Box width="100%">
            {storage?.storedForProjectId && (
              <Typography variant="subtitle1" color="white">
                <FormattedMessage id="label.project" />
              </Typography>
            )}
            <Typography color="white">{project?.projectCode}</Typography>
          </Box>
        </Box>
      </Box>
      <Box flex={1} width="100%">
        <Typography variant="subtitle1" color="white">
          {loadedItems.length} <FormattedMessage id="label.storedItems" />
        </Typography>
        {isLoading && <Skeleton width={500} height={300} light />}
        {!isLoading && (
          <FlashList
            refreshing={isFetching}
            onRefresh={refetch}
            renderItem={handleRenderStoredItem}
            data={loadedItems}
            extraData={{ employees }}
            keyExtractor={(item) => item.itemId}
            estimatedItemSize={61}
          />
        )}
      </Box>
    </Box>
  );
};

const ShowStorageBottomSheet = ({
  bottomSheetRef,
  ...props
}: ShowStorageBottomSheetProps): ReactElement => {
  const onClose = useCallback(() => {
    bottomSheetRef?.current?.close();
    bottomSheetRef?.current?.forceClose();
    bottomSheetRef?.current?.dismiss();
  }, [bottomSheetRef]);

  const handleRender = useCallback(
    (incomingData: { data?: { storageId: Uuid } } | undefined) => {
      const storageId = incomingData?.data?.storageId;
      return <ShowStorageBottomSheetContent storageId={storageId || ''} />;
    },
    [],
  );

  return (
    <BottomSheet<{ storageId: Uuid }>
      ref={bottomSheetRef}
      disablePadding
      enableDismissOnClose
      onDismiss={onClose}
      {...props}
    >
      {handleRender}
    </BottomSheet>
  );
};

export default ShowStorageBottomSheet;
