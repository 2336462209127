import * as Yup from 'yup';
import { Uuid } from '@bas/value-objects';

export interface ReturnStorageToWarehouseInputType {
  selectedStorageId: Uuid | null;
  currentWarehouseId: Uuid | null;
  currentLocationId: Uuid | null;
  newWarehouseId: Uuid | null;
  newLocationId: Uuid | null;
  employeeId: Uuid | null;
}

export const returnStorageToWarehouseInputTypeDefaultValues =
  (): ReturnStorageToWarehouseInputType => ({
    selectedStorageId: null,
    currentWarehouseId: null,
    currentLocationId: null,
    newWarehouseId: null,
    newLocationId: null,
    employeeId: null,
  });

export const returnStorageToWarehouseInputTypeValidationBuilder =
  (): Yup.ObjectSchema<ReturnStorageToWarehouseInputType> =>
    Yup.object({
      selectedStorageId: Yup.string().required().label('label.storage'),
      currentWarehouseId: Yup.string()
        .ensure()
        .nullable()
        .max(0)
        .label('label.currentWarehouse'),
      currentLocationId: Yup.string()
        .ensure()
        .max(0)
        .nullable()
        .label('label.currentLocation'),
      newWarehouseId: Yup.string().required().label('label.newWarehouse'),
      newLocationId: Yup.string().required().label('label.newLocation'),
      employeeId: Yup.string().required().label('label.employee'),
    });
