import { MovingJob } from '@bas/project-domain/models';
import { Box, Typography } from '@bas/ui/native/base';
import { Uuid } from '@bas/value-objects';
import { InventoryMovementInputType } from '@bas/wms-domain/input-types';
import {
  useOutstandingStockByProjectIdRequest,
  usePublicMovingBoxesRequest,
} from '@bas/wms-domain/requests';
import { ReactElement, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

export type BoxesSigningDataItemsProps = {
  project: MovingJob;
  materialMovements: InventoryMovementInputType[];
};

type BoxesBox = {
  inventoryItemId: Uuid;
  name: string;
  outstanding: number;
  reusable?: boolean;
};

const BoxesSigningDataItems = ({
  project,
  materialMovements,
}: BoxesSigningDataItemsProps): ReactElement => {
  const { data: boxesData } = usePublicMovingBoxesRequest();

  const { data: outstandingData } = useOutstandingStockByProjectIdRequest({
    projectId: project.projectId,
  });

  const inventoryItems: BoxesBox[] = useMemo(() => {
    const outstanding = outstandingData?.data?.member || [];

    const result: { [key: string]: BoxesBox } = {};

    outstanding.forEach((item) => {
      if (typeof result[item.inventoryItemId] === 'undefined') {
        result[item.inventoryItemId] = {
          outstanding: 0,
          inventoryItemId: item.inventoryItemId,
          name: item.inventoryItemName,
          reusable: !boxesData?.data?.member.find(
            ({ inventoryItemId }) => inventoryItemId === item.inventoryItemId,
          ),
        };
      }

      if (item.outstandingStock > 0) {
        result[item.inventoryItemId].outstanding += item.outstandingStock;
      }
    });

    materialMovements.forEach((movement) => {
      if (typeof result[movement.inventoryItemId] !== 'undefined') {
        result[movement.inventoryItemId].outstanding -=
          movement.quantityWornOut || 0;
        result[movement.inventoryItemId].outstanding -= movement.quantity;
      }
    });

    return Object.values(result);
  }, [boxesData?.data, materialMovements, outstandingData?.data]);

  return (
    <>
      {inventoryItems.filter(
        ({ reusable, outstanding }) => !reusable && outstanding > 0,
      ).length > 0 && (
        <Box pt={2}>
          <Typography color="white">
            <FormattedMessage id="label.theFollowingBoxesArePresent" />
          </Typography>
        </Box>
      )}
      {inventoryItems
        .filter(({ reusable, outstanding }) => !reusable && outstanding > 0)
        .map((item) => (
          <Box key={item.name}>
            <Typography color="white">{`- ${item.outstanding} ${item.name}`}</Typography>
          </Box>
        ))}
      {inventoryItems.filter(
        ({ reusable, outstanding }) => reusable && outstanding > 0,
      ).length > 0 && (
        <Box pt={2}>
          <Typography color="white">
            <FormattedMessage id="label.theFollowingMaterialsArePresent" />
          </Typography>
        </Box>
      )}
      {inventoryItems
        .filter(({ reusable, outstanding }) => reusable && outstanding > 0)
        .map((item) => (
          <Box key={item.name}>
            <Typography color="white">{`- ${item.outstanding} ${item.name}`}</Typography>
          </Box>
        ))}
    </>
  );
};

export default BoxesSigningDataItems;
