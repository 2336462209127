import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type CreateStorageLocationMutationProps = {
  storageLocationId: Uuid;
  warehouseId: Uuid;
  name: string;
  code: string;
  description?: string | null;
};

export const CreateStorageLocationMutation = async ({
  ...values
}: CreateStorageLocationMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/{tenantId}/locations`, {
    ...values,
  });

export const useCreateStorageLocationMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    CreateStorageLocationMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  CreateStorageLocationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    CreateStorageLocationMutationProps
  >({ mutationFn: CreateStorageLocationMutation, ...options });
