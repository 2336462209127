import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type AcceptQuoteMutationProps = {
  quoteId: Uuid;
  nameOfAcceptor: string;
  acceptedLines: { lineId: Uuid }[];
};

export const AcceptQuoteMutation = async ({
  quoteId,
  ...values
}: AcceptQuoteMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/quotes/${quoteId}/accept`, {
    quoteId,
    ...values,
  });

export const useAcceptQuoteMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AcceptQuoteMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  AcceptQuoteMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AcceptQuoteMutationProps
  >({
    mutationFn: AcceptQuoteMutation,
    throwOnError: false,
    ...options,
  });
