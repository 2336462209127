import { ReactElement } from 'react';
import { Box, BoxProps, Icon, Typography } from '@bas/ui/native/base';
import { faQuestion } from '@fortawesome/pro-light-svg-icons/faQuestion';
import { RenderTranslatedName } from '@bas/ui/native/atoms';
import { TranslatedName } from '@bas/value-objects';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { BackendIcon } from '../BackendIcon';
import styles from './styles';

export type RoomListItemProps = Omit<BoxProps, 'color'> & {
  room: {
    name?: string | ReactElement;
    cubicMeter?: number;
    roomType: {
      icon: IconProp | string;
      translatedNames: TranslatedName[];
      roomTypeName: string;
    };
  };
  color?: string;
};

const RoomListItem = ({
  children,
  room,
  color,
  ...args
}: RoomListItemProps): ReactElement => (
  <Box {...args}>
    <Box style={styles.row}>
      <Box style={styles.column}>
        {typeof room.roomType.icon === 'string' ? (
          <BackendIcon
            overrideColor={color}
            size={24}
            icon={room.roomType.icon}
          />
        ) : (
          <Icon
            icon={room.roomType.icon || faQuestion}
            overrideColor={color}
            size={24}
          />
        )}
      </Box>
      <Box style={styles.column}>
        <Typography variant="h4" fontWeight={700} overrideColor={color}>
          {room.name || (
            <RenderTranslatedName
              variant="h4"
              fontWeight={700}
              overrideColor={color}
              translations={room.roomType.translatedNames || []}
              fallback={room.roomType.roomTypeName || ''}
            />
          )}
        </Typography>
      </Box>
      <Box style={[styles.column, styles.cubicMeter]}>
        {!!room.cubicMeter && (
          <Typography overrideColor={color} variant="body2" fontWeight={700}>
            {room.cubicMeter.toFixed(2)}
            &#13221;
          </Typography>
        )}
      </Box>
    </Box>
    {children}
  </Box>
);

export default RoomListItem;
