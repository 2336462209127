import { useCallback } from 'react';
import { useIntercom } from 'react-use-intercom';

export const useHandleOpenIntercom = () => {
  const { show } = useIntercom();

  return useCallback(() => {
    show();
  }, [show]);
};
