import { TranslatedName, Uuid } from '@bas/value-objects';
import { InternalTimeOffType } from './InternalTimeOffType';

export interface TimeOffType {
  timeOffTypeId: Uuid;
  name: string;
  weight: number;
  icon: string | null;
  translatedNames: TranslatedName[];
  internalTimeOffType: InternalTimeOffType;
  start: boolean;
  allowBuildUp: boolean;
  disabledAfter?: Date | null;
}
