import { colors } from '@bas/theme';
import { Button, Signature, SignatureRef } from '@bas/ui/native/atoms';
import { Box, Typography } from '@bas/ui/native/base';
import { ReactHookFormTextField } from '@bas/ui/native/molecules';
import { ReactElement, ReactNode, RefObject, useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { ScrollView, View } from 'react-native';

export type SignDocumentFormProps = {
  children?: ReactNode;
  prefix?: string;
  light?: boolean;
  setScrollEnabled?: (enabled: boolean) => void;
  scrollEnabled?: boolean;
  scrollRef: RefObject<ScrollView>;
};
const SignDocumentForm = ({
  children,
  prefix,
  light,
  setScrollEnabled,
  scrollRef,
  scrollEnabled,
}: SignDocumentFormProps): ReactElement => {
  const signatureRef = useRef<SignatureRef | null>(null);
  const { formatMessage } = useIntl();
  const { setValue } = useFormContext();

  const existingSignature = useWatch({
    name: prefix ? `${prefix}.signature` : 'signature',
  });

  return (
    <View>
      <View>
        <ReactHookFormTextField
          name={prefix ? `${prefix}.name` : 'name'}
          autoComplete="off"
          label={formatMessage({ id: 'label.name' })}
          light={light}
        />
      </View>
      <View>
        <ReactHookFormTextField
          name={prefix ? `${prefix}.emailAddress` : 'emailAddress'}
          autoComplete="off"
          keyboardType="email-address"
          label={formatMessage({ id: 'label.emailAddress' })}
          autoCapitalize="none"
          light={light}
        />
      </View>
      <View>
        <ReactHookFormTextField
          name={prefix ? `${prefix}.remarks` : 'remarks'}
          label={formatMessage({ id: 'label.remarks' })}
          multiline
          light={light}
        />
      </View>
      <View>
        <Typography overrideColor={light ? colors.white : undefined}>
          <FormattedMessage id="label.signature" />
        </Typography>
        <Box mt={1}>
          <Box pb={2} flexDirection="row">
            <Box flexBasis="50%">
              <Button
                variant="text"
                overrideColor={light ? 'white' : colors.lila[800]}
                onPress={() => {
                  setScrollEnabled?.(!scrollEnabled);
                  scrollRef?.current?.scrollToEnd();
                }}
              >
                <FormattedMessage
                  id={`button.${
                    scrollEnabled ? 'enableSignature' : 'disableSignature'
                  }`}
                />
              </Button>
            </Box>
            <Box flexBasis="50%">
              <Button
                variant="text"
                overrideColor={light ? 'white' : colors.lila[800]}
                onPress={() => {
                  signatureRef?.current?.clearSignature();
                  setValue(prefix ? `${prefix}.signature` : 'signature', '', {
                    shouldDirty: true,
                    shouldTouch: true,
                    shouldValidate: true,
                  });
                }}
              >
                <FormattedMessage id="button.clearSignature" />
              </Button>
            </Box>
          </Box>
          <Box pb={3}>
            <Signature
              signatureRef={signatureRef}
              onChange={(signature) => {
                setValue(
                  prefix ? `${prefix}.signature` : 'signature',
                  signature,
                  { shouldValidate: true },
                );
              }}
              existingSignature={existingSignature}
              enabled={!scrollEnabled}
            />
          </Box>
        </Box>
      </View>
      {children}
    </View>
  );
};

export default SignDocumentForm;
