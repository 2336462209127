import dayjs from 'dayjs';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { ReactElement } from 'react';

export type FormattedDateDayMonthRangeProps = {
  from: Date;
  to: Date;
};

const FormattedDateDayMonthRange = ({
  from,
  to,
}: FormattedDateDayMonthRangeProps): ReactElement => (
  <>
    <FormattedDate value={from} day="numeric" />
    {!dayjs(from).isSame(to, 'month') && (
      <>
        &nbsp;
        <FormattedDate value={from} month="long" />
      </>
    )}
    &nbsp;
    <FormattedMessage id="label.until" />
    &nbsp;
    <FormattedDate value={to} day="numeric" />
    &nbsp;
    <FormattedDate value={to} month="long" />
  </>
);

export default FormattedDateDayMonthRange;
