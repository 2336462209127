import { ErrorResponse, Money, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ChangePriceOfServiceTypeMutationProps = {
  serviceTypeId: Uuid;
  price: Money;
};

export const ChangePriceOfServiceTypeMutation = async ({
  serviceTypeId,
  ...values
}: ChangePriceOfServiceTypeMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/settings/service-types/${serviceTypeId}/change-price`,
    {
      serviceTypeId,
      ...values,
    },
  );

export const useChangePriceOfServiceTypeMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangePriceOfServiceTypeMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ChangePriceOfServiceTypeMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangePriceOfServiceTypeMutationProps
  >({
    mutationFn: ChangePriceOfServiceTypeMutation,
    throwOnError: false,
    ...options,
  });
