import type { ImageMessageMetadata } from '../../types';
import { MessageType } from '../../types';
import { isMessageMetadata } from './MessageMetadata';

export function isImageMessageMetadata(
  object: unknown,
): object is ImageMessageMetadata {
  return (
    isMessageMetadata(object) &&
    object.messageType === MessageType.IMAGE_MESSAGE
  );
}
