import { formatDate } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type EmptyAllStoragesForRelationMutationProps = {
  relationId: Uuid;
  invoicingEndDate?: Date | null;
  createInvoice: boolean;
};

export const EmptyAllStoragesForRelationMutation = async ({
  invoicingEndDate,
  ...values
}: EmptyAllStoragesForRelationMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/{tenantId}/storages/empty-all-for-customer`, {
    invoicingEndDate: invoicingEndDate ? formatDate(invoicingEndDate) : null,
    ...values,
  });

export const useEmptyAllStoragesForRelationMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    EmptyAllStoragesForRelationMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  EmptyAllStoragesForRelationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    EmptyAllStoragesForRelationMutationProps
  >({
    mutationFn: EmptyAllStoragesForRelationMutation,
    throwOnError: false,
    ...options,
  });
