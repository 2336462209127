import { isObjectResponse } from '@bas/value-objects';
import type { CargoEvent } from '../types';

export function isCargoEvent(object: unknown): object is CargoEvent {
  return (
    isObjectResponse(object) &&
    ['ScannedCargoItemEvent'].includes(object['@type']) &&
    Object.prototype.hasOwnProperty.call(object, 'eventType')
  );
}
