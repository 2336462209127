import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type InvoiceQuoteMutationProps = {
  quoteId: Uuid;
  templateId: Uuid;
};

export const InvoiceQuoteMutation = async ({
  quoteId,
  ...values
}: InvoiceQuoteMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/quotes/${quoteId}/invoice`, {
    quoteId,
    ...values,
  });

export const useInvoiceQuoteMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    InvoiceQuoteMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  InvoiceQuoteMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    InvoiceQuoteMutationProps
  >({
    mutationFn: InvoiceQuoteMutation,
    throwOnError: false,
    ...options,
  });
