import {
  EnterHoursInputType,
  EventParticularsInputType,
  EventParticularsInputTypeDefaultValues,
  FinishEventAtCustomerInputTypeDefaultValues,
  PeopleWithDifferentHoursInputType,
  PeopleWithDifferentHoursInputTypeDefaultValues,
  SignForEventInputType,
} from '@bas/planning-domain/input-types';
import { isProjectEvent, ProjectEvent } from '@bas/planning-domain/models';
import { PublicRoomType } from '@bas/shared/models';
import { MovingJobTaxationRoom } from '@bas/taxation-tool-domain/models';
import { colors } from '@bas/theme';
import { BottomSheetMethods, Typography } from '@bas/ui/native/base';
import {
  BottomSheetProps,
  StepsNavigationProps,
} from '@bas/ui/native/organisms';
import { FormWizardBottomSheet } from '@bas/ui/native/templates';
import { InternalServiceType, ReactHookWizardStep } from '@bas/value-objects';
import { InventoryMovementInputType } from '@bas/wms-domain/input-types';
import {
  usePublicMovingBoxesRequest,
  useReusableMaterialsRequest,
} from '@bas/wms-domain/requests';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { keepPreviousData } from '@tanstack/react-query';
import dayjs from 'dayjs';
import {
  memo,
  ReactElement,
  ReactNode,
  RefObject,
  useCallback,
  useMemo,
} from 'react';
import isEqual from 'react-fast-compare';
import { FormattedMessage } from 'react-intl';
import styles from './styles';

type InputType = EnterHoursInputType &
  PeopleWithDifferentHoursInputType &
  EventParticularsInputType &
  SignForEventInputType & {
    materials: InventoryMovementInputType[];
  };

export type FinishEventAtCustomerFormWizardBottomSheetProps = Omit<
  BottomSheetProps,
  'snapPoints' | 'children'
> & {
  onSubmit: (values: InputType) => void;
  event: ProjectEvent;
  onClose?: () => void;
  steps: ReactHookWizardStep<InputType>[];
  bottomSheetRef: RefObject<BottomSheetModal & BottomSheetMethods>;
  currentTime: Date;
};

type Data = {
  intakeRoom: MovingJobTaxationRoom;
  room: PublicRoomType;
  usedServices: InternalServiceType[];
};

const FinishEventAtCustomerFormWizardBottomSheet = ({
  event,
  onSubmit,
  onClose,
  steps,
  currentTime,
  bottomSheetRef,
}: FinishEventAtCustomerFormWizardBottomSheetProps): ReactElement => {
  const renderTitle = useCallback(
    (data: Data, title: ReactNode) => (
      <Typography
        variant="h4"
        fontWeight={700}
        overrideColor={colors.white}
        style={styles.title}
      >
        {typeof title === 'string' ? <FormattedMessage id={title} /> : title}
      </Typography>
    ),
    [],
  );

  const { data: boxesData } = usePublicMovingBoxesRequest();

  const { data: reusableMaterialsData } = useReusableMaterialsRequest(
    {
      page: 1,
      perPage: 999999,
    },
    {
      placeholderData: keepPreviousData,
    },
  );
  const reusableMaterials = useMemo(
    () => reusableMaterialsData?.data.member || [],
    [reusableMaterialsData?.data],
  );

  const boxes = useMemo(() => boxesData?.data.member || [], [boxesData?.data]);
  const defaultValues = {
    ...FinishEventAtCustomerInputTypeDefaultValues(),
    ...PeopleWithDifferentHoursInputTypeDefaultValues(),
    ...EventParticularsInputTypeDefaultValues(),
    startDate: dayjs(event.realStart || event.start).toDate(),
    endDate: dayjs(event.realStart).isAfter(event.end)
      ? currentTime
      : dayjs(event.end).toDate(),
    overrideTime:
      event.realStart &&
      event.end &&
      dayjs(event.realStart).isValid() &&
      dayjs(event.end).isValid() &&
      dayjs(event.realStart).isAfter(event.end),
    materials: [...reusableMaterials, ...boxes].map(({ inventoryItemId }) => ({
      inventoryItemId,
      quantity: 0,
    })),
    eventData: {
      eventId: event.eventId,
      projectId: isProjectEvent(event) ? event.projectId : undefined,
      realTravelStartTime:
        typeof event.realTravelStart === 'string'
          ? dayjs(event.realTravelStart).toDate()
          : event.realTravelStart || null,
      startDate:
        typeof event.start === 'string'
          ? dayjs(event.start).toDate()
          : event.start,
      endDate:
        typeof event.end === 'string' ? dayjs(event.end).toDate() : event.end,
      travelStartTime:
        typeof event.travelStart === 'string'
          ? dayjs(event.travelStart).toDate()
          : event.travelStart || null,
      travelEndTime:
        typeof event.travelEnd === 'string'
          ? dayjs(event.travelEnd).toDate()
          : event.travelEnd || null,
    },
  };

  return (
    <FormWizardBottomSheet<
      { stepNavigationProps: Partial<StepsNavigationProps> },
      InputType,
      Data
    >
      name="finish-event-at-customer-form-wizard"
      wizardSteps={steps}
      initialValues={defaultValues}
      onSubmit={onSubmit}
      initialStepIndex={0}
      resetFormOnOpen
      successFullFormViewProps={{
        gifUrl:
          'https://media.istockphoto.com/id/1271311350/vector/thank-you-ink-brush-vector-lettering-thank-you-modern-phrase-handwritten-vector-calligraphy.jpg?s=612x612&w=0&k=20&c=k50bI-VIGLno48xtby-RWr4TcFNUp-K38VMkn59_Cfw=',
        title: <FormattedMessage id="label.finishedAtCustomer.finished" />,
        color: colors.lila[100],
      }}
      extraTemplateProps={{
        stepNavigationProps: {
          dark: true,
          allowCancel: true,
          onCancel: onClose,
          onClose,
        },
      }}
      renderTitle={renderTitle}
      bottomSheetRef={bottomSheetRef}
    />
  );
};

const MemoComponent = memo(
  FinishEventAtCustomerFormWizardBottomSheet,
  isEqual,
) as typeof FinishEventAtCustomerFormWizardBottomSheet;
export default MemoComponent;
