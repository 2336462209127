import { EmployeeRole } from '@bas/hrm-domain/models';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ChangeEmployeeRolesMutationProps = {
  employeeId: Uuid;
  roles: EmployeeRole[];
};

export const ChangeEmployeeRolesMutation = async ({
  employeeId,
  ...values
}: ChangeEmployeeRolesMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/hrm/employees/${employeeId}/update-roles`, {
    employeeId,
    ...values,
  });

export const useChangeEmployeeRolesMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeEmployeeRolesMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ChangeEmployeeRolesMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeEmployeeRolesMutationProps
  >({
    mutationFn: ChangeEmployeeRolesMutation,
    throwOnError: false,
    ...options,
  });
