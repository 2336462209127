import { useIsFocused } from '@react-navigation/core';
import { Stack } from 'expo-router';

const IntakeProjectLayout = () => {
  const isFocused = useIsFocused();

  if (!isFocused) {
    return null;
  }

  return <Stack screenOptions={{ headerShown: false }} />;
};

export default IntakeProjectLayout;
