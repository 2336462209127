import { EmailAddress } from '@bas/value-objects';
import * as Yup from 'yup';

export interface FinalizeInvoiceInputType {
  invoicedAt: Date | null;
  expiresAt: Date | null;
  sendInvoice?: boolean;
  extraEmailAddresses: EmailAddress[] | undefined;
  exactOnlineOverrideReportingPeriod?: boolean;
  reportingYear?: number;
  reportingPeriod?: number;
}

export const FinalizeInvoiceInputTypeDefaultValues =
  (): FinalizeInvoiceInputType => ({
    invoicedAt: null,
    expiresAt: null,
    sendInvoice: true,
    extraEmailAddresses: [],
  });

export const FinalizeInvoiceInputTypeValidationBuilder =
  (): Yup.ObjectSchema<FinalizeInvoiceInputType> =>
    Yup.object({
      invoicedAt: Yup.date().required().nullable().label('label.invoicedAt'),
      expiresAt: Yup.date().required().nullable().label('label.expiresAt'),
      sendInvoice: Yup.boolean().label('label.sendInvoice'),
      extraEmailAddresses: Yup.array()
        .of(Yup.string().email().required().label('label.emailAddress'))
        .optional(),
      exactOnlineOverrideReportingPeriod: Yup.boolean().label(
        'label.exactOnlineOverrideReportingPeriod',
      ),
      reportingYear: Yup.number()
        .min(2000)
        .max(9999)
        .when('exactOnlineOverrideReportingPeriod', {
          is: true,
          then: (schema) => schema.required(),
        })
        .label('label.reportingYear'),
      reportingPeriod: Yup.number()
        .min(0)
        .max(99)
        .when('exactOnlineOverrideReportingPeriod', {
          is: true,
          then: (schema) => schema.required(),
        }),
    });
