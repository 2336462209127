import { colors } from '@bas/theme';
import { Typography } from '@bas/ui/native/base';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons/faArrowLeft';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { router, useLocalSearchParams } from 'expo-router';
import { StatusBar } from 'expo-status-bar';
import { ReactElement, useCallback, useMemo } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.lila[100],
    position: 'relative',
    paddingBottom: 28,
    paddingLeft: 20,
    paddingRight: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  backButton: {
    position: 'absolute',
    left: 20,
    bottom: 28,
    height: 24,
    width: 24,
    zIndex: 20,
  },
  title: {
    alignSelf: 'center',
  },
});

const NewsHeader = (): ReactElement => {
  const { top } = useSafeAreaInsets();
  const { title } = useLocalSearchParams<{
    title: string;
  }>();

  const handleBack = useCallback(() => {
    router.navigate('/news');
  }, []);

  const topStyle = useMemo(() => ({ paddingTop: top < 28 ? 28 : top }), [top]);

  return (
    <View style={[styles.container, topStyle]}>
      {/* eslint-disable-next-line react/style-prop-object */}
      <StatusBar style="dark" />
      <View style={styles.backButton}>
        <TouchableOpacity onPress={handleBack}>
          <FontAwesomeIcon icon={faArrowLeft} size={24} />
        </TouchableOpacity>
      </View>
      <Typography style={styles.title} fontWeight={700}>
        {title}
      </Typography>
    </View>
  );
};

export default NewsHeader;
