import { ParticipantType, RelationParticipant } from '../../types';
import { isParticipant } from './Participant';

export function isRelationParticipant(
  object: unknown,
): object is RelationParticipant {
  return (
    isParticipant(object) && object.participantType === ParticipantType.RELATION
  );
}
