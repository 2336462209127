import { Vehicle } from '@bas/planning-domain/models';
import { Avatar, LicensePlate } from '@bas/ui/native/atoms';
import {
  TouchableWithoutFeedback,
  TouchableWithoutFeedbackProps,
  Typography,
} from '@bas/ui/native/base';
import { Country } from '@bas/value-objects';
import { ReactElement } from 'react';
import { View } from 'react-native';
import styles from './styles';

export type PlannedMaterialListItemProps = TouchableWithoutFeedbackProps & {
  material: Vehicle;
  country: Country | undefined | null;
  isLast?: boolean;
};

const PlannedMaterialListItem = ({
  material,
  isLast,
  country,
  ...props
}: PlannedMaterialListItemProps): ReactElement => (
  <TouchableWithoutFeedback {...props}>
    <View style={[styles.container, isLast && styles.borderBottom]}>
      <Avatar src={material.pictureUrl} name={material.name} />

      <Typography variant="h5" style={styles.role}>
        {material.name}
      </Typography>
      {material.licensePlate && country && (
        <View style={styles.licensePlate}>
          <LicensePlate
            licensePlate={material.licensePlate}
            country={country}
          />
        </View>
      )}
    </View>
  </TouchableWithoutFeedback>
);

export default PlannedMaterialListItem;
