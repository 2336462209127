import { FinishEventAtTenantInputType } from '@bas/planning-domain/input-types';
import { Vehicle } from '@bas/planning-domain/models';
import { calculateTimeOfDaySliderValues } from '@bas/shared/utils';
import { ReactHookFormTimeSliderField } from '@bas/ui/native/molecules';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { View } from 'react-native';
import { MileageReportForm } from '../MileageReportForm';

export type FinishEventAtTenantFormProps = {
  vehicles: Vehicle[];
  endTime?: Date;
};

const FinishEventAtTenantForm = ({
  vehicles,
  endTime,
}: FinishEventAtTenantFormProps) => {
  const mileageReports = useWatch<
    FinishEventAtTenantInputType,
    'mileageReports'
  >({
    name: 'mileageReports',
  });

  const timeOfDayValues = useMemo(
    () => calculateTimeOfDaySliderValues(15, endTime),
    [endTime],
  );

  return (
    <View>
      <View>
        <ReactHookFormTimeSliderField
          name="realTravelEndTime"
          label={<FormattedMessage id="label.arrivalTimeWarehouse" />}
          values={timeOfDayValues}
          light
        />
      </View>
      <View style={{ paddingHorizontal: 20 }}>
        {mileageReports.map(({ materialId }, index) => (
          <View key={materialId}>
            <MileageReportForm
              vehicles={vehicles}
              showEnd
              hideVehicles
              prefix={`mileageReports.${index}`}
            />
          </View>
        ))}
      </View>
    </View>
  );
};

export default FinishEventAtTenantForm;
