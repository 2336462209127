import { ReactElement, ReactNode } from 'react';
import { SquareTile } from '@bas/ui/native/atoms';
import {
  Box,
  Icon,
  TouchableWithoutFeedback,
  Typography,
} from '@bas/ui/native/base';
import { faThumbsUp } from '@fortawesome/pro-light-svg-icons/faThumbsUp';
import { faMinus } from '@fortawesome/pro-solid-svg-icons/faMinus';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { DimensionValue } from 'react-native';

export type SquareCounterTileProps = {
  width: DimensionValue;
  variantLabel?: string | ReactNode;
  count: number;
  onCountChange: (count: number) => void;
  onIncrease: () => void;
  onDecrease: () => void;
  onFinish: () => void;
};

const SquareCounterTile = ({
  width,
  variantLabel,
  count,
  onCountChange,
  onIncrease,
  onDecrease,
  onFinish,
}: SquareCounterTileProps): ReactElement => (
  <SquareTile width={width}>
    <Box flex={1} flexDirection="column">
      {variantLabel && (
        <Box
          height={42}
          borderColor="titleBorder"
          borderBottomWidth={1}
          justifyContent="center"
          alignItems="center"
        >
          <Typography textAlign="center" fontWeight={700} variant="body2">
            {variantLabel}
          </Typography>
        </Box>
      )}
      <Box flex={1} flexDirection="row" alignContent="stretch" flexWrap="wrap">
        <Box
          flex={1}
          alignItems="center"
          justifyContent="center"
          flexBasis="50%"
          borderColor="titleBorder"
          borderBottomWidth={1}
        >
          <Typography textAlign="center" fontWeight={700} variant="h4">
            {count}
          </Typography>
        </Box>
        <TouchableWithoutFeedback onPress={onFinish}>
          <Box
            flex={1}
            alignItems="center"
            justifyContent="center"
            flexBasis="50%"
            borderColor="titleBorder"
            borderBottomWidth={1}
            borderLeftWidth={1}
          >
            <Icon icon={faThumbsUp} size={30} color="success" />
          </Box>
        </TouchableWithoutFeedback>
        <TouchableWithoutFeedback onPress={onDecrease}>
          <Box
            flex={1}
            alignItems="center"
            justifyContent="center"
            flexBasis="50%"
            borderColor="titleBorder"
          >
            <Icon icon={faMinus} size={24} />
          </Box>
        </TouchableWithoutFeedback>
        <TouchableWithoutFeedback onPress={onIncrease}>
          <Box
            flex={1}
            alignItems="center"
            justifyContent="center"
            flexBasis="50%"
            borderColor="titleBorder"
            borderLeftWidth={1}
          >
            <Icon icon={faPlus} size={24} />
          </Box>
        </TouchableWithoutFeedback>
      </Box>
    </Box>
  </SquareTile>
);

export default SquareCounterTile;
