// All of the requested modules are loaded behind enumerable getters.
const map = Object.defineProperties({}, {
  "./(app)/(drawer)/(tabs)/_layout.tsx": { enumerable: true, get() { return require("/builds/bas/bas-software-monorepo/apps/bas-employee-app/src/app/(app)/(drawer)/(tabs)/_layout.tsx"); } },
  "./(app)/(drawer)/(tabs)/calendar.tsx": { enumerable: true, get() { return require("/builds/bas/bas-software-monorepo/apps/bas-employee-app/src/app/(app)/(drawer)/(tabs)/calendar.tsx"); } },
  "./(app)/(drawer)/(tabs)/chat.tsx": { enumerable: true, get() { return require("/builds/bas/bas-software-monorepo/apps/bas-employee-app/src/app/(app)/(drawer)/(tabs)/chat.tsx"); } },
  "./(app)/(drawer)/(tabs)/drawer.tsx": { enumerable: true, get() { return require("/builds/bas/bas-software-monorepo/apps/bas-employee-app/src/app/(app)/(drawer)/(tabs)/drawer.tsx"); } },
  "./(app)/(drawer)/(tabs)/index.tsx": { enumerable: true, get() { return require("/builds/bas/bas-software-monorepo/apps/bas-employee-app/src/app/(app)/(drawer)/(tabs)/index.tsx"); } },
  "./(app)/(drawer)/(tabs)/news.tsx": { enumerable: true, get() { return require("/builds/bas/bas-software-monorepo/apps/bas-employee-app/src/app/(app)/(drawer)/(tabs)/news.tsx"); } },
  "./(app)/(drawer)/(tabs)/profile.tsx": { enumerable: true, get() { return require("/builds/bas/bas-software-monorepo/apps/bas-employee-app/src/app/(app)/(drawer)/(tabs)/profile.tsx"); } },
  "./(app)/(drawer)/(tabs)/time-tracking/_layout.tsx": { enumerable: true, get() { return require("/builds/bas/bas-software-monorepo/apps/bas-employee-app/src/app/(app)/(drawer)/(tabs)/time-tracking/_layout.tsx"); } },
  "./(app)/(drawer)/(tabs)/time-tracking/calendar.tsx": { enumerable: true, get() { return require("/builds/bas/bas-software-monorepo/apps/bas-employee-app/src/app/(app)/(drawer)/(tabs)/time-tracking/calendar.tsx"); } },
  "./(app)/(drawer)/(tabs)/time-tracking/declarations.tsx": { enumerable: true, get() { return require("/builds/bas/bas-software-monorepo/apps/bas-employee-app/src/app/(app)/(drawer)/(tabs)/time-tracking/declarations.tsx"); } },
  "./(app)/(drawer)/(tabs)/time-tracking/hours-insight.tsx": { enumerable: true, get() { return require("/builds/bas/bas-software-monorepo/apps/bas-employee-app/src/app/(app)/(drawer)/(tabs)/time-tracking/hours-insight.tsx"); } },
  "./(app)/(drawer)/(tabs)/time-tracking/time-off-registrations.tsx": { enumerable: true, get() { return require("/builds/bas/bas-software-monorepo/apps/bas-employee-app/src/app/(app)/(drawer)/(tabs)/time-tracking/time-off-registrations.tsx"); } },
  "./(app)/(drawer)/(tabs)/time-tracking/track-time.tsx": { enumerable: true, get() { return require("/builds/bas/bas-software-monorepo/apps/bas-employee-app/src/app/(app)/(drawer)/(tabs)/time-tracking/track-time.tsx"); } },
  "./(app)/(drawer)/(tabs)/warehouse.tsx": { enumerable: true, get() { return require("/builds/bas/bas-software-monorepo/apps/bas-employee-app/src/app/(app)/(drawer)/(tabs)/warehouse.tsx"); } },
  "./(app)/(drawer)/_layout.tsx": { enumerable: true, get() { return require("/builds/bas/bas-software-monorepo/apps/bas-employee-app/src/app/(app)/(drawer)/_layout.tsx"); } },
  "./(app)/_layout.tsx": { enumerable: true, get() { return require("/builds/bas/bas-software-monorepo/apps/bas-employee-app/src/app/(app)/_layout.tsx"); } },
  "./(app)/event/[eventId]/_layout.tsx": { enumerable: true, get() { return require("/builds/bas/bas-software-monorepo/apps/bas-employee-app/src/app/(app)/event/[eventId]/_layout.tsx"); } },
  "./(app)/event/[eventId]/employee/[employeeId].tsx": { enumerable: true, get() { return require("/builds/bas/bas-software-monorepo/apps/bas-employee-app/src/app/(app)/event/[eventId]/employee/[employeeId].tsx"); } },
  "./(app)/event/[eventId]/index.tsx": { enumerable: true, get() { return require("/builds/bas/bas-software-monorepo/apps/bas-employee-app/src/app/(app)/event/[eventId]/index.tsx"); } },
  "./(app)/event/[eventId]/vehicle/[vehicleId].tsx": { enumerable: true, get() { return require("/builds/bas/bas-software-monorepo/apps/bas-employee-app/src/app/(app)/event/[eventId]/vehicle/[vehicleId].tsx"); } },
  "./(app)/intake/[projectId]/[projectType].tsx": { enumerable: true, get() { return require("/builds/bas/bas-software-monorepo/apps/bas-employee-app/src/app/(app)/intake/[projectId]/[projectType].tsx"); } },
  "./(app)/intake/[projectId]/_layout.tsx": { enumerable: true, get() { return require("/builds/bas/bas-software-monorepo/apps/bas-employee-app/src/app/(app)/intake/[projectId]/_layout.tsx"); } },
  "./(app)/intake/[projectId]/index.tsx": { enumerable: true, get() { return require("/builds/bas/bas-software-monorepo/apps/bas-employee-app/src/app/(app)/intake/[projectId]/index.tsx"); } },
  "./(app)/news/[newsId].tsx": { enumerable: true, get() { return require("/builds/bas/bas-software-monorepo/apps/bas-employee-app/src/app/(app)/news/[newsId].tsx"); } },
  "./+not-found.tsx": { enumerable: true, get() { return require("/builds/bas/bas-software-monorepo/apps/bas-employee-app/src/app/+not-found.tsx"); } },
  "./_layout.tsx": { enumerable: true, get() { return require("/builds/bas/bas-software-monorepo/apps/bas-employee-app/src/app/_layout.tsx"); } },
  "./forgot-password.tsx": { enumerable: true, get() { return require("/builds/bas/bas-software-monorepo/apps/bas-employee-app/src/app/forgot-password.tsx"); } },
  "./login.tsx": { enumerable: true, get() { return require("/builds/bas/bas-software-monorepo/apps/bas-employee-app/src/app/login.tsx"); } },
});

function metroContext(request) {
    return map[request];
}

// Return the keys that can be resolved.
metroContext.keys = function metroContextKeys() {
  return Object.keys(map);
};

// Return the module identifier for a user request.
metroContext.resolve = function metroContextResolve(request) {
  throw new Error('Unimplemented Metro module context functionality');
}

module.exports = metroContext;