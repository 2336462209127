import { useCustomersRequest } from '@bas/crm-domain/requests';
import { isTimeEntry } from '@bas/hrm-domain/models';
import { useTimeTypesRequest } from '@bas/hrm-domain/requests';
import { TimeTrackingItem, useTimeTrackingStore } from '@bas/shared/state';
import { colors } from '@bas/theme';
import { Button } from '@bas/ui/native/atoms';
import { BottomSheetMethods, Box, Icon, Typography } from '@bas/ui/native/base';
import {
  BottomSheet,
  BottomSheetProps,
  StepsNavigationProps,
} from '@bas/ui/native/organisms';
import { FormWizardBottomSheet } from '@bas/ui/native/templates';
import { ReactHookWizardStep } from '@bas/value-objects';
import { faClock } from '@fortawesome/pro-light-svg-icons';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { FlashList } from '@shopify/flash-list';
import { ListRenderItem } from '@shopify/flash-list/src/FlashListProps';
import dayjs from 'dayjs';
import { ReactElement, RefObject, useCallback, useMemo } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import {
  UnknownTimeEntryStep,
  UnknownTimeEntryStepProps,
} from '../UnknownTimeEntryStep';
import { WorkingDayOverviewStep } from '../WorkingDayOverviewStep';
import { WorkingDayWithBreakTimeStep } from '../WorkingDayWithBreakTimeStep';

type InputType = {
  trackedItems: TimeTrackingItem[];
  calculatedTimeEntries: TimeTrackingItem[];
  breakTimeStartedAt?: Date | null;
  dayStartedAt: Date;
  dayEndedAt: Date;
  requiredBreakTime: number;
};

export type FinishWorkingDayFormWizardBottomSheetProps = Omit<
  BottomSheetProps,
  'snapPoints' | 'children'
> & {
  bottomSheetRef?: RefObject<BottomSheetModal & BottomSheetMethods>;
  onStop: () => void;
  onSubmit: (values: InputType) => Promise<void>;
};

const FinishWorkingDayFormWizardBottomSheet = ({
  bottomSheetRef,
  onSubmit,
  onStop,
}: FinishWorkingDayFormWizardBottomSheetProps): ReactElement => {
  const trackedTimePerDate = useTimeTrackingStore(
    (state) => state.trackedTimePerDate,
  );
  const { data: timeTypesData } = useTimeTypesRequest();

  const timeTypes = useMemo(
    () => timeTypesData?.data?.member || [],
    [timeTypesData?.data],
  );

  const trackedTimeForToday = useMemo(
    () =>
      (trackedTimePerDate[dayjs().format('YYYY-MM-DD')] || []).sort((a, b) =>
        dayjs(a.start).diff(dayjs(b.start)),
      ),
    [trackedTimePerDate],
  );

  const relationIds = useMemo(
    () =>
      trackedTimeForToday
        .filter((item) => !!item.relationId)
        .map((item) => item.relationId),
    [trackedTimeForToday],
  );

  const { data: relationsData } = useCustomersRequest(
    {
      relationId: relationIds,
    },
    {
      enabled: relationIds.length > 0,
    },
  );

  const relations = useMemo(
    () => relationsData?.data?.member || [],
    [relationsData?.data],
  );

  const handleLoadTrackedTime: ListRenderItem<TimeTrackingItem> = useCallback(
    ({ item }) => {
      let description = '';

      const startDate = dayjs(item.start).toDate();
      const endDate = item.end ? dayjs(item.end).toDate() : null;

      if (isTimeEntry(item)) {
        description = item.timeTypeName;
      } else {
        const timeType = timeTypes.find(
          ({ timeTypeId }) => item.timeTypeId === timeTypeId,
        );
        description = timeType?.name || '';
      }

      if (item.relationId) {
        const relation = relations.find(
          ({ relationId }) => item.relationId === relationId,
        );
        description = `${description} - ${relation?.name}`;
      }

      return (
        <Box marginBottom={2} flexDirection="row">
          <Box height="100%" paddingRight={3}>
            <Box flex={1} alignItems="center" justifyContent="center">
              <Icon icon={faClock} size={24} color="white" />
            </Box>
          </Box>
          <Box flex={1}>
            <Typography color="white">{description}</Typography>
            <Typography color="white">
              <FormattedMessage id="label.startTime" />
              {': '}
              <FormattedDate value={startDate} timeStyle="short" />
            </Typography>
            <Typography color="white">
              <FormattedMessage id="label.endTime" />
              {': '}
              {endDate ? (
                <FormattedDate value={endDate} timeStyle="short" />
              ) : (
                <FormattedMessage id="label.now" />
              )}
            </Typography>
          </Box>
        </Box>
      );
    },
    [relations, timeTypes],
  );

  const onClose = useCallback(() => {
    bottomSheetRef?.current?.close();
    bottomSheetRef?.current?.forceClose();
    bottomSheetRef?.current?.dismiss();
  }, [bottomSheetRef]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const steps: ReactHookWizardStep<InputType, any>[] = useMemo(
    () => [
      {
        category: 'workingDay',
        key: 'workingDay',
        title: 'label.finishWorkingDay',
        validationSchema: Yup.object({
          breakTimeStartedAt: Yup.date()
            .required()
            .label('label.breakTimeStartedAt'),
        }),
        component: WorkingDayWithBreakTimeStep,
      } as ReactHookWizardStep<InputType>,
      {
        category: 'unknownTimeEntries',
        key: 'unknownTimeEntries',
        title: 'label.unknownTimeEntry',
        hideWhenSubStepsExist: true,
        enabled: ({ calculatedTimeEntries }) =>
          calculatedTimeEntries.some((entry) => !entry.wasUnknown),
        component: UnknownTimeEntryStep,
        validationSchema: Yup.object({
          // eslint-disable-next-line no-empty-pattern
          calculatedTimeEntries: Yup.array().when(([], schema, options) => {
            if (options.parent.currentTimeEntryId) {
              return Yup.array().of(
                // eslint-disable-next-line no-empty-pattern
                Yup.object().when(([], schema, currentOptions) => {
                  if (
                    currentOptions.value.timeEntryId ===
                    options.parent.currentTimeEntryId
                  ) {
                    return schema.shape({
                      timeTypeId: Yup.string().required(),
                    });
                  }

                  return schema;
                }),
              );
            }

            return schema;
          }),
        }),
      } as ReactHookWizardStep<InputType, UnknownTimeEntryStepProps>,
      {
        category: 'workingDayOverview',
        key: 'workingDayOverview',
        title: 'label.workingDayOverview',
        component: WorkingDayOverviewStep,
      } as ReactHookWizardStep<InputType>,
    ],
    [],
  );

  const dayStartedAt = useMemo(() => {
    let firstItem: TimeTrackingItem | undefined;
    trackedTimeForToday.forEach((item) => {
      if (!firstItem || dayjs(item.start).isBefore(dayjs(firstItem.start))) {
        firstItem = item;
      }
    });

    return firstItem?.start;
  }, [trackedTimeForToday]);

  const dayEndedAt = useMemo(() => {
    let lastItem: TimeTrackingItem | undefined;
    trackedTimeForToday.forEach((item) => {
      if (!lastItem || dayjs(item.end).isAfter(dayjs(lastItem.end))) {
        lastItem = item;
      }
    });

    return lastItem?.end ? dayjs(lastItem.end) : dayjs();
  }, [trackedTimeForToday]);

  const defaultBreakTimeStart = useMemo(() => {
    if (!dayStartedAt) {
      return null;
    }

    const totalWorkedTime = dayjs(dayEndedAt).diff(dayStartedAt, 'minute');

    const expectedBreakTime = dayjs(dayStartedAt).add(
      totalWorkedTime / 2,
      'minute',
    );

    const remainder = Math.floor(expectedBreakTime.minute() / 15) * 15;

    return expectedBreakTime.set('minutes', remainder).toDate();
  }, [dayEndedAt, dayStartedAt]);

  const requiredBreakTime = useMemo(() => {
    const totalWorkedTime = dayjs(dayEndedAt).diff(dayStartedAt, 'minute');

    switch (true) {
      case totalWorkedTime >= 270 && totalWorkedTime < 450:
        return 30;
      case totalWorkedTime >= 450 && totalWorkedTime < 630:
        return 60;
      case totalWorkedTime >= 630 && totalWorkedTime < 810:
        return 90;
      case totalWorkedTime >= 810 && totalWorkedTime < 990:
        return 120;
      case totalWorkedTime >= 990:
        return 150;
      default:
        return 0;
    }
  }, [dayEndedAt, dayStartedAt]);

  const handleDefaultValues = useCallback(
    (): InputType => ({
      trackedItems: trackedTimeForToday,
      calculatedTimeEntries: [],
      breakTimeStartedAt: defaultBreakTimeStart,
      dayStartedAt: dayStartedAt || dayjs().toDate(),
      dayEndedAt: dayEndedAt.toDate(),
      requiredBreakTime,
    }),
    [
      dayEndedAt,
      dayStartedAt,
      defaultBreakTimeStart,
      requiredBreakTime,
      trackedTimeForToday,
    ],
  );

  return (
    <FormWizardBottomSheet<
      { stepNavigationProps: Partial<StepsNavigationProps> },
      InputType,
      unknown
    >
      name="finish-working-day-form-wizard"
      disablePadding
      enableDismissOnClose
      onSubmit={onSubmit}
      resetFormOnOpen
      onCancel={onClose}
      useData
      disableScroll
      hideBackendErrors
      initialValues={handleDefaultValues}
      successFullFormViewProps={{
        gifUrl: 'https://media.giphy.com/media/iXaXJVF0t72rm/giphy.gif',
        title: 'label.movedStorage.finished',
        color: colors.lila[100],
        labelId: 'label.storageIsMovedToANewLocation',
      }}
      extraTemplateProps={{
        stepNavigationProps: {
          dark: true,
          onCancel: onClose,
          onClose,
        },
      }}
      bottomSheetRef={bottomSheetRef}
      initialStepIndex={0}
      // renderTitle={renderTitle}
      wizardSteps={steps}
    />
  );

  return (
    <BottomSheet ref={bottomSheetRef}>
      <Box height="100%" width="100%" paddingBottom={4} gap={3}>
        <Box>
          <Typography
            variant="h4"
            fontWeight={700}
            color="white"
            textAlign="center"
          >
            <FormattedMessage id="label.stopWorkingDay" />
          </Typography>
        </Box>
        <Box>
          <Typography color="white">
            <FormattedMessage id="label.stopWorkingDay.description" />
          </Typography>
        </Box>
        <Box flex={1}>
          <Typography color="white" variant="subtitle1" paddingBottom={2}>
            <FormattedMessage id="label.stopWorkingDay.trackedTime" />
          </Typography>
          <FlashList<TimeTrackingItem>
            estimatedItemSize={88}
            renderItem={handleLoadTrackedTime}
            data={trackedTimeForToday}
          />
        </Box>
        <Box justifyContent="flex-end" flex={1}>
          <Button onPress={onStop}>
            <FormattedMessage id="label.stopWorkingDay.stopButton" />
          </Button>
        </Box>
      </Box>
    </BottomSheet>
  );
};

export default FinishWorkingDayFormWizardBottomSheet;
