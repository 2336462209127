import { InvoiceStatus } from '@bas/financial-domain/models';
import { colors } from '@bas/theme';
import { Badge, Chip } from '@bas/ui/native/atoms';
import { Typography } from '@bas/ui/native/base';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { View } from 'react-native';

export type InvoiceStatusChipProps = {
  invoiceStatus: InvoiceStatus;
};

const statusColors: {
  [key: string]: string;
} = {
  [InvoiceStatus.CONCEPT]: colors.blue[500],
  [InvoiceStatus.OPEN]: colors.blue[500],
  [InvoiceStatus.SCHEDULED]: colors.blue[500],
  [InvoiceStatus.EXPIRED]: colors.orange[700],
  [InvoiceStatus.REMINDED]: colors.red[700],
  [InvoiceStatus.PAID]: colors.green[300],
  [InvoiceStatus.IRRECOVERABLE]: colors.red[700],
};

const InvoiceStatusChip = ({
  invoiceStatus,
}: InvoiceStatusChipProps): ReactElement => (
  <Chip>
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      {statusColors[invoiceStatus] && (
        <Badge color={statusColors[invoiceStatus]} />
      )}
      <Typography overrideColor={colors.lila[700]} style={{ paddingLeft: 4 }}>
        <FormattedMessage id={`invoiceStatus.${invoiceStatus}`} />
      </Typography>
    </View>
  </Chip>
);

export default InvoiceStatusChip;
