import { EmailAddress, Uuid } from '@bas/value-objects';
import * as Yup from 'yup';

export type SignDocumentInputType = {
  name: string;
  emailAddress: EmailAddress;
  remarks?: string | null;
  signature: string;
  acceptedLines: Uuid[];
};

export const SignDocumentInputTypeValidationBuilder =
  (): Yup.ObjectSchema<SignDocumentInputType> =>
    Yup.object({
      name: Yup.string().max(200).required().label('label.name'),
      emailAddress: Yup.string().required().email().label('label.emailAddress'),
      remarks: Yup.string().label('label.remarks'),
      signature: Yup.string().required().label('label.signature'),
      acceptedLines: Yup.array()
        .of(Yup.string().required())
        .required()
        .label('label.acceptedLines'),
    });

export const SignDocumentInputTypeDefaultValues =
  (): SignDocumentInputType => ({
    name: '',
    emailAddress: '',
    signature: '',
    acceptedLines: [],
  });
