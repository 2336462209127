import { formatDate } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type EnableOrDisableClaCompensationRuleMutationProps = {
  ruleId: Uuid;
  enabledFrom: Date;
  enabledUntil?: Date;
};

export const EnableOrDisableClaCompensationRuleMutation = async ({
  enabledFrom,
  enabledUntil,
  ruleId,
  ...values
}: EnableOrDisableClaCompensationRuleMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/hrm/cla/compensation-rules/${ruleId}/update-enabled-from-or-until-dates`,
    {
      ...values,
      enabledFrom: formatDate(enabledFrom),
      enabledUntil: enabledUntil ? formatDate(enabledUntil) : undefined,
    },
  );

export const useEnableOrDisableClaCompensationRuleMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    EnableOrDisableClaCompensationRuleMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  EnableOrDisableClaCompensationRuleMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    EnableOrDisableClaCompensationRuleMutationProps
  >({
    mutationFn: EnableOrDisableClaCompensationRuleMutation,
    throwOnError: false,
    ...options,
  });
