import { CalculatedTotalsForAcceptanceQuote } from '@bas/financial-domain/models';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type CalculateTotalsForAcceptanceQuoteMutationProps = {
  quoteId: Uuid;
  relationId: Uuid;
  code: string;
  acceptedLines: Uuid[];
};

export const CalculateTotalsForAcceptanceQuoteMutation = async ({
  quoteId,
  relationId,
  code,
  ...values
}: CalculateTotalsForAcceptanceQuoteMutationProps): Promise<
  AxiosResponse<CalculatedTotalsForAcceptanceQuote>
> =>
  axios.put(
    `api/public/{tenantId}/quotes/acceptance/${quoteId}/${relationId}/${code}/calculate-totals`,
    {
      quoteId,
      relationId,
      code,
      ...values,
    },
  );

export const useCalculateTotalsForAcceptanceQuoteMutation = (
  options: UseMutationOptions<
    AxiosResponse<CalculatedTotalsForAcceptanceQuote>,
    AxiosError<ErrorResponse>,
    CalculateTotalsForAcceptanceQuoteMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse<CalculatedTotalsForAcceptanceQuote>,
  AxiosError<ErrorResponse>,
  CalculateTotalsForAcceptanceQuoteMutationProps
> =>
  useMutation<
    AxiosResponse<CalculatedTotalsForAcceptanceQuote>,
    AxiosError<ErrorResponse>,
    CalculateTotalsForAcceptanceQuoteMutationProps
  >({
    mutationFn: CalculateTotalsForAcceptanceQuoteMutation,
    throwOnError: false,
    ...options,
  });
