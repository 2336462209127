import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type RenamePackageMutationProps = {
  packageId: Uuid;
  packageName: string;
  ignoreZeroQuantityLines: boolean;
};

export const RenamePackageMutation = async ({
  packageId,
  ...values
}: RenamePackageMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/packages/${packageId}/change-name`, {
    packageId,
    ...values,
  });

export const useRenamePackageMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RenamePackageMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  RenamePackageMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RenamePackageMutationProps
  >({
    mutationFn: RenamePackageMutation,
    throwOnError: false,
    ...options,
  });
