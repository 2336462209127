import _set from 'lodash/set';
import _get from 'lodash/get';

export const mergeObjects = <T extends object>(...objects: Partial<T>[]): T => {
  const result: T = {} as T;

  objects.forEach((obj) => {
    Object.keys(obj).forEach((key) => {
      _set(result, key, _get(obj, key));
    });
  });

  return result;
};
