import { isObjectResponse } from '@bas/value-objects';
import { EventWithoutHoursEntry } from '../types';

export function isEventWithoutHoursEntry(
  object: unknown,
): object is EventWithoutHoursEntry {
  return (
    isObjectResponse(object) &&
    Object.prototype.hasOwnProperty.call(object, 'eventId') &&
    Object.prototype.hasOwnProperty.call(object, 'eventType')
  );
}
