import { formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ScheduleInvoiceMutationProps = {
  invoiceId: Uuid;
  scheduledAt: Date;
  expiresAt?: Date | null;
  reportingYear?: number;
  reportingPeriod?: number;
};

export const ScheduleInvoiceMutation = async ({
  invoiceId,
  scheduledAt,
  expiresAt,
  ...values
}: ScheduleInvoiceMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/invoices/${invoiceId}/schedule`, {
    invoiceId,
    scheduledAt: formatDateTime(scheduledAt),
    expiresAt: expiresAt ? formatDateTime(expiresAt) : null,
    ...values,
  });

export const useScheduleInvoiceMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ScheduleInvoiceMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ScheduleInvoiceMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ScheduleInvoiceMutationProps
  >({
    mutationFn: ScheduleInvoiceMutation,
    throwOnError: false,
    ...options,
  });
