export enum QuoteStatus {
  CONCEPT = 'concept',
  SENT = 'sent',
  EXPIRED = 'expired',
  DECLINED = 'declined',
  REMINDED = 'reminded',
  ACCEPTED = 'accepted',
  CANCELLED = 'cancelled',
  INVOICED = 'invoiced',
}
