import {
  Filterable,
  Pagination,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';

export type BulkInvoicesPdfRequestProps = Pagination &
  Filterable & {
    projectId?: Uuid;
    relation?: Uuid;
  };

export const BulkInvoicesPdfRequest = async ({
  ...params
}: BulkInvoicesPdfRequestProps): Promise<AxiosResponse<Blob>> =>
  axios.get(`api/{tenantId}/invoices/download-bulk-pdf`, {
    params: { ...params },
    paramsSerializer(param) {
      return Qs.stringify(param, { arrayFormat: 'brackets' });
    },
    responseType: 'blob',
  });

export const useBulkInvoicesPdfRequestQuery = (
  request: BulkInvoicesPdfRequestProps,
  options: QueryOptionsWithKey<
    AxiosResponse<Blob>,
    AxiosError,
    AxiosResponse<Blob>
  > = {},
): UseQueryResult<AxiosResponse<Blob>, AxiosError> =>
  useQuery<AxiosResponse<Blob>, AxiosError, AxiosResponse<Blob>>({
    ...options,
    queryFn: async () => BulkInvoicesPdfRequest(request),
    queryKey: ['invoices', 'bulk-pdf', request],
    gcTime: 0,
    staleTime: 0,
  });
