import * as Yup from 'yup';
import { Money } from '@bas/value-objects';

export type ApproveDeclarationInputType = {
  amount: Money;
  reason: string;
};

export const approveDeclarationInputTypeDefaultValues =
  (): ApproveDeclarationInputType => ({
    amount: {
      amount: 0,
      currency: 'EUR',
    },
    reason: '',
  });

export const approveDeclarationInputTypeValidationBuilder =
  (): Yup.ObjectSchema<ApproveDeclarationInputType> =>
    Yup.object({
      reason: Yup.string().label('label.reaction').ensure(),
      amount: Yup.object({
        amount: Yup.number()
          .transform((value, original) =>
            original === '' || original === null ? null : value,
          )
          .nullable()
          .required()
          .label('label.amount'),
        currency: Yup.string().required(),
      }),
    });
