import { isInvoiceEvent } from './InvoiceEvent';
import { InvoiceEventType, InvoiceFinalizedEvent } from '../types';

export function isInvoiceFinalizedEvent(
  object: unknown,
): object is InvoiceFinalizedEvent {
  return (
    isInvoiceEvent(object) &&
    object.eventType === InvoiceEventType.InvoiceFinalizedEvent
  );
}
