import { Box, Typography, TypographyProps } from '@bas/ui/native/base';
import { ReactElement, useCallback } from 'react';
import { AnimatedCircularProgress } from 'react-native-circular-progress';

export type ProgressValue = {
  value: number;
  color: string;
};

export type StackedCircularProgressProps = Partial<AnimatedCircularProgress> & {
  values: ProgressValue[];
  hideFirst?: boolean;
  title?: string;
  titleValue?: number;
  titleMaxValue?: number;
  titleColor?: string;
  titleStyle?: TypographyProps['style'];
  titleFontSize?: TypographyProps['fontSize'];
  subtitle?: string;
  subtitleStyle?: TypographyProps['style'];
  subtitleColor?: string;
  subtitleFontSize?: TypographyProps['fontSize'];
  inActiveStrokeColor?: string;
  progressValueColor?: string;
};

const StackedCircularProgress = ({
  values,
  hideFirst,
  titleValue,
  titleMaxValue,
  inActiveStrokeColor,
  progressValueColor,
  title,
  titleColor,
  titleStyle,
  titleFontSize,
  subtitle,
  subtitleStyle,
  subtitleColor,
  subtitleFontSize,
  ...props
}: StackedCircularProgressProps): ReactElement => {
  const renderTitleComponent = useCallback(
    () => (
      <Box justifyContent="center">
        <Typography
          textAlign="center"
          fontSize={titleFontSize || 20}
          fontWeight={700}
          style={titleStyle}
          overrideColor={titleColor}
        >
          {titleValue}
        </Typography>
        <Typography
          textAlign="center"
          overrideColor={titleColor}
          fontSize={titleFontSize || 20}
          style={titleStyle}
        >
          {title}
        </Typography>
        {subtitle && (
          <Typography
            textAlign="center"
            overrideColor={subtitleColor}
            fontSize={subtitleFontSize}
            style={subtitleStyle}
          >
            {subtitle}
          </Typography>
        )}
      </Box>
    ),
    [
      titleFontSize,
      titleStyle,
      titleColor,
      titleValue,
      title,
      subtitle,
      subtitleColor,
      subtitleFontSize,
      subtitleStyle,
    ],
  );

  return (
    <Box position="relative" width={120} height={120}>
      {(!!title || titleValue !== undefined) && (
        <AnimatedCircularProgress
          fill={((titleValue || 0) / (titleMaxValue || 0)) * 100}
          size={120}
          width={0}
          tintColor={titleColor}
          backgroundColor="transparent"
          lineCap="butt"
          rotation={0}
          {...props}
        >
          {renderTitleComponent}
        </AnimatedCircularProgress>
      )}
      {!hideFirst && (
        <Box
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
          }}
        >
          <AnimatedCircularProgress
            size={120}
            width={10}
            fill={0}
            {...props}
            tintColor={progressValueColor}
            backgroundColor={inActiveStrokeColor}
            lineCap="butt"
            rotation={0}
          />
        </Box>
      )}
      {values
        .sort(({ value: aValue }, { value: bValue }) =>
          aValue > bValue ? -1 : 1,
        )
        .map(({ value, color }) => (
          <Box
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              zIndex: 0,
            }}
            key={color}
          >
            <AnimatedCircularProgress
              {...props}
              fill={value}
              tintColor={color}
              backgroundColor="transparent"
              width={10}
              size={120}
              lineCap="butt"
              rotation={0}
            />
          </Box>
        ))}
    </Box>
  );
};

export default StackedCircularProgress;
