import type {
  CrmAddress,
  CrmContactPerson,
  Relation,
} from '@bas/crm-domain/models';
import type {
  BilledBillingMoment,
  BillingMoment,
  Package,
} from '@bas/shared/models';
import type { Money, PricingType, ProjectType, Uuid } from '@bas/value-objects';
import type { NeededInventoryItem } from '@bas/wms-domain/models';
import type { MovingJobStatus } from './MovingJobStatus';
import type { MovingLiftJobStatus } from './MovingLiftJobStatus';
import type { ProjectCost } from './ProjectCost';
import type { TransportJobStatus } from './TransportJobStatus';

export interface Project {
  projectId: Uuid;
  projectType: ProjectType;
  customer: Relation;
  tenantId: Uuid;
  identityId?: Uuid;
  projectCode?: string;
  name: string;
  notes?: string;
  customerNotes?: string;
  notesForEmployees?: string;
  package: Package;
  pricingType: PricingType;
  billingMoments: BillingMoment[];
  billedBillingMoments: BilledBillingMoment[];
  reference?: string;
  projectContactPerson?: CrmContactPerson;
  quoteContactPerson?: CrmContactPerson;
  invoiceContactPerson?: CrmContactPerson;
  invoiceAddress?: CrmAddress;
  quoteAddress?: CrmAddress;
  neededInventoryItems: NeededInventoryItem[];
  projectStatus: MovingJobStatus | MovingLiftJobStatus | TransportJobStatus;
  expectedRevenue?: Money;
  expectedCosts?: Money;
  realizedCosts?: Money;
  realizedRevenue?: Money;
  costs: ProjectCost[];
  createdAt: Date;
}
