import { colors } from '@bas/theme';
import { BottomSheetMethods, Box, Typography } from '@bas/ui/native/base';
import {
  SuccessFullFormView,
  SuccessFullFormViewProps,
} from '@bas/ui/native/molecules';
import { BottomSheet, BottomSheetProps } from '@bas/ui/native/organisms';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import {
  ReactElement,
  ReactNode,
  RefObject,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { MobilePageWithStepsNavigationTemplate } from '../MobilePageWithStepsNavigationTemplate';

export type ConfirmBottomSheetProps = Omit<
  BottomSheetProps,
  'snapPoints' | 'children'
> & {
  onCancel?: () => void | Promise<void>;
  closeOnCancel?: boolean;
  onConfirm: () => void | Promise<void>;
  bottomSheetRef?: RefObject<BottomSheetModal & BottomSheetMethods>;
  title: ReactNode | string;
  content: ReactNode | string;
  dangerous?: boolean;
  successFullFormViewProps?: SuccessFullFormViewProps;
};

const ConfirmBottomSheet = ({
  onConfirm,
  onCancel,
  closeOnCancel,
  bottomSheetRef,
  title,
  content,
  dangerous,
  successFullFormViewProps,
  ...props
}: ConfirmBottomSheetProps): ReactElement => {
  const [isConfirming, setIsConfirming] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const onClose = useCallback(() => {
    bottomSheetRef?.current?.close();
    bottomSheetRef?.current?.forceClose();
    bottomSheetRef?.current?.dismiss();
  }, [bottomSheetRef]);

  const handleConfirm = useCallback(async () => {
    setIsConfirming(true);
    await onConfirm();
    setIsConfirmed(true);
    setIsConfirming(false);
  }, [onConfirm]);

  const realContent = useMemo(
    () => (
      <Box flex={1}>
        <Box alignSelf="center">
          <Typography
            fontWeight={700}
            overrideColor={colors.white}
            fontSize={22}
            lineHeight={29}
          >
            {title}
          </Typography>
        </Box>
        <Box paddingHorizontal={20} paddingTop={4}>
          <Typography color="white">{content}</Typography>
        </Box>
      </Box>
    ),
    [title, content],
  );

  const handleChange = useCallback((index: number) => {
    if (index === -1) {
      setIsConfirmed(false);
    }
  }, []);

  return (
    <BottomSheet
      ref={bottomSheetRef}
      disablePadding
      enableDismissOnClose
      onChange={handleChange}
      {...props}
    >
      <MobilePageWithStepsNavigationTemplate
        inBottomSheet
        disablePadding
        stepsNavigation={{
          dark: true,
          steps: [],
          allowCancel: true,
          allowNext: true,
          allowPrevious: false,
          onNext: handleConfirm,
          onPrevious: onClose,
          onCancel: closeOnCancel ? onClose : onCancel,
          onClose,
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onValidate: () => {},
          isSubmit: true,
          loading: isConfirming,
          finalize: isConfirmed,
        }}
      >
        {successFullFormViewProps ? (
          <Box paddingHorizontal={20}>
            <SuccessFullFormView
              isSuccessFull={isConfirmed}
              {...successFullFormViewProps}
            >
              {realContent}
            </SuccessFullFormView>
          </Box>
        ) : (
          realContent
        )}
      </MobilePageWithStepsNavigationTemplate>
    </BottomSheet>
  );
};

export default ConfirmBottomSheet;
