import { EmployeeProjectRole } from '../types';

export const sortEmployeeProjectRole = (
  projectRoleA: EmployeeProjectRole,
  projectRoleB: EmployeeProjectRole,
): number => {
  if (projectRoleA === projectRoleB) {
    return 0;
  }

  if (projectRoleA === EmployeeProjectRole.FOREMAN) {
    return -1;
  }

  if (
    projectRoleA === EmployeeProjectRole.APPRAISER &&
    projectRoleB !== EmployeeProjectRole.FOREMAN
  ) {
    return -1;
  }

  if (
    projectRoleA === EmployeeProjectRole.HANDYMAN &&
    projectRoleB !== EmployeeProjectRole.FOREMAN
  ) {
    return -1;
  }

  if (
    projectRoleA === EmployeeProjectRole.PACKER &&
    projectRoleB !== EmployeeProjectRole.FOREMAN
  ) {
    return -1;
  }

  if (
    projectRoleA === EmployeeProjectRole.CO_DRIVER &&
    projectRoleB !== EmployeeProjectRole.FOREMAN
  ) {
    return -1;
  }

  return 1;
};
