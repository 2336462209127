import type { IssueWithSyncingExternalEmployeeTask } from '../types';
import { TaskType } from '../types';
import { isTask } from './Task';

export function isIssueWithSyncingExternalEmployeeTask(
  object: unknown,
): object is IssueWithSyncingExternalEmployeeTask {
  return (
    !!object &&
    isTask(object) &&
    object.taskType === TaskType.ISSUE_WITH_SYNCING_EXTERNAL_EMPLOYEE_TASK
  );
}
