import {
  RecurringInformationInputType,
  RecurringInformationInputTypeDefaultValues,
  RecurringInformationInputTypeValidationSchema,
} from '@bas/shared/input-types';
import { EventType, Uuid } from '@bas/value-objects';
import dayjs, { Dayjs } from 'dayjs';
import * as Yup from 'yup';
import { AnyObjectSchema } from 'yup';

export interface EventDetailsInputType {
  eventType: EventType;
  start?: Date | null;
  end?: Date | null;
  travelStart?: Date | null;
  travelEnd?: Date | null;
  fullDay?: boolean | null;
  notes?: string | null;
  relation?: {
    relationId?: Uuid;
    name?: string | null;
  };
  name?: string | null;
  description?: string | null;
  reason?: string | null;
  durationInSeconds?: number | null;
  distanceInMeters?: number | null;
  automaticTravelTime: boolean;
  travelInformation?: {
    distanceToEventInMeters: number | null;
    durationToEventInSeconds: number | null;
    distanceBackFromEventInMeters: number | null;
    durationBackFromEventInSeconds: number | null;
  };
  isRecurring: boolean;
  recurringInformation?: RecurringInformationInputType;
}

export const EventDetailsInputTypeDefaultValues = (
  date?: Date | Dayjs,
): EventDetailsInputType => ({
  eventType: EventType.UNKNOWN,
  start: null,
  end: null,
  travelStart: null,
  travelEnd: null,
  fullDay: false,
  durationInSeconds: null,
  distanceInMeters: null,
  automaticTravelTime: true,
  travelInformation: {
    distanceToEventInMeters: 0,
    durationToEventInSeconds: 0,
    distanceBackFromEventInMeters: 0,
    durationBackFromEventInSeconds: 0,
  },
  isRecurring: false,
  recurringInformation: RecurringInformationInputTypeDefaultValues(date),
});

export const EventDetailsInputTypeValidationBuilder = (
  allowedEventTypes?: EventType[],
  existingRepeatFromDate?: Date,
): Yup.ObjectSchema<EventDetailsInputType> =>
  Yup.object({
    eventType: Yup.mixed<EventType>()
      .label('label.eventType')
      .oneOf(
        allowedEventTypes ||
          Object.values(EventType).filter((option) => !!option),
      )
      .required(),
    notes: Yup.string().nullable().label('label.notes'),
    name: Yup.string()
      .when(['eventType'], ([eventType], schema) => {
        if (!eventType || eventType !== EventType.CUSTOM_EVENT) {
          return schema;
        }

        return schema.required();
      })
      .nullable()
      .label('label.name'),
    description: Yup.string().nullable().label('label.description'),
    reason: Yup.string()
      .when(['eventType'], ([eventType], schema) => {
        if (
          !eventType ||
          ![
            EventType.HRM_OTHER_ABSENCE_EVENT,
            EventType.MATERIAL_OTHER_ABSENCE_EVENT,
          ].includes(eventType)
        ) {
          return schema;
        }

        return schema.required();
      })
      .nullable()
      .label('label.reason'),
    travelStart: Yup.date()
      .nullable()
      .label('label.departureTime')
      .when(['start'], ([start], schema) => {
        if (!start || !dayjs(start).isValid()) {
          return schema;
        }

        return schema.max(start, 'Vertrektijd moet voor de starttijd liggen');
      }),
    travelEnd: Yup.date()
      .nullable()
      .label('label.arrivalTimeWarehouse')
      .when(['end'], ([end], schema) => {
        if (!end || !dayjs(end).isValid()) {
          return schema;
        }

        return schema.min(
          end,
          'Aankomsttijd bij de loods moet na de eindtijd liggen',
        );
      }),
    start: Yup.date().nullable().required().label('label.start'),
    relation: Yup.object()
      .label('label.relation')
      .when(['eventType'], ([eventType], schema) => {
        if (
          !eventType ||
          ![
            EventType.LIFT_WORK_EVENT,
            EventType.LENDING_STAFF_EVENT,
            EventType.TRANSPORT_EVENT,
          ].includes(eventType)
        ) {
          return Yup.object({
            relationId: Yup.string().nullable().optional(),
          }).nullable();
        }

        return schema
          .concat(
            Yup.object({
              relationId: Yup.string()
                .required()
                .label('label.relation')
                .nullable(),
            }),
          )
          .required();
      }),
    fullDay: Yup.boolean().nullable().label('label.fullDay'),
    end: Yup.date()
      .nullable()
      .when(['start'], ([start], schema) => {
        if (!start || !dayjs(start).isValid()) {
          return schema;
        }

        return schema.min(start);
      })
      .required()
      .label('label.end'),
    durationInSeconds: Yup.number().nullable().label('label.durationInSeconds'),
    distanceInMeters: Yup.number().nullable().label('label.distanceInMeters'),
    travelInformation: Yup.object({
      distanceToEventInMeters: Yup.number()
        .nullable()
        .defined()
        .label('label.distanceToEventInMeters'),
      durationToEventInSeconds: Yup.number()
        .nullable()
        .defined()
        .label('label.durationToEventInSeconds'),
      distanceBackFromEventInMeters: Yup.number()
        .nullable()
        .defined()
        .label('label.distanceBackFromEventInMeters'),
      durationBackFromEventInSeconds: Yup.number()
        .nullable()
        .defined()
        .label('label.durationBackFromEventInSeconds'),
    }).label('label.travelInformation'),
    automaticTravelTime: Yup.boolean()
      .required()
      .label('label.automaticTravelTime'),
    isRecurring: Yup.boolean().required().label('label.isRecurring'),
    recurringInformation: RecurringInformationInputTypeValidationSchema(
      existingRepeatFromDate,
    )
      .label('label.recurringInformation')
      .when(['isRecurring'], ([isRecurring], schema) => {
        if (!isRecurring) {
          return Yup.object() as AnyObjectSchema;
        }

        return schema.required();
      }),
  });
