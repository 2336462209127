import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  content: {
    paddingHorizontal: 20,
  },
  paddingTop: {
    paddingTop: 32,
  },
  label: {
    paddingBottom: 8,
  },
});
