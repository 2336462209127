import type { BuildingType } from '@bas/value-objects';
import type { MovingJobAddressType } from './MovingJobAddressType';
import type { ProjectAddress } from './ProjectAddress';

export interface MovingJobAddress extends ProjectAddress {
  addressType: MovingJobAddressType;
  buildingType?: BuildingType;
  primary: boolean;
  deliverBoxes: boolean;
  retrieveBoxes: boolean;
  distanceFromCar?: number | null;
  distanceToApartment?: number | null;
  floor?: number | null;
  hasElevator?: boolean | null;
  trafficSigns?: boolean | null;
  permitNeeded?: boolean | null;
  unknownAddress?: boolean;
  notes?: string | null;
}
