import { useEmployeesRequest } from '@bas/hrm-domain/requests';
import { AddressBlock, Skeleton } from '@bas/ui/native/atoms';
import { BottomSheetMethods, Box, Typography } from '@bas/ui/native/base';
import { BottomSheet, BottomSheetProps } from '@bas/ui/native/organisms';
import { Uuid, VolumeType } from '@bas/value-objects';
import {
  useStorageByStorageIdRequestQuery,
  useStoredItemsRequest,
  useWarehouseByWarehouseIdRequestQuery,
} from '@bas/wms-domain/requests';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import dayjs from 'dayjs';
import * as React from 'react';
import { ReactElement, RefObject, useCallback, useMemo } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

export type ShowStoredItemBottomSheetProps = Omit<
  BottomSheetProps,
  'snapPoints' | 'children'
> & {
  bottomSheetRef?: RefObject<BottomSheetModal & BottomSheetMethods>;
};

const ShowStoredItemBottomSheetContent = ({
  storageId,
  itemId,
}: {
  storageId: Uuid;
  itemId: Uuid;
}): ReactElement => {
  const { data: storageData } = useStorageByStorageIdRequestQuery({
    storageId,
  });

  const { data: storedItemsData } = useStoredItemsRequest({
    storageId: storageId || '',
  });

  const storedItems = useMemo(
    () => storedItemsData?.data?.member || [],
    [storedItemsData?.data],
  );

  const storage = useMemo(() => {
    if (storageData?.data === undefined) {
      return null;
    }

    return storageData?.data;
  }, [storageData?.data]);

  const { data: warehouseData, isPending: isLoadingWarehouse } =
    useWarehouseByWarehouseIdRequestQuery(
      {
        warehouseId: storage?.warehouseId || '',
      },
      {
        enabled: !!storage?.warehouseId,
      },
    );

  const warehouse = useMemo(() => {
    if (warehouseData?.data === undefined) {
      return null;
    }

    return warehouseData?.data;
  }, [warehouseData?.data]);

  const storedItem = useMemo(() => {
    if (storedItems.length === 0) {
      return null;
    }

    return storedItems.find((s) => s.itemId === itemId);
  }, [storedItems, itemId]);

  const { data: employeesData } = useEmployeesRequest({
    perPage: 9999,
  });

  const employees = useMemo(
    () => employeesData?.data?.member || [],
    [employeesData],
  );

  const loadedByEmployee = useMemo(
    () =>
      employees.find((e) => e.employeeId === storedItem?.loadedByEmployeeId),
    [employees, storedItem?.loadedByEmployeeId],
  );

  const unloadedByEmployee = useMemo(
    () =>
      employees.find((e) => e.employeeId === storedItem?.unloadedByEmployeeId),
    [employees, storedItem?.unloadedByEmployeeId],
  );

  return (
    <Box flex={1} paddingHorizontal={20} gap={3}>
      <Box flexDirection="row" flexWrap="wrap" rowGap={3}>
        <Box width="100%">
          <Typography
            color="white"
            variant="h4"
            fontWeight={700}
            textAlign="center"
          >
            {storedItem?.description}
          </Typography>
          <Typography variant="h5" textAlign="center" color="white">
            {storedItem?.storageCode} - {storage?.contentOwnerName}
          </Typography>
        </Box>

        <Box width={{ phone: '100%', tablet: '50%' }}>
          <Typography variant="subtitle1" color="white">
            <FormattedMessage id="label.details" />
          </Typography>
          <Typography color="white">{storedItem?.description}</Typography>
          {storedItem?.itemCode && (
            <Typography color="white">
              <FormattedMessage id="label.itemCode" />
              :&nbsp;{storedItem?.itemCode}
            </Typography>
          )}
          {storedItem?.externalItemCode && (
            <Typography color="white">
              <FormattedMessage id="label.externalItemCode" />: &nbsp;
              {storedItem?.externalItemCode}
            </Typography>
          )}
          <Typography color="white">
            {storedItem?.loadedOn ? (
              <FormattedMessage
                id="label.loadedOnDate"
                values={{
                  date: (
                    <FormattedDate
                      value={dayjs(storedItem?.loadedOn).toDate()}
                      dateStyle="short"
                    />
                  ),
                }}
              />
            ) : (
              <FormattedMessage id="label.notLoaded" />
            )}
          </Typography>
          {storedItem?.loadedByEmployeeId && (
            <Typography color="white">
              <FormattedMessage id="label.loadedByEmployeeId" />
              {`:\n${loadedByEmployee?.personName?.fullName}`}
            </Typography>
          )}
          {storedItem?.loadedByCustomer && (
            <Typography color="white">
              <FormattedMessage id="label.loadedByCustomer" />
            </Typography>
          )}
          {storedItem?.unloadedOn && (
            <Typography color="white">
              <FormattedMessage
                id="label.unloadedOnDate"
                values={{
                  date: (
                    <FormattedDate
                      value={dayjs(storedItem?.unloadedOn).toDate()}
                      dateStyle="short"
                    />
                  ),
                }}
              />
            </Typography>
          )}
          {storedItem?.unloadedByEmployeeId && (
            <Typography color="white">
              <FormattedMessage id="label.unloadedByEmployeeId" />:
              {`:\n${unloadedByEmployee?.personName?.fullName}`}
            </Typography>
          )}
          {storedItem?.volume && (
            <Typography color="white">
              {storedItem?.volume}
              {storage?.volumeType === VolumeType.SQUARE_METER ? (
                <>&#13217;</>
              ) : (
                <>&#13221;</>
              )}
            </Typography>
          )}
        </Box>

        <Box width={{ phone: '100%', tablet: '50%' }}>
          <Typography variant="subtitle1" color="white">
            <FormattedMessage id="label.storageInformation" />
          </Typography>
          <Typography color="white">{storage?.contentOwnerName}</Typography>
          <Typography color="white">
            <FormattedMessage id={`storageTypes.${storage?.storageType}`} />
          </Typography>
          {storage?.warehouseId && (
            <Typography color="white">{warehouse?.name}</Typography>
          )}
          {storage?.storageLocationId && (
            <Typography color="white">
              {storage?.storageLocationName} ({storage?.storageLocationCode})
            </Typography>
          )}
          <Typography color="white">
            <FormattedMessage id="label.sealNumber" />:{' '}
            {storage?.sealNumber || <FormattedMessage id="label.notSealed" />}
          </Typography>
          <Typography color="white">
            {storage?.percentageFull}% <FormattedMessage id="label.full" />
          </Typography>
          <Typography color="white">
            {storage?.cubicMeter}
            {storage?.volumeType === VolumeType.SQUARE_METER ? (
              <>&#13217;</>
            ) : (
              <>&#13221;</>
            )}
          </Typography>
          <Typography color="white">
            <FormattedMessage
              id={`${storage?.selfOwned ? 'label.selfOwned' : 'label.rented'}`}
            />
          </Typography>
        </Box>
        {storedItem?.loadedOn && !storedItem.unloadedOn && (
          <Box width={{ phone: '100%', tablet: '50%' }}>
            <Typography variant="subtitle1" color="white">
              <FormattedMessage id="label.location" />
            </Typography>
            {isLoadingWarehouse && <Skeleton width={500} height={300} light />}
            {storage && !storage.warehouseId && (
              <Typography color="white">
                {storedItem?.storageLocationId ? (
                  <Typography color="white">
                    {storedItem?.storageLocationName} (
                    {storedItem?.storageLocationCode})
                  </Typography>
                ) : (
                  <FormattedMessage id="label.itemIsInStorage" />
                )}
              </Typography>
            )}
            {storage?.warehouseId && warehouse && (
              <>
                <Typography color="white">{warehouse.name}</Typography>
                <Typography color="white">
                  {storedItem?.storageLocationId ? (
                    `${storedItem?.storageLocationName} (${storedItem?.storageLocationCode})`
                  ) : (
                    <FormattedMessage id="label.itemIsInStorage" />
                  )}
                </Typography>
                <AddressBlock address={warehouse.address} color="white" />
              </>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

const ShowStoredItemBottomSheet = ({
  bottomSheetRef,
  ...props
}: ShowStoredItemBottomSheetProps): ReactElement => {
  const onClose = useCallback(() => {
    bottomSheetRef?.current?.close();
    bottomSheetRef?.current?.forceClose();
    bottomSheetRef?.current?.dismiss();
  }, [bottomSheetRef]);

  const handleRender = useCallback(
    (
      incomingData: { data?: { storageId: Uuid; itemId: Uuid } } | undefined,
    ) => {
      const storageId = incomingData?.data?.storageId;
      const itemId = incomingData?.data?.itemId;
      return (
        <ShowStoredItemBottomSheetContent
          storageId={storageId || ''}
          itemId={itemId || ''}
        />
      );
    },
    [],
  );

  return (
    <BottomSheet
      ref={bottomSheetRef}
      disablePadding
      enableDismissOnClose
      onDismiss={onClose}
      {...props}
    >
      {handleRender}
    </BottomSheet>
  );
};

export default ShowStoredItemBottomSheet;
