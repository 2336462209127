import { Project } from '@bas/project-domain/models';
import { useDamagesRequest } from '@bas/project-domain/requests';
import { DamageInputType } from '@bas/shared/input-types';
import { Box, Typography } from '@bas/ui/native/base';
import { ReactElement, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

export type DamagesSigningDataItemsProps = {
  project: Project;
  damagesToProcess: DamageInputType[];
  wereThereAnyParticulars?: boolean;
  particulars?: string | null;
};

const DamagesSigningDataItems = ({
  project,
  damagesToProcess,
  wereThereAnyParticulars,
  particulars,
}: DamagesSigningDataItemsProps): ReactElement => {
  const { data: damagesData } = useDamagesRequest({
    projectId: project.projectId,
    perPage: 9999,
  });

  const damages = useMemo(
    () => [
      ...(wereThereAnyParticulars ? damagesToProcess : []),
      ...(damagesData?.data?.member || []),
    ],
    [wereThereAnyParticulars, damagesData?.data, damagesToProcess],
  );
  const damagesAlreadyThere = useMemo(
    () => damages.filter(({ damageWasAlreadyThere }) => damageWasAlreadyThere),
    [damages],
  );
  const newDamages = useMemo(
    () => damages.filter(({ damageWasAlreadyThere }) => !damageWasAlreadyThere),
    [damages],
  );

  return (
    <>
      {wereThereAnyParticulars && particulars && (
        <>
          <Box pt={2}>
            <Typography color="white">
              <FormattedMessage id="label.thereWereTheFollowingParticulars" />
            </Typography>
          </Box>
          <Box>
            <Typography color="white">{particulars}</Typography>
          </Box>
        </>
      )}
      {newDamages.length > 0 && (
        <>
          <Box pt={2}>
            <Typography color="white">
              <FormattedMessage id="label.theFollowingDamagesAreMade" />
            </Typography>
          </Box>
          {newDamages.map((damage) => (
            <Box key={damage.damageId}>
              <Typography color="white">{`- ${damage.summary}`}</Typography>
            </Box>
          ))}
        </>
      )}
      {damagesAlreadyThere.length > 0 && (
        <>
          <Box pt={2}>
            <Typography color="white">
              <FormattedMessage id="label.theFollowingDamagesWereAlreadyThere" />
            </Typography>
          </Box>
          {damagesAlreadyThere.map((damage) => (
            <Box key={damage.damageId}>
              <Typography color="white">{`- ${damage.summary}`}</Typography>
            </Box>
          ))}
        </>
      )}
    </>
  );
};

export default DamagesSigningDataItems;
