import { ReactElement } from 'react';
import { useWatch } from 'react-hook-form';
import { ScrollView } from 'react-native-gesture-handler';
import { FinancialDocumentCustomerFormPart } from '../FinancialDocumentCustomerFormPart';

export type FinancialDocumentCustomerFormStepProps = {
  documentType: 'quote' | 'invoice';
  prefix?: string;
  light?: boolean;
};

const FinancialDocumentCustomerFormStep = ({
  documentType,
  prefix,
  light,
}: FinancialDocumentCustomerFormStepProps): ReactElement => {
  const [relation, selectedAddressId, selectedContactPersonId] = useWatch({
    name: [
      `${prefix}.relation`,
      `${prefix}.addressId`,
      `${prefix}.contactPersonId`,
    ],
  });

  return (
    <ScrollView>
      <FinancialDocumentCustomerFormPart
        documentType={documentType}
        relation={relation}
        selectedAddressId={selectedAddressId || ''}
        selectedContactPersonId={selectedContactPersonId}
        prefix={prefix}
        light={light}
      />
    </ScrollView>
  );
};

export default FinancialDocumentCustomerFormStep;
