import { EmailAddress, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type SendInvoiceMutationProps = {
  invoiceId: Uuid;
  extraEmailAddresses?: EmailAddress[];
};

export const SendInvoiceMutation = async ({
  invoiceId,
  ...values
}: SendInvoiceMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/invoices/${invoiceId}/send`, {
    invoiceId,
    ...values,
  });

export const useSendInvoiceMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    SendInvoiceMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  SendInvoiceMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    SendInvoiceMutationProps
  >({
    mutationFn: SendInvoiceMutation,
    throwOnError: false,
    ...options,
  });
