import { isBillingMoment } from './BillingMoment';
import { BillingMomentWhenProjectIsFinished } from '../types';

export function isBillingMomentWhenProjectIsFinished(
  object: unknown,
): object is BillingMomentWhenProjectIsFinished {
  return (
    !!object &&
    isBillingMoment(object) &&
    Object.prototype.hasOwnProperty.call(object, 'billQuoteLines')
  );
}
