import { formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type PostNewsMutationProps = {
  newsId: Uuid;
  title: string;
  body: string;
  publishedAt?: Date | null;
};

export const PostNewsMutation = async ({
  newsId,
  publishedAt,
  ...values
}: PostNewsMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/{tenantId}/news`, {
    newsId,
    publishedAt: publishedAt ? formatDateTime(publishedAt) : null,
    ...values,
  });

export const usePostNewsMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    PostNewsMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  PostNewsMutationProps
> =>
  useMutation<AxiosResponse, AxiosError<ErrorResponse>, PostNewsMutationProps>({
    mutationFn: PostNewsMutation,
    throwOnError: false,
    ...options,
  });
