import { BottomSheetMethods } from '@bas/ui/native/base';
import { BottomSheetProps } from '@bas/ui/native/organisms';
import { FormBottomSheet } from '@bas/ui/native/templates';
import {
  StoredItemInputType,
  storedItemInputTypeDefaultValues,
  storedItemInputTypeValidationBuilder,
} from '@bas/wms-domain/input-types';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { ReactElement, RefObject, useCallback } from 'react';
import { AddExtraStoredItemForm } from '../AddExtraStoredItemForm';

export type AddExtraStoredItemFormBottomSheetProps = Omit<
  BottomSheetProps,
  'snapPoints' | 'children'
> & {
  bottomSheetRef?: RefObject<BottomSheetModal & BottomSheetMethods>;
  onSubmit: (data: StoredItemInputType) => void;
};

const AddExtraStoredItemFormBottomSheet = ({
  bottomSheetRef,
  onSubmit,
  ...props
}: AddExtraStoredItemFormBottomSheetProps): ReactElement => {
  const onClose = useCallback(() => {
    bottomSheetRef?.current?.close();
    bottomSheetRef?.current?.forceClose();
    bottomSheetRef?.current?.dismiss();
  }, [bottomSheetRef]);

  return (
    <FormBottomSheet<StoredItemInputType>
      bottomSheetRef={bottomSheetRef}
      disablePadding
      enableDismissOnClose
      onSubmit={onSubmit}
      useProvider
      resetFormOnOpen
      onCancel={onClose}
      disableScroll
      defaultValues={{
        ...storedItemInputTypeDefaultValues(),
      }}
      hideBackendErrors
      validationSchema={storedItemInputTypeValidationBuilder}
      {...props}
    >
      <AddExtraStoredItemForm />
    </FormBottomSheet>
  );
};

export default AddExtraStoredItemFormBottomSheet;
