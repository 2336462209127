import {
  useEmployeeStore,
  useTenantStore,
  useUserStore,
} from '@bas/shared/state';
import * as Sentry from '@sentry/react-native';
import { useEffect } from 'react';
import { Platform } from 'react-native';

export default () => {
  const user = useUserStore((state) => state.user);

  const tenantId = useTenantStore((state) => state.tenant?.tenantId);

  const employeeId = useEmployeeStore((state) => state.employee?.employeeId);

  useEffect(() => {
    if (Platform.OS === 'web' && window.location.hostname.split('.')[0]) {
      Sentry.setTag('tenant', window.location.hostname.split('.')[0]);
    }
  }, []);

  useEffect(() => {
    Sentry.setTag('tenant_id', tenantId);
  }, [tenantId]);

  useEffect(() => {
    Sentry.setTag('employee_id', employeeId);
  }, [employeeId]);

  useEffect(() => {
    Sentry.setUser(
      user
        ? {
            id: user.userId as string,
            email: user.emailAddress as string,
            username: user.emailAddress as string,
          }
        : null,
    );
  }, [user]);
};
