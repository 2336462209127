import { Money } from '@bas/value-objects';
import * as Yup from 'yup';

export interface InventoryItemInformationInputType {
  name: string;
  expiresAt?: Date | null;
  rentalPrice: Money;
  sellingPrice: Money;
  weight: number;
}

export const InventoryItemInformationInputTypeDefaultValues =
  (): InventoryItemInformationInputType => ({
    name: '',
    expiresAt: null,
    rentalPrice: {
      amount: 0,
      currency: 'EUR',
    },
    sellingPrice: {
      amount: 0,
      currency: 'EUR',
    },
    weight: 0,
  });

export const InventoryItemInformationInputTypeValidationBuilder =
  (): Yup.ObjectSchema<InventoryItemInformationInputType> =>
    Yup.object({
      name: Yup.string().max(200).required().label('label.name'),
      expiresAt: Yup.date().nullable().label('label.expiresAt'),
      weight: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .nullable()
        .min(0)
        .required()
        .label('label.sortingWeight'),
      rentalPrice: Yup.object({
        amount: Yup.number()
          .transform((value, original) =>
            original === '' || original === null ? null : value,
          )
          .nullable()
          .required()
          .label('label.rentalPrice'),
        currency: Yup.string().required(),
      })
        .required()
        .label('label.rentalPrice'),
      sellingPrice: Yup.object({
        amount: Yup.number()
          .transform((value, original) =>
            original === '' || original === null ? null : value,
          )
          .nullable()
          .required()
          .label('label.sellingPrice'),
        currency: Yup.string().required(),
      })
        .required()
        .label('label.sellingPrice'),
    });
