import { AddressInputType } from '@bas/shared/input-types';
import { EmployeeProjectRole, EventType, Uuid } from '@bas/value-objects';
import dayjs from 'dayjs';
import { v7 } from 'uuid';
import { ProjectEventDetailsInputType } from './projectEventDetailsInputType';

export interface EventDtoInputType {
  eventId: Uuid;
  eventType: EventType;
  start: Date;
  end: Date;
  travelStart?: Date | null;
  travelEnd?: Date | null;
  neededRoles: EmployeeProjectRole[];
  location?: AddressInputType;
  notes?: string | null;
  departureLocation: AddressInputType;
  automaticTravelTime?: boolean | null;
  recurringInformation?: {
    isRecurring: boolean;
    rrule: string;
    repeatFrom: Date;
    repeatUntil: Date;
  };
  date?: Date;
  updateFutureEvents?: boolean;
}

export function isEventDtoInputType(
  object: unknown,
): object is EventDtoInputType {
  return (
    !!object &&
    Object.prototype.hasOwnProperty.call(object, 'eventId') &&
    Object.prototype.hasOwnProperty.call(object, 'eventType') &&
    Object.prototype.hasOwnProperty.call(object, 'start') &&
    Object.prototype.hasOwnProperty.call(object, 'end') &&
    Object.prototype.hasOwnProperty.call(object, 'neededRoles')
  );
}

export const createEventDtoInputTypeFromProjectEventDetails = ({
  start,
  end,
  neededRoles,
  eventId,
  isRecurring,
  recurringInformation,
  ...event
}: ProjectEventDetailsInputType & {
  eventId?: Uuid;
  date?: Date;
  updateFutureEvents?: boolean;
}): EventDtoInputType => {
  const neededRolesArray: EmployeeProjectRole[] = [];
  Object.values(neededRoles).forEach(({ role, value }) => {
    neededRolesArray.push(...new Array(value || 0).fill(role));
  });

  return {
    ...event,
    start: start || new Date(),
    end: end || new Date(),
    eventId: eventId || v7(),
    neededRoles: neededRolesArray,
    recurringInformation:
      isRecurring && recurringInformation?.rrule
        ? {
            isRecurring,
            rrule: recurringInformation.rrule as string,
            repeatFrom: recurringInformation.repeatFrom
              ? dayjs(recurringInformation.repeatFrom).toDate()
              : (null as unknown as Date),
            repeatUntil: recurringInformation.repeatUntil
              ? dayjs(recurringInformation.repeatUntil).toDate()
              : (null as unknown as Date),
          }
        : undefined,
  };
};
