import * as Yup from 'yup';
import {
  MovingLiftInformationInputType,
  MovingLiftInformationInputTypeDefaultValues,
  MovingLiftInformationInputTypeValidationBuilder,
} from './movingLiftInformationInputType';
import {
  MovingTruckInformationInputType,
  MovingTruckInformationInputTypeDefaultValues,
  MovingTruckInformationInputTypeValidationBuilder,
} from './movingTruckInformationInputType';
import {
  VehicleInformationInputType,
  VehicleInformationInputTypeDefaultValues,
  VehicleInformationInputTypeValidationBuilder,
} from './vehicleInformationInputType';

export type CreateVehicleInputType = {
  vehicleType: 'vehicle' | 'moving-truck' | 'moving-lift' | '';
} & (
  | VehicleInformationInputType
  | MovingTruckInformationInputType
  | MovingLiftInformationInputType
);

export const CreateVehicleInputTypeDefaultValues =
  (): CreateVehicleInputType => ({
    vehicleType: '',
    ...VehicleInformationInputTypeDefaultValues(),
    ...MovingTruckInformationInputTypeDefaultValues(),
    ...MovingLiftInformationInputTypeDefaultValues(),
  });

export const CreateVehicleInputTypeValidationBuilder =
  (): Yup.ObjectSchema<CreateVehicleInputType> =>
    Yup.object({
      vehicleType: Yup.string()
        .required()
        .oneOf(['vehicle', 'moving-truck', 'moving-lift'])
        .label('label.vehicleType'),
    })
      .concat(VehicleInformationInputTypeValidationBuilder())
      .when(['vehicleType'], ([vehicleType], schema) => {
        if (vehicleType === 'moving-truck') {
          return schema.concat(
            MovingTruckInformationInputTypeValidationBuilder(),
          );
        }
        if (vehicleType === 'moving-lift') {
          return schema.concat(
            MovingLiftInformationInputTypeValidationBuilder(),
          );
        }

        return schema;
      });
