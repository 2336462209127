/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useEffect } from 'react';
import {
  FieldPath,
  FieldValues,
  PathValue,
  useFormContext,
  useWatch,
} from 'react-hook-form';

export const useSyncDates = <TFieldValues extends FieldValues = FieldValues>(
  dateKey: FieldPath<TFieldValues>,
  keys: FieldPath<TFieldValues>[],
  forceSameDay = false,
) => {
  const { setValue } = useFormContext<TFieldValues>();
  const dateValue = useWatch<TFieldValues>({
    name: dateKey,
  });
  const values = useWatch<TFieldValues>({
    name: keys,
  });

  const makeSameDate = useCallback(
    (date: Dayjs | null, time: Date | null) =>
      dayjs(date)
        .set('hour', dayjs(time).hour())
        .set('minute', dayjs(time).minute())
        .set('second', dayjs(time).second())
        .toDate(),
    [],
  );

  const handleChange = useCallback(
    (date: Dayjs | null) => {
      const currentStart = dayjs(dateValue);

      setValue(
        dateKey,
        date as PathValue<TFieldValues, FieldPath<TFieldValues>>,
        { shouldValidate: true, shouldDirty: true, shouldTouch: true },
      );
      if (currentStart.isSame(date, 'day')) {
        return;
      }

      keys.forEach((key, index) => {
        const value = values[index];

        if (!!value && currentStart.isSame(value, 'day')) {
          setValue(
            key,
            makeSameDate(date, value) as PathValue<
              TFieldValues,
              FieldPath<TFieldValues>
            >,
            {
              shouldValidate: false,
              shouldDirty: true,
              shouldTouch: true,
            },
          );
        }
      });
    },
    [dateKey, dateValue, keys, makeSameDate, setValue, values],
  );

  useEffect(() => {
    if (!forceSameDay) {
      return;
    }

    values.forEach((value, index) => {
      if (
        !!value &&
        dayjs(value).isValid() &&
        dayjs(dateValue).isValid() &&
        !dayjs(dateValue).isSame(value, 'day')
      ) {
        setValue(
          keys[index],
          makeSameDate(dayjs(dateValue), value) as PathValue<
            TFieldValues,
            FieldPath<TFieldValues>
          >,
          {
            shouldValidate: false,
            shouldDirty: true,
            shouldTouch: true,
          },
        );
      }
    });
  }, [dateValue, forceSameDay, keys, makeSameDate, setValue, values]);

  return handleChange;
};
