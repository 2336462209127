import { EmailAddress, ErrorResponse, Uuid } from '@bas/value-objects';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ResendEmailMutationProps = {
  emailId: Uuid;
  receivers: EmailAddress[];
};

export const ResendEmailMutation = async ({
  emailId,
  ...params
}: ResendEmailMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/communication/emails/${emailId}/resend`,
    {
      emailId,
      ...params,
    },
    { params },
  );

export const useResendEmailMutation = (): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ResendEmailMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ResendEmailMutationProps
  >({
    mutationFn: ResendEmailMutation,
    throwOnError: false,
  });
