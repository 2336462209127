import {
  EnterEventHoursInputType,
  EnterEventHoursInputTypeDefaultValues,
  EnterEventHoursInputTypeValidationBuilder,
} from '@bas/planning-domain/input-types';
import { Event, isProjectEvent } from '@bas/planning-domain/models';
import { EventWithoutHoursEntry } from '@bas/shared/models';
import { colors } from '@bas/theme';
import { BottomSheetMethods, Box, Typography } from '@bas/ui/native/base';
import { BackendErrors, BottomSheetProps } from '@bas/ui/native/organisms';
import { FormBottomSheet } from '@bas/ui/native/templates';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import dayjs from 'dayjs';
import { ReactElement, RefObject, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { StyleSheet } from 'react-native';
import { ensuredForwardRef } from 'react-use/lib/useEnsuredForwardedRef';
import { EnterEventHoursForm } from '../EnterEventHoursForm';

export type EnterEventHoursBottomSheetProps = Omit<
  BottomSheetProps,
  'snapPoints' | 'children'
> & {
  greetingName?: string | null;
  enterForEvent?: EventWithoutHoursEntry | Event;
  onSubmit: (values: EnterEventHoursInputType) => Promise<boolean>;
  finishEvent?: boolean;
};

const styles = StyleSheet.create({
  flexContainer: {
    flex: 1,
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 15,
  },
  avatar: {
    height: '100%',
    width: '100%',
  },
  niceJob: {
    textAlign: 'center',
  },
  avatarContainer: {
    overflow: 'hidden',
    borderRadius: 269,
    height: 268,
    width: 268,
    marginBottom: 29,
    marginTop: 29,
    alignSelf: 'center',
  },
  header: {
    alignSelf: 'center',
    fontSize: 22,
    lineHeight: 29,
    height: 51,
  },
  padding: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 31,
  },
});

const initialValues = { ...EnterEventHoursInputTypeDefaultValues() };
const EnterEventHoursBottomSheet = ensuredForwardRef<
  BottomSheetModal & BottomSheetMethods,
  EnterEventHoursBottomSheetProps
>(
  (
    {
      onSubmit,
      greetingName,
      enterForEvent,
      finishEvent,
      ...props
    }: EnterEventHoursBottomSheetProps,
    ref: RefObject<BottomSheetModal & BottomSheetMethods>,
  ): ReactElement => {
    const handleFinalize = useCallback(() => {
      ref?.current?.close();
      ref?.current?.forceClose();
      ref?.current?.dismiss();
    }, [ref]);

    const defaultValues = useMemo(() => {
      if (!enterForEvent) {
        return initialValues;
      }

      return {
        ...initialValues,
        overrideTime: false,
        overrideTravelTime: !!enterForEvent.travelStart,
        startDate:
          typeof enterForEvent.start === 'string'
            ? dayjs(enterForEvent.start).toDate()
            : enterForEvent.start,
        endDate:
          typeof enterForEvent.end === 'string'
            ? dayjs(enterForEvent.end).toDate()
            : enterForEvent.end,
        travelStartTime:
          typeof enterForEvent.travelStart === 'string'
            ? dayjs(enterForEvent.travelStart).toDate()
            : enterForEvent.travelStart || null,
        travelEndTime:
          typeof enterForEvent.travelEnd === 'string'
            ? dayjs(enterForEvent.travelEnd).toDate()
            : enterForEvent.travelEnd || null,
        eventData: {
          eventId: enterForEvent.eventId,
          projectId: isProjectEvent(enterForEvent)
            ? enterForEvent.projectId
            : undefined,
          startDate:
            typeof enterForEvent.start === 'string'
              ? dayjs(enterForEvent.start).toDate()
              : enterForEvent.start,
          endDate:
            typeof enterForEvent.end === 'string'
              ? dayjs(enterForEvent.end).toDate()
              : enterForEvent.end,
          travelStartTime:
            typeof enterForEvent.travelStart === 'string'
              ? dayjs(enterForEvent.travelStart).toDate()
              : enterForEvent.travelStart || null,
          travelEndTime:
            typeof enterForEvent.travelEnd === 'string'
              ? dayjs(enterForEvent.travelEnd).toDate()
              : enterForEvent.travelEnd || null,
        },
      };
    }, [enterForEvent]);

    const content = useMemo(
      () => (
        <Box style={styles.flexContainer}>
          <Typography
            fontWeight={700}
            overrideColor={colors.white}
            style={styles.header}
            textAlign="center"
          >
            <FormattedMessage id="mobileApp.event.fillInHours.title" />
          </Typography>
          <BackendErrors />
          <EnterEventHoursForm askTravelTime showTravelTime />
        </Box>
      ),
      [],
    );

    return (
      <FormBottomSheet<EnterEventHoursInputType>
        bottomSheetRef={ref}
        disablePadding
        onSubmit={onSubmit}
        useProvider
        defaultValues={defaultValues}
        validationSchema={EnterEventHoursInputTypeValidationBuilder}
        resetFormOnOpen
        successFullFormViewProps={{
          gifUrl: 'https://media.giphy.com/media/26u4lOMA8JKSnL9Uk/giphy.gif',
          color: colors.lila[100],
          greetingName,
          title: `mobileApp.event.${
            finishEvent ? 'finalizeEvent' : 'fillInHours'
          }.title.finished`,
        }}
        onCancel={handleFinalize}
        {...props}
      >
        {content}
      </FormBottomSheet>
    );
  },
);

export default EnterEventHoursBottomSheet;
