import { useCalculateEstimateForMovingJobMutation } from '@bas/project-domain/mutations';
import { MovingJobTaxationWizardInput } from '@bas/taxation-tool-domain/models';
import {
  MobileTaxationStepTemplate,
  RequestingEstimateStepTemplate,
} from '@bas/taxation-tool-domain/native/templates';
import { Alert } from '@bas/ui/native/atoms';
import { Box } from '@bas/ui/native/base';
import { useWizardStore } from '@bas/ui/native/organisms';
import { isBackendViolations } from '@bas/value-objects';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import isEqual from 'react-fast-compare';
import { UseFormReturn, useFormState } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Platform } from 'react-native';
import { MobileTaxationPricingTypeForm } from '../MobileTaxationPricingTypeForm';

const PricingTypeFormStep = ({
  onNext,
}: {
  onNext: (disableOverriddenSubmit?: boolean, force?: boolean) => Promise<void>;
}): ReactElement => {
  const { setTemporarySubmit } = useWizardStore();
  const [loading, setLoading] = useState<boolean>(false);
  const {
    mutateAsync: calculateEstimate,
    isPending,
    error,
    isError,
  } = useCalculateEstimateForMovingJobMutation({
    throwOnError: (error) => (error?.response?.status || 500) >= 500,
  });

  const { isSubmitting } = useFormState<MovingJobTaxationWizardInput>();
  const { formatMessage } = useIntl();

  useEffect(() => {
    setTemporarySubmit(
      ({
        setValue: setFormValue,
      }: UseFormReturn<MovingJobTaxationWizardInput>) =>
        async ({
          latestRequestedValues,
          latestQuoteRequestedValues,
          quote,
          estimate,
          ...values
        }: MovingJobTaxationWizardInput) => {
          setLoading(true);
          if (!isEqual(latestRequestedValues, values)) {
            const { data, status } = await calculateEstimate(values);

            if (status >= 200 && status < 210) {
              setFormValue('estimate', {
                ...data,
                items: (data.items || []).map((item) => ({
                  ...item,
                  overwritten: item.overwritten || item.calculated,
                })),
              });
              setFormValue('latestRequestedValues', values);

              await new Promise((resolve) => {
                setTimeout(resolve, Platform.OS === 'android' ? 500 : 100);
              });

              setTemporarySubmit(undefined);
              await onNext(true, true);
            }
          }
        },
    );

    return () => {
      setTemporarySubmit(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isError && !isPending && !isSubmitting) {
      setLoading(false);
    }
  }, [isError, isPending, isSubmitting]);

  const errorMessage = useMemo(() => {
    if (!error) {
      return undefined;
    }

    const response = error.response?.data;
    if (isBackendViolations(response)) {
      return response.violations
        .map((violation) => {
          const name = formatMessage({
            id: `label.${violation.propertyPath}`,
          });

          return `${name}: ${violation.message}`;
        })
        .join(', \n');
    }

    if (response?.message) {
      return response.message;
    }

    return error?.message;
  }, [error, formatMessage]);

  if (isSubmitting || loading) {
    return <RequestingEstimateStepTemplate />;
  }

  return (
    <MobileTaxationStepTemplate
      primary={
        <FormattedMessage id="mobileApp.intake.movingJob.pricingDetails" />
      }
      secondary={<FormattedMessage id="label.quote" />}
    >
      {isError && (
        <Box pb={4}>
          <Alert severity="warning">{errorMessage}</Alert>
        </Box>
      )}
      <MobileTaxationPricingTypeForm />
    </MobileTaxationStepTemplate>
  );
};

export default PricingTypeFormStep;
