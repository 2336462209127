import { WeatherApiResponse } from '@bas/shared/models';
import { QueryOptionsWithKey } from '@bas/shared/requests';
import { ErrorResponse } from '@bas/value-objects';
import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type WeatherApiRequestProps = {
  query: string;
  language: string;
};

type Response = AxiosResponse<WeatherApiResponse>;
type QueryKeyType = QueryKey & {
  [0]: 'weather';
  [1]: 'weather-api';
  [2]: string;
  [2]: string;
};

export const WeatherApiRequest = async ({
  query,
  language,
}: WeatherApiRequestProps): Promise<Response> =>
  axios.get('/api/weather/weather-api', {
    params: {
      query,
      language,
    },
  });

export const useWeatherApiRequest = (
  request: WeatherApiRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response,
    QueryKeyType
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response, QueryKeyType>({
    queryFn: async () => WeatherApiRequest({ ...request }),
    queryKey: ['weather', 'weather-api', request.query, request.language],
    ...options,
  });
