import {
  ProjectEventDetailsInputType,
  ProjectEventDetailsInputTypeValidationBuilder,
} from '@bas/planning-domain/input-types';
import * as Yup from 'yup';
import {
  NewProjectCustomerInputType,
  NewProjectCustomerInputTypeDefaultValues,
  NewProjectCustomerInputTypeValidationBuilder,
} from './newProjectCustomerInputType';
import {
  ProjectPricingInputType,
  ProjectPricingInputTypeDefaultValues,
  ProjectPricingInputTypeValidationBuilder,
} from './projectPricingInputType';

export type NewMovingLiftJobInputType = NewProjectCustomerInputType &
  ProjectPricingInputType & {
    events: ProjectEventDetailsInputType[];
  };

export const NewMovingLiftJobInputTypeDefaultValues =
  (): NewMovingLiftJobInputType => ({
    useIdentities: false,
    ...NewProjectCustomerInputTypeDefaultValues(),
    ...ProjectPricingInputTypeDefaultValues(),
    events: [],
  });

export const NewMovingLiftJobInputTypeValidationBuilder =
  (): Yup.ObjectSchema<NewMovingLiftJobInputType> =>
    Yup.object({
      events: Yup.array()
        .of(ProjectEventDetailsInputTypeValidationBuilder())
        .required(),
    })
      .concat(NewProjectCustomerInputTypeValidationBuilder())
      .concat(ProjectPricingInputTypeValidationBuilder());
