import { formatDate } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type DisableStorageLocationMutationProps = {
  storageLocationId: Uuid;
  disableAfter: Date;
};

export const DisableStorageLocationMutation = async ({
  storageLocationId,
  disableAfter,
  ...values
}: DisableStorageLocationMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/locations/${storageLocationId}/disable`, {
    storageLocationId,
    disableAfter: formatDate(disableAfter),
    ...values,
  });

export const useDisableStorageLocationMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    DisableStorageLocationMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  DisableStorageLocationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    DisableStorageLocationMutationProps
  >({
    mutationFn: DisableStorageLocationMutation,
    throwOnError: false,
    ...options,
  });
