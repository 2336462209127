import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  content: {
    width: '100%',
    flexDirection: 'row',
  },
  column: {
    flexGrow: 1,
    alignSelf: 'flex-end',
  },
  fullColumn: {
    flexBasis: '100%',
    width: '100%',
    flexGrow: 1,
  },
});
