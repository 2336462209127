import { WhatServicesAreNeededStepInputType } from '@bas/taxation-tool-domain/input-types';
import { MobileTaxationStepTemplate } from '@bas/taxation-tool-domain/native/templates';
import { InternalServiceType } from '@bas/value-objects';
import { memo, ReactElement, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { MobileTaxationNotesForm } from '../MobileTaxationNotesForm';

const NotesFormStep = (): ReactElement => {
  const services = useWatch<WhatServicesAreNeededStepInputType, 'services'>({
    name: 'services',
  });
  const usesStorage = useMemo(
    () =>
      !!services.find(
        ({ internalType, selected }) =>
          internalType === InternalServiceType.STORAGE && selected,
      ),
    [services],
  );

  return (
    <MobileTaxationStepTemplate
      primary={
        <FormattedMessage id="mobileApp.intake.movingJob.whatIsImportantForTheOffice" />
      }
      secondary={<FormattedMessage id="label.notes" />}
    >
      <MobileTaxationNotesForm usesStorage={usesStorage} />
    </MobileTaxationStepTemplate>
  );
};

const MemoComponent = memo(NotesFormStep, () => true);

export default MemoComponent;
