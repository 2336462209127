import { Typography, TypographyProps } from '@bas/ui/native/base';
import { ReactElement } from 'react';

export type RenderPersonNameProps = {
  firstName?: string | null;
  middleName?: string | null;
  lastName: string;
} & TypographyProps;

const RenderRenderPersonName = ({
  firstName,
  middleName,
  lastName,
  ...props
}: RenderPersonNameProps): ReactElement => {
  if (!firstName && !middleName) {
    return <Typography {...props}>{lastName}</Typography>;
  }

  if (firstName && !middleName) {
    return <Typography {...props}>{`${firstName} ${lastName}`}</Typography>;
  }

  return (
    <Typography
      {...props}
    >{`${firstName} ${middleName} ${lastName}`}</Typography>
  );
};

export default RenderRenderPersonName;
