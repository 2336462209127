import { StyleSheet } from 'react-native';
import { colors } from '@bas/theme';

export default StyleSheet.create({
  squareTile: {
    maxHeight: 258,
    maxWidth: 258,
    alignSelf: 'flex-start',
    aspectRatio: 1,
    paddingLeft: 19,
    paddingTop: 19,
    overflow: 'hidden',
  },
  children: {
    borderRadius: 6,
    backgroundColor: colors.lila[300],
    flex: 1,
    width: '100%',
    height: '100%',
  },
  selected: {
    backgroundColor: colors.blue[500],
  },
});
