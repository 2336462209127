import { BuildingType } from '@bas/value-objects';
import { faApartment } from '@fortawesome/pro-light-svg-icons/faApartment';
import { faBuilding } from '@fortawesome/pro-light-svg-icons/faBuilding';
import { faFarm } from '@fortawesome/pro-light-svg-icons/faFarm';
import { faGarage } from '@fortawesome/pro-light-svg-icons/faGarage';
import { faHospital } from '@fortawesome/pro-light-svg-icons/faHospital';
import { faHouseChimneyWindow } from '@fortawesome/pro-light-svg-icons/faHouseChimneyWindow';
import { faHouseHeart } from '@fortawesome/pro-light-svg-icons/faHouseHeart';
import { faHouseTree } from '@fortawesome/pro-light-svg-icons/faHouseTree';
import { faHouseTurret } from '@fortawesome/pro-light-svg-icons/faHouseTurret';
import { faHouseWater } from '@fortawesome/pro-light-svg-icons/faHouseWater';
import { faIndustryWindows } from '@fortawesome/pro-light-svg-icons/faIndustryWindows';
import { faSchool } from '@fortawesome/pro-light-svg-icons/faSchool';
import { faShip } from '@fortawesome/pro-light-svg-icons/faShip';
import { faStore } from '@fortawesome/pro-light-svg-icons/faStore';
import { faWarehouse } from '@fortawesome/pro-light-svg-icons/faWarehouse';

import { FontAwesomeIcon, Props } from '@fortawesome/react-native-fontawesome';
import { FunctionComponent, useMemo } from 'react';

export type BuildingTypeIconProps = Omit<Props, 'icon'> & {
  buildingType: BuildingType;
};

const BuildingTypeIcon: FunctionComponent<BuildingTypeIconProps> = ({
  buildingType,
  ...args
}: BuildingTypeIconProps) => {
  const buildingTypeIcon = useMemo(() => {
    switch (buildingType) {
      case BuildingType.HOUSE:
      case BuildingType.TWO_UNDER_ONE_ROOF:
      case BuildingType.TERRACED_HOUSE:
      case BuildingType.CORNER_HOUSE:
        return faHouseChimneyWindow;
      case BuildingType.STORAGE:
        return faWarehouse;
      case BuildingType.DRIVE_IN:
        return faHouseTurret;
      case BuildingType.VILLA:
      case BuildingType.MANSION:
      case BuildingType.DETACHED_HOUSE:
      case BuildingType.BUNGALOW:
        return faHouseTree;
      case BuildingType.OFFICE:
        return faIndustryWindows;
      case BuildingType.FARM:
        return faFarm;
      case BuildingType.SCHOOL:
        return faSchool;
      case BuildingType.NURSING_HOME:
        return faHospital;
      case BuildingType.DIKE_HOUSE:
        return faHouseWater;
      case BuildingType.COMMERCIAL_BUILDING:
        return faStore;
      case BuildingType.SENIOR_HOUSING:
      case BuildingType.ASSISTED_LIVING_CENTER:
        return faHouseHeart;
      case BuildingType.APARTMENT:
      case BuildingType.DOUBLE_APARTMENT:
      case BuildingType.UPSTAIRS_APARTMENT:
        return faApartment;
      case BuildingType.HOUSEBOAT:
        return faShip;
      case BuildingType.GARAGE:
        return faGarage;
      default:
        return faBuilding;
    }
  }, [buildingType]);
  return <FontAwesomeIcon {...args} icon={buildingTypeIcon} />;
};

export default BuildingTypeIcon;
