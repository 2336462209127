import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type CreateTenantContractMutationProps = {
  templateId: Uuid;
  templateFields: {
    api_id: string;
    value: string;
  }[];
  signers: {
    employeeId: Uuid;
  }[];
};

export const CreateTenantContractMutation = async ({
  ...values
}: CreateTenantContractMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/tenants/{tenantId}/contracts`, {
    ...values,
  });

export const useCreateTenantContractMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    CreateTenantContractMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  CreateTenantContractMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    CreateTenantContractMutationProps
  >({
    mutationFn: CreateTenantContractMutation,
    throwOnError: false,
    ...options,
  });
