import { EmployeeProjectRole, isEmployeeProjectRole } from '@bas/value-objects';
import { MovingJobSkill, OfficeSkill } from '../types';

export const employeeProjectRoleIsSkill = (
  projectRole: unknown,
): projectRole is MovingJobSkill | OfficeSkill =>
  isEmployeeProjectRole(projectRole) &&
  [
    EmployeeProjectRole.FOREMAN,
    EmployeeProjectRole.APPRAISER,
    EmployeeProjectRole.HANDYMAN,
    EmployeeProjectRole.CO_DRIVER,
    EmployeeProjectRole.PACKER,
    EmployeeProjectRole.LIFT_TRUCK,
  ].includes(projectRole);
