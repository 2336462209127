import * as Yup from 'yup';

export type RejectTimeEntryInputType = {
  reaction: string;
};

export const rejectTimeEntryInputTypeDefaultValues =
  (): RejectTimeEntryInputType => ({
    reaction: '',
  });

export const rejectTimeEntryInputTypeValidationBuilder =
  (): Yup.ObjectSchema<RejectTimeEntryInputType> =>
    Yup.object({
      reaction: Yup.string().label('label.reaction').ensure().required(),
    });
