import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { ErrorResponse, Uuid } from '@bas/value-objects';

export type DisableTwoFactorMutationProps = {
  userId: Uuid;
  code: string;
  password: string;
};

type DisableTwoFactorMutationResponse = AxiosResponse;

export const DisableTwoFactorMutation = async ({
  userId,
  ...values
}: DisableTwoFactorMutationProps): Promise<DisableTwoFactorMutationResponse> =>
  axios.put(`api/users/${userId}/disable-two-factor`, {
    userId,
    ...values,
  });

export const useDisableTwoFactorMutation = (
  options: UseMutationOptions<
    DisableTwoFactorMutationResponse,
    AxiosError<ErrorResponse>,
    DisableTwoFactorMutationProps
  > = {},
): UseMutationResult<
  DisableTwoFactorMutationResponse,
  AxiosError<ErrorResponse>,
  DisableTwoFactorMutationProps
> =>
  useMutation<
    DisableTwoFactorMutationResponse,
    AxiosError<ErrorResponse>,
    DisableTwoFactorMutationProps
  >({
    mutationFn: DisableTwoFactorMutation,
    throwOnError: false,
    ...options,
  });
