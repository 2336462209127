import * as Yup from 'yup';
import {
  StoredItemInputType,
  storedItemInputTypeValidationBuilder,
} from './storedItemInputType';

export type InventoryListInputType = {
  storedItems: StoredItemInputType[];
  originalStoredItems: StoredItemInputType[];
};

export const inventoryListInputTypeDefaultValues =
  (): InventoryListInputType => ({
    storedItems: [],
    originalStoredItems: [],
  });

export const inventoryListInputTypeValidationBuilder =
  (): Yup.ObjectSchema<InventoryListInputType> =>
    Yup.object({
      storedItems: Yup.array()
        .of(
          (Yup.object() as Yup.ObjectSchema<StoredItemInputType>).when(
            [],
            // eslint-disable-next-line no-empty-pattern
            ([], schema, { value }) => {
              if (value.delete) {
                return schema as Yup.ObjectSchema<StoredItemInputType>;
              }

              return schema.concat(storedItemInputTypeValidationBuilder());
            },
          ),
        )
        .required(),
      originalStoredItems: Yup.array()
        .of(storedItemInputTypeValidationBuilder().required())
        .required(),
    });
