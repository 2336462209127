import {
  isCreatedQuoteLineInputType,
  QuoteLineInputType,
} from '@bas/financial-domain/input-types';
import { Quote, QuoteLineType } from '@bas/financial-domain/models';
import { formatDate } from '@bas/shared/utils';
import { Country, Discount, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type CreateQuoteMutationProps = {
  quoteId: Uuid;
  relationId: Uuid;
  identityId?: Uuid | null;
  projectId?: Uuid | null;
  lines: QuoteLineInputType[];
  freeText: { [key: string]: string };
  includingVat: boolean;
  templateId: Uuid;
  expiresAt?: Date | null;
  addressId: Uuid;
  contactPersonId?: Uuid | null;
  region: Country;
  reference?: string | null;
  discount?: Discount;
};

export const CreateQuoteMutation = async ({
  lines,
  expiresAt,
  ...values
}: CreateQuoteMutationProps): Promise<AxiosResponse<Quote>> =>
  axios.post(`api/{tenantId}/quotes`, {
    expiresAt: expiresAt ? formatDate(expiresAt) : undefined,
    lines: lines.filter(isCreatedQuoteLineInputType).map((line) => ({
      ...line,
      vatCodeId: line.vatCode.vatCodeId,
      lineType: line.hourlyRate
        ? QuoteLineType.HOURLY_RATE
        : QuoteLineType.NORMAL,
    })),
    ...values,
  });

export const useCreateQuoteMutation = (
  options: UseMutationOptions<
    AxiosResponse<Quote>,
    AxiosError<ErrorResponse>,
    CreateQuoteMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse<Quote>,
  AxiosError<ErrorResponse>,
  CreateQuoteMutationProps
> =>
  useMutation<
    AxiosResponse<Quote>,
    AxiosError<ErrorResponse>,
    CreateQuoteMutationProps
  >({
    mutationFn: CreateQuoteMutation,
    throwOnError: false,
    ...options,
  });
