import {
  AddressInputType,
  addressInputTypeDefaultValues,
  addressInputTypeValidationBuilder,
} from '@bas/shared/input-types';
import dayjs from 'dayjs';
import * as Yup from 'yup';

export interface EventTimeInputType {
  start?: Date | null;
  end?: Date | null;
  travelStart?: Date | null;
  travelEnd?: Date | null;
  departureLocation?: AddressInputType;
  durationInSeconds?: number | null;
  notes?: string | null;
  automaticTravelTime: boolean;
}

export const EventTimeInputTypeDefaultValues = (): EventTimeInputType => ({
  automaticTravelTime: true,
  start: null,
  end: null,
  travelStart: null,
  travelEnd: null,
  departureLocation: { ...addressInputTypeDefaultValues() },
  durationInSeconds: null,
  notes: '',
});

export const EventTimeInputTypeValidationBuilder =
  (): Yup.ObjectSchema<EventTimeInputType> =>
    Yup.object({
      travelStart: Yup.date()
        .nullable()
        .label('label.departureTime')
        .when(['start'], ([start], schema) => {
          if (!start || !dayjs(start).isValid()) {
            return schema;
          }

          return schema.max(start, 'Vertrektijd moet voor de starttijd liggen');
        }),
      travelEnd: Yup.date()
        .nullable()
        .label('label.arrivalTimeWarehouse')
        .when(['end'], ([end], schema) => {
          if (!end || !dayjs(end).isValid()) {
            return schema;
          }

          return schema.min(
            end,
            'Aankomsttijd bij de loods moet na de eindtijd liggen',
          );
        }),
      start: Yup.date().nullable().required().label('label.start'),
      end: Yup.date()
        .nullable()
        .when(['start'], ([start], schema) => {
          if (!start || !dayjs(start).isValid()) {
            return schema;
          }

          return schema.min(start, 'label.endMustBeAfterStart');
        })
        .label('label.end'),

      departureLocation: Yup.object({
        addressId: Yup.string().required().label('label.departureLocation'),
      })
        .concat(addressInputTypeValidationBuilder())
        .label('label.departureLocation')
        .nullable()
        .required(),
      durationInSeconds: Yup.number()
        .nullable()
        .label('label.durationInSeconds'),
      notes: Yup.string().nullable().defined().label('label.notes'),
      automaticTravelTime: Yup.boolean()
        .defined()
        .label('label.automaticTravelTime'),
    });
