import { formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ReportWornOutInventoryMutationProps = {
  inventoryItemId: Uuid;
  eventDate: Date;
  quantity: number;
  supplierId?: Uuid | null;
  customerId?: Uuid | null;
  projectId?: Uuid | null;
  planningEventId?: Uuid | null;
};

export const ReportWornOutInventoryMutation = async ({
  inventoryItemId,
  eventDate,
  ...values
}: ReportWornOutInventoryMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/inventory/item/${inventoryItemId}/report-worn-out`,
    {
      inventoryItemId,
      eventDate: formatDateTime(eventDate),
      ...values,
    },
  );

export const useReportWornOutInventoryMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ReportWornOutInventoryMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ReportWornOutInventoryMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ReportWornOutInventoryMutationProps
  >({
    mutationFn: ReportWornOutInventoryMutation,
    throwOnError: false,
    ...options,
  });
