import * as Yup from 'yup';

export type TeamInputType = {
  name: string;
};

export const TeamInputTypeDefaultValues = (): TeamInputType => ({
  name: '',
});

export const TeamInputTypeValidationBuilder =
  (): Yup.ObjectSchema<TeamInputType> =>
    Yup.object({
      name: Yup.string().max(200).required().label('label.name'),
    });
