export enum MovingJobStatus {
  FIRST_CONTACT = 'first-contact',
  INTAKE = 'intake',
  QUOTATION = 'quotation',
  QUOTE_CREATED = 'quote-created',
  QUOTE_SENT = 'quote-sent',
  QUOTE_DECLINED = 'quote-declined',
  TUNING = 'tuning',
  QUOTE_ACCEPTED = 'quote-accepted',
  AWAITING_DEPOSIT = 'awaiting-deposit',
  RECEIVED_DEPOSIT = 'received-deposit',
  SKIPPED_DEPOSIT = 'skipped-deposit',
  PLANNED = 'planned',
  DELIVER_BOXES = 'deliver-boxes',
  CARRY_OUT = 'carry-out',
  IN_STORAGE = 'in-storage',
  AFTERCARE = 'aftercare',
  PICK_UP_BOXES = 'pick-up-boxes',
  FINISHED = 'finished',
  CANCELLED = 'cancelled',
}
