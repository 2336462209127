import * as Yup from 'yup';
import dayjs from 'dayjs';

export type DayOffOffInputType = {
  start?: Date | null | undefined;
  end?: Date | null | undefined;
  hours: number;
};

export const dayOffInputTypeDefaultValues = (): DayOffOffInputType => ({
  start: null,
  end: null,
  hours: 0,
});

export const dayOffInputTypeValidationBuilder =
  (): Yup.ObjectSchema<DayOffOffInputType> =>
    Yup.object({
      start: Yup.date().required().label('label.start'),
      end: Yup.date()
        .when(['start'], ([start], schema) => {
          if (!start || !dayjs(start).isValid()) {
            return schema;
          }

          return schema
            .min(dayjs(start).clone().add(1, 'second').toDate())
            .required();
        })
        .label('label.end'),
      hours: Yup.number().required().label('label.hours'),
    });
