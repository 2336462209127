import { basParaph, isIcon } from '@bas/svg-icons';
import { RectangularTile, RectangularTileProps } from '@bas/ui/native/atoms';
import {
  Box,
  Icon,
  IconProps,
  Typography,
  TypographyProps,
} from '@bas/ui/native/base';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { cloneElement, isValidElement, ReactElement, useMemo } from 'react';
import styles from './styles';

export type RectangularIconTileProps = Omit<
  RectangularTileProps,
  'children'
> & {
  icon:
    | IconProp
    | ReactElement<IconProps>
    | { prefix: string; iconName: string };
  label: string | ReactElement<TypographyProps>;
  finished?: boolean;
};

const RectangularIconTile = ({
  icon,
  finished,
  label,
  ...props
}: RectangularIconTileProps): ReactElement => {
  const color = useMemo(
    () => (finished ? 'mainPrimary' : 'darkText'),
    [finished],
  );

  return (
    <RectangularTile {...props}>
      <Box
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start"
        flex={1}
      >
        {isValidElement(icon) &&
          cloneElement(icon as ReactElement<IconProps>, { color })}
        {isIcon(icon) && <Icon icon={icon} size={30} color={color} />}
        <Box style={styles.label} pl={2}>
          {isValidElement(label) ? (
            cloneElement(label as ReactElement<TypographyProps>, {
              color,
              fontWeight: 700,
              variant: 'h5',
            })
          ) : (
            <Typography fontWeight={700} color={color} variant="h5">
              {label}
            </Typography>
          )}
        </Box>
        <Box style={styles.paraghHolder}>
          {finished && <Icon icon={basParaph} size={24} color={color} />}
        </Box>
      </Box>
    </RectangularTile>
  );
};

export default RectangularIconTile;
