import { RelationType } from '@bas/crm-domain/models';
import { CustomerDetailsFormStepInputType } from '@bas/taxation-tool-domain/input-types';
import { Box } from '@bas/ui/native/base';
import {
  ReactHookFormDropdown,
  ReactHookFormTextField,
} from '@bas/ui/native/molecules';
import {
  Gender,
  HowDidYouFindUs,
  HowDidYouFindUsLeadWebsites,
  Language,
  Salutation,
} from '@bas/value-objects';
import { FlashList, ListRenderItemInfo } from '@shopify/flash-list';
import { useResponsiveProp } from '@shopify/restyle';
import { ReactElement, useCallback, useMemo } from 'react';
import { UseFormSetFocus, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';

export type MobileTaxationCustomerDetailsFormProps = {
  setFocus: UseFormSetFocus<CustomerDetailsFormStepInputType>;
};

const MobileTaxationCustomerDetailsForm = (): ReactElement => {
  const relationType = useWatch<CustomerDetailsFormStepInputType>({
    name: 'customer.relationType',
  });
  const howDidYouFindUs = useWatch<CustomerDetailsFormStepInputType>({
    name: 'customer.howDidYouFindUs',
  });
  const { formatMessage, formatDisplayName } = useIntl();

  const fields = useMemo(
    () => [
      'customer.relationType',
      'customer.companyName',
      'customer.vatNumber',
      'customer.gender',
      'customer.salutation',
      'customer.language',
      'customer.howDidYouFindUs',
      'customer.howDidYouFindUsOther',
      'customer.firstName',
      'customer.middleName',
      'customer.lastName',
      'customer.emailAddress',
      'customer.phoneNumber',
      'customer.mobileNumber',
    ],
    [],
  );

  const paddingHorizontal = useResponsiveProp({
    largerTablet: 80,
    tablet: 50,
    phone: 20,
  });

  const renderField = useCallback(
    (field: string) => {
      switch (field) {
        case 'customer.relationType':
          return (
            <ReactHookFormDropdown
              name="customer.relationType"
              key="customer.relationType"
              autoComplete="off"
              label={formatMessage({ id: 'label.customerType' })}
              items={Object.values(RelationType)
                .filter((option) => !!option)
                .map((option) => ({
                  label: formatMessage({ id: `relationTypes.${option}` }),
                  id: option,
                }))}
            />
          );
        case 'customer.companyName':
          return relationType === RelationType.BUSINESS ? (
            <ReactHookFormTextField
              name="customer.companyName"
              key="customer.companyName"
              autoComplete="off"
              label={formatMessage({ id: 'label.companyName' })}
            />
          ) : null;
        case 'customer.vatNumber':
          return relationType === RelationType.BUSINESS ? (
            <ReactHookFormTextField
              name="customer.vatNumber"
              key="customer.vatNumber"
              autoComplete="off"
              label={formatMessage({ id: 'label.vatNumber' })}
            />
          ) : null;
        case 'customer.gender':
          return (
            <ReactHookFormDropdown
              name="customer.gender"
              key="customer.gender"
              label={formatMessage({ id: 'label.gender' })}
              items={[
                ...Object.values(Gender).map((option) => ({
                  id: option,
                  label: formatMessage({
                    id: `genders.${option || 'unknown'}`,
                  }),
                })),
              ]}
            />
          );
        case 'customer.salutation':
          return (
            <ReactHookFormDropdown
              name="customer.salutation"
              key="customer.salutation"
              label={formatMessage({ id: 'label.salutation' })}
              items={[
                ...Object.values(Salutation).map((option) => ({
                  id: option,
                  label: option,
                })),
              ]}
            />
          );
        case 'customer.language':
          return (
            <ReactHookFormDropdown
              name="customer.language"
              key="customer.language"
              label={formatMessage({ id: 'label.language' })}
              items={[
                ...Object.values(Language).map((option) => ({
                  id: option,
                  label:
                    formatDisplayName(option, {
                      type: 'language',
                    }) || option,
                })),
              ]}
            />
          );

        case 'customer.howDidYouFindUs':
          return (
            <ReactHookFormDropdown
              name="customer.howDidYouFindUs"
              key="customer.howDidYouFindUs"
              label={formatMessage({ id: 'label.howDidYouFindUs' })}
              items={[
                ...Object.values(HowDidYouFindUs).map((option) => ({
                  id: option,
                  label: formatMessage({
                    id: `howDidYouFindUsOptions.${option || 'unknown'}`,
                  }),
                })),
              ]}
            />
          );

        case 'customer.howDidYouFindUsOther':
          return (
            <>
              {howDidYouFindUs === HowDidYouFindUs.OTHER && (
                <ReactHookFormTextField
                  name="customer.howDidYouFindUsOther"
                  key="customer.howDidYouFindUsOther"
                  autoComplete="off"
                  label={formatMessage({ id: 'label.howDidYouFindUs' })}
                />
              )}
              {howDidYouFindUs === HowDidYouFindUs.LEAD_WEBSITE && (
                <ReactHookFormDropdown
                  name="customer.howDidYouFindUsOther"
                  key="customer.howDidYouFindUsOther"
                  label={formatMessage({ id: 'label.whichLeadWebsite' })}
                  items={[
                    ...Object.keys(HowDidYouFindUsLeadWebsites)
                      .sort((a, b) => a.localeCompare(b))
                      .map((option) => ({
                        id: option,
                        label:
                          HowDidYouFindUsLeadWebsites[
                            option as keyof typeof HowDidYouFindUsLeadWebsites
                          ],
                      })),
                  ]}
                />
              )}
            </>
          );
        case 'customer.firstName':
          return (
            <ReactHookFormTextField
              name="customer.firstName"
              key="customer.firstName"
              autoComplete="off"
              label={formatMessage({ id: 'label.firstName' })}
            />
          );
        case 'customer.middleName':
          return (
            <ReactHookFormTextField
              name="customer.middleName"
              key="customer.middleName"
              autoComplete="off"
              label={formatMessage({ id: 'label.middleName' })}
              autoCapitalize="none"
            />
          );
        case 'customer.lastName':
          return (
            <ReactHookFormTextField
              name="customer.lastName"
              key="customer.lastName"
              autoComplete="off"
              label={formatMessage({ id: 'label.lastName' })}
            />
          );
        case 'customer.emailAddress':
          return (
            <ReactHookFormTextField
              name="customer.emailAddress"
              key="customer.emailAddress"
              autoComplete="off"
              label={formatMessage({ id: 'label.emailAddress' })}
              keyboardType="email-address"
              autoCapitalize="none"
            />
          );
        case 'customer.phoneNumber':
          return (
            <ReactHookFormTextField
              name="customer.phoneNumber"
              key="customer.phoneNumber"
              autoComplete="off"
              label={formatMessage({ id: 'label.phoneNumber' })}
              keyboardType="phone-pad"
            />
          );
        case 'customer.mobileNumber':
          return (
            <ReactHookFormTextField
              name="customer.mobileNumber"
              key="customer.mobileNumber"
              autoComplete="off"
              label={formatMessage({ id: 'label.mobileNumber' })}
              keyboardType="phone-pad"
            />
          );

        default:
          return null;
      }
    },
    [formatMessage, formatDisplayName, howDidYouFindUs, relationType],
  );

  const renderItem = useCallback(
    ({ item: field, index }: ListRenderItemInfo<string>) => {
      const renderedField = renderField(field);
      if (!renderedField) {
        return null;
      }

      return <Box pt={index > 0 ? 3 : 0}>{renderedField}</Box>;
    },

    [renderField],
  );

  return (
    <FlashList
      renderItem={renderItem}
      data={fields}
      estimatedItemSize={77}
      contentContainerStyle={{
        paddingBottom: 24,
        paddingHorizontal,
      }}
      extraData={{
        relationType,
      }}
    />
  );
};

export default MobileTaxationCustomerDetailsForm;
