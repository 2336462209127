import { EventDtoInputType } from '@bas/planning-domain/input-types';
import { MovingJobAddressInputType } from '@bas/project-domain/input-types';
import { ProjectEstimate } from '@bas/project-domain/models';
import { AddressInputType } from '@bas/shared/input-types';
import { formatDate, formatDateTime } from '@bas/shared/utils';
import {
  EmployeeProjectRole,
  ErrorResponse,
  Money,
  PricingType,
  Uuid,
} from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type CreateMovingJobMutationProps = {
  projectId: Uuid;
  identityId?: Uuid | null;
  customerId: Uuid;
  packageId: Uuid;
  pricingType: PricingType;
  hourlyPrice: Money;
  addresses: (MovingJobAddressInputType & { addressId: Uuid })[];
  events: EventDtoInputType[];
  neededRoles: EmployeeProjectRole[];
  changeAddressAfterMoving: boolean;
  intakeEvent?: {
    start: Date;
    end: Date;
    travelStart?: Date | null;
    travelEnd?: Date | null;
    employeeIds: Uuid[];
    date?: Date;
    departureLocation: {
      addressId?: string | null;
    } & AddressInputType;
  };
};

export const CreateMovingJobMutation = async ({
  projectId,
  intakeEvent,
  events,
  addresses,
  ...values
}: CreateMovingJobMutationProps): Promise<AxiosResponse<ProjectEstimate>> =>
  axios.post(`api/{tenantId}/moving-jobs`, {
    projectId,
    events: events.map(
      ({
        start,
        end,
        travelStart,
        travelEnd,
        recurringInformation,
        ...event
      }) => ({
        ...event,
        date: null,
        start: formatDateTime(start),
        end: formatDateTime(end),
        travelStart: travelStart ? formatDateTime(travelStart) : null,
        travelEnd: travelEnd ? formatDateTime(travelEnd) : null,
        recurringInformation: recurringInformation
          ? {
              ...recurringInformation,
              repeatFrom: formatDate(recurringInformation.repeatFrom),
              repeatUntil: formatDate(recurringInformation.repeatUntil),
            }
          : null,
      }),
    ),
    addresses: addresses.filter(({ unknownAddress }) => !unknownAddress),
    intakeEvent: intakeEvent
      ? {
          ...intakeEvent,
          date: intakeEvent.date ? formatDate(intakeEvent.date) : null,
          start: formatDateTime(intakeEvent.start),
          end: formatDateTime(intakeEvent.end),
          travelStart: intakeEvent.travelStart
            ? formatDateTime(intakeEvent.travelStart)
            : null,
          travelEnd: intakeEvent.travelEnd
            ? formatDateTime(intakeEvent.travelEnd)
            : null,
        }
      : undefined,
    ...values,
  });

export const useCreateMovingJobMutation = (
  options: UseMutationOptions<
    AxiosResponse<ProjectEstimate>,
    AxiosError<ErrorResponse>,
    CreateMovingJobMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse<ProjectEstimate>,
  AxiosError<ErrorResponse>,
  CreateMovingJobMutationProps
> =>
  useMutation<
    AxiosResponse<ProjectEstimate>,
    AxiosError<ErrorResponse>,
    CreateMovingJobMutationProps
  >({
    mutationFn: CreateMovingJobMutation,
    throwOnError: false,
    ...options,
  });
