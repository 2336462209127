import { ErrorResponse } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type UserOpensNewsMutationProps = {
  newsId: string;
  userId: string;
};

export const UserOpensNewsMutation = async ({
  newsId,
  userId,
}: UserOpensNewsMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/news/${newsId}/opens`, {
    newsId,
    userId,
  });

export const useUserOpensNewsMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UserOpensNewsMutationProps
  >,
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  UserOpensNewsMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UserOpensNewsMutationProps
  >({
    mutationFn: UserOpensNewsMutation,
    throwOnError: false,
    ...options,
  });
