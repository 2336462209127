import { formatDate } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type AddTenantClosureDateMutationProps = {
  closureDateId: Uuid;
  date: Date;
  reason: string;
};

export const AddTenantClosureDateMutation = async ({
  date,
  ...values
}: AddTenantClosureDateMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/{tenantId}/planning/tenant-closure-dates/add`, {
    date: formatDate(date),
    ...values,
  });

export const useAddTenantClosureDateMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddTenantClosureDateMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  AddTenantClosureDateMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddTenantClosureDateMutationProps
  >({
    mutationFn: AddTenantClosureDateMutation,
    throwOnError: false,
    ...options,
  });
