/* eslint-disable @typescript-eslint/no-explicit-any */
import { Event, isCustomEvent } from '@bas/planning-domain/models';
import {
  useEventByPeriodRequest,
  useEventsByProjectIdRequest,
} from '@bas/planning-domain/requests';
import { Dropdown, DropdownProps } from '@bas/ui/native/atoms';
import { Uuid } from '@bas/value-objects';
import dayjs from 'dayjs';
import {
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import isEqual from 'react-fast-compare';
import {
  FieldPath,
  FieldPathValue,
  FieldValues,
  useController,
  UseFormSetFocus,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { TextInput } from 'react-native';
import { TextInputFocusEventData } from 'react-native/Libraries/Components/TextInput/TextInput';
import { NativeSyntheticEvent } from 'react-native/Libraries/Types/CoreEventTypes';

export type ReactHookFormEventDropdownProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<DropdownProps, 'name' | 'value' | 'error' | 'items' | 'onChange'> & {
  name: TName;
  fieldValue?: string;
  updateOnBlur?: boolean;
  disableError?: boolean;
  loading?: boolean;
  setFocus?: UseFormSetFocus<TFieldValues>;
  nextField?: FieldPath<TFieldValues>;
  onChange?: (
    value: FieldPathValue<TFieldValues, TName>,
    newValue?: Event,
  ) => void;
  onSubmit?: () => void | Promise<void>;
  onFocus?: () => void;
  onBlur?: () => void;
  variant?: 'outlined' | 'standard' | 'filled';
  projectId?: Uuid | null;
  disabled?: boolean;
  allowSearch?: boolean;
  showAllOfDay?: boolean;
  employeeId?: Uuid;
};

const ControlledReactHookFormEventDropdown = memo(
  <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  >({
    fieldValue,
    disabled,
    disableError,
    loading,
    setFocus,
    nextField,
    onSubmit,
    onFocusProp,
    onBlurProp,
    onChangeProp,
    allowSearch,
    value,
    onBlur,
    onChange,
    fieldRef,
    error,
    invalid,
    isSubmitting,
    projectId,
    employeeId,
    showAllOfDay,
    updateOnBlur = false,
    ...props
  }: Omit<ReactHookFormEventDropdownProps, 'name' | 'onChange' | 'multiple'> & {
    onChangeProp?: (
      value: FieldPathValue<TFieldValues, TName>,
      newValue?: Event,
    ) => void;
    onFocusProp?:
      | ((e: NativeSyntheticEvent<TextInputFocusEventData>) => void)
      | undefined;
    onBlurProp?: () => void;
    value: string;
    error: any;
    invalid: boolean;
    isSubmitting: boolean;
    fieldRef: (ref: TextInput) => void;
    onChange: (value: string) => void;
    projectId?: Uuid | null;
  }): ReactElement => {
    const fieldError = error?.message;
    const showError = !disableError && invalid;
    const inputRef = useRef<TextInput | null>(null);
    const { formatMessage, formatDate } = useIntl();

    const textFieldArgs = useMemo(
      () => ({
        validationMessage: showError ? fieldError : undefined,
        enableErrors: true,
        error: showError ? !!fieldError : undefined,
        disabled: disabled ?? isSubmitting,
      }),
      [showError, fieldError, disabled, isSubmitting],
    );

    const { isLoading, data: eventsData } = useEventsByProjectIdRequest(
      {
        perPage: 50,
        projectId: projectId || '',
      },
      { enabled: !!projectId },
    );

    const { isPending: isLoadingAll, data: allEventsData } =
      useEventByPeriodRequest(
        {
          fromDate: new Date(),
          untilDate: new Date(),
        },
        {
          enabled: showAllOfDay,
        },
      );

    const selectNextFieldOrSubmit = useCallback(() => {
      if (nextField) {
        setFocus?.(nextField, { shouldSelect: false });
      } else if (onSubmit) {
        onSubmit();
      }
    }, [nextField, onSubmit, setFocus]);

    useEffect(() => {
      if (inputRef.current) {
        fieldRef(inputRef.current);
      }
    }, [fieldRef, inputRef]);

    const valueProp: { value?: string } = {};
    if (
      loading ||
      (isLoading && !!projectId && !showAllOfDay) ||
      (showAllOfDay && !projectId && isLoadingAll)
    ) {
      valueProp.value = formatMessage({ id: 'label.loading' });
    } else if (!fieldValue) {
      valueProp.value = value || '';
    } else {
      valueProp.value = (value[fieldValue as keyof typeof value] ||
        '') as string;
    }

    const handleBlur = useCallback(
      () => () => {
        onBlurProp?.();
        onBlur?.();
      },
      [onBlur, onBlurProp],
    );

    const events: Event[] = useMemo(() => {
      const allEvents = allEventsData?.data?.member || [];
      const filteredEvents = eventsData?.data?.member || [];
      if (projectId) {
        return filteredEvents;
      }

      return allEvents;
    }, [allEventsData?.data, eventsData?.data, projectId]);

    const handleChange = useCallback(
      (text: unknown) => {
        if (onChangeProp) {
          onChangeProp(
            text as FieldPathValue<TFieldValues, TName>,
            events.find((p: Event) => p.eventId === text),
          );
        } else if (fieldValue && !!value && typeof value === 'object') {
          onChange({
            ...(value as object),
            [fieldValue]: text,
          } as FieldPathValue<TFieldValues, TName>);
        } else {
          onChange(text as FieldPathValue<TFieldValues, TName>);
        }
      },
      [fieldValue, onChange, onChangeProp, events, value],
    );

    const items = useMemo(
      () =>
        events
          .sort((a, b) => {
            const timeDiff =
              dayjs(a.start).toDate().getTime() -
              dayjs(b.start).toDate().getTime();
            if (!employeeId) {
              return timeDiff;
            }

            const aHasEmployee = !!a.employees.find(
              (e) => e.employee.employeeId === employeeId,
            );
            const bHasEmployee = !!b.employees.find(
              (e) => e.employee.employeeId === employeeId,
            );
            if (aHasEmployee && !bHasEmployee) {
              return -1;
            }

            if (!aHasEmployee && bHasEmployee) {
              return 1;
            }

            return timeDiff;
          })
          .map((event) => ({
            id: event.eventId,
            label: `${
              isCustomEvent(event)
                ? event.name
                : formatMessage({
                    id: `mobileApp.event.eventType.${event.eventType}`,
                  })
            } (${formatDate(event.start, {
              dateStyle: 'short',
              timeStyle: 'short',
            })} - ${formatDate(event.end, {
              timeStyle: 'short',
            })})`,
          })),
      [employeeId, events, formatDate, formatMessage],
    );

    return (
      <Dropdown
        onChange={handleChange}
        value={value}
        {...textFieldArgs}
        {...props}
        {...valueProp}
        onSubmitEditing={selectNextFieldOrSubmit}
        blurOnSubmit={false}
        onBlur={handleBlur}
        onFocus={onFocusProp}
        inputRef={inputRef}
        items={items}
        disabled={disabled || items.length === 0}
        multiple={false}
      />
    );
  },
  ({ fieldRef: _Prev, ...prevProps }, { fieldRef: _Next, ...nextProps }) =>
    isEqual(prevProps, nextProps),
);

const ReactHookFormEventDropdown = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  onFocus: onFocusProp,
  onBlur: onBlurProp,
  onChange: onChangeProp,
  updateOnBlur = false,
  ...props
}: ReactHookFormEventDropdownProps<TFieldValues, TName>): ReactElement => {
  const {
    field: { value, onBlur, onChange, ref },
    fieldState: { error, invalid },
    formState: { isSubmitting },
  } = useController<TFieldValues, TName>({
    name,
  });

  return (
    <ControlledReactHookFormEventDropdown
      {...props}
      onFocusProp={onFocusProp}
      onBlurProp={onBlurProp}
      onChangeProp={onChangeProp}
      value={value}
      error={error}
      invalid={invalid}
      isSubmitting={isSubmitting}
      onBlur={onBlur}
      updateOnBlur={updateOnBlur}
      onChange={onChange}
      fieldRef={ref}
    />
  );
};

export default ReactHookFormEventDropdown;
