import { ErrorResponse, Money, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type AddExistingSupplierToInventoryItemMutationProps = {
  inventoryItemId: Uuid;
  supplierId: Uuid;
  purchasePrice: Money;
};

export const AddExistingSupplierToInventoryItemMutation = async ({
  inventoryItemId,
  ...values
}: AddExistingSupplierToInventoryItemMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/inventory/item/${inventoryItemId}/add-existing-supplier`,
    {
      inventoryItemId,
      ...values,
    },
  );

export const useAddExistingSupplierToInventoryItemMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddExistingSupplierToInventoryItemMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  AddExistingSupplierToInventoryItemMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddExistingSupplierToInventoryItemMutationProps
  >({
    mutationFn: AddExistingSupplierToInventoryItemMutation,
    throwOnError: false,
    ...options,
  });
