import { Box, BoxProps } from '@bas/ui/native/base';
import { ReactElement } from 'react';
import styles from './styles';

export type BadgeProps = Omit<BoxProps, 'color'> & { color: string };

const Badge = ({ children, color, ...props }: BadgeProps): ReactElement => (
  <Box style={[styles.badge, { backgroundColor: color }]} {...props}>
    {children}
  </Box>
);

export default Badge;
