import type { Money, Uuid } from '@bas/value-objects';
import type { ProjectEstimateItem } from './ProjectEstimateItem';

export interface ProjectEstimate {
  projectEstimateId: Uuid;
  projectId: Uuid;
  version: number;
  items: ProjectEstimateItem[];
  total: Money;
}
