import {
  TouchableWithoutFeedback,
  TouchableWithoutFeedbackProps,
  Typography,
} from '@bas/ui/native/base';
import { colors } from '@bas/theme';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, Props } from '@fortawesome/react-native-fontawesome';
import { ReactElement, ReactPortal, useMemo, useState } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

export type MobileActionButtonProps = TouchableWithoutFeedbackProps & {
  icon: IconProp;
  children: ReactElement | ReactElement[] | ReactPortal | string;
  style?: StyleProp<ViewStyle>;
  iconProps?: Omit<Props, 'icon'>;
  active?: boolean;
};

const styles = StyleSheet.create({
  button: {
    backgroundColor: colors.white,
    borderColor: colors.lila[300],
    borderRadius: 4,
    borderWidth: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 11,
    paddingBottom: 11,
    paddingLeft: 18,
    paddingRight: 18,
    minWidth: 162,
    height: 80,
  },
  iconContainer: {},
  buttonText: {
    paddingTop: 8,
    textAlign: 'center',
    color: colors.lila[800],
  },
  buttonWhilePress: {},
  activeButton: {
    backgroundColor: colors.blue[500],
  },
});

const MobileActionButton = ({
  children,
  style,
  icon,
  iconProps,
  disabled,
  active,
  ...props
}: MobileActionButtonProps): ReactElement => {
  const [isPressing, setIsPressing] = useState<boolean>(false);
  const color = useMemo(() => {
    switch (true) {
      case active:
        return colors.white;
      case isPressing:
        return colors.blue[500];
      case disabled:
        return colors.lila[400];
      default:
        return colors.lila[800];
    }
  }, [active, isPressing, disabled]);

  return (
    <TouchableWithoutFeedback
      {...props}
      disabled={disabled}
      onPressIn={() => setIsPressing(true)}
      onPressOut={() => setIsPressing(false)}
    >
      <View
        style={[
          styles.button,
          isPressing && styles.buttonWhilePress,
          active && styles.activeButton,
          style,
        ]}
      >
        <View style={styles.iconContainer}>
          <FontAwesomeIcon icon={icon} size={24} color={color} {...iconProps} />
        </View>
        <Typography
          style={[styles.buttonText]}
          overrideColor={color}
          fontWeight={isPressing || active ? 700 : undefined}
          variant="body2"
        >
          {children}
        </Typography>
      </View>
    </TouchableWithoutFeedback>
  );
};

export default MobileActionButton;
