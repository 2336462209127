import { ClaAllowanceRuleType, ClaOvertimeAllowanceRule } from '../types';
import { isClaAllowanceRule } from './ClaAllowanceRule';

export function isClaOvertimeAllowanceRule(
  object: unknown,
): object is ClaOvertimeAllowanceRule {
  return (
    isClaAllowanceRule(object) &&
    object.ruleType === ClaAllowanceRuleType.ClaOvertime
  );
}
