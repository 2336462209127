import { EventWithoutHoursEntry } from '@bas/shared/models';
import { colors } from '@bas/theme';
import { EventIcon } from '@bas/ui/native/atoms';
import {
  TouchableWithoutFeedback,
  TouchableWithoutFeedbackProps,
  Typography,
} from '@bas/ui/native/base';
import { showEventName } from '@bas/value-objects';
import { ReactElement, useMemo, useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { StyleSheet, View } from 'react-native';

export type EventWithoutHoursListItemProps = TouchableWithoutFeedbackProps & {
  planningEvent: EventWithoutHoursEntry;
};

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    flex: 1,
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 12,
    backgroundColor: colors.white,
    paddingTop: 26,
    paddingBottom: 14,
    borderWidth: 1,
    borderColor: colors.lila[300],
    borderRadius: 2,
  },
  eventColor: {
    position: 'absolute',
    height: 10,
    left: -1,
    right: -1,
    top: -1,
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
    backgroundColor: colors.blue[500],
  },
  eventIconContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  eventInfoContainer: {
    minHeight: 59,
    paddingLeft: 20,
    borderLeftWidth: 1,
    borderLeftColor: colors.lila[300],
    flex: 1,
  },
  eventNameRow: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  paddingTop2: {
    paddingTop: 2,
  },
});
const EventWithoutHoursListItem = ({
  planningEvent,
  style,
  ...props
}: EventWithoutHoursListItemProps): ReactElement => {
  const [isPressing, setIsPressing] = useState<boolean>(false);

  const color = useMemo(
    () => (isPressing ? colors.blue[500] : colors.lila[800]),
    [isPressing],
  );

  return (
    <TouchableWithoutFeedback
      onPressIn={() => setIsPressing(true)}
      onPressOut={() => setIsPressing(false)}
      {...props}
    >
      <View style={[styles.container, style]}>
        <View style={[styles.eventColor]} />
        <View style={styles.eventIconContainer}>
          <EventIcon
            eventType={planningEvent.eventType}
            size={24}
            color={color}
          />
        </View>

        <View style={styles.eventInfoContainer}>
          <View style={styles.eventNameRow}>
            <Typography fontWeight={700} overrideColor={color}>
              {showEventName(planningEvent.eventType) ? (
                planningEvent.name.trim()
              ) : (
                <FormattedMessage
                  id={`mobileApp.event.eventType.${planningEvent.eventType}`}
                />
              )}
            </Typography>
          </View>
          <View style={styles.paddingTop2}>
            <Typography overrideColor={color}>
              {planningEvent?.location?.city &&
                `${planningEvent.location.city} - `}
              <FormattedDate
                value={planningEvent.start}
                day="numeric"
                month="long"
                year="numeric"
              />
            </Typography>
          </View>
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
};
export default EventWithoutHoursListItem;
