import { isQuoteEvent } from './QuoteEvent';
import { QuoteEventType, QuoteOpenedEvent } from '../types';

export function isQuoteOpenedEvent(
  object: unknown,
): object is QuoteOpenedEvent {
  return (
    isQuoteEvent(object) && object.eventType === QuoteEventType.QuoteOpenedEvent
  );
}
