import { Box } from '@bas/ui/native/base';
import {
  ReactHookFormDatePickerField,
  ReactHookFormNumberTextField,
  ReactHookFormTextField,
} from '@bas/ui/native/molecules';
import { ReactElement, ReactNode } from 'react';
import { useIntl } from 'react-intl';

export type PaymentFormProps = {
  children?: ReactNode;
  light?: boolean;
};

const PaymentForm = ({ children, light }: PaymentFormProps): ReactElement => {
  const { formatMessage } = useIntl();
  return (
    <Box>
      <Box>
        <ReactHookFormDatePickerField
          name="paidAt"
          light={light}
          label={formatMessage({ id: 'label.paymentDate' })}
          mode="date"
        />
      </Box>
      <Box>
        <ReactHookFormNumberTextField
          name="amount.amount"
          autoComplete="off"
          label={formatMessage({ id: 'label.paymentAmount' })}
          light={light}
          keyboardType="decimal-pad"
          textAlign="right"
          divideBy={100}
          fractionDigits={2}
          type="currency"
        />
      </Box>
      <Box>
        <ReactHookFormTextField
          name="remarks"
          autoComplete="off"
          multiline
          label={formatMessage({ id: 'label.remarks' })}
          light={light}
        />
      </Box>
      {children}
    </Box>
  );
};

export default PaymentForm;
