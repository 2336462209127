import type { LocationMessageMetadata } from '../../types';
import { MessageType } from '../../types';
import { isMessageMetadata } from './MessageMetadata';

export function isLocationMessageMetadata(
  object: unknown,
): object is LocationMessageMetadata {
  return (
    isMessageMetadata(object) &&
    object.messageType === MessageType.LOCATION_MESSAGE
  );
}
