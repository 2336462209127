import { formatDate } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type AddEmployeeCertificateProps = {
  employeeId: Uuid;
  certificate: {
    certificate: string;
    validFrom?: Date | null;
    validUntil?: Date | null;
  };
};

export const AddEmployeeCertificate = async ({
  employeeId,
  certificate: { validFrom, validUntil, ...certificate },
  ...values
}: AddEmployeeCertificateProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/hrm/employees/${employeeId}/add-certificate`, {
    employeeId,
    certificate: {
      ...certificate,
      validFrom: validFrom ? formatDate(validFrom) : null,
      validUntil: validUntil ? formatDate(validUntil) : null,
    },
    ...values,
  });

export const useAddEmployeeCertificate = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddEmployeeCertificateProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  AddEmployeeCertificateProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddEmployeeCertificateProps
  >({
    mutationFn: AddEmployeeCertificate,
    throwOnError: false,
    ...options,
  });
