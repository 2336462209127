import { QueryOptionsWithKey } from '@bas/shared/requests';
import { Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ProjectEventSignedContractPdfByEventIdRequestProps = {
  eventId: Uuid;
};

export const ProjectEventSignedContractPdfByEventIdRequest = async ({
  eventId,
  ...params
}: ProjectEventSignedContractPdfByEventIdRequestProps): Promise<
  AxiosResponse<Blob>
> =>
  axios.get(`api/{tenantId}/events/${eventId}/download-signed-contract`, {
    responseType: 'blob',
    params,
  });

export const useProjectEventSignedContractPdfByEventIdRequestQuery = (
  request: ProjectEventSignedContractPdfByEventIdRequestProps,
  options: QueryOptionsWithKey<
    AxiosResponse<Blob>,
    AxiosError,
    AxiosResponse<Blob>
  > = {},
): UseQueryResult<AxiosResponse<Blob>, AxiosError> =>
  useQuery<AxiosResponse<Blob>, AxiosError, AxiosResponse<Blob>>({
    ...options,
    queryFn: async () => ProjectEventSignedContractPdfByEventIdRequest(request),
    queryKey: ['events', 'pdf-signed-contract', request.eventId],
    gcTime: 0,
    staleTime: 0,
  });
