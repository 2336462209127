import { TrafficObstructionSummary } from '@bas/shared/models';
import { ReactElement } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { colors } from '@bas/theme';
import { Typography } from '@bas/ui/native/base';
import {
  LoadingTrafficObstructionListItem,
  TrafficObstructionListItem,
} from '@bas/ui/native/molecules';

export type MobileTrafficObstructionsProps = {
  traffic: TrafficObstructionSummary[];
  maximumObstructionsToShow?: number;
  onShowAll: () => void;
  numberOfExtraTraffic?: number;
  isLoaded?: boolean;
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
  },
  trafficContainer: {
    marginBottom: 24,
  },
  badge: {
    backgroundColor: colors.blue[500],
    borderRadius: 100,
    position: 'absolute',
    right: 0,
    top: 0,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 2,
    paddingBottom: 2,
  },
  badgeText: {
    color: colors.white,
  },
});

const MobileTrafficObstructions = ({
  traffic,
  maximumObstructionsToShow,
  onShowAll,
  numberOfExtraTraffic,
  isLoaded,
}: MobileTrafficObstructionsProps): ReactElement => {
  if (!isLoaded) {
    return (
      <View style={styles.container}>
        <LoadingTrafficObstructionListItem />
      </View>
    );
  }

  if (traffic.length === 0) {
    // @todo party hard!
    return <Typography>No traffic</Typography>;
  }

  return (
    <View style={styles.container}>
      {!!numberOfExtraTraffic && numberOfExtraTraffic > 0 && (
        <View style={styles.badge}>
          <Typography style={styles.badgeText}>
            {numberOfExtraTraffic}
          </Typography>
        </View>
      )}
      {traffic
        .slice(0, maximumObstructionsToShow || 1)
        .map((obstruction, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <TouchableOpacity onPress={onShowAll} key={index}>
            <TrafficObstructionListItem obstruction={obstruction} />
          </TouchableOpacity>
        ))}
    </View>
  );
};

export default MobileTrafficObstructions;
