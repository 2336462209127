import {
  useGenerateQuoteByProjectMutation,
  useOverwriteEstimateValuesMutation,
} from '@bas/project-domain/mutations';
import { MovingJobTaxationWizardInput } from '@bas/taxation-tool-domain/models';
import {
  MovingJobEstimateStepTemplate,
  RequestingEstimateStepTemplate,
} from '@bas/taxation-tool-domain/native/templates';
import { useWizardStore } from '@bas/ui/native/organisms';
import { ReactElement, useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { UseFormReturn, useFormState } from 'react-hook-form';

const MovingJobEstimationStep = ({
  onNext,
}: {
  onNext: (disableOverriddenSubmit?: boolean, force?: boolean) => Promise<void>;
}): ReactElement => {
  const { isSubmitting } = useFormState<MovingJobTaxationWizardInput>();
  const { setTemporarySubmit } = useWizardStore();

  const [loading, setLoading] = useState<boolean>(false);
  const { mutateAsync: overwriteValue } = useOverwriteEstimateValuesMutation({
    throwOnError: true,
  });
  const { mutateAsync: generateQuoteMutation } =
    useGenerateQuoteByProjectMutation({
      throwOnError: true,
    });

  useEffect(() => {
    setTemporarySubmit(
      ({
        setValue: setFormValue,
      }: UseFormReturn<MovingJobTaxationWizardInput>) =>
        async ({
          latestQuoteRequestedValues,
          estimate,
          projectId,
          overwrittenEstimateReason,
          ignoreZeroQuantityLines,
        }: MovingJobTaxationWizardInput) => {
          setLoading(true);
          try {
            if (!isEqual(latestQuoteRequestedValues, estimate)) {
              const items = estimate?.items || [];
              await overwriteValue({
                projectId,
                reason: overwrittenEstimateReason || null,
                overwriteItems: items
                  .filter(
                    ({ overwritten, calculated, wasOverwritten }) =>
                      overwritten?.quantity !== calculated?.quantity ||
                      overwritten?.pricePerUnit?.amount !==
                        calculated?.pricePerUnit?.amount ||
                      wasOverwritten,
                  )
                  .map((item) => ({
                    lineNumber: item.lineNumber,
                    pricePerUnit:
                      !!item.overwritten?.pricePerUnit?.amount &&
                      !!item.overwritten?.pricePerUnit?.currency
                        ? item.overwritten?.pricePerUnit
                        : null,
                    quantity: item.overwritten?.quantity || null,
                  })),
              });

              const { data } = await generateQuoteMutation({
                projectId,
              });

              setFormValue('quote', {
                ...data,
                ignoreZeroQuantityLines,
                lines: data.lines.map((line) => ({ ...line, created: true })),
              });
              setFormValue('latestQuoteRequestedValues', {
                projectId,
                overwrittenEstimateReason,
                estimate,
              });
            }

            setTemporarySubmit(undefined);
            await onNext(true, true);
          } catch (exception) {
            // eslint-disable-next-line no-console
            console.error(exception);
            throw exception;
          }
        },
    );

    return () => {
      setTemporarySubmit(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isSubmitting || loading) {
    return <RequestingEstimateStepTemplate />;
  }

  return <MovingJobEstimateStepTemplate />;
};

export default MovingJobEstimationStep;
