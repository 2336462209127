import { formatDate, formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { v7 } from 'uuid';

export type RequestTimeOffMutationProps = {
  timeOffId: Uuid;
  employeeId: Uuid;
  start: Date;
  end: Date;
  fullDay: boolean;
  timeOffTypeId: Uuid;
  reason: string;
  effectiveHours: number;
  buildUp: boolean;
  daysOff: {
    start: Date;
    end: Date;
    hours: number;
  }[];
};

export const RequestTimeOffMutation = async ({
  employeeId,
  start,
  end,
  daysOff,
  ...values
}: RequestTimeOffMutationProps): Promise<AxiosResponse> =>
  axios.post(
    `api/{tenantId}/hrm/employees/${employeeId}/time-off-registrations/request`,
    {
      employeeId,
      start: formatDate(start),
      end: formatDate(end),
      daysOff: daysOff.map((dayOff) => ({
        ...dayOff,
        dayOffId: v7(),
        start: formatDateTime(dayOff.start),
        end: formatDateTime(dayOff.end),
      })),
      ...values,
    },
  );

export const useRequestTimeOffMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RequestTimeOffMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  RequestTimeOffMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RequestTimeOffMutationProps
  >({
    mutationFn: RequestTimeOffMutation,
    throwOnError: false,
    ...options,
  });
