import {
  TimeTrackingItemInputType,
  timeTrackingItemInputTypeDefaultValues,
  timeTrackingItemInputTypeValidationBuilder,
} from '@bas/hrm-domain/input-types';
import {
  isTimeEntry,
  TimeEntry,
  TimeEntryStatus,
} from '@bas/hrm-domain/models';
import {
  TimeEntryApprovalInformationContentSection,
  TimeEntryInformationContentSection,
  TimeEntryRejectionInformationContentSection,
  TimeEntryRelatedContentSection,
} from '@bas/hrm-domain/native/molecules';
import { colors } from '@bas/theme';
import { Button } from '@bas/ui/native/atoms';
import { BottomSheetMethods, Box, Typography } from '@bas/ui/native/base';
import { BackendErrors, BottomSheetProps } from '@bas/ui/native/organisms';
import { FormBottomSheet } from '@bas/ui/native/templates';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { ReactElement, RefObject, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { TimeTrackingItemForm } from '../TimeTrackingItemForm';

export type ShowAndChangeTimeEntryBottomSheetProps = Omit<
  BottomSheetProps,
  'snapPoints' | 'children'
> & {
  onSubmit: (values: TimeTrackingItemInputType) => Promise<void> | void;
  onStartRemoving: (item: TimeEntry) => Promise<void> | void;
  bottomSheetRef?: RefObject<BottomSheetModal & BottomSheetMethods>;
};

const ShowAndChangeTimeEntryBottomSheet = ({
  onSubmit,
  onStartRemoving,
  bottomSheetRef,
  ...props
}: ShowAndChangeTimeEntryBottomSheetProps): ReactElement => {
  const handleFinalize = useCallback(() => {
    bottomSheetRef?.current?.close();
    bottomSheetRef?.current?.forceClose();
    bottomSheetRef?.current?.dismiss();
  }, [bottomSheetRef]);

  const handleDefaultValues = useCallback(
    (data: { item: TimeEntry }): TimeTrackingItemInputType => ({
      ...timeTrackingItemInputTypeDefaultValues(),
      ...data.item,
      isExisting: isTimeEntry(data.item),
      project: {
        projectId: data.item.projectId,
      },
      relation: {
        relationId: data.item.relationId,
      },
    }),
    [],
  );

  const handleShouldHideSubmit = useCallback(
    ({ item }: { item: TimeEntry }) =>
      ![TimeEntryStatus.OPEN, TimeEntryStatus.REJECTED].includes(item.status),
    [],
  );

  const handleRenderContent = useCallback(
    ({ item }: { item: TimeEntry }) => (
      <Box flex={1}>
        <Typography
          fontWeight={700}
          color="white"
          textAlign="center"
          fontSize={22}
          lineHeight={29}
        >
          <FormattedMessage id="label.timeEntryDetails" />
        </Typography>
        <Box paddingHorizontal={20} marginBottom={2}>
          <BackendErrors />
          <Box paddingTop={2}>
            <TimeEntryInformationContentSection timeEntry={item} />
          </Box>
          <Box paddingTop={2}>
            <TimeEntryRelatedContentSection
              projectId={item.projectId}
              relationId={item.relationId}
              eventId={item.eventId}
            />
          </Box>
          {item.approvedOn && (
            <Box paddingTop={2}>
              <TimeEntryApprovalInformationContentSection timeEntry={item} />
            </Box>
          )}
          {item.rejectedOn && (
            <Box paddingTop={2}>
              <TimeEntryRejectionInformationContentSection timeEntry={item} />
            </Box>
          )}
          {[TimeEntryStatus.OPEN, TimeEntryStatus.REJECTED].includes(
            item.status,
          ) && (
            <Box paddingTop={2}>
              <Button
                onPress={() => onStartRemoving(item)}
                buttonColor="danger"
              >
                <FormattedMessage id="label.removeTimeEntry" />
              </Button>
            </Box>
          )}
          {[TimeEntryStatus.OPEN, TimeEntryStatus.REJECTED].includes(
            item.status,
          ) && (
            <Box paddingTop={2}>
              <Typography variant="subtitle1" color="white" mb={1}>
                <FormattedMessage id="label.changeTimeTrackingItem" />
              </Typography>
              <TimeTrackingItemForm />
            </Box>
          )}
        </Box>
      </Box>
    ),
    [onStartRemoving],
  );

  return (
    <FormBottomSheet<TimeTrackingItemInputType, { item: TimeEntry }>
      bottomSheetRef={bottomSheetRef}
      disablePadding
      onSubmit={onSubmit}
      useProvider
      resetFormOnOpen
      onCancel={handleFinalize}
      validationSchema={timeTrackingItemInputTypeValidationBuilder}
      successFullFormViewProps={{
        gifUrl: 'https://media.giphy.com/media/26xBFg4zXVwzdI9lS/giphy.gif',
        color: colors.lila[100],
        title: 'label.timeTrackingItemSaved',
      }}
      useData
      defaultValues={handleDefaultValues}
      hideSubmit={handleShouldHideSubmit}
      renderWithData={handleRenderContent}
      {...props}
    />
  );
};

export default ShowAndChangeTimeEntryBottomSheet;
