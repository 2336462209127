import { ErrorResponse, Uuid } from '@bas/value-objects';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ConnectConversationToEmployeeMutationProps = {
  conversationId: Uuid;
  employeeId: Uuid;
};

export const ConnectConversationToEmployeeMutation = async ({
  conversationId,
  employeeId,
  ...params
}: ConnectConversationToEmployeeMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/chat/conversations/${conversationId}/connect-to-employee`,
    {
      conversationId,
      employeeId,
    },
    { params },
  );

export const useConnectConversationToEmployeeMutation = (): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ConnectConversationToEmployeeMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ConnectConversationToEmployeeMutationProps
  >({
    mutationFn: ConnectConversationToEmployeeMutation,
    throwOnError: false,
  });
