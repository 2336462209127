import { isObjectResponse } from '@bas/value-objects';
import { BillingMoment } from '../types';

export function isBillingMoment(object: unknown): object is BillingMoment {
  return (
    !!object &&
    isObjectResponse(object) &&
    [
      'BillingMomentAfterStorageWasLoaded',
      'BillingMomentBeforeProjectStarts',
      'BillingMomentWhenProjectIsFinished',
      'BillingMomentInvoiceWithoutProject',
      'BillingMomentAfterAcceptedQuote',
    ].includes(object['@type'])
  );
}
