import { TextField } from '@bas/ui/native/atoms';
import { TextFieldProps } from '@bas/ui/native/base';
import { ReactElement, useCallback, useEffect, useMemo, useRef } from 'react';
import {
  Control,
  FieldPath,
  FieldPathValue,
  FieldValues,
  useController,
  UseFormSetFocus,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { TextInput } from 'react-native';

export type ReactHookFormTextFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<TextFieldProps, 'name' | 'value' | 'error'> & {
  name: TName;
  control?: Control<TFieldValues>;
  updateOnBlur?: boolean;
  disableError?: boolean;
  loading?: boolean;
  setFocus?: UseFormSetFocus<TFieldValues>;
  nextField?: FieldPath<TFieldValues>;
  onSubmit?: () => void | Promise<void>;
  onFocus?: () => void;
  onBlur?: () => void;
  variant?: 'outlined' | 'standard' | 'filled';
};

const ReactHookFormTextField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  control,
  disabled,
  disableError,
  loading,
  setFocus,
  nextField,
  onSubmit,
  onFocus: onFocusProp,
  onBlur: onBlurProp,
  ...props
}: ReactHookFormTextFieldProps<TFieldValues, TName>): ReactElement => {
  const {
    field: { value, onBlur, onChange, ref },
    fieldState: { error, invalid },
    formState: { isSubmitting },
  } = useController<TFieldValues, TName>({
    name,
    control,
  });
  const { formatMessage } = useIntl();
  const fieldError = error?.message;
  const showError = !disableError && invalid;
  const inputRef = useRef<TextInput | null>(null);

  const textFieldArgs = useMemo(
    () => ({
      validationMessage: showError ? fieldError : undefined,
      enableErrors: true,
      error: showError ? !!fieldError : undefined,
      disabled: disabled ?? isSubmitting,
    }),
    [showError, fieldError, disabled, isSubmitting],
  );

  const selectNextFieldOrSubmit = useCallback(() => {
    if (nextField) {
      setFocus?.(nextField, { shouldSelect: false });
    } else if (onSubmit) {
      onSubmit();
    }
  }, [nextField, onSubmit, setFocus]);

  useEffect(() => {
    if (inputRef.current) {
      ref(inputRef.current);
    }
  }, [ref, inputRef]);

  const valueProp: { value?: string } = {};
  if (loading) {
    valueProp.value = formatMessage({ id: 'label.loading' });
  } else {
    valueProp.value = value || '';
  }

  const handleBlur = useCallback(
    () => () => {
      onBlurProp?.();
      onBlur();
    },
    [onBlur, onBlurProp],
  );

  const handleChange = useCallback(
    (text: string) => {
      onChange(text as FieldPathValue<TFieldValues, TName>);
    },
    [onChange],
  );

  return (
    <TextField
      onChangeText={handleChange}
      value={value?.toString()}
      {...textFieldArgs}
      {...props}
      {...valueProp}
      onSubmitEditing={selectNextFieldOrSubmit}
      blurOnSubmit={false}
      onBlur={handleBlur}
      onFocus={onFocusProp}
      inputRef={inputRef}
    />
  );
};

export default ReactHookFormTextField;
