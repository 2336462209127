import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type AddEmployeeAttributeMutationProps = {
  employeeAttributeId: Uuid;
  name: string;
  description: string;
};

export const AddEmployeeAttributeMutation = async ({
  ...values
}: AddEmployeeAttributeMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/{tenantId}/hrm/employee-attributes`, {
    ...values,
  });

export const useAddEmployeeAttributeMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddEmployeeAttributeMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  AddEmployeeAttributeMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddEmployeeAttributeMutationProps
  >({
    mutationFn: AddEmployeeAttributeMutation,
    throwOnError: false,
    ...options,
  });
