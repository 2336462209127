export const billingMomentPeriodMapper: { [key: string]: string } = {
  '1 second': 'direct',
  '5 minutes': 'fiveMinutes',
  '1 hour': 'hour',
  '1 day': 'day',
  '1 week': 'week',
  '2 weeks': 'twoWeeks',
  '1 month': 'month',
};

export const getBillingMomentPeriodName = (period: string) =>
  billingMomentPeriodMapper[period] || period;
