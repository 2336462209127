import { formatDate, formatDateTime } from '@bas/shared/utils';
import { EmailAddress, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type FinalizeInvoiceMutationProps = {
  invoiceId: Uuid;
  invoicedAt: Date;
  expiresAt: Date;
  reportingYear?: number;
  reportingPeriod?: number;
  sendInvoice?: boolean;
  extraEmailAddresses?: EmailAddress[];
};

export const FinalizeInvoiceMutation = async ({
  invoiceId,
  invoicedAt,
  expiresAt,
  ...values
}: FinalizeInvoiceMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/invoices/${invoiceId}/finalize`, {
    invoiceId,
    invoicedAt: formatDateTime(invoicedAt),
    expiresAt: formatDate(expiresAt),
    ...values,
  });

export const useFinalizeInvoiceMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    FinalizeInvoiceMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  FinalizeInvoiceMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    FinalizeInvoiceMutationProps
  >({
    mutationFn: FinalizeInvoiceMutation,
    throwOnError: false,
    ...options,
  });
