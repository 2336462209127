import { Vehicle } from '@bas/planning-domain/models';
import { Country } from '@bas/value-objects';
import { memo, ReactElement } from 'react';
import isEqual from 'react-fast-compare';
import { View } from 'react-native';
import { PlannedMaterialListItem } from '../PlannedMaterialListItem';

export type PlannedMaterialListProps = {
  materials: Vehicle[];
  onPressMaterial: (material: Vehicle) => void;
  country: Country | undefined | null;
};

const PlannedMaterialList = ({
  materials,
  onPressMaterial,
  country,
}: PlannedMaterialListProps): ReactElement => (
  <View>
    {materials.map((material, index) => (
      <PlannedMaterialListItem
        key={material.materialId}
        material={material}
        country={country}
        onPress={() => onPressMaterial(material)}
        isLast={materials.length - 1 === index}
      />
    ))}
  </View>
);
const MemoComponent = memo(PlannedMaterialList, isEqual);
export default MemoComponent;
