import { formatDate } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type CancelEventMutationProps = {
  eventId: Uuid;
  date?: Date;
  updateFutureEvents: boolean;
};

export const CancelEventMutation = async ({
  eventId,
  date,
  ...values
}: CancelEventMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/events/${eventId}/cancel`, {
    eventId,
    date: date ? formatDate(date) : undefined,
    ...values,
  });

export const useCancelEventMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    CancelEventMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  CancelEventMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    CancelEventMutationProps
  >({
    mutationFn: CancelEventMutation,
    throwOnError: false,
    ...options,
  });
