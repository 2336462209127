import { QueryOptionsWithKey } from '@bas/shared/requests';
import { Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type QuoteImageByQuoteIdRequestProps = {
  quoteId: Uuid;
};

export const QuoteImageByQuoteIdRequest = async ({
  quoteId,
  ...params
}: QuoteImageByQuoteIdRequestProps): Promise<AxiosResponse<Blob>> =>
  axios.get(`api/{tenantId}/quotes/${quoteId}/download-image`, {
    responseType: 'blob',
    params,
  });

export const useQuoteImageByQuoteIdRequestQuery = (
  request: QuoteImageByQuoteIdRequestProps,
  options: QueryOptionsWithKey<
    AxiosResponse<Blob>,
    AxiosError,
    AxiosResponse<Blob>
  > = {},
): UseQueryResult<AxiosResponse<Blob>, AxiosError> =>
  useQuery<AxiosResponse<Blob>, AxiosError, AxiosResponse<Blob>>({
    ...options,
    queryFn: async () => QuoteImageByQuoteIdRequest(request),
    queryKey: ['quotes', 'image', request.quoteId],
  });
