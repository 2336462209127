import { Money } from '@bas/value-objects';
import * as Yup from 'yup';

export interface ProcessInvoicePaymentInputType {
  amount: Money;
  paidAt?: Date | null;
  remarks?: string | null;
}

export const ProcessInvoicePaymentInputTypeDefaultValues =
  (): ProcessInvoicePaymentInputType => ({
    amount: { amount: 0, currency: 'EUR' },
    paidAt: null,
    remarks: '',
  });

export const ProcessInvoicePaymentInputTypeValidationBuilder =
  (): Yup.ObjectSchema<ProcessInvoicePaymentInputType> =>
    Yup.object({
      amount: Yup.object({
        amount: Yup.number()
          .transform((value, original) =>
            original === '' || original === null ? null : value,
          )
          .nullable()
          .required()
          .label('label.paymentAmount'),
        currency: Yup.string().required(),
      }),
      paidAt: Yup.date().required().label('label.paymentDate'),
      remarks: Yup.string().label('label.remarks'),
    });
