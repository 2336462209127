import { StyleSheet } from 'react-native';
import { colors } from '@bas/theme';

export default StyleSheet.create({
  paddingUnderQuestion: {
    paddingBottom: 24,
  },
  questionLabel: {
    paddingBottom: 8,
  },
  uploadButton: {
    backgroundColor: 'transparent',
    borderColor: colors.white,
    flex: 1,
  },
  flex: {
    flex: 1,
  },
  buttons: {
    flex: 1,
    flexDirection: 'row',
    paddingTop: 16,
  },
  paddingRight: {
    paddingRight: 16,
  },
  photosContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
  },
});
