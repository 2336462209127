import { colors } from '@bas/theme';
import { BottomSheetMethods } from '@bas/ui/native/base';
import { BottomSheetProps } from '@bas/ui/native/organisms';
import { FormBottomSheet } from '@bas/ui/native/templates';
import { Uuid } from '@bas/value-objects';
import {
  MoveStoredItemsInputType,
  moveStoredItemsInputTypeDefaultValues,
  moveStoredItemsInputTypeValidationBuilder,
} from '@bas/wms-domain/input-types';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { ReactElement, RefObject, useCallback, useState } from 'react';
import * as Yup from 'yup';
import { MoveStoredItemsForm } from '../MoveStoredItemsForm';

export type MoveStoredItemsBottomSheetProps = Omit<
  BottomSheetProps,
  'snapPoints' | 'children'
> & {
  bottomSheetRef?: RefObject<BottomSheetModal & BottomSheetMethods>;
  onSubmit: (
    data: MoveStoredItemsInputType & {
      selectedStorageId?: Uuid;
    },
  ) => void;
};

const MoveStoredItemsBottomSheet = ({
  bottomSheetRef,
  onSubmit,
  ...props
}: MoveStoredItemsBottomSheetProps): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const onClose = useCallback(() => {
    bottomSheetRef?.current?.close();
    bottomSheetRef?.current?.forceClose();
    bottomSheetRef?.current?.dismiss();
  }, [bottomSheetRef]);

  const handleChangeBottomSheet = useCallback(async (index: number) => {
    if (index === 0) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, []);

  return (
    <FormBottomSheet<
      MoveStoredItemsInputType & {
        currentLocationId?: Uuid;
        newLocationId?: Uuid;
      }
    >
      bottomSheetRef={bottomSheetRef}
      disablePadding
      enableDismissOnClose
      onChange={handleChangeBottomSheet}
      onSubmit={onSubmit}
      useProvider
      resetFormOnOpen
      onCancel={onClose}
      disableScroll
      defaultValues={{
        ...moveStoredItemsInputTypeDefaultValues(),
      }}
      hideBackendErrors
      validationSchema={moveStoredItemsInputTypeValidationBuilder().concat(
        Yup.object({
          currentLocationId: Yup.string().required(),
          newLocationId: Yup.string().required(),
        }),
      )}
      successFullFormViewProps={{
        gifUrl: 'https://media.giphy.com/media/N35rW3vRNeaDC/giphy.gif',
        title: 'label.storedItemsAreMoved',
        color: colors.lila[100],
        labelId: 'label.storedItemsAreMovedToTheNewLocation',
      }}
      {...props}
    >
      <MoveStoredItemsForm isOpen={isOpen} />
    </FormBottomSheet>
  );
};

export default MoveStoredItemsBottomSheet;
