import { Uuid } from '@bas/value-objects';
import dayjs from 'dayjs';
import * as Yup from 'yup';

export type EnterTimeEntryInputType = {
  employeeId: Uuid;
  timeTypeId: Uuid;
  description?: string;
  eventId?: Uuid | null;
  project?: {
    projectId?: Uuid | null;
  };
  relation?: {
    relationId?: Uuid | null;
  };
  start?: Date | null;
  end?: Date | null;
  changeOverlappingTimeEntries?: boolean | null;
};

export const enterTimeEntryInputTypeDefaultValues =
  (): EnterTimeEntryInputType => ({
    employeeId: '',
    timeTypeId: '',
    description: '',
    eventId: '',
    project: {
      projectId: '',
    },
    relation: {
      relationId: '',
    },
    start: null,
    end: null,
    changeOverlappingTimeEntries: true,
  });

export const enterTimeEntryInputTypeValidationBuilder =
  (): Yup.ObjectSchema<EnterTimeEntryInputType> =>
    Yup.object({
      employeeId: Yup.string().label('label.employeeId').ensure().required(),
      timeTypeId: Yup.string().label('label.timeTypeId').ensure().required(),
      description: Yup.string().label('label.description').ensure(),
      eventId: Yup.string().optional().label('label.eventId').nullable(),
      project: Yup.object({
        projectId: Yup.string().optional().label('label.project').nullable(),
      }),
      relation: Yup.object({
        relationId: Yup.string().optional().label('label.relation').nullable(),
      }),

      start: Yup.date().required().label('label.start'),
      end: Yup.date()
        .when(['start'], ([start], schema) => {
          if (!start || !dayjs(start).isValid()) {
            return schema;
          }

          return schema
            .min(dayjs(start).clone().add(1, 'second').toDate())
            .max(dayjs(start).clone().endOf('day').toDate())
            .required();
        })
        .label('label.end'),
      changeOverlappingTimeEntries: Yup.boolean()
        .optional()
        .nullable()
        .label('label.changeOverlappingTimeEntries'),
    });
