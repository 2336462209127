import { ReactElement, useCallback, useEffect, useMemo, useRef } from 'react';
import { Animated, Easing, Platform } from 'react-native';
import { Icon, IconProps } from '../Icon';

export type IconWithSpinProps = IconProps;

const IconWithSpin = ({ ...props }: IconWithSpinProps): ReactElement => {
  const spinValue = useRef(new Animated.Value(0)).current;

  const spin = useCallback(() => {
    spinValue.setValue(0);

    Animated.timing(spinValue, {
      toValue: 1,
      duration: 1000,
      easing: Easing.linear,
      useNativeDriver: Platform.OS !== 'web',
    }).start(() => spin());
  }, [spinValue]);

  const rotate = useMemo(
    () =>
      spinValue.interpolate({
        inputRange: [0, 1],
        outputRange: ['0deg', '360deg'],
      }),
    [spinValue],
  );

  useEffect(() => spin(), [spin]);

  return (
    <Animated.View style={{ transform: [{ rotate }] }}>
      <Icon {...props} />
    </Animated.View>
  );
};

export default IconWithSpin;
