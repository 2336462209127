import { isIcon } from '@bas/svg-icons';
import { SquareTile, SquareTileProps } from '@bas/ui/native/atoms';
import {
  Box,
  Icon,
  IconProps,
  Typography,
  TypographyProps,
} from '@bas/ui/native/base';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import {
  cloneElement,
  isValidElement,
  memo,
  ReactElement,
  useMemo,
} from 'react';
import { BackendIcon } from '../BackendIcon';
import styles from './styles';

export type SquareIconTileProps = Omit<SquareTileProps, 'children'> & {
  icon:
    | IconProp
    | ReactElement<IconProps>
    | { prefix: string; iconName: string }
    | string;
  label: string | ReactElement<TypographyProps>;
  selected?: boolean;
};

const SquareIconTile = ({
  icon,
  selected,
  label,
  ...props
}: SquareIconTileProps): ReactElement => {
  const color = useMemo(() => (selected ? 'white' : 'darkText'), [selected]);

  return (
    <SquareTile selected={selected} {...props}>
      {selected && (
        <Icon
          icon={faCheck}
          style={styles.selectedIcon}
          color={color}
          size={22}
        />
      )}
      <Box
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        flex={1}
      >
        {isValidElement(icon) &&
          cloneElement(icon as ReactElement<IconProps>, {
            color,
            size: 61,
          })}
        {typeof icon === 'string' && (
          <BackendIcon icon={icon} size={61} color={color} />
        )}
        {isIcon(icon) && <Icon icon={icon} size={61} color={color} />}
        <Box style={styles.label}>
          {isValidElement(label) ? (
            cloneElement(label as ReactElement<TypographyProps>, {
              color,
              fontWeight: 700,
            })
          ) : (
            <Typography fontWeight={700} color={color}>
              {label}
            </Typography>
          )}
        </Box>
      </Box>
    </SquareTile>
  );
};

const MemoComponent = memo(
  SquareIconTile,
  (prevProps, nextProps) =>
    prevProps.selected === nextProps.selected &&
    prevProps.width === nextProps.width &&
    prevProps.label === nextProps.label,
);
export default MemoComponent;
