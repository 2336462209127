import { WhatInventoryItemsAreNeededStepInputType } from '@bas/taxation-tool-domain/input-types';
import { MobileTaxationStepTemplate } from '@bas/taxation-tool-domain/native/templates';
import { colors } from '@bas/theme';
import { Skeleton } from '@bas/ui/native/atoms';
import { Box, Typography } from '@bas/ui/native/base';
import { ReactHookFormNumberTextField } from '@bas/ui/native/molecules';
import { useReusableMaterialsRequest } from '@bas/wms-domain/requests';
import { FlashList, ListRenderItemInfo } from '@shopify/flash-list';
import { useResponsiveProp } from '@shopify/restyle';
import { keepPreviousData } from '@tanstack/react-query';
import { memo, ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useFieldArray } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  input: {
    textAlign: 'center',
  },
});

const WhatInventoryItemsAreNeededStep = (): ReactElement => {
  const { isPending: isLoadingReusableMaterials, data: reusableMaterialsData } =
    useReusableMaterialsRequest(
      {
        page: 1,
        perPage: 999999,
      },
      {
        placeholderData: keepPreviousData,
      },
    );

  const { fields: neededInventoryItems, append } = useFieldArray<
    WhatInventoryItemsAreNeededStepInputType,
    'neededInventoryItems'
  >({
    name: 'neededInventoryItems',
  });

  const inventoryItemTypes = useMemo(
    () =>
      isLoadingReusableMaterials
        ? []
        : reusableMaterialsData?.data.member || [],
    [reusableMaterialsData?.data, isLoadingReusableMaterials],
  );

  useEffect(() => {
    if (
      inventoryItemTypes.length > 0 &&
      neededInventoryItems.length !== inventoryItemTypes.length
    ) {
      const newValue = [...neededInventoryItems];
      inventoryItemTypes.forEach((item) => {
        if (
          !newValue.find(
            ({ inventoryItemId }) => inventoryItemId === item.inventoryItemId,
          )
        ) {
          append({
            inventoryItemId: item.inventoryItemId,
            quantity: 0,
          });
        }
      });
    }
  }, [inventoryItemTypes, neededInventoryItems, append]);

  const mappedNeededInventoryItems = useMemo(
    () =>
      inventoryItemTypes.map((item) => {
        const inventoryItem = neededInventoryItems.find(
          ({ inventoryItemId }) => inventoryItemId === item.inventoryItemId,
        );

        return {
          name: item.name,
          inventoryItemIndex: neededInventoryItems.findIndex(
            ({ inventoryItemId }) => inventoryItemId === item.inventoryItemId,
          ),
          inventoryItemId: item.inventoryItemId,
          quantity: inventoryItem?.quantity || 0,
          storageQuantity: 0,
        };
      }),
    [neededInventoryItems, inventoryItemTypes],
  );

  const handleRenderItem = useCallback(
    ({
      item: { name, inventoryItemIndex },
    }: ListRenderItemInfo<{ name: string; inventoryItemIndex: number }>) => (
      <Box
        flex={1}
        pt={2}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box flexBasis="58.33333333%">
          <Typography variant="h5" fontWeight={700}>
            {name}
          </Typography>
        </Box>
        <Box maxWidth={125} width="41.66666667%">
          <ReactHookFormNumberTextField
            variant="outlined"
            keyboardType="decimal-pad"
            style={styles.input}
            name={`neededInventoryItems[${inventoryItemIndex}].quantity`}
            key={`neededInventoryItems[${inventoryItemIndex}].quantity`}
            label={name}
            shouldBeZero
            showZero
            fractionDigits={2}
            padZero={false}
          />
        </Box>
      </Box>
    ),
    [],
  );

  const paddingHorizontal = useResponsiveProp({
    largerTablet: 80,
    tablet: 50,
    phone: 20,
  });

  const loadingItems = useMemo(() => new Array(10).fill(''), []);

  const handleRenderLoadingItem = useCallback(
    () => (
      <Box
        style={{
          maxHeight: 60,
          maxWidth: '100%',
          borderRadius: 6,
          marginLeft: 19,
          marginTop: 19,
          overflow: 'hidden',
          backgroundColor: colors.lila[300],
        }}
      >
        <Skeleton
          style={{
            height: '100%',
            width: '100%',
          }}
        />
      </Box>
    ),
    [],
  );

  return (
    <MobileTaxationStepTemplate
      primary={
        <FormattedMessage id="mobileApp.intake.movingJob.whatInventoryItemsAreNeeded" />
      }
      disableScroll
      disableContentPadding
      secondary={<FormattedMessage id="label.rooms" />}
    >
      {isLoadingReusableMaterials ? (
        <FlashList
          renderItem={handleRenderLoadingItem}
          data={loadingItems}
          estimatedItemSize={83}
          contentContainerStyle={{
            paddingBottom: 24,
            paddingHorizontal,
          }}
        />
      ) : (
        <FlashList
          renderItem={handleRenderItem}
          data={mappedNeededInventoryItems}
          estimatedItemSize={83}
          contentContainerStyle={{
            paddingBottom: 24,
            paddingHorizontal,
          }}
        />
      )}
    </MobileTaxationStepTemplate>
  );
};

const MemoComponent = memo(WhatInventoryItemsAreNeededStep, () => true);
export default MemoComponent;
