import {
  TimeTrackingItemInputType,
  timeTrackingItemInputTypeDefaultValues,
  timeTrackingItemInputTypeValidationBuilder,
} from '@bas/hrm-domain/input-types';
import { colors } from '@bas/theme';
import { BottomSheetMethods, Typography } from '@bas/ui/native/base';
import { BackendErrors, BottomSheetProps } from '@bas/ui/native/organisms';
import { FormBottomSheet } from '@bas/ui/native/templates';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import dayjs from 'dayjs';
import { ReactElement, RefObject, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { TimeTrackingItemForm } from '../TimeTrackingItemForm';

export type CreateTimeTrackingItemBottomSheetProps = Omit<
  BottomSheetProps,
  'snapPoints' | 'children'
> & {
  onSubmit: (values: TimeTrackingItemInputType) => Promise<void> | void;
  bottomSheetRef?: RefObject<BottomSheetModal & BottomSheetMethods>;
};

const styles = StyleSheet.create({
  flexContainer: {
    flex: 1,
  },
  container: {
    marginBottom: 15,
    paddingLeft: 20,
    paddingRight: 20,
  },
  description: {
    textAlign: 'center',
  },
  avatar: {
    height: '100%',
    width: '100%',
  },
  niceJob: {
    textAlign: 'center',
  },
  avatarContainer: {
    overflow: 'hidden',
    borderRadius: 269,
    height: 268,
    width: 268,
    marginBottom: 29,
    marginTop: 29,
    alignSelf: 'center',
  },
  header: {
    alignSelf: 'center',
    fontSize: 22,
    lineHeight: 29,
    height: 51,
  },
  paddingTop: {
    paddingTop: 24,
  },
  padding: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 31,
    paddingTop: 31,
  },
});

const CreateTimeTrackingItemBottomSheet = ({
  onSubmit,
  bottomSheetRef,
  ...props
}: CreateTimeTrackingItemBottomSheetProps): ReactElement => {
  const handleFinalize = useCallback(() => {
    bottomSheetRef?.current?.close();
    bottomSheetRef?.current?.forceClose();
    bottomSheetRef?.current?.dismiss();
  }, [bottomSheetRef]);

  const handleDefaultValues = useCallback(
    (data: { time: string }): TimeTrackingItemInputType => ({
      ...timeTrackingItemInputTypeDefaultValues(),
      start: dayjs(data.time).toDate(),
    }),
    [],
  );

  return (
    <FormBottomSheet<TimeTrackingItemInputType, { time: string }>
      bottomSheetRef={bottomSheetRef}
      disablePadding
      onSubmit={onSubmit}
      useProvider
      resetFormOnOpen
      onCancel={handleFinalize}
      validationSchema={timeTrackingItemInputTypeValidationBuilder}
      successFullFormViewProps={{
        gifUrl: 'https://media.giphy.com/media/26xBFg4zXVwzdI9lS/giphy.gif',
        color: colors.lila[100],
        title: 'label.timeTrackingItemSaved',
      }}
      useData
      defaultValues={handleDefaultValues}
      {...props}
    >
      <View style={styles.flexContainer}>
        <Typography
          fontWeight={700}
          overrideColor={colors.white}
          style={styles.header}
        >
          <FormattedMessage id="label.createTimeTrackingItem" />
        </Typography>
        <View>
          <BackendErrors />
          <View style={styles.container}>
            <TimeTrackingItemForm />
          </View>
        </View>
      </View>
    </FormBottomSheet>
  );
};

export default CreateTimeTrackingItemBottomSheet;
