import { colors } from '@bas/theme';
import { BuildingTypeIcon, Tooltip } from '@bas/ui/native/atoms';
import { Typography } from '@bas/ui/native/base';
import { BuildingType } from '@bas/value-objects';
import { faElevator } from '@fortawesome/pro-light-svg-icons/faElevator';
import { faSign } from '@fortawesome/pro-light-svg-icons/faSign';
import { faStamp } from '@fortawesome/pro-light-svg-icons/faStamp';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { View, ViewProps } from 'react-native';
import styles from './styles';

export type MovingJobAddressInformationProps = ViewProps & {
  buildingType?: BuildingType;
  trafficSigns: boolean;
  permitNeeded: boolean;
  hasElevator: boolean;
  forceShowFloor?: boolean;
  floor?: number | null;
  distanceFromCar?: number | null;
  distanceToApartment?: number | null;
};

const MovingJobAddressInformation = ({
  buildingType,
  trafficSigns,
  permitNeeded,
  hasElevator,
  floor,
  distanceFromCar,
  distanceToApartment,
  forceShowFloor,
  style,
  ...props
}: MovingJobAddressInformationProps): ReactElement => (
  <View style={style} {...props}>
    <View style={styles.row}>
      {forceShowFloor && (
        <Typography>
          <FormattedMessage id="label.floorWithValue" values={{ floor }} />
        </Typography>
      )}
    </View>
    <View style={styles.row}>
      {buildingType && (
        <Tooltip
          popover={
            <Typography overrideColor={colors.white}>
              <FormattedMessage id={`buildingTypes.${buildingType}`} />
              {'\n'}
              {typeof floor === 'number' && floor >= 0 && (
                <FormattedMessage
                  id="label.floorWithValue"
                  values={{ floor }}
                />
              )}
            </Typography>
          }
        >
          <View style={styles.iconContainer}>
            <BuildingTypeIcon
              color={colors.lila[800]}
              size={20}
              buildingType={buildingType}
            />
          </View>
        </Tooltip>
      )}
      {trafficSigns && (
        <View style={styles.iconContainer}>
          <Tooltip popover={<FormattedMessage id="label.placeTrafficSign" />}>
            <FontAwesomeIcon color={colors.lila[800]} size={20} icon={faSign} />
          </Tooltip>
        </View>
      )}
      {permitNeeded && (
        <View style={styles.iconContainer}>
          <Tooltip popover={<FormattedMessage id="label.requestPermit" />}>
            <FontAwesomeIcon
              color={colors.lila[800]}
              size={20}
              icon={faStamp}
            />
          </Tooltip>
        </View>
      )}
      {hasElevator && (
        <View style={styles.iconContainer}>
          <Tooltip
            disabled={!floor && !distanceFromCar && !distanceToApartment}
            popover={
              <Typography overrideColor={colors.white}>
                {typeof floor === 'number' && floor >= 0 && (
                  <>
                    <FormattedMessage
                      id="label.floorWithValue"
                      values={{ floor }}
                    />
                    {'\n'}
                  </>
                )}
                {!!distanceFromCar && distanceFromCar > 0 && (
                  <>
                    <FormattedMessage
                      id="movingJob.distanceFromCarWithValue"
                      values={{
                        distance: distanceFromCar,
                      }}
                    />
                    {'\n'}
                  </>
                )}
                {!!distanceToApartment && distanceToApartment > 0 && (
                  <>
                    <FormattedMessage
                      id="movingJob.distanceToApartmentWithValue"
                      values={{
                        distance: distanceToApartment,
                      }}
                    />
                    {'\n'}
                  </>
                )}
              </Typography>
            }
          >
            <FontAwesomeIcon
              color={colors.lila[800]}
              size={20}
              icon={faElevator}
            />
          </Tooltip>
        </View>
      )}
    </View>
  </View>
);

export default MovingJobAddressInformation;
