import { isBillingMoment } from './BillingMoment';
import { BillingMoment } from '../types';

export function isBillingMomentAfterStorageWasLoaded(
  object: unknown,
): object is BillingMoment {
  return (
    !!object &&
    isBillingMoment(object) &&
    object['@type'] === 'BillingMomentAfterStorageWasLoaded'
  );
}
