import { formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ChangeHoursMutationProps = {
  entryId: Uuid;
  reason?: string | null;
  startDate: Date;
  endDate: Date;
  travelStartDate?: Date | null;
  travelEndDate?: Date | null;
  breakTime?: number | null;
};

export const ChangeHoursMutation = async ({
  entryId,
  startDate,
  endDate,
  travelStartDate,
  travelEndDate,
  ...values
}: ChangeHoursMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/hours-entries/${entryId}/change-hours`, {
    entryId,
    startDate: formatDateTime(startDate),
    endDate: formatDateTime(endDate),
    travelStartDate: travelStartDate ? formatDateTime(travelStartDate) : null,
    travelEndDate: travelEndDate ? formatDateTime(travelEndDate) : null,
    ...values,
  });

export const useChangeHoursMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeHoursMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ChangeHoursMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeHoursMutationProps
  >({
    mutationFn: ChangeHoursMutation,
    throwOnError: false,
    ...options,
  });
