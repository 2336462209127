import { useEmployeesRequest } from '@bas/hrm-domain/requests';
import { Damage } from '@bas/project-domain/models';
import { AddressBlock, Avatar } from '@bas/ui/native/atoms';
import { Box, Typography } from '@bas/ui/native/base';
import { Accordion } from '@bas/ui/native/molecules';
import { ReactElement, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Image, Modal, TouchableOpacity } from 'react-native';

export type DamagesAccordionProps = {
  damages: Damage[];
};

const DamagesAccordion = ({ damages }: DamagesAccordionProps): ReactElement => {
  const [showingImage, setShowingImage] = useState<string>();

  const { data: employeesData } = useEmployeesRequest({
    perPage: 9999,
  });

  return (
    <Box>
      {damages.length === 0 && (
        <Typography color="white">
          <FormattedMessage id="label.thereAreNoDamages" />
        </Typography>
      )}
      <Box gap={1}>
        {damages.map((damage) => (
          <Accordion
            key={damage.damageId}
            summary={
              <Box flexDirection="row" flex={1}>
                <Box flexBasis="75%">
                  <Typography>{damage.summary}</Typography>
                </Box>
                <Box flexBasis="25%">
                  <Typography>
                    <FormattedMessage
                      id={`damageInflictedOnTypes.${
                        damage.damageInflictedOn || 'unknown'
                      }`}
                    />
                  </Typography>
                </Box>
              </Box>
            }
          >
            <Box gap={3} flexWrap="wrap" flexDirection="row" flex={1}>
              <Box flex={1} flexBasis="100%">
                <Typography variant="subtitle1" color="subtitle1Color">
                  <FormattedMessage id="label.details" />
                </Typography>
                <Typography>
                  {
                    (employeesData?.data?.member || []).find(
                      ({ employeeId }) =>
                        employeeId === damage.damagedByEmployeeId,
                    )?.personName.fullName
                  }
                </Typography>
                <Typography>
                  <FormattedMessage
                    id={
                      damage.damageWasAlreadyThere
                        ? 'label.damageAlreadyExisted'
                        : 'label.newDamage'
                    }
                  />
                </Typography>
                <Typography>
                  <FormattedMessage
                    id={`damageInflictedOnTypes.${
                      damage.damageInflictedOn || 'unknown'
                    }`}
                  />
                </Typography>
              </Box>
              <Box flex={1} flexBasis="100%">
                <Typography variant="subtitle1" color="subtitle1Color">
                  <FormattedMessage id="label.location" />
                </Typography>
                <AddressBlock address={damage.location} />
              </Box>
              <Box flex={1} flexBasis="100%">
                <Box columnGap={3}>
                  <Box flex={1} flexBasis="100%">
                    <Typography variant="subtitle1" color="subtitle1Color">
                      <FormattedMessage id="label.description" />
                    </Typography>
                  </Box>
                  <Box flex={1} flexBasis="100%">
                    <Typography>{damage.description}</Typography>
                  </Box>
                </Box>
              </Box>
              <Box pt={3} gap={2} flexWrap="nowrap">
                {damage.pictures.map((photo) => (
                  <Box key={photo.mediaObjectId}>
                    <TouchableOpacity
                      onPress={() => setShowingImage(photo.contentUrl)}
                    >
                      <Avatar
                        src={photo.contentUrl}
                        size={88}
                        borderRadius={4}
                        name="damage"
                      />
                    </TouchableOpacity>
                  </Box>
                ))}
              </Box>
            </Box>
          </Accordion>
        ))}
        <Modal
          visible={!!showingImage}
          onRequestClose={() => setShowingImage(undefined)}
          transparent
          animationType="slide"
        >
          <TouchableOpacity
            style={{
              width: '100%',
              height: '100%',
            }}
            onPress={() => {
              setShowingImage(undefined);
            }}
            activeOpacity={1}
          >
            <Box
              width="100%"
              height="100%"
              opacity={0.9}
              backgroundColor="black"
              position="absolute"
              top={0}
              bottom={0}
              left={0}
              right={0}
            />
            <Image
              source={{ uri: showingImage }}
              style={{ height: '100%', width: '100%' }}
              resizeMode="contain"
            />
          </TouchableOpacity>
        </Modal>
      </Box>
    </Box>
  );
};

export default DamagesAccordion;
