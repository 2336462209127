import { TimeTrackingItem, useTenantStore } from '@bas/shared/state';
import { Uuid } from '@bas/value-objects';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import { v7 } from 'uuid';
import { useSplitTrackedTimes } from './useSplitTrackedTimes';

const minimumNumberOfMinutes = 5;

export const useCalculateRealTimeEntriesFromTrackedTime = () => {
  const tenantState = useTenantStore((state) => state.tenant);
  const mobileAppFeature = tenantState?.mobileAppFeature;
  const splitTrackedTimes = useSplitTrackedTimes();

  return useCallback(
    ({
      trackedItems,
      breakTimeStartedAt,
      breakTimeMinutes,
      employeeId,
    }: {
      trackedItems: TimeTrackingItem[];
      breakTimeStartedAt: Date;
      breakTimeMinutes: number;
      employeeId: Uuid;
    }) => {
      if (!mobileAppFeature?.defaultHourTypes) {
        // show error

        return trackedItems;
      }

      const { defaultHourTypes } = mobileAppFeature;
      const breakTimeItem: TimeTrackingItem = {
        timeEntryId: v7(),
        timeTypeId:
          defaultHourTypes.breakTimeTypeId ||
          'a2d60fa3-9b3c-46e4-b13a-cf368caf4deb',
        start: dayjs(breakTimeStartedAt).toDate(),
        end: dayjs(breakTimeStartedAt)
          .add(breakTimeMinutes, 'minutes')
          .toDate(),
        employeeId,
        wasUnknown: false,
      };

      let sortedTrackedItems: TimeTrackingItem[] = [
        ...trackedItems.map((item) => ({
          ...item,
          wasUnknown: !item.timeTypeId,
        })),
      ].sort((a, b) => (dayjs(a.start).isBefore(dayjs(b.start)) ? -1 : 1));

      const itemsWithBreakTime = [
        ...splitTrackedTimes({
          trackedItems: sortedTrackedItems,
          itemToAdd: breakTimeItem,
        }),
        breakTimeItem,
      ];

      sortedTrackedItems = itemsWithBreakTime.sort((a, b) =>
        dayjs(a.start).isBefore(dayjs(b.start)) ? -1 : 1,
      );

      const result: TimeTrackingItem[] = [];
      sortedTrackedItems.forEach((item, index) => {
        if (
          dayjs(item.end).diff(item.start, 'minutes') < minimumNumberOfMinutes
        ) {
          const previousItem = sortedTrackedItems[index - 1];
          const nextItem = sortedTrackedItems[index + 1];

          if (previousItem?.timeTypeId === nextItem?.timeTypeId) {
            sortedTrackedItems[index - 1] = {
              ...previousItem,
              end: nextItem.end,
            };
            delete sortedTrackedItems[index];
            delete sortedTrackedItems[index + 1];

            return;
          }

          if (!previousItem) {
            sortedTrackedItems[index + 1] = {
              ...nextItem,
              start: item.start,
            };
            delete sortedTrackedItems[index];

            return;
          }

          if (!nextItem) {
            result[result.length - 1] = {
              ...result[result.length - 1],
              end: item.end,
            };
            delete sortedTrackedItems[index];

            return;
          }

          if (previousItem.timeTypeId === item.timeTypeId) {
            result[result.length - 1] = {
              ...result[result.length - 1],
              end: item.end,
            };
            delete sortedTrackedItems[index];

            return;
          }

          if (nextItem.timeTypeId === item.timeTypeId) {
            sortedTrackedItems[index + 1] = {
              ...nextItem,
              start: item.start,
            };
            delete sortedTrackedItems[index];

            return;
          }

          if (previousItem.timeTypeId === defaultHourTypes.drivingHourTypeId) {
            result[result.length - 1] = {
              ...result[result.length - 1],
              end: item.end,
            };
            delete sortedTrackedItems[index];

            return;
          }

          if (nextItem.timeTypeId === defaultHourTypes.drivingHourTypeId) {
            sortedTrackedItems[index + 1] = {
              ...nextItem,
              start: item.start,
            };
            delete sortedTrackedItems[index];

            return;
          }

          const numberOfMinutes = dayjs(item.end).diff(item.start, 'minutes');
          const numberOfMinutesHalf = Math.floor(numberOfMinutes / 2);
          result[result.length - 1] = {
            ...result[result.length - 1],
            end: dayjs(result[result.length - 1].end)
              .subtract(numberOfMinutesHalf, 'minutes')
              .toDate(),
          };

          sortedTrackedItems[index + 1] = {
            ...sortedTrackedItems[index + 1],
            start: dayjs(item.start)
              .subtract(numberOfMinutes - numberOfMinutesHalf, 'minutes')
              .toDate(),
          };
        } else {
          result.push(item);
        }
      });

      return result;
    },
    [mobileAppFeature, splitTrackedTimes],
  );
};
