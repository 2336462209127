import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ReturnStorageToWarehouseMutationProps = {
  storageId: Uuid;
  warehouseId: Uuid | null;
  storageLocationId: Uuid | null;
  employeeId: Uuid;
};

export const ReturnStorageToWarehouseMutation = async ({
  storageId,
  ...values
}: ReturnStorageToWarehouseMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/storages/${storageId}/return-to-warehouse`, {
    storageId,
    ...values,
  });

export const useReturnStorageToWarehouseMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ReturnStorageToWarehouseMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ReturnStorageToWarehouseMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ReturnStorageToWarehouseMutationProps
  >({
    mutationFn: ReturnStorageToWarehouseMutation,
    throwOnError: false,
    ...options,
  });
