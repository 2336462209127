import { MovingJobRoom } from '@bas/project-domain/models';
import { colors } from '@bas/theme';
import { Box, Typography } from '@bas/ui/native/base';
import { Accordion } from '@bas/ui/native/molecules';
import { Uuid } from '@bas/value-objects';
import {
  InventoryEvent,
  InventoryEventType,
  NeededInventoryItem,
} from '@bas/wms-domain/models';
import {
  useInventoryEventsByProjectIdRequest,
  useOutstandingAndUsedStockByProjectIdRequest,
  useOutstandingStockByProjectIdRequest,
  usePublicMovingBoxesRequest,
  useReusableMaterialsRequest,
} from '@bas/wms-domain/requests';
import dayjs from 'dayjs';
import { ReactElement, ReactNode, useMemo } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import styles from './styles';

export type ProjectOutstandingInventoryItemsProps = {
  projectId: Uuid;
  rooms: MovingJobRoom[];
  neededInventoryItems: NeededInventoryItem[];
  hideReusable?: boolean;
  hideNonReusable?: boolean;
  title?: ReactNode;
  light?: boolean;
  showForInvoicing?: boolean;
};

export type InventoryItemResult = {
  inventoryItemId: Uuid;
  name: string;
  needed: number;
  outstanding: number;
  used: number;
  sold: number;
  reusable?: boolean;
  weight: number;
};

const ProjectOutstandingInventoryItems = ({
  projectId,
  rooms,
  neededInventoryItems,
  hideReusable,
  hideNonReusable,
  title,
  light,
  showForInvoicing,
}: ProjectOutstandingInventoryItemsProps): ReactElement => {
  const { data: outstandingData } = useOutstandingStockByProjectIdRequest(
    {
      projectId,
    },
    { enabled: !showForInvoicing },
  );

  const { data: inventoryEventsData } = useInventoryEventsByProjectIdRequest(
    {
      projectId,
    },
    { enabled: showForInvoicing },
  );

  const { data: outstandingAndUsedData } =
    useOutstandingAndUsedStockByProjectIdRequest(
      {
        projectId,
      },
      { enabled: showForInvoicing },
    );

  const { data: reusableMaterialsData } = useReusableMaterialsRequest();
  const { data: boxesData } = usePublicMovingBoxesRequest();
  const textColor = useMemo(() => (light ? colors.white : undefined), [light]);
  const subtitleColor = useMemo(() => colors.lila[600], []);

  const inventoryItems: InventoryItemResult[] = useMemo(() => {
    const boxes = boxesData?.data?.member || [];
    const outstanding = outstandingData?.data?.member || [];
    const outstandingAndUsed = outstandingAndUsedData?.data?.member || [];

    const result: {
      [key: string]: InventoryItemResult;
    } = {};
    rooms.forEach((room) => {
      room.inventoryItems.forEach((item) => {
        if (typeof result[item.inventoryItemId] === 'undefined') {
          const box = boxes.find(
            ({ inventoryItemId }) => inventoryItemId === item.inventoryItemId,
          );

          result[item.inventoryItemId] = {
            needed: 0,
            outstanding: 0,
            used: 0,
            sold: 0,
            inventoryItemId: item.inventoryItemId,
            name: box?.name || '',
            weight: box?.weight || 0,
          };
        }

        result[item.inventoryItemId].needed += item.quantity;
      });
    });

    neededInventoryItems.forEach(({ quantity, inventoryItem }) => {
      if (typeof result[inventoryItem.inventoryItemId] === 'undefined') {
        result[inventoryItem.inventoryItemId] = {
          needed: 0,
          outstanding: 0,
          used: 0,
          sold: 0,
          inventoryItemId: inventoryItem.inventoryItemId,
          name: inventoryItem.name,
          reusable: true,
          weight: inventoryItem.weight,
        };
      }

      result[inventoryItem.inventoryItemId].needed += quantity;
    });

    outstanding.forEach((item) => {
      if (typeof result[item.inventoryItemId] === 'undefined') {
        result[item.inventoryItemId] = {
          needed: 0,
          outstanding: 0,
          used: 0,
          sold: 0,
          inventoryItemId: item.inventoryItemId,
          name: item.inventoryItemName,
          reusable: !boxes.find(
            ({ inventoryItemId }) => inventoryItemId === item.inventoryItemId,
          ),
          weight: item.inventoryItemWeight,
        };
      }
    });

    outstandingAndUsed.forEach((item) => {
      if (typeof result[item.inventoryItemId] === 'undefined') {
        result[item.inventoryItemId] = {
          needed: 0,
          outstanding: 0,
          used: 0,
          sold: 0,
          inventoryItemId: item.inventoryItemId,
          name: item.inventoryItemName,
          reusable: !boxes.find(
            ({ inventoryItemId }) => inventoryItemId === item.inventoryItemId,
          ),
          weight: item.inventoryItemWeight,
        };
      }

      if (item.outstandingStock > 0) {
        result[item.inventoryItemId].outstanding += item.outstandingStock;
      }
      if (item.usedStock > 0) {
        result[item.inventoryItemId].used += item.usedStock;
      }
      if (item.soldStock > 0) {
        result[item.inventoryItemId].sold += item.soldStock;
      }
    });

    return Object.values(result);
  }, [
    boxesData?.data,
    neededInventoryItems,
    outstandingAndUsedData?.data,
    outstandingData?.data,
    rooms,
  ]);

  const inventoryEvents: (InventoryEvent & {
    inventoryItem?: { name: string };
    labelType: string;
  })[] = useMemo(() => {
    const boxes = boxesData?.data?.member || [];
    const reusableMaterials = reusableMaterialsData?.data?.member || [];
    const items = [...reusableMaterials, ...boxes];

    return (inventoryEventsData?.data?.member || [])
      .map((event) => {
        const inventoryItem = items.find(
          ({ inventoryItemId }) => inventoryItemId === event.inventoryItemId,
        );
        let labelType = '';
        switch (event.eventType) {
          case InventoryEventType.INVENTORY_SOLD:
            labelType = 'sold';
            break;
          case InventoryEventType.INVENTORY_IN:
            labelType = 'pickedUp';
            break;
          case InventoryEventType.INVENTORY_OUT:
            labelType = 'delivered';
            break;
          case InventoryEventType.INVENTORY_OUT_STORAGE:
            labelType = 'retrievedFromStorage';
            break;
          case InventoryEventType.STORAGE:
            labelType = 'putInStorage';
            break;
          default:
            labelType = 'unknown';
        }

        return {
          ...event,
          labelType,
          inventoryItem,
        };
      })
      .sort(
        (
          { inventoryItem: inventoryItemA, eventDate: eventDateA },
          { inventoryItem: inventoryItemB, eventDate: eventDateB },
        ) => {
          if (inventoryItemA?.name === inventoryItemB?.name) {
            return dayjs(eventDateA).isBefore(eventDateB) ? -1 : 1;
          }

          return (
            inventoryItemA?.name.localeCompare(inventoryItemB?.name || '') || 0
          );
        },
      );
  }, [boxesData?.data, inventoryEventsData?.data, reusableMaterialsData?.data]);

  const filteredItems = useMemo(
    () =>
      inventoryItems
        .filter(
          ({ reusable }) =>
            (hideReusable && !reusable) ||
            (hideNonReusable && reusable) ||
            (!hideReusable && !hideNonReusable),
        )
        .sort((a, b) => {
          if (a.weight === b.weight) {
            return a.name.localeCompare(b.name);
          }

          return a.weight - b.weight;
        }),
    [hideNonReusable, inventoryItems, hideReusable],
  );

  return (
    <Box>
      <Typography variant="subtitle1" overrideColor={textColor}>
        {title || <FormattedMessage id="label.materials" />}
      </Typography>
      <Box style={styles.content}>
        {showForInvoicing && (
          <Box
            flexBasis={showForInvoicing ? '40%' : '50%'}
            style={styles.column}
          >
            <Typography overrideColor={subtitleColor} variant="subtitle2">
              <FormattedMessage id="label.name" />
            </Typography>
          </Box>
        )}
        {!showForInvoicing && (
          <Box style={styles.column} flexBasis="50%">
            <Typography overrideColor={subtitleColor} variant="subtitle2">
              <FormattedMessage id="mobileApp.event.quantityNeeded" />
            </Typography>
          </Box>
        )}
        <Box style={styles.column} flexBasis={showForInvoicing ? '20%' : '50%'}>
          <Typography variant="subtitle2" overrideColor={subtitleColor}>
            <FormattedMessage id="mobileApp.event.quantityOutstanding" />
          </Typography>
        </Box>
        {showForInvoicing && (
          <Box style={styles.column} flexBasis="20%">
            <Typography variant="subtitle2" overrideColor={subtitleColor}>
              <FormattedMessage id="mobileApp.event.quantityUsed" />
            </Typography>
          </Box>
        )}
        {showForInvoicing && (
          <Box style={styles.column} flexBasis="20%">
            <Typography variant="subtitle2" overrideColor={subtitleColor}>
              <FormattedMessage id="mobileApp.event.quantitySold" />
            </Typography>
          </Box>
        )}
      </Box>
      {filteredItems.length === 0 && (
        <Box style={styles.fullColumn}>
          <Typography overrideColor={textColor}>
            <FormattedMessage id="label.noMaterialsNeededOrUsed" />
          </Typography>
        </Box>
      )}
      {filteredItems.map((item) => (
        <Box
          key={item.name || item.inventoryItemId}
          style={styles.content}
          pt={showForInvoicing ? 1 : 0}
          pb={showForInvoicing ? 1 : 0}
          borderBottomWidth={showForInvoicing ? 0.5 : 0}
          borderBottomColor="white"
        >
          {showForInvoicing && (
            <Box
              flexBasis={showForInvoicing ? '40%' : '50%'}
              style={styles.column}
            >
              <Typography overrideColor={textColor}>{item.name}</Typography>
            </Box>
          )}
          {!showForInvoicing && (
            <Box style={styles.column} flexBasis="50%">
              <Typography
                overrideColor={textColor}
              >{`${item.needed} ${item.name}`}</Typography>
            </Box>
          )}
          <Box
            flexBasis={showForInvoicing ? '20%' : '50%'}
            style={styles.column}
          >
            <Typography
              overrideColor={textColor}
              textAlign={showForInvoicing ? 'center' : undefined}
            >
              {showForInvoicing
                ? item.outstanding
                : `${item.outstanding} ${item.name}`}
            </Typography>
          </Box>
          {showForInvoicing && (
            <Box style={styles.column} flexBasis="20%">
              <Typography overrideColor={textColor} textAlign="center">
                {item.used}
              </Typography>
            </Box>
          )}
          {showForInvoicing && (
            <Box style={styles.column} flexBasis="20%">
              <Typography overrideColor={textColor} textAlign="center">
                {item.sold}
              </Typography>
            </Box>
          )}
        </Box>
      ))}

      {showForInvoicing && (
        <Box pt={2}>
          <Accordion
            key="inventoryItemMutations"
            summary={
              <Typography variant="subtitle2">
                <FormattedMessage id="label.mutations" />
              </Typography>
            }
          >
            <Box gap={1}>
              {inventoryEvents.map((event) => (
                <Box
                  gap={1}
                  key={event.inventoryEventId}
                  flexDirection="row"
                  flex={1}
                >
                  <Box flexBasis="8.3333333333%">
                    <Typography>{event.positiveQuantity}</Typography>
                  </Box>
                  <Box flexBasis="25%">
                    <Typography>
                      <FormattedMessage id={`label.${event.labelType}`} />
                    </Typography>
                  </Box>
                  <Box flexBasis="33.3333333333%">
                    <Typography>{event.inventoryItem?.name}</Typography>
                  </Box>
                  <Box flexBasis="30%">
                    <Typography>
                      <FormattedDate value={event.eventDate} />
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Accordion>
        </Box>
      )}
    </Box>
  );
};

export default ProjectOutstandingInventoryItems;
