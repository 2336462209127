import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type MergeRelationsMutationProps = {
  relationToMergeIntoId: Uuid;
  relationIdToBeMerged: Uuid;
};

export const MergeRelationsMutation = async ({
  relationToMergeIntoId,
  ...values
}: MergeRelationsMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/relations/${relationToMergeIntoId}/merge`, {
    relationToMergeIntoId,
    mergeContactPersons: true,
    mergeAddresses: true,
    removeRelation: true,
    ...values,
  });

export const useMergeRelationsMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    MergeRelationsMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  MergeRelationsMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    MergeRelationsMutationProps
  >({
    mutationFn: MergeRelationsMutation,
    throwOnError: false,
    ...options,
  });
