import { FailedFile, UploadingFile } from '@bas/media-domain/input-types';
import { useUploadFileToCreateAMediaObjectMutation } from '@bas/media-domain/mutations';
import { colors } from '@bas/theme';
import { Button } from '@bas/ui/native/atoms';
import {
  PhotoListItem,
  ReactHookFormDropdown,
  ReactHookFormTextField,
} from '@bas/ui/native/molecules';
import { DocumentType } from '@bas/value-objects';
import * as ImagePicker from 'expo-image-picker';
import { ImagePickerAsset } from 'expo-image-picker';
import * as React from 'react';
import { ReactElement, useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { View } from 'react-native';
import styles from './styles';

const UploadDocumentForm = (): ReactElement => {
  const { formatMessage } = useIntl();
  const [failedFile, setFailedFile] = useState<FailedFile>();
  const [uploadingFile, setUploadingFile] = useState<UploadingFile>();
  const { setValue } = useFormContext();
  const [status, requestPermission] = ImagePicker.useCameraPermissions();
  const [statusMedia, requestPermissionMedia] =
    ImagePicker.useMediaLibraryPermissions();

  const { mutateAsync: uploadFile } =
    useUploadFileToCreateAMediaObjectMutation();

  const handleUpload = useCallback(
    async (files: ImagePickerAsset[]) => {
      files.forEach((file) => {
        const fileSize = (file.fileSize || 0) / 1024 / 1024;
        if (fileSize > 45) {
          setFailedFile({
            file,
            reason: <FormattedMessage id="label.fileTooBig" />,
          });

          return;
        }

        setUploadingFile({ file, progress: 0 });

        uploadFile({
          file,
          onUploadProgress: (progressEvent) => {
            setUploadingFile({
              file,
              progress: Math.round(
                (progressEvent.loaded * 100) / (progressEvent.total || 0),
              ),
            });
          },
        })
          .then((result) => {
            setValue('fileId', result.data?.mediaObjectId);
            setUploadingFile(() => {
              const mediaObject = {
                mediaObjectId: result.data.mediaObjectId,
                url: result.data.contentUrl,
                contentUrl: result.data.contentUrl,
              };

              return {
                file,
                progress: 100,
                result: mediaObject,
              };
            });
          })
          .catch(() => {
            setFailedFile({
              file,
              reason: <FormattedMessage id="label.failedToUpload" />,
            });
            setUploadingFile(undefined);
          });
      });
    },
    [setValue, uploadFile],
  );

  const takePhoto = useCallback(async () => {
    if (!status) {
      return;
    }
    if (!status.granted) {
      const result = await requestPermission();
      if (!result.granted) {
        return;
      }
    }

    const photoResult = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: 1,
      allowsMultipleSelection: true,
    });

    if (photoResult.canceled) {
      return;
    }

    const files = photoResult.assets;

    await handleUpload(files);
  }, [handleUpload, requestPermission, status]);

  const pickImages = useCallback(async () => {
    if (!statusMedia) {
      return;
    }
    if (!statusMedia.granted) {
      const result = await requestPermissionMedia();
      if (!result.granted) {
        return;
      }
    }

    const pickResult = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: 1,
      allowsMultipleSelection: true,
    });

    if (pickResult.canceled) {
      return;
    }

    const files = pickResult.assets;

    await handleUpload(files);
  }, [handleUpload, requestPermissionMedia, statusMedia]);

  return (
    <View style={styles.flex}>
      <View>
        <ReactHookFormTextField
          name="fileName"
          label={formatMessage({ id: 'label.fileName' })}
          light
        />
      </View>
      <View>
        <ReactHookFormDropdown
          name="documentType"
          label={formatMessage({ id: 'label.documentType' })}
          light
          items={[
            ...Object.values(DocumentType).map((type) => ({
              id: type,
              label: formatMessage({ id: `documentTypes.${type}` }),
            })),
          ]}
        />
      </View>
      <View style={styles.buttons}>
        <View style={[styles.uploadButton, styles.paddingRight]}>
          <Button
            onPress={pickImages}
            variant="outlined"
            style={styles.uploadButton}
            paddingRight={2}
            overrideColor={colors.white}
          >
            <FormattedMessage id="button.uploadPhoto" />
          </Button>
        </View>
        <View style={[styles.uploadButton]}>
          <Button
            onPress={takePhoto}
            variant="outlined"
            style={styles.uploadButton}
            overrideColor={colors.white}
          >
            <FormattedMessage id="button.takePhoto" />
          </Button>
        </View>
      </View>
      <View style={styles.photosContainer}>
        {failedFile && !uploadingFile && (
          <PhotoListItem
            file={failedFile.file as ImagePickerAsset}
            reason={failedFile.reason}
            spacingBetweenColumns
          />
        )}
        {uploadingFile && (
          <PhotoListItem
            file={uploadingFile.file as ImagePickerAsset}
            progress={uploadingFile.progress}
            result={uploadingFile.result}
            spacingBetweenColumns
          />
        )}
      </View>
    </View>
  );
};

export default UploadDocumentForm;
