/* eslint-disable react/prop-types */
import { FinancialDocumentLinesEditorInputType } from '@bas/financial-domain/input-types';
import {
  useTurnoverGroupsRequest,
  useVatCodesRequest,
} from '@bas/financial-domain/requests';
import { FormattedCurrency, Tooltip } from '@bas/ui/native/atoms';
import { Box, Icon, Typography } from '@bas/ui/native/base';
import {
  Accordion,
  ReactHookFormDropdown,
  ReactHookFormHoursTextField,
  ReactHookFormNumberTextField,
  ReactHookFormTextField,
} from '@bas/ui/native/molecules';
import { DocumentType, isMoney } from '@bas/value-objects';
import { faArrowDown } from '@fortawesome/pro-light-svg-icons/faArrowDown';
import { faArrowUp } from '@fortawesome/pro-light-svg-icons/faArrowUp';
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock';
import { faSquareCheck } from '@fortawesome/pro-light-svg-icons/faSquareCheck';
import { faTrashCan } from '@fortawesome/pro-light-svg-icons/faTrashCan';
import { faClock as faSolidClock } from '@fortawesome/pro-solid-svg-icons/faClock';
import { faSquareCheck as faSolidSquareCheck } from '@fortawesome/pro-solid-svg-icons/faSquareCheck';
import * as React from 'react';
import { memo, ReactElement, useCallback, useMemo } from 'react';
import isEqual from 'react-fast-compare';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { TouchableOpacity } from 'react-native';
import styles from './styles';

export type MobileFinancialDocumentLinesEditorLineRowProps = {
  index: number;
  documentType: 'quote' | 'invoice';
};

type ActionButtonProps = {
  value: boolean;
  onPress: (value: boolean) => void;
};

const FinancialDocumentLinesEditorLineRowHourlyRateButton = memo(
  ({ value, onPress }: ActionButtonProps) => (
    <Tooltip popover={<FormattedMessage id="label.hourlyRate.explained" />}>
      <Box>
        <TouchableOpacity onPress={() => onPress(value)}>
          <Icon
            icon={value ? faSolidClock : faClock}
            size={30}
            color={value ? 'mainPrimary' : 'darkText'}
          />
        </TouchableOpacity>
      </Box>
    </Tooltip>
  ),
  isEqual,
);

const FinancialDocumentLinesEditorLineRowOptionalButton = memo(
  ({ value, onPress }: ActionButtonProps) => (
    <Tooltip popover={<FormattedMessage id="label.optional.explained" />}>
      <Box>
        <TouchableOpacity onPress={() => onPress(value)}>
          <Icon
            icon={value ? faSolidSquareCheck : faSquareCheck}
            size={30}
            color={value ? 'mainPrimary' : 'darkText'}
          />
        </TouchableOpacity>
      </Box>
    </Tooltip>
  ),
  isEqual,
);

type QuantityFieldProps = {
  documentType: 'quote' | 'invoice';
  light?: boolean;
  index: number;
};

const QuantityField = memo<QuantityFieldProps>(
  // eslint-disable-next-line react/prop-types
  ({ documentType, light, index }: QuantityFieldProps): ReactElement => {
    const hourlyRate = useWatch<FinancialDocumentLinesEditorInputType>({
      name: `quote.lines.${index}.hourlyRate`,
    });

    return useMemo(
      () =>
        hourlyRate ? (
          <ReactHookFormHoursTextField
            name={`${documentType}.lines.${index}.quantity`}
            textAlign="right"
            light={light}
            autoComplete="off"
            keyboardType="decimal-pad"
          />
        ) : (
          <ReactHookFormNumberTextField
            name={`${documentType}.lines.${index}.quantity`}
            keyboardType="decimal-pad"
            textAlign="right"
            light={light}
            fractionDigits={2}
            padZero={false}
            showZero
          />
        ),
      [documentType, hourlyRate, index, light],
    );
  },
  isEqual,
);

const ActionButtons = memo(
  ({
    documentType,
    index,
  }: {
    documentType: 'quote' | 'invoice';
    index: number;
  }): ReactElement | null => {
    const { setValue } =
      useFormContext<FinancialDocumentLinesEditorInputType>();
    const [hourlyRate, optional] =
      useWatch<FinancialDocumentLinesEditorInputType>({
        name: [
          `quote.lines.${index}.hourlyRate`,
          `quote.lines.${index}.optional`,
        ],
      });

    return useMemo(
      () =>
        documentType === DocumentType.QUOTE ? (
          <>
            <Box pl={3}>
              <FinancialDocumentLinesEditorLineRowHourlyRateButton
                value={hourlyRate as boolean}
                onPress={(value) =>
                  setValue(`quote.lines.${index}.hourlyRate`, !value)
                }
              />
            </Box>

            <Box pl={3}>
              <FinancialDocumentLinesEditorLineRowOptionalButton
                value={optional as boolean}
                onPress={(value) =>
                  setValue(`quote.lines.${index}.optional`, !value)
                }
              />
            </Box>
          </>
        ) : null,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [documentType, hourlyRate, index, optional],
    );
  },
  isEqual,
);

const VatCodeField = memo(
  ({
    documentType,
    index,
    light,
  }: {
    documentType: 'quote' | 'invoice';
    index: number;
    light?: boolean;
  }) => {
    const { setValue } =
      useFormContext<FinancialDocumentLinesEditorInputType>();
    const { data: vatCodesData } = useVatCodesRequest(
      {
        perPage: 99999,
      },
      {
        refetchInterval: false,
        refetchOnWindowFocus: false,
      },
    );

    const vatCodes = useMemo(
      () => vatCodesData?.data?.member || [],
      [vatCodesData],
    );

    const handleSelectVatCode = useCallback(
      (newVatCodeId: string | undefined) => {
        if (
          typeof newVatCodeId !== 'string' &&
          typeof newVatCodeId !== 'undefined'
        ) {
          return;
        }

        const vatCode = vatCodes.find(
          ({ vatCodeId }) => vatCodeId === newVatCodeId,
        );
        if (vatCode) {
          setValue(`${documentType}.lines.${index}.vatCode`, vatCode, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          });
          setValue(
            `${documentType}.lines.${index}.vatPercentage`,
            vatCode.percentage,
            {
              shouldDirty: true,
              shouldTouch: true,
              shouldValidate: true,
            },
          );
        }
      },
      [documentType, index, setValue, vatCodes],
    );

    return useMemo(
      () => (
        <ReactHookFormDropdown
          name={`${documentType}.lines.${index}.vatCode`}
          fieldValue="vatCodeId"
          light={light}
          textAlign="right"
          onChange={handleSelectVatCode}
          items={vatCodes
            .filter((vatCode) => !!vatCode.percentage)
            .map((vatCode) => ({
              id: vatCode.vatCodeId,
              label: `${vatCode.percentage?.percentage}%`,
            }))}
        />
      ),
      [documentType, index, light, handleSelectVatCode, vatCodes],
    );
  },
  isEqual,
);

const TurnoverGroupField = memo(
  ({
    documentType,
    index,
    light,
  }: {
    documentType: 'quote' | 'invoice';
    index: number;
    light?: boolean;
  }): ReactElement => {
    const { data: turnoverGroupsData } = useTurnoverGroupsRequest(
      {
        perPage: 9999,
      },
      {
        refetchInterval: false,
        refetchOnWindowFocus: false,
      },
    );

    const { formatMessage } = useIntl();

    const turnoverGroups = useMemo(
      () =>
        (turnoverGroupsData?.data?.member || []).map((turnoverGroup) => ({
          id: turnoverGroup.turnoverGroupId,
          label: turnoverGroup.name,
        })),
      [turnoverGroupsData],
    );

    return (
      <ReactHookFormDropdown
        name={`${documentType}.lines.${index}.turnoverGroupId`}
        light={light}
        textAlign="right"
        items={[
          {
            id: '',
            label: formatMessage({ id: 'label.none' }),
          },
          ...turnoverGroups,
        ]}
      />
    );
  },
  isEqual,
);

const Summary = memo(
  ({
    documentType,
    index,
    open,
  }: {
    documentType: 'quote' | 'invoice';
    index: number;
    open: boolean;
  }) => {
    const [pricePerUnit, quantity, description] = useWatch({
      name: [
        `${documentType}.lines.${index}.pricePerUnit`,
        `${documentType}.lines.${index}.quantity`,
        `${documentType}.lines.${index}.description`,
      ],
    });

    const totalPrice = useMemo(
      () => ({
        amount: pricePerUnit.amount * quantity,
        currency: pricePerUnit.currency,
      }),
      [pricePerUnit.amount, pricePerUnit.currency, quantity],
    );

    return (
      <Box alignItems="center" flexDirection="row" flex={1}>
        <Box flexBasis="75%" width="75%">
          <Typography numberOfLines={open ? undefined : 1}>
            {description}
          </Typography>
        </Box>
        <Box flexBasis="25%" width="25%">
          <Typography textAlign="right">
            {!!totalPrice && isMoney(totalPrice) && (
              <FormattedCurrency
                currency={totalPrice.currency}
                amount={totalPrice.amount}
              />
            )}
          </Typography>
        </Box>
      </Box>
    );
  },
  isEqual,
);

const OptionalMessage = memo(
  ({
    documentType,
    index,
  }: {
    documentType: 'quote' | 'invoice';
    index: number;
  }) => {
    const optional = useWatch<FinancialDocumentLinesEditorInputType>({
      name: `quote.lines.${index}.optional`,
    });

    return optional ? (
      <Typography variant="caption">
        <FormattedMessage id="label.optional" />
      </Typography>
    ) : null;
  },
  isEqual,
);

const Actions = memo(
  ({
    documentType,
    index,
  }: {
    documentType: 'quote' | 'invoice';
    index: number;
  }) => {
    const { swap, fields: lines } =
      useFieldArray<FinancialDocumentLinesEditorInputType>({
        name: `${documentType}.lines`,
      } as never);

    const { setValue } =
      useFormContext<FinancialDocumentLinesEditorInputType>();
    const allowMoveDown = lines.length - 1 !== index;
    const allowMoveUp = index !== 0;
    const allowRemove = lines.length > 1;
    const moveUp = useCallback(() => swap(index - 1, index), [index, swap]);
    const moveDown = useCallback(() => swap(index + 1, index), [index, swap]);

    const onRemove = useCallback(() => {
      setValue(`${documentType}.lines.${index}.created`, false);
    }, [documentType, index, setValue]);

    return (
      <Box pt={4} flexBasis="100%" width="100%">
        <Box flexDirection="row">
          <Box>
            <TouchableOpacity onPress={allowMoveUp ? moveUp : undefined}>
              <Icon
                icon={faArrowUp}
                size={30}
                color={!allowMoveUp ? 'titleBorder' : 'darkText'}
              />
            </TouchableOpacity>
          </Box>
          <Box pl={3}>
            <TouchableOpacity onPress={allowMoveDown ? moveDown : undefined}>
              <Icon
                icon={faArrowDown}
                size={30}
                color={!allowMoveDown ? 'titleBorder' : 'darkText'}
              />
            </TouchableOpacity>
          </Box>
          <ActionButtons documentType={documentType} index={index} />

          <Box pl={3}>
            <TouchableOpacity onPress={allowRemove ? onRemove : undefined}>
              <Icon
                icon={faTrashCan}
                size={30}
                color={!allowRemove ? 'titleBorder' : 'darkText'}
              />
            </TouchableOpacity>
          </Box>
        </Box>
      </Box>
    );
  },
  isEqual,
);

const MobileFinancialDocumentLinesEditorLineRow = ({
  index,
  documentType,
}: MobileFinancialDocumentLinesEditorLineRowProps): ReactElement => {
  const handleRenderSummary = useCallback(
    (open: boolean) => (
      <Summary documentType={documentType} index={index} open={open} />
    ),
    [documentType, index],
  );

  const handleRenderChildren = useCallback(
    (open: boolean) =>
      open ? (
        <Box alignItems="center" flexDirection="row" flex={1} flexWrap="wrap">
          <Box flexBasis="100%" width="100%">
            <Typography variant="subtitle1" color="subtitle1Color">
              <FormattedMessage id="label.description" />
            </Typography>
          </Box>
          <Box flexBasis="100%" width="100%">
            <ReactHookFormTextField
              name={`${documentType}.lines.${index}.description`}
              multiline
            />
            <OptionalMessage documentType={documentType} index={index} />
          </Box>
          <Box flexBasis="41.66666667%" width="41.66666667%">
            <Typography variant="subtitle1" color="subtitle1Color">
              <FormattedMessage id="label.quantity" />
            </Typography>
          </Box>
          <Box flexBasis="58.33333333%" width="58.33333333%">
            <QuantityField documentType={documentType} index={index} />
          </Box>
          <Box flexBasis="41.66666667%" width="41.66666667%">
            <Typography variant="subtitle1" color="subtitle1Color">
              <FormattedMessage id="label.vatCodeId" />
            </Typography>
          </Box>
          <Box flexBasis="58.33333333%" width="58.33333333%">
            <VatCodeField documentType={documentType} index={index} />
          </Box>
          <Box flexBasis="41.66666667%" width="41.66666667%">
            <Typography variant="subtitle1" color="subtitle1Color">
              <FormattedMessage id="label.pricePerUnit" />
            </Typography>
          </Box>
          <Box flexBasis="58.33333333%" width="58.33333333%">
            <ReactHookFormNumberTextField
              name={`${documentType}.lines.${index}.pricePerUnit`}
              fieldValue="amount"
              autoComplete="off"
              keyboardType="decimal-pad"
              textAlign="right"
              divideBy={100}
              fractionDigits={2}
              type="currency"
            />
          </Box>
          <Box flexBasis="41.66666667%" width="41.66666667%">
            <Typography variant="subtitle1" color="subtitle1Color">
              <FormattedMessage id="label.turnoverGroup" />
            </Typography>
          </Box>
          <Box flexBasis="58.33333333%" width="58.33333333%">
            <TurnoverGroupField documentType={documentType} index={index} />
          </Box>
          <Actions documentType={documentType} index={index} />
        </Box>
      ) : null,
    [documentType, index],
  );

  return (
    <Accordion style={styles.accordion} summary={handleRenderSummary}>
      {handleRenderChildren}
    </Accordion>
  );
};

const MemoComponent = memo(MobileFinancialDocumentLinesEditorLineRow, isEqual);
export default MemoComponent;
