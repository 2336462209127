import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type UpdateCustomerOfQuoteMutationProps = {
  quoteId: Uuid;
  relationId: Uuid;
  contactPersonId?: Uuid | null;
  addressId: Uuid;
};

export const UpdateCustomerOfQuoteMutation = async ({
  quoteId,
  ...values
}: UpdateCustomerOfQuoteMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/quotes/${quoteId}/update-customer`, {
    quoteId,
    ...values,
  });

export const useUpdateCustomerOfQuoteMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateCustomerOfQuoteMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  UpdateCustomerOfQuoteMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateCustomerOfQuoteMutationProps
  >({
    mutationFn: UpdateCustomerOfQuoteMutation,
    throwOnError: false,
    ...options,
  });
