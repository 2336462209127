import * as Yup from 'yup';
import { Uuid } from '@bas/value-objects';

export type RelatedDeclarationInputType = {
  eventId?: Uuid | null;
  project?: {
    projectId?: Uuid | null;
  };
  relation?: {
    relationId?: Uuid | null;
  };
};

export const relatedDeclarationInputTypeDefaultValues =
  (): RelatedDeclarationInputType => ({
    eventId: '',
    project: {
      projectId: '',
    },
    relation: {
      relationId: '',
    },
  });

export const relatedDeclarationInputTypeValidationBuilder =
  (): Yup.ObjectSchema<RelatedDeclarationInputType> =>
    Yup.object({
      eventId: Yup.string().optional().nullable().label('label.eventId'),
      project: Yup.object({
        projectId: Yup.string().optional().nullable().label('label.project'),
      }),
      relation: Yup.object({
        relationId: Yup.string().optional().nullable().label('label.relation'),
      }),
    });
