import { Declaration, DeclarationStatus } from '../types';

export function isDeclarationApproved(
  declaration: Declaration,
): declaration is Declaration & {
  status: DeclarationStatus.APPROVED | DeclarationStatus.PAID;
} {
  return [DeclarationStatus.APPROVED, DeclarationStatus.PAID].includes(
    declaration.status,
  );
}

export function isDeclarationRejected(
  declaration: Declaration,
): declaration is Declaration & {
  status: DeclarationStatus.DISAPPROVED | DeclarationStatus.CANCELLED;
} {
  return [DeclarationStatus.DISAPPROVED, DeclarationStatus.CANCELLED].includes(
    declaration.status,
  );
}
