import {
  MovingJobAddress,
  MovingJobAddressType,
  ProjectEstimate,
} from '@bas/project-domain/models';
import { BillingMoment, PublicServiceType } from '@bas/shared/models';
import { getDefaultCountry } from '@bas/shared/utils';
import {
  isMovingJobTaxationStandardFurniture,
  MovingJobTaxationCustomer,
  MovingJobTaxationRoom,
  MovingJobTaxationStandardFurniture,
} from '@bas/taxation-tool-domain/models';
import {
  ErrorResponse,
  HowDidYouFindUs,
  PricingType,
  Uuid,
} from '@bas/value-objects';
import { InventoryMovementInputType } from '@bas/wms-domain/input-types';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { v7 } from 'uuid';

export type CalculateEstimateForMovingJobMutationProps = {
  projectId: Uuid;
  identityId?: Uuid | null;
  customer: MovingJobTaxationCustomer;
  packageId: Uuid;
  pricingType: PricingType;
  addresses: MovingJobAddress[];
  rooms: MovingJobTaxationRoom[];
  services: (PublicServiceType & { selected: boolean })[];
  notes: string;
  storageNotes: string;
  customerNotes: string;
  billingMoments: BillingMoment[];
  neededInventoryItems: InventoryMovementInputType[];
};

const mapServices = (
  services: (PublicServiceType & { selected: boolean })[],
  selectedServices: {
    [key: string]: boolean;
  },
) =>
  typeof selectedServices === 'object'
    ? Object.keys(selectedServices)
        .filter((internalType) => selectedServices[internalType])
        .map((internalType) => {
          const service = services.find(
            ({ internalType: serviceInternalType }) =>
              serviceInternalType === internalType,
          );
          if (!service) {
            return {
              selected: false,
            };
          }
          return {
            selected: service.selected,
            serviceTypeId: service.serviceTypeId,
          };
        })
        .filter(({ selected }) => selected)
        .map(({ serviceTypeId }) => ({ serviceTypeId }))
    : [];

export const CalculateEstimateForMovingJobMutation = async ({
  projectId,
  neededInventoryItems,
  ...values
}: CalculateEstimateForMovingJobMutationProps): Promise<
  AxiosResponse<ProjectEstimate>
> => {
  const handlesAddressTypes: MovingJobAddressType[] = [];

  return axios.post('api/{tenantId}/moving-jobs/request-estimate', {
    projectId,
    ...values,
    services: values.services
      .filter(({ selected }) => selected)
      .map(({ serviceTypeId }) => ({ serviceTypeId })),
    addresses: values.addresses
      .filter(({ unknownAddress }) => !unknownAddress)
      .map((address) => {
        const floor = address.floor
          ? parseFloat(address.floor.toString())
          : null;

        if (
          address.primary ||
          handlesAddressTypes.includes(address.addressType)
        ) {
          handlesAddressTypes.push(address.addressType);
          return { ...address, floor };
        }

        const primaryAddress = values.addresses.find(
          ({ addressType, primary }) =>
            addressType === address.addressType && primary,
        );

        if (!primaryAddress) {
          handlesAddressTypes.push(address.addressType);
          return { ...address, primary: true, floor };
        }

        return {
          ...address,
          floor,
        };
      }),
    neededInventoryItems: neededInventoryItems.filter(
      ({ quantity }) => quantity > 0,
    ),
    rooms: values.rooms
      .filter((room) => room.selected)
      .map((room) => {
        const selectedFurniture = room.furniture.filter(
          (item) => item.selected,
        );

        const furniture: unknown[] = [];
        selectedFurniture.forEach((item) => {
          if (isMovingJobTaxationStandardFurniture(item) && item.quantity > 0) {
            furniture.push(
              ...new Array(item.quantity)
                .fill(item)
                .map(
                  (i: MovingJobTaxationStandardFurniture, quantityIndex) => ({
                    ...i,
                    furnitureId: v7(),
                    services: mapServices(
                      values.services,
                      i.services[quantityIndex],
                    ),
                  }),
                ),
            );
          } else {
            furniture.push({
              ...item,
              services: mapServices(values.services, item.services[0]),
            });
          }
        });

        return {
          ...room,
          photos: room.photos.filter((photo) => !!photo.mediaObjectId),
          furniture,
          inventoryItems: room.inventoryItems.map(
            ({ quantity, storageQuantity, ...item }) => ({
              ...item,
              quantity: !quantity ? 0 : quantity,
              storageQuantity: !storageQuantity ? 0 : storageQuantity,
            }),
          ),
        };
      }),
    customer: {
      ...values.customer,
      howDidYouFindUs:
        values.customer.howDidYouFindUs &&
        [HowDidYouFindUs.OTHER, HowDidYouFindUs.LEAD_WEBSITE].includes(
          values.customer.howDidYouFindUs as HowDidYouFindUs,
        )
          ? values.customer.howDidYouFindUsOther
          : values.customer.howDidYouFindUs,
      region: getDefaultCountry(),
    },
  });
};

export const useCalculateEstimateForMovingJobMutation = (
  options: UseMutationOptions<
    AxiosResponse<ProjectEstimate>,
    AxiosError<ErrorResponse>,
    CalculateEstimateForMovingJobMutationProps
  >,
): UseMutationResult<
  AxiosResponse<ProjectEstimate>,
  AxiosError<ErrorResponse>,
  CalculateEstimateForMovingJobMutationProps
> =>
  useMutation<
    AxiosResponse<ProjectEstimate>,
    AxiosError<ErrorResponse>,
    CalculateEstimateForMovingJobMutationProps
  >({
    mutationFn: CalculateEstimateForMovingJobMutation,
    throwOnError: false,
    ...options,
  });
