import type { EmployeeProjectRole, PricingType } from '@bas/value-objects';
import type { TransportJobStatus } from './TransportJobStatus';
import type { Project } from './Project';
import type { Cargo } from './Cargo';

export interface TransportJob extends Project {
  pricingType: PricingType;
  neededRoles: EmployeeProjectRole[];
  customerNotes?: string;
  projectStatus: TransportJobStatus;
  nextActionDate?: Date;
  loads: Cargo[];
}
