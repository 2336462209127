import { useFormAddressAutoComplete } from '@bas/shared/hooks';
import { ReactElement, useState } from 'react';
import { DutchAddressForm } from '../DutchAddressForm';
import { OtherCountriesAddressForm } from '../OtherCountriesAddressForm';

export type AddressFormProps = {
  prefix: string;
  disabled?: boolean;
  light?: boolean;
};

const AddressForm = ({
  prefix,
  disabled,
  light,
}: AddressFormProps): ReactElement => {
  const [validAddress, setValidAddress] = useState<boolean>(false);
  const { country } = useFormAddressAutoComplete(prefix, setValidAddress);

  if (country === 'NL') {
    return (
      <DutchAddressForm
        prefix={prefix}
        disabled={disabled}
        light={light}
        validAddress={validAddress}
      />
    );
  }

  return (
    <OtherCountriesAddressForm
      prefix={prefix}
      disabled={disabled}
      light={light}
    />
  );
};

export default AddressForm;
