import * as Yup from 'yup';
import { Uuid } from '@bas/value-objects';
import {
  SupplierInputType,
  SupplierInputTypeDefaultValues,
  SupplierInputTypeValidationBuilder,
} from './supplierInputType';
import {
  InventoryItemSupplierInputType,
  InventoryItemSupplierInputTypeDefaultValues,
  InventoryItemSupplierInputTypeValidationBuilder,
} from './inventoryItemSupplierInputType';

export type AddSupplierToInventoryItemInputType =
  InventoryItemSupplierInputType & {
    existingSupplierId?: Uuid;
    newSupplier?: SupplierInputType | null;
    addNewOrExistingSupplier: 'new' | 'existing' | '';
  };

export const AddSupplierToInventoryItemInputTypeDefaultValues =
  (): AddSupplierToInventoryItemInputType => ({
    ...InventoryItemSupplierInputTypeDefaultValues(),
    existingSupplierId: '',
    newSupplier: SupplierInputTypeDefaultValues(),
    addNewOrExistingSupplier: '',
  });

export const AddSupplierToInventoryItemInputTypeValidationBuilder =
  (): Yup.ObjectSchema<
    Omit<AddSupplierToInventoryItemInputType, 'newSupplier'>
  > =>
    Yup.object({
      addNewOrExistingSupplier: Yup.string()
        .nullable()
        .required()
        .oneOf(['new', 'existing'])
        .label('label.addNewOrExistingSupplier'),
      existingSupplierId: Yup.string().when('addNewOrExistingSupplier', {
        is: 'existing',
        then: (schema) => schema.required().label('label.supplier'),
      }),
      newSupplier: Yup.object()
        .when('addNewOrExistingSupplier', {
          is: 'new',
          then: (schema) => schema.concat(SupplierInputTypeValidationBuilder()),
        })
        .nullable(),
    }).concat(InventoryItemSupplierInputTypeValidationBuilder());
