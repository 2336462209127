import { useAppStore } from '@bas/shared/state';
import axios from 'axios';
import { useCallback } from 'react';

const stagingUrls = [
  'test',
  'staging',
  'android-test@bas.software',
  'ios-test@bas.bas.software',
];

export const useSetEnvironment = () => {
  const handleChangeEnvironment = useAppStore(
    (state) => state.handleChangeEnvironment,
  );

  return useCallback(
    (inputAppUrl: string): string => {
      let appUrl = inputAppUrl.toLocaleLowerCase();
      let apiUrl = 'https://api.bas.app';
      let mercureUrl = 'https://mercure.bas.app/.well-known/mercure';

      console.log(process.env.NODE_ENV);
      if (stagingUrls.some((url) => inputAppUrl.includes(url))) {
        appUrl += '.bas.app';
        apiUrl = 'https://staging-api.bas.app';
        mercureUrl = 'https://dev-mercure.bas.app/.well-known/mercure';
      } else if (
        inputAppUrl.includes('bas.local') ||
        inputAppUrl.includes('10.8.0.63') ||
        inputAppUrl.includes('exp.direct') ||
        inputAppUrl === 'localhost'
      ) {
        apiUrl = 'https://local.bas.app';
        mercureUrl = 'https://dev-mercure.bas.app/.well-known/mercure';
      } else {
        appUrl += '.bas.app';
      }

      axios.defaults.baseURL = apiUrl;
      handleChangeEnvironment(appUrl, apiUrl, mercureUrl);

      if (inputAppUrl.includes('@bas.software')) {
        return 'test.bas.app';
      }

      return appUrl;
    },
    [handleChangeEnvironment],
  );
};
