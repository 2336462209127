/* eslint-disable prefer-destructuring */
import * as React from 'react';
import { ReactElement, useCallback, useMemo } from 'react';
import { colors } from '@bas/theme';
import {
  TimeOffRegistration,
  TimeOffRegistrationStatus,
} from '@bas/hrm-domain/models';
import { Box, Icon, TouchableOpacity, Typography } from '@bas/ui/native/base';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { Badge, Chip } from '@bas/ui/native/atoms';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTimeOffTypesRequest } from '@bas/hrm-domain/requests';
import { formatHours } from '@bas/shared/utils';
import Swipeable from 'react-native-gesture-handler/Swipeable';
import { Platform } from 'react-native';

export type TimeOffRegistrationListItemProps = {
  timeOffRegistration: TimeOffRegistration;
  onCancelTimeOff?: () => void;
  onPress?: () => void;
};

const TimeOffRegistrationListItem = ({
  timeOffRegistration,
  onPress,
  onCancelTimeOff,
}: TimeOffRegistrationListItemProps): ReactElement => {
  const { formatDate } = useIntl();

  const { data: timeOffTypesData } = useTimeOffTypesRequest({
    perPage: 9999,
  });

  const timeOffTypes = useMemo(
    () => timeOffTypesData?.data.member || [],
    [timeOffTypesData?.data],
  );

  let dotColor = colors.blue[500];
  if (timeOffRegistration.status === TimeOffRegistrationStatus.APPROVED) {
    dotColor = colors.green[300];
  } else if (
    timeOffRegistration.status === TimeOffRegistrationStatus.REJECTED
  ) {
    dotColor = colors.red[700];
  } else if (
    timeOffRegistration.status === TimeOffRegistrationStatus.CANCELLED
  ) {
    dotColor = colors.orange[700];
  }

  const timeOffType = timeOffTypes.find(
    ({ timeOffTypeId }) => timeOffTypeId === timeOffRegistration.timeOffTypeId,
  );

  let startToUse = timeOffRegistration.start;
  let endToUse = timeOffRegistration.end;
  let hoursToUse = timeOffRegistration.effectiveHours;
  if (
    timeOffRegistration.status === TimeOffRegistrationStatus.APPROVED &&
    timeOffRegistration.approvalInformation
  ) {
    startToUse = timeOffRegistration.approvalInformation.approvedStart;
    endToUse = timeOffRegistration.approvalInformation.approvedEnd;
    hoursToUse = timeOffRegistration.approvedEffectiveHours;
  }

  if (!timeOffRegistration.buildUp) {
    hoursToUse *= -1;
  }

  const handleRenderRightActions = useCallback(
    () => (
      <Box width={100}>
        <TouchableOpacity onPress={onCancelTimeOff} style={{ height: '100%' }}>
          <Box
            height="100%"
            width="100%"
            backgroundColor="deleteBackground"
            justifyContent="center"
            alignItems="center"
          >
            <Icon icon={faTimes} color="white" size={24} />
          </Box>
        </TouchableOpacity>
      </Box>
    ),
    [onCancelTimeOff],
  );

  return (
    <Swipeable
      renderRightActions={handleRenderRightActions}
      friction={2}
      enableTrackpadTwoFingerGesture
      leftThreshold={30}
      rightThreshold={40}
      enabled={[
        TimeOffRegistrationStatus.PENDING,
        TimeOffRegistrationStatus.REJECTED,
      ].includes(timeOffRegistration.status)}
      key={`${timeOffRegistration.timeOffId}-${timeOffRegistration.status}`}
    >
      <TouchableOpacity
        onPress={onPress}
        delayPressIn={Platform.OS === 'web' ? 100 : undefined}
      >
        <Box
          flex={1}
          flexDirection="row"
          alignItems="center"
          flexWrap="wrap"
          paddingHorizontal={20}
          paddingVertical={1}
          justifyContent="space-between"
          gap={3}
          paddingBottom={0.5}
          borderBottomWidth={1}
          borderBottomColor="titleBorder"
          backgroundColor="white"
        >
          <Box flex={1} flexDirection="column" flexWrap="wrap" gap={0.2}>
            <Box width="100%">
              <Typography variant="medium" fontWeight={700}>
                {timeOffRegistration.reason}
              </Typography>
            </Box>
            <Box width="100%">
              <Typography color="subtitle1Color">
                {timeOffType?.name}
              </Typography>
            </Box>
            <Box width="100%">
              <Typography variant="body2" color="subtitle1Color">
                <FormattedMessage
                  id="label.timeOffFromUntil"
                  values={{
                    from: formatDate(startToUse, {
                      day: '2-digit',
                      month: '2-digit',
                      year: '2-digit',
                      hour: timeOffRegistration.fullDay ? undefined : '2-digit',
                      minute: timeOffRegistration.fullDay
                        ? undefined
                        : '2-digit',
                    }),
                    until: formatDate(endToUse, {
                      day: '2-digit',
                      month: '2-digit',
                      year: '2-digit',
                      hour: timeOffRegistration.fullDay ? undefined : '2-digit',
                      minute: timeOffRegistration.fullDay
                        ? undefined
                        : '2-digit',
                    }),
                  }}
                />
              </Typography>
            </Box>
          </Box>
          <Box flexDirection="column" flexWrap="wrap">
            <Typography variant="medium" fontWeight={700} pb={1}>
              {formatHours(hoursToUse)}
            </Typography>
            <Chip>
              <Box flexDirection="row" alignItems="center" gap={0.5}>
                <Badge color={dotColor} />
                <Typography variant="body2" overrideColor={colors.lila[700]}>
                  <FormattedMessage
                    id={`timeOffRegistrationStatus.${timeOffRegistration.status}`}
                  />
                </Typography>
              </Box>
            </Chip>
          </Box>
        </Box>
      </TouchableOpacity>
    </Swipeable>
  );
};

export default TimeOffRegistrationListItem;
