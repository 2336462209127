import { Uuid } from '@bas/value-objects';
import * as Yup from 'yup';

export interface FinancialDocumentInputType {
  identityId?: Uuid | null;
  templateId: Uuid;
  reference?: string | null;
}

export const FinancialDocumentInputTypeValidationBuilder =
  (): Yup.ObjectSchema<FinancialDocumentInputType> =>
    Yup.object({
      identityId: Yup.string().nullable().label('label.identityId'),
      templateId: Yup.string().required().label('label.templateId'),
      reference: Yup.string().nullable().label('label.reference'),
    });
