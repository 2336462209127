import * as Yup from 'yup';

export type UploadEmployeeImageInputType = {
  fileId: string;
};

export const UploadEmployeeImageInputTypeDefaultValues =
  (): UploadEmployeeImageInputType => ({
    fileId: '',
  });

export const UploadEmployeeImageInputTypeValidationBuilder =
  (): Yup.ObjectSchema<UploadEmployeeImageInputType> =>
    Yup.object({
      fileId: Yup.string().required().label('label.fileId'),
    });
