import dayjs from 'dayjs';
import * as Yup from 'yup';
import {
  MileageReportInputType,
  MileageReportInputTypeValidationBuilder,
} from './mileageReportInputType';

export interface FinishEventAtTenantInputType {
  realTravelEndTime?: Date | null;
  finishedWorkingTime?: Date | null;
  mileageReports: MileageReportInputType[];
}

export const FinishEventAtTenantInputTypeDefaultValues =
  (): FinishEventAtTenantInputType => ({
    realTravelEndTime: null,
    finishedWorkingTime: null,
    mileageReports: [],
  });

export const FinishEventAtTenantInputTypeValidationBuilder =
  (): Yup.ObjectSchema<FinishEventAtTenantInputType> =>
    Yup.object({
      finishedWorkingTime: Yup.date().nullable().label('label.date'),
      realTravelEndTime: Yup.date()
        .nullable()
        .required()
        .label('label.realTravelEndTime')
        .when(['finishedWorkingTime'], ([finishedWorkingTime], schema) => {
          if (!finishedWorkingTime || !dayjs(finishedWorkingTime).isValid())
            return schema;
          return schema
            .min(dayjs(finishedWorkingTime).clone().add(1, 'second').toDate())
            .required();
        }),
      mileageReports: Yup.array()
        .of(MileageReportInputTypeValidationBuilder())
        .required(),
    });
