import { Language, TranslatedName, Uuid } from '@bas/value-objects';
import * as Yup from 'yup';

export type TimeTypeInputType = {
  name: string;
  categoryId: Uuid;
  icon: string;
  iconValue?: {
    value?: string;
  };
  weight: number | null;
  fieldService: boolean;
  isWorkingHours: boolean;
  translatedNames: TranslatedName[];
};

export const timeTypeInputTypeDefaultValues = (): TimeTypeInputType => ({
  name: '',
  categoryId: '',
  icon: '',
  weight: null,
  fieldService: true,
  isWorkingHours: true,
  translatedNames: [],
  iconValue: {
    value: '',
  },
});

export const timeTypeInputTypeValidationBuilder =
  (): Yup.ObjectSchema<TimeTypeInputType> =>
    Yup.object({
      name: Yup.string().label('label.name').ensure().required(),
      weight: Yup.number().nullable().required().label('label.sortingWeight'),
      categoryId: Yup.string().required().label('label.category'),
      icon: Yup.string().required().label('label.icon'),
      fieldService: Yup.boolean().required().label('label.fieldService'),
      isWorkingHours: Yup.boolean().required().label('label.isWorkingHours'),
      translatedNames: Yup.array()
        .of(
          Yup.object({
            locale: Yup.mixed<Language>()
              .oneOf(Object.values(Language))
              .required()
              .label('label.locale'),
            name: Yup.string().max(200).required().label('label.name'),
          }).required(),
        )
        .required(),
      iconValue: Yup.object({
        value: Yup.string().label('label.icon').optional(),
      }).optional(),
    });
