import { TouchableWithoutFeedback, Typography } from '@bas/ui/native/base';
import { EmailAddress } from '@bas/value-objects';
import { ReactElement } from 'react';
import { Linking } from 'react-native';
import styles from './styles';

export type RenderEmailAddressProps = {
  emailAddress: EmailAddress;
  withoutLinks?: boolean;
};

const RenderEmailAddress = ({
  emailAddress,
  withoutLinks,
}: RenderEmailAddressProps): ReactElement => {
  if (withoutLinks) {
    return <Typography>{emailAddress}</Typography>;
  }

  return (
    <TouchableWithoutFeedback
      onPress={() => Linking.openURL(`mailto:${emailAddress}`)}
    >
      <Typography style={styles.link}>{emailAddress}</Typography>
    </TouchableWithoutFeedback>
  );
};

export default RenderEmailAddress;
