import { basParaph } from '@bas/svg-icons';
import { colors } from '@bas/theme';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { memo, ReactElement } from 'react';
import { View, ViewProps } from 'react-native';
import { AnimatedCircularProgress } from 'react-native-circular-progress';
import { StepState } from './stepState';
import styles from './style';

export type StepProps = ViewProps & {
  state?: StepState;
  value?: number;
};

const Step = ({
  state = StepState.TODO,
  value = 0,
  ...args
}: StepProps): ReactElement => {
  let content = null;

  if (state === StepState.FINISHED) {
    content = (
      <FontAwesomeIcon color={colors.blue[500]} size={18} icon={basParaph} />
    );
  }

  if (state === StepState.IN_PROGRESS) {
    content = (
      <View style={styles.progressContainer}>
        <AnimatedCircularProgress
          size={26}
          width={2.5}
          rotation={0}
          backgroundColor={colors.lila[400]}
          tintColor={colors.blue[500]}
          fill={value || 0}
        />
      </View>
    );
  }

  return (
    <View style={styles.step} {...args}>
      {content}
    </View>
  );
};

const MemoComponent = memo(
  Step,
  (prevProps, nextProps) =>
    prevProps.state === nextProps.state && prevProps.value === nextProps.value,
);

export default MemoComponent;
