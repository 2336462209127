import { TimeType } from '@bas/hrm-domain/models';
import { Event } from '@bas/planning-domain/models';
import * as Notifications from 'expo-notifications';
import { Location } from 'react-native-background-geolocation';

type TrackLocationForTimeTrackingProps = {
  eventsToTrack: Event[];
  timeTypes: TimeType[];
};

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: false,
  }),
});

export const useTrackLocationForTimeTracking =
  // eslint-disable-next-line no-empty-pattern
  ({}: TrackLocationForTimeTrackingProps): {
    isTracking: boolean;
    currentLocation: Location | undefined;
    toggleTrackingLocationForTimeTracking: () => void;
  } => ({
    isTracking: false,
    currentLocation: undefined,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    toggleTrackingLocationForTimeTracking: () => {},
  });
