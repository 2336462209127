import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    flexBasis: '50%',
    width: '50%',
    flexGrow: 1,
    position: 'relative',
  },
  spacingBetweenColumns: {
    paddingRight: 8,
  },
  spacingBetweenRows: {
    paddingTop: 8,
  },
  image: {
    width: '100%',
    height: 250,
  },
  bar: {
    position: 'absolute',
    height: 100,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  name: {
    paddingRight: 8,
  },
});
