import {
  ChangeTimeOffRegistrationInputType,
  changeTimeOffRegistrationInputTypeValidationBuilder,
} from '@bas/hrm-domain/input-types';
import {
  TimeOffRegistration,
  TimeOffRegistrationStatus,
} from '@bas/hrm-domain/models';
import {
  TimeOffRegistrationApprovalInformationContentSection,
  TimeOffRegistrationInformationContentSection,
  TimeOffRegistrationRejectionInformationContentSection,
} from '@bas/hrm-domain/native/molecules';
import { colors } from '@bas/theme';
import { BottomSheetMethods, Box, Typography } from '@bas/ui/native/base';
import { BackendErrors, BottomSheetProps } from '@bas/ui/native/organisms';
import { FormBottomSheet } from '@bas/ui/native/templates';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { ReactElement, RefObject, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { ChangeTimeOffRegistrationForm } from '../ChangeTimeOffRegistrationForm';

export type TimeOffDetailsBottomSheetProps = Omit<
  BottomSheetProps,
  'snapPoints' | 'children'
> & {
  onSubmit: (
    values: ChangeTimeOffRegistrationInputType,
  ) => Promise<void> | void;
  bottomSheetRef?: RefObject<BottomSheetModal & BottomSheetMethods>;
};

const TimeOffDetailsBottomSheet = ({
  onSubmit,
  bottomSheetRef,
  ...props
}: TimeOffDetailsBottomSheetProps): ReactElement => {
  const handleFinalize = useCallback(() => {
    bottomSheetRef?.current?.close();
    bottomSheetRef?.current?.forceClose();
    bottomSheetRef?.current?.dismiss();
  }, [bottomSheetRef]);

  const handleDefaultValues = useCallback(
    (data: {
      timeOffRegistration: TimeOffRegistration;
    }): ChangeTimeOffRegistrationInputType => ({
      timeOffId: data.timeOffRegistration.timeOffId,
      start: data.timeOffRegistration.start,
      end: data.timeOffRegistration.end,
      reason: data.timeOffRegistration.reason,
      timeOffTypeId: data.timeOffRegistration.timeOffTypeId,
      fullDay: data.timeOffRegistration.fullDay,
      effectiveHours: data.timeOffRegistration.effectiveHours,
      daysOff: data.timeOffRegistration.daysOff,
      buildUp: data.timeOffRegistration.buildUp,
    }),
    [],
  );
  const handleRender = useCallback(
    ({ timeOffRegistration }: { timeOffRegistration: TimeOffRegistration }) => (
      <Box flex={1}>
        <Typography
          fontWeight={700}
          color="white"
          textAlign="center"
          fontSize={22}
          lineHeight={29}
        >
          <FormattedMessage id="label.timeOffRegistrationDetails" />
        </Typography>
        <Box paddingHorizontal={20} marginBottom={2}>
          <BackendErrors />
          <Box paddingTop={2}>
            <TimeOffRegistrationInformationContentSection
              timeOffRegistration={timeOffRegistration}
            />
          </Box>
          {timeOffRegistration.approvalInformation?.approvedOn && (
            <Box paddingTop={2}>
              <TimeOffRegistrationApprovalInformationContentSection
                approvalInformation={timeOffRegistration.approvalInformation}
                approvedByName={timeOffRegistration.approvedByName}
                approvedEffectiveHours={
                  timeOffRegistration.approvedEffectiveHours
                }
                daysOff={timeOffRegistration.daysOff}
              />
            </Box>
          )}
          {timeOffRegistration.rejectionInformation?.rejectedOn && (
            <Box paddingTop={2}>
              <TimeOffRegistrationRejectionInformationContentSection
                rejectionInformation={timeOffRegistration.rejectionInformation}
                rejectedByName={timeOffRegistration.rejectedByName}
              />
            </Box>
          )}
          {[
            TimeOffRegistrationStatus.PENDING,
            TimeOffRegistrationStatus.REJECTED,
          ].includes(timeOffRegistration.status) && (
            <Box paddingTop={2}>
              <Typography variant="subtitle1" color="white" mb={1}>
                <FormattedMessage id="label.changeTimeOffRegistration" />
              </Typography>
              <ChangeTimeOffRegistrationForm
                buildUp={timeOffRegistration.buildUp}
              />
            </Box>
          )}
        </Box>
      </Box>
    ),
    [],
  );

  const handleHideSubmit = useCallback(
    (data: { timeOffRegistration: TimeOffRegistration }): boolean =>
      ![
        TimeOffRegistrationStatus.REJECTED,
        TimeOffRegistrationStatus.PENDING,
      ].includes(data.timeOffRegistration.status),
    [],
  );

  const validationSchema = useMemo(
    () => changeTimeOffRegistrationInputTypeValidationBuilder(),
    [],
  );

  return (
    <FormBottomSheet<
      ChangeTimeOffRegistrationInputType,
      {
        timeOffRegistration: TimeOffRegistration;
      }
    >
      bottomSheetRef={bottomSheetRef}
      disablePadding
      onSubmit={onSubmit}
      useProvider
      resetFormOnOpen
      onCancel={handleFinalize}
      defaultValues={handleDefaultValues}
      useData
      hideSubmit={handleHideSubmit}
      hideBackendErrors
      validationSchema={validationSchema}
      successFullFormViewProps={{
        gifUrl: 'https://media.giphy.com/media/d7kO1wIoaXbJV5uSSy/giphy.gif',
        title: 'label.changedTimeOffRegistration',
        color: colors.lila[100],
        labelId: 'label.timeOffRegistrationChangedDescription',
      }}
      renderWithData={handleRender}
      {...props}
    />
  );
};

export default TimeOffDetailsBottomSheet;
