import {
  FontAwesomeIcon,
  FontAwesomeIconStyle,
  Props,
} from '@fortawesome/react-native-fontawesome';
import {
  color,
  ColorProps,
  composeRestyleFunctions,
  useRestyle,
} from '@shopify/restyle';
import { ShopifyTheme } from '../theme';

export type RestyleProps = ColorProps<ShopifyTheme> & {
  style?: FontAwesomeIconStyle;
};

const restyleFunctions = composeRestyleFunctions<ShopifyTheme, RestyleProps>([
  color,
]);

export type IconProps = RestyleProps &
  Omit<Props, 'color'> & {
    overrideColor?: string;
  };

const Icon = ({
  color: colorProp,
  style,
  overrideColor,
  ...rest
}: IconProps) => {
  const props = useRestyle<ShopifyTheme, RestyleProps, RestyleProps>(
    restyleFunctions,
    { color: colorProp },
  ) as RestyleProps;

  return (
    <FontAwesomeIcon
      style={[props.style, style]}
      color={overrideColor}
      {...rest}
    />
  );
};

export default Icon;
