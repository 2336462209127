import { CanPayAtCustomer } from '@bas/planning-domain/models';
import { BillingMomentType, PublicServiceType } from '@bas/shared/models';
import { basAssemble, basDisassemble, basParaph } from '@bas/svg-icons';
import { MobileActionButton } from '@bas/ui/native/atoms';
import { EventType, InternalServiceType, Uuid } from '@bas/value-objects';
import { faArrowRightToBracket } from '@fortawesome/pro-light-svg-icons/faArrowRightToBracket';
import { faBoxOpen } from '@fortawesome/pro-light-svg-icons/faBoxOpen';
import { faCabinetFiling } from '@fortawesome/pro-light-svg-icons/faCabinetFiling';
import { faCashRegister } from '@fortawesome/pro-light-svg-icons/faCashRegister';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle';
import { faHouseChimneyCrack } from '@fortawesome/pro-light-svg-icons/faHouseChimneyCrack';
import { faMoneyCheckEditAlt } from '@fortawesome/pro-light-svg-icons/faMoneyCheckEditAlt';
import { faPeopleCarryBox } from '@fortawesome/pro-light-svg-icons/faPeopleCarryBox';
import { faPersonDolly } from '@fortawesome/pro-light-svg-icons/faPersonDolly';
import { faPersonDollyEmpty } from '@fortawesome/pro-light-svg-icons/faPersonDollyEmpty';
import { faRoute } from '@fortawesome/pro-light-svg-icons/faRoute';
import { faSteeringWheel } from '@fortawesome/pro-light-svg-icons/faSteeringWheel';
import { faTruckContainer } from '@fortawesome/pro-light-svg-icons/faTruckContainer';
import { faTruckRamp } from '@fortawesome/pro-light-svg-icons/faTruckRamp';
import { faUpload } from '@fortawesome/pro-light-svg-icons/faUpload';
import { faWarehouseAlt } from '@fortawesome/pro-light-svg-icons/faWarehouseAlt';
import { useVariant } from '@unleash/proxy-client-react';
import * as React from 'react';
import { ReactElement, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Platform } from 'react-native';
import { OpenListType } from './openListType';
import { showMovingBoxesEventTypes } from './showMovingBoxesEventTypes';

export type useMovingJobEventPageActionsProps = {
  eventId: Uuid;
  eventType: EventType;
  finished: boolean;
  finishedAtCustomer: boolean;
  startedWorking: boolean;
  startedDriving: boolean;
  hasForeman: boolean;
  isForeman: boolean;
  projectId: Uuid;
  services: PublicServiceType[];
  onOpenList: (newValue: OpenListType | undefined) => void;
  isLoadingStorage: boolean;
  onStartFinalizingEventAtCustomer: () => void;
  onStartRegisterMileage: () => void;
  onStartFinalizingEvent: () => void;
  onStartPayingAtCustomer: (billingMomentType: BillingMomentType) => void;
  onStartReportingDamage: () => void;
  onStartUploadingDocument: () => void;
  onOpenWorkingOnEventForm: () => void;
  onOpenDrivingToEventForm: () => void;
  onStartNavigation: () => void;
  onStartTaxation: () => void;
  numberOfStorages: number;
  canPayAtCustomer?: CanPayAtCustomer;
  loadingInvoice?: boolean;
  hasVehicles?: boolean;
};

export const useMovingJobEventPageActions = ({
  eventId,
  eventType,
  finished,
  finishedAtCustomer,
  projectId,
  isLoadingStorage,
  services,
  numberOfStorages,
  canPayAtCustomer,
  onOpenList,
  onStartFinalizingEventAtCustomer,
  onStartFinalizingEvent,
  onStartPayingAtCustomer,
  onStartReportingDamage,
  onStartUploadingDocument,
  onOpenWorkingOnEventForm,
  onOpenDrivingToEventForm,
  onStartRegisterMileage,
  onStartNavigation,
  onStartTaxation,
  hasForeman,
  isForeman,
  startedWorking,
  startedDriving,
  loadingInvoice,
  hasVehicles,
}: useMovingJobEventPageActionsProps) => {
  const allowMapboxNavigation = useVariant('mapbox-navigation');

  return useMemo(() => {
    const usedServices: InternalServiceType[] = services.map(
      ({ internalType }) => internalType,
    );

    const reportDamageAction = (
      <MobileActionButton
        icon={faHouseChimneyCrack}
        onPress={onStartReportingDamage}
      >
        <FormattedMessage id="eventActions.reportDamage" />
      </MobileActionButton>
    );

    const registerMileageReportAction = (
      <MobileActionButton
        icon={faCabinetFiling}
        onPress={onStartRegisterMileage}
        disabled={!hasVehicles}
      >
        <FormattedMessage id="eventActions.registerMileageReport" />
      </MobileActionButton>
    );

    const uploadDocumentAction = (
      <MobileActionButton icon={faUpload} onPress={onStartUploadingDocument}>
        <FormattedMessage id="eventActions.uploadDocument" />
      </MobileActionButton>
    );

    const actions: ReactElement[] = [];

    if (eventType === EventType.INTAKE_EVENT) {
      actions.push(
        <MobileActionButton
          icon={faMoneyCheckEditAlt}
          onPress={onStartTaxation}
        >
          <FormattedMessage id="eventActions.startWithIntake" />
        </MobileActionButton>,
      );
      actions.push(
        <MobileActionButton
          icon={faBoxOpen}
          onPress={() => onOpenList(OpenListType.DELIVER_BOXES)}
        >
          <FormattedMessage id="eventActions.deliverBoxes" />
        </MobileActionButton>,
      );
    } else {
      actions.push(
        <MobileActionButton
          icon={faPersonDollyEmpty}
          onPress={() => onOpenList(OpenListType.TAKE_MATERIAL)}
        >
          <FormattedMessage id="eventActions.takeMaterial" />
        </MobileActionButton>,
      );
      actions.push(
        <MobileActionButton
          icon={faPersonDolly}
          onPress={() => onOpenList(OpenListType.RETRIEVE_MATERIAL)}
        >
          <FormattedMessage id="eventActions.retrieveMaterial" />
        </MobileActionButton>,
      );
    }

    if (
      eventType === EventType.DISASSEMBLE_EVENT ||
      ([
        EventType.MOVING_EVENT,
        EventType.LOAD_STORAGE_EVENT,
        EventType.UNLOAD_STORAGE_EVENT,
        EventType.MOVING_LOADING_EVENT,
        EventType.MOVING_UNLOADING_EVENT,
      ].includes(eventType) &&
        usedServices.includes(InternalServiceType.DISASSEMBLE))
    ) {
      actions.push(
        <MobileActionButton
          icon={basDisassemble}
          onPress={() => onOpenList(OpenListType.DISASSEMBLY)}
        >
          <FormattedMessage id="eventActions.disassemblyList" />
        </MobileActionButton>,
      );
    }

    if (
      eventType === EventType.ASSEMBLE_EVENT ||
      ([
        EventType.MOVING_EVENT,
        EventType.LOAD_STORAGE_EVENT,
        EventType.UNLOAD_STORAGE_EVENT,
        EventType.MOVING_LOADING_EVENT,
        EventType.MOVING_UNLOADING_EVENT,
      ].includes(eventType) &&
        usedServices.includes(InternalServiceType.ASSEMBLE))
    ) {
      actions.push(
        <MobileActionButton
          icon={basAssemble}
          onPress={() => onOpenList(OpenListType.ASSEMBLY)}
        >
          <FormattedMessage id="eventActions.assemblyList" />
        </MobileActionButton>,
      );
    }

    if (showMovingBoxesEventTypes.includes(eventType)) {
      if (
        eventType !== EventType.PICKUP_BOXES_EVENT &&
        eventType !== EventType.UNPACKING_EVENT
      ) {
        actions.push(
          <MobileActionButton
            icon={faBoxOpen}
            onPress={() => onOpenList(OpenListType.DELIVER_BOXES)}
          >
            <FormattedMessage id="eventActions.deliverBoxes" />
          </MobileActionButton>,
        );
      }

      if (eventType !== EventType.DELIVER_BOXES_EVENT) {
        actions.push(
          <MobileActionButton
            icon={faBoxOpen}
            onPress={() => onOpenList(OpenListType.PICKUP_BOXES)}
          >
            <FormattedMessage id="eventActions.pickupBoxes" />
          </MobileActionButton>,
        );
      }
    }

    if (
      [
        EventType.MOVING_EVENT,
        EventType.LOAD_STORAGE_EVENT,
        EventType.UNLOAD_STORAGE_EVENT,
      ].includes(eventType)
    ) {
      actions.push(
        <MobileActionButton
          icon={faTruckRamp}
          onPress={() => onOpenList(OpenListType.FURNITURE_LIST)}
        >
          <FormattedMessage id="eventActions.furnitureList" />
        </MobileActionButton>,
      );

      const usesStorage =
        !!services.find(
          (serviceType) =>
            serviceType.internalType === InternalServiceType.STORAGE,
        ) || numberOfStorages > 0;

      if (usesStorage) {
        if (
          ![
            EventType.UNLOAD_STORAGE_EVENT,
            EventType.MOVING_UNLOADING_EVENT,
          ].includes(eventType)
        ) {
          actions.push(
            <MobileActionButton
              icon={faArrowRightToBracket}
              onPress={() => onOpenList(OpenListType.LOAD_STORAGE)}
              disabled={isLoadingStorage}
            >
              <FormattedMessage id="eventActions.loadStorage" />
            </MobileActionButton>,
          );
          actions.push(
            <MobileActionButton
              icon={faTruckContainer}
              onPress={() => onOpenList(OpenListType.PICKUP_STORAGE)}
            >
              <FormattedMessage id="button.pickUpStorage" />
            </MobileActionButton>,
          );
        }
        if (
          ![
            EventType.LOAD_STORAGE_EVENT,
            EventType.MOVING_LOADING_EVENT,
          ].includes(eventType)
        ) {
          actions.push(
            <MobileActionButton
              icon={faWarehouseAlt}
              onPress={() => onOpenList(OpenListType.UNLOAD_STORAGE)}
              disabled={isLoadingStorage}
            >
              <FormattedMessage id="eventActions.unloadStorage" />
            </MobileActionButton>,
          );
        }
      }
    }

    actions.push(reportDamageAction);
    actions.push(registerMileageReportAction);
    actions.push(uploadDocumentAction);

    if (hasForeman && !isForeman) {
      return actions;
    }

    if (!finishedAtCustomer && startedWorking) {
      actions.push(
        <MobileActionButton
          icon={basParaph}
          onPress={onStartFinalizingEventAtCustomer}
        >
          <FormattedMessage id="eventActions.finishWithCustomer" />
        </MobileActionButton>,
      );
    } else if (!startedWorking && startedDriving) {
      actions.push(
        <MobileActionButton
          icon={faPeopleCarryBox}
          onPress={onOpenWorkingOnEventForm}
        >
          <FormattedMessage id="eventActions.startWorking" />
        </MobileActionButton>,
      );
    } else if (!finishedAtCustomer) {
      actions.push(
        <MobileActionButton
          icon={faSteeringWheel}
          onPress={onOpenDrivingToEventForm}
        >
          <FormattedMessage id="eventActions.startDriving" />
        </MobileActionButton>,
      );
    } else {
      actions.push(
        <MobileActionButton
          icon={faCheckCircle}
          onPress={onStartFinalizingEvent}
          disabled={finished}
        >
          <FormattedMessage id="eventActions.finalizeEvent" />
        </MobileActionButton>,
      );
    }

    if (canPayAtCustomer) {
      actions.push(
        <MobileActionButton
          icon={faCashRegister}
          onPress={() =>
            canPayAtCustomer?.canPayForBillingMoment &&
            onStartPayingAtCustomer(canPayAtCustomer?.canPayForBillingMoment)
          }
          disabled={!canPayAtCustomer?.canPayForBillingMoment || loadingInvoice}
        >
          <FormattedMessage id="eventActions.payAtCustomer" />
        </MobileActionButton>,
      );
    }

    if (
      allowMapboxNavigation.enabled &&
      allowMapboxNavigation.payload?.value?.includes(Platform.OS)
    ) {
      actions.push(
        <MobileActionButton icon={faRoute} onPress={() => onStartNavigation()}>
          <FormattedMessage id="eventActions.startNavigation" />
        </MobileActionButton>,
      );
    }

    return actions;
  }, [
    hasVehicles,

    services,
    onStartReportingDamage,
    onStartUploadingDocument,
    eventType,
    hasForeman,
    isForeman,
    finishedAtCustomer,
    startedWorking,
    startedDriving,
    canPayAtCustomer,
    allowMapboxNavigation,
    onStartTaxation,
    onStartRegisterMileage,
    onOpenList,
    numberOfStorages,
    onStartFinalizingEventAtCustomer,
    onOpenWorkingOnEventForm,
    onOpenDrivingToEventForm,
    onStartFinalizingEvent,
    finished,
    loadingInvoice,
    onStartPayingAtCustomer,
    onStartNavigation,
    isLoadingStorage,
  ]);
};
