import { formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, Money, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ApproveDeclarationMutationProps = {
  declarationId: Uuid;
  approvedById: Uuid;
  approvedOn: Date;
  amount: Money;
  reason: string;
};

export const ApproveDeclarationMutation = async ({
  declarationId,
  approvedOn,
  ...values
}: ApproveDeclarationMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/hrm/declarations/${declarationId}/approve`, {
    declarationId,
    approvedOn: formatDateTime(approvedOn),
    ...values,
  });

export const useApproveDeclarationMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ApproveDeclarationMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ApproveDeclarationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ApproveDeclarationMutationProps
  >({
    mutationFn: ApproveDeclarationMutation,
    throwOnError: false,
    ...options,
  });
