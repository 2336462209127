import { ReactElement, ReactNode } from 'react';
import { SquareTile } from '@bas/ui/native/atoms';
import { Uuid } from '@bas/value-objects';
import { Box, Typography } from '@bas/ui/native/base';
import { DimensionValue, TouchableWithoutFeedback } from 'react-native';

export type SquareVariantsTileProps = {
  width: DimensionValue;
  variantLabel: string | ReactNode;
  variants: {
    label: string | ReactElement;
    variantId: string;
  }[];
  onSelect: (variantId: Uuid) => Promise<void> | void;
};

const SquareVariantsTile = ({
  width,
  variants,
  variantLabel,
  onSelect,
}: SquareVariantsTileProps): ReactElement => (
  <SquareTile width={width}>
    <Box flex={1} flexDirection="column">
      <Box
        height={42}
        borderColor="titleBorder"
        borderBottomWidth={1}
        justifyContent="center"
        alignItems="center"
      >
        <Typography textAlign="center" fontWeight={700} variant="body2">
          {variantLabel}
        </Typography>
      </Box>
      <Box flex={1} flexDirection="row" alignContent="stretch" flexWrap="wrap">
        {variants.map(({ label, variantId }, index) => {
          const lastRow = index >= variants.length - 2;
          const lastOfRow = index % 2 === 1;
          return (
            <TouchableWithoutFeedback
              onPress={() => onSelect(variantId)}
              key={variantId}
            >
              <Box
                flex={1}
                alignItems="center"
                justifyContent="center"
                flexBasis="50%"
                borderColor="titleBorder"
                borderBottomWidth={lastRow ? 0 : 1}
                borderLeftWidth={lastOfRow ? 1 : 0}
              >
                <Typography textAlign="center" fontWeight={700} variant="h4">
                  {label}
                </Typography>
              </Box>
            </TouchableWithoutFeedback>
          );
        })}
      </Box>
    </Box>
  </SquareTile>
);

export default SquareVariantsTile;
