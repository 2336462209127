import { Box } from '@bas/ui/native/base';
import { ReactElement, ReactNode } from 'react';
import { useWatch } from 'react-hook-form';
import { FinancialDocumentContentFormPart } from '../FinancialDocumentContentFormPart';

export type FinancialDocumentContentFormStepProps = {
  documentType: 'quote' | 'invoice';
  prefix?: string;
  light?: boolean;
  children?: ReactNode[] | ReactNode;
};

const FinancialDocumentContentFormStep = ({
  documentType,
  prefix,
  light,
  children,
}: FinancialDocumentContentFormStepProps): ReactElement => {
  const [templateId, language] = useWatch({
    name: [`${prefix}.templateId`, `${prefix}.relation.language`],
  });

  return (
    <Box paddingHorizontal={20} height="100%">
      <FinancialDocumentContentFormPart
        documentType={documentType}
        prefix={prefix}
        light={light}
        templateId={templateId}
        language={language}
        forceMobile
      >
        {children}
      </FinancialDocumentContentFormPart>
    </Box>
  );
};

export default FinancialDocumentContentFormStep;
