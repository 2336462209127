import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type CustomerOpenedDocumentMutationProps = {
  quoteId: Uuid;
  relationId: Uuid;
  confirmationCode: string;
  clientIp: string;
  userAgent: string;
};

export const CustomerOpenedDocumentMutation = async ({
  quoteId,
  relationId,
  confirmationCode,
  ...values
}: CustomerOpenedDocumentMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/public/{tenantId}/quotes/acceptance/${quoteId}/${relationId}/${confirmationCode}/opened`,
    {
      quoteId,
      relationId,
      code: confirmationCode,
      ...values,
    },
  );

export const useCustomerOpenedDocumentMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    CustomerOpenedDocumentMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  CustomerOpenedDocumentMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    CustomerOpenedDocumentMutationProps
  >({
    mutationFn: CustomerOpenedDocumentMutation,
    throwOnError: false,
    ...options,
  });
