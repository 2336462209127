import { formatHours, parseHoursToFloat } from '@bas/shared/utils';
import { NumberTextFieldProps, TextField } from '@bas/ui/native/atoms';
import { Box, TouchableOpacity } from '@bas/ui/native/base';
import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  FieldPath,
  FieldPathValue,
  FieldValues,
  useController,
  UseFormSetFocus,
} from 'react-hook-form';
import { Modal, TextInput } from 'react-native';
import Pickers from './Pickers';

export type ReactHookFormHoursTextFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<NumberTextFieldProps, 'name' | 'value' | 'error' | 'onChangeText'> & {
  name: TName;
  updateOnBlur?: boolean;
  disableError?: boolean;
  loading?: boolean;
  setFocus?: UseFormSetFocus<TFieldValues>;
  nextField?: FieldPath<TFieldValues>;
  onSubmit?: () => void | Promise<void>;
  onFocus?: () => void;
  onBlur?: () => void;
  variant?: 'outlined' | 'standard' | 'filled';
  readonly?: boolean;
};

const ReactHookFormHoursTextField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  disabled,
  disableError,
  loading,
  setFocus,
  nextField,
  onSubmit,
  onFocus: onFocusProp,
  onBlur: onBlurProp,
  textAlign,
  readonly,
  ...props
}: ReactHookFormHoursTextFieldProps<TFieldValues, TName>): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    field: { value, onChange, ref },
    fieldState: { error, invalid },
    formState: { isSubmitting },
  } = useController<TFieldValues, TName>({
    name,
  });

  const currentValue = useRef(value);
  const [minutes, setMinutes] = useState<number>();
  const [hours, setHours] = useState<number>();
  const fieldError = error?.message;
  const showError = !disableError && invalid;
  const inputRef = useRef<TextInput | null>(null);

  const textFieldArgs = useMemo(
    () => ({
      validationMessage: showError ? fieldError : undefined,
      enableErrors: true,
      error: showError ? !!fieldError : undefined,
      disabled: disabled ?? isSubmitting,
    }),
    [showError, fieldError, disabled, isSubmitting],
  );

  useEffect(() => {
    if (inputRef.current) {
      ref(inputRef.current);
    }
  }, [ref, inputRef]);

  const content = (
    <TextField
      value={formatHours(value)}
      variant={props.variant}
      textAlign={textAlign || 'right'}
      light={props.light}
      label={props.label}
      editable={false}
      readOnly
      {...textFieldArgs}
      pointerEvents="none"
    />
  );

  useEffect(() => {
    const [newHours, newMinutes] = formatHours(value).split(':');

    setHours(Number(newHours));
    setMinutes(Number(newMinutes));
    currentValue.current = value;
  }, [value]);

  const processChange = useCallback(
    (newHours: number, newMinutes: number) => {
      if (newHours === undefined || newMinutes === undefined) {
        return;
      }

      const newValue = parseHoursToFloat(
        `${newHours}:${newMinutes}`,
      ) as FieldPathValue<TFieldValues, TName>;

      if (newValue !== currentValue.current) {
        onChange(newValue);
      }
    },
    [onChange],
  );

  const sectionItems = useMemo(
    () => ({
      hours: new Array(999).fill(0).map((_, i) => ({
        value: i,
        label: i.toString(),
      })),
      minutes: new Array(60).fill(0).map((_, i) => ({
        value: i,
        label: i.toString(),
      })),
    }),
    [],
  );

  const pickers = useMemo(
    () => (
      <Pickers
        hours={hours}
        minutes={minutes}
        sectionItems={sectionItems}
        onClose={() => setIsOpen(false)}
        onSubmit={(newHours, newMinutes) => {
          processChange(newHours, newMinutes);
          setIsOpen(false);
        }}
      />
    ),
    [processChange, hours, minutes, sectionItems],
  );

  if (readonly) {
    return content;
  }

  return (
    <>
      <Modal
        visible={isOpen}
        transparent
        animationType="none"
        supportedOrientations={[
          'portrait',
          'portrait-upside-down',
          'landscape',
          'landscape-left',
          'landscape-right',
        ]}
        onRequestClose={() => {
          setIsOpen(false);
        }}
      >
        <Box
          height="100%"
          width="100%"
          paddingHorizontal={20}
          flex={1}
          justifyContent="center"
          alignItems="center"
        >
          <Box
            height={300}
            alignItems="center"
            backgroundColor="white"
            borderRadius={20}
          >
            {pickers}
          </Box>
        </Box>
      </Modal>
      <TouchableOpacity onPress={() => setIsOpen(true)}>
        {content}
      </TouchableOpacity>
    </>
  );
};

export default ReactHookFormHoursTextField;
