import type {
  FurnitureType,
  PublicFurnitureTypeVariant,
} from '@bas/shared/models';
import type { MovingJobFurniture } from './MovingJobFurniture';

export interface MovingJobStandardFurniture extends MovingJobFurniture {
  furnitureType: FurnitureType;
  furnitureVariant?: PublicFurnitureTypeVariant;
}
