import { WeekHours } from '@bas/hrm-domain/models';
import * as Yup from 'yup';

export type WorkingHoursInputType = {
  week1Hours: WeekHours;
  week2Hours: WeekHours;
};

export const workingHoursInputTypeDefaultValues =
  (): WorkingHoursInputType => ({
    week1Hours: {
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
    },
    week2Hours: {
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
    },
  });

export const workingHoursInputTypeValidationBuilder =
  (): Yup.ObjectSchema<WorkingHoursInputType> =>
    Yup.object({
      week1Hours: Yup.object({
        monday: Yup.number().required(),
        tuesday: Yup.number().required(),
        wednesday: Yup.number().required(),
        thursday: Yup.number().required(),
        friday: Yup.number().required(),
        saturday: Yup.number().required(),
        sunday: Yup.number().required(),
      }).required(),
      week2Hours: Yup.object({
        monday: Yup.number().required(),
        tuesday: Yup.number().required(),
        wednesday: Yup.number().required(),
        thursday: Yup.number().required(),
        friday: Yup.number().required(),
        saturday: Yup.number().required(),
        sunday: Yup.number().required(),
      }).required(),
    });
