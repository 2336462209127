import { Box, Typography } from '@bas/ui/native/base';
import { RenderRules } from '@jonasmerlin/react-native-markdown-display';
import MarkdownIt from 'markdown-it';
import customFence from 'markdown-it-container';

export const breakLineRules: RenderRules = {
  container_breakLine: (node, children, parent, styles) => (
    <Box key={node.key}>
      <Typography>{'  '}</Typography>
      {children}
    </Box>
  ),
};

export default function breakLine(md: MarkdownIt) {
  return customFence(md, 'breakLine', {
    marker: '<>',
    validate: () => true,
  });
}
