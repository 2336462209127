import { ErrorResponse, Uuid } from '@bas/value-objects';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ConnectConversationToRelationMutationProps = {
  conversationId: Uuid;
  relationId: Uuid;
};

export const ConnectConversationToRelationMutation = async ({
  conversationId,
  relationId,
  ...params
}: ConnectConversationToRelationMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/chat/conversations/${conversationId}/connect-to-relation`,
    {
      conversationId,
      relationId,
    },
    { params },
  );

export const useConnectConversationToRelationMutation = (): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ConnectConversationToRelationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ConnectConversationToRelationMutationProps
  >({
    mutationFn: ConnectConversationToRelationMutation,
    throwOnError: false,
  });
