import {
  CustomFurnitureInputType,
  CustomFurnitureInputTypeDefaultValues,
  CustomFurnitureInputTypeValidatorBuilder,
} from '@bas/taxation-tool-domain/input-types';
import { BottomSheetMethods, Box } from '@bas/ui/native/base';
import {
  ReactHookFormCheckbox,
  ReactHookFormNumberTextField,
  ReactHookFormTextField,
} from '@bas/ui/native/molecules';
import { FormBottomSheet } from '@bas/ui/native/templates';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import * as React from 'react';
import { ReactElement, RefObject, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Platform } from 'react-native';
import styles from './styles';

export type AddCustomFurnitureBottomSheetProps = {
  onAddCustomFurniture: (customFurniture: CustomFurnitureInputType) => void;
  bottomSheetRef: RefObject<BottomSheetModal & BottomSheetMethods>;
};

const Form = (): ReactElement => {
  const { formatMessage } = useIntl();

  return (
    <Box style={[{ marginTop: -24 }, styles.paddingHorizontal]}>
      <Box pt={3}>
        <ReactHookFormTextField
          name="label"
          label={formatMessage({ id: 'label.name' })}
          light
          autoFocus={Platform.OS !== 'web'}
        />
      </Box>
      <Box pt={3}>
        <ReactHookFormNumberTextField
          name="cubicMeter"
          light
          autoComplete="off"
          label={formatMessage({
            id: 'label.volume',
          })}
          keyboardType="decimal-pad"
          suffix="m³"
          fractionDigits={2}
          textAlign="left"
        />
      </Box>
      <Box pt={3}>
        <ReactHookFormCheckbox
          name="servicesPossible"
          label={formatMessage({ id: 'label.servicesPossible' })}
          desktop
          size={24}
        />
      </Box>
    </Box>
  );
};

const AddCustomFurnitureBottomSheet = ({
  onAddCustomFurniture,
  bottomSheetRef,
}: AddCustomFurnitureBottomSheetProps): ReactElement => {
  const handleFinalize = useCallback(() => {
    bottomSheetRef?.current?.close();
    bottomSheetRef?.current?.forceClose();
    bottomSheetRef?.current?.dismiss();
  }, [bottomSheetRef]);

  return (
    <FormBottomSheet<CustomFurnitureInputType>
      bottomSheetRef={bottomSheetRef}
      onSubmit={onAddCustomFurniture}
      defaultValues={{ ...CustomFurnitureInputTypeDefaultValues() }}
      useProvider
      resetFormOnOpen
      onCancel={handleFinalize}
      validationSchema={CustomFurnitureInputTypeValidatorBuilder}
    >
      <Form />
    </FormBottomSheet>
  );
};

export default AddCustomFurnitureBottomSheet;
