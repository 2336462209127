import { formatDate, formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type PlanPickingUpOfBoxesMutationProps = {
  projectId: Uuid;
  start: Date;
  end: Date;
  travelStart?: Date | null;
  travelEnd?: Date | null;
  employeeIds: Uuid[];
  notes?: string | null;
  recurringInformation?: {
    isRecurring: boolean;
    rrule: string;
    repeatFrom: Date;
    repeatUntil: Date;
  };
};

export const PlanPickingUpOfBoxesMutation = async ({
  projectId,
  start,
  end,
  travelStart,
  travelEnd,
  employeeIds,
  recurringInformation,
  ...values
}: PlanPickingUpOfBoxesMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/moving-jobs/${projectId}/plan-picking-up-of-boxes`,
    {
      projectId,
      start: formatDateTime(start),
      end: formatDateTime(end),
      travelStart: travelStart ? formatDateTime(travelStart) : null,
      travelEnd: travelEnd ? formatDateTime(travelEnd) : null,
      employeeIds: employeeIds.filter((employeeId) => !!employeeId),
      recurringInformation: recurringInformation
        ? {
            ...recurringInformation,
            repeatFrom: formatDate(recurringInformation.repeatFrom),
            repeatUntil: formatDate(recurringInformation.repeatUntil),
          }
        : null,
      ...values,
    },
  );

export const usePlanPickingUpOfBoxesMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    PlanPickingUpOfBoxesMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  PlanPickingUpOfBoxesMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    PlanPickingUpOfBoxesMutationProps
  >({
    mutationFn: PlanPickingUpOfBoxesMutation,
    throwOnError: false,
    ...options,
  });
