import { isQrCode, isStorageQrCode } from '@bas/shared/models';
import { Alert, Skeleton } from '@bas/ui/native/atoms';
import { Box, Typography } from '@bas/ui/native/base';
import { QrCodeScanner } from '@bas/ui/native/organisms';
import { PositionStorageForPickupInputType } from '@bas/wms-domain/input-types';
import {
  useStorageByStorageIdRequestQuery,
  useStorageLocationByStorageLocationIdRequestQuery,
  useWarehouseByWarehouseIdRequestQuery,
} from '@bas/wms-domain/requests';
import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import type { Code } from 'react-native-vision-camera';

const PositionStorageForPickupForm = (): ReactElement => {
  const selectedStorageId = useWatch<
    PositionStorageForPickupInputType,
    'selectedStorageId'
  >({
    name: 'selectedStorageId',
  });

  const { setValue } = useFormContext<PositionStorageForPickupInputType>();

  const { data: storageData, isFetching: isLoadingStorage } =
    useStorageByStorageIdRequestQuery(
      {
        storageId: selectedStorageId || '',
      },
      {
        enabled: !!selectedStorageId,
        gcTime: 0,
      },
    );

  const selectedStorage = useMemo(() => {
    if (storageData?.data === undefined) {
      return null;
    }

    return storageData?.data;
  }, [storageData?.data]);

  const { data: warehouseData, isFetching: isLoadingWarehouse } =
    useWarehouseByWarehouseIdRequestQuery(
      {
        warehouseId: selectedStorage?.warehouseId || '',
      },
      {
        enabled: !!selectedStorage?.warehouseId,
      },
    );

  const { data: currentLocationData, isFetching: isLoadingCurrentLocation } =
    useStorageLocationByStorageLocationIdRequestQuery(
      {
        storageLocationId: selectedStorage?.storageLocationId || '',
      },
      {
        enabled: !!selectedStorage?.storageLocationId,
      },
    );

  const currentLocation = useMemo(() => {
    if (currentLocationData?.data === undefined) {
      return null;
    }

    return currentLocationData?.data;
  }, [currentLocationData?.data]);

  const warehouse = useMemo(() => {
    if (warehouseData?.data === undefined) {
      return null;
    }

    return warehouseData?.data;
  }, [warehouseData?.data]);

  useEffect(() => {
    if (selectedStorageId && selectedStorage?.storageId === selectedStorageId) {
      setValue('currentWarehouseId', selectedStorage.warehouseId, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
      setValue('currentLocationId', selectedStorage.storageLocationId, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
    }
  }, [selectedStorageId, selectedStorage, setValue]);

  const handleScannedCode = useCallback(
    (code: Code) => {
      const parsed = JSON.parse(code.value || '{}');
      if (!isQrCode(parsed)) {
        return false;
      }

      if (isStorageQrCode(parsed)) {
        if (selectedStorage?.storageId !== parsed.storageId) {
          setValue('selectedStorageId', parsed.storageId, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          });

          return true;
        }
      }

      return false;
    },
    [setValue, selectedStorage?.storageId],
  );

  const qrCodeScanner = useMemo(
    () => <QrCodeScanner onScannedCode={handleScannedCode} />,
    [handleScannedCode],
  );

  return (
    <Box flex={1} flexDirection="row" flexWrap="wrap" height="100%">
      <Box flexBasis="100%" pb={3}>
        <Typography
          color="white"
          variant="h4"
          fontWeight={700}
          textAlign="center"
        >
          <FormattedMessage id="label.positionStorageForPickup" />
        </Typography>
        <Typography variant="h5" color="white" textAlign="center">
          {!selectedStorageId && (
            <FormattedMessage id="label.noStorageSelected" />
          )}
          {!selectedStorage && selectedStorageId && isLoadingStorage && (
            <Skeleton light width={400} height={20} />
          )}

          {!isLoadingStorage &&
            !!selectedStorage &&
            `${selectedStorage.contentOwnerName} (${selectedStorage.storageCode})`}
        </Typography>
      </Box>
      <Box
        height={{
          phone: '40%',
          tablet: '60%',
        }}
        width="100%"
      >
        {qrCodeScanner}
      </Box>
      <Box pt={3} width="100%" paddingHorizontal={20}>
        {!selectedStorageId && (
          <Box width="100%" flex={1}>
            <Alert severity="info">
              <FormattedMessage id="label.scanAQrCodeOfAStorage" />
            </Alert>
          </Box>
        )}

        {selectedStorage && (
          <Box flexDirection="row" width="100%" flexWrap="wrap">
            {!selectedStorage.warehouseId && (
              <Box width="100%">
                <Alert severity="warning">
                  <FormattedMessage id="label.storageIsNotInAWarehouse" />
                </Alert>
              </Box>
            )}
            {selectedStorage.warehouseId &&
              !selectedStorage.storageLocationId && (
                <Box width="100%" pb={3}>
                  <Alert severity="warning">
                    <FormattedMessage id="label.storageIsAlreadyPositionedForPickup" />
                  </Alert>
                </Box>
              )}
            {selectedStorage.warehouseId && (
              <Box width="100%" pr={1}>
                <Typography variant="subtitle1" color="white">
                  <FormattedMessage id="label.currentLocation" />
                </Typography>
                {selectedStorage.warehouseId && isLoadingWarehouse && (
                  <Skeleton light width={400} height={20} />
                )}
                {!isLoadingWarehouse && !!warehouse && (
                  <Typography variant="body1" color="white">
                    <FormattedMessage id="label.warehouse" />: {warehouse.name}
                  </Typography>
                )}

                {selectedStorage.storageLocationId &&
                  isLoadingCurrentLocation && (
                    <Skeleton light width={400} height={20} />
                  )}

                {currentLocation && (
                  <>
                    <Typography variant="body1" color="white">
                      {currentLocation.name} ({currentLocation.code})
                    </Typography>
                    <Typography variant="body1" color="white">
                      {currentLocation.description}
                    </Typography>
                  </>
                )}
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PositionStorageForPickupForm;
