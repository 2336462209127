import { formatDate } from '@bas/shared/utils';
import { ErrorResponse, Money, NullableMoney, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type AddCostsToProjectMutationProps = {
  projectId: Uuid;
  costId: Uuid;
  costTypeId: Uuid;
  useDate?: Date | null;
  name: string;
  note?: string | null;
  expectedPurchasePricePerUnit: Money | NullableMoney;
  expectedQuantity?: number | null;
  realizedPurchasePricePerUnit: Money | NullableMoney;
  realizedQuantity?: number | null;
};

export const AddCostsToProjectMutation = async ({
  projectId,
  costId,
  useDate,
  expectedPurchasePricePerUnit,
  realizedPurchasePricePerUnit,
  ...values
}: AddCostsToProjectMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/{tenantId}/projects/${projectId}/costs/add-costs`, {
    costId,
    projectId,
    useDate: useDate ? formatDate(useDate) : null,
    expectedPurchasePricePerUnit:
      expectedPurchasePricePerUnit.amount === null
        ? { ...expectedPurchasePricePerUnit, amount: 0 }
        : expectedPurchasePricePerUnit,
    realizedPurchasePricePerUnit:
      realizedPurchasePricePerUnit.amount === null
        ? { ...realizedPurchasePricePerUnit, amount: 0 }
        : realizedPurchasePricePerUnit,
    ...values,
  });

export const useAddCostsToProjectMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddCostsToProjectMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  AddCostsToProjectMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddCostsToProjectMutationProps
  >({
    mutationFn: AddCostsToProjectMutation,
    throwOnError: false,
    ...options,
  });
