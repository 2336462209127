import { formatDate } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type RemoveEmployeeFromEventMutationProps = {
  eventId: Uuid;
  employeeId: Uuid;
  date: Date;
  updateFutureEvents: boolean;
};

export const RemoveEmployeeFromEventMutation = async ({
  eventId,
  date,
  ...values
}: RemoveEmployeeFromEventMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/events/${eventId}/remove-employee`, {
    ...values,
    date: formatDate(date),
    eventId,
  });

export const useRemoveEmployeeFromEventMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveEmployeeFromEventMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  RemoveEmployeeFromEventMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveEmployeeFromEventMutationProps
  >({
    mutationFn: RemoveEmployeeFromEventMutation,
    throwOnError: false,
    ...options,
  });
