/* eslint-disable prefer-destructuring */
import {
  ChangeTimeOffRegistrationInputType,
  RequestTimeOffInputType,
} from '@bas/hrm-domain/input-types';
import {
  GroupedHours,
  InternalTimeOffType,
  TimeOffRegistration,
  TimeOffRegistrationStatus,
  timeOffTypeGroupsOrder,
  timeOffTypeGroupsShowStatistics,
  timeOffTypeGroupsToCalculate,
  timeOffTypeGroupsWithBuildUp,
} from '@bas/hrm-domain/models';
import {
  useCancelTimeOffRegistrationMutation,
  useChangeTimeOffRegistrationMutation,
  useRequestTimeOffMutation,
} from '@bas/hrm-domain/mutations';
import { TimeOffRegistrationListItem } from '@bas/hrm-domain/native/molecules';
import {
  RequestHoursBuildUpBottomSheet,
  RequestTimeOffBottomSheet,
  TimeOffDetailsBottomSheet,
} from '@bas/hrm-domain/native/organisms';
import {
  useTimeOffRegistrationsByEmployeeIdRequest,
  useTimeOffStatisticsByEmployeeIdRequest,
  useTimeOffTypesRequest,
} from '@bas/hrm-domain/requests';
import { useEmployeeStore } from '@bas/shared/state';
import { formatHours } from '@bas/shared/utils';
import { colors } from '@bas/theme';
import { Button } from '@bas/ui/native/atoms';
import {
  BottomSheetMethods,
  Box,
  ShopifyTheme,
  TouchableOpacity,
  Typography,
} from '@bas/ui/native/base';
import { StackedCircularProgress } from '@bas/ui/native/molecules';
import { ConfirmBottomSheet } from '@bas/ui/native/templates';
import { Uuid } from '@bas/value-objects';
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { FlashList, ListRenderItemInfo } from '@shopify/flash-list';
import { useResponsiveProp } from '@shopify/restyle';
import dayjs from 'dayjs';
import * as React from 'react';
import { ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import isEqual from 'react-fast-compare';
import { FormattedMessage, useIntl } from 'react-intl';
import { RefreshControl } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { v7 } from 'uuid';

const TimeOffRegistrationsScreen = (): ReactElement => {
  const { formatMessage } = useIntl();

  const employeeId = useEmployeeStore((state) => state.employee?.employeeId);
  const [showOnlyHourTypes, setShowOnlyHourTypes] = useState<Uuid[]>([]);

  const requestTimeOffBottomSheetRef = useRef<
    BottomSheetModal & BottomSheetMethods
  >(null);
  const requestHoursBuildUpBottomSheetRef = useRef<
    BottomSheetModal & BottomSheetMethods
  >(null);
  const timeOffDetailsBottomSheetRef = useRef<
    BottomSheetModal & BottomSheetMethods
  >(null);
  const cancelTimeOffBottomSheetRef = useRef<
    BottomSheetModal & BottomSheetMethods
  >(null);

  const cancelTimeOffIdRef = useRef<Uuid>();

  const { data: timeOffStatisticsData, refetch: refetchStatistics } =
    useTimeOffStatisticsByEmployeeIdRequest({
      employeeId: employeeId as string,
      year: new Date().getFullYear(),
    });

  const { data: timeOffTypesData } = useTimeOffTypesRequest({
    perPage: 9999,
  });

  const {
    data: timeOffRegistrationsData,
    isFetching,
    refetch: refetchTimeRegistrations,
  } = useTimeOffRegistrationsByEmployeeIdRequest({
    perPage: 9999,
    employeeId: employeeId as string,
    startAfter: dayjs().startOf('year').toDate(),
    startBefore: dayjs().endOf('year').toDate(),
  });

  const timeOffTypes = useMemo(
    () => timeOffTypesData?.data.member || [],
    [timeOffTypesData?.data],
  );

  const timeOffRegistrations = useMemo(
    () => timeOffRegistrationsData?.data.member || [],
    [timeOffRegistrationsData?.data],
  );

  const filteredTimeOffRegistrations = useMemo(() => {
    const result = timeOffRegistrations.sort((a, b) =>
      a.start > b.start ? -1 : 1,
    );

    if (showOnlyHourTypes.length === 0) {
      return result;
    }

    return result.filter(
      (item) =>
        showOnlyHourTypes.includes(item.timeOffTypeId) &&
        (item.status !== TimeOffRegistrationStatus.CANCELLED ||
          dayjs(item.start).isAfter(dayjs().subtract(1, 'month'))),
    );
  }, [showOnlyHourTypes, timeOffRegistrations]);

  const statistics = useMemo(
    () => timeOffStatisticsData?.data,
    [timeOffStatisticsData?.data],
  );

  const calculateValue = useCallback(
    (groupedHours: GroupedHours[], key: keyof GroupedHours) =>
      groupedHours.reduce((total, item) => {
        if (timeOffTypeGroupsToCalculate.includes(item.group)) {
          return total + (item[key] as number);
        }

        return total;
      }, 0),
    [],
  );

  const totalHoursBuildUp = useMemo(() => {
    if (!statistics) {
      return 0;
    }

    return calculateValue(statistics.groupedHours, 'buildUpHours');
  }, [calculateValue, statistics]);

  const totalUsedHours = useMemo(() => {
    if (!statistics) {
      return 0;
    }

    return calculateValue(statistics.groupedHours, 'usedHours');
  }, [calculateValue, statistics]);

  const totalReservedHours = useMemo(() => {
    if (!statistics) {
      return 0;
    }

    return calculateValue(statistics.groupedHours, 'reservedHours');
  }, [calculateValue, statistics]);

  const totalRequestedBuildUp = useMemo(() => {
    if (!statistics) {
      return 0;
    }

    return calculateValue(statistics.groupedHours, 'requestedBuildUpHours');
  }, [calculateValue, statistics]);

  const usedHoursPercentage = (totalUsedHours / totalHoursBuildUp) * 100;
  const reservedHoursPercentage =
    (totalReservedHours / totalHoursBuildUp) * 100;
  const requestedBuildUpPercentage =
    (totalRequestedBuildUp / totalHoursBuildUp) * 100;

  const { mutateAsync: requestTimeOff } = useRequestTimeOffMutation();
  const { mutateAsync: cancelTimeOff } = useCancelTimeOffRegistrationMutation();
  const { mutateAsync: changeTimeOff } = useChangeTimeOffRegistrationMutation();

  const onRefresh = useCallback(() => {
    refetchTimeRegistrations();
    refetchStatistics();
  }, [refetchTimeRegistrations, refetchStatistics]);

  const handleFilterTimeOffRegistrations = useCallback(
    (internalTimeOffTypes: InternalTimeOffType[]) => {
      let resultingTypes: Uuid[] = [];

      resultingTypes = resultingTypes.concat(
        timeOffTypes
          .filter((item) =>
            internalTimeOffTypes.includes(item.internalTimeOffType),
          )
          .map((item) => item.timeOffTypeId),
      );

      setShowOnlyHourTypes((prev) => {
        if (isEqual(resultingTypes, prev)) {
          resultingTypes = [];
        }

        if (prev.length === 0 && resultingTypes.length === 0) {
          return prev;
        }

        return resultingTypes;
      });
    },
    [timeOffTypes],
  );

  const handleRequestTimeOff = useCallback(
    async (values: RequestTimeOffInputType) => {
      await requestTimeOff({
        start: values.start as Date,
        end: values.end as Date,
        fullDay: values.fullDay || false,
        reason: values.reason,
        timeOffTypeId: values.timeOffTypeId,
        effectiveHours: values.effectiveHours,
        buildUp: false,
        timeOffId: v7(),
        employeeId: employeeId as string,
        daysOff: values.daysOff as {
          start: Date;
          end: Date;
          hours: number;
        }[],
      });
    },
    [employeeId, requestTimeOff],
  );

  const handleRequestHoursBuildUp = useCallback(
    async (values: RequestTimeOffInputType) => {
      await requestTimeOff({
        start: values.start as Date,
        end: values.end as Date,
        fullDay: values.fullDay || false,
        reason: values.reason,
        timeOffTypeId: values.timeOffTypeId,
        effectiveHours: values.effectiveHours,
        buildUp: true,
        timeOffId: v7(),
        employeeId: employeeId as string,
        daysOff: values.daysOff as {
          start: Date;
          end: Date;
          hours: number;
        }[],
      });

      requestHoursBuildUpBottomSheetRef.current?.dismiss();
    },
    [employeeId, requestTimeOff],
  );

  const handleChangeTimeOff = useCallback(
    async (values: ChangeTimeOffRegistrationInputType) => {
      await changeTimeOff({
        timeOffId: values.timeOffId,
        start: values.start as Date,
        end: values.end as Date,
        fullDay: values.fullDay || false,
        reason: values.reason,
        timeOffTypeId: values.timeOffTypeId,
        effectiveHours: values.effectiveHours,
        employeeId: employeeId as string,
        buildUp: values.buildUp,
        daysOff: values.daysOff as {
          dayOffId?: Uuid;
          start: Date;
          end: Date;
          hours: number;
        }[],
      });
    },
    [changeTimeOff, employeeId],
  );

  const handleCancelTimeOff = useCallback(async () => {
    await cancelTimeOff({
      timeOffId: cancelTimeOffIdRef.current as string,
    });
  }, [cancelTimeOff]);

  const handlePressTimeOffRegistration = useCallback(
    (timeOffRegistration: TimeOffRegistration) => {
      timeOffDetailsBottomSheetRef.current?.present({
        timeOffRegistration,
      });
    },
    [],
  );

  const handleRenderTimeOffRegistration = useCallback(
    ({ item }: ListRenderItemInfo<TimeOffRegistration>) => (
      <TimeOffRegistrationListItem
        timeOffRegistration={item}
        onPress={() => handlePressTimeOffRegistration(item)}
        onCancelTimeOff={() => {
          cancelTimeOffIdRef.current = item.timeOffId;
          cancelTimeOffBottomSheetRef.current?.present();
        }}
      />
    ),
    [handlePressTimeOffRegistration],
  );

  const hourStatistics = useMemo(
    () => (
      <Box flexDirection="row" justifyContent="center">
        <TouchableOpacity
          onPress={() =>
            handleFilterTimeOffRegistrations(Object.values(InternalTimeOffType))
          }
        >
          <Box paddingRight={2}>
            <StackedCircularProgress
              values={[
                {
                  value: usedHoursPercentage,
                  color: colors.green[500],
                  realValue: usedHoursPercentage,
                },
                {
                  realValue: reservedHoursPercentage,
                  value: reservedHoursPercentage + usedHoursPercentage,
                  color: colors.green[300],
                },
                {
                  realValue: requestedBuildUpPercentage,
                  value:
                    reservedHoursPercentage +
                    usedHoursPercentage +
                    requestedBuildUpPercentage,
                  color: colors.blue[500],
                },
              ].filter(({ realValue }) => realValue > 0)}
              title={formatMessage({ id: 'label.hours' })}
              titleColor={colors.lila[800]}
              titleMaxValue={totalHoursBuildUp}
              titleValue={
                totalHoursBuildUp - totalUsedHours - totalReservedHours
              }
              inActiveStrokeColor={colors.lila[400]}
              progressValueColor={colors.lila[800]}
            />
          </Box>
        </TouchableOpacity>
        <Box
          paddingLeft={2}
          flexDirection="column"
          rowGap="line"
          minWidth={{
            phone: 260,
            tablet: 300,
            largerTablet: 375,
          }}
        >
          <Box flexDirection="row" justifyContent="space-between">
            <Box>
              <Typography fontWeight={700}>
                <FormattedMessage id="label.description" />
              </Typography>
            </Box>
            <Box maxWidth={100}>
              <Typography fontWeight={700}>
                <FormattedMessage id="label.hours" />
              </Typography>
            </Box>
          </Box>
          {statistics?.groupedHours
            .sort((a, b) =>
              timeOffTypeGroupsOrder.indexOf(a.group) >
              timeOffTypeGroupsOrder.indexOf(b.group)
                ? 1
                : -1,
            )
            .filter((item) =>
              timeOffTypeGroupsShowStatistics.includes(item.group),
            )
            .map((item) => (
              <TouchableOpacity
                key={item.group}
                onPress={() =>
                  handleFilterTimeOffRegistrations(item.internalTimeOffTypes)
                }
              >
                <Box>
                  <Box flexDirection="row" justifyContent="space-between">
                    <Box>
                      <Typography>
                        <FormattedMessage id={`timeOffGroup.${item.group}`} />
                      </Typography>
                    </Box>
                    <Box maxWidth={100}>
                      <Typography>
                        {formatHours(
                          timeOffTypeGroupsWithBuildUp.includes(item.group)
                            ? item.buildUpHours
                            : item.usedHours,
                        )}
                      </Typography>
                    </Box>
                  </Box>
                  {timeOffTypeGroupsWithBuildUp.includes(item.group) &&
                    item.requestedBuildUpHours > 0 && (
                      <Box flexDirection="row" justifyContent="space-between">
                        <Box>
                          <Typography>
                            <FormattedMessage id="label.requestedBuildUpHours" />
                          </Typography>
                        </Box>
                        <Box maxWidth={100}>
                          <Typography>
                            {formatHours(item.requestedBuildUpHours)}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  {timeOffTypeGroupsWithBuildUp.includes(item.group) && (
                    <Box flexDirection="row" justifyContent="space-between">
                      <Box>
                        <Typography>
                          <FormattedMessage id="label.usedLeaveHours" />
                        </Typography>
                      </Box>
                      <Box maxWidth={100}>
                        <Typography>{formatHours(item.usedHours)}</Typography>
                      </Box>
                    </Box>
                  )}
                  <Box
                    flexDirection="row"
                    justifyContent="space-between"
                    paddingBottom={0.5}
                    borderBottomWidth={1}
                    borderBottomColor="titleBorder"
                  >
                    <Box>
                      <Typography>
                        <FormattedMessage id="label.requested" />
                      </Typography>
                    </Box>
                    <Box maxWidth={100}>
                      <Typography>{formatHours(item.reservedHours)}</Typography>
                    </Box>
                  </Box>
                </Box>
              </TouchableOpacity>
            ))}
        </Box>
      </Box>
    ),
    [
      formatMessage,
      handleFilterTimeOffRegistrations,
      requestedBuildUpPercentage,
      reservedHoursPercentage,
      statistics?.groupedHours,
      totalHoursBuildUp,
      totalReservedHours,
      totalUsedHours,
      usedHoursPercentage,
    ],
  );

  // @ts-expect-error - workaround for responsive prop
  const isTablet = useResponsiveProp<ShopifyTheme, boolean>({
    lg: true,
    largerTablet: true,
    tablet: true,
    phone: false,
  });

  return (
    <Box flex={1}>
      <Box
        width="100%"
        backgroundColor="cardBorder"
        paddingHorizontal={20}
        paddingBottom={2}
      >
        <Box
          flexDirection="row"
          width="100%"
          flexWrap="wrap"
          justifyContent="space-between"
          columnGap={2}
        >
          <Box>
            <Button
              variant="contained"
              icon={faClock}
              paddingVertical={1}
              onPress={() => requestTimeOffBottomSheetRef.current?.present()}
            >
              <FormattedMessage id="button.requestTimeOff" />
            </Button>
          </Box>
          {isTablet && <Box paddingTop={2}>{hourStatistics}</Box>}
          <Box>
            <Button
              variant="contained"
              icon={faClock}
              paddingVertical={1}
              onPress={() =>
                requestHoursBuildUpBottomSheetRef.current?.present()
              }
            >
              <FormattedMessage id="button.requestHoursBuildUp" />
            </Button>
          </Box>
        </Box>
        {!isTablet && (
          <Box flexDirection="row" width="100%" paddingTop={2}>
            {hourStatistics}
          </Box>
        )}
      </Box>
      <Box flex={1}>
        <FlashList
          renderScrollComponent={ScrollView}
          renderItem={handleRenderTimeOffRegistration}
          data={filteredTimeOffRegistrations}
          keyExtractor={(item) => item.timeOffId}
          estimatedItemSize={90}
          refreshControl={
            <RefreshControl refreshing={isFetching} onRefresh={onRefresh} />
          }
        />
      </Box>
      <RequestTimeOffBottomSheet
        bottomSheetRef={requestTimeOffBottomSheetRef}
        onSubmit={handleRequestTimeOff}
      />
      <RequestHoursBuildUpBottomSheet
        bottomSheetRef={requestHoursBuildUpBottomSheetRef}
        onSubmit={handleRequestHoursBuildUp}
      />
      <TimeOffDetailsBottomSheet
        bottomSheetRef={timeOffDetailsBottomSheetRef}
        onSubmit={handleChangeTimeOff}
      />
      <ConfirmBottomSheet
        closeOnCancel
        onConfirm={handleCancelTimeOff}
        bottomSheetRef={cancelTimeOffBottomSheetRef}
        dangerous
        title={<FormattedMessage id="label.cancelTimeOffRegistration" />}
        content={
          <FormattedMessage id="label.areYouSureYouWantToCancelTimeOffRegistration" />
        }
        successFullFormViewProps={{
          gifUrl: 'https://media.giphy.com/media/KbvZsN07K9Hy9ZoyR7/giphy.gif',
          color: colors.lila[100],
          title: 'label.timeOffRegistrationCancelled',
          labelId: 'label.timeOffRegistrationCancelledDescription',
        }}
      />
    </Box>
  );
};

export default TimeOffRegistrationsScreen;
