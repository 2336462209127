import dayjs from 'dayjs';
import * as Yup from 'yup';

export type ApproveTimeEntryInputType = {
  start?: Date | null;
  end?: Date | null;
  reaction: string | undefined;
};

export const approveTimeEntryInputTypeDefaultValues =
  (): ApproveTimeEntryInputType => ({
    reaction: '',
    start: null,
    end: null,
  });

export const approveTimeEntryInputTypeValidationBuilder =
  (): Yup.ObjectSchema<ApproveTimeEntryInputType> =>
    Yup.object({
      reaction: Yup.string().label('label.reaction'),
      start: Yup.date().required().label('label.start'),
      end: Yup.date()
        .when(['start'], ([start], schema) => {
          if (!start || !dayjs(start).isValid()) {
            return schema;
          }

          return schema
            .min(dayjs(start).clone().add(1, 'second').toDate())
            .max(dayjs(start).clone().endOf('day').toDate())
            .required();
        })
        .label('label.end'),
    });
