import { FailedFile, UploadingFile } from '@bas/media-domain/input-types';
import { useUploadFileToCreateAMediaObjectMutation } from '@bas/media-domain/mutations';
import { UploadSomePicturesStepInputType } from '@bas/taxation-tool-domain/input-types';
import { Button } from '@bas/ui/native/atoms';
import { Box } from '@bas/ui/native/base';
import { PhotoListItem } from '@bas/ui/native/molecules';
import { useWizardStore } from '@bas/ui/native/organisms';
import { useResponsiveProp } from '@shopify/restyle';
import * as ImagePicker from 'expo-image-picker';
import { ImagePickerAsset } from 'expo-image-picker/build/ImagePicker.types';
import * as React from 'react';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Platform } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';

const UploadSomePicturesStep = (): ReactElement => {
  const [failedFiles, setFailedFiles] = useState<FailedFile[]>([]);
  const [uploadingFiles, setUploadingFiles] = useState<UploadingFile[]>([]);
  const [status, requestPermission] = ImagePicker.useCameraPermissions();
  const [statusMedia, requestPermissionMedia] =
    ImagePicker.useMediaLibraryPermissions();

  const { mutateAsync: uploadFile } =
    useUploadFileToCreateAMediaObjectMutation();
  const { append, fields: photos } = useFieldArray<
    UploadSomePicturesStepInputType,
    'photos'
  >({ name: 'photos' });

  useEffect(() => {
    uploadingFiles
      .filter(({ progress, result }) => progress === 100 && !!result)
      .forEach((uploadedFile) => {
        if (!uploadedFile.result) {
          return;
        }

        const exists = photos.find(
          ({ mediaObjectId }) =>
            uploadedFile.result?.mediaObjectId === mediaObjectId,
        );

        if (!exists) {
          append(uploadedFile.result);
        }
      });
  }, [photos, append, uploadingFiles]);

  const { setBlockedNext } = useWizardStore();

  useEffect(() => {
    setBlockedNext(
      uploadingFiles.filter(({ result, progress }) => !result || progress < 100)
        .length > 0,
    );
  }, [uploadingFiles, setBlockedNext]);

  const handleUpload = useCallback(
    async (files: ImagePickerAsset[]) => {
      files.forEach((file) => {
        const fileSize = (file.fileSize || 0) / 1024 / 1024;
        if (fileSize > 45) {
          setFailedFiles((prevState) => [
            ...prevState,
            { file, reason: <FormattedMessage id="label.fileTooBig" /> },
          ]);

          return;
        }

        setUploadingFiles((prevState) => [...prevState, { file, progress: 0 }]);

        uploadFile({
          file,
          onUploadProgress: (progressEvent) => {
            setUploadingFiles((prevState) => {
              const newState = [...prevState];
              const currentFile = prevState.find((uf) => uf.file === file);
              if (currentFile) {
                newState[newState.indexOf(currentFile)] = {
                  ...currentFile,
                  progress: Math.round(
                    (progressEvent.loaded * 100) / (progressEvent.total || 0),
                  ),
                };
              } else {
                newState.push({
                  file,
                  progress: Math.round(
                    (progressEvent.loaded * 100) / (progressEvent.total || 0),
                  ),
                });
              }

              return newState;
            });
          },
        })
          .then((result) => {
            setUploadingFiles((prevState) => {
              const mediaObject = {
                mediaObjectId: result.data.mediaObjectId,
                url: result.data.contentUrl,
                contentUrl: result.data.contentUrl,
              };
              const newState = [...prevState];
              const currentFile = prevState.find((uf) => uf.file === file);
              if (currentFile) {
                newState[newState.indexOf(currentFile)] = {
                  ...currentFile,
                  progress: 100,
                  result: mediaObject,
                };
              } else {
                newState.push({
                  file,
                  progress: 100,
                  result: mediaObject,
                });
              }

              return newState;
            });
          })
          .catch((e) => {
            setFailedFiles((prevState) => [
              {
                file,
                reason: <FormattedMessage id="label.failedToUpload" />,
              },
              ...prevState,
            ]);
            setUploadingFiles((prevState) => [
              ...prevState.filter((uf) => uf.file !== file),
            ]);
          });
      });
    },
    [uploadFile],
  );

  const takePhoto = useCallback(async () => {
    if (!status) {
      return;
    }

    if (!status.granted) {
      const result = await requestPermission();
      if (!result.granted) {
        return;
      }
    }

    const photoResult = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: 1,
      allowsMultipleSelection: true,
    });

    if (photoResult.canceled) {
      return;
    }

    const files = photoResult.assets;

    await handleUpload(files);
  }, [handleUpload, requestPermission, status]);

  const pickImages = useCallback(async () => {
    if (!statusMedia) {
      return;
    }
    if (!statusMedia.granted) {
      const result = await requestPermissionMedia();
      if (!result.granted) {
        return;
      }
    }
    const pickResult = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: 1,
      allowsMultipleSelection: true,
    });

    if (pickResult.canceled) {
      return;
    }

    const files = pickResult.assets;

    await handleUpload(files);
  }, [handleUpload, requestPermissionMedia, statusMedia]);

  const paddingHorizontal = useResponsiveProp({
    tablet: 50,
    phone: 20,
  });

  return (
    <Box paddingHorizontal={paddingHorizontal} height="100%">
      <Box flexDirection="row">
        <Box flex={1}>
          <Button onPress={pickImages} variant="outlined">
            <FormattedMessage id="button.uploadPhoto" />
          </Button>
        </Box>
        {Platform.OS !== 'web' && (
          <Box pl={2} flex={1}>
            <Button onPress={takePhoto} variant="outlined">
              <FormattedMessage id="button.takePhoto" />
            </Button>
          </Box>
        )}
      </Box>
      <Box height="100%">
        <ScrollView
          style={{
            height: '100%',
          }}
        >
          <Box
            flexDirection="row"
            flexWrap="wrap"
            pt={3}
            style={{ paddingBottom: 120 }}
          >
            {failedFiles.map((failedFile, failedIndex) => {
              const firstOfRow = !(failedIndex % 2);

              return (
                <PhotoListItem
                  file={failedFile.file as ImagePickerAsset}
                  reason={failedFile.reason}
                  spacingBetweenColumns={firstOfRow}
                  // eslint-disable-next-line react/no-array-index-key
                  key={failedIndex}
                />
              );
            })}
            {uploadingFiles.map((uploadingFile, uploadingIndex) => {
              const firstOfRow = !((uploadingIndex + failedFiles.length) % 2);

              return (
                <PhotoListItem
                  file={uploadingFile.file as ImagePickerAsset}
                  progress={uploadingFile.progress}
                  result={uploadingFile.result}
                  spacingBetweenColumns={firstOfRow}
                  // eslint-disable-next-line react/no-array-index-key
                  key={uploadingIndex}
                />
              );
            })}
            {photos
              .filter(
                ({ mediaObjectId }) =>
                  !uploadingFiles.find(
                    ({ result }) => result?.mediaObjectId === mediaObjectId,
                  ),
              )
              .map((photo, index) => {
                const firstOfRow = !(
                  (index + failedFiles.length + uploadingFiles.length) %
                  2
                );

                return (
                  <PhotoListItem
                    key={photo.id}
                    result={photo}
                    progress={100}
                    spacingBetweenColumns={firstOfRow}
                  />
                );
              })}
          </Box>
        </ScrollView>
      </Box>
    </Box>
  );
};

export default UploadSomePicturesStep;
