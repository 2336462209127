import { MediaObject } from '@bas/media-domain/models';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type AddPicturesToDamageMutationProps = {
  damageId: Uuid;
  pictures: MediaObject[];
};

export const AddPicturesToDamageMutation = async ({
  damageId,
  ...values
}: AddPicturesToDamageMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/damages/${damageId}/add-pictures`, {
    damageId,
    ...values,
  });

export const useAddPicturesToDamageMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddPicturesToDamageMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  AddPicturesToDamageMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddPicturesToDamageMutationProps
  >({
    mutationFn: AddPicturesToDamageMutation,
    throwOnError: false,
    ...options,
  });
