/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';

export const flattenObject = <T>(obj: T, prefix = ''): { [key: string]: any } =>
  _.transform(
    obj as unknown as _.Dictionary<any> | _.NumericDictionary<any>,
    (result, value, key) => {
      const currentKey = prefix ? `${prefix}.${key}` : key;
      if (_.isPlainObject(value)) {
        _.assign(result, flattenObject(value, currentKey));
      } else {
        // eslint-disable-next-line no-param-reassign
        result[currentKey] = value;
      }
    },
    {} as { [key: string]: any },
  );
