import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type EnableTimeOffTypeMutationProps = {
  timeOffTypeId: Uuid;
};

export const EnableTimeOffTypeMutation = async ({
  timeOffTypeId,
  ...values
}: EnableTimeOffTypeMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/hrm/time-off-types/${timeOffTypeId}/enable`, {
    timeOffTypeId,
    ...values,
  });

export const useEnableTimeOffTypeMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    EnableTimeOffTypeMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  EnableTimeOffTypeMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    EnableTimeOffTypeMutationProps
  >({
    mutationFn: EnableTimeOffTypeMutation,
    throwOnError: false,
    ...options,
  });
