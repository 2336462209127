import { isProjectHoursEntry } from '@bas/hrm-domain/models';
import { useHourEntriesByProjectIdRequest } from '@bas/hrm-domain/requests';
import { isProjectEvent } from '@bas/planning-domain/models';
import {
  useEventsByProjectIdRequest,
  useMileageReportsByProjectIdRequest,
  useVehiclesRequest,
} from '@bas/planning-domain/requests';
import {
  useDamagesRequest,
  useProjectByProjectIdRequest,
} from '@bas/project-domain/requests';
import { Box, Typography } from '@bas/ui/native/base';
import { ProjectNotesContentSection } from '@bas/ui/native/molecules';
import { Uuid } from '@bas/value-objects';
import { ReactElement, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { DamagesAccordion } from '../DamagesAccordion';
import { ProjectHoursForInvoicing } from '../ProjectHoursForInvoicing';
import { ProjectOutstandingInventoryItems } from '../ProjectOutstandingInventoryItems';

export type ProjectDataForInvoiceProps = {
  projectId: Uuid;
  light?: boolean;
  className?: string;
};

const ProjectDataForInvoice = ({
  projectId,
  light,
  className,
}: ProjectDataForInvoiceProps): ReactElement => {
  const { data: projectHoursData, isPending: isLoadingProjectHours } =
    useHourEntriesByProjectIdRequest({
      projectId,
      perPage: 9999,
    });

  const { data: projectData } = useProjectByProjectIdRequest({
    projectId,
  });

  const { data: projectEventsData, isPending: isLoadingProjectEvents } =
    useEventsByProjectIdRequest({
      projectId,
      perPage: 9999,
    });

  const { data: projectDamagesData } = useDamagesRequest({
    projectId,
    perPage: 9999,
  });

  const { data: materialsData } = useVehiclesRequest({
    perPage: 1000,
  });

  const { data: projectMileageReportsData } =
    useMileageReportsByProjectIdRequest({
      projectId,
      perPage: 9999,
    });

  const projectEvents = useMemo(
    () => (projectEventsData?.data.member || []).filter(isProjectEvent),
    [projectEventsData?.data],
  );

  const projectHours = useMemo(
    () => (projectHoursData?.data.member || []).filter(isProjectHoursEntry),
    [projectHoursData?.data],
  );

  const projectDamages = useMemo(
    () => projectDamagesData?.data.member || [],
    [projectDamagesData?.data],
  );

  const projectMileageReports = useMemo(
    () => projectMileageReportsData?.data.member || [],
    [projectMileageReportsData?.data],
  );

  const materials = useMemo(
    () => materialsData?.data.member || [],
    [materialsData?.data],
  );

  const project = useMemo(() => projectData?.data, [projectData?.data]);

  const mileagesPerVehicle = useMemo(() => {
    const result: { [key: string]: { name: string; mileage: number } } = {};

    projectMileageReports.forEach(
      ({ materialId, mileageStart, mileageEnd }) => {
        if (mileageEnd !== null && mileageEnd !== undefined) {
          if (typeof result[materialId] === 'undefined') {
            result[materialId] = {
              name:
                materials.find((material) => material.materialId === materialId)
                  ?.name || 'unknown',
              mileage: 0,
            };
          }

          result[materialId].mileage += mileageEnd - mileageStart;
        }
      },
    );

    return Object.values(result);
  }, [materials, projectMileageReports]);

  return (
    <Box rowGap={3} pb={3}>
      <Box>
        <ProjectNotesContentSection
          notes={project?.notes}
          notesForEmployees={project?.notesForEmployees}
          customerNotes={project?.customerNotes}
        />
      </Box>
      <Box>
        <ProjectHoursForInvoicing
          hoursEntries={projectHours}
          events={projectEvents}
          isLoading={isLoadingProjectEvents || isLoadingProjectHours}
        />
      </Box>
      <Box>
        <ProjectOutstandingInventoryItems
          projectId={projectId}
          light={light}
          rooms={[]}
          neededInventoryItems={[]}
          showForInvoicing
        />
      </Box>
      <Box>
        <Typography variant="subtitle1" color="subtitle1Color">
          <FormattedMessage id="label.drivenDistance" />
        </Typography>
        <Box>
          <Box
            flex={1}
            flexDirection="row"
            borderBottomWidth={0.5}
            borderBottomColor="white"
            pb={1}
          >
            <Box flexBasis="50%" flex={1}>
              <Typography color="white" fontWeight={700}>
                <FormattedMessage id="label.vehicle" />
              </Typography>
            </Box>
            <Box flexBasis="50%" flex={1}>
              <Typography color="white" fontWeight={700}>
                <FormattedMessage id="label.drivenDistance" />
              </Typography>
            </Box>
          </Box>
          <Box>
            {mileagesPerVehicle.length === 0 && (
              <Box flexBasis="100%">
                <Typography color="white">
                  <FormattedMessage id="label.noKnownMileageReports" />
                </Typography>
              </Box>
            )}
            {mileagesPerVehicle.map(({ name, mileage }) => (
              <Box
                key={name}
                flex={1}
                flexDirection="row"
                pt={2}
                pb={2}
                borderBottomWidth={0.5}
                borderBottomColor="white"
              >
                <Box flexBasis="50%" flex={1}>
                  <Typography color="white">{name}</Typography>
                </Box>
                <Box flexBasis="50%" flex={1}>
                  <Typography color="white">{mileage}km</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box>
        <Typography variant="subtitle1" color="subtitle1Color">
          <FormattedMessage id="label.damages" />
        </Typography>
        <DamagesAccordion damages={projectDamages} />
      </Box>
    </Box>
  );
};

export default ProjectDataForInvoice;
