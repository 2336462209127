import { Box, BoxProps } from '@bas/ui/native/base';
import { ReactElement } from 'react';
import { StyleSheet } from 'react-native';

export type BlueSelectButtonsGroupProps = BoxProps & {
  buttons: ReactElement[];
};

const styles = StyleSheet.create({
  buttonWithPadding: {
    paddingLeft: 18,
  },
});

const BlueSelectButtonsGroup = ({
  buttons,
  style,
  ...props
}: BlueSelectButtonsGroupProps): ReactElement => (
  <Box flexDirection="row" flexWrap="wrap" style={[style]} {...props}>
    {buttons.map((button, index) => {
      const first = index === 0;
      return (
        <Box
          flex={1}
          height={105}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          style={[first ? undefined : styles.buttonWithPadding]}
        >
          {button}
        </Box>
      );
    })}
  </Box>
);

export default BlueSelectButtonsGroup;
