import { Money, NullableMoney } from '@bas/value-objects';
import * as Yup from 'yup';

export interface ProjectRevenueInputType {
  expectedRevenue: Money | NullableMoney;
  realizedRevenue: Money | NullableMoney;
}

export const ProjectRevenueInputTypeDefaultValues =
  (): ProjectRevenueInputType => ({
    expectedRevenue: { amount: 0, currency: 'EUR' },
    realizedRevenue: { amount: 0, currency: 'EUR' },
  });

export const ProjectRevenueInputTypeValidationBuilder =
  (): Yup.ObjectSchema<ProjectRevenueInputType> =>
    Yup.object({
      expectedRevenue: Yup.object({
        amount: Yup.number()
          .transform((value, original) =>
            original === '' || original === null ? null : value,
          )
          .defined()
          .nullable()
          .label('label.expectedRevenue'),
        currency: Yup.string().required(),
      }),
      realizedRevenue: Yup.object({
        amount: Yup.number()
          .transform((value, original) =>
            original === '' || original === null ? null : value,
          )
          .defined()
          .nullable()
          .label('label.realizedRevenue'),
        currency: Yup.string().required(),
      }),
    });
