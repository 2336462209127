import { CommunicationType } from '@bas/crm-domain/models';
import { formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type AddCommunicationMutationProps = {
  communicationId: Uuid;
  relationId: Uuid;
  projectId?: Uuid | null;
  damageId?: Uuid | null;
  communicationType: CommunicationType;
  communicationDate: Date;
  sender: string;
  message: string;
};

export const AddCommunicationMutation = async ({
  relationId,
  communicationDate,
  ...values
}: AddCommunicationMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/{tenantId}/relations/${relationId}/communications`, {
    relationId,
    communicationDate: formatDateTime(communicationDate),
    ...values,
  });

export const useAddCommunicationMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddCommunicationMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  AddCommunicationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddCommunicationMutationProps
  >({
    mutationFn: AddCommunicationMutation,
    throwOnError: false,
    ...options,
  });
