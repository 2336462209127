import { Box, Typography } from '@bas/ui/native/base';
import {
  ReactHookFormDatePickerField,
  ReactHookFormNumberTextField,
} from '@bas/ui/native/molecules';
import { VolumeType } from '@bas/value-objects';
import {
  InventoryListInputType,
  VolumeChangeInputType,
} from '@bas/wms-domain/input-types';
import * as React from 'react';
import { ReactElement } from 'react';
import { useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

type VolumeChangeFormProps = {
  action: 'load' | 'unload';
};

const VolumeChangeForm = ({ action }: VolumeChangeFormProps): ReactElement => {
  const { formatMessage } = useIntl();
  const volumeType = useWatch<VolumeChangeInputType, 'volumeType'>({
    name: 'volumeType',
  });
  const oldVolume = useWatch<VolumeChangeInputType, 'oldVolume'>({
    name: 'oldVolume',
  });
  const invoicePerVolume = useWatch<VolumeChangeInputType, 'invoicePerVolume'>({
    name: 'invoicePerVolume',
  });
  const storedItems = useWatch<InventoryListInputType, 'storedItems'>({
    name: 'storedItems',
  });

  return (
    <Box flex={1} paddingHorizontal={20}>
      <Box flex={1} flexDirection="row" flexWrap="wrap" rowGap={3}>
        <Box flexBasis="50%">
          {!!invoicePerVolume && (
            <Typography color="white">
              <FormattedMessage
                id="label.theCurrentVolumeIs"
                values={{
                  volume: oldVolume,
                  suffix: volumeType === VolumeType.CUBIC_METER ? 'm³' : 'm²',
                }}
              />
            </Typography>
          )}
        </Box>
        <Box flexBasis="50%">
          {!!invoicePerVolume && storedItems.length > 0 && action && (
            <Typography color="white">
              <FormattedMessage
                id={`label.${
                  action === 'load' ? 'loaded' : 'unloaded'
                }VolumeIs`}
                values={{
                  volume: storedItems
                    .filter((item) => {
                      if (
                        item.volume === null ||
                        item.hasError ||
                        item.delete ||
                        !item.scanned
                      ) {
                        return false;
                      }

                      if (action === 'load') {
                        return item.loadedOn !== null;
                      }

                      return item.unloadedOn !== null;
                    })
                    .reduce((acc, item) => acc + (item.volume || 0), 0),
                  suffix: volumeType === VolumeType.CUBIC_METER ? 'm³' : 'm²',
                }}
              />
            </Typography>
          )}
        </Box>
        <Box flexBasis="50%" pr={1}>
          <ReactHookFormNumberTextField<VolumeChangeInputType>
            name="newVolume"
            autoComplete="off"
            label={formatMessage({ id: 'label.newVolume' })}
            keyboardType="decimal-pad"
            suffix={volumeType === VolumeType.CUBIC_METER ? 'm³' : 'm²'}
            fractionDigits={2}
            shouldBeZero
            light
            padZero={false}
          />
        </Box>
        <Box flexBasis="25%" pr={1} pl={1}>
          <ReactHookFormDatePickerField<VolumeChangeInputType>
            name="changedOn"
            label={formatMessage({ id: 'label.changedOn' })}
            light
            mode="date"
          />
        </Box>
        <Box flexBasis="25%" pl={1}>
          <ReactHookFormDatePickerField<VolumeChangeInputType>
            name="changedOn"
            label={formatMessage({ id: 'label.time' })}
            light
            mode="time"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default VolumeChangeForm;
