import React, {
  type ComponentClass,
  RefObject,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  NativeSyntheticEvent,
  StyleSheet,
  TextInput,
  TextInputFocusEventData,
  TextInputProps,
  View,
} from 'react-native';
import Animated, {
  AnimatedProps,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

const AnimatedTextInput = Animated.createAnimatedComponent(TextInput);

export type FilledTextFieldProps = TextInputProps & {
  label?: string;
  value?: string;
  onChangeText?: (text: string) => void;
  disabled?: boolean;
  error?: boolean;
  light?: boolean;
  InputComponent?: ComponentClass<AnimatedProps<TextInputProps>>;
  labelColor: string;
  textColor: string;
  borderColor: string;
  handleFocusChange: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void;
  inputRef?: RefObject<TextInput> | null;
};

const styles = StyleSheet.create({
  filledContainer: {
    height: 56,
    backgroundColor: '#fff',
    borderColor: '#DADCE4',
    borderWidth: 1,
    borderRadius: 5,
    paddingHorizontal: 12,
    paddingTop: 18,
    paddingBottom: 8,
    justifyContent: 'center',
  },
  label: {
    position: 'absolute',
    left: 12,
  },
  input: {
    fontSize: 20,
  },
});

export const FilledTextField = ({
  label,
  value,
  onChangeText,
  disabled,
  error,
  light,
  InputComponent = AnimatedTextInput,
  onFocus,
  onBlur,
  labelColor,
  textColor,
  borderColor,
  handleFocusChange,
  inputRef,
  ...props
}: FilledTextFieldProps) => {
  const [isFocused, setIsFocused] = useState(false);

  const animationProgress = useSharedValue(!!value || isFocused ? 1 : 0);

  useEffect(() => {
    animationProgress.value = withTiming(!!value || isFocused ? 1 : 0, {
      duration: 200,
    });
  }, [value, isFocused, animationProgress]);

  const animatedLabelStyle = useAnimatedStyle(() => ({
    fontSize: interpolate(animationProgress.value, [0, 1], [20, 14]),
    top: interpolate(animationProgress.value, [0, 1], [24, 0]),
  }));

  const animatedInputStyle = useAnimatedStyle(() => ({
    marginTop: interpolate(animationProgress.value, [0, 1], [26, 16]),
  }));

  const handleFocusInternal = useCallback(
    (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      setIsFocused(true);
      handleFocusChange?.(e);
      onFocus?.(e);
    },
    [handleFocusChange, onFocus],
  );

  const handleBlurInternal = useCallback(
    (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      setIsFocused(false);
      handleFocusChange?.(e);
      onBlur?.(e);
    },
    [handleFocusChange, onBlur],
  );

  return (
    <View style={styles.filledContainer}>
      {label && (
        <Animated.Text
          style={[styles.label, animatedLabelStyle, { color: labelColor }]}
        >
          {label}
        </Animated.Text>
      )}
      <InputComponent
        {...props}
        ref={inputRef}
        style={[styles.input, animatedInputStyle, { color: textColor }]}
        value={value}
        onFocus={handleFocusInternal}
        onBlur={handleBlurInternal}
        onChangeText={onChangeText}
        editable={!disabled}
      />
    </View>
  );
};

export default FilledTextField;
