import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type EnableInvoiceRemindersForRelationMutationProps = {
  relationId: Uuid;
};

export const EnableInvoiceRemindersForRelationMutation = async ({
  relationId,
  ...values
}: EnableInvoiceRemindersForRelationMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/relations/${relationId}/enable-invoice-reminders`, {
    relationId,
    ...values,
  });

export const useEnableInvoiceRemindersForRelationMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    EnableInvoiceRemindersForRelationMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  EnableInvoiceRemindersForRelationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    EnableInvoiceRemindersForRelationMutationProps
  >({
    mutationFn: EnableInvoiceRemindersForRelationMutation,
    throwOnError: false,
    ...options,
  });
