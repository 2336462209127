import * as Yup from 'yup';

export interface CreateTurnoverGroupInputType {
  name: string;
}

export const CreateTurnoverGroupInputTypeDefaultValues =
  (): CreateTurnoverGroupInputType => ({
    name: '',
  });

export const CreateTurnoverGroupInputTypeValidationBuilder =
  (): Yup.ObjectSchema<CreateTurnoverGroupInputType> =>
    Yup.object({
      name: Yup.string().required().ensure().label('label.name'),
    });
