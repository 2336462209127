import * as Yup from 'yup';
import { StorageType } from '@bas/wms-domain/models';

export type StorageSpecificationsInputType = {
  storageType: StorageType;
  storageCode: string;
  selfOwned: boolean;
  cubicMeter: number;
};

export const storageSpecificationsInputTypeDefaultValues =
  (): StorageSpecificationsInputType => ({
    storageType: StorageType.UNKNOWN,
    storageCode: '',
    selfOwned: true,
    cubicMeter: 0,
  });

export const storageSpecificationsInputTypeValidationBuilder =
  (): Yup.ObjectSchema<StorageSpecificationsInputType> =>
    Yup.object({
      storageType: Yup.mixed<StorageType>()
        .oneOf(Object.values(StorageType).filter((option) => !!option))
        .required()
        .label('label.type'),
      storageCode: Yup.string().required().label('label.storageCode'),
      selfOwned: Yup.boolean().required().label('label.isStorageSelfOwned'),
      cubicMeter: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .nullable()
        .required(),
    });
