import { useEmployeeStore } from '@bas/shared/state';
import { MobileActionButton } from '@bas/ui/native/atoms';
import { BottomSheetMethods, Box } from '@bas/ui/native/base';
import { MobileActionButtonsGroup } from '@bas/ui/native/molecules';
import { Uuid } from '@bas/value-objects';
import {
  LoadOrUnloadStorageWithInventoryInputType,
  MoveStorageInputType,
  MoveStoredItemsInputType,
  OptionalHandlingCostsInputType,
  PickUpStorageInputType,
  PositionStorageForPickupInputType,
  ReturnStorageToWarehouseInputType,
} from '@bas/wms-domain/input-types';
import {
  useAddHandlingCostsToStorageMutation,
  useAddStorageVolumeChangeMutation,
  useMoveStorageMutation,
  usePickUpStorageMutation,
  usePositionStorageForPickupMutation,
  useReturnStorageToWarehouseMutation,
  useUpdateStorageInventoryListMutation,
} from '@bas/wms-domain/mutations';
import { QrScannerBottomSheet } from '@bas/wms-domain/native/organisms';
import {
  LoadItemsIntoStorageFormWizardBottomSheet,
  MoveStorageFormWizardBottomSheet,
  MoveStoredItemsBottomSheet,
  PickUpStorageFormWizardBottomSheet,
  PositionStorageForPickupFormWizardBottomSheet,
  ReturnStorageToWarehouseFormWizardBottomSheet,
  UnloadItemsFromStorageFormWizardBottomSheet,
} from '@bas/wms-domain/storage/native/organisms';
import { faBoxOpen } from '@fortawesome/pro-light-svg-icons/faBoxOpen';
import { faBoxTaped } from '@fortawesome/pro-light-svg-icons/faBoxTaped';
import { faForklift } from '@fortawesome/pro-light-svg-icons/faForklift';
import { faPersonDolly } from '@fortawesome/pro-light-svg-icons/faPersonDolly';
import { faQrcode } from '@fortawesome/pro-light-svg-icons/faQrcode';
import { faShelves } from '@fortawesome/pro-light-svg-icons/faShelves';
import { faTruckContainer } from '@fortawesome/pro-light-svg-icons/faTruckContainer';
import { faTruckContainerEmpty } from '@fortawesome/pro-light-svg-icons/faTruckContainerEmpty';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import * as React from 'react';
import { ReactElement, useCallback, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { RefreshControl, StyleSheet } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { v7 } from 'uuid';

const styles = StyleSheet.create({
  flexContainer: {
    flex: 1,
  },
});

const MobileEmployeeWarehouseScreen = (): ReactElement => {
  const qrSheetRef = useRef<BottomSheetModal & BottomSheetMethods>(null);
  const loadItemsBottomSheetRef = useRef<BottomSheetModal & BottomSheetMethods>(
    null,
  );
  const unloadItemsBottomSheetRef = useRef<
    BottomSheetModal & BottomSheetMethods
  >(null);
  const moveStorageFormWizardBottomSheetRef = useRef<
    BottomSheetModal & BottomSheetMethods
  >(null);
  const moveItemsBottomSheetRef = useRef<BottomSheetModal & BottomSheetMethods>(
    null,
  );
  const positionStorageForPickupFormBottomSheetRef = useRef<
    BottomSheetModal & BottomSheetMethods
  >(null);
  const pickUpStorageFormBottomSheetRef = useRef<
    BottomSheetModal & BottomSheetMethods
  >(null);
  const returnStorageToWarehouseFormBottomSheetRef = useRef<
    BottomSheetModal & BottomSheetMethods
  >(null);

  const { mutateAsync: moveStorageMutation } = useMoveStorageMutation();
  const { mutateAsync: positionStorageMutation } =
    usePositionStorageForPickupMutation();
  const { mutateAsync: pickUpStorageMutation } = usePickUpStorageMutation();
  const { mutateAsync: returnStorageToWarehouseMutation } =
    useReturnStorageToWarehouseMutation();
  const { mutateAsync: updateInventoryListMutation } =
    useUpdateStorageInventoryListMutation();
  const { mutateAsync: addHandlingCostsMutation } =
    useAddHandlingCostsToStorageMutation();
  const { mutateAsync: addStorageVolumeChange } =
    useAddStorageVolumeChangeMutation();

  const handleLoadAction = useCallback(() => {
    loadItemsBottomSheetRef.current?.present({
      storageId: '0c2bd5a3-c65a-4ed8-8885-984683727965',
    });
  }, []);

  const handleUnloadAction = useCallback(() => {
    unloadItemsBottomSheetRef.current?.present({});
  }, []);

  const handleMoveItemsActions = useCallback(() => {
    moveItemsBottomSheetRef.current?.present({});
  }, []);

  const handleMoveStorageAction = useCallback(() => {
    moveStorageFormWizardBottomSheetRef.current?.present();
  }, []);

  const employeeId = useEmployeeStore((state) => state.employee?.employeeId);
  const handleSubmitLoadOrUnloadItems = useCallback(
    async ({
      invoiceHandlingCosts,
      handlingCosts,
      selectedStorageId,
      ...values
    }: LoadOrUnloadStorageWithInventoryInputType & {
      selectedStorageId?: Uuid;
    }) => {
      if (invoiceHandlingCosts && handlingCosts) {
        await addHandlingCostsMutation({
          storageId: selectedStorageId as Uuid,
          handlingCostsId: v7(),
          ...handlingCosts,
        });
      }

      if (
        values.newVolume !== values.oldVolume &&
        values.newVolume !== null &&
        values.changedOn !== null
      ) {
        await addStorageVolumeChange({
          ...values,
          storageId: selectedStorageId as Uuid,
          volumeChangeId: v7(),
          changedByEmployeeId: employeeId || '',
          newVolume: values.newVolume as number,
          changedOn: values.changedOn as Date,
        });
      }

      await updateInventoryListMutation({
        storageId: selectedStorageId as Uuid,
        storedItems: values.storedItems.filter(
          (storedItem) => storedItem.scanned,
        ),
        isUpdateOnly: true,
      });
    },
    [
      addHandlingCostsMutation,
      addStorageVolumeChange,
      employeeId,
      updateInventoryListMutation,
    ],
  );

  const handleSubmitMoveStoredItems = useCallback(
    async (values: MoveStoredItemsInputType) => {
      const changedItemsPerStorage = values.storedItems.reduce(
        (acc, storedItem) => {
          const storageId = storedItem.storageId as Uuid;
          const current = acc[storageId] || [];
          return {
            ...acc,
            [storageId]: [...current, storedItem],
          };
        },
        {} as Record<Uuid, MoveStoredItemsInputType['storedItems']>,
      );

      await Promise.all(
        Object.entries(changedItemsPerStorage).map(([storageId, storedItems]) =>
          updateInventoryListMutation({
            storageId: storageId as Uuid,
            storedItems,
            isUpdateOnly: true,
          }),
        ),
      );
    },
    [updateInventoryListMutation],
  );

  const handleSubmitMoveStorage = useCallback(
    async ({
      invoiceHandlingCosts,
      handlingCosts,
      selectedStorageId,
      ...values
    }: MoveStorageInputType & OptionalHandlingCostsInputType) => {
      if (invoiceHandlingCosts && handlingCosts) {
        await addHandlingCostsMutation({
          storageId: selectedStorageId as Uuid,
          handlingCostsId: v7(),
          ...handlingCosts,
        });
      }

      await moveStorageMutation({
        ...values,
        employeeId: values.employeeId as Uuid,
        storageId: selectedStorageId as Uuid,
      });
    },
    [moveStorageMutation, addHandlingCostsMutation],
  );

  const handleSubmitPositionStorageForPickup = useCallback(
    async ({
      invoiceHandlingCosts,
      handlingCosts,
      selectedStorageId,
      ...values
    }: PositionStorageForPickupInputType & OptionalHandlingCostsInputType) => {
      if (invoiceHandlingCosts && handlingCosts) {
        await addHandlingCostsMutation({
          storageId: selectedStorageId as Uuid,
          handlingCostsId: v7(),
          ...handlingCosts,
        });
      }

      await positionStorageMutation({
        ...values,
        employeeId: values.employeeId as Uuid,
        storageId: selectedStorageId as Uuid,
      });
    },
    [addHandlingCostsMutation, positionStorageMutation],
  );

  const handleSubmitPickUpStorage = useCallback(
    async ({
      invoiceHandlingCosts,
      handlingCosts,
      selectedStorageId,
      ...values
    }: PickUpStorageInputType & OptionalHandlingCostsInputType) => {
      if (invoiceHandlingCosts && handlingCosts) {
        await addHandlingCostsMutation({
          storageId: selectedStorageId as Uuid,
          handlingCostsId: v7(),
          ...handlingCosts,
        });
      }

      await pickUpStorageMutation({
        ...values,
        employeeId: values.employeeId as Uuid,
        storageId: selectedStorageId as Uuid,
      });
    },
    [pickUpStorageMutation, addHandlingCostsMutation],
  );

  const handleSubmitReturnStorageToWarehouse = useCallback(
    async ({
      invoiceHandlingCosts,
      handlingCosts,
      selectedStorageId,
      ...values
    }: ReturnStorageToWarehouseInputType & OptionalHandlingCostsInputType) => {
      if (invoiceHandlingCosts && handlingCosts) {
        await addHandlingCostsMutation({
          storageId: selectedStorageId as Uuid,
          handlingCostsId: v7(),
          ...handlingCosts,
        });
      }

      await returnStorageToWarehouseMutation({
        warehouseId: values.newWarehouseId as Uuid,
        storageLocationId: values.newLocationId as Uuid,
        employeeId: values.employeeId as Uuid,
        storageId: selectedStorageId as Uuid,
      });
    },
    [returnStorageToWarehouseMutation, addHandlingCostsMutation],
  );

  const { top } = useSafeAreaInsets();

  const isFetching = false;
  const onRefresh = () => {
    // eslint-disable-next-line no-console
    console.log('refresh');
  };

  return (
    <Box
      flex={1}
      paddingHorizontal={20}
      paddingTop={4}
      style={{ marginTop: top }}
    >
      <ScrollView
        style={styles.flexContainer}
        refreshControl={
          <RefreshControl refreshing={isFetching} onRefresh={onRefresh} />
        }
      >
        <Box>
          <Box>
            <MobileActionButtonsGroup
              buttons={[
                <MobileActionButton
                  icon={faQrcode}
                  onPress={() => qrSheetRef.current?.present()}
                >
                  <FormattedMessage id="button.scanQrCodes" />
                </MobileActionButton>,
                <MobileActionButton
                  icon={faPersonDolly}
                  onPress={handleMoveItemsActions}
                >
                  <FormattedMessage id="button.moveStoredItems" />
                </MobileActionButton>,
                <MobileActionButton
                  icon={faBoxTaped}
                  onPress={handleLoadAction}
                >
                  <FormattedMessage id="button.loadStorage" />
                </MobileActionButton>,
                <MobileActionButton
                  icon={faBoxOpen}
                  onPress={handleUnloadAction}
                >
                  <FormattedMessage id="button.unloadStorage" />
                </MobileActionButton>,
                <MobileActionButton
                  icon={faForklift}
                  onPress={handleMoveStorageAction}
                >
                  <FormattedMessage id="button.moveStorage" />
                </MobileActionButton>,
                <MobileActionButton
                  icon={faTruckContainerEmpty}
                  onPress={() =>
                    positionStorageForPickupFormBottomSheetRef.current?.present()
                  }
                >
                  <FormattedMessage id="button.positionStorageForPickup" />
                </MobileActionButton>,
                <MobileActionButton
                  icon={faTruckContainer}
                  onPress={() =>
                    pickUpStorageFormBottomSheetRef.current?.present()
                  }
                >
                  <FormattedMessage id="button.pickUpStorage" />
                </MobileActionButton>,
                <MobileActionButton
                  icon={faShelves}
                  onPress={() =>
                    returnStorageToWarehouseFormBottomSheetRef.current?.present()
                  }
                >
                  <FormattedMessage id="button.returnStorageToWarehouse" />
                </MobileActionButton>,
              ]}
            />
          </Box>
        </Box>
      </ScrollView>
      <LoadItemsIntoStorageFormWizardBottomSheet
        bottomSheetRef={loadItemsBottomSheetRef}
        onSubmit={handleSubmitLoadOrUnloadItems}
      />
      <UnloadItemsFromStorageFormWizardBottomSheet
        bottomSheetRef={unloadItemsBottomSheetRef}
        onSubmit={handleSubmitLoadOrUnloadItems}
      />
      <MoveStoredItemsBottomSheet
        bottomSheetRef={moveItemsBottomSheetRef}
        onSubmit={handleSubmitMoveStoredItems}
      />
      <MoveStorageFormWizardBottomSheet
        onSubmit={handleSubmitMoveStorage}
        bottomSheetRef={moveStorageFormWizardBottomSheetRef}
      />
      <PositionStorageForPickupFormWizardBottomSheet
        onSubmit={handleSubmitPositionStorageForPickup}
        bottomSheetRef={positionStorageForPickupFormBottomSheetRef}
      />
      <PickUpStorageFormWizardBottomSheet
        onSubmit={handleSubmitPickUpStorage}
        bottomSheetRef={pickUpStorageFormBottomSheetRef}
      />
      <ReturnStorageToWarehouseFormWizardBottomSheet
        onSubmit={handleSubmitReturnStorageToWarehouse}
        bottomSheetRef={returnStorageToWarehouseFormBottomSheetRef}
      />
      <QrScannerBottomSheet bottomSheetRef={qrSheetRef} />
    </Box>
  );
};

export default MobileEmployeeWarehouseScreen;
