import {
  EnterHoursInputType,
  EnterHoursInputTypeValidationBuilder,
  EventParticularsInputType,
  EventParticularsInputTypeValidationBuilder,
  PeopleWithDifferentHoursInputType,
  PeopleWithDifferentHoursInputTypeValidationBuilder,
  SignForEventInputType,
  SignForEventInputTypeValidationBuilder,
} from '@bas/planning-domain/input-types';
import { ProjectEvent } from '@bas/planning-domain/models';
import {
  ConfirmEventHoursStep,
  ConfirmEventHoursStepProps,
  RetrieveMaterialsStep,
  RetrieveMaterialsStepProps,
  SignForEventStep,
  SignForEventStepProps,
  WereTheAnyParticularsStep,
  WereTheAnyParticularsStepProps,
  WereTherePeopleWithDifferentHoursStep,
  WereTherePeopleWithDifferentHoursStepProps,
} from '@bas/planning-domain/native/organisms';
import { Damage, isMovingJob, Project } from '@bas/project-domain/models';
import { SigningMoment } from '@bas/shared/models';
import { Address, EventType, ReactHookWizardStep } from '@bas/value-objects';
import {
  InventoryMovementInputType,
  InventoryMovementInputTypeValidationBuilder,
} from '@bas/wms-domain/input-types';
import {
  MovingBox,
  PublicMovingBox,
  ReusableMaterial,
} from '@bas/wms-domain/models';
import { useMemo } from 'react';
import { FieldValues } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';

type InputType = EnterHoursInputType &
  PeopleWithDifferentHoursInputType &
  EventParticularsInputType &
  SignForEventInputType & {
    materials: InventoryMovementInputType[];
  };

export const getAllSteps = <TFieldValues extends FieldValues = FieldValues>({
  event,
  damages,
  addresses,
  project,
  reusableMaterials,
  boxes,

  signingMoment,
}: {
  event: ProjectEvent;
  damages: Damage[];
  addresses: Address[];
  project: Project;
  reusableMaterials: ReusableMaterial[];
  boxes: (MovingBox | PublicMovingBox)[];
  signingMoment?: SigningMoment;
}): {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: ReactHookWizardStep<TFieldValues, any>;
} => ({
  enterHoursStep: {
    key: 'mobileApp.finishEvent.enterHoursStep',
    title: 'mobileApp.finishEvent.enterHoursStep',
    category: 'hours',
    validationSchema: EnterHoursInputTypeValidationBuilder,
    component: ConfirmEventHoursStep,
    props: {
      startTime: event.start,
      endTime: event.end,
      realStart: event.realStart,
    },
    propsToCompare: ['startTime', 'endTime', 'realStart'],
  } as ReactHookWizardStep<TFieldValues, ConfirmEventHoursStepProps>,
  wereTherePeopleWhoHadDifferentHours: {
    key: 'mobileApp.finishEvent.wereTherePeopleWhoHadDifferentHours',
    title: 'mobileApp.finishEvent.wereTherePeopleWhoHadDifferentHours',
    category: 'hours',
    validationSchema: PeopleWithDifferentHoursInputTypeValidationBuilder,
    component: WereTherePeopleWithDifferentHoursStep,
    props: {
      event,
    },

    propsToCompare: ['event'],
  } as ReactHookWizardStep<
    TFieldValues,
    WereTherePeopleWithDifferentHoursStepProps
  >,
  retrieveMaterials: {
    key: 'mobileApp.event.retrieveMaterials.title',
    title: 'mobileApp.event.retrieveMaterials.title',
    category: 'deliver',
    validationSchema: Yup.object({
      materials: Yup.array().of(InventoryMovementInputTypeValidationBuilder()),
    }),
    component: RetrieveMaterialsStep,
    props: {
      key: 'mobileApp.event.retrieveMaterials.description',
      title: 'mobileApp.event.retrieveMaterials.description',
      projectId: project.projectId,
      neededInventoryItems:
        event.neededInventoryItems || project?.neededInventoryItems || [],
      project,
      materials: reusableMaterials,
      projectOutstandingInventoryItemsProps: {
        hideNonReusable: true,
      },
    },
  } as ReactHookWizardStep<TFieldValues, RetrieveMaterialsStepProps>,
  retrieveBoxes: {
    key: 'mobileApp.event.pickupMovingBoxes.title',
    title: 'mobileApp.event.pickupMovingBoxes.title',
    category: 'deliver',
    validationSchema: Yup.object({
      materials: Yup.array().of(InventoryMovementInputTypeValidationBuilder()),
    }),
    component: RetrieveMaterialsStep,
    props: {
      key: 'mobileApp.event.pickupMovingBoxes.description',
      title: 'mobileApp.event.pickupMovingBoxes.description',
      projectId: project.projectId,
      neededInventoryItems:
        event.neededInventoryItems || project?.neededInventoryItems || [],
      project,
      materials: boxes,
      offSetIndex: reusableMaterials.length,
      projectOutstandingInventoryItemsProps: {
        rooms: isMovingJob(project) ? project.rooms : [],
        hideReusable: true,
      },
      wornLabel: (
        <FormattedMessage id="mobileApp.event.pickupMovingBoxes.areThereBoxesWornOut" />
      ),
      wornDescription: (
        <FormattedMessage id="mobileApp.event.pickupMovingBoxes.whatIsWorn" />
      ),
    },
  } as ReactHookWizardStep<TFieldValues, RetrieveMaterialsStepProps>,
  wereTheAnyParticularsStep: {
    key: 'mobileApp.finishEvent.wereTheAnyParticularsStep',
    title: 'mobileApp.finishEvent.wereTheAnyParticularsStep',
    category: 'particulars',
    validationSchema: EventParticularsInputTypeValidationBuilder,
    component: WereTheAnyParticularsStep,
    props: {
      damages,
      addresses,
      employees: event.employees.map(({ employee }) => employee),
    },
  } as ReactHookWizardStep<TFieldValues, WereTheAnyParticularsStepProps>,
  signingStep: {
    key: 'mobileApp.finishEvent.signingStep',
    title: 'mobileApp.finishEvent.signingStep',
    category: 'signing',
    validationSchema: SignForEventInputTypeValidationBuilder,
    component: SignForEventStep,
    props: {
      event,
      signingMoment,
    },
  } as ReactHookWizardStep<TFieldValues, SignForEventStepProps>,
});

export const getStepsFilteredByEventType = <
  TFieldValues extends FieldValues = FieldValues,
>({
  eventType,
  allSteps,
}: {
  eventType: EventType;
  allSteps: {
    [key: string]: ReactHookWizardStep<TFieldValues>;
  };
}) => {
  switch (eventType) {
    case EventType.DELIVER_BOXES_EVENT:
    case EventType.POST_ROAD_SIGNS_EVENT:
    case EventType.REMOVE_ROAD_SIGNS_EVENT:
      return [allSteps.enterHoursStep, allSteps.signingStep];
    case EventType.LOAD_STORAGE_EVENT:
    case EventType.UNLOAD_STORAGE_EVENT:
    case EventType.MOVING_EVENT:
    case EventType.MOVING_LOADING_EVENT:
    case EventType.MOVING_UNLOADING_EVENT:
    case EventType.PREPARE_HOUSE_ACCORDING_TO_QUOTE_EVENT:
    case EventType.ASSEMBLE_EVENT:
    case EventType.DISASSEMBLE_EVENT:
    case EventType.MOVING_LIFT_EVENT:
      return [
        allSteps.enterHoursStep,
        allSteps.wereTherePeopleWhoHadDifferentHours,
        allSteps.retrieveMaterials,
        allSteps.retrieveBoxes,
        allSteps.wereTheAnyParticularsStep,
        allSteps.signingStep,
      ];
    case EventType.TRANSPORT_JOB_PICKUP:
    case EventType.TRANSPORT_JOB_DELIVER:
      return [
        allSteps.cargoStep,
        allSteps.enterHoursStep,
        allSteps.wereTherePeopleWhoHadDifferentHours,
        allSteps.retrieveMaterials,
        allSteps.wereTheAnyParticularsStep,
        allSteps.signingStep,
      ];
    default:
      return [
        allSteps.enterHoursStep,
        allSteps.wereTherePeopleWhoHadDifferentHours,
        allSteps.retrieveMaterials,
        allSteps.retrieveBoxes,
        allSteps.wereTheAnyParticularsStep,
        allSteps.signingStep,
      ];
  }
};

export const useFinishEventAtCustomerWizardSteps = ({
  event,
  signingMoments,
  damages,
  addresses,
  project,
  reusableMaterials,
  boxes,
}: {
  event: ProjectEvent;
  signingMoments: SigningMoment[];
  damages: Damage[];
  addresses: Address[];
  project: Project;
  reusableMaterials: ReusableMaterial[];
  boxes: (MovingBox | PublicMovingBox)[];
}) =>
  useMemo(() => {
    const { eventType } = event;
    const signingMoment = signingMoments.find(
      (moment) => moment.eventType === eventType,
    );

    const allSteps = getAllSteps<InputType>({
      event,
      damages,
      addresses,
      project,
      reusableMaterials,
      boxes,
      signingMoment,
    });

    let newSteps: ReactHookWizardStep<InputType>[] =
      getStepsFilteredByEventType<InputType>({
        eventType,
        allSteps,
      });

    if (event.employees.length === 1) {
      newSteps = newSteps.filter(
        ({ title }) =>
          title !== 'mobileApp.finishEvent.wereTherePeopleWhoHadDifferentHours',
      );
    }

    if (!signingMoment) {
      newSteps = newSteps.filter(({ category }) => category !== 'signing');
    }

    return newSteps;
  }, [
    event,
    damages,
    addresses,
    project,
    reusableMaterials,
    boxes,
    signingMoments,
  ]);
