import { TowBar } from '@bas/planning-domain/models';
import { formatDate } from '@bas/shared/utils';
import {
  ErrorResponse,
  Money,
  NullableMoney,
  PropulsionType,
  TransmissionType,
  Uuid,
} from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ChangeMovingTruckInformationMutationProps = {
  materialId: Uuid;
  vinNumber?: string | null;
  licensePlate?: string | null;
  model?: string | null;
  propulsionType?: PropulsionType;
  transmissionType?: TransmissionType;
  registeredAt?: Date | null;
  purchasedAt?: Date | null;
  towBar?: TowBar | null;
  pricePerKilometer?: Money | NullableMoney;
  pricePerHour?: Money | NullableMoney;
  cubicMeter?: number | null;
  length?: number | null;
  height?: number | null;
  width?: number | null;
  tailGateLength?: number | null;
};

export const ChangeMovingTruckInformationMutation = async ({
  materialId,
  registeredAt,
  purchasedAt,
  pricePerKilometer,
  pricePerHour,
  ...values
}: ChangeMovingTruckInformationMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/moving-trucks/${materialId}/change-information`, {
    materialId,
    registeredAt: registeredAt ? formatDate(registeredAt) : null,
    purchasedAt: purchasedAt ? formatDate(purchasedAt) : null,
    pricePerKilometer:
      !pricePerKilometer || pricePerKilometer.amount === null
        ? { ...pricePerKilometer, amount: 0 }
        : pricePerKilometer,
    pricePerHour:
      !pricePerHour || pricePerHour.amount === null
        ? { ...pricePerHour, amount: 0 }
        : pricePerHour,
    ...values,
  });

export const useChangeMovingTruckInformationMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeMovingTruckInformationMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ChangeMovingTruckInformationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeMovingTruckInformationMutationProps
  >({
    mutationFn: ChangeMovingTruckInformationMutation,
    throwOnError: false,
    ...options,
  });
