import * as Yup from 'yup';

export interface MarkInvoiceAsIrrecoverableInputType {
  reason: string;
}

export const MarkInvoiceAsIrrecoverableInputTypeDefaultValues =
  (): MarkInvoiceAsIrrecoverableInputType => ({ reason: '' });

export const MarkInvoiceAsIrrecoverableInputTypeValidationBuilder =
  (): Yup.ObjectSchema<MarkInvoiceAsIrrecoverableInputType> =>
    Yup.object({
      reason: Yup.string().ensure().required().label('label.reason'),
    });
