import {
  ReactHookForm,
  ReactHookFormProps,
  useFormSubmitState,
} from '@bas/ui/native/atoms';
import { BottomSheetMethods, Box } from '@bas/ui/native/base';
import {
  SuccessFullFormView,
  SuccessFullFormViewProps,
} from '@bas/ui/native/molecules';
import { BottomSheet, BottomSheetProps } from '@bas/ui/native/organisms';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import * as React from 'react';
import {
  memo,
  MutableRefObject,
  ReactElement,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import isEqual from 'react-fast-compare';
import {
  DefaultValues,
  FieldValues,
  useFormContext,
  useFormState,
} from 'react-hook-form';
import { MobilePageWithStepsNavigationTemplate } from '../MobilePageWithStepsNavigationTemplate';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Data = Record<string, any>;

export type FormBottomSheetProps<
  TFieldValues extends FieldValues = FieldValues,
  TData = Data,
> = Omit<BottomSheetProps, 'children' | 'backfaceVisibility'> &
  Omit<
    ReactHookFormProps<TFieldValues>,
    'style' | 'defaultValues' | 'children'
  > & {
    resetFormOnOpen?: boolean;
    onCancel?: () => void;
    successFullFormViewProps?: SuccessFullFormViewProps;
    disableScroll?: boolean;

    bottomSheetRef?: MutableRefObject<
      (BottomSheetModal<TData> & BottomSheetMethods) | null
    >;
    useData?: boolean;
    hideSubmit?: (data: TData) => boolean;
    defaultValues?: TFieldValues | ((data: TData) => TFieldValues);
    renderWithData?: (data: TData) => ReactElement;
    children?: ReactNode;
  } & (
    | {
        renderWithData: (data: TData) => ReactElement;
        children?: ReactNode;
      }
    | {
        children: ReactNode;
        renderWithData?: (data: TData) => ReactElement;
      }
  );

type ContentProps = {
  successFullFormViewProps?: SuccessFullFormViewProps;
  children: ReactNode;
  disableScroll?: boolean;
  onCancel: () => void;
  hideSubmit?: boolean;
};

const RenderContent = memo(
  ({
    successFullFormViewProps,
    children,
    onCancel,
    disableScroll,
    hideSubmit,
  }: ContentProps): ReactElement => {
    const { trigger } = useFormContext();

    const { isSubmitSuccessful, isSubmitting } = useFormState();
    const { onSubmit } = useFormSubmitState();

    return (
      <MobilePageWithStepsNavigationTemplate
        inBottomSheet
        disablePadding
        disableScroll={disableScroll}
        stepsNavigation={{
          dark: true,
          steps: [],
          allowCancel: !isSubmitSuccessful && !isSubmitting,
          allowNext: !hideSubmit && !isSubmitting,
          hideSubmit,
          allowPrevious: false,
          onNext: onSubmit,
          onPrevious: onCancel,
          onCancel,
          onClose: onCancel,

          onValidate: () => {
            if (hideSubmit) {
              return;
            }
            trigger();
          },
          isSubmit: true,
          loading: isSubmitting,
          finalize: isSubmitSuccessful,
        }}
      >
        {successFullFormViewProps ? (
          <Box paddingHorizontal={20} height="100%" flex={1}>
            <SuccessFullFormView
              isSuccessFull={isSubmitSuccessful}
              {...successFullFormViewProps}
            >
              {children}
            </SuccessFullFormView>
          </Box>
        ) : (
          <Box height="100%" flex={1}>
            {children}
          </Box>
        )}
      </MobilePageWithStepsNavigationTemplate>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps),
);

const FormBottomSheetContent = <
  TFieldValues extends FieldValues = FieldValues,
  TData = Data,
>({
  defaultValues,
  validationSchema,
  onSubmit,
  children,
  hideBackendErrors,
  successFullFormViewProps,
  disableScroll,
  onSetDirty,
  onCancel,
  data,
  hideSubmit,
}: Omit<
  FormBottomSheetProps<TFieldValues, TData>,
  'bottomSheetRef' | 'onCancel' | 'children' | 'renderWithData'
> & {
  data: TData;
  onSetDirty: (isDirty: boolean) => void;
  onCancel: () => void;
  children: ReactNode;
}) => {
  const initialValues =
    typeof defaultValues === 'function' ? defaultValues(data) : defaultValues;

  return (
    <ReactHookForm
      defaultValues={initialValues as DefaultValues<TFieldValues>}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      hideBackendErrors={hideBackendErrors}
      onDirty={onSetDirty}
      useProvider
    >
      <RenderContent
        onCancel={onCancel}
        hideSubmit={hideSubmit?.(data) || false}
        disableScroll={disableScroll}
        successFullFormViewProps={successFullFormViewProps}
      >
        {children}
      </RenderContent>
    </ReactHookForm>
  );
};

const FormBottomSheet = <
  TFieldValues extends FieldValues = FieldValues,
  TData = Data,
>({
  onCancel,
  useData,
  children,
  renderWithData,
  bottomSheetRef,
  ...props
}: FormBottomSheetProps<TFieldValues, TData>): ReactElement => {
  const isDirtyRef = useRef(false);
  const realBottomSheetRef = useRef<BottomSheetMethods>(null);
  const [bottomSheetData, setBottomSheetData] = useState<TData>({} as TData);
  const [isVisible, setIsVisible] = useState(false);

  const handleCancel = useCallback(() => {
    if (isDirtyRef.current) {
      console.log('confirm');
    }
    onCancel?.();
  }, [onCancel]);

  useImperativeHandle(
    bottomSheetRef,
    () => ({
      // Expose bottom sheet methods
      present: (data?: TData) => {
        setBottomSheetData(data || ({} as TData));
        setIsVisible(true);
        realBottomSheetRef.current?.present();
      },
      dismiss: () => {
        setIsVisible(false);
        setBottomSheetData({} as TData);
        realBottomSheetRef.current?.dismiss();
      },
      forceClose: () => realBottomSheetRef.current?.forceClose(),
      snapToPosition: (position: number | string) =>
        realBottomSheetRef.current?.snapToPosition(position),
      toggleBlockingClose: () =>
        realBottomSheetRef.current?.toggleBlockingClose(),
      close: () => realBottomSheetRef.current?.close(),
      snapToIndex: (index: number) =>
        realBottomSheetRef.current?.snapToIndex(index),
      expand: () => realBottomSheetRef.current?.expand(),
      collapse: () => realBottomSheetRef.current?.collapse(),
      enableBlockingClose: () =>
        realBottomSheetRef.current?.enableBlockingClose(),
      disableBlockingClose: () =>
        realBottomSheetRef.current?.disableBlockingClose(),
    }),
    [],
  );

  return (
    <BottomSheet
      ref={realBottomSheetRef}
      disablePadding
      enableDismissOnClose
      onDismiss={() => setIsVisible(false)}
      {...props}
    >
      {isVisible && (
        <FormBottomSheetContent<TFieldValues, TData>
          data={bottomSheetData}
          onSetDirty={(dirty) => {
            isDirtyRef.current = dirty;
            if (dirty) {
              realBottomSheetRef.current?.enableBlockingClose();
            } else {
              realBottomSheetRef.current?.disableBlockingClose();
            }
          }}
          onCancel={handleCancel}
          useData={useData}
          {...props}
        >
          {renderWithData?.(bottomSheetData)}
          {children}
        </FormBottomSheetContent>
      )}
    </BottomSheet>
  );
};

// Update the memo wrapper to work with forwardRef
const MemoComponent = memo(FormBottomSheet, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps),
) as typeof FormBottomSheet;

export default MemoComponent;
