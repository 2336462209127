import { PublicRoomType } from '@bas/shared/models';
import { WhichRoomsAreGoingToBeMovedStepInputType } from '@bas/taxation-tool-domain/input-types';
import {
  MovingJobTaxationRoom,
  MovingJobTaxationRoomInitialValues,
} from '@bas/taxation-tool-domain/models';
import { MobileTaxationStepTemplate } from '@bas/taxation-tool-domain/native/templates';
import { usePublicRoomTypesRequest } from '@bas/tenant-domain/requests';
import { RenderTranslatedName } from '@bas/ui/native/atoms';
import { BottomSheetMethods, Box } from '@bas/ui/native/base';
import {
  BackendIcon,
  SquareAddTile,
  SquareIconTile,
} from '@bas/ui/native/molecules';
import { SquareTilesList } from '@bas/ui/native/organisms';
import { faQuestion } from '@fortawesome/pro-light-svg-icons/faQuestion';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { ListRenderItemInfo } from '@shopify/flash-list';
import {
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { v7 } from 'uuid';
import { AddExtraRoomBottomSheet } from '../AddExtraRoomBottomSheet';

type ItemType =
  | (MovingJobTaxationRoom & { dataType: 'room'; index: number })
  | {
      dataType: 'addRoom';
    };

const WhichRoomsAreGoingToBeMovedStep = (): ReactElement => {
  const { setValue } =
    useFormContext<WhichRoomsAreGoingToBeMovedStepInputType>();
  const { formatMessage } = useIntl();
  const addExtraRoomRef = useRef<BottomSheetModal & BottomSheetMethods>(null);

  const { data, isLoading } = usePublicRoomTypesRequest(undefined, {
    throwOnError: true,
  });

  const roomTypes = useMemo(() => data?.data.member || [], [data]);
  const { append, fields: intakeArrayRooms } = useFieldArray<
    WhichRoomsAreGoingToBeMovedStepInputType,
    'rooms'
  >({
    name: 'rooms',
  } as never);

  const intakeValueRooms = useWatch({ name: 'rooms' });
  const intakeRooms = useMemo(
    () =>
      intakeArrayRooms.map((room, index) => ({
        ...room,
        ...intakeValueRooms[index],
      })),
    [intakeArrayRooms, intakeValueRooms],
  );

  const handleAddOrSelectRoom = useCallback(
    (
      room: PublicRoomType,
      intakeRoom?: MovingJobTaxationRoom,
      index?: number,
    ) => {
      if (intakeRoom && typeof index !== 'undefined') {
        setValue(`rooms.${index}.selected`, true);

        return;
      }

      const foundUnselectedRoomIndex = intakeRooms.findIndex(
        (i) => !i.selected && i.roomTypeId === room.roomTypeId,
      );

      if (foundUnselectedRoomIndex !== -1) {
        setValue(`rooms.${foundUnselectedRoomIndex}.selected`, true);
        addExtraRoomRef?.current?.dismiss();

        return;
      }

      const roomToAdd: MovingJobTaxationRoom = {
        roomId: v7(),
        roomTypeId: room.roomTypeId,
        ...MovingJobTaxationRoomInitialValues,
      };

      append(roomToAdd);
      addExtraRoomRef?.current?.dismiss();
    },
    [intakeRooms, append, setValue],
  );

  useEffect(() => {
    intakeRooms
      .filter(({ roomId }) => !roomId)
      .forEach((room, index) => {
        setValue(`rooms.${index}.roomId`, v7());
      });
  }, [intakeRooms, setValue]);

  useEffect(() => {
    if (roomTypes.length > intakeRooms.length) {
      const newRooms = [
        ...roomTypes.map((roomType) => {
          const foundRoom = intakeRooms.find(
            (i) => i.roomTypeId === roomType.roomTypeId,
          );
          return {
            roomId: foundRoom?.roomId || v7(),
            roomTypeId: roomType.roomTypeId,
            ...MovingJobTaxationRoomInitialValues,
            selected: foundRoom?.selected || false,
            finished: foundRoom?.finished || false,
            ...(foundRoom || {}),
          };
        }),
      ];

      intakeRooms.forEach((intakeRoom) => {
        const foundRoom = newRooms.find((i) => i.roomId === intakeRoom.roomId);
        if (!foundRoom) {
          newRooms.push({ ...intakeRoom, roomId: intakeRoom.roomId || v7() });
        }
      });

      setValue('rooms', newRooms);
    }
  }, [roomTypes, intakeRooms, setValue]);

  const rooms = useMemo(
    () => [
      {
        dataType: 'addRoom',
      },
      ...intakeRooms.map((room, index) => ({
        ...room,
        index,
        dataType: 'room',
      })),
    ],
    [intakeRooms],
  );

  const handleRenderItem = useCallback(
    ({ item, index }: ListRenderItemInfo<ItemType>) => {
      if (item.dataType === 'addRoom') {
        return (
          <SquareAddTile
            onPress={() => addExtraRoomRef?.current?.present()}
            label={formatMessage({ id: 'mobileApp.intake.movingJob.addRoom' })}
            width="100%"
          />
        );
      }

      if (item.dataType === 'room') {
        const room = roomTypes.find(
          ({ roomTypeId }) => roomTypeId === item.roomTypeId,
        );

        return (
          <SquareIconTile
            key={item.roomId}
            width="100%"
            selected={item.selected}
            onPress={() =>
              setValue(`rooms.${item.index}.selected`, !item.selected)
            }
            label={
              item.name || (
                <RenderTranslatedName
                  fontWeight={700}
                  translations={room?.translatedNames || []}
                  fallback={room?.roomTypeName}
                />
              )
            }
            icon={
              room?.icon ? (
                <BackendIcon icon={room.icon} size={61} />
              ) : (
                faQuestion
              )
            }
          />
        );
      }

      return null;
    },
    [formatMessage, roomTypes, setValue],
  );

  return (
    <MobileTaxationStepTemplate
      primary={
        <FormattedMessage id="mobileApp.intake.movingJob.whatRoomsNeedToBeMoved" />
      }
      secondary={<FormattedMessage id="label.rooms" />}
      disableScroll
      disableContentPadding
    >
      <Box
        flex={1}
        style={{
          marginLeft: -19,
        }}
      >
        <SquareTilesList<ItemType>
          renderItem={handleRenderItem}
          data={rooms}
          isLoading={isLoading}
        />
      </Box>
      <AddExtraRoomBottomSheet
        ref={addExtraRoomRef}
        rooms={roomTypes}
        onAddRoom={handleAddOrSelectRoom}
      />
    </MobileTaxationStepTemplate>
  );
};

const MemoWhichRoomsAreGoingToBeMovedStep = memo(
  WhichRoomsAreGoingToBeMovedStep,
  () => true,
);
export default MemoWhichRoomsAreGoingToBeMovedStep;
