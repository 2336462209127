/* eslint-disable react/prop-types */
import { Box, Typography } from '@bas/ui/native/base';
import { MonthCalendar } from '@bas/ui/native/molecules';
import { FlashList } from '@shopify/flash-list';
import { ListRenderItemInfo } from '@shopify/flash-list/src/FlashListProps';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import * as React from 'react';
import {
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import isEqual from 'react-fast-compare';
import { MarkedDays } from 'react-native-month';
import { DayDot, DayTheme } from 'react-native-month/src/types';
import { create } from 'zustand';

dayjs.extend(localeData);

type ScrollableCalendarStore = {
  selectedDate?: Date;
  markedDays?: MarkedDays;
  setSelectedDate: (date: Date | undefined) => void;
  addMarkedDay: (date: Date, dots?: DayDot[], theme?: DayTheme) => void;
  removeMarkedDay: (date: Date) => void;
  setMarkedDays: (markedDays: MarkedDays | undefined) => void;
};

const useScrollableCalendarStore = create<ScrollableCalendarStore>(
  (set, get) => ({
    selectedDate: undefined,
    markedDays: {},
    addMarkedDay: (date, dots, theme) => {
      set((state) => ({
        markedDays: {
          ...state.markedDays,
          [dayjs(date).format('YYYY-MM-DD')]: {
            dots,
            theme,
          },
        },
      }));
    },
    removeMarkedDay: (date) => {
      set((state) => {
        const markedDays = { ...state.markedDays };
        delete markedDays[dayjs(date).format('YYYY-MM-DD')];
        return { markedDays };
      });
    },
    setMarkedDays: (markedDays) => set({ markedDays }),
    setSelectedDate: (date) => set({ selectedDate: date }),
  }),
);

export type ScrollableCalendarProps = {
  monthsBack?: number;
  monthsForward?: number;
  onPressDay: (date: Date) => void;
  currentDate?: Date;
  markedDays?: MarkedDays;
};

type RenderMonthProps = {
  month: Date;
  onPressDay: (date: Date) => void;
};

const RenderMonth = memo(
  ({ month, onPressDay }: RenderMonthProps) => {
    const [currentDate, setCurrentDate] = useState<Date>();
    const [markedDaysToUse, setMarkedDaysToUse] = useState<MarkedDays>({});

    const selectedDate = useScrollableCalendarStore(
      (state) => state.selectedDate,
    );
    const markedDays = useScrollableCalendarStore((state) => state.markedDays);

    const monthDayJs = useMemo(() => dayjs(month), [month]);

    useEffect(() => {
      if (
        dayjs(selectedDate).isBetween(
          monthDayJs.startOf('month'),
          monthDayJs.endOf('month'),
        )
      ) {
        setCurrentDate(selectedDate);
      } else if (currentDate) {
        setCurrentDate(undefined);
      }
    }, [currentDate, selectedDate, monthDayJs]);

    useEffect(() => {
      const newMarkedDays: MarkedDays = {};

      if (markedDays) {
        Object.keys(markedDays).forEach((key) => {
          if (
            dayjs(key).isBetween(
              monthDayJs.startOf('month'),
              monthDayJs.endOf('month'),
            )
          ) {
            newMarkedDays[key] = markedDays[key];
          }
        });
      }

      if (!isEqual(newMarkedDays, markedDaysToUse)) {
        setMarkedDaysToUse(newMarkedDays);
      }
    }, [markedDaysToUse, markedDays, monthDayJs, month]);

    return useMemo(
      () => (
        <Box height={430} flexDirection="column" rowGap={3}>
          <Box>
            <Typography
              textAlign="center"
              fontFamily="DmSans"
              textTransform="capitalize"
              fontSize={24}
              lineHeight={28}
            >
              {dayjs(month).format('MMMM YYYY')}
            </Typography>
          </Box>
          <Box flexBasis="100%">
            <MonthCalendar
              markedDays={markedDaysToUse}
              currentDate={currentDate}
              month={month}
              onPressDay={onPressDay}
            />
          </Box>
        </Box>
      ),
      [currentDate, markedDaysToUse, month, onPressDay],
    );
  },
  (prevProps, nextProps) =>
    prevProps.month.getTime() === nextProps.month.getTime(),
);

const ScrollableCalendar = ({
  monthsBack = 12,
  monthsForward = 0,
  onPressDay,
  currentDate,
  markedDays,
}: ScrollableCalendarProps): ReactElement => {
  const setSelectedDate = useScrollableCalendarStore(
    (state) => state.setSelectedDate,
  );
  const setMarkedDays = useScrollableCalendarStore(
    (state) => state.setMarkedDays,
  );

  useEffect(() => {
    setSelectedDate(currentDate);
  }, [currentDate, setSelectedDate]);

  useEffect(() => {
    setMarkedDays(markedDays);
  }, [markedDays, setMarkedDays]);

  const months = useMemo(() => {
    if (monthsForward === 0 && monthsBack === 0) {
      return [];
    }

    if (monthsBack !== undefined && monthsBack > 0 && monthsForward === 0) {
      return Array.from({ length: monthsBack + 1 }, (_, i) =>
        dayjs().subtract(i, 'month').toDate(),
      );
    }

    if (monthsBack === 0 && monthsForward !== undefined && monthsForward > 0) {
      return Array.from({ length: monthsForward + 1 }, (_, i) =>
        dayjs().add(i, 'month').toDate(),
      );
    }

    if (monthsBack !== undefined && monthsForward !== undefined) {
      return Array.from({ length: monthsBack + monthsForward + 1 }, (_, i) =>
        dayjs()
          .subtract(monthsBack - i, 'month')
          .toDate(),
      );
    }

    return [];
  }, [monthsForward, monthsBack]);

  const handleRenderMonth = useCallback(
    ({ item }: ListRenderItemInfo<Date>) => (
      <RenderMonth month={item} onPressDay={onPressDay} />
    ),
    [onPressDay],
  );

  return useMemo(
    () => (
      <FlashList<Date>
        data={months}
        renderItem={handleRenderMonth}
        estimatedItemSize={430}
        inverted
      />
    ),
    [handleRenderMonth, months],
  );
};

export default ScrollableCalendar;
