import { HoursEntryType } from '@bas/hrm-domain/models';
import dayjs from 'dayjs';
import * as Yup from 'yup';

export type EnterNormalHoursInputType = {
  date: Date | null;
  startDate: Date | null;
  endDate: Date | null;
  breakTime: number | null;
  entryType: HoursEntryType | null;
  reason?: string | null;
};

export const EnterNormalHoursInputTypeDefaultValues =
  (): EnterNormalHoursInputType => ({
    date: new Date(),
    startDate: null,
    endDate: null,
    breakTime: 0,
    entryType: null,
    reason: '',
  });

export const EnterNormalHoursInputTypeValidationBuilder =
  (): Yup.ObjectSchema<EnterNormalHoursInputType> =>
    Yup.object({
      date: Yup.date().required().nullable().label('label.date'),
      startDate: Yup.date().required().nullable().label('label.startTime'),
      endDate: Yup.date()
        .nullable()
        .required()
        .when('startDate', {
          is: (startDate: string | Date) =>
            !!startDate && dayjs(startDate).isValid(),
          then: (s) =>
            s
              .when(['startDate'], ([startDate], schema) =>
                schema.min(dayjs(startDate).clone().add(1, 'second').toDate()),
              )

              .required(),
        })
        .label('label.endTime'),
      breakTime: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .nullable()
        .required()
        .label('label.breakTime'),
      entryType: Yup.mixed<HoursEntryType>()
        .oneOf(Object.values(HoursEntryType))
        .required()
        .label('label.entryType'),
      reason: Yup.string().nullable(),
    });
