import { TowBar } from '@bas/planning-domain/models';
import { Money, PropulsionType, TransmissionType } from '@bas/value-objects';
import * as Yup from 'yup';

export interface VehicleInformationInputType {
  name: string;
  vinNumber?: string | null;
  licensePlate?: string | null;
  model?: string | null;
  propulsionType?: PropulsionType;
  transmissionType?: TransmissionType;
  registeredAt?: Date | null;
  purchasedAt?: Date | null;
  towBar?: TowBar | null;
  pricePerKilometer:
    | Money
    | {
        amount: number | null;
        currency: string;
      };
  pricePerHour:
    | Money
    | {
        amount: number | null;
        currency: string;
      };
}

export const VehicleInformationInputTypeDefaultValues =
  (): VehicleInformationInputType => ({
    name: '',
    vinNumber: '',
    licensePlate: '',
    model: '',
    propulsionType: PropulsionType.UNKNOWN,
    transmissionType: TransmissionType.UNKNOWN,
    registeredAt: null,
    purchasedAt: null,
    towBar: {
      hasTowBar: false,
      towWeight: 0,
    },
    pricePerKilometer: { amount: 0, currency: 'EUR' },
    pricePerHour: { amount: 0, currency: 'EUR' },
  });

export const VehicleInformationInputTypeValidationBuilder =
  (): Yup.ObjectSchema<VehicleInformationInputType> =>
    Yup.object({
      name: Yup.string().max(200).required().label('label.name'),
      vinNumber: Yup.string().label('label.vinNumber'),
      licensePlate: Yup.string().label('label.licensePlate'),
      model: Yup.string().label('label.model'),
      propulsionType: Yup.mixed<PropulsionType>()
        .oneOf(Object.values(PropulsionType))
        .label('label.propulsionType'),
      transmissionType: Yup.mixed<TransmissionType>()
        .oneOf(Object.values(TransmissionType))
        .label('label.transmissionType'),
      registeredAt: Yup.date().nullable().label('label.registeredAt'),
      purchasedAt: Yup.date().nullable().label('label.purchasedAt'),
      towBar: Yup.object({
        hasTowBar: Yup.boolean().label('label.hasTowBar'),
        towWeight: Yup.number()
          .transform((value, original) =>
            original === '' || original === null ? null : value,
          )
          .nullable()
          .label('label.towWeight'),
      }).nullable(),
      pricePerKilometer: Yup.object({
        amount: Yup.number()
          .transform((value, original) =>
            original === '' || original === null ? null : value,
          )
          .defined()
          .nullable()
          .label('label.pricePerKilometer'),
        currency: Yup.string().required(),
      }),
      pricePerHour: Yup.object({
        amount: Yup.number()
          .transform((value, original) =>
            original === '' || original === null ? null : value,
          )
          .defined()
          .nullable()
          .label('label.pricePerHour'),
        currency: Yup.string().required(),
      }),
    });
