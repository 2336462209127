import { calculateStepState, StepState } from '@bas/ui/native/atoms';
import { Box } from '@bas/ui/native/base';
import {
  SuccessFullFormView,
  SuccessFullFormViewProps,
} from '@bas/ui/native/molecules';
import {
  StepsNavigationProps,
  useWizardStore,
  WizardTemplateProps,
  WizardTemplateType,
} from '@bas/ui/native/organisms';
import { ReactElement, useMemo } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { MobilePageWithStepsNavigationTemplate } from '../MobilePageWithStepsNavigationTemplate';

export type CategoryType = {
  category: string;
  state: StepState;
  value: number;
  stepIndexes: number[];
};

export type ExtraReactHookWizardWithStepsNavigationTemplateProps = {
  onPressSteps: () => void;
  categories: CategoryType[];
  stepNavigationProps: Partial<StepsNavigationProps>;
  successFullFormViewProps?: SuccessFullFormViewProps;
};

const ReactHookWizardWithStepsNavigationTemplate: WizardTemplateType<
  ExtraReactHookWizardWithStepsNavigationTemplateProps
> = ({
  children,
  onNext,
  onPrevious,
  onPressSteps,
  categories,
  stepNavigationProps,
  successFullFormViewProps,
}: WizardTemplateProps<ExtraReactHookWizardWithStepsNavigationTemplateProps>): ReactElement => {
  const { currentStepIndex, numberOfSteps, submitted } = useWizardStore();
  const { trigger } = useFormContext();
  const { isValid, isSubmitting } = useFormState();

  const navigationSteps = useMemo(
    () =>
      categories.map((category) => ({
        ...category,
        state: calculateStepState(category.stepIndexes, currentStepIndex),
        value: currentStepIndex
          ? (100 / category.stepIndexes.length) *
            category.stepIndexes.indexOf(currentStepIndex)
          : 0,
      })),
    [categories, currentStepIndex],
  );

  const stepsNavigation = useMemo(
    (): StepsNavigationProps => ({
      steps: navigationSteps,
      allowPrevious: currentStepIndex > 0 && !isSubmitting,
      allowCancel: !isSubmitting,
      allowNext: isValid && !isSubmitting,
      isSubmit: currentStepIndex === numberOfSteps - 1,
      loading: isSubmitting,
      finalize: submitted,
      onPressSteps,
      onPrevious: () => onPrevious(),
      onNext: () => onNext(),
      onValidate: () => {
        trigger();
      },
      ...stepNavigationProps,
    }),
    [
      navigationSteps,
      currentStepIndex,
      isValid,
      isSubmitting,
      numberOfSteps,
      submitted,
      onPressSteps,
      stepNavigationProps,
      onPrevious,
      onNext,
      trigger,
    ],
  );

  return (
    <MobilePageWithStepsNavigationTemplate
      stepsNavigation={stepsNavigation}
      disableScroll
      disablePadding
    >
      {successFullFormViewProps ? (
        <SuccessFullFormView
          isSuccessFull={submitted}
          {...successFullFormViewProps}
        >
          {children}
        </SuccessFullFormView>
      ) : (
        <Box height="100%" flex={1}>
          {children}
        </Box>
      )}
    </MobilePageWithStepsNavigationTemplate>
  );
};

export default ReactHookWizardWithStepsNavigationTemplate;
