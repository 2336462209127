import { FinancialDocumentLinesEditorInputType } from '@bas/financial-domain/input-types';
import { useCalculateTotals } from '@bas/financial-domain/utils';
import { FormattedCurrency } from '@bas/ui/native/atoms';
import { Box, Typography } from '@bas/ui/native/base';
import {
  ReactHookFormNumberTextField,
  ReactHookFormTextField,
} from '@bas/ui/native/molecules';
import * as React from 'react';
import { Fragment, memo, ReactElement, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

export type MobileFinancialDocumentTotalsProps = {
  documentType: 'quote' | 'invoice';
  light?: boolean;
};

const MobileFinancialDocumentTotals = ({
  documentType,
  light,
}: MobileFinancialDocumentTotalsProps): ReactElement => {
  const { formatMessage } = useIntl();
  const totals = useCalculateTotals(documentType);
  const currency = useWatch<
    FinancialDocumentLinesEditorInputType,
    'quote.totalIncludingVat.currency' | 'invoice.totalIncludingVat.currency'
  >({
    name: `${documentType}.totalIncludingVat.currency`,
  });

  const discount = useMemo(
    () => (
      <Box flexBasis="100%" flexDirection="row">
        <Box flex={1} flexDirection="row" gap={1} pt={2} pl={2}>
          <Box flex={1}>
            <ReactHookFormTextField
              name={`${documentType}.discount.description`}
              label={formatMessage({ id: 'label.discountDescription' })}
              light={light}
            />
          </Box>
          <Box width={110}>
            <ReactHookFormNumberTextField
              name={`${documentType}.discount.percentage`}
              autoComplete="off"
              keyboardType="decimal-pad"
              light={light}
              label={formatMessage({ id: 'label.percentage' })}
              suffix="%"
              fractionDigits={2}
              padZero={false}
              showZero
            />
          </Box>
        </Box>
        <Box flexBasis="auto" pt={2} paddingLeft={2}>
          <Typography color={light ? 'white' : undefined} textAlign="right">
            <FormattedCurrency
              currency={currency}
              amount={totals.discountAmount}
            />
          </Typography>
        </Box>
      </Box>
    ),
    [currency, documentType, formatMessage, light, totals.discountAmount],
  );

  return (
    <Box pt={3}>
      <Box
        width="100%"
        flexBasis="100%"
        flexDirection="row"
        style={{
          marginTop: -16,
          marginLeft: -16,
        }}
        flexWrap="wrap"
      >
        {discount}
        <Box flexBasis="50%" width="50%" pt={2} pl={2}>
          <Typography color={light ? 'white' : undefined}>
            <FormattedMessage id="label.subTotal" />
          </Typography>
        </Box>
        <Box flexBasis="50%" width="50%" pt={2} pl={2}>
          <Typography color={light ? 'white' : undefined} textAlign="right">
            <FormattedCurrency
              currency={currency}
              amount={totals.totalExcludingVat}
            />
          </Typography>
        </Box>
        {Object.values(totals.vats)
          .sort(
            (vatA, vatB) =>
              vatA.vatPercentage.percentage - vatB.vatPercentage.percentage,
          )
          .map((vat, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={index}>
              <Box flexBasis="50%" width="50%" pt={2} pl={2}>
                <Typography
                  color={light ? 'white' : undefined}
                >{`${vat.vatPercentage.percentage}%`}</Typography>
              </Box>
              <Box flexBasis="50%" width="50%" pt={2} pl={2}>
                <Typography
                  color={light ? 'white' : undefined}
                  textAlign="right"
                >
                  <FormattedCurrency
                    currency={currency}
                    amount={vat.totalVatAmount}
                  />
                </Typography>
              </Box>
            </Fragment>
          ))}
        <Box flexBasis="50%" width="50%" pt={2} pl={2}>
          <Typography color={light ? 'white' : undefined} fontWeight={700}>
            <FormattedMessage id="label.total" />
          </Typography>
        </Box>
        <Box flexBasis="50%" width="50%" pt={2} pl={2}>
          <Typography
            color={light ? 'white' : undefined}
            textAlign="right"
            fontWeight={700}
          >
            <FormattedCurrency
              currency={currency}
              amount={totals.totalIncludingVat}
            />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const MemoComponent = memo(
  MobileFinancialDocumentTotals,
  (prevProps, nextProps) =>
    prevProps.light === nextProps.light &&
    prevProps.documentType === nextProps.documentType,
);
export default MemoComponent;
