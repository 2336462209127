import { MovingJobAddressType } from '@bas/project-domain/models';
import { TaxationMovingJobAddressesInputType } from '@bas/taxation-tool-domain/input-types';
import { MobileTaxationStepTemplate } from '@bas/taxation-tool-domain/native/templates';
import { Box, Typography } from '@bas/ui/native/base';
import { ConfirmAddressBlock } from '@bas/ui/native/organisms';
import { ReactElement } from 'react';
import { useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

const ConfirmPrimaryAddressesStep = (): ReactElement => {
  const addresses = useWatch<TaxationMovingJobAddressesInputType, 'addresses'>({
    name: 'addresses',
  });

  return (
    <MobileTaxationStepTemplate
      primary={<FormattedMessage id="mobileApp.intake.movingJob.confirmInfo" />}
      secondary={<FormattedMessage id="label.addresses" />}
    >
      {Object.values(MovingJobAddressType).map((addressType) => (
        <Box mt={3} key={addressType}>
          <Typography variant="h5" mb={1} fontWeight={700}>
            <FormattedMessage
              id={`project.moving-job.addressType.${addressType}`}
            />
          </Typography>
          {addresses
            .filter(
              (address) =>
                address.addressType === addressType && address.primary,
            )
            .map((address) => (
              <Box key={address.addressId} mb={2}>
                <ConfirmAddressBlock address={address} />
              </Box>
            ))}
        </Box>
      ))}
    </MobileTaxationStepTemplate>
  );
};

export default ConfirmPrimaryAddressesStep;
