import { Typography } from '@bas/ui/native/base';
import { colors } from '@bas/theme';
import { ReactElement, ReactPortal } from 'react';
import { StyleSheet, View } from 'react-native';

export type TitleWithNumberProps = {
  count: number;
  title: string | ReactElement | ReactPortal;
};

const styles = StyleSheet.create({
  badge: {
    backgroundColor: colors.blue[200],
    borderRadius: 5,
    paddingLeft: 7,
    paddingRight: 7,
    paddingTop: 2,
    paddingBottom: 2,
  },
  titleContainer: {
    flex: 1,
    flexDirection: 'row',
    marginBottom: 1,
  },
  title: {
    marginLeft: 7,
  },
  badgeText: {
    color: colors.white,
  },
});

const TitleWithNumber = ({ count, title }: TitleWithNumberProps) => (
  <View style={styles.titleContainer}>
    <View style={styles.badge}>
      <Typography style={styles.badgeText} fontWeight={700}>
        {count || 0}
      </Typography>
    </View>
    <Typography style={styles.title} fontWeight={700} variant="h5">
      {title}
    </Typography>
  </View>
);

export default TitleWithNumber;
