import { getDefaultCountry } from '@bas/shared/utils';
import { Country } from '@bas/value-objects';
import * as Yup from 'yup';

export interface AddressInputType {
  streetName: string;
  houseNumber: string;
  houseNumberAddition?: string | null;
  zipCode: string;
  city: string;
  country: Country;
  latitude?: number | null;
  longitude?: number | null;
}

export const addressInputTypeDefaultValues = (): AddressInputType => ({
  city: '',
  country: getDefaultCountry(),
  houseNumber: '',
  houseNumberAddition: '',
  streetName: '',
  zipCode: '',
});

export const addressInputTypeValidationBuilder =
  (): Yup.ObjectSchema<AddressInputType> =>
    Yup.object({
      streetName: Yup.string().label('label.streetName').required(),
      houseNumber: Yup.string().label('label.houseNumber').required(),
      houseNumberAddition: Yup.string()
        .label('label.houseNumberAddition')
        .nullable(),
      zipCode: Yup.string().label('label.zipCode').required(),
      city: Yup.string().label('label.city').required(),
      country: Yup.mixed<Country>().label('label.country').required(),
      latitude: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .nullable()
        .label('label.latitude'),
      longitude: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .nullable()
        .label('label.longitude'),
    });
