import { useRetrieveQrInformation } from '@bas/shared/hooks';
import {
  isQrCode,
  isStorageLocationQrCode,
  isStorageQrCode,
  isStoredItemQrCode,
  QrCode,
  StoredItemQrCode,
} from '@bas/shared/models';
import { useEmployeeStore } from '@bas/shared/state';
import { Alert, Button, Skeleton } from '@bas/ui/native/atoms';
import { Box, Typography } from '@bas/ui/native/base';
import { BackendErrors, QrCodeScanner } from '@bas/ui/native/organisms';
import { Uuid, VolumeType } from '@bas/value-objects';
import {
  LoadOrUnloadStorageWithInventoryInputType,
  StoredItemInputType,
  storedItemInputTypeDefaultValues,
} from '@bas/wms-domain/input-types';
import {
  useStorageByStorageIdRequestQuery,
  useStorageLocationByStorageLocationIdRequestQuery,
  useStoredItemsRequest,
  useWarehouseByWarehouseIdRequestQuery,
} from '@bas/wms-domain/requests';
import { FlashList } from '@shopify/flash-list';
import * as Notifications from 'expo-notifications';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import type { Code } from 'react-native-vision-camera';
import { useDebouncedCallback } from 'use-debounce';

type LoadOrUnloadItemsIntoStorageFormProps = {
  action: 'load' | 'unload';
};

type SelectionValueType = {
  selectedStorageId: string;
  itemLocationId: Uuid | null;
};

const LoadOrUnloadItemsIntoStorageForm = ({
  action,
}: LoadOrUnloadItemsIntoStorageFormProps): ReactElement => {
  const selectedStorageId = useWatch<SelectionValueType, 'selectedStorageId'>({
    name: 'selectedStorageId',
  });

  const {
    fields: formStoredItems,
    prepend,
    update,
    move,
  } = useFieldArray<LoadOrUnloadStorageWithInventoryInputType>({
    name: 'storedItems',
  });

  const itemLocationId = useWatch<SelectionValueType, 'itemLocationId'>({
    name: 'itemLocationId',
  });

  const { setValue } = useFormContext<
    SelectionValueType & LoadOrUnloadStorageWithInventoryInputType
  >();

  const { data: storageData, isFetching: isLoadingStorage } =
    useStorageByStorageIdRequestQuery(
      {
        storageId: selectedStorageId || '',
      },
      {
        enabled: !!selectedStorageId,
        gcTime: 0,
      },
    );

  const selectedStorage = useMemo(() => {
    if (storageData?.data === undefined) {
      return null;
    }

    return storageData?.data;
  }, [storageData?.data]);

  const { data: warehouseData, isFetching: isLoadingWarehouse } =
    useWarehouseByWarehouseIdRequestQuery(
      {
        warehouseId: selectedStorage?.warehouseId || '',
      },
      {
        enabled: !!selectedStorage?.warehouseId,
      },
    );

  const { data: storageLocationData, isFetching: isLoadingCurrentLocation } =
    useStorageLocationByStorageLocationIdRequestQuery(
      {
        storageLocationId: selectedStorage?.storageLocationId || '',
      },
      {
        enabled: !!selectedStorage?.storageLocationId,
      },
    );

  const { data: selectedLocationData, isFetching: isLoadingSelectedLocation } =
    useStorageLocationByStorageLocationIdRequestQuery(
      {
        storageLocationId: itemLocationId || '',
      },
      {
        enabled: !!itemLocationId,
      },
    );

  const { data: storedItemsData } = useStoredItemsRequest(
    {
      storageId: selectedStorageId || '',
    },
    {
      gcTime: 0,
      staleTime: 0,
      enabled: !!selectedStorageId,
    },
  );

  const storedItems = useMemo(
    () => storedItemsData?.data?.member || [],
    [storedItemsData?.data],
  );

  const storageLocation = useMemo(() => {
    if (storageLocationData?.data === undefined) {
      return null;
    }

    return storageLocationData?.data;
  }, [storageLocationData?.data]);

  const selectedLocation = useMemo(() => {
    if (selectedLocationData?.data === undefined) {
      return null;
    }

    return selectedLocationData?.data;
  }, [selectedLocationData?.data]);

  const warehouse = useMemo(() => {
    if (warehouseData?.data === undefined) {
      return null;
    }

    return warehouseData?.data;
  }, [warehouseData?.data]);

  const employeeId = useEmployeeStore((state) => state.employee?.employeeId);
  const parseQrCode = useRetrieveQrInformation();

  const { formatMessage } = useIntl();

  const handleNotifyUserItemIsNotInStorage = useDebouncedCallback(
    (body: string) => {
      Notifications.scheduleNotificationAsync({
        content: {
          title: formatMessage({ id: 'label.itemIsNotInStorage' }),
          body,
        },
        trigger: null,
      });
    },
    500,
    {
      leading: true,
      trailing: false,
    },
  );

  const handleScannedCode = useCallback(
    (code: Code) => {
      let parsed: QrCode | null = null;
      try {
        parsed = parseQrCode(code.value || '{}');
      } catch (e) {
        return false;
      }

      if (!parsed || !isQrCode(parsed)) {
        return false;
      }

      if (isStorageQrCode(parsed)) {
        if (!selectedStorageId) {
          setValue('selectedStorageId', parsed.storageId, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          });

          return true;
        }
      }

      if (isStorageLocationQrCode(parsed) && action !== 'unload') {
        if (itemLocationId !== parsed.locationId) {
          setValue('itemLocationId', parsed.locationId, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          });

          return true;
        }
      }

      if (isStoredItemQrCode(parsed)) {
        if (
          selectedStorage?.warehouseId &&
          selectedLocation &&
          selectedLocation?.warehouseId !== selectedStorage?.warehouseId
        ) {
          return false;
        }

        if (selectedStorageId !== parsed.storageId) {
          handleNotifyUserItemIsNotInStorage(
            formatMessage({
              id: 'label.theStoredItemsAreNotInTheSelectedStorage',
            }),
          );

          return false;
        }

        const indexOfItem = storedItems.findIndex(
          (storedItem) =>
            storedItem.itemId === (parsed as StoredItemQrCode).itemId,
        );

        if (indexOfItem === -1) {
          handleNotifyUserItemIsNotInStorage(
            formatMessage({
              id: 'label.theItemShouldBeInStorageButWasNotFound',
            }),
          );

          return false;
        }

        const formIndexOfItem = formStoredItems.findIndex(
          (storedItem) =>
            storedItem.itemId === (parsed as StoredItemQrCode).itemId,
        );

        if (action === 'unload') {
          if (
            !storedItems[indexOfItem].loadedOn ||
            storedItems[indexOfItem].unloadedOn
          ) {
            if (formIndexOfItem === -1) {
              prepend({
                ...storedItemInputTypeDefaultValues(),
                ...storedItems[indexOfItem],
                scanned: false,
                hasError: true,
              });

              return true;
            }

            if (formStoredItems[formIndexOfItem].hasError) {
              return false;
            }

            move(formIndexOfItem, 0);
            return true;
          }
        } else if (storedItems[indexOfItem].loadedOn) {
          if (formIndexOfItem === -1) {
            prepend({
              ...storedItemInputTypeDefaultValues(),
              ...storedItems[indexOfItem],
              scanned: false,
              hasError: true,
            });

            return true;
          }

          if (formStoredItems[formIndexOfItem].hasError) {
            return false;
          }

          move(formIndexOfItem, 0);
          return true;
        }

        const loadingInformation =
          action === 'unload'
            ? {
                unloadedOn: new Date(),
                unloadedByEmployeeId: employeeId || null,
                storageLocationId: null,
                storageLocationName: null,
                storageLocationCode: null,
              }
            : {
                loadedOn: new Date(),
                loadedByEmployeeId: employeeId || null,
                storageLocationId: itemLocationId,
                storageLocationName: selectedLocation?.name || '',
                storageLocationCode: selectedLocation?.code || '',
              };

        if (formIndexOfItem === -1) {
          prepend({
            ...storedItemInputTypeDefaultValues(),
            ...storedItems[indexOfItem],
            ...loadingInformation,
            create: false,
            scanned: true,
          });

          return true;
        }

        if (action === 'unload') {
          return false;
        }

        if (
          formStoredItems[formIndexOfItem].storageLocationId === itemLocationId
        ) {
          return false;
        }

        update(formIndexOfItem, {
          ...formStoredItems[formIndexOfItem],
          ...loadingInformation,
        });

        move(formIndexOfItem, 0);
        return true;
      }

      return false;
    },
    [
      action,
      parseQrCode,
      selectedStorageId,
      setValue,
      itemLocationId,
      selectedStorage?.warehouseId,
      selectedLocation,
      storedItems,
      formStoredItems,
      employeeId,
      update,
      move,
      handleNotifyUserItemIsNotInStorage,
      formatMessage,
      prepend,
    ],
  );

  const [hasSetFormValues, setHasSetFormValues] = useState(!!selectedStorageId);
  useEffect(() => {
    if (selectedStorage && !hasSetFormValues) {
      setValue('originalStoredItems', []);
      setValue('storedItems', []);
      setValue('percentageFull', selectedStorage.percentageFull || 0);
      setValue('notes', selectedStorage.notes || '');
      setValue('invoicePerVolume', selectedStorage.invoicePerVolume || false);
      setValue('oldVolume', selectedStorage.currentInvoicableVolume || null);
      setValue('lastInvoicedDate', selectedStorage.lastInvoicedDate || null);
      setValue(
        'volumeType',
        selectedStorage.volumeType || VolumeType.CUBIC_METER,
      );

      setHasSetFormValues(true);
    }
  }, [hasSetFormValues, selectedStorage, setValue]);

  const qrCodeScanner = useMemo(
    () => <QrCodeScanner onScannedCode={handleScannedCode} />,
    [handleScannedCode],
  );

  const scannedItems = useMemo(() => formStoredItems, [formStoredItems]);

  const handleRemoveLocationForItem = useCallback(
    (item: StoredItemInputType) => {
      const indexOfItem = formStoredItems.findIndex(
        (storedItem) => storedItem.itemId === item.itemId,
      );

      if (indexOfItem === -1) {
        return;
      }

      update(indexOfItem, {
        ...formStoredItems[indexOfItem],
        storageLocationId: null,
        storageLocationName: '',
        storageLocationCode: '',
      });
      move(indexOfItem, 0);
    },
    [formStoredItems, update, move],
  );

  const handleRenderScannedItem = useCallback(
    // eslint-disable-next-line react/no-unused-prop-types
    ({ item }: { item: StoredItemInputType }) => (
      <Box
        flexDirection="row"
        flexWrap="nowrap"
        gap={2}
        width="100%"
        alignItems="center"
        paddingVertical={1}
        borderBottomWidth={1}
        borderBottomColor="transparentBorder"
      >
        <Box flex={0.5}>
          <Typography color="white">{`${item.description} ${item.externalItemCode} ${item.itemCode}`}</Typography>
        </Box>
        <Box flex={item.scanned && action === 'load' ? 0.3 : 0.5}>
          {item.scanned && !item.hasError ? (
            <Typography color="white">
              {item.storageLocationId ? (
                `${item.storageLocationName} (${item.storageLocationCode})`
              ) : (
                <FormattedMessage id="label.itemIsInStorage" />
              )}
            </Typography>
          ) : (
            <Typography color="warningButtonColor">
              <FormattedMessage
                id={
                  action === 'unload'
                    ? 'label.itemWasAlreadyUnloadedOrWasNeverLoaded'
                    : 'label.itemWasAlreadyLoaded'
                }
              />
            </Typography>
          )}
        </Box>
        {item.scanned && action === 'load' && (
          <Box flex={0.4}>
            <Button
              variant="outlined"
              disabled={!item.storageLocationId}
              onLongPress={() => handleRemoveLocationForItem(item)}
              forceLongPress
              longPressLoaderColor="rgba(229, 78, 68, 0.2)"
            >
              <FormattedMessage id="button.clearLocation" />
            </Button>
          </Box>
        )}
      </Box>
    ),
    [action, handleRemoveLocationForItem],
  );

  return (
    <Box flex={1}>
      <Box width="100%" pb={3}>
        <Typography
          color="white"
          variant="h4"
          fontWeight={700}
          textAlign="center"
        >
          {action === 'load' && (
            <FormattedMessage id="label.loadItemsIntoStorage" />
          )}
          {action === 'unload' && (
            <FormattedMessage id="label.unloadItemsFromStorage" />
          )}
        </Typography>
        <Typography variant="h5" color="white" textAlign="center">
          {!selectedStorageId && (
            <FormattedMessage id="label.noStorageSelected" />
          )}
          {!selectedStorage && selectedStorageId && isLoadingStorage && (
            <Skeleton light width={400} height={20} />
          )}

          {!isLoadingStorage &&
            !!selectedStorage &&
            `${selectedStorage.contentOwnerName} (${selectedStorage.storageCode})`}
        </Typography>
      </Box>
      <Box
        height={
          selectedStorageId
            ? '40%'
            : {
                phone: '40%',
                tablet: '60%',
              }
        }
        width="100%"
      >
        {qrCodeScanner}
      </Box>
      <Box pt={3} flex={1} paddingHorizontal={20}>
        <BackendErrors pb={1} />
        {selectedStorage && (
          <Box flexDirection="row" width="100%" flexWrap="wrap">
            {!selectedStorage.warehouseId && (
              <Box width="100%">
                <Alert severity="warning">
                  <FormattedMessage id="label.storageIsNotReturnedToAWarehouse" />
                </Alert>
                <Typography variant="body1" color="white" pt={1}>
                  <FormattedMessage id="label.pleaseGoBackAndReturnTheStorageToAWarehouse" />
                </Typography>
              </Box>
            )}
            {selectedStorage.warehouseId &&
              selectedLocation &&
              selectedLocation.warehouseId !== selectedStorage.warehouseId && (
                <Box width="100%" pb={3}>
                  <Alert severity="warning">
                    <FormattedMessage id="label.locationIsNotInTheWarehouseOfTheStorage" />
                  </Alert>
                </Box>
              )}

            {selectedStorage.warehouseId && (
              <Box width={action === 'unload' ? '100%' : '50%'} pr={1}>
                <Typography variant="subtitle1" color="white">
                  <FormattedMessage id="label.storageLocation" />
                </Typography>
                {selectedStorage.warehouseId && isLoadingWarehouse && (
                  <Skeleton light width={400} height={20} />
                )}
                {!isLoadingWarehouse && !!warehouse && (
                  <Typography variant="body1" color="white">
                    <FormattedMessage id="label.warehouse" />: {warehouse.name}
                  </Typography>
                )}

                {!selectedStorage.storageLocationId && (
                  <Typography variant="body1" color="white">
                    <FormattedMessage id="label.storageCurrentlyNotInALocation" />
                  </Typography>
                )}

                {selectedStorage.storageLocationId &&
                  isLoadingCurrentLocation && (
                    <Skeleton light width={400} height={20} />
                  )}

                {storageLocation && (
                  <>
                    <Typography variant="body1" color="white">
                      {storageLocation.name} ({storageLocation.code})
                    </Typography>
                    <Typography variant="body1" color="white">
                      {storageLocation.description}
                    </Typography>
                  </>
                )}
              </Box>
            )}
            {selectedStorage.warehouseId && action === 'load' && (
              <Box width="50%" pl={1}>
                <Typography variant="subtitle1" color="white">
                  <FormattedMessage id="label.selectedLocation" />
                </Typography>

                {!itemLocationId && (
                  <Typography variant="body1" color="white">
                    <FormattedMessage id="label.itemsWillGoIntoStorage" />
                  </Typography>
                )}
                {!itemLocationId && (
                  <Typography variant="body1" color="white" pt={0.5}>
                    <FormattedMessage id="label.ifYouWantItemsToGoIntoASpecificLocationScanTheLocationQrCode" />
                  </Typography>
                )}
                {itemLocationId && isLoadingSelectedLocation && (
                  <Skeleton light width={400} height={20} />
                )}
                {!isLoadingSelectedLocation && !!selectedLocation && (
                  <>
                    <Typography variant="body1" color="white">
                      <FormattedMessage id="label.warehouse" />:{' '}
                      {selectedLocation.warehouseName}
                    </Typography>
                    <Typography variant="body1" color="white">
                      {selectedLocation.name} ({selectedLocation.code})
                    </Typography>
                    <Typography variant="body1" color="white">
                      {selectedLocation.description}
                    </Typography>
                  </>
                )}
              </Box>
            )}
          </Box>
        )}

        {!selectedStorageId && (
          <Box width="100%" flex={1}>
            <Alert severity="info">
              <FormattedMessage id="label.scanAQrCodeOfAStorage" />
            </Alert>
          </Box>
        )}

        <Box width="100%" flex={1} pt={3} pb={1}>
          <Typography variant="subtitle1" color="white">
            {scannedItems.length} <FormattedMessage id="label.scannedItems" />
          </Typography>
          {selectedStorageId && scannedItems.length === 0 && (
            <Alert severity="info">
              <FormattedMessage id="label.scanItemsToLoadThemIntoTheStorage" />
            </Alert>
          )}
          {!selectedStorageId && (
            <Typography variant="body1" color="white">
              <FormattedMessage id="label.noStorageSelected" />
            </Typography>
          )}
          {selectedStorageId && scannedItems.length > 0 && (
            <FlashList
              renderItem={handleRenderScannedItem}
              keyExtractor={(item) => item.id}
              data={scannedItems}
              estimatedItemSize={62}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default LoadOrUnloadItemsIntoStorageForm;
