import {
  CalculatedTotalsForAcceptanceQuote,
  QuoteLine,
} from '@bas/financial-domain/models';
import {
  Checkbox,
  FormattedCurrency,
  OptionalLabel,
  Skeleton,
} from '@bas/ui/native/atoms';
import { Box, Typography } from '@bas/ui/native/base';
import { Discount, Uuid } from '@bas/value-objects';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

export type DesktopQuoteTableForAcceptanceProps = {
  setAcceptedLines: (newValue: Uuid[]) => void;
  reloadTotals: (newValue: Uuid[]) => Promise<void> | void;
  loadingTotals?: boolean;
  totals: CalculatedTotalsForAcceptanceQuote;
  hasOptionalLines: boolean;
  showTotals?: boolean;
  showQuantity?: boolean;
  showTotalPerLine?: boolean;
  acceptedLines: Uuid[];
  lines: QuoteLine[];
  discount?: Discount;
};

const DesktopQuoteTableForAcceptance = ({
  setAcceptedLines,
  reloadTotals,
  loadingTotals,
  totals,
  hasOptionalLines,
  acceptedLines,
  showTotals,
  showQuantity,
  showTotalPerLine,
  lines,
  discount,
}: DesktopQuoteTableForAcceptanceProps): ReactElement => (
  <Box>
    {lines.map((line) => (
      <Box key={line.lineId} minHeight={77.75} height="auto">
        <Box
          width="100%"
          borderBottomWidth={1}
          borderColor="titleBorder"
          flexDirection="row"
          flexWrap="wrap"
          alignItems="center"
          alignContent="center"
          paddingVertical={1}
          gap={1}
          flex={1}
        >
          {hasOptionalLines && (
            <Box width={76}>
              <Checkbox
                desktop
                size={24}
                disabled={!line.optional}
                checked={!!acceptedLines && acceptedLines.includes(line.lineId)}
                onCheck={(checked) => {
                  const newValue = [...acceptedLines];
                  const index = newValue.indexOf(line.lineId);

                  if (checked) {
                    newValue.push(line.lineId);
                  } else {
                    newValue.splice(index, 1);
                  }

                  reloadTotals(newValue);
                  setAcceptedLines(newValue);
                }}
              />
            </Box>
          )}
          {showQuantity && (
            <Box minWidth={55} maxWidth={110}>
              <Typography>{`${line.quantity}x`}</Typography>
            </Box>
          )}
          <Box flex={1}>
            <Typography>{line.description}</Typography>
            {line.optional && <OptionalLabel />}
          </Box>
          <Box width={100}>
            <Typography textAlign="right">
              <FormattedCurrency {...line.pricePerUnit} />
            </Typography>
          </Box>
          {showTotalPerLine && (
            <Box width={100}>
              <Typography textAlign="right">
                <FormattedCurrency {...line.totalPrice} />
              </Typography>
            </Box>
          )}
          <Box width={76}>
            <Typography textAlign="right">
              {`${line.vatPercentage.percentage}%`}
            </Typography>
          </Box>
        </Box>
      </Box>
    ))}
    {showTotals && (
      <Box>
        {discount &&
          discount.percentage &&
          totals.discount &&
          totals.discount.amount !== 0 && (
            <Box
              pt={2}
              width="100%"
              flexDirection="row"
              flexWrap="wrap"
              alignItems="center"
              alignContent="center"
            >
              {hasOptionalLines && <Box width={76} />}
              {showQuantity && <Box width={55} />}
              <Box flex={1}>
                <Typography textAlign="right">
                  {`${discount.description} ${discount.percentage}%`}
                </Typography>
              </Box>
              <Box width={100} />
              <Box width={100} alignItems="flex-end">
                {loadingTotals ? (
                  <Skeleton width={100} height={24} />
                ) : (
                  <Typography textAlign="right">
                    <FormattedCurrency {...totals.discount} />
                  </Typography>
                )}
              </Box>
              <Box width={76} />
            </Box>
          )}
        <Box
          pt={2}
          width="100%"
          flexDirection="row"
          flexWrap="wrap"
          alignItems="center"
          alignContent="center"
        >
          {hasOptionalLines && <Box width={76} />}
          {showQuantity && <Box width={55} />}
          <Box flex={1}>
            <Typography textAlign="right">
              <FormattedMessage id="label.subTotal" />
            </Typography>
          </Box>
          <Box width={100} />
          <Box width={100} alignItems="flex-end">
            {loadingTotals ? (
              <Skeleton width={100} height={24} />
            ) : (
              <Typography textAlign="right">
                <FormattedCurrency {...totals.totalExcludingVat} />
              </Typography>
            )}
          </Box>
          <Box width={76} />
        </Box>
        {totals.vatAmounts
          .sort(
            ({ vatPercentage: vatA }, { vatPercentage: vatB }) =>
              parseInt(vatA, 10) - parseInt(vatB, 10),
          )
          .map(({ vatPercentage, vatAmount }) => (
            <Box
              key={vatPercentage}
              pt={2}
              width="100%"
              flexDirection="row"
              flexWrap="wrap"
              alignItems="center"
              alignContent="center"
            >
              {hasOptionalLines && <Box width={76} />}
              {showQuantity && <Box width={55} />}
              <Box flex={1}>
                <Typography textAlign="right">
                  {`${vatPercentage}% `}
                  <FormattedMessage id="label.vat" />
                </Typography>
              </Box>
              <Box width={100} />
              <Box width={100} alignItems="flex-end">
                {loadingTotals ? (
                  <Skeleton width={100} height={24} />
                ) : (
                  <Typography textAlign="right">
                    <FormattedCurrency {...vatAmount} />
                  </Typography>
                )}
              </Box>
              <Box width={76} />
            </Box>
          ))}
        <Box
          pt={2}
          width="100%"
          flexDirection="row"
          flexWrap="wrap"
          alignItems="center"
          alignContent="center"
        >
          {hasOptionalLines && <Box width={76} />}
          {showQuantity && <Box width={55} />}
          <Box flex={1}>
            <Typography textAlign="right" fontWeight={700}>
              <FormattedMessage id="label.total" />
            </Typography>
          </Box>
          <Box width={100} />
          <Box width={100} alignItems="flex-end">
            {loadingTotals ? (
              <Skeleton width={100} height={24} />
            ) : (
              <Typography textAlign="right">
                <FormattedCurrency {...totals.totalIncludingVat} />
              </Typography>
            )}
          </Box>
          <Box width={76} />
        </Box>
      </Box>
    )}
  </Box>
);

export default DesktopQuoteTableForAcceptance;
