import { formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, Money, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type CreateNewReusableMaterialMutationProps = {
  inventoryItemId: Uuid;
  tenantId: Uuid;
  name: string;
  length?: number | null;
  width?: number | null;
  height?: number | null;
  rentalPrice: Money;
  sellingPrice: Money;
  expiresAt?: Date | null;
};

export const CreateNewReusableMaterialMutation = async ({
  expiresAt,
  ...values
}: CreateNewReusableMaterialMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/{tenantId}/inventory/reusable-materials`, {
    expiresAt: expiresAt ? formatDateTime(expiresAt) : null,
    ...values,
  });

export const useCreateNewReusableMaterialMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    CreateNewReusableMaterialMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  CreateNewReusableMaterialMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    CreateNewReusableMaterialMutationProps
  >({
    mutationFn: CreateNewReusableMaterialMutation,
    throwOnError: false,
    ...options,
  });
