import { Uuid } from '@bas/value-objects';
import * as Yup from 'yup';

export interface AcceptQuoteInputType {
  nameOfAcceptor: string;
  acceptedLines: Uuid[];
}

export const AcceptQuoteInputTypeDefaultValues = (): AcceptQuoteInputType => ({
  nameOfAcceptor: '',
  acceptedLines: [],
});

export const AcceptQuoteInputTypeValidationBuilder =
  (): Yup.ObjectSchema<AcceptQuoteInputType> =>
    Yup.object({
      nameOfAcceptor: Yup.string().required().label('label.nameOfAcceptor'),
      acceptedLines: Yup.array()
        .of(Yup.string().required())
        .required()
        .label('label.acceptedLines'),
    });
