import { ErrorResponse, Uuid } from '@bas/value-objects';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type CopyAttachmentsToDocumentsMutationProps = {
  emailId: Uuid;
  attachmentIds: Uuid[];
  projectId?: Uuid | null;
  relationId?: Uuid | null;
  invoiceId?: Uuid | null;
  quoteId?: Uuid | null;
};

export const CopyAttachmentsToDocumentsMutation = async ({
  emailId,
  ...params
}: CopyAttachmentsToDocumentsMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/communication/emails/${emailId}/copy-attachments`, {
    emailId,
    ...params,
  });

export const useCopyAttachmentsToDocumentsMutation = (): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  CopyAttachmentsToDocumentsMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    CopyAttachmentsToDocumentsMutationProps
  >({
    mutationFn: CopyAttachmentsToDocumentsMutation,
    throwOnError: false,
  });
