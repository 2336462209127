import { MovingJobRoom } from '@bas/project-domain/models';
import { ProjectOutstandingInventoryItems } from '@bas/project-domain/native/organisms';
import { colors } from '@bas/theme';
import { BottomSheetMethods, Box, Typography } from '@bas/ui/native/base';
import { BackendErrors, BottomSheetProps } from '@bas/ui/native/organisms';
import { FormBottomSheet } from '@bas/ui/native/templates';
import { Uuid } from '@bas/value-objects';
import {
  InventoryMovementInputType,
  InventoryMovementInputTypeValidationBuilder,
} from '@bas/wms-domain/input-types';
import {
  InventoryItem,
  NeededInventoryItem,
  PublicMovingBox,
} from '@bas/wms-domain/models';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { ReactElement, ReactNode, RefObject, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { StyleSheet } from 'react-native';
import { ensuredForwardRef } from 'react-use/lib/useEnsuredForwardedRef';
import * as Yup from 'yup';
import { TakeMaterialsForm } from '../TakeMaterialsForm';

type InputType = { materials: InventoryMovementInputType[] };

export type TakeMaterialsBottomSheetProps = Omit<
  BottomSheetProps,
  'snapPoints' | 'children'
> & {
  title?: ReactNode;
  description?: ReactNode;
  buttonText?: ReactElement;
  materials: (InventoryItem | PublicMovingBox)[];
  onSubmit: (values: InputType) => Promise<void> | void;
  projectId: Uuid;
  rooms: MovingJobRoom[];
  neededInventoryItems: NeededInventoryItem[];
  hideReusable?: boolean;
  hideNonReusable?: boolean;
  greetingName?: string | null;
};

const styles = StyleSheet.create({
  flexContainer: {
    flex: 1,
    height: '100%',
  },
  container: {
    height: '100%',
    marginBottom: 15,
    paddingLeft: 20,
    paddingRight: 20,
  },
  description: {
    textAlign: 'center',
  },
  avatar: {
    height: '100%',
    width: '100%',
  },
  niceJob: {
    textAlign: 'center',
  },
  avatarContainer: {
    overflow: 'hidden',
    borderRadius: 269,
    height: 268,
    width: 268,
    marginBottom: 29,
    marginTop: 29,
    alignSelf: 'center',
  },
  header: {
    alignSelf: 'center',
    fontSize: 22,
    lineHeight: 29,
    height: 51,
  },
  paddingTop: {
    paddingTop: 24,
  },
  padding: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 31,
    paddingTop: 31,
  },
});

const TakeMaterialsBottomSheet = ensuredForwardRef<
  BottomSheetModal & BottomSheetMethods,
  TakeMaterialsBottomSheetProps
>(
  (
    {
      title,
      description,
      buttonText,
      materials,
      onSubmit,
      projectId,
      rooms,
      neededInventoryItems,
      hideReusable,
      hideNonReusable,
      greetingName,
      ...props
    }: TakeMaterialsBottomSheetProps,
    ref: RefObject<BottomSheetModal & BottomSheetMethods>,
  ): ReactElement => {
    const handleFinalize = useCallback(() => {
      ref?.current?.close();
      ref?.current?.forceClose();
      ref?.current?.dismiss();
    }, [ref]);

    const handleRenderHeader = useCallback(
      () => (
        <Box backgroundColor="darkText" marginBottom={2}>
          <BackendErrors />
          <Box>
            <Typography
              overrideColor={colors.white}
              variant="h5"
              style={styles.description}
            >
              {description || (
                <FormattedMessage id="mobileApp.event.takeMaterials.description" />
              )}
            </Typography>
            <Box style={styles.paddingTop}>
              <ProjectOutstandingInventoryItems
                projectId={projectId}
                rooms={rooms}
                neededInventoryItems={neededInventoryItems}
                hideReusable={hideReusable}
                hideNonReusable={hideNonReusable}
                light
              />
            </Box>
          </Box>
        </Box>
      ),
      [
        description,
        hideNonReusable,
        hideReusable,
        neededInventoryItems,
        projectId,
        rooms,
      ],
    );

    return (
      <FormBottomSheet<InputType>
        bottomSheetRef={ref}
        disablePadding
        onSubmit={onSubmit}
        useProvider
        resetFormOnOpen
        successFullFormViewProps={{
          gifUrl: 'https://media.giphy.com/media/KxhVRbDbwyW4BtCDRZ/giphy.gif',
          color: colors.lila[100],
          title: title || (
            <FormattedMessage id="mobileApp.event.takeMaterials.title" />
          ),
        }}
        onCancel={handleFinalize}
        defaultValues={{
          materials: materials.map(({ inventoryItemId }) => ({
            inventoryItemId,
            quantity: 0,
          })),
        }}
        disableScroll
        validationSchema={Yup.object({
          materials: Yup.array().of(
            InventoryMovementInputTypeValidationBuilder(),
          ),
        })}
        {...props}
      >
        <Box style={styles.flexContainer}>
          <Typography
            fontWeight={700}
            overrideColor={colors.white}
            style={styles.header}
            textAlign="center"
          >
            {title || (
              <FormattedMessage id="mobileApp.event.takeMaterials.title" />
            )}
          </Typography>
          <Box
            flex={1}
            height="100%"
            style={[styles.container, styles.paddingTop]}
          >
            <TakeMaterialsForm
              materials={materials}
              renderHeader={handleRenderHeader}
            />
          </Box>
        </Box>
      </FormBottomSheet>
    );
  },
);

export default TakeMaterialsBottomSheet;
