import {
  StartDrivingInputType,
  StartDrivingInputTypeValidationBuilder,
} from '@bas/planning-domain/input-types';
import { Vehicle } from '@bas/planning-domain/models';
import {
  ShowNotesStep,
  ShowNotesStepProps,
  StartDrivingStep,
  StartDrivingStepProps,
  TakeMaterialsStep,
  TakeMaterialsStepProps,
} from '@bas/planning-domain/native/organisms';
import { isMovingJob, Project } from '@bas/project-domain/models';
import { EventType, ReactHookWizardStep, Uuid } from '@bas/value-objects';
import {
  InventoryMovementInputType,
  InventoryMovementInputTypeValidationBuilder,
} from '@bas/wms-domain/input-types';
import {
  InventoryItem,
  NeededInventoryItem,
  PublicMovingBox,
} from '@bas/wms-domain/models';
import { Dayjs } from 'dayjs';
import { useMemo } from 'react';
import * as Yup from 'yup';

export const useStartDrivingWizardSteps = ({
  boxes,
  currentTime,
  eventType,
  neededInventoryItems,
  notes,
  project,
  projectId,
  reusableMaterials,
  vehicles,
}: {
  boxes: PublicMovingBox[];
  currentTime: Dayjs;
  eventType?: EventType;
  neededInventoryItems?: NeededInventoryItem[];
  notes?: string | null;
  project?: Project;
  projectId?: Uuid;
  reusableMaterials: InventoryItem[];
  vehicles: Vehicle[];
}) =>
  useMemo(
    () =>
      [
        {
          key: 'mobileApp.event.takeMaterials.title',
          title: 'mobileApp.event.takeMaterials.title',
          category: 'deliver',
          enabled:
            !!eventType &&
            ![EventType.INTAKE_EVENT, EventType.DRIVING_DAY].includes(
              eventType,
            ),
          validationSchema: Yup.object({
            materials: Yup.array().of(
              InventoryMovementInputTypeValidationBuilder(),
            ),
          }),
          component: TakeMaterialsStep,
          props: {
            projectId,
            neededInventoryItems,
            project,
            materials: reusableMaterials,
            key: 'mobileApp.event.takeMaterials.description',
            title: 'mobileApp.event.takeMaterials.description',
            projectOutstandingInventoryItemsProps: {
              hideNonReusable: true,
            },
          },
        } as ReactHookWizardStep<StartDrivingInputType, TakeMaterialsStepProps>,
        {
          key: 'mobileApp.event.deliverMovingBoxes.title',
          title: 'mobileApp.event.deliverMovingBoxes.title',
          category: 'deliver',
          enabled:
            !!eventType &&
            ![
              EventType.INTAKE_EVENT,
              EventType.TRANSPORT_JOB_PICKUP,
              EventType.TRANSPORT_JOB_DELIVER,
              EventType.DRIVING_DAY,
            ].includes(eventType),
          validationSchema: Yup.object({
            materials: Yup.array().of(
              InventoryMovementInputTypeValidationBuilder(),
            ),
          }),
          component: TakeMaterialsStep,
          props: {
            projectId,
            neededInventoryItems,
            project,
            materials: boxes,
            key: 'mobileApp.event.deliverMovingBoxes.description',
            title: 'mobileApp.event.deliverMovingBoxes.description',
            offSetIndex: reusableMaterials.length,
            projectOutstandingInventoryItemsProps: {
              hideReusable: true,
              rooms: !!project && isMovingJob(project) ? project.rooms : [],
            },
          },
        } as ReactHookWizardStep<StartDrivingInputType, TakeMaterialsStepProps>,
        {
          key: 'label.notes',
          title: 'label.notes',
          category: 'notes',
          enabled: true,
          component: ShowNotesStep,
          props: {
            notes,
            projectNotes: project?.notesForEmployees,
          },
        } as ReactHookWizardStep<StartDrivingInputType, ShowNotesStepProps>,
        {
          key: 'label.startDriving',
          title: 'label.startDriving',
          category: 'start',
          enabled: true,
          validationSchema: StartDrivingInputTypeValidationBuilder(),
          component: StartDrivingStep,
          props: {
            startTime: currentTime.toDate(),
            vehicles,
          },
        } as ReactHookWizardStep<StartDrivingInputType, StartDrivingStepProps>,
      ].filter(
        (step) =>
          step.enabled ||
          step.enabled === undefined ||
          typeof step.enabled === 'function',
      ) as ReactHookWizardStep<
        StartDrivingInputType & {
          materials: InventoryMovementInputType[];
        }
      >[],
    [
      boxes,
      currentTime,
      eventType,
      neededInventoryItems,
      notes,
      project,
      projectId,
      reusableMaterials,
      vehicles,
    ],
  );
