import { useTrafficByCountryRequest } from '@bas/integration-domain/requests';
import { ObstructionType, TrafficObstructionSummary } from '@bas/shared/models';
import { colors } from '@bas/theme';
import { BottomSheetMethods, Box, Typography } from '@bas/ui/native/base';
import { TrafficObstructionListItem } from '@bas/ui/native/molecules';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { FlashList, RenderTarget } from '@shopify/flash-list';
import { forwardRef, ReactElement, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { BottomSheet, BottomSheetProps } from '../BottomSheet';

export type TrafficObstructionsBottomSheetProps = Omit<
  BottomSheetProps,
  'snapPoints' | 'children'
>;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 15,
  },
  scrollview: {
    padding: 12,
    paddingBottom: 50,
  },
  sectionFooter: {
    paddingBottom: 24,
  },
  sectionHeader: {
    paddingHorizontal: 12,
    paddingBottom: 12,
    backgroundColor: colors.lila[800],
  },
  obstructionContainer: { paddingTop: 16, minHeight: 78 },
  header: {
    color: colors.lila[100],
  },
});

const TrafficObstructionsBottomSheet = forwardRef<
  BottomSheetModal & BottomSheetMethods,
  TrafficObstructionsBottomSheetProps
>(({ ...props }: TrafficObstructionsBottomSheetProps, ref): ReactElement => {
  const { data: trafficData } = useTrafficByCountryRequest(
    { country: 'nl' },
    { retry: false },
  );

  const traffic = useMemo(() => trafficData?.data?.member ?? [], [trafficData]);

  const items = useMemo(
    () => [
      'important',
      ...traffic.filter(
        ({ obstructionType }) => obstructionType !== ObstructionType.ROAD_WORK,
      ),
      'footer',
      'roadWork',
      ...traffic.filter(
        ({ obstructionType }) => obstructionType === ObstructionType.ROAD_WORK,
      ),
    ],
    [traffic],
  );

  const handleRenderItem = useCallback(
    ({
      item: obstruction,
      target,
    }: {
      item: TrafficObstructionSummary | string;
      target: RenderTarget;
    }) => {
      if (typeof obstruction === 'string') {
        if (obstruction === 'footer') {
          return <Box style={styles.sectionFooter} />;
        }
        if (target === 'StickyHeader') {
          return (
            <Box style={styles.sectionHeader}>
              <Typography style={styles.header} variant="h5" fontWeight={700}>
                <FormattedMessage id={`label.${obstruction}Obstruction`} />
              </Typography>
            </Box>
          );
        }
        return (
          <Typography style={styles.header} variant="h5" fontWeight={700}>
            <FormattedMessage id={`label.${obstruction}Obstruction`} />
          </Typography>
        );
      }

      return (
        <View style={styles.obstructionContainer}>
          <TrafficObstructionListItem obstruction={obstruction} dark />
        </View>
      );
    },
    [],
  );

  const stickyHeaderIndices = items
    .map((item, index) => {
      if (typeof item === 'string') {
        return index;
      }
      return null;
    })
    .filter((item) => item !== null) as number[];

  return (
    <BottomSheet ref={ref} {...props}>
      <FlashList<TrafficObstructionSummary | string>
        renderScrollComponent={ScrollView}
        data={items}
        estimatedItemSize={81}
        keyExtractor={(item) =>
          typeof item === 'string' ? item : item.obstructionId
        }
        stickyHeaderIndices={stickyHeaderIndices}
        renderItem={handleRenderItem}
        contentContainerStyle={styles.scrollview}
      />
    </BottomSheet>
  );
});

export default TrafficObstructionsBottomSheet;
