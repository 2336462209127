import { BillingMomentInputType } from '@bas/tenant-domain/input-types';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

const mapPeriod: {
  [key: string]: string;
} = {
  direct: '1 second',
  fiveMinutes: '5 minutes',
  hour: '1 hour',
  day: '1 day',
  week: '1 week',
  twoWeeks: '2 weeks',
  month: '1 month',
};

export type ChangeBillingMomentsOfProjectMutationProps = {
  projectId: Uuid;
  packageId?: Uuid | null;
  billingMoments: BillingMomentInputType[];
};

export const ChangeBillingMomentsOfProjectMutation = async ({
  projectId,
  billingMoments,
  ...values
}: ChangeBillingMomentsOfProjectMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/projects/${projectId}/change-billing-moments`, {
    projectId,
    billingMoments: billingMoments.map(
      ({ period, lineType, invoiceLines, ...billingMoment }) => ({
        ...billingMoment,
        period: mapPeriod[period] || period,
        lineType,
        invoiceLines: invoiceLines.map((line) => ({
          ...line,
          lineType,
          translations: [],
        })),
      }),
    ),
    ...values,
  });

export const useChangeBillingMomentsOfProjectMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeBillingMomentsOfProjectMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ChangeBillingMomentsOfProjectMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeBillingMomentsOfProjectMutationProps
  >({
    mutationFn: ChangeBillingMomentsOfProjectMutation,
    throwOnError: false,
    ...options,
  });
