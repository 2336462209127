import { MovingJobAddressInputTypeValidationBuilder } from '@bas/project-domain/input-types';
import {
  MovingJobAddress,
  MovingJobAddressType,
} from '@bas/project-domain/models';
import * as Yup from 'yup';

export interface TaxationMovingJobAddressesInputType {
  addresses: MovingJobAddress[];
}

export const TaxationMovingJobAddressesInputTypeDefaultValues =
  (): TaxationMovingJobAddressesInputType => ({
    addresses: [],
  });

export const TaxationMovingJobAddressesInputTypeValidatorBuilder = ({
  expectedIndex,
  primary,
  addressType,
}: {
  expectedIndex?: number;
  primary?: boolean;
  addressType?: MovingJobAddressType;
}): Yup.ObjectSchema<TaxationMovingJobAddressesInputType> => {
  if (typeof expectedIndex === 'number') {
    return Yup.object({
      addresses: Yup.object({
        [expectedIndex]: MovingJobAddressInputTypeValidationBuilder()
          .concat(
            Yup.object({
              addressId: Yup.string().required().label('label.addressId'),
            }),
          )
          .when(['unknownAddress'], ([unknownAddress], schema) => {
            if (unknownAddress === true) {
              return Yup.object();
            }

            return schema;
          }),
      }).transform((value, original) => ({ ...original })),
    }) as Yup.ObjectSchema<TaxationMovingJobAddressesInputType>;
  }

  if (primary !== undefined && addressType) {
    return Yup.object({
      addresses: Yup.array()
        .of(
          MovingJobAddressInputTypeValidationBuilder()
            .concat(
              Yup.object({
                addressId: Yup.string().required().label('label.addressId'),
              }),
            )
            .when(
              [],
              (
                // eslint-disable-next-line no-empty-pattern
                [],
                schema,
                {
                  value: {
                    unknownAddress,
                    primary: isPrimary,
                    addressType: isAddressType,
                  },
                },
              ) => {
                if (
                  unknownAddress === true ||
                  isPrimary !== primary ||
                  isAddressType !== addressType
                ) {
                  return Yup.object();
                }

                return schema;
              },
            ),
        )
        .required(),
    });
  }

  throw new Error('Not implemented');
};
