import { Uuid } from '@bas/value-objects';
import * as Yup from 'yup';

export interface CreateCreditInvoiceInputType {
  invoiceIdToCredit: Uuid;
  markAsPaid: boolean;
}

export const CreateCreditInvoiceInputTypeDefaultValues =
  (): CreateCreditInvoiceInputType => ({
    invoiceIdToCredit: '',
    markAsPaid: true,
  });

export const CreateCreditInvoiceInputTypeValidationBuilder =
  (): Yup.ObjectSchema<CreateCreditInvoiceInputType> =>
    Yup.object({
      invoiceIdToCredit: Yup.string()
        .required()
        .label('label.invoiceIdToCredit'),
      markAsPaid: Yup.boolean().required().label('label.markAsPaid'),
    });
