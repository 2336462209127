import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  question: {
    marginBottom: 4,
  },
  paddingBetweenRows: {
    paddingTop: 24,
  },
});
