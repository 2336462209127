import { Box } from '@bas/ui/native/base';
import {
  ReactHookFormNumberTextField,
  ReactHookFormTextField,
} from '@bas/ui/native/molecules';
import { StoredItemInputType } from '@bas/wms-domain/input-types';
import * as React from 'react';
import { ReactElement } from 'react';
import { useIntl } from 'react-intl';

const AddExtraStoredItemForm = (): ReactElement => {
  const { formatMessage } = useIntl();

  return (
    <Box flex={1} paddingHorizontal={20}>
      <Box flex={1} flexDirection="row" flexWrap="wrap" rowGap={3}>
        <Box flexBasis="100%">
          <ReactHookFormTextField<StoredItemInputType>
            name="description"
            label={formatMessage({ id: 'label.description' })}
            light
            multiline
          />
        </Box>
        <Box
          flexBasis={{
            phone: '100%',
            tablet: '50%',
          }}
          paddingRight={{
            phone: 0,
            tablet: 1,
          }}
        >
          <ReactHookFormTextField<StoredItemInputType>
            name="itemCode"
            label={formatMessage({ id: 'label.itemCode' })}
            light
            multiline
          />
        </Box>
        <Box
          flexBasis={{
            phone: '100%',
            tablet: '50%',
          }}
          paddingLeft={{
            phone: 0,
            tablet: 1,
          }}
        >
          <ReactHookFormTextField<StoredItemInputType>
            name="externalItemCode"
            label={formatMessage({ id: 'label.externalItemCode' })}
            light
            multiline
          />
        </Box>
        <Box flexBasis="50%" pr={1}>
          <ReactHookFormNumberTextField<StoredItemInputType>
            name="volume"
            autoComplete="off"
            label={formatMessage({ id: 'label.cubicMeter' })}
            keyboardType="decimal-pad"
            suffix="m³"
            fractionDigits={2}
            shouldBeZero
            light
            padZero={false}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AddExtraStoredItemForm;
