import { Uuid } from '@bas/value-objects';
import * as Yup from 'yup';
import {
  dayOffInputTypeValidationBuilder,
  DayOffOffInputType,
} from './dayOffInputType';
import {
  RequestTimeOffInputType,
  requestTimeOffInputTypeDefaultValues,
  requestTimeOffInputTypeValidationBuilder,
} from './requestTimeOffInputType';

export type CompleteSickLeaveInputType = Omit<
  RequestTimeOffInputType,
  'daysOff' | 'reason'
> & {
  employeeId: Uuid;
  reason?: string;
  daysOff: DayOffOffInputType[];
};

export const completeSickLeaveInputTypeDefaultValues =
  (): CompleteSickLeaveInputType => ({
    ...requestTimeOffInputTypeDefaultValues(),
    employeeId: '',
    daysOff: [],
  });

export const completeSickLeaveInputTypeValidationBuilder =
  (): Yup.ObjectSchema<CompleteSickLeaveInputType> =>
    requestTimeOffInputTypeValidationBuilder().concat(
      Yup.object({
        employeeId: Yup.string().required().label('label.employee'),
        reason: Yup.string().label('label.reason'),
        daysOff: Yup.array()
          .of(dayOffInputTypeValidationBuilder())
          .required()
          .label('label.daysOff'),
      }),
    );
