import { RelationType } from '@bas/crm-domain/models';
import { MovingJobTaxationCustomer } from '@bas/taxation-tool-domain/models';
import {
  Gender,
  HowDidYouFindUs,
  Language,
  Salutation,
} from '@bas/value-objects';
import * as Yup from 'yup';

export type CustomerDetailsFormStepInputType = {
  customer: MovingJobTaxationCustomer;
};

export const CustomerDetailsFormStepValidatorBuilder =
  (): Yup.ObjectSchema<CustomerDetailsFormStepInputType> =>
    Yup.object({
      customer: Yup.object({
        customerId: Yup.string(),
        relationType: Yup.mixed<RelationType>()
          .label('label.relationType')
          .oneOf(Object.values(RelationType))
          .required(),
        salutation: Yup.mixed<Salutation>()
          .label('label.salutation')
          .oneOf(Object.values(Salutation))
          .nullable(),
        gender: Yup.mixed<Gender>()
          .label('label.relationType')
          .oneOf(Object.values(Gender))
          .nullable(),
        firstName: Yup.string().label('label.firstName').nullable(),
        middleName: Yup.string().label('label.middleName').nullable(),
        lastName: Yup.string().label('label.lastName').required(),
        companyName: Yup.string().label('label.companyName').nullable(),
        emailAddress: Yup.string()
          .email()
          .label('label.emailAddress')
          .required(),
        phoneNumber: Yup.string().label('label.phoneNumber').phone().nullable(),
        mobileNumber: Yup.string()
          .label('label.mobileNumber')
          .phone()
          .nullable(),
        vatNumber: Yup.string().vatNumber().label('label.vatNumber').nullable(),
        language: Yup.mixed<Language>()
          .oneOf(Object.values(Language))
          .required(),
        howDidYouFindUs: Yup.mixed<HowDidYouFindUs>()
          .oneOf(Object.values(HowDidYouFindUs))
          .nullable()
          .label('label.howDidYouFindUs'),
        howDidYouFindUsOther: Yup.string()
          .label('label.howDidYouFindUsOther')
          .nullable(),
      }),
    });
