import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type RemoveAttachmentFromInvoiceMutationProps = {
  invoiceId: Uuid;
  attachmentId: Uuid;
};

export const RemoveAttachmentFromInvoiceMutation = async ({
  invoiceId,
  ...values
}: RemoveAttachmentFromInvoiceMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/invoices/${invoiceId}/remove-attachment`, {
    invoiceId,
    ...values,
  });

export const useRemoveAttachmentFromInvoiceMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveAttachmentFromInvoiceMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  RemoveAttachmentFromInvoiceMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveAttachmentFromInvoiceMutationProps
  >({
    mutationFn: RemoveAttachmentFromInvoiceMutation,
    throwOnError: false,
    ...options,
  });
