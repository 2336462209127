import * as React from 'react';
import {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { colors } from '@bas/theme';
import { Box } from '@bas/ui/native/base';
import { Gesture, GestureDetector } from 'react-native-gesture-handler';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
  WithSpringConfig,
} from 'react-native-reanimated';
import { View } from 'react-native';
import { LayoutChangeEvent } from 'react-native/Libraries/Types/CoreEventTypes';

export type ExpandableHeaderProps = {
  children: ReactNode;
  alwaysVisibleChildren: ReactNode;
  maxHeight: number;
  onAlwaysVisibleHeightChange?: (height: number) => void;
};

const DRAG_BUFFER = 40;

const defaultSpringConfig: WithSpringConfig = {
  damping: 150,
  mass: 0.3,
  stiffness: 120,
  overshootClamping: true,
  restSpeedThreshold: 0.3,
  restDisplacementThreshold: 0.3,
};

const HANDLE_HEIGHT = 25;

const ExpandableHeader = ({
  children,
  alwaysVisibleChildren,
  maxHeight,
  onAlwaysVisibleHeightChange,
}: ExpandableHeaderProps): ReactElement => {
  const [alwaysVisibleHeight, setAlwaysVisibleHeight] = useState(64);

  const minHeight = useMemo(
    () => alwaysVisibleHeight + HANDLE_HEIGHT,
    [alwaysVisibleHeight],
  );

  const sheetHeight = useSharedValue(0);
  const startHeight = useSharedValue(0);

  useEffect(() => {
    if (alwaysVisibleHeight === 0) {
      return;
    }

    if (sheetHeight.value === 0) {
      sheetHeight.value = minHeight;
    }

    if (startHeight.value === 0) {
      sheetHeight.value = minHeight;
    }
  }, [alwaysVisibleHeight, minHeight, startHeight.value, sheetHeight]);

  const handleLayoutAlwaysVisible = useCallback((event: LayoutChangeEvent) => {
    setAlwaysVisibleHeight(event.nativeEvent.layout.height || 64);
  }, []);

  useEffect(() => {
    onAlwaysVisibleHeightChange?.(alwaysVisibleHeight);
  }, [alwaysVisibleHeight, onAlwaysVisibleHeightChange]);

  const tap = Gesture.Tap()
    .maxDuration(250)
    .onStart(() => {
      if (sheetHeight.value === maxHeight) {
        sheetHeight.value = withSpring(minHeight, defaultSpringConfig);
        startHeight.value = minHeight;
      } else {
        sheetHeight.value = withSpring(maxHeight, defaultSpringConfig);
        startHeight.value = maxHeight;
      }
    });

  const pan = Gesture.Pan()
    .minDistance(DRAG_BUFFER)
    .onStart((event) => {
      startHeight.value = sheetHeight.value;
    })
    .onUpdate((event) => {
      const newHeight = startHeight.value + event.translationY + DRAG_BUFFER;
      if (newHeight > minHeight) {
        sheetHeight.value = newHeight;
      } else {
        sheetHeight.value = minHeight;
      }
    })
    .onEnd((event) => {
      const newHeight = startHeight.value + event.translationY;
      if (newHeight > startHeight.value) {
        startHeight.value = maxHeight;
        sheetHeight.value = withSpring(maxHeight, defaultSpringConfig);
      } else {
        startHeight.value = minHeight;
        sheetHeight.value = withSpring(minHeight, defaultSpringConfig);
      }
    });

  const sheetHeightAnimatedStyle = useAnimatedStyle(() => ({
    height: sheetHeight.value,
  }));

  const contentAnimatedStyle = useAnimatedStyle(() => ({
    height: sheetHeight.value - alwaysVisibleHeight - 50,
  }));

  const handleStyle = useAnimatedStyle(() => ({
    top: sheetHeight.value - HANDLE_HEIGHT,
  }));

  return (
    <Animated.View
      style={[
        {
          backgroundColor: colors.lila[200],
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          minHeight,
          overflow: 'hidden',
          zIndex: 2,
        },
        sheetHeightAnimatedStyle,
      ]}
    >
      <Box position="relative">
        <Box onLayout={handleLayoutAlwaysVisible}>{alwaysVisibleChildren}</Box>
        <Box height={25} />
        <GestureDetector gesture={Gesture.Race(tap, pan)}>
          <Animated.View
            style={[
              {
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: 10,
                paddingBottom: 10,
                position: 'absolute',
                backgroundColor: colors.lila[200],
                height: 25,
                left: 0,
                right: 0,
                zIndex: 5,
              },
              handleStyle,
            ]}
          >
            <View
              style={{
                width: '15%',
                minWidth: 51,
                maxWidth: 121,
                height: 8,
                borderRadius: 100,
                backgroundColor: colors.lila[600],
              }}
            />
          </Animated.View>
        </GestureDetector>

        <Box paddingBottom={2} paddingHorizontal={20} alignItems="center">
          <Animated.View
            style={[{ width: '100%', maxWidth: 500 }, contentAnimatedStyle]}
          >
            {children}
          </Animated.View>
        </Box>
      </Box>
    </Animated.View>
  );
};

export default ExpandableHeader;
