import { EnterEventHoursInputType } from '@bas/planning-domain/input-types';
import { calculateTimeOfDaySliderValues } from '@bas/shared/utils';
import { colors } from '@bas/theme';
import { BlueSelectButton } from '@bas/ui/native/atoms';
import { Box, Typography } from '@bas/ui/native/base';
import {
  BlueSelectButtonsGroup,
  ReactHookFormTextField,
  ReactHookFormTimeSliderField,
} from '@bas/ui/native/molecules';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import dayjs from 'dayjs';
import { memo, ReactElement, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage, FormattedTime, useIntl } from 'react-intl';
import { StyleSheet, ViewProps } from 'react-native';

export type EnterEventHoursFormProps = ViewProps & {
  showTravelTime?: boolean;
  askTravelTime?: boolean;
  hideDescription?: boolean;
  forceOverrideTime?: boolean;
};

const breakTimes = [
  0, 0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.25, 2.5, 2.75, 3, 3.25, 3.5,
  3.75, 4, 4.25, 4.5, 4.75, 5, 5.25, 5.5, 5.75, 6, 6.25, 6.5, 6.75, 7, 7.25,
  7.5, 7.75, 8, 8.25, 8.5, 8.75, 9,
];

const styles = StyleSheet.create({
  question: {
    paddingBottom: 24,
    paddingLeft: 20,
    paddingRight: 20,
    textAlign: 'center',
  },
  paddingUnderQuestion: {
    paddingBottom: 54,
  },
  reasonPadding: {
    paddingBottom: 62,
  },
  timeSlider: {
    paddingBottom: 31,
  },
});
const EnterEventHoursForm = ({
  style,
  showTravelTime,
  askTravelTime,
  hideDescription,
  forceOverrideTime,
  ...props
}: EnterEventHoursFormProps): ReactElement => {
  const { formatMessage } = useIntl();
  const { startDate, endDate, travelStartTime, travelEndTime } = useWatch<
    EnterEventHoursInputType,
    'eventData'
  >({ name: 'eventData' });
  const { setValue } = useFormContext();
  const overrideTimeValue = useWatch<EnterEventHoursInputType, 'overrideTime'>({
    name: 'overrideTime',
  });

  const overrideTravelTime = useWatch<EnterEventHoursInputType>({
    name: 'overrideTravelTime',
  });

  const timeOfDayValues = useMemo(() => calculateTimeOfDaySliderValues(15), []);

  const timeIsInvalid = useMemo(
    () =>
      startDate &&
      endDate &&
      dayjs(startDate).isValid() &&
      dayjs(endDate).isValid() &&
      dayjs(startDate).isAfter(endDate),
    [endDate, startDate],
  );

  const overrideTime = useMemo(
    () => overrideTimeValue || timeIsInvalid || forceOverrideTime,
    [overrideTimeValue, timeIsInvalid, forceOverrideTime],
  );

  const blockTravelTime = useMemo(
    () => !travelStartTime || !travelEndTime,
    [travelEndTime, travelStartTime],
  );

  return (
    <Box flex={1} flexDirection="row" flexWrap="wrap" {...props}>
      {!hideDescription && (
        <Box flexBasis="100%">
          <Typography
            overrideColor={colors.white}
            variant="h5"
            style={styles.question}
          >
            <FormattedMessage
              id={`mobileApp.event.fillInHours.${
                !forceOverrideTime
                  ? 'theEventWasFromXUntilX'
                  : 'theEventWasFromXUntilXButTheEndTimeIsBeforeTheStartTime'
              }`}
              values={{
                startTime: (
                  <Typography
                    overrideColor={colors.white}
                    variant="h5"
                    fontWeight={700}
                  >
                    {startDate && <FormattedTime value={startDate} />}
                  </Typography>
                ),
                endTime: (
                  <Typography
                    overrideColor={colors.white}
                    variant="h5"
                    fontWeight={700}
                  >
                    {endDate && <FormattedTime value={endDate} />}
                  </Typography>
                ),
              }}
            />
          </Typography>
        </Box>
      )}
      {travelStartTime && travelEndTime && (
        <Box flexBasis="100%">
          <Typography
            overrideColor={colors.white}
            variant="h5"
            style={styles.question}
          >
            <FormattedMessage
              id="mobileApp.event.fillInHours.theTravelTimeWasFromXUntilX"
              values={{
                startTime: (
                  <Typography
                    overrideColor={colors.white}
                    variant="h5"
                    fontWeight={700}
                  >
                    <FormattedTime value={travelStartTime} />
                  </Typography>
                ),
                endTime: (
                  <Typography
                    overrideColor={colors.white}
                    variant="h5"
                    fontWeight={700}
                  >
                    <FormattedTime value={travelEndTime} />
                  </Typography>
                ),
              }}
            />
          </Typography>
        </Box>
      )}

      {!forceOverrideTime && !timeIsInvalid && (
        <Box flexBasis="100%" paddingHorizontal={20} marginBottom={4}>
          <BlueSelectButtonsGroup
            buttons={[
              <BlueSelectButton
                notSelected={
                  overrideTimeValue !== undefined && overrideTimeValue !== false
                }
                onSelect={() => {
                  setValue('overrideTime', false, {
                    shouldDirty: true,
                    shouldTouch: true,
                    shouldValidate: true,
                  });
                }}
                icon={faCheckCircle}
              >
                <FormattedMessage id="label.correct" />
              </BlueSelectButton>,
              <BlueSelectButton
                notSelected={
                  overrideTimeValue !== undefined && overrideTimeValue !== true
                }
                onSelect={() =>
                  setValue('overrideTime', true, {
                    shouldDirty: true,
                    shouldTouch: true,
                    shouldValidate: true,
                  })
                }
                icon={faTimes}
              >
                <FormattedMessage id="label.no" />
              </BlueSelectButton>,
            ]}
          />
        </Box>
      )}
      {overrideTime && (
        <>
          <Box flexBasis="100%">
            <ReactHookFormTimeSliderField
              name="startDate"
              label={formatMessage({ id: 'label.startTime' })}
              values={timeOfDayValues}
              light
              style={styles.timeSlider}
            />
          </Box>
          <Box flexBasis="100%">
            <ReactHookFormTimeSliderField
              name="endDate"
              label={formatMessage({ id: 'label.endTime' })}
              values={timeOfDayValues}
              light
              style={styles.timeSlider}
            />
          </Box>
        </>
      )}

      {!blockTravelTime && overrideTime && showTravelTime && askTravelTime && (
        <Box
          paddingHorizontal={{
            phone: 20,
            tablet: 50,
          }}
          flexBasis="100%"
        >
          <Typography
            overrideColor={colors.white}
            variant="h5"
            style={styles.question}
          >
            <FormattedMessage id="mobileApp.event.fillInHours.didYouHaveToTravel" />
          </Typography>
          <BlueSelectButtonsGroup
            style={styles.paddingUnderQuestion}
            buttons={[
              <BlueSelectButton
                notSelected={
                  overrideTravelTime !== undefined &&
                  overrideTravelTime !== true
                }
                onSelect={() =>
                  setValue('overrideTravelTime', true, {
                    shouldDirty: true,
                    shouldTouch: true,
                    shouldValidate: true,
                  })
                }
                icon={faCheckCircle}
              >
                <FormattedMessage id="label.yes" />
              </BlueSelectButton>,
              <BlueSelectButton
                notSelected={
                  overrideTravelTime !== undefined &&
                  overrideTravelTime !== false
                }
                onSelect={() =>
                  setValue('overrideTravelTime', false, {
                    shouldDirty: true,
                    shouldTouch: true,
                    shouldValidate: true,
                  })
                }
                icon={faTimes}
              >
                <FormattedMessage id="label.no" />
              </BlueSelectButton>,
            ]}
          />
        </Box>
      )}
      {overrideTime &&
        (!askTravelTime || overrideTravelTime) &&
        !blockTravelTime &&
        showTravelTime && (
          <>
            <Box>
              <ReactHookFormTimeSliderField
                name="travelStartTime"
                label={formatMessage({ id: 'label.departureTime' })}
                values={timeOfDayValues}
                light
                style={styles.timeSlider}
              />
            </Box>
            <Box>
              <ReactHookFormTimeSliderField
                name="travelEndTime"
                label={formatMessage({
                  id: 'label.arrivalTimeWarehouse',
                })}
                values={timeOfDayValues}
                light
                style={styles.timeSlider}
              />
            </Box>
          </>
        )}
      <Box flexBasis="100%">
        <ReactHookFormTimeSliderField
          name="breakTime"
          label={formatMessage({ id: 'label.breakTime' })}
          values={breakTimes}
          light
          style={styles.timeSlider}
        />
      </Box>
      <Box
        style={[styles.reasonPadding]}
        flex={1}
        paddingHorizontal={{
          phone: 20,
        }}
      >
        <ReactHookFormTextField
          name="reason"
          label={formatMessage({ id: 'label.reason' })}
          autoCapitalize="none"
          autoCorrect={false}
          multiline
          light
        />
      </Box>
    </Box>
  );
};

const MemoComponent = memo(EnterEventHoursForm, () => true);
export default MemoComponent;
