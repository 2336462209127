import {
  PasswordInputType,
  ProfileInputType,
} from '@bas/authentication-domain/input-types';
import {
  useChangeUserPasswordMutation,
  useRegisterPassKeyMutation,
  useStartRegisterPassKeyMutation,
  useUpdateUserMutation,
} from '@bas/authentication-domain/mutations';
import {
  ChangePasswordBottomSheet,
  ChangeProfileBottomSheet,
} from '@bas/authentication-domain/native/organisms';
import { usePassKeysRequest } from '@bas/authentication-domain/requests';
import { useUserStore } from '@bas/shared/state';
import { colors } from '@bas/theme';
import {
  Alert,
  Button,
  MobileActionButton,
  Skeleton,
} from '@bas/ui/native/atoms';
import {
  BottomSheetMethods,
  Box,
  IconWithSpin,
  Typography,
} from '@bas/ui/native/base';
import { useHandleOpenIntercom, useLogout } from '@bas/ui/native/hooks';
import { MobileActionButtonsGroup } from '@bas/ui/native/molecules';
import { Language } from '@bas/value-objects';
import { faIntercom } from '@fortawesome/free-brands-svg-icons/faIntercom';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons/faSpinnerThird';
import { faAddressCard } from '@fortawesome/pro-light-svg-icons/faAddressCard';
import { faUnlock } from '@fortawesome/pro-light-svg-icons/faUnlock';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { captureException } from '@sentry/react';
import { startRegistration } from '@simplewebauthn/browser';
import { RegistrationResponseJSON } from '@simplewebauthn/types';
import base64url from 'base64url';
import dayjs from 'dayjs';
import * as Notifications from 'expo-notifications';
import { StatusBar } from 'expo-status-bar';
import * as Updates from 'expo-updates';
import { encode } from 'js-base64';
import * as React from 'react';
import { ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import {
  FormattedDate,
  FormattedMessage,
  FormattedRelativeTime,
  useIntl,
} from 'react-intl';
import { Platform, ScrollView, StyleSheet, View } from 'react-native';
import { Passkey, PasskeyCreateResult } from 'react-native-passkey';
import { PublicKeyCredentialDescriptor } from 'react-native-passkey/lib/typescript/PasskeyTypes';
import { SafeAreaView } from 'react-native-safe-area-context';

const isSupported: boolean = Passkey.isSupported();

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 14,
  },
  flexContainer: {
    flex: 1,
    gap: 24,
    paddingTop: 8,
  },
  actionsContainer: {
    marginBottom: 28,
    display: 'flex',
    flex: 10,
    flexDirection: 'row',
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  halfWidth: {
    flexBasis: '50%',
    flexGrow: 1,
  },
  newsContainer: {
    marginBottom: 10,
  },
  readTitle: {
    marginTop: 29,
    marginBottom: 8,
  },
});

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: false,
  }),
});

const MobileEmployeeProfileScreen = (): ReactElement => {
  const userState = useUserStore((state) => state.user);
  const setUserState = useUserStore((state) => state.setUser);
  const [isUpdating, setIsUpdating] = useState(false);
  const changeProfileRef = useRef<BottomSheetModal & BottomSheetMethods>(null);
  const changePasswordRef = useRef<BottomSheetModal & BottomSheetMethods>(null);
  const logout = useLogout();
  const {
    currentlyRunning,
    availableUpdate,
    isUpdateAvailable,
    isUpdatePending,
    isChecking,
    isDownloading,
    downloadedUpdate,
    lastCheckForUpdateTimeSinceRestart,
    downloadError,
    initializationError,
    checkError,
  } = Updates.useUpdates();

  const { mutateAsync: updateUserMutation } = useUpdateUserMutation();
  const { mutateAsync: changeUserPasswordMutation } =
    useChangeUserPasswordMutation();

  const openIntercom = useHandleOpenIntercom();

  const handleOpenIntercom = useCallback(() => {
    openIntercom();
  }, [openIntercom]);

  const handleOpenChangeProfileForm = useCallback(() => {
    changeProfileRef.current?.present();
  }, [changeProfileRef]);

  const handleOpenChangePasswordForm = useCallback(() => {
    changePasswordRef.current?.present();
  }, [changePasswordRef]);

  const checkForUpdates = useCallback(async () => {
    await Updates.checkForUpdateAsync();
  }, []);

  const update = useCallback(async () => {
    setIsUpdating(true);
    await Updates.fetchUpdateAsync();
    await Updates.reloadAsync();
    setIsUpdating(false);
  }, []);

  const handleUpdateProfile = useCallback(
    async ({
      emailAddress,
      firstName,
      middleName,
      lastName,
    }: ProfileInputType) => {
      if (!userState) {
        return;
      }

      await updateUserMutation({
        userId: userState.userId,
        language: userState.language.toLocaleLowerCase() as Language,
        firstName,
        middleName,
        lastName,
        emailAddress: emailAddress || userState.emailAddress,
      });

      if (emailAddress !== userState.emailAddress) {
        await logout();
      }

      const fullName = [firstName, middleName, lastName]
        .filter((v) => !!v)
        .join(' ');

      setUserState({
        ...userState,
        emailAddress: emailAddress || userState.emailAddress,
        personName: { firstName, middleName, lastName, fullName },
      });
    },
    [logout, setUserState, updateUserMutation, userState],
  );

  const handleUpdatePassword = useCallback(
    async (values: PasswordInputType) => {
      if (!userState) {
        return;
      }

      await changeUserPasswordMutation({
        userId: userState.userId,
        ...values,
      });

      setUserState({
        ...userState,
        passwordChangedAt: new Date(),
      });
    },
    [changeUserPasswordMutation, setUserState, userState],
  );

  const {
    data: passKeysData,
    isPending: isPassKeysPending,
    refetch,
  } = usePassKeysRequest();

  const { mutateAsync: startRegisterPasskeyMutation } =
    useStartRegisterPassKeyMutation();
  const { mutateAsync: registerPassKeyMutation } = useRegisterPassKeyMutation({
    onSuccess: () => {
      refetch();
    },
  });

  const passkeys = useMemo(
    () => passKeysData?.data?.member || [],
    [passKeysData],
  );
  const { formatMessage } = useIntl();

  const handleStartRegisterPassKey = useCallback(async () => {
    const response = await startRegisterPasskeyMutation({});
    try {
      let attestationResponse: RegistrationResponseJSON | PasskeyCreateResult;

      if (Platform.OS === 'web') {
        attestationResponse = await startRegistration({
          optionsJSON: response.data,
        });
      } else {
        attestationResponse = await Passkey.create({
          challenge: encode(response.data.challenge),
          attestation: response.data.attestation,
          pubKeyCredParams: response.data.pubKeyCredParams,
          rp: response.data.rp as {
            id: string;
            name: string;
          },
          user: response.data.user,
          authenticatorSelection: response.data.authenticatorSelection,
          excludeCredentials: response.data
            .excludeCredentials as PublicKeyCredentialDescriptor[],
          extensions: {},
          timeout: response.data.timeout,
        });
      }

      if (attestationResponse) {
        await registerPassKeyMutation({
          authenticatorAttachment: undefined,
          clientExtensionResults: {
            hmacCreateSecret: undefined,
            appid: undefined,
            credProps: undefined,
          },
          id: base64url.fromBase64(attestationResponse.id),
          rawId: attestationResponse.rawId,
          type: 'public-key' as const,
          response: {
            clientDataJSON: base64url.fromBase64(
              attestationResponse.response.clientDataJSON,
            ),
            attestationObject: attestationResponse.response.attestationObject,
          },
          device: Platform.OS !== 'web' ? 'native' : 'web',
        });
      }
    } catch (error) {
      await Notifications.scheduleNotificationAsync({
        content: {
          title: formatMessage({ id: 'errorFallback.oopsSomethingWentWrong' }),
        },
        trigger: null,
      });
      captureException(error);
    }
  }, [formatMessage, registerPassKeyMutation, startRegisterPasskeyMutation]);

  return (
    <SafeAreaView style={styles.flexContainer}>
      {/* eslint-disable-next-line react/style-prop-object */}
      <StatusBar style="dark" />
      <Box style={styles.container}>
        <ScrollView
          style={styles.flexContainer}
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: 'flex-start',
          }}
        >
          <Box>
            <MobileActionButtonsGroup
              style={styles.actionsContainer}
              buttons={[
                <MobileActionButton
                  icon={faAddressCard}
                  onPress={handleOpenChangeProfileForm}
                >
                  <FormattedMessage id="mobileApp.changeProfile" />
                </MobileActionButton>,
                <MobileActionButton
                  icon={faUnlock}
                  onPress={handleOpenChangePasswordForm}
                >
                  <FormattedMessage id="mobileApp.changePassword" />
                </MobileActionButton>,
              ]}
            />
          </Box>
          <Box gap={3}>
            <Box>
              <Typography variant="h5" fontWeight={700}>
                <FormattedMessage id="mobileApp.profile" />
              </Typography>
              <Box>
                <Typography style={styles.halfWidth}>
                  {userState?.personName.fullName}
                </Typography>
                <Typography style={styles.halfWidth} />
                <Typography style={styles.halfWidth}>
                  {userState?.emailAddress}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Typography variant="h5" fontWeight={700}>
                <FormattedMessage id="label.password" />
              </Typography>
              <View>
                {userState?.passwordChangedAt && (
                  <Typography>
                    <FormattedMessage
                      id="label.passwordWasLastChangedXAgo"
                      values={{
                        ago: (
                          <FormattedRelativeTime
                            value={
                              dayjs(userState?.passwordChangedAt).unix() -
                              dayjs().unix()
                            }
                            numeric="auto"
                            // eslint-disable-next-line react/style-prop-object
                            style="long"
                            updateIntervalInSeconds={1}
                          />
                        ),
                      }}
                    />
                  </Typography>
                )}
              </View>
            </Box>
            <Box>
              <Typography variant="h5" fontWeight={700}>
                <FormattedMessage id="label.passKeyAuthentication" />
              </Typography>
              <Box>
                {isPassKeysPending && (
                  <Typography>
                    <Skeleton width={100} height={20} />
                  </Typography>
                )}
                {!isPassKeysPending && (
                  <>
                    {passkeys.length === 0 && (
                      <Typography>
                        <FormattedMessage id="label.noPassKeysRegistered" />
                      </Typography>
                    )}
                    {passkeys.length > 0 && (
                      <>
                        <Typography variant="subtitle1">
                          <FormattedMessage id="label.passKeysRegistered" />
                        </Typography>
                        <Box>
                          {passkeys.map((passKey) => (
                            <Box
                              key={passKey.webauthnCredentialId}
                              pt={1}
                              pb={1}
                            >
                              <Typography style={{ width: '100%' }}>
                                <FormattedMessage id="label.created" />:{' '}
                                <FormattedDate
                                  value={passKey.createdAt}
                                  timeStyle="short"
                                  dateStyle="long"
                                />
                              </Typography>
                              <Typography style={{ width: '100%' }}>
                                <FormattedMessage id="label.lastUsedAt" />:{' '}
                                {passKey.lastUsedAt ? (
                                  <FormattedRelativeTime
                                    value={
                                      dayjs(passKey.lastUsedAt).unix() -
                                      dayjs().unix()
                                    }
                                    numeric="auto"
                                    // eslint-disable-next-line react/style-prop-object
                                    style="long"
                                    updateIntervalInSeconds={1}
                                  />
                                ) : (
                                  <FormattedMessage id="label.notYetUsed" />
                                )}
                              </Typography>
                            </Box>
                          ))}
                        </Box>
                      </>
                    )}
                    {(Platform.OS === 'web' || isSupported) && (
                      <Box maxWidth={250}>
                        <Button onPress={handleStartRegisterPassKey}>
                          <FormattedMessage id="button.registerNewPassKey" />
                        </Button>
                      </Box>
                    )}
                  </>
                )}
              </Box>
            </Box>
            <Box pb={3}>
              <Typography variant="h5" fontWeight={700}>
                <FormattedMessage id="label.getHelp" />
              </Typography>
              <Box>
                <Button
                  mt={1}
                  onPress={handleOpenIntercom}
                  maxWidth={175}
                  icon={faIntercom}
                >
                  <FormattedMessage id="button.openIntercom" />
                </Button>
                {Platform.OS !== 'web' && (
                  <Box pt={3}>
                    <Typography variant="h5" fontWeight={700}>
                      Informatie voor de helpdesk
                    </Typography>
                    {(downloadError || initializationError || checkError) && (
                      <Alert severity="error">
                        {downloadError?.message ||
                          initializationError?.message ||
                          checkError?.message}
                      </Alert>
                    )}
                    {(isDownloading || isUpdating || isChecking) && (
                      <Box pt={3} pb={3}>
                        <Typography>
                          {isDownloading && 'Update wordt gedownload'}
                          {isUpdating && 'Update wordt geïnstalleerd'}
                          {isChecking && 'Controleren op updates'}
                        </Typography>
                        <Box height={72} width={72}>
                          <IconWithSpin
                            size={72}
                            icon={faSpinnerThird}
                            overrideColor={colors.blue[800]}
                            secondaryColor={colors.blue[500]}
                          />
                        </Box>
                      </Box>
                    )}
                    {(availableUpdate || downloadedUpdate) && (
                      <Box pt={3} pb={3}>
                        <Typography>
                          Update beschikbaar:{' '}
                          {(availableUpdate || downloadedUpdate)?.updateId}
                        </Typography>
                        <Button onPress={update} maxWidth={205}>
                          Update
                        </Button>
                      </Box>
                    )}
                    <Typography>
                      Version: {currentlyRunning.runtimeVersion}
                    </Typography>
                    <Typography>Update: {currentlyRunning.updateId}</Typography>
                    <Typography>Channel: {currentlyRunning.channel}</Typography>
                    <Typography>
                      Embedded: {currentlyRunning.isEmbeddedLaunch}
                    </Typography>
                    <Typography>
                      Emergency: {currentlyRunning.isEmergencyLaunch}
                    </Typography>
                    <Typography>
                      Laatst gecontroleerd op updates op:{' '}
                      {lastCheckForUpdateTimeSinceRestart && (
                        <FormattedDate
                          value={lastCheckForUpdateTimeSinceRestart}
                          timeStyle="short"
                          dateStyle="medium"
                        />
                      )}
                    </Typography>
                    <Button
                      mt={1}
                      onPress={checkForUpdates}
                      maxWidth={260}
                      disabled={isUpdateAvailable || isUpdatePending}
                      loading={isChecking}
                    >
                      Controleer op updates
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </ScrollView>
      </Box>
      {userState && (
        <ChangeProfileBottomSheet
          ref={changeProfileRef}
          onSubmit={handleUpdateProfile}
          user={userState}
        />
      )}
      <ChangePasswordBottomSheet
        onSubmit={handleUpdatePassword}
        ref={changePasswordRef}
      />
    </SafeAreaView>
  );
};
export default MobileEmployeeProfileScreen;
