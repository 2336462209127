{
  "nm": "Comp 1",
  "ddd": 0,
  "h": 512,
  "w": 512,
  "meta": { "g": "@lottiefiles/toolkit-js 0.33.2" },
  "layers": [
    {
      "ty": 4,
      "nm": "Layer 9",
      "sr": 1,
      "st": 0,
      "op": 180.00000733155,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 1,
      "ks": {
        "a": { "a": 0, "k": [165.127, -168.309, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [352.694, 176.901, 0],
              "t": 0,
              "ti": [-9.924, 14.04, 0],
              "to": [16.384, -5.211, 0]
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [393.595, 146.971, 0],
              "t": 23.216,
              "ti": [-0.857, 8.28, 0],
              "to": [4.805, -6.798, 0]
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [403.042, 124.15, 0],
              "t": 29.3,
              "ti": [6.009, 5.762, 0],
              "to": [0.857, -8.28, 0]
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [395.499, 101.24, 0],
              "t": 34.945,
              "ti": [7.13, -4.297, 0],
              "to": [-6.009, -5.762, 0]
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [372.511, 97.88, 0],
              "t": 40.459,
              "ti": [-1.175, -4.872, 0],
              "to": [-4.292, 2.586, 0]
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [366.308, 110.482, 0],
              "t": 43.826,
              "ti": [-4.167, -1.293, 0],
              "to": [1.023, 4.242, 0]
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [375.436, 119.036, 0],
              "t": 46.851,
              "ti": [-5.321, 2.333, 0],
              "to": [5.55, 1.721, 0]
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [392.441, 117.632, 0],
              "t": 51.109,
              "ti": [-3.98, 4.234, 0],
              "to": [5.321, -2.333, 0]
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [406.319, 107.283, 0],
              "t": 55.826,
              "ti": [-5.803, 10.794, 0],
              "to": [8.394, -8.93, 0]
            },
            { "s": [426.416, 76.629, 0], "t": 75.0000030548126 }
          ],
          "ix": 2
        },
        "r": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [19],
              "t": 0
            },
            { "s": [49], "t": 75.0000030548126 }
          ],
          "ix": 10
        },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [176.999, -174.841],
                    [168.979, -181.128],
                    [164.202, -167.325]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.0471, 0.251, 0.651], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 2",
          "ix": 2,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [156.848, -190.635],
                    [201.921, -204.973],
                    [168.979, -181.128],
                    [164.202, -167.325]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.0078, 0.1059, 0.3059], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 3",
          "ix": 3,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [144.994, -199.927],
                    [208.26, -210.289],
                    [192.964, -162.328]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.2275, 0.4706, 0.949], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 1
    },
    {
      "ty": 4,
      "nm": "Layer 8",
      "sr": 1,
      "st": 0,
      "op": 180.00000733155,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [133.555, -129.235, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [389.555, 126.765, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [-9.924, 14.04],
                    [-0.857, 8.28],
                    [6.009, 5.762],
                    [7.13, -4.297],
                    [-1.175, -4.872],
                    [-4.167, -1.293],
                    [-5.321, 2.333],
                    [-3.98, 4.234],
                    [-5.803, 10.794]
                  ],
                  "o": [
                    [16.384, -5.211],
                    [4.805, -6.798],
                    [0.857, -8.28],
                    [-6.009, -5.762],
                    [-4.292, 2.586],
                    [1.023, 4.242],
                    [5.55, 1.721],
                    [5.321, -2.333],
                    [8.394, -8.93],
                    [0, 0]
                  ],
                  "v": [
                    [96.694, -79.099],
                    [137.595, -109.029],
                    [147.041, -131.85],
                    [139.499, -154.76],
                    [116.511, -158.12],
                    [110.308, -145.518],
                    [119.436, -136.964],
                    [136.441, -138.368],
                    [150.319, -148.717],
                    [170.416, -179.371]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 3, "ix": 5 },
              "d": [
                { "nm": "dash", "n": "d", "v": { "a": 0, "k": 9, "ix": 1 } }
              ],
              "c": { "a": 0, "k": [0.2275, 0.4706, 0.949], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "tm",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Filter - Trim",
          "nm": "Trim Paths 1",
          "ix": 2,
          "e": {
            "a": 1,
            "k": [
              {
                "o": { "x": 0.333, "y": 0 },
                "i": { "x": 0.667, "y": 1 },
                "s": [0],
                "t": 0
              },
              { "s": [100], "t": 75.0000030548126 }
            ],
            "ix": 2
          },
          "o": { "a": 0, "k": 0, "ix": 3 },
          "s": { "a": 0, "k": 0, "ix": 1 },
          "m": 1
        }
      ],
      "ind": 2
    },
    {
      "ty": 4,
      "nm": "Layer 6",
      "sr": 1,
      "st": 0,
      "op": 180.00000733155,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [114.174, 129.844, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [370.174, 385.844, 0],
              "t": 120,
              "ti": [0, 0, 0],
              "to": [0, -3.333, 0]
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [370.174, 365.844, 0],
              "t": 142.5,
              "ti": [0, -3.333, 0],
              "to": [0, 0, 0]
            },
            { "s": [370.174, 385.844, 0], "t": 165.000006720588 }
          ],
          "ix": 2
        },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [33.392, 174.088],
                    [35.847, 176.724],
                    [40.3, 173.349],
                    [40.3, 186.377],
                    [44.585, 186.377],
                    [44.585, 173.353],
                    [48.601, 176.396],
                    [51.382, 174.005],
                    [42.44, 167.23]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 0.6863, 0.4], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 2",
          "ix": 2,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [53.846, 174.088],
                    [56.3, 176.724],
                    [60.753, 173.349],
                    [60.753, 186.377],
                    [65.039, 186.377],
                    [65.039, 173.353],
                    [69.054, 176.396],
                    [71.836, 174.005],
                    [62.893, 167.23]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 0.6863, 0.4], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 3",
          "ix": 3,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [115.053, 180.012],
                    [93.028, 180.012],
                    [93.028, 182.75],
                    [115.053, 182.75]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.7137, 0.7804, 0.9804], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 4",
          "ix": 4,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [149.076, 175.318],
                    [93.029, 175.318],
                    [93.029, 172.58],
                    [149.076, 172.58]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.7137, 0.7804, 0.9804], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 5",
          "ix": 5,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [149.076, 167.886],
                    [93.029, 167.886],
                    [93.029, 165.148],
                    [149.076, 165.148]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.7137, 0.7804, 0.9804], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 6",
          "ix": 6,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [158.305, 189.048],
                    [83.799, 189.048],
                    [83.799, 159.789],
                    [158.305, 159.789]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 1, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 7",
          "ix": 7,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [56.384, 114.137],
                    [30.155, 114.137],
                    [30.155, 107.067],
                    [56.384, 107.067]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 0.6863, 0.4], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 8",
          "ix": 8,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [65.6, 103.498],
                    [30.155, 103.498],
                    [30.155, 96.428],
                    [65.6, 96.428]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 0.6863, 0.4], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 9",
          "ix": 9,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [141.27, 59.69],
                    [114.912, 82.528],
                    [79.244, 82.528],
                    [108.181, 59.69]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 0.2902, 0.3765], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 10",
          "ix": 10,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [114.912, 82.528],
                    [114.237, 112.633],
                    [79.577, 112.633],
                    [79.244, 82.528]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 0.2902, 0.3765], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 11",
          "ix": 11,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [170.222, 82.53],
                    [203.574, 62.746]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 1,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 2, "ix": 5 },
              "c": { "a": 0, "k": [0, 0, 0], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 12",
          "ix": 12,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [208.358, 59.688],
                    [148.423, 59.688],
                    [70.915, 59.688],
                    [19.99, 82.53],
                    [123.634, 82.53],
                    [170.222, 82.53]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 0.6863, 0.4], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 13",
          "ix": 13,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [170.222, 82.53],
                    [208.358, 59.688],
                    [207.312, 180.598],
                    [170.222, 200]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 0.6863, 0.4], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 14",
          "ix": 14,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [170.224, 82.528],
                    [170.224, 199.996],
                    [19.993, 199.996],
                    [19.993, 82.528],
                    [79.244, 82.528],
                    [79.577, 112.633],
                    [114.237, 112.633],
                    [114.912, 82.528]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 0.749, 0.2784], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 3
    },
    {
      "ty": 4,
      "nm": "Layer 5",
      "sr": 1,
      "st": 0,
      "op": 180.00000733155,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [166.377, 36.442, 0], "ix": 1 },
        "s": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [0, 0, 100],
              "t": 165
            },
            { "s": [100, 100, 100], "t": 170.000006924242 }
          ],
          "ix": 6
        },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [422.377, 292.442, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, -6.365],
                    [6.365, 0],
                    [0, 6.365],
                    [-6.365, 0]
                  ],
                  "o": [
                    [0, 6.365],
                    [-6.365, 0],
                    [0, -6.365],
                    [6.365, 0]
                  ],
                  "v": [
                    [176.627, 36.442],
                    [165.102, 47.967],
                    [153.577, 36.442],
                    [165.102, 24.917]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.3137, 0.6157, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 2",
          "ix": 2,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, -6.365],
                    [6.365, 0],
                    [0, 6.365],
                    [-6.365, 0]
                  ],
                  "o": [
                    [0, 6.365],
                    [-6.365, 0],
                    [0, -6.365],
                    [6.365, 0]
                  ],
                  "v": [
                    [179.176, 36.442],
                    [167.651, 47.967],
                    [156.126, 36.442],
                    [167.651, 24.917]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0, 0, 0], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 4
    },
    {
      "ty": 4,
      "nm": "Layer 4",
      "sr": 1,
      "st": 0,
      "op": 180.00000733155,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [164.318, -32.981, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [420.318, 223.019, 0],
              "t": 120,
              "ti": [0, 0, 0],
              "to": [0, -3.167, 0]
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [420.318, 204.019, 0],
              "t": 142.5,
              "ti": [0, -3.167, 0],
              "to": [0, 0, 0]
            },
            { "s": [420.318, 223.019, 0], "t": 165.000006720588 }
          ],
          "ix": 2
        },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-0.936, -13.868],
                    [-13.868, 0.936],
                    [-0.43, 0.053],
                    [0.875, 12.882],
                    [-13.202, 1.584],
                    [0.967, -0.066]
                  ],
                  "o": [
                    [0.936, 13.868],
                    [0.431, -0.029],
                    [-12.597, -0.599],
                    [-0.907, -13.424],
                    [-0.946, -0.045],
                    [-13.868, 0.936]
                  ],
                  "v": [
                    [136.367, -42.592],
                    [163.168, -19.175],
                    [164.46, -19.296],
                    [140.56, -42.746],
                    [162.654, -69.43],
                    [159.778, -69.399]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0, 0, 0], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 2",
          "ix": 2,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-0.936, -13.868],
                    [-13.868, 0.936],
                    [0.936, 13.868],
                    [13.868, -0.936]
                  ],
                  "o": [
                    [0.936, 13.868],
                    [13.868, -0.936],
                    [-0.936, -13.868],
                    [-13.868, 0.936]
                  ],
                  "v": [
                    [136.365, -42.593],
                    [163.171, -19.178],
                    [186.587, -45.984],
                    [159.781, -69.399]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 1, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 3",
          "ix": 3,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-1.203, -17.82],
                    [-17.82, 1.203],
                    [1.203, 17.82],
                    [17.82, -1.203]
                  ],
                  "o": [
                    [1.203, 17.82],
                    [17.82, -1.203],
                    [-1.203, -17.82],
                    [-17.82, 1.203]
                  ],
                  "v": [
                    [129.134, -42.108],
                    [163.577, -12.02],
                    [193.665, -46.464],
                    [159.221, -76.551]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.0706, 0.2863, 0.7176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 4",
          "ix": 4,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-1.645, -24.369],
                    [0, 0],
                    [1.645, 24.369],
                    [24.369, -1.645]
                  ],
                  "o": [
                    [1.645, 24.369],
                    [0, 0],
                    [-1.645, -24.369],
                    [-24.369, 1.645]
                  ],
                  "v": [
                    [117.447, -38.763],
                    [164.465, 20.204],
                    [205.694, -44.721],
                    [158.592, -85.865]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.2275, 0.4706, 0.949], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 5",
          "ix": 5,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-1.643, -24.371],
                    [-10.306, -10.449],
                    [0, 0],
                    [-0.144, 0.006],
                    [-0.904, 1.102],
                    [1.47, 21.827],
                    [25.904, -1.75]
                  ],
                  "o": [
                    [1.404, 20.843],
                    [-0.282, 1.409],
                    [0.125, -0.001],
                    [1.424, -0.057],
                    [7.568, -9.223],
                    [-1.643, -24.371],
                    [-25.904, 1.749]
                  ],
                  "v": [
                    [117.448, -38.762],
                    [164.399, 17.269],
                    [164.464, 20.201],
                    [164.87, 20.191],
                    [170.537, 18.842],
                    [211.245, -45.095],
                    [161.369, -86.051]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0, 0, 0], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 5
    },
    {
      "ty": 4,
      "nm": "Layer 3",
      "sr": 1,
      "st": 0,
      "op": 180.00000733155,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [-50.306, -73.264, 0], "ix": 1 },
        "s": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [0, 0, 100],
              "t": 60
            },
            { "s": [100, 100, 100], "t": 65.0000026475043 }
          ],
          "ix": 6
        },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [205.694, 182.736, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, -5.481],
                    [5.481, 0],
                    [0, 5.481],
                    [-5.481, 0]
                  ],
                  "o": [
                    [0, 5.481],
                    [-5.481, 0],
                    [0, -5.481],
                    [5.481, 0]
                  ],
                  "v": [
                    [-40.382, -73.264],
                    [-50.306, -63.34],
                    [-60.23, -73.264],
                    [-50.306, -83.188]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 0.2902, 0.3765], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 6
    },
    {
      "ty": 4,
      "nm": "Layer 7",
      "sr": 1,
      "st": 0,
      "op": 180.00000733155,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [-39.606, 54.853, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [216.394, 310.853, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [-6.591, -0.455],
                    [6.707, -9.973],
                    [37.633, -13.89],
                    [-22.747, -4.744],
                    [-10.408, -9.537],
                    [-50.2, -25.688],
                    [0, 0]
                  ],
                  "o": [
                    [24.348, -0.94],
                    [48.009, 3.314],
                    [-16.09, 23.923],
                    [-34.113, 12.591],
                    [22.747, 4.744],
                    [14.088, 27.247],
                    [55.535, 28.418],
                    [0, 0]
                  ],
                  "v": [
                    [-49.467, -76.17],
                    [6.482, -77.436],
                    [49.829, -29.208],
                    [-123.458, 8.222],
                    [-129.215, 82.528],
                    [-36.267, 82.53],
                    [-67.642, 175.318],
                    [68.448, 172.58]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 3, "ix": 5 },
              "d": [
                { "nm": "dash", "n": "d", "v": { "a": 0, "k": 9, "ix": 1 } }
              ],
              "c": { "a": 0, "k": [0.3765, 0.4235, 0.6118], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "tm",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Filter - Trim",
          "nm": "Trim Paths 1",
          "ix": 2,
          "e": {
            "a": 1,
            "k": [
              {
                "o": { "x": 0.333, "y": 0 },
                "i": { "x": 0.667, "y": 1 },
                "s": [0],
                "t": 65
              },
              { "s": [100], "t": 120.0000048877 }
            ],
            "ix": 2
          },
          "o": { "a": 0, "k": 0, "ix": 3 },
          "s": { "a": 0, "k": 0, "ix": 1 },
          "m": 1
        }
      ],
      "ind": 7
    },
    {
      "ty": 4,
      "nm": "Layer 2",
      "sr": 1,
      "st": 0,
      "op": 180.00000733155,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [-50.306, -144.678, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [205.694, 111.322, 0],
              "t": 0,
              "ti": [0, 0, 0],
              "to": [0, -2.333, 0]
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [205.694, 97.322, 0],
              "t": 30,
              "ti": [0, -2.333, 0],
              "to": [0, 0, 0]
            },
            { "s": [205.694, 111.322, 0], "t": 60.0000024438501 }
          ],
          "ix": 2
        },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0.936, -13.868],
                    [13.868, 0.936],
                    [0.43, 0.053],
                    [-0.875, 12.882],
                    [13.202, 1.584],
                    [-0.967, -0.066]
                  ],
                  "o": [
                    [-0.936, 13.868],
                    [-0.431, -0.029],
                    [12.597, -0.599],
                    [0.907, -13.424],
                    [0.946, -0.045],
                    [13.868, 0.936]
                  ],
                  "v": [
                    [-22.356, -154.288],
                    [-49.157, -130.871],
                    [-50.449, -130.993],
                    [-26.548, -154.442],
                    [-48.642, -181.127],
                    [-45.767, -181.095]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0, 0, 0], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 2",
          "ix": 2,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0.936, -13.868],
                    [13.868, 0.936],
                    [-0.936, 13.868],
                    [-13.868, -0.936]
                  ],
                  "o": [
                    [-0.936, 13.868],
                    [-13.868, -0.936],
                    [0.936, -13.868],
                    [13.868, 0.936]
                  ],
                  "v": [
                    [-22.354, -154.29],
                    [-49.16, -130.874],
                    [-72.575, -157.68],
                    [-45.769, -181.096]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 1, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 3",
          "ix": 3,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [1.203, -17.82],
                    [17.82, 1.203],
                    [-1.203, 17.82],
                    [-17.82, -1.203]
                  ],
                  "o": [
                    [-1.203, 17.82],
                    [-17.82, -1.203],
                    [1.203, -17.82],
                    [17.82, 1.203]
                  ],
                  "v": [
                    [-15.122, -153.804],
                    [-49.566, -123.717],
                    [-79.653, -158.16],
                    [-45.209, -188.248]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.7608, 0.3098, 0.3098], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 4",
          "ix": 4,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [1.645, -24.369],
                    [0, 0],
                    [-1.645, 24.369],
                    [-24.369, -1.645]
                  ],
                  "o": [
                    [-1.645, 24.369],
                    [0, 0],
                    [1.645, -24.369],
                    [24.369, 1.645]
                  ],
                  "v": [
                    [-3.436, -150.459],
                    [-50.453, -91.493],
                    [-91.683, -156.417],
                    [-44.58, -197.562]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.898, 0.3059, 0.2667], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 5",
          "ix": 5,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [1.643, -24.371],
                    [10.306, -10.449],
                    [0, 0],
                    [0.144, 0.006],
                    [0.904, 1.102],
                    [-1.47, 21.827],
                    [-25.904, -1.75]
                  ],
                  "o": [
                    [-1.404, 20.843],
                    [0.282, 1.409],
                    [-0.125, -0.001],
                    [-1.424, -0.057],
                    [-7.568, -9.223],
                    [1.643, -24.371],
                    [25.904, 1.749]
                  ],
                  "v": [
                    [-3.436, -150.458],
                    [-50.388, -94.428],
                    [-50.452, -91.495],
                    [-50.858, -91.505],
                    [-56.525, -92.854],
                    [-97.233, -156.791],
                    [-47.357, -197.747]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0, 0, 0], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 8
    },
    {
      "ty": 4,
      "nm": "Layer 1",
      "sr": 1,
      "st": 0,
      "op": 180.00000733155,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [256, 256, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [30.09, 42.23],
                    [31.363, 66.479],
                    [49.829, 65.745],
                    [48.557, 41.495]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.9412, 0.9608, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 60, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 2",
          "ix": 2,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-32.629, 44.726],
                    [-31.356, 68.975],
                    [18.719, 66.983],
                    [17.446, 42.733]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.9412, 0.9608, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 60, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 3",
          "ix": 3,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-122.032, 31.829],
                    [-122.968, 14.008],
                    [-157.571, 15.385],
                    [-155.487, 55.1],
                    [-133.527, 54.226],
                    [-132.591, 72.047],
                    [-97.988, 70.67],
                    [-100.072, 30.955]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.9412, 0.9608, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 60, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 4",
          "ix": 4,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [54.943, -65.525],
                    [53.58, -91.493],
                    [-32.123, -88.082],
                    [-30.76, -62.115]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.9412, 0.9608, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 60, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 5",
          "ix": 5,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-69.498, -60.573],
                    [-70.861, -86.541],
                    [-127.46, -84.289],
                    [-126.097, -58.321]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.9412, 0.9608, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 60, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 6",
          "ix": 6,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-135.378, -57.952],
                    [-136.741, -83.919],
                    [-157.537, -83.092],
                    [-156.174, -57.124]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.9412, 0.9608, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 60, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 7",
          "ix": 7,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-30.544, 5.802],
                    [-9.265, 4.955],
                    [-10.695, -22.286],
                    [-31.973, -21.439]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.9412, 0.9608, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 60, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 8",
          "ix": 8,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-60.701, -3.08],
                    [-61.877, -25.474],
                    [-125.095, -22.958],
                    [-123.458, 8.222],
                    [-86.744, 6.761],
                    [-83.577, 67.099],
                    [-57.073, 66.044],
                    [-60.701, -3.08]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.9412, 0.9608, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 60, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 9",
          "ix": 9,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [0.265, 13.951],
                    [31.319, 12.715],
                    [31.319, 12.715],
                    [55.997, 11.733],
                    [53.682, -32.364],
                    [29.005, -31.382],
                    [30.204, -8.545],
                    [-0.851, -7.309]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.9412, 0.9608, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 60, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 10",
          "ix": 10,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [79.369, 31.329],
                    [78.702, 18.616],
                    [-38.709, 23.288],
                    [-41.88, -37.123],
                    [75.531, -41.795],
                    [74.864, -54.508],
                    [-42.547, -49.836],
                    [-45.591, -107.831],
                    [-56.668, -107.391],
                    [-53.624, -49.395],
                    [-178.02, -44.445],
                    [-177.352, -31.732],
                    [-52.957, -36.682],
                    [-49.119, 36.442]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 1, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 80, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 11",
          "ix": 11,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-0.589, -11.218],
                    [0, 0],
                    [9.774, -0.389],
                    [0, 0],
                    [0.589, 11.218],
                    [0, 0],
                    [-9.774, 0.389],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0.589, 11.218],
                    [0, 0],
                    [-9.774, 0.389],
                    [0, 0],
                    [-0.589, -11.218],
                    [0, 0],
                    [9.774, -0.389]
                  ],
                  "v": [
                    [72.886, -92.192],
                    [81.063, 63.614],
                    [64.432, 84.629],
                    [-153.057, 93.284],
                    [-171.82, 73.677],
                    [-179.998, -82.129],
                    [-163.367, -103.145],
                    [54.122, -111.799]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8196, 0.8784, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 12",
          "ix": 12,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [-0.299, 4.682],
                    [-18.334, -0.468],
                    [0, 0],
                    [-3.266, -15.411],
                    [-1.095, -6.743]
                  ],
                  "o": [
                    [-0.148, -4.936],
                    [1.174, -18.39],
                    [0, 0],
                    [15.753, 0.117],
                    [1.287, 6.074],
                    [0.144, 0.89]
                  ],
                  "v": [
                    [-199.875, -126.217],
                    [-199.3, -140.688],
                    [-165.556, -172.182],
                    [48.882, -175.267],
                    [81.457, -148.685],
                    [85.134, -129.201]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.0471, 0.251, 0.651], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 13",
          "ix": 13,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [13.261, -0.983],
                    [68.402, -2.43],
                    [1.201, 13.32],
                    [1.681, 46.744],
                    [-0.277, 4.647],
                    [-17.826, -0.138],
                    [0, 0],
                    [-3.264, -15.407],
                    [-1.098, -6.743],
                    [-0.64, -63.148]
                  ],
                  "o": [
                    [-68.387, 5.071],
                    [-13.365, 0.475],
                    [-6.153, -68.264],
                    [-0.178, -4.94],
                    [1.091, -17.795],
                    [0, 0],
                    [15.752, 0.123],
                    [1.283, 6.075],
                    [7.752, 47.774],
                    [0.135, 13.297]
                  ],
                  "v": [
                    [77.641, 114.708],
                    [-161.975, 123.525],
                    [-187.939, 100.615],
                    [-199.878, -126.219],
                    [-199.304, -140.689],
                    [-165.556, -172.179],
                    [48.878, -175.269],
                    [81.458, -148.687],
                    [85.137, -129.202],
                    [100.981, 89.307]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.9412, 0.9608, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 14",
          "ix": 14,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [-0.4, 4.675],
                    [-18.284, -0.338],
                    [0, 0],
                    [-3.266, -15.411],
                    [-1.095, -6.743]
                  ],
                  "o": [
                    [-0.148, -4.936],
                    [1.546, -18.083],
                    [0, 0],
                    [15.753, 0.117],
                    [1.287, 6.074],
                    [0.144, 0.89]
                  ],
                  "v": [
                    [-211.248, -126.217],
                    [-210.673, -140.688],
                    [-176.929, -172.182],
                    [37.508, -175.267],
                    [70.084, -148.685],
                    [73.76, -129.201]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.0078, 0.1059, 0.3059], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 15",
          "ix": 15,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [13.261, -0.983],
                    [68.402, -2.43],
                    [1.201, 13.32],
                    [1.681, 46.744],
                    [-0.277, 4.647],
                    [-17.826, -0.138],
                    [0, 0],
                    [-3.264, -15.407],
                    [-1.098, -6.743],
                    [-0.64, -63.148]
                  ],
                  "o": [
                    [-68.387, 5.071],
                    [-13.365, 0.475],
                    [-6.153, -68.264],
                    [-0.178, -4.94],
                    [1.091, -17.795],
                    [0, 0],
                    [15.752, 0.123],
                    [1.283, 6.075],
                    [7.752, 47.774],
                    [0.135, 13.297]
                  ],
                  "v": [
                    [66.268, 114.708],
                    [-173.348, 123.525],
                    [-199.312, 100.615],
                    [-211.251, -126.219],
                    [-210.677, -140.689],
                    [-176.93, -172.179],
                    [37.505, -175.269],
                    [70.085, -148.687],
                    [73.764, -129.202],
                    [89.607, 89.307]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8196, 0.8784, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 9
    }
  ],
  "v": "5.12.2",
  "fr": 29.9700012207031,
  "op": 180.00000733155,
  "ip": 0,
  "assets": []
}
