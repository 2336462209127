import {
  TouchableOpacity as BaseTouchableOpacity,
  TouchableOpacityProps as BaseTouchableOpacityProps,
} from 'react-native';
import { cloneElement, ReactElement } from 'react';
import styles from './styles';

export type TouchableOpacityProps = Omit<
  BaseTouchableOpacityProps,
  'children'
> & {
  children?: ReactElement;
};

const TouchableOpacity = ({
  children,
  ...props
}: TouchableOpacityProps): ReactElement => (
  <BaseTouchableOpacity {...props}>
    {children &&
      cloneElement(children, {
        style: [styles.clickable, children.props.style],
      })}
  </BaseTouchableOpacity>
);

export default TouchableOpacity;
