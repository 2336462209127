import type { FieldValues } from 'react-hook-form';
import type { ReactHookWizardStep } from '../types';
import { StepState } from '../types';

export const calculateHookIndexedSteps = <
  TFieldValues extends FieldValues = FieldValues,
>(
  steps: ReactHookWizardStep<TFieldValues>[],
): { index: number; category: string }[] =>
  steps.map(({ category }, index) => ({ index, category }));

export const calculateHookCategories = <
  TFieldValues extends FieldValues = FieldValues,
>(
  steps: ReactHookWizardStep<TFieldValues>[],
  indexedSteps: { index: number; category: string }[],
): {
  category: string;
  state: StepState;
  value: number;
  stepIndexes: number[];
}[] =>
  steps
    .map((step) => step.category)
    .filter((v, i, a) => a.indexOf(v) === i)
    .map((category) => ({
      category,
      state: StepState.TODO,
      value: 0,
      stepIndexes: indexedSteps
        .filter((stepIndex) => stepIndex.category === category)
        .map(({ index }) => index),
    }));
