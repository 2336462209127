import type { Address, ProjectType, Uuid } from '@bas/value-objects';

export interface ActiveIntake {
  projectId: Uuid;
  tenantId: Uuid;
  currentStep: number;
  name?: string;
  location?: Address;
  startedOn: Date;
  projectExists: boolean;
  projectType?: ProjectType;
}
