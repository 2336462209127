import { EmailAddress, Language } from '@bas/value-objects';
import * as Yup from 'yup';

export interface UserInformationInputType {
  emailAddress: EmailAddress;
  firstName?: string | null;
  middleName?: string | null;
  lastName: string;
  language: Language;
}

export const UserInformationInputTypeDefaultValues =
  (): UserInformationInputType => ({
    emailAddress: '',
    firstName: '',
    middleName: '',
    lastName: '',
    language: Language.NL,
  });

export const UserInformationInputTypeValidationBuilder =
  (): Yup.ObjectSchema<UserInformationInputType> =>
    Yup.object({
      emailAddress: Yup.string().email().required().label('label.emailAddress'),
      firstName: Yup.string().nullable().label('label.firstName'),
      middleName: Yup.string().nullable().label('label.middleName'),
      lastName: Yup.string().required().label('label.lastName'),
      language: Yup.mixed<Language>()
        .oneOf(Object.values(Language))
        .required()
        .label('label.language'),
    });
