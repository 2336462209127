import { useEmployeeStore, useTimeTrackingStore } from '@bas/shared/state';
import { useEffect } from 'react';
import dayjs from 'dayjs';

export const useCalculateTotalHoursOfToday = () => {
  const employeeId = useEmployeeStore((state) => state.employee?.employeeId);

  const hasActiveItem = useTimeTrackingStore((state) => state.hasActiveItem);
  const calculateToday = useTimeTrackingStore((state) => state.calculateToday);
  const todayDate = useTimeTrackingStore((state) => state.today.date);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        !todayDate ||
        hasActiveItem ||
        !dayjs(todayDate).isSame(dayjs(), 'day')
      ) {
        calculateToday(employeeId || '');
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [calculateToday, hasActiveItem, employeeId, todayDate]);
};
