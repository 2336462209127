import {
  Pressable as BasePressable,
  PressableProps as BasePressableProps,
} from 'react-native';
import { ReactElement } from 'react';

export type PressableProps = BasePressableProps;

const Pressable = ({ ...props }: PressableProps): ReactElement => (
  <BasePressable {...props} />
);

export default Pressable;
