import { InvoiceForPayment } from '@bas/financial-domain/models';
import { QueryOptionsWithKey } from '@bas/shared/requests';
import { Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type InvoiceByInvoiceIdAndCodeRequestProps = {
  invoiceId: Uuid;
  relationId: Uuid;
  confirmationCode: string;
};

export const InvoiceByInvoiceIdAndCodeRequest = async ({
  invoiceId,
  relationId,
  confirmationCode,
  ...params
}: InvoiceByInvoiceIdAndCodeRequestProps): Promise<
  AxiosResponse<InvoiceForPayment>
> =>
  axios.get(
    `api/public/{tenantId}/invoices/payment/${invoiceId}/${relationId}/${confirmationCode}`,
    {
      params,
    },
  );

export const useInvoiceByInvoiceIdAndCodeRequest = (
  request: InvoiceByInvoiceIdAndCodeRequestProps,
  options: QueryOptionsWithKey<
    AxiosResponse<InvoiceForPayment>,
    AxiosError,
    AxiosResponse<InvoiceForPayment>
  > = {},
): UseQueryResult<AxiosResponse<InvoiceForPayment>, AxiosError> =>
  useQuery<
    AxiosResponse<InvoiceForPayment>,
    AxiosError,
    AxiosResponse<InvoiceForPayment>
  >({
    ...options,
    queryFn: async () => InvoiceByInvoiceIdAndCodeRequest(request),
    queryKey: ['invoices', 'payment', request.invoiceId],
  });
