import { Invoice } from '@bas/financial-domain/models';
import { BillingMomentType } from '@bas/shared/models';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type GenerateInvoiceByProjectMutationProps = {
  projectId: Uuid;
  billingMomentType?: BillingMomentType;
};

type Response = AxiosResponse<Invoice>;

export const GenerateInvoiceByProjectMutation = async ({
  projectId,
  ...values
}: GenerateInvoiceByProjectMutationProps): Promise<Response> =>
  axios.put(`api/{tenantId}/projects/${projectId}/generate-invoice`, {
    projectId,
    ...values,
  });

export const useGenerateInvoiceByProjectMutation = (
  options: UseMutationOptions<
    Response,
    AxiosError<ErrorResponse>,
    GenerateInvoiceByProjectMutationProps
  > = {},
): UseMutationResult<
  Response,
  AxiosError<ErrorResponse>,
  GenerateInvoiceByProjectMutationProps
> =>
  useMutation<
    Response,
    AxiosError<ErrorResponse>,
    GenerateInvoiceByProjectMutationProps
  >({
    mutationFn: GenerateInvoiceByProjectMutation,
    throwOnError: false,
    ...options,
  });
