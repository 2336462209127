import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ChangeUserPasswordMutationProps = {
  userId: Uuid;
  currentPassword: string;
  newPassword: string;
};

export const ChangeUserPasswordMutation = async ({
  userId,
  ...values
}: ChangeUserPasswordMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/users/${userId}/change-password`, {
    userId,
    ...values,
  });

export const useChangeUserPasswordMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeUserPasswordMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ChangeUserPasswordMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeUserPasswordMutationProps
  >({
    mutationFn: ChangeUserPasswordMutation,
    throwOnError: false,
    ...options,
  });
