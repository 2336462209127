import { isQuoteEvent } from './QuoteEvent';
import { ManuallyAcceptedQuoteEvent, QuoteEventType } from '../types';

export function isManuallyAcceptedQuoteEvent(
  object: unknown,
): object is ManuallyAcceptedQuoteEvent {
  return (
    isQuoteEvent(object) &&
    object.eventType === QuoteEventType.ManuallyAcceptedQuoteEvent
  );
}
