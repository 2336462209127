import { isBillingMoment } from './BillingMoment';
import { BillingMomentInvoiceWithoutProject } from '../types';

export function isBillingMomentInvoiceWithoutProject(
  object: unknown,
): object is BillingMomentInvoiceWithoutProject {
  return (
    !!object &&
    isBillingMoment(object) &&
    object['@type'] === 'BillingMomentInvoiceWithoutProject'
  );
}
