import { colors } from '@bas/theme';
import React, {
  Component,
  type ComponentClass,
  RefObject,
  useCallback,
  useState,
} from 'react';
import {
  NativeSyntheticEvent,
  Pressable,
  StyleSheet,
  TextInput,
  TextInputFocusEventData,
  TextInputProps,
  View,
} from 'react-native';
import Animated, { AnimatedProps } from 'react-native-reanimated';
import { Typography } from '../Typography';

const AnimatedTextInput = Animated.createAnimatedComponent(TextInput);

export type OutlinedTextFieldProps = TextInputProps & {
  label?: string;
  value?: string;
  onChangeText?: (text: string) => void;
  disabled?: boolean;
  error?: boolean;
  light?: boolean;
  InputComponent?: ComponentClass<AnimatedProps<TextInputProps>>;
  labelColor: string;
  textColor: string;
  borderColor: string;
  handleFocusChange: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void;
  inputRef?: RefObject<TextInput | null>;
  formatDisplayValue?: (value: string) => string; // Optional formatter for display mode
};

const styles = StyleSheet.create({
  outlinedContainer: {
    borderWidth: 1,
    borderColor: colors.lila[400],
    borderRadius: 5,
    backgroundColor: colors.white,
    paddingHorizontal: 8,
    flexDirection: 'row',
    height: 44,
    minHeight: 44,
    flex: 1,
    alignItems: 'center',
  },
  outlinedLabelContainer: {
    position: 'absolute',
    top: -10,
    left: 12,
    zIndex: 1,
  },
  label: {
    backgroundColor: '#fff',
    paddingHorizontal: 4,
  },
  input: {
    fontSize: 16,
    height: '100%',
    flex: 1,
  },
  inputWrapper: {
    flex: 1,
    flexDirection: 'row',
  },
});

export const OutlinedTextField = ({
  label,
  value,
  onChangeText,
  disabled,
  error,
  light,
  InputComponent = AnimatedTextInput,
  onFocus,
  onBlur,
  labelColor,
  textColor,
  handleFocusChange,
  inputRef,
  formatDisplayValue,
  textAlign,
  style,
  pointerEvents,
  ...props
}: OutlinedTextFieldProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const fixedLabelStyle = { fontSize: 14 };

  const handleFocusInternal = useCallback(
    (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      setIsEditing(true);
      handleFocusChange?.(e);
      onFocus?.(e);
    },
    [handleFocusChange, onFocus],
  );

  const handleBlurInternal = useCallback(
    (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      setIsEditing(false);
      handleFocusChange?.(e);
      onBlur?.(e);
    },
    [handleFocusChange, onBlur],
  );

  const handlePress = useCallback(() => {
    if (!disabled) {
      setIsEditing(true);
    }
  }, [disabled]);

  const displayValue = formatDisplayValue
    ? formatDisplayValue(value || '')
    : value;
  const field = (
    <View style={[style, styles.outlinedContainer]}>
      {label && (
        <View style={styles.outlinedLabelContainer} pointerEvents="none">
          <Animated.Text
            style={[
              styles.label,
              fixedLabelStyle,
              {
                color: labelColor,
              },
            ]}
          >
            {label}
          </Animated.Text>
        </View>
      )}

      <View style={styles.inputWrapper}>
        {isEditing ? (
          <InputComponent
            {...props}
            ref={
              inputRef as RefObject<Component<AnimatedProps<TextInputProps>>>
            }
            style={[
              styles.input,
              {
                color: textColor,
                marginTop: label ? 8 : 0,
              },
            ]}
            value={value}
            onFocus={handleFocusInternal}
            onBlur={handleBlurInternal}
            onChangeText={onChangeText}
            editable={!disabled}
            multiline={false}
            numberOfLines={1}
            textAlignVertical="center"
            autoFocus
            textAlign={textAlign}
          />
        ) : (
          <Typography
            overrideColor={textColor}
            mt={label ? 1 : 0}
            textAlign={textAlign}
            numberOfLines={1}
            style={{ flex: 1 }}
            ellipsizeMode="head"
          >
            {displayValue}
          </Typography>
        )}
      </View>
    </View>
  );

  if (pointerEvents === 'none') {
    return field;
  }

  return <Pressable onPress={handlePress}>{field}</Pressable>;
};

export default OutlinedTextField;
