import { StyleSheet } from 'react-native';
import { colors } from '@bas/theme';

export default StyleSheet.create({
  flex: {
    flex: 1,
  },
  qrCode: {
    marginTop: 24,
    padding: 20,
    backgroundColor: colors.lila[200],
    maxWidth: 340,
    alignSelf: 'center',
  },
  icon: {
    marginTop: 24,
    marginBottom: 72,
    alignItems: 'center',
  },
  paddingTop: {
    paddingTop: 16,
  },
  center: {
    alignItems: 'center',
  },
  invoiceInfo: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
});
