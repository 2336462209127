import { Address } from '../types';

export function isAddress(object: unknown): object is Address {
  return (
    !!object &&
    Object.prototype.hasOwnProperty.call(object, 'streetName') &&
    Object.prototype.hasOwnProperty.call(object, 'houseNumber') &&
    Object.prototype.hasOwnProperty.call(object, 'zipCode') &&
    Object.prototype.hasOwnProperty.call(object, 'city') &&
    Object.prototype.hasOwnProperty.call(object, 'country')
  );
}
