import { ClaCompensationRuleType, ClaHourType } from '@bas/hrm-domain/models';
import { EmployeeContractType, NullableMoney, Uuid } from '@bas/value-objects';

export type AddClaCompensationRuleInputType = {
  name: string;
  description: string;
  ruleType: ClaCompensationRuleType;
  contractType: EmployeeContractType;
  employeeType: 'field-staff' | 'backoffice-staff' | 'all';
  attributeId: Uuid | undefined | null;
  excludedAttributeIds: Uuid[];
  enabledFrom: Date | null;

  hourType: ClaHourType;
  calculatePer: 'hour' | 'day';
  hoursMoreThan: number | null;
  hoursLessThan: number | null;
  startTimeAfter: number;
  startTimeBefore: number;
  endTimeAfter: number;
  endTimeBefore: number;
  applyCompensationAfterTime: number;
  applyCompensationBeforeTime: number;
  compensation: NullableMoney;

  nonReimbursedInitialKilometers: number;
  maxReimbursedKilometers: number;
  compensationPerKilometer: NullableMoney;
};

export const addClaCompensationRuleInputTypeDefaultValues =
  (): AddClaCompensationRuleInputType => ({
    name: '',
    description: '',
    ruleType: ClaCompensationRuleType.ClaSingleDayTrip,
    contractType: EmployeeContractType.FIXED_AMOUNT_OF_HOURS,
    employeeType: 'field-staff',
    attributeId: null,
    excludedAttributeIds: [],
    enabledFrom: null,

    hourType: ClaHourType.FIELD_WORK_HOURS,
    calculatePer: 'hour',
    hoursMoreThan: null,
    hoursLessThan: null,
    startTimeAfter: 0,
    startTimeBefore: 24,
    endTimeAfter: 0,
    endTimeBefore: 24,
    applyCompensationAfterTime: 0,
    applyCompensationBeforeTime: 24,
    compensation: {
      amount: null,
      currency: 'EUR',
    },

    nonReimbursedInitialKilometers: 0,
    maxReimbursedKilometers: 0,
    compensationPerKilometer: {
      amount: null,
      currency: 'EUR',
    },
  });
