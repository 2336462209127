export enum HowDidYouFindUs {
  FOLDER = 'folder',
  FRIENDS_OR_FAMILY = 'friends_or_family',
  REAL_ESTATE_AGENT = 'real_estate_agent',
  ALREADY_MOVED_EARLIER = 'already_moved_earlier',
  GOOGLE = 'google',
  SOCIAL_MEDIA = 'social_media',
  LEAD_WEBSITE = 'lead_website',
  OTHER = 'other',
  UNKNOWN = '',
}
