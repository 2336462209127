import * as Yup from 'yup';
import { Uuid } from '@bas/value-objects';

export interface NeededInventoryItemInputType {
  quantity: number;
  inventoryItemId: Uuid;
}

export const NeededInventoryItemInputTypeDefaultValues =
  (): NeededInventoryItemInputType => ({
    quantity: 0,
    inventoryItemId: '',
  });

export const NeededInventoryItemInputTypeValidationBuilder =
  (): Yup.ObjectSchema<NeededInventoryItemInputType> =>
    Yup.object({
      quantity: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .nullable()
        .min(0)
        .required()
        .label('label.quantity'),
      inventoryItemId: Yup.string().required().label('label.inventoryItem'),
    });
