import * as Yup from 'yup';
import {
  InventoryItemInformationInputType,
  InventoryItemInformationInputTypeDefaultValues,
  InventoryItemInformationInputTypeValidationBuilder,
} from './inventoryItemInformationInputType';

export interface MovingBoxInformationInputType
  extends InventoryItemInformationInputType {
  length: number;
  width: number;
  height: number;
}

export const MovingBoxInformationInputTypeDefaultValues =
  (): MovingBoxInformationInputType => ({
    ...InventoryItemInformationInputTypeDefaultValues(),
    length: 0,
    width: 0,
    height: 0,
  });

export const MovingBoxInformationInputTypeValidationBuilder =
  (): Yup.ObjectSchema<MovingBoxInformationInputType> =>
    Yup.object({
      length: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .nullable()
        .min(0)
        .required()
        .label('label.length'),
      width: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .nullable()
        .min(0)
        .required()
        .label('label.width'),
      height: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .nullable()
        .min(0)
        .required()
        .label('label.height'),
    }).concat(InventoryItemInformationInputTypeValidationBuilder());
