import { ReactElement } from 'react';
import * as React from 'react';
import { FontAwesomeIcon, Props } from '@fortawesome/react-native-fontawesome';

type BottomTabIconProps = Props;

const BottomTabIcon = ({ ...props }: BottomTabIconProps): ReactElement => (
  <FontAwesomeIcon size={24} {...props} />
);

export default BottomTabIcon;
