import { ErrorResponse, TranslatedName, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type CreateDeclarationCategoryMutationProps = {
  categoryId: Uuid;
  name: string;
  weight: number;
  translatedNames: TranslatedName[];
  icon: string;
};

export const CreateDeclarationCategoryMutation = async ({
  ...values
}: CreateDeclarationCategoryMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/{tenantId}/hrm/declaration-categories`, {
    ...values,
  });

export const useCreateDeclarationCategoryMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    CreateDeclarationCategoryMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  CreateDeclarationCategoryMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    CreateDeclarationCategoryMutationProps
  >({
    mutationFn: CreateDeclarationCategoryMutation,
    throwOnError: false,
    ...options,
  });
