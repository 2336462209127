import {
  ChangeDeclarationInputType,
  changeDeclarationInputTypeValidationBuilder,
} from '@bas/hrm-domain/input-types';
import { Declaration, DeclarationStatus } from '@bas/hrm-domain/models';
import {
  DeclarationApprovalInformationContentSection,
  DeclarationInformationContentSection,
  DeclarationRejectionInformationContentSection,
  DeclarationRelatedContentSection,
} from '@bas/hrm-domain/native/molecules';
import { colors } from '@bas/theme';
import { BottomSheetMethods, Box, Typography } from '@bas/ui/native/base';
import { BackendErrors, BottomSheetProps } from '@bas/ui/native/organisms';
import { FormBottomSheet } from '@bas/ui/native/templates';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { ReactElement, RefObject, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { ChangeDeclarationForm } from '../ChangeDeclarationForm';

export type DeclarationDetailsBottomSheetProps = Omit<
  BottomSheetProps,
  'snapPoints' | 'children'
> & {
  onSubmit: (values: ChangeDeclarationInputType) => Promise<void> | void;
  bottomSheetRef?: RefObject<BottomSheetModal & BottomSheetMethods>;
};

const DeclarationDetailsBottomSheet = ({
  onSubmit,
  bottomSheetRef,
  ...props
}: DeclarationDetailsBottomSheetProps): ReactElement => {
  const handleFinalize = useCallback(() => {
    bottomSheetRef?.current?.close();
    bottomSheetRef?.current?.forceClose();
    bottomSheetRef?.current?.dismiss();
  }, [bottomSheetRef]);

  const handleDefaultValues = useCallback(
    (data: { declaration: Declaration }): ChangeDeclarationInputType => ({
      declarationId: data.declaration.declarationId,
      amount: data.declaration.amount,
      description: data.declaration.description,
      declarationDate: data.declaration.declarationDate,
      categoryId: data.declaration.categoryId,
      eventId: data.declaration.eventId,
      receipt: {
        mediaObjectId: data.declaration.receiptFileId || '',
        contentUrl: data.declaration.receiptContentUrl || '',
        url: data.declaration.receiptContentUrl || '',
      },
      project: {
        projectId: data.declaration.projectId,
      },
      relation: {
        relationId: data.declaration.relationId,
      },
    }),
    [],
  );

  const handleRender = useCallback(
    ({ declaration }: { declaration: Declaration }) => (
      <Box flex={1}>
        <Typography
          fontWeight={700}
          color="white"
          textAlign="center"
          fontSize={22}
          lineHeight={29}
        >
          <FormattedMessage id="label.declarationDetails" />
        </Typography>
        <Box paddingHorizontal={20} marginBottom={2}>
          <BackendErrors />
          <Box paddingTop={2}>
            <DeclarationInformationContentSection declaration={declaration} />
          </Box>
          <Box paddingTop={2}>
            <DeclarationRelatedContentSection
              projectId={declaration.projectId}
              relationId={declaration.relationId}
              eventId={declaration.eventId}
            />
          </Box>
          {declaration.approvedOn && (
            <Box paddingTop={2}>
              <DeclarationApprovalInformationContentSection
                declaration={declaration}
              />
            </Box>
          )}
          {declaration.rejectedOn && (
            <Box paddingTop={2}>
              <DeclarationRejectionInformationContentSection
                declaration={declaration}
              />
            </Box>
          )}
          {[DeclarationStatus.PENDING, DeclarationStatus.DISAPPROVED].includes(
            declaration.status,
          ) && (
            <Box paddingTop={2}>
              <Typography variant="subtitle1" color="white" mb={1}>
                <FormattedMessage id="label.changeDeclaration" />
              </Typography>
              <ChangeDeclarationForm />
            </Box>
          )}
        </Box>
      </Box>
    ),
    [],
  );

  const handleHideSubmit = useCallback(
    (data: { declaration: Declaration }): boolean =>
      ![DeclarationStatus.DISAPPROVED, DeclarationStatus.PENDING].includes(
        data.declaration.status,
      ),
    [],
  );

  const validationSchema = useMemo(
    () => changeDeclarationInputTypeValidationBuilder(),
    [],
  );

  return (
    <FormBottomSheet<
      ChangeDeclarationInputType,
      {
        declaration: Declaration;
      }
    >
      enableDismissOnClose
      bottomSheetRef={bottomSheetRef}
      disablePadding
      onSubmit={onSubmit}
      useProvider
      resetFormOnOpen
      onCancel={handleFinalize}
      defaultValues={handleDefaultValues}
      useData
      hideSubmit={handleHideSubmit}
      hideBackendErrors
      validationSchema={validationSchema}
      successFullFormViewProps={{
        gifUrl: 'https://media.giphy.com/media/l0Ex6kAKAoFRsFh6M/giphy.gif',
        title: 'label.changedDeclaration',
        color: colors.lila[100],
        labelId: 'label.declarationChangedDescription',
      }}
      renderWithData={handleRender}
      {...props}
    />
  );
};

export default DeclarationDetailsBottomSheet;
