import { colors } from '@bas/theme';
import { Box, ShopifyTheme } from '@bas/ui/native/base';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { BoxProps } from '@shopify/restyle';
import { ReactElement, ReactNode, useCallback, useMemo, useState } from 'react';
import { StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native';
import Animated, {
  interpolate,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { LayoutChangeEvent } from 'react-native/Libraries/Types/CoreEventTypes';
import gridStyles from './gridStyles';
import styles from './styles';

export type AccordionProps = BoxProps<ShopifyTheme> & {
  summary?: ReactNode | ((open: boolean) => ReactNode);
  children?: ReactNode | ((open: boolean) => ReactNode);
  style?: StyleProp<ViewStyle>;
  headerStyle?: StyleProp<ViewStyle>;
};

const Accordion = ({
  summary,
  children,
  style,
  headerStyle,
  ...props
}: AccordionProps): ReactElement => {
  const open = useSharedValue(false);
  const [isOpen, setIsOpen] = useState(false);

  const progress = useDerivedValue(() =>
    open.value ? withTiming(1, {}) : withTiming(0, {}),
  );

  const height = useSharedValue(0);
  const calculatedHeaderStyle = useAnimatedStyle(() => ({
    borderBottomLeftRadius: progress.value === 0 ? 8 : 0,
    borderBottomRightRadius: progress.value === 0 ? 8 : 0,
  }));

  const contentStyle = useAnimatedStyle(
    () => ({
      height: interpolate(progress.value, [0, 1], [0, height.value]),
      opacity: progress.value === 0 ? 0 : 1,
    }),
    [progress, height],
  );

  const iconAnimatedStyle = useAnimatedStyle(() => ({
    transform: [
      {
        rotateZ: withTiming(`${progress.value * 180}deg`),
      },
    ],
  }));

  const handleLayout = useCallback(
    ({
      nativeEvent: {
        layout: { height: h },
      },
    }: LayoutChangeEvent) => {
      height.value = h;
    },
    [height],
  );

  const renderedSummary = useMemo(() => {
    if (typeof summary === 'function') {
      return summary(isOpen);
    }

    return summary;
  }, [summary, isOpen]);

  const renderedChildren = useMemo(() => {
    if (typeof children === 'function') {
      return children(isOpen);
    }

    return children;
  }, [children, isOpen]);

  return (
    <Box style={[styles.accordion, style]} {...props}>
      <TouchableOpacity
        onPress={() => {
          setIsOpen(!open.value);
          open.value = !open.value;
        }}
      >
        <Animated.View
          style={[
            gridStyles.row,
            gridStyles.noWrap,
            styles.summary,
            headerStyle,
            calculatedHeaderStyle,
          ]}
        >
          <Box flex={1}>{renderedSummary}</Box>

          <Box minWidth={32} alignItems="flex-end">
            <Animated.View style={[iconAnimatedStyle, styles.chevron]}>
              <FontAwesomeIcon icon={faChevronDown} color={colors.lila[800]} />
            </Animated.View>
          </Box>
        </Animated.View>
      </TouchableOpacity>
      <Animated.View style={[styles.contentContainer, contentStyle]}>
        <View onLayout={handleLayout} style={[gridStyles.row, styles.content]}>
          {renderedChildren}
        </View>
      </Animated.View>
    </Box>
  );
};

export default Accordion;
