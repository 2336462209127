import { isObjectResponse } from '@bas/value-objects';
import { AbstractBillingMomentLine } from '../types';

export function isAbstractBillingMomentLine(
  object: unknown,
): object is AbstractBillingMomentLine {
  return (
    !!object &&
    isObjectResponse(object) &&
    [
      'FixedAmountBillingMomentLine',
      'PercentageAmountBillingMomentLine',
    ].includes(object['@type'])
  );
}
