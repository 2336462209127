import { formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type OrderInventoryMutationProps = {
  inventoryItemId: Uuid;
  orderedAt: Date;
  quantity: number;
  supplierId: Uuid;
};

export const OrderInventoryMutation = async ({
  inventoryItemId,
  orderedAt,
  ...values
}: OrderInventoryMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/inventory/item/${inventoryItemId}/order`, {
    inventoryItemId,
    orderedAt: formatDateTime(orderedAt),
    ...values,
  });

export const useOrderInventoryMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    OrderInventoryMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  OrderInventoryMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    OrderInventoryMutationProps
  >({
    mutationFn: OrderInventoryMutation,
    throwOnError: false,
    ...options,
  });
