import { Button } from '@bas/ui/native/atoms';
import { Box, Icon } from '@bas/ui/native/base';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { SliderField } from '../SliderField';

type PickersProps = {
  hours?: number;
  minutes?: number;
  sectionItems: {
    hours: {
      value: number;
      label: string;
    }[];
    minutes: {
      value: number;
      label: string;
    }[];
  };
  onClose?: () => void;
  onSubmit?: (newHours: number, newMinutes: number) => void;
};

const Pickers = ({
  hours,
  minutes,
  sectionItems,
  onClose,
  onSubmit,
}: PickersProps) => {
  const { formatMessage } = useIntl();
  const [newHours, setNewHours] = useState<number>(hours || 0);
  const [newMinutes, setNewMinutes] = useState<number>(minutes || 0);

  const handleConfirm = useCallback(() => {
    onSubmit?.(newHours, newMinutes);
  }, [onSubmit, newHours, newMinutes]);

  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  return (
    <Box flex={1} maxWidth={450} paddingBottom={3}>
      <Box
        width="100%"
        justifyContent="space-between"
        flexDirection="row"
        paddingHorizontal={3}
        paddingTop={3}
      >
        <Button variant="text" onPress={handleClose}>
          <Icon icon={faTimes} size={18} />
        </Button>
        <Button variant="text" onPress={handleConfirm}>
          <Icon icon={faCheck} size={18} />
        </Button>
      </Box>
      <Box flex={1} flexDirection="row" alignItems="center" padding={3} gap={2}>
        <Box flex={1} minWidth={100} height="100%">
          <SliderField
            label={formatMessage({ id: 'label.hours' })}
            value={newHours || 0}
            values={sectionItems.hours}
            onChange={setNewHours}
            estimatedItemSize={38}
          />
        </Box>
        <Box flex={1} minWidth={100} height="100%">
          <SliderField
            label={formatMessage({ id: 'label.minutes' })}
            value={newMinutes || 0}
            values={sectionItems.minutes}
            onChange={setNewMinutes}
            estimatedItemSize={38}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Pickers;
