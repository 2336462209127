import { Pressable, Typography } from '@bas/ui/native/base';
import { PhoneNumber } from '@bas/value-objects';
import { ReactElement } from 'react';
import { Linking } from 'react-native';
import styles from './styles';

export type RenderPhoneNumberProps = {
  phoneNumber: PhoneNumber;
  withoutLinks?: boolean;
};

const RenderPhoneNumber = ({
  phoneNumber,
  withoutLinks,
}: RenderPhoneNumberProps): ReactElement => {
  if (withoutLinks) {
    return <Typography>{phoneNumber}</Typography>;
  }

  return (
    <Pressable
      onPress={() => Linking.openURL(`tel:${phoneNumber.replace(/\s/g, '')}`)}
    >
      <Typography style={styles.link}>{phoneNumber}</Typography>
    </Pressable>
  );
};

export default RenderPhoneNumber;
