import { Uuid } from '@bas/value-objects';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import {
  dayOffInputTypeValidationBuilder,
  DayOffOffInputType,
} from './dayOffInputType';

export type ChangeTimeOffRegistrationInputType = {
  timeOffId: Uuid;
  start?: Date | null;
  end?: Date | null;
  fullDay?: boolean;
  timeOffTypeId: Uuid;
  reason: string;
  effectiveHours: number;
  daysOff: DayOffOffInputType[];
  buildUp: boolean;
};

export const changeTimeOffRegistrationInputTypeDefaultValues =
  (): ChangeTimeOffRegistrationInputType => ({
    timeOffId: '',
    timeOffTypeId: '',
    reason: '',
    fullDay: true,
    start: null,
    end: null,
    effectiveHours: 0,
    daysOff: [],
    buildUp: false,
  });

export const changeTimeOffRegistrationInputTypeValidationBuilder =
  (): Yup.ObjectSchema<ChangeTimeOffRegistrationInputType> =>
    Yup.object({
      timeOffId: Yup.string().required().label('label.timeOffId'),
      timeOffTypeId: Yup.string().required().label('label.timeOffType'),
      reason: Yup.string().required().label('label.reason'),
      fullDay: Yup.boolean().required().label('label.fullDay'),
      buildUp: Yup.boolean().required().label('label.buildUp'),
      start: Yup.date().required().label('label.start'),
      end: Yup.date()
        .when(['start', 'fullDay'], ([start, fullDay], schema) => {
          if (!start || !dayjs(start).isValid()) {
            return schema;
          }

          if (fullDay) {
            return schema.min(dayjs(start).clone().startOf('day').toDate());
          }

          return schema
            .min(dayjs(start).clone().add(1, 'second').toDate())
            .required();
        })
        .label('label.end'),
      effectiveHours: Yup.number().required().label('label.effectiveHours'),
      daysOff: Yup.array()
        .of(dayOffInputTypeValidationBuilder())
        .required()
        .label('label.daysOff'),
    });
