import { EmailFolder } from '@bas/communication-domain/models';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type MoveEmailToNewFolderMutationProps = {
  emailId: Uuid;
  newFolder: EmailFolder;
};

export const MoveEmailToNewFolderMutation = async ({
  emailId,
  ...params
}: MoveEmailToNewFolderMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/communication/emails/${emailId}/move`,
    {
      emailId,
      ...params,
    },
    { params },
  );

export const useMoveEmailToNewFolderMutation = (): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  MoveEmailToNewFolderMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    MoveEmailToNewFolderMutationProps
  >({
    mutationFn: MoveEmailToNewFolderMutation,
    throwOnError: false,
  });
