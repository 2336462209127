import * as Yup from 'yup';
import { Money } from '@bas/value-objects';

export interface HandlingCostsInputType {
  amount: Money;
  quantity: number;
  handlingDate: Date;
  description: string;
  invoiced: boolean;
  invoicedDate?: Date | null;
}

export const handlingCostsInputTypeDefaultValues =
  (): HandlingCostsInputType => ({
    amount: {
      amount: 0,
      currency: 'EUR',
    },
    quantity: 1,
    handlingDate: new Date(),
    description: '',
    invoiced: false,
    invoicedDate: null,
  });

export const handlingCostsInputTypeValidationBuilder =
  (): Yup.ObjectSchema<HandlingCostsInputType> =>
    Yup.object({
      amount: Yup.object({
        amount: Yup.number()
          .transform((value, original) =>
            original === '' || original === null ? null : value,
          )
          .nullable()
          .required()
          .label('label.linePrice'),
        currency: Yup.string().required(),
      }),
      quantity: Yup.number().required().label('label.quantity'),
      handlingDate: Yup.date().required().label('label.handlingDate'),
      description: Yup.string().required().label('label.description'),
      invoiced: Yup.boolean().required().label('label.invoiced'),
      invoicedDate: Yup.date().nullable().label('label.invoicedDate'),
    });
