import { Money } from '@bas/value-objects';
import * as Yup from 'yup';

export interface InventoryItemSupplierInputType {
  purchasePrice: Money;
}

export const InventoryItemSupplierInputTypeDefaultValues =
  (): InventoryItemSupplierInputType => ({
    purchasePrice: {
      amount: 0,
      currency: 'EUR',
    },
  });

export const InventoryItemSupplierInputTypeValidationBuilder =
  (): Yup.ObjectSchema<InventoryItemSupplierInputType> =>
    Yup.object({
      purchasePrice: Yup.object({
        amount: Yup.number()
          .transform((value, original) =>
            original === '' || original === null ? null : value,
          )
          .nullable()
          .required()
          .label('label.purchasePrice'),
        currency: Yup.string().required(),
      })
        .required()
        .label('label.purchasePrice'),
    });
