import { EmployeeContractInputType } from '@bas/hrm-domain/input-types';
import { MovingJobSkill, OfficeSkill } from '@bas/hrm-domain/models';
import { AddressInputType } from '@bas/shared/input-types';
import { formatDate } from '@bas/shared/utils';
import {
  Country,
  DriversLicenceCharacteristics,
  EmailAddress,
  ErrorResponse,
  Gender,
  Language,
  PhoneNumber,
  Uuid,
  WorkingSchedule,
} from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type AddEmployeeMutationProps = {
  employeeId: Uuid;
  gender?: Gender | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName: string;
  dateOfBirth?: Date | null;
  emailAddress: EmailAddress;
  phoneNumber?: PhoneNumber | null;
  language: Language;
  availability: WorkingSchedule;
  address: AddressInputType;
  skills: (OfficeSkill | MovingJobSkill)[];
  driversLicense: DriversLicenceCharacteristics;
  contract: EmployeeContractInputType;
  employmentStartDate?: Date | null;
  employmentEndDate?: Date | null;
  probationDate?: Date | null;
  region: Country;
  attributes: {
    employeeAttributeId: Uuid;
  }[];
};

export const AddEmployeeMutation = async ({
  employeeId,
  contract: {
    startDate: contractStartDate,
    endDate: contractEndDate,
    ...contract
  },
  dateOfBirth,
  employmentStartDate,
  employmentEndDate,
  probationDate,
  emailAddress,
  ...values
}: AddEmployeeMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/{tenantId}/hrm/employees`, {
    employeeId,
    emailAddress: emailAddress.toLowerCase(),
    dateOfBirth: dateOfBirth ? formatDate(dateOfBirth) : null,
    employmentStartDate: employmentStartDate
      ? formatDate(employmentStartDate)
      : null,
    employmentEndDate: employmentEndDate ? formatDate(employmentEndDate) : null,
    probationDate: probationDate ? formatDate(probationDate) : null,
    contract: {
      ...contract,
      startDate: contractStartDate ? formatDate(contractStartDate) : null,
      endDate: contractEndDate ? formatDate(contractEndDate) : null,
    },
    ...values,
  });

export const useAddEmployeeMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddEmployeeMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  AddEmployeeMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddEmployeeMutationProps
  >({
    mutationFn: AddEmployeeMutation,
    throwOnError: false,
    ...options,
  });
