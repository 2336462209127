import type { BuildingType } from '@bas/value-objects';
import type { TransportJobAddressType } from './TransportJobAddressType';
import type { ProjectAddress } from './ProjectAddress';

export interface TransportJobAddress extends ProjectAddress {
  addressType: TransportJobAddressType;
  buildingType?: BuildingType;
  distanceFromCar?: number | null;
  distanceToApartment?: number | null;
  floor?: number | null;
  hasElevator?: boolean | null;
  unknownAddress?: boolean;
  notes?: string | null;
}
