import {
  EmailAddress,
  Gender,
  PhoneNumber,
  Salutation,
} from '@bas/value-objects';
import * as Yup from 'yup';

export interface PrivateRelationInformationInputType {
  gender?: Gender | null;
  salutation?: Salutation | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName: string;
  emailAddress?: EmailAddress;
  phoneNumber?: PhoneNumber | null;
  mobileNumber?: PhoneNumber | null;
}

export const PrivateRelationInformationInputTypeValidationBuilder =
  (): Yup.ObjectSchema<PrivateRelationInformationInputType> =>
    Yup.object({
      gender: Yup.mixed<Gender>()
        .oneOf(Object.values(Gender))
        .label('label.gender')
        .nullable(),
      salutation: Yup.mixed<Salutation>()
        .oneOf(Object.values(Salutation))
        .label('label.salutation')
        .nullable(),
      firstName: Yup.string().label('label.firstName').nullable(),
      middleName: Yup.string().label('label.middleName').nullable(),
      lastName: Yup.string().required().label('label.lastName'),
      emailAddress: Yup.string()
        .email()
        .required()
        .ensure()
        .label('label.emailAddress'),
      phoneNumber: Yup.string().phone().nullable().label('label.phoneNumber'),
      mobileNumber: Yup.string().phone().nullable().label('label.mobileNumber'),
    });

export const PrivateRelationInformationInputTypeDefaultValues =
  (): PrivateRelationInformationInputType => ({
    gender: Gender.UNKNOWN,
    salutation: Salutation.UNKNOWN,
    firstName: '',
    middleName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: '',
    mobileNumber: '',
  });
