import dayjs from 'dayjs';
import * as Yup from 'yup';

export type EnterHoursInputType = {
  startDate?: Date | null;
  endDate?: Date | null;
  travelStartTime?: Date | null;
  breakTime: number | null;
  overrideTime?: boolean;
  reason?: string | null;
};

export const EnterHoursInputTypeDefaultValues = (): EnterHoursInputType => ({
  travelStartTime: null,
  startDate: null,
  endDate: null,
  breakTime: 0,
  overrideTime: undefined,
  reason: '',
});

export const EnterHoursInputTypeValidationBuilder =
  (): Yup.ObjectSchema<EnterHoursInputType> =>
    Yup.object({
      travelStartTime: Yup.date().nullable().label('label.travelStartTime'),
      realTravelStartTime: Yup.date()
        .nullable()
        .label('label.realTravelStartTime'),
      startDate: Yup.date()
        .when(
          [
            'overrideTime',
            'eventData.realTravelStartTime',
            'eventData.realTravelStartTime',
          ],
          ([overrideTime, travelStartTime, realTravelStartTime], schema) => {
            if (!overrideTime) {
              return schema;
            }

            if (realTravelStartTime && dayjs(realTravelStartTime).isValid()) {
              return schema
                .min(
                  dayjs(realTravelStartTime).clone().add(1, 'second').toDate(),
                )
                .max(dayjs(realTravelStartTime).clone().endOf('day').toDate())
                .required();
            }

            if (!travelStartTime || !dayjs(travelStartTime).isValid()) {
              return schema.required();
            }

            return schema
              .min(dayjs(travelStartTime).clone().add(1, 'second').toDate())
              .max(dayjs(travelStartTime).clone().endOf('day').toDate())
              .required();
          },
        )
        .label('label.startTime'),
      endDate: Yup.date()
        .when(['startDate'], ([startDate], schema) => {
          if (!startDate || !dayjs(startDate).isValid()) return schema;
          return schema
            .min(dayjs(startDate).clone().add(1, 'second').toDate())
            .max(dayjs(startDate).clone().endOf('day').toDate())
            .required();
        })
        .label('label.endTime'),
      breakTime: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .nullable()
        .required()
        .label('label.breakTime'),
      overrideTime: Yup.boolean().required().label('label.overrideTime'),
      reason: Yup.string().nullable(),
    });
