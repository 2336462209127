import * as Yup from 'yup';
import { Uuid } from '@bas/value-objects';

export interface OrderInventoryInputType {
  orderedAt?: Date | null;
  quantity: number;
  supplierId: Uuid;
}

export const OrderInventoryInputTypeDefaultValues =
  (): OrderInventoryInputType => ({
    orderedAt: null,
    quantity: 0,
    supplierId: '',
  });

export const OrderInventoryInputTypeValidationBuilder =
  (): Yup.ObjectSchema<OrderInventoryInputType> =>
    Yup.object({
      orderedAt: Yup.date().nullable().required().label('label.orderDate'),
      quantity: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .nullable()
        .min(0)
        .required()
        .label('label.quantity'),
      supplierId: Yup.string().required().label('label.supplier'),
    });
