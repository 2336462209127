import { HoursInsight } from '@bas/hrm-domain/models';
import { useHoursInsightByEmployeeIdRequest } from '@bas/hrm-domain/requests';
import { useEmployeeStore } from '@bas/shared/state';
import { formatHours } from '@bas/shared/utils';
import { colors } from '@bas/theme';
import { Button, FormattedDateDayMonthRange } from '@bas/ui/native/atoms';
import { Box, TouchableOpacity, Typography } from '@bas/ui/native/base';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import dayjs from 'dayjs';
import { router } from 'expo-router';
import * as React from 'react';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { ScrollView } from 'react-native-gesture-handler';

const HoursInsightBlock = ({
  hoursInsight,
}: {
  hoursInsight: HoursInsight;
}) => {
  const { formatMessage, formatDate } = useIntl();
  const handleGoToCalendar = useCallback((date: Date) => {
    router.navigate(`./calendar?date=${dayjs(date).format('YYYY-MM-DD')}`);
  }, []);

  const remainingHoursForDay =
    hoursInsight.hoursToWork -
    (hoursInsight.totalWorkedHours +
      hoursInsight.totalTimeOffHours +
      hoursInsight.totalSickHours);

  const totalRejectedForDay =
    hoursInsight.hoursRejected +
    hoursInsight.requestedTimeOffHours +
    hoursInsight.requestedSickHours;

  return (
    <TouchableOpacity onPress={() => handleGoToCalendar(hoursInsight.date)}>
      <Box
        style={{
          backgroundColor: colors.lila[200],
          borderRadius: 6,
        }}
        padding={2}
        rowGap={2}
      >
        <Box>
          <Typography variant="subtitle1" color="lila.700">
            {formatDate(dayjs(hoursInsight.date).toDate(), {
              weekday: 'long',
              day: 'numeric',
              month: 'long',
            })}
          </Typography>
        </Box>
        <Box flexDirection="row" flexWrap="wrap">
          <Box flexBasis="33%">
            <Typography color="lila.700">
              {formatMessage({ id: 'label.planned' })}
            </Typography>
            <Typography fontWeight={700}>
              {formatHours(hoursInsight.hoursToWork)}
            </Typography>
          </Box>
          <Box flexBasis="33%">
            <Typography color="lila.700">
              {formatMessage({ id: 'label.total' })}
            </Typography>
            <Typography fontWeight={700}>
              {formatHours(
                hoursInsight.totalWorkedHours +
                  hoursInsight.totalTimeOffHours +
                  hoursInsight.totalSickHours,
              )}
            </Typography>
          </Box>
          <Box flexBasis="33%">
            <Typography color="lila.700">
              {formatMessage({
                id:
                  remainingHoursForDay > 0
                    ? 'label.notEnough'
                    : 'label.overworked',
              })}
            </Typography>
            <Typography
              fontWeight={700}
              color={remainingHoursForDay > 0 ? 'orange.750' : 'red.500'}
            >
              {formatHours(remainingHoursForDay * -1)}
            </Typography>
          </Box>
        </Box>
        <Box flexDirection="row" flexWrap="wrap">
          <Box flexBasis="33%">
            <Typography color="lila.700">
              {formatMessage({ id: 'label.entered' })}
            </Typography>
            <Typography fontWeight={700}>
              {formatHours(
                hoursInsight.hoursToBeApproved +
                  hoursInsight.requestedTimeOffHours +
                  hoursInsight.requestedSickHours,
              )}
            </Typography>
          </Box>
          <Box flexBasis="33%">
            <Typography color="lila.700">
              {formatMessage({ id: 'label.rejected' })}
            </Typography>
            <Typography
              fontWeight={700}
              color={totalRejectedForDay > 0 ? 'orange.750' : undefined}
            >
              {formatHours(totalRejectedForDay)}
            </Typography>
          </Box>
          <Box flexBasis="33%">
            <Typography color="lila.700">
              {formatMessage({ id: 'label.approved' })}
            </Typography>
            <Typography fontWeight={700}>
              {formatHours(
                hoursInsight.hoursApproved +
                  hoursInsight.approvedTimeOffHours +
                  hoursInsight.approvedSickHours,
              )}
            </Typography>
          </Box>
        </Box>
        <Box flexDirection="row" flexWrap="wrap">
          <Box flexBasis="33%">
            <Typography color="lila.700">
              {formatMessage({ id: 'label.fieldService' })}
            </Typography>
            <Typography fontWeight={700}>
              {formatHours(hoursInsight.totalWorkedFieldServiceHours)}
            </Typography>
          </Box>
          <Box flexBasis="33%">
            <Typography color="lila.700">
              {formatMessage({ id: 'label.internal' })}
            </Typography>
            <Typography fontWeight={700}>
              {formatHours(hoursInsight.totalWorkedInternalHours)}
            </Typography>
          </Box>
          <Box flexBasis="33%">
            <Typography color="lila.700">
              {formatMessage({ id: 'label.breakTime' })}
            </Typography>
            <Typography fontWeight={700}>
              {formatHours(hoursInsight.totalWorkedBreakTimeHours)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </TouchableOpacity>
  );
};

const HoursOverviewScreen = (): ReactElement => {
  const employeeId = useEmployeeStore((state) => state.employee?.employeeId);
  const { formatMessage } = useIntl();

  const [{ startDate, endDate }, setWeek] = useState<{
    startDate: Date;
    endDate: Date;
  }>({
    startDate: dayjs().startOf('week').toDate(),
    endDate: dayjs().endOf('week').toDate(),
  });

  const { data: hoursInsightData } = useHoursInsightByEmployeeIdRequest({
    employeeId: employeeId as string,
    startDate,
    endDate,
  });

  const hoursInsights = useMemo(
    () => hoursInsightData?.data?.member || [],
    [hoursInsightData?.data],
  );

  const { workedHours, hoursToWork, hoursTimeOff, hoursSick } = useMemo(() => {
    if (hoursInsights.length === 0) {
      return {
        workedHours: 0,
        hoursToWork: 0,
        hoursTimeOff: 0,
        hoursSick: 0,
      };
    }

    return {
      workedHours: hoursInsights.reduce(
        (acc, hoursInsight) =>
          acc +
          hoursInsight.totalWorkedHours +
          hoursInsight.totalSickHours +
          hoursInsight.totalTimeOffHours,
        0,
      ),
      hoursToWork: hoursInsights.reduce(
        (acc, hoursInsight) => acc + hoursInsight.hoursToWork,
        0,
      ),
      hoursTimeOff: hoursInsights.reduce(
        (acc, hoursInsight) => acc + hoursInsight.totalTimeOffHours,
        0,
      ),
      hoursSick: hoursInsights.reduce(
        (acc, hoursInsight) => acc + hoursInsight.totalSickHours,
        0,
      ),
    };
  }, [hoursInsights]);

  const handlePreviousWeek = useCallback(() => {
    setWeek((state) => ({
      startDate: dayjs(state.startDate).subtract(7, 'days').toDate(),
      endDate: dayjs(state.endDate).subtract(7, 'days').toDate(),
    }));
  }, []);

  const handleNextWeek = useCallback(() => {
    setWeek((state) => ({
      startDate: dayjs(state.startDate).add(7, 'days').toDate(),
      endDate: dayjs(state.endDate).add(7, 'days').toDate(),
    }));
  }, []);

  const allowNext = useMemo(() => !dayjs().isSame(endDate, 'week'), [endDate]);

  const remainingHoursToWork = useMemo(
    () => hoursToWork - workedHours,
    [hoursToWork, workedHours],
  );

  return (
    <Box flex={1} paddingHorizontal={20} paddingVertical={20} rowGap={3}>
      <Box
        flexDirection="row"
        flexWrap="nowrap"
        alignItems="center"
        justifyContent="space-between"
        gap={0.5}
      >
        <Box>
          <Button
            height={24}
            width={24}
            justifyContent="center"
            variant="text"
            icon={faChevronLeft}
            onPress={handlePreviousWeek}
          />
        </Box>
        <Typography variant="h4" textAlign="center">
          {`${formatMessage({ id: 'label.week' })} ${dayjs(
            startDate,
          ).week()} - `}
          <FormattedDateDayMonthRange from={startDate} to={endDate} />
        </Typography>
        <Box>
          <Button
            disabled={!allowNext}
            height={24}
            width={24}
            justifyContent="center"
            variant="text"
            icon={faChevronRight}
            onPress={handleNextWeek}
            overrideColor={allowNext ? undefined : colors.lila[600]}
          />
        </Box>
      </Box>
      <Box flexDirection="row" flexWrap="wrap" gap={3}>
        <Box>
          <Typography color="lila.700">
            {formatMessage({ id: 'label.worked' })}
          </Typography>
          <Typography variant="h4" fontWeight={700}>
            {formatHours(workedHours)}
          </Typography>
          <Typography
            variant="body2"
            color={remainingHoursToWork > 0 ? 'orange.750' : 'red.500'}
            fontWeight={700}
          >
            {`${formatHours(remainingHoursToWork * -1)} - ${formatMessage({
              id:
                remainingHoursToWork > 0
                  ? 'label.notEnough'
                  : 'label.overworked',
            })}`}
          </Typography>
        </Box>
        <Box>
          <Typography color="lila.700">Ziekte/vrije uren</Typography>
          <Typography variant="h4" fontWeight={700}>
            {formatHours(hoursTimeOff + hoursSick)}
          </Typography>
          <Typography
            variant="body2"
            color={hoursSick === 0 ? 'green.500' : 'orange.750'}
            fontWeight={700}
          >
            {`${formatHours(hoursSick)} - ${formatMessage({
              id: 'label.sick',
            })}`}
          </Typography>
        </Box>
      </Box>

      <ScrollView>
        <Box flexDirection="column" rowGap={2}>
          {hoursInsights.map((hoursInsight) => (
            <HoursInsightBlock
              hoursInsight={hoursInsight}
              key={dayjs(hoursInsight.date).format('YYYY-MM-DD')}
            />
          ))}
        </Box>
      </ScrollView>
    </Box>
  );
};

export default HoursOverviewScreen;
