import { useTheme } from '@shopify/restyle';
import { useMemo } from 'react';
import { useWindowDimensions } from 'react-native';

export const useIsMobile = () => {
  const theme = useTheme();
  const dimensions = useWindowDimensions();

  return useMemo(
    () => dimensions.width < theme.breakpoints.tablet.width,
    [dimensions.width, theme.breakpoints.tablet.width],
  );
};
