import {
  RequestTimeOffInputType,
  requestTimeOffInputTypeDefaultValues,
  requestTimeOffInputTypeValidationBuilder,
} from '@bas/hrm-domain/input-types';
import { TimeTrackingItem } from '@bas/shared/state';
import { colors } from '@bas/theme';
import { BottomSheetMethods, Box, Typography } from '@bas/ui/native/base';
import { BackendErrors, BottomSheetProps } from '@bas/ui/native/organisms';
import { FormBottomSheet } from '@bas/ui/native/templates';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { ReactElement, RefObject, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { RequestTimeOffForm } from '../RequestTimeOffForm';

export type RequestHoursBuildUpBottomSheetProps = Omit<
  BottomSheetProps,
  'snapPoints' | 'children'
> & {
  onSubmit: (values: RequestTimeOffInputType) => Promise<void> | void;
  bottomSheetRef?: RefObject<BottomSheetModal & BottomSheetMethods>;
};

const RequestHoursBuildUpBottomSheet = ({
  onSubmit,
  bottomSheetRef,
  ...props
}: RequestHoursBuildUpBottomSheetProps): ReactElement => {
  const handleFinalize = useCallback(() => {
    bottomSheetRef?.current?.close();
    bottomSheetRef?.current?.forceClose();
    bottomSheetRef?.current?.dismiss();
  }, [bottomSheetRef]);

  return (
    <FormBottomSheet<RequestTimeOffInputType, { item: TimeTrackingItem }>
      bottomSheetRef={bottomSheetRef}
      disablePadding
      onSubmit={onSubmit}
      useProvider
      resetFormOnOpen
      onCancel={handleFinalize}
      validationSchema={requestTimeOffInputTypeValidationBuilder}
      successFullFormViewProps={{
        gifUrl: 'https://media.giphy.com/media/d7kO1wIoaXbJV5uSSy/giphy.gif',
        color: colors.lila[100],
        title: 'label.requestedHoursBuildUp',
        labelId: 'label.requestedHoursBuildUpDescription',
      }}
      defaultValues={{
        ...requestTimeOffInputTypeDefaultValues(),
        buildUp: true,
      }}
      {...props}
    >
      <Box flex={1}>
        <Typography
          fontWeight={700}
          color="white"
          textAlign="center"
          fontSize={22}
          lineHeight={29}
        >
          <FormattedMessage id="label.requestHoursBuildUp" />
        </Typography>
        <Box>
          <BackendErrors />
          <Box paddingHorizontal={20} marginBottom={2}>
            <RequestTimeOffForm buildUp />
          </Box>
        </Box>
      </Box>
    </FormBottomSheet>
  );
};

export default RequestHoursBuildUpBottomSheet;
