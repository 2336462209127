import { formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type LoadInventoryInStorageMutationProps = {
  inventoryItemId: Uuid;
  eventDate: Date;
  quantity: number;
  storageId: Uuid;
  customerId?: Uuid | null;
  projectId?: Uuid | null;
  planningEventId?: Uuid | null;
};

export const LoadInventoryInStorageMutation = async ({
  inventoryItemId,
  eventDate,
  ...values
}: LoadInventoryInStorageMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/inventory/item/${inventoryItemId}/put-in-storage`, {
    inventoryItemId,
    eventDate: formatDateTime(eventDate),
    ...values,
  });

export const useLoadInventoryInStorageMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    LoadInventoryInStorageMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  LoadInventoryInStorageMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    LoadInventoryInStorageMutationProps
  >({
    mutationFn: LoadInventoryInStorageMutation,
    throwOnError: false,
    ...options,
  });
