import {
  BuildingRate,
  ServiceTimeConsumption,
} from '@bas/project-domain/models';
import { ErrorResponse, Money, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ChangeMovingJobEstimationSettingsMutationProps = {
  estimationSettingsId: Uuid;
  employeeCubicMeterPerHour: number;
  cubicMeterRate: Money;
  travelHourlyRate: Money;
  pricePerKm: Money;
  pricePerBox: Money;
  buildingTypeRates: BuildingRate[];
  serviceTimeConsumptions: ServiceTimeConsumption[];
  includeMovingCompanyAddress: boolean;
};

export const ChangeMovingJobEstimationSettingsMutation = async ({
  estimationSettingsId,
  ...values
}: ChangeMovingJobEstimationSettingsMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/estimation-settings/${estimationSettingsId}/moving-job`,
    {
      estimationSettingsId,
      ...values,
    },
  );

export const useChangeMovingJobEstimationSettingsMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeMovingJobEstimationSettingsMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ChangeMovingJobEstimationSettingsMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeMovingJobEstimationSettingsMutationProps
  >({
    mutationFn: ChangeMovingJobEstimationSettingsMutation,
    throwOnError: false,
    ...options,
  });
