import { Uuid } from '@bas/value-objects';
import * as Yup from 'yup';

export interface InvoiceQuoteInputType {
  templateId: Uuid;
}

export const InvoiceQuoteInputTypeDefaultValues =
  (): InvoiceQuoteInputType => ({
    templateId: '',
  });

export const InvoiceQuoteInputTypeValidationBuilder =
  (): Yup.ObjectSchema<InvoiceQuoteInputType> =>
    Yup.object({
      templateId: Yup.string().required().ensure().label('label.templateId'),
    });
