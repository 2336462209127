import {
  MovingJobAddressInputType,
  MovingJobAddressInputTypeDefaultValues,
  MovingJobAddressInputTypeValidationBuilder,
} from '@bas/project-domain/input-types';
import { MovingJobAddress } from '@bas/project-domain/models';
import { BottomSheetMethods, Box } from '@bas/ui/native/base';
import { BottomSheetProps } from '@bas/ui/native/organisms';
import { FormBottomSheet } from '@bas/ui/native/templates';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { ReactElement, RefObject, useCallback } from 'react';
import { v7 } from 'uuid';
import { array, object } from 'yup';
import { TaxationToolAddressForm } from '../TaxationToolAddressForm';

type Input = { addresses: MovingJobAddressInputType[] };

export type AddExtraAddressBottomSheetProps = Omit<
  BottomSheetProps,
  'snapPoints' | 'children'
> & {
  onAddAddress: (values: MovingJobAddress) => Promise<void> | void;
  bottomSheetRef: RefObject<BottomSheetModal & BottomSheetMethods>;
};

const AddExtraAddressBottomSheet = ({
  bottomSheetRef,
  onAddAddress,
}: AddExtraAddressBottomSheetProps): ReactElement => {
  const onSubmit = useCallback(
    ({ addresses }: Input) =>
      onAddAddress({ ...addresses[0], addressId: v7() }),
    [onAddAddress],
  );

  const handleFinalize = useCallback(() => {
    bottomSheetRef?.current?.close();
    bottomSheetRef?.current?.forceClose();
    bottomSheetRef?.current?.dismiss();
  }, [bottomSheetRef]);

  return (
    <FormBottomSheet<Input>
      bottomSheetRef={bottomSheetRef}
      disablePadding
      onSubmit={onSubmit}
      useProvider
      resetFormOnOpen
      onCancel={handleFinalize}
      defaultValues={{
        addresses: [
          {
            ...MovingJobAddressInputTypeDefaultValues(),
            unknownAddress: false,
          },
        ],
      }}
      validationSchema={object({
        addresses: array().of(MovingJobAddressInputTypeValidationBuilder()),
      })}
    >
      <Box paddingHorizontal={20} marginBottom={2}>
        <TaxationToolAddressForm addressIndex={0} light />
      </Box>
    </FormBottomSheet>
  );
};

export default AddExtraAddressBottomSheet;
