import { useYupTranslations } from '@bas/shared/hooks';
import { colors } from '@bas/theme';
import nlTranslations from '@bas/translations/nlTranslations.json';
import { Box, theme, Typography } from '@bas/ui/native/base';
import { useNativeFlagConfig } from '@bas/ui/native/hooks';
import { LoadingScreen } from '@bas/ui/native/templates';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import NetInfo from '@react-native-community/netinfo';
import * as Sentry from '@sentry/react-native';
import { reactNativeTracingIntegration } from '@sentry/react-native';
import { ThemeProvider } from '@shopify/restyle';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import FlagProvider from '@unleash/proxy-client-react';
import axios from 'axios';
import { isRunningInExpoGo } from 'expo';
import { Slot } from 'expo-router';
import * as SplashScreen from 'expo-splash-screen';
import { StatusBar } from 'expo-status-bar';
import * as React from 'react';
import { memo, Suspense, useEffect, useState } from 'react';
import 'react-native-reanimated';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { Platform } from 'react-native';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import KeyboardManager from 'react-native-keyboard-manager';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { BootIntercom } from '../components/BootIntercom';
import { IntercomHandler } from '../components/IntercomHandler';
import { MercureSubscriber } from '../components/MercureSubscriber';
import { NotificationsSubscriber } from '../components/NotificationsSubscriber';
import { SetApiUrl } from '../components/SetApiUrl';
import useCachedResources from '../hooks/useCachedResources';
import useSetSentryData from '../hooks/useSetSentryData';

export { ErrorBoundary } from 'expo-router';

const navigationIntegration = Sentry.reactNavigationIntegration({
  enableTimeToInitialDisplay: !isRunningInExpoGo(),
});

Sentry.init({
  dsn: 'https://f298e40b19234c12b065414fb7bceab2@sentry.bas.software/10',
  tracesSampleRate: 0.6,
  environment: __DEV__
    ? 'development'
    : process.env.EXPO_PUBLIC_SENTRY_ENVIRONMENT || 'production',
  tracePropagationTargets: [
    'api.bas.app',
    'staging-api.bas.app',
    'local.bas.app',
  ],
  enableUserInteractionTracing: true,
  enableNativeFramesTracking: !isRunningInExpoGo(),
  integrations: [navigationIntegration, reactNativeTracingIntegration()],
  _experiments: {
    profilesSampleRate: 0.6,
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if (error instanceof Error && error.message.includes('refreshing')) {
          // eslint-disable-next-line no-console
          console.error('retry delaying for refresh', error);
          return failureCount <= 10;
        }

        if (error instanceof Error && error.message.includes('expired')) {
          // eslint-disable-next-line no-console
          console.error('retry delaying for expired');
          return failureCount <= 6;
        }
        // eslint-disable-next-line no-console
        console.error('retry delaying for normal', error);

        return failureCount <= 3;
      },
      retryDelay: (failureCount, error) => {
        if (
          error instanceof Error &&
          (error.message.includes('refreshing') ||
            (error.message.includes('expired') && failureCount <= 3))
        ) {
          // eslint-disable-next-line no-console
          console.error('retry delaying for refresh');
          if (failureCount <= 1) {
            return 1;
          }

          return Math.min(1000 * failureCount, 30000);
        }
        // eslint-disable-next-line no-console
        console.error('retry delaying for normal', error);

        return Math.min(1000 * 2 ** failureCount, 30000);
      },
      staleTime: 30000,
    },
  },
});

if (Platform.OS === 'web') {
  // eslint-disable-next-line no-underscore-dangle
  (
    window as Window & typeof globalThis & { _frameTimestamp: null }
  )._frameTimestamp = null;
}

if (Platform.OS === 'ios') {
  KeyboardManager.setEnable(false);
  KeyboardManager.setEnableDebugging(false);
  KeyboardManager.setKeyboardDistanceFromTextField(10);
  KeyboardManager.setLayoutIfNeededOnUpdate(false);
  KeyboardManager.setEnableAutoToolbar(true);
  KeyboardManager.setToolbarDoneBarButtonItemText('Klaar');
  KeyboardManager.setShouldShowToolbarPlaceholder(true);
  KeyboardManager.setToolbarManageBehaviourBy('subviews'); // "subviews" | "tag" | "position"
  KeyboardManager.setToolbarTintColor(colors.blue[500]); // Only #000000 format is supported
  KeyboardManager.setToolbarBarTintColor(colors.lila[200]); // Only #000000 format is supported
  KeyboardManager.setShouldResignOnTouchOutside(true);
  KeyboardManager.setShouldPlayInputClicks(true);
  KeyboardManager.setToolbarPreviousNextButtonEnable(true);
  KeyboardManager.isKeyboardShowing().then((isShowing) => {
    console.log('isKeyboardShowing', isShowing);
  });
}

axios.defaults.headers['Content-Type'] = 'application/ld+json';

// eslint-disable-next-line camelcase,react-refresh/only-export-components
export const unstable_settings = {
  // Ensure that reloading on `/modal` keeps a back button present.
  initialRouteName: '(app)',
};

// Prevent the splash screen from auto-hiding before asset loading is complete.
SplashScreen.preventAutoHideAsync();

const RootLayoutNav = () => {
  const config = useNativeFlagConfig();

  return (
    <FlagProvider config={config} startTransition={(fn) => fn()}>
      <MercureSubscriber />
      <IntercomHandler />
      <NotificationsSubscriber />
      <Slot />
    </FlagProvider>
  );
};

const RootLayout = () => {
  const [isLoadingComplete, error] = useCachedResources();

  // Expo Router uses Error Boundaries to catch errors in the navigation tree.
  useEffect(() => {
    if (error) throw error;
  }, [error]);

  useEffect(() => {
    if (isLoadingComplete) {
      SplashScreen.hideAsync();
    }
  }, [isLoadingComplete]);

  useSetSentryData();

  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isInternetReachable, setIsInternetReachable] =
    useState<boolean>(false);

  useEffect(() => {
    const unsubscribe = NetInfo.addEventListener((state) => {
      setIsConnected(state.isConnected ?? false);
      setIsInternetReachable(state.isInternetReachable ?? false);
    });

    return () => unsubscribe();
  }, []);

  const setTranslations = useYupTranslations();

  if (!isLoadingComplete || !setTranslations) {
    return (
      <ThemeProvider theme={theme}>
        <LoadingScreen />
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <BootIntercom>
        <GestureHandlerRootView>
          <Suspense fallback={<LoadingScreen />}>
            <ActionSheetProvider>
              <SafeAreaProvider>
                {(!isConnected || !isInternetReachable) && (
                  <Box
                    position="absolute"
                    top={0}
                    left={0}
                    right={0}
                    zIndex={100}
                    padding={2}
                    backgroundColor="red.600"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography color="white">
                      <FormattedMessage
                        id={
                          !isConnected
                            ? 'label.noConnection'
                            : 'label.noInternet'
                        }
                      />
                    </Typography>
                  </Box>
                )}
                <SetApiUrl component={RootLayoutNav} />
                <StatusBar />
              </SafeAreaProvider>
            </ActionSheetProvider>
          </Suspense>
        </GestureHandlerRootView>
      </BootIntercom>
    </ThemeProvider>
  );
};

const MemoizedRootLayout = memo(RootLayout);

const IntlProvidedApp = () => (
  <IntlProvider
    locale="nl"
    messages={nlTranslations}
    timeZone="Europe/Amsterdam"
  >
    <QueryClientProvider client={queryClient}>
      <MemoizedRootLayout />
    </QueryClientProvider>
  </IntlProvider>
);

const SentryWrappedApp = Sentry.wrap(IntlProvidedApp);

export default SentryWrappedApp;
