import {
  useQuoteByQuoteIdRequestQuery,
  useQuotePdfByQuoteIdRequestQuery,
} from '@bas/financial-domain/requests';
import { SignDocumentInputType } from '@bas/shared/input-types';
import { useTenantStore } from '@bas/shared/state';
import {
  RequestingEstimateStepTemplate,
  SignQuoteTemplate,
} from '@bas/taxation-tool-domain/native/templates';
import { Uuid } from '@bas/value-objects';
import * as FileSystem from 'expo-file-system';
import * as React from 'react';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Platform } from 'react-native';

const SignQuoteWithCustomerStep = (): ReactElement => {
  const tenant = useTenantStore((state) => state.tenant);
  const quoteId = useWatch({ name: 'quoteId' });
  const [pdfUrl, setPdfUrl] = useState<string | undefined>(undefined);
  const { isLoading, data: quoteData } = useQuoteByQuoteIdRequestQuery({
    quoteId,
  });

  const { data: pdfData } = useQuotePdfByQuoteIdRequestQuery({
    quoteId,
  });

  const acceptedLines = useWatch<
    {
      signQuote: SignDocumentInputType;
    },
    'signQuote.acceptedLines'
  >({
    name: 'signQuote.acceptedLines',
  });

  const { setValue } = useFormContext<{
    signQuote: SignDocumentInputType;
  }>();

  const setAcceptedLines = useCallback(
    (newValue: Uuid[]) => {
      setValue('signQuote.acceptedLines', newValue);
    },
    [setValue],
  );

  useEffect(() => {
    (async () => {
      if (!pdfData?.data) {
        return;
      }

      const result = await new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(pdfData?.data);
      });

      if (Platform.OS !== 'web') {
        const fileUri = `${FileSystem.documentDirectory}${encodeURI(
          `quote-${quoteId}`,
        )}.pdf`;

        const fileResult = await FileSystem.getInfoAsync(fileUri);
        if (fileResult.exists) {
          await FileSystem.deleteAsync(fileUri);
        }

        await FileSystem.writeAsStringAsync(
          fileUri,
          (result as string).split(',')[1],
          {
            encoding: FileSystem.EncodingType.Base64,
          },
        );

        setPdfUrl(fileUri);
      } else {
        setPdfUrl(
          (result as string).replace(
            'application/octet-stream',
            'application/pdf',
          ),
        );
      }
    })();
  }, [pdfData?.data, quoteId]);

  if (isLoading || !quoteData?.data || !pdfUrl || !tenant) {
    return <RequestingEstimateStepTemplate />;
  }

  return (
    <SignQuoteTemplate
      quote={quoteData?.data}
      tenant={tenant}
      pdf={{ uri: pdfUrl, downloadUri: pdfUrl }}
      relationId={quoteData?.data.relationId}
      code={quoteData?.data.code}
      acceptedLines={acceptedLines}
      setAcceptedLines={setAcceptedLines}
      prefix="signQuote"
      attachments={quoteData?.data.attachments.map(({ name, attachment }) => ({
        name,
        contentUrl: attachment.contentUrl,
      }))}
    />
  );
};

export default SignQuoteWithCustomerStep;
