import { Country, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type UpdateIdentityAddressMutationProps = {
  identityId: Uuid;
  streetName: string;
  houseNumber: string;
  houseNumberAddition?: string | null;
  zipCode: string;
  city: string;
  country: Country;
};

export const UpdateIdentityAddressMutation = async ({
  identityId,
  ...values
}: UpdateIdentityAddressMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/tenants/{tenantId}/identities/${identityId}/update-address`, {
    identityId,
    ...values,
  });

export const useUpdateIdentityAddressMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateIdentityAddressMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  UpdateIdentityAddressMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateIdentityAddressMutationProps
  >({
    mutationFn: UpdateIdentityAddressMutation,
    throwOnError: false,
    ...options,
  });
