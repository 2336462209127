export enum HoursEntryType {
  APPROVED_HOURS_ENTRY = 'approved',
  HOLIDAY_HOURS_ENTRY = 'holiday',
  OTHER_ABSENCE_HOURS_ENTRY = 'other-absence',
  OFFICE_WORK_HOURS_ENTRY = 'office-work',
  PROJECT_HOURS_ENTRY = 'project',
  EVENT_HOURS_ENTRY = 'event',
  SICK_HOURS_ENTRY = 'sick',
  WAREHOUSE_WORK_HOURS_ENTRY = 'warehouse-work',
}
