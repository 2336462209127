import { ErrorResponse, Language, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type AddAttachmentToDocumentTemplateMutationProps = {
  documentTemplateId: Uuid;
  attachmentId: Uuid;
  name: string;
  language: Language;
};

export const AddAttachmentToDocumentTemplateMutation = async ({
  documentTemplateId,
  ...values
}: AddAttachmentToDocumentTemplateMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/document-templates/${documentTemplateId}/add-attachment`,
    {
      documentTemplateId,
      ...values,
    },
  );

export const useAddAttachmentToDocumentTemplateMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddAttachmentToDocumentTemplateMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  AddAttachmentToDocumentTemplateMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddAttachmentToDocumentTemplateMutationProps
  >({
    mutationFn: AddAttachmentToDocumentTemplateMutation,
    throwOnError: false,
    ...options,
  });
