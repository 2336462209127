export * from './addAddressToRelationMutation';
export * from './addCommunicationMutation';
export * from './addContactPersonToRelationMutation';
export * from './addCustomerMutation';
export * from './addDocumentMutation';
export * from './changeContactInformationOfPrivateRelationMutation';
export * from './disableInvoiceRemindersForRelationMutation';
export * from './enableInvoiceRemindersForRelationMutation';
export * from './mergeRelationsMutation';
export * from './removeAddressFromRelationMutation';
export * from './removeContactPersonFromRelationMutation';
export * from './removeDocumentMutation';
export * from './removeRelationMutation';
export * from './renameRelationMutation';
export * from './updateAddressOfRelationMutation';
export * from './updateCommunicationLanguageMutation';
export * from './updateContactPersonOfRelationMutation';
