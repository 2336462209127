import { ErrorResponse } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type RemoveSeatsFromTenantMutationProps = {
  componentType: 'bas-moving-software';
  decreaseBy: number;
};

export const RemoveSeatsFromTenantMutation = async ({
  ...values
}: RemoveSeatsFromTenantMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/billing/remove-seats-from-component`, {
    ...values,
  });

export const useRemoveSeatsFromTenantMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveSeatsFromTenantMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  RemoveSeatsFromTenantMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveSeatsFromTenantMutationProps
  >({
    mutationFn: RemoveSeatsFromTenantMutation,
    throwOnError: false,
    ...options,
  });
