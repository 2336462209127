import { Project } from '@bas/project-domain/models';
import {
  ProjectOutstandingInventoryItems,
  ProjectOutstandingInventoryItemsProps,
} from '@bas/project-domain/native/organisms';
import { colors } from '@bas/theme';
import { Box, Typography } from '@bas/ui/native/base';
import { Uuid } from '@bas/value-objects';
import { TakeMaterialsForm } from '@bas/wms-domain/inventory/native/organisms';
import {
  InventoryItem,
  NeededInventoryItem,
  PublicMovingBox,
} from '@bas/wms-domain/models';
import { FormattedMessage } from 'react-intl';
import { ScrollView } from 'react-native-gesture-handler';

export type TakeMaterialsStepProps = {
  title: string;
  projectId?: Uuid;
  neededInventoryItems?: NeededInventoryItem[];
  project?: Project;
  materials: (InventoryItem | PublicMovingBox)[];
  offSetIndex?: number;
  projectOutstandingInventoryItemsProps?: Partial<ProjectOutstandingInventoryItemsProps>;
};

const TakeMaterialsStep = ({
  projectId,
  neededInventoryItems,
  project,
  materials,
  title,
  offSetIndex,
  projectOutstandingInventoryItemsProps,
}: TakeMaterialsStepProps) => (
  <ScrollView>
    <Box
      flex={1}
      paddingHorizontal={{
        tablet: 50,
        phone: 20,
      }}
    >
      <Typography overrideColor={colors.white}>
        <FormattedMessage id={title} />
      </Typography>
      {projectId && (
        <Box pt={3}>
          <ProjectOutstandingInventoryItems
            light
            projectId={projectId}
            rooms={[]}
            neededInventoryItems={
              neededInventoryItems || project?.neededInventoryItems || []
            }
            {...(projectOutstandingInventoryItemsProps || {})}
          />
        </Box>
      )}
      <Box pt={3} flex={1}>
        <TakeMaterialsForm materials={materials} offSetIndex={offSetIndex} />
      </Box>
    </Box>
  </ScrollView>
);

export default TakeMaterialsStep;
