import { InternalTimeOffType } from '@bas/hrm-domain/models';
import { ErrorResponse, TranslatedName, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type CreateTimeOffTypeMutationProps = {
  timeOffTypeId: Uuid;
  name: string;
  weight: number;
  translatedNames: TranslatedName[];
  internalTimeOffType: InternalTimeOffType;
  allowBuildUp: boolean;
  icon: string;
};

export const CreateTimeOffTypeMutation = async ({
  ...values
}: CreateTimeOffTypeMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/{tenantId}/hrm/time-off-types`, {
    ...values,
  });

export const useCreateTimeOffTypeMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    CreateTimeOffTypeMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  CreateTimeOffTypeMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    CreateTimeOffTypeMutationProps
  >({
    mutationFn: CreateTimeOffTypeMutation,
    throwOnError: false,
    ...options,
  });
