import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 21,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(255, 255, 255, 0.2)',
  },
  column: {
    paddingRight: 14,
  },
  cubicMeter: {
    paddingTop: 4,
  },
});
