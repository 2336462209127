import {
  BusinessRelation,
  isBusinessRelation,
  isPrivateRelation,
  PrivateRelation,
  Relation,
} from '@bas/crm-domain/models';
import { Box, Typography } from '@bas/ui/native/base';
import { ContactPerson } from '@bas/value-objects';
import { FunctionComponent, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { ViewProps } from 'react-native';
import { RenderContactInformation } from '../RenderContactInformation';

export type RelationContactInformationProps = ViewProps & {
  relation: Relation;
};

export type ContactPersonContactInformationProps = {
  contactPerson: ContactPerson;
};

const ContactPersonContactInformation = ({
  contactPerson,
}: ContactPersonContactInformationProps): ReactElement | null => {
  if (contactPerson.primaryContactInformation) {
    return (
      <RenderContactInformation
        contactInformation={contactPerson.primaryContactInformation}
      />
    );
  }

  if (contactPerson.businessContactInformation) {
    return (
      <RenderContactInformation
        contactInformation={contactPerson.businessContactInformation}
      />
    );
  }

  return null;
};

export type PrivateRelationInformationProps = {
  relation: PrivateRelation;
};

const PrivateRelationInformation: FunctionComponent<
  PrivateRelationInformationProps
> = ({ relation }: PrivateRelationInformationProps) => {
  if (relation.primaryContactInformation) {
    return (
      <RenderContactInformation
        contactInformation={relation.primaryContactInformation}
      />
    );
  }

  if (relation.businessContactInformation) {
    return (
      <RenderContactInformation
        contactInformation={relation.businessContactInformation}
      />
    );
  }

  return null;
};

export type BusinessRelationInformationProps = {
  relation: BusinessRelation;
};

const BusinessRelationInformation: FunctionComponent<
  BusinessRelationInformationProps
> = ({ relation }: BusinessRelationInformationProps) => {
  if (relation.primaryContactPerson) {
    return (
      <ContactPersonContactInformation
        contactPerson={relation.primaryContactPerson}
      />
    );
  }

  if (relation.invoiceContactPerson) {
    return (
      <ContactPersonContactInformation
        contactPerson={relation.invoiceContactPerson}
      />
    );
  }

  if (relation.contactPersons.length > 0) {
    return (
      <ContactPersonContactInformation
        contactPerson={relation.contactPersons[0]}
      />
    );
  }

  return null;
};

const RelationContactInformation: FunctionComponent<
  RelationContactInformationProps
> = ({ relation, ...args }: RelationContactInformationProps) => (
  <Box flexDirection="column" {...args}>
    <Typography variant="subtitle1">
      <FormattedMessage id="label.contactInformation" />
    </Typography>
    <Typography>
      {isPrivateRelation(relation) && (
        <PrivateRelationInformation relation={relation} />
      )}
      {isBusinessRelation(relation) && (
        <BusinessRelationInformation relation={relation} />
      )}
    </Typography>
  </Box>
);

export default RelationContactInformation;
