import { isTask } from './Task';
import type { TaskRegardingQuote } from '../types';

export function isTaskRegardingQuote(
  object: unknown,
): object is TaskRegardingQuote {
  return (
    !!object &&
    isTask(object) &&
    Object.prototype.hasOwnProperty.call(object, 'quoteId') &&
    Object.prototype.hasOwnProperty.call(object, 'quotationNumber')
  );
}
