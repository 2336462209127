import { CrmContactPersonType } from '@bas/crm-domain/models';
import { getDefaultCountry } from '@bas/shared/utils';
import {
  Country,
  EmailAddress,
  ErrorResponse,
  Gender,
  PhoneNumber,
  Salutation,
  Uuid,
} from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type AddContactPersonToRelationMutationProps = {
  relationId: Uuid;
  contactPersonId: Uuid;
  contactPersonType: CrmContactPersonType;
  firstName?: string | null;
  middleName?: string | null;
  lastName: string;
  emailAddress?: EmailAddress | null;
  phoneNumber?: PhoneNumber | null;
  mobileNumber?: PhoneNumber | null;
  gender?: Gender | null;
  salutation?: Salutation | null;
  region?: Country;
};

export const AddContactPersonToRelationMutation = async ({
  relationId,
  ...values
}: AddContactPersonToRelationMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/relations/${relationId}/contact-persons`, {
    relationId,
    region: getDefaultCountry(),
    ...values,
  });

export const useAddContactPersonToRelationMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddContactPersonToRelationMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  AddContactPersonToRelationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddContactPersonToRelationMutationProps
  >({
    mutationFn: AddContactPersonToRelationMutation,
    throwOnError: false,
    ...options,
  });
