import * as Yup from 'yup';
import dayjs from 'dayjs';
import { VolumeType } from '@bas/value-objects';
import {
  InventoryListInputType,
  inventoryListInputTypeDefaultValues,
  inventoryListInputTypeValidationBuilder,
} from './inventoryListInputType';
import {
  VolumeChangeInputType,
  volumeChangeInputTypeDefaultValues,
  volumeChangeInputTypeValidationBuilder,
} from './volumeChangeInputType';
import {
  OptionalHandlingCostsInputType,
  optionalHandlingCostsInputTypeDefaultValues,
  optionalHandlingCostsInputTypeValidationBuilder,
} from './optionalHandlingCostsInputType';

export type LoadOrUnloadStorageWithInventoryInputType = InventoryListInputType &
  VolumeChangeInputType &
  OptionalHandlingCostsInputType & {
    notes?: string | null;
    percentageFull: number;
  };

export const loadOrUnloadStorageWithInventoryInputTypeDefaultValues =
  (): LoadOrUnloadStorageWithInventoryInputType => ({
    notes: '',
    percentageFull: 0,
    ...optionalHandlingCostsInputTypeDefaultValues(),
    ...inventoryListInputTypeDefaultValues(),
    ...volumeChangeInputTypeDefaultValues(),
  });

export const loadOrUnloadStorageWithInventoryInputTypeValidationBuilder =
  (): Yup.ObjectSchema<LoadOrUnloadStorageWithInventoryInputType> =>
    Yup.object({
      notes: Yup.string().label('label.notes').nullable(),
      percentageFull: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .nullable()
        .required()
        .label('label.percentageFull'),
      newVolume: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .defined()
        .nullable()
        .when(['oldVolume', 'invoicePerVolume'], {
          is: (oldVolume: number | null, invoicePerVolume: boolean | null) =>
            invoicePerVolume && oldVolume === null,
          then: (schema) => schema.required().min(0),
        })
        .label('label.newVolume'),
      invoicePerVolume: Yup.boolean()
        .nullable()
        .defined()
        .label('label.invoicePerVolume'),
      oldVolume: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .defined()
        .nullable()
        .label('label.oldVolume'),
      volumeType: Yup.mixed<VolumeType>()
        .oneOf(Object.values(VolumeType).filter((option) => !!option))
        .defined()
        .nullable()
        .label('label.volumeType'),
      lastInvoicedDate: Yup.date()
        .defined()
        .nullable()
        .label('label.lastInvoicedDate'),
      changedOn: Yup.date()
        .when(
          ['newVolume', 'oldVolume', 'lastInvoicedDate'],
          ([newVolume, oldVolume, lastInvoicedDate], schema) => {
            if (oldVolume === null || newVolume === oldVolume) {
              return schema;
            }

            if (!lastInvoicedDate || !dayjs(lastInvoicedDate).isValid()) {
              return schema;
            }

            return schema.min(dayjs(lastInvoicedDate).toDate());
          },
        )
        .defined()
        .nullable()
        .label('label.changedOn'),
    })
      .concat(inventoryListInputTypeValidationBuilder())
      .concat(optionalHandlingCostsInputTypeValidationBuilder())
      .concat(volumeChangeInputTypeValidationBuilder());
