import { Address } from '@bas/value-objects';
import axios, { AxiosResponse, CancelTokenSource } from 'axios';

export type AutocompleteAddressRequestProps = {
  country: string;
  zipCode: string;
  houseNumber: string;
  streetName?: string | null;
  houseNumberAddition?: string | null;
};

let cancelTokenSource: CancelTokenSource;
export const AutocompleteAddressRequest = async ({
  ...params
}: AutocompleteAddressRequestProps): Promise<AxiosResponse<Address>> => {
  if (typeof cancelTokenSource !== typeof undefined) {
    cancelTokenSource.cancel('Operation canceled due to new request.');
  }

  cancelTokenSource = axios.CancelToken.source();

  return axios.post('api/address/autocomplete', params, {
    cancelToken: cancelTokenSource.token,
  });
};
