import { getCountryName } from '@bas/shared/utils';
import {
  TouchableWithoutFeedback,
  Typography,
  TypographyProps,
  TypographyVariant,
} from '@bas/ui/native/base';
import { Address } from '@bas/value-objects';
import { ReactElement, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Linking, View, ViewProps } from 'react-native';

export type AddressBlockProps = ViewProps & {
  address: Address;
  showLink?: boolean;
  showCountry?: boolean;
  variant?: 'single_line' | 'multiline';
  addressLabel?: string | ReactNode;
  children?: ReactNode | ReactNode[];
  color?: string;
  addressLabelProps?: TypographyProps;
  textVariant?: TypographyVariant;
};

const AddressBlock = ({
  address,
  showLink = false,
  addressLabel,
  showCountry = false,
  variant = 'multiline',
  children,
  color,
  addressLabelProps,
  textVariant = 'body1',
  ...args
}: AddressBlockProps): ReactElement => {
  if (variant === 'single_line') {
    return (
      <View {...args}>
        <Typography overrideColor={color} variant={textVariant}>
          {addressLabel && <b>{addressLabel} -&nbsp;</b>}
          {`${address.streetName} ${address.houseNumber}`}
          {address.houseNumberAddition && ` ${address.houseNumberAddition}`}
          {variant !== 'single_line' && '\n'}
          {variant === 'single_line' && ', '}
          {`${address.zipCode} ${address.city}`}
          {variant !== 'single_line' && showCountry && '\n'}
          {showCountry && getCountryName(address.country)}
        </Typography>
      </View>
    );
  }

  if (!address) {
    return <View />;
  }

  return (
    <View {...args}>
      {addressLabel && (
        <Typography
          overrideColor={color}
          variant="subtitle2"
          {...addressLabelProps}
        >
          {addressLabel}
        </Typography>
      )}
      <Typography overrideColor={color} variant={textVariant}>
        {showLink && address.streetName && (
          <TouchableWithoutFeedback
            onPress={() =>
              Linking.openURL(
                `http://maps.google.com/maps?q=${address.streetName.replace(
                  '&',
                  ' ',
                )} ${address.houseNumber} ${
                  address.houseNumberAddition || ''
                } ${address.zipCode} ${address.city} ${getCountryName(
                  address.country,
                )}&navigate=yes`,
              )
            }
          >
            <Typography
              overrideColor={color}
              style={{
                textDecorationLine: 'underline',
              }}
              variant={textVariant}
            >
              <FormattedMessage id="button.goToGoogleMaps" />
            </Typography>
          </TouchableWithoutFeedback>
        )}
        {showLink && '\n'}
        {`${address.streetName} ${address.houseNumber}`}
        {address.houseNumberAddition && ` ${address.houseNumberAddition}`}
        {'\n'}
        {`${address.zipCode} ${address.city}`}
        {showCountry && '\n'}
        {showCountry && getCountryName(address.country)}
      </Typography>
      {children}
    </View>
  );
};

export default AddressBlock;
