/* eslint-disable react/prop-types */
import { FinancialDocumentLinesEditorInputType } from '@bas/financial-domain/input-types';
import {
  useTurnoverGroupsRequest,
  useVatCodesRequest,
} from '@bas/financial-domain/requests';
import { Tooltip } from '@bas/ui/native/atoms';
import {
  Box,
  BoxProps,
  Icon,
  ShopifyTheme,
  Typography,
} from '@bas/ui/native/base';
import {
  ReactHookFormDropdown,
  ReactHookFormHoursTextField,
  ReactHookFormNumberTextField,
  ReactHookFormTextField,
} from '@bas/ui/native/molecules';
import { DocumentType } from '@bas/value-objects';
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock';
import { faSquareCheck } from '@fortawesome/pro-light-svg-icons/faSquareCheck';
import { faTrashCan } from '@fortawesome/pro-light-svg-icons/faTrashCan';
import { faClock as faSolidClock } from '@fortawesome/pro-solid-svg-icons/faClock';
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons/faEllipsisVertical';
import { faSquareCheck as faSolidSquareCheck } from '@fortawesome/pro-solid-svg-icons/faSquareCheck';
import { pick } from 'lodash';
import * as React from 'react';
import { memo, ReactElement, useCallback, useMemo } from 'react';
import isEqual from 'react-fast-compare';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { TouchableOpacity } from 'react-native';

export type FinancialDocumentLinesEditorLineRowProps = BoxProps & {
  index: number;
  onRemove: () => void;
  onStartDrag?: () => void;
  allowRemove: boolean;
  documentType: 'quote' | 'invoice';
  light?: boolean;
  disableDrag?: boolean;
};

type ActionButtonProps = {
  value: boolean;
  onPress: (value: boolean) => void;
};

export const FinancialDocumentLinesEditorLineRowHourlyRateButton = memo(
  ({ value, onPress }: ActionButtonProps) => (
    <Tooltip popover={<FormattedMessage id="label.hourlyRate.explained" />}>
      <Box>
        <TouchableOpacity onPress={() => onPress(value)}>
          <Icon
            icon={value ? faSolidClock : faClock}
            size={20}
            color={value ? 'mainPrimary' : 'darkText'}
          />
        </TouchableOpacity>
      </Box>
    </Tooltip>
  ),
  isEqual,
);

export const FinancialDocumentLinesEditorLineRowOptionalButton = memo(
  ({ value, onPress }: ActionButtonProps) => (
    <Tooltip popover={<FormattedMessage id="label.optional.explained" />}>
      <Box>
        <TouchableOpacity onPress={() => onPress(value)}>
          <Icon
            icon={value ? faSolidSquareCheck : faSquareCheck}
            size={20}
            color={value ? 'mainPrimary' : 'darkText'}
          />
        </TouchableOpacity>
      </Box>
    </Tooltip>
  ),
  isEqual,
);

const QuantityField = memo(
  ({
    documentType,
    light,
    index,
  }: {
    documentType: 'quote' | 'invoice';
    light?: boolean;
    index: number;
  }): ReactElement => {
    const hourlyRate = useWatch<FinancialDocumentLinesEditorInputType>({
      name: `quote.lines.${index}.hourlyRate`,
    });

    return useMemo(
      () =>
        hourlyRate ? (
          <ReactHookFormHoursTextField
            name={`${documentType}.lines.${index}.quantity`}
            light={light}
            autoComplete="off"
            keyboardType="decimal-pad"
          />
        ) : (
          <ReactHookFormNumberTextField
            name={`${documentType}.lines.${index}.quantity`}
            keyboardType="decimal-pad"
            light={light}
            fractionDigits={2}
            padZero={false}
            showZero
          />
        ),
      [documentType, hourlyRate, index, light],
    );
  },
  isEqual,
);

const ActionButtons = memo(
  ({
    documentType,
    index,
  }: {
    documentType: 'quote' | 'invoice';
    index: number;
  }): ReactElement | null => {
    const { setValue } =
      useFormContext<FinancialDocumentLinesEditorInputType>();
    const [hourlyRate, optional] =
      useWatch<FinancialDocumentLinesEditorInputType>({
        name: [
          `quote.lines.${index}.hourlyRate`,
          `quote.lines.${index}.optional`,
        ],
      });

    return useMemo(
      () =>
        documentType === DocumentType.QUOTE ? (
          <>
            <Box pl={1}>
              <FinancialDocumentLinesEditorLineRowHourlyRateButton
                value={hourlyRate as boolean}
                onPress={(value) =>
                  setValue(`quote.lines.${index}.hourlyRate`, !value)
                }
              />
            </Box>

            <Box pl={1}>
              <FinancialDocumentLinesEditorLineRowOptionalButton
                value={optional as boolean}
                onPress={(value) =>
                  setValue(`quote.lines.${index}.optional`, !value)
                }
              />
            </Box>
          </>
        ) : null,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [documentType, hourlyRate, index, optional],
    );
  },
  isEqual,
);

const OptionalMessage = memo(
  ({
    documentType,
    index,
  }: {
    documentType: 'quote' | 'invoice';
    index: number;
  }) => {
    const optional = useWatch<FinancialDocumentLinesEditorInputType>({
      name: `quote.lines.${index}.optional`,
    });

    return optional ? (
      <Typography variant="caption">
        <FormattedMessage id="label.optional" />
      </Typography>
    ) : null;
  },
  isEqual,
);

const VatCodeField = memo(
  ({
    documentType,
    index,
    light,
  }: {
    documentType: 'quote' | 'invoice';
    index: number;
    light?: boolean;
  }) => {
    const { setValue } =
      useFormContext<FinancialDocumentLinesEditorInputType>();
    const { data: vatCodesData } = useVatCodesRequest(
      {
        perPage: 99999,
      },
      {
        refetchInterval: false,
        refetchOnWindowFocus: false,
      },
    );

    const vatCodes = useMemo(
      () => vatCodesData?.data?.member || [],
      [vatCodesData],
    );

    const handleSelectVatCode = useCallback(
      (newVatCodeId: string | undefined) => {
        if (
          typeof newVatCodeId !== 'string' &&
          typeof newVatCodeId !== 'undefined'
        ) {
          return;
        }

        const vatCode = vatCodes.find(
          ({ vatCodeId }) => vatCodeId === newVatCodeId,
        );
        if (vatCode) {
          setValue(`${documentType}.lines.${index}.vatCode`, vatCode, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          });
          setValue(
            `${documentType}.lines.${index}.vatPercentage`,
            vatCode.percentage,
            {
              shouldDirty: true,
              shouldTouch: true,
              shouldValidate: true,
            },
          );
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [documentType, index, vatCodes],
    );

    return useMemo(
      () => (
        <ReactHookFormDropdown
          name={`${documentType}.lines.${index}.vatCode`}
          fieldValue="vatCodeId"
          light={light}
          onChange={handleSelectVatCode}
          items={vatCodes
            .filter((vatCode) => !!vatCode.percentage)
            .map((vatCode) => ({
              id: vatCode.vatCodeId,
              label: `${vatCode.percentage?.percentage}%`,
            }))}
        />
      ),
      [documentType, index, light, handleSelectVatCode, vatCodes],
    );
  },
  isEqual,
);

const TurnoverGroupField = memo(
  ({
    documentType,
    index,
    light,
  }: {
    documentType: 'quote' | 'invoice';
    index: number;
    light?: boolean;
  }): ReactElement => {
    const { data: turnoverGroupsData } = useTurnoverGroupsRequest(
      {
        perPage: 9999,
      },
      {
        refetchInterval: false,
        refetchOnWindowFocus: false,
      },
    );

    const { formatMessage } = useIntl();

    const turnoverGroups = useMemo(
      () =>
        (turnoverGroupsData?.data?.member || []).map((turnoverGroup) => ({
          id: turnoverGroup.turnoverGroupId,
          label: turnoverGroup.name,
        })),
      [turnoverGroupsData],
    );

    return (
      <ReactHookFormDropdown
        name={`${documentType}.lines.${index}.turnoverGroupId`}
        light={light}
        items={[
          {
            id: '',
            label: formatMessage({ id: 'label.none' }),
          },
          ...turnoverGroups,
        ]}
      />
    );
  },
  isEqual,
);
const FinancialDocumentLinesEditorLineRow = ({
  index,
  onRemove,
  allowRemove,
  documentType,
  light,
  onStartDrag,
  disableDrag,
  ...props
}: FinancialDocumentLinesEditorLineRowProps): ReactElement => {
  let thrashColor: keyof ShopifyTheme['colors'] = 'darkText';
  if (!allowRemove) {
    thrashColor = 'titleBorder';
  } else if (light) {
    thrashColor = 'white';
  }

  return (
    <Box
      flex={1}
      flexDirection="row"
      pt="invoiceLines"
      pb="invoiceLines"
      alignItems="center"
      {...props}
    >
      <Box width={40}>
        <TouchableOpacity onPressIn={onStartDrag} disabled={disableDrag}>
          <Box width="100%" minWidth={40}>
            <Icon color="secondaryTitle" icon={faEllipsisVertical} size={20} />
          </Box>
        </TouchableOpacity>
      </Box>
      <Box mr={2} width="20%" minWidth={175} maxWidth={175} flex={1}>
        <TurnoverGroupField
          documentType={documentType}
          index={index}
          light={light}
        />
      </Box>

      <Box mr={2} minWidth={200} width={200} flex={1}>
        <ReactHookFormTextField
          name={`${documentType}.lines.${index}.description`}
          multiline
          light={light}
        />
        <OptionalMessage documentType={documentType} index={index} />
      </Box>
      <Box mr={2} width={87.75}>
        <QuantityField
          documentType={documentType}
          index={index}
          light={light}
        />
      </Box>
      <Box mr={2} width={82.75}>
        <VatCodeField documentType={documentType} index={index} light={light} />
      </Box>
      <Box mr={2} width={137.75}>
        <ReactHookFormNumberTextField
          name={`${documentType}.lines.${index}.pricePerUnit`}
          light={light}
          fieldValue="amount"
          autoComplete="off"
          keyboardType="decimal-pad"
          textAlign="right"
          divideBy={100}
          fractionDigits={2}
          type="currency"
        />
      </Box>
      <Box width={84} flexDirection="row" flexWrap="nowrap">
        <ActionButtons documentType={documentType} index={index} />

        <Box pl={1}>
          <TouchableOpacity onPress={allowRemove ? onRemove : undefined}>
            <Icon icon={faTrashCan} size={20} color={thrashColor} />
          </TouchableOpacity>
        </Box>
      </Box>
    </Box>
  );
};

const propsToCheck: (keyof FinancialDocumentLinesEditorLineRowProps)[] = [
  'index',
  'allowRemove',
  'documentType',
  'light',
  'disableDrag',
];

const MemoComponent = memo(
  FinancialDocumentLinesEditorLineRow,
  (prevPros, nextPros) =>
    isEqual(pick(prevPros, propsToCheck), pick(nextPros, propsToCheck)),
);
export default MemoComponent;
