export enum ClaHourType {
  FIELD_WORK_HOURS = 'field-work-hours',
  WAREHOUSE_HOURS = 'warehouse-hours',
  BACKOFFICE_HOURS = 'backoffice-hours',
  BREAK_TIME_HOURS = 'break-time-hours',
  SICK_HOURS = 'sick-hours',
  LEAVE_HOURS = 'leave-hours',
  ALL_EXCEPT_BREAK_TIME_HOURS = 'all-except-break-time-hours',
  UNKNOWN = '',
}
