import { Alert } from '@bas/ui/native/atoms';
import { Box } from '@bas/ui/native/base';
import {
  ASTNode,
  RenderRules,
} from '@jonasmerlin/react-native-markdown-display';
import MarkdownIt from 'markdown-it';
import customFence from 'markdown-it-container';
import Children from 'react-children-utilities';

const severityMap = {
  warning: 'error',
  tip: 'info',
  info: 'warning',
};
type ASTNodeWithSourceInfo = ASTNode & { sourceInfo: string };

const isASTNodeWithSourceInfo = (
  node: unknown,
): node is ASTNodeWithSourceInfo => {
  if (typeof node !== 'object' || node === null) {
    return false;
  }

  return Object.prototype.hasOwnProperty.call(node, 'sourceInfo') === false;
};

export const noticeRules: RenderRules = {
  container_notice: (node: unknown, children) => {
    if (!isASTNodeWithSourceInfo(node)) {
      return null;
    }

    if (
      !(
        node.sourceInfo || !['warning', 'tip', 'info'].includes(node.sourceInfo)
      )
    ) {
      return null;
    }

    return (
      <Box key={node.key} mb={1}>
        <Alert
          severity={
            (severityMap[
              (node.sourceInfo || 'info') as keyof typeof severityMap
            ] || 'info') as 'error' | 'warning' | 'info' | 'success'
          }
        >
          {Children.onlyText(children)}
        </Alert>
      </Box>
    );
  },
};

export default function notice(md: MarkdownIt) {
  return customFence(md, 'notice', {
    marker: ':',
    validate: () => true,
  });
}
