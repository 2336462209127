import type { VideoMessageMetadata } from '../../types';
import { MessageType } from '../../types';
import { isMessageMetadata } from './MessageMetadata';

export function isVideoMessageMetadata(
  object: unknown,
): object is VideoMessageMetadata {
  return (
    isMessageMetadata(object) &&
    object.messageType === MessageType.VIDEO_MESSAGE
  );
}
