import { Crew, PlannedEmployee, Vehicle } from '@bas/planning-domain/models';
import { EventPageHeader } from '@bas/planning-domain/native/molecules';
import {
  isMovingJobAddress,
  MovingJobAddressType,
  ProjectAddress,
} from '@bas/project-domain/models';
import { MovingJobAddressInformation } from '@bas/project-domain/moving-job/native/molecules';
import { Location } from '@bas/shared/models';
import { colors } from '@bas/theme';
import {
  AddressBlock,
  Alert,
  AlertProps,
  EventIcon,
} from '@bas/ui/native/atoms';
import { Box, Typography } from '@bas/ui/native/base';
import {
  IdentityIndicator,
  MobileActionButtonsGroup,
  PlannedEmployeeList,
  PlannedMaterialList,
  PlannedMaterialListItem,
} from '@bas/ui/native/molecules';
import { Country, EventType, ProjectType, Uuid } from '@bas/value-objects';
import { router } from 'expo-router';
import * as React from 'react';
import { memo, ReactElement, ReactNode, useCallback, useMemo } from 'react';
import isEqual from 'react-fast-compare';
import { FormattedMessage } from 'react-intl';
import {
  RefreshControl,
  ScrollView,
  useWindowDimensions,
  ViewProps,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import styles from './styles';

export type EventScreenTemplateProps = ViewProps & {
  finished?: boolean;
  headerLabel: string | ReactNode;
  subHeaderLabel: string | ReactNode;
  onBack?: () => void;
  alert?: AlertProps;
  actions: ReactElement[];
  loading?: boolean;
  notes?: string | null;
  identityId?: Uuid;
  eventType: EventType;
  location?: Location;
  team: PlannedEmployee[];
  country?: Country;
  eventId: Uuid;
  projectType?: ProjectType;
  plannedMaterials: Vehicle[];
  allowEmployeeToSeeColleagueDetails?: boolean;
  addresses: ProjectAddress[];
  crews: Crew[];
  isFetching?: boolean;
  onRefresh?: () => void;
};

const pushPlannedEmployeeDetails = (
  eventId: Uuid,
  plannedEmployee: PlannedEmployee,
) =>
  router.push(
    `/event/${eventId}/employee/${plannedEmployee.employee.employeeId}`,
  );

const pushVehicleDetails = (eventId: Uuid, vehicle: Vehicle) =>
  router.push(`/event/${eventId}/vehicle/${vehicle.materialId}`);

const EventScreenTemplate = ({
  finished,
  headerLabel,
  subHeaderLabel,
  onBack,
  alert,
  actions,
  loading,
  notes,
  identityId,
  eventId,
  eventType,
  location,
  crews,
  team,
  country,
  projectType,
  plannedMaterials,
  allowEmployeeToSeeColleagueDetails,
  addresses,
  children,
  isFetching,
  onRefresh,
}: EventScreenTemplateProps): ReactElement => {
  const insets = useSafeAreaInsets();
  const dimensions = useWindowDimensions();

  const smallScreen = useMemo(() => dimensions.width < 600, [dimensions]);

  const handlePressEmployee = useCallback(
    (employee: PlannedEmployee) => {
      if (allowEmployeeToSeeColleagueDetails) {
        pushPlannedEmployeeDetails(eventId, employee);
      }
    },
    [allowEmployeeToSeeColleagueDetails, eventId],
  );

  const handlePressMaterial = useCallback(
    (material: Vehicle) => {
      pushVehicleDetails(eventId, material);
    },
    [eventId],
  );

  return (
    <Box style={styles.flexContainer}>
      <EventPageHeader
        loading={loading}
        onBack={onBack}
        headerLabel={headerLabel}
        subHeaderLabel={subHeaderLabel}
        finished={finished}
        identityId={identityId}
      />
      <ScrollView
        style={styles.flexContainer}
        contentContainerStyle={styles.contentContainer}
        refreshControl={
          onRefresh ? (
            <RefreshControl
              refreshing={isFetching || false}
              onRefresh={onRefresh}
              tintColor={colors.lila[800]}
              colors={[colors.lila[800]]}
            />
          ) : undefined
        }
      >
        <Box style={styles.actionsContainer}>
          <Box style={styles.eventTypeContainer}>
            <EventIcon
              eventType={eventType}
              style={styles.eventTypeIcon}
              size={24}
              color={colors.blue[900]}
            />
            <Typography variant="h5" overrideColor={colors.blue[900]}>
              <FormattedMessage id={`mobileApp.event.eventType.${eventType}`} />
            </Typography>
          </Box>
          {alert && <Alert {...alert} style={[alert.style, styles.alert]} />}

          <MobileActionButtonsGroup style={styles.actions} buttons={actions} />
        </Box>
        <Box
          style={[
            styles.container,
            styles.whiteBackground,
            { paddingBottom: insets.bottom + 28 },
          ]}
        >
          {identityId && (
            <Box style={[styles.paddingContent]}>
              <Typography variant="subtitle1">
                <FormattedMessage id="label.forIdentity" />
              </Typography>
              <IdentityIndicator identityId={identityId} full />
            </Box>
          )}
          {notes && (
            <Box style={[styles.paddingContent]}>
              <Typography variant="subtitle1">
                <FormattedMessage id="label.notes" />
              </Typography>
              <Typography>{notes}</Typography>
            </Box>
          )}
          {crews.length > 1 ? (
            crews.map((crew, index) => (
              <Box style={[styles.paddingContent]} key={crew.crewId}>
                <Typography variant="subtitle1" style={[styles.teamTitle]}>
                  <FormattedMessage
                    id="label.teamWithNumber"
                    values={{ number: index + 1 }}
                  />
                </Typography>

                {crew.vehicle && (
                  <PlannedMaterialListItem
                    material={crew.vehicle}
                    country={country}
                    onPress={() => handlePressMaterial(crew.vehicle)}
                  />
                )}
                <PlannedEmployeeList
                  plannedEmployees={crew.employees}
                  onPressEmployee={handlePressEmployee}
                />
              </Box>
            ))
          ) : (
            <>
              {team.length > 0 && (
                <Box style={[styles.paddingContent]}>
                  <Typography variant="subtitle1" style={[styles.teamTitle]}>
                    <FormattedMessage id="label.team" />
                  </Typography>
                  <PlannedEmployeeList
                    plannedEmployees={team}
                    onPressEmployee={handlePressEmployee}
                  />
                </Box>
              )}
              {plannedMaterials.length > 0 && (
                <Box style={[styles.paddingContent]}>
                  <Typography
                    variant="subtitle1"
                    style={[styles.plannedMaterialsTitle]}
                  >
                    <FormattedMessage id="label.plannedMaterials" />
                  </Typography>
                  <PlannedMaterialList
                    materials={plannedMaterials}
                    country={country}
                    onPressMaterial={handlePressMaterial}
                  />
                </Box>
              )}
            </>
          )}
          {addresses.length > 0 && (
            <Box style={[styles.paddingContent]}>
              <Typography variant="subtitle1">
                <FormattedMessage id="label.addresses" />
              </Typography>
              <Box style={styles.addressesContainer}>
                {Object.values(MovingJobAddressType).map(
                  (movingJobAddressType, typeIndex) => (
                    <Box
                      key={movingJobAddressType}
                      style={[
                        styles.addressesTypeContainer,
                        smallScreen && styles.fullWidthAddress,
                        typeIndex !== 0 && styles.paddingFullWidth,
                      ]}
                    >
                      {addresses
                        .filter(
                          ({ addressType }) =>
                            addressType === movingJobAddressType,
                        )
                        .map((address) => (
                          <AddressBlock
                            key={address.addressId}
                            address={address}
                            showLink
                            showCountry={country !== address.country}
                            color={colors.lila[800]}
                            addressLabelProps={{
                              overrideColor: colors.lila[600],
                              variant: 'body1',
                            }}
                            addressLabel={
                              <FormattedMessage
                                id={`project.${projectType}.addressType.${address.addressType}`}
                              />
                            }
                          >
                            {isMovingJobAddress(address) && (
                              <MovingJobAddressInformation
                                style={styles.addressInformation}
                                forceShowFloor
                                buildingType={address.buildingType}
                                trafficSigns={!!address.trafficSigns}
                                permitNeeded={!!address.permitNeeded}
                                hasElevator={!!address.hasElevator}
                                floor={address.floor}
                                distanceFromCar={address.distanceFromCar}
                                distanceToApartment={
                                  address.distanceToApartment
                                }
                              />
                            )}
                          </AddressBlock>
                        ))}
                    </Box>
                  ),
                )}
              </Box>
            </Box>
          )}
          {location && (
            <Box style={[styles.paddingContent]}>
              <Typography variant="subtitle1">
                <FormattedMessage id="label.addresses" />
              </Typography>
              <AddressBlock
                address={location}
                showLink
                color={colors.lila[800]}
                showCountry={country !== location.country}
              />
            </Box>
          )}
          {children && <Box>{children}</Box>}
        </Box>
      </ScrollView>
    </Box>
  );
};

const MemoComponent = memo(EventScreenTemplate, isEqual);
export default MemoComponent;
