import * as Yup from 'yup';

export interface NotesOfStorageInputType {
  notes?: string | null;
}

export const notesOfStorageInputTypeDefaultValues =
  (): NotesOfStorageInputType => ({
    notes: '',
  });

export const notesOfStorageInputTypeValidationBuilder =
  (): Yup.ObjectSchema<NotesOfStorageInputType> =>
    Yup.object({
      notes: Yup.string().label('label.notes'),
    });
