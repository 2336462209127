import { colors } from '@bas/theme';
import { Box, Typography } from '@bas/ui/native/base';
import dayjs from 'dayjs';
import { ReactElement, useMemo } from 'react';
import { FormattedMessage, FormattedTime } from 'react-intl';
import { ScrollView } from 'react-native-gesture-handler';
import { EnterEventHoursForm } from '../EnterEventHoursForm';
import styles from './styles';

export type ConfirmEventHoursStepProps = {
  realStart?: Date | null;
  startTime: Date;
  endTime: Date;
};

const ConfirmEventHoursStep = ({
  realStart,
  startTime,
  endTime,
}: ConfirmEventHoursStepProps): ReactElement => {
  const forceOverrideTime = useMemo(
    () =>
      !!realStart &&
      !!endTime &&
      dayjs(realStart).isValid() &&
      dayjs(endTime).isValid() &&
      dayjs(realStart).isAfter(endTime),
    [endTime, realStart],
  );

  return (
    <ScrollView>
      <Box flex={1}>
        <Box>
          <Typography
            overrideColor={colors.white}
            style={styles.textContainer}
            variant="h5"
          >
            {realStart ? (
              <FormattedMessage
                id={`mobileApp.event.finalizeEvent.${
                  !forceOverrideTime
                    ? 'youStartedAtXAndItWasUntilX'
                    : 'youStartedAtXAndItWasUntilXButTheEndTimeIsBeforeTheStartTime'
                }`}
                values={{
                  startTime: (
                    <Typography fontWeight={700} overrideColor={colors.white}>
                      <FormattedTime value={realStart} />
                    </Typography>
                  ),
                  endTime: (
                    <Typography fontWeight={700} overrideColor={colors.white}>
                      <FormattedTime value={endTime} />
                    </Typography>
                  ),
                }}
              />
            ) : (
              <FormattedMessage
                id="mobileApp.event.finalizeEvent.theEventWasFromXUntilX"
                values={{
                  startTime: (
                    <Typography fontWeight={700} overrideColor={colors.white}>
                      <FormattedTime value={startTime} />
                    </Typography>
                  ),
                  endTime: (
                    <Typography fontWeight={700} overrideColor={colors.white}>
                      <FormattedTime value={endTime} />
                    </Typography>
                  ),
                }}
              />
            )}
          </Typography>
        </Box>
        <Box flex={1}>
          <EnterEventHoursForm
            hideDescription
            forceOverrideTime={forceOverrideTime}
          />
        </Box>
      </Box>
    </ScrollView>
  );
};

export default ConfirmEventHoursStep;
