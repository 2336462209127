import { formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ReportInventoryOutMutationProps = {
  inventoryItemId: Uuid;
  eventDate: Date;
  quantity: number;
  customerId: Uuid;
  projectId?: Uuid | null;
  planningEventId?: Uuid | null;
};

export const ReportInventoryOutMutation = async ({
  inventoryItemId,
  eventDate,
  ...values
}: ReportInventoryOutMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/inventory/item/${inventoryItemId}/report-out`, {
    inventoryItemId,
    eventDate: formatDateTime(eventDate),
    ...values,
  });

export const useReportInventoryOutMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ReportInventoryOutMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ReportInventoryOutMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ReportInventoryOutMutationProps
  >({
    mutationFn: ReportInventoryOutMutation,
    throwOnError: false,
    ...options,
  });
