import { AddressInputType } from '@bas/shared/input-types';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type UpdateWarehouseAddressMutationProps = {
  warehouseId: Uuid;
  address: AddressInputType;
};

export const UpdateWarehouseAddressMutation = async ({
  warehouseId,
  ...values
}: UpdateWarehouseAddressMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/warehouses/${warehouseId}/update-address`, {
    warehouseId,
    ...values,
  });

export const useUpdateWarehouseAddressMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateWarehouseAddressMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  UpdateWarehouseAddressMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateWarehouseAddressMutationProps
  >({
    mutationFn: UpdateWarehouseAddressMutation,
    throwOnError: false,
    ...options,
  });
