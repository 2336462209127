import { DayOff } from '@bas/hrm-domain/models';
import { Box, Typography } from '@bas/ui/native/base';
import dayjs from 'dayjs';
import { ReactElement, useMemo } from 'react';
import {
  FormattedDateTimeRange,
  FormattedMessage,
  FormattedNumber,
} from 'react-intl';

export type TimeOffRegistrationDatesContentSectionProps = {
  daysOff: DayOff[];
  showApproved?: boolean;
};

const TimeOffRegistrationDatesContentSection = ({
  daysOff,
  showApproved,
}: TimeOffRegistrationDatesContentSectionProps): ReactElement => {
  const groupedDaysOff = useMemo(() => {
    const result = (daysOff || []).reduce(
      (acc, dayOff) => {
        const start = showApproved ? dayOff.approvedStart : dayOff.start;
        const end = showApproved ? dayOff.approvedEnd : dayOff.end;
        const hours = (showApproved ? dayOff.approvedHours : dayOff.hours) || 0;

        if (!start || !end) {
          return acc;
        }

        const key = `${dayjs(start).format('HH:mm')}-${dayjs(end).format(
          'HH:mm',
        )}`;
        if (!acc[key]) {
          acc[key] = {
            start,
            end,
            hours,
          };
        } else {
          acc[key].end = end;
          acc[key].hours += hours;
        }

        return acc;
      },
      {} as Record<
        string,
        {
          start: Date;
          end: Date;
          hours: number;
        }
      >,
    );

    return Object.values(result).sort(
      (a, b) =>
        dayjs(a.start).toDate().getTime() - dayjs(b.start).toDate().getTime(),
    );
  }, [daysOff, showApproved]);

  return (
    <Box flexDirection="row" flexWrap="wrap">
      <Box flexBasis="100%">
        <Typography variant="subtitle1" color="white">
          <FormattedMessage id="label.daysOff" />
        </Typography>
      </Box>
      <Box flexBasis="100%" flexDirection="row" flexWrap="wrap" rowGap={0.5}>
        {groupedDaysOff.map((dayOff) => (
          <Box
            key={`${dayOff.start}-${dayOff.end}`}
            flexBasis="100%"
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="space-between"
            columnGap={1}
          >
            <Box>
              <Typography color="white">
                <FormattedDateTimeRange
                  from={dayjs(dayOff.start).toDate()}
                  to={dayjs(dayOff.end).toDate()}
                  year="numeric"
                  month="long"
                  day="numeric"
                  hour="numeric"
                  minute="numeric"
                />
              </Typography>
            </Box>
            <Box>
              <Typography color="white">
                <FormattedNumber
                  value={dayOff.hours}
                  // eslint-disable-next-line react/style-prop-object
                  style="unit"
                  unit="hour"
                  unitDisplay="long"
                  maximumFractionDigits={2}
                />
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default TimeOffRegistrationDatesContentSection;
