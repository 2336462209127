import type { FileMessageMetadata } from '../../types';
import { MessageType } from '../../types';
import { isMessageMetadata } from './MessageMetadata';

export function isFileMessageMetadata(
  object: unknown,
): object is FileMessageMetadata {
  return (
    isMessageMetadata(object) && object.messageType === MessageType.FILE_MESSAGE
  );
}
