import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type RemoveAddressFromRelationMutationProps = {
  relationId: Uuid;
  addressId: Uuid;
};

export const RemoveAddressFromRelationMutation = async ({
  relationId,
  addressId,
  ...values
}: RemoveAddressFromRelationMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/relations/${relationId}/addresses/${addressId}/delete`,
    {
      relationId,
      addressId,
      ...values,
    },
  );

export const useRemoveAddressFromRelationMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveAddressFromRelationMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  RemoveAddressFromRelationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveAddressFromRelationMutationProps
  >({
    mutationFn: RemoveAddressFromRelationMutation,
    throwOnError: false,
    ...options,
  });
