import * as Yup from 'yup';
import { Uuid } from '@bas/value-objects';
import {
  SupplierInputType,
  SupplierInputTypeValidationBuilder,
} from './supplierInputType';
import {
  InventoryItemSupplierInputType,
  InventoryItemSupplierInputTypeValidationBuilder,
} from './inventoryItemSupplierInputType';

export interface EditSuppliersInputType {
  suppliers: (SupplierInputType &
    InventoryItemSupplierInputType & { supplierId: Uuid })[];
}

export const EditSuppliersInputTypeDefaultValues =
  (): EditSuppliersInputType => ({
    suppliers: [],
  });

export const EditSuppliersInputTypeValidationBuilder =
  (): Yup.ObjectSchema<EditSuppliersInputType> =>
    Yup.object({
      suppliers: Yup.array()
        .of(
          Yup.object({
            supplierId: Yup.string().required().label('label.supplier'),
          })
            .concat(SupplierInputTypeValidationBuilder())
            .concat(InventoryItemSupplierInputTypeValidationBuilder()),
        )
        .required(),
    });
