import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type AddAddressToMovingJobMutationProps = {
  projectId: Uuid;
  addressType: string;
  buildingType?: string | null;
  primary: boolean;
  notes?: string | null;
  streetName: string;
  houseNumber: string;
  houseNumberAddition?: string | null;
  zipCode: string;
  city: string;
  country: string;
  addressId?: string | null;
  distanceFromCar?: number | null;
  distanceToApartment?: number | null;
  floor?: number | null;
  hasElevator?: boolean | null;
  deliverBoxes?: boolean | null;
  retrieveBoxes?: boolean | null;
  trafficSigns?: boolean | null;
  permitNeeded?: boolean | null;
};

export const AddAddressToMovingJobMutation = async ({
  projectId,
  ...values
}: AddAddressToMovingJobMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/moving-jobs/${projectId}/add-address`, {
    projectId,
    ...values,
  });

export const useAddAddressToMovingJobMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddAddressToMovingJobMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  AddAddressToMovingJobMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddAddressToMovingJobMutationProps
  >({
    mutationFn: AddAddressToMovingJobMutation,
    throwOnError: false,
    ...options,
  });
