import { formatDate } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type SetTimeOffBalanceInBulkMutationProps = {
  timeOffId: Uuid;
  enteredByEmployeeId: Uuid;
  balancePerEmployee: {
    employeeId: Uuid;
    start: Date;
    end: Date;
    timeOffTypeId: Uuid;
    effectiveHours: number;
  }[];
};

export const SetTimeOffBalanceInBulkMutation = async ({
  balancePerEmployee,
  ...values
}: SetTimeOffBalanceInBulkMutationProps): Promise<AxiosResponse> =>
  axios.post(
    `api/{tenantId}/hrm/employees/time-off-registrations/set-balance-for-year-in-bulk`,
    {
      balancePerEmployee: balancePerEmployee.map((balance) => ({
        ...balance,
        start: formatDate(balance.start),
        end: formatDate(balance.end),
      })),
      ...values,
    },
  );

export const useSetTimeOffBalanceInBulkMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    SetTimeOffBalanceInBulkMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  SetTimeOffBalanceInBulkMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    SetTimeOffBalanceInBulkMutationProps
  >({
    mutationFn: SetTimeOffBalanceInBulkMutation,
    throwOnError: false,
    ...options,
  });
