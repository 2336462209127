import { MovingJobSkill } from '@bas/hrm-domain/models';
import {
  FinishWorkingDayFormWizardBottomSheet,
  StopWorkingDayBottomSheet,
} from '@bas/hrm-domain/native/organisms';
import { useTimeTypesRequest } from '@bas/hrm-domain/requests';
import {
  useTrafficByCountryRequest,
  useWeatherApiRequest,
} from '@bas/integration-domain/requests';
import {
  calculateRecurringEventsWithingPeriod,
  Event,
  isProjectEvent,
} from '@bas/planning-domain/models';
import { useOverrideRecurringEventMutation } from '@bas/planning-domain/mutations';
import {
  EventListItem,
  LoadingEventListItem,
} from '@bas/planning-domain/native/molecules';
import {
  useEventsByEmployeeIdRequest,
  useRecurringEventsByEmployeeIdRequest,
} from '@bas/planning-domain/requests';
import { useProjectsByProjectIdsRequest } from '@bas/project-domain/requests';
import { useHasBackendRight } from '@bas/shared/hooks';
import { ObstructionType } from '@bas/shared/models';
import {
  useAppStore,
  useEmployeeStore,
  useTenantStore,
} from '@bas/shared/state';
import { formatDate, getProjectIdsFromEvents } from '@bas/shared/utils';
import { OpenTaxationList } from '@bas/taxation-tool-domain/native/organisms';
import { colors } from '@bas/theme';
import dayImage from '@bas/ui/assets/Resources/day.png';
import {
  Button,
  MobileActionButton,
  Skeleton,
  TitleWithNumber,
} from '@bas/ui/native/atoms';
import { BottomSheetMethods, Box, Typography } from '@bas/ui/native/base';
import {
  useLogout,
  useTrackLocationForTimeTracking,
} from '@bas/ui/native/hooks';
import { MobileActionButtonsGroup } from '@bas/ui/native/molecules';
import {
  MobileTrafficObstructions,
  RequestWorkingDayPermissionsBottomSheet,
  StartWorkingDayBottomSheet,
  TrafficObstructionsBottomSheet,
} from '@bas/ui/native/organisms';
import { hasEventPage, Uuid } from '@bas/value-objects';
import { QrScannerBottomSheet } from '@bas/wms-domain/native/organisms';
import { faQrcode } from '@fortawesome/pro-light-svg-icons';
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock';
import { faMoneyCheckEditAlt } from '@fortawesome/pro-light-svg-icons/faMoneyCheckEditAlt';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { useIsFocused } from '@react-navigation/core';
import { keepPreviousData } from '@tanstack/react-query';
import dayjs from 'dayjs';
import * as Location from 'expo-location';
import { router } from 'expo-router';
import { StatusBar } from 'expo-status-bar';
import getDistance from 'geolib/es/getDistance';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ImageBackground,
  Linking,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
  RefreshControl,
  ScrollView,
  StyleSheet,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { v7 } from 'uuid';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 18,
  },
  header: {
    width: '100%',
    position: 'relative',
    minHeight: 200,
  },
  headerTextContainer: {
    position: 'absolute',
    top: 24,
    flex: 1,
    padding: 20,
    paddingTop: 27,
    paddingBottom: 17,
    width: '100%',
  },
  headerWelcome: {
    alignSelf: 'flex-end',
  },
  headerGoodLuck: {
    alignSelf: 'flex-end',
  },
  weatherTextContainer: {
    top: 20,
  },
  weatherTextContent: {
    backgroundColor: 'rgba(76, 109, 173, 0.5)',
    borderRadius: 5,
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 8,
    paddingRight: 8,
    flex: 1,
    alignSelf: 'flex-start',
  },
  weatherText: {
    color: colors.white,
    fontFamily: 'DM_Sans_700_normal',
    fontSize: 20,
    lineHeight: 26,
    flex: 1,
  },
  scrollContainer: { flex: 1 },
  trafficContainer: {
    marginBottom: 24,
  },
  actionsContainer: {
    marginBottom: 28,
    display: 'flex',
    flex: 10,
    flexDirection: 'row',
  },
  eventsContainer: {
    marginBottom: 50,
  },
  openEventsContainer: {
    marginBottom: 35,
  },
  events: {
    marginTop: 20,
  },
  headerImage: {
    flex: 1,
    width: '100%',
  },
  noOpenEventsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  avatar: {
    height: '100%',
    width: '100%',
  },
  avatarContainer: {
    overflow: 'hidden',
    borderRadius: 269,
    height: 268,
    width: 268,
    marginBottom: 29,
    marginTop: 29,
    alignSelf: 'center',
  },
  actionsLine: {
    borderTopWidth: 1,
    borderTopColor: colors.black,
    opacity: 0.1,
    width: '110%',
    left: -20,
    right: -20,
    flex: 1,
  },
  actionButtons: {
    paddingTop: 35,
  },
  actionButton: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
  },
  marginTop: {
    marginTop: 8,
  },
  actionButtonWithMargin: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
    marginTop: 8,
  },
});

const HomeScreen = () => {
  const trafficSheetRef = useRef<BottomSheetModal & BottomSheetMethods>(null);
  const permissionsSheetRef = useRef<BottomSheetModal & BottomSheetMethods>(
    null,
  );
  const trackWorkingDaySheetRef = useRef<BottomSheetModal & BottomSheetMethods>(
    null,
  );
  const stopWorkingDaySheetRef = useRef<BottomSheetModal & BottomSheetMethods>(
    null,
  );
  const finishWorkingDayRef = useRef<BottomSheetModal & BottomSheetMethods>(
    null,
  );
  const qrSheetRef = useRef<BottomSheetModal & BottomSheetMethods>(null);

  const [statusbarType, setStatusBarType] = useState<'light' | 'dark'>('light');

  const country = useTenantStore((state) => state.tenant?.address.country);
  const mobileAppFeature = useTenantStore(
    (state) => state.tenant?.mobileAppFeature,
  );
  const tenantAddress = useTenantStore(
    (state) => state.tenant?.address || state.internalTenant?.address,
  );
  const appUrl = useAppStore((state) => state.appUrl);
  const employeeId = useEmployeeStore((state) => state.employee?.employeeId);
  const personName = useEmployeeStore((state) => state.employee?.personName);
  const employeeSkills = useEmployeeStore(
    (state) => state.employee?.employeeSkills,
  );

  const today = useMemo(() => dayjs().startOf('day').utc(true).toDate(), []);

  const hasBackendRights = useHasBackendRight();
  const logout = useLogout();
  const [foregroundStatus, requestForegroundPermission] =
    Location.useForegroundPermissions();
  const [backgroundStatus, requestBackgroundPermission] =
    Location.useBackgroundPermissions();

  const {
    data: trafficData,
    refetch: refetchTraffic,
    isPending: isLoadingTraffic,
  } = useTrafficByCountryRequest(
    { country: country || 'nl' },
    { retry: false },
  );

  const {
    isLoading: isLoadingEvents,
    isFetching: isFetchingEvents,
    data: eventsForTodayData,
    refetch: refetchEventsForToday,
  } = useEventsByEmployeeIdRequest(
    {
      employeeId: employeeId || '',
      fromDate: today,
      untilDate: today,
    },
    {
      enabled: !!employeeId,
    },
  );

  const {
    isLoading: isLoadingRecurringEvents,
    isFetching: isFetchingRecurringEvents,
    data: recurringEventsForTodayData,
    refetch: refetchRecurringEventsForToday,
  } = useRecurringEventsByEmployeeIdRequest(
    {
      employeeId: employeeId || '',
      fromDate: today,
      untilDate: today,
    },
    {
      enabled: !!employeeId,
    },
  );

  const recurringEventsForToday = useMemo(
    () =>
      (recurringEventsForTodayData?.data.member || []).reduce(
        (acc: Event[], event: Event) => [
          ...acc,
          ...calculateRecurringEventsWithingPeriod(event, today, today),
        ],
        [],
      ),
    [recurringEventsForTodayData, today],
  );

  const projectIds = useMemo(
    () => [
      ...getProjectIdsFromEvents(eventsForTodayData),
      ...getProjectIdsFromEvents(recurringEventsForTodayData),
    ],
    [eventsForTodayData, recurringEventsForTodayData],
  );

  const {
    isLoading: isLoadingProjects,
    isFetching: isFetchingProjects,
    data: projectsData,
    refetch: refetchProjects,
  } = useProjectsByProjectIdsRequest({
    projectIds,
    perPage: projectIds.length,
  });

  const eventsForToday = useMemo(
    () => eventsForTodayData?.data?.member || [],
    [eventsForTodayData?.data],
  );

  const allEventsForToday = useMemo(
    () =>
      [...eventsForToday, ...recurringEventsForToday].sort((a, b) =>
        dayjs(a.travelStart || a.start).isBefore(
          dayjs(b.travelStart || b.start),
        )
          ? -1
          : 1,
      ),
    [eventsForToday, recurringEventsForToday],
  );

  const projects = useMemo(
    () => projectsData?.data?.member || [],
    [projectsData?.data],
  );

  const traffic = useMemo(() => trafficData?.data?.member || [], [trafficData]);

  const timeMarginBeforeEventInMinutes = useMemo(
    () => mobileAppFeature?.timeMarginBeforeEventInMinutes || 0,
    [mobileAppFeature],
  );

  const greetingName = useMemo(
    () => personName?.firstName,
    [personName?.firstName],
  );

  const { data: timeTypesData } = useTimeTypesRequest();

  const timeTypes = useMemo(
    () => timeTypesData?.data?.member || [],
    [timeTypesData?.data],
  );

  const {
    requestPermission,
    toggleTrackingLocationForTimeTracking,
    isTracking,
    currentLocation,
  } = useTrackLocationForTimeTracking({
    eventsToTrack: allEventsForToday,
    timeTypes,
  });

  const [weatherLocation, setWeatherLocation] = useState<{
    latitude: number;
    longitude: number;
  }>();

  useEffect(() => {
    if (isTracking && currentLocation && currentLocation.coords) {
      return;
    }

    const sortedEvents = allEventsForToday.sort((a, b) =>
      dayjs(a.start).isBefore(dayjs(b.start)) ? -1 : 1,
    );

    if (sortedEvents.length > 0) {
      let currentOrNextEvent = sortedEvents.find((event) =>
        dayjs(event.start).isAfter(dayjs()),
      );
      if (!currentOrNextEvent) {
        currentOrNextEvent = sortedEvents[sortedEvents.length - 1];
      }

      if (
        currentOrNextEvent.location &&
        currentOrNextEvent.location.latitude &&
        currentOrNextEvent.location.longitude
      ) {
        setWeatherLocation({
          latitude: currentOrNextEvent.location.latitude,
          longitude: currentOrNextEvent.location.longitude,
        });

        return;
      }
    }

    if (tenantAddress && tenantAddress.latitude && tenantAddress.longitude) {
      setWeatherLocation({
        latitude: tenantAddress.latitude,
        longitude: tenantAddress.longitude,
      });
    }
  }, [currentLocation, allEventsForToday, isTracking, tenantAddress]);

  useEffect(() => {
    if (!currentLocation || !currentLocation.coords) {
      return;
    }

    if (
      weatherLocation &&
      getDistance(weatherLocation, {
        latitude: currentLocation.coords.latitude,
        longitude: currentLocation.coords.longitude,
      }) < 10000
    ) {
      return;
    }

    setWeatherLocation({
      latitude: currentLocation.coords.latitude,
      longitude: currentLocation.coords.longitude,
    });
  }, [currentLocation, weatherLocation]);

  const { data: weatherData, isLoading } = useWeatherApiRequest(
    {
      language: 'nl',
      query: weatherLocation
        ? `${weatherLocation?.latitude},${weatherLocation?.longitude}`
        : '',
    },
    {
      enabled: !!weatherLocation?.latitude && !!weatherLocation?.longitude,
      placeholderData: keepPreviousData,
    },
  );

  const weather = useMemo(() => weatherData?.data, [weatherData]);

  const importantTrafficObstructions = useMemo(
    () =>
      traffic.filter(
        ({ obstructionType }) => obstructionType !== ObstructionType.ROAD_WORK,
      ),
    [traffic],
  );

  const handleShowAllTrafficObstructions = useCallback(() => {
    if (trafficSheetRef.current) {
      trafficSheetRef.current.present({ test: 'wauw' });
    }
  }, []);

  const handleStartScanning = useCallback(() => {
    if (qrSheetRef.current) {
      qrSheetRef.current.present({ test: 'wauw' });
    }
  }, []);

  const blockedLocationPermission = useMemo(() => {
    if (!backgroundStatus || !foregroundStatus) {
      return false;
    }

    if (
      backgroundStatus?.status === 'granted' ||
      foregroundStatus?.status === 'granted'
    ) {
      return false;
    }

    return !backgroundStatus?.canAskAgain || !foregroundStatus?.canAskAgain;
  }, [backgroundStatus, foregroundStatus]);

  const needsLocationPermission = useMemo(() => {
    if (blockedLocationPermission) {
      return false;
    }

    return (
      backgroundStatus?.status !== 'granted' ||
      foregroundStatus?.status !== 'granted'
    );
  }, [blockedLocationPermission, backgroundStatus, foregroundStatus]);

  const handleFinishWorkingDay = useCallback(async (values: unknown) => {
    console.log('handleFinishWorkingDay', values);
  }, []);

  const handleStartWorking = useCallback(async () => {
    await toggleTrackingLocationForTimeTracking();
    trackWorkingDaySheetRef.current?.close();
  }, [toggleTrackingLocationForTimeTracking]);

  const handleStopWorking = useCallback(async () => {
    await toggleTrackingLocationForTimeTracking();
    stopWorkingDaySheetRef.current?.close();
  }, [toggleTrackingLocationForTimeTracking]);

  const handleRequestPermissions = useCallback(async () => {
    if (foregroundStatus?.status !== 'granted') {
      const { granted: grantedForeground } =
        await requestForegroundPermission();

      if (!grantedForeground) {
        console.log('foreground permission not granted');
        return;
      }
    }

    if (backgroundStatus?.status !== 'granted') {
      const { granted: grantedBackground } =
        await requestBackgroundPermission();

      if (!grantedBackground) {
        console.log('background permission not granted');
        return;
      }
    }

    await requestPermission();

    permissionsSheetRef.current?.close();
  }, [
    backgroundStatus?.status,
    foregroundStatus?.status,
    requestBackgroundPermission,
    requestForegroundPermission,
    requestPermission,
  ]);

  const handleToggleTrackWorkingDay = useCallback(async () => {
    if (needsLocationPermission) {
      permissionsSheetRef.current?.present();
    } else if (isTracking) {
      stopWorkingDaySheetRef.current?.present({
        currentDate: new Date(),
      });
    } else {
      trackWorkingDaySheetRef.current?.present();
    }
  }, [isTracking, needsLocationPermission]);

  const handleNewTaxation = useCallback(() => {
    router.push(`/intake/${v7()}/moving-job`);
  }, []);

  const [blockOpeningEvent, setBlockOpeningEvent] = useState(false);
  const isCreatingNewEvent = useRef(false);
  const { mutateAsync: overrideRecurringEvent } =
    useOverrideRecurringEventMutation({
      onError: () => {
        setBlockOpeningEvent(false);
        isCreatingNewEvent.current = false;
      },
    });

  const onPressEvent = useCallback(
    async (event: {
      eventId: Uuid;
      recurringInformation?: {
        recurring: boolean;
      };
    }) => {
      if (event.recurringInformation?.recurring) {
        if (isCreatingNewEvent.current) {
          return;
        }

        isCreatingNewEvent.current = true;
        setBlockOpeningEvent(true);
        const newEventId = v7();
        await overrideRecurringEvent({
          eventId: event.eventId,
          newEventId,
          date: today,
        });

        isCreatingNewEvent.current = false;
        setBlockOpeningEvent(false);

        router.navigate(
          `/event/${newEventId}/?selectedDate=${formatDate(today)}`,
        );

        return;
      }

      router.navigate(
        `/event/${event.eventId}/?selectedDate=${formatDate(today)}`,
      );
    },
    [overrideRecurringEvent, today],
  );

  const isFetching = useMemo(
    () => isFetchingEvents || isFetchingProjects || isFetchingRecurringEvents,
    [isFetchingEvents, isFetchingProjects, isFetchingRecurringEvents],
  );

  const onRefresh = useCallback(() => {
    refetchEventsForToday();
    refetchRecurringEventsForToday();
    refetchTraffic();
    if (projectIds.length > 0) {
      refetchProjects();
    }
  }, [
    projectIds.length,
    refetchEventsForToday,
    refetchProjects,
    refetchTraffic,
    refetchRecurringEventsForToday,
  ]);

  const onScroll = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      if (event.nativeEvent.contentOffset.y > 175) {
        setStatusBarType('dark');
      } else {
        setStatusBarType('light');
      }
    },
    [],
  );

  const handleToBackoffice = useCallback(() => {
    if (Platform.OS === 'web') {
      window.location.href = '/';
    } else {
      let backofficeUrl = appUrl || '';
      if (!appUrl.includes('.bas.app') && !appUrl.includes('.bas.local')) {
        backofficeUrl = `${appUrl}.bas.app`;
      }

      Linking.openURL(`https://${backofficeUrl}`);
    }
  }, [appUrl]);

  const canUseIntake = useMemo(
    () =>
      employeeSkills &&
      !!employeeSkills.find((skill) => skill === MovingJobSkill.APPRAISER),
    [employeeSkills],
  );

  const isFocused = useIsFocused();

  const isWarehouseEmployee = useEmployeeStore(
    (state) => state.isWarehouseEmployee,
  );

  const actions = useMemo(() => {
    const result = [];
    if (Platform.OS !== 'web') {
      result.push(
        <MobileActionButton
          disabled={blockedLocationPermission}
          icon={faClock}
          onPress={handleToggleTrackWorkingDay}
        >
          <FormattedMessage
            id={
              isTracking
                ? 'mobileApp.stopWorkingDay'
                : 'mobileApp.startWorkingDay'
            }
          />
        </MobileActionButton>,
      );
    }
    if (isWarehouseEmployee()) {
      result.push(
        <MobileActionButton icon={faQrcode} onPress={handleStartScanning}>
          <FormattedMessage id="button.scanQrCodes" />
        </MobileActionButton>,
      );
    }
    if (canUseIntake) {
      result.push(
        <MobileActionButton
          icon={faMoneyCheckEditAlt}
          onPress={handleNewTaxation}
        >
          <FormattedMessage id="mobileApp.startIntakeWithoutProject" />
        </MobileActionButton>,
      );
    }

    return result;
  }, [
    blockedLocationPermission,
    canUseIntake,
    handleNewTaxation,
    handleStartScanning,
    handleToggleTrackWorkingDay,
    isTracking,
    isWarehouseEmployee,
  ]);

  return useMemo(
    () =>
      isFocused ? (
        <ScrollView
          style={styles.scrollContainer}
          onScroll={onScroll}
          scrollEventThrottle={16}
          refreshControl={
            <RefreshControl
              refreshing={isFetching}
              onRefresh={onRefresh}
              tintColor={colors.lila[800]}
              colors={[colors.lila[800]]}
            />
          }
        >
          <Box
            height={25}
            position="absolute"
            left={0}
            right={0}
            top={0}
            backgroundColor="lila.100"
          />
          <StatusBar style={statusbarType} />
          <Box style={styles.header}>
            <ImageBackground
              source={dayImage}
              resizeMode="stretch"
              style={styles.headerImage}
            >
              <SafeAreaView>
                <Box style={styles.headerTextContainer}>
                  <Skeleton
                    visible={!!greetingName}
                    style={styles.headerWelcome}
                    height={31}
                  >
                    <Typography
                      fontWeight={700}
                      lineHeight={31}
                      color="lightText"
                      variant="h4"
                    >
                      <FormattedMessage
                        id="mobileApp.greeting"
                        values={{
                          greetingName,
                        }}
                      />
                    </Typography>
                  </Skeleton>
                  <Typography
                    style={styles.headerGoodLuck}
                    fontWeight={400}
                    color="lightText"
                    variant="h5"
                  >
                    <FormattedMessage id="mobileApp.goodLuck" />
                  </Typography>
                  <Skeleton
                    visible={!isLoading}
                    style={styles.weatherTextContainer}
                    height={26}
                  >
                    {weather && (
                      <Box style={styles.weatherTextContent}>
                        <Typography style={styles.weatherText}>
                          {weather && weather?.current.condition?.text
                            ? `${weather?.current?.temp_c.toFixed()}° ${weather?.current.condition?.text}`
                            : ''}
                        </Typography>
                      </Box>
                    )}
                  </Skeleton>
                </Box>
              </SafeAreaView>
            </ImageBackground>
          </Box>
          <Box style={styles.container}>
            <Box style={styles.trafficContainer}>
              <MobileTrafficObstructions
                traffic={
                  importantTrafficObstructions.length > 0
                    ? importantTrafficObstructions
                    : traffic
                }
                numberOfExtraTraffic={importantTrafficObstructions.length}
                onShowAll={handleShowAllTrafficObstructions}
                isLoaded={!isLoadingTraffic}
              />
            </Box>
            <MobileActionButtonsGroup
              style={styles.actionsContainer}
              buttons={actions}
            />
            <OpenTaxationList />
            <Box style={styles.eventsContainer}>
              <Skeleton
                height={24}
                width={250}
                visible={!isLoadingEvents && !isLoadingRecurringEvents}
              >
                <TitleWithNumber
                  count={allEventsForToday.length}
                  title={<FormattedMessage id="mobileApp.eventsForToday" />}
                />
              </Skeleton>
              {isLoadingProjects ||
              isLoadingEvents ||
              isLoadingRecurringEvents ? (
                <Box style={styles.events}>
                  <LoadingEventListItem />
                </Box>
              ) : (
                <Box style={styles.events}>
                  {allEventsForToday.length === 0 && (
                    <Typography>
                      <FormattedMessage id="mobileApp.noEventsForToday" />
                    </Typography>
                  )}
                  {allEventsForToday.map((event) => (
                    <EventListItem
                      key={event.eventId}
                      disabled={blockOpeningEvent}
                      onPress={
                        hasEventPage(event.eventType)
                          ? () => onPressEvent(event)
                          : undefined
                      }
                      timeMarginBeforeEventInMinutes={
                        timeMarginBeforeEventInMinutes
                      }
                      planningEvent={event}
                      project={
                        isProjectEvent(event)
                          ? projects.find(
                              ({ projectId }) => projectId === event.projectId,
                            )
                          : undefined
                      }
                    />
                  ))}
                </Box>
              )}
            </Box>
            <Box style={styles.actionsLine} />
            <Box style={styles.actionButtons}>
              <Button onPress={logout} style={styles.actionButton}>
                <FormattedMessage id="label.logout" />
              </Button>
              {hasBackendRights && (
                <Button
                  onPress={handleToBackoffice}
                  style={styles.actionButtonWithMargin}
                >
                  <FormattedMessage id="button.toBackoffice" />
                </Button>
              )}
            </Box>
          </Box>
          <RequestWorkingDayPermissionsBottomSheet
            ref={permissionsSheetRef}
            name={greetingName || ''}
            onNotNow={() => permissionsSheetRef.current?.close()}
            onRequestPermissions={handleRequestPermissions}
          />
          <StartWorkingDayBottomSheet
            ref={trackWorkingDaySheetRef}
            onNotNow={() => trackWorkingDaySheetRef.current?.close()}
            onStart={handleStartWorking}
          />
          <StopWorkingDayBottomSheet
            onStop={handleStopWorking}
            ref={stopWorkingDaySheetRef}
          />
          <FinishWorkingDayFormWizardBottomSheet
            bottomSheetRef={finishWorkingDayRef}
            onSubmit={handleFinishWorkingDay}
            onStop={handleStopWorking}
          />
          <TrafficObstructionsBottomSheet ref={trafficSheetRef} />
          <QrScannerBottomSheet bottomSheetRef={qrSheetRef} />
        </ScrollView>
      ) : null,
    [
      isFocused,
      onScroll,
      isFetching,
      onRefresh,
      statusbarType,
      greetingName,
      isLoading,
      weather,
      importantTrafficObstructions,
      traffic,
      handleShowAllTrafficObstructions,
      isLoadingTraffic,
      actions,
      isLoadingEvents,
      isLoadingRecurringEvents,
      allEventsForToday,
      isLoadingProjects,
      logout,
      hasBackendRights,
      handleToBackoffice,
      handleRequestPermissions,
      handleStartWorking,
      handleStopWorking,
      handleFinishWorkingDay,
      blockOpeningEvent,
      timeMarginBeforeEventInMinutes,
      projects,
      onPressEvent,
    ],
  );
};

export default HomeScreen;
