import { colors } from '@bas/theme';
import { TimeSliderBox } from '@bas/ui/native/atoms';
import { Box, Typography } from '@bas/ui/native/base';
import { FlashList } from '@shopify/flash-list';
import dayjs from 'dayjs';
import {
  createContext,
  memo,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';
import isEqual from 'react-fast-compare';
import { useIntl } from 'react-intl';
import { StyleSheet, ViewProps } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';

export type TimeSliderFieldProps = ViewProps & {
  error?: boolean;
  disabled?: boolean;
  helperText?: ReactNode | string;
  onChange: (newValue: Date | number) => void;
  label?: ReactElement | string;
  value: Date | number;
  values: Date[] | number[];
  light?: boolean;
};

const calculateDateOfValue = (value: number | Date) => {
  if (value instanceof Date) {
    return value;
  }

  return dayjs()
    .set('hour', 0)
    .set('minute', 0)
    .add(value * 60, 'minutes')
    .toDate();
};

type FieldValueContext = {
  value: Date | number | undefined | null;
  length: number;
};

const TimeSliderFieldContext = createContext<FieldValueContext>({
  value: null,
  length: 0,
});

const TimeSliderBoxWithPress = memo(
  ({
    item,
    index,
    light,
    handleChange,
  }: {
    item: Date | number;
    index: number;
    light?: boolean;
    handleChange: (newValue: Date | number) => void;
  }) => {
    const handlePress = useCallback(
      () => handleChange(item),
      [handleChange, item],
    );

    const { value, length } = useContext(TimeSliderFieldContext);

    const { formatTime } = useIntl();
    const textValue = useMemo(
      () =>
        formatTime(calculateDateOfValue(item), {
          hour: 'numeric',
          minute: '2-digit',
        }),
      [formatTime, item],
    );

    let selected = false;
    if (value instanceof Date && item instanceof Date) {
      const valueDayjs = dayjs(value);
      const itemDayjs = dayjs(item);
      selected = valueDayjs.format('LT') === itemDayjs.format('LT');
    } else {
      selected = value === item;
    }

    return (
      <TimeSliderBox
        light={light}
        first={index === 0}
        last={index === length - 1}
        selected={selected}
        onPress={handlePress}
      >
        {textValue}
      </TimeSliderBox>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps),
);

const styles = StyleSheet.create({
  label: {
    textAlign: 'center',
    paddingBottom: 7,
  },
  helperText: {
    paddingLeft: 20,
    paddingRight: 20,
  },
});

const TimeSliderField = ({
  error,
  disabled,
  helperText,
  onChange,
  label,
  value,
  values,
  light,
  ...props
}: TimeSliderFieldProps): ReactElement => {
  const ref = useRef<FlashList<number | Date> | null>(null);

  const centerList = useCallback(
    (valueToCheck: Date | number, animate = true): void => {
      let index;
      if (values[0] instanceof Date && valueToCheck instanceof Date) {
        const valueDayjs = dayjs(valueToCheck);
        index = values.findIndex(
          (date) => valueDayjs.format('LT') === dayjs(date).format('LT'),
        );
      } else if (typeof valueToCheck === 'number') {
        index = values.findIndex(
          (number) => typeof number === 'number' && number === valueToCheck,
        );
      }

      if (!index && index !== 0) {
        index = Math.ceil(values.length / 2);
      }

      if (ref.current && index >= 0) {
        ref.current.scrollToIndex({
          animated: animate,
          index,
          viewPosition: 0.5,
        });
      }
    },
    [values],
  );

  const handleChange = useCallback(
    (newValue: Date | number) => {
      if (newValue instanceof Date) {
        const newDate = dayjs(newValue);
        onChange(
          dayjs(newValue)
            .set('hour', newDate.hour())
            .set('minute', newDate.minute())
            .set('second', newDate.second())
            .toDate(),
        );

        centerList(newDate.toDate());

        return;
      }

      onChange(newValue);
      centerList(newValue);
    },
    [centerList, onChange],
  );

  const renderItem = useCallback(
    ({ item, index }: { item: Date | number; index: number }) => (
      <TimeSliderBoxWithPress
        item={item}
        index={index}
        handleChange={handleChange}
        light={light}
      />
    ),
    [handleChange, light],
  );

  const getKey = useCallback((item: Date | number) => item.toString(), []);

  const context = useMemo(
    () => ({
      value,
      length: values.length,
    }),
    [value, values.length],
  );

  const flatList = useMemo(
    () => {
      let index;

      if (values[0] instanceof Date && value instanceof Date) {
        const valueDayjs = dayjs(value);
        index = values.findIndex(
          (date) => valueDayjs.format('LT') === dayjs(date).format('LT'),
        );
      } else if (typeof value === 'number') {
        index = values.findIndex(
          (number) => typeof number === 'number' && number === value,
        );
      }

      if (!index && index !== 0) {
        index = Math.ceil(values.length / 2);
      }

      return (
        <FlashList<Date | number>
          data={values}
          ref={ref}
          initialScrollIndex={index - 5}
          renderItem={renderItem}
          estimatedItemSize={85}
          keyExtractor={getKey}
          centerContent
          horizontal
          onLayout={() => centerList(value, false)}
          renderScrollComponent={ScrollView}
          contentContainerStyle={{
            // @ts-expect-error - workaround for flexBasis
            flexBasis: '100%',
          }}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getKey, renderItem, values],
  );

  return (
    <Box flexDirection="row" flexWrap="wrap" {...props}>
      <Box flexBasis="100%">
        <Typography
          variant="subtitle1"
          overrideColor={error ? colors.red[500] : colors.lila[600]}
          style={styles.label}
        >
          {label}
        </Typography>
      </Box>
      <Box flexBasis="100%">
        <TimeSliderFieldContext.Provider value={context}>
          {flatList}
        </TimeSliderFieldContext.Provider>
      </Box>
      <Box flexBasis="100%">
        {helperText && (
          <Typography
            variant="body2"
            overrideColor={error ? colors.red[500] : colors.lila[600]}
            style={styles.helperText}
          >
            {helperText}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const MemoComponent = memo(TimeSliderField, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps),
);

export default MemoComponent;
