import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type RemoveDocumentTemplateMutationProps = {
  documentTemplateId: Uuid;
};

export const RemoveDocumentTemplateMutation = async ({
  documentTemplateId,
  ...values
}: RemoveDocumentTemplateMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/document-templates/${documentTemplateId}/delete`, {
    documentTemplateId,
    ...values,
  });

export const useRemoveDocumentTemplateMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveDocumentTemplateMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  RemoveDocumentTemplateMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveDocumentTemplateMutationProps
  >({
    mutationFn: RemoveDocumentTemplateMutation,
    throwOnError: false,
    ...options,
  });
