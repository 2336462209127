import { ReactElement } from 'react';
import { Box, Typography } from '@bas/ui/native/base';
import {
  FormattedDate,
  FormattedDateTimeRange,
  FormattedMessage,
} from 'react-intl';
import { TimeEntry } from '@bas/hrm-domain/models';
import dayjs from 'dayjs';
import { formatHours } from '@bas/shared/utils';

export type TimeEntryApprovalInformationContentSectionProps = {
  timeEntry: TimeEntry;
};

const TimeEntryApprovalInformationContentSection = ({
  timeEntry,
}: TimeEntryApprovalInformationContentSectionProps): ReactElement => (
  <Box flexDirection="row" flexWrap="wrap">
    <Box flexBasis="100%">
      <Typography variant="subtitle1" color="white">
        <FormattedMessage id="label.approvalInformation" />
      </Typography>
    </Box>
    <Box flexBasis="50%">
      <Typography variant="subtitle2" color="lila.600">
        <FormattedMessage id="label.approvalInformation" />
      </Typography>
      {timeEntry.approvedByName && (
        <Typography color="white">{timeEntry.approvedByName}</Typography>
      )}
      {timeEntry.approvedOn && (
        <Typography color="white">
          <FormattedDate value={timeEntry.approvedOn} dateStyle="short" />
        </Typography>
      )}
      <Typography color="white">{timeEntry.approvalReaction}</Typography>
      <Typography color="white">
        <FormattedDateTimeRange
          from={dayjs(timeEntry.approvedStart).toDate()}
          to={dayjs(timeEntry.approvedEnd).toDate()}
          year="numeric"
          month="short"
          day="numeric"
          hour="numeric"
          minute="numeric"
        />
      </Typography>
      <Typography color="white">
        {formatHours(timeEntry.approvedHours || 0)}
      </Typography>
    </Box>
  </Box>
);

export default TimeEntryApprovalInformationContentSection;
