import type { DriversLicenceCharacteristics } from '../types';

export function isKeyOfDriversLicenceCharacteristics(
  key: string,
): key is keyof DriversLicenceCharacteristics {
  return [
    'bLicense',
    'beLicense',
    'bPlusLicense',
    'cLicense',
    'c1License',
    'c1eLicense',
    'ceLicense',
    'code95License',
  ].includes(key);
}
