import { isInvoiceEvent } from './InvoiceEvent';
import { InvoiceEventType, InvoiceWasNotPaidEvent } from '../types';

export function isInvoiceWasNotPaidEvent(
  object: unknown,
): object is InvoiceWasNotPaidEvent {
  return (
    isInvoiceEvent(object) &&
    object.eventType === InvoiceEventType.InvoiceWasNotPaidEvent
  );
}
