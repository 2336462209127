import {
  useAuthStore,
  useEmployeeStore,
  useTenantStore,
  useUserStore,
} from '@bas/shared/state';
import { formatDate } from '@bas/shared/utils';
import { PublicTenant } from '@bas/tenant-domain/models';
import { useEffect, useMemo } from 'react';
import { useIntercom } from 'react-use-intercom';

const mapTenantToCompany = (tenant: PublicTenant) => ({
  companyId: tenant.tenantId,
  name: tenant.name,
  createdAt: formatDate(tenant.createdAt),
});

const IntercomHandler = (): null => {
  const tenant = useTenantStore((state) => state.tenant);
  const authState = useAuthStore();
  const employeeState = useEmployeeStore((state) => state.employee);
  const user = useUserStore((state) => state.user);
  const { boot, shutdown } = useIntercom();
  const companies = useMemo(
    () => (tenant ? [mapTenantToCompany(tenant)] : []),
    // @TODO
    // const mappedTenants = (user?.accessToTenants || [])
    //   .filter(({ tenantId }) => tenantId !== tenant?.tenantId)
    //   .map(({ tenantId }) => mapTenantToCompany(t));
    //
    // return tenant
    //   ? [mapTenantToCompany(tenant), ...mappedTenants]
    //   : mappedTenants;
    [tenant],
  );

  useEffect(() => {
    if (employeeState && tenant && user) {
      for (let i = 0; i < localStorage.length; i += 1) {
        const key = localStorage.key(i);
        if (key && key.includes('intercom.intercom-state-')) {
          localStorage.removeItem(key);
        }
      }

      boot({
        horizontalPadding: 25,
        verticalPadding: 110,
        userId: user.userId,
        userHash: authState?.intercomHash,
        phone: employeeState.contactInformation?.phoneNumber || undefined,
        name: employeeState.personName?.fullName,
        email: employeeState.contactInformation?.emailAddress,
        createdAt: employeeState.createdAt
          ? formatDate(employeeState.createdAt)
          : undefined,
        languageOverride: employeeState.language,
        company: mapTenantToCompany(tenant),
        companies,
        hideDefaultLauncher: true,
        avatar: {
          type: 'avatar',
          imageUrl: employeeState.profilePictureUrl,
        },
      });
    }

    return () => {
      shutdown();
    };
  }, [
    authState?.intercomHash,
    boot,
    companies,
    employeeState,
    shutdown,
    tenant,
    user,
  ]);

  return null;
};

export default IntercomHandler;
