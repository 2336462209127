import { MovingJobFurniture, MovingJobRoom } from '@bas/project-domain/models';
import { colors } from '@bas/theme';
import { BottomSheetMethods, Typography } from '@bas/ui/native/base';
import {
  BottomSheet,
  BottomSheetProps,
  RoomsAndFurnitureList,
} from '@bas/ui/native/organisms';
import { InternalServiceType } from '@bas/value-objects';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { ReactElement, RefObject, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { View } from 'react-native';
import { ensuredForwardRef } from 'react-use/lib/useEnsuredForwardedRef';
import styles from './styles';

export type CheckServiceOfFurnitureListBottomSheetProps = Omit<
  BottomSheetProps,
  'snapPoints' | 'children'
> & {
  rooms: MovingJobRoom[];
  serviceType?: InternalServiceType;
  onCheckFurniture: (
    furniture: MovingJobFurniture,
    serviceType: InternalServiceType,
    newValue: boolean,
  ) => Promise<void> | void;
};

const CheckServiceOfFurnitureListBottomSheet = ensuredForwardRef<
  BottomSheetModal & BottomSheetMethods,
  CheckServiceOfFurnitureListBottomSheetProps
>(
  (
    {
      rooms,
      serviceType,
      onCheckFurniture,
      ...props
    }: CheckServiceOfFurnitureListBottomSheetProps,
    ref: RefObject<BottomSheetModal & BottomSheetMethods>,
  ): ReactElement => {
    const [totalItemsCount, finishedItemsCount] = useMemo(() => {
      let totalItemsLength = 0;
      let finishedItemsLength = 0;
      rooms.forEach(({ furniture }) => {
        totalItemsLength += furniture.filter((item) =>
          item.services.find(
            ({ internalType }) => internalType === serviceType,
          ),
        ).length;
        finishedItemsLength += furniture.filter((item) =>
          item.finishedServices.find(
            ({ internalType }) => internalType === serviceType,
          ),
        ).length;
      });

      return [totalItemsLength, finishedItemsLength];
    }, [rooms, serviceType]);

    const handleCheckFurniture = useCallback(
      (furniture: MovingJobFurniture, newValue: boolean) => {
        if (serviceType) {
          onCheckFurniture(furniture, serviceType, newValue);
        }
      },
      [onCheckFurniture, serviceType],
    );

    return (
      <BottomSheet ref={ref} {...props}>
        <View style={styles.flex}>
          <Typography
            overrideColor={colors.white}
            variant="h4"
            fontWeight={700}
            style={[styles.textCenter, styles.title]}
          >
            <FormattedMessage
              id={`mobileApp.event.disassemblyOrAssembly.${serviceType}.title`}
            />
          </Typography>
          <Typography
            overrideColor={colors.white}
            style={[styles.textCenter, styles.description]}
            variant="h5"
          >
            <FormattedMessage
              id="mobileApp.event.disassemblyOrAssembly.itemCount"
              values={{ totalItemsCount, finishedItemsCount }}
            />
          </Typography>
          <RoomsAndFurnitureList
            rooms={rooms}
            serviceType={serviceType}
            onCheckFurniture={handleCheckFurniture}
          />
        </View>
      </BottomSheet>
    );
  },
);

export default CheckServiceOfFurnitureListBottomSheet;
