import { Uuid } from '@bas/value-objects';
import * as Yup from 'yup';

export interface ConnectToProjectInputType {
  project?: {
    projectId: Uuid;
    customer: {
      relationId: Uuid;
    };
  };
}

export const ConnectToProjectInputTypeDefaultValues =
  (): ConnectToProjectInputType => ({});

export const ConnectToProjectInputTypeValidationBuilder =
  (): Yup.ObjectSchema<ConnectToProjectInputType> =>
    Yup.object({
      project: Yup.object({
        projectId: Yup.string().required().label('label.project'),
        customer: Yup.object({
          relationId: Yup.string().required().label('label.relation'),
        }),
      }),
    });
