import { InternalPaymentMethodType } from '@bas/financial-domain/models';
import { Money } from '@bas/value-objects';
import * as Yup from 'yup';

export type PayAtCustomerInputType = {
  paymentMethodType?: InternalPaymentMethodType;
  amount?: Money;
  paidAt?: Date;
  remarks?: string | null;
};

export const PayAtCustomerInputTypeDefaultValues =
  (): PayAtCustomerInputType => ({
    paymentMethodType: InternalPaymentMethodType.MANUAL,
  });

export const PayAtCustomerInputTypeValidationBuilder = (): Yup.ObjectSchema<
  Omit<PayAtCustomerInputType, 'relation' | 'address' | 'contactPerson'>
> =>
  Yup.object({
    paymentMethodType: Yup.mixed<InternalPaymentMethodType>()
      .oneOf(
        Object.values(InternalPaymentMethodType).filter((value) => !!value),
      )
      .required(),
    amount: Yup.object<Money>()
      .shape({
        amount: Yup.number().required().label('label.amount'),
        currency: Yup.string().required().label('label.currency'),
      })
      .when(['paymentMethodType'], ([paymentMethodType], schema) => {
        if (paymentMethodType === InternalPaymentMethodType.MANUAL) {
          return schema.required().shape({
            currency: Yup.string().nullable(),
          });
        }

        return schema
          .shape({
            amount: Yup.number().nullable(),
            currency: Yup.string().nullable(),
          })
          .nullable();
      }),
    paidAt: Yup.date()
      .optional()
      .when(['paymentMethodType'], ([paymentMethodType], schema) => {
        if (paymentMethodType === InternalPaymentMethodType.MANUAL) {
          return schema.required();
        }

        return schema;
      })
      .label('label.paidAt'),
    remarks: Yup.string().nullable().label('label.remarks'),
  });
