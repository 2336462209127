import { isIcon } from '@bas/svg-icons';
import { RectangularTile, RectangularTileProps } from '@bas/ui/native/atoms';
import {
  Box,
  Icon,
  IconProps,
  Typography,
  TypographyProps,
} from '@bas/ui/native/base';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons/faPlusCircle';
import { cloneElement, isValidElement, ReactElement } from 'react';
import styles from './styles';

export type RectangularAddTileProps = Omit<RectangularTileProps, 'children'> & {
  icon?:
    | IconProp
    | ReactElement<IconProps>
    | { prefix: string; iconName: string };
  label: string | ReactElement<TypographyProps>;
};

const color = 'darkText';

const RectangularAddTile = ({
  icon = faPlusCircle,
  label,
  ...props
}: RectangularAddTileProps): ReactElement => (
  <RectangularTile {...props} style={styles.container}>
    <Box
      flexDirection="row"
      alignItems="center"
      justifyContent="flex-start"
      flex={1}
    >
      {isValidElement(icon) &&
        cloneElement(icon as ReactElement<IconProps>, { color })}
      {isIcon(icon) && <Icon icon={icon} size={30} color={color} />}
      <Box style={styles.label} pl={2}>
        {isValidElement(label) ? (
          cloneElement(label as ReactElement<TypographyProps>, {
            color,
            fontWeight: 700,
            variant: 'h5',
          })
        ) : (
          <Typography fontWeight={700} color={color} variant="h5">
            {label}
          </Typography>
        )}
      </Box>
    </Box>
  </RectangularTile>
);

export default RectangularAddTile;
