import { Uuid } from '@bas/value-objects';
import * as Yup from 'yup';
import {
  StorageSpecificationsInputType,
  storageSpecificationsInputTypeDefaultValues,
  storageSpecificationsInputTypeValidationBuilder,
} from './storageSpecificationsInputType';

export type CreateStorageInputType = StorageSpecificationsInputType & {
  warehouseId: Uuid;
  storageLocationId?: Uuid | null;
};

export const createStorageInputTypeDefaultValues =
  (): CreateStorageInputType => ({
    ...storageSpecificationsInputTypeDefaultValues(),
    warehouseId: '',
    storageLocationId: '',
  });

export const createStorageInputTypeValidationBuilder =
  (): Yup.ObjectSchema<CreateStorageInputType> =>
    storageSpecificationsInputTypeValidationBuilder().concat(
      Yup.object({
        warehouseId: Yup.string().required().label('label.warehouse'),
        storageLocationId: Yup.string()
          .nullable()
          .label('label.storageLocation'),
      }),
    );
