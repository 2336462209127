import {
  isAudioMessageMetadata,
  isFileMessageMetadata,
  isImageMessageMetadata,
  isLocationMessageMetadata,
  isVideoMessageMetadata,
  Message,
} from '@bas/communication-domain/models';
import { GiftedChatIMessage } from '@bas/shared/models';
import { convertParticipantToUser } from './convertParticipantToUser';

export const convertMessageToChatMessage = (
  message: Message,
): GiftedChatIMessage => {
  const result: GiftedChatIMessage = {
    _id: message.messageId,
    text: message.content,
    audio: '',
    createdAt: message.createdAt,
    image: '',
    pending: false,
    quickReplies: undefined,
    received: false,
    sent: false,
    system: false,
    user: convertParticipantToUser(message.sender),
    video: '',
    location: undefined,
  };

  if (isFileMessageMetadata(message.metadata)) {
    result.file = message.metadata.attachmentUrl;
  }

  if (isImageMessageMetadata(message.metadata)) {
    result.image = message.metadata.attachmentUrl;
  }

  if (isVideoMessageMetadata(message.metadata)) {
    result.video = message.metadata.attachmentUrl;
  }

  if (isAudioMessageMetadata(message.metadata)) {
    result.audio = message.metadata.attachmentUrl;
  }

  if (isLocationMessageMetadata(message.metadata)) {
    result.location = {
      ...message.metadata,
    };
  }

  return result;
};
