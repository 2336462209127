import { StyleSheet } from 'react-native';
import { colors } from '@bas/theme';

export default StyleSheet.create({
  textCenter: {
    textAlign: 'center',
  },
  title: {
    paddingBottom: 16,
  },
  description: {
    paddingBottom: 16,
  },
  room: {
    backgroundColor: colors.lila[800],
  },
  separator: {
    paddingTop: 21,
  },
});
