import { colors } from '@bas/theme';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import localeData from 'dayjs/plugin/localeData';
import * as React from 'react';
import { ReactElement, useMemo } from 'react';
import { MarkedDays, Month } from 'react-native-month';

dayjs.extend(isBetween);
dayjs.extend(localeData);

export type MonthCalendarProps = {
  currentDate: Date | undefined;
  month: Date;
  onPressDay: (date: Date) => void;
  markedDays?: MarkedDays;
};

const MonthCalendar = ({
  currentDate,
  month,
  onPressDay,
  markedDays,
}: MonthCalendarProps): ReactElement => {
  const monthDayJs = useMemo(() => dayjs(month), [month]);

  return useMemo(
    () => (
      <Month
        month={monthDayJs.month()}
        year={monthDayJs.year()}
        minDate={monthDayJs.startOf('month').format('YYYY-MM-DD')}
        maxDate={monthDayJs.endOf('month').format('YYYY-MM-DD')}
        onPress={(date) => onPressDay(dayjs(date).toDate())}
        markedDays={markedDays}
        disableOffsetDays
        startDate={dayjs(currentDate).format('YYYY-MM-DD')}
        firstDayMonday={monthDayJs.localeData().firstDayOfWeek() === 1}
        dayNames={dayjs.weekdaysMin(true)}
        showWeekdays
        theme={{
          hiddenDayContainerStyle: {
            backgroundColor: 'transparent',
          },
          dayContentStyle: {
            width: 46,
            height: 46,
            borderRadius: 80,
            backgroundColor: 'transparent',
          },
          activeDayContentStyle: {
            backgroundColor: colors.blue[500],
          },
          dotContainerStyle: {
            bottom: 11,
          },
          dayContainerStyle: {
            padding: 0,
            margin: 0,
            backgroundColor: 'transparent',
            height: 46,
          },
          startDateContainerStyle: {
            borderBottomLeftRadius: 100,
            borderTopLeftRadius: 100,
          },
          endDateContainerStyle: {
            borderBottomRightRadius: 100,
            borderTopRightRadius: 100,
          },
          activeDayTextStyle: {
            color: colors.white,
          },
          activeDayContainerStyle: {
            backgroundColor: 'transparent',
            borderRadius: 0,
          },
          todayTextStyle: {
            color: colors.blue[500],
          },
          weekColumnStyle: {
            paddingBottom: 20,
          },
          weekColumnTextStyle: {
            fontFamily: 'DM_Sans_400_normal',
            fontSize: 15,
            color: colors.lila[600],
            textTransform: 'capitalize',
          },
          dayTextStyle: {
            fontFamily: 'DM_Sans_700_normal',
            fontSize: 20,
            color: colors.lila[800],
          },
          dayOutOfRangeContainerStyle: {
            backgroundColor: 'transparent',
            opacity: 0.3,
          },
          dayOutOfRangeTextStyle: {
            fontFamily: 'DM_Sans_700_normal',
            fontSize: 20,
            color: colors.lila[800],
          },
        }}
      />
    ),
    [monthDayJs, onPressDay, markedDays, currentDate],
  );
};

export default MonthCalendar;
