import { EmailAddress } from '@bas/value-objects';
import * as Yup from 'yup';

export type ProfileInputType = {
  firstName?: string | null;
  middleName?: string | null;
  lastName: string;
  emailAddress?: EmailAddress | null;
};

export const ProfileInputTypeDefaultValues = (): ProfileInputType => ({
  firstName: '',
  middleName: '',
  lastName: '',
  emailAddress: '',
});

export const ProfileInputTypeValidationBuilder =
  (): Yup.ObjectSchema<ProfileInputType> =>
    Yup.object({
      firstName: Yup.string().label('label.firstName').required(),
      middleName: Yup.string().label('label.middleName').nullable(),
      lastName: Yup.string().required().label('label.lastName'),
      emailAddress: Yup.string().email().label('label.emailAddress'),
    });
