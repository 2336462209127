import { QueryOptionsWithKey } from '@bas/shared/requests';
import { Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ProjectWorkOrderPdfByProjectIdRequestProps = {
  projectId: Uuid;
};

export const ProjectWorkOrderPdfByProjectIdRequest = async ({
  projectId,
  ...params
}: ProjectWorkOrderPdfByProjectIdRequestProps): Promise<AxiosResponse<Blob>> =>
  axios.get(`api/{tenantId}/projects/${projectId}/download-work-order`, {
    responseType: 'blob',
    params,
  });

export const useProjectWorkOrderPdfByProjectIdRequestQuery = (
  request: ProjectWorkOrderPdfByProjectIdRequestProps,
  options: QueryOptionsWithKey<
    AxiosResponse<Blob>,
    AxiosError,
    AxiosResponse<Blob>
  > = {},
): UseQueryResult<AxiosResponse<Blob>, AxiosError> =>
  useQuery<AxiosResponse<Blob>, AxiosError, AxiosResponse<Blob>>({
    ...options,
    queryFn: async () => ProjectWorkOrderPdfByProjectIdRequest(request),
    queryKey: ['projects', 'pdf', request.projectId],
    gcTime: 0,
    staleTime: 0,
  });
