import { AutocompleteAddressRequest } from '@bas/shared/requests';
import { Address, Country } from '@bas/value-objects';
import axios from 'axios';
import useDebounce from 'react-use/lib/useDebounce';

export type AutocompleteType = {
  country?: Country;
  zipCode?: string;
  houseNumber?: string;
  streetName?: string | null;
  houseNumberAddition?: string | null;
};

type ValidFunction = (valid: boolean) => void;
type AutocompleteFunction = (newAddress: Address) => void;

const handleAutocomplete = async (
  {
    country,
    zipCode,
    houseNumber,
    streetName,
    houseNumberAddition,
  }: AutocompleteType,
  onAutocomplete: AutocompleteFunction,
  setValid?: ValidFunction,
) => {
  if (!country || !['NL', 'BE'].includes(country)) {
    if (setValid) {
      setValid(false);
    }

    return;
  }

  if (!zipCode || !houseNumber || (country === 'BE' && !streetName)) {
    return;
  }

  try {
    const { data } = await AutocompleteAddressRequest({
      zipCode,
      country,
      streetName,
      houseNumber,
      houseNumberAddition,
    });
    if (
      data.zipCode &&
      data.zipCode.toLowerCase().replace(' ', '') ===
        zipCode.toLowerCase().replace(' ', '')
    ) {
      data.zipCode = zipCode;
      if (data.houseNumber === null) {
        data.houseNumber = houseNumber;
      }

      if (data.houseNumberAddition === null) {
        data.houseNumberAddition = houseNumberAddition;
      }
      onAutocomplete(data);
    }

    if (setValid) {
      setValid(!!data.latitude);
    }
  } catch (e) {
    if (axios.isCancel(e)) {
      return;
    }

    if (setValid) {
      setValid(false);
    }
  }
};

export const useAddressAutocomplete = (
  values: AutocompleteType,
  onAutocomplete: AutocompleteFunction,
  setValid?: ValidFunction,
): void => {
  useDebounce(
    () => {
      handleAutocomplete(values, onAutocomplete, setValid);
    },
    300,
    [values, onAutocomplete, setValid],
  );
};
