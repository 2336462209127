import {
  StartWorkingOnEventInputType,
  StartWorkingOnEventInputTypeDefaultValues,
  StartWorkingOnEventInputTypeValidationBuilder,
} from '@bas/planning-domain/input-types';
import { calculateTimeOfDaySliderValues } from '@bas/shared/utils';
import { colors } from '@bas/theme';
import { BottomSheetMethods, Box, Typography } from '@bas/ui/native/base';
import { ReactHookFormTimeSliderField } from '@bas/ui/native/molecules';
import { BackendErrors, BottomSheetProps } from '@bas/ui/native/organisms';
import { FormBottomSheet } from '@bas/ui/native/templates';
import { EventType, isTransportEvent } from '@bas/value-objects';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { ReactElement, RefObject, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { StyleSheet } from 'react-native';
import { ensuredForwardRef } from 'react-use/lib/useEnsuredForwardedRef';

export type StartWorkingOnEventBottomSheetProps = Omit<
  BottomSheetProps,
  'snapPoints' | 'children'
> & {
  eventType?: EventType;
  startDrivingTime?: Date | null;
  currentTime: Date;
  onSubmit: (values: StartWorkingOnEventInputType) => Promise<boolean>;
};

const styles = StyleSheet.create({
  flexContainer: {
    flex: 1,
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 15,
  },
  avatar: {
    height: '100%',
    width: '100%',
  },
  niceJob: {
    textAlign: 'center',
  },
  avatarContainer: {
    overflow: 'hidden',
    borderRadius: 269,
    height: 268,
    width: 268,
    marginBottom: 29,
    marginTop: 29,
    alignSelf: 'center',
  },
  header: {
    alignSelf: 'center',
    fontSize: 22,
    lineHeight: 29,
    height: 51,
  },
  padding: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 31,
  },
});

const initialValues = { ...StartWorkingOnEventInputTypeDefaultValues() };
const StartWorkingOnEventBottomSheet = ensuredForwardRef<
  BottomSheetModal & BottomSheetMethods,
  StartWorkingOnEventBottomSheetProps
>(
  (
    {
      onSubmit,
      eventType,
      startDrivingTime,
      currentTime,
      ...props
    }: StartWorkingOnEventBottomSheetProps,
    ref: RefObject<BottomSheetModal & BottomSheetMethods>,
  ): ReactElement => {
    const handleFinalize = useCallback(() => {
      ref?.current?.close();
      ref?.current?.forceClose();
      ref?.current?.dismiss();
    }, [ref]);

    const defaultValues = useMemo(() => {
      if (!eventType) {
        return initialValues;
      }

      return {
        ...initialValues,
        startTime: currentTime,
        startDrivingTime,
      };
    }, [currentTime, eventType, startDrivingTime]);

    const timeOfDayValues = useMemo(
      () => calculateTimeOfDaySliderValues(15, currentTime),
      [currentTime],
    );

    const content = useMemo(
      () => (
        <Box style={styles.flexContainer}>
          <Typography
            fontWeight={700}
            overrideColor={colors.white}
            style={styles.header}
            textAlign="center"
          >
            <FormattedMessage
              id={
                !!eventType && isTransportEvent(eventType)
                  ? 'label.arrivedAtLocation'
                  : 'label.startWorkingOnEvent'
              }
            />
          </Typography>
          <BackendErrors />
          <Box pb={3} pt={3}>
            <ReactHookFormTimeSliderField
              name="startTime"
              label={
                <FormattedMessage
                  id={
                    !!eventType && isTransportEvent(eventType)
                      ? 'label.arrivalTime'
                      : 'label.startTimeAtCustomer'
                  }
                />
              }
              light
              values={timeOfDayValues}
            />
          </Box>
        </Box>
      ),
      [eventType, timeOfDayValues],
    );

    return (
      <FormBottomSheet<StartWorkingOnEventInputType>
        bottomSheetRef={ref}
        disablePadding
        onSubmit={onSubmit}
        useProvider
        defaultValues={defaultValues}
        validationSchema={StartWorkingOnEventInputTypeValidationBuilder}
        resetFormOnOpen
        successFullFormViewProps={{
          gifUrl: 'https://media.giphy.com/media/26u4lOMA8JKSnL9Uk/giphy.gif',
          title: (
            <FormattedMessage
              id={
                !!eventType && isTransportEvent(eventType)
                  ? 'label.arrivedAtLocation'
                  : 'label.startWorkingOnEvent'
              }
            />
          ),
          color: colors.lila[100],
        }}
        onCancel={handleFinalize}
        {...props}
      >
        {content}
      </FormBottomSheet>
    );
  },
);

export default StartWorkingOnEventBottomSheet;
