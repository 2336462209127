import { AcceptanceQuote } from '@bas/financial-domain/models';
import { QueryOptionsWithKey } from '@bas/shared/requests';
import { Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type AcceptanceQuoteByQuoteIdRelationIdAndCodeRequestProps = {
  quoteId: Uuid;
  tenantId: Uuid;
  relationId: Uuid;
  confirmationCode: string;
};

export const AcceptanceQuoteByQuoteIdRelationIdAndCodeRequest = async ({
  quoteId,
  relationId,
  confirmationCode,
  tenantId,
  ...params
}: AcceptanceQuoteByQuoteIdRelationIdAndCodeRequestProps): Promise<
  AxiosResponse<AcceptanceQuote>
> =>
  axios.get(
    `api/public/${tenantId}/quotes/acceptance/${quoteId}/${relationId}/${confirmationCode}`,
    {
      params,
    },
  );

export const useAcceptanceQuoteByQuoteIdRelationIdAndCodeRequestQuery = (
  request: AcceptanceQuoteByQuoteIdRelationIdAndCodeRequestProps,
  options: QueryOptionsWithKey<
    AxiosResponse<AcceptanceQuote>,
    AxiosError,
    AxiosResponse<AcceptanceQuote>
  > = {},
): UseQueryResult<AxiosResponse<AcceptanceQuote>, AxiosError> =>
  useQuery<
    AxiosResponse<AcceptanceQuote>,
    AxiosError,
    AxiosResponse<AcceptanceQuote>
  >({
    ...options,
    queryFn: async () =>
      AcceptanceQuoteByQuoteIdRelationIdAndCodeRequest(request),
    queryKey: ['quotes', 'acceptance', request.quoteId],
  });
