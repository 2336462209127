import { Country, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type UpdateTenantAddressMutationProps = {
  tenantId: Uuid;
  streetName: string;
  houseNumber: string;
  houseNumberAddition?: string | null;
  zipCode: string;
  city: string;
  country: Country;
};

export const UpdateTenantAddressMutation = async ({
  ...values
}: UpdateTenantAddressMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/tenants/{tenantId}/update-address`, {
    ...values,
  });

export const useUpdateTenantAddressMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateTenantAddressMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  UpdateTenantAddressMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateTenantAddressMutationProps
  >({
    mutationFn: UpdateTenantAddressMutation,
    throwOnError: false,
    ...options,
  });
