import { TextFieldProps } from '@bas/ui/native/base';
import {
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import isEqual from 'react-fast-compare';
import {
  FieldPath,
  FieldValues,
  useController,
  UseFormSetFocus,
} from 'react-hook-form';
import { TextInput } from 'react-native';
import { TextInputFocusEventData } from 'react-native/Libraries/Components/TextInput/TextInput';
import { NativeSyntheticEvent } from 'react-native/Libraries/Types/CoreEventTypes';
import { DateTimeField } from '../DateTimeField';

export type ReactHookFormDatePickerFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<TextFieldProps, 'name' | 'value' | 'error'> & {
  name: TName;
  updateOnBlur?: boolean;
  disableError?: boolean;
  loading?: boolean;
  setFocus?: UseFormSetFocus<TFieldValues>;
  nextField?: FieldPath<TFieldValues>;
  onSubmit?: () => void | Promise<void>;
  onFocus?: () => void;
  onBlur?: () => void;
  variant?: 'outlined' | 'standard' | 'filled';
  mode?: 'date' | 'time';
};

const ControlledReactHookFormDatePickerField = memo(
  ({
    disabled,
    disableError,
    loading,
    setFocus,
    nextField,
    onSubmit,
    onFocusProp,
    onBlurProp,
    value,
    onBlur,
    onChange,
    fieldRef,
    error,
    invalid,
    isSubmitting,
    mode = 'date',
    updateOnBlur = false,
    ...props
  }: Omit<ReactHookFormDatePickerFieldProps, 'name'> & {
    onFocusProp?:
      | ((e: NativeSyntheticEvent<TextInputFocusEventData>) => void)
      | undefined;
    onBlurProp?: () => void;
    value: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any;
    invalid: boolean;
    isSubmitting: boolean;
    fieldRef: (ref: TextInput) => void;
    onChange: (value: Date) => void;
  }) => {
    const fieldError = error?.message;
    const showError = !disableError && invalid;
    const inputRef = useRef<TextInput | null>(null);

    const textFieldArgs = useMemo(
      () => ({
        validationMessage: showError ? fieldError : undefined,
        enableErrors: true,
        error: showError ? !!fieldError : undefined,
        disabled: disabled ?? isSubmitting,
      }),
      [showError, fieldError, disabled, isSubmitting],
    );

    const selectNextFieldOrSubmit = useCallback(() => {
      if (nextField) {
        setFocus?.(nextField, { shouldSelect: false });
      } else if (onSubmit) {
        onSubmit();
      }
    }, [nextField, onSubmit, setFocus]);

    useEffect(() => {
      if (inputRef.current) {
        fieldRef(inputRef.current);
      }
    }, [fieldRef, inputRef]);

    const handleBlur = useCallback(
      () => () => {
        onBlurProp?.();
        onBlur?.();
      },
      [onBlur, onBlurProp],
    );

    const handleChange = useCallback(
      (newDate: Date) => {
        onChange(newDate);
      },
      [onChange],
    );

    const dateValue = useMemo(() => {
      if (value) {
        return new Date(value);
      }
      return undefined;
    }, [value]);

    return (
      <DateTimeField
        onChange={handleChange}
        {...textFieldArgs}
        {...props}
        updateOnBlur={updateOnBlur}
        value={dateValue}
        onSubmitEditing={selectNextFieldOrSubmit}
        blurOnSubmit={false}
        onBlur={handleBlur}
        onFocus={onFocusProp}
        inputRef={inputRef}
        mode={mode}
      />
    );
  },
  ({ fieldRef: _field, ...prevProps }, { fieldRef, ...nextProps }) =>
    isEqual(prevProps, nextProps),
);

const ReactHookFormDatePickerField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  onFocus: onFocusProp,
  onBlur: onBlurProp,
  ...props
}: ReactHookFormDatePickerFieldProps<TFieldValues, TName>): ReactElement => {
  const {
    field: { value, onBlur, onChange, ref },
    fieldState: { error, invalid },
    formState: { isSubmitting },
  } = useController<TFieldValues, TName>({
    name,
  });

  return (
    <ControlledReactHookFormDatePickerField
      {...props}
      onFocusProp={onFocusProp}
      onBlurProp={onBlurProp}
      value={value}
      error={error}
      invalid={invalid}
      isSubmitting={isSubmitting}
      onBlur={onBlur}
      onChange={onChange}
      fieldRef={ref}
    />
  );
};

export default ReactHookFormDatePickerField;
