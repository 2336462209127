import { getWeekDayString } from '@bas/shared/utils';
import dayjs from 'dayjs';
import * as Yup from 'yup';

export interface AddBulkOccasionalAvailabilityInputType {
  availabilities: {
    startDate?: Date | null;
    endDate?: Date | null;
  }[];
}

export const AddBulkOccasionalAvailabilityInputTypeDefaultValues =
  (): AddBulkOccasionalAvailabilityInputType => ({
    availabilities: [
      {
        startDate: null,
        endDate: null,
      },
    ],
  });

export const AddBulkOccasionalAvailabilityInputTypeValidationBuilder =
  (alreadyWorkingHours: {
    week1: {
      monday?: number;

      tuesday?: number;

      wednesday?: number;

      thursday?: number;

      friday?: number;

      saturday?: number;

      sunday?: number;
    };
    week2: {
      monday?: number;

      tuesday?: number;

      wednesday?: number;

      thursday?: number;

      friday?: number;

      saturday?: number;

      sunday?: number;
    };
  }): Yup.ObjectSchema<AddBulkOccasionalAvailabilityInputType> =>
    Yup.object({
      availabilities: Yup.array()
        .of(
          Yup.object({
            startDate: Yup.date()
              .required()
              .nullable()
              .label('label.startTime'),
            endDate: Yup.date()
              .required()
              .nullable()
              .when(['startDate'], ([startDate], schema) => {
                if (!startDate || !dayjs(startDate).isValid()) return schema;
                return schema
                  .min(dayjs(startDate).clone().add(1, 'second').toDate())
                  .max(dayjs(startDate).clone().endOf('day').toDate())
                  .required();
              })
              // eslint-disable-next-line no-empty-pattern
              .when([], ([], schema, { parent }) => {
                const { startDate } = parent;
                const { endDate } = parent;

                if (!startDate || !endDate) {
                  return schema;
                }

                const weekToUse =
                  dayjs(startDate).isoWeek() % 2
                    ? alreadyWorkingHours.week2
                    : alreadyWorkingHours.week1;

                if (!weekToUse) {
                  return schema;
                }

                const weekDayString = getWeekDayString(startDate);
                const workingHours = weekToUse[weekDayString];
                if (workingHours === undefined) {
                  return schema;
                }

                if (
                  workingHours >
                  dayjs(endDate).diff(dayjs(startDate), 'hours', true)
                ) {
                  return schema.min(
                    dayjs(startDate).clone().add(workingHours, 'hour').toDate(),
                    `De werktijd mag niet minder zijn dan de al planbare werktijd van ${workingHours} uur`,
                  );
                }

                return schema;
              })
              .label('label.endTime'),
          }),
        )
        .required(),
    });
