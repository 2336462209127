import { isMovingJobAddress } from '@bas/project-domain/models';
import { AddressBlock } from '@bas/ui/native/atoms';
import { Box, Icon, Typography } from '@bas/ui/native/base';
import { Address } from '@bas/value-objects';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons/faTrashCan';
import { faElevator } from '@fortawesome/pro-solid-svg-icons/faElevator';
import { faHouseChimneyWindow } from '@fortawesome/pro-solid-svg-icons/faHouseChimneyWindow';
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons/faLocationDot';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { TouchableNativeFeedback } from 'react-native';
import styles from './styles';

export type ConfirmAddressBlockProps = {
  address: Address;
  removeAddress?: () => void;
};

const ConfirmAddressBlock = ({
  address,
  removeAddress,
}: ConfirmAddressBlockProps): ReactElement => (
  <Box style={styles.container} backgroundColor="card" borderColor="cardBorder">
    <Box
      style={styles.address}
      flexDirection="row"
      alignItems="center"
      paddingLeft={3}
      paddingTop={2}
      paddingBottom={2}
    >
      <Box style={styles.addressIcon}>
        <Icon icon={faLocationDot} color="secondaryTitle" size={24} />
      </Box>
      {isMovingJobAddress(address) && address.unknownAddress && (
        <Typography variant="h5">
          <FormattedMessage id="label.unknownAddress" />
        </Typography>
      )}
      <AddressBlock address={address} textVariant="h5" />
      {removeAddress && (
        <TouchableNativeFeedback onPress={removeAddress}>
          <Box style={{ marginLeft: 'auto' }} minWidth={36}>
            <Icon icon={faTrashCan} size={20} color="secondaryTitle" />
          </Box>
        </TouchableNativeFeedback>
      )}
    </Box>
    {isMovingJobAddress(address) && address.buildingType && (
      <Box
        flexDirection="row"
        alignItems="center"
        paddingLeft={3}
        paddingTop={2}
        paddingBottom={2}
        borderColor="cardBorder"
        borderTopWidth={1}
      >
        <Box style={styles.addressIcon}>
          <Icon icon={faHouseChimneyWindow} color="secondaryTitle" size={20} />
        </Box>

        <Box flexDirection="column">
          <Typography variant="h5">
            <FormattedMessage id={`buildingTypes.${address.buildingType}`} />
          </Typography>
          {address.buildingType &&
            address.floor !== null &&
            typeof address.floor !== 'undefined' &&
            parseFloat(address.floor.toString()) >= 0 && (
              <Typography variant="h5">
                <FormattedMessage
                  id="label.floorWithValue"
                  values={{ floor: address.floor }}
                />
              </Typography>
            )}
        </Box>
      </Box>
    )}
    {isMovingJobAddress(address) &&
      !!address.buildingType &&
      (!!address.distanceFromCar || !!address.distanceToApartment) && (
        <Box
          flexDirection="row"
          alignItems="center"
          paddingLeft={3}
          paddingTop={2}
          paddingBottom={2}
          borderColor="cardBorder"
          borderTopWidth={1}
        >
          <Box style={styles.addressIcon}>
            <Icon icon={faElevator} color="secondaryTitle" size={20} />
          </Box>

          <Box flexDirection="column">
            {address.distanceFromCar && (
              <Typography variant="h5">
                <FormattedMessage
                  id="movingJob.distanceFromCarWithValue"
                  values={{ distance: address.distanceFromCar }}
                />
              </Typography>
            )}
            {address.distanceToApartment && (
              <Typography variant="h5">
                <FormattedMessage
                  id="movingJob.distanceToApartmentWithValue"
                  values={{ distance: address.distanceToApartment }}
                />
              </Typography>
            )}
          </Box>
        </Box>
      )}
  </Box>
);

export default ConfirmAddressBlock;
