import { CustomerDetailsFormStepInputType } from '@bas/taxation-tool-domain/input-types';
import { MobileTaxationStepTemplate } from '@bas/taxation-tool-domain/native/templates';
import { RenderPersonName } from '@bas/ui/native/atoms';
import { Box, Icon, Typography } from '@bas/ui/native/base';
import { faAt } from '@fortawesome/pro-light-svg-icons/faAt';
import { faMobile } from '@fortawesome/pro-light-svg-icons/faMobile';
import { faPhone } from '@fortawesome/pro-light-svg-icons/faPhone';
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser';
import { memo, ReactElement } from 'react';
import { useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

const ConfirmCustomerDetailsStep = (): ReactElement => {
  const customer = useWatch<CustomerDetailsFormStepInputType, 'customer'>({
    name: 'customer',
  });

  return (
    <MobileTaxationStepTemplate
      primary={<FormattedMessage id="mobileApp.intake.movingJob.confirmInfo" />}
      secondary={<FormattedMessage id="label.customer" />}
    >
      <Box
        backgroundColor="card"
        borderColor="cardBorder"
        borderRadius={5}
        borderWidth={1}
      >
        <Box
          flexDirection="row"
          alignItems="center"
          paddingLeft={3}
          paddingTop={2}
          paddingBottom={2}
        >
          <Box minWidth={34}>
            <Icon icon={faUser} color="secondaryTitle" size={24} />
          </Box>
          <Typography variant="h5">
            <RenderPersonName variant="h5" {...customer} />
          </Typography>
        </Box>
        {customer.phoneNumber && (
          <Box
            flexDirection="row"
            alignItems="center"
            paddingLeft={3}
            paddingTop={2}
            paddingBottom={2}
            borderColor="cardBorder"
            borderTopWidth={1}
          >
            <Box minWidth={34}>
              <Icon icon={faPhone} color="secondaryTitle" size={24} />
            </Box>
            <Typography variant="h5">{customer.phoneNumber}</Typography>
          </Box>
        )}
        {customer.mobileNumber && (
          <Box
            flexDirection="row"
            alignItems="center"
            paddingLeft={3}
            paddingTop={2}
            paddingBottom={2}
            borderColor="cardBorder"
            borderTopWidth={1}
          >
            <Box minWidth={34}>
              <Icon icon={faMobile} color="secondaryTitle" size={24} />
            </Box>
            <Typography variant="h5">{customer.mobileNumber}</Typography>
          </Box>
        )}
        <Box
          flexDirection="row"
          alignItems="center"
          paddingLeft={3}
          paddingTop={2}
          paddingBottom={2}
          borderColor="cardBorder"
          borderTopWidth={1}
        >
          <Box minWidth={34}>
            <Icon icon={faAt} color="secondaryTitle" size={24} />
          </Box>
          <Typography variant="h5">{customer.emailAddress}</Typography>
        </Box>
      </Box>
    </MobileTaxationStepTemplate>
  );
};

const MemoComponent = memo(ConfirmCustomerDetailsStep, () => true);
export default MemoComponent;
