import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type CancelScheduledInvoiceMutationProps = {
  invoiceId: Uuid;
};

export const CancelScheduledInvoiceMutation = async ({
  invoiceId,
  ...values
}: CancelScheduledInvoiceMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/invoices/${invoiceId}/cancel-scheduled`, {
    invoiceId,
    ...values,
  });

export const useCancelScheduledInvoiceMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    CancelScheduledInvoiceMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  CancelScheduledInvoiceMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    CancelScheduledInvoiceMutationProps
  >({
    mutationFn: CancelScheduledInvoiceMutation,
    throwOnError: false,
    ...options,
  });
