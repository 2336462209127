import { AddressInputType } from '@bas/shared/input-types';
import { formatDate } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ChangeLocationOfEventMutationProps = {
  eventId: Uuid;
  location: AddressInputType;
  departureLocation: AddressInputType;
  date?: Date;
  updateFutureEvents: boolean;
};

export const ChangeLocationOfEventMutation = async ({
  eventId,
  date,
  ...values
}: ChangeLocationOfEventMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/events/${eventId}/change-location`, {
    eventId,
    date: date ? formatDate(date) : undefined,
    ...values,
  });

export const useChangeLocationOfEventMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeLocationOfEventMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ChangeLocationOfEventMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeLocationOfEventMutationProps
  >({
    mutationFn: ChangeLocationOfEventMutation,
    throwOnError: false,
    ...options,
  });
