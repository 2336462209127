import type { TextMessageMetadata } from '../../types';
import { MessageType } from '../../types';
import { isMessageMetadata } from './MessageMetadata';

export function isTextMessageMetadata(
  object: unknown,
): object is TextMessageMetadata {
  return (
    isMessageMetadata(object) && object.messageType === MessageType.TEXT_MESSAGE
  );
}
