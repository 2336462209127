import { ErrorResponse, Uuid } from '@bas/value-objects';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type SetMetadataOfEmailMutationProps = {
  emailId: Uuid;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metadata: Record<string, any>;
};

export const SetMetadataOfEmailMutation = async ({
  emailId,
  ...params
}: SetMetadataOfEmailMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/communication/emails/${emailId}/set-metadata`, {
    emailId,
    ...params,
  });

export const useSetMetadataOfEmailMutation = (): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  SetMetadataOfEmailMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    SetMetadataOfEmailMutationProps
  >({
    mutationFn: SetMetadataOfEmailMutation,
    throwOnError: false,
  });
