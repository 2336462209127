import { Box, Typography } from '@bas/ui/native/base';
import dayjs from 'dayjs';
import React, { ComponentType, useEffect, useRef, useState } from 'react';
import { TouchableOpacity, useWindowDimensions } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';

const HOUR_HEIGHT = 155;
const TIME_LABEL_WIDTH = 116;

const formatHour = (hour: number) =>
  dayjs().hour(hour).minute(0).format('HH:mm');

export type CalendarDayViewEvent = {
  id: string | number;
  startDate: Date;
  endDate: Date;
  color?: string;
};

type CalendarDayViewProps<
  TEvent extends CalendarDayViewEvent = CalendarDayViewEvent,
> = {
  events: TEvent[];
  onEventPress: (event: TEvent) => void;
  currentDate: Date;
  EventComponent: ComponentType<{
    event: TEvent;
  }>;
  unavailableHours: {
    start: number;
    end: number;
  }[];
};

// Helper function to check if two events overlap
const doEventsOverlap = (
  event1: CalendarDayViewEvent,
  event2: CalendarDayViewEvent,
) => {
  const start1 = dayjs(event1.startDate);
  const end1 = dayjs(event1.endDate);
  const start2 = dayjs(event2.startDate);
  const end2 = dayjs(event2.endDate);

  return start1.isBefore(end2) && end1.isAfter(start2);
};

// Group overlapping events together
const groupOverlappingEvents = <TEvent extends CalendarDayViewEvent>(
  events: TEvent[],
): TEvent[][] => {
  const sortedEvents = [...events].sort(
    (a, b) => dayjs(a.startDate).valueOf() - dayjs(b.startDate).valueOf(),
  );

  const groups: TEvent[][] = [];
  let currentGroup: TEvent[] = [];

  sortedEvents.forEach((event) => {
    if (
      currentGroup.length === 0 ||
      currentGroup.some((groupEvent) => doEventsOverlap(groupEvent, event))
    ) {
      currentGroup.push(event);
    } else {
      if (currentGroup.length > 0) {
        groups.push([...currentGroup]);
      }
      currentGroup = [event];
    }
  });

  if (currentGroup.length > 0) {
    groups.push(currentGroup);
  }

  return groups;
};

const CalendarDayView = <
  TEvent extends CalendarDayViewEvent = CalendarDayViewEvent,
>({
  events = [],
  unavailableHours = [],
  EventComponent,
  currentDate,
  onEventPress,
}: CalendarDayViewProps<TEvent>) => {
  const [currentTime, setCurrentTime] = useState(dayjs());
  const scrollViewRef = useRef<ScrollView>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(dayjs());
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const currentHour = currentTime.hour();
  const currentMinute = currentTime.minute();
  const currentTimePosition = (currentHour + currentMinute / 60) * HOUR_HEIGHT;
  const { width: screenWidth } = useWindowDimensions();
  const availableWidth = screenWidth - TIME_LABEL_WIDTH - 8;

  const hours = Array.from({ length: 24 }, (_, index) => index);

  useEffect(() => {
    const now = dayjs();
    const scrollOffset = dayjs(currentDate).isSame(now, 'day')
      ? (now.clone().subtract(1, 'hour').hour() + now.minute() / 60) *
        HOUR_HEIGHT
      : 8 * HOUR_HEIGHT;

    if (scrollViewRef.current) {
      setTimeout(
        () =>
          scrollViewRef.current?.scrollTo({ y: scrollOffset, animated: true }),
        100,
      );
    }
  }, [currentDate, scrollViewRef]);

  // Group overlapping events
  const eventGroups = groupOverlappingEvents(events);

  return (
    <ScrollView style={{ flex: 1 }} ref={scrollViewRef}>
      <Box height={HOUR_HEIGHT * 24} position="relative">
        {hours.map((hour) => (
          <Box
            key={`line-${hour}`}
            position="absolute"
            top={hour * HOUR_HEIGHT}
            left={0}
            right={0}
            borderBottomWidth={1}
            borderColor="calendarLine"
          />
        ))}
        <Box flexDirection="row" position="relative">
          {/* LEFT COLUMN: Time Labels */}
          <Box width={TIME_LABEL_WIDTH}>
            {hours.map((hour) => (
              <Box
                key={`time-${hour}`}
                height={HOUR_HEIGHT}
                justifyContent="flex-start"
                alignItems="flex-start"
                paddingTop="calendarTimeTop"
                paddingLeft="calendarTimeLeft"
              >
                <Typography>{formatHour(hour)}</Typography>
              </Box>
            ))}
          </Box>
          {/* RIGHT COLUMN: Events */}
          <Box style={{ flex: 1, position: 'relative' }}>
            {unavailableHours.map(({ start, end }) => {
              const startTime = dayjs().hour(start).minute(0);
              const endTime = dayjs().hour(end).minute(0);
              const unavailableTop = startTime.hour() * HOUR_HEIGHT;
              const unavailableHeight =
                (endTime.diff(startTime, 'minute') / 60) * HOUR_HEIGHT;

              return (
                <Box
                  key={`unavailable-${start}-${end}`}
                  position="absolute"
                  top={unavailableTop}
                  left={0}
                  right={0}
                  height={unavailableHeight}
                  style={{ backgroundColor: 'hsla(0,0%,84%,.3)' }}
                />
              );
            })}

            {eventGroups.map((group, groupIndex) =>
              group.map((event, eventIndex) => {
                const start = dayjs(event.startDate);
                const end = dayjs(event.endDate);
                const eventTop =
                  (start.hour() + start.minute() / 60) * HOUR_HEIGHT;
                const eventDurationInHours = end.diff(start, 'minute') / 60;
                const eventHeight = eventDurationInHours * HOUR_HEIGHT;

                const EVENT_WIDTH = 200;
                const PADDING = 24;

                // Check if we have enough space to fully separate events with padding
                const totalWidthNeeded =
                  EVENT_WIDTH * group.length + PADDING * (group.length - 1);

                let leftPosition;
                if (totalWidthNeeded <= availableWidth) {
                  // Enough space to fully separate events
                  leftPosition = 4 + eventIndex * (EVENT_WIDTH + PADDING);
                } else {
                  // Not enough space, use proportional spacing with minimum overlap
                  const spacing = Math.max(
                    20,
                    (availableWidth - EVENT_WIDTH) /
                      Math.max(group.length - 1, 1),
                  );
                  leftPosition = 4 + eventIndex * spacing;
                }

                return (
                  <TouchableOpacity
                    key={event.id}
                    onPress={() => onEventPress && onEventPress(event)}
                    style={{
                      position: 'absolute',
                      top: eventTop,
                      left: leftPosition,
                      height: eventHeight,
                      width: 200,
                      borderColor: 'white',
                      borderRadius: 8,
                      borderWidth: 2,
                      padding: 0,
                      zIndex: 999 - groupIndex,
                    }}
                  >
                    <EventComponent event={event} />
                  </TouchableOpacity>
                );
              }),
            )}
          </Box>
        </Box>

        {/* Current time line */}
        <Box
          position="absolute"
          top={currentTimePosition}
          left={0}
          right={0}
          height={2}
          backgroundColor="orange.700"
        />
        <Box
          position="absolute"
          top={currentTimePosition - 15.5}
          left={49}
          width={50}
          height={31}
          borderRadius={5}
          backgroundColor="orange.700"
          justifyContent="center"
          alignItems="center"
          zIndex={10}
        >
          <Typography color="white">{currentTime.format('HH:mm')}</Typography>
        </Box>
      </Box>
    </ScrollView>
  );
};

export default CalendarDayView;
