import { News } from '@bas/tenant-domain/models';
import { colors } from '@bas/theme';
import {
  TouchableWithoutFeedback,
  TouchableWithoutFeedbackProps,
  Typography,
} from '@bas/ui/native/base';
import MarkdownIt from 'markdown-it';
import { ReactElement, useMemo, useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { StyleSheet, View } from 'react-native';

export type NewsListItemProps = TouchableWithoutFeedbackProps & {
  newsItem: News;
  isRead?: boolean;
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    paddingTop: 14,
    paddingBottom: 14,
    paddingLeft: 24,
    paddingRight: 24,
  },
  title: {
    marginTop: 4,
    marginBottom: 4,
  },
  preview: {
    height: 66,
  },
});

const NewsListItem = ({
  newsItem,
  isRead,
  ...props
}: NewsListItemProps): ReactElement => {
  const [isPressing, setIsPressing] = useState<boolean>(false);
  const preview = useMemo(
    () =>
      MarkdownIt({
        breaks: false,
        html: false,
        linkify: false,
        typographer: false,
        xhtmlOut: false,
      })
        .renderInline(newsItem.preview)
        .replace(/(\r\n|\n|\r)/gm, ' ')
        .trim(),
    [newsItem.preview],
  );

  return (
    <TouchableWithoutFeedback
      onPressIn={() => setIsPressing(true)}
      onPressOut={() => setIsPressing(false)}
      {...props}
    >
      <View style={styles.container}>
        <Typography
          variant="body2"
          overrideColor={isPressing ? colors.blue[500] : colors.lila[600]}
        >
          {newsItem.publisherName === 'Unknown' ||
          newsItem.publisherName === undefined ? (
            <FormattedMessage id="label.unknown" />
          ) : (
            newsItem.publisherName
          )}
          {' - '}
          <FormattedDate
            value={newsItem.publishedAt}
            month="long"
            day="2-digit"
          />
        </Typography>
        <Typography
          variant="h5"
          fontWeight={700}
          overrideColor={isRead && !isPressing ? undefined : colors.blue[500]}
          style={styles.title}
        >
          {newsItem.title}
        </Typography>
        <Typography
          style={styles.preview}
          ellipsizeMode="tail"
          numberOfLines={3}
          overrideColor={isPressing ? colors.blue[500] : undefined}
        >
          {preview}
        </Typography>
      </View>
    </TouchableWithoutFeedback>
  );
};

export default NewsListItem;
