import { getCountryOptions } from '@bas/shared/utils';
import { Box } from '@bas/ui/native/base';
import {
  ReactHookFormDropdown,
  ReactHookFormTextField,
} from '@bas/ui/native/molecules';
import { ReactElement } from 'react';
import { useIntl } from 'react-intl';

export type DutchAddressFormProps = {
  prefix: string;
  disabled?: boolean;
  light?: boolean;
  validAddress: boolean;
};

const DutchAddressForm = ({
  prefix,
  disabled,
  light,
  validAddress,
}: DutchAddressFormProps): ReactElement => {
  const { formatMessage } = useIntl();
  return (
    <Box>
      <Box flexDirection="row" flexWrap="wrap" width="100%">
        <Box flexBasis="50%" overflow="hidden" flexGrow={1} paddingRight={3}>
          <ReactHookFormTextField
            name={`${prefix}.zipCode`}
            autoComplete="off"
            disabled={disabled}
            label={formatMessage({ id: 'label.zipCode' })}
            light={light}
          />
        </Box>
        <Box paddingRight={3} flexBasis="25%" flexGrow={1} overflow="hidden">
          <ReactHookFormTextField
            name={`${prefix}.houseNumber`}
            autoComplete="off"
            disabled={disabled}
            label={formatMessage({ id: 'label.houseNumber' })}
            light={light}
          />
        </Box>
        <Box flexBasis="25%" flexGrow={1} overflow="hidden">
          <ReactHookFormTextField
            name={`${prefix}.houseNumberAddition`}
            autoComplete="off"
            disabled={disabled}
            label={formatMessage({ id: 'label.houseNumberAddition' })}
            light={light}
          />
        </Box>
      </Box>
      {!validAddress && (
        <Box paddingRight={2} flexDirection="row" flexWrap="wrap" width="100%">
          <Box flexBasis="50%" overflow="hidden" flexGrow={1} paddingRight={3}>
            <ReactHookFormTextField
              name={`${prefix}.streetName`}
              autoComplete="off"
              disabled={disabled}
              label={formatMessage({ id: 'label.streetName' })}
              light={light}
            />
          </Box>
          <Box style={[]} flexBasis="50%" overflow="hidden" flexGrow={1}>
            <ReactHookFormTextField
              name={`${prefix}.city`}
              autoComplete="off"
              disabled={disabled}
              label={formatMessage({ id: 'label.city' })}
              light={light}
            />
          </Box>
        </Box>
      )}
      <Box paddingRight={2} flexDirection="row" flexWrap="wrap" width="100%">
        <Box flexBasis="100%">
          <ReactHookFormDropdown
            name={`${prefix}.country`}
            label={formatMessage({ id: 'label.country' })}
            disabled={disabled}
            light={light}
            items={[
              ...getCountryOptions().map((country) => ({
                id: country.code,
                label: country.label,
              })),
            ]}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default DutchAddressForm;
