import { ErrorResponse, Uuid } from '@bas/value-objects';
import { AuthenticationResponseJSON } from '@simplewebauthn/types';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type LoginWithPassKeyMutationProps = AuthenticationResponseJSON & {
  deviceId: Uuid;
  device?: string;
  tenantId?: Uuid;
};

export type LoginWithPassKeyMutationResponse = {
  employeeId: Uuid;
  userId: Uuid;
  intercomHash: string;
  mercureToken: string;
  token: string;
  twoFactorNeeded: boolean;
  expiresAt?: Date;
  sessionId: Uuid;
};

export const LoginWithPassKeyMutation = async ({
  ...values
}: LoginWithPassKeyMutationProps): Promise<
  AxiosResponse<LoginWithPassKeyMutationResponse>
> =>
  axios.post(
    '/api/auth/login-with-passkey',
    { ...values },
    {
      headers: {
        'content-type': 'application/json',
      },
    },
  );

export const useLoginWithPassKeyMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    LoginWithPassKeyMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse<LoginWithPassKeyMutationResponse>,
  AxiosError<ErrorResponse>,
  LoginWithPassKeyMutationProps
> =>
  useMutation<
    AxiosResponse<LoginWithPassKeyMutationResponse>,
    AxiosError<ErrorResponse>,
    LoginWithPassKeyMutationProps
  >({
    mutationFn: LoginWithPassKeyMutation,
    ...options,
    throwOnError: (error) => (error?.response?.status || 500) >= 500,
  });
