import { createText, TextProps } from '@shopify/restyle';
import { ReactElement, useMemo } from 'react';
import { StyleProp, StyleSheet, TextStyle } from 'react-native';
import { ShopifyTheme } from '../theme';

export type TypographyVariant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2'
  | 'caption'
  | 'button'
  | 'overline'
  | 'medium';

export type TypographyWeight =
  | 100
  | 200
  | 300
  | 400
  | 500
  | 600
  | 700
  | 800
  | 900;

const Text = createText<ShopifyTheme>();

export type TypographyProps = Omit<
  TextProps<ShopifyTheme>,
  'fontWeight' | 'fontFamily' | 'variant'
> & {
  variant?: TypographyVariant;
  fontWeight?: TypographyWeight;
  fontFamily?: 'DmSans' | 'WorkSans';
  overrideColor?: string;
  style?: StyleProp<TextStyle>;
  children?: React.ReactNode;
  ellipsizeMode?: 'head' | 'middle' | 'tail' | 'clip';
  numberOfLines?: number;
};

const weightStyling: {
  [key: string]: {
    [key: string]: {
      normal: StyleProp<TextStyle>;
      italic: StyleProp<TextStyle>;
    };
  };
} = {
  DmSans: {
    400: {
      normal: {
        fontFamily: 'DM_Sans_400_normal',
      },
      italic: {
        fontFamily: 'DM_Sans_400_italic',
      },
    },
    500: {
      normal: {
        fontFamily: 'DM_Sans_500_normal',
      },
      italic: {
        fontFamily: 'DM_Sans_500_italic',
      },
    },
    700: {
      normal: {
        fontFamily: 'DM_Sans_700_normal',
      },
      italic: {
        fontFamily: 'DM_Sans_700_italic',
      },
    },
  },
  WorkSans: {
    100: {
      normal: {
        fontFamily: 'Work_Sans_100_normal',
      },
      italic: {
        fontFamily: 'Work_Sans_100_italic',
      },
    },
    200: {
      normal: {
        fontFamily: 'Work_Sans_200_normal',
      },
      italic: {
        fontFamily: 'Work_Sans_200_italic',
      },
    },
    300: {
      normal: {
        fontFamily: 'Work_Sans_300_normal',
      },
      italic: {
        fontFamily: 'Work_Sans_300_italic',
      },
    },
    400: {
      normal: {
        fontFamily: 'Work_Sans_400_normal',
      },
      italic: {
        fontFamily: 'Work_Sans_400_italic',
      },
    },
    500: {
      normal: {
        fontFamily: 'Work_Sans_500_normal',
      },
      italic: {
        fontFamily: 'Work_Sans_500_italic',
      },
    },
    600: {
      normal: {
        fontFamily: 'Work_Sans_600_normal',
      },
      italic: {
        fontFamily: 'Work_Sans_600_italic',
      },
    },
    700: {
      normal: {
        fontFamily: 'Work_Sans_700_normal',
      },
      italic: {
        fontFamily: 'Work_Sans_700_italic',
      },
    },
    800: {
      normal: {
        fontFamily: 'Work_Sans_800_normal',
      },
      italic: {
        fontFamily: 'Work_Sans_800_italic',
      },
    },
    900: {
      normal: {
        fontFamily: 'Work_Sans_900_normal',
      },
      italic: {
        fontFamily: 'Work_Sans_900_italic',
      },
    },
  },
};

const styles = StyleSheet.create({
  baseStyling: {
    fontFamily: 'DM_Sans_500_normal',
  },
});

const Typography = ({
  style,
  overrideColor,
  ...props
}: TypographyProps): ReactElement => {
  const fontFamily = props.fontFamily || 'DmSans';
  const fontWeight = props.fontWeight || 400;
  const variant = props.variant || 'body1';

  const resultingStyle = useMemo(() => {
    const result: StyleProp<TextStyle> = [styles.baseStyling];
    if (fontWeight && fontFamily) {
      if (typeof weightStyling[fontFamily][fontWeight] === 'undefined') {
        // eslint-disable-next-line no-console
        console.warn(
          `Font ${fontFamily} with weight ${fontWeight} does not exist`,
        );
      } else {
        result.push(weightStyling[fontFamily][fontWeight].normal);
      }
    }

    if (style) {
      result.push(style);
    }

    if (overrideColor) {
      result.push({ color: overrideColor });
    }

    if (variant === 'subtitle1') {
      result.push(weightStyling.WorkSans[700].normal);
    }

    return result;
  }, [fontWeight, fontFamily, style, overrideColor, variant]);

  return <Text style={resultingStyle} variant={variant} {...props} />;
};

export default Typography;
