import type { CostType } from '@bas/shared/models';
import type { Money, Uuid } from '@bas/value-objects';

export interface ProjectCost {
  costId: Uuid;
  costType: CostType;
  expectedPurchasePricePerUnit?: Money;
  expectedQuantity?: number;
  realizedPurchasePricePerUnit?: Money;
  realizedQuantity?: number;
  expectedCosts?: Money;
  realizedCosts?: Money;
  difference?: Money;
  useDate: Date;
  name?: string;
  notes?: string;
  isFromIntegration: boolean;
}
