import { Uuid } from '@bas/value-objects';
import * as Yup from 'yup';
import {
  dayOffInputTypeValidationBuilder,
  DayOffOffInputType,
} from './dayOffInputType';
import {
  RequestTimeOffInputType,
  requestTimeOffInputTypeDefaultValues,
  requestTimeOffInputTypeValidationBuilder,
} from './requestTimeOffInputType';

export type ApproveTimeOffRegistrationInputType = Omit<
  RequestTimeOffInputType,
  'daysOff' | 'reason'
> & {
  employeeId: Uuid;
  reason?: string;
  daysOff: (DayOffOffInputType & {
    dayOffId: Uuid;
  })[];
};

export const approveTimeOffRegistrationInputTypeDefaultValues =
  (): ApproveTimeOffRegistrationInputType => ({
    ...requestTimeOffInputTypeDefaultValues(),
    employeeId: '',
    daysOff: [],
  });

export const approveTimeOffRegistrationInputTypeValidationBuilder =
  (): Yup.ObjectSchema<ApproveTimeOffRegistrationInputType> =>
    requestTimeOffInputTypeValidationBuilder().concat(
      Yup.object({
        employeeId: Yup.string().required().label('label.employee'),
        reason: Yup.string().label('label.reason'),
        daysOff: Yup.array()
          .of(
            dayOffInputTypeValidationBuilder().concat(
              Yup.object({
                dayOffId: Yup.string().required(),
              }),
            ),
          )
          .required()
          .label('label.daysOff'),
      }),
    );
