import { Button, FormattedCurrency } from '@bas/ui/native/atoms';
import { Box, Icon, Typography } from '@bas/ui/native/base';
import {
  Accordion,
  ReactHookFormHoursTextField,
  ReactHookFormNumberTextField,
  RenderProjectEstimateItemTitle,
} from '@bas/ui/native/molecules';
import {
  Money,
  ProjectEstimateItemGroupType,
  ProjectEstimateItemType,
} from '@bas/value-objects';
import { faUndo } from '@fortawesome/pro-light-svg-icons/faUndo';
import { FlashList, ListRenderItemInfo } from '@shopify/flash-list';
import * as React from 'react';
import { memo, ReactElement, useCallback } from 'react';
import isEqual from 'react-fast-compare';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { v7 } from 'uuid';
import styles from './styles';

type SmallItem = {
  key: string;
  isLast: boolean;
  isOverridden: boolean;
  itemType: ProjectEstimateItemType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extraData?: any;
  hasOverwrittenPricePerUnit: boolean;
  originalIndex: number;
  originalQuantity: number;
  originalPricePerUnitAmount?: number;
};

export type ItemGroupAccordionProps = {
  itemGroup: {
    total: Money;
    groupType: ProjectEstimateItemGroupType;
    items: SmallItem[];
  };
};

const RenderProjectEstimateItem = memo(
  ({
    index,
    groupType,
    item,
  }: {
    index: number;
    groupType: ProjectEstimateItemGroupType;
    item: SmallItem;
  }) => {
    const { setValue } = useFormContext();

    const onPressAction = useCallback(() => {
      setValue(
        `estimate.items.${item.originalIndex}.overwritten.quantity`,
        item.originalQuantity,
      );

      setValue(`estimate.items.${item.originalIndex}.wasOverwritten`, true);

      if (item.originalPricePerUnitAmount !== undefined) {
        setValue(
          `estimate.items.${item.originalIndex}.overwritten.pricePerUnit.amount`,
          item.originalPricePerUnitAmount,
        );
      } else {
        setValue(
          `estimate.items.${item.originalIndex}.overwritten.pricePerUnit`,
          undefined,
        );
      }

      setValue(`estimate.items.${item.originalIndex}.key`, v7());
    }, [
      item.originalIndex,
      item.originalPricePerUnitAmount,
      item.originalQuantity,
      setValue,
    ]);

    return (
      <Box flexDirection="row" flex={1} pt={1} key={`${index}-${item.key}`}>
        <Box
          flexBasis="41.666667%"
          flexDirection="row"
          alignItems="center"
          minHeight={44}
          borderBottomWidth={item.isLast ? 0 : 1}
          borderColor="titleBorder"
        >
          <RenderProjectEstimateItemTitle
            groupType={groupType}
            itemType={item.itemType}
            extraData={item.extraData}
          />
        </Box>
        <Box flexBasis="58.333333%" flexDirection="row" alignItems="center">
          <Box flexBasis="40%" paddingLeft="line" height="100%">
            {[
              ProjectEstimateItemType.CUBIC_METER,
              ProjectEstimateItemType.DISTANCE,
            ].includes(item.itemType) && (
              <ReactHookFormNumberTextField
                name={`estimate.items.${item.originalIndex}.overwritten.quantity`}
                autoComplete="off"
                variant="outlined"
                keyboardType="decimal-pad"
                textAlign="right"
                suffix={
                  item.itemType === ProjectEstimateItemType.CUBIC_METER
                    ? 'm³'
                    : 'km'
                }
                fractionDigits={2}
              />
            )}
            {[
              ProjectEstimateItemType.HOURS,
              ProjectEstimateItemType.TRAVEL_TIME,
            ].includes(item.itemType) && (
              <ReactHookFormHoursTextField
                name={`estimate.items.${item.originalIndex}.overwritten.quantity`}
                autoComplete="off"
                variant="outlined"
                textAlign="right"
              />
            )}
            {![
              ProjectEstimateItemType.CUBIC_METER,
              ProjectEstimateItemType.DISTANCE,
              ProjectEstimateItemType.HOURS,
              ProjectEstimateItemType.TRAVEL_TIME,
            ].includes(item.itemType) && (
              <ReactHookFormNumberTextField
                name={`estimate.items.${item.originalIndex}.overwritten.quantity`}
                autoComplete="off"
                variant="outlined"
                keyboardType="decimal-pad"
                textAlign="right"
                fractionDigits={2}
              />
            )}
          </Box>
          <Box flexBasis="40%" paddingLeft="line" height="100%">
            {item.hasOverwrittenPricePerUnit && (
              <ReactHookFormNumberTextField
                name={`estimate.items.${item.originalIndex}.overwritten.pricePerUnit.amount`}
                autoComplete="off"
                variant="outlined"
                keyboardType="decimal-pad"
                textAlign="right"
                divideBy={100}
                fractionDigits={2}
                type="currency"
              />
            )}
          </Box>
          <Box flexBasis="20%" paddingLeft="line" justifyContent="center">
            {item.isOverridden && (
              <Button
                onPress={onPressAction}
                variant="text"
                alignContent="center"
              >
                <Icon icon={faUndo} />
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    );
  },
  isEqual,
);

const ItemGroupAccordion = ({
  itemGroup,
}: ItemGroupAccordionProps): ReactElement => {
  const handleRenderItem = useCallback(
    ({ item, index }: ListRenderItemInfo<SmallItem>) => (
      <RenderProjectEstimateItem
        index={index}
        groupType={itemGroup.groupType}
        item={item}
      />
    ),
    [itemGroup.groupType],
  );

  return (
    <Accordion
      style={styles.accordion}
      headerStyle={styles.summary}
      summary={
        <Box flexDirection="row" flex={1} alignItems="center">
          <Box>
            <Typography>
              <FormattedMessage
                id={`estimate.groupTypes.${itemGroup.groupType}`}
              />
            </Typography>
          </Box>
          <Box style={styles.price}>
            <Typography>
              <FormattedCurrency {...itemGroup.total} />
            </Typography>
          </Box>
        </Box>
      }
    >
      <Box flexDirection="column" style={{ marginTop: -8 }} flex={1}>
        <FlashList
          renderItem={handleRenderItem}
          data={itemGroup.items}
          estimatedItemSize={88}
        />
      </Box>
    </Accordion>
  );
};

const MemoComponent = memo(ItemGroupAccordion, isEqual);
export default MemoComponent;
