export enum CargoStatus {
  CREATED = 'created',
  PLANNED_FOR_PICKUP = 'planned-for-pickup',
  OUT_FOR_PICKUP = 'out-for-pickup',
  FAILED_TO_PICKUP = 'failed-to-pickup',
  IN_POSSESSION = 'in-possession',
  PLANNED_FOR_DELIVERY = 'planned-for-delivery',
  OUT_FOR_DELIVERY = 'out-for-delivery',
  FAILED_TO_DELIVER = 'failed-to-deliver',
  DELIVERED = 'delivered',
  CANCELLED = 'cancelled',
}
