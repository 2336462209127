import type { SendingOfEmailFailedTask } from '../types';
import { TaskType } from '../types';
import { isTask } from './Task';

export function isSendingOfEmailFailedTask(
  object: unknown,
): object is SendingOfEmailFailedTask {
  return (
    !!object &&
    isTask(object) &&
    object.taskType === TaskType.SENDING_OF_EMAIL_FAILED_TASK
  );
}
