import { Typography } from '@bas/ui/native/base';
import { colors } from '@bas/theme';
import { memo, ReactElement, ReactNode } from 'react';
import isEqual from 'react-fast-compare';
import { StyleProp, TouchableOpacityProps, ViewStyle } from 'react-native';
import BaseTooltip from 'rn-tooltip';
import styles from './styles';

export type TooltipProps = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  popover?: ReactElement<{}>;
  withPointer?: boolean;
  height?: number | string;
  width?: number | string;
  containerStyle?: StyleProp<ViewStyle>;
  pointerColor?: string;
  pointerStyle?: StyleProp<ViewStyle>;
  onClose?: () => void;
  onOpen?: () => void;
  withOverlay?: boolean;
  overlayColor?: string;
  backgroundColor?: string;
  highlightColor?: string;
  toggleWrapperProps?: TouchableOpacityProps;
  actionType?: 'press' | 'longPress' | 'none';
  children: ReactNode;
  disabled?: boolean;
};

const Tooltip = ({
  actionType,
  children,
  disabled,
  popover,
  ...props
}: TooltipProps): ReactElement => (
  <BaseTooltip
    backgroundColor={colors.blue[700]}
    containerStyle={styles.container}
    actionType={disabled ? 'none' : actionType || 'press'}
    withOverlay={false}
    withPointer={false}
    height="auto"
    popover={<Typography overrideColor={colors.white}>{popover}</Typography>}
    {...props}
  >
    {children}
  </BaseTooltip>
);

const MemoComponent = memo(Tooltip, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps),
);
export default MemoComponent;
