import { PublicRoomType } from '@bas/shared/models';
import { FillingInRoomWizardInputType } from '@bas/taxation-tool-domain/input-types';
import { MovingJobTaxationRoom } from '@bas/taxation-tool-domain/models';
import { colors } from '@bas/theme';
import { RenderTranslatedName } from '@bas/ui/native/atoms';
import { BottomSheetMethods, Typography } from '@bas/ui/native/base';
import {
  BottomSheetProps,
  StepsNavigationProps,
} from '@bas/ui/native/organisms';
import { FormWizardBottomSheet } from '@bas/ui/native/templates';
import { InternalServiceType, ReactHookWizardStep } from '@bas/value-objects';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { ReactElement, ReactNode, RefObject, useCallback } from 'react';
import { Control, UseFormGetValues } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { NavigationStepsRoomNameLabel } from '../NavigationStepsRoomNameLabel';
import styles from './styles';

export type FillingInRoomWizardBottomSheetProps = Omit<
  BottomSheetProps,
  'snapPoints' | 'children'
> & {
  onFinishRoom: (intakeRoom: MovingJobTaxationRoom) => Promise<void> | void;
  onClose: () => void;
  bottomSheetRef: RefObject<BottomSheetModal & BottomSheetMethods>;
  steps: ReactHookWizardStep<FillingInRoomWizardInputType>[];
};

type Data = {
  intakeRoom: MovingJobTaxationRoom;
  room: PublicRoomType;
  usedServices: InternalServiceType[];
};

export type FillingInRoomWizardBottomSheetContentProps = {
  data: {
    intakeRoom: MovingJobTaxationRoom;
    room: PublicRoomType;
    usedServices: InternalServiceType[];
  };
  onFormTouched: () => void;
  onFinishRoom: (intakeRoom: MovingJobTaxationRoom) => Promise<void> | void;
  onClose: () => void;
};

const FillingInRoomWizardBottomSheet = ({
  onFinishRoom,
  steps,
  onClose,
  bottomSheetRef,
}: FillingInRoomWizardBottomSheetProps): ReactElement => {
  const renderTitle = useCallback(
    (
      data: Data,
      title: ReactNode,
      getValues: UseFormGetValues<FillingInRoomWizardInputType>,
    ) => {
      const values = getValues();
      const originalRoomName = data.room && (
        <RenderTranslatedName
          variant="h4"
          fontWeight={700}
          color="white"
          translations={data.room.translatedNames}
          fallback={data.room.roomTypeName}
        />
      );

      const roomName = values.name || originalRoomName;

      return (
        <Typography
          variant="h4"
          fontWeight={700}
          overrideColor={colors.white}
          style={styles.title}
        >
          {typeof title === 'string' ? (
            <FormattedMessage
              id={title}
              values={{
                room: roomName,
                originalRoom: originalRoomName,
              }}
            />
          ) : (
            title
          )}
        </Typography>
      );
    },
    [],
  );

  const renderLabel = useCallback(
    (data: Data, control: Control<FillingInRoomWizardInputType>) =>
      data.room ? (
        <NavigationStepsRoomNameLabel room={data.room} control={control} />
      ) : null,
    [],
  );

  const getFormName = useCallback(
    (data: Data) =>
      `filling-in-room-wizard-${data.intakeRoom.roomId || 'no-room'}`,
    [],
  );

  const getDefaultValues = useCallback(
    (data: Data) =>
      data.intakeRoom
        ? {
            ...JSON.parse(JSON.stringify(data.intakeRoom)),
            logicalRoomFurniture: (data.room.furnitureType || []).sort(
              (a, b) => a.weight - b.weight,
            ),
          }
        : {
            logicalRoomFurniture: (data.room.furnitureType || []).sort(
              (a, b) => a.weight - b.weight,
            ),
          },
    [],
  );

  return (
    <FormWizardBottomSheet<
      { stepNavigationProps: Partial<StepsNavigationProps> },
      FillingInRoomWizardInputType,
      Data
    >
      useData
      name={getFormName}
      wizardSteps={steps}
      initialValues={getDefaultValues}
      onSubmit={onFinishRoom}
      initialStepIndex={0}
      onRenderLabel={renderLabel}
      resetFormOnOpen
      extraTemplateProps={{
        stepNavigationProps: {
          dark: true,
          allowCancel: true,
          onCancel: onClose,
        },
      }}
      renderTitle={renderTitle}
      bottomSheetRef={bottomSheetRef}
    />
  );
};

export default FillingInRoomWizardBottomSheet;
