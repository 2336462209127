import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type UpdateDocumentTemplateDetailsMutationProps = {
  documentTemplateId: Uuid;
  name: string;
  expireInterval: string;
};

export const UpdateDocumentTemplateDetailsMutation = async ({
  documentTemplateId,
  ...values
}: UpdateDocumentTemplateDetailsMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/document-templates/${documentTemplateId}/update-settings`,
    {
      documentTemplateId,
      ...values,
    },
  );

export const useUpdateDocumentTemplateDetailsMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateDocumentTemplateDetailsMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  UpdateDocumentTemplateDetailsMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateDocumentTemplateDetailsMutationProps
  >({
    mutationFn: UpdateDocumentTemplateDetailsMutation,
    throwOnError: false,
    ...options,
  });
