import {
  FinishEventAtTenantInputType,
  FinishEventAtTenantInputTypeDefaultValues,
  FinishEventAtTenantInputTypeValidationBuilder,
} from '@bas/planning-domain/input-types';
import { Vehicle } from '@bas/planning-domain/models';
import { colors } from '@bas/theme';
import { BottomSheetMethods, Box, Typography } from '@bas/ui/native/base';
import { BackendErrors, BottomSheetProps } from '@bas/ui/native/organisms';
import { FormBottomSheet } from '@bas/ui/native/templates';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { ReactElement, RefObject, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { View } from 'react-native';
import { ensuredForwardRef } from 'react-use/lib/useEnsuredForwardedRef';
import { FinishEventAtTenantForm } from '../FinishEventAtTenantForm';

export type FinishEventAtTenantBottomSheetProps = Omit<
  BottomSheetProps,
  'snapPoints' | 'children'
> & {
  onSubmit: (values: FinishEventAtTenantInputType) => Promise<boolean>;
  vehicles: Vehicle[];
  endTime?: Date;
  finishedWorkingTime?: Date;
  currentTime: Date;
};

const FinishEventAtTenantBottomSheet = ensuredForwardRef<
  BottomSheetModal & BottomSheetMethods,
  FinishEventAtTenantBottomSheetProps
>(
  (
    {
      onSubmit,

      vehicles,
      endTime,
      finishedWorkingTime,
      currentTime,
      ...props
    }: FinishEventAtTenantBottomSheetProps,
    ref: RefObject<BottomSheetModal & BottomSheetMethods>,
  ): ReactElement => {
    const handleFinalize = useCallback(() => {
      ref?.current?.close();
      ref?.current?.forceClose();
      ref?.current?.dismiss();
    }, [ref]);

    return (
      <FormBottomSheet<FinishEventAtTenantInputType>
        bottomSheetRef={ref}
        disablePadding
        onSubmit={onSubmit}
        useProvider
        resetFormOnOpen
        onCancel={handleFinalize}
        defaultValues={{
          ...FinishEventAtTenantInputTypeDefaultValues(),
          mileageReports: vehicles.map(({ materialId }) => ({
            materialId,
            mileageStart: 0,
            mileageEnd: 0,
          })),
          realTravelEndTime: currentTime,
          finishedWorkingTime,
        }}
        validationSchema={FinishEventAtTenantInputTypeValidationBuilder}
        successFullFormViewProps={{
          gifUrl: 'https://media.giphy.com/media/26u4lOMA8JKSnL9Uk/giphy.gif',
          color: colors.lila[100],
          title: 'label.finishEventWhenBack',
        }}
        {...props}
      >
        <View>
          <Box alignSelf="center" height={51}>
            <Typography
              fontWeight={700}
              overrideColor={colors.white}
              fontSize={22}
              lineHeight={29}
            >
              <FormattedMessage id="label.finishEventWhenBack" />
            </Typography>
          </Box>
          <BackendErrors />
          <Box paddingBottom={3}>
            <FinishEventAtTenantForm
              vehicles={vehicles}
              endTime={currentTime}
            />
          </Box>
        </View>
      </FormBottomSheet>
    );
  },
);

export default FinishEventAtTenantBottomSheet;
