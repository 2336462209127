import {
  isQrCode,
  isShortStoredItemQrCode,
  StoredItemQrCode,
} from '@bas/shared/models';

export const useRetrieveQrInformation = () => (qrData: string) => {
  const parsedData = JSON.parse(qrData);
  if (!isQrCode(parsedData)) {
    throw new Error('Unknown QR code');
  }

  if (isShortStoredItemQrCode(parsedData)) {
    return {
      qrType: 'stored-item',
      storageId: parsedData.s,
      itemId: parsedData.i,
    } as StoredItemQrCode;
  }

  return parsedData;
};
