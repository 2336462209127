import { LinearGradient } from 'expo-linear-gradient';
import {
  createShimmerPlaceholder,
  ShimmerPlaceholderProps,
} from 'react-native-shimmer-placeholder';
import { ReactElement } from 'react';

const ShimmerPlaceholder = createShimmerPlaceholder(LinearGradient);

export type SkeletonProps = ShimmerPlaceholderProps & {
  light?: boolean;
};

const Skeleton = ({ light, ...props }: SkeletonProps): ReactElement => (
  <ShimmerPlaceholder
    shimmerColors={
      light
        ? [
            'rgba(255,255,255, 0.11)',
            'rgba(255,255,255, 0.21)',
            'rgba(255,255,255, 0.11)',
          ]
        : [
            'rgba(70, 68, 87, 0.11)',
            'rgba(70, 68, 87, 0.21)',
            'rgba(70, 68, 87, 0.11)',
          ]
    }
    {...props}
  />
);

export default Skeleton;
