import { MobileEmployeeChatScreen } from '@bas/communication-domain/native/pages';
import { useGetConversationForEmployee } from '@bas/shared/hooks';
import { useEmployeeStore } from '@bas/shared/state';
import { LoadingScreen } from '@bas/ui/native/templates';

const ChatScreen = () => {
  const employeeId = useEmployeeStore((state) => state.employee?.employeeId);

  const { conversation, isPending: isLoadingConversation } =
    useGetConversationForEmployee({
      employeeId: employeeId || '',
    });

  if (isLoadingConversation || !conversation || !employeeId) {
    return <LoadingScreen />;
  }

  return (
    <MobileEmployeeChatScreen
      conversation={conversation}
      employeeId={employeeId}
    />
  );
};

export default ChatScreen;
