import {
  AddressInputType,
  addressInputTypeDefaultValues,
  DriversLicenseInputType,
  DriversLicenseInputTypeDefaultValues,
} from '@bas/shared/input-types';
import { WorkingSchedule } from '@bas/value-objects';
import {
  EmployeeContractInputType,
  EmployeeContractInputTypeDefaultValues,
} from './employeeContractInputType';
import {
  EmployeeInformationInputType,
  EmployeeInformationInputTypeDefaultValues,
} from './employeeInformationInputType';
import {
  EmployeesAttributesInputType,
  EmployeesAttributesInputTypeDefaultValues,
} from './employeesAttributesInputType';
import {
  EmployeeSkillsInputType,
  EmployeeSkillsInputTypeDefaultValues,
} from './employeeSkillsInputType';
import {
  EmploymentInputType,
  EmploymentInputTypeDefaultValues,
} from './employmentInputType';

export type CreateEmployeeInputType = EmployeeInformationInputType &
  EmployeeSkillsInputType &
  EmploymentInputType &
  EmployeesAttributesInputType &
  DriversLicenseInputType & {
    availability: WorkingSchedule;
    contract: EmployeeContractInputType;
    address: AddressInputType;
  };

export const CreateEmployeeInputTypeDefaultValues =
  (): CreateEmployeeInputType => ({
    ...EmployeeInformationInputTypeDefaultValues(),
    ...EmployeeSkillsInputTypeDefaultValues(),
    ...EmploymentInputTypeDefaultValues(),
    ...DriversLicenseInputTypeDefaultValues(),
    ...EmployeesAttributesInputTypeDefaultValues(),
    availability: {
      monday: {},
      tuesday: {},
      wednesday: {},
      thursday: {},
      friday: {},
      saturday: {},
      sunday: {},
    },
    contract: EmployeeContractInputTypeDefaultValues(),
    address: addressInputTypeDefaultValues(),
  });
