import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type UpdateWarehouseInformationMutationProps = {
  warehouseId: Uuid;
  name: string;
  isOwnWarehouse: boolean;
};

export const UpdateWarehouseInformationMutation = async ({
  warehouseId,
  ...values
}: UpdateWarehouseInformationMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/warehouses/${warehouseId}/update-information`, {
    warehouseId,
    ...values,
  });

export const useUpdateWarehouseInformationMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateWarehouseInformationMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  UpdateWarehouseInformationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateWarehouseInformationMutationProps
  >({
    mutationFn: UpdateWarehouseInformationMutation,
    throwOnError: false,
    ...options,
  });
