import { getDecimalSeparator } from '@bas/shared/utils';
import React, { RefObject, useCallback, useMemo, useState } from 'react';
import { TextInput } from 'react-native';
import { TextField } from '../TextField';

const LOCALE = 'nl';

export interface NumberTextFieldProps
  extends Omit<
    React.ComponentProps<typeof TextField>,
    'value' | 'onChangeText'
  > {
  value?: string | number;
  onChangeText: (value: string) => void;
  type?: 'number' | 'currency' | 'percentage';
  prefix?: string;
  suffix?: string;
  padZero?: boolean;
  shouldBeZero?: boolean;
  showZero?: boolean;
  divideBy?: number;
  fractionDigits?: number;
  inputRef?: RefObject<TextInput>;
  hideLabel?: boolean;
}

const NumberTextField = ({
  value,
  onChangeText,
  type = 'number',
  prefix = '',
  suffix = '',
  padZero = false,
  shouldBeZero = false,
  showZero = true,
  divideBy = 1,
  fractionDigits = 2,
  inputRef,
  hideLabel = false,
  textAlign = 'center',
  label,
  ...props
}: NumberTextFieldProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [internalRaw, setInternalRaw] = useState<string | undefined>(undefined);

  const decimalSeparator = useMemo(() => getDecimalSeparator(LOCALE), []);

  const computedDivideBy = useMemo(
    () => (type !== 'percentage' ? divideBy : 1),
    [type, divideBy],
  );

  const numberFormatter = useMemo(() => {
    const options: Intl.NumberFormatOptions = {
      minimumFractionDigits: padZero ? fractionDigits : 0,
      maximumFractionDigits: fractionDigits,
    };
    if (type === 'currency') {
      return new Intl.NumberFormat(LOCALE, {
        ...options,
        style: 'currency',
        currency: 'EUR',
      });
    }
    if (type === 'percentage') {
      return new Intl.NumberFormat(LOCALE, {
        ...options,
        style: 'percent',
      });
    }
    return new Intl.NumberFormat(LOCALE, options);
  }, [padZero, type, fractionDigits]);

  const formattedValue = useMemo(() => {
    let numericValue: number;

    if (typeof value === 'number') {
      numericValue = value / computedDivideBy;
    } else if (typeof value === 'string') {
      numericValue = parseFloat(value) / computedDivideBy;
    } else {
      numericValue = NaN;
    }

    if (Number.isNaN(numericValue)) {
      if (shouldBeZero || padZero) {
        numericValue = 0;
      } else {
        return '';
      }
    }

    if (!showZero && numericValue === 0) {
      return '';
    }

    let formatted = numberFormatter.format(numericValue);
    if (prefix || suffix) {
      formatted = `${prefix}${formatted}${suffix}`;
    }
    return formatted;
  }, [
    value,
    numberFormatter,
    padZero,
    shouldBeZero,
    showZero,
    prefix,
    suffix,
    computedDivideBy,
  ]);

  const processValue = useCallback(
    (raw: string): string => {
      let finalRaw = raw.replace(decimalSeparator, '.');
      if (padZero && finalRaw === '') {
        finalRaw = '0';
      }

      const parsed = parseFloat(finalRaw);
      if (Number.isNaN(parsed)) {
        return finalRaw;
      }

      const factor = 10 ** fractionDigits;
      let storedValue: number;

      if (type === 'percentage') {
        // For percentages, first round the input value, then divide by 100
        const roundedInput = Math.round(parsed * factor) / factor;
        storedValue = roundedInput / 100;
      } else {
        const roundedInput = Math.round(parsed * factor) / factor;
        storedValue = roundedInput * computedDivideBy;
      }

      return storedValue.toString();
    },
    [decimalSeparator, padZero, type, fractionDigits, computedDivideBy],
  );

  const handleChangeText = useCallback(
    (text: string) => {
      let raw = text;
      if (prefix) {
        raw = raw.replace(prefix, '');
      }
      if (suffix) {
        raw = raw.replace(suffix, '');
      }
      // Replace any dot or comma with the locale's decimal separator
      const normalized = raw.replace(/[.,]/g, decimalSeparator);
      // Remove any characters except digits and the locale decimal separator
      const allowedChars = new RegExp(`[^0-9${decimalSeparator}]`, 'g');
      let cleaned = normalized.replace(allowedChars, '');
      // Ensure only one instance of the decimal separator
      const parts = cleaned.split(decimalSeparator);
      if (parts.length > 2) {
        cleaned = parts[0] + decimalSeparator + parts.slice(1).join('');
      }

      setInternalRaw(cleaned);
      // Process and update the value immediately
      onChangeText(processValue(cleaned));
    },
    [prefix, suffix, decimalSeparator, processValue, onChangeText],
  );

  const handleFocus = useCallback(() => {
    setIsFocused(true);
    if (value !== undefined) {
      let raw: string;
      if (typeof value === 'number') {
        const displayValue = value / computedDivideBy;
        // For percentages, multiply by 100 for display
        raw =
          type === 'percentage'
            ? (displayValue * 100).toString()
            : displayValue.toString();
      } else {
        const parsed = parseFloat(value);
        if (Number.isNaN(parsed)) {
          raw = value;
        } else {
          const displayValue = parsed / computedDivideBy;
          raw =
            type === 'percentage'
              ? (displayValue * 100).toString()
              : displayValue.toString();
        }
      }
      // Convert dot to the locale's decimal separator
      raw = raw.replace('.', decimalSeparator);
      setInternalRaw(raw);
    }
  }, [value, decimalSeparator, computedDivideBy, type]);

  const handleBlur = useCallback(() => {
    setIsFocused(false);
    setInternalRaw(undefined);
  }, []);

  const displayValue = useMemo(() => {
    if (isFocused) {
      return internalRaw ?? '';
    }
    return formattedValue;
  }, [isFocused, internalRaw, formattedValue]);

  return (
    <TextField
      {...props}
      value={displayValue}
      onChangeText={handleChangeText}
      onFocus={handleFocus}
      onBlur={handleBlur}
      keyboardType="numeric"
      inputRef={inputRef}
      label={hideLabel || props.variant === 'outlined' ? undefined : label}
      textAlign={textAlign}
    />
  );
};

export default NumberTextField;
