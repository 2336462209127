import { Employee } from '@bas/hrm-domain/models';
import { Damage } from '@bas/project-domain/models';
import { DamageInputType } from '@bas/shared/input-types';
import { colors } from '@bas/theme';
import { Typography } from '@bas/ui/native/base';
import { useMemo } from 'react';
import { View } from 'react-native';

export type DamageSummaryItemProps = {
  damage: DamageInputType | Damage;
  employees: Employee[];
  color?: string;
  lessImportant?: boolean;
};

const DamageSummaryItem = ({
  damage,
  employees,
  color,
  lessImportant,
}: DamageSummaryItemProps) => {
  const employee = useMemo(
    () =>
      employees.find(
        ({ employeeId }) => employeeId === damage.damagedByEmployeeId,
      ),
    [damage.damagedByEmployeeId, employees],
  );

  return (
    <View>
      <View>
        <Typography
          overrideColor={color}
          variant={lessImportant ? 'subtitle2' : 'subtitle1'}
          fontWeight={lessImportant ? 700 : undefined}
        >
          {damage.summary}
        </Typography>
      </View>
      {employee && (
        <View>
          <Typography overrideColor={colors.lila[600]} variant="subtitle2">
            {employee?.personName?.fullName}
          </Typography>
        </View>
      )}
      <View>
        <Typography overrideColor={color}>{damage.description}</Typography>
      </View>
    </View>
  );
};

export default DamageSummaryItem;
