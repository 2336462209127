import dayjs from 'dayjs';
import * as Yup from 'yup';

export interface RecurringEventFutureEventInputType {
  date: Date;
  updateFutureEvents: boolean;
}

export const RecurringEventFutureEventInputTypeDefaultValues =
  (): RecurringEventFutureEventInputType => ({
    date: new Date(),
    updateFutureEvents: false,
  });

export const RecurringEventFutureEventInputTypeValidationBuilder = (
  eventDates: Date[],
  disableDateValidation = false,
): Yup.ObjectSchema<RecurringEventFutureEventInputType> =>
  Yup.object({
    date: Yup.date()
      .required()
      .label('label.date')
      .when(['updateFutureEvents'], ([updateFutureEvents], schema) => {
        if (disableDateValidation) {
          return schema;
        }

        if (updateFutureEvents) {
          return schema.min(dayjs().startOf('day').toDate());
        }

        return schema
          .test(
            'is-in-event-dates',
            'De datum moet overeenkomen met een van de herhalende datums, en mag niet overschreven of uitgesloten zijn.',
            (value) => {
              if (!dayjs(value).isValid()) {
                return true;
              }

              return eventDates.some((date) =>
                dayjs(value).isSame(date, 'day'),
              );
            },
          )
          .min(dayjs().startOf('day').toDate());
      }),
    updateFutureEvents: Yup.boolean()
      .required()
      .label('label.updateFutureEvents'),
  });
