import { MediaObject } from '@bas/media-domain/models';
import { DamageInflictedOnType } from '@bas/project-domain/models';
import { Uuid } from '@bas/value-objects';
import { v7 } from 'uuid';
import * as Yup from 'yup';
import {
  AddressInputType,
  addressInputTypeDefaultValues,
  addressInputTypeValidationBuilder,
} from './addressInputType';

export interface DamageInputType {
  damageId: Uuid;
  damageWasAlreadyThere: boolean;
  damageInflictedOn: DamageInflictedOnType;
  location: AddressInputType & {
    index?: string | null;
  };
  damagedAt?: Date | null;
  summary: string;
  description: string;
  damagedByEmployeeId?: Uuid;
  pictures: MediaObject[];
}

export const DamageInputTypeDefaultValues = (): DamageInputType => ({
  damageId: v7(),
  damageWasAlreadyThere: false,
  damageInflictedOn: DamageInflictedOnType.UNKNOWN,
  location: { ...addressInputTypeDefaultValues() },
  damagedByEmployeeId: '',
  summary: '',
  description: '',
  pictures: [],
  damagedAt: null,
});

export const DamageInputTypeValidationBuilder =
  (): Yup.ObjectSchema<DamageInputType> =>
    Yup.object({
      damageId: Yup.string().required().label('label.employee'),
      damageWasAlreadyThere: Yup.boolean()
        .required()
        .label('label.damageWasAlreadyThere'),
      damageInflictedOn: Yup.mixed<DamageInflictedOnType>()
        .oneOf(Object.values(DamageInflictedOnType).filter((v) => !!v))
        .required()
        .label('label.whatWasTheDamageInflictedOn'),
      damagedAt: Yup.date()
        .nullable()
        .required()
        .label('label.whenWasTheDamageMade'),
      summary: Yup.string().required().label('label.summary'),
      description: Yup.string().required().label('label.description'),
      location: addressInputTypeValidationBuilder().concat(
        Yup.object({
          index: Yup.string().nullable().required().label('label.location'),
        }),
      ),
      damagedByEmployeeId: Yup.string()
        .label('label.byWhomWasTheDamageMade')
        .when(['damageWasAlreadyThere'], ([damageWasAlreadyThere], schema) => {
          if (damageWasAlreadyThere) {
            return schema;
          }

          return schema.required();
        }),
      pictures: Yup.array()
        .of(
          Yup.object({
            mediaObjectId: Yup.string().required().label('label.mediaObjectId'),
            url: Yup.string().required().label('label.url'),
            contentUrl: Yup.string().required().label('label.contentUrl'),
          }),
        )
        .required()
        .label('label.pictures'),
    });
