import { isInvoiceEvent } from './InvoiceEvent';
import { InvoiceEventType, InvoicePaymentRemovedEvent } from '../types';

export function isInvoicePaymentRemovedEvent(
  object: unknown,
): object is InvoicePaymentRemovedEvent {
  return (
    isInvoiceEvent(object) &&
    object.eventType === InvoiceEventType.InvoicePaymentRemovedEvent
  );
}
