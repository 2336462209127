import { isRelation } from './Relation';
import { PrivateRelation, RelationType } from '../types';

export function isPrivateRelation(object?: unknown): object is PrivateRelation {
  return (
    !!object &&
    isRelation(object) &&
    object.relationType === RelationType.PRIVATE
  );
}
