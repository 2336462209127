import { QueryOptionsWithKey } from '@bas/shared/requests';
import { Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type TenantInvoicePdfByTenantInvoiceIdRequestProps = {
  invoiceId: Uuid;
};

export const TenantInvoicePdfByTenantInvoiceIdRequest = async ({
  invoiceId,
  ...params
}: TenantInvoicePdfByTenantInvoiceIdRequestProps): Promise<
  AxiosResponse<Blob>
> =>
  axios.get(`api/{tenantId}/tenant-invoices/${invoiceId}/download-pdf`, {
    responseType: 'blob',
    params,
  });

export const useTenantInvoicePdfByTenantInvoiceIdRequestQuery = (
  request: TenantInvoicePdfByTenantInvoiceIdRequestProps,
  options: QueryOptionsWithKey<
    AxiosResponse<Blob>,
    AxiosError,
    AxiosResponse<Blob>
  > = {},
): UseQueryResult<AxiosResponse<Blob>, AxiosError> =>
  useQuery<AxiosResponse<Blob>, AxiosError, AxiosResponse<Blob>>({
    ...options,
    queryFn: async () => TenantInvoicePdfByTenantInvoiceIdRequest(request),
    queryKey: ['tenant-invoices', 'pdf', request.invoiceId],
  });
