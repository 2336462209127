import type { MovingJobAddress } from '../types';

export function isMovingJobAddress(
  object: unknown,
): object is MovingJobAddress {
  return (
    Object.prototype.hasOwnProperty.call(object, 'primary') &&
    Object.prototype.hasOwnProperty.call(object, 'addressId')
  );
}
