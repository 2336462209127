import { Platform, StyleSheet } from 'react-native';

export default StyleSheet.create({
  clickable: {
    ...Platform.select({
      web: {
        cursor: 'pointer',
      },
    }),
  },
});
