import * as Yup from 'yup';
import { Uuid } from '@bas/value-objects';

export interface InventoryEventInputType {
  eventDate?: Date | null;
  quantity: number;
  customer?: {
    relationId: Uuid;
  } | null;
  projectId?: Uuid;
  planningEventId?: Uuid;
}

export const InventoryEventInputTypeDefaultValues =
  (): InventoryEventInputType => ({
    eventDate: null,
    quantity: 0,
    customer: null,
    projectId: '',
    planningEventId: '',
  });

export const InventoryEventInputTypeValidationBuilder =
  (): Yup.ObjectSchema<InventoryEventInputType> =>
    Yup.object({
      eventDate: Yup.date().nullable().required().label('label.date'),
      quantity: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .nullable()
        .min(0)
        .required()
        .label('label.quantity'),
      customer: Yup.object({
        relationId: Yup.string().required().label('label.customer'),
      })
        .nullable()
        .required()
        .label('label.customer'),
      projectId: Yup.string().label('label.project'),
      planningEventId: Yup.string().label('label.event'),
    });
