import { BasMovingSoftwareComponent } from '../types';
import { isTenantBillableComponent } from './TenantBillableComponent';

export function isBasMovingSoftwareComponent(
  component: unknown,
): component is BasMovingSoftwareComponent {
  return (
    typeof component === 'object' &&
    isTenantBillableComponent(component) &&
    component.componentType === 'bas-moving-software'
  );
}
