import { useEmployeeStore } from '@bas/shared/state';
import { Box, Typography } from '@bas/ui/native/base';
import { ReactElement, ReactNode, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Image } from 'react-native';
import styles from './styles';

export type SuccessFullFormViewProps = {
  labelId?: string;
  title?: ReactNode;
  gifUrl: string;
  color: string;
  greetingName?: string | null;
  children?: ReactNode;
  isSuccessFull?: boolean;
};

const SuccessFullFormView = ({
  title,
  gifUrl,
  greetingName,
  labelId,
  color,
  isSuccessFull,
  children,
}: SuccessFullFormViewProps): ReactElement => {
  const firstName = useEmployeeStore(
    (state) => state.employee?.personName?.firstName,
  );

  const greeting = useMemo(
    () => greetingName || firstName,
    [greetingName, firstName],
  );

  if (!isSuccessFull) {
    return <Box flex={1}>{children}</Box>;
  }

  return (
    <Box flex={1}>
      {title && (
        <Typography
          overrideColor={color}
          variant="h4"
          style={styles.title}
          fontWeight={700}
        >
          {typeof title === 'string' && (
            <FormattedMessage id={title} values={{ greetingName: greeting }} />
          )}
          {typeof title !== 'string' && title}
        </Typography>
      )}
      <Typography style={styles.niceJob} overrideColor={color}>
        <FormattedMessage
          id={labelId || 'label.niceJob'}
          values={{ greetingName: greeting }}
        />
      </Typography>
      <Box>
        <Image
          source={{
            uri: gifUrl,
          }}
          style={styles.avatar}
        />
      </Box>
    </Box>
  );
};
export default SuccessFullFormView;
