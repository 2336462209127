import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ChangeRelatedInformationOfDeclarationMutationProps = {
  declarationId: Uuid;
  eventId?: Uuid | null;
  projectId?: Uuid | null;
  relationId?: Uuid | null;
};

export const ChangeRelatedInformationOfDeclarationMutation = async ({
  declarationId,
  ...values
}: ChangeRelatedInformationOfDeclarationMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/hrm/declarations/${declarationId}/change-related-information`,
    {
      declarationId,
      ...values,
    },
  );

export const useChangeRelatedInformationOfDeclarationMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeRelatedInformationOfDeclarationMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ChangeRelatedInformationOfDeclarationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeRelatedInformationOfDeclarationMutationProps
  >({
    mutationFn: ChangeRelatedInformationOfDeclarationMutation,
    throwOnError: false,
    ...options,
  });
