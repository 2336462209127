import { CustomerNotificationsFeature, FeatureType } from '../types';
import { isFeature } from './Feature';

export function isCustomerNotificationsFeature(
  object: unknown,
): object is CustomerNotificationsFeature {
  return (
    isFeature(object) &&
    object.featureType === FeatureType.CUSTOMER_NOTIFICATIONS
  );
}
