import { PublicFurnitureType } from '@bas/shared/models';
import {
  CustomFurnitureInputType,
  WhatFurnitureIsInTheRoomInputType,
} from '@bas/taxation-tool-domain/input-types';
import {
  isMovingJobTaxationCustomFurniture,
  isMovingJobTaxationStandardFurniture,
  MovingJobTaxationCustomFurniture,
  MovingJobTaxationFurniture,
  MovingJobTaxationFurnitureInitialValues,
  MovingJobTaxationStandardFurniture,
} from '@bas/taxation-tool-domain/models';
import { usePublicFurnitureTypesRequest } from '@bas/tenant-domain/requests';
import { BottomSheetMethods, Box } from '@bas/ui/native/base';
import { SquareAddTile } from '@bas/ui/native/molecules';
import { FurnitureSquareTile, SquareTilesList } from '@bas/ui/native/organisms';
import { Uuid } from '@bas/value-objects';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { ListRenderItemInfo } from '@shopify/flash-list';
import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import isEqual from 'react-fast-compare';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { v7 } from 'uuid';
import { AddCustomFurnitureBottomSheet } from '../AddCustomFurnitureBottomSheet';
import { AddFurnitureBottomSheet } from '../AddFurnitureBottomSheet';

const FormFurnitureSquareTile = ({
  index,
  onAddFurniture,
}: {
  index: number;
  onAddFurniture: (
    quantity: number,
    furnitureType?: PublicFurnitureType,
    furnitureVariantId?: Uuid,
    intakeFurniture?: MovingJobTaxationFurniture,
    index?: number,
  ) => void;
}): ReactElement | null => {
  const { data } = usePublicFurnitureTypesRequest();
  const furnitureTypes = useMemo(() => data?.data.member ?? [], [data]);
  const { setValue } = useFormContext();

  const handleUnselectFurniture = useCallback(
    (intakeFurniture?: MovingJobTaxationFurniture) => {
      setValue(`furniture[${index}].selected`, false);
      if (intakeFurniture) {
        setValue(`furniture[${index}].quantity`, 0);
        setValue(`furniture[${index}].furnitureVariantId`, undefined);
      }
    },
    [index, setValue],
  );

  const handleChangeQuantity = useCallback(
    (quantity: number, intakeFurniture?: MovingJobTaxationFurniture) => {
      if (intakeFurniture) {
        setValue(`furniture[${index}].quantity`, quantity);
      }
    },
    [index, setValue],
  );

  const item = useWatch({
    name: `furniture[${index}]`,
  });

  if (isMovingJobTaxationCustomFurniture(item)) {
    return (
      <FurnitureSquareTile
        handleAddFurniture={onAddFurniture}
        handleUnselectFurniture={handleUnselectFurniture}
        intakeFurniture={item}
        index={index}
        width="100%"
      />
    );
  }

  if (!isMovingJobTaxationStandardFurniture(item)) {
    return null;
  }

  const furnitureType = furnitureTypes.find(
    ({ furnitureTypeId }) => furnitureTypeId === item.furnitureTypeId,
  );

  if (!furnitureType) {
    return null;
  }
  return (
    <FurnitureSquareTile
      width="100%"
      furnitureType={furnitureType}
      intakeFurniture={item}
      handleAddFurniture={onAddFurniture}
      handleUnselectFurniture={handleUnselectFurniture}
      handleChangeQuantity={handleChangeQuantity}
      index={index}
    />
  );
};

type ItemType =
  | { dataType: 'furniture'; index: number; furnitureId: Uuid }
  | { dataType: 'addFurniture' }
  | { dataType: 'addCustomFurniture' };

const WhatFurnitureIsInTheRoom = (): ReactElement => {
  const furnitureBottomSheetRef = useRef<BottomSheetModal & BottomSheetMethods>(
    null,
  );
  const customFurnitureBottomSheetRef = useRef<
    BottomSheetModal & BottomSheetMethods
  >(null);
  const [filter, setFilter] = useState<string>();
  const [furnitureIndexes, setFurnitureIndexes] = useState<
    { dataType: 'furniture'; index: number; furnitureId: Uuid }[]
  >([]);

  const { formatMessage } = useIntl();

  const { data, isLoading } = usePublicFurnitureTypesRequest();
  const furnitureTypes = useMemo(() => data?.data.member ?? [], [data]);

  const { append } = useFieldArray<
    WhatFurnitureIsInTheRoomInputType,
    'furniture'
  >({ name: 'furniture' });

  const [furniture, logicalRoomFurniture] = useWatch<
    WhatFurnitureIsInTheRoomInputType,
    ['furniture', 'logicalRoomFurniture']
  >({ name: ['furniture', 'logicalRoomFurniture'] });

  const { setValue } = useFormContext();

  const handleAddCustomFurniture = useCallback(
    ({ label, cubicMeter, servicesPossible }: CustomFurnitureInputType) => {
      const furnitureToAdd: MovingJobTaxationCustomFurniture = {
        furnitureId: v7(),
        label,
        cubicMeter:
          typeof cubicMeter === 'string' ? parseFloat(cubicMeter) : cubicMeter,
        furnitureType: 'custom',
        ...MovingJobTaxationFurnitureInitialValues,
        servicesPossible,
      };

      append(furnitureToAdd);
      customFurnitureBottomSheetRef.current?.close();
    },
    [append],
  );

  const handleAddFurniture = useCallback(
    (
      quantity: number,
      furnitureType?: PublicFurnitureType,
      furnitureVariantId?: Uuid,
      intakeFurniture?: MovingJobTaxationFurniture,
      index?: number,
    ) => {
      if (intakeFurniture) {
        if (index === -1 || index === null || index === undefined) {
          return;
        }

        setValue(`furniture[${index}].selected`, true);
        setValue(`furniture[${index}].quantity`, quantity);
        if (furnitureVariantId) {
          setValue(
            `furniture[${index}].furnitureVariantId`,
            furnitureVariantId,
          );
        }

        setFilter(undefined);
        return;
      }

      if (!furnitureType) {
        return;
      }

      const furnitureToAdd: MovingJobTaxationStandardFurniture = {
        furnitureId: v7(),
        furnitureTypeId: furnitureType?.furnitureTypeId,
        furnitureVariantId,
        furnitureType: 'furniture',
        ...MovingJobTaxationFurnitureInitialValues,
        servicesPossible: furnitureType.servicesPossible,
        quantity,
      };

      append(furnitureToAdd);
      setFilter(undefined);
      furnitureBottomSheetRef?.current?.close();
    },
    [append, setValue],
  );

  useEffect(() => {
    const standardFurniture = furniture.filter(
      isMovingJobTaxationStandardFurniture,
    );
    const hasLogicalFurnitureNotInRoom = logicalRoomFurniture.some(
      (logicalFurniture) => {
        const foundItem = standardFurniture.find(
          (item) => item.furnitureTypeId === logicalFurniture.furnitureTypeId,
        );

        return !foundItem;
      },
    );
    if (hasLogicalFurnitureNotInRoom) {
      setValue('furniture', [
        ...logicalRoomFurniture.map((furnitureType) => {
          const foundItem = standardFurniture.find(
            (item) => item.furnitureTypeId === furnitureType.furnitureTypeId,
          );

          return {
            furnitureId: v7(),
            furnitureTypeId: furnitureType.furnitureTypeId,
            furnitureType: 'furniture',
            ...MovingJobTaxationFurnitureInitialValues,
            selected: false,
            quantity: 0,
            servicesPossible: furnitureType.servicesPossible,
            ...(foundItem || {}),
          };
        }),
        ...standardFurniture.filter((item) => {
          const foundItem = logicalRoomFurniture.find(
            (furnitureType) =>
              furnitureType.furnitureTypeId === item.furnitureTypeId,
          );

          return !foundItem;
        }),
        ...furniture.filter(isMovingJobTaxationCustomFurniture),
      ]);
    }
  }, [logicalRoomFurniture, furniture, setValue]);

  const furnitureItems = useMemo(
    (): ItemType[] => [
      {
        dataType: 'addFurniture',
      },
      {
        dataType: 'addCustomFurniture',
      },
      ...furnitureIndexes,
    ],
    [furnitureIndexes],
  );

  useEffect(() => {
    const result = [
      ...furniture.map((item, index) => ({
        furnitureId: item.furnitureId,
        dataType: 'furniture' as const,
        index,
      })),
    ];

    if (isEqual(result, furnitureIndexes)) {
      return;
    }

    setFurnitureIndexes(result);
  }, [furniture, furnitureIndexes]);

  const handleRenderItem = useCallback(
    ({ item }: ListRenderItemInfo<ItemType>) => {
      if (item.dataType === 'addFurniture') {
        return (
          <SquareAddTile
            width="100%"
            onPress={() => furnitureBottomSheetRef.current?.present()}
            light
            label={formatMessage({
              id: 'mobileApp.intake.movingJob.addFurniture',
            })}
          />
        );
      }
      if (item.dataType === 'addCustomFurniture') {
        return (
          <SquareAddTile
            width="100%"
            onPress={() => customFurnitureBottomSheetRef.current?.present()}
            light
            label={formatMessage({
              id: 'mobileApp.intake.movingJob.addCustomFurniture',
            })}
          />
        );
      }

      if (item.dataType === 'furniture') {
        return (
          <FormFurnitureSquareTile
            key={item.index}
            index={item.index}
            onAddFurniture={handleAddFurniture}
          />
        );
      }

      return null;
    },
    [handleAddFurniture, formatMessage],
  );

  return (
    <Box
      flex={1}
      style={{
        marginLeft: -19,
      }}
    >
      <SquareTilesList
        renderItem={handleRenderItem}
        data={furnitureItems}
        isLoading={isLoading}
        inBottomSheet
        extraData={furnitureIndexes.length}
      />
      <AddFurnitureBottomSheet
        furniture={furnitureTypes}
        bottomSheetRef={furnitureBottomSheetRef}
        onAddFurniture={handleAddFurniture}
        onAddCustomFurniture={() => {
          customFurnitureBottomSheetRef.current?.present();
          furnitureBottomSheetRef.current?.close();
        }}
        filter={filter}
        setFilter={setFilter}
      />
      <AddCustomFurnitureBottomSheet
        bottomSheetRef={customFurnitureBottomSheetRef}
        onAddCustomFurniture={handleAddCustomFurniture}
      />
    </Box>
  );
};

export default WhatFurnitureIsInTheRoom;
