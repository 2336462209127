import type { VatCode } from '../types';

export function isVatCode(object: unknown): object is VatCode {
  return (
    !!object &&
    typeof object === 'object' &&
    'vatCodeId' in object &&
    'vatCode' in object &&
    'country' in object &&
    'percentages' in object &&
    'percentage' in object
  );
}
