import { ReactElement } from 'react';
import { View, ViewProps } from 'react-native';
import styles from './styles';

export type ChipProps = ViewProps;

const Chip = ({ children, style, ...props }: ChipProps): ReactElement => (
  <View style={[styles.badge, style]} {...props}>
    {children}
  </View>
);

export default Chip;
