import { LoginInputType } from '@bas/authentication-domain/input-types';
import { colors } from '@bas/theme';
import { Alert, Button, useFormSubmitState } from '@bas/ui/native/atoms';
import { Box, Icon, IconWithSpin, Typography } from '@bas/ui/native/base';
import { ReactHookFormTextField } from '@bas/ui/native/molecules';
import { BackendErrors } from '@bas/ui/native/organisms';
import {
  faScannerKeyboard,
  faSpinnerThird,
} from '@fortawesome/pro-light-svg-icons';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons/faArrowRight';
import { router } from 'expo-router';
import * as React from 'react';
import { ReactElement, ReactNode } from 'react';
import { useFormState, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Platform, View } from 'react-native';
import { Passkey } from 'react-native-passkey';
import styles from './styles';

const isSupported: boolean = Passkey.isSupported();

export type LoginFormProps = {
  requireTwoFactorCode?: boolean;
  error?: ReactNode;
  onLoginWithPassKey: (appUrl: string) => void;
  isAuthenticatingWithPassKey: boolean;
};

const LoginForm = ({
  requireTwoFactorCode,
  error,
  onLoginWithPassKey,
  isAuthenticatingWithPassKey,
}: LoginFormProps): ReactElement => {
  const { formatMessage } = useIntl();
  const { onSubmit } = useFormSubmitState();
  const { isSubmitting } = useFormState<
    LoginInputType & {
      backendErrors?: {
        message: string;
      };
    }
  >();

  const appUrl = useWatch({
    name: 'appUrl',
  });

  return (
    <Box>
      <Box pb={2}>
        <BackendErrors />
      </Box>
      {error && <Alert severity="warning">{error}</Alert>}
      {isAuthenticatingWithPassKey && (
        <Box>
          <Typography color="white" textAlign="center">
            <FormattedMessage id="label.weAreLoggingYouIn" />
          </Typography>
          <Box height={64} width={64} alignSelf="center" margin={2}>
            <IconWithSpin
              size={64}
              icon={faSpinnerThird}
              color="white"
              secondaryColor={colors.blue[500]}
            />
          </Box>
        </Box>
      )}
      {!isAuthenticatingWithPassKey && (
        <>
          {Platform.OS !== 'web' && (
            <View>
              <ReactHookFormTextField
                name="appUrl"
                testID="appUrl"
                label={formatMessage({ id: 'label.appUrl' })}
                suffix=".bas.app"
                autoCapitalize="none"
                autoCorrect={false}
                light
              />
            </View>
          )}
          <View style={styles.paddingBetweenRows}>
            <ReactHookFormTextField
              testID="emailAddress"
              name="emailAddress"
              label={formatMessage({ id: 'label.emailAddress' })}
              keyboardType="email-address"
              textContentType="username"
              autoCapitalize="none"
              autoCorrect={false}
              light
            />
          </View>
          <View style={styles.paddingBetweenRows}>
            <ReactHookFormTextField
              name="password"
              testID="password"
              label={formatMessage({ id: 'label.password' })}
              secureTextEntry
              textContentType="password"
              autoCapitalize="none"
              autoCorrect={false}
              light
            />
          </View>
          {requireTwoFactorCode && (
            <View style={styles.paddingBetweenRows}>
              <ReactHookFormTextField
                name="twoFactorCode"
                label={formatMessage({
                  id: 'label.twoFactorCode',
                })}
                textContentType="oneTimeCode"
                autoCapitalize="none"
                keyboardType="number-pad"
                autoCorrect={false}
                light
              />
            </View>
          )}
        </>
      )}
      <View style={styles.formEndContainer}>
        <Button
          variant="text"
          onPress={() => router.navigate('/forgot-password')}
        >
          <View style={styles.forgotPasswordContainer}>
            <Typography style={styles.forgotPasswordText}>
              <FormattedMessage id="label.forgotPassword" />
            </Typography>
            <Icon
              overrideColor={colors.lila[200]}
              style={styles.forgotPasswordIcon}
              size={12}
              icon={faArrowRight}
            />
          </View>
        </Button>
        <View style={styles.loginButtonContainer}>
          {!isAuthenticatingWithPassKey && (
            <Button
              marginBottom={1}
              onPress={onSubmit}
              loading={isSubmitting || isAuthenticatingWithPassKey}
              testID="loginButton"
            >
              <FormattedMessage id="button.login" />
            </Button>
          )}
          {(isSupported || Platform.OS === 'web') && (
            <Button
              onPress={() => onLoginWithPassKey(appUrl)}
              loading={isAuthenticatingWithPassKey}
              variant="outlined"
              buttonColor="warning"
              icon={faScannerKeyboard}
            >
              <FormattedMessage id="label.passkey" />
            </Button>
          )}
        </View>
      </View>
    </Box>
  );
};

export default LoginForm;
