import { ReactElement } from 'react';
import { Box, Typography } from '@bas/ui/native/base';
import { ContactInformation } from '@bas/value-objects';
import { RenderEmailAddress, RenderPhoneNumber } from '@bas/ui/native/atoms';
import styles from './styles';

export type RenderContactInformationProps = {
  contactInformation: ContactInformation;
  withoutLinks?: boolean;
};

const RenderContactInformation = ({
  contactInformation,
  withoutLinks,
}: RenderContactInformationProps): ReactElement => (
  <Box flexDirection="column">
    {contactInformation.phoneNumber && (
      <Typography style={styles.row}>
        <RenderPhoneNumber
          withoutLinks={withoutLinks}
          phoneNumber={contactInformation.phoneNumber || ''}
        />
      </Typography>
    )}
    {contactInformation.mobileNumber && (
      <Typography style={styles.row}>
        <RenderPhoneNumber
          withoutLinks={withoutLinks}
          phoneNumber={contactInformation.mobileNumber || ''}
        />
      </Typography>
    )}
    {contactInformation.emailAddress && (
      <Typography style={styles.row}>
        <RenderEmailAddress
          withoutLinks={withoutLinks}
          emailAddress={contactInformation.emailAddress || ''}
        />
      </Typography>
    )}
  </Box>
);

export default RenderContactInformation;
