import { Discount, Money, Uuid } from '@bas/value-objects';
import { InvoiceLineInputType } from './invoiceLineInputType';
import { QuoteLineInputType } from './quoteLineInputType';

export type FinancialDocumentLinesEditorInputType = {
  quote?: {
    templateId?: Uuid | null;
    shouldChargeVat: boolean;
    includingVat: boolean;
    lines: QuoteLineInputType[];
    totalIncludingVat: Money;
    ignoreZeroQuantityLines: boolean;
    discount?: Discount;
  };
  invoice?: {
    templateId?: Uuid | null;
    shouldChargeVat: boolean;
    includingVat: boolean;
    ignoreZeroQuantityLines: boolean;
    lines: InvoiceLineInputType[];
    totalIncludingVat: Money;
    discount?: Discount;
  };
};
