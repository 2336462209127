import type { IntegrationComponent } from '../types';
import { isTenantBillableComponent } from './TenantBillableComponent';

export function isIntegrationComponent(
  component: unknown,
): component is IntegrationComponent {
  return (
    typeof component === 'object' &&
    isTenantBillableComponent(component) &&
    component.componentType === 'integration'
  );
}
