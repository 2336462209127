import { usePackagesRequest } from '@bas/project-domain/requests';
import { useTenantStore } from '@bas/shared/state';
import { PricingTypeFormStepInputType } from '@bas/taxation-tool-domain/input-types';
import { useIdentitiesRequest } from '@bas/tenant-domain/requests';
import { Box, Typography } from '@bas/ui/native/base';
import {
  ReactHookFormDropdown,
  ReactHookFormSelectButtons,
} from '@bas/ui/native/molecules';
import { PricingType, ProjectType } from '@bas/value-objects';
import * as React from 'react';
import { ReactElement, useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';

const MobileTaxationPricingTypeForm = (): ReactElement => {
  const tenant = useTenantStore((state) => state.tenant);
  const { data: packagesData, isPending: isLoadingPackages } =
    usePackagesRequest({ perPage: 9999 });
  const { data: identitiesData, isPending: isLoadingIdentities } =
    useIdentitiesRequest({}, { enabled: tenant?.useIdentities || false });

  const { setValue } = useFormContext<PricingTypeFormStepInputType>();
  const [pricingType, identityId, packageId] = useWatch<
    PricingTypeFormStepInputType,
    ['pricingType', 'identityId', 'packageId']
  >({
    name: ['pricingType', 'identityId', 'packageId'],
  });
  const { formatMessage } = useIntl();

  const packages = useMemo(
    () =>
      (packagesData?.data.member || []).filter(
        (p) =>
          p.pricingType === pricingType &&
          p.projectType === ProjectType.MOVING_JOB &&
          (!tenant?.useIdentities || p.identityId === identityId),
      ),
    [identityId, packagesData?.data, pricingType, tenant?.useIdentities],
  );

  useEffect(() => {
    if (!packageId) {
      return;
    }

    const foundPackage = packages.find((p) => p.packageId === packageId);
    if ((packagesData?.data?.member?.length || 0) > 0 && !foundPackage) {
      setValue('packageId', '');
    } else if (foundPackage) {
      setValue('ignoreZeroQuantityLines', foundPackage.ignoreZeroQuantityLines);
      setValue('billingMoments', foundPackage.billingMoments);
    }
  }, [packages, packageId, setValue, packagesData?.data]);

  return (
    <Box style={{ marginTop: -24 }}>
      <Box pt={3}>
        <ReactHookFormSelectButtons
          name="pricingType"
          label={formatMessage({
            id: 'label.pricingType',
          })}
          options={Object.values(PricingType)
            .filter((type) => type !== '')
            .map((option) => ({
              value: option,
              label: (
                <Typography fontWeight={500}>
                  {formatMessage({
                    id: `pricingTypes.${option || 'unknown'}`,
                  })}
                </Typography>
              ),
            }))}
        />
      </Box>
      {tenant?.useIdentities && !isLoadingIdentities && (
        <Box pt={3}>
          <ReactHookFormDropdown
            name="identityId"
            label={formatMessage({ id: 'label.identityId' })}
            items={(identitiesData?.data.member || []).map((identity) => ({
              id: identity.identityId,
              label: identity.companyInformation.companyName,
            }))}
          />
        </Box>
      )}
      {!isLoadingPackages && (
        <Box pt={3}>
          <ReactHookFormDropdown
            name="packageId"
            label={formatMessage({ id: 'label.packageId' })}
            disabled={
              isLoadingPackages ||
              !pricingType ||
              (tenant?.useIdentities && !identityId)
            }
            items={packages.map((p) => ({
              id: p.packageId,
              label: p.packageName,
            }))}
          />
        </Box>
      )}
    </Box>
  );
};

export default MobileTaxationPricingTypeForm;
