import { WorkedHoursSpecification } from '../types';

export const WorkedHoursSpecificationDefaultValues: WorkedHoursSpecification = {
  workedHours: 0,
  workedHoursIncludingBreakTime: 0,
  travelTime: 0,
  breakTime: 0,
};

export const mergeWorkedHoursSpecifications = (
  workedHoursSpecifications: WorkedHoursSpecification,
  otherWorkedHoursSpecification: WorkedHoursSpecification,
): WorkedHoursSpecification => ({
  workedHours:
    workedHoursSpecifications.workedHours +
    otherWorkedHoursSpecification.workedHours,
  workedHoursIncludingBreakTime:
    workedHoursSpecifications.workedHoursIncludingBreakTime +
    otherWorkedHoursSpecification.workedHoursIncludingBreakTime,
  travelTime:
    workedHoursSpecifications.travelTime +
    otherWorkedHoursSpecification.travelTime,
  breakTime:
    workedHoursSpecifications.breakTime +
    otherWorkedHoursSpecification.breakTime,
});

export const mergeMultipleWorkedHoursSpecifications = (
  workedHoursSpecifications: WorkedHoursSpecification[],
): WorkedHoursSpecification => {
  let result = { ...WorkedHoursSpecificationDefaultValues };
  workedHoursSpecifications.forEach((workedHoursSpecification) => {
    result = mergeWorkedHoursSpecifications(result, workedHoursSpecification);
  });

  return result;
};
