import { isWeb } from '@bas/shared/utils';
import { colors } from '@bas/theme';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { StyleProp, TextInput, ViewStyle } from 'react-native';

type NativeTextFieldCalculatorProps = {
  light?: boolean;
  disabled?: boolean;
  error?: boolean;
  hasValue?: boolean;
  inputRef?: MutableRefObject<TextInput | null>;
  variant?: 'outlined' | 'filled' | 'standard';
};

export const useNativeTextFieldCalculator = ({
  disabled,
  error,
  inputRef,
  light,
  hasValue,
  variant = 'outlined',
}: NativeTextFieldCalculatorProps) => {
  const ref = useRef<TextInput>(null);
  const [isFocused, setIsFocused] = useState(false);

  const handleFocusChange = useCallback(() => {
    setIsFocused(ref.current?.isFocused() || false);
  }, [ref]);

  useEffect(() => {
    if (ref.current && inputRef) {
      // eslint-disable-next-line no-param-reassign
      inputRef.current = ref.current;
    }
  }, [inputRef, ref]);

  const {
    labelColor,
    borderColor,
    activeBorderColor,
    disabledBorderColor,
    textColor,
  } = useMemo(() => {
    let base = {};
    if (error) {
      base = {
        labelColor: colors.red[700],
        borderColor: colors.red[700],
      };
    }

    if (isFocused) {
      base = {
        labelColor: colors.blue[500],
        borderColor: colors.blue[500],
      };
    }

    if (light) {
      return {
        labelColor: disabled ? colors.lila[600] : colors.lila[200],
        textColor: disabled ? colors.lila[600] : colors.lila[200],
        borderColor: disabled ? colors.lila[600] : colors.lila[200],
        activeBorderColor: colors.blue[500],
        errorColor: colors.red[700],
        placeholderTextColor: colors.lila[700],
        disabledBorderColor: colors.lila[800],
        ...base,
      };
    }

    const valueTextColor = hasValue ? colors.lila[700] : colors.lila[800];
    return {
      labelColor: disabled ? colors.lila[400] : valueTextColor,
      textColor: disabled ? colors.lila[400] : colors.lila[800],
      borderColor: disabled ? colors.lila[400] : colors.lila[800],
      activeBorderColor: colors.blue[500],
      errorColor: colors.red[700],
      placeholderTextColor: colors.lila[700],
      disabledBorderColor: colors.lila[200],
      ...base,
    };
  }, [isFocused, error, light, hasValue, disabled]);

  const fieldStyle = useMemo((): StyleProp<ViewStyle> => {
    if (variant === 'filled') {
      return {
        borderColor: disabled ? disabledBorderColor : colors.lila[400],
        borderWidth: 1,
        borderRadius: 5,
        height: 44,
        overflow: 'hidden',
        backgroundColor: disabled ? colors.lila[600] : '#fff',
        paddingHorizontal: 11,
      };
    }
    return {
      borderColor: disabled ? disabledBorderColor : borderColor,
      borderBottomWidth: 1,
      paddingBottom: 7,
      minHeight: isWeb ? 36 : undefined,
      height: 'auto',
    };
  }, [borderColor, disabled, disabledBorderColor, variant]);

  const validationMessageStyle = useMemo(
    () => ({
      color: error ? colors.red[700] : colors.lila[200],
      fontFamily: 'DM_Sans_400_normal',
      fontSize: 14,
      marginTop: 4,
    }),
    [error],
  );

  return {
    ref,
    handleFocusChange,
    fieldStyle,
    validationMessageStyle,
    borderColor,
    activeBorderColor,
    labelColor,
    textColor,
  };
};
