import { MaterialType } from '@bas/planning-domain/models';
import { EmployeeProjectRole } from '@bas/value-objects';

export const calculateUnusedProjectRoles = (
  a: EmployeeProjectRole[],
  b: EmployeeProjectRole[],
): EmployeeProjectRole[] => {
  const bMap = b.reduce(
    (acc, v) => acc.set(v, (acc.get(v) || 0) + 1),
    new Map<EmployeeProjectRole, number>(),
  );
  return a.filter((v) => {
    const count = bMap.get(v) || 0;
    if (count > 0) {
      bMap.set(v, count - 1);
      return false;
    }
    return true;
  });
};

export const calculateUnusedMaterials = (
  a: MaterialType[],
  b: MaterialType[],
): MaterialType[] => {
  const bMap = b.reduce(
    (acc, v) => acc.set(v, (acc.get(v) || 0) + 1),
    new Map<MaterialType, number>(),
  );
  return a.filter((v) => {
    const count = bMap.get(v) || 0;
    if (count > 0) {
      bMap.set(v, count - 1);
      return false;
    }
    return true;
  });
};
