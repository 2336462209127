import { FinancialDocumentLinesEditorInputType } from '@bas/financial-domain/input-types';
import { Skeleton } from '@bas/ui/native/atoms';
import { Box, Typography } from '@bas/ui/native/base';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { View } from 'react-native';
import {
  DragEndParams,
  NestableDraggableFlatList,
  RenderItemParams,
} from 'react-native-draggable-flatlist';
import { v7 } from 'uuid';
import { FinancialDocumentLinesEditorLineRow } from '../FinancialDocumentLinesEditorLineRow';

const Header = ({ light }: { light?: boolean }) => (
  <Box flexDirection="row">
    <Box width={40} />
    <Box mr={2} width="20%" minWidth={175} maxWidth={175} flex={1}>
      <Typography variant="subtitle1" color={light ? 'white' : undefined}>
        <FormattedMessage id="label.turnoverGroup" />
      </Typography>
    </Box>
    <Box mr={2} minWidth={200} flex={1}>
      <Typography variant="subtitle1" color={light ? 'white' : undefined}>
        <FormattedMessage id="label.description" />
      </Typography>
    </Box>
    <Box mr={2} width={87.75}>
      <Typography variant="subtitle1" color={light ? 'white' : undefined}>
        <FormattedMessage id="label.quantity" />
      </Typography>
    </Box>
    <Box mr={2} width={82.75}>
      <Typography variant="subtitle1" color={light ? 'white' : undefined}>
        <FormattedMessage id="label.vatCodeId" />
      </Typography>
    </Box>
    <Box mr={2} width={137.75}>
      <Typography variant="subtitle1" color={light ? 'white' : undefined}>
        <FormattedMessage id="label.pricePerUnit" />
      </Typography>
    </Box>
    <Box width={84} />
  </Box>
);

const LoadingRow = () => <Skeleton height={60} width={500} />;

const ActiveFinancialDocumentLinesEditorLineRow = ({
  formIndex,
  documentType,
  light,
  drag,
  isActive,
}: {
  formIndex: number;
  documentType: 'quote' | 'invoice';
  light?: boolean;
  drag: () => void;
  isActive: boolean;
}) => {
  const [loaded, setLoaded] = useState(false);
  const isCreated = useWatch<FinancialDocumentLinesEditorInputType>({
    name: `${documentType}.lines.${formIndex}.created`,
  });
  const lineId = useWatch<FinancialDocumentLinesEditorInputType>({
    name: `${documentType}.lines.${formIndex}.lineId`,
  }) as string;

  const { setValue } = useFormContext<FinancialDocumentLinesEditorInputType>();
  const onRemove = useCallback(() => {
    setValue(`${documentType}.lines.${formIndex}.created`, false);
  }, [documentType, formIndex, setValue]);

  if (!isCreated) {
    return null;
  }

  if (!loaded) {
    return (
      <View onLayout={() => setLoaded(true)}>
        <LoadingRow />
      </View>
    );
  }

  return (
    <FinancialDocumentLinesEditorLineRow
      key={lineId}
      index={formIndex}
      allowRemove
      onRemove={onRemove}
      documentType={documentType}
      light={light}
      onStartDrag={drag}
      disableDrag={isActive}
    />
  );
};

const mappedLines = new Array(100).fill(null).map((_, index) => ({
  id: v7(),
  formIndex: index,
}));

const FinancialDocumentLinesEditorLinesList = ({
  onSorting,
  light,
  documentType,
}: {
  documentType: 'quote' | 'invoice';
  onSorting: (params: { oldIndex: number; newIndex: number }) => void;
  light?: boolean;
}) => {
  const handleRender = useCallback(
    ({
      item,
      drag,
      isActive,
    }: RenderItemParams<{
      id: string;
      formIndex: number;
    }>) => (
      <ActiveFinancialDocumentLinesEditorLineRow
        formIndex={item.formIndex}
        documentType={documentType}
        drag={drag}
        isActive={isActive}
        light={light}
      />
    ),
    [documentType, light],
  );

  const handleDragEnd = useCallback(
    ({
      from,
      to,
    }: DragEndParams<{
      id: string;
      formIndex: number;
    }>) => {
      onSorting({ oldIndex: from, newIndex: to });
    },
    [onSorting],
  );

  return (
    <NestableDraggableFlatList
      data={mappedLines}
      keyExtractor={({ id }) => id}
      onDragEnd={handleDragEnd}
      ListHeaderComponent={Header}
      renderItem={handleRender}
      initialNumToRender={1}
      maxToRenderPerBatch={2}
    />
  );
};

export default FinancialDocumentLinesEditorLinesList;
