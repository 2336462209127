import { AddressInputType } from '@bas/shared/input-types';
import {
  Country,
  EmailAddress,
  ErrorResponse,
  PhoneNumber,
  Uuid,
} from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ChangeSupplierMutationProps = {
  supplierId: Uuid;
  name: string;
  website?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName: string;
  phoneNumber?: PhoneNumber | null;
  mobileNumber?: PhoneNumber | null;
  emailAddress?: EmailAddress | null;
  address: AddressInputType;
  region: Country;
};

export const ChangeSupplierMutation = async ({
  supplierId,
  ...values
}: ChangeSupplierMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/inventory/supplier/${supplierId}`, {
    supplierId,
    ...values,
  });

export const useChangeSupplierMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeSupplierMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ChangeSupplierMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeSupplierMutationProps
  >({
    mutationFn: ChangeSupplierMutation,
    throwOnError: false,
    ...options,
  });
