import * as Yup from 'yup';
import {
  EmployeeContractInputType,
  EmployeeContractInputTypeValidatorBuilder,
} from './employeeContractInputType';

export interface EmployeeContractsInputType {
  contracts: EmployeeContractInputType[];
}

export const EmployeeContractsInputTypeDefaultValues =
  (): EmployeeContractsInputType => ({
    contracts: [],
  });

export const EmployeeContractsInputTypeValidatorBuilder =
  (): Yup.ObjectSchema<EmployeeContractsInputType> =>
    Yup.object({
      contracts: Yup.array()
        .of(EmployeeContractInputTypeValidatorBuilder())
        .required(),
    });
