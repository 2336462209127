import { Quote } from '@bas/financial-domain/models';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type GenerateQuoteByProjectMutationProps = {
  projectId: Uuid;
};

export const GenerateQuoteByProjectMutation = async ({
  projectId,
  ...values
}: GenerateQuoteByProjectMutationProps): Promise<AxiosResponse<Quote>> =>
  axios.put(`api/{tenantId}/projects/${projectId}/generate-quote`, {
    projectId,
    ...values,
  });

export const useGenerateQuoteByProjectMutation = (
  options: UseMutationOptions<
    AxiosResponse<Quote>,
    AxiosError<ErrorResponse>,
    GenerateQuoteByProjectMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse<Quote>,
  AxiosError<ErrorResponse>,
  GenerateQuoteByProjectMutationProps
> =>
  useMutation<
    AxiosResponse<Quote>,
    AxiosError<ErrorResponse>,
    GenerateQuoteByProjectMutationProps
  >({
    mutationFn: GenerateQuoteByProjectMutation,
    throwOnError: false,
    ...options,
  });
