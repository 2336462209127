import { MediaObject } from '@bas/media-domain/models';
import { Money, Uuid } from '@bas/value-objects';
import * as Yup from 'yup';

export type AddDeclarationInputType = {
  amount: Money;
  receipt?: MediaObject;
  employeeId: Uuid;
  categoryId: Uuid;
  description: string;
  declarationDate?: Date | null;
  eventId?: Uuid;
  project?: {
    projectId?: Uuid | null;
  } | null;
  relation?: {
    relationId?: Uuid | null;
  } | null;
};

export const addDeclarationInputTypeDefaultValues =
  (): AddDeclarationInputType => ({
    amount: {
      amount: 0,
      currency: 'EUR',
    },
    employeeId: '',
    receipt: undefined,
    categoryId: '',
    description: '',
    declarationDate: null,
    eventId: '',
    project: undefined,
    relation: undefined,
  });

export const addDeclarationInputTypeValidationBuilder =
  (): Yup.ObjectSchema<AddDeclarationInputType> =>
    Yup.object({
      description: Yup.string().required().label('label.description'),
      categoryId: Yup.string().required().label('label.category'),
      employeeId: Yup.string().required().label('label.employee'),
      declarationDate: Yup.date().required().label('label.declarationDate'),
      amount: Yup.object({
        amount: Yup.number()
          .transform((value, original) =>
            original === '' || original === null ? null : value,
          )
          .required()
          .label('label.amount'),
        currency: Yup.string().required(),
      }).required(),
      eventId: Yup.string().optional().label('label.eventId'),
      project: Yup.object({
        projectId: Yup.string().optional().label('label.project').nullable(),
      }).nullable(),
      relation: Yup.object({
        relationId: Yup.string().optional().label('label.relation').nullable(),
      }).nullable(),
      receipt: Yup.object({
        mediaObjectId: Yup.string().nullable().label('label.receipt'),
      }) as Yup.ObjectSchema<MediaObject>,
    });
