import { MessageType } from '@bas/communication-domain/models';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type SendWhatsAppMessageTemplateToConversationMutationProps = {
  conversationId: Uuid;
  employeeId: Uuid;
  messageId: Uuid;
  content: string;
  templateName: string;
  language: string;
  componentVariables: { [key: string]: { [key: string]: string } };
};

export const SendWhatsAppMessageTemplateToConversationMutation = async ({
  conversationId,
  employeeId,
  messageId,
  ...params
}: SendWhatsAppMessageTemplateToConversationMutationProps): Promise<AxiosResponse> =>
  axios.post(
    `api/{tenantId}/chat/conversations/${conversationId}/messages/send-whatsapp-message-template`,
    {
      conversationId,
      messageId,
      sender: { employeeId },
      messageMetadata: { messageType: MessageType.TEXT_MESSAGE },
      ...params,
    },
    { params },
  );

export const useSendWhatsAppMessageTemplateToConversationMutation =
  (): UseMutationResult<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    SendWhatsAppMessageTemplateToConversationMutationProps
  > =>
    useMutation<
      AxiosResponse,
      AxiosError<ErrorResponse>,
      SendWhatsAppMessageTemplateToConversationMutationProps
    >({
      mutationFn: SendWhatsAppMessageTemplateToConversationMutation,
      throwOnError: false,
    });
