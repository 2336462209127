import { isPrivateRelation, Relation } from '@bas/crm-domain/models';
import {
  CrmAddressForm,
  CrmContactPersonForm,
} from '@bas/crm-domain/native/organisms';
import { addressToValue } from '@bas/shared/utils';
import { Box } from '@bas/ui/native/base';
import { ReactHookFormDropdown } from '@bas/ui/native/molecules';
import { Uuid } from '@bas/value-objects';
import * as React from 'react';
import { ReactElement, ReactNode, useCallback } from 'react';
import { useIntl } from 'react-intl';

export type FinancialDocumentCustomerFormPartProps = {
  relation?: Relation;
  documentType: 'quote' | 'invoice';
  selectedContactPersonId?: Uuid | 'new' | 'private';
  selectedAddressId: Uuid | 'new';
  prefix?: string;
  children?: ReactNode;
  disableRelation?: boolean;
  light?: boolean;
};

const FinancialDocumentCustomerFormPart = ({
  relation,
  documentType,
  selectedContactPersonId,
  selectedAddressId,
  prefix,
  children,
  disableRelation,
  light,
}: FinancialDocumentCustomerFormPartProps): ReactElement => {
  const getPrefixedName = useCallback(
    (name: string) => {
      if (prefix) {
        return `${prefix}.${name}`;
      }

      return name;
    },
    [prefix],
  );
  const { formatMessage } = useIntl();

  return (
    <Box paddingHorizontal={20}>
      <Box>
        <ReactHookFormDropdown
          name={getPrefixedName('contactPersonId')}
          label={formatMessage({
            id: `financial.financialDocument.${documentType}ContactPerson`,
          })}
          light={light}
          items={[
            {
              id: 'new',
              label: formatMessage({
                id: 'financial.financialDocument.newContactPerson',
              }),
            },
            ...(isPrivateRelation(relation)
              ? [{ id: 'relation', label: relation.name }]
              : []),
            ...(relation?.contactPersons || []).map(
              ({
                contactPersonId,
                contactPersonType,
                personalInformation: { personName },
              }) => ({
                id: contactPersonId,
                label: formatMessage({
                  id: `relation.contactPersonType.${contactPersonType}`,
                }),
              }),
            ),
          ]}
        />
        {selectedContactPersonId === 'new' && (
          <Box>
            <CrmContactPersonForm
              disabledContactPersonTypes={
                relation?.contactPersons.map(
                  ({ contactPersonType }) => contactPersonType,
                ) || []
              }
              prefix={getPrefixedName('contactPerson')}
              light={light}
            />
          </Box>
        )}
      </Box>
      <Box style={{ paddingTop: 24 }}>
        <ReactHookFormDropdown
          name={getPrefixedName('addressId')}
          label={formatMessage({
            id: `financial.financialDocument.${documentType}Address`,
          })}
          light={light}
          items={[
            {
              id: 'new',
              label: formatMessage({
                id: 'financial.financialDocument.newAddress',
              }),
            },
            ...(relation?.addresses || []).map(({ addressId, ...address }) => ({
              id: addressId,
              label: addressToValue(address),
            })),
          ]}
        />
        {selectedAddressId === 'new' && (
          <Box>
            <CrmAddressForm
              prefix={getPrefixedName('address')}
              disabledAddressTypes={
                relation?.addresses.map(({ addressType }) => addressType) || []
              }
              light={light}
            />
          </Box>
        )}
      </Box>

      {children}
    </Box>
  );
};

export default FinancialDocumentCustomerFormPart;
