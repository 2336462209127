import { isAbstractBillingMomentLine } from './AbstractBillingMomentLine';
import { PercentageAmountBillingMomentLine } from '../types';

export function isPercentageAmountBillingMomentLine(
  object: unknown,
): object is PercentageAmountBillingMomentLine {
  return (
    !!object &&
    isAbstractBillingMomentLine(object) &&
    object['@type'] === 'PercentageAmountBillingMomentLine'
  );
}
