import { colors } from '@bas/theme';
import { Box, Typography } from '@bas/ui/native/base';
import { FormattedMessage } from 'react-intl';

export type ShowNotesStepProps = {
  notes?: string;
  projectNotes?: string;
};

const ShowNotesStep = ({ notes, projectNotes }: ShowNotesStepProps) => (
  <Box
    paddingHorizontal={{
      tablet: 50,
      phone: 20,
    }}
  >
    <Box>
      <Typography overrideColor={colors.lila[600]} variant="subtitle2">
        <FormattedMessage id="label.eventNotes" />
      </Typography>
      <Typography overrideColor={colors.white}>
        {notes || <FormattedMessage id="label.noNotes" />}
      </Typography>
    </Box>
    <Box pt={3}>
      <Typography overrideColor={colors.lila[600]} variant="subtitle2">
        <FormattedMessage id="label.projectNotes" />
      </Typography>
      <Typography overrideColor={colors.white}>
        {projectNotes || <FormattedMessage id="label.noNotes" />}
      </Typography>
    </Box>
  </Box>
);

export default ShowNotesStep;
