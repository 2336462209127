import { PublicRoomType } from '@bas/shared/models';
import { RoomWizardInputType } from '@bas/taxation-tool-domain/input-types';
import {
  MovingJobTaxationRoom,
  MovingJobTaxationRoomInitialValues,
  MovingJobTaxationWizardInput,
} from '@bas/taxation-tool-domain/models';
import { MobileTaxationStepTemplate } from '@bas/taxation-tool-domain/native/templates';
import {
  usePublicRoomTypesRequest,
  usePublicServiceTypesRequest,
} from '@bas/tenant-domain/requests';
import { RenderTranslatedName, Skeleton } from '@bas/ui/native/atoms';
import { BottomSheetMethods, Box } from '@bas/ui/native/base';
import {
  BackendIcon,
  RectangularAddTile,
  RectangularIconTile,
} from '@bas/ui/native/molecules';
import { faQuestion } from '@fortawesome/pro-light-svg-icons/faQuestion';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { FlashList, ListRenderItemInfo } from '@shopify/flash-list';
import { useResponsiveProp } from '@shopify/restyle';
import { memo, ReactElement, useCallback, useMemo, useRef } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { v7 } from 'uuid';
import { AddExtraRoomBottomSheet } from '../AddExtraRoomBottomSheet';
import { FillingInRoomWizardBottomSheet } from '../FillingInRoomWizardBottomSheet';
import { movingJobFillingInRoomWizardSteps } from './steps';

const OpenRoomSpecificFlowsStep = (): ReactElement => {
  const addExtraRoomRef = useRef<BottomSheetModal & BottomSheetMethods>(null);
  const roomWizardRef = useRef<BottomSheetModal & BottomSheetMethods>(null);

  const { formatMessage } = useIntl();

  const { data: roomsData, isLoading } = usePublicRoomTypesRequest(undefined, {
    throwOnError: true,
  });
  const { data: servicesData } = usePublicServiceTypesRequest();

  const roomTypes = useMemo(() => roomsData?.data.member || [], [roomsData]);

  const {
    fields: rooms,
    append,
    update,
  } = useFieldArray<RoomWizardInputType, 'rooms'>({
    name: 'rooms',
  } as never);
  const services = useWatch<MovingJobTaxationWizardInput, 'services'>({
    name: 'services',
  });

  const { setValue } = useFormContext<RoomWizardInputType>();

  const intakeValueRooms = useWatch({ name: 'rooms' });
  const intakeRooms = useMemo(
    () =>
      rooms.map((room, index) => ({
        ...room,
        ...intakeValueRooms[index],
      })),
    [rooms, intakeValueRooms],
  );

  const handleAddOrSelectRoom = useCallback(
    (room: PublicRoomType) => {
      const foundUnselectedRoomIndex = intakeRooms.findIndex(
        (i) => !i.selected && i.roomTypeId === room.roomTypeId,
      );

      if (foundUnselectedRoomIndex !== -1) {
        setValue(`rooms.${foundUnselectedRoomIndex}.selected`, true);
        addExtraRoomRef?.current?.dismiss();

        return;
      }

      const roomToAdd: MovingJobTaxationRoom = {
        roomId: v7(),
        roomTypeId: room.roomTypeId,
        ...MovingJobTaxationRoomInitialValues,
      };

      append(roomToAdd);
      addExtraRoomRef?.current?.dismiss();
    },
    [intakeRooms, append, setValue],
  );

  const handleFinishRoom = useCallback(
    async (intakeRoom: MovingJobTaxationRoom) => {
      const index = rooms.findIndex(
        ({ roomId }) => intakeRoom.roomId === roomId,
      );

      if (index === -1) {
        return;
      }

      update(index, { ...intakeRoom, finished: true });
      roomWizardRef?.current?.dismiss();
    },
    [rooms, update],
  );

  const handleClose = useCallback(() => {
    roomWizardRef?.current?.dismiss();
  }, []);

  const usedServices = useMemo(
    () =>
      (servicesData?.data.member || [])
        .filter(({ serviceTypeId }) =>
          services.find((s) => s.serviceTypeId === serviceTypeId && s.selected),
        )
        .map(({ internalType }) => internalType),
    [services, servicesData],
  );

  const selectedIntakeRooms = useMemo(
    () => [...intakeRooms.filter(({ selected }) => selected), 'addExtraRoom'],
    [intakeRooms],
  );

  const handleRenderItem = useCallback(
    ({
      item: intakeRoom,
    }: ListRenderItemInfo<MovingJobTaxationRoom | 'addExtraRoom'>) => {
      if (intakeRoom === 'addExtraRoom') {
        return (
          <Box mt={1}>
            <RectangularAddTile
              onPress={() => addExtraRoomRef?.current?.present()}
              label={formatMessage({
                id: 'mobileApp.intake.movingJob.addRoom',
              })}
            />
          </Box>
        );
      }

      const room = roomTypes.find(
        ({ roomTypeId }) => roomTypeId === intakeRoom.roomTypeId,
      );

      if (!room) {
        return null;
      }

      return (
        <Box mt={1}>
          <RectangularIconTile
            onPress={() => {
              roomWizardRef?.current?.present({
                intakeRoom: { ...intakeRoom },
                room,
                usedServices,
              });
            }}
            label={
              intakeRoom.name || (
                <RenderTranslatedName
                  fontWeight={700}
                  translations={room?.translatedNames || []}
                  fallback={room?.roomTypeName || ''}
                />
              )
            }
            finished={intakeRoom.finished}
            icon={
              room?.icon ? (
                <BackendIcon icon={room.icon} size={30} />
              ) : (
                faQuestion
              )
            }
          />
        </Box>
      );
    },
    [formatMessage, roomTypes, usedServices],
  );

  const paddingHorizontal = useResponsiveProp({
    largerTablet: 80,
    tablet: 50,
    phone: 20,
  });

  const steps = useMemo(
    () => movingJobFillingInRoomWizardSteps(usedServices || []),
    [usedServices],
  );

  const handleRenderLoadingItem = useCallback(
    () => (
      <Box
        style={{
          maxHeight: 64,
          width: '100%',
          alignSelf: 'flex-start',
          paddingTop: 8,
        }}
      >
        <Skeleton
          style={{
            backgroundColor: 'white',
            borderRadius: 5,
            height: '100%',
            width: '100%',
          }}
        />
      </Box>
    ),
    [],
  );

  const loadingItems = useMemo(() => new Array(4).fill(''), []);

  return (
    <MobileTaxationStepTemplate
      primary={
        <FormattedMessage id="mobileApp.intake.movingJob.whatNeedsToBeMoved" />
      }
      secondary={<FormattedMessage id="label.rooms" />}
      disableScroll
      disableContentPadding
    >
      {isLoading ? (
        <FlashList
          renderItem={handleRenderLoadingItem}
          data={loadingItems}
          estimatedItemSize={74}
          contentContainerStyle={{
            paddingBottom: 24,
            paddingHorizontal,
          }}
        />
      ) : (
        <FlashList
          renderItem={handleRenderItem}
          data={selectedIntakeRooms}
          estimatedItemSize={74}
          contentContainerStyle={{
            paddingBottom: 24,
            paddingHorizontal,
          }}
          keyExtractor={(item) =>
            item === 'addExtraRoom' ? 'addExtraRoom' : item.roomId
          }
        />
      )}
      <FillingInRoomWizardBottomSheet
        onClose={handleClose}
        onFinishRoom={handleFinishRoom}
        bottomSheetRef={roomWizardRef}
        steps={steps}
      />
      <AddExtraRoomBottomSheet
        ref={addExtraRoomRef}
        rooms={roomTypes}
        onAddRoom={handleAddOrSelectRoom}
      />
    </MobileTaxationStepTemplate>
  );
};

const MemoComponent = memo(OpenRoomSpecificFlowsStep, () => true);

export default MemoComponent;
