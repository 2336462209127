import {
  ClaCompensationRuleType,
  ClaTravelCostCompensationRule,
} from '../types';
import { isClaCompensationRule } from './ClaCompensationRule';

export function isClaTravelCostCompensationRule(
  object: unknown,
): object is ClaTravelCostCompensationRule {
  return (
    isClaCompensationRule(object) &&
    object.ruleType === ClaCompensationRuleType.ClaTravelCost
  );
}
