import { MediaObject } from '@bas/media-domain/models';
import { MovingJob, MovingJobRoom } from '@bas/project-domain/models';
import { colors } from '@bas/theme';
import { RenderTranslatedName } from '@bas/ui/native/atoms';
import {
  BottomSheetMethods,
  Box,
  TouchableOpacity,
  Typography,
} from '@bas/ui/native/base';
import { BottomSheet, BottomSheetProps } from '@bas/ui/native/organisms';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { FlashList } from '@shopify/flash-list';
import * as WebBrowser from 'expo-web-browser';
import { ReactElement, RefObject, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Image } from 'react-native';

type MovingJobPhotosBottomSheetProps = Omit<
  BottomSheetProps,
  'snapPoints' | 'children'
> & {
  project: MovingJob;
  onClose: () => void;
  bottomSheetRef?: RefObject<BottomSheetModal & BottomSheetMethods>;
};

const MovingJobPhotosBottomSheet = ({
  project,
  onClose,
  bottomSheetRef,
  ...props
}: MovingJobPhotosBottomSheetProps): ReactElement | null => {
  const roomsWithPhotos = project.rooms.filter(
    (room) => room.photos.length > 0,
  );
  const [selectedRoom, setSelectedRoom] = useState<MovingJobRoom>(
    roomsWithPhotos[0],
  );
  const [selectedPhoto, setSelectedPhoto] = useState<MediaObject>(
    roomsWithPhotos[0]?.photos[0],
  );

  if (roomsWithPhotos.length === 0) {
    return null;
  }

  const renderPhotoItem = ({
    item,
  }: {
    item: MediaObject & { room: MovingJobRoom };
  }) => (
    <Box key={item.mediaObjectId} width={200} height={150} paddingRight={2}>
      <TouchableOpacity
        onPress={() => {
          setSelectedPhoto(item);
          setSelectedRoom(item.room);
        }}
      >
        <Image
          source={{ uri: item.contentUrl }}
          style={{ height: 150, width: '100%', borderRadius: 5 }}
          resizeMode="cover"
        />
      </TouchableOpacity>
    </Box>
  );

  const renderItem = ({ item }: { item: MovingJobRoom }) => (
    <Box key={item.roomId} height={200}>
      <Typography
        overrideColor={colors.white}
        textAlign="left"
        paddingBottom={2}
      >
        <RenderTranslatedName
          overrideColor={colors.white}
          fallback={item.roomType.roomTypeName}
          translations={item.roomType.translatedNames}
        />
        &nbsp;({item.photos.length})
      </Typography>
      <FlashList
        renderItem={renderPhotoItem}
        data={item.photos.map((photo) => ({ ...photo, room: item }))}
        horizontal
      />
    </Box>
  );

  return (
    <BottomSheet onDismiss={onClose} {...props} ref={bottomSheetRef}>
      <Box flex={1}>
        <Typography
          overrideColor={colors.white}
          variant="h4"
          fontWeight={700}
          textAlign="center"
          paddingBottom={2}
        >
          <FormattedMessage id="label.photos" />
        </Typography>
        <Typography
          overrideColor={colors.white}
          variant="h5"
          textAlign="center"
          paddingBottom={3}
        >
          {selectedRoom && (
            <RenderTranslatedName
              overrideColor={colors.white}
              fallback={selectedRoom.roomType.roomTypeName}
              translations={selectedRoom.roomType.translatedNames}
            />
          )}
        </Typography>
        <Box paddingBottom={2} maxHeight={350} height="30%" minHeight={150}>
          <TouchableOpacity
            style={{
              height: '100%',
              width: '100%',
            }}
            onPress={() => {
              WebBrowser.openBrowserAsync(selectedPhoto.contentUrl);
            }}
          >
            <Image
              source={{ uri: selectedPhoto.contentUrl }}
              style={{ height: '100%', width: '100%' }}
              resizeMode="contain"
            />
          </TouchableOpacity>
        </Box>
        <FlashList renderItem={renderItem} data={roomsWithPhotos} />
      </Box>
    </BottomSheet>
  );
};

export default MovingJobPhotosBottomSheet;
