import * as Yup from 'yup';
import { Uuid } from '@bas/value-objects';

export interface ThrowAwayInventoryInputType {
  eventDate?: Date | null;
  quantity: number;
  supplierId?: Uuid;
  customerId?: Uuid;
  projectId?: Uuid;
  planningEventId?: Uuid;
}

export const ThrowAwayInventoryInputTypeDefaultValues =
  (): ThrowAwayInventoryInputType => ({
    eventDate: null,
    quantity: 0,
    supplierId: '',
    customerId: '',
    projectId: '',
    planningEventId: '',
  });

export const ThrowAwayInventoryInputTypeValidationBuilder =
  (): Yup.ObjectSchema<ThrowAwayInventoryInputType> =>
    Yup.object({
      eventDate: Yup.date().nullable().required().label('label.date'),
      quantity: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .nullable()
        .min(0)
        .required()
        .label('label.quantity'),
      supplierId: Yup.string().label('label.supplier'),
      customerId: Yup.string().label('label.customer'),
      projectId: Yup.string().label('label.project'),
      planningEventId: Yup.string().label('label.event'),
    });
