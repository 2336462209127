import { useRelationByRelationIdRequestQuery } from '@bas/crm-domain/requests';
import { EventWithRelation, Vehicle } from '@bas/planning-domain/models';
import { useEmployeeStore } from '@bas/shared/state';
import { basParaph } from '@bas/svg-icons';
import { PublicTenant } from '@bas/tenant-domain/models';
import { colors } from '@bas/theme';
import { AddressBlock, MobileActionButton } from '@bas/ui/native/atoms';
import { RelationContactInformation } from '@bas/ui/native/molecules';
import { LoadingScreen } from '@bas/ui/native/templates';
import {
  EmployeeProjectRole,
  EventType,
  showEventLocation,
  showEventName,
} from '@bas/value-objects';
import dayjs from 'dayjs';
import { ReactElement, ReactNode, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { EventScreenTemplate } from '../EventScreenTemplate';

const eventsWithNormalNotes = [
  EventType.LENDING_STAFF_EVENT,
  EventType.OFFICE_WORK_EVENT,
  EventType.WAREHOUSE_WORK_EVENT,
  EventType.TRANSPORT_EVENT,
  EventType.LIFT_WORK_EVENT,
];

export type RelationEventScreenTemplateProps = {
  tenant: PublicTenant;
  event: EventWithRelation;
  materials: Vehicle[];
  showEmployeeDetails: boolean;
  onStartFinalizingEvent: () => void;
  onBack: () => void;
  children: ReactNode;
};

const RelationEventScreenTemplate = ({
  tenant,
  event,
  materials,
  showEmployeeDetails,
  onStartFinalizingEvent,
  onBack,
  children,
}: RelationEventScreenTemplateProps): ReactElement => {
  const employeeState = useEmployeeStore((state) => state.employee);

  const { isPending: isLoadingRelation, data: relationData } =
    useRelationByRelationIdRequestQuery({
      relationId: event.relationId,
    });
  const relation = relationData?.data;

  const hasForeman = useMemo(
    () =>
      event.employees.filter(
        ({ projectRole }) => projectRole === EmployeeProjectRole.FOREMAN,
      ).length > 0,
    [event.employees],
  );

  const isForeman = useMemo(
    () =>
      !!event.employees.find(
        ({ projectRole, employee: { employeeId } }) =>
          projectRole === EmployeeProjectRole.FOREMAN &&
          employeeId === employeeState?.employeeId,
      ),
    [event.employees, employeeState?.employeeId],
  );

  const eventActions = useMemo(() => {
    const finalizeEventAction = (
      <MobileActionButton
        icon={basParaph}
        onPress={onStartFinalizingEvent}
        disabled={event.finished || !dayjs(event.start).isSame(dayjs(), 'day')}
      >
        <FormattedMessage id="eventActions.finalizeEvent" />
      </MobileActionButton>
    );

    const actions: ReactElement[] = [];

    let showFinalize = false;

    if (
      event.eventType &&
      [
        EventType.INSPECTION_EVENT,
        EventType.MAINTENANCE_EVENT,
        EventType.MATERIAL_OTHER_ABSENCE_EVENT,
        EventType.CUSTOM_EVENT,
        EventType.LENDING_STAFF_EVENT,
        EventType.LIFT_WORK_EVENT,
        EventType.TRANSPORT_EVENT,
      ].includes(event.eventType)
    ) {
      showFinalize = true;
    }

    if (!showFinalize || (hasForeman && !isForeman)) {
      return actions;
    }

    return [...actions, finalizeEventAction];
  }, [
    event.eventType,
    event.finished,
    event.start,
    hasForeman,
    isForeman,
    onStartFinalizingEvent,
  ]);

  if (isLoadingRelation || !event || !relation) {
    return <LoadingScreen />;
  }

  return (
    <EventScreenTemplate
      eventId={event.eventId}
      allowEmployeeToSeeColleagueDetails={showEmployeeDetails}
      finished={event.finished}
      headerLabel={
        showEventName(event.eventType) ? (
          event.name.trim()
        ) : (
          <FormattedMessage
            id={`mobileApp.event.eventType.${event.eventType}`}
          />
        )
      }
      addresses={[]}
      location={event.location}
      actions={eventActions}
      subHeaderLabel={
        showEventLocation(event.eventType) &&
        event.location?.streetName &&
        event.location?.zipCode && (
          <AddressBlock
            color={colors.lila[600]}
            variant="single_line"
            address={event.location}
          />
        )
      }
      plannedMaterials={materials}
      team={event.employees}
      crews={event.crews}
      alert={
        event.notes && !eventsWithNormalNotes.includes(event.eventType)
          ? {
              severity: 'warning',
              children: event.notes,
            }
          : undefined
      }
      notes={
        eventsWithNormalNotes.includes(event.eventType)
          ? event.notes
          : undefined
      }
      eventType={event.eventType}
      onBack={onBack}
      country={tenant.address.country}
    >
      {relation && <RelationContactInformation relation={relation} />}
      {children}
    </EventScreenTemplate>
  );
};

export default RelationEventScreenTemplate;
