import { TextField } from '@bas/ui/native/atoms';
import { Box, TextFieldProps, TouchableOpacity } from '@bas/ui/native/base';
import dayjs, { Dayjs } from 'dayjs';
import { memo, ReactElement, useCallback, useMemo, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useIntl } from 'react-intl';
import { Modal } from 'react-native';
import Pickers from './Pickers';

export type DateTimeFieldProps = Omit<TextFieldProps, 'value' | 'onChange'> & {
  value?: Date | null;
  mode?: 'date' | 'time';
  onChange?: (value: Date) => void;
};

const DateTimeField = ({
  value,
  mode = 'date',
  onChange,
  ...props
}: DateTimeFieldProps): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const { formatDate } = useIntl();

  const displayValue = useMemo(() => {
    if (!value) {
      return '';
    }

    const dayjsValue = dayjs(value);

    if (mode === 'date') {
      return dayjsValue.format('L');
    }

    if (mode === 'time') {
      return dayjsValue.format('LT');
    }

    return dayjsValue.format('L');
  }, [mode, value]);

  const currentDate = useMemo(() => dayjs(value), [value]);
  const startingYear = useMemo(() => dayjs().year() - 100, []);

  const sectionItems = useMemo(
    () => ({
      hours: new Array(24).fill(0).map((_, i) => ({
        value: i,
        label: String(i),
      })),
      minutes: new Array(60).fill(0).map((_, i) => ({
        value: i,
        label: String(i),
      })),
      days: new Array(currentDate.daysInMonth()).fill(0).map((_, i) => ({
        value: i + 1,
        label: String(i + 1),
      })),
      months: new Array(12).fill(0).map((_, i) => ({
        value: i,
        label: formatDate(dayjs().month(i).toDate(), { month: 'long' }),
      })),
      years: new Array(200).fill(0).map((_, i) => ({
        value: startingYear + i,
      })),
    }),
    [currentDate, formatDate, startingYear],
  );

  const handleChange = useCallback(
    (val: Dayjs) => {
      onChange?.(val.toDate());
    },
    [onChange],
  );

  const pickers = useMemo(
    () => (
      <Pickers
        sectionItems={sectionItems}
        date={currentDate}
        onChange={handleChange}
        mode={mode}
        onClose={() => {
          setIsOpen(false);
        }}
      />
    ),
    [currentDate, handleChange, sectionItems, mode],
  );

  return (
    <>
      <Modal
        visible={isOpen}
        transparent
        animationType="none"
        supportedOrientations={[
          'portrait',
          'portrait-upside-down',
          'landscape',
          'landscape-left',
          'landscape-right',
        ]}
        onRequestClose={() => {
          setIsOpen(false);
        }}
      >
        <Box
          height="100%"
          width="100%"
          paddingHorizontal={20}
          flex={1}
          justifyContent="center"
          alignItems="center"
        >
          <Box
            height={300}
            alignItems="center"
            backgroundColor="white"
            borderRadius={20}
          >
            {pickers}
          </Box>
        </Box>
      </Modal>
      <TouchableOpacity onPress={() => setIsOpen(true)}>
        <TextField {...props} value={displayValue} pointerEvents="none" />
      </TouchableOpacity>
    </>
  );
};

const MemoComponent = memo(DateTimeField, (prev, next) => isEqual(prev, next));
export default MemoComponent;
