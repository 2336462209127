import {
  FinancialDocumentLinesEditorInputType,
  FinancialLineInputType,
  QuoteLineInputType,
} from '@bas/financial-domain/input-types';
import { QuoteLineType, VatCode } from '@bas/financial-domain/models';
import { useVatCodesRequest } from '@bas/financial-domain/requests';
import { Button } from '@bas/ui/native/atoms';
import { Box } from '@bas/ui/native/base';
import {
  ReactHookFormCheckbox,
  ReactHookFormSelectButtons,
} from '@bas/ui/native/molecules';
import { useTheme } from '@shopify/restyle';
import * as React from 'react';
import {
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import isEqual from 'react-fast-compare';
import { useFieldArray } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useWindowDimensions } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { LayoutChangeEvent } from 'react-native/Libraries/Types/CoreEventTypes';
import { v7 } from 'uuid';
import { FinancialDocumentLinesEditorLinesList } from '../FinancialDocumentLinesEditorLinesList';
import { FinancialDocumentTotals } from '../FinancialDocumentTotals';
import { MobileFinancialDocumentLinesEditor } from '../MobileFinancialDocumentLinesEditor';
import { MobileFinancialDocumentTotals } from '../MobileFinancialDocumentTotals';

export type FinancialDocumentLinesEditorProps = {
  forceMobile?: boolean;
  light?: boolean;
  documentType: 'quote' | 'invoice';
  inBottomSheet?: boolean;
};
const FinancialDocumentLinesEditor = ({
  forceMobile,
  documentType,
  light,
  inBottomSheet,
}: FinancialDocumentLinesEditorProps): ReactElement => {
  const { formatMessage } = useIntl();

  const theme = useTheme();
  const dimensions = useWindowDimensions();

  const isMobile = useMemo(() => {
    if (forceMobile) {
      return true;
    }

    return dimensions.width < theme.breakpoints.tablet.width;
  }, [dimensions.width, forceMobile, theme.breakpoints.tablet.width]);

  const { data: vatCodesData } = useVatCodesRequest(
    {
      perPage: 99999,
    },
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  );

  const vatCodes = useMemo(
    () => vatCodesData?.data?.member || [],
    [vatCodesData],
  );

  const defaultVatCode: VatCode = useMemo(
    () =>
      vatCodes.find((vatCode) => vatCode.percentage.percentage > 9) ||
      vatCodes[0],
    [vatCodes],
  );

  const [mappedLines, setMappedLines] = useState<
    {
      formIndex: number;
      id: string;
    }[]
  >([]);

  const {
    append,
    move,
    fields: lines,
  } = useFieldArray<FinancialDocumentLinesEditorInputType>({
    name: `${documentType}.lines`,
  } as never);

  const addLine = useCallback(() => {
    let newLine: FinancialLineInputType = {
      lineId: v7(),
      quantity: 1,
      description: '',
      pricePerUnit: {
        amount: 0,
        currency: 'EUR',
      },
      totalPrice: {
        amount: 0,
        currency: 'EUR',
      },
      vatCode: defaultVatCode
        ? { ...defaultVatCode }
        : {
            vatCodeId: '',
            vatCode: '',
          },
      vatPercentage: defaultVatCode
        ? { ...defaultVatCode.percentage }
        : {
            percentage: 0,
            activeFrom: new Date(),
            activeTo: new Date(),
          },
      created: true,
    };

    if (documentType === 'quote') {
      newLine = {
        ...newLine,
        hourlyRate: false,
        optional: false,
        lineType: QuoteLineType.NORMAL,
      } as QuoteLineInputType;
    }
    append(newLine);
  }, [append, documentType, defaultVatCode]);

  useEffect(() => {
    const newMappedLines = [
      ...lines.map((line, index) => ({ formIndex: index, id: line.id })),
    ];

    if (!isEqual(mappedLines, newMappedLines)) {
      setMappedLines(newMappedLines);
    }
  }, [lines, mappedLines]);

  const handleSorting = useCallback(
    ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
      move(oldIndex, newIndex);
    },
    [move],
  );

  const [parentWidth, setParentWidth] = useState(0);
  const [linesWidth, setLinesWidth] = useState(0);

  const handleLayoutParent = useCallback(
    (event: LayoutChangeEvent) => {
      setParentWidth(event.nativeEvent.layout.width);
    },
    [setParentWidth],
  );

  const handleLayoutLines = useCallback(
    (event: LayoutChangeEvent) => {
      setLinesWidth(event.nativeEvent.layout.width);
    },
    [setLinesWidth],
  );

  const linesContent = useMemo(
    () =>
      isMobile ? (
        <MobileFinancialDocumentLinesEditor
          documentType={documentType}
          light={light}
          onSorting={handleSorting}
        />
      ) : (
        <ScrollView horizontal enabled={linesWidth > parentWidth}>
          <Box minWidth={parentWidth} onLayout={handleLayoutLines}>
            <FinancialDocumentLinesEditorLinesList
              onSorting={handleSorting}
              light={light}
              documentType={documentType}
            />
          </Box>
        </ScrollView>
      ),
    [
      documentType,
      handleLayoutLines,
      handleSorting,
      isMobile,
      light,
      linesWidth,
      parentWidth,
    ],
  );

  return useMemo(
    () => (
      <Box pb={3}>
        <Box width="100%" flexDirection="row" flexWrap="wrap" gap={2}>
          <Box>
            <ReactHookFormSelectButtons
              name={`${documentType}.includingVat`}
              label={formatMessage({
                id: 'label.includingOrExcludingVat',
              })}
              light={light}
              options={[
                {
                  label: formatMessage({ id: 'label.includingVat' }),
                  value: true,
                },
                {
                  label: formatMessage({ id: 'label.excludingVat' }),
                  value: false,
                },
              ]}
            />
          </Box>
          <Box>
            <ReactHookFormCheckbox
              size={24}
              light={light || false}
              name={`${documentType}.ignoreZeroQuantityLines`}
              label={formatMessage({
                id: 'label.ignoreZeroQuantityLines',
              })}
            />
          </Box>
        </Box>
        <Box mt={3} onLayout={handleLayoutParent}>
          {linesContent}
        </Box>
        <Box
          flexDirection="row"
          alignItems="center"
          pt="invoiceLines"
          pb="invoiceLines"
        >
          <Box width="100%" style={{ paddingLeft: 20 }}>
            <Button onPress={addLine} variant="outlined">
              <FormattedMessage id="button.addLine" />
            </Button>
          </Box>
        </Box>

        {isMobile ? (
          <MobileFinancialDocumentTotals
            light={light}
            documentType={documentType}
          />
        ) : (
          <FinancialDocumentTotals documentType={documentType} light={light} />
        )}
      </Box>
    ),
    [
      documentType,
      formatMessage,
      light,
      handleLayoutParent,
      linesContent,
      addLine,
      isMobile,
    ],
  );
};

const MemoComponent = memo(FinancialDocumentLinesEditor, (prev, next) =>
  isEqual(prev, next),
);
export default MemoComponent;
