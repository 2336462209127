import { StyleSheet } from 'react-native';
import { colors } from '@bas/theme';

export default StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
  },
  flex: {
    flex: 1,
  },
  room: {
    backgroundColor: colors.lila[800],
  },
  warehouseButtons: {
    height: 80,
    marginBottom: 32,
  },
  separator: {
    paddingTop: 21,
  },
  warehouseButtonText: {
    flexDirection: 'column',
  },
  label: {
    flexGrow: 1,
  },
  inputContainer: {
    width: 65,
    marginRight: 16,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    paddingTop: 16,
  },
  input: {
    maxWidth: 65,
    textAlign: 'center',
  },
});
