import * as Yup from 'yup';

export interface ScheduleInvoiceInputType {
  scheduledAt?: Date | null;
  expiresAt?: Date | null;
  exactOnlineOverrideReportingPeriod?: boolean;
  reportingYear?: number;
  reportingPeriod?: number;
}

export const ScheduleInvoiceInputTypeDefaultValues =
  (): ScheduleInvoiceInputType => ({ scheduledAt: null, expiresAt: null });

export const ScheduleInvoiceInputTypeValidationBuilder =
  (): Yup.ObjectSchema<ScheduleInvoiceInputType> =>
    Yup.object({
      scheduledAt: Yup.date().required().label('label.scheduledAt'),
      expiresAt: Yup.date().nullable().label('label.expiresAt'),
      exactOnlineOverrideReportingPeriod: Yup.boolean().label(
        'label.exactOnlineOverrideReportingPeriod',
      ),
      reportingYear: Yup.number()
        .min(2000)
        .max(9999)
        .when('exactOnlineOverrideReportingPeriod', {
          is: true,
          then: (schema) => schema.required(),
        })
        .label('label.reportingYear'),
      reportingPeriod: Yup.number()
        .min(0)
        .max(99)
        .when('exactOnlineOverrideReportingPeriod', {
          is: true,
          then: (schema) => schema.required(),
        }),
    });
