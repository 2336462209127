import { colors } from '@bas/theme';
import {
  Box,
  BoxProps,
  TouchableWithoutFeedback,
  Typography,
} from '@bas/ui/native/base';
import { memo, ReactElement, ReactNode, useMemo } from 'react';
import isEqual from 'react-fast-compare';
import { StyleSheet } from 'react-native';

export type TimeSliderBoxProps = BoxProps & {
  onPress: () => void;
  selected: boolean;
  light?: boolean;
  first?: boolean;
  last?: boolean;
  children: string | number | ReactNode;
};

const styles = StyleSheet.create({
  timeSliderBox: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 6,
    paddingBottom: 6,
  },
  firstBox: {
    paddingLeft: 0,
  },
  lastBox: {
    paddingRight: 0,
  },
});

const TimeSliderBox = ({
  style,
  selected,
  light,
  children,
  onPress,
  first,
  last,
  ...props
}: TimeSliderBoxProps): ReactElement => {
  const widthStyle = useMemo(
    (): { paddingLeft: number; paddingRight: number; textAlign: 'center' } => ({
      paddingLeft: first ? 0 : 17,
      paddingRight: last ? 0 : 17,
      textAlign: 'center',
    }),
    [first, last],
  );

  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <Box
        style={[
          styles.timeSliderBox,
          first && styles.firstBox,
          last && styles.lastBox,
          style,
        ]}
        {...props}
      >
        <Typography
          fontWeight={700}
          variant="h5"
          style={widthStyle}
          overrideColor={
            selected ? colors.blue[500] : colors.lila[light ? 100 : 800]
          }
        >
          {children}
        </Typography>
      </Box>
    </TouchableWithoutFeedback>
  );
};

const MemoComponent = memo(
  TimeSliderBox,
  (prevProps, nextProps) =>
    isEqual(prevProps.style, nextProps.style) &&
    prevProps.selected === nextProps.selected &&
    prevProps.light === nextProps.light &&
    prevProps.first === nextProps.first &&
    prevProps.last === nextProps.last &&
    prevProps.children === nextProps.children,
);
export default MemoComponent;
