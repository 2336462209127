import { SigningMomentInputType } from '@bas/tenant-domain/input-types';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ChangeSigningMomentsOfPackageMutationProps = {
  packageId: Uuid;
  signingMoments: SigningMomentInputType[];
};

export const ChangeSigningMomentsOfPackageMutation = async ({
  packageId,
  signingMoments,
  ...values
}: ChangeSigningMomentsOfPackageMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/packages/${packageId}/change-signing-moments`, {
    packageId,
    signingMoments: signingMoments.filter(({ enabled }) => enabled),
    ...values,
  });

export const useChangeSigningMomentsOfPackageMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeSigningMomentsOfPackageMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ChangeSigningMomentsOfPackageMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeSigningMomentsOfPackageMutationProps
  >({
    mutationFn: ChangeSigningMomentsOfPackageMutation,
    throwOnError: false,
    ...options,
  });
