import { Conversation } from '@bas/communication-domain/models';
import { useStartConversationWithEmployeeMutation } from '@bas/communication-domain/mutations';
import { useConversationByEmployeeIdRequestQuery } from '@bas/communication-domain/requests';
import { Uuid } from '@bas/value-objects';
import { useEffect, useMemo, useState } from 'react';

export const useGetConversationForEmployee = ({
  employeeId,
}: {
  employeeId?: Uuid;
}): {
  conversation?: Conversation;
  isPending: boolean;
  isFetching: boolean;
} => {
  const [createdConversation, setCreatedConversation] =
    useState<boolean>(false);
  const { mutateAsync: startConversation, isPending: isStartingConversation } =
    useStartConversationWithEmployeeMutation();
  const conversationProps = useMemo(
    () => ({ employeeId: employeeId || '' }),
    [employeeId],
  );

  const {
    isPending: isPendingConversation,
    isFetching: isFetchingConversation,
    data: conversationResponse,
    error,
  } = useConversationByEmployeeIdRequestQuery(conversationProps, {
    enabled: !!employeeId,
    retry: (failureCount, e) => e.response?.status !== 404 && failureCount <= 3,
  });

  useEffect(() => {
    if (!error) {
      return;
    }

    if (
      error.response?.status === 404 &&
      !isStartingConversation &&
      !createdConversation
    ) {
      setCreatedConversation(true);
      startConversation(conversationProps);
    }
  }, [
    conversationProps,
    createdConversation,
    error,
    isStartingConversation,
    startConversation,
  ]);

  return {
    conversation: conversationResponse?.data,
    isPending: isPendingConversation,
    isFetching: isFetchingConversation,
  };
};
