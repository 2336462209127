import { FormattedNumber } from 'react-intl';
import { Money } from '@bas/value-objects';
import { FunctionComponent } from 'react';

const FormattedCurrency: FunctionComponent<Money> = ({
  amount,
  currency,
}: Money) => (
  <FormattedNumber
    {...{
      value: amount / 100,
      style: 'currency',
      currency: currency || 'EUR',
    }}
  />
);

export default FormattedCurrency;
