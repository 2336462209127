import { Feature } from '@bas/tenant-domain/models';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type UpdateTenantFeatureMutationProps = {
  tenantId: Uuid;
  feature: Feature;
};

export const UpdateTenantFeatureMutation = async ({
  ...values
}: UpdateTenantFeatureMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/tenants/{tenantId}/update-feature`, {
    ...values,
  });

export const useUpdateTenantFeatureMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateTenantFeatureMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  UpdateTenantFeatureMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateTenantFeatureMutationProps
  >({
    mutationFn: UpdateTenantFeatureMutation,
    throwOnError: false,
    ...options,
  });
