import * as Yup from 'yup';
import {
  StoredItemInputType,
  storedItemInputTypeValidationBuilder,
} from './storedItemInputType';

export type MoveStoredItemsInputType = {
  storedItems: StoredItemInputType[];
};

export const moveStoredItemsInputTypeDefaultValues =
  (): MoveStoredItemsInputType => ({
    storedItems: [],
  });

export const moveStoredItemsInputTypeValidationBuilder =
  (): Yup.ObjectSchema<MoveStoredItemsInputType> =>
    Yup.object({
      storedItems: Yup.array()
        .of(
          (Yup.object() as Yup.ObjectSchema<StoredItemInputType>).when(
            [],
            // eslint-disable-next-line no-empty-pattern
            ([], schema, { value }) => {
              if (value.delete) {
                return schema as Yup.ObjectSchema<StoredItemInputType>;
              }

              return schema.concat(storedItemInputTypeValidationBuilder());
            },
          ),
        )
        .required(),
    });
