import type {
  ProjectEstimateItemGroupType,
  ProjectEstimateItemType,
} from '@bas/value-objects';
import type { ProjectEstimateItemValue } from './ProjectEstimateItemValue';

export interface ProjectEstimateItem {
  groupType: ProjectEstimateItemGroupType;
  itemType: ProjectEstimateItemType;
  calculated: ProjectEstimateItemValue;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extraData?: any;
  overwritten?: ProjectEstimateItemValue;
  wasOverwritten?: boolean;
  lineNumber: number;
}
