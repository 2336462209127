import { ListedEmail } from '@bas/communication-domain/models';
import { Pagination, QueryOptionsWithKey } from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useEffect } from 'react';

export type ListedEmailsByRelationIdRequestProps = Pagination & {
  relationId: Uuid;
};

type Response = AxiosResponse<Collection<ListedEmail>>;

export const ListedEmailsByRelationIdRequest = async ({
  ...params
}: ListedEmailsByRelationIdRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/communication/emails`, {
    params: { ...params },
  });

export const useListedEmailsByRelationIdRequest = (
  { relationId, ...request }: ListedEmailsByRelationIdRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () =>
      ListedEmailsByRelationIdRequest({ ...request, relationId }),
    queryKey: ['emails', 'list', relationId, request],
  });

export const usePrefetchListedEmailsByRelationIdRequest = ({
  relationId,
  ...request
}: ListedEmailsByRelationIdRequestProps): void => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.prefetchQuery<Response, AxiosError<ErrorResponse>, Response>({
      queryKey: [
        'emails',
        'list',
        relationId,
        ...Object.values({ relationId, ...request }),
      ],
      queryFn: async () =>
        ListedEmailsByRelationIdRequest({ relationId, ...request }),
    });
  }, [relationId, queryClient, request]);
};
