import { ErrorResponse, Language, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type RemoveLanguageFromAutomatedEmailMutationProps = {
  automatedEmailId: Uuid;
  language: Language;
};

export const RemoveLanguageFromAutomatedEmailMutation = async ({
  automatedEmailId,
  ...values
}: RemoveLanguageFromAutomatedEmailMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/communication/automated-emails/${automatedEmailId}/remove-language`,
    {
      automatedEmailId,
      ...values,
    },
  );

export const useRemoveLanguageFromAutomatedEmailMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveLanguageFromAutomatedEmailMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  RemoveLanguageFromAutomatedEmailMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveLanguageFromAutomatedEmailMutationProps
  >({
    mutationFn: RemoveLanguageFromAutomatedEmailMutation,
    throwOnError: false,
    ...options,
  });
