import * as React from 'react';
import {
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  ReactHookFormCheckbox,
  ReactHookFormDatePickerField,
  ReactHookFormDropdown,
  ReactHookFormEventDropdown,
  ReactHookFormProjectDropdown,
  ReactHookFormRelationDropdown,
} from '@bas/ui/native/molecules';
import { useIntl } from 'react-intl';
import { Box, BoxProps } from '@bas/ui/native/base';
import { TimeTrackingItemInputType } from '@bas/hrm-domain/input-types';
import { useFormContext, useWatch } from 'react-hook-form';
import dayjs from 'dayjs';
import { Project } from '@bas/project-domain/models';
import { useTimeTypesRequest } from '@bas/hrm-domain/requests';
import { TimeType } from '@bas/hrm-domain/models';

export type TimeTrackingItemFormProps = BoxProps;

const TimeTrackingItemForm = ({
  style,
  ...props
}: TimeTrackingItemFormProps): ReactElement => {
  const { formatMessage } = useIntl();
  const { setValue } = useFormContext<TimeTrackingItemInputType>();

  const start = useWatch<TimeTrackingItemInputType, 'start'>({
    name: 'start',
  });
  const end = useWatch<TimeTrackingItemInputType, 'end'>({
    name: 'end',
  });

  const relationId = useWatch<TimeTrackingItemInputType, 'relation.relationId'>(
    {
      name: 'relation.relationId',
    },
  );

  const projectId = useWatch<TimeTrackingItemInputType, 'project.projectId'>({
    name: 'project.projectId',
  });

  const [eventsProjectId, setEventsProjectId] = useState<
    string | null | undefined
  >(projectId);

  const handleChangeProject = useCallback(
    (id?: unknown, newValue?: Project) => {
      setValue(
        'project',
        newValue || {
          projectId: '',
        },
      );
      if (!relationId) {
        setValue('relation', newValue?.customer || undefined);
      }
      if (newValue?.projectId !== eventsProjectId) {
        setValue('eventId', '');
        setEventsProjectId(newValue?.projectId || '');
      }
    },
    [eventsProjectId, relationId, setValue],
  );

  useEffect(() => {
    const startDay = dayjs(start);
    const endDay = dayjs(end);

    if (!startDay.isSame(endDay, 'day') && !!start && !!end) {
      setValue(
        'end',
        startDay
          .clone()
          .set('hour', endDay.hour())
          .set('minute', endDay.minute())
          .toDate(),
      );
    }
  }, [end, setValue, start]);

  const { data: timeTypesData } = useTimeTypesRequest();

  const timeTypes = useMemo(
    () => timeTypesData?.data?.member || [],
    [timeTypesData?.data],
  );

  const mappedItemTypes = useMemo(
    () =>
      timeTypes.map((timeType: TimeType) => ({
        label: timeType.name,
        id: timeType.timeTypeId,
      })),
    [timeTypes],
  );

  return (
    <Box flex={1} flexDirection="row" flexWrap="wrap" {...props} rowGap={3}>
      <Box flexBasis="100%">
        <ReactHookFormCheckbox<TimeTrackingItemInputType>
          name="changeOverlappingTimeEntries"
          label={formatMessage({ id: 'label.changeOverlappingTimeEntries' })}
          size={24}
        />
      </Box>
      <Box flexBasis="100%">
        <ReactHookFormDropdown<TimeTrackingItemInputType>
          items={mappedItemTypes}
          name="timeTypeId"
          label={formatMessage({ id: 'label.timeType' })}
          light
        />
      </Box>

      <Box flexBasis="100%">
        <ReactHookFormProjectDropdown<TimeTrackingItemInputType>
          name="project.projectId"
          label={formatMessage({ id: 'label.project' })}
          light
          onChange={handleChangeProject}
        />
      </Box>
      <Box flexBasis="100%">
        <ReactHookFormRelationDropdown<TimeTrackingItemInputType>
          name="relation.relationId"
          label={formatMessage({ id: 'label.customer' })}
          light
        />
      </Box>
      <Box flexBasis="100%">
        <ReactHookFormEventDropdown<TimeTrackingItemInputType>
          name="eventId"
          label={formatMessage({ id: 'label.event' })}
          light
          projectId={eventsProjectId || undefined}
        />
      </Box>
      <Box flexBasis="100%">
        <ReactHookFormDatePickerField<TimeTrackingItemInputType>
          name="start"
          label={formatMessage({ id: 'label.date' })}
          light
          mode="date"
        />
      </Box>
      <Box flexBasis="50%" pr={1}>
        <ReactHookFormDatePickerField<TimeTrackingItemInputType>
          name="start"
          label={formatMessage({ id: 'label.startTime' })}
          light
          mode="time"
        />
      </Box>
      <Box flexBasis="50%" pl={1}>
        <ReactHookFormDatePickerField<TimeTrackingItemInputType>
          name="end"
          label={formatMessage({ id: 'label.endTime' })}
          light
          mode="time"
        />
      </Box>
    </Box>
  );
};

const MemoComponent = memo(TimeTrackingItemForm, () => true);
export default MemoComponent;
