import { formatDate } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type AddNationalHolidayMutationProps = {
  nationalHolidayId: Uuid;
  start: Date;
  end: Date;
  name: string;
};

export const AddNationalHolidayMutation = async ({
  start,
  end,
  ...values
}: AddNationalHolidayMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/{tenantId}/hrm/national-holidays`, {
    start: formatDate(start),
    end: formatDate(end),
    ...values,
  });

export const useAddNationalHolidayMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddNationalHolidayMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  AddNationalHolidayMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddNationalHolidayMutationProps
  >({
    mutationFn: AddNationalHolidayMutation,
    throwOnError: false,
    ...options,
  });
