import { RelationType } from '@bas/crm-domain/models';
import {
  EmailAddress,
  Gender,
  HowDidYouFindUs,
  Language,
  PhoneNumber,
  Salutation,
  Uuid,
} from '@bas/value-objects';

export type MovingJobTaxationCustomer = {
  customerId?: Uuid | null;
  relationType: RelationType;
  salutation?: Salutation | null;
  gender?: Gender | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName: string;
  companyName?: string | null;
  emailAddress?: EmailAddress | null;
  phoneNumber?: PhoneNumber | null;
  mobileNumber?: PhoneNumber | null;
  vatNumber?: string | null;
  language: Language;
  howDidYouFindUs?: string | HowDidYouFindUs | null;
  howDidYouFindUsOther?: string | HowDidYouFindUs | null;
};
