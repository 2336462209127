import * as Yup from 'yup';
import { Uuid } from '@bas/value-objects';

export interface ReceivedOrderedInventoryInputType {
  receivedAt?: Date | null;
  quantity: number;
  supplierId: Uuid;
}

export const ReceivedOrderedInventoryInputTypeDefaultValues =
  (): ReceivedOrderedInventoryInputType => ({
    receivedAt: null,
    quantity: 0,
    supplierId: '',
  });

export const ReceivedOrderedInventoryInputTypeValidationBuilder =
  (): Yup.ObjectSchema<ReceivedOrderedInventoryInputType> =>
    Yup.object({
      receivedAt: Yup.date().nullable().required().label('label.receivedAt'),
      quantity: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .nullable()
        .min(0)
        .required()
        .label('label.quantity'),
      supplierId: Yup.string().required().label('label.supplier'),
    });
