import { Checkbox, CheckboxProps } from '@bas/ui/native/atoms';
import { memo, ReactElement } from 'react';
import {
  FieldPath,
  FieldPathValue,
  FieldValues,
  useController,
} from 'react-hook-form';
import isEqual from 'react-fast-compare';

export interface ReactHookFormCheckboxProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<
    CheckboxProps,
    'name' | 'value' | 'error' | 'onChange' | 'checked'
  > {
  name: TName;
}
const ReactHookFormCheckbox = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  ...props
}: ReactHookFormCheckboxProps<TFieldValues, TName>): ReactElement => {
  const {
    field: { value, onChange },
    fieldState: { error, invalid },
    formState: { isSubmitting },
  } = useController<TFieldValues, TName>({
    name,
  });
  const fieldError = error?.message;
  const showError = invalid;

  const fieldToCheckbox = {
    error: showError,
    helperText: showError && fieldError,
    disabled: props.disabled ?? isSubmitting,
    checked: value,
    ...props,
    onCheck: () => {
      onChange(!value as FieldPathValue<TFieldValues, TName>);
    },
  };
  return <Checkbox {...fieldToCheckbox} />;
};

const MemoComponent = memo(
  ReactHookFormCheckbox,
  isEqual,
) as typeof ReactHookFormCheckbox;
export default MemoComponent;
