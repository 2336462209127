import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  question: {
    marginBottom: 4,
  },
  paddingBetweenRows: {
    paddingBottom: 32,
  },
  paddingBetweenDamages: {
    paddingBottom: 16,
  },
});
