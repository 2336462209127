import { Document, isPrivateRelation } from '@bas/crm-domain/models';
import { ProjectEvent, Vehicle } from '@bas/planning-domain/models';
import {
  useCanPayAtCustomerByEventIdRequest,
  useEventsByProjectIdRequest,
} from '@bas/planning-domain/requests';
import { Damage, MovingLiftJob } from '@bas/project-domain/models';
import { BillingMomentType } from '@bas/shared/models';
import { useEmployeeStore } from '@bas/shared/state';
import { PublicTenant } from '@bas/tenant-domain/models';
import { colors } from '@bas/theme';
import { AddressBlock } from '@bas/ui/native/atoms';
import { Box, Typography } from '@bas/ui/native/base';
import {
  ContactPersonBlock,
  DamageSummaryItem,
  IdentityIndicator,
  RenderContactInformation,
} from '@bas/ui/native/molecules';
import { EmployeeProjectRole, showEventName } from '@bas/value-objects';
import { faDownload } from '@fortawesome/pro-light-svg-icons/faDownload';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import dayjs from 'dayjs';
import * as WebBrowser from 'expo-web-browser';
import * as React from 'react';
import { ReactElement, ReactNode, useMemo } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { TouchableOpacity, useWindowDimensions, View } from 'react-native';
import { EventScreenTemplate } from '../EventScreenTemplate';
import styles from './styles';
import { useMovingLiftJobEventPageActions } from './useMovingLiftJobEventPageActions';

export type MovingLiftJobEventScreenTemplateProps = {
  project: MovingLiftJob;
  tenant: PublicTenant;
  event: ProjectEvent;
  materials: Vehicle[];
  showEmployeeDetails: boolean;
  onStartFinalizingEventAtCustomer: () => void;
  onStartFinalizingEvent: () => void;
  onStartPayingAtCustomer: (billingMomentType: BillingMomentType) => void;
  onStartReportingDamage: () => void;
  onStartRegisterMileage: () => void;
  onStartUploadingDocument: () => void;
  onOpenWorkingOnEventForm: () => void;
  onOpenDrivingToEventForm: () => void;
  damages: Damage[];
  documents: Document[];
  loadingInvoice?: boolean;
  children?: ReactNode;
  onBack: () => void;
};

const MovingLiftJobEventScreenTemplate = ({
  tenant,
  event,
  materials,
  showEmployeeDetails,
  onStartFinalizingEventAtCustomer,
  onStartFinalizingEvent,
  onStartPayingAtCustomer,
  onStartReportingDamage,
  onStartUploadingDocument,
  onStartRegisterMileage,
  onOpenWorkingOnEventForm,
  onOpenDrivingToEventForm,
  project,
  damages,
  documents,
  loadingInvoice,
  children,
  onBack,
}: MovingLiftJobEventScreenTemplateProps): ReactElement => {
  const employeeState = useEmployeeStore((state) => state.employee);

  const { data: eventsData } = useEventsByProjectIdRequest({
    projectId: project.projectId,
  });

  const projectEvents = useMemo(
    () => eventsData?.data?.member || [],
    [eventsData?.data],
  );

  const hasForeman = useMemo(
    () =>
      event.employees.filter(
        ({ projectRole }) => projectRole === EmployeeProjectRole.FOREMAN,
      ).length > 0,
    [event.employees],
  );

  const isForeman = useMemo(
    () =>
      !!event.employees.find(
        ({ projectRole, employee: { employeeId } }) =>
          projectRole === EmployeeProjectRole.FOREMAN &&
          employeeId === employeeState?.employeeId,
      ),
    [event.employees, employeeState?.employeeId],
  );

  const { data: canPayAtCustomerData } = useCanPayAtCustomerByEventIdRequest({
    eventId: event.eventId,
  });

  const dimensions = useWindowDimensions();
  const smallScreen = useMemo(() => dimensions.width < 600, [dimensions]);

  const eventActions = useMovingLiftJobEventPageActions({
    finished: event.finished,
    finishedAtCustomer: event.finishedAtCustomer,
    startedWorking: event.startedWorking,
    startedDriving: event.startedDriving,
    canPayAtCustomer: canPayAtCustomerData?.data,
    onStartFinalizingEventAtCustomer,
    onStartFinalizingEvent,
    onStartRegisterMileage,
    onStartPayingAtCustomer,
    onOpenWorkingOnEventForm,
    onOpenDrivingToEventForm,
    onStartReportingDamage,
    onStartUploadingDocument,
    hasForeman,
    isForeman,
    loadingInvoice,
  });

  return (
    <EventScreenTemplate
      eventId={event.eventId}
      allowEmployeeToSeeColleagueDetails={showEmployeeDetails}
      finished={event.finished}
      headerLabel={
        showEventName(event.eventType) ? (
          event.name.trim()
        ) : (
          <FormattedMessage
            id={`mobileApp.event.eventType.${event.eventType}`}
          />
        )
      }
      actions={eventActions}
      subHeaderLabel={
        <AddressBlock
          variant="single_line"
          address={event.location}
          color={colors.lila[600]}
        />
      }
      plannedMaterials={materials}
      team={event.employees}
      crews={event.crews}
      addresses={[]}
      alert={
        event.notes
          ? {
              severity: 'warning',
              children: event.notes,
            }
          : undefined
      }
      notes={project.notesForEmployees}
      eventType={event.eventType}
      onBack={onBack}
      country={tenant.address.country}
      identityId={project?.identityId}
      projectType={project?.projectType}
    >
      <View style={styles.row}>
        <Typography variant="subtitle1">
          <FormattedMessage id="label.project" />
        </Typography>
      </View>
      <View style={[styles.row, styles.marginBetweenContent]}>
        <Typography>{project.projectCode}</Typography>
        {project.reference && <Typography>{project.reference}</Typography>}
        {project.identityId && (
          <IdentityIndicator identityId={project.identityId} full />
        )}
      </View>
      <View style={styles.row}>
        <Typography variant="subtitle1">
          <FormattedMessage id="label.customer" />
        </Typography>
      </View>
      <View style={[styles.row, styles.marginBetweenContent]}>
        <View style={[styles.halfWidth, smallScreen && styles.fullWidth]}>
          <Typography>{project.customer.name.trim()}</Typography>
          {isPrivateRelation(project.customer) &&
            project.customer.primaryContactInformation && (
              <RenderContactInformation
                contactInformation={project.customer.primaryContactInformation}
              />
            )}
        </View>
        <View
          style={[
            styles.halfWidth,
            smallScreen && styles.fullWidth,
            smallScreen && styles.smallPaddingContent,
          ]}
        >
          {project.projectContactPerson && (
            <ContactPersonBlock
              contactPersonLabel={
                <FormattedMessage id="label.projectContactPerson" />
              }
              contactPerson={project.projectContactPerson}
            />
          )}
        </View>
      </View>
      {projectEvents.length > 0 && (
        <View style={[styles.row, styles.marginBetweenContent]}>
          <View style={[styles.fullWidth]}>
            <Typography variant="subtitle1">
              <FormattedMessage id="label.data" />
            </Typography>
          </View>
          {projectEvents.map((projectEvent) => (
            <View
              key={projectEvent.eventId}
              style={[styles.row, styles.fullWidth]}
            >
              <View style={styles.halfWidth}>
                <Typography>
                  <FormattedMessage
                    id={`mobileApp.event.eventType.${projectEvent.eventType}`}
                  />
                </Typography>
              </View>
              <View style={styles.halfWidth}>
                <Typography>
                  <FormattedDate
                    value={projectEvent.start}
                    weekday="long"
                    day="numeric"
                    month="long"
                    year={
                      dayjs(projectEvent.start).isSame(new Date(), 'year')
                        ? undefined
                        : 'numeric'
                    }
                  />
                </Typography>
              </View>
            </View>
          ))}
        </View>
      )}
      {damages.length > 0 && (
        <View style={[styles.row, styles.marginBetweenContent]}>
          <View style={[styles.fullWidth]}>
            <Typography variant="subtitle1">
              <FormattedMessage id="label.damages" />
            </Typography>
          </View>
          {damages.map((damage) => (
            <View key={damage.damageId}>
              <DamageSummaryItem
                damage={damage}
                employees={event.employees.map(({ employee }) => employee)}
                lessImportant
              />
            </View>
          ))}
        </View>
      )}
      {documents.length > 0 && (
        <View style={[styles.row, styles.marginBetweenContent]}>
          <View style={[styles.fullWidth]}>
            <Typography variant="subtitle1">
              <FormattedMessage id="label.documents" />
            </Typography>
          </View>
          <Box flexDirection="column">
            {documents.map((document) => (
              <Box key={document.documentId}>
                <TouchableOpacity
                  style={styles.row}
                  onPress={() =>
                    WebBrowser.openBrowserAsync(
                      document.file.contentUrl || document.file.url,
                    )
                  }
                >
                  <Typography>{document.fileName}&nbsp;</Typography>
                  <FontAwesomeIcon size={20} icon={faDownload} />
                </TouchableOpacity>
              </Box>
            ))}
          </Box>
        </View>
      )}
      {children}
    </EventScreenTemplate>
  );
};

export default MovingLiftJobEventScreenTemplate;
