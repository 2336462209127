import * as Yup from 'yup';
import {
  AddressInputType,
  addressInputTypeDefaultValues,
  addressInputTypeValidationBuilder,
} from '@bas/shared/input-types';
import {
  WarehouseInformationInputType,
  warehouseInformationInputTypeDefaultValues,
  warehouseInformationInputTypeValidationBuilder,
} from './warehouseInformationInputType';

export type CreateWarehouseInputType = WarehouseInformationInputType & {
  address: AddressInputType;
};

export const createWarehouseInputTypeDefaultValues =
  (): CreateWarehouseInputType => ({
    ...warehouseInformationInputTypeDefaultValues(),
    address: addressInputTypeDefaultValues(),
  });

export const createWarehouseInputTypeValidationBuilder =
  (): Yup.ObjectSchema<CreateWarehouseInputType> =>
    warehouseInformationInputTypeValidationBuilder().concat(
      Yup.object({
        address: addressInputTypeValidationBuilder().required(),
      }),
    );
