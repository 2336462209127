import { StartDrivingInputType } from '@bas/planning-domain/input-types';
import { Vehicle } from '@bas/planning-domain/models';
import { calculateTimeOfDaySliderValues } from '@bas/shared/utils';
import { Box } from '@bas/ui/native/base';
import { ReactHookFormTimeSliderField } from '@bas/ui/native/molecules';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { MileageReportForm } from '../MileageReportForm';

export type StartDrivingFormProps = {
  vehicles: Vehicle[];
  startTime?: Date;
};

const StartDrivingForm = ({ vehicles, startTime }: StartDrivingFormProps) => {
  const { formatMessage } = useIntl();
  const mileageReports = useWatch<StartDrivingInputType, 'mileageReports'>({
    name: 'mileageReports',
  });

  const timeOfDayValues = useMemo(
    () => calculateTimeOfDaySliderValues(15, startTime),
    [startTime],
  );

  return (
    <Box rowGap={3}>
      <Box>
        <ReactHookFormTimeSliderField
          name="realTravelStartTime"
          label={formatMessage({ id: 'label.departureTime' })}
          values={timeOfDayValues}
          light
        />
      </Box>
      {mileageReports.map(({ materialId }, index) => (
        <Box key={materialId} paddingHorizontal={20}>
          <MileageReportForm
            vehicles={vehicles}
            showStart
            hideVehicles
            prefix={`mileageReports.${index}`}
          />
        </Box>
      ))}
    </Box>
  );
};

export default StartDrivingForm;
