import dayjs from 'dayjs';

export const calculateTimeOfDaySliderValues = (
  interval: number,
  date: Date | string | undefined | null = undefined,
): Date[] =>
  new Array(24 * (60 / interval))
    .fill(() => '')
    .map((value, index) =>
      dayjs(date)
        .startOf('day')
        .add(index * interval, 'minutes')
        .toDate(),
    );
