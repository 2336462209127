import { queryInvalidators as authenticationQueryInvalidators } from '@bas/authentication-domain/requests';
import { queryInvalidators as communicationQueryInvalidators } from '@bas/communication-domain/requests';
import { queryInvalidators as crmQueryInvalidators } from '@bas/crm-domain/requests';
import { queryInvalidators as financialQueryInvalidators } from '@bas/financial-domain/requests';
import { queryInvalidators as hrmQueryInvalidators } from '@bas/hrm-domain/requests';
import { queryInvalidators as integrationQueryInvalidators } from '@bas/integration-domain/requests';
import { queryInvalidators as planningQueryInvalidators } from '@bas/planning-domain/requests';
import { queryInvalidators as projectQueryInvalidators } from '@bas/project-domain/requests';
import { useAppStore, useAuthStore, useTenantStore } from '@bas/shared/state';
import { subscribeToMercure } from '@bas/shared/utils';
import { queryInvalidators as taskQueryInvalidators } from '@bas/task-domain/requests';
import { queryInvalidators as tenantQueryInvalidators } from '@bas/tenant-domain/requests';
import { queryInvalidators as wmsQueryInvalidators } from '@bas/wms-domain/requests';
import { useQueryClient } from '@tanstack/react-query';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { memo, useEffect, useState } from 'react';
import { Platform } from 'react-native';
import NativeEventSource from 'react-native-sse';

const allQueryInvalidators = [
  ...authenticationQueryInvalidators,
  ...communicationQueryInvalidators,
  ...crmQueryInvalidators,
  ...financialQueryInvalidators,
  ...hrmQueryInvalidators,
  ...integrationQueryInvalidators,
  ...planningQueryInvalidators,
  ...projectQueryInvalidators,
  ...taskQueryInvalidators,
  ...tenantQueryInvalidators,
  ...wmsQueryInvalidators,
];

const MercureSubscriber = (): null => {
  const [tries, setTries] = useState(0);
  const tenantId = useTenantStore((state) => state.tenant?.tenantId);
  const mercureToken = useAuthStore((state) => state.mercureToken);
  const userId = useAuthStore((state) => state.userId);
  const mercureUrl = useAppStore((state) => state.mercureUrl);
  const apiUrl = useAppStore((state) => state.apiUrl);
  const queryClient = useQueryClient();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let eventSource:
      | EventSource
      | NativeEventSource
      | EventSourcePolyfill
      | null = null;

    if (tries >= 5) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {};
    }

    if (mercureToken && tenantId && mercureUrl) {
      eventSource = subscribeToMercure(
        mercureToken,
        tenantId,
        userId || '',
        apiUrl,
        mercureUrl || '',
      );

      // eslint-disable-next-line no-console
      console.log('subscribed to mercure');
      if (eventSource instanceof NativeEventSource) {
        eventSource.addEventListener('error', (message) => {
          // eslint-disable-next-line no-console
          console.error({ platform: Platform.OS, message });
          setTries((t) => t + 1);
        });

        eventSource.addEventListener('message', (message) => {
          if ('data' in message && !!message.data) {
            const data = JSON.parse(message.data);
            if (Platform.OS === 'web') {
              // eslint-disable-next-line no-console
              console.log(data);
            }
            // eslint-disable-next-line no-console
            console.log('received a message');
            allQueryInvalidators.forEach((queryInvalidator) =>
              queryInvalidator(data, queryClient, tenantId),
            );
          }
        });
      } else if (eventSource instanceof EventSourcePolyfill) {
        eventSource.onerror = (message) => {
          // eslint-disable-next-line no-console
          console.error({ platform: Platform.OS, message });
          setTries((t) => t + 1);
        };

        eventSource.onmessage = (message) => {
          const data = JSON.parse(message.data);
          if (Platform.OS === 'web') {
            // eslint-disable-next-line no-console
            console.log(data);
          }
          // eslint-disable-next-line no-console
          console.log('received a message');
          allQueryInvalidators.forEach((queryInvalidator) =>
            queryInvalidator(data, queryClient, tenantId),
          );
        };
      }
    }

    return () => {
      if (eventSource instanceof NativeEventSource) {
        eventSource.removeAllEventListeners();
        eventSource.close();
      } else if (eventSource instanceof EventSourcePolyfill) {
        eventSource.close();
      }
    };
  }, [tenantId, mercureToken, mercureUrl, userId, apiUrl, queryClient, tries]);

  return null;
};

const MemoComponent = memo(MercureSubscriber, () => true);
export default MemoComponent;
