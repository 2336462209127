export * from './addAttachmentToDocumentTemplateMutation';
export * from './generateMetabaseJwtTokenMutation';
export * from './createTenantContractMutation';
export * from './addCoverSheetToDocumentTemplateMutation';
export * from './addIdentityMutation';
export * from './addLanguageToDocumentTemplateMutation';
export * from './addSeatsToTenantMutation';
export * from './changeBillingMomentsOfPackageMutation';
export * from './changePriceOfServiceTypeMutation';
export * from './changeSigningMomentsOfPackageMutation';
export * from './createDocumentTemplateMutation';
export * from './createPackageMutation';
export * from './postNewsMutation';
export * from './removeAttachmentFromDocumentTemplateMutation';
export * from './removeCoverSheetFromDocumentTemplateMutation';
export * from './removeDocumentTemplateMutation';
export * from './removeLanguageFromDocumentTemplateMutation';
export * from './removeNewsMutation';
export * from './removeSeatsFromTenantMutation';
export * from './renamePackageMutation';
export * from './updateDocumentTemplateDetailsMutation';
export * from './updateDocumentTemplateTranslationMutation';
export * from './updateIdentityAddressMutation';
export * from './updateIdentityContactInformationMutation';
export * from './updateIdentityInformationMutation';
export * from './updateNewsMutation';
export * from './updateTenantAddressMutation';
export * from './updateTenantContactInformationMutation';
export * from './updateTenantFeatureMutation';
export * from './updateTenantInformationMutation';
export * from './updateTenantSignerInformationMutation';
export * from './updateTenantWorkingScheduleMutation';
export * from './userOpensNewsMutation';
