import { ErrorResponse, Language, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type UpdateCommunicationLanguageMutationProps = {
  relationId: Uuid;
  language: Language;
};

export const UpdateCommunicationLanguageMutation = async ({
  relationId,
  ...values
}: UpdateCommunicationLanguageMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/relations/${relationId}/update-communication-language`,
    {
      relationId,
      ...values,
    },
  );

export const useUpdateCommunicationLanguageMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateCommunicationLanguageMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  UpdateCommunicationLanguageMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateCommunicationLanguageMutationProps
  >({
    mutationFn: UpdateCommunicationLanguageMutation,
    throwOnError: false,
    ...options,
  });
