import {
  MovingJobAddress,
  MovingJobAddressType,
} from '@bas/project-domain/models';
import { MobileTaxationStepTemplate } from '@bas/taxation-tool-domain/native/templates';
import { memo, ReactElement, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { TaxationToolAddressForm } from '../TaxationToolAddressForm';

export type PrimaryAddressFormStepProps = {
  addressType: MovingJobAddressType;
};

const PrimaryAddressFormStep = ({
  addressType,
}: PrimaryAddressFormStepProps): ReactElement => {
  const addresses: MovingJobAddress[] = useWatch({
    name: 'addresses',
  });

  const addressIndex = useMemo(
    () =>
      addresses.findIndex(
        (value) => value.addressType === addressType && value.primary,
      ),
    [addresses, addressType],
  );

  return (
    <MobileTaxationStepTemplate
      primary={
        <FormattedMessage
          id={`mobileApp.intake.movingJob.${addressType}Address`}
        />
      }
      secondary={<FormattedMessage id="label.addresses" />}
      disableScroll
      disableContentPadding
    >
      <TaxationToolAddressForm
        addressIndex={addressIndex}
        showUnknownAddress={addressType === MovingJobAddressType.TO}
      />
    </MobileTaxationStepTemplate>
  );
};

const MemoComponent = memo(
  PrimaryAddressFormStep,
  (prevProps, nextProps) => prevProps.addressType === nextProps.addressType,
);

export default MemoComponent;
