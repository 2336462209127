import { RootStackParamList } from '@bas/employee-app/value-objects';
import { useEnterHoursForEventMutation } from '@bas/hrm-domain/mutations';
import {
  EnterEventHoursInputType,
  StartDrivingInputType,
  StartWorkingOnEventInputType,
} from '@bas/planning-domain/input-types';
import {
  createEventObjectForRecurringDate,
  isCustomEvent,
  isEventWithRelation,
  isProjectEvent,
  isVehicle,
} from '@bas/planning-domain/models';
import {
  useFinishEventMutation,
  useStartDrivingToEventMutation,
  useStartWorkingOnEventMutation,
} from '@bas/planning-domain/mutations';
import { useStartDrivingWizardSteps } from '@bas/planning-domain/native/hooks';
import {
  EnterEventHoursBottomSheet,
  StartDrivingToEventFormWizardBottomSheet,
  StartWorkingOnEventBottomSheet,
} from '@bas/planning-domain/native/organisms';
import {
  EventScreenTemplate,
  ProjectEventScreenTemplate,
  RelationEventScreenTemplate,
} from '@bas/planning-domain/native/templates';
import {
  useEventByEventIdRequest,
  useVehiclesByMaterialIdsRequest,
} from '@bas/planning-domain/requests';
import { useProjectByProjectIdRequest } from '@bas/project-domain/requests';
import { useHandleTakeMaterials } from '@bas/shared/hooks';
import { useEmployeeStore, useTenantStore } from '@bas/shared/state';
import { basParaph } from '@bas/svg-icons';
import { isMobileAppFeature } from '@bas/tenant-domain/models';
import { colors } from '@bas/theme';
import { AddressBlock, MobileActionButton } from '@bas/ui/native/atoms';
import { BottomSheetMethods, Box, Typography } from '@bas/ui/native/base';
import {
  AppErrorBoundaryScreen,
  LoadingScreen,
} from '@bas/ui/native/templates';
import {
  EventType,
  ReactHookWizardStep,
  showEventLocation,
  showEventName,
} from '@bas/value-objects';
import { InventoryMovementInputType } from '@bas/wms-domain/input-types';
import {
  usePublicMovingBoxesRequest,
  useReusableMaterialsRequest,
} from '@bas/wms-domain/requests';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { ErrorBoundary } from '@sentry/react-native';
import { keepPreviousData } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { router, useLocalSearchParams } from 'expo-router';
import * as React from 'react';
import { ReactElement, useCallback, useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { View } from 'react-native';
import { v7 } from 'uuid';

export type MobileEmployeeEventScreenProps = NativeStackScreenProps<
  RootStackParamList,
  'Event'
>;

const EventNotOnTheSameDay = () => (
  <Box
    flex={1}
    paddingHorizontal={{
      tablet: 50,
      phone: 20,
    }}
  >
    <Typography color="white">
      <FormattedMessage id="mobileApp.event.eventIsNotOnTheSameDay.description" />
    </Typography>
  </Box>
);

const MobileEmployeeEventScreen = (): ReactElement => {
  const tenantState = useTenantStore((state) => state.tenant);
  const employeeState = useEmployeeStore((state) => state.employee);
  const startDrivingRef = useRef<BottomSheetModal & BottomSheetMethods>(null);
  const startWorkingRef = useRef<BottomSheetModal & BottomSheetMethods>(null);
  const enterHoursRef = useRef<BottomSheetModal & BottomSheetMethods>(null);
  const { eventId, selectedDate } = useLocalSearchParams<{
    eventId: string;
    selectedDate?: string;
  }>();

  const {
    data: eventData,
    isLoading,
    isFetching: isFetchingEvent,
    refetch: refetchEvent,
  } = useEventByEventIdRequest(
    {
      eventId: eventId || '',
    },
    { enabled: !!eventId },
  );

  const event = useMemo(() => {
    const result = eventData?.data;

    if (!result) {
      return undefined;
    }

    if (
      !result?.recurringInformation?.recurring ||
      !result.recurringInformation?.rrule ||
      !selectedDate
    ) {
      return result;
    }

    return createEventObjectForRecurringDate(
      result,
      dayjs(selectedDate).toDate(),
    );
  }, [eventData?.data, selectedDate]);

  const {
    data: projectData,
    isFetching: isFetchingProject,
    refetch: refetchProject,
  } = useProjectByProjectIdRequest(
    {
      projectId: isProjectEvent(event) ? event.projectId : '',
    },
    { enabled: isProjectEvent(event) && !!event.projectId },
  );

  const { mutateAsync: enterHours } = useEnterHoursForEventMutation();
  const { mutateAsync: finishEvent } = useFinishEventMutation();
  const { mutateAsync: drivingToEventMutation } =
    useStartDrivingToEventMutation();
  const { mutateAsync: startWorkingOnEventMutation } =
    useStartWorkingOnEventMutation();

  const materialIds = event ? event.materialIds : [];

  const { data: materialsData } = useVehiclesByMaterialIdsRequest(
    {
      materialIds,
    },
    {
      enabled: !!event && materialIds.length > 0,
    },
  );

  const materials = useMemo(
    () => materialsData?.data.member || [],
    [materialsData?.data],
  );

  const mobileAppFeature = tenantState?.mobileAppFeature;

  let showEmployeeDetails = false;
  if (mobileAppFeature && isMobileAppFeature(mobileAppFeature)) {
    showEmployeeDetails = mobileAppFeature.showEmployeeDetails;
  }

  const handleFinishEvent = useCallback(
    async (values: EnterEventHoursInputType) => {
      if (!event || !employeeState) {
        return false;
      }

      let endDate = values.overrideTime ? values.endDate : event.end;
      if (!endDate) {
        endDate = event.end;
      }
      let startDate = values.overrideTime ? values.startDate : event.start;
      if (!startDate) {
        startDate = event.start;
      }

      const travelStartDate = values.overrideTime
        ? values.travelStartTime
        : event.travelStart;

      const travelEndDate = values.overrideTime
        ? values.travelEndTime
        : event.travelEnd;

      await enterHours({
        entryId: v7(),
        eventId: event.eventId,
        employeeId: employeeState.employeeId,
        projectId: isProjectEvent(event) ? event.projectId : undefined,
        startDate,
        endDate,
        travelStartDate,
        travelEndDate,
        breakTime: values.breakTime,
        reason: values.reason,
      });

      await finishEvent({
        eventId: event?.eventId,
        startTime: startDate,
        finishedAt: endDate,
        realTravelStartTime: travelStartDate,
        realTravelEnd: travelEndDate,
        mileageReports: [],
        breakTime: values.breakTime,
      });

      return true;
    },
    [employeeState, enterHours, event, finishEvent],
  );

  const project = projectData?.data;
  const handleTakeMaterials = useHandleTakeMaterials({
    project,
    eventId: event?.eventId || '',
  });

  const handleStartDrivingToEvent = useCallback(
    async ({
      realTravelStartTime,
      mileageReports,
      ...values
    }: StartDrivingInputType & {
      materials: InventoryMovementInputType[];
    }) => {
      if (!event || !employeeState || !realTravelStartTime) {
        return false;
      }

      await drivingToEventMutation({
        eventId: event.eventId,
        realTravelStartTime,
        mileageReports: mileageReports
          .filter(({ mileageStart }) => !!mileageStart)
          .map((mileageReport) => ({
            ...mileageReport,
            reportStartDate: realTravelStartTime,
          })),
        ...values,
      });

      await handleTakeMaterials(values);
      return true;
    },
    [drivingToEventMutation, employeeState, event, handleTakeMaterials],
  );

  const handleStartWorkingOnEvent = useCallback(
    async ({ startTime, ...values }: StartWorkingOnEventInputType) => {
      if (!event || !employeeState || !startTime) {
        return false;
      }

      await startWorkingOnEventMutation({
        eventId: event.eventId,
        startTime,
        ...values,
      });

      return true;
    },
    [employeeState, event, startWorkingOnEventMutation],
  );

  const handleBack = useCallback(() => {
    if (router.canGoBack()) {
      router.back();
    } else {
      router.navigate('/drawer');
    }
  }, []);

  const handleOpenDrivingToEventForm = useCallback(() => {
    startDrivingRef.current?.present();
  }, [startDrivingRef]);

  const handleOpenStartWorkingOnEventForm = useCallback(() => {
    startWorkingRef.current?.present();
  }, [startWorkingRef]);

  const handleOpenFinalizeEvent = useCallback(() => {
    enterHoursRef.current?.present();
  }, [enterHoursRef]);

  const greetingName = useMemo(
    () => employeeState?.personName?.firstName,
    [employeeState?.personName?.firstName],
  );

  const handleFallback = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ({ eventId, error, resetError }: any) => (
      <AppErrorBoundaryScreen
        resetError={resetError}
        eventId={eventId}
        error={error}
      />
    ),
    [],
  );

  const {
    data: boxesData,
    isFetching: isFetchingPublicMovingBoxes,
    refetch: refetchPublicMovingBoxes,
  } = usePublicMovingBoxesRequest();

  const {
    data: reusableMaterialsData,
    isFetching: isFetchingResuableMaterials,
    refetch: refetchResuableMaterials,
  } = useReusableMaterialsRequest(
    {
      page: 1,
      perPage: 999999,
    },
    {
      placeholderData: keepPreviousData,
    },
  );
  const reusableMaterials = useMemo(
    () => reusableMaterialsData?.data.member || [],
    [reusableMaterialsData?.data],
  );

  const boxes = useMemo(() => boxesData?.data.member || [], [boxesData?.data]);

  const currentTime = useMemo(() => {
    let result = dayjs();

    const remainder = Math.floor(result.minute() / 15) * 15;
    result = result.set('minutes', remainder);

    return result;
  }, []);

  const handleCloseDriving = useCallback(() => {
    startDrivingRef?.current?.dismiss();
  }, []);

  const startDrivingSteps = useStartDrivingWizardSteps({
    eventType: event?.eventType,
    vehicles: materials.filter(isVehicle),
    projectId: isProjectEvent(event) ? event?.projectId : undefined,
    neededInventoryItems: event?.neededInventoryItems,
    notes: event?.notes,
    project: isProjectEvent(event) ? project : undefined,
    currentTime,
    boxes,
    reusableMaterials,
  });

  const isFetching = useMemo(
    () =>
      isFetchingEvent ||
      isFetchingProject ||
      isFetchingPublicMovingBoxes ||
      isFetchingResuableMaterials,
    [
      isFetchingEvent,
      isFetchingProject,
      isFetchingPublicMovingBoxes,
      isFetchingResuableMaterials,
    ],
  );

  const handleRefresh = useCallback(() => {
    refetchEvent();
    refetchProject();
    refetchPublicMovingBoxes();
    refetchResuableMaterials();
  }, [
    refetchEvent,
    refetchProject,
    refetchPublicMovingBoxes,
    refetchResuableMaterials,
  ]);

  const allowActions = useMemo(() => {
    if (!event) {
      return false;
    }

    return dayjs(
      event.realTravelStart || event.travelStart || event.start,
    ).isSame(dayjs(), 'day');
  }, [event]);

  const notAllowedSteps = useMemo(
    () => [
      {
        key: 'mobileApp.event.eventIsNotOnTheSameDay.title',
        title: 'mobileApp.event.eventIsNotOnTheSameDay.title',
        category: 'event',
        component: EventNotOnTheSameDay,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as ReactHookWizardStep<any, any>,
    ],
    [],
  );

  const children = useMemo(
    () => (
      <View>
        <StartDrivingToEventFormWizardBottomSheet
          vehicles={materials.filter(isVehicle)}
          onSubmit={handleStartDrivingToEvent}
          bottomSheetRef={startDrivingRef}
          steps={allowActions ? startDrivingSteps : notAllowedSteps}
          onClose={handleCloseDriving}
          disableSubmit={!allowActions}
        />
        <StartWorkingOnEventBottomSheet
          onSubmit={handleStartWorkingOnEvent}
          eventType={event?.eventType}
          currentTime={currentTime.toDate()}
          startDrivingTime={event?.realTravelStart || event?.travelStart}
          ref={startWorkingRef}
        />
        <EnterEventHoursBottomSheet
          ref={enterHoursRef}
          onSubmit={handleFinishEvent}
          enterForEvent={event}
          greetingName={greetingName}
          finishEvent
        />
      </View>
    ),
    [
      notAllowedSteps,
      allowActions,
      currentTime,
      event,
      greetingName,
      handleCloseDriving,
      handleFinishEvent,
      handleStartDrivingToEvent,
      handleStartWorkingOnEvent,
      materials,
      startDrivingSteps,
    ],
  );

  if (
    !event ||
    !tenantState ||
    (!project && isProjectEvent(event)) ||
    isLoading
  ) {
    return <LoadingScreen />;
  }

  if (isProjectEvent(event) && project) {
    return (
      <ProjectEventScreenTemplate
        tenant={tenantState}
        event={event}
        project={project}
        showEmployeeDetails={showEmployeeDetails}
        materials={materials}
        onOpenWorkingOnEventForm={handleOpenStartWorkingOnEventForm}
        onOpenDrivingToEventForm={handleOpenDrivingToEventForm}
        onBack={handleBack}
        isFetching={isFetching}
        onRefresh={handleRefresh}
      >
        {children}
      </ProjectEventScreenTemplate>
    );
  }

  if (isEventWithRelation(event)) {
    return (
      <RelationEventScreenTemplate
        tenant={tenantState}
        event={event}
        showEmployeeDetails={showEmployeeDetails}
        materials={materials}
        onStartFinalizingEvent={handleOpenFinalizeEvent}
        onBack={handleBack}
      >
        {children}
      </RelationEventScreenTemplate>
    );
  }

  const finalizeEventAction = (
    <MobileActionButton
      icon={basParaph}
      onPress={handleOpenFinalizeEvent}
      disabled={event.finished || !dayjs(event.start).isSame(dayjs(), 'day')}
    >
      <FormattedMessage id="eventActions.finalizeEvent" />
    </MobileActionButton>
  );

  return (
    <ErrorBoundary fallback={handleFallback}>
      <EventScreenTemplate
        eventId={event.eventId}
        allowEmployeeToSeeColleagueDetails={showEmployeeDetails}
        finished={event.finished}
        headerLabel={
          showEventName(event.eventType) ? (
            event.name.trim()
          ) : (
            <FormattedMessage
              id={`mobileApp.event.eventType.${event.eventType}`}
            />
          )
        }
        subHeaderLabel={
          showEventLocation(event.eventType) && (
            <AddressBlock
              variant="single_line"
              address={event.location}
              color={colors.lila[600]}
            />
          )
        }
        actions={
          [
            EventType.OFFICE_WORK_EVENT,
            EventType.CUSTOM_EVENT,
            EventType.WAREHOUSE_WORK_EVENT,
          ].includes(event.eventType)
            ? [finalizeEventAction]
            : []
        }
        plannedMaterials={materials}
        team={event.employees}
        crews={event.crews}
        addresses={[]}
        alert={
          event.notes
            ? {
                severity: 'warning',
                children: event.notes,
              }
            : undefined
        }
        notes={isCustomEvent(event) ? event.description : undefined}
        eventType={event.eventType}
        onBack={handleBack}
        country={tenantState?.address.country}
      >
        {children}
      </EventScreenTemplate>
    </ErrorBoundary>
  );
};

export default MobileEmployeeEventScreen;
