import * as Yup from 'yup';

export interface TenantClosureDateInputType {
  date: Date | null;
  reason: string;
}

export const TenantClosureDateInputTypeDefaultValues =
  (): TenantClosureDateInputType => ({
    date: null,
    reason: '',
  });

export const TenantClosureDateInputTypeValidationBuilder =
  (): Yup.ObjectSchema<TenantClosureDateInputType> =>
    Yup.object({
      date: Yup.date().nullable().label('label.date').required(),
      reason: Yup.string().label('label.reason').required(),
    });
