import * as Yup from 'yup';

export interface UserPasswordInputType {
  currentPassword: string;
  newPassword: string;
  repeatPassword: string;
}

export const UserPasswordInputTypeDefaultValues =
  (): UserPasswordInputType => ({
    currentPassword: '',
    newPassword: '',
    repeatPassword: '',
  });

export const UserPasswordInputTypeValidationBuilder =
  (): Yup.ObjectSchema<UserPasswordInputType> =>
    Yup.object({
      currentPassword: Yup.string().required().label('label.currentPassword'),
      newPassword: Yup.string().required().label('label.newPassword'),
      repeatPassword: Yup.string()
        .required()
        .label('label.repeatPassword')
        .oneOf(
          [Yup.ref('newPassword')],
          'Wachtwoord is niet gelijk aan het nieuwe wachtwoord',
        ),
    });
