import type { MediaObject } from '@bas/media-domain/models';
import type { Address, Uuid } from '@bas/value-objects';
import type { DamageInflictedOnType } from './DamageInflictedOnType';
import type { DamageStatus } from './DamageStatus';

export interface Damage {
  damageId: Uuid;
  damageStatus: DamageStatus;
  projectId: Uuid;
  projectCode: string;
  damagedAt: Date;
  damageWasAlreadyThere: boolean;
  damageInflictedOn: DamageInflictedOnType;
  location: Address;
  summary: string;
  description: string;
  damagedByEmployeeId?: Uuid;
  pictures: MediaObject[];
  closedAt?: Date;
}
