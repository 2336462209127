import { ClaAllowanceRuleType } from '@bas/hrm-domain/models';
import { EmployeeContractType, Uuid } from '@bas/value-objects';
import * as Yup from 'yup';

export type AddClaAllowanceRuleInputType = {
  name: string;
  description: string;
  ruleType: ClaAllowanceRuleType;
  contractType: EmployeeContractType;
  employeeType: 'field-staff' | 'backoffice-staff' | 'all';
  attributeId: Uuid | undefined | null;
  excludedAttributeIds: Uuid[];
  percentage: number;
  enabledFrom: Date | null;
  whichDays: ('mo' | 'tu' | 'we' | 'th' | 'fr' | 'sa' | 'su')[];
  onlyOnPublicHolidays: boolean;
  calculatePer?: 'day' | 'week' | null;
  overtimeThresholdHours?: number | null;
  fromTime?: number | null;
  untilTime?: number | null;
};

export const addClaAllowanceRuleInputTypeDefaultValues =
  (): AddClaAllowanceRuleInputType => ({
    name: '',
    description: '',
    ruleType: ClaAllowanceRuleType.UNKNOWN,
    contractType: EmployeeContractType.FIXED_AMOUNT_OF_HOURS,
    employeeType: 'field-staff',
    attributeId: null,
    excludedAttributeIds: [],
    percentage: 0,
    enabledFrom: null,
    whichDays: [],
    calculatePer: 'week',
    onlyOnPublicHolidays: false,
    overtimeThresholdHours: 0,
    fromTime: 0,
    untilTime: 0,
  });

export const addClaAllowanceRuleInputTypeValidationBuilder =
  (): Yup.ObjectSchema<AddClaAllowanceRuleInputType> =>
    Yup.object({
      name: Yup.string().label('label.name').ensure().required().max(25),
      description: Yup.string()
        .label('label.description')
        .ensure()
        .required()
        .max(150),
      ruleType: Yup.mixed<ClaAllowanceRuleType>()
        .oneOf(Object.values(ClaAllowanceRuleType).filter((x) => !!x))
        .required()
        .label('label.ruleType'),
      contractType: Yup.mixed<EmployeeContractType>()
        .oneOf(Object.values(EmployeeContractType).filter((x) => !!x))
        .required()
        .label('label.contractType'),
      employeeType: Yup.mixed<'field-staff' | 'backoffice-staff' | 'all'>()
        .oneOf(['field-staff', 'backoffice-staff', 'all'])
        .required()
        .label('label.employeeType'),
      attributeId: Yup.string().nullable().label('label.withAttribute'),
      excludedAttributeIds: Yup.array()
        .of(Yup.string().required().label('label.attribute'))
        .required()
        .label('label.withoutAttributes'),
      percentage: Yup.number().required().label('label.percentage'),
      enabledFrom: Yup.date().required().nullable().label('label.enabledFrom'),
      onlyOnPublicHolidays: Yup.boolean()
        .required()
        .label('label.onlyOnPublicHolidays'),
      whichDays: Yup.array()
        .of(
          Yup.mixed<'mo' | 'tu' | 'we' | 'th' | 'fr' | 'sa' | 'su'>()
            .required()
            .label('label.whichDays')
            .oneOf(['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su']),
        )
        .required()
        .min(1)
        .label('label.whichDays'),
      calculatePer: Yup.mixed<'day' | 'week'>()
        .nullable()
        .label('label.calculatePer')
        .oneOf(['day', 'week']),
      overtimeThresholdHours: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .nullable()
        .moreThan(0)
        .label('label.overtimeThresholdHours'),
      fromTime: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .nullable()
        .label('label.fromTime'),
      untilTime: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .nullable()
        .label('label.untilTime'),
    });
