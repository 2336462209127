import type { Money } from '@bas/value-objects';

export interface ProjectStatistics {
  year: number;
  month: number;
  expectedCosts?: Money;
  realizedCosts?: Money;
  expectedRevenue?: Money;
  realizedRevenue?: Money;
  expectedProfit?: Money;
  realizedProfit?: Money;
  expectedMargin?: number;
  realizedMargin?: number;
  numberOfProjects: number;
  numberOfSentQuotes: number;
  numberOfAcceptedQuotes: number;
  successRate: number;
}
