import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ChangeCustomerOfProjectMutationProps = {
  projectId: Uuid;
  reference?: string | null;
  relationId: Uuid;
  projectContactPersonId?: Uuid | null;
  quoteContactPersonId?: Uuid | null;
  invoiceContactPersonId?: Uuid | null;
  invoiceAddressId: Uuid;
  quoteAddressId: Uuid;
};

export const ChangeCustomerOfProjectMutation = async ({
  projectId,
  ...values
}: ChangeCustomerOfProjectMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/projects/${projectId}/change-customer`, {
    projectId,
    ...values,
  });

export const useChangeCustomerOfProjectMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeCustomerOfProjectMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ChangeCustomerOfProjectMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeCustomerOfProjectMutationProps
  >({
    mutationFn: ChangeCustomerOfProjectMutation,
    throwOnError: false,
    ...options,
  });
