import { ErrorResponse, Uuid } from '@bas/value-objects';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type StartConversationWithEmployeeMutationProps = {
  employeeId: Uuid;
};

export const StartConversationWithEmployeeMutation = async ({
  employeeId,
  ...params
}: StartConversationWithEmployeeMutationProps): Promise<AxiosResponse> =>
  axios.post(
    'api/{tenantId}/chat/start-with-employee',
    {
      employeeId,
    },
    { params },
  );

export const useStartConversationWithEmployeeMutation = (): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  StartConversationWithEmployeeMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    StartConversationWithEmployeeMutationProps
  >({
    mutationFn: StartConversationWithEmployeeMutation,
    throwOnError: false,
  });
