import { Box } from '@bas/ui/native/base';
import { ReactHookFormTextField } from '@bas/ui/native/molecules';
import { useResponsiveProp } from '@shopify/restyle';
import { ReactElement } from 'react';
import { useIntl } from 'react-intl';

const HowDoYouWantToNameThisRoomStep = (): ReactElement => {
  const { formatMessage } = useIntl();
  const paddingHorizontal = useResponsiveProp({
    tablet: 50,
    phone: 20,
  });

  return (
    <Box paddingHorizontal={paddingHorizontal}>
      <ReactHookFormTextField
        name="name"
        label={formatMessage({ id: 'label.name' })}
        light
      />
    </Box>
  );
};

export default HowDoYouWantToNameThisRoomStep;
