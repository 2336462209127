/* eslint-disable prefer-destructuring */
import * as React from 'react';
import { ReactElement, useCallback, useMemo } from 'react';
import { colors } from '@bas/theme';
import { Declaration, DeclarationStatus } from '@bas/hrm-domain/models';
import { Box, Icon, TouchableOpacity, Typography } from '@bas/ui/native/base';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { Badge, Chip } from '@bas/ui/native/atoms';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDeclarationCategoriesRequest } from '@bas/hrm-domain/requests';
import Swipeable from 'react-native-gesture-handler/Swipeable';

export type DeclarationListItemProps = {
  declaration: Declaration;
  onCancelDeclaration?: () => void;
  onPress?: () => void;
};

const DeclarationListItem = ({
  declaration,
  onPress,
  onCancelDeclaration,
}: DeclarationListItemProps): ReactElement => {
  const { formatNumber, formatDate } = useIntl();
  let dotColor = colors.blue[500];
  if (declaration.status === DeclarationStatus.PAID) {
    dotColor = colors.green[300];
  } else if (declaration.status === DeclarationStatus.APPROVED) {
    dotColor = colors.green[300];
  } else if (declaration.status === DeclarationStatus.DISAPPROVED) {
    dotColor = colors.red[700];
  } else if (declaration.status === DeclarationStatus.CANCELLED) {
    dotColor = colors.orange[700];
  }

  let { amount } = declaration.amount;
  if (declaration.status === DeclarationStatus.PAID && declaration.paidAmount) {
    amount = declaration.paidAmount.amount;
  } else if (
    declaration.status === DeclarationStatus.APPROVED &&
    declaration.approvedAmount
  ) {
    amount = declaration.approvedAmount.amount;
  }
  const { data: declarationCategoriesData } = useDeclarationCategoriesRequest();

  const declarationCategories = useMemo(
    () => declarationCategoriesData?.data.member || [],
    [declarationCategoriesData?.data],
  );
  const category = useMemo(
    () =>
      declarationCategories.find(
        (c) => c.categoryId === declaration.categoryId,
      ),
    [declarationCategories, declaration.categoryId],
  );

  const handleRenderRightActions = useCallback(
    () => (
      <Box width={100}>
        <TouchableOpacity
          onPress={onCancelDeclaration}
          style={{ height: '100%' }}
        >
          <Box
            height="100%"
            width="100%"
            backgroundColor="deleteBackground"
            justifyContent="center"
            alignItems="center"
          >
            <Icon icon={faTimes} color="white" size={24} />
          </Box>
        </TouchableOpacity>
      </Box>
    ),
    [onCancelDeclaration],
  );

  return (
    <Swipeable
      renderRightActions={handleRenderRightActions}
      friction={2}
      enableTrackpadTwoFingerGesture
      leftThreshold={30}
      rightThreshold={40}
      enabled={[
        DeclarationStatus.PENDING,
        DeclarationStatus.DISAPPROVED,
      ].includes(declaration.status)}
      key={`${declaration.declarationId}-${declaration.status}`}
    >
      <TouchableOpacity onPress={onPress} delayPressIn={100}>
        <Box
          backgroundColor="white"
          flex={1}
          flexDirection="row"
          alignItems="center"
          flexWrap="wrap"
          paddingHorizontal={20}
          paddingVertical={1}
          justifyContent="space-between"
          gap={3}
          borderBottomWidth={1}
          borderBottomColor="titleBorder"
        >
          <Box flex={1} flexDirection="column" flexWrap="wrap" gap={0.2}>
            <Box width="100%">
              <Typography variant="medium" fontWeight={700}>
                {declaration.description}
              </Typography>
            </Box>
            <Box width="100%">
              <Typography color="subtitle1Color">{category?.name}</Typography>
            </Box>
            <Box width="100%">
              <Typography variant="body2" color="subtitle1Color">
                {formatDate(declaration.declarationDate, {
                  dateStyle: 'long',
                  timeStyle: undefined,
                })}
              </Typography>
            </Box>
          </Box>
          <Box flexDirection="column" flexWrap="wrap" justifyContent="flex-end">
            <Typography variant="medium" fontWeight={700} pb={1}>
              {formatNumber(amount / 100, {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Typography>
            <Chip>
              <Box flexDirection="row" alignItems="center" gap={0.5}>
                <Badge color={dotColor} />
                <Typography variant="body2" overrideColor={colors.lila[700]}>
                  <FormattedMessage
                    id={`declarationStatus.${declaration.status}`}
                  />
                </Typography>
              </Box>
            </Chip>
          </Box>
        </Box>
      </TouchableOpacity>
    </Swipeable>
  );
};

export default DeclarationListItem;
