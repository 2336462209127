import { ReactElement } from 'react';
import { Platform, View, ViewProps } from 'react-native';
import { useAnimatedKeyboard, useAnimatedStyle } from 'react-native-reanimated';
import { AnimatedView } from '../AnimatedView';

const KeyboardAvoidingView = ({
  children,
  style,
  ...props
}: ViewProps): ReactElement => {
  const keyboard = useAnimatedKeyboard();
  const translateStyle = useAnimatedStyle(() => ({
    transform: [{ translateY: -keyboard.height.value }],
  }));

  if (Platform.OS !== 'web') {
    return (
      <View style={style} {...props}>
        {children}
      </View>
    );
  }

  return (
    <AnimatedView style={[style, translateStyle]} {...props}>
      {children}
    </AnimatedView>
  );
};

export default KeyboardAvoidingView;
