import { ErrorResponse, TranslatedName, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ChangeTimeTypeMutationProps = {
  timeTypeId: Uuid;
  name: string;
  categoryId: Uuid;
  icon: string;
  weight: number;
  fieldService: boolean;
  isWorkingHours: boolean;
  translatedNames: TranslatedName[];
};

export const ChangeTimeTypeMutation = async ({
  timeTypeId,
  ...values
}: ChangeTimeTypeMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/hrm/time-types/${timeTypeId}/change`, {
    timeTypeId,
    ...values,
  });

export const useChangeTimeTypeMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeTimeTypeMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ChangeTimeTypeMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeTimeTypeMutationProps
  >({
    mutationFn: ChangeTimeTypeMutation,
    throwOnError: false,
    ...options,
  });
