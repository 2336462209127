import {
  ClaAllowanceRuleType,
  ClaWeekendAndPublicHolidayAllowanceRule,
} from '../types';
import { isClaAllowanceRule } from './ClaAllowanceRule';

export function isClaWeekendAndPublicHolidayAllowanceRule(
  object: unknown,
): object is ClaWeekendAndPublicHolidayAllowanceRule {
  return (
    isClaAllowanceRule(object) &&
    object.ruleType === ClaAllowanceRuleType.ClaWeekendAndPublicHoliday
  );
}
