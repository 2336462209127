import { StyleSheet } from 'react-native';
import { colors } from '@bas/theme';

export default StyleSheet.create({
  eventColor: {
    position: 'absolute',
    height: 10,
    left: -1,
    right: -1,
    top: -1,
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
    backgroundColor: colors.blue[500],
  },
  finishedEventColor: {
    backgroundColor: colors.green[300],
  },
  headerText: {
    paddingTop: 13,
  },
  header: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.lila[200],
    minHeight: 78,
    paddingBottom: 13,
  },
  backButton: {
    position: 'absolute',
    left: 20,
    bottom: 28,
    height: 24,
    width: 24,
    zIndex: 20,
  },
});
