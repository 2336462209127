import dayjs from 'dayjs';

const calculateDateOfValue = (value: number | Date) => {
  if (value instanceof Date) {
    return value;
  }
  return dayjs()
    .startOf('day')
    .add(value * 60, 'minutes')
    .toDate();
};

const hourWidths: { [key: number]: number } = {
  0: 15,
  1: 7.5,
  2: 12,
  3: 12.5,
  4: 13.5,
  5: 13,
  6: 13.5,
  7: 11.5,
  8: 13.5,
  9: 13.5,
  10: 22,
  11: 14.5,
  12: 19,
  13: 19.5,
  14: 20.5,
  15: 20,
  16: 20.5,
  17: 18.5,
  18: 20,
  19: 20.5,
  20: 26.5,
  21: 19,
  22: 23.5,
  23: 24,
};

export const calculateWidthOfTimeSliderBox = (
  value: number | Date | null | undefined,
  first: boolean,
  last: boolean,
) => {
  if (value === null || value === undefined) {
    return 0;
  }

  const date = calculateDateOfValue(value);
  const hour = date.getHours();
  const minute = date.getMinutes();
  let result = hourWidths[hour] + 7;

  if (minute === 0) {
    result += 29;
  }
  if (minute === 15) {
    result += 20;
  }
  if (minute === 30) {
    result += 27;
  }
  if (minute === 45) {
    result += 26;
  }

  if (!first) {
    result += 17;
  }

  if (!last) {
    result += 17;
  }

  return result;
};
