import * as React from 'react';
import { ReactElement, ReactNode, useMemo } from 'react';
import { TouchableOpacity, View, ViewProps } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons/faArrowLeft';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Uuid } from '@bas/value-objects';
import { colors } from '@bas/theme';
import { Skeleton } from '@bas/ui/native/atoms';
import { Box, Typography } from '@bas/ui/native/base';
import styles from './styles';

export type EventPageHeaderProps = ViewProps & {
  finished?: boolean;
  loading?: boolean;
  headerLabel?: string | ReactNode;
  subHeaderLabel?: string | ReactNode;
  onBack?: () => void;
  identityId?: Uuid;
};

const EventPageHeader = ({
  finished,
  loading,
  headerLabel,
  subHeaderLabel,
  onBack,
  style,
  identityId,
  ...props
}: EventPageHeaderProps): ReactElement => {
  const insets = useSafeAreaInsets();

  const safePaddingStyle = useMemo(
    () => ({
      paddingTop: insets.top,
      paddingLeft: insets.left,
      paddingRight: insets.right,
    }),
    [insets],
  );

  return (
    <View style={[styles.header, safePaddingStyle, style]} {...props}>
      <StatusBar style={finished ? 'dark' : 'light'} />
      <View
        style={[
          styles.eventColor,
          finished && styles.finishedEventColor,
          safePaddingStyle,
        ]}
      />
      <View style={styles.backButton}>
        <TouchableOpacity onPress={onBack}>
          <FontAwesomeIcon icon={faArrowLeft} size={24} />
        </TouchableOpacity>
      </View>
      <Box flex={1} alignItems="center">
        <Skeleton
          visible={!loading}
          width={100}
          height={21}
          style={{ marginBottom: 4, top: 7 }}
        />
        <Skeleton
          visible={!loading}
          width={250}
          height={21}
          style={{ top: 4 }}
        />
      </Box>
      <Typography style={styles.headerText} fontWeight={700}>
        {headerLabel}
      </Typography>
      <Typography overrideColor={colors.lila[600]}>{subHeaderLabel}</Typography>
    </View>
  );
};

export default EventPageHeader;
