import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { LoginMutationResponse } from './loginMutation';

export type TwoFactorLoginMutationProps = {
  token: string;
  code: string;
  tenantId?: Uuid;
};

export type TwoFactorLoginMutationResponse = LoginMutationResponse;

export const TwoFactorLoginMutation = async ({
  token,
  code,
  tenantId,
  ...params
}: TwoFactorLoginMutationProps): Promise<
  AxiosResponse<TwoFactorLoginMutationResponse>
> =>
  axios.post(
    'api/auth/two-factor-check',
    {
      tenantId,
      code,
    },
    {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

export const useTwoFactorLoginMutation = (
  options: UseMutationOptions<
    AxiosResponse<TwoFactorLoginMutationResponse>,
    AxiosError<ErrorResponse>,
    TwoFactorLoginMutationProps
  >,
): UseMutationResult<
  AxiosResponse<TwoFactorLoginMutationResponse>,
  AxiosError<ErrorResponse>,
  TwoFactorLoginMutationProps
> =>
  useMutation<
    AxiosResponse<TwoFactorLoginMutationResponse>,
    AxiosError<ErrorResponse>,
    TwoFactorLoginMutationProps
  >({
    mutationFn: TwoFactorLoginMutation,
    ...options,
    throwOnError: (error) => (error?.response?.status || 500) >= 500,
  });
