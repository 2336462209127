import { Box, Typography } from '@bas/ui/native/base';
import {
  FormattedDateTimeRange,
  FormattedMessage,
  FormattedNumber,
} from 'react-intl';
import { TimeOffRegistration } from '@bas/hrm-domain/models';
import dayjs from 'dayjs';

export type TimeOffRegistrationInformationContentSectionProps = {
  timeOffRegistration: TimeOffRegistration;
};

const TimeOffRegistrationInformationContentSection = ({
  timeOffRegistration,
}: TimeOffRegistrationInformationContentSectionProps) => (
  <Box flexDirection="row" flexWrap="wrap">
    <Box flexBasis="100%">
      <Typography variant="subtitle1" color="white">
        <FormattedMessage id="label.timeOffRegistrationInformation" />
      </Typography>
    </Box>
    <Box flexBasis="100%">
      <Typography color="white">{timeOffRegistration.reason}</Typography>
      <Typography color="white">
        {timeOffRegistration.timeOffTypeName}
      </Typography>
      <Typography color="white">
        <FormattedDateTimeRange
          from={dayjs(timeOffRegistration.start).toDate()}
          to={dayjs(timeOffRegistration.end).toDate()}
          year="numeric"
          month="short"
          day="numeric"
          hour={timeOffRegistration.fullDay ? undefined : 'numeric'}
          minute={timeOffRegistration.fullDay ? undefined : 'numeric'}
        />
      </Typography>
      <Typography color="white">{timeOffRegistration.employeeName}</Typography>
      <Typography color="white">
        <FormattedNumber
          value={timeOffRegistration.effectiveHours}
          // eslint-disable-next-line react/style-prop-object
          style="unit"
          unit="hour"
          unitDisplay="long"
          maximumFractionDigits={2}
        />
      </Typography>
    </Box>
  </Box>
);

export default TimeOffRegistrationInformationContentSection;
