import {
  isEmployeeParticipant,
  isRelationParticipant,
  isUserParticipant,
  isWhatsAppBusinessParticipant,
  isWhatsAppParticipant,
  Participant,
} from '@bas/communication-domain/models';
import { User } from 'react-native-gifted-chat/lib/Models';

export const convertParticipantToUser = (participant: Participant): User => {
  if (isEmployeeParticipant(participant)) {
    return {
      _id: participant.employeeId,
      avatar: participant.profileImageUrl,
      name: participant.name,
    };
  }
  if (isUserParticipant(participant)) {
    return {
      _id: participant.userId,
      avatar: '',
      name: '',
    };
  }
  if (isRelationParticipant(participant)) {
    return {
      _id: participant.relationId,
      avatar: participant.profileImageUrl,
      name: participant.name,
    };
  }
  if (isWhatsAppParticipant(participant)) {
    return {
      _id: participant.phoneNumber,
      avatar: participant.profileImageUrl,
      name: participant.name,
    };
  }
  if (isWhatsAppBusinessParticipant(participant)) {
    return {
      _id: participant.phoneNumberId,
      avatar: participant.profileImageUrl,
      name: participant.name,
    };
  }

  return {
    _id: 'unknown',
    name: 'unknown',
  };
};
