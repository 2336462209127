import { colors } from '@bas/theme';
import { TimeSliderBox } from '@bas/ui/native/atoms';
import { Box, Typography } from '@bas/ui/native/base';
import { FlashList } from '@shopify/flash-list';
import {
  createContext,
  memo,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';
import isEqual from 'react-fast-compare';
import { StyleSheet, ViewProps } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';

type ItemType = {
  value: number;
  label?: string | ReactNode;
};
export type SliderFieldProps = ViewProps & {
  error?: boolean;
  disabled?: boolean;
  helperText?: ReactNode | string;
  onChange: (newValue: number) => void;
  label?: ReactElement | string;
  value: number;
  values: ItemType[];
  light?: boolean;
  estimatedItemSize?: number;
};

type FieldValueContext = {
  value: number | undefined | null;
  length: number;
};

const SliderFieldContext = createContext<FieldValueContext>({
  value: null,
  length: 0,
});

const SliderBoxWithPress = memo(
  ({
    item,
    light,
    handleChange,
  }: {
    item: ItemType;
    light?: boolean;
    handleChange: (newValue: number) => void;
  }) => {
    const handlePress = useCallback(
      () => handleChange(item.value),
      [handleChange, item],
    );

    const { value } = useContext(SliderFieldContext);

    return (
      <TimeSliderBox
        light={light}
        selected={value === item.value}
        onPress={handlePress}
        paddingVertical={1}
      >
        {item.label || item.value}
      </TimeSliderBox>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps),
);

const styles = StyleSheet.create({
  label: {
    textAlign: 'center',
    paddingBottom: 7,
  },
  helperText: {
    paddingLeft: 20,
    paddingRight: 20,
  },
});

const SliderField = ({
  error,
  disabled,
  helperText,
  onChange,
  label,
  value,
  values,
  light,
  estimatedItemSize,
  ...props
}: SliderFieldProps): ReactElement => {
  const ref = useRef<FlashList<ItemType> | null>(null);

  const centerList = useCallback(
    (valueToCheck: number, animate = true): void => {
      let index = values.findIndex((item) => item.value === valueToCheck);

      if (!index && index !== 0) {
        index = Math.ceil(values.length / 2);
      }

      if (ref.current && index >= 0) {
        ref.current.scrollToIndex({
          animated: animate,
          index,
          viewPosition: 0.5,
        });
      }
    },
    [values],
  );

  const handleChange = useCallback(
    (newValue: number) => {
      onChange(newValue);
      centerList(newValue);
    },
    [centerList, onChange],
  );

  const renderItem = useCallback(
    ({ item }: { item: ItemType; index: number }) => (
      <SliderBoxWithPress
        item={item}
        handleChange={handleChange}
        light={light}
      />
    ),
    [handleChange, light],
  );

  const getKey = useCallback((item: ItemType) => item.value.toString(), []);

  const context = useMemo(
    () => ({
      value,
      length: values.length,
    }),
    [value, values.length],
  );

  const handleCenter = useCallback(() => {
    setTimeout(() => {
      centerList(value, false);
    }, 100);
  }, [centerList, value]);

  const flatList = useMemo(
    () => {
      let index = values.findIndex((item) => item.value === value);

      if (!index && index !== 0) {
        index = Math.ceil(values.length / 2);
      }

      return (
        <FlashList<ItemType>
          data={values}
          ref={ref}
          initialScrollIndex={index - 5}
          renderItem={renderItem}
          estimatedItemSize={estimatedItemSize}
          keyExtractor={getKey}
          centerContent
          onLayout={handleCenter}
          renderScrollComponent={ScrollView}
          contentContainerStyle={{
            // @ts-expect-error - workaround
            flexBasis: '100%',
            height: '100%',
          }}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getKey, renderItem, values],
  );

  return (
    <Box flexDirection="row" flexWrap="wrap" {...props} height="100%">
      <Box flexBasis="100%">
        <Typography
          variant="subtitle1"
          overrideColor={error ? colors.red[500] : colors.lila[600]}
          style={styles.label}
        >
          {label}
        </Typography>
      </Box>
      <Box flexBasis="100%" height="100%">
        <SliderFieldContext.Provider value={context}>
          {flatList}
        </SliderFieldContext.Provider>
      </Box>
      <Box flexBasis="100%">
        {helperText && (
          <Typography
            variant="body2"
            overrideColor={error ? colors.red[500] : colors.lila[600]}
            style={styles.helperText}
          >
            {helperText}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const MemoComponent = memo(SliderField, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps),
);
export default MemoComponent;
