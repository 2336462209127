import { useFormBackendHandler } from '@bas/shared/hooks';
import { createWizardStore, WizardStoreType } from '@bas/shared/state';
import { AnimatedSlide, StepState } from '@bas/ui/native/atoms';
import { Box } from '@bas/ui/native/base';
import { SuccessFullFormViewProps } from '@bas/ui/native/molecules';
import {
  calculateHookCategories,
  calculateHookIndexedSteps,
  ReactHookWizardStep,
} from '@bas/value-objects';
import { yupResolver } from '@hookform/resolvers/yup';
import cloneDeep from 'lodash/cloneDeep';
import {
  ElementType,
  FunctionComponent,
  ReactElement,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import isEqual from 'react-fast-compare';
import {
  Control,
  DefaultValues,
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
  UseFormGetValues,
  UseFormReturn,
  useWatch,
} from 'react-hook-form';
import { useSharedValue } from 'react-native-reanimated';
import useDebounce from 'react-use/lib/useDebounce';
import { AnyObject, AnyObjectSchema } from 'yup';
import { StoreApi } from 'zustand/vanilla';
import { BackendErrors } from '../BackendErrors';
import { StepsNavigationProps } from '../StepsNavigation';
import { useWizardStore, WizardContext } from './state';

export type OverrideSubmitType<TFieldValues extends FieldValues = FieldValues> =

    | ((form: UseFormReturn<TFieldValues>) => SubmitHandler<TFieldValues>)
    | undefined;

export type ExtraWizardProps = AnyObject;

export type WizardTemplateProps<
  TExtraWizardProps extends ExtraWizardProps = ExtraWizardProps,
> = TExtraWizardProps & {
  children: ReactNode;
  onNext: () => void;
  onPrevious: () => void;
  onPressSteps: () => void;
  categories: {
    category: string;
    state: StepState;
    value: number;
    stepIndexes: number[];
  }[];
  stepNavigationProps: Partial<StepsNavigationProps>;
  successFullFormViewProps?: SuccessFullFormViewProps;
};

export type WizardTemplateType<
  TExtraWizardProps extends ExtraWizardProps = ExtraWizardProps,
> = FunctionComponent<WizardTemplateProps<TExtraWizardProps>>;

const PersistValues = <TFieldValues extends FieldValues = FieldValues>({
  onPersist,
}: {
  onPersist?: (values: TFieldValues, currentStepIndex: number) => void;
}): null => {
  const values = useWatch<TFieldValues>();
  const { updateValues, getValues, getCurrentStepIndex } = useWizardStore();

  useDebounce(
    () => {
      if (isEqual(values, getValues())) {
        return;
      }

      updateValues(cloneDeep(values));
      onPersist?.(values as TFieldValues, getCurrentStepIndex());
    },
    300,
    [values, getValues, updateValues, onPersist, getCurrentStepIndex],
  );

  return null;
};

export type ReactHookWizardProps<
  TExtraWizardProps extends ExtraWizardProps = ExtraWizardProps,
  TFieldValues extends FieldValues = FieldValues,
  TReactHookWizardStep extends
    ReactHookWizardStep<TFieldValues> = ReactHookWizardStep<TFieldValues>,
> = {
  store?: StoreApi<
    WizardStoreType<TFieldValues, OverrideSubmitType<TFieldValues>>
  >;
  storeRef?: RefObject<
    StoreApi<WizardStoreType<TFieldValues, OverrideSubmitType<TFieldValues>>>
  >;
  waitForStore?: boolean;
  onDirty?: (isDirty: boolean) => void;
  wizardSteps: TReactHookWizardStep[];
  initialValues: DefaultValues<TFieldValues>;
  onSubmit: SubmitHandler<TFieldValues>;
  initialStepIndex: number;
  children?: ReactNode;
  Template: WizardTemplateType<TExtraWizardProps>;
  onRenderLabel?: (control: Control<TFieldValues>) => ReactNode | null;
  extraTemplateProps?: TExtraWizardProps;
  extraContent?: {
    component: ElementType<{
      control: Control<TFieldValues>;
    }>;
    props: TExtraWizardProps;
  }[];
  disableFormTag?: boolean;
  hideBackendErrors?: boolean;
  persist?: string;
  name: string;
  renderTitle?: (
    title: ReactNode,
    getValues: UseFormGetValues<TFieldValues>,
  ) => ReactNode;
  onPersist?: (values: TFieldValues, currentStepIndex: number) => void;
  successFullFormViewProps?: SuccessFullFormViewProps;
};

const ReactHookWizardWithContext = <
  TExtraWizardProps extends ExtraWizardProps = ExtraWizardProps,
  TFieldValues extends FieldValues = FieldValues,
  TReactHookWizardStep extends
    ReactHookWizardStep<TFieldValues> = ReactHookWizardStep<TFieldValues>,
>({
  wizardSteps,
  initialValues,
  onSubmit,
  children,
  disableFormTag,
  hideBackendErrors,
  persist,
  onPersist,
  renderTitle,
  Template,
  name,
  extraContent,
  extraTemplateProps,
  onDirty,
  onRenderLabel,
  successFullFormViewProps,
}: Omit<
  ReactHookWizardProps<TExtraWizardProps, TFieldValues, TReactHookWizardStep>,
  'store' | 'storeRef'
>): ReactElement | null => {
  const directionValue = useSharedValue<'left' | 'right'>('right');
  const [initializedFormValues, setInitializedFormValues] = useState(!persist);
  const [steps, setSteps] = useState<TReactHookWizardStep[]>(wizardSteps);

  const {
    currentStepIndex,
    currentValues,
    loadedData,
    deleteWizard,
    finishCurrentStep,
    setSubmitted,
    goBack,
    isLastStep,
    isFirstStep,
    hasBlockedNext,
    getTemporarySubmit,
    setNumberOfSteps,
    numberOfSteps,
    subSteps,
  } = useWizardStore();

  const stepsIncludingSubSteps = useMemo(() => {
    const result = wizardSteps.map((step) => {
      const subStepsForStep = (subSteps[step.key] || []).map((subStep) => ({
        ...step,
        ...subStep,
      }));

      if (subStepsForStep) {
        if (step.hideWhenSubStepsExist) {
          return subStepsForStep;
        }

        return [step, ...subStepsForStep];
      }
      return [step];
    });

    return result.flat();
  }, [subSteps, wizardSteps]);

  useEffect(() => {
    if (numberOfSteps !== steps.length) {
      setNumberOfSteps(steps.length);
    }
  }, [numberOfSteps, setNumberOfSteps, steps.length]);

  const currentStep = useMemo(
    () =>
      steps[
        currentStepIndex >= steps.length
          ? currentStepIndex - 1
          : currentStepIndex
      ],
    [steps, currentStepIndex],
  );

  const resolver = useMemo(() => {
    let result;
    if (currentStep && currentStep.validationSchema) {
      let schema: AnyObjectSchema | undefined;
      if (typeof currentStep.validationSchema === 'function') {
        schema = currentStep.validationSchema();
      } else {
        schema = currentStep.validationSchema;
      }

      if (schema) {
        result = yupResolver(schema);
      }
    }
    return result;
  }, [currentStep]);

  const defaultValues = useMemo(
    () => (!persist ? cloneDeep(initialValues) : undefined),
    [persist, initialValues],
  );

  const form = useForm<TFieldValues>({
    resolver,
    mode: 'all',
    criteriaMode: 'all',
    defaultValues,
  });

  const values = form.watch();

  useEffect(() => {
    const filteredSteps = (
      stepsIncludingSubSteps as TReactHookWizardStep[]
    ).filter((step) => {
      if (step.enabled === undefined) {
        return true;
      }

      if (typeof step.enabled === 'function') {
        return step.enabled(values);
      }

      return step.enabled;
    });

    if (filteredSteps.length !== steps.length) {
      setSteps(filteredSteps);
    }
  }, [wizardSteps, steps, values, stepsIncludingSubSteps]);

  const changingStep = useRef(false);

  const handleSubmitWithError = useFormBackendHandler(onSubmit, form);
  const handleFinishWizard = useCallback(
    async (v: TFieldValues) => {
      const result = await handleSubmitWithError(v);
      if (result !== false) {
        const isLast = isLastStep();
        if (isLast) {
          setSubmitted(true);
        }
        if (isLast && persist) {
          deleteWizard();
        }
      }
    },
    [deleteWizard, handleSubmitWithError, isLastStep, persist, setSubmitted],
  );

  const handleNextStep = useRef(async () => {
    finishCurrentStep();
    form.reset(undefined, {
      keepValues: true,
      keepDefaultValues: true,
      keepDirtyValues: true,
      keepDirty: false,
      keepErrors: false,
      keepTouched: false,
      keepIsSubmitSuccessful: false,
      keepIsSubmitted: false,
      keepSubmitCount: false,
      keepIsValid: false,
      keepIsValidating: false,
    });
  });

  useEffect(() => {
    if (persist && loadedData && !initializedFormValues) {
      form.reset(cloneDeep(currentValues || initialValues), {
        keepDefaultValues: false,
        keepValues: false,
        keepDirtyValues: false,
      });
      setInitializedFormValues(true);
    }
  }, [
    persist,
    form,
    loadedData,
    currentValues,
    initializedFormValues,
    initialValues,
  ]);

  const handleNext = useRef(
    async (disableOverriddenSubmit = false, force = false) => {
      if ((!force && changingStep.current) || hasBlockedNext()) {
        return;
      }

      changingStep.current = true;
      const isLast = isLastStep();
      let submitFunction: SubmitHandler<TFieldValues> = isLast
        ? handleFinishWizard
        : handleNextStep.current;

      const temporarySubmit = getTemporarySubmit();
      if (!disableOverriddenSubmit && temporarySubmit) {
        submitFunction = temporarySubmit(form);
      }

      directionValue.value = 'right';

      try {
        await form.handleSubmit(submitFunction)();
      } finally {
        await new Promise((r) => {
          setTimeout(r, 300);
        });
        changingStep.current = false;
      }
    },
  );

  const handlePrev = useRef(async () => {
    if (changingStep.current || isFirstStep() || form.formState.isSubmitting) {
      return;
    }

    changingStep.current = true;
    directionValue.value = 'left';
    goBack();
    await new Promise((r) => {
      setTimeout(r, 300);
    });
    changingStep.current = false;
    form.clearErrors();
    form.trigger();
  });

  useEffect(() => {
    onDirty?.(form.formState.isDirty);
  }, [form.formState.isDirty, onDirty]);

  const stepContent = useMemo(() => {
    if (!currentStep) {
      return null;
    }

    return (
      <>
        {renderTitle && renderTitle(currentStep.title, form.getValues)}

        {currentStep.component && (
          <currentStep.component
            {...(currentStep.props || {})}
            key={currentStep.key}
            onNext={handleNext.current}
          />
        )}
      </>
    );
  }, [currentStep, form.getValues, renderTitle]);

  const indexedSteps = useMemo(
    () => calculateHookIndexedSteps<TFieldValues>(steps),
    [steps],
  );

  const categories = useMemo(
    () => calculateHookCategories<TFieldValues>(steps, indexedSteps),
    [steps, indexedSteps],
  );

  const label = useMemo(
    () => onRenderLabel?.(form.control),
    [onRenderLabel, form.control],
  );

  const content = useMemo(
    () => (
      <Box flex={1} overflow="hidden">
        {/* @ts-expect-error - bit dirty but we need to pass the extra props */}
        <Template
          onNext={handleNext.current}
          onPrevious={handlePrev.current}
          categories={categories}
          {...(extraTemplateProps || {})}
          stepNavigationProps={{
            label,
            ...(extraTemplateProps?.stepNavigationProps || {}),
          }}
          successFullFormViewProps={successFullFormViewProps}
        >
          <AnimatedSlide
            direction={directionValue}
            currentStepIndex={currentStepIndex}
          >
            <Box height="100%" width="100%">
              {stepContent}
            </Box>
          </AnimatedSlide>
        </Template>
      </Box>
    ),
    [
      label,
      categories,
      Template,
      currentStepIndex,
      directionValue,
      extraTemplateProps,
      stepContent,
      successFullFormViewProps,
    ],
  );

  const renderedExtraContent = useMemo(() => {
    if (!extraContent) {
      return null;
    }

    return extraContent.map((item, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <item.component key={index} {...item.props} control={form.control} />
    ));
  }, [extraContent, form.control]);

  if (persist && (!loadedData || !initializedFormValues)) {
    return null;
  }

  if (!currentStep) {
    // eslint-disable-next-line no-console
    console.error(
      'No Step given',
      currentStepIndex,
      steps,
      currentStepIndex >= steps.length
        ? currentStepIndex - 1
        : currentStepIndex,
      steps[
        currentStepIndex >= steps.length
          ? currentStepIndex - 1
          : currentStepIndex
      ],
    );
    return null;
  }

  if (disableFormTag) {
    return content;
  }

  return (
    <Box flex={1}>
      <FormProvider {...form}>
        <BackendErrors />
        {persist && <PersistValues onPersist={onPersist} />}
        {content}
        {renderedExtraContent}
      </FormProvider>
    </Box>
  );
};

export const ReactHookWizard = <
  TExtraWizardProps extends ExtraWizardProps = ExtraWizardProps,
  TFieldValues extends FieldValues = FieldValues,
  TReactHookWizardStep extends
    ReactHookWizardStep<TFieldValues> = ReactHookWizardStep<TFieldValues>,
>({
  store,
  storeRef,
  waitForStore,
  ...props
}: ReactHookWizardProps<
  TExtraWizardProps,
  TFieldValues,
  TReactHookWizardStep
>): ReactElement | null => {
  const createStore = useMemo(
    () =>
      createWizardStore<TFieldValues, OverrideSubmitType<TFieldValues>>(
        props.persist || props.name,
        props.initialStepIndex,
        !!props.persist,
      ),
    [props.initialStepIndex, props.name, props.persist],
  );

  if (waitForStore && !store && !storeRef?.current) {
    return null;
  }

  return (
    <WizardContext.Provider value={storeRef?.current || store || createStore}>
      <ReactHookWizardWithContext {...props} />
    </WizardContext.Provider>
  );
};

export default ReactHookWizard;
