import * as React from 'react';
import { ReactElement } from 'react';
import { FontAwesomeIcon, Props } from '@fortawesome/react-native-fontawesome';

type DrawerIconProps = Props;

const DrawerIcon = ({ ...props }: DrawerIconProps): ReactElement => (
  <FontAwesomeIcon size={18} {...props} />
);

export default DrawerIcon;
