import {
  useQuotesRequest,
  useTurnoverGroupsRequest,
  useVatCodesRequest,
} from '@bas/financial-domain/requests';
import { usePackagesRequest } from '@bas/project-domain/requests';
import { useTenantStore } from '@bas/shared/state';
import {
  useDocumentTemplatesRequest,
  useIdentitiesRequest,
  usePublicFurnitureTypesRequest,
  usePublicRoomTypesRequest,
  usePublicServiceTypesRequest,
} from '@bas/tenant-domain/requests';
import { DocumentType } from '@bas/value-objects';
import {
  usePublicMovingBoxesRequest,
  useReusableMaterialsRequest,
} from '@bas/wms-domain/requests';
import { keepPreviousData } from '@tanstack/react-query';

export const usePreloadData = ({ projectId }: { projectId?: string }) => {
  const tenant = useTenantStore((state) => state.tenant);

  useDocumentTemplatesRequest({
    perPage: 99999,
    'documentType.value': DocumentType.QUOTE,
  });
  usePackagesRequest({ perPage: 9999 });
  useIdentitiesRequest({}, { enabled: tenant?.useIdentities || false });
  usePublicFurnitureTypesRequest();
  usePublicMovingBoxesRequest();
  usePublicRoomTypesRequest();
  usePublicServiceTypesRequest();
  useReusableMaterialsRequest(
    {
      page: 1,
      perPage: 999999,
    },
    {
      placeholderData: keepPreviousData,
    },
  );
  useTurnoverGroupsRequest(
    {
      perPage: 9999,
    },
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  );
  useVatCodesRequest(
    {
      perPage: 99999,
    },
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  );

  useQuotesRequest(
    {
      projectId: projectId || '',
    },
    { enabled: !!projectId },
  );
};
