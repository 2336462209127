import { Project } from '@bas/project-domain/models';
import { Uuid } from '@bas/value-objects';
import { InventoryMovementInputType } from '@bas/wms-domain/input-types';
import {
  useReportInventoryInMutation,
  useReportWornOutInventoryMutation,
} from '@bas/wms-domain/mutations';
import { useCallback } from 'react';

export type HandleRetrieveMaterialsProps = {
  project?: Project;
  eventId: Uuid;
  callback?: () => void | Promise<void>;
};

export const useHandleRetrieveMaterials = ({
  project,
  eventId,
  callback,
}: HandleRetrieveMaterialsProps) => {
  const { mutateAsync: reportInventoryInMutation } =
    useReportInventoryInMutation();
  const { mutateAsync: reportWornOutInventoryMutation } =
    useReportWornOutInventoryMutation();

  return useCallback(
    async (values: { materials: InventoryMovementInputType[] }) => {
      if (project) {
        await Promise.all(
          values.materials.map(
            async ({ inventoryItemId, quantity, quantityWornOut }) => {
              if (
                (!!quantity && quantity > 0) ||
                (!!quantityWornOut && quantityWornOut > 0)
              ) {
                let inQuantity = 0;
                if (quantity) {
                  inQuantity += quantity;
                }

                if (quantityWornOut) {
                  inQuantity += quantityWornOut;
                }

                await reportInventoryInMutation({
                  inventoryItemId,
                  quantity: inQuantity,
                  projectId: project.projectId,
                  customerId: project.customer.relationId,
                  eventDate: new Date(),
                  planningEventId: eventId,
                });
              }

              if (!!quantityWornOut && quantityWornOut > 0) {
                await reportWornOutInventoryMutation({
                  inventoryItemId,
                  quantity: quantityWornOut,
                  projectId: project.projectId,
                  customerId: project.customer.relationId,
                  eventDate: new Date(),
                  planningEventId: eventId,
                });
              }

              if (callback) {
                callback();
              }
            },
          ),
        );
      }
    },
    [
      callback,
      eventId,
      project,
      reportInventoryInMutation,
      reportWornOutInventoryMutation,
    ],
  );
};
