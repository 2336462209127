import { isFeature } from './Feature';
import { FeatureType, TimeRegistrationFeature } from '../types';

export function isTimeRegistrationFeature(
  object: unknown,
): object is TimeRegistrationFeature {
  return (
    isFeature(object) && object.featureType === FeatureType.TIME_REGISTRATION
  );
}
