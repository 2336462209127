import { isInvoiceEvent } from './InvoiceEvent';
import { InvoiceEventType, InvoicePaidEvent } from '../types';

export function isInvoicePaidEvent(
  object: unknown,
): object is InvoicePaidEvent {
  return (
    isInvoiceEvent(object) &&
    object.eventType === InvoiceEventType.InvoicePaidEvent
  );
}
