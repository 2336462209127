import { QuoteLineType } from '@bas/financial-domain/models';
import * as Yup from 'yup';
import {
  CreatedFinancialLineInputType,
  FinancialLineInputType,
  financialLineInputTypeValidatorBuilder,
  isCreatedFinancialLineInputType,
  isFinancialLineInputType,
} from './financialLineInputType';

type CreatedQuoteLineInput = {
  optional: boolean;
  hourlyRate: boolean;
  lineType: QuoteLineType;
};

export type QuoteLineInputType = FinancialLineInputType & CreatedQuoteLineInput;

export function isCreatedQuoteLineInputType(
  object: unknown,
): object is CreatedFinancialLineInputType & CreatedQuoteLineInput {
  return isCreatedFinancialLineInputType(object);
}

export const quoteLineInputTypeValidatorBuilder =
  (): Yup.ObjectSchema<QuoteLineInputType> =>
    financialLineInputTypeValidatorBuilder(
      Yup.object({
        optional: Yup.boolean().required().label('label.optional'),
        hourlyRate: Yup.boolean().required().label('label.hourlyRate'),
        lineType: Yup.mixed<QuoteLineType>()
          .oneOf(Object.values(QuoteLineType))
          .required()
          .label('label.lineType'),
      }),
    ) as Yup.ObjectSchema<QuoteLineInputType>;

export function isQuoteLineInputType(
  object: unknown,
): object is QuoteLineInputType {
  return (
    isFinancialLineInputType(object) &&
    Object.prototype.hasOwnProperty.call(object, 'optional') &&
    Object.prototype.hasOwnProperty.call(object, 'lineType')
  );
}
