import { ProjectType } from '@bas/value-objects';
import type { ListedMovingLiftJob } from '../types';
import { isListedProject } from './ListedProject';

export function isListedMovingLiftJob(
  object: unknown,
): object is ListedMovingLiftJob {
  return (
    isListedProject(object) &&
    object.projectType === ProjectType.MOVING_LIFT_JOB
  );
}
