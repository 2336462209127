import { Employee } from '@bas/hrm-domain/models';
import { EventParticularsInputType } from '@bas/planning-domain/input-types';
import { Damage } from '@bas/project-domain/models';
import { ReportDamageBottomSheet } from '@bas/project-domain/native/organisms';
import { DamageInputType } from '@bas/shared/input-types';
import { colors } from '@bas/theme';
import { BlueSelectButton, Button } from '@bas/ui/native/atoms';
import { BottomSheetMethods, Box, Typography } from '@bas/ui/native/base';
import {
  BlueSelectButtonsGroup,
  DamageSummaryItem,
  ReactHookFormTextField,
} from '@bas/ui/native/molecules';
import { Address } from '@bas/value-objects';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { ReactElement, useCallback, useEffect, useMemo, useRef } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { ScrollView } from 'react-native-gesture-handler';
import { v7 } from 'uuid';
import styles from './styles';

export type WereTheAnyParticularsStepProps = {
  damages: Damage[];
  employees: Employee[];
  addresses: Address[];
};

const WereTheAnyParticularsStep = ({
  damages: alreadyProcessedDamages,
  employees,
  addresses,
}: WereTheAnyParticularsStepProps): ReactElement => {
  const reportDamageRef = useRef<BottomSheetModal & BottomSheetMethods>(null);
  const { formatMessage } = useIntl();
  const { setValue } = useFormContext<EventParticularsInputType>();
  const wereThereAnyParticulars = useWatch<
    EventParticularsInputType,
    'wereThereAnyParticulars'
  >({
    name: 'wereThereAnyParticulars',
  });

  const damagesValues = useWatch<EventParticularsInputType, 'damages'>({
    name: 'damages',
  });

  const { append, fields: damagesFields } = useFieldArray<
    EventParticularsInputType,
    'damages'
  >({
    name: 'damages',
  });

  const damages = useMemo(
    () =>
      damagesFields.map((damage) => {
        const damageValue =
          (damagesValues || []).find(
            ({ damageId }) => damageId === damage.damageId,
          ) || {};

        return { ...damage, ...damageValue };
      }),
    [damagesFields, damagesValues],
  );

  const handleReportDamage = useCallback(
    async (values: DamageInputType) => {
      append({ ...values, damageId: v7() });
      reportDamageRef.current?.close();
      reportDamageRef.current?.dismiss();
    },
    [append],
  );

  useEffect(() => {
    if (!wereThereAnyParticulars && alreadyProcessedDamages.length > 0) {
      setValue('wereThereAnyParticulars', alreadyProcessedDamages.length > 0);
    }
  }, [alreadyProcessedDamages.length, setValue, wereThereAnyParticulars]);

  return (
    <ScrollView>
      <Box paddingHorizontal={20} gap={4}>
        <Box>
          <BlueSelectButtonsGroup
            buttons={[
              <BlueSelectButton
                notSelected={
                  wereThereAnyParticulars !== undefined &&
                  wereThereAnyParticulars !== true
                }
                onSelect={() =>
                  setValue('wereThereAnyParticulars', true, {
                    shouldDirty: true,
                    shouldTouch: true,
                    shouldValidate: true,
                  })
                }
                icon={faCheckCircle}
              >
                <FormattedMessage id="label.yes" />
              </BlueSelectButton>,
              <BlueSelectButton
                notSelected={
                  wereThereAnyParticulars !== undefined &&
                  wereThereAnyParticulars !== false
                }
                onSelect={() =>
                  setValue('wereThereAnyParticulars', false, {
                    shouldDirty: true,
                    shouldTouch: true,
                    shouldValidate: true,
                  })
                }
                icon={faTimes}
              >
                <FormattedMessage id="label.no" />
              </BlueSelectButton>,
            ]}
          />
        </Box>
        {wereThereAnyParticulars && (
          <>
            <Button onPress={() => reportDamageRef?.current?.present()}>
              <FormattedMessage id="button.reportDamage" />
            </Button>
            <ReactHookFormTextField
              name="particulars"
              light
              label={formatMessage({ id: 'label.particulars' })}
              multiline
            />
            <ReactHookFormTextField
              name="internalParticulars"
              light
              label={formatMessage({ id: 'label.internalParticulars' })}
              multiline
            />
          </>
        )}
        {wereThereAnyParticulars && (damages || []).length > 0 && (
          <Box>
            <Typography
              variant="h4"
              fontWeight={700}
              overrideColor={colors.white}
            >
              <FormattedMessage id="label.damages" />
            </Typography>
            <Box>
              {damages.map((damage) => (
                <Box key={damage.id} style={styles.paddingBetweenDamages}>
                  <DamageSummaryItem
                    damage={damage}
                    employees={employees}
                    color={colors.white}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        )}
        {wereThereAnyParticulars && alreadyProcessedDamages.length > 0 && (
          <Box>
            <Typography
              variant="h4"
              fontWeight={700}
              overrideColor={colors.white}
            >
              <FormattedMessage id="label.theFollowingDamagesAreAlreadyProcessed" />
            </Typography>
            <Box>
              {alreadyProcessedDamages.map((damage) => (
                <Box key={damage.damageId} style={styles.paddingBetweenDamages}>
                  <DamageSummaryItem
                    damage={damage}
                    employees={employees}
                    color={colors.white}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        )}
        <ReportDamageBottomSheet
          onSubmit={handleReportDamage}
          employees={employees}
          addresses={addresses}
          directlyFinalize
          ref={reportDamageRef}
        />
      </Box>
    </ScrollView>
  );
};

export default WereTheAnyParticularsStep;
