import { formatDate } from '@bas/shared/utils';
import { ErrorResponse, Money, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ProcessInvoicePaymentMutationProps = {
  invoiceId: Uuid;
  paidAt: Date;
  amount: Money;
  remarks?: string | null;
};

export const ProcessInvoicePaymentMutation = async ({
  invoiceId,
  paidAt,
  amount,
  ...values
}: ProcessInvoicePaymentMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/invoices/${invoiceId}/add-manual-payment`, {
    invoiceId,
    paidAt: formatDate(paidAt),
    amount: {
      ...amount,
      currency: amount.currency || 'EUR',
    },
    ...values,
  });

export const useProcessInvoicePaymentMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ProcessInvoicePaymentMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ProcessInvoicePaymentMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ProcessInvoicePaymentMutationProps
  >({
    mutationFn: ProcessInvoicePaymentMutation,
    throwOnError: false,
    ...options,
  });
