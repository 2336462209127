import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  niceJob: {
    textAlign: 'center',
  },
  title: {
    textAlign: 'center',
    paddingBottom: 32,
  },
  avatar: {
    overflow: 'hidden',
    borderRadius: 269,
    height: 268,
    width: 268,
    marginBottom: 29,
    marginTop: 29,
    alignSelf: 'center',
  },
});
