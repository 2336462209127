import { formatDate, formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, Money, Uuid, VolumeType } from '@bas/value-objects';
import { StoredItemInputType } from '@bas/wms-domain/input-types';
import { StorageInvoicingPeriod } from '@bas/wms-domain/models';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ReserveStorageForCustomerMutationProps = {
  storageId: Uuid;
  contentOwnerId: Uuid;
  turnoverGroupId?: Uuid | null;
  vatCodeId: Uuid;
  percentageFull: number;
  storedItems: StoredItemInputType[];
  identityId?: Uuid | null;
  projectId?: Uuid | null;
  invoicingPeriod: StorageInvoicingPeriod;
  price: Money;
  invoicingStartDate?: Date;
  includingVat: boolean;
  lastInvoicedDate?: Date;
  notes?: string | null;
  billStorageInAdvance?: boolean | null;
  invoicableVolume: number | null;
  setVolumeToQuantity: boolean | null;
  invoicePerVolume: boolean | null;
  reservedByEmployeeId: Uuid;
  volumeType: VolumeType | null;
  invoiceReference: string | null | undefined;
};

export const ReserveStorageForCustomerMutation = async ({
  storageId,
  invoicingStartDate,
  lastInvoicedDate,
  storedItems,
  ...values
}: ReserveStorageForCustomerMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/storages/${storageId}/reserve`, {
    storageId,
    invoicingStartDate: invoicingStartDate
      ? formatDate(invoicingStartDate)
      : null,
    lastInvoicedDate: lastInvoicedDate ? formatDate(lastInvoicedDate) : null,
    storedItems: storedItems
      .filter((item) => !item.delete)
      .map((item) => ({
        ...item,
        loadedOn: item.loadedOn ? formatDateTime(item.loadedOn) : null,
        unloadedOn: item.unloadedOn ? formatDateTime(item.unloadedOn) : null,
      })),
    ...values,
  });

export const useReserveStorageForCustomerMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ReserveStorageForCustomerMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ReserveStorageForCustomerMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ReserveStorageForCustomerMutationProps
  >({
    mutationFn: ReserveStorageForCustomerMutation,
    throwOnError: false,
    ...options,
  });
