import { Language, TranslatedName } from '@bas/value-objects';
import * as Yup from 'yup';

export type DeclarationCategoryInputType = {
  name: string;
  weight: number;
  translatedNames: TranslatedName[];
  icon: string;
  iconValue?: {
    value?: string;
  };
};

export const declarationCategoryInputTypeDefaultValues =
  (): DeclarationCategoryInputType => ({
    name: '',
    weight: 0,
    translatedNames: [],
    icon: '',
    iconValue: {
      value: '',
    },
  });

export const declarationCategoryInputTypeValidationBuilder =
  (): Yup.ObjectSchema<DeclarationCategoryInputType> =>
    Yup.object({
      name: Yup.string().max(200).required().label('label.name'),
      weight: Yup.number().required().label('label.sortingWeight'),
      translatedNames: Yup.array()
        .of(
          Yup.object({
            locale: Yup.mixed<Language>()
              .oneOf(Object.values(Language))
              .required()
              .label('label.locale'),
            name: Yup.string().max(200).required().label('label.name'),
          }).required(),
        )
        .required(),
      icon: Yup.string().required().label('label.icon'),
      iconValue: Yup.object({
        value: Yup.string().label('label.icon').optional(),
      }).optional(),
    });
