import { formatDate } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type AddNeededInventoryItemsToEventMutationProps = {
  eventId: Uuid;
  inventoryItemId: Uuid;
  quantity: number;
  date?: Date | null;
  updateFutureEvents: boolean;
};

export const AddNeededInventoryItemToEventMutation = async ({
  eventId,
  date,
  ...values
}: AddNeededInventoryItemsToEventMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/events/${eventId}/add-needed-inventory-item`, {
    eventId,
    date: date ? formatDate(date) : undefined,
    ...values,
  });

export const useAddNeededInventoryItemsToEventMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddNeededInventoryItemsToEventMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  AddNeededInventoryItemsToEventMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddNeededInventoryItemsToEventMutationProps
  >({
    mutationFn: AddNeededInventoryItemToEventMutation,
    throwOnError: false,
    ...options,
  });
