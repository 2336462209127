import { colors } from '@bas/theme';
import { BottomSheetMethods, Box, Typography } from '@bas/ui/native/base';
import {
  BottomSheetProps,
  StepsNavigationProps,
} from '@bas/ui/native/organisms';
import { FormWizardBottomSheet } from '@bas/ui/native/templates';
import { ReactHookWizardStep, Uuid } from '@bas/value-objects';
import {
  LoadOrUnloadStorageWithInventoryInputType,
  loadOrUnloadStorageWithInventoryInputTypeDefaultValues,
  loadOrUnloadStorageWithInventoryInputTypeValidationBuilder,
} from '@bas/wms-domain/input-types';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import {
  ComponentProps,
  ReactElement,
  ReactNode,
  RefObject,
  useCallback,
  useMemo,
} from 'react';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import { HandlingCostsForm } from '../HandlingCostsForm';
import { LoadOrUnloadItemsIntoStorageForm } from '../LoadOrUnloadItemsIntoStorageForm';
import { VolumeChangeForm } from '../VolumeChangeForm';

type InputType = LoadOrUnloadStorageWithInventoryInputType & {
  selectedStorageId?: Uuid;
};
export type LoadItemsIntoStorageFormWizardBottomSheetProps = Omit<
  BottomSheetProps,
  'snapPoints' | 'children'
> & {
  bottomSheetRef?: RefObject<BottomSheetModal & BottomSheetMethods>;
  onSubmit: (data: InputType) => void;
};

const LoadItemsIntoStorageFormWizardBottomSheet = ({
  bottomSheetRef,
  onSubmit,
}: LoadItemsIntoStorageFormWizardBottomSheetProps): ReactElement => {
  const onClose = useCallback(() => {
    bottomSheetRef?.current?.close();
    bottomSheetRef?.current?.forceClose();
    bottomSheetRef?.current?.dismiss();
  }, [bottomSheetRef]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const steps: ReactHookWizardStep<InputType, any>[] = useMemo(
    () => [
      {
        key: 'loadItems',
        category: 'loadItems',
        title: undefined,
        validationSchema:
          loadOrUnloadStorageWithInventoryInputTypeValidationBuilder().concat(
            Yup.object({
              selectedStorageId: Yup.string().required().label('label.storage'),
            }),
          ),
        component: LoadOrUnloadItemsIntoStorageForm,
        props: {
          action: 'load',
        },
      } as ReactHookWizardStep<
        InputType,
        ComponentProps<typeof LoadOrUnloadItemsIntoStorageForm>
      >,
      {
        key: 'handlingCosts',
        category: 'handlingCosts',
        title: 'label.handlingCosts',
        validationSchema:
          loadOrUnloadStorageWithInventoryInputTypeValidationBuilder().concat(
            Yup.object({
              selectedStorageId: Yup.string().required().label('label.storage'),
            }),
          ),
        component: HandlingCostsForm,
      } as ReactHookWizardStep<InputType>,
      {
        key: 'volumeChange',
        category: 'volumeChange',
        title: 'label.volumeChange',
        enabled: ({ invoicePerVolume }) => !!invoicePerVolume,
        validationSchema:
          loadOrUnloadStorageWithInventoryInputTypeValidationBuilder().concat(
            Yup.object({
              selectedStorageId: Yup.string().required().label('label.storage'),
            }),
          ),
        component: VolumeChangeForm,
        props: {
          action: 'load',
        },
      } as ReactHookWizardStep<
        InputType,
        ComponentProps<typeof VolumeChangeForm>
      >,
    ],
    [],
  );
  const renderTitle = useCallback((data: unknown, title: ReactNode) => {
    if (!title) {
      return undefined;
    }

    return (
      <Box width="100%" pb={3}>
        <Typography
          color="white"
          variant="h4"
          fontWeight={700}
          textAlign="center"
        >
          <FormattedMessage id="label.loadItemsIntoStorage" />
        </Typography>
        <Typography variant="h5" color="white" textAlign="center">
          {typeof title === 'string' ? <FormattedMessage id={title} /> : title}
        </Typography>
      </Box>
    );
  }, []);

  return (
    <FormWizardBottomSheet<
      { stepNavigationProps: Partial<StepsNavigationProps> },
      InputType,
      unknown
    >
      name="load-items-into-storage"
      disablePadding
      enableDismissOnClose
      onSubmit={onSubmit}
      resetFormOnOpen
      onCancel={onClose}
      disableScroll
      hideBackendErrors
      initialValues={{
        ...loadOrUnloadStorageWithInventoryInputTypeDefaultValues(),
      }}
      successFullFormViewProps={{
        gifUrl:
          'https://media.giphy.com/media/EOlw4aQjGBYkw/giphy-downsized-large.gif',
        title: 'label.loadedStorage.finished',
        color: colors.lila[100],
        labelId: 'label.itemsAreLoadedIntoStorage',
      }}
      extraTemplateProps={{
        stepNavigationProps: {
          dark: true,
          allowCancel: true,
          onCancel: onClose,
          onClose,
        },
      }}
      bottomSheetRef={bottomSheetRef}
      initialStepIndex={0}
      renderTitle={renderTitle}
      wizardSteps={steps}
    />
  );
};

export default LoadItemsIntoStorageFormWizardBottomSheet;
