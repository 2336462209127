import { InventoryListSettingType } from '../types';

export function isInventoryListSettingType(
  option: unknown,
): option is InventoryListSettingType {
  return (
    typeof option === 'string' &&
    Object.values(InventoryListSettingType).includes(
      option as InventoryListSettingType,
    )
  );
}
