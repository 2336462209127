import { ErrorResponse, Uuid } from '@bas/value-objects';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type EmployeeOpenedConversationMutationProps = {
  conversationId: Uuid;
  employeeId: Uuid;
};

export const EmployeeOpenedConversationMutation = async ({
  conversationId,
  employeeId,
  ...params
}: EmployeeOpenedConversationMutationProps): Promise<AxiosResponse> =>
  axios.post(
    `api/{tenantId}/chat/conversations/${conversationId}/employee-opened`,
    {
      conversationId,
      employeeId,
    },
    { params },
  );

export const useEmployeeOpenedConversationMutation = (): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  EmployeeOpenedConversationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    EmployeeOpenedConversationMutationProps
  >({
    mutationFn: EmployeeOpenedConversationMutation,
    throwOnError: false,
  });
