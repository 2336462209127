import { EmailAddress, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type LoginMutationProps = {
  emailAddress: EmailAddress;
  password: string;
  deviceId: Uuid;
  tenantId?: Uuid;
};

export type LoginMutationResponse = {
  employeeId: Uuid;
  userId: Uuid;
  intercomHash: string;
  mercureToken: string;
  token: string;
  twoFactorNeeded: boolean;
  expiresAt?: Date;
  sessionId: Uuid;
};

export const LoginMutation = async ({
  emailAddress,
  password,
  deviceId,
  tenantId,
  ...params
}: LoginMutationProps): Promise<AxiosResponse<LoginMutationResponse>> =>
  axios.post(
    'api/auth/login',
    { email: emailAddress.toLowerCase(), password, deviceId, tenantId },
    {
      params,
    },
  );

export const useLoginMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    LoginMutationProps
  >,
): UseMutationResult<
  AxiosResponse<LoginMutationResponse>,
  AxiosError<ErrorResponse>,
  LoginMutationProps
> =>
  useMutation<
    AxiosResponse<LoginMutationResponse>,
    AxiosError<ErrorResponse>,
    LoginMutationProps
  >({
    mutationFn: LoginMutation,
    ...options,
    throwOnError: (error) => (error?.response?.status || 500) >= 500,
  });
