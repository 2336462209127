import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ForceRerenderInvoiceMutationProps = {
  invoiceId: Uuid;
};

export const ForceRerenderInvoiceMutation = async ({
  invoiceId,
  ...values
}: ForceRerenderInvoiceMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/invoices/${invoiceId}/force-rerender`, {
    invoiceId,
    ...values,
  });

export const useForceRerenderInvoiceMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ForceRerenderInvoiceMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ForceRerenderInvoiceMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ForceRerenderInvoiceMutationProps
  >({
    mutationFn: ForceRerenderInvoiceMutation,
    throwOnError: false,
    ...options,
  });
