import {
  initialCrmAddressInputType,
  initialCrmContactPersonInputType,
} from '@bas/crm-domain/input-types';
import { Money, Uuid } from '@bas/value-objects';
import * as Yup from 'yup';
import {
  FinancialDocumentContentInputType,
  FinancialDocumentContentInputTypeValidationBuilder,
} from './financialDocumentContentInputType';
import {
  FinancialDocumentCustomerInformationInputType,
  FinancialDocumentCustomerInformationInputTypeValidationBuilder,
} from './financialDocumentCustomerInformationInputType';
import {
  FinancialDocumentInputType,
  FinancialDocumentInputTypeValidationBuilder,
} from './financialDocumentInputType';
import { InvoiceLineInputType } from './invoiceLineInputType';

export type CreateInvoiceInputType =
  FinancialDocumentContentInputType<InvoiceLineInputType> &
    FinancialDocumentCustomerInformationInputType &
    FinancialDocumentInputType & {
      projectId?: Uuid;
      shouldChargeVat: boolean;
      totalIncludingVat: Money;
      expiresAt?: Date | null;
      invoicedAt?: Date | null;
      sendInvoice?: boolean | null;
      finishInvoice?: boolean | null;
    };

export const CreateInvoiceInputTypeDefaultValues =
  (): CreateInvoiceInputType => ({
    contactPersonId: '',
    addressId: '',
    templateId: '',
    identityId: '',
    projectId: '',
    includingVat: true,
    ignoreZeroQuantityLines: false,
    shouldChargeVat: true,
    freeText: {},
    lines: [],
    totalIncludingVat: {
      amount: 0,
      currency: 'EUR',
    },
    reference: '',
    contactPerson: { ...initialCrmContactPersonInputType() },
    address: { ...initialCrmAddressInputType() },
    expiresAt: null,
    invoicedAt: null,
    finishInvoice: true,
    discount: {
      percentage: null,
      description: '',
    },
  });

export const CreateInvoiceInputTypeValidationBuilder = (): Yup.ObjectSchema<
  Omit<CreateInvoiceInputType, 'relation' | 'address' | 'contactPerson'>
> =>
  Yup.object({
    projectId: Yup.string().label('label.projectId'),
    shouldChargeVat: Yup.boolean().required().label('label.shouldChargeVat'),
    totalIncludingVat: Yup.object({
      amount: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .nullable()
        .required()
        .label('label.pricePerUnit'),
      currency: Yup.string().required(),
    }),
    invoicedAt: Yup.date().nullable().label('label.invoicedAt'),
    expiresAt: Yup.date().nullable().label('label.expiresAt'),
    sendInvoice: Yup.boolean().nullable().label('label.sendInvoice'),
    finishInvoice: Yup.boolean().nullable().label('label.finishInvoice'),
  })
    .concat(FinancialDocumentCustomerInformationInputTypeValidationBuilder())
    .concat(FinancialDocumentContentInputTypeValidationBuilder())
    .concat(FinancialDocumentInputTypeValidationBuilder());
