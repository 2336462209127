import { faFile } from '@fortawesome/pro-light-svg-icons/faFile';
import { faFileExcel } from '@fortawesome/pro-light-svg-icons/faFileExcel';
import { faFilePdf } from '@fortawesome/pro-light-svg-icons/faFilePdf';
import { faFilePowerpoint } from '@fortawesome/pro-light-svg-icons/faFilePowerpoint';
import { faFileWord } from '@fortawesome/pro-light-svg-icons/faFileWord';
import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export const iconMap: { [key: string]: IconDefinition } = {
  'application/pdf': faFilePdf,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    faFileWord,
  'application/msword': faFileWord,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    faFileExcel,
  'application/vnd.ms-excel': faFileExcel,
  'application/vnd.ms-powerpoint': faFilePowerpoint,
  'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    faFilePowerpoint,
  'application/vnd.oasis.opendocument.text': faFileWord,
  'application/vnd.oasis.opendocument.spreadsheet': faFileExcel,
  'application/vnd.oasis.opendocument.presentation': faFilePowerpoint,
  'application/vnd.oasis.opendocument.graphics': faFile,
};
