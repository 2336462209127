import * as Yup from 'yup';

export interface ProjectNotesInputType {
  notes: string | null;
  notesForEmployees: string | null;
  customerNotes: string | null;
}

export const ProjectNotesInputTypeDefaultValues =
  (): ProjectNotesInputType => ({
    notes: '',
    notesForEmployees: '',
    customerNotes: '',
  });

export const ProjectNotesInputTypeValidationBuilder =
  (): Yup.ObjectSchema<ProjectNotesInputType> =>
    Yup.object({
      notes: Yup.string().label('notes').nullable().defined(),
      notesForEmployees: Yup.string()
        .label('notesForEmployees')
        .nullable()
        .defined(),
      customerNotes: Yup.string().label('customerNotes').nullable().defined(),
    });
