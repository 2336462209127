import { ErrorResponse, Uuid, WorkingDay } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type UpdateTenantWorkingScheduleMutationProps = {
  tenantId: Uuid;
  workingScheduleId: Uuid;
  monday: WorkingDay;
  tuesday: WorkingDay;
  wednesday: WorkingDay;
  thursday: WorkingDay;
  friday: WorkingDay;
  saturday: WorkingDay;
  sunday: WorkingDay;
};

export const UpdateTenantWorkingScheduleMutation = async ({
  workingScheduleId,
  ...values
}: UpdateTenantWorkingScheduleMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/working-schedules/${workingScheduleId}`, {
    workingScheduleId,
    ...values,
  });

export const useUpdateTenantWorkingScheduleMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateTenantWorkingScheduleMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  UpdateTenantWorkingScheduleMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateTenantWorkingScheduleMutationProps
  >({
    mutationFn: UpdateTenantWorkingScheduleMutation,
    throwOnError: false,
    ...options,
  });
