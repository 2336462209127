import { useUploadFileToCreateAMediaObjectMutation } from '@bas/media-domain/mutations';
import { GiftedChatIMessage } from '@bas/shared/models';
import { colors } from '@bas/theme';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons/faPlusCircle';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import * as DocumentPicker from 'expo-document-picker';
import * as ImagePicker from 'expo-image-picker';
import { ImagePickerAsset } from 'expo-image-picker/src/ImagePicker.types';
import * as Location from 'expo-location';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { v7 } from 'uuid';

const styles = StyleSheet.create({
  container: {
    color: colors.white,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export type GiftedChatActionsProps = {
  onSend: (messages: GiftedChatIMessage[]) => void;
};

const GiftedChatActions = ({ onSend }: GiftedChatActionsProps) => {
  const { showActionSheetWithOptions } = useActionSheet();
  const { formatMessage } = useIntl();

  const [status, requestPermission] = ImagePicker.useCameraPermissions();
  const [statusMedia, requestPermissionMedia] =
    ImagePicker.useMediaLibraryPermissions();

  const { mutateAsync: uploadFile } =
    useUploadFileToCreateAMediaObjectMutation();

  const handleUploadImages = useCallback(
    async (files: ImagePickerAsset[], image = true) => {
      files.map(async (file) => {
        const fileSize = (file.fileSize || 0) / 1024 / 1024;
        if (fileSize > 45) {
          return;
        }

        const result = await uploadFile({
          file,
        });

        onSend([
          {
            _id: v7(),
            image: image ? result.data?.contentUrl : undefined,
            text: !image ? result.data?.contentUrl : '',
            createdAt: new Date(),
            user: {
              _id: 1,
            },
          },
        ]);
      });
    },
    [onSend, uploadFile],
  );

  const takePhoto = useCallback(async () => {
    if (!status) {
      return;
    }

    if (!status.granted) {
      const result = await requestPermission();
      if (!result.granted) {
        return;
      }
    }

    const photoResult = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: 1,
      allowsMultipleSelection: true,
    });

    if (photoResult.canceled) {
      return;
    }

    const files = photoResult.assets;

    await handleUploadImages(files);
  }, [handleUploadImages, requestPermission, status]);

  const pickImages = useCallback(async () => {
    if (!statusMedia) {
      return;
    }
    if (!statusMedia.granted) {
      const result = await requestPermissionMedia();
      if (!result.granted) {
        return;
      }
    }
    const pickResult = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: 1,
      allowsMultipleSelection: true,
    });

    if (pickResult.canceled) {
      return;
    }

    const files = pickResult.assets;

    await handleUploadImages(files);
  }, [handleUploadImages, requestPermissionMedia, statusMedia]);

  const pickDocuments = useCallback(async () => {
    const pickResult = await DocumentPicker.getDocumentAsync({
      type: [
        'application/msword',
        'application/vnd.ms-excel',
        'application/vnd.ms-powerpoint',
        'text/plain',
        'application/pdf',
        'image/*',
        'video/*',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
      ],
    });

    if (pickResult.canceled) {
      return;
    }

    const messages: GiftedChatIMessage[] = [];
    await Promise.all(
      pickResult.assets.map(async (file) => {
        const fileSize = (file.size || 0) / 1024 / 1024;
        if (fileSize > 45) {
          return;
        }

        const result = await uploadFile({
          file: {
            fileName: file.name,
            uri: file.uri || '',
            height: 0,
            width: 0,
          },
        });

        messages.push({
          _id: v7(),
          text: '',
          video: file.mimeType,
          image: result.data?.contentUrl,
          system: true,
          createdAt: new Date(),
          user: {
            _id: 1,
          },
        });
      }),
    );

    onSend(messages);
  }, [onSend, uploadFile]);

  const sendLocation = useCallback(async () => {
    const { status: locationStatus } =
      await Location.requestForegroundPermissionsAsync();
    if (locationStatus !== 'granted') {
      // alert('Permission to access location was denied');
      return;
    }

    const result = await Location.getCurrentPositionAsync({});

    onSend([
      {
        _id: v7(),
        text: '',
        location: {
          ...result.coords,
        },
        system: true,
        createdAt: new Date(),
        user: {
          _id: 1,
        },
      },
    ]);
  }, [onSend]);

  const openMenu = useCallback(() => {
    const options = [
      formatMessage({ id: 'button.uploadPhoto' }),
      formatMessage({ id: 'button.takePhoto' }),
      formatMessage({ id: 'button.uploadDocument' }),
      formatMessage({ id: 'button.sendLocation' }),
      formatMessage({ id: 'button.cancel' }),
    ];
    const cancelButtonIndex = options.length - 1;

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      (selectedIndex) => {
        switch (selectedIndex) {
          case 0:
            pickImages();
            break;
          case 1:
            takePhoto();
            break;
          case 2:
            pickDocuments();
            break;
          case 3:
            sendLocation();
            break;

          case cancelButtonIndex:
          default:
          // Canceled
        }
      },
    );
  }, [
    formatMessage,
    pickDocuments,
    pickImages,
    sendLocation,
    showActionSheetWithOptions,
    takePhoto,
  ]);

  return (
    <View>
      <TouchableOpacity style={[styles.container]} onPress={openMenu}>
        <FontAwesomeIcon color={colors.white} size={24} icon={faPlusCircle} />
      </TouchableOpacity>
    </View>
  );
};

export default GiftedChatActions;
