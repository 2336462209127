import { useGetTranslatedName } from '@bas/shared/hooks';
import { FillingInRoomWizardInputType } from '@bas/taxation-tool-domain/input-types';
import {
  isMovingJobTaxationCustomFurniture,
  isMovingJobTaxationStandardFurniture,
  MovingJobTaxationCustomFurniture,
  MovingJobTaxationFurniture,
  MovingJobTaxationStandardFurniture,
} from '@bas/taxation-tool-domain/models';
import { usePublicFurnitureTypesRequest } from '@bas/tenant-domain/requests';
import { RenderTranslatedName } from '@bas/ui/native/atoms';
import { Box, Typography } from '@bas/ui/native/base';
import {
  RectangularCheckboxTile,
  RectangularTileButton,
} from '@bas/ui/native/molecules';
import { InternalServiceType } from '@bas/value-objects';
import { FlashList, ListRenderItemInfo } from '@shopify/flash-list';
import { useResponsiveProp } from '@shopify/restyle';
import {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

export type WhatFurnitureNeedServiceStepProps = {
  disableServicePossibleCheck?: boolean;
  serviceType: InternalServiceType;
  copyFromServiceType?: InternalServiceType;
};

type ListItemType = (
  | MovingJobTaxationCustomFurniture
  | (MovingJobTaxationStandardFurniture & { quantityIndex: number })
) & { index: number };

const FormRectangularCheckboxTile = ({
  index,
  quantityIndex,
  label,
  serviceType,
}: {
  index: number;
  quantityIndex?: number;
  label: ReactNode;
  serviceType: InternalServiceType;
}) => {
  const key = useMemo(
    () =>
      `furniture[${index}].services[${
        quantityIndex || 0
      }].${serviceType}` as keyof FillingInRoomWizardInputType,
    [index, quantityIndex, serviceType],
  );
  const selected = useWatch({
    name: key,
  });
  const { setValue } = useFormContext<FillingInRoomWizardInputType>();

  const handleToggleService = useCallback(() => {
    setValue(key, !selected);
  }, [key, selected, setValue]);

  return (
    <RectangularCheckboxTile
      label={label}
      onPress={handleToggleService}
      onCheck={handleToggleService}
      selected={selected}
    />
  );
};

const WhatFurnitureNeedServiceStep = ({
  disableServicePossibleCheck,
  serviceType,
  copyFromServiceType,
}: WhatFurnitureNeedServiceStepProps): ReactElement => {
  const [copied, setCopied] = useState(false);
  const { setValue } = useFormContext();
  const getTranslatedName = useGetTranslatedName();
  const furniture = useWatch<FillingInRoomWizardInputType, 'furniture'>({
    name: 'furniture',
  });

  const furnitureCanHaveService = useCallback(
    (intakeFurniture: MovingJobTaxationFurniture) => {
      if (!intakeFurniture.selected) {
        return false;
      }

      if (disableServicePossibleCheck) {
        return true;
      }

      return intakeFurniture.servicesPossible;
    },
    [disableServicePossibleCheck],
  );

  const furnitureWithServicesPossible = useMemo(
    () =>
      furniture
        .map((f, index) => ({ ...f, index }))
        .filter(furnitureCanHaveService),
    [furniture, furnitureCanHaveService],
  );

  const data = useMemo(() => {
    const result: ListItemType[] = [];
    furnitureWithServicesPossible.forEach((intakeFurniture) => {
      if (isMovingJobTaxationCustomFurniture(intakeFurniture)) {
        result.push({ ...intakeFurniture });
      }

      if (isMovingJobTaxationStandardFurniture(intakeFurniture)) {
        new Array(intakeFurniture.quantity)
          .fill('test')
          .forEach((v, quantityIndex) => {
            result.push({
              ...intakeFurniture,
              quantityIndex,
            });
          });
      }
    });

    return result;
  }, [furnitureWithServicesPossible]);

  const allHaveService = useMemo(
    () =>
      data.length ===
      data.filter((pf) => {
        if (isMovingJobTaxationCustomFurniture(pf)) {
          return pf.services?.[0]?.[serviceType] || false;
        }

        if (isMovingJobTaxationStandardFurniture(pf)) {
          return pf.services?.[pf.quantityIndex]?.[serviceType] || false;
        }

        return false;
      }).length,
    [data, serviceType],
  );

  const handleToggleAll = useCallback(() => {
    data.forEach((item) => {
      if (isMovingJobTaxationCustomFurniture(item)) {
        setValue(
          `furniture[${item.index}].services[0].${serviceType}`,
          !allHaveService,
        );
      }

      if (isMovingJobTaxationStandardFurniture(item)) {
        setValue(
          `furniture[${item.index}].services[${item.quantityIndex}].${serviceType}`,
          !allHaveService,
        );
      }
    });
  }, [allHaveService, data, serviceType, setValue]);

  const { data: furnitureTypeData } = usePublicFurnitureTypesRequest();
  const furnitureTypes = useMemo(
    () => furnitureTypeData?.data.member ?? [],
    [furnitureTypeData?.data],
  );

  const handleRenderItem = useCallback(
    ({ item }: ListRenderItemInfo<ListItemType>) => {
      if (isMovingJobTaxationCustomFurniture(item)) {
        return (
          <Box key={`${item.furnitureId}`} flex={1} pt={1}>
            <FormRectangularCheckboxTile
              label={<Typography fontWeight={700}>{item.label}</Typography>}
              serviceType={serviceType}
              index={item.index}
            />
          </Box>
        );
      }

      let label: ReactElement | string = '';
      const furnitureItem = furnitureTypes.find(
        ({ furnitureTypeId }) => furnitureTypeId === item.furnitureTypeId,
      );

      if (furnitureItem) {
        label = (
          <RenderTranslatedName
            fontWeight={700}
            fontSize={20}
            translations={furnitureItem?.translatedNames || []}
            fallback={furnitureItem?.furnitureTypeName || ''}
          />
        );
        if (item.furnitureVariantId) {
          const selectedVariant = furnitureItem.variants.find(
            ({ variantTypeId }) => variantTypeId === item.furnitureVariantId,
          );

          if (selectedVariant) {
            label = (
              <Box>
                <Typography fontWeight={700}>
                  {label}
                  &nbsp;
                  <Typography fontWeight={700} variant="body2">
                    <FormattedMessage
                      id={`variantLabel.selected.${selectedVariant.valueType}`}
                      values={{
                        variantLabel: getTranslatedName(
                          selectedVariant.translatedNames,
                          selectedVariant.variantTypeName,
                        ),
                      }}
                    />
                  </Typography>
                </Typography>
              </Box>
            );
          }
        }
      }

      return (
        <Box key={`${item.furnitureId}.${item.quantityIndex}`} flex={1} pt={1}>
          <FormRectangularCheckboxTile
            label={label}
            index={item.index}
            quantityIndex={item.quantityIndex}
            serviceType={serviceType}
          />
        </Box>
      );
    },
    [furnitureTypes, getTranslatedName, serviceType],
  );

  useEffect(() => {
    if (!copyFromServiceType || copied) {
      return;
    }

    if (
      data.filter(
        (item) =>
          item.services?.[
            isMovingJobTaxationStandardFurniture(item) ? item.quantityIndex : 0
          ]?.[serviceType],
      ).length > 0
    ) {
      return;
    }

    setCopied(true);
    data.forEach((intakeFurniture) => {
      let quantityIndex = 0;
      if (isMovingJobTaxationStandardFurniture(intakeFurniture)) {
        quantityIndex = intakeFurniture.quantityIndex;
      }

      if (intakeFurniture.services[quantityIndex]) {
        setValue(
          `furniture[${intakeFurniture.index}].services[${quantityIndex}].${serviceType}`,
          intakeFurniture.services[quantityIndex][copyFromServiceType],
        );
      }
    });
  }, [copied, copyFromServiceType, data, serviceType, setValue]);

  const paddingHorizontal = useResponsiveProp({
    tablet: 50,
    phone: 20,
  });

  return (
    <Box
      paddingHorizontal={paddingHorizontal}
      flexDirection="column"
      flexWrap="wrap"
      flex={1}
    >
      <Box width="100%">
        <RectangularTileButton onPress={handleToggleAll}>
          <Typography fontWeight={700} color="white" textAlign="center">
            <FormattedMessage
              id={allHaveService ? 'button.deselectAll' : 'button.selectAll'}
            />
          </Typography>
        </RectangularTileButton>
      </Box>
      <Box flex={1}>
        <FlashList
          renderItem={handleRenderItem}
          data={data}
          estimatedItemSize={73}
        />
      </Box>
    </Box>
  );
};

export default WhatFurnitureNeedServiceStep;
