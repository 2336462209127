import { isTask } from './Task';
import type { RequestMovingCertificateTask } from '../types';
import { TaskType } from '../types';

export function isRequestMovingCertificateTask(
  object: unknown,
): object is RequestMovingCertificateTask {
  return (
    !!object &&
    isTask(object) &&
    object.taskType === TaskType.REQUEST_MOVING_CERTIFICATE_TASK
  );
}
