/* eslint-disable @typescript-eslint/no-explicit-any */
import { Relation } from '@bas/crm-domain/models';
import {
  useCustomersRequest,
  useRelationByRelationIdRequestQuery,
} from '@bas/crm-domain/requests';
import { Dropdown, DropdownProps } from '@bas/ui/native/atoms';
import { Uuid } from '@bas/value-objects';
import { keepPreviousData } from '@tanstack/react-query';
import { throttle } from 'lodash';
import {
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import isEqual from 'react-fast-compare';
import {
  FieldPath,
  FieldPathValue,
  FieldValues,
  useController,
  UseFormSetFocus,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { TextInput } from 'react-native';
import { TextInputFocusEventData } from 'react-native/Libraries/Components/TextInput/TextInput';
import { NativeSyntheticEvent } from 'react-native/Libraries/Types/CoreEventTypes';

export type ReactHookFormRelationDropdownProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<
  DropdownProps,
  'name' | 'value' | 'error' | 'items' | 'onChange' | 'multiple'
> & {
  name: TName;
  fieldValue?: string;
  updateOnBlur?: boolean;
  disableError?: boolean;
  loading?: boolean;
  setFocus?: UseFormSetFocus<TFieldValues>;
  nextField?: FieldPath<TFieldValues>;
  onChange?: (
    value: FieldPathValue<TFieldValues, TName>,
    newValue?: Relation,
  ) => void;
  onSubmit?: () => void | Promise<void>;
  onFocus?: () => void;
  onBlur?: () => void;
  variant?: 'outlined' | 'standard' | 'filled';
  relationId?: Uuid;
  allowSearch?: boolean;
};

const ControlledReactHookFormRelationDropdown = memo(
  <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  >({
    fieldValue,
    disabled,
    disableError,
    loading,
    setFocus,
    nextField,
    onSubmit,
    onFocusProp,
    onBlurProp,
    onChangeProp,
    allowSearch,
    value,
    onBlur,
    onChange,
    fieldRef,
    error,
    invalid,
    isSubmitting,
    relationId,

    ...props
  }: Omit<ReactHookFormRelationDropdownProps, 'name' | 'onChange'> & {
    onChangeProp?: (
      value: FieldPathValue<TFieldValues, TName>,
      newValue?: Relation,
    ) => void;
    onFocusProp?:
      | ((e: NativeSyntheticEvent<TextInputFocusEventData>) => void)
      | undefined;
    onBlurProp?: () => void;
    value: string;
    error: any;
    invalid: boolean;
    isSubmitting: boolean;
    fieldRef: (ref: TextInput) => void;
    onChange: (value: string) => void;
  }): ReactElement => {
    const [search, setSearch] = useState('');
    const [globalFilter, setGlobalFilter] = useState<string>();

    const { formatMessage } = useIntl();
    const fieldError = error?.message;
    const showError = !disableError && invalid;
    const inputRef = useRef<TextInput | null>(null);

    const textFieldArgs = useMemo(
      () => ({
        validationMessage: showError ? fieldError : undefined,
        enableErrors: true,
        error: showError ? !!fieldError : undefined,
        disabled: disabled ?? isSubmitting,
      }),
      [showError, fieldError, disabled, isSubmitting],
    );

    const { isLoading, data: relationsData } = useCustomersRequest(
      {
        perPage: 50,

        globalFilter,
      },
      { placeholderData: keepPreviousData },
    );

    const { data: selectedRelationData } = useRelationByRelationIdRequestQuery(
      {
        relationId: value || '',
      },
      { enabled: !!value },
    );

    const searchCustomers = useMemo(
      () => throttle((newValue) => setGlobalFilter(newValue), 100),
      [],
    );

    useEffect(() => {
      searchCustomers(search);
    }, [search, searchCustomers]);

    const selectNextFieldOrSubmit = useCallback(() => {
      if (nextField) {
        setFocus?.(nextField, { shouldSelect: false });
      } else if (onSubmit) {
        onSubmit();
      }
    }, [nextField, onSubmit, setFocus]);

    useEffect(() => {
      if (inputRef.current) {
        fieldRef(inputRef.current);
      }
    }, [fieldRef, inputRef]);

    const valueProp: { value?: string } = {};
    if (loading || isLoading) {
      valueProp.value = formatMessage({ id: 'label.loading' });
    } else if (!fieldValue) {
      valueProp.value = value || '';
    } else {
      valueProp.value = (value[fieldValue as keyof typeof value] ||
        '') as string;
    }

    const handleBlur = useCallback(
      () => () => {
        onBlurProp?.();
        onBlur?.();
      },
      [onBlur, onBlurProp],
    );

    const handleChange = useCallback(
      (text: unknown) => {
        if (onChangeProp) {
          onChangeProp(
            text as FieldPathValue<TFieldValues, TName>,
            relationsData?.data?.member?.find(
              (p: Relation) => p.relationId === text,
            ),
          );
        } else if (fieldValue && !!value && typeof value === 'object') {
          onChange({
            ...(value as object),
            [fieldValue]: text,
          } as FieldPathValue<TFieldValues, TName>);
        } else {
          onChange(text as FieldPathValue<TFieldValues, TName>);
        }
      },
      [fieldValue, onChange, onChangeProp, relationsData?.data, value],
    );

    const items = useMemo(() => {
      const relationsToUse = [];
      if (selectedRelationData?.data) {
        relationsToUse.push(selectedRelationData?.data);
      }

      relationsToUse.push(
        ...(relationsData?.data?.member || []).filter(
          (relation: Relation) =>
            relation.relationId !== selectedRelationData?.data?.relationId,
        ),
      );

      return relationsToUse.map((relation) => ({
        id: relation.relationId,
        label: `${relation.name} (${relation.customerInformation?.customerNumber})`,
      }));
    }, [relationsData?.data, selectedRelationData?.data]);

    const filteredItems = useMemo(() => {
      if (!allowSearch || !search) {
        return items;
      }

      return items.filter(
        (item: { id: string; label: string | ReactElement }) => {
          if (
            typeof item.label === 'string' &&
            item.label.toLowerCase().includes(search.toLowerCase())
          ) {
            return true;
          }

          return item.id.toLowerCase().includes(search.toLowerCase());
        },
      );
    }, [allowSearch, items, search]);

    return (
      <Dropdown
        onChange={handleChange}
        value={value}
        {...textFieldArgs}
        {...props}
        {...valueProp}
        onSubmitEditing={selectNextFieldOrSubmit}
        blurOnSubmit={false}
        onBlur={handleBlur}
        onFocus={onFocusProp}
        inputRef={inputRef}
        items={filteredItems}
        hideSearch={!allowSearch}
        onSearchChange={setSearch}
      />
    );
  },
  ({ fieldRef: _Prev, ...prevProps }, { fieldRef: _Next, ...nextProps }) =>
    isEqual(prevProps, nextProps),
);

const ReactHookFormRelationDropdown = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  onFocus: onFocusProp,
  onBlur: onBlurProp,
  onChange: onChangeProp,
  ...props
}: ReactHookFormRelationDropdownProps<TFieldValues, TName>): ReactElement => {
  const {
    field: { value, onBlur, onChange, ref },
    fieldState: { error, invalid },
    formState: { isSubmitting },
  } = useController<TFieldValues, TName>({
    name,
  });

  return (
    <ControlledReactHookFormRelationDropdown
      {...props}
      onFocusProp={onFocusProp}
      onBlurProp={onBlurProp}
      onChangeProp={onChangeProp}
      value={value}
      error={error}
      invalid={invalid}
      isSubmitting={isSubmitting}
      onBlur={onBlur}
      onChange={onChange}
      fieldRef={ref}
    />
  );
};

export default ReactHookFormRelationDropdown;
