import {
  useDocumentTemplatesRequest,
  useIdentitiesRequest,
} from '@bas/tenant-domain/requests';
import {
  ReactHookFormDropdown,
  ReactHookFormTextField,
} from '@bas/ui/native/molecules';
import { DocumentType, Uuid } from '@bas/value-objects';
import { ReactElement, ReactNode, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { View } from 'react-native';

export type FinancialDocumentFormPartProps = {
  identityId?: Uuid;
  documentType: DocumentType;
  prefix?: string;
  light?: boolean;
  children?: ReactNode;
  useIdentities?: boolean;
};

const FinancialDocumentFormPart = ({
  identityId,
  documentType,
  prefix,
  light,
  children,
  useIdentities,
}: FinancialDocumentFormPartProps): ReactElement => {
  const getPrefixedName = useCallback(
    (name: string) => {
      if (prefix) {
        return `${prefix}.${name}`;
      }

      return name;
    },
    [prefix],
  );

  const { data: identitiesData, isInitialLoading: isLoadingIdentities } =
    useIdentitiesRequest({}, { enabled: useIdentities || false });

  const { data: templatesData, isInitialLoading: isLoadingTemplates } =
    useDocumentTemplatesRequest({
      perPage: 99999,
      'documentType.value': documentType,
    });

  const templates = useMemo(
    () =>
      (templatesData?.data.member || []).filter(
        (p) => !useIdentities || p.identityId === identityId,
      ),
    [identityId, templatesData?.data, useIdentities],
  );

  const { formatMessage } = useIntl();

  return (
    <View>
      {useIdentities && (
        <View>
          <ReactHookFormDropdown
            name={getPrefixedName('identityId')}
            label={formatMessage({ id: 'label.identityId' })}
            disabled={isLoadingIdentities}
            light={light}
            items={[
              ...(identitiesData?.data.member || []).map((identity) => ({
                label: identity.companyInformation.companyName,
                id: identity.identityId,
              })),
            ]}
          />
        </View>
      )}
      <View>
        <ReactHookFormDropdown
          name={getPrefixedName('templateId')}
          label={formatMessage({ id: 'label.templateId' })}
          disabled={isLoadingIdentities || isLoadingTemplates}
          light={light}
          items={[
            ...templates.map((template) => ({
              label: template.name,
              id: template.documentTemplateId,
            })),
          ]}
        />
      </View>
      <View>
        <ReactHookFormTextField
          name={getPrefixedName('reference')}
          label={formatMessage({ id: 'label.reference' })}
          light={light}
        />
      </View>
      {children}
    </View>
  );
};

export default FinancialDocumentFormPart;
