import type { Money } from '@bas/value-objects';
import type { EstimationSettings } from './EstimationSettings';
import type { BuildingRate } from './BuildingRate';
import type { ServiceTimeConsumption } from './ServiceTimeConsumption';

export interface MovingJobEstimationSettings extends EstimationSettings {
  employeeCubicMeterPerHour: number;
  cubicMeterRate: Money;
  travelHourlyRate: Money;
  pricePerKm: Money;
  pricePerBox: Money;
  buildingTypeRates: BuildingRate[];
  serviceTimeConsumptions: ServiceTimeConsumption[];
  includeMovingCompanyAddress: boolean;
}
