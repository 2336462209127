import { ActiveIntake, isMovingJob } from '@bas/project-domain/models';
import { useProjectByProjectIdRequest } from '@bas/project-domain/requests';
import {
  MovingJobTaxationWizardInitialValues,
  MovingJobTaxationWizardInput,
} from '@bas/taxation-tool-domain/models';
import { PublicTenant } from '@bas/tenant-domain/models';
import {
  calculateHookCategories,
  calculateHookIndexedSteps,
  ProjectType,
} from '@bas/value-objects';
import { router } from 'expo-router';
import { useEffect, useMemo } from 'react';
import { convertMovingJobToMovingJobTaxation } from './convertMovingJobToMovingJobIntake';
import { movingJobTaxationWizardSteps } from './movingJobTaxationWizardSteps';

export const useMovingJobTaxationTool = ({
  projectId,
  projectType,
  activeIntake,
  tenant,
}: {
  projectId: string;
  projectType?: ProjectType;
  activeIntake?: ActiveIntake;
  tenant?: PublicTenant;
}) => {
  const { data: projectData, isInitialLoading: isLoadingProject } =
    useProjectByProjectIdRequest(
      { projectId: projectId || '' },
      {
        enabled: !activeIntake || !projectId,
        retry: false,
        throwOnError: (error) => error?.response?.status !== 404,
        refetchInterval: false,
        refetchIntervalInBackground: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
    );

  const project = useMemo(() => projectData?.data, [projectData?.data]);

  useEffect(() => {
    if (!isLoadingProject && !project && projectId && !projectType) {
      router.push('/');
    }
  }, [isLoadingProject, project, projectId, projectType]);

  const defaultValues: MovingJobTaxationWizardInput | object = useMemo(() => {
    if (
      !project &&
      !isLoadingProject &&
      projectType === ProjectType.MOVING_JOB
    ) {
      const initialValues: MovingJobTaxationWizardInput = {
        ...MovingJobTaxationWizardInitialValues(),
        projectId: projectId || '',
        tenantId: tenant?.tenantId || '',
      };

      return initialValues;
    }

    if (project && isMovingJob(project)) {
      return convertMovingJobToMovingJobTaxation(project);
    }

    return {};
  }, [project, isLoadingProject, projectType, projectId, tenant?.tenantId]);

  const steps = useMemo(() => {
    if ((projectType || project?.projectType) === ProjectType.MOVING_JOB) {
      return movingJobTaxationWizardSteps();
    }

    return [];
  }, [project?.projectType, projectType]);

  const indexedSteps = useMemo(
    () => calculateHookIndexedSteps<MovingJobTaxationWizardInput>(steps),
    [steps],
  );

  const categories = useMemo(
    () =>
      calculateHookCategories<MovingJobTaxationWizardInput>(
        steps,
        indexedSteps,
      ),
    [steps, indexedSteps],
  );

  return { defaultValues, steps, indexedSteps, categories, isLoadingProject };
};
