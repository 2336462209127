import { useRetrieveQrInformation } from '@bas/shared/hooks';
import {
  isQrCode,
  isStorageLocationQrCode,
  isStoredItemQrCode,
  QrCode,
  StoredItemQrCode,
} from '@bas/shared/models';
import { Alert, Button, Skeleton } from '@bas/ui/native/atoms';
import { Box, Icon, ShopifyTheme, Typography } from '@bas/ui/native/base';
import { BackendErrors, QrCodeScanner } from '@bas/ui/native/organisms';
import { Uuid } from '@bas/value-objects';
import {
  LoadOrUnloadStorageWithInventoryInputType,
  StoredItemInputType,
  storedItemInputTypeDefaultValues,
} from '@bas/wms-domain/input-types';
import {
  useStorageLocationByStorageLocationIdRequestQuery,
  useStoredItemsRequest,
} from '@bas/wms-domain/requests';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons/faTimesCircle';
import { FlashList } from '@shopify/flash-list';
import { useResponsiveProp } from '@shopify/restyle';
import * as Notifications from 'expo-notifications';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import type { Code } from 'react-native-vision-camera';
import { useDebouncedCallback } from 'use-debounce';

type MoveStoredItemsFormProps = {
  isOpen: boolean;
};

type SelectionValueType = {
  currentLocationId: Uuid | null;
  newLocationId: Uuid | null;
};

const MoveStoredItemsForm = ({
  isOpen,
}: MoveStoredItemsFormProps): ReactElement => {
  const parseQrCode = useRetrieveQrInformation();
  const [isScanning, setIsScanning] = useState(true);

  const { setValue, reset } = useFormContext<
    SelectionValueType & LoadOrUnloadStorageWithInventoryInputType
  >();

  const {
    fields: formStoredItems,
    prepend,
    update,
    move,
    remove,
  } = useFieldArray<LoadOrUnloadStorageWithInventoryInputType>({
    name: 'storedItems',
  });

  useEffect(() => {
    if (isOpen) {
      setIsScanning(true);
    } else {
      reset();
    }
  }, [reset, isOpen]);

  const currentLocationId = useWatch<SelectionValueType, 'currentLocationId'>({
    name: 'currentLocationId',
  });

  const newLocationId = useWatch<SelectionValueType, 'newLocationId'>({
    name: 'newLocationId',
  });

  const { data: currentLocationData, isFetching: isLoadingCurrentLocation } =
    useStorageLocationByStorageLocationIdRequestQuery(
      {
        storageLocationId: currentLocationId || '',
      },
      {
        enabled: !!currentLocationId,
      },
    );

  const currentLocation = useMemo(() => {
    if (currentLocationData?.data === undefined) {
      return null;
    }

    return currentLocationData?.data;
  }, [currentLocationData?.data]);

  const { data: newLocationData, isFetching: isLoadingNewLocation } =
    useStorageLocationByStorageLocationIdRequestQuery(
      {
        storageLocationId: newLocationId || '',
      },
      {
        enabled: !!newLocationId,
      },
    );

  const newLocation = useMemo(() => {
    if (newLocationData?.data === undefined) {
      return null;
    }

    return newLocationData?.data;
  }, [newLocationData?.data]);

  const { data: storedItemsData } = useStoredItemsRequest(
    {
      storageLocationId: currentLocationId || '',
    },
    {
      enabled: !!currentLocationId,
    },
  );

  const storedItems = useMemo(
    () => storedItemsData?.data?.member || [],
    [storedItemsData?.data],
  );

  const { formatMessage } = useIntl();
  const handleNotifyNotOnLocation = useDebouncedCallback(
    () => {
      Notifications.scheduleNotificationAsync({
        content: {
          title: formatMessage({ id: 'label.itemIsNotOnLocation' }),
          body: formatMessage({
            id: 'label.itemIsNotOnLocationDescription',
          }),
          autoDismiss: true,
        },
        trigger: null,
      });
    },
    500,
    {
      leading: true,
      trailing: false,
    },
  );

  const handleScannedCode = useCallback(
    (code: Code) => {
      let parsed: QrCode | null = null;
      try {
        parsed = parseQrCode(code.value || '{}');
      } catch (e) {
        return false;
      }

      if (!parsed || !isQrCode(parsed)) {
        return false;
      }

      if (isStorageLocationQrCode(parsed)) {
        if (!currentLocationId) {
          setValue('currentLocationId', parsed.locationId, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          });

          return true;
        }

        if (currentLocationId === parsed.locationId) {
          return false;
        }

        if (
          newLocationId &&
          (!newLocation ||
            !currentLocation ||
            newLocation.warehouseId === currentLocation.warehouseId)
        ) {
          return false;
        }

        setValue('newLocationId', parsed.locationId, {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        });

        return true;
      }

      if (isStoredItemQrCode(parsed)) {
        if (
          !currentLocationId ||
          !newLocationId ||
          !currentLocation ||
          !newLocation ||
          currentLocation.warehouseId !== newLocation.warehouseId
        ) {
          return false;
        }

        const indexOfItem = storedItems.findIndex(
          (storedItem) =>
            storedItem.itemId === (parsed as StoredItemQrCode).itemId,
        );

        if (indexOfItem === -1) {
          handleNotifyNotOnLocation();

          return false;
        }

        const formIndexOfItem = formStoredItems.findIndex(
          (storedItem) =>
            storedItem.itemId === (parsed as StoredItemQrCode).itemId,
        );

        if (
          !storedItems[indexOfItem].loadedOn ||
          storedItems[indexOfItem].unloadedOn
        ) {
          if (formIndexOfItem === -1) {
            prepend({
              ...storedItemInputTypeDefaultValues(),
              ...storedItems[indexOfItem],
              scanned: false,
              hasError: true,
            });

            return true;
          }

          move(formIndexOfItem, 0);
          return true;
        }

        if (formIndexOfItem === -1) {
          prepend({
            ...storedItemInputTypeDefaultValues(),
            ...storedItems[indexOfItem],
            storageLocationId: newLocationId,
            storageLocationName: newLocation?.name || '',
            storageLocationCode: newLocation?.code || '',
            create: false,
            scanned: true,
          });

          return true;
        }

        if (
          formStoredItems[formIndexOfItem].storageLocationId === newLocationId
        ) {
          return false;
        }

        update(formIndexOfItem, {
          ...formStoredItems[formIndexOfItem],
          storageLocationId: newLocationId,
          storageLocationName: newLocation?.name || '',
          storageLocationCode: newLocation?.code || '',
        });

        move(formIndexOfItem, 0);

        return true;
      }

      return false;
    },
    [
      parseQrCode,
      currentLocationId,
      newLocationId,
      newLocation,
      currentLocation,
      setValue,
      storedItems,
      formStoredItems,
      update,
      move,
      handleNotifyNotOnLocation,
      prepend,
    ],
  );

  const qrCodeScanner = useMemo(
    () => <QrCodeScanner onScannedCode={handleScannedCode} />,
    [handleScannedCode],
  );

  const handleRemoveItem = useCallback(
    (item: StoredItemInputType) => {
      const indexOfItem = formStoredItems.findIndex(
        (storedItem) => storedItem.itemId === item.itemId,
      );

      if (indexOfItem === -1) {
        return;
      }

      remove(indexOfItem);
    },
    [formStoredItems, remove],
  );

  // @ts-expect-error - This is a bug in the library
  const isTablet = useResponsiveProp<ShopifyTheme, boolean>({
    lg: true,
    largerTablet: true,
    tablet: true,
    phone: false,
  });

  const handleRenderScannedItem = useCallback(
    // eslint-disable-next-line react/no-unused-prop-types
    ({ item }: { item: StoredItemInputType }) => (
      <Box
        flexDirection="row"
        flexWrap="nowrap"
        gap={2}
        width="100%"
        alignItems="center"
        paddingVertical={1}
        borderBottomWidth={1}
        borderBottomColor="transparentBorder"
      >
        <Box flex={0.4}>
          <Typography color="white">{`${item.description} ${item.externalItemCode} ${item.itemCode}`}</Typography>
        </Box>
        <Box flex={0.2}>
          <Typography color="white">{`${item.storageCode}`}</Typography>
        </Box>
        <Box flex={item.scanned ? 0.3 : 0.5}>
          {item.scanned && !item.hasError ? (
            <Typography color="white">
              {item.storageLocationId ? (
                `${item.storageLocationName} (${item.storageLocationCode})`
              ) : (
                <FormattedMessage id="label.itemIsInStorage" />
              )}
            </Typography>
          ) : (
            <Typography color="warningButtonColor">
              <FormattedMessage id="label.itemIsNotOnLocation" />
            </Typography>
          )}
        </Box>
        {item.scanned && (
          <Box flex={0.4}>
            <Button
              variant="outlined"
              disabled={!item.storageLocationId}
              onLongPress={() => handleRemoveItem(item)}
              forceLongPress
              longPressLoaderColor="rgba(229, 78, 68, 0.2)"
            >
              {isTablet ? (
                <FormattedMessage id="button.dontMoveItem" />
              ) : (
                <Icon icon={faTimesCircle} color="mainPrimary" />
              )}
            </Button>
          </Box>
        )}
      </Box>
    ),
    [handleRemoveItem, isTablet],
  );

  return (
    <Box flex={1} rowGap={3}>
      <Box width="100%">
        <Typography
          color="white"
          variant="h4"
          fontWeight={700}
          textAlign="center"
        >
          <FormattedMessage id="label.moveItemsBetweenStorageLocation" />
        </Typography>
        <Typography variant="h5" color="white" textAlign="center">
          {!currentLocationId && (
            <FormattedMessage id="label.scanATheCurrentStorageLocationQrCode" />
          )}
          {currentLocationId &&
            isLoadingCurrentLocation &&
            !currentLocation && <Skeleton light width={400} height={20} />}

          {!newLocationId && currentLocationId && (
            <FormattedMessage id="label.scanATheNewStorageLocationQrCode" />
          )}

          {newLocationId && isLoadingNewLocation && !newLocation && (
            <Skeleton light width={400} height={20} />
          )}

          {newLocation && currentLocation && (
            <FormattedMessage
              id="label.moveItemsFromCurrentStorageLocationToNewStorageLocation"
              values={{
                currentLocation: `${currentLocation.name} (${currentLocation.code})`,
                newLocation: `${newLocation.name} (${newLocation.code})`,
              }}
            />
          )}
        </Typography>
      </Box>
      {isScanning && (
        <Box height="40%" width="100%">
          {qrCodeScanner}
        </Box>
      )}
      <Box flex={1}>
        {!currentLocationId && (
          <Box width="100%" flex={1}>
            <Alert severity="info">
              <FormattedMessage id="label.scanTheQrCodeOfTheCurrentStorageLocation" />
            </Alert>
          </Box>
        )}
        {currentLocationId && !newLocationId && (
          <Box width="100%" flex={1}>
            <Alert severity="info">
              <FormattedMessage id="label.scanTheQrCodeOfTheNewStorageLocation" />
            </Alert>
          </Box>
        )}

        {currentLocation && newLocation && (
          <Box flexDirection="row" width="100%" flexWrap="wrap" flex={1} pb={1}>
            {currentLocation.warehouseId &&
              newLocation.warehouseId !== currentLocation.warehouseId && (
                <Box width="100%" pb={3}>
                  <Alert severity="warning">
                    <FormattedMessage id="label.locationsAreNotInTheSameWarehouse" />
                  </Alert>
                </Box>
              )}
            <Box width="100%" flex={1}>
              <BackendErrors pb={1} />

              <Typography variant="subtitle1" color="white">
                {formStoredItems.length}{' '}
                <FormattedMessage id="label.scannedItems" />
              </Typography>
              {currentLocationId && formStoredItems.length === 0 && (
                <Alert severity="info">
                  <FormattedMessage id="label.scanItemsToLoadThemIntoTheStorage" />
                </Alert>
              )}
              {!currentLocationId && (
                <Typography variant="body1" color="white">
                  <FormattedMessage id="label.noStorageLocationSelected" />
                </Typography>
              )}
              {!newLocationId && currentLocationId && (
                <Typography variant="body1" color="white">
                  <FormattedMessage id="label.noNewStorageLocationSelected" />
                </Typography>
              )}
              {currentLocationId &&
                newLocationId &&
                formStoredItems.length > 0 && (
                  <FlashList
                    renderItem={handleRenderScannedItem}
                    keyExtractor={(item) => item.id}
                    data={formStoredItems}
                    estimatedItemSize={62}
                  />
                )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default MoveStoredItemsForm;
