import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type DeclineQuoteMutationProps = {
  quoteId: Uuid;
  reason?: string | null;
  cancelProject?: boolean;
};

export const DeclineQuoteMutation = async ({
  quoteId,
  ...values
}: DeclineQuoteMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/quotes/${quoteId}/decline`, {
    quoteId,
    ...values,
  });

export const useDeclineQuoteMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    DeclineQuoteMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  DeclineQuoteMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    DeclineQuoteMutationProps
  >({
    mutationFn: DeclineQuoteMutation,
    throwOnError: false,
    ...options,
  });
