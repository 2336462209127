import { TravelTimeForAddress } from '@bas/planning-domain/models';
import { QueryOptionsWithKey } from '@bas/shared/requests';
import { formatDateTime } from '@bas/shared/utils';
import { Address, ErrorResponse } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { v7 } from 'uuid';

export type TravelTimeToAddressRequestProps = {
  toAddress: Address;
  fromAddress: Address | null;
  extraAddresses?: Address[];
  arrivalTime?: Date | null;
};

type Response = AxiosResponse<TravelTimeForAddress>;

export const TravelTimeToAddressRequest = async ({
  arrivalTime,
  fromAddress,
  ...request
}: TravelTimeToAddressRequestProps): Promise<Response> =>
  axios.put(`api/{tenantId}/calculate-travel-time/${v7()}`, {
    arrivalTime: arrivalTime ? formatDateTime(arrivalTime) : null,
    fromAddress: fromAddress?.streetName ? fromAddress : null,
    ...request,
  });

export const useTravelTimeToAddressRequest = (
  request: TravelTimeToAddressRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    queryFn: async () => TravelTimeToAddressRequest(request),
    queryKey: ['planning-event', 'travel-time', request],
    ...options,
  });
