import { WeekHours } from '@bas/hrm-domain/models';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ChangeEmployeeWorkingHoursMutationProps = {
  employeeId: Uuid;
  week1Hours: WeekHours;
  week2Hours: WeekHours;
};

export const ChangeEmployeeWorkingHoursMutation = async ({
  employeeId,
  ...values
}: ChangeEmployeeWorkingHoursMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/hrm/employees/${employeeId}/update-working-hours`, {
    employeeId,
    ...values,
  });

export const useChangeEmployeeWorkingHoursMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeEmployeeWorkingHoursMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ChangeEmployeeWorkingHoursMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeEmployeeWorkingHoursMutationProps
  >({
    mutationFn: ChangeEmployeeWorkingHoursMutation,
    throwOnError: false,
    ...options,
  });
