import { ErrorResponse, Uuid } from '@bas/value-objects';
import { StorageType } from '@bas/wms-domain/models';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type UpdateStorageSpecificationsMutationProps = {
  storageId: Uuid;
  tenantId: Uuid;
  storageCode: string;
  storageType: StorageType;
  selfOwned: boolean;
  cubicMeter: number;
};

export const UpdateStorageSpecificationsMutation = async ({
  storageId,
  ...values
}: UpdateStorageSpecificationsMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/storages/${storageId}/update-specifications`, {
    storageId,
    ...values,
  });

export const useUpdateStorageSpecificationsMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateStorageSpecificationsMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  UpdateStorageSpecificationsMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateStorageSpecificationsMutationProps
  >({
    mutationFn: UpdateStorageSpecificationsMutation,
    throwOnError: false,
    ...options,
  });
