import { TranslatedName, Uuid } from '@bas/value-objects';

export interface TimeType {
  timeTypeId: Uuid;
  name: string;
  weight: number;
  translatedNames: TranslatedName[];
  workingHours: boolean;
  fieldService: boolean;
  icon: string;
  categoryId: Uuid;
  categoryName: string;
  categoryWeight: number;
  categoryIcon?: string;
  disabledAfter?: Date | null;
}
