import { AddressInputType } from '@bas/shared/input-types';
import { formatDate, formatDateTime } from '@bas/shared/utils';
import {
  EmployeeProjectRole,
  ErrorResponse,
  EventType,
  Uuid,
} from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import dayjs from 'dayjs';

export type PlanEventMutationProps = {
  eventId: Uuid;
  eventType: EventType;
  start?: Date | null;
  end?: Date | null;
  travelStart?: Date | null;
  travelEnd?: Date | null;
  plannedEmployees: { employeeId: Uuid; projectRole: EmployeeProjectRole }[];
  location?: AddressInputType;
  notes?: string | null;
  relationId?: string | null;
  name?: string | null;
  description?: string | null;
  plannedMaterialIds: Uuid[];
  fullDay?: boolean | null;
  departureLocation: AddressInputType;
  confirmed?: boolean | null;
  automaticTravelTime?: boolean | null;
  recurringInformation?: {
    isRecurring: boolean;
    rrule: string;
    repeatFrom: Date;
    repeatUntil: Date;
  };
};

export const PlanEventMutation = async ({
  eventId,
  start,
  end,
  travelStart,
  travelEnd,
  fullDay,
  recurringInformation,
  ...values
}: PlanEventMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/{tenantId}/events/plan`, {
    ...values,
    fullDay,
    start: start
      ? formatDateTime(fullDay ? dayjs(start).startOf('day') : start)
      : null,
    end: end ? formatDateTime(fullDay ? dayjs(end).endOf('day') : end) : null,
    travelStart: travelStart && !fullDay ? formatDateTime(travelStart) : null,
    travelEnd: travelEnd && !fullDay ? formatDateTime(travelEnd) : null,
    eventId,
    recurringInformation: recurringInformation
      ? {
          ...recurringInformation,
          repeatFrom: formatDate(recurringInformation.repeatFrom),
          repeatUntil: formatDate(recurringInformation.repeatUntil),
        }
      : null,
  });

export const usePlanEventMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    PlanEventMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  PlanEventMutationProps
> =>
  useMutation<AxiosResponse, AxiosError<ErrorResponse>, PlanEventMutationProps>(
    {
      mutationFn: PlanEventMutation,
      throwOnError: false,
      ...options,
    },
  );
