import { StyleSheet } from 'react-native';
import { colors } from '@bas/theme';

export default StyleSheet.create({
  accordion: {
    backgroundColor: colors.white,
    borderRadius: 5,
    paddingHorizontal: 11,
    paddingVertical: 16,
  },
  summary: {
    justifyContent: 'space-between',
  },
  contentContainer: {
    overflow: 'hidden',
  },
  chevron: {},
  content: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    paddingTop: 24,
    maxHeight: 'auto',
    display: 'flex',
  },
});
