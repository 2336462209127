import { Invoice, InvoiceStatus } from '@bas/financial-domain/models';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

export const useFilterBulkInvoicesForAction = (
  action: 'finalize' | 'finalizeAndSend' | 'send' | 'sendReminder' | 'delete',
  invoices: Invoice[],
): (Invoice & {
  actionAvailable: boolean;
  reason?: string;
})[] => {
  const { formatMessage } = useIntl();

  const checkInvoice = useCallback(
    (invoice: Invoice) => {
      if (
        ['delete', 'finalize', 'finalizeAndSend'].includes(action) &&
        invoice.invoiceStatus !== InvoiceStatus.CONCEPT
      ) {
        return {
          actionAvailable: false,
          reason: formatMessage({ id: 'label.invoiceIsAlreadyFinalized' }),
        };
      }

      if (
        (action === 'send' || action === 'sendReminder') &&
        invoice.invoiceStatus === InvoiceStatus.CONCEPT
      ) {
        return {
          actionAvailable: false,
          reason: formatMessage({ id: 'label.invoiceIsNotFinalized' }),
        };
      }

      if (
        (action === 'finalize' || action === 'finalizeAndSend') &&
        (invoice.lines.length === 0 || !invoice.address)
      ) {
        return {
          actionAvailable: false,
          reason: formatMessage({ id: 'label.invoiceIsNotComplete' }),
        };
      }

      return { actionAvailable: true, reason: '' };
    },
    [formatMessage, action],
  );

  return useMemo(
    () =>
      invoices.map((invoice) => {
        const { actionAvailable, reason } = checkInvoice(invoice);
        return {
          ...invoice,
          actionAvailable,
          reason,
        };
      }),
    [checkInvoice, invoices],
  );
};
