import { Project } from '@bas/project-domain/models';
import { Pagination, QueryOptionsWithKey } from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  keepPreviousData,
  QueryKey,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type AllProjectsRequestProps = Pagination & {
  globalFilter?: string;
  customer?: Uuid;
};

type Response = AxiosResponse<Collection<Project>>;
type QueryKeyType = QueryKey & {
  [0]: 'projects';
  [1]: 'list';
};

export const AllProjectsRequest = async ({
  ...params
}: AllProjectsRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/projects`, {
    params: { ...params },
  });

export const useAllProjectsRequest = (
  request: AllProjectsRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response,
    QueryKeyType
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response, QueryKeyType>({
    ...options,
    queryFn: async () => AllProjectsRequest({ ...request }),
    queryKey: ['projects', 'list', JSON.stringify(request)],
    placeholderData: keepPreviousData,
  });
