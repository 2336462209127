import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ChangeVehiclePictureMutationProps = {
  materialId: Uuid;
  mediaObjectId: Uuid;
};

export const ChangeVehiclePictureMutation = async ({
  materialId,
  ...values
}: ChangeVehiclePictureMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/vehicles/${materialId}/change-picture`, {
    materialId,
    ...values,
  });

export const useChangeVehiclePictureMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeVehiclePictureMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ChangeVehiclePictureMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeVehiclePictureMutationProps
  >({
    mutationFn: ChangeVehiclePictureMutation,
    throwOnError: false,
    ...options,
  });
