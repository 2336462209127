import { formatDate } from '@bas/shared/utils';
import { ErrorResponse, Money, NullableMoney, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ChangeCostsOfProjectMutationProps = {
  projectId: Uuid;
  costId: Uuid;
  costTypeId: Uuid;
  useDate?: Date | null;
  name: string;
  note?: string | null;
  expectedPurchasePricePerUnit: Money | NullableMoney;
  expectedQuantity?: number | null;
  realizedPurchasePricePerUnit: Money | NullableMoney;
  realizedQuantity?: number | null;
};

export const ChangeCostsOfProjectMutation = async ({
  projectId,
  costId,
  useDate,
  expectedPurchasePricePerUnit,
  realizedPurchasePricePerUnit,
  ...values
}: ChangeCostsOfProjectMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/projects/${projectId}/costs/${costId}/change-costs`,
    {
      costId,
      useDate: useDate ? formatDate(useDate) : null,
      expectedPurchasePricePerUnit:
        expectedPurchasePricePerUnit.amount === null
          ? { ...expectedPurchasePricePerUnit, amount: 0 }
          : expectedPurchasePricePerUnit,
      realizedPurchasePricePerUnit:
        realizedPurchasePricePerUnit.amount === null
          ? { ...realizedPurchasePricePerUnit, amount: 0 }
          : realizedPurchasePricePerUnit,
      ...values,
    },
  );

export const useChangeCostsOfProjectMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeCostsOfProjectMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ChangeCostsOfProjectMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeCostsOfProjectMutationProps
  >({
    mutationFn: ChangeCostsOfProjectMutation,
    throwOnError: false,
    ...options,
  });
