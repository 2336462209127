import * as Yup from 'yup';
import dayjs from 'dayjs';
import { VolumeType } from '@bas/value-objects';

export type VolumeChangeInputType = {
  newVolume: number | null;
  oldVolume: number | null;
  changedOn: Date | null;
  lastInvoicedDate: Date | null;
  invoicePerVolume: boolean | null;
  volumeType: VolumeType | null;
};

export const volumeChangeInputTypeDefaultValues =
  (): VolumeChangeInputType => ({
    newVolume: null,
    oldVolume: null,
    changedOn: null,
    lastInvoicedDate: null,
    invoicePerVolume: null,
    volumeType: null,
  });

export const volumeChangeInputTypeValidationBuilder =
  (): Yup.ObjectSchema<VolumeChangeInputType> =>
    Yup.object({
      newVolume: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .defined()
        .nullable()
        .when(['oldVolume', 'invoicePerVolume'], {
          is: (oldVolume: number | null, invoicePerVolume: boolean | null) =>
            invoicePerVolume && oldVolume === null,
          then: (schema) => schema.required().min(0),
        })
        .label('label.newVolume'),
      invoicePerVolume: Yup.boolean()
        .nullable()
        .defined()
        .label('label.invoicePerVolume'),
      oldVolume: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .defined()
        .nullable()
        .label('label.oldVolume'),
      volumeType: Yup.mixed<VolumeType>()
        .oneOf(Object.values(VolumeType).filter((option) => !!option))
        .defined()
        .nullable()
        .label('label.volumeType'),
      lastInvoicedDate: Yup.date()
        .defined()
        .nullable()
        .label('label.lastInvoicedDate'),
      changedOn: Yup.date()
        .when(
          ['newVolume', 'oldVolume', 'lastInvoicedDate'],
          ([newVolume, oldVolume, lastInvoicedDate], schema) => {
            if (oldVolume === null || newVolume === oldVolume) {
              return schema;
            }

            if (!lastInvoicedDate || !dayjs(lastInvoicedDate).isValid()) {
              return schema;
            }

            return schema.min(dayjs(lastInvoicedDate).toDate());
          },
        )
        .defined()
        .nullable()
        .label('label.changedOn'),
    });
