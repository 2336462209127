import { EmailAddress, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type SendInvoiceReminderMutationProps = {
  invoiceId: Uuid;
  extraEmailAddresses?: EmailAddress[];
};

export const SendInvoiceReminderMutation = async ({
  invoiceId,
  ...values
}: SendInvoiceReminderMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/invoices/${invoiceId}/send-reminder`, {
    invoiceId,
    force: true,
    ...values,
  });

export const useSendInvoiceReminderMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    SendInvoiceReminderMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  SendInvoiceReminderMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    SendInvoiceReminderMutationProps
  >({
    mutationFn: SendInvoiceReminderMutation,
    throwOnError: false,
    ...options,
  });
