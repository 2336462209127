import { Invoice } from '@bas/financial-domain/models';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type UpdateTurnoverGroupDetailsMutationProps = {
  turnoverGroupId: Uuid;
  name: string;
};

export const UpdateTurnoverGroupDetailsMutation = async ({
  turnoverGroupId,
  ...values
}: UpdateTurnoverGroupDetailsMutationProps): Promise<AxiosResponse<Invoice>> =>
  axios.put(
    `api/{tenantId}/turnover-groups/${turnoverGroupId}/update-details`,
    {
      ...values,
    },
  );

export const useUpdateTurnoverGroupDetailsMutation = (
  options: UseMutationOptions<
    AxiosResponse<Invoice>,
    AxiosError<ErrorResponse>,
    UpdateTurnoverGroupDetailsMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse<Invoice>,
  AxiosError<ErrorResponse>,
  UpdateTurnoverGroupDetailsMutationProps
> =>
  useMutation<
    AxiosResponse<Invoice>,
    AxiosError<ErrorResponse>,
    UpdateTurnoverGroupDetailsMutationProps
  >({
    mutationFn: UpdateTurnoverGroupDetailsMutation,
    throwOnError: false,
    ...options,
  });
