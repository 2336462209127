import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type UpdateAddressesOfMovingJobMutationProps = {
  projectId: Uuid;
  addresses: {
    addressType: string;
    buildingType?: string | null;
    primary: boolean;
    notes?: string | null;
    streetName: string;
    houseNumber: string;
    houseNumberAddition?: string | null;
    zipCode: string;
    city: string;
    country: string;
    addressId?: string | null;
    distanceFromCar?: number | null;
    distanceToApartment?: number | null;
    floor?: number | null;
    hasElevator?: boolean | null;
    deliverBoxes?: boolean | null;
    retrieveBoxes?: boolean | null;
    trafficSigns?: boolean | null;
    permitNeeded?: boolean | null;
  }[];
};

export const UpdateAddressesOfMovingJobMutation = async ({
  projectId,
  ...values
}: UpdateAddressesOfMovingJobMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/moving-jobs/${projectId}/update-addresses`, {
    projectId,
    ...values,
  });

export const useUpdateAddressesOfMovingJobMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateAddressesOfMovingJobMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  UpdateAddressesOfMovingJobMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateAddressesOfMovingJobMutationProps
  >({
    mutationFn: UpdateAddressesOfMovingJobMutation,
    throwOnError: false,
    ...options,
  });
