import { colors } from '@bas/theme';
import { BlueSelectButton } from '@bas/ui/native/atoms';
import { Box, Typography } from '@bas/ui/native/base';
import {
  BlueSelectButtonsGroup,
  ReactHookFormNumberTextField,
} from '@bas/ui/native/molecules';
import { InventoryItem, PublicMovingBox } from '@bas/wms-domain/models';
import { faHandSparkles } from '@fortawesome/pro-light-svg-icons/faHandSparkles';
import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash';
import { FlashList, ListRenderItemInfo } from '@shopify/flash-list';
import * as React from 'react';
import { ReactElement, ReactNode, useCallback, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { ViewProps } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import styles from './styles';

export type RetrieveMaterialsFormProps = ViewProps & {
  materials: (InventoryItem | PublicMovingBox)[];
  wornLabel?: ReactElement | string;
  wornDescription?: ReactNode;
  offSetIndex?: number;
  renderHeader?: () => ReactElement;
};

type ListItemType =
  | ((InventoryItem | PublicMovingBox) & {
      materialIndex: number;
      wornOut: boolean;
    })
  | string;

const HasWornOutMaterials = () => {
  const hasWornOutMaterials = useWatch({
    name: 'hasWornOutMaterials',
  });

  const { setValue } = useFormContext();

  return (
    <BlueSelectButtonsGroup
      style={styles.buttonsGroup}
      buttons={[
        <BlueSelectButton
          notSelected={
            hasWornOutMaterials !== undefined && hasWornOutMaterials !== true
          }
          onSelect={() =>
            setValue('hasWornOutMaterials', true, {
              shouldDirty: true,
              shouldTouch: true,
              shouldValidate: true,
            })
          }
          icon={faTrash}
        >
          <FormattedMessage id="label.yes" />
        </BlueSelectButton>,
        <BlueSelectButton
          notSelected={
            hasWornOutMaterials !== undefined && hasWornOutMaterials !== false
          }
          onSelect={() =>
            setValue('hasWornOutMaterials', false, {
              shouldDirty: true,
              shouldTouch: true,
              shouldValidate: true,
            })
          }
          icon={faHandSparkles}
        >
          <FormattedMessage id="label.no" />
        </BlueSelectButton>,
      ]}
    />
  );
};

const MaterialInput = ({
  wornOut,
  name,
  materialIndex,
  offSetIndex,
}: {
  wornOut: boolean;
  name: string;
  materialIndex: number;
  offSetIndex?: number;
}) => {
  const hasWornOutMaterials = useWatch({
    name: 'hasWornOutMaterials',
  });
  const content = useMemo(
    () => (
      <Box style={styles.row}>
        <Typography
          variant="h5"
          fontWeight={700}
          overrideColor={colors.white}
          style={styles.label}
        >
          {name}
        </Typography>
        <Box style={styles.inputContainer}>
          <ReactHookFormNumberTextField
            name={`materials[${materialIndex + (offSetIndex || 0)}].${
              wornOut ? 'quantityWornOut' : 'quantity'
            }`}
            style={styles.input}
            variant="outlined"
            keyboardType="numeric"
            fractionDigits={2}
            shouldBeZero
            padZero={false}
          />
        </Box>
      </Box>
    ),
    [materialIndex, name, offSetIndex, wornOut],
  );

  if (wornOut && !hasWornOutMaterials) {
    return null;
  }

  return content;
};

const WornOutDescription = ({
  wornDescription,
}: {
  wornDescription?: ReactNode;
}) => {
  const hasWornOutMaterials = useWatch({
    name: 'hasWornOutMaterials',
  });

  return hasWornOutMaterials ? (
    <Typography overrideColor={colors.white} pt={4}>
      {wornDescription || (
        <FormattedMessage id="mobileApp.event.retrieveMaterials.whatIsWorn" />
      )}
    </Typography>
  ) : null;
};

const RetrieveMaterialsForm = ({
  materials,
  renderHeader,
  offSetIndex,
  wornDescription,
  wornLabel,
}: RetrieveMaterialsFormProps): ReactElement => {
  const handleRenderItem = useCallback(
    ({ item: material }: ListRenderItemInfo<ListItemType>) => {
      if (typeof material === 'string') {
        if (material === 'header' && renderHeader) {
          return renderHeader();
        }

        if (material === 'wornOut') {
          return (
            <>
              <Typography
                overrideColor={colors.white}
                variant="h5"
                style={styles.question}
              >
                {wornLabel || (
                  <FormattedMessage id="mobileApp.event.retrieveMaterials.areThereMaterialsWornOut" />
                )}
              </Typography>
              <HasWornOutMaterials />
            </>
          );
        }

        if (material === 'wornOutDescription') {
          return <WornOutDescription wornDescription={wornDescription} />;
        }

        return null;
      }

      return (
        <MaterialInput
          materialIndex={material.materialIndex}
          wornOut={material.wornOut}
          name={material.name}
          offSetIndex={offSetIndex}
        />
      );
    },
    [offSetIndex, renderHeader, wornDescription, wornLabel],
  );

  const data = useMemo(() => {
    const result = [];
    const materialsWithIndex = materials.map((material, index) => ({
      ...material,
      wornOut: false,
      materialIndex: index,
    }));
    if (renderHeader) {
      result.push('header');
    }
    result.push(...materialsWithIndex);

    result.push('wornOut');
    result.push('wornOutDescription');
    result.push(
      ...materialsWithIndex.map((material) => ({
        ...material,
        wornOut: true,
      })),
    );

    return result;
  }, [materials, renderHeader]);

  return (
    <FlashList
      renderScrollComponent={ScrollView}
      renderItem={handleRenderItem}
      data={data}
      estimatedItemSize={100}
    />
  );
};

export default RetrieveMaterialsForm;
