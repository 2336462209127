import { isTask } from './Task';
import type { TaskRegardingInvoice } from '../types';

export function isTaskRegardingInvoice(
  object: unknown,
): object is TaskRegardingInvoice {
  return (
    !!object &&
    isTask(object) &&
    Object.prototype.hasOwnProperty.call(object, 'invoiceId') &&
    Object.prototype.hasOwnProperty.call(object, 'invoiceNumber')
  );
}
