import { MobileTaxationStepTemplate } from '@bas/taxation-tool-domain/native/templates';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { MobileTaxationCustomerDetailsForm } from '../MobileTaxationCustomerDetailsForm';

const CustomerDetailsFormStep = (): ReactElement => (
  <MobileTaxationStepTemplate
    primary={
      <FormattedMessage id="mobileApp.intake.movingJob.customerDetails" />
    }
    secondary={<FormattedMessage id="label.customer" />}
    disableScroll
    disableContentPadding
  >
    <MobileTaxationCustomerDetailsForm />
  </MobileTaxationStepTemplate>
);

export default CustomerDetailsFormStep;
