import { useCalculateRealTimeEntriesFromTrackedTime } from '@bas/hrm-domain/utils';
import { TimeTrackingItem, useEmployeeStore } from '@bas/shared/state';
import { Box, Typography } from '@bas/ui/native/base';
import { useWizardStore } from '@bas/ui/native/organisms';
import { useEffect, useState } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { FormattedDate, FormattedMessage, FormattedNumber } from 'react-intl';
import { BreakTimeForm } from '../BreakTimeForm';
import CalculatingTrackedItemsStep from '../CalculatingTrackedItemsStep/CalculatingTrackedItemsStep';

type InputType = {
  trackedItems: TimeTrackingItem[];
  calculatedTimeEntries: TimeTrackingItem[];
  breakTimeStartedAt?: Date | null;
};

const WorkingDayWithBreakTimeStep = ({
  onNext,
}: {
  onNext: (disableOverriddenSubmit?: boolean, force?: boolean) => Promise<void>;
}) => {
  const dayStartedAt = useWatch({
    name: 'dayStartedAt',
  });
  const dayEndedAt = useWatch({
    name: 'dayEndedAt',
  });
  const requiredBreakTime = useWatch({
    name: 'requiredBreakTime',
  });
  const { setTemporarySubmit, setSubStepsForStep } = useWizardStore();
  const [isCalculating, setIsCalculating] = useState<boolean>(false);
  const calculateTimeEntries = useCalculateRealTimeEntriesFromTrackedTime();
  const employeeId = useEmployeeStore((state) => state.employee?.employeeId);

  useEffect(() => {
    setTemporarySubmit(
      ({ setValue: setFormValue }: UseFormReturn<InputType>) =>
        async ({ trackedItems, breakTimeStartedAt }: InputType) => {
          if (!breakTimeStartedAt) {
            // show error
            return;
          }
          setIsCalculating(true);
          const realTimeEntries = calculateTimeEntries({
            trackedItems,
            breakTimeStartedAt,
            breakTimeMinutes: requiredBreakTime,
            employeeId: employeeId || '',
          });
          setSubStepsForStep(
            'unknownTimeEntries',
            realTimeEntries
              .map((item, index) => ({
                ...item,
                originalIndex: index,
              }))
              .filter((item) => !item.timeTypeId)
              .map((item) => ({
                key: item.timeEntryId,
                props: {
                  timeEntry: item,
                  index: item.originalIndex,
                },
              })),
          );
          setFormValue('calculatedTimeEntries', realTimeEntries);
          setTemporarySubmit(undefined);
          onNext(true, true);
        },
    );
  }, [
    calculateTimeEntries,
    employeeId,
    onNext,
    requiredBreakTime,
    setSubStepsForStep,
    setTemporarySubmit,
  ]);

  if (isCalculating) {
    return <CalculatingTrackedItemsStep />;
  }

  return (
    <Box flex={1} flexDirection="column" paddingHorizontal={20} gap={3}>
      <Box>
        <Typography
          variant="h4"
          fontWeight={700}
          color="white"
          textAlign="center"
        >
          <FormattedMessage id="label.finishUpWorkingDay" />
        </Typography>
      </Box>
      <Box>
        <Typography color="white" variant="h5">
          <FormattedMessage
            id="label.stoppingWorkingDayYouStartedAtXAndYouAreEndingNowAtXWhatTimeDidYouHaveABreakAndYourBreakTimeShouldBe"
            values={{
              dayStartedAt: (
                <Typography fontWeight={700} color="white" variant="h5">
                  <FormattedDate value={dayStartedAt} timeStyle="short" />
                </Typography>
              ),
              dayEndedAt: (
                <Typography fontWeight={700} color="white" variant="h5">
                  <FormattedDate value={dayEndedAt} timeStyle="short" />
                </Typography>
              ),
              requiredBreakTime: (
                <Typography fontWeight={700} color="white" variant="h5">
                  <FormattedNumber
                    value={requiredBreakTime}
                    unit="minute"
                    // eslint-disable-next-line react/style-prop-object
                    style="unit"
                  />
                </Typography>
              ),
            }}
          />
        </Typography>
      </Box>
      <Box>
        <BreakTimeForm />
      </Box>
    </Box>
  );
};

export default WorkingDayWithBreakTimeStep;
