import { Uuid } from '@bas/value-objects';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import {
  dayOffInputTypeValidationBuilder,
  DayOffOffInputType,
} from './dayOffInputType';

export type RequestTimeOffInputType = {
  start?: Date | null;
  end?: Date | null;
  fullDay?: boolean;
  timeOffTypeId: Uuid;
  buildUp: boolean;
  reason: string;
  effectiveHours: number;
  daysOff: DayOffOffInputType[];
};

export const requestTimeOffInputTypeDefaultValues =
  (): RequestTimeOffInputType => ({
    timeOffTypeId: '',
    reason: '',
    fullDay: true,
    buildUp: false,
    start: null,
    end: null,
    effectiveHours: 0,
    daysOff: [],
  });

export const requestTimeOffInputTypeValidationBuilder =
  (): Yup.ObjectSchema<RequestTimeOffInputType> =>
    Yup.object({
      timeOffTypeId: Yup.string().required().label('label.timeOffType'),
      reason: Yup.string().required().label('label.reason'),
      fullDay: Yup.boolean().required().label('label.fullDay'),
      buildUp: Yup.boolean().required().label('label.buildUp'),
      start: Yup.date().required().label('label.start'),
      end: Yup.date()
        .when(['start'], ([start], schema) => {
          if (!start || !dayjs(start).isValid()) {
            return schema;
          }

          return schema
            .min(dayjs(start).clone().startOf('day').toDate())
            .required();
        })
        .label('label.end'),
      effectiveHours: Yup.number()
        .min(0)
        .required()
        .label('label.effectiveHours'),
      daysOff: Yup.array()
        .of(dayOffInputTypeValidationBuilder())
        .required()
        .label('label.daysOff'),
    });
