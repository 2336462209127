import * as Yup from 'yup';

export type RejectDeclarationInputType = {
  reason: string;
};

export const rejectDeclarationInputTypeDefaultValues =
  (): RejectDeclarationInputType => ({
    reason: '',
  });

export const rejectDeclarationInputTypeValidationBuilder =
  (): Yup.ObjectSchema<RejectDeclarationInputType> =>
    Yup.object({
      reason: Yup.string().label('label.reaction').ensure(),
    });
