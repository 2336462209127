import {
  InvoiceLineInputType,
  isCreatedFinancialLineInputType,
} from '@bas/financial-domain/input-types';
import { Invoice } from '@bas/financial-domain/models';
import { BillingMomentType } from '@bas/shared/models';
import { formatDate } from '@bas/shared/utils';
import {
  Country,
  Discount,
  ErrorResponse,
  Money,
  Uuid,
} from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type CreateInvoiceForProjectMutationProps = {
  projectId: Uuid;
  billingMomentType: BillingMomentType;
  invoiceId: Uuid;
  relationId: Uuid;
  identityId?: Uuid | null;
  lines: InvoiceLineInputType[];
  freeText: { [key: string]: string };
  includingVat: boolean;
  templateId: Uuid;
  reference?: string | null;
  expiresAt?: Date;
  addressId: Uuid;
  contactPersonId?: Uuid | null;
  region: Country;
  sendInvoice?: boolean | null;
  finishInvoice?: boolean | null;
  discount?: Discount;
  payment?: {
    paidAt: Date;
    amount: Money;
    remarks?: string | null;
  };
};

export const CreateInvoiceForProjectMutation = async ({
  projectId,
  expiresAt,
  lines,
  payment,
  ...values
}: CreateInvoiceForProjectMutationProps): Promise<AxiosResponse<Invoice>> =>
  axios.put(`api/{tenantId}/projects/${projectId}/create-invoice`, {
    projectId,
    expiresAt: expiresAt ? formatDate(expiresAt) : undefined,
    lines: lines.filter(isCreatedFinancialLineInputType).map((line) => ({
      ...line,
      vatCodeId: line.vatCode.vatCodeId,
    })),
    payment: payment?.amount
      ? {
          ...payment,
          paidAt: formatDate(payment.paidAt),
          amount: {
            ...payment.amount,
            currency: payment.amount.currency || 'EUR',
          },
        }
      : null,
    ...values,
  });

export const useCreateInvoiceForProjectMutation = (
  options: UseMutationOptions<
    AxiosResponse<Invoice>,
    AxiosError<ErrorResponse>,
    CreateInvoiceForProjectMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse<Invoice>,
  AxiosError<ErrorResponse>,
  CreateInvoiceForProjectMutationProps
> =>
  useMutation<
    AxiosResponse<Invoice>,
    AxiosError<ErrorResponse>,
    CreateInvoiceForProjectMutationProps
  >({
    mutationFn: CreateInvoiceForProjectMutation,
    throwOnError: false,
    ...options,
  });
