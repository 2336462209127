/* eslint-disable no-underscore-dangle */
import { colors } from '@bas/theme';
import { Typography, TypographyVariant } from '@bas/ui/native/base';
import { RenderRules } from '@jonasmerlin/react-native-markdown-display';
import { View } from 'react-native';

export const textRules: RenderRules = {
  text: (node, children, parent, styles) => {
    let variant = 'body1';
    const headingParent = parent.find(({ type }) => type.includes('heading'));
    if (headingParent) {
      variant = headingParent.type.replace('heading', 'h');
    }

    return (
      <Typography
        key={node.key}
        style={[styles.text]}
        variant={variant as TypographyVariant}
      >
        {node.content}
      </Typography>
    );
  },
  hr: (node, children, parent, styles) => (
    <View
      key={node.key}
      style={[styles._VIEW_SAFE_hr, { backgroundColor: colors.lila[400] }]}
    />
  ),
};
