import { isTask } from './Task';
import type { CustomTaskRegardingEmail } from '../types';
import { TaskType } from '../types';

export function isCustomTaskRegardingEmail(
  object: unknown,
): object is CustomTaskRegardingEmail {
  return (
    !!object &&
    isTask(object) &&
    object.taskType === TaskType.CUSTOM_TASK_REGARDING_EMAIL
  );
}
