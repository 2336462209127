import { ReactElement } from 'react';
import { Box, Typography } from '@bas/ui/native/base';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Declaration } from '@bas/hrm-domain/models';
import { FormattedCurrency } from '@bas/ui/native/atoms';

export type DeclarationInformationContentSectionProps = {
  declaration: Declaration;
};

const DeclarationInformationContentSection = ({
  declaration,
}: DeclarationInformationContentSectionProps): ReactElement => (
  <Box flexDirection="row" flexWrap="wrap">
    <Box flexBasis="100%">
      <Typography variant="subtitle1" color="white">
        <FormattedMessage id="label.declarationInformation" />
      </Typography>
    </Box>
    <Box flexBasis="50%">
      <Typography color="white">{declaration.description}</Typography>
      <Typography color="white">{declaration.categoryName}</Typography>
    </Box>
    <Box flexBasis="50%">
      <Typography color="white">
        <FormattedCurrency {...declaration.amount} />
      </Typography>
      <Typography color="white">
        <FormattedDate value={declaration.declarationDate} dateStyle="short" />
      </Typography>
    </Box>
  </Box>
);

export default DeclarationInformationContentSection;
