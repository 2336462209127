import { formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type UnloadInventoryFromStorageMutationProps = {
  inventoryItemId: Uuid;
  eventDate: Date;
  quantity: number;
  storageId: Uuid;
  customerId?: Uuid | null;
  projectId?: Uuid | null;
  planningEventId?: Uuid | null;
};

export const UnloadInventoryFromStorageMutation = async ({
  inventoryItemId,
  eventDate,
  ...values
}: UnloadInventoryFromStorageMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/inventory/item/${inventoryItemId}/retrieve-from-storage`,
    {
      inventoryItemId,
      eventDate: formatDateTime(eventDate),
      ...values,
    },
  );

export const useUnloadInventoryFromStorageMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UnloadInventoryFromStorageMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  UnloadInventoryFromStorageMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UnloadInventoryFromStorageMutationProps
  >({
    mutationFn: UnloadInventoryFromStorageMutation,
    throwOnError: false,
    ...options,
  });
