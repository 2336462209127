import * as Yup from 'yup';
import { Uuid } from '@bas/value-objects';
import { v7 } from 'uuid';
import {
  TransportJobAddressInputType,
  TransportJobAddressInputTypeValidationBuilder,
} from './transportJobAddressInputType';
import {
  TransportJobCargoItemInputType,
  TransportJobCargoItemInputTypeValidationBuilder,
} from './transportJobCargoItemInputType';

export interface TransportJobCargoInputType {
  cargoId: Uuid;
  summary: string;
  description?: string | null;
  cubicMeter?: number | null;
  length?: number | null;
  width?: number | null;
  height?: number | null;
  addresses: TransportJobAddressInputType[];
  items: TransportJobCargoItemInputType[];
}

export const TransportJobCargoInputTypeDefaultValues =
  (): TransportJobCargoInputType => ({
    cargoId: v7(),
    summary: '',
    description: '',
    length: 0,
    width: 0,
    height: 0,
    cubicMeter: 0,
    addresses: [],
    items: [],
  });

export const TransportJobCargoInputTypeValidationBuilder =
  (): Yup.ObjectSchema<TransportJobCargoInputType> =>
    Yup.object({
      cargoId: Yup.string().required().label('label.cargoId'),
      summary: Yup.string().required().label('label.summary'),
      description: Yup.string().label('label.description').nullable(),
      cubicMeter: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .nullable()
        .label('label.cubicMeter'),
      length: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .nullable()
        .label('label.length'),
      height: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .nullable()
        .label('label.height'),
      width: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .nullable()
        .label('label.width'),
      addresses: Yup.array()
        .of(TransportJobAddressInputTypeValidationBuilder())
        .required(),
      items: Yup.array()
        .of(TransportJobCargoItemInputTypeValidationBuilder())
        .required(),
    });
