import { formatDate } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type DisableDeclarationCategoryMutationProps = {
  categoryId: Uuid;
  disableAfter: Date;
};

export const DisableDeclarationCategoryMutation = async ({
  categoryId,
  disableAfter,
  ...values
}: DisableDeclarationCategoryMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/hrm/declaration-categories/${categoryId}/disable`, {
    categoryId,
    disableAfter: formatDate(disableAfter),
    ...values,
  });

export const useDisableDeclarationCategoryMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    DisableDeclarationCategoryMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  DisableDeclarationCategoryMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    DisableDeclarationCategoryMutationProps
  >({
    mutationFn: DisableDeclarationCategoryMutation,
    throwOnError: false,
    ...options,
  });
