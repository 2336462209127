import {
  initialCrmAddressInputType,
  initialCrmContactPersonInputType,
} from '@bas/crm-domain/input-types';
import { Money, Uuid } from '@bas/value-objects';
import * as Yup from 'yup';
import {
  FinancialDocumentContentInputType,
  FinancialDocumentContentInputTypeValidationBuilder,
} from './financialDocumentContentInputType';
import {
  FinancialDocumentCustomerInformationInputType,
  FinancialDocumentCustomerInformationInputTypeValidationBuilder,
} from './financialDocumentCustomerInformationInputType';
import {
  FinancialDocumentInputType,
  FinancialDocumentInputTypeValidationBuilder,
} from './financialDocumentInputType';
import {
  QuoteLineInputType,
  quoteLineInputTypeValidatorBuilder,
} from './quoteLineInputType';

export type CreateQuoteInputType =
  FinancialDocumentContentInputType<QuoteLineInputType> &
    FinancialDocumentCustomerInformationInputType &
    FinancialDocumentInputType & {
      projectId?: Uuid;
      shouldChargeVat: boolean;
      totalIncludingVat: Money;
      expiresAt?: Date | null;
      sendQuote?: boolean | null;
      finishQuote?: boolean | null;
    };

export const CreateQuoteInputTypeDefaultValues = (): CreateQuoteInputType => ({
  contactPersonId: '',
  addressId: '',
  templateId: '',
  identityId: '',
  projectId: '',
  includingVat: true,
  ignoreZeroQuantityLines: false,
  shouldChargeVat: true,
  freeText: {},
  lines: [],
  totalIncludingVat: {
    amount: 0,
    currency: 'EUR',
  },
  reference: '',
  contactPerson: { ...initialCrmContactPersonInputType() },
  address: { ...initialCrmAddressInputType() },
  expiresAt: null,
  finishQuote: true,
});

export const CreateQuoteInputTypeValidationBuilder = (): Yup.ObjectSchema<
  Omit<CreateQuoteInputType, 'relation' | 'address' | 'contactPerson'>
> =>
  Yup.object({
    projectId: Yup.string().label('label.projectId'),
    shouldChargeVat: Yup.boolean().required().label('label.shouldChargeVat'),
    totalIncludingVat: Yup.object({
      amount: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .nullable()
        .required()
        .label('label.pricePerUnit'),
      currency: Yup.string().required(),
    }),
    expiresAt: Yup.date().nullable().label('label.expiresAt'),
    sendQuote: Yup.boolean().nullable().label('label.sendQuote'),
    finishQuote: Yup.boolean().nullable().label('label.finishQuote'),
  })
    .concat(FinancialDocumentCustomerInformationInputTypeValidationBuilder())
    .concat(FinancialDocumentContentInputTypeValidationBuilder())
    .concat(
      Yup.object({
        lines: Yup.array()
          .of(quoteLineInputTypeValidatorBuilder())
          .required()
          .min(1),
      }),
    )
    .concat(FinancialDocumentInputTypeValidationBuilder());
