import { WhatsAppMessageTemplate } from '@bas/integration-domain/models';
import { Pagination, QueryOptionsWithKey } from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';

export type WhatsAppMessageTemplatesByIntegrationIdRequestProps = Pagination & {
  integrationId: Uuid;
  phoneNumberId: string;
};

type Response = AxiosResponse<Collection<WhatsAppMessageTemplate>>;

export const WhatsAppMessageTemplatesByIntegrationIdAndPhoneNumberIdRequest =
  async ({
    integrationId,
    phoneNumberId,
    ...params
  }: WhatsAppMessageTemplatesByIntegrationIdRequestProps): Promise<Response> =>
    axios.get(
      `api/{tenantId}/integrations/${integrationId}/whatsapp/${phoneNumberId}/message-templates`,
      {
        params: { ...params },
        paramsSerializer(param) {
          return Qs.stringify(param, { arrayFormat: 'brackets' });
        },
      },
    );

export const useWhatsAppMessageTemplatesByIntegrationIdRequest = (
  request: WhatsAppMessageTemplatesByIntegrationIdRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () =>
      WhatsAppMessageTemplatesByIntegrationIdAndPhoneNumberIdRequest(request),
    queryKey: [
      'integrations',
      'whatsapp-message-templates',
      request.integrationId,
      request.phoneNumberId,
      request,
    ],
  });
