import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type RemoveHoursMutationProps = {
  entryId: Uuid;
};

export const RemoveHoursMutation = async ({
  entryId,
  ...values
}: RemoveHoursMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/hours-entries/${entryId}/remove-hours`, {
    entryId,
    ...values,
  });

export const useRemoveHoursMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveHoursMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  RemoveHoursMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveHoursMutationProps
  >({
    mutationFn: RemoveHoursMutation,
    throwOnError: false,
    ...options,
  });
