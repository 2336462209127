import { Invoice } from '@bas/financial-domain/models';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type CreateTurnoverGroupMutationProps = {
  turnoverGroupId: Uuid;
  name: string;
};

export const CreateTurnoverGroupMutation = async ({
  ...values
}: CreateTurnoverGroupMutationProps): Promise<AxiosResponse<Invoice>> =>
  axios.post(`api/{tenantId}/turnover-groups`, {
    ...values,
  });

export const useCreateTurnoverGroupMutation = (
  options: UseMutationOptions<
    AxiosResponse<Invoice>,
    AxiosError<ErrorResponse>,
    CreateTurnoverGroupMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse<Invoice>,
  AxiosError<ErrorResponse>,
  CreateTurnoverGroupMutationProps
> =>
  useMutation<
    AxiosResponse<Invoice>,
    AxiosError<ErrorResponse>,
    CreateTurnoverGroupMutationProps
  >({
    mutationFn: CreateTurnoverGroupMutation,
    throwOnError: false,
    ...options,
  });
