import { colors } from '@bas/theme';
import { Checkbox, InternalServiceTypeIcon } from '@bas/ui/native/atoms';
import { Typography } from '@bas/ui/native/base';
import { InternalServiceType } from '@bas/value-objects';
import { ReactElement, ReactNode } from 'react';
import { View, ViewProps } from 'react-native';
import styles from './styles';

export type MobileRoomFurnitureListItemProps = ViewProps & {
  name: string | ReactNode;
  color?: string;
  services: {
    serviceType: InternalServiceType;
    requested: boolean;
  }[];
  hasCheckbox?: boolean;
  checked?: boolean;
  disabled?: boolean;
  onCheck?: (checked: boolean) => void;
};

const MobileRoomFurnitureListItem = ({
  name,
  services,
  color,
  hasCheckbox,
  checked,
  disabled,
  onCheck,
  ...args
}: MobileRoomFurnitureListItemProps): ReactElement => (
  <View style={styles.listItem} {...args}>
    <View style={styles.checkboxLabelContainer}>
      {hasCheckbox && (
        <Checkbox
          checked={checked || false}
          disabled={disabled}
          onCheck={onCheck}
          size={31}
          keepLoadingUntilValueChanges
        />
      )}
      <Typography
        style={styles.label}
        overrideColor={disabled ? colors.lila[600] : color}
      >
        {name}
      </Typography>
    </View>
    <View style={styles.icons}>
      {services.map(({ serviceType, requested }) => (
        <InternalServiceTypeIcon
          key={serviceType}
          serviceType={serviceType}
          overrideColor={requested ? colors.green[300] : colors.red[700]}
          style={styles.icon}
          size={21}
        />
      ))}
    </View>
  </View>
);

export default MobileRoomFurnitureListItem;
