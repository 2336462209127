import { ReactHookWizardStep } from '@bas/value-objects';
import { FieldValues, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { zustandStorage } from './zustandStorage';

export type OverrideSubmitType<TFieldValues extends FieldValues = FieldValues> =

    | ((form: UseFormReturn<TFieldValues>) => SubmitHandler<TFieldValues>)
    | undefined;

export type WizardStoreType<
  TFieldValues extends FieldValues = FieldValues,
  TOverrideSubmitType extends
    OverrideSubmitType<TFieldValues> = OverrideSubmitType<TFieldValues>,
> = {
  wizardKey: string;
  currentStepIndex: number;
  finishedSteps: number[];
  setSubStepsForStep: (
    stepKey: string,
    subSteps: ({
      key: string;
    } & Partial<ReactHookWizardStep<TFieldValues>>)[],
  ) => void;
  subSteps: {
    [key: string]: ({
      key: string;
    } & Partial<ReactHookWizardStep<TFieldValues>>)[];
  };
  currentValues?: TFieldValues;
  loadedData: boolean;
  submitted: boolean;
  deleteWizard: () => void;
  goBack: () => void;
  updateValues: (values: TFieldValues) => void;
  getValues: () => TFieldValues | undefined;
  finishCurrentStep: () => void;
  setSubmitted: (submitted: boolean) => void;
  setCurrentStepIndex: (currentStepIndex: number) => void;
  updateWizard: (values: {
    wizardKey: string;
    currentStepIndex: number;
    finishedSteps: number[];
    currentValues?: TFieldValues;
    loadedData: boolean;
  }) => void;
  setNumberOfSteps: (numberOfSteps: number) => void;
  getCurrentStepIndex: () => number;
  numberOfSteps: number;
  isLastStep: () => boolean;
  isFirstStep: () => boolean;
  isStepFinished: (stepIndex: number) => boolean;
  blockedNext: boolean;
  hasBlockedNext: () => boolean;
  setBlockedNext: (blockedNext: boolean) => void;
  temporarySubmit?: TOverrideSubmitType;
  getTemporarySubmit: () => TOverrideSubmitType | undefined;
  setTemporarySubmit: (
    temporarySubmit: TOverrideSubmitType | undefined,
  ) => void;
};

export const createWizardStore = <
  TFieldValues extends FieldValues = FieldValues,
  TOverrideSubmitType extends
    OverrideSubmitType<TFieldValues> = OverrideSubmitType<TFieldValues>,
>(
  wizardKey: string,
  initialStepIndex = 0,
  persistStore = false,
) =>
  create<WizardStoreType<TFieldValues, TOverrideSubmitType>>()(
    persist(
      (set, get) => ({
        wizardKey,
        currentStepIndex: initialStepIndex,
        numberOfSteps: 0,
        finishedSteps: [],
        currentValues: undefined,
        loadedData: false,
        submitted: false,
        subSteps: {},
        setSubStepsForStep: (stepIndex, subSteps) => {
          set((state) => ({
            subSteps: {
              ...state.subSteps,
              [stepIndex]: subSteps,
            },
          }));
        },
        blockedNext: false,
        temporarySubmit: undefined,
        updateValues: (values: TFieldValues) => {
          set({ currentValues: values });
        },
        getValues: () => {
          const { currentValues } = get();
          return currentValues;
        },
        hasBlockedNext: () => {
          const { blockedNext } = get();
          return blockedNext;
        },
        setBlockedNext: (blockedNext: boolean) => {
          set({ blockedNext });
        },
        setNumberOfSteps: (numberOfSteps: number) => {
          set({ numberOfSteps });
        },
        getCurrentStepIndex: () => {
          const { currentStepIndex } = get();
          return currentStepIndex;
        },
        deleteWizard: () => {
          set({
            currentStepIndex: initialStepIndex,
            finishedSteps: [],
            currentValues: undefined,
            loadedData: false,
            submitted: false,
            blockedNext: false,
            temporarySubmit: undefined,
          });
        },
        setCurrentStepIndex: (currentStepIndex: number) => {
          set({ currentStepIndex });
        },
        goBack: () => {
          const { currentStepIndex } = get();
          set({
            currentStepIndex: currentStepIndex - 1,
          });
        },
        finishCurrentStep: () => {
          const { currentStepIndex, finishedSteps } = get();
          let newFinishedSteps = finishedSteps;
          if (!finishedSteps.includes(currentStepIndex)) {
            newFinishedSteps = [...finishedSteps, currentStepIndex];
          }

          set({
            currentStepIndex: currentStepIndex + 1,
            finishedSteps: newFinishedSteps,
          });
        },
        setSubmitted: () => {
          set({ submitted: true });
        },
        updateWizard: (values) => {
          set({
            ...values,
          });
        },
        isLastStep: () => {
          const { currentStepIndex, numberOfSteps } = get();
          return currentStepIndex === numberOfSteps - 1;
        },
        isFirstStep: () => {
          const { currentStepIndex } = get();
          return currentStepIndex === 0;
        },
        isStepFinished: (stepIndex: number) => {
          const { finishedSteps } = get();
          return finishedSteps.includes(stepIndex);
        },
        getTemporarySubmit: () => {
          const { temporarySubmit } = get();
          return temporarySubmit;
        },
        setTemporarySubmit: (
          temporarySubmit: TOverrideSubmitType | undefined,
        ) => {
          set({ temporarySubmit });
        },
      }),
      {
        version: 1,
        merge: (persistedState, initialState) => {
          if (!persistedState) {
            return { ...initialState, loadedData: true };
          }

          return { ...initialState, ...persistedState, loadedData: true };
        },
        name: `wizard-store-${wizardKey}`,
        storage: persistStore
          ? zustandStorage()
          : {
              getItem: () => Promise.resolve(null),
              setItem: () => Promise.resolve(),
              removeItem: () => Promise.resolve(),
            },
      },
    ),
  );
