import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    borderRadius: 2,
    paddingTop: 4,
    paddingBottom: 6,
    paddingLeft: 10,
    paddingRight: 10,
    height: 'auto',
    width: 'auto',
  },
});
