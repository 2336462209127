import {
  InvoiceLineInputType,
  isCreatedFinancialLineInputType,
} from '@bas/financial-domain/input-types';
import { Invoice } from '@bas/financial-domain/models';
import { formatDate } from '@bas/shared/utils';
import { Country, Discount, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type CreateInvoiceMutationProps = {
  invoiceId: Uuid;
  relationId: Uuid;
  identityId?: Uuid | null;
  projectId?: Uuid | null;
  lines: InvoiceLineInputType[];
  freeText: { [key: string]: string };
  includingVat: boolean;
  templateId: Uuid;
  reference?: string | null;
  expiresAt?: Date;
  addressId: Uuid;
  contactPersonId?: Uuid | null;
  region: Country;
  sendInvoice?: boolean | null;
  finishInvoice?: boolean | null;
  discount?: Discount;
};

export const CreateInvoiceMutation = async ({
  lines,
  expiresAt,
  ...values
}: CreateInvoiceMutationProps): Promise<AxiosResponse<Invoice>> =>
  axios.post('api/{tenantId}/invoices', {
    expiresAt: expiresAt ? formatDate(expiresAt) : undefined,
    lines: lines.filter(isCreatedFinancialLineInputType).map((line) => ({
      ...line,
      vatCodeId: line.vatCode.vatCodeId,
    })),
    ...values,
  });

export const useCreateInvoiceMutation = (
  options: UseMutationOptions<
    AxiosResponse<Invoice>,
    AxiosError<ErrorResponse>,
    CreateInvoiceMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse<Invoice>,
  AxiosError<ErrorResponse>,
  CreateInvoiceMutationProps
> =>
  useMutation<
    AxiosResponse<Invoice>,
    AxiosError<ErrorResponse>,
    CreateInvoiceMutationProps
  >({
    mutationFn: CreateInvoiceMutation,
    throwOnError: false,
    ...options,
  });
