import {
  isMovingJob,
  MovingJobAddressType,
  Project,
} from '@bas/project-domain/models';
import { DamageInputType } from '@bas/shared/input-types';
import { EventType } from '@bas/value-objects';
import { InventoryMovementInputType } from '@bas/wms-domain/input-types';
import { ReactElement, useMemo } from 'react';
import { FormattedMessage, FormattedTime } from 'react-intl';
import { Event } from '@bas/planning-domain/models';
import { View } from 'react-native';
import { Box, Typography } from '@bas/ui/native/base';
import { colors } from '@bas/theme';
import { AddressBlock } from '@bas/ui/native/atoms';
import {
  BoxesSigningDataItems,
  DamagesSigningDataItems,
} from '@bas/ui/native/molecules';
import styles from './styles';

export type EventTypeSigningDataProps = {
  className?: string;
  startTime?: null | Date;
  endTime?: null | Date;
  breakTime?: null | number;
  event: Event;
  project: Project;
  damagesToProcess: DamageInputType[];
  wereThereAnyParticulars?: boolean;
  particulars?: string | null;
  materialMovements: InventoryMovementInputType[];
};

const EventTypeSigningData = ({
  className,
  startTime,
  endTime,
  breakTime,
  event,
  project,
  damagesToProcess,
  wereThereAnyParticulars,
  particulars,
  materialMovements,
}: EventTypeSigningDataProps): ReactElement => {
  let extraContent: JSX.Element;

  const fromAddress = useMemo(
    () =>
      isMovingJob(project) &&
      project.addresses.find(
        ({ primary, addressType }) =>
          primary && addressType === MovingJobAddressType.FROM,
      ),
    [project],
  );

  const toAddress = useMemo(
    () =>
      isMovingJob(project) &&
      project.addresses.find(
        ({ primary, addressType }) =>
          primary && addressType === MovingJobAddressType.TO,
      ),
    [project],
  );

  if (
    [EventType.DELIVER_BOXES_EVENT, EventType.PICKUP_BOXES_EVENT].includes(
      event.eventType,
    ) &&
    isMovingJob(project)
  ) {
    extraContent = (
      <BoxesSigningDataItems
        project={project}
        materialMovements={materialMovements}
      />
    );
  } else {
    extraContent = (
      <>
        {isMovingJob(project) && (
          <BoxesSigningDataItems
            project={project}
            materialMovements={materialMovements}
          />
        )}
        <DamagesSigningDataItems
          project={project}
          damagesToProcess={damagesToProcess}
          wereThereAnyParticulars={wereThereAnyParticulars}
          particulars={particulars}
        />
      </>
    );
  }

  return (
    <View>
      <View>
        <Typography overrideColor={colors.white} variant="subtitle1">
          <FormattedMessage id="mobileApp.finishEvent.signingStep.performedWork" />
        </Typography>
        <Typography overrideColor={colors.white}>
          <FormattedMessage
            id={`mobileApp.finishEvent.signingStep.performedWork.${event.eventType}`}
            values={{
              location: (
                <AddressBlock
                  address={event.location}
                  variant="single_line"
                  color={colors.white}
                />
              ),
              fromAddress: fromAddress ? (
                <AddressBlock
                  address={fromAddress}
                  variant="single_line"
                  color={colors.white}
                />
              ) : null,
              toAddress: toAddress ? (
                <AddressBlock
                  address={toAddress}
                  variant="single_line"
                  color={colors.white}
                />
              ) : null,
            }}
          />
        </Typography>
      </View>
      <View style={styles.paddingBetweenRows}>
        <Typography overrideColor={colors.white}>
          <FormattedMessage
            id="mobileApp.finishEvent.signingStep.theWorkWasFromXUntilX"
            values={{
              startTime: (
                <Typography overrideColor={colors.white} fontWeight={700}>
                  <FormattedTime value={startTime || event.start} />
                </Typography>
              ),
              endTime: (
                <Typography overrideColor={colors.white} fontWeight={700}>
                  <FormattedTime value={endTime || event.end} />
                </Typography>
              ),
              breakTime: breakTime ? 60 * breakTime : 0,
              breakTimeBold: (
                <Typography overrideColor={colors.white} fontWeight={700}>
                  {breakTime ? 60 * breakTime : 0}
                </Typography>
              ),
            }}
          />
        </Typography>
      </View>
      <Box rowGap={1}>{extraContent}</Box>
    </View>
  );
};

export default EventTypeSigningData;
