import { formatDate } from '@bas/shared/utils';
import {
  Country,
  EmailAddress,
  ErrorResponse,
  Gender,
  Language,
  PhoneNumber,
  Uuid,
} from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ChangeEmployeeInformationMutationProps = {
  employeeId: Uuid;
  gender?: Gender | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName: string;
  dateOfBirth?: Date | null;
  emailAddress: EmailAddress;
  phoneNumber?: PhoneNumber | null;
  language: Language;
  region: Country;
};

export const ChangeEmployeeInformationMutation = async ({
  employeeId,
  dateOfBirth,
  ...values
}: ChangeEmployeeInformationMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/hrm/employees/${employeeId}`, {
    employeeId,
    dateOfBirth: dateOfBirth ? formatDate(dateOfBirth) : null,
    ...values,
  });

export const useChangeEmployeeInformationMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeEmployeeInformationMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ChangeEmployeeInformationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeEmployeeInformationMutationProps
  >({
    mutationFn: ChangeEmployeeInformationMutation,
    throwOnError: false,
    ...options,
  });
