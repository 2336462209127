import { InternalTimeOffType } from '@bas/hrm-domain/models';
import { ErrorResponse, TranslatedName, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ChangeTimeOffTypeMutationProps = {
  timeOffTypeId: Uuid;
  name: string;
  weight: number;
  translatedNames: TranslatedName[];
  internalTimeOffType: InternalTimeOffType;
  allowBuildUp: boolean;
  icon: string;
};

export const ChangeTimeOffTypeMutation = async ({
  timeOffTypeId,
  ...values
}: ChangeTimeOffTypeMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/hrm/time-off-types/${timeOffTypeId}/change`, {
    timeOffTypeId,
    ...values,
  });

export const useChangeTimeOffTypeMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeTimeOffTypeMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ChangeTimeOffTypeMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeTimeOffTypeMutationProps
  >({
    mutationFn: ChangeTimeOffTypeMutation,
    throwOnError: false,
    ...options,
  });
