import { useGetConversationForEmployee } from '@bas/shared/hooks';
import {
  TimeTrackingItem,
  useEmployeeStore,
  useTimeTrackingStore,
  useUserStore,
} from '@bas/shared/state';
import { userHasReadNewsItem } from '@bas/tenant-domain/models';
import { usePublishedNewsRequest } from '@bas/tenant-domain/requests';
import { colors } from '@bas/theme';
import { BottomTabIcon, CustomTabButton } from '@bas/ui/native/atoms';
import { faBars } from '@fortawesome/pro-light-svg-icons/faBars';
import { faCalendar } from '@fortawesome/pro-light-svg-icons/faCalendar';
import { faHome } from '@fortawesome/pro-light-svg-icons/faHome';
import { faMessage } from '@fortawesome/pro-light-svg-icons/faMessage';
import { faNewspaper } from '@fortawesome/pro-light-svg-icons/faNewspaper';
import { faTruckClock } from '@fortawesome/pro-light-svg-icons/faTruckClock';
import dayjs from 'dayjs';
import { Tabs } from 'expo-router';
import humanizeDuration from 'humanize-duration';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Platform } from 'react-native';

const TabsLayout = () => {
  const [numberOfHelpMessages, setNumberOfHelpMessages] = useState<number>();
  const employeeId = useEmployeeStore((state) => state.employee?.employeeId);
  const userId = useUserStore((state) => state.user?.userId);

  const { conversation, isPending: isLoadingConversation } =
    useGetConversationForEmployee({
      employeeId: employeeId || '',
    });

  const numberOfUnreadMessages = useMemo(() => {
    if (isLoadingConversation || !conversation || !userId || !employeeId) {
      return 0;
    }

    return conversation.unreadMessages;
  }, [conversation, employeeId, isLoadingConversation, userId]);

  const publishedBefore = useMemo(() => new Date(), []);
  const { isLoading, data: newsData } = usePublishedNewsRequest({
    publishedBefore,
  });

  useEffect(() => {
    const interval = setInterval(async () => {
      if (Platform.OS !== 'web') {
        // const result = await Intercom.getUnreadConversationCount();
        setNumberOfHelpMessages(0 || undefined);
      }
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  const numberOfUnreadNews = useMemo(
    () =>
      !isLoading && newsData?.data && userId
        ? newsData.data.member?.filter(
            (newsItem) =>
              !userHasReadNewsItem(newsItem, userId) &&
              dayjs(newsItem?.publishedAt).isBefore(dayjs()),
          ).length
        : 0,
    [isLoading, newsData?.data, userId],
  );

  useEffect(() => {
    // setBadgeCountAsync(numberOfUnreadMessages + numberOfUnreadNews);
  }, [numberOfUnreadMessages, numberOfUnreadNews]);

  const renderNull = useCallback(() => null, []);

  const renderHomeIcon = useCallback(
    // eslint-disable-next-line react/no-unused-prop-types
    ({ color }: { color: string }) => (
      <BottomTabIcon icon={faHome} color={color} />
    ),
    [],
  );

  const renderCalendarIcon = useCallback(
    // eslint-disable-next-line react/no-unused-prop-types
    ({ color }: { color: string }) => (
      <BottomTabIcon icon={faCalendar} color={color} />
    ),
    [],
  );

  const renderTruckClockIcon = useCallback(
    // eslint-disable-next-line react/no-unused-prop-types
    ({ color }: { color: string }) => (
      <BottomTabIcon icon={faTruckClock} color={color} />
    ),
    [],
  );

  const renderMessageIcon = useCallback(
    // eslint-disable-next-line react/no-unused-prop-types
    ({ color }: { color: string }) => (
      <BottomTabIcon icon={faMessage} color={color} />
    ),
    [],
  );

  const renderNewspaperIcon = useCallback(
    // eslint-disable-next-line react/no-unused-prop-types
    ({ color }: { color: string }) => (
      <BottomTabIcon icon={faNewspaper} color={color} />
    ),
    [],
  );

  const renderDrawerIcon = useCallback(
    // eslint-disable-next-line react/no-unused-prop-types
    ({ color }: { color: string }) => (
      <BottomTabIcon icon={faBars} color={color} />
    ),
    [],
  );

  const workedHours = useTimeTrackingStore((state) => state.today.workedHours);
  const setActiveEmployeeId = useTimeTrackingStore(
    (state) => state.setActiveEmployeeId,
  );
  const activeEmployeeId = useTimeTrackingStore(
    (state) => state.activeEmployeeId,
  );

  useEffect(() => {
    if (!employeeId && !!activeEmployeeId) {
      setActiveEmployeeId('');
      return;
    }

    if (employeeId !== activeEmployeeId) {
      setActiveEmployeeId(employeeId || '');
    }
  }, [activeEmployeeId, employeeId, setActiveEmployeeId]);

  const trackedTimePerDate = useTimeTrackingStore(
    (state) => state.trackedTimePerDate,
  );

  const numberOfTrackedItemsToSync = useMemo(() => {
    const items = Object.values(trackedTimePerDate).reduce(
      (acc, row) => [...acc, ...row],
      [] as TimeTrackingItem[],
    );

    const itemsToSync = items.filter(
      ({ employeeId: itemEmployeeId, start, end }) =>
        itemEmployeeId === employeeId &&
        (end || dayjs(start).isSame(dayjs(), 'day')),
    );

    return itemsToSync.length;
  }, [employeeId, trackedTimePerDate]);

  return (
    <Tabs
      screenOptions={{
        headerShown: false,
        tabBarShowLabel: true,
        tabBarLabelPosition: 'below-icon',
        tabBarActiveTintColor: colors.blue[500],
        tabBarInactiveTintColor: colors.lila[600],
        tabBarStyle: {
          borderTopWidth: 0,
          minHeight: 56,
        },
      }}
    >
      <Tabs.Screen
        name="index"
        options={{
          tabBarLabel: renderNull,
          tabBarButton: CustomTabButton,
          tabBarIcon: renderHomeIcon,
        }}
      />
      <Tabs.Screen
        name="calendar"
        options={{
          tabBarButton: CustomTabButton,
          tabBarLabel: renderNull,
          tabBarIcon: renderCalendarIcon,
        }}
      />
      <Tabs.Screen
        name="time-tracking"
        options={{
          tabBarLabelStyle: {
            paddingBottom: 8,
          },
          tabBarBadge:
            numberOfTrackedItemsToSync > 0
              ? numberOfTrackedItemsToSync
              : undefined,
          tabBarBadgeStyle: {
            backgroundColor: colors.blue[500],
            marginLeft: 8,
          },
          tabBarLabel: humanizeDuration(workedHours * 3600 * 1000, {
            language: 'nl',
            round: true,
            fallbacks: ['en'],
            units: ['y', 'mo', 'w', 'd', 'h', 'm'],
          }),
          tabBarShowLabel: true,
          tabBarButton: CustomTabButton,
          tabBarIcon: renderTruckClockIcon,
        }}
      />
      <Tabs.Screen
        name="chat"
        options={{
          tabBarLabel: () => null,
          tabBarButton: CustomTabButton,
          tabBarIcon: renderMessageIcon,
          tabBarBadge:
            numberOfUnreadMessages > 0 ? numberOfUnreadMessages : undefined,
          tabBarBadgeStyle: {
            backgroundColor: colors.blue[500],
          },
        }}
      />
      <Tabs.Screen
        name="news"
        options={{
          tabBarLabel: () => null,
          tabBarButton: CustomTabButton,
          tabBarIcon: renderNewspaperIcon,
          tabBarBadge: numberOfUnreadNews > 0 ? numberOfUnreadNews : undefined,
          tabBarBadgeStyle: {
            backgroundColor: colors.blue[500],
          },
        }}
      />
      <Tabs.Screen
        name="drawer"
        listeners={({ navigation }) => ({
          tabPress: (e: { preventDefault: () => void }) => {
            e.preventDefault();
            navigation.toggleDrawer();
          },
        })}
        options={{
          tabBarLabel: renderNull,
          tabBarButton: CustomTabButton,
          tabBarIcon: renderDrawerIcon,
          tabBarBadge: numberOfHelpMessages,
          tabBarBadgeStyle: {
            backgroundColor: colors.blue[500],
          },
        }}
      />
      <Tabs.Screen
        name="profile"
        options={{
          tabBarLabel: renderNull,
          tabBarItemStyle: {
            display: 'none',
          },
        }}
      />
      <Tabs.Screen
        name="warehouse"
        options={{
          tabBarLabel: renderNull,
          tabBarItemStyle: {
            display: 'none',
          },
        }}
      />
    </Tabs>
  );
};

export default TabsLayout;
