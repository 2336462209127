import dayjs from 'dayjs';
import * as Yup from 'yup';

export interface AddTemporaryWorkerInputType {
  employeeId?: string | null;
  startDate?: Date | null;
  endDate?: Date | null;
}

export const AddTemporaryWorkerInputTypeDefaultValues =
  (): AddTemporaryWorkerInputType => ({
    employeeId: '',
    startDate: null,
    endDate: null,
  });

export const AddTemporaryWorkerInputTypeValidationBuilder =
  (): Yup.ObjectSchema<AddTemporaryWorkerInputType> =>
    Yup.object({
      employeeId: Yup.string().label('label.employeeId'),
      startDate: Yup.date().required().nullable().label('label.startTime'),
      endDate: Yup.date()
        .nullable()
        .required()

        .when(['startDate'], ([startDate], schema) => {
          if (!startDate || !dayjs(startDate).isValid()) return schema;
          return schema
            .min(dayjs(startDate).clone().add(1, 'second').toDate())
            .max(dayjs(startDate).clone().endOf('day').toDate())
            .required();
        })
        .label('label.endTime'),
    });
