import { Button } from '@bas/ui/native/atoms';
import { Box, Icon, Typography } from '@bas/ui/native/base';
import { faIntercom } from '@fortawesome/free-brands-svg-icons/faIntercom';
import { faBlockQuestion } from '@fortawesome/pro-light-svg-icons';
import { router, Stack } from 'expo-router';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { SafeAreaView } from 'react-native-safe-area-context';

const NotFoundScreen = () => {
  const handleBackHome = useCallback(() => {
    router.push('/');
  }, []);

  const handleRequestHelp = useCallback(() => {
    console.log('handleRequestHelp');
  }, []);

  return (
    <Box flex={1} backgroundColor="darkBackground">
      <Stack.Screen options={{ headerShown: false }} />
      <SafeAreaView style={{ flex: 1 }}>
        <Box flex={1} alignItems="center" justifyContent="center">
          <Box maxWidth={425} padding={{ phone: 20 }}>
            <Box alignItems="center" marginBottom={2}>
              <Icon icon={faBlockQuestion} size={104} color="lightText" />
            </Box>
            <Typography
              variant="h3"
              color="lightText"
              marginBottom={4}
              fontWeight={700}
            >
              <FormattedMessage id="notFoundPage.title" />
            </Typography>
            <Typography variant="h5" color="lightText">
              <FormattedMessage id="notFoundPage.text" />
            </Typography>
            <Box flexDirection="row" flexWrap="wrap" marginTop={2} gap={2}>
              <Button onPress={handleRequestHelp}>
                <Icon icon={faIntercom} color="lightText" />
                &nbsp;
                <FormattedMessage id="errorFallback.iNeedHelp" />
              </Button>
              <Button variant="outlined" onPress={handleBackHome}>
                <FormattedMessage id="errorFallback.backToHome" />
              </Button>
            </Box>
          </Box>
        </Box>
      </SafeAreaView>
    </Box>
  );
};

export default NotFoundScreen;
