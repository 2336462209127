import { StyleSheet } from 'react-native';
import { colors } from '@bas/theme';

export default StyleSheet.create({
  checkbox: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.white,
    borderColor: colors.white,
    borderRadius: 5,
  },
  checkMark: {
    height: 31,
    width: 31,
  },
  disabled: {
    backgroundColor: colors.lila[600],
  },
  desktop: {
    backgroundColor: colors.white,
    borderColor: colors.lila[800],
    borderWidth: 2,
    borderRadius: 1,
    marginRight: 13,
  },
  disabledDesktop: {
    opacity: 0.12,
  },
  desktopChecked: {
    backgroundColor: '#1F1F1F',
  },
});
