import {
  Filterable,
  Pagination,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';

type InvoiceExpiresAtResult = {
  invoiceId: Uuid;
  calculatedExpiresAt: Date;
};

export type BulkInvoiceExpiresAtRequestProps = Pagination &
  Filterable & {
    projectId?: Uuid;
    relation?: Uuid;
  };

type Response = AxiosResponse<Collection<InvoiceExpiresAtResult>>;

export const BulkInvoiceExpiresAtRequest = async ({
  ...params
}: BulkInvoiceExpiresAtRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/invoices/calculate-expires-at`, {
    params: { ...params },
    paramsSerializer(param) {
      return Qs.stringify(param, { arrayFormat: 'brackets' });
    },
  });

export const useBulkInvoiceExpiresAtRequestQuery = (
  request: BulkInvoiceExpiresAtRequestProps,
  options: QueryOptionsWithKey<Response, AxiosError, Response> = {},
): UseQueryResult<Response, AxiosError> =>
  useQuery<Response, AxiosError, Response>({
    ...options,
    queryFn: async () => BulkInvoiceExpiresAtRequest(request),
    queryKey: ['invoices', 'bulk-expires-at', request],
  });
