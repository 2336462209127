import {
  isMovingJobCustomFurniture,
  isMovingJobStandardFurniture,
  MovingJobFurniture,
} from '@bas/project-domain/models';
import { useGetTranslatedName } from '@bas/shared/hooks';
import { RenderTranslatedName } from '@bas/ui/native/atoms';
import { Typography, TypographyProps } from '@bas/ui/native/base';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

export type RenderMovingJobFurnitureNameProps = TypographyProps & {
  furniture: MovingJobFurniture;
};

const RenderMovingJobFurnitureName = ({
  furniture,
  ...props
}: RenderMovingJobFurnitureNameProps): ReactElement | null => {
  const getTranslatedName = useGetTranslatedName();
  if (isMovingJobCustomFurniture(furniture)) {
    return <Typography {...props}>{furniture.label}</Typography>;
  }

  if (isMovingJobStandardFurniture(furniture)) {
    return (
      <>
        <RenderTranslatedName
          translations={furniture.furnitureType.translatedNames}
          fallback={furniture.furnitureType.furnitureTypeName}
          {...props}
        />
        {furniture.furnitureVariant && (
          <Typography {...props} variant="body2">
            &nbsp;
            <FormattedMessage
              id={`variantLabel.selected.${furniture.furnitureVariant.valueType}`}
              values={{
                variantLabel: getTranslatedName(
                  furniture.furnitureVariant.translatedNames,
                  furniture.furnitureVariant.variantTypeName,
                ),
              }}
            />
          </Typography>
        )}
      </>
    );
  }

  return null;
};

export default RenderMovingJobFurnitureName;
