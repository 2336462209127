import * as Yup from 'yup';

export type ClaEnableOrDisableAllowanceRuleInputType = {
  enabledFrom: Date;
  enabledUntil?: Date;
};

export const claEnableOrDisableAllowanceRuleInputTypeValidationBuilder =
  (): Yup.ObjectSchema<ClaEnableOrDisableAllowanceRuleInputType> =>
    Yup.object({
      enabledFrom: Yup.date().required().label('label.enabledFrom'),
      enabledUntil: Yup.date().optional().label('label.enabledUntil'),
    });
