import { PublicMovingBox } from '../../types';

export function isPublicMovingBox(object: unknown): object is PublicMovingBox {
  return (
    !!object &&
    typeof object === 'object' &&
    Object.prototype.hasOwnProperty.call(object, 'inventoryItemId') &&
    Object.prototype.hasOwnProperty.call(object, 'name') &&
    Object.prototype.hasOwnProperty.call(object, 'length') &&
    Object.prototype.hasOwnProperty.call(object, 'width') &&
    Object.prototype.hasOwnProperty.call(object, 'height')
  );
}
