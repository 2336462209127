import * as Yup from 'yup';
import { Money } from '@bas/value-objects';

export type MarkDeclarationAsPaidInputType = {
  amount: Money;
  paidOn: Date | null | undefined;
  reason?: string;
};

export const markDeclarationAsPaidInputTypeDefaultValues =
  (): MarkDeclarationAsPaidInputType => ({
    amount: {
      amount: 0,
      currency: 'EUR',
    },
    paidOn: new Date(),
    reason: '',
  });

export const markDeclarationAsPaidInputTypeValidationBuilder =
  (): Yup.ObjectSchema<MarkDeclarationAsPaidInputType> =>
    Yup.object({
      paidOn: Yup.date().nullable().label('label.paidOn').required(),
      reason: Yup.string().label('label.reaction').ensure(),
      amount: Yup.object({
        amount: Yup.number()
          .transform((value, original) =>
            original === '' || original === null ? null : value,
          )
          .nullable()
          .required()
          .label('label.amount'),
        currency: Yup.string().required(),
      }),
    });
