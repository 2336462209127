import { TrafficObstructionSummary } from '@bas/shared/models';
import { colors } from '@bas/theme';
import { Typography } from '@bas/ui/native/base';
import { ReactElement } from 'react';
import { View } from 'react-native';
import styles from './styles';

export type TrafficObstructionListItemProps = {
  obstruction: TrafficObstructionSummary;
  dark?: boolean;
};

const TrafficObstructionListItem = ({
  obstruction,
  dark,
}: TrafficObstructionListItemProps): ReactElement => (
  <View style={styles.container}>
    <View style={styles.roadNumberContainer}>
      <View style={styles.roadNumberStyling} />
      <Typography
        variant="body2"
        fontWeight={700}
        overrideColor={colors.lila[100]}
      >
        {obstruction.roadNumber}
      </Typography>
    </View>
    <View style={styles.obstructionText}>
      <Typography style={styles.obstructionTitle}>
        {obstruction.title}
      </Typography>
      <Typography
        style={[styles.obstructionDescription, dark && styles.lightText]}
      >
        {obstruction.directionText?.replace(' - ', ' → ')}
        {'\n'}
        {obstruction.locationText}
      </Typography>
    </View>
  </View>
);

export default TrafficObstructionListItem;
