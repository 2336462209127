import { TransportJobAddressType } from '@bas/project-domain/models';
import {
  AddressInputType,
  addressInputTypeDefaultValues,
  addressInputTypeValidationBuilder,
} from '@bas/shared/input-types';
import { BuildingType, Uuid } from '@bas/value-objects';
import { v7 } from 'uuid';
import * as Yup from 'yup';

export interface TransportJobAddressInputType extends AddressInputType {
  addressId: Uuid;
  addressType: TransportJobAddressType;
  buildingType?: BuildingType | null;
  distanceFromCar?: number | null;
  distanceToApartment?: number | null;
  floor?: number | null;
  hasElevator?: boolean | null;
  notes?: string | null;
}

export const TransportJobAddressInputTypeDefaultValues =
  (): TransportJobAddressInputType => ({
    ...addressInputTypeDefaultValues(),
    addressId: v7(),
    addressType: TransportJobAddressType.FROM,
    buildingType: BuildingType.UNKNOWN,
    distanceFromCar: undefined,
    distanceToApartment: undefined,
    floor: undefined,
    hasElevator: null,
    houseNumberAddition: '',
    notes: '',
  });

export const TransportJobAddressInputTypeValidationBuilder =
  (): Yup.ObjectSchema<TransportJobAddressInputType> =>
    Yup.object({
      addressType: Yup.mixed<TransportJobAddressType>()
        .label('label.addressType')
        .oneOf(Object.values(TransportJobAddressType))
        .required(),
      buildingType: Yup.mixed<BuildingType>()
        .label('label.buildingType')
        .nullable()
        .oneOf([...Object.values(BuildingType), null, undefined]),
      distanceFromCar: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .label('label.distance')
        .nullable(),
      distanceToApartment: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .label('label.distance')
        .nullable(),
      floor: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .label('label.floor')
        .nullable(),
      hasElevator: Yup.boolean()
        .label('label.hasElevator')
        .notRequired()
        .nullable(),
      notes: Yup.string().label('label.notes').nullable(),
      addressId: Yup.string().required().label('label.addressId'),
    }).concat(addressInputTypeValidationBuilder());
