import { ErrorResponse, Language, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type RemoveCoverSheetFromDocumentTemplateMutationProps = {
  documentTemplateId: Uuid;
  mediaObjectId: Uuid;
  language: Language;
};

export const RemoveCoverSheetFromDocumentTemplateMutation = async ({
  documentTemplateId,
  ...values
}: RemoveCoverSheetFromDocumentTemplateMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/document-templates/${documentTemplateId}/remove-cover-sheet`,
    {
      documentTemplateId,
      ...values,
    },
  );

export const useRemoveCoverSheetFromDocumentTemplateMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveCoverSheetFromDocumentTemplateMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  RemoveCoverSheetFromDocumentTemplateMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveCoverSheetFromDocumentTemplateMutationProps
  >({
    mutationFn: RemoveCoverSheetFromDocumentTemplateMutation,
    throwOnError: false,
    ...options,
  });
