import { isAbstractBillingMomentLine } from './AbstractBillingMomentLine';
import type { FixedAmountBillingMomentLine } from '../types';

export function isFixedAmountBillingMomentLine(
  object: unknown,
): object is FixedAmountBillingMomentLine {
  return (
    !!object &&
    isAbstractBillingMomentLine(object) &&
    object['@type'] === 'FixedAmountBillingMomentLine'
  );
}
