import * as Yup from 'yup';
import { Uuid } from '@bas/value-objects';

export interface HowManyMovingBoxesAreNeededStepInputType {
  inventoryItems: {
    inventoryItemId: Uuid;
    quantity?: number | null;
    storageQuantity?: number | null;
  }[];
}

export const HowManyMovingBoxesAreNeededStepInputTypeDefaultValues =
  (): HowManyMovingBoxesAreNeededStepInputType => ({
    inventoryItems: [],
  });

export const HowManyMovingBoxesAreNeededStepInputTypeValidatorBuilder =
  (): Yup.ObjectSchema<HowManyMovingBoxesAreNeededStepInputType> =>
    Yup.object({
      inventoryItems: Yup.array()
        .of(
          Yup.object({
            inventoryItemId: Yup.string().required(),
            quantity: Yup.number()
              .transform((value, original) =>
                original === '' || original === null ? null : value,
              )
              .nullable()
              .min(0)
              .integer()
              .label('label.quantity'),
            storageQuantity: Yup.number()
              .transform((value, original) =>
                original === '' || original === null ? null : value,
              )
              .nullable()
              .min(0)
              .integer()
              .label('label.quantity'),
          }),
        )
        .required(),
    });
