import { EmailAddress, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type UpdateTenantSignerInformationMutationProps = {
  tenantId: Uuid;
  name: string;
  emailAddress: EmailAddress;
  signature: string;
};

export const UpdateTenantSignerInformationMutation = async ({
  ...values
}: UpdateTenantSignerInformationMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/tenants/{tenantId}/update-signer-information`, {
    ...values,
  });

export const useUpdateTenantSignerInformationMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateTenantSignerInformationMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  UpdateTenantSignerInformationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateTenantSignerInformationMutationProps
  >({
    mutationFn: UpdateTenantSignerInformationMutation,
    throwOnError: false,
    ...options,
  });
