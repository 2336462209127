import type { TaskRegardingProject } from '../types';
import { isTaskRegardingRelation } from './TaskRegardingRelation';

export function isTaskRegardingProject(
  object: unknown,
): object is TaskRegardingProject {
  return (
    !!object &&
    isTaskRegardingRelation(object) &&
    Object.prototype.hasOwnProperty.call(object, 'projectId') &&
    object.projectId !== null
  );
}
