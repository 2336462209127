import { formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type EnterTimeMutationProps = {
  timeEntryId: Uuid;
  employeeId: Uuid;
  start: Date;
  end: Date;
  timeTypeId: Uuid;
  description?: string;
  projectId: Uuid | null | undefined;
  eventId: Uuid | null | undefined;
  relationId: Uuid | null | undefined;
};

export const EnterTimeMutation = async ({
  start,
  end,
  ...values
}: EnterTimeMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/{tenantId}/hrm/time-entries`, {
    employeeId: values.employeeId,
    timeEntry: {
      start: formatDateTime(start),
      end: formatDateTime(end),
      ...values,
    },
  });

export const useEnterTimeMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    EnterTimeMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  EnterTimeMutationProps
> =>
  useMutation<AxiosResponse, AxiosError<ErrorResponse>, EnterTimeMutationProps>(
    {
      mutationFn: EnterTimeMutation,
      throwOnError: false,
      ...options,
    },
  );
