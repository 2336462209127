import { Button } from '@bas/ui/native/atoms';
import { BottomSheetMethods, Box, Typography } from '@bas/ui/native/base';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import LottieView from 'lottie-react-native';
import { forwardRef, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { BottomSheet, BottomSheetProps } from '../BottomSheet';

export type RequestWorkingDayPermissionsBottomSheetProps = Omit<
  BottomSheetProps,
  'snapPoints' | 'children'
> & {
  onRequestPermissions: () => void;
  onNotNow: () => void;
  name: string;
};

const RequestWorkingDayPermissionsBottomSheet = forwardRef<
  BottomSheetModal & BottomSheetMethods,
  RequestWorkingDayPermissionsBottomSheetProps
>(
  (
    {
      onRequestPermissions,
      onNotNow,
      name,
      ...props
    }: RequestWorkingDayPermissionsBottomSheetProps,
    ref,
  ): ReactElement => (
    <BottomSheet ref={ref} {...props}>
      <Box height="100%" width="100%" paddingBottom={4} gap={3}>
        <Box>
          <Typography
            variant="h4"
            fontWeight={700}
            color="white"
            textAlign="center"
          >
            <FormattedMessage
              id="label.requestWorkingDayPermissions"
              values={{ name }}
            />
          </Typography>
        </Box>
        <Box>
          <LottieView
            autoPlay
            // eslint-disable-next-line global-require
            source={require('./delivery-woman.json')}
            style={{
              height: 300,
            }}
          />
        </Box>
        <Box>
          <Typography color="white">
            <FormattedMessage id="label.requestWorkingDayPermissions.description" />
          </Typography>
        </Box>
        <Box justifyContent="flex-end" flex={1}>
          <Button onPress={onRequestPermissions}>
            <FormattedMessage id="label.requestWorkingDayPermissions.permissionsButton" />
          </Button>
          <Button mt={2} variant="text" color="white" onPress={onNotNow}>
            <FormattedMessage id="button.notNow" />
          </Button>
        </Box>
      </Box>
    </BottomSheet>
  ),
);

export default RequestWorkingDayPermissionsBottomSheet;
