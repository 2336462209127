import { formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type RejectDeclarationMutationProps = {
  declarationId: Uuid;
  rejectedById: Uuid;
  rejectedOn: Date;
  reason: string;
};

export const RejectDeclarationMutation = async ({
  declarationId,
  rejectedOn,
  ...values
}: RejectDeclarationMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/hrm/declarations/${declarationId}/reject`, {
    declarationId,
    rejectedOn: formatDateTime(rejectedOn),
    ...values,
  });

export const useRejectDeclarationMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RejectDeclarationMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  RejectDeclarationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RejectDeclarationMutationProps
  >({
    mutationFn: RejectDeclarationMutation,
    throwOnError: false,
    ...options,
  });
