import { FuelCard, Maintenance, TowBar } from '@bas/planning-domain/models';
import { formatDate } from '@bas/shared/utils';
import {
  DriversLicenceCharacteristics,
  ErrorResponse,
  Money,
  NullableMoney,
  PropulsionType,
  TransmissionType,
  Uuid,
} from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type AddVehicleMutationProps = {
  materialId: Uuid;
  name: string;
  vinNumber?: string | null;
  licensePlate?: string | null;
  model?: string | null;
  propulsionType?: PropulsionType;
  transmissionType?: TransmissionType;
  registeredAt?: Date | null;
  purchasedAt?: Date | null;
  towBar?: TowBar | null;
  pricePerKilometer?: Money | NullableMoney;
  pricePerHour?: Money | NullableMoney;
  dealer?: string | null;
  leasingCompany?: string | null;
  maintenance: Maintenance;
  fuelCard?: FuelCard | null;
  driversLicenseNeeded: DriversLicenceCharacteristics;
};

export const AddVehicleMutation = async ({
  materialId,
  registeredAt,
  purchasedAt,
  maintenance,
  pricePerKilometer,
  pricePerHour,
  ...values
}: AddVehicleMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/{tenantId}/vehicles`, {
    materialId,
    registeredAt: registeredAt ? formatDate(registeredAt) : null,
    purchasedAt: purchasedAt ? formatDate(purchasedAt) : null,
    maintenance: {
      motNeededAt: maintenance?.motNeededAt
        ? formatDate(maintenance?.motNeededAt)
        : null,
      maintenanceNeededAt: maintenance?.maintenanceNeededAt
        ? formatDate(maintenance?.maintenanceNeededAt)
        : null,
    },
    pricePerKilometer:
      !pricePerKilometer || pricePerKilometer.amount === null
        ? { ...pricePerKilometer, amount: 0, currency: 'EUR' }
        : { ...pricePerKilometer, currency: 'EUR' },
    pricePerHour:
      !pricePerHour || pricePerHour.amount === null
        ? { ...pricePerHour, amount: 0, currency: 'EUR' }
        : { ...pricePerHour, currency: 'EUR' },
    ...values,
  });

export const useAddVehicleMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddVehicleMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  AddVehicleMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddVehicleMutationProps
  >({
    mutationFn: AddVehicleMutation,
    throwOnError: false,
    ...options,
  });
