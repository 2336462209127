import { DamageInputType } from '@bas/shared/input-types';
import { formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import dayjs from 'dayjs';

export type FinishProjectEventAtCustomerMutationProps = {
  eventId: Uuid;
  finishedAtCustomerAt: Date;
  startTime: Date;
  endTime: Date;
  breakTime: number | null;
  peopleWithDifferentHours?: {
    employeeId: Uuid;
    label: string;
    startDate: Date;
    endDate: Date;
    breakTime: number | null;
    reason?: string | null;
  }[];
  particulars?: string | null;
  internalParticulars?: string | null;
  damages: DamageInputType[];
  wasTheWorkPerformedSatisfactorily?: boolean;
  remarks?: string | null;
  name?: string | null;
  emailAddress?: string | null;
  signature?: string | null;
};

export const FinishProjectEventAtCustomerMutation = async ({
  eventId,
  finishedAtCustomerAt,
  startTime,
  endTime,
  peopleWithDifferentHours,
  damages,
  ...values
}: FinishProjectEventAtCustomerMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/events/${eventId}/finish-project-event-at-customer`,
    {
      ...values,
      eventId,
      finishedAtCustomerAt: formatDateTime(finishedAtCustomerAt),
      startTime: formatDateTime(startTime),
      endTime: formatDateTime(endTime),
      damages: damages.map((damage) => ({
        ...damage,
        damagedAt: formatDateTime(dayjs(damage.damagedAt)),
      })),
      peopleWithDifferentHours: (peopleWithDifferentHours || []).map(
        ({
          startDate: employeeStartTime,
          endDate: employeeEndTime,
          ...row
        }) => ({
          ...row,
          startDate: formatDateTime(employeeStartTime),
          endDate: formatDateTime(employeeEndTime),
        }),
      ),
    },
  );

export const useFinishProjectEventAtCustomerMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    FinishProjectEventAtCustomerMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  FinishProjectEventAtCustomerMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    FinishProjectEventAtCustomerMutationProps
  >({
    mutationFn: FinishProjectEventAtCustomerMutation,
    throwOnError: false,
    ...options,
  });
