import {
  AddressInputType,
  addressInputTypeDefaultValues,
  addressInputTypeValidationBuilder,
} from '@bas/shared/input-types';
import {
  EventType,
  isLocationOptional,
  optionalDepartureLocation,
  showEventLocation,
} from '@bas/value-objects';
import { Dayjs } from 'dayjs';
import * as Yup from 'yup';
import {
  EventDetailsInputType,
  EventDetailsInputTypeDefaultValues,
  EventDetailsInputTypeValidationBuilder,
} from './eventDetailsInputType';

export type PlanNonProjectEventInputType = EventDetailsInputType & {
  location: AddressInputType;
  departureLocation: AddressInputType;
};

export const PlanNonProjectEventInputTypeDefaultValues = (
  date?: Date | Dayjs,
): PlanNonProjectEventInputType => ({
  ...EventDetailsInputTypeDefaultValues(date),
  location: addressInputTypeDefaultValues(),
  departureLocation: addressInputTypeDefaultValues(),
});

export const PlanNonProjectEventInputTypeValidationBuilder = (
  allowedEventTypes?: EventType[],
): Yup.ObjectSchema<
  Omit<PlanNonProjectEventInputType, 'location' | 'departureLocation'>
> =>
  EventDetailsInputTypeValidationBuilder(allowedEventTypes).concat(
    // eslint-disable-next-line no-empty-pattern
    Yup.object().when([], ([], schema, { value: { eventType } }) => {
      let result = schema;

      if (!optionalDepartureLocation(eventType)) {
        result = result.concat(
          Yup.object({
            departureLocation: addressInputTypeValidationBuilder()
              .required()
              .concat(
                Yup.object({
                  addressId: Yup.string()
                    .required()
                    .label('label.departureLocation'),
                }),
              ),
          }),
        );
      }

      if (!showEventLocation(eventType) || isLocationOptional(eventType)) {
        return result;
      }

      return result.concat(
        Yup.object({
          location: addressInputTypeValidationBuilder().required(),
        }),
      );
    }),
  );
