import { Document, isPrivateRelation } from '@bas/crm-domain/models';
import { MediaObject } from '@bas/media-domain/models';
import { ProjectEvent, Vehicle } from '@bas/planning-domain/models';
import { MovingJobPhotosBottomSheet } from '@bas/planning-domain/native/organisms';
import {
  useCanPayAtCustomerByEventIdRequest,
  useEventsByProjectIdRequest,
} from '@bas/planning-domain/requests';
import {
  Damage,
  isMovingJob,
  isMovingJobCustomFurniture,
  isMovingJobStandardFurniture,
  MovingJob,
  MovingJobFurniture,
  ProjectEstimateItem,
} from '@bas/project-domain/models';
import { MovingJobAddressBlock } from '@bas/project-domain/moving-job/native/molecules';
import { useFinishOrResetFurnitureServiceMutation } from '@bas/project-domain/mutations';
import {
  CheckServiceOfFurnitureListBottomSheet,
  FurnitureListBottomSheet,
  ProjectOutstandingInventoryItems,
} from '@bas/project-domain/native/organisms';
import { useProjectEstimates } from '@bas/project-domain/requests';
import {
  useGetTranslatedName,
  useHandleRetrieveMaterials,
  useHandleTakeMaterials,
} from '@bas/shared/hooks';
import { BillingMomentType } from '@bas/shared/models';
import { useEmployeeStore } from '@bas/shared/state';
import { PublicTenant } from '@bas/tenant-domain/models';
import { colors } from '@bas/theme';
import { AddressBlock } from '@bas/ui/native/atoms';
import { BottomSheetMethods, Box, Typography } from '@bas/ui/native/base';
import {
  ContactPersonBlock,
  DamageSummaryItem,
  IdentityIndicator,
  RenderContactInformation,
} from '@bas/ui/native/molecules';
import {
  EmployeeProjectRole,
  EventType,
  InternalServiceType,
  isEventWithoutShowingAddresses,
  showEventLocation,
  showEventName,
  Uuid,
} from '@bas/value-objects';
import {
  LoadOrUnloadStorageInputType,
  OptionalHandlingCostsInputType,
  PickUpStorageInputType,
  StoredItemInputType,
  storedItemInputTypeDefaultValues,
} from '@bas/wms-domain/input-types';
import {
  RetrieveMaterialsBottomSheet,
  TakeMaterialsBottomSheet,
} from '@bas/wms-domain/inventory/native/organisms';
import { StoredItem } from '@bas/wms-domain/models';
import {
  LoadInventoryInStorageMutationProps,
  UnloadInventoryFromStorageMutationProps,
  useAddHandlingCostsToStorageMutation,
  useLoadInventoryInStorageMutation,
  usePickUpStorageMutation,
  useUnloadInventoryFromStorageMutation,
  useUpdateStorageInventoryListMutation,
} from '@bas/wms-domain/mutations';
import {
  usePublicMovingBoxesRequest,
  useReusableMaterialsRequest,
  useStorageOutstandingStockByProjectIdRequest,
  useStoragesByProjectIdRequest,
  useStoredItemsRequest,
} from '@bas/wms-domain/requests';
import {
  LoadOrUnloadStorageBottomSheet,
  PickUpStorageFormWizardBottomSheet,
} from '@bas/wms-domain/storage/native/organisms';
import { faDownload } from '@fortawesome/pro-light-svg-icons/faDownload';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { keepPreviousData, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import * as Location from 'expo-location';
import { LocationObject } from 'expo-location/src/Location.types';
import { router } from 'expo-router';
import * as WebBrowser from 'expo-web-browser';
import * as React from 'react';
import {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import {
  Image,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from 'react-native';
import { v7 } from 'uuid';
import { EventScreenTemplate } from '../EventScreenTemplate';
import { useMovingJobEventPageActions } from './eventActions';
import { OpenListType } from './openListType';
import { showMovingBoxesEventTypes } from './showMovingBoxesEventTypes';
import styles from './styles';

export type MovingJobEventScreenTemplateProps = {
  project: MovingJob;
  tenant: PublicTenant;
  event: ProjectEvent;
  materials: Vehicle[];
  showEmployeeDetails: boolean;
  onStartFinalizingEventAtCustomer: () => void;
  onStartRegisterMileage: () => void;
  onStartFinalizingEvent: () => void;
  onStartPayingAtCustomer: (billingMomentType: BillingMomentType) => void;
  onStartReportingDamage: () => void;
  onStartUploadingDocument: () => void;
  onOpenWorkingOnEventForm: () => void;
  onOpenDrivingToEventForm: () => void;
  damages: Damage[];
  documents: Document[];
  loadingInvoice?: boolean;
  children?: ReactNode;
  onBack: () => void;
  isFetching: boolean;
  onRefresh: () => void;
};

const eventsWithStorage = [
  EventType.MOVING_EVENT,
  EventType.LOAD_STORAGE_EVENT,
  EventType.UNLOAD_STORAGE_EVENT,
  EventType.MOVING_LOADING_EVENT,
  EventType.MOVING_UNLOADING_EVENT,
];

const MovingJobEventScreenTemplate = ({
  tenant,
  event,
  materials,
  showEmployeeDetails,
  onStartFinalizingEventAtCustomer,
  onStartFinalizingEvent,
  onStartPayingAtCustomer,
  onStartReportingDamage,
  onStartUploadingDocument,
  onOpenWorkingOnEventForm,
  onOpenDrivingToEventForm,
  onStartRegisterMileage,
  project,
  damages,
  documents,
  loadingInvoice,
  children,
  onBack,
  isFetching: isFetchingParent,
  onRefresh: onRefreshParent,
}: MovingJobEventScreenTemplateProps): ReactElement => {
  const [location, setLocation] = useState<LocationObject | null>(null);
  const employeeState = useEmployeeStore((state) => state.employee);
  const deliverBoxesRef = useRef<BottomSheetModal & BottomSheetMethods>(null);
  const takeMaterialsRef = useRef<BottomSheetModal & BottomSheetMethods>(null);
  const pickupBoxesRef = useRef<BottomSheetModal & BottomSheetMethods>(null);
  const retrieveMaterialsRef = useRef<BottomSheetModal & BottomSheetMethods>(
    null,
  );
  const furnitureListRef = useRef<BottomSheetModal & BottomSheetMethods>(null);
  const checkFurnitureListRef = useRef<BottomSheetModal & BottomSheetMethods>(
    null,
  );
  const pickUpStorageFormBottomSheetRef = useRef<
    BottomSheetModal & BottomSheetMethods
  >(null);
  const loadOrUnloadFurnitureListRef = useRef<
    BottomSheetModal & BottomSheetMethods
  >(null);
  const [serviceType, setServiceType] = useState<InternalServiceType>();
  const [loadOrUnloadStorage, setLoadOrUnloadStorage] = useState<
    'load' | 'unload'
  >();
  const dimensions = useWindowDimensions();
  const smallScreen = useMemo(() => dimensions.width < 600, [dimensions]);

  const photosBottomSheetRef = useRef<BottomSheetModal & BottomSheetMethods>(
    null,
  );

  const { mutateAsync: pickUpStorageMutation } = usePickUpStorageMutation();
  const { mutateAsync: addHandlingCostsMutation } =
    useAddHandlingCostsToStorageMutation();

  const handleSubmitPickUpStorage = useCallback(
    async ({
      invoiceHandlingCosts,
      handlingCosts,
      selectedStorageId,
      ...values
    }: PickUpStorageInputType & OptionalHandlingCostsInputType) => {
      if (invoiceHandlingCosts && handlingCosts) {
        await addHandlingCostsMutation({
          storageId: selectedStorageId as Uuid,
          handlingCostsId: v7(),
          ...handlingCosts,
        });
      }

      await pickUpStorageMutation({
        ...values,
        employeeId: values.employeeId as Uuid,
        storageId: selectedStorageId as Uuid,
      });
    },
    [pickUpStorageMutation, addHandlingCostsMutation],
  );

  const {
    data: eventsData,
    isFetching: isFetchingEvents,
    refetch: refetchEvents,
  } = useEventsByProjectIdRequest({
    projectId: project.projectId,
  });

  const {
    data: canPayAtCustomerData,
    isFetching: isFetchingCanPayAtCustomer,
    refetch: refetchCanPayAtCustomer,
  } = useCanPayAtCustomerByEventIdRequest({
    eventId: event.eventId,
  });

  useEffect(() => {
    (async () => {
      const result = await Location.getCurrentPositionAsync({});
      setLocation(result);
    })();
  }, []);

  const projectEvents = useMemo(
    () => eventsData?.data?.member || [],
    [eventsData?.data],
  );

  const intakeEvent = useMemo(
    () =>
      EventType.INTAKE_EVENT === event.eventType
        ? event
        : projectEvents.find(
            ({ eventType }) => eventType === EventType.INTAKE_EVENT,
          ),
    [event, projectEvents],
  );

  const {
    data: storagesData,
    isFetching: isFetchingStorage,
    refetch: refetchStorage,
  } = useStoragesByProjectIdRequest(
    {
      projectId: project.projectId,
    },
    {
      enabled: eventsWithStorage.includes(event.eventType),
    },
  );

  const {
    data: storageOutstandingData,
    isFetching: isFetchingStorageOutstanding,
    refetch: refetchStorageOutstanding,
  } = useStorageOutstandingStockByProjectIdRequest(
    {
      projectId: project.projectId,
    },
    {
      enabled: event.eventType !== EventType.INTAKE_EVENT,
    },
  );

  const {
    data: boxesData,
    isFetching: isFetchingPublicMovingBoxes,
    refetch: refetchPublicMovingBoxes,
  } = usePublicMovingBoxesRequest();

  const {
    data: reusableMaterialsData,
    isFetching: isFetchingReusableMaterials,
    refetch: refetchReusableMaterials,
  } = useReusableMaterialsRequest(
    {
      page: 1,
      perPage: 999999,
    },
    {
      placeholderData: keepPreviousData,
    },
  );

  const { mutateAsync: finishOrResetFurnitureService } =
    useFinishOrResetFurnitureServiceMutation();

  const { mutateAsync: loadInventoryInStorageMutation } =
    useLoadInventoryInStorageMutation();
  const { mutateAsync: unloadInventoryFromStorageMutation } =
    useUnloadInventoryFromStorageMutation();
  const { mutateAsync: updateInventoryListMutation } =
    useUpdateStorageInventoryListMutation();

  const greetingName = useMemo(
    () => employeeState?.personName?.firstName,
    [employeeState?.personName?.firstName],
  );

  const hasForeman = useMemo(
    () =>
      event.employees.filter(
        ({ projectRole }) => projectRole === EmployeeProjectRole.FOREMAN,
      ).length > 0,
    [event.employees],
  );

  const isForeman = useMemo(
    () =>
      !!event.employees.find(
        ({ projectRole, employee: { employeeId } }) =>
          projectRole === EmployeeProjectRole.FOREMAN &&
          employeeId === employeeState?.employeeId,
      ),
    [event.employees, employeeState?.employeeId],
  );

  const handleOpenList = useCallback((newValue: OpenListType | undefined) => {
    switch (true) {
      case newValue === OpenListType.DELIVER_BOXES:
        return deliverBoxesRef.current?.present();
      case newValue === OpenListType.TAKE_MATERIAL:
        return takeMaterialsRef.current?.present();
      case newValue === OpenListType.PICKUP_BOXES:
        return pickupBoxesRef.current?.present();
      case newValue === OpenListType.RETRIEVE_MATERIAL:
        return retrieveMaterialsRef.current?.present();
      case newValue === OpenListType.FURNITURE_LIST:
        return furnitureListRef.current?.present();
      case newValue === OpenListType.DISASSEMBLY:
        setServiceType(InternalServiceType.DISASSEMBLE);
        return checkFurnitureListRef.current?.present();
      case newValue === OpenListType.ASSEMBLY:
        setServiceType(InternalServiceType.ASSEMBLE);
        return checkFurnitureListRef.current?.present();
      case newValue === OpenListType.LOAD_STORAGE:
        setLoadOrUnloadStorage('load');
        return loadOrUnloadFurnitureListRef.current?.present();
      case newValue === OpenListType.UNLOAD_STORAGE:
        setLoadOrUnloadStorage('unload');
        return loadOrUnloadFurnitureListRef.current?.present();
      case newValue === OpenListType.PICKUP_STORAGE:
        return pickUpStorageFormBottomSheetRef.current?.present();
      default:
        return undefined;
    }
  }, []);

  const handleStartTaxation = useCallback(() => {
    router.push(
      `/intake/${project.projectId}/moving-job?eventId=${event.eventId}`,
    );
  }, [event.eventId, project.projectId]);

  const handleTakeMaterials = useHandleTakeMaterials({
    project,
    eventId: event.eventId,
  });

  const handleRetrieveMaterials = useHandleRetrieveMaterials({
    project,
    eventId: event.eventId,
  });

  const handleCheckFurnitureService = useCallback(
    async (
      { furnitureId, services }: MovingJobFurniture,
      internalServiceType: InternalServiceType,
      finished: boolean,
    ) => {
      const serviceToCheck = services.find(
        ({ internalType }) => internalType === internalServiceType,
      );
      if (serviceToCheck && project) {
        await finishOrResetFurnitureService({
          projectId: project.projectId,
          furnitureId,
          serviceTypeId: serviceToCheck.serviceTypeId,
          finished,
        });
      }
    },
    [finishOrResetFurnitureService, project],
  );

  const numberOfStorages = useMemo(
    () => storagesData?.data?.totalItems || 0,
    [storagesData?.data],
  );

  const storages = useMemo(
    () => storagesData?.data?.member || [],
    [storagesData?.data],
  );

  const usesStorage =
    !!project.services.find(
      (s) => s.internalType === InternalServiceType.STORAGE,
    ) || numberOfStorages > 0;
  const queryClient = useQueryClient();

  const {
    data: storedItemsData,
    refetch: refetchStoredItems,
    isFetching: isLoadingStoredItems,
  } = useStoredItemsRequest(
    {
      storage: storages.map(({ storageId }) => storageId),
    },
    {
      enabled: storages.length > 0,
    },
  );

  const storedItems = useMemo(
    () => storedItemsData?.data?.member || [],
    [storedItemsData?.data],
  );

  const allFurniture = useMemo(
    () => project.rooms.map((room) => room.furniture).flat(),
    [project.rooms],
  );

  const movingBoxes = useMemo(
    () => boxesData?.data?.member || [],
    [boxesData?.data],
  );

  const { furnitureInStorage, storedItemsNotMappedToFurniture } = useMemo(
    () =>
      storedItems.reduce(
        (
          result: {
            furnitureInStorage: { [key: string]: Uuid[] };
            storedItemsNotMappedToFurniture: {
              [key: string]: StoredItem[];
            };
          },
          item,
        ) => {
          if (
            typeof result.furnitureInStorage[item.storageId] === 'undefined'
          ) {
            // eslint-disable-next-line no-param-reassign
            result.furnitureInStorage[item.storageId] = [];
          }

          if (
            typeof result.storedItemsNotMappedToFurniture[item.storageId] ===
            'undefined'
          ) {
            // eslint-disable-next-line no-param-reassign
            result.storedItemsNotMappedToFurniture[item.storageId] = [];
          }

          if (item.itemCode) {
            const foundFurniture = allFurniture.find(
              (furniture) => furniture.furnitureId === item.itemCode,
            );

            if (foundFurniture) {
              if (!item.unloadedOn) {
                result.furnitureInStorage[item.storageId].push(item.itemCode);
              }

              return result;
            }

            if (
              movingBoxes.find((box) => box.inventoryItemId === item.itemCode)
            ) {
              return result;
            }
          }

          result.storedItemsNotMappedToFurniture[item.storageId].push(item);

          return result;
        },
        {
          furnitureInStorage: {},
          storedItemsNotMappedToFurniture: {},
        },
      ),
    [allFurniture, movingBoxes, storedItems],
  );

  const getTranslatedName = useGetTranslatedName();
  const handleUnloadOrLoad = useCallback(
    async (
      values: LoadOrUnloadStorageInputType & {
        storedItemsNotMappedToFurniture: {
          [key: string]: StoredItemInputType[];
        };
      },
    ) => {
      const employeeId = employeeState?.employeeId;
      if (employeeId) {
        const storedItemsPerStorage: {
          [key: string]: StoredItemInputType[];
        } = values.storedItemsNotMappedToFurniture || {};

        const unloadRequests: UnloadInventoryFromStorageMutationProps[] = [];
        const loadRequests: LoadInventoryInStorageMutationProps[] = [];

        Object.values(values.furniture).forEach(
          ({ storageId, furnitureId }) => {
            const existingStoredItems = storedItems.filter(
              (item) => item.itemCode === furnitureId,
            );

            if (existingStoredItems.length > 0) {
              existingStoredItems.forEach((existingStoredItem) => {
                if (!storageId || existingStoredItem.storageId !== storageId) {
                  if (
                    existingStoredItem.unloadedOn ||
                    !existingStoredItem.loadedOn
                  ) {
                    return;
                  }

                  if (!storedItemsPerStorage[existingStoredItem.storageId]) {
                    storedItemsPerStorage[existingStoredItem.storageId] = [];
                  }

                  storedItemsPerStorage[existingStoredItem.storageId].push({
                    ...storedItemInputTypeDefaultValues(),
                    ...existingStoredItem,
                    unloadedOn: new Date(),
                    unloadedByEmployeeId: employeeId,
                  });
                } else {
                  if (!storedItemsPerStorage[storageId]) {
                    storedItemsPerStorage[storageId] = [];
                  }

                  if (
                    existingStoredItem.loadedOn &&
                    !existingStoredItem.unloadedOn
                  ) {
                    return;
                  }

                  storedItemsPerStorage[storageId].push({
                    ...storedItemInputTypeDefaultValues(),
                    ...existingStoredItem,
                    loadedOn: new Date(),
                    loadedByEmployeeId: employeeId,
                    unloadedOn: null,
                    unloadedByEmployeeId: null,
                  });
                }
              });
            }

            if (!storageId) {
              return;
            }

            if (
              existingStoredItems.some((item) => item.storageId === storageId)
            ) {
              return;
            }

            const foundFurniture = allFurniture.find(
              (furniture) => furniture.furnitureId === furnitureId,
            );

            if (!foundFurniture) {
              return;
            }

            let furnitureInformation: Partial<StoredItemInputType> = {};
            if (isMovingJobCustomFurniture(foundFurniture)) {
              furnitureInformation = {
                description: foundFurniture.label,
                volume: foundFurniture.cubicMeter,
                itemCode: furnitureId,
              };
            } else if (isMovingJobStandardFurniture(foundFurniture)) {
              const furnitureLabel = getTranslatedName(
                foundFurniture.furnitureType.translatedNames,
                foundFurniture.furnitureType.furnitureTypeName,
              );
              if (foundFurniture.furnitureVariant) {
                const variantLabel = getTranslatedName(
                  foundFurniture.furnitureVariant.translatedNames,
                  foundFurniture.furnitureVariant.variantTypeName,
                );
                furnitureInformation = {
                  description: `${furnitureLabel} ${variantLabel.toLocaleLowerCase()}`,
                  volume: foundFurniture.furnitureVariant.cubicMeter,
                  itemCode: furnitureId,
                };
              } else {
                furnitureInformation = {
                  description: furnitureLabel,
                  volume: foundFurniture.furnitureType.cubicMeter,
                  itemCode: furnitureId,
                };
              }
            }

            if (!storedItemsPerStorage[storageId]) {
              storedItemsPerStorage[storageId] = [];
            }

            storedItemsPerStorage[storageId].push({
              ...storedItemInputTypeDefaultValues(),
              itemId: v7(),
              loadedOn: new Date(),
              loadedByEmployeeId: employeeId,
              storageId,
              ...furnitureInformation,
            });
          },
        );
        values.storages.forEach(({ storageId, boxes }) => {
          const storedItemsInStorage = storedItems.filter(
            (item) => item.storageId === storageId && item.itemCode,
          );

          boxes.forEach(({ inventoryItemId, quantity, originalQuantity }) => {
            const valueQuantity = (quantity || 0) - (originalQuantity || 0);
            if (valueQuantity === 0) {
              return;
            }

            const foundStoredItem = storedItemsInStorage.find(
              (item) => item.itemCode === inventoryItemId,
            );

            const movingBox = movingBoxes.find(
              (box) => box.inventoryItemId === inventoryItemId,
            );

            if (!movingBox) {
              return;
            }

            if (foundStoredItem) {
              storedItemsPerStorage[storageId].push({
                ...storedItemInputTypeDefaultValues(),
                ...foundStoredItem,
                unloadedOn: quantity === 0 ? new Date() : null,
                unloadedByEmployeeId: quantity === 0 ? employeeId : null,
                volume: quantity
                  ? movingBox.cubicMeter * (quantity || 0)
                  : foundStoredItem.volume,
                description: quantity
                  ? `${quantity}x ${movingBox.name}`
                  : foundStoredItem.description,
              });
            } else {
              if (!storedItemsPerStorage[storageId]) {
                storedItemsPerStorage[storageId] = [];
              }

              storedItemsPerStorage[storageId].push({
                ...storedItemInputTypeDefaultValues(),
                itemId: v7(),
                loadedOn: new Date(),
                loadedByEmployeeId: employeeId,
                storageId,
                itemCode: inventoryItemId,
                volume: movingBox.cubicMeter * (quantity || 0),
                description: `${quantity}x ${movingBox.name}`,
              });
            }

            const request = {
              inventoryItemId,
              eventDate: new Date(),
              quantity: valueQuantity,
              storageId,
              customerId: project.customer.relationId,
              projectId: project.projectId,
              planningEventId: event.eventId,
            };

            if (valueQuantity < 0) {
              unloadRequests.push({
                ...request,
                quantity: Math.abs(valueQuantity),
              });
            } else {
              loadRequests.push({
                ...request,
                quantity: Math.abs(valueQuantity),
              });
            }
          });
        });

        await Promise.all([
          ...Object.values(storedItemsPerStorage)
            .filter((items) => items.length > 0)
            .map(async (items) =>
              updateInventoryListMutation({
                storageId: items[0].storageId as Uuid,
                storedItems: items,
                isUpdateOnly: true,
              }),
            ),
          ...unloadRequests.map(async (request) =>
            unloadInventoryFromStorageMutation(request),
          ),
          ...loadRequests.map(async (request) =>
            loadInventoryInStorageMutation(request),
          ),
        ]);
      }

      refetchStoredItems();
      queryClient.invalidateQueries({ queryKey: ['stored-items'] });
    },
    [
      employeeState?.employeeId,
      refetchStoredItems,
      queryClient,
      storedItems,
      allFurniture,
      getTranslatedName,
      movingBoxes,
      project.customer.relationId,
      project.projectId,
      event.eventId,
      updateInventoryListMutation,
      unloadInventoryFromStorageMutation,
      loadInventoryInStorageMutation,
    ],
  );

  const onStartNavigation = useCallback(async () => {
    // let latitude = event.location?.latitude;
    // let longitude = event.location?.longitude;
    // if (!showEventLocation(event.eventType)) {
    //   const fromAddress = project.addresses.find(
    //     ({ primary, addressType }) =>
    //       primary && addressType === MovingJobAddressType.FROM
    //   );
    //   if (fromAddress) {
    //     latitude = fromAddress.latitude;
    //     longitude = fromAddress.longitude;
    //   } else {
    //     const toAddress = project.addresses.find(
    //       ({ primary, addressType }) =>
    //         primary && addressType === MovingJobAddressType.TO
    //     );
    //     if (toAddress) {
    //       latitude = toAddress.latitude;
    //       longitude = toAddress.longitude;
    //     }
    //   }
    // }
    //
    // console.log(
    //   latitude,
    //   longitude,
    //   location?.coords.latitude || 52.161072,
    //   location?.coords.longitude || 5.37901
    // );
    // hello();
    // NativeModules.RNMapboxNavigation.takeMeToDestination(
    //   latitude,
    //   longitude,
    //   52.161072,
    //   5.37901
    // );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    event.eventType,
    event.location?.latitude,
    event.location?.longitude,
    location,
    project.addresses,
  ]);

  const {
    data: projectEstimatesData,
    isFetching: isFetchingEstimates,
    refetch: refetchEstimates,
  } = useProjectEstimates({
    projectId: project.projectId,
    perPage: 1,
  });

  const isFetching = useMemo(
    () =>
      isFetchingParent ||
      isFetchingEvents ||
      isFetchingCanPayAtCustomer ||
      isFetchingStorage ||
      isFetchingStorageOutstanding ||
      isFetchingPublicMovingBoxes ||
      isFetchingReusableMaterials ||
      isFetchingEstimates ||
      isLoadingStoredItems,
    [
      isFetchingParent,
      isFetchingEvents,
      isFetchingCanPayAtCustomer,
      isFetchingStorage,
      isFetchingStorageOutstanding,
      isFetchingPublicMovingBoxes,
      isFetchingReusableMaterials,
      isFetchingEstimates,
      isLoadingStoredItems,
    ],
  );

  const handleRefresh = useCallback(() => {
    onRefreshParent();
    refetchEvents();
    refetchCanPayAtCustomer();
    refetchStorage();
    refetchStorageOutstanding();
    refetchPublicMovingBoxes();
    refetchReusableMaterials();
    refetchEstimates();
    refetchStoredItems();
  }, [
    onRefreshParent,
    refetchStoredItems,
    refetchEvents,
    refetchCanPayAtCustomer,
    refetchStorage,
    refetchStorageOutstanding,
    refetchPublicMovingBoxes,
    refetchReusableMaterials,
    refetchEstimates,
  ]);

  const roomsCubicMeter = useMemo(() => {
    let resultingCubicMeter = 0;
    project.rooms.forEach((r) => {
      resultingCubicMeter += r.cubicMeter;
    });

    return resultingCubicMeter;
  }, [project.rooms]);

  const cubicMeterItem: ProjectEstimateItem | undefined = useMemo(() => {
    const projectEstimates = projectEstimatesData?.data.member || [];
    const projectEstimate = projectEstimates[0];
    if (!projectEstimate) {
      return undefined;
    }

    return projectEstimate.items.find(
      ({ itemType, groupType }) =>
        itemType === 'cubic-meter' && groupType === 'moving-job',
    );
  }, [projectEstimatesData?.data]);

  const eventActions = useMovingJobEventPageActions({
    eventId: event.eventId,
    eventType: event.eventType,
    finished: event.finished,
    finishedAtCustomer: event.finishedAtCustomer,
    startedWorking: event.startedWorking,
    startedDriving: event.startedDriving,
    projectId: event.projectId,
    services: project.services,
    numberOfStorages,
    canPayAtCustomer: canPayAtCustomerData?.data,
    onOpenList: handleOpenList,
    onStartRegisterMileage,
    onStartFinalizingEventAtCustomer,
    onStartFinalizingEvent,
    onStartPayingAtCustomer,
    onOpenWorkingOnEventForm,
    onOpenDrivingToEventForm,
    onStartNavigation,
    onStartReportingDamage,
    onStartUploadingDocument,
    hasForeman,
    isForeman,
    onStartTaxation: handleStartTaxation,
    loadingInvoice,
    hasVehicles: event.materialIds.length > 0,
    isLoadingStorage: isLoadingStoredItems || isFetchingStorage,
  });

  const allPhotos = useMemo(
    () =>
      project.rooms.reduce((acc, room) => {
        if (room.photos.length > 0) {
          acc.push(...room.photos);
        }
        return acc;
      }, [] as MediaObject[]),
    [project.rooms],
  );

  // only return max 12 photos
  const photos = useMemo(() => allPhotos.slice(0, 12), [allPhotos]);

  const handleOpenPhotoBottomSheet = useCallback(
    (photo: MediaObject) => {
      const room = project.rooms.find((room) => room.photos.includes(photo));
      if (!room) {
        return;
      }

      console.log(photosBottomSheetRef);
      photosBottomSheetRef.current?.present();
      console.log(room);
    },
    [project.rooms],
  );

  const { width: screenWidth } = useWindowDimensions();

  const imageSize = useMemo(() => {
    const maxSize = 225; // Maximum size
    const gap = 16; // Gap between items
    const screenPadding = 40; // Total padding (20px on each side)
    const minItemsPerRow = 3; // Minimum items per row

    // Calculate actual available width
    const availableWidth = screenWidth - screenPadding;

    // Calculate how many items will fit at max size (including gaps)
    const maxItemsAtMaxSize =
      Math.floor((availableWidth + gap) / (maxSize + gap)) + 1;

    // Make sure we have at least minItemsPerRow
    const itemsPerRow = Math.max(maxItemsAtMaxSize, minItemsPerRow);

    // Calculate the exact size needed to fill the width with itemsPerRow items
    const sizeToFillWidth =
      (availableWidth - (itemsPerRow - 1) * gap) / itemsPerRow;

    // The key change: always use sizeToFillWidth to ensure we fill the available space
    return Math.floor(Math.min(sizeToFillWidth, maxSize));
  }, [screenWidth]);

  return (
    <EventScreenTemplate
      eventId={event.eventId}
      allowEmployeeToSeeColleagueDetails={showEmployeeDetails}
      finished={event.finished}
      headerLabel={
        showEventName(event.eventType) ? (
          event.name.trim()
        ) : (
          <FormattedMessage
            id={`mobileApp.event.eventType.${event.eventType}`}
          />
        )
      }
      isFetching={isFetching}
      onRefresh={handleRefresh}
      actions={eventActions}
      subHeaderLabel={
        showEventLocation(event.eventType) ? (
          <AddressBlock
            variant="single_line"
            address={event.location}
            color={colors.lila[600]}
          />
        ) : (
          project &&
          isMovingJob(project) && (
            <MovingJobAddressBlock color={colors.lila[600]} project={project} />
          )
        )
      }
      plannedMaterials={materials}
      team={event.employees}
      crews={event.crews}
      addresses={
        (project &&
          isMovingJob(project) &&
          project.addresses.filter((address) =>
            isEventWithoutShowingAddresses(event.eventType)
              ? address.zipCode === event.location.zipCode &&
                address.houseNumber === event.location.houseNumber
              : true,
          )) ||
        []
      }
      alert={
        event.notes
          ? {
              severity: 'warning',
              children: event.notes,
            }
          : undefined
      }
      notes={project.notesForEmployees}
      eventType={event.eventType}
      onBack={onBack}
      country={tenant.address.country}
      identityId={project?.identityId}
      projectType={project?.projectType}
    >
      {usesStorage && (
        <>
          <View style={styles.row}>
            <Typography variant="subtitle1">
              <FormattedMessage id="label.storage" />
            </Typography>
          </View>
          <View style={[styles.row, styles.marginBetweenContent]}>
            {storages.length === 0 && (
              <View style={[styles.fullWidth]}>
                <Typography>
                  <FormattedMessage id="label.noStoragesReservedForThisProject" />
                </Typography>
              </View>
            )}
            {storages.map((storage) => (
              <Box
                key={storage.storageId}
                width="100%"
                flexDirection="row"
                flexWrap="wrap"
                paddingBottom={2}
              >
                <Box
                  width={{
                    phone: '100%',
                    tablet: '50%',
                  }}
                >
                  <Typography color="lila.600">
                    <FormattedMessage id="label.storage" />{' '}
                    {storage.storageCode}
                  </Typography>
                  <Typography>
                    <FormattedMessage
                      id={`storageTypes.${storage.storageType}`}
                    />
                  </Typography>
                  <Typography>{storage?.cubicMeter}&#13221;</Typography>
                </Box>
                <Box
                  width={{
                    phone: '100%',
                    tablet: '50%',
                  }}
                >
                  <Typography color="lila.600">
                    <FormattedMessage id="label.location" />
                  </Typography>
                  <Typography>{storage.warehouseName}</Typography>

                  {storage.storageLocationId ? (
                    <Typography>
                      {storage.storageLocationName} (
                      {storage.storageLocationCode})
                    </Typography>
                  ) : (
                    <Typography>
                      {storage.warehouseId ? (
                        <FormattedMessage id="label.storageIsReadyForPickup" />
                      ) : (
                        <FormattedMessage id="label.storageIsPickedUp" />
                      )}
                    </Typography>
                  )}
                </Box>
              </Box>
            ))}
          </View>
        </>
      )}
      <View style={styles.row}>
        <Typography variant="subtitle1">
          <FormattedMessage id="label.project" />
        </Typography>
      </View>
      <View style={[styles.row, styles.marginBetweenContent]}>
        <Typography>{project.projectCode}</Typography>
        {project.reference && <Typography>{project.reference}</Typography>}
        {project.identityId && (
          <IdentityIndicator identityId={project.identityId} full />
        )}
      </View>
      <View style={styles.row}>
        <Typography variant="subtitle1">
          <FormattedMessage id="label.customer" />
        </Typography>
      </View>
      <View style={[styles.row, styles.marginBetweenContent]}>
        <View style={[styles.halfWidth, smallScreen && styles.fullWidth]}>
          <Typography>{project.customer.name.trim()}</Typography>
          {isPrivateRelation(project.customer) &&
            project.customer.primaryContactInformation && (
              <RenderContactInformation
                contactInformation={project.customer.primaryContactInformation}
              />
            )}
        </View>
        <View
          style={[
            styles.halfWidth,
            smallScreen && styles.fullWidth,
            smallScreen && styles.smallPaddingContent,
          ]}
        >
          {project.projectContactPerson && (
            <ContactPersonBlock
              contactPersonLabel={
                <FormattedMessage id="label.projectContactPerson" />
              }
              contactPerson={project.projectContactPerson}
            />
          )}
        </View>
      </View>
      {documents.length > 0 && (
        <View style={[styles.row, styles.marginBetweenContent]}>
          <View style={[styles.fullWidth]}>
            <Typography variant="subtitle1">
              <FormattedMessage id="label.documents" />
            </Typography>
          </View>
          <Box flexDirection="column">
            {documents.map((document) => (
              <Box key={document.documentId}>
                <TouchableOpacity
                  style={styles.row}
                  onPress={() =>
                    WebBrowser.openBrowserAsync(
                      document.file.contentUrl || document.file.url,
                    )
                  }
                >
                  <Typography>{document.fileName}&nbsp;</Typography>
                  <FontAwesomeIcon size={20} icon={faDownload} />
                </TouchableOpacity>
              </Box>
            ))}
          </Box>
        </View>
      )}
      <View style={[styles.marginBetweenContent]}>
        <ProjectOutstandingInventoryItems
          projectId={project.projectId}
          rooms={project.rooms}
          neededInventoryItems={
            event.neededInventoryItems || project.neededInventoryItems
          }
          hideNonReusable
        />
      </View>
      {showMovingBoxesEventTypes.includes(event.eventType) && (
        <View style={[styles.marginBetweenContent]}>
          <ProjectOutstandingInventoryItems
            projectId={project.projectId}
            rooms={project.rooms}
            neededInventoryItems={event.neededInventoryItems}
            hideReusable
            title={<FormattedMessage id="label.movingBoxes" />}
          />
        </View>
      )}
      {damages.length > 0 && (
        <View style={[styles.row, styles.marginBetweenContent]}>
          <View style={[styles.fullWidth]}>
            <Typography variant="subtitle1">
              <FormattedMessage id="label.damages" />
            </Typography>
          </View>
          <Box flexDirection="column">
            {damages.map((damage, index) => (
              <Box key={damage.damageId} mt={index > 0 ? 1 : 0}>
                <DamageSummaryItem
                  damage={damage}
                  employees={event.employees.map(({ employee }) => employee)}
                  lessImportant
                />
              </Box>
            ))}
          </Box>
        </View>
      )}
      {projectEvents.length > 0 && intakeEvent?.eventId !== event.eventId && (
        <View style={[styles.row, styles.marginBetweenContent]}>
          <View style={[styles.fullWidth]}>
            <Typography variant="subtitle1">
              <FormattedMessage id="mobileApp.event.eventType.project-intake" />
            </Typography>
            {intakeEvent && (
              <View>
                <View style={[styles.row]}>
                  <Typography style={styles.halfWidth}>
                    {!!intakeEvent.employees[0] &&
                      intakeEvent.employees[0].employee.personName.fullName}
                    {!intakeEvent.employees[0] && (
                      <FormattedMessage id="mobileApp.event.unknownAppraiser" />
                    )}
                  </Typography>
                  <Typography style={styles.halfWidth}>
                    <FormattedDate
                      value={intakeEvent.start}
                      weekday="long"
                      day="numeric"
                      month="long"
                      year={
                        dayjs(intakeEvent.start).isSame(new Date(), 'year')
                          ? undefined
                          : 'numeric'
                      }
                    />
                  </Typography>
                </View>
                {!cubicMeterItem && (
                  <Typography>
                    {roomsCubicMeter.toFixed(2)}
                    &#13221;
                  </Typography>
                )}
                {cubicMeterItem && (
                  <Typography>
                    {(
                      cubicMeterItem.overwritten || cubicMeterItem.calculated
                    ).quantity.toFixed(2)}
                    &#13221;
                  </Typography>
                )}
              </View>
            )}
            {!intakeEvent && (
              <View>
                <Typography>
                  <FormattedMessage id="mobileApp.event.noIntakeDone" />
                </Typography>
                {!cubicMeterItem && (
                  <Typography>
                    {roomsCubicMeter.toFixed(2)}
                    &#13221;
                  </Typography>
                )}
                {cubicMeterItem && (
                  <Typography>
                    {(
                      cubicMeterItem.overwritten || cubicMeterItem.calculated
                    ).quantity.toFixed(2)}
                    &#13221;
                  </Typography>
                )}
              </View>
            )}
          </View>
        </View>
      )}
      {photos.length > 0 && (
        <View style={[styles.row, styles.marginBetweenContent]}>
          <View style={[styles.fullWidth]}>
            <Typography variant="subtitle1">
              <FormattedMessage id="label.photos" />
            </Typography>
          </View>
          <Box flexDirection="row" flexWrap="wrap" gap={2} width="100%">
            {photos.map((photo, index) => (
              <Box
                key={photo.mediaObjectId}
                width={imageSize}
                height={imageSize}
              >
                <TouchableOpacity
                  style={{
                    height: '100%',
                    width: '100%',
                  }}
                  onPress={() => handleOpenPhotoBottomSheet(photo)}
                >
                  <Image
                    source={{ uri: photo.contentUrl }}
                    style={styles.image}
                    resizeMode="cover"
                  />
                </TouchableOpacity>
              </Box>
            ))}
          </Box>
        </View>
      )}
      {projectEvents.length > 0 && (
        <View style={[styles.row, styles.marginBetweenContent]}>
          <View style={[styles.fullWidth]}>
            <Typography variant="subtitle1">
              <FormattedMessage id="label.data" />
            </Typography>
          </View>
          {projectEvents.map((projectEvent) => (
            <View
              key={projectEvent.eventId}
              style={[styles.row, styles.fullWidth]}
            >
              <View style={styles.halfWidth}>
                <Typography>
                  <FormattedMessage
                    id={`mobileApp.event.eventType.${projectEvent.eventType}`}
                  />
                </Typography>
              </View>
              <View style={styles.halfWidth}>
                <Typography>
                  <FormattedDate
                    value={projectEvent.start}
                    weekday="long"
                    day="numeric"
                    month="long"
                    year={
                      dayjs(projectEvent.start).isSame(new Date(), 'year')
                        ? undefined
                        : 'numeric'
                    }
                  />
                </Typography>
              </View>
            </View>
          ))}
        </View>
      )}
      <View>
        <TakeMaterialsBottomSheet
          title={
            <FormattedMessage id="mobileApp.event.deliverMovingBoxes.title" />
          }
          description={
            <FormattedMessage id="mobileApp.event.deliverMovingBoxes.description" />
          }
          buttonText={
            <FormattedMessage id="mobileApp.event.deliverMovingBoxes.confirm" />
          }
          hideReusable
          materials={boxesData?.data?.member || []}
          onSubmit={handleTakeMaterials}
          projectId={project.projectId}
          greetingName={greetingName}
          ref={deliverBoxesRef}
          rooms={project.rooms}
          neededInventoryItems={
            event.neededInventoryItems || project.neededInventoryItems
          }
        />
        <RetrieveMaterialsBottomSheet
          title={
            <FormattedMessage id="mobileApp.event.pickupMovingBoxes.title" />
          }
          description={
            <FormattedMessage id="mobileApp.event.pickupMovingBoxes.description" />
          }
          buttonText={
            <FormattedMessage id="mobileApp.event.pickupMovingBoxes.confirm" />
          }
          wornLabel={
            <FormattedMessage id="mobileApp.event.pickupMovingBoxes.areThereBoxesWornOut" />
          }
          wornDescription={
            <FormattedMessage id="mobileApp.event.pickupMovingBoxes.whatIsWorn" />
          }
          hideReusable
          materials={boxesData?.data?.member || []}
          onSubmit={handleRetrieveMaterials}
          projectId={project.projectId}
          greetingName={greetingName}
          ref={pickupBoxesRef}
          rooms={project.rooms}
          neededInventoryItems={
            event.neededInventoryItems || project.neededInventoryItems
          }
        />
        <TakeMaterialsBottomSheet
          materials={reusableMaterialsData?.data?.member || []}
          onSubmit={handleTakeMaterials}
          projectId={project.projectId}
          rooms={project.rooms}
          neededInventoryItems={
            event.neededInventoryItems || project.neededInventoryItems
          }
          greetingName={greetingName}
          ref={takeMaterialsRef}
          hideNonReusable
        />
        <RetrieveMaterialsBottomSheet
          materials={reusableMaterialsData?.data?.member || []}
          onSubmit={handleRetrieveMaterials}
          projectId={project.projectId}
          rooms={project.rooms}
          neededInventoryItems={
            event.neededInventoryItems || project.neededInventoryItems
          }
          greetingName={greetingName}
          ref={retrieveMaterialsRef}
          hideNonReusable
        />
        <FurnitureListBottomSheet
          rooms={project.rooms}
          ref={furnitureListRef}
        />
        <CheckServiceOfFurnitureListBottomSheet
          rooms={project.rooms}
          serviceType={serviceType}
          onCheckFurniture={handleCheckFurnitureService}
          ref={checkFurnitureListRef}
        />
        <LoadOrUnloadStorageBottomSheet
          rooms={project.rooms}
          onSubmit={handleUnloadOrLoad}
          isUnloading={loadOrUnloadStorage === 'unload'}
          boxes={boxesData?.data?.member || []}
          storages={storagesData?.data?.member || []}
          furnitureInStorage={furnitureInStorage}
          storedItemsNotMappedToFurniture={storedItemsNotMappedToFurniture}
          outstanding={storageOutstandingData?.data?.member || []}
          ref={loadOrUnloadFurnitureListRef}
        />
        <PickUpStorageFormWizardBottomSheet
          onSubmit={handleSubmitPickUpStorage}
          bottomSheetRef={pickUpStorageFormBottomSheetRef}
          disableHandlingCosts
        />
        <MovingJobPhotosBottomSheet
          project={project}
          onClose={() => photosBottomSheetRef.current?.close()}
          bottomSheetRef={photosBottomSheetRef}
        />
        {children}
      </View>
    </EventScreenTemplate>
  );
};

export default MovingJobEventScreenTemplate;
