import { ReactElement } from 'react';
import { useFormState } from 'react-hook-form';
import { Alert as AlertComponent } from '@bas/ui/native/atoms';
import { FormattedMessage } from 'react-intl';
import { Box, BoxProps } from '@bas/ui/native/base';

type BackendErrorsProps = BoxProps;

const BackendErrors = ({
  ...props
}: BackendErrorsProps): ReactElement | null => {
  const { errors, isValid } = useFormState();
  // eslint-disable-next-line no-console
  console.log(JSON.stringify({ errors, isValid, tett: 'test' }));
  return errors.backendErrors && errors.backendErrors.message ? (
    <Box {...props}>
      <AlertComponent severity="warning">
        <FormattedMessage id={errors.backendErrors.message.toString()} />
      </AlertComponent>
    </Box>
  ) : null;
};

export default BackendErrors;
