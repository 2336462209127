import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type CreateCreditInvoiceMutationProps = {
  invoiceId: Uuid;
  invoiceIdToCredit: Uuid;
  markAsPaid: boolean;
};

export const CreateCreditInvoiceMutation = async ({
  invoiceIdToCredit,
  ...values
}: CreateCreditInvoiceMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/{tenantId}/invoices/${invoiceIdToCredit}/credit`, {
    invoiceIdToCredit,
    ...values,
  });

export const useCreateCreditInvoiceMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    CreateCreditInvoiceMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  CreateCreditInvoiceMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    CreateCreditInvoiceMutationProps
  >({
    mutationFn: CreateCreditInvoiceMutation,
    throwOnError: false,
    ...options,
  });
