import { formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ApproveTimeEntryMutationProps = {
  timeEntryId: Uuid;
  approvedById: Uuid;
  approvedOn: Date;
  approvedStart: Date;
  approvedEnd: Date;
  approvalReaction: string;
};

export const ApproveTimeEntryMutation = async ({
  timeEntryId,
  approvedOn,
  approvedStart,
  approvedEnd,
  ...values
}: ApproveTimeEntryMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/hrm/time-entries/${timeEntryId}/approve`, {
    timeEntryId,
    approvedOn: formatDateTime(approvedOn),
    approvedStart: formatDateTime(approvedStart),
    approvedEnd: formatDateTime(approvedEnd),
    ...values,
  });

export const useApproveTimeEntryMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ApproveTimeEntryMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ApproveTimeEntryMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ApproveTimeEntryMutationProps
  >({
    mutationFn: ApproveTimeEntryMutation,
    throwOnError: false,
    ...options,
  });
