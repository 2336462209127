import { FuelCard, Maintenance, TowBar } from '@bas/planning-domain/models';
import { formatDate } from '@bas/shared/utils';
import {
  DriversLicenceCharacteristics,
  ErrorResponse,
  PropulsionType,
  TransmissionType,
  Uuid,
} from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type AddMovingTruckMutationProps = {
  materialId: Uuid;
  name: string;
  vinNumber?: string | null;
  licensePlate?: string | null;
  model?: string | null;
  propulsionType?: PropulsionType;
  transmissionType?: TransmissionType;
  registeredAt?: Date | null;
  purchasedAt?: Date | null;
  towBar?: TowBar | null;
  dealer?: string | null;
  leasingCompany?: string | null;
  maintenance: Maintenance;
  fuelCard?: FuelCard | null;
  driversLicenseNeeded: DriversLicenceCharacteristics;
  cubicMeter?: number | null;
  length?: number | null;
  height?: number | null;
  width?: number | null;
  tailGateLength?: number | null;
};

export const AddMovingTruckMutation = async ({
  materialId,
  registeredAt,
  purchasedAt,
  maintenance,
  ...values
}: AddMovingTruckMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/{tenantId}/moving-trucks`, {
    materialId,
    registeredAt: registeredAt ? formatDate(registeredAt) : null,
    purchasedAt: purchasedAt ? formatDate(purchasedAt) : null,
    maintenance: {
      motNeededAt: maintenance?.motNeededAt
        ? formatDate(maintenance?.motNeededAt)
        : null,
      maintenanceNeededAt: maintenance?.maintenanceNeededAt
        ? formatDate(maintenance?.maintenanceNeededAt)
        : null,
    },
    ...values,
  });

export const useAddMovingTruckMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddMovingTruckMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  AddMovingTruckMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddMovingTruckMutationProps
  >({
    mutationFn: AddMovingTruckMutation,
    throwOnError: false,
    ...options,
  });
