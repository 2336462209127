import { ReactElement, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Typography } from '@bas/ui/native/base';

export type ProjectNotesContentSectionProps = {
  title?: string | ReactNode;
  notes?: string;
  notesForEmployees?: string;
  customerNotes?: string;
};

const ProjectNotesContentSection = ({
  title,
  notes,
  notesForEmployees,
  customerNotes,
}: ProjectNotesContentSectionProps): ReactElement => (
  <Box>
    <Box>
      <Typography color="white" variant="subtitle1">
        {title || <FormattedMessage id="projects.projectDetails.notes" />}
      </Typography>
    </Box>
    <Box columnGap={3} rowGap={2}>
      <Box>
        <Typography color="secondaryTitle" variant="subtitle2">
          <FormattedMessage id="projects.projectDetails.generalNotes" />
        </Typography>
        <Typography color="white">
          {notes || <FormattedMessage id="label.noNotes" />}
        </Typography>
      </Box>
      <Box>
        <Typography color="secondaryTitle" variant="subtitle2">
          <FormattedMessage id="projects.projectDetails.notesForEmployees" />
        </Typography>
        <Typography color="white">
          {notesForEmployees || <FormattedMessage id="label.noNotes" />}
        </Typography>
      </Box>
      <Box>
        <Typography color="secondaryTitle" variant="subtitle2">
          <FormattedMessage id="projects.projectDetails.customerNotes" />
        </Typography>
        <Typography color="white">
          {customerNotes || <FormattedMessage id="label.noNotes" />}
        </Typography>
      </Box>
    </Box>
  </Box>
);

export default ProjectNotesContentSection;
