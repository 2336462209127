import { StyleSheet } from 'react-native';
import { colors } from '@bas/theme';

export default StyleSheet.create({
  accordion: {
    paddingHorizontal: 11,
    paddingVertical: 16,
    borderWidth: 1,
    borderColor: colors.lila[300],
    backgroundColor: colors.white,
    marginBottom: 6,
  },
});
