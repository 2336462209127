import * as Yup from 'yup';
import { Uuid } from '@bas/value-objects';

type BoxInput = {
  inventoryItemId: Uuid;
  quantity?: number | null;
  originalQuantity?: number | null;
};

export type FurnitureInput = {
  furnitureId: Uuid;
  storageId?: Uuid | null;
};

export interface LoadOrUnloadStorageInputType {
  activeStorageId?: Uuid | null;
  storages: {
    storageId: Uuid;
    boxes: BoxInput[];
  }[];
  furniture: {
    [key: string]: FurnitureInput;
  };
}

export const loadOrUnloadStorageInputTypeDefaultValues =
  (): LoadOrUnloadStorageInputType => ({
    activeStorageId: '',
    storages: [],
    furniture: {},
  });

export const loadOrUnloadStorageInputTypeValidatorBuilder =
  (): Yup.ObjectSchema<
    Omit<LoadOrUnloadStorageInputType, 'furniture'> & {
      furniture: FurnitureInput[];
    }
  > =>
    Yup.object({
      activeStorageId: Yup.string().nullable(),
      furniture: Yup.array()
        .of(
          Yup.object({
            furnitureId: Yup.string().required(),
            storageId: Yup.string(),
          }),
        )
        .required(),
      storages: Yup.array()
        .of(
          Yup.object({
            storageId: Yup.string().required(),
            boxes: Yup.array()
              .of(
                Yup.object({
                  inventoryItemId: Yup.string().required(),
                  quantity: Yup.number()
                    .transform((value, original) =>
                      original === '' || original === null ? null : value,
                    )
                    .nullable()
                    .min(0)
                    .integer()
                    .label('label.quantity'),
                  originalQuantity: Yup.number()
                    .transform((value, original) =>
                      original === '' || original === null ? null : value,
                    )
                    .nullable()
                    .min(0)
                    .integer()
                    .label('label.quantity'),
                }),
              )
              .required(),
          }),
        )
        .required(),
    });
