import {
  LoginInputType,
  LoginInputTypeDefaultValues,
  LoginInputTypeValidationBuilder,
} from '@bas/authentication-domain/input-types';
import {
  LoginMutationResponse,
  useLoginMutation,
  useLoginWithPassKeyMutation,
  useRegisterMobileDeviceMutation,
  useRetrievePassKeyOptions,
  useTwoFactorLoginMutation,
} from '@bas/authentication-domain/mutations';
import { LoginForm } from '@bas/authentication-domain/native/organisms';
import { UserByUserIdRequest } from '@bas/authentication-domain/requests';
import {
  useAppStore,
  useAuthStore,
  useTenantStore,
  useUserStore,
} from '@bas/shared/state';
import { TenantByHostnameRequest } from '@bas/tenant-domain/requests';
import { colors } from '@bas/theme';
import { ReactHookForm } from '@bas/ui/native/atoms';
import { Typography } from '@bas/ui/native/base';
import { useSetEnvironment } from '@bas/ui/native/hooks';
import { startAuthentication } from '@simplewebauthn/browser';
import { AuthenticationResponseJSON } from '@simplewebauthn/types';
import axios from 'axios';
import base64url from 'base64url';
import * as Device from 'expo-device';
import { router } from 'expo-router';
import { StatusBar } from 'expo-status-bar';
import { encode } from 'js-base64';
import * as React from 'react';
import { ReactElement, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { KeyboardAvoidingView, Platform, ScrollView, View } from 'react-native';
import { Passkey, PasskeyGetResult } from 'react-native-passkey';
import { SafeAreaView } from 'react-native-safe-area-context';
import { v7 } from 'uuid';
import styles from './styles';

const LoginScreen = (): ReactElement => {
  const [requireTwoFactorCode, setRequireTwoFactorCode] =
    useState<boolean>(false);
  const [error, setError] = useState<ReactElement>();
  const [isAuthenticatingWithPassKey, setIsAuthenticatingWithPassKey] =
    useState<boolean>(false);

  const setAuthState = useAuthStore((state) => state.setAuth);
  const setUserState = useUserStore((state) => state.setUser);
  const deviceId = useAppStore((state) => state.deviceId || v7());
  const appUrl = useAppStore((state) => state.appUrl);

  const tenantState = useTenantStore((state) => state.tenant);
  const setTenantState = useTenantStore((state) => state.setTenant);

  const { mutateAsync: registerDevice } = useRegisterMobileDeviceMutation();

  const handleAuthenticated = useCallback(
    async (data: LoginMutationResponse) => {
      const { data: user } = await UserByUserIdRequest({
        userId: data.userId,
        token: data.token,
      });

      if (
        !user.roles.includes('ROLE_SUPER_ADMIN') &&
        user.accessToTenants.filter(
          (access) =>
            access.tenantId === tenantState?.tenantId && access.enabled,
        ).length === 0
      ) {
        setError(<FormattedMessage id="label.accessDenied" />);

        return;
      }

      setUserState(user);
      setAuthState(data);
      console.log('login user', data);
      router.push('/');
    },
    [setAuthState, setUserState, tenantState?.tenantId],
  );

  const { mutateAsync: loginUser } = useLoginMutation({
    onSuccess: async ({ data }) => {
      if (data.twoFactorNeeded) {
        setRequireTwoFactorCode(true);

        return;
      }

      await handleAuthenticated(data);
    },
  });

  const { mutateAsync: handleTwoFactorCode } = useTwoFactorLoginMutation({
    onSuccess: async ({ data }) => {
      await handleAuthenticated(data);
    },
  });

  const setEnvironment = useSetEnvironment();

  const handleSetTenant = useCallback(
    (newUrl: string) => {
      let newAppUrl = '';
      if (Platform.OS === 'web') {
        newAppUrl = setEnvironment(
          window.location.hostname.replace('.bas.app', ''),
        );
      } else {
        newAppUrl = setEnvironment(newUrl);
      }

      return newAppUrl;
    },
    [setEnvironment],
  );

  const handleLogin = useCallback(
    async (values: LoginInputType) => {
      const newAppUrl = handleSetTenant(values.appUrl);
      if (!newAppUrl) {
        setError(<FormattedMessage id="label.invalidTenant" />);

        return;
      }

      const { data } = await TenantByHostnameRequest({
        hostname: newAppUrl,
      });

      if (data.tenantId) {
        setTenantState(data);
      }

      await registerDevice({
        deviceId,
        userId: null,
        deviceName: Device.deviceName || 'Unknown',
        deviceType: Platform.OS,
        userAgent: `${Device.osName} ${Device.osVersion} ${Device.modelName} ${Device.brand} ${Device.deviceYearClass}`,
      });

      const loginResponse = await loginUser({
        ...values,
        tenantId: data?.tenantId,
        deviceId,
      });

      if (values.twoFactorCode) {
        await handleTwoFactorCode({
          ...values,
          tenantId: data?.tenantId,
          token: loginResponse.data.token,
          code: values.twoFactorCode,
        });
      }
    },
    [
      deviceId,
      handleSetTenant,
      handleTwoFactorCode,
      loginUser,
      registerDevice,
      setTenantState,
    ],
  );

  const { mutateAsync: retrievePassKeyOptions } = useRetrievePassKeyOptions();
  const { mutateAsync: loginWithPassKey } = useLoginWithPassKeyMutation();

  const handleLoginWithPassKey = useCallback(
    async (domain: string) => {
      setIsAuthenticatingWithPassKey(true);
      const newAppUrl = handleSetTenant(domain);
      if (!newAppUrl) {
        setError(<FormattedMessage id="label.invalidTenant" />);
        setIsAuthenticatingWithPassKey(false);

        return;
      }

      const { data } = await TenantByHostnameRequest({
        hostname: newAppUrl,
      });

      if (data.tenantId) {
        setTenantState(data);
      }

      await registerDevice({
        deviceId,
        userId: null,
        deviceName: Device.deviceName || 'Unknown',
        deviceType: Platform.OS,
        userAgent: `${Device.osName} ${Device.osVersion} ${Device.modelName} ${Device.brand} ${Device.deviceYearClass}`,
      });

      const optionsResponse = await retrievePassKeyOptions({});
      let rpId;
      if (Object.prototype.hasOwnProperty.call(optionsResponse.data, 'rpId')) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        rpId = (optionsResponse.data as any).rpId;
      } else {
        rpId = optionsResponse.data.rp.id;
      }
      try {
        let result: PasskeyGetResult | AuthenticationResponseJSON;
        if (Platform.OS === 'web') {
          result = await startAuthentication({
            optionsJSON: optionsResponse.data,
          });
        } else {
          result = await Passkey.get({
            challenge: encode(optionsResponse.data.challenge),
            rpId,
          });
        }

        const loginResponse = await loginWithPassKey({
          deviceId,
          tenantId: data?.tenantId,
          id: base64url.fromBase64(result.id),
          response: {
            clientDataJSON: base64url.fromBase64(
              result.response.clientDataJSON,
            ),
            authenticatorData: result.response.authenticatorData,
            signature: result.response.signature,
            userHandle: result.response.userHandle,
          },
          type: (result.type || 'public-key') as 'public-key',
          rawId: result.rawId,
          authenticatorAttachment: 'platform',
          clientExtensionResults: {},
          device: Platform.OS !== 'web' ? 'native' : 'web',
        });
        await handleAuthenticated(loginResponse.data);
      } catch (e) {
        if (axios.isAxiosError(e)) {
          setError(e.response?.data?.message || e.message);
          setIsAuthenticatingWithPassKey(false);
          return;
        }

        setError(<FormattedMessage id="label.unknownError" />);
      }

      setIsAuthenticatingWithPassKey(false);
    },
    [
      handleSetTenant,
      registerDevice,
      deviceId,
      retrievePassKeyOptions,
      setTenantState,
      loginWithPassKey,
      handleAuthenticated,
    ],
  );

  const validationSchema = useCallback(
    () =>
      LoginInputTypeValidationBuilder({
        requireTwoFactorCode: false,
      }),
    [],
  );

  return (
    <ScrollView
      contentContainerStyle={{ flex: 1 }}
      style={{
        backgroundColor: colors.blue[900],
      }}
    >
      <KeyboardAvoidingView style={{ flex: 1 }} behavior="height">
        <SafeAreaView style={styles.container}>
          {/* eslint-disable-next-line react/style-prop-object */}
          <StatusBar style="light" />
          <View style={styles.keyboardContainer}>
            <View style={styles.formContainer}>
              <Typography style={styles.title} fontWeight={700} variant="h3">
                <FormattedMessage id="login.welcome" />
              </Typography>
              <ReactHookForm<LoginInputType & { backendErrors: unknown }>
                style={styles.form}
                onSubmit={handleLogin}
                useProvider
                defaultValues={{
                  ...LoginInputTypeDefaultValues(),
                  appUrl:
                    Platform.OS === 'web'
                      ? window.location.hostname.replace('.bas.app', '')
                      : appUrl.replace('.bas.app', ''),
                }}
                validationSchema={validationSchema}
              >
                <LoginForm
                  requireTwoFactorCode={requireTwoFactorCode}
                  error={error}
                  isAuthenticatingWithPassKey={isAuthenticatingWithPassKey}
                  onLoginWithPassKey={handleLoginWithPassKey}
                />
              </ReactHookForm>
            </View>
          </View>
        </SafeAreaView>
      </KeyboardAvoidingView>
    </ScrollView>
  );
};

export default LoginScreen;
