import * as Yup from 'yup';
import { Uuid } from '@bas/value-objects';

export interface StorageLocationInputType {
  name: string;
  code: string;
  description?: string | null;
  warehouseId: Uuid;
}

export const storageLocationInputTypeDefaultValues =
  (): StorageLocationInputType => ({
    name: '',
    code: '',
    description: '',
    warehouseId: '',
  });

export const storageLocationInputTypeValidationBuilder =
  (): Yup.ObjectSchema<StorageLocationInputType> =>
    Yup.object({
      name: Yup.string().label('label.name').required(),
      code: Yup.string().label('label.code').required(),
      description: Yup.string().label('label.description').nullable(),
      warehouseId: Yup.string().label('label.warehouse').required(),
    });
