import {
  AddressInputType,
  addressInputTypeDefaultValues,
  addressInputTypeValidationBuilder,
  RecurringInformationInputType,
  RecurringInformationInputTypeDefaultValues,
  RecurringInformationInputTypeValidationSchema,
} from '@bas/shared/input-types';
import { EmployeeProjectRole, EventType } from '@bas/value-objects';
import dayjs, { Dayjs } from 'dayjs';
import * as Yup from 'yup';
import { AnyObjectSchema } from 'yup';

export interface ProjectEventDetailsInputType {
  eventType: EventType;
  start?: Date | null;
  end?: Date | null;
  travelStart?: Date | null;
  travelEnd?: Date | null;
  notes?: string | null;
  neededRoles: {
    value?: number | null;
    role: EmployeeProjectRole;
  }[];
  departureLocation: AddressInputType;
  durationInSeconds?: number | null;
  distanceInMeters?: number | null;
  automaticTravelTime: boolean;
  isRecurring: boolean;
  recurringInformation?: RecurringInformationInputType;
}

export const ProjectEventDetailsInputTypeDefaultValues = (
  date?: Date | Dayjs,
): ProjectEventDetailsInputType => ({
  eventType: EventType.UNKNOWN,
  start: null,
  end: null,
  travelStart: null,
  travelEnd: null,
  durationInSeconds: null,
  distanceInMeters: null,
  neededRoles: [
    { value: 0, role: EmployeeProjectRole.PROJECT_LEADER },
    { value: 0, role: EmployeeProjectRole.FOREMAN },
    { value: 0, role: EmployeeProjectRole.APPRAISER },
    { value: 0, role: EmployeeProjectRole.HANDYMAN },
    { value: 0, role: EmployeeProjectRole.CO_DRIVER },
    { value: 0, role: EmployeeProjectRole.PACKER },
    { value: 0, role: EmployeeProjectRole.LIFT_TRUCK },
    { value: 0, role: EmployeeProjectRole.ATTENDEE },
    { value: 0, role: EmployeeProjectRole.INAPPLICABLE },
  ],
  departureLocation: {
    ...addressInputTypeDefaultValues(),
  },
  automaticTravelTime: true,
  isRecurring: false,
  recurringInformation: RecurringInformationInputTypeDefaultValues(date),
});

export function isProjectEventDetailsInputTypeWithLocation(
  object: unknown,
): object is ProjectEventDetailsInputType & { location: AddressInputType } {
  return !!object && Object.prototype.hasOwnProperty.call(object, 'location');
}

export const ProjectEventDetailsInputTypeValidationBuilder = (
  allowedEventTypes?: EventType[],
  existingRepeatFromDate?: Date,
): Yup.ObjectSchema<ProjectEventDetailsInputType> =>
  Yup.object({
    eventType: Yup.mixed<EventType>()
      .label('label.eventType')
      .oneOf(
        allowedEventTypes ||
          Object.values(EventType).filter((option) => !!option),
      )
      .required(),
    notes: Yup.string().nullable().label('label.notes'),
    start: Yup.date().nullable().required().label('label.start'),
    end: Yup.date()
      .nullable()
      .when(['start'], ([start], schema) => {
        if (!start || !dayjs(start).isValid()) {
          return schema;
        }

        return schema.min(start, 'label.endMustBeAfterStart');
      })
      .label('label.end'),

    travelStart: Yup.date()
      .nullable()
      .label('label.departureTime')
      .when(['start'], ([start], schema) => {
        if (!start || !dayjs(start).isValid()) {
          return schema;
        }

        return schema.max(start, 'Vertrektijd moet voor de starttijd liggen');
      }),
    travelEnd: Yup.date()
      .nullable()
      .label('label.arrivalTimeWarehouse')
      .when(['end'], ([end], schema) => {
        if (!end || !dayjs(end).isValid()) {
          return schema;
        }

        return schema.min(
          end,
          'Aankomsttijd bij de loods moet na de eindtijd liggen',
        );
      }),

    departureLocation: Yup.object({
      addressId: Yup.string().required().label('label.departureLocation'),
    })
      .concat(addressInputTypeValidationBuilder())
      .label('label.departureLocation'),
    neededRoles: Yup.array()
      .of(
        Yup.object({
          value: Yup.number()
            .transform((value, original) =>
              original === '' || original === null ? null : value,
            )
            .nullable()
            .label('label.quantity'),
          role: Yup.mixed<EmployeeProjectRole>()
            .label('label.role')
            .oneOf(
              Object.values(EmployeeProjectRole).filter((option) => !!option),
            )
            .required(),
        }),
      )
      .required(),
    durationInSeconds: Yup.number().nullable().label('label.durationInSeconds'),
    distanceInMeters: Yup.number().nullable().label('label.distanceInMeters'),
    automaticTravelTime: Yup.boolean()
      .required()
      .label('label.automaticTravelTime'),
    isRecurring: Yup.boolean().required().label('label.isRecurring'),
    recurringInformation: RecurringInformationInputTypeValidationSchema(
      existingRepeatFromDate,
    )
      .label('label.recurringInformation')
      .when(['isRecurring'], ([isRecurring], schema) => {
        if (!isRecurring) {
          return Yup.object() as AnyObjectSchema;
        }

        return schema.required();
      }),
  });
