import { MovingJobFurniture, MovingJobRoom } from '@bas/project-domain/models';
import { useEmployeeStore } from '@bas/shared/state';
import { BlueSelectButton } from '@bas/ui/native/atoms';
import { BottomSheetMethods, Typography } from '@bas/ui/native/base';
import { BlueSelectButtonsGroup } from '@bas/ui/native/molecules';
import { RoomsAndFurnitureList } from '@bas/ui/native/organisms';
import { Uuid } from '@bas/value-objects';
import {
  LoadOrUnloadStorageInputType,
  StoredItemInputType,
} from '@bas/wms-domain/input-types';
import { PublicMovingBox, Storage } from '@bas/wms-domain/models';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import * as React from 'react';
import { ReactElement, useCallback, useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { View } from 'react-native';
import { AddExtraStoredItemFormBottomSheet } from '../AddExtraStoredItemFormBottomSheet';
import styles from './styles';

export type LoadOrUnloadStorageFormProps = {
  storages: Storage[];
  rooms: MovingJobRoom[];
  boxes: PublicMovingBox[];
};

const LoadOrUnloadStorageForm = ({
  storages,
  rooms,
  boxes,
}: LoadOrUnloadStorageFormProps): ReactElement => {
  const addExtraStoredItemModalRef = useRef<
    BottomSheetModal & BottomSheetMethods
  >(null);
  const activeStorageIdRef = useRef<string | null>(null);
  const activeStorageId = useWatch<
    LoadOrUnloadStorageInputType,
    'activeStorageId'
  >({
    name: 'activeStorageId',
  });

  const storedItemsNotMappedToFurniture = useWatch<
    {
      storedItemsNotMappedToFurniture: {
        [key: string]: StoredItemInputType[];
      };
    },
    'storedItemsNotMappedToFurniture'
  >({
    name: 'storedItemsNotMappedToFurniture',
  });

  const { setValue } = useFormContext<
    LoadOrUnloadStorageInputType & {
      storedItemsNotMappedToFurniture: {
        [key: string]: StoredItemInputType[];
      };
    }
  >();

  const handleCheck = useCallback(
    (furnitureItem: MovingJobFurniture, checked: boolean) => {
      let value: Uuid | undefined | null;
      if (checked) {
        value = activeStorageIdRef.current;
      }

      setValue(`furniture.${furnitureItem.furnitureId}`, {
        furnitureId: furnitureItem.furnitureId,
        storageId: value,
      });
    },
    [setValue],
  );

  const handleAddExtraStoredItem = useCallback(
    (data: StoredItemInputType) => {
      const storageId = activeStorageIdRef.current;
      if (storageId) {
        setValue(
          `storedItemsNotMappedToFurniture.${storageId}`,
          [
            ...(storedItemsNotMappedToFurniture[storageId] ?? []),
            {
              ...data,
              storageId,
            },
          ],
          {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
          },
        );
        addExtraStoredItemModalRef.current?.close();
      }
    },
    [setValue, storedItemsNotMappedToFurniture],
  );

  const employeeId = useEmployeeStore((state) => state.employee?.employeeId);
  const handleCheckExtra = useCallback(
    (
      storedItem: StoredItemInputType,
      storedItemIndex: number,
      checked: boolean,
    ) => {
      if (checked) {
        if (storedItem.unloadedOn) {
          setValue(
            `storedItemsNotMappedToFurniture.${storedItem.storageId}.${storedItemIndex}.unloadedOn`,
            null,
          );
          setValue(
            `storedItemsNotMappedToFurniture.${storedItem.storageId}.${storedItemIndex}.unloadedByEmployeeId`,
            null,
          );
        } else {
          setValue(
            `storedItemsNotMappedToFurniture.${storedItem.storageId}.${storedItemIndex}.loadedOn`,
            new Date(),
          );
          setValue(
            `storedItemsNotMappedToFurniture.${storedItem.storageId}.${storedItemIndex}.loadedByEmployeeId`,
            employeeId as Uuid,
          );
        }
      } else {
        setValue(
          `storedItemsNotMappedToFurniture.${storedItem.storageId}.${storedItemIndex}.unloadedOn`,
          new Date(),
        );
        setValue(
          `storedItemsNotMappedToFurniture.${storedItem.storageId}.${storedItemIndex}.unloadedByEmployeeId`,
          employeeId as Uuid,
        );
      }
    },
    [employeeId, setValue],
  );

  const handleOpenAddExtraStoredItemModal = useCallback(() => {
    addExtraStoredItemModalRef.current?.present();
  }, []);

  return (
    <View style={styles.container}>
      <View style={styles.warehouseButtons}>
        {storages.length > 0 ? (
          <BlueSelectButtonsGroup
            buttons={storages.map((storage) => (
              <BlueSelectButton
                notSelected={
                  activeStorageId === undefined ||
                  activeStorageId !== storage.storageId
                }
                key={storage.storageId}
                whiteInactive
                onSelect={() => {
                  activeStorageIdRef.current =
                    activeStorageId === storage.storageId
                      ? null
                      : storage.storageId;
                  setValue(
                    'activeStorageId',
                    activeStorageId === storage.storageId
                      ? null
                      : storage.storageId,
                    {
                      shouldValidate: true,
                      shouldDirty: true,
                      shouldTouch: true,
                    },
                  );
                }}
              >
                <Typography fontWeight={700}>{storage.storageCode}</Typography>
                <Typography>
                  <FormattedMessage
                    id={`storageTypes.${storage.storageType}`}
                  />
                </Typography>
                <Typography>{storage.cubicMeter}&#13221;</Typography>
              </BlueSelectButton>
            ))}
          />
        ) : (
          <Typography color="white" textAlign="center" variant="h5">
            <FormattedMessage id="mobileApp.event.loadOrUnloadStorage.noStorages" />
          </Typography>
        )}
      </View>

      <RoomsAndFurnitureList
        rooms={rooms}
        onCheckFurniture={handleCheck}
        onCheckExtraItem={handleCheckExtra}
        onAddExtraStoredItem={handleOpenAddExtraStoredItemModal}
        isUnloadingOrLoading
        storages={storages}
        extraStoredItems={
          Object.values(
            storedItemsNotMappedToFurniture,
          ).flat() as StoredItemInputType[]
        }
      />
      <AddExtraStoredItemFormBottomSheet
        onSubmit={handleAddExtraStoredItem}
        bottomSheetRef={addExtraStoredItemModalRef}
      />
    </View>
  );
};

export default LoadOrUnloadStorageForm;
