import { UploadDocumentInputType } from '@bas/crm-domain/input-types';
import {
  useAddAddressToRelationMutation,
  useAddContactPersonToRelationMutation,
  useAddDocumentMutation,
} from '@bas/crm-domain/mutations';
import { UploadDocumentBottomSheet } from '@bas/crm-domain/native/organisms';
import { useDocumentsRequest } from '@bas/crm-domain/requests';
import { CreateInvoiceInputType } from '@bas/financial-domain/input-types';
import {
  InternalPaymentMethodType,
  Invoice,
} from '@bas/financial-domain/models';
import { CreateInvoiceMutationProps } from '@bas/financial-domain/mutations';
import { PaymentQrCodeBottomSheet } from '@bas/financial-domain/native/organisms';
import { useCreateInvoiceFromInputType } from '@bas/financial-domain/utils';
import {
  EnterHoursInputType,
  EventParticularsInputType,
  FinishEventAtTenantInputType,
  MileageReportInputType,
  PayAtCustomerPaymentInputType,
  PeopleWithDifferentHoursInputType,
  SignForEventInputType,
} from '@bas/planning-domain/input-types';
import { ProjectEvent, Vehicle } from '@bas/planning-domain/models';
import {
  useFinishEventWhenBackMutation,
  useFinishProjectEventAtCustomerMutation,
  useLogNewMileageReportMutation,
} from '@bas/planning-domain/mutations';
import {
  useFinishEventAtCustomerWizardSteps,
  usePayAtCustomerWizardSteps,
} from '@bas/planning-domain/native/hooks';
import {
  FinishEventAtCustomerFormWizardBottomSheet,
  FinishEventAtTenantBottomSheet,
  PayAtCustomerFormWizardBottomSheet,
  RegisterMileageReportBottomSheet,
} from '@bas/planning-domain/native/organisms';
import {
  isMovingJob,
  isMovingLiftJob,
  Project,
} from '@bas/project-domain/models';
import {
  useCreateInvoiceForProjectMutation,
  useGenerateInvoiceByProjectMutation,
  useReportDamageMutation,
} from '@bas/project-domain/mutations';
import { ReportDamageBottomSheet } from '@bas/project-domain/native/organisms';
import { useDamagesRequest } from '@bas/project-domain/requests';
import { useHandleRetrieveMaterials } from '@bas/shared/hooks';
import { DamageInputType } from '@bas/shared/input-types';
import { BillingMomentType } from '@bas/shared/models';
import { PublicTenant } from '@bas/tenant-domain/models';
import { BottomSheetMethods } from '@bas/ui/native/base';
import { Uuid } from '@bas/value-objects';
import { InventoryMovementInputType } from '@bas/wms-domain/input-types';
import {
  usePublicMovingBoxesRequest,
  useReusableMaterialsRequest,
} from '@bas/wms-domain/requests';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { keepPreviousData } from '@tanstack/react-query';
import dayjs from 'dayjs';
import * as React from 'react';
import {
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { View } from 'react-native';
import { v7 } from 'uuid';
import { MovingJobEventScreenTemplate } from '../MovingJobEventScreenTemplate';
import { MovingLiftJobEventScreenTemplate } from '../MovingLiftJobEventScreenTemplate';

export type ProjectEventScreenTemplateProps = {
  tenant: PublicTenant;
  project: Project;
  event: ProjectEvent;
  materials: Vehicle[];
  showEmployeeDetails: boolean;
  onOpenWorkingOnEventForm: () => void;
  onOpenDrivingToEventForm: () => void;
  onBack: () => void;
  children?: ReactNode;
  isFetching: boolean;
  onRefresh: () => void;
};

const ProjectEventScreenTemplate = ({
  tenant,
  project,
  event,
  materials,
  showEmployeeDetails,
  onOpenWorkingOnEventForm,
  onOpenDrivingToEventForm,
  onBack,
  children,
  isFetching: isFetchingParent,
  onRefresh: onRefreshParent,
}: ProjectEventScreenTemplateProps): ReactElement => {
  const { mutateAsync: reportDamageMutation } = useReportDamageMutation();
  const reportingDamageRef = useRef<BottomSheetModal & BottomSheetMethods>(
    null,
  );
  const uploadDocumentRef = useRef<BottomSheetModal & BottomSheetMethods>(null);
  const finishAtCustomerRef = useRef<BottomSheetModal & BottomSheetMethods>(
    null,
  );
  const finishAtTenantRef = useRef<BottomSheetModal & BottomSheetMethods>(null);
  const registerMileageRef = useRef<BottomSheetModal & BottomSheetMethods>(
    null,
  );
  const payAtCustomerRef = useRef<BottomSheetModal & BottomSheetMethods>(null);
  const qrCodeRef = useRef<BottomSheetModal & BottomSheetMethods>(null);
  const [showQrCodeForInvoice, setShowQrCodeForInvoice] = useState<Uuid>();
  const [loadingInvoice, setLoadingInvoice] = useState<boolean>(false);
  const [
    creatingInvoiceForBillingMomentType,
    setCreatingInvoiceForBillingMomentType,
  ] = useState<BillingMomentType>();
  const [generatedInvoice, setGeneratedInvoice] = useState<Invoice>();
  const { mutateAsync: generateInvoiceByProjectMutation } =
    useGenerateInvoiceByProjectMutation();

  const { mutateAsync: finishProjectEventAtCustomer } =
    useFinishProjectEventAtCustomerMutation();
  const { mutateAsync: finishWhenBack } = useFinishEventWhenBackMutation();
  const { mutateAsync: logNewMileageReport } = useLogNewMileageReportMutation();

  const { mutateAsync: createInvoiceForProjectMutation } =
    useCreateInvoiceForProjectMutation();

  const { mutateAsync: addAddressToRelationMutation } =
    useAddAddressToRelationMutation({
      throwOnError: true,
    });
  const { mutateAsync: addDocument } = useAddDocumentMutation();

  const { mutateAsync: addContactPersonToRelationMutation } =
    useAddContactPersonToRelationMutation({ throwOnError: true });

  const {
    data: damagesData,
    isFetching: isFetchingDamages,
    refetch: refetchDamages,
  } = useDamagesRequest({
    projectId: event.projectId,
    perPage: 250,
  });

  const {
    data: documentsData,
    isFetching: isFetchingDocuments,
    refetch: refetchDocuments,
  } = useDocumentsRequest({
    projectId: event.projectId,
    page: 1,
    perPage: 250,
    visibleInApp: true,
  });

  const handleRetrieveMaterials = useHandleRetrieveMaterials({
    project,
    eventId: event.eventId,
  });

  const handleReportDamage = useCallback(
    async ({ damagedAt, ...values }: DamageInputType) => {
      if (!damagedAt) {
        return;
      }

      await reportDamageMutation({
        ...values,
        damageId: v7(),
        eventId: event.eventId,
        projectId: event.projectId,
        damagedAt,
      });
    },
    [event?.eventId, event?.projectId, reportDamageMutation],
  );

  const handleUploadDocument = useCallback(
    async (values: UploadDocumentInputType) => {
      await addDocument({
        documentId: v7(),
        relationId: project.customer.relationId,
        projectId: project.projectId,
        ...values,
      });
    },
    [addDocument, project],
  );

  const handleRegisterMileageReport = useCallback(
    async (values: MileageReportInputType) => {
      if (!values.mileageEnd && !values.mileageStart) {
        return;
      }

      await logNewMileageReport({
        ...values,
        eventId: event.eventId,
        projectId: event.projectId,
        mileageStart: values.mileageStart || 0,
        reportStartDate: values.reportStartDate || new Date(),
      });
    },
    [event.eventId, event.projectId, logNewMileageReport],
  );

  const handleFinishEvent = useCallback(
    async ({
      mileageReports,
      realTravelEndTime,
    }: FinishEventAtTenantInputType) => {
      if (!realTravelEndTime) {
        return false;
      }

      await finishWhenBack({
        eventId: event.eventId,
        finishedAt: new Date(),
        realTravelEnd: realTravelEndTime,
        mileageReports: mileageReports
          .filter(({ mileageEnd }) => !!mileageEnd)
          .map((mileageReport) => ({
            ...mileageReport,
            reportEndDate: realTravelEndTime,
          })),
      });
      return true;
    },
    [event.eventId, finishWhenBack],
  );

  const handleFinishAtCustomerEvent = useCallback(
    async ({
      startDate,
      endDate,
      ...values
    }: EnterHoursInputType &
      PeopleWithDifferentHoursInputType &
      EventParticularsInputType &
      SignForEventInputType & {
        materials: InventoryMovementInputType[];
      }) => {
      await handleRetrieveMaterials(values);
      await finishProjectEventAtCustomer({
        eventId: event.eventId,
        finishedAtCustomerAt: new Date(),
        startTime: startDate || event.start,
        endTime: endDate || event.end,
        ...values,
      });
      return true;
    },
    [
      event.end,
      event.eventId,
      event.start,
      finishProjectEventAtCustomer,
      handleRetrieveMaterials,
    ],
  );

  const createInvoice = useCallback(
    async (
      mutation: CreateInvoiceMutationProps,
      {
        billingMomentType,
        ...values
      }: {
        invoice: CreateInvoiceInputType;
        billingMomentType: BillingMomentType;
      } & PayAtCustomerPaymentInputType,
    ) =>
      createInvoiceForProjectMutation({
        ...mutation,
        projectId: project?.projectId || '',
        billingMomentType,
        sendInvoice: mutation.finishInvoice,
        payment:
          values.paidAt && values.amount?.amount
            ? {
                paidAt: values.paidAt,
                amount: values.amount,
                remarks: values.remarks,
              }
            : undefined,
      }),
    [createInvoiceForProjectMutation, project?.projectId],
  );

  const handlePayment = useCallback(
    async (
      invoiceId: Uuid,
      values: {
        invoice: CreateInvoiceInputType;
        billingMomentType: BillingMomentType;
      } & PayAtCustomerPaymentInputType,
    ) => {
      if (
        !(
          values.paymentMethodType === InternalPaymentMethodType.MANUAL &&
          values.paidAt &&
          values.amount
        )
      ) {
        setShowQrCodeForInvoice(invoiceId);
        payAtCustomerRef.current?.close();
        payAtCustomerRef.current?.dismiss();
        payAtCustomerRef.current?.forceClose();
        qrCodeRef.current?.present();
        payAtCustomerRef.current?.close();
        payAtCustomerRef.current?.dismiss();
        payAtCustomerRef.current?.forceClose();
      }
    },
    [],
  );

  const handleCreateInvoiceAndPayment = useCreateInvoiceFromInputType<
    {
      invoice: CreateInvoiceInputType;
      billingMomentType: BillingMomentType;
    } & PayAtCustomerPaymentInputType
  >(
    addAddressToRelationMutation,
    addContactPersonToRelationMutation,
    createInvoice,
    handlePayment,
  );

  const handleOpenReportDamageForm = useCallback(() => {
    reportingDamageRef.current?.present();
  }, [reportingDamageRef]);

  const handleOpenUploadDocumentForm = useCallback(() => {
    uploadDocumentRef.current?.present();
  }, [uploadDocumentRef]);

  const handleOpenFinishAtCustomerForm = useCallback(() => {
    finishAtCustomerRef.current?.present();
  }, [finishAtCustomerRef]);

  const handleOpenFinishAtTenantForm = useCallback(() => {
    finishAtTenantRef.current?.present();
  }, [finishAtTenantRef]);

  const handleStartRegisterMileage = useCallback(() => {
    registerMileageRef.current?.present();
  }, [registerMileageRef]);

  const handleOpenPayAtCustomerForm = useCallback(
    async (billingMomentType: BillingMomentType) => {
      setCreatingInvoiceForBillingMomentType(billingMomentType);
      setLoadingInvoice(true);
      const { data: invoice } = await generateInvoiceByProjectMutation({
        projectId: project.projectId,
        billingMomentType,
      });

      setGeneratedInvoice(invoice);
      setLoadingInvoice(false);
      await new Promise((r) => {
        setTimeout(r, 100);
      });
      payAtCustomerRef.current?.present();
    },
    [generateInvoiceByProjectMutation, project.projectId],
  );

  const {
    data: boxesData,
    isFetching: isFetchingPublicMovingBoxes,
    refetch: refetchPublicMovingBoxes,
  } = usePublicMovingBoxesRequest();

  const {
    data: reusableMaterialsData,
    isFetching: isFetchingResuableMaterials,
    refetch: refetchResuableMaterials,
  } = useReusableMaterialsRequest(
    {
      page: 1,
      perPage: 999999,
    },
    {
      placeholderData: keepPreviousData,
    },
  );
  const reusableMaterials = useMemo(
    () => reusableMaterialsData?.data.member || [],
    [reusableMaterialsData?.data],
  );

  const boxes = useMemo(() => boxesData?.data.member || [], [boxesData?.data]);

  const finishEventAtCustomerSteps = useFinishEventAtCustomerWizardSteps({
    event,
    signingMoments: project.package.signingMoments,
    damages: damagesData?.data?.member || [],
    addresses: isMovingJob(project) ? project.addresses : [event.location],
    project,
    reusableMaterials,
    boxes,
  });

  const payAtCustomerSteps = usePayAtCustomerWizardSteps({
    project,
    useIdentities: tenant.useIdentities,
    billingMomentType:
      creatingInvoiceForBillingMomentType ||
      BillingMomentType.BILLING_MOMENT_WHEN_PROJECT_IS_FINISHED,
  });
  const currentTime = useMemo(() => {
    let result = dayjs();

    const remainder = Math.floor(result.minute() / 15) * 15;
    result = result.set('minutes', remainder);

    return result;
  }, []);

  const handleCloseFinishBottomSheet = useCallback(() => {
    finishAtCustomerRef.current?.dismiss();
  }, [finishAtCustomerRef]);

  const handleCLosePayAtCustomerBottomSheet = useCallback(() => {
    payAtCustomerRef.current?.dismiss();
  }, [payAtCustomerRef]);

  const isFetching = useMemo(
    () =>
      isFetchingParent ||
      isFetchingDamages ||
      isFetchingDocuments ||
      isFetchingPublicMovingBoxes ||
      isFetchingResuableMaterials,
    [
      isFetchingParent,
      isFetchingDamages,
      isFetchingDocuments,
      isFetchingPublicMovingBoxes,
      isFetchingResuableMaterials,
    ],
  );

  const handleRefresh = useCallback(() => {
    onRefreshParent();
    refetchDamages();
    refetchDocuments();
    refetchPublicMovingBoxes();
    refetchResuableMaterials();
  }, [
    onRefreshParent,
    refetchDamages,
    refetchDocuments,
    refetchPublicMovingBoxes,
    refetchResuableMaterials,
  ]);

  const extraContent = (
    <>
      <ReportDamageBottomSheet
        ref={reportingDamageRef}
        onSubmit={handleReportDamage}
        employees={event.employees.map(({ employee }) => employee)}
        addresses={isMovingJob(project) ? project.addresses : [event.location]}
      />

      <RegisterMileageReportBottomSheet
        ref={registerMileageRef}
        vehicles={materials}
        onSubmit={handleRegisterMileageReport}
      />

      <UploadDocumentBottomSheet
        ref={uploadDocumentRef}
        onSubmit={handleUploadDocument}
      />

      <FinishEventAtCustomerFormWizardBottomSheet
        event={event}
        onSubmit={handleFinishAtCustomerEvent}
        steps={finishEventAtCustomerSteps}
        bottomSheetRef={finishAtCustomerRef}
        currentTime={currentTime.toDate()}
        onClose={handleCloseFinishBottomSheet}
      />
      <FinishEventAtTenantBottomSheet
        endTime={
          event.travelEnd ? dayjs(event.travelEnd).toDate() : dayjs().toDate()
        }
        currentTime={currentTime.toDate()}
        finishedWorkingTime={event.realEnd || event.end}
        onSubmit={handleFinishEvent}
        vehicles={materials}
        ref={finishAtTenantRef}
      />
      <PayAtCustomerFormWizardBottomSheet
        project={project}
        generatedInvoice={generatedInvoice}
        onSubmit={handleCreateInvoiceAndPayment}
        billingMomentType={
          creatingInvoiceForBillingMomentType ||
          BillingMomentType.BILLING_MOMENT_WHEN_PROJECT_IS_FINISHED
        }
        bottomSheetRef={payAtCustomerRef}
        steps={payAtCustomerSteps}
        onClose={handleCLosePayAtCustomerBottomSheet}
      />
      <PaymentQrCodeBottomSheet
        ref={qrCodeRef}
        invoiceId={showQrCodeForInvoice}
      />
    </>
  );

  if (isMovingJob(project)) {
    return (
      <MovingJobEventScreenTemplate
        project={project}
        tenant={tenant}
        event={event}
        materials={materials}
        showEmployeeDetails={showEmployeeDetails}
        onStartFinalizingEventAtCustomer={handleOpenFinishAtCustomerForm}
        onStartFinalizingEvent={handleOpenFinishAtTenantForm}
        onStartPayingAtCustomer={handleOpenPayAtCustomerForm}
        onStartReportingDamage={handleOpenReportDamageForm}
        onStartRegisterMileage={handleStartRegisterMileage}
        onStartUploadingDocument={handleOpenUploadDocumentForm}
        onOpenWorkingOnEventForm={onOpenWorkingOnEventForm}
        onOpenDrivingToEventForm={onOpenDrivingToEventForm}
        damages={damagesData?.data?.member || []}
        documents={documentsData?.data?.member || []}
        loadingInvoice={loadingInvoice}
        isFetching={isFetching}
        onRefresh={handleRefresh}
        onBack={onBack}
      >
        {children}
        {extraContent}
      </MovingJobEventScreenTemplate>
    );
  }

  if (isMovingLiftJob(project)) {
    return (
      <MovingLiftJobEventScreenTemplate
        project={project}
        tenant={tenant}
        event={event}
        materials={materials}
        showEmployeeDetails={showEmployeeDetails}
        onStartFinalizingEventAtCustomer={handleOpenFinishAtCustomerForm}
        onStartFinalizingEvent={handleOpenFinishAtTenantForm}
        onStartPayingAtCustomer={handleOpenPayAtCustomerForm}
        onStartReportingDamage={handleOpenReportDamageForm}
        onStartRegisterMileage={handleStartRegisterMileage}
        onStartUploadingDocument={handleOpenUploadDocumentForm}
        onOpenWorkingOnEventForm={onOpenWorkingOnEventForm}
        onOpenDrivingToEventForm={onOpenDrivingToEventForm}
        damages={damagesData?.data?.member || []}
        documents={documentsData?.data?.member || []}
        loadingInvoice={loadingInvoice}
        onBack={onBack}
      >
        {children}
        {extraContent}
      </MovingLiftJobEventScreenTemplate>
    );
  }

  return <View />;
};

export default ProjectEventScreenTemplate;
