import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    // alignContent: 'center',
    flex: 1,
    height: 33,
    maxHeight: 33,
  },
  step: {
    marginRight: 2,
    height: 32,
    width: 32,
    justifyContent: 'center',
    position: 'relative',
  },
});
