import { ErrorResponse, TranslatedName, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type AddTimeTypeMutationProps = {
  timeTypeId: Uuid;
  name: string;
  categoryId: Uuid;
  icon: string;
  weight: number;
  fieldService: boolean;
  isWorkingHours: boolean;
  translatedNames: TranslatedName[];
};

export const AddTimeTypeMutation = async ({
  ...values
}: AddTimeTypeMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/{tenantId}/hrm/time-types`, {
    ...values,
  });

export const useAddTimeTypeMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddTimeTypeMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  AddTimeTypeMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddTimeTypeMutationProps
  >({
    mutationFn: AddTimeTypeMutation,
    throwOnError: false,
    ...options,
  });
