import * as Yup from 'yup';
import {
  VehicleInformationInputType,
  VehicleInformationInputTypeDefaultValues,
  VehicleInformationInputTypeValidationBuilder,
} from './vehicleInformationInputType';

export interface MovingTruckInformationInputType
  extends VehicleInformationInputType {
  cubicMeter?: number | null;
  length?: number | null;
  height?: number | null;
  width?: number | null;
  tailGateLength?: number | null;
}

export const MovingTruckInformationInputTypeDefaultValues =
  (): MovingTruckInformationInputType => ({
    ...VehicleInformationInputTypeDefaultValues(),
    cubicMeter: 0,
    length: 0,
    height: 0,
    width: 0,
    tailGateLength: 0,
  });

export const MovingTruckInformationInputTypeValidationBuilder =
  (): Yup.ObjectSchema<MovingTruckInformationInputType> =>
    Yup.object({
      cubicMeter: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .nullable()
        .label('label.cubicMeter'),
      length: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .nullable()
        .label('label.length'),
      height: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .nullable()
        .label('label.height'),
      width: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .nullable()
        .label('label.width'),
      tailGateLength: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .nullable()
        .label('label.tailGateLength'),
    }).concat(VehicleInformationInputTypeValidationBuilder());

export function isMovingTruckInformationInputType(
  object: unknown,
): object is MovingTruckInformationInputType {
  return (
    !!object &&
    typeof object === 'object' &&
    Object.prototype.hasOwnProperty.call(object, 'cubicMeter') &&
    Object.prototype.hasOwnProperty.call(object, 'length') &&
    Object.prototype.hasOwnProperty.call(object, 'height') &&
    Object.prototype.hasOwnProperty.call(object, 'width') &&
    Object.prototype.hasOwnProperty.call(object, 'tailGateLength')
  );
}
