import { ActiveIntake } from '@bas/project-domain/models';
import {
  storage,
  useTaxationToolStore,
  useTenantStore,
} from '@bas/shared/state';
import { TitleWithNumber } from '@bas/ui/native/atoms';
import { Box } from '@bas/ui/native/base';
import { ProjectType, Uuid } from '@bas/value-objects';
import { router } from 'expo-router';
import localForage from 'localforage';
import * as React from 'react';
import { ReactElement, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Platform } from 'react-native';
import { OpenTaxationListItem } from '../OpenTaxationListItem';

const OpenTaxationList = (): ReactElement => {
  const activeTaxations = useTaxationToolStore(
    (state) => state.activeTaxations,
  );
  const deleteTaxation = useTaxationToolStore((state) => state.deleteTaxation);
  const tenantState = useTenantStore((state) => state.tenant);

  const handleClickActiveIntake = useCallback((activeIntake: ActiveIntake) => {
    router.push(
      `/intake/${activeIntake.projectId}/${activeIntake.projectType || ProjectType.MOVING_JOB}`,
    );
  }, []);

  const handleDeleteActiveIntake = useCallback(
    (projectId: Uuid) => {
      deleteTaxation(projectId);
      if (Platform.OS === 'web') {
        localForage.removeItem(`project-intake-${projectId}`);
      } else {
        storage.delete(`project-intake-${projectId}`);
        storage.delete(`wizard-store-project-intake-${projectId}`);
      }
    },
    [deleteTaxation],
  );

  const filteredActiveTaxations = useMemo(
    () =>
      activeTaxations.filter(
        ({ tenantId }) => tenantId === tenantState?.tenantId || !tenantId,
      ),
    [activeTaxations, tenantState],
  );

  if (!filteredActiveTaxations || filteredActiveTaxations.length === 0) {
    return <Box />;
  }

  return (
    <Box mb={50}>
      <TitleWithNumber
        count={filteredActiveTaxations.length}
        title={<FormattedMessage id="mobileApp.stillActiveIntakes" />}
      />
      <Box mt={20}>
        {filteredActiveTaxations.map((activeIntake) => (
          <OpenTaxationListItem
            key={activeIntake.projectId}
            activeIntake={activeIntake}
            onPress={() => handleClickActiveIntake(activeIntake)}
            onDelete={() => handleDeleteActiveIntake(activeIntake.projectId)}
          />
        ))}
      </Box>
    </Box>
  );
};

export default OpenTaxationList;
