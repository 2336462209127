import {
  TimeTrackingItemInputType,
  timeTrackingItemInputTypeDefaultValues,
  timeTrackingItemInputTypeValidationBuilder,
} from '@bas/hrm-domain/input-types';
import { isTimeEntry, TimeEntry } from '@bas/hrm-domain/models';
import { TimeTrackingItem } from '@bas/shared/state';
import { colors } from '@bas/theme';
import { Button } from '@bas/ui/native/atoms';
import { BottomSheetMethods, Box, Typography } from '@bas/ui/native/base';
import { BackendErrors, BottomSheetProps } from '@bas/ui/native/organisms';
import { FormBottomSheet } from '@bas/ui/native/templates';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { ReactElement, RefObject, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { TimeTrackingItemForm } from '../TimeTrackingItemForm';

export type ChangeTimeTrackingItemBottomSheetProps = Omit<
  BottomSheetProps,
  'snapPoints' | 'children'
> & {
  onSubmit: (values: TimeTrackingItemInputType) => Promise<void> | void;
  onStartRemoving: (item: TimeTrackingItem) => Promise<void> | void;
  bottomSheetRef?: RefObject<BottomSheetModal & BottomSheetMethods>;
};

const ChangeTimeTrackingItemBottomSheet = ({
  onSubmit,
  bottomSheetRef,
  onStartRemoving,
  ...props
}: ChangeTimeTrackingItemBottomSheetProps): ReactElement => {
  const handleFinalize = useCallback(() => {
    bottomSheetRef?.current?.close();
    bottomSheetRef?.current?.forceClose();
    bottomSheetRef?.current?.dismiss();
  }, [bottomSheetRef]);

  const handleDefaultValues = useCallback(
    (data: {
      item: TimeTrackingItem | TimeEntry;
    }): TimeTrackingItemInputType => ({
      ...timeTrackingItemInputTypeDefaultValues(),
      ...data.item,
      isExisting: isTimeEntry(data.item),
      project: {
        projectId: data.item.projectId,
      },
      relation: {
        relationId: data.item.relationId,
      },
    }),
    [],
  );

  const handleRender = useCallback(
    ({ item }: { item: TimeTrackingItem }) => (
      <Box flex={1}>
        <Typography
          fontWeight={700}
          color="white"
          textAlign="center"
          fontSize={22}
          lineHeight={29}
        >
          <FormattedMessage id="label.timeEntryDetails" />
        </Typography>
        <Box paddingHorizontal={20} marginBottom={2}>
          <BackendErrors />
          <Box paddingTop={2}>
            <Button onPress={() => onStartRemoving(item)} buttonColor="danger">
              <FormattedMessage id="label.removeTimeEntry" />
            </Button>
          </Box>
          <Box paddingTop={2}>
            <Typography variant="subtitle1" color="white" mb={1}>
              <FormattedMessage id="label.changeTimeTrackingItem" />
            </Typography>
            <TimeTrackingItemForm />
          </Box>
        </Box>
      </Box>
    ),
    [onStartRemoving],
  );

  return (
    <FormBottomSheet<TimeTrackingItemInputType, { item: TimeTrackingItem }>
      bottomSheetRef={bottomSheetRef}
      disablePadding
      onSubmit={onSubmit}
      useProvider
      resetFormOnOpen
      onCancel={handleFinalize}
      validationSchema={timeTrackingItemInputTypeValidationBuilder}
      successFullFormViewProps={{
        gifUrl: 'https://media.giphy.com/media/26xBFg4zXVwzdI9lS/giphy.gif',
        color: colors.lila[100],
        title: 'label.timeTrackingItemSaved',
      }}
      useData
      defaultValues={handleDefaultValues}
      renderWithData={handleRender}
      {...props}
    />
  );
};

export default ChangeTimeTrackingItemBottomSheet;
