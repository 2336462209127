import { HoursOverview } from '../types';

export function isHoursOverview(object: unknown): object is HoursOverview {
  return (
    !!object &&
    Object.prototype.hasOwnProperty.call(object, 'totalAvailableHours') &&
    Object.prototype.hasOwnProperty.call(object, 'totalConfirmedHours') &&
    Object.prototype.hasOwnProperty.call(object, 'totalReservedHours') &&
    Object.prototype.hasOwnProperty.call(object, 'totalHoursOfDay') &&
    Object.prototype.hasOwnProperty.call(object, 'totalPlannedHours') &&
    Object.prototype.hasOwnProperty.call(object, 'totalShortageHours')
  );
}
