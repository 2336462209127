import { HoursEntryType } from '@bas/hrm-domain/models';
import { formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ApproveHoursMutationProps = {
  entryId: Uuid;
  employeeId: Uuid;
  reason?: string | null;
  startDate: Date;
  endDate: Date;
  travelStartDate?: Date | null;
  travelEndDate?: Date | null;
  breakTime: number | null;
  approvedHoursEntryId: Uuid;
  approvedById: Uuid;
};

export const ApproveHoursMutation = async ({
  employeeId,
  startDate,
  endDate,
  travelStartDate,
  travelEndDate,
  approvedHoursEntryId,
  approvedById,
  ...values
}: ApproveHoursMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/{tenantId}/hrm/employees/${employeeId}/enter-hours`, {
    employeeId,
    startDate: formatDateTime(startDate),
    endDate: formatDateTime(endDate),
    travelStartDate: travelStartDate ? formatDateTime(travelStartDate) : null,
    travelEndDate: travelEndDate ? formatDateTime(travelEndDate) : null,
    entryData: {
      entryType: HoursEntryType.APPROVED_HOURS_ENTRY,
      approvedHoursEntryId,
      approvedById,
    },
    ...values,
  });

export const useApproveHoursMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ApproveHoursMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ApproveHoursMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ApproveHoursMutationProps
  >({
    mutationFn: ApproveHoursMutation,
    throwOnError: false,
    ...options,
  });
