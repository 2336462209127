import {
  Event,
  isProjectEvent,
  ProjectEvent,
} from '@bas/planning-domain/models';
import { Collection, Uuid } from '@bas/value-objects';
import { AxiosResponse } from 'axios';

export const getProjectIdsFromEvents = (
  eventsResponse: AxiosResponse<Collection<Event>> | undefined,
): Uuid[] =>
  eventsResponse
    ? (eventsResponse.data.member || [])
        .filter((event): event is ProjectEvent => isProjectEvent(event))
        .map(({ projectId }) => projectId)
    : [];
