import { EmployeeWithNameOnly } from '@bas/hrm-domain/models';
import { Pagination, QueryOptionsWithKey } from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type EmployeeNamesByEmployeeIdsRequestProps = Pagination & {
  employeeIds: Array<Uuid>;
};

type Response = AxiosResponse<Collection<EmployeeWithNameOnly>>;
type QueryKeyType = QueryKey & {
  [0]: 'employees';
  [1]: 'list';
  [2]: Uuid[];
};

export const EmployeeNamesByEmployeeIdsRequest = async ({
  employeeIds,
  ...params
}: EmployeeNamesByEmployeeIdsRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/hrm/employees-names`, {
    params: { employeeId: employeeIds, ...params },
  });

export const useEmployeeNamesByEmployeeIdsRequest = (
  request: EmployeeNamesByEmployeeIdsRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response,
    QueryKeyType
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response, QueryKeyType>({
    ...options,
    queryFn: async () => EmployeeNamesByEmployeeIdsRequest({ ...request }),
    queryKey: ['employees', 'list', request.employeeIds],
  });
