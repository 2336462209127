import {
  EmployeeTimelineForApproval,
  isHoursEntry,
  isTimeOffRegistration,
} from '@bas/hrm-domain/models';
import { isEvent } from '@bas/planning-domain/models';
import {
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { formatDate } from '@bas/shared/utils';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type EmployeeTimelinesForApprovalByDateRequestProps = Pagination & {
  date: Date;
};

type Response = AxiosResponse<Collection<EmployeeTimelineForApproval>>;

export const EmployeeTimelinesForApprovalByDateRequest = async ({
  date,
  ...params
}: EmployeeTimelinesForApprovalByDateRequestProps): Promise<Response> =>
  axios.get(
    `api/{tenantId}/hrm/employees/timelines-for-approval/${formatDate(date)}`,
    {
      params: { ...params },
    },
  );

export const useEmployeeTimelinesForApprovalByDateRequest = (
  { date, ...request }: EmployeeTimelinesForApprovalByDateRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () =>
      EmployeeTimelinesForApprovalByDateRequest({ ...request, date }),
    queryKey: [
      'employee-timelines-for-approval',
      'list',
      formatDate(date),
      ...Object.values(request),
    ],
  });

export const EmployeeTimelinesForApprovalByDateRequestInvalidator: QueryInvalidator =
  (data, queryClient) => {
    if (isHoursEntry(data) || isTimeOffRegistration(data) || isEvent(data)) {
      queryClient.invalidateQueries({
        queryKey: ['employee-timelines-for-approval', 'list'],
      });
    }
  };
