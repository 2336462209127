{
  "label.employeeForAllowanceRule": "Medewerker waarvoor toeslagregel \"{name}\" van toepassing is",
  "label.employeeForCompensationRule": "Medewerker waarvoor vergoedingsregel \"{name}\" van toepassing is",
  "label.attributes": "Kenmerken",
  "label.allowance": "Toeslag",
  "label.projectCreated": "Dossier aangemaakt",
  "label.quoteCreated": "Offerte aangemaakt",
  "label.invoiceCreated": "Factuur aangemaakt",
  "label.storageCreated": "Opslag aangemaakt",
  "label.relationCreated": "Relatie aangemaakt",
  "label.employeeAttributes": "Medewerker kenmerken",
  "label.claAllowanceRules": "CAO toeslagen",
  "label.attribute": "Kenmerk",
  "label.withAttribute": "Met kenmerk",
  "label.withoutAttributes": "Zonder kenmerk(en)",
  "label.insideOrOutside": "Binnen/buiten",
  "button.addEmployeeAttribute": "Kenmerk toevoegen",
  "label.addEmployeeAttribute": "Medewerker kenmerk toevoegen",
  "label.noEmployeeAttributesFoundStartWorkingWithEmployeeAttributes": "Als je gaat werken met CAO-regels, kun je specifieke regels maken voor medewerkers op basis van de medewerker kenmerken die je hier definieert.\n\nStap 1: Voeg hier medewerker kenmerken toe.\nStap 2: Koppel in HRM deze kenmerken aan de betreffende medewerkers.\nStap 3: Pas deze kenmerken toe bij de CAO-regels die je maakt.\n\nHet resultaat zie je in het overzicht van alle toegepaste regels voor toeslagen en vergoedingen.",
  "label.areYouSureYouWantToEnableEmployeeAttribute.title": "Medewerker kenmerk inschakelen",
  "label.areYouSureYouWantToEnableEmployeeAttribute.content": "Weet je zeker dat je de kenmerk \"{name}\" wilt inschakelen?",
  "button.expand": "Uitklappen",
  "button.collapse": "Inklappen",
  "button.importEmployee": "Importeer medewerker",
  "button.connectEmployee": "Medewerker koppelen aan bestaande medewerker",
  "button.rejectEmployee": "Medewerker weigeren",
  "button.importEmployees": "Medewerkers importeren",
  "button.connectEmployeesToExistingEmployees": "Medewerkers koppelen aan bestaande medewerkers",
  "button.rejectEmployees": "Medewerkers weigeren",
  "resources.employeeDetails.tabs.attributes": "Medewerker kenmerken",
  "label.noEmployeeAttributesSelected": "Er zijn nog geen medewerker kenmerken geselecteerd",
  "label.importEmployees": "Medewerkers importeren",
  "label.selectTheSkillsAndDriversLicensesForTheNewEmployees": "Selecteer de vaardigheden en rijbewijzen voor de nieuwe medewerkers.",
  "label.connectExternalEmployeesToExistingEmployeesDescription": "Selecteer de bestaande medewerkers voor elke externe medewerker.",
  "label.connectExternalEmployeesToExistingEmployees": "Externe medewerkers koppelen aan bestaande medewerkers",
  "label.rejectEmployees": "Medewerkers weigeren",
  "label.areYouSureYouWantToRejectTheFollowingEmployees": "Weet je zeker dat je de volgende medewerkers wilt weigeren? Je kan optioneel een reden opgeven.",
  "label.createCostCenterPerProjectInvoice": "Maak kostenplaats per project voor verkoopfacturen",
  "label.purchaseOrdersPerProjectByCostCenter": "Importeer inkooporders per project als kosten",
  "label.travelTimeToStart": "Reistijd naar eerste werklocatie",
  "label.travelTimeToFinish": "Reistijd na laatste werklocatie",
  "label.automaticTravelTime": "Automatisch de vertrek- en aankomsttijd berekenen",
  "label.disableTask": "Taak uitschakelen",
  "label.employeeAttributesExplanation": "Je kunt medewerker kenmerken koppelen aan deze medewerker. Op basis van deze kenmerken kun je CAO regels maken voor deze medewerker en de andere medewerkers die een specifiek medewerker kenmerk hebben.",
  "label.withDisabled": "Toon ook uitgeschakelde",
  "label.claOvertimeAllowance": "Overwerk",
  "label.weekendAndPublicHolidays": "Weekend en feestdagen",
  "label.nightWork": "Nachtwerk",
  "menu.employees": "Medewerkers",
  "menu.hoursOverview": "Urenoverzicht",
  "menu.hours-to-approve": "Uren goedkeuren",
  "menu.declarations": "Declaraties",
  "menu.leaveCards": "Verlofkaarten",
  "menu.time-off-requests": "Verlofaanvragen",
  "menu.teamsForTasks": "Teams voor taken",
  "menu.data": "Inzicht",
  "label.availableVersion": "Beschikbare versie",
  "label.yourSoftwareIsUpToDate": "Je software is up-to-date",
  "label.waitingForServiceWorker": "Er staat een nieuwe versie klaar. Wacht even tot de nieuwe versie is gedownload.",
  "label.uploadPictures": "Foto's uploaden",
  "label.newUpdateDescription": "Als je op \"update installeren\" drukt, wordt de nieuwe versie geïnstalleerd en wordt de app opnieuw opgestart.",
  "label.newUpdateDescriptionWarning": "Let op: Werk je ook in andere tabbladen? Sla dan eerst daar de wijzigingen op. Anders kunnen je wijzigingen verloren gaan.",
  "label.pictures": "Foto's",
  "button.postponeUpdate": "Update uitstellen",
  "label.requestedOn": "Aanvraagdatum",
  "label.numberOfHours": "Aantal uur",
  "label.timeOffDays": "Verlofdagen",
  "button.updateApp": "Update installeren",
  "label.thisIsAnExternalEmployee": "Deze medewerker is via een koppeling aangemaakt.\n",
  "label.youCannotEditPersonalAndContractInformation": "Je kunt de persoonlijke en contractinformatie niet wijzigen. Wil je deze informatie wijzigen, doe dit dan in Nmbrs. Wil je de medewerker ontkoppelen? Doe dit dan bij de koppeling op de instellingenpagina.",
  "label.youCannotEditWorkingSchedule": "Je kunt de werkschema niet wijzigen. Wil je deze informatie wijzigen, doe dit dan in Nmbrs. Wil je de medewerker ontkoppelen? Doe dit dan bij de koppeling op de instellingenpagina.",
  "label.noEmailsFoundForReminder.title": "Je kunt deze mail nu niet versturen",
  "label.newUpdatePostponed": "De nieuwe update staat klaar",
  "label.timeOffRequest": "Verlofaanvraag",
  "label.timeOffRequestForNameFromUntil": "{name} - {from} - {until}",
  "label.noEmailsFoundForReminder.description": "De e-mail template gekoppeld aan deze mail is niet ingeschakeld. Om deze mail te activeren ga je naar instellingen, controleer de opmaak en de inhoud van de mail en probeer het dan opnieuw.",
  "label.arrivalTimeWarehouseMustBeAfterEnd": "Aankomsttijd bij de loods moet na het einde van de klus zijn",
  "label.departureTimeMustBeBeforeStart": "Vertrektijd moet voor het begin van de klus zijn",
  "label.noEmailsFound": "Er zijn geen e-mails gevonden",
  "label.theIntegrationIsSuccessfullyConnected": "De koppeling is succesvol verbonden",
  "button.retractTimeOff": "Verlof intrekken",
  "button.cancelSickLeave": "Ziekmelding annuleren",
  "button.editSickLeave": "Ziekmelding aanpassen",
  "button.recoverSickLeave": "Beter melden",
  "label.standardWorkingHours": "Standaard werktijden",
  "label.account": "Account",
  "label.workflows": "Werkwijze",
  "label.hrm": "Hrm",
  "label.approveInvoiceableHours": "Factureerbare uren goedkeuren",
  "label.taskType": "Taak type",
  "label.defaultAssignedToId": "Standaard toegewezen aan",
  "label.parentIdentityId": "Handelsnaam van",
  "label.parentIdentityName": "Handelsnaam van {name}",
  "label.setupTwoFactorAuthentication": "Twee factor authenticatie instellen",
  "label.setupTwoFactorAuthenticationDescription": "Voer hieronder je wachtwoord in om tweestapsverificatie in te stellen.",
  "label.enableTwoFactorAuthentication": "Twee factor authenticatie inschakelen",
  "label.enableTwoFactorAuthenticationDescription": "Scan de QR code met je authenticator app en voer de code in om tweestapsverificatie in te schakelen.",
  "label.disableTwoFactorAuthentication": "Twee factor authenticatie uitschakelen",
  "label.disableTwoFactorAuthenticationDescription": "Voer je wachtwoord en de code van je authenticator app in om tweestapsverificatie uit te schakelen.",
  "label.twoFactorAuthentication": "Twee factor authenticatie",
  "label.customQuoteIsRequiredForMovingLiftJob": "Om een offerte te maken voor een verhuislift klus moet je een aangepaste offerte aanzetten in het pakket.",
  "label.passKeyAuthentication": "PassKey authenticatie",
  "label.created": "Aangemaakt",
  "label.lastUsedAt": "Laatst gebruikt",
  "label.notYetUsed": "Nog niet gebruikt",
  "label.security": "Beveiliging",
  "label.weAreLoggingYouIn": "We zijn je aan het inloggen...",
  "label.passkey": "PassKey",
  "label.twoFactorAuthenticationIsDisabled": "Twee factor authenticatie is uitgeschakeld",
  "label.twoFactorAuthenticationIsEnabled": "Twee factor authenticatie is ingeschakeld",
  "label.passKeyAuthenticationIsDisabled": "PassKey authenticatie is uitgeschakeld",
  "label.noPassKeysRegistered": "Geen passkeys geregistreerd",
  "label.passKeysRegistered": "Geregistreerde passkeys",
  "label.passKeyAuthenticationIsEnabled": "PassKey authenticatie is ingeschakeld",
  "label.storageNotFound": "Opslag niet gevonden",
  "label.storedItemNotFound": "Opgeslagen item niet gevonden",
  "label.scanATheCurrentStorageLocationQrCode": "Scan de QR code van de huidige opslaglocatie",
  "label.scanATheNewStorageLocationQrCode": "Scan de QR code van de nieuwe opslaglocatie",
  "label.moveItemsFromCurrentStorageLocationToNewStorageLocation": "Verplaats items van {currentLocation} naar {newLocation}",
  "label.locationsAreNotInTheSameWarehouse": "De locaties zijn niet in dezelfde loods",
  "label.sameWarehouse": "Je kan de opslag niet verplaatsen naar een locatie in een andere loods, hiervoor moet je hem eerst klaarzetten en ophalen.",
  "label.applyChangesToRecurringEvent.title": "Wijzigingen toepassen op terugkerende afspraak",
  "label.applyChangesToRecurringEvent.description": "Wil je de wijzigingen alleen op deze afspraak toepassen, of op deze en alle toekomstige afspraken na {date}?",
  "label.cancelRecurringEventAfterDate.title": "Weet je zeker dat je de terugkerende afspraak wilt annuleren?",
  "label.cancelSingleEvent.description": "Je staat op het punt om een van de afspraken te annuleren.",
  "label.cancelRecurringEventAfterDate.description": "Je staat op het punt om de terugkerende afspraak te annuleren na de geselecteerde datum.",
  "label.applyChangesToRecurringEventAfterDate.title": "Wijzigingen toepassen op terugkerende afspraak",
  "label.applyChangesToRecurringEventAfterDate.description": "Selecteer hieronder vanaf welke datum je de wijzigingen wilt toepassen.",
  "label.compensationRuleType": "Vergoedingtype",
  "label.allowanceRuleType": "Toeslagtype",
  "label.allowanceRulePercentage": "Toeslagpercentage",
  "label.allowanceRule": "Toeslagregel",
  "label.employeeType": "Medewerkertype",
  "label.conditions": "Voorwaarden",
  "label.compensation": "Vergoeding",
  "label.hourType": "Voor type uren",
  "label.workedHoursMoreThan": "Aantal gewerkte uren meer dan",
  "label.workedHoursLessThan": "Aantal gewerkte uren minder dan",
  "label.excludedAttributes": "Uitgesloten kenmerken",
  "label.claSingleDayTripCompensation": "Eendaagse vergoeding",
  "label.claTravelCostCompensation": "Reiskosten vergoeding",
  "label.forEmployees": "Voor medewerkers",
  "label.forEmployeesWithAttribute": "Alleen voor medewerkers met kenmerk",
  "label.notForEmployeesWithAttributes": "Niet voor medewerkers met kenmerken",
  "label.applyChangesToRecurringEventAfterDate": "Wijzigingen toepassen vanaf",
  "label.applyChangesToSingleEvent.description": "Selecteer hieronder de datum waarop je de wijzigingen wilt toepassen.",
  "claCompensationRuleTypes.cla-travel-cost": "Reiskosten",
  "claCompensationRuleTypes.cla-single-day-trip": "Eendaagse rit",
  "claAllowanceRuleTypes.cla-overtime": "Overwerk",
  "claAllowanceRuleTypes.cla-weekend-and-public-holiday": "Weekend en feestdag",
  "claAllowanceRuleTypes.cla-night-work": "Nachtwerk",
  "claHourTypes.field-work-hours": "Buitendienst uren",
  "claHourTypes.warehouse-hours": "Loods uren",
  "claHourTypes.backoffice-hours": "Backoffice uren",
  "claHourTypes.break-time-hours": "Pauze",
  "claHourTypes.sick-hours": "Ziekte",
  "claHourTypes.leave-hours": "Verlof",
  "claHourTypes.unknown": "Onbekend",
  "materialTypes.material": "Materiaal",
  "employeeTypes.field-staff": "Buitendienst",
  "employeeTypes.backoffice-staff": "Backoffice",
  "employeeTypes.all": "Alle medewerkers",
  "label.addClaCompensationRule": "Vergoedingsregel toevoegen",
  "label.thereIsACompensationOfXPerHourBetweenXAndX": "{compensation} per uur tussen {startTime} en {endTime}",
  "label.thereIsACompensationOfXPerHour": "{compensation} per uur",
  "label.thereIsACompensationOfXPerDay": "{compensation} per dag",
  "label.numberOfTotalHoursForClaCompensationResults": "Aantal uren inclusief pauze: {numberOfTotalHours}",
  "label.numberOfTotalHoursForClaAllowanceResults": "Totale uren voor toeslag: {numberOfTotalHours}",
  "label.theTotalAllowanceIs": "Totale toeslag: {numberOfTotalHours} * {percentage} = {totalAllowanceHours}",
  "label.theTotalCompensationIs": "Totale vergoeding: {numberOfTotalHours} x {compensation} = {totalCompensation}",
  "label.editClaCompensationRule": "Vergoedingsregel \"{name}\" bewerken",
  "label.addClaAllowanceRule": "Toeslagregel toevoegen",
  "label.editClaAllowanceRule": "Toeslagregel \"{name}\" bewerken",
  "label.addYourFirstClaAllowanceRule": "Voeg je eerste toeslagregel toe!",
  "label.thisCompensationRuleHasNoResults": "Deze vergoeding heeft geen resultaten voor deze week.",
  "label.employeesWithAttribute": "Medewerkers met kenmerk: \"{attribute}\"",
  "label.employeesWithoutAttributes": "Medewerkers zonder de volgende kenmerken: {attributes}",
  "label.thisAllowanceRuleHasNoResults": "Deze toeslag heeft geen resultaten voor deze week.",
  "label.noClaAllowanceRulesFoundStartWorkingWithClaAllowanceRules": "Stel snel toeslagen in voor feestdagen, overwerk en nachtwerk. Kies wie het krijgt, bepaal het bedrag of percentage en kies voor uitbetaling of TVT.",
  "label.wantToKnowMoreAboutClaAllowanceRules": "Wil je meer weten hoe dit werkt?\nLees dan ons helpdeskartikel of vraag aan de Bassie Bot 'hoe stel ik toeslagregels in'",
  "label.wantToKnowMoreAboutClaRules": "Wil je meer weten hoe dit werkt?\nLees dan ons helpdeskartikel of vraag aan de Bassie Bot 'hoe stel ik toeslagregels in' of 'hoe stel ik vergoedingen in'",
  "button.goToHelpdeskArticle": "Helpdeskartikel",
  "label.addYourFirstClaCompensationRule": "Voeg je eerste regel voor vergoedingen toe!",
  "label.noClaCompensationRulesFoundStartWorkingWithClaCompensationRules": "Stel zelf in welke vergoedingen je wilt geven aan je medewerkers.",
  "label.wantToKnowMoreAboutClaCompensationRules": "Wil je meer weten hoe dit werkt?\nLees dan ons helpdeskartikel of vraag aan de Bassie Bot 'hoe stel ik vergoedingen in'",
  "label.wantToKnowMoreAboutEmployeeAttributesRules": "Wil je meer weten hoe dit werkt?\nLees dan ons helpdeskartikel of vraag aan de Bassie Bot 'hoe stel ik medewerker kenmerken in'",
  "label.fieldAndBackofficeEmployee": "Buiten en binnendienst medewerker",
  "label.fieldEmployee": "Buitendienst medewerker",
  "label.backofficeEmployee": "Binnendienst medewerker",
  "button.addClaCompensationRule": "Voeg regel voor vergoedingen toe",
  "button.editClaCompensationRule": "Vergoeding bewerken",
  "label.noHoursOnThisDay": "Er zijn geen uren doorgegeven en/of goedgekeurd voor deze dag.",
  "button.addClaAllowanceRule": "Toeslagregel toevoegen",
  "button.editClaAllowanceRule": "Toeslagregel bewerken",
  "label.itemIsAlreadyLoaded": "Item is al geladen",
  "label.enableOrDisableClaAllowanceRule": "Toeslagregel \"{name}\" in of uitschakelen",
  "label.claHoursOfDay": "CAO uren op: {date}",
  "label.enableOrDisableClaCompensationRule": "Vergoedingsregel \"{name}\" in of uitschakelen",
  "label.allowanceInHoursBetween": "Deze toeslag geldt tussen",
  "label.theItemIsAlreadyLoadedIntoTheStorage": "Het item is al geladen in de opslag",
  "label.wrongStorage": "Verkeerde opslag",
  "label.doYouWantToUpdateFutureEvents": "Wil je de wijzigingen ook toepassen op toekomstige afspraken?",
  "label.eventDate": "Afspraakdatum",
  "label.theStoredItemsAreNotInTheSelectedStorage": "De opgeslagen item staan niet in de geselecteerde opslag",
  "label.theItemShouldBeInStorageButWasNotFound": "Het item zou in de opslag moeten staan, maar is niet gevonden",
  "label.itemsWillGoIntoStorage": "De items worden in de opslag gezet",
  "label.currentWarehouse": "Huidige loods",
  "label.onlyOnPublicHolidays": "Alleen voor feestdagen",
  "label.edit": "Aanpassen",
  "label.enableOrDisable": "Inschakelen of uitschakelen",
  "label.showEmployees": "Medewerkers weergeven",
  "label.scanAQrCodeOfAStorage": "Scan een QR code van een opslag",
  "label.ifYouWantItemsToGoIntoASpecificLocationScanTheLocationQrCode": "Als je de items in een specifieke locatie wilt zetten, scan dan de QR code van de locatie",
  "label.scanTheQrCodeOfTheCurrentStorageLocation": "Scan de QR code van de huidige opslaglocatie",
  "label.scanTheQrCodeOfTheNewStorageLocation": "Scan de QR code van de nieuwe opslaglocatie",
  "label.loadItemsIntoStorage": "Items in de opslag zetten",
  "label.unloadItemsFromStorage": "Items uit de opslag halen",
  "label.moveItemsBetweenStorageLocation": "Items verplaatsen tussen opslaglocaties",
  "label.scannedItems": "Gescande items",
  "label.scanItemsToLoadThemIntoTheStorage": "Scan items om ze in de opslag te zetten",
  "label.selectedLocation": "Geselecteerde locatie",
  "label.returnStorageToWarehouse": "Opslag terugzetten",
  "label.storageIsAlreadyReturnedToWarehouse": "Opslag is al teruggezet in de loods",
  "label.pleaseGoBackAndUseMoveStorage": "Ga terug en gebruik de knop “Opslag verplaatsen”",
  "label.workingDayOverview": "Je werkdag zag er zo uit",
  "label.youStartedAtXAndFinishedAtXAndHadXBreakTime": "Je bent begonnen om {dayStartedAt} en hebt gewerkt tot {dayEndedAt}. Je hebt {requiredBreakTime} pauze gehad.",
  "label.youHaveTrackedXTimeEntries": "Je hebt {numberOfTimeEntries} tijdregistraties bijgehouden",
  "label.yourTrackedTimeEntries": "Je tijdregistraties",
  "label.returnedStorage.finished": "Opslag teruggezet",
  "label.loadingModule": "We zijn de module aan het laden... Even geduld!",
  "label.storageIsReturnedToWarehouse": "Opslag is teruggezet in de loods",
  "label.pickedUpStorage.finished": "Opslag is succesvol opgehaald",
  "label.storageIsPickedUp": "Opslag is opgehaald",
  "label.pickUpStorage": "Opslag ophalen",
  "label.other": "Overig",
  "label.storageIsAlreadyPickedUp": "Opslag is al opgehaald",
  "label.storageIsNotPositionedForPickup": "Opslag is nog niet klaargezet",
  "label.unknownTimeEntryToProcess": "We hebben nog een vraag over de tijdregistratie",
  "label.whatWasThisTimeEntry": "Wat waren je werkzaamheden van {startedAt} tot {endedAt}?",
  "label.beforeThisTimeEntryYouWereDoing": "Voor deze tijdregistratie was je bezig met {timeType}.",
  "label.afterThisTimeEntryYouWereDoing": "Na deze tijdregistratie was je bezig met {timeType}.",
  "label.possibleTimeTypes": "Voorgestelde werkzaamheden",
  "label.sameAsBeforeOrAfter": "Hetzelfde als voor of na",
  "label.positionedStorageForPickup.finished": "Opslag is klaargezet",
  "label.storageIsPositionedForPickup": "Opslag is klaagezet om opgehaald te worden voor een klus of verplaatsing.",
  "label.positionStorageForPickup": "Opslag klaarzetten",
  "label.storageIsNotInAWarehouse": "Opslag staat niet in een loods",
  "label.storageIsAlreadyPositionedForPickup": "Opslag is al klaargezet",
  "label.itemIsNotOnLocation": "Item staat niet op de locatie",
  "label.noStorageLocationSelected": "Er is geen opslaglocatie geselecteerd",
  "label.noNewStorageLocationSelected": "Er is geen nieuwe opslaglocatie geselecteerd",
  "label.itemIsNotOnLocationDescription": "Het item staat niet op de geselecteerde locatie",
  "label.breakTimeStartedAt": "Pauze gestart om",
  "label.stoppingWorkingDayYouStartedAtXAndYouAreEndingNowAtXWhatTimeDidYouHaveABreakAndYourBreakTimeShouldBe": "Je gaat nu je werkdag afronden, je bent begonnen om {dayStartedAt} en bent nu klaar om {dayEndedAt}. Dat betekent dat je {requiredBreakTime} pauze hebt gehad. Hoe laat heb je pauze gehad?",
  "label.finishUpWorkingDay": "Werkdag afronden",
  "label.movedStorage.finished": "Opslag verplaatst",
  "label.storageIsMovedToANewLocation": "Opslag is verplaatst naar een nieuwe locatie",
  "label.storageWasScanned": "Opslag is gescand",
  "label.weHaveSelectedTheStorage": "Bas heeft de opslag geselecteerd",
  "label.locationWasScanned": "Locatie is gescand",
  "label.itemWasAlreadyLoaded": "Item is al geladen",
  "label.itemWasAlreadyUnloadedOrWasNeverLoaded": "Item is al uitgeladen of is nooit geladen",
  "label.weHaveSelectedTheLocation": "Bas heeft de locatie geselecteerd",
  "label.locationIsNotInTheWarehouseOfTheStorage": "Locatie is niet in dezelfde loods als de opslag",
  "label.locationIsNotInSameWarehouse": "De geselcteerde locatie is niet in dezelfde loods als de opslag",
  "label.moveStorageToANewLocation": "Opslag verplaatsen naar een nieuwe locatie",
  "label.noStorageSelected": "Er is geen opslag geselecteerd",
  "label.storageIsNotReturnedToAWarehouse": "Opslag is nog niet teruggezet in een loods",
  "label.pleaseGoBackAndReturnTheStorageToAWarehouse": "Ga terug en zet de opslag terug in een loods, via de knop “Opslag terugzetten”",
  "label.currentLocation": "Huidige locatie",
  "label.storageCurrentlyNotInALocation": "Opslag staat momenteel niet op een locatie",
  "label.scanALocationQrCode": "Scan een locatie QR code",
  "label.storagePositionedForPickupFrom": "Opslag is klaargezet, hij stond in {oldWarehouse} op {oldLocation}",
  "label.storageMovedToNewLocationFromTo": "Opslag is verplaatst van {oldWarehouse} op {oldLocation} naar {newWarehouse} op {newLocation}",
  "label.storageWasPickedUpFrom": "Opslag is opgehaald, hij stond in {oldWarehouse}",
  "label.storageWasReturnedToWarehouse": "Opslag is teruggezet in {newWarehouse}",
  "label.storageReservedForCustomer": "Opslag is gereserveerd voor {customerName}",
  "label.storageEmptied": "Opslag voor {customerName} is leeggehaald en ontkoppeld",
  "label.storageWasInvoiced": "Opslag is gefactureerd, laatste factuurdatum was {oldLastInvoicedDate} en nu is hij gefactureerd tot {newLastInvoicedDate}",
  "label.createNewWarehouse": "Nieuwe loods aanmaken",
  "label.createWarehouseDescription": "Maak een nieuwe loods aan, hierna kan je locaties toevoegen aan de loods.",
  "label.systemUser": "Bassie",
  "label.isOwnWarehouse": "Eigen loods",
  "label.never": "nooit",
  "label.newWarehouse": "Nieuwe loods",
  "label.newLocation": "Nieuwe locatie",
  "label.warehouse": "Loods",
  "label.warehouses": "Loodsen",
  "label.isNotOwnWarehouse": "Niet eigen loods",
  "label.storageLocations": "Opslaglocaties",
  "label.createNewStorageLocation": "Nieuwe opslaglocatie aanmaken",
  "label.storageOnLocation": "Opslag op locatie",
  "label.storedItemsOnLocation": "Opgeslagen items op locatie",
  "label.createStorageLocationDescription": "Maak een nieuwe opslaglocatie aan, hierna kan je items en opslag koppelen aan de opslaglocatie.",
  "label.currentlyNotStoredInWarehouse": "Momenteel niet opgeslagen in een loods",
  "label.storageLocation": "Opslaglocatie",
  "label.locationIsNotKnown": "Locatie is niet bekend",
  "label.incorrectQrCode": "De QR code is niet correct",
  "label.true": "waar",
  "label.false": "onwaar",
  "label.after": "na “{value}”",
  "label.onOrAfter": "op of na “{value}”",
  "label.before": "voor “{value}”",
  "label.onOrBefore": "op of voor “{value}”",
  "label.isEmpty": "is leeg",
  "label.isNotEmpty": "is niet leeg",
  "label.greaterThan": "is groter dan “{value}”",
  "label.lessThan": "is kleiner dan “{value}”",
  "label.greaterThanOrEqual": "is groter dan of gelijk aan “{value}”",
  "label.lessThanOrEqual": "is kleiner dan of gelijk aan “{value}”",
  "label.startsWith": "begint met “{value}”",
  "label.endsWith": "eindigt met “{value}”",
  "label.contains": "bevat “{value}”",
  "label.notContains": "bevat niet “{value}”",
  "label.editInventoryListForStorage": "Inboeddelijst bewerken voor opslag {storageCode}",
  "label.inventoryListIsEmpty": "Inboedelijst is leeg",
  "label.inventoryListHasNoResults": "Inboedelijst heeft geen items",
  "label.noRows": "Geen regels",
  "label.noResults": "Geen resultaten",
  "label.loadingPaymentMethods": "Betaalmethodes laden...",
  "label.couldNotLoadPaymentMethods": "Kon betaalmethodes niet laden",
  "label.tryAgain": "Probeer opnieuw",
  "label.position": "Positie",
  "label.timeTrackingItemSaved": "Tijdregistratie item opgeslagen",
  "label.changeTimeTrackingItem": "Geregistreerde tijd wijzigen",
  "label.timeEntryDetails": "Tijdregistratie details",
  "label.createTimeTrackingItem": "Tijdregistratie aanmaken",
  "label.softwareInformation": "Software informatie",
  "label.softwareVersion": "Software versie",
  "label.currentVersion": "Huidige versie",
  "label.explainGrossRevenue": "Let op, alleen facturen verstuurd vanuit de klus worden meegenomen in de omzet. Opslag en andere losse facturen worden niet meegenomen.",
  "label.explainOpenQuotesValue": "Openstaande offertes zijn offertes die nog niet geaccepteerd zijn door de klant, als er voor de klus een andere offerte geaccepteerd is wordt deze niet meegeteld.",
  "label.grossMarginLowerThen": "Marge lager dan",
  "label.reminderSentAt": "{numberOfReminder, selectordinal,\n    =1 {#ste}\n    other {#de}\n} herinnering",
  "label.marginLowerThen": "Marge lager dan",
  "label.seperateStorage": "Losse opslag",
  "label.connected": "Verbonden",
  "label.notConnected": "Niet verbonden",
  "label.apiKeyPerIdentity": "API sleutel per identiteit",
  "label.company": "Bedrijf",
  "label.workLocation": "Werklocatie",
  "label.departureLocation": "Vertreklocatie",
  "label.disableLateTimes": "Toon geen late tijden",
  "label.workingSchedule": "Werkrooster",
  "label.availability": "Beschikbaarheid",
  "label.evenWeeks": "Even weken",
  "label.oddWeeks": "Oneven weken",
  "label.even": "Even",
  "label.odd": "Oneven",
  "label.occasionalAvailabilities": "Sporadische beschikbaarheden",
  "label.editAvailability": "Beschikbaarheid bewerken",
  "label.removingOccasionalAvailability": "Sporadische beschikbaarheid verwijderen",
  "label.areYouSureYouWantToRemoveOccasionalAvailability": "Weet je zeker dat je de sporadische beschikbaarheid met datum {date} wilt verwijderen?",
  "label.addClosureDate": "Sluitingsdag toevoegen",
  "label.removeClosureDate": "Sluitingsdag verwijderen",
  "label.areYouSureYouWantToRemoveClosureDate": "Weet je zeker dat je de sluitingsdatum met datum {date} en reden: {reason} wilt verwijderen?",
  "label.closureDates": "Sluitingsdata",
  "label.copyAttachmentsToDocuments": "Kopieer bijlagen naar documenten",
  "label.openedBy": "Geopend door",
  "label.notOpenedYet": "Nog niet geopend",
  "label.changeNumberOfSeatsDescription": "Zodra je het aantal medewerkers verhoogd wordt dit direct verwerkt in je facturatie. Je kunt het aantal medewerkers ook verlagen, maar dan wordt dit pas verwerkt in je volgende factuur.",
  "label.changeNumberOfSeats": "Aantal medewerkers wijzigen",
  "label.newTotalNumberOfSeats": "Nieuwe aantal medewerkers",
  "label.changingNumberOfSeats": "Aantal medewerkers wijzigen",
  "label.requestSepaAndGetADiscount": "Vraag nu automatische incasso aan en ontvang € 25 korting op je volgende factuur.",
  "label.requestSepa": "Vraag automatische incasso aan",
  "label.sepa": "Automatische incasso",
  "label.usedSeats": "Gebruikte plaatsen",
  "label.timeEntryInformation": "Tijdregistratie informatie",
  "label.removeTimeEntry": "Tijdregistratie verwijderen",
  "label.areYouSureYouWantToRemoveTimeEntry": "Weet je zeker dat je de tijdregistratie wilt verwijderen?",
  "label.timeEntryRemoved": "Tijdregistratie verwijderd",
  "label.timeEntryRemovedDescription": "De tijdregistratie is verwijderd",
  "label.approveTimeEntry": "Tijdregistratie goedkeuren",
  "label.rejectTimeEntry": "Tijdregistratie afkeuren",
  "label.enterTimeEntry": "Tijdregistratie invoeren",
  "label.changeOverlappingTimeEntries": "Overlappende tijdregistraties automatisch wijzigen",
  "label.overlappingTimeEntries": "Overlappende tijdregistraties",
  "label.leftOver": "Over",
  "label.noOverlappingTimeEntries": "Geen overlappende tijdregistraties",
  "label.worked": "Gewerkt",
  "label.totalForWeek": "Totaal week {weekNumber}",
  "label.timeOff": "Vrij",
  "label.leave": "Verlof",
  "label.totalContract": "Totaal contract",
  "label.fieldHours": "Buitendienst uren",
  "label.insideHours": "Binnendienst uren",
  "label.workedFieldService": "Gewerkt buitendienst",
  "label.workedInternal": "Gewerkt binnendienst",
  "label.fieldService": "Buitendienst",
  "label.internal": "Binnendienst",
  "label.thereAreNoActiveClaRules": "Er zijn geen actieve CAO regels",
  "label.goToSettingsToAddClaRules": "Ga naar instellingen, dan naar HRM en stel daar de regels voor toeslagen en vergoedingen in.",
  "button.goToHrmSettings": "Ga naar HRM instellingen",
  "label.totalWorked": "Totaal gewerkt",
  "label.holiday": "Vakantie",
  "label.nr": "Nr.",
  "label.contractType": "Contract type",
  "label.buildUp": "Opbouw",
  "label.reduction": "Afbouw",
  "label.result": "Resultaat",
  "label.usedLeaveHours": "Opgenomen",
  "label.requestedBuildUpHours": "Opbouw aangevraagd",
  "label.usedSeatsOfIncluded": "{numberOfEmployees} van {numberOfSeats} medewerkers",
  "label.youHaveReachedYourNumberOfSeatsPleaseOrderMoreSeats": "Je hebt je aantal plaatsen bereikt. Bestel meer plaatsen.",
  "label.hoursToLate": "+{value} uur te laat",
  "label.sessionDisconnected": "Sessie verbroken",
  "label.numberOfProjects": "Aantal klussen",
  "label.numberOfSentQuotes": "Aantal verstuurde offertes",
  "label.numberOfAcceptedQuotes": "Aantal geaccepteerde offertes",
  "label.successRate": "Slagingspercentage",
  "label.somebodyLoggedIntoTheSameAccountOnADifferentDevice": "Jij of iemand anders heeft zojuist ingelogd op uw account vanaf een ander apparaat.\n\nAls je dit inderdaad was, klikt u gewoon op de knop Bevestigen en kunt u vanaf hier verder werken. Zo niet, wijzig dan uw wachtwoord en schakel twee factor authenticatie in.",
  "label.areYouStillHere": "Ben je er nog?",
  "label.areYouStillHereTheSessionIsAboutToExpire": "Je sessie is verloopt in {remaining} minuten. Wil je nog even blijven?",
  "label.roadmap": "Roadmap van Bas",
  "label.hoursOfEmployee": "Uren van de medewerker",
  "label.invoiceReference": "Factuurreferentie",
  "label.priorityId": "Prioriteit",
  "label.createCustomTask": "Nieuwe taak aanmaken",
  "label.dueDate": "Vervaldatum",
  "label.plannedOn": "Gepland op",
  "label.quoteMomentLines": "Offerteregels",
  "button.requestAccess": "Toegang vragen",
  "label.manual": "Handmatig",
  "label.withoutProject": "Zonder klus",
  "label.timeType": "Type uren",
  "label.billing": "Pakket & Facturatie",
  "label.createTenantContract": "Contract aanmaken",
  "label.manageYourBillingAndPayment": "Beheer uw facturatie en betaling",
  "label.handlingCosts": "Handlingkosten",
  "label.handlingDate": "Handlingdatum",
  "label.signer": "Ondertekenaar",
  "label.grossMargin": "Bruto margin",
  "label.expectedMargin": "Verwachte margin",
  "label.addAvailability": "Beschikbaarheid toevoegen",
  "label.useTheTimeOffRegistrationInHrmInstead": "Deze functie is uitgeschakeld, gebruik de verlofregistratie in HRM in plaats daarvan.",
  "label.pricePerKilometer": "Prijs per kilometer",
  "label.hour": "Uur",
  "label.youNeedToBeSuperAdminToConnectThisIntegration": "Ik wil graag de integratie {provider} koppelen, hoeveel kost deze koppeling?",
  "label.value": "Waarde",
  "label.key": "Sleutel",
  "label.fields": "Velden",
  "label.signers": "Ondertekenaars",
  "label.leaveEmptyForDefault": "Laat leeg voor standaard waarde, of later te bepalen",
  "label.templateFieldsForBas": "Velde voor Bas",
  "label.templateFieldsForCustomer": "Velden voor klant",
  "label.minute": "Minuut",
  "label.monthlyPricePerMonth": "{price} per maand",
  "label.contractXHoursPerWeek": "Contracturen per week: {contractHoursPerWeek}",
  "label.contractHours": "Contracturen",
  "label.pricePerHour": "Prijs per uur",
  "label.perMonth": "per maand",
  "label.year": "Jaar",
  "label.expectedPurchasePricePerUnit": "Verwachte aankoopprijs per stuk",
  "label.expectedQuantity": "Verwachte hoeveelheid",
  "label.totalExpectedCosts": "Totaal verwachte kosten",
  "label.realizedPurchasePricePerUnit": "Gerealiseerde aankoopprijs per stuk",
  "label.realizedQuantity": "Gerealiseerde hoeveelheid",
  "label.realized": "Gerealiseerd",
  "label.expected": "Verwacht",
  "label.invalid": "Ongeldig",
  "label.invalidDate": "Ongeldige datum",
  "label.realizedCosts": "Gerealiseerde kosten",
  "label.totalRealizedQuantity": "Totaal gerealiseerde hoeveelheid",
  "label.useDate": "Gebruiksdatum",
  "label.changeHowYouPayForYourPlan": "Wijzig hoe je betaalt voor uw pakket",
  "label.viewYourPastInvoices": "Bekijk uw vorige facturen en de betaalstatus",
  "label.taskFor": "{eventType} voor {eventName}",
  "label.incomingEmail": "Inkomende e-mail",
  "label.noLeadsFound": "Geen leads gevonden",
  "label.costType": "Type kosten",
  "label.difference": "Verschil",
  "label.removeCostsFromProject": "Wilt u de kosten van dit project verwijderen?",
  "label.photos": "Foto's",
  "label.areYouSureYouWantToRemoveCostsFromProject": "Weet je zeker dat je de kosten van dit project wilt verwijderen?",
  "label.whichLeadWebsite": "Welke lead website?",
  "label.totalCosts": "Totaal kosten",
  "label.allProjects": "Alle projecten",
  "label.customerProjects": "Klant projecten",
  "label.approveHours": "Uren goedkeuren",
  "label.vatScenario": "BTW-scenario",
  "label.doYouWantToInvoiceHandlingCosts": "Wil je de handlingkosten factureren?",
  "label.visibleInApp": "Zichtbaar in app",
  "label.projectCode": "Dossier nummer",
  "label.defaultAssignee": "Standaard toegewezen",
  "label.defaultPriority": "Standaard prioriteit",
  "label.disable": "Uitschakelen",
  "label.enable": "Inschakelen",
  "label.whichItemsDoYouNeedToPickup": "Welke items moet je ophalen?",
  "label.whichItemsDoYouNeedToDeliver": "Welke items moet je afleveren?",
  "label.whatAreYouGoingToPickup": "Wat ga je ophalen?",
  "label.whatAreYouGoingToDeliver": "Wat ga je afleveren?",
  "label.pickupOn": "Laden op: {dates}",
  "label.connectToProject": "Koppel aan klus",
  "label.deliverOn": "Leveren op: {dates}",
  "label.delivered": "Afgeleverd",
  "label.pickedUp": "Opgehaald",
  "label.retrievedFromStorage": "Uit de opslag gehaald",
  "label.putInStorage": "In de opslag gezet",
  "label.wornOut": "Verbruikt",
  "label.sold": "Verkocht",
  "label.items": "Items",
  "label.noItemsAdded": "Er zijn nog geen items toegevoegd",
  "label.noItemsKnown": "Er zijn geen items bekend",
  "label.transportAddresses": "Transport adressen",
  "label.noFromAddresses": "Geen laadadressen",
  "label.noToAddresses": "Geen losadressen",
  "label.dimensions": "Dimensies",
  "label.cubicMeter": "Kuub",
  "label.squareMeter": "Vierkante meter",
  "label.cargo": "Lading",
  "label.loads": "Ladingen",
  "label.notifications": "Notificaties",
  "label.discountDescription": "Korting omschrijving",
  "label.extraLines": "Extra regels",
  "label.billingMomentWhenProjectIsFinishedExplained": "Als er een offerte is komen deze regels standaard op de factuur, daarnaast kan je hieronder standaard regels toevoegen.",
  "label.receivedNewMessage": "Niew bericht ontvangen van {senderName}",
  "label.connectedWhatsAppBusinesses": "Verbonden WhatsApp bedrijven",
  "label.connectedWhatsAppNumbers": "Verbonden WhatsApp nummers",
  "label.reachedWhatsAppHoursLimit": "Je hebt de 24-uurs antwoordlimiet van WhatsApp bereikt",
  "label.reachedWhatsAppHoursLimitDescription": "Om door te gaan, moet u hieronder een vooraf goedgekeurd berichtsjabloon verzenden. Nadat de klant heeft geantwoord, kunt u het gesprek voortzetten.",
  "label.noMaterialsNeededOrUsed": "Er is niet bekend welk materiaal er nodig is.",
  "label.noKnownMileageReports": "Er zijn geen ritten geregistreerd",
  "label.drivenDistance": "Gereden afstand",
  "label.mileageReports": "Rittenregistratie",
  "label.updateRevenue": "Omzet bijwerken",
  "label.weDetectedYouAreAtALocation": "Bas heeft gedetecteerd dat je op een locatie bent",
  "label.weStartedTrackingYourTimeWithType": "Bas houdt nu je tijd bij met type {type}",
  "label.here": "hier",
  "label.ifYouUpdateOrSetTheRealizedRevenueItWillNotBeAutomated": "Als je de gerealiseerde omzet bijwerkt of instelt, wordt deze niet automatisch bijgewerkt.",
  "label.clickHereToGoToUpdateTheCosts": "Klik {here} om de kosten bij te werken",
  "label.document": "Document",
  "label.invoice": "Factuur",
  "label.lastInvoicedDateBefore": "Laatst gefactureerd voor",
  "label.hasMissingInvoices": "Mist nog facturen",
  "label.missingInvoices": "Mist nog facturen",
  "label.noSignature": "Geen handtekening",
  "label.startWorkingOnEvent": "Starttijd van werkzaamheden doorgeven",
  "label.arrivedAtLocation": "Aankomsttijd op locatie doorgeven",
  "label.startDriving": "Starten met rijden",
  "label.startDriving.finished": "Gestart met rijden",
  "label.thereAreNoDamages": "Er zijn (nog) geen schades",
  "label.damageAlreadyExisted": "Schade was al bekend",
  "label.newDamage": "Nieuwe schade",
  "label.damageWasAlreadyThere": "Al aanwezig",
  "label.damageInflictedOn": "Type schade",
  "label.location": "Locatie",
  "label.hidePdfOnAcceptancePage": "Verberg de PDF op de acceptatie pagina",
  "label.forceFullPdfOnAcceptancePage": "Forceer volledige PDF op de acceptatie pagina",
  "label.damagedByEmployee": "Beschadigd door",
  "label.damagedByEmployeeId": "Beschadigd door",
  "label.theFollowingDamagesAreMade": "De volgende schades zijn door ons personeel gemaakt:",
  "label.theFollowingDamagesWereAlreadyThere": "Deze schades waren al bekend voor de klus:",
  "label.thereWereTheFollowingParticulars": "Er waren de volgende bijzonderheden:",
  "label.damages": "Schades",
  "label.theFollowingDamagesAreAlreadyProcessed": "Schades die al eerder zijn gemeld",
  "label.sickAndTimeOffHours": "Ziekte/vrije uren",
  "label.serverError": "Er ging iets mis, probeer het later opnieuw. De foutcode is {code}.",
  "label.onWhatAddressDidTheDamageOccur": "Op welk adres is de schade ontstaan?",
  "label.summary": "Samenvatting",
  "label.whatWasTheDamageInflictedOn": "Op wat is de schade toegebracht?",
  "label.byWhomWasTheDamageMade": "Door wie is de schade gemaakt?",
  "label.noDescription": "Geen beschrijving",
  "label.noParticulars": "Geen bijzonderheden",
  "label.particulars": "Bijzonderheden",
  "label.internalParticulars": "Bijzonderheden (alleen intern)",
  "label.whoHadDifferentHours": "Wie had er afwijkende uren?",
  "label.noConnection": "Geen internetverbinding (connectie)",
  "label.noInternet": "Geen internetverbinding (internet)",
  "label.theFollowingBoxesArePresent": "Deze dozen zijn nog in uw bezit:",
  "label.theFollowingMaterialsArePresent": "Deze materialen zijn nog in uw bezit: ",
  "label.wasTheWorkPerformedSatisfactorily": "Zijn de werkzaamheden naar tevredenheid uitgevoerd?",
  "label.isTheCustomerPresent": "Is de klant aanwezig?",
  "label.doYouWantToSignWithTheCustomer": "Wil je tekenen met de klant?",
  "label.notSetUpYet": "De koppeling is nog niet ingesteld",
  "label.webhookUrl": "Webhook URL",
  "label.apiUrl": "API URL",
  "label.sharedSecret": "Gedeeld geheim",
  "label.packageInformation": "Pakket informatie",
  "label.noDownloadsYet": "Er zijn nog geen downloads beschikbaar",
  "label.tenantId": "Omgeving ID",
  "label.integrationId": "Integratie ID",
  "label.downloads": "Downloads",
  "label.howDidYouFindUs": "Hoe heb je ons gevonden?",
  "label.automatedEmailTemplate": "Gekoppelde email template",
  "label.noCustomAutomatedEmail": "Geen aangepaste email geselecteerd",
  "label.fullDay": "Volledige dag",
  "label.weStartedTrackingYourLocation": "Bas is begonnen met het bijhouden van je locatie",
  "label.weStartedTrackingYourLocationSoYourHoursWillBeRegistered": "Hierdoor worden je uren automatisch bijgehouden.",
  "label.weStoppedTrackingYourLocation": "Bas is gestopt met het bijhouden van je locatie",
  "label.weStoppedTrackingYourLocationSoYourHoursWillBeRegistered": "Als dit automatisch is gebeurd, dan kan je het weer aanzetten in de app.",
  "label.sendInvoice": "Factuur meteen versturen",
  "label.registeredHours": "Geregistreerde uren",
  "label.hoursOverview": "Urenoverzicht",
  "label.websites": "Websites",
  "label.websiteCode": "Unieke code",
  "label.noConnectedWebsites": "Er zijn nog geen websites verbonden",
  "label.turnoverGroup": "Omzetgroep",
  "label.linePrice": "Prijs",
  "label.itemsToUseForPrice": "Welke onderdelen moeten worden gebruikt voor de prijs?",
  "label.itemsToUseForQuantity": "Welke onderdelen moeten worden gebruikt voor het aantal?",
  "label.ifYouDontUseTheWorkflowTheCustomerWillNotReceiveTheCorrectEmail": "Let op! Door het op deze manier in te plannen ontvangt de klant een afspraak wijziging en niet de specifieke email",
  "label.usePlanProjectButton": "Let op! Als je de verhuizing wil plannen moet je de hiervoor bestemde knop gebruiken.",
  "label.passwordChanged": "Je wachtwoord is nu aangepast, je kan nu inloggen met dit wachtwoord.",
  "label.passwordResetRequested": "We hebben je een mail gestuurd om het wachtwoord te herstellen",
  "label.restorePassword": "Wachtwoord herstellen",
  "label.createPassword": "Wachtwoord aanmaken",
  "label.defaultDisableAutomaticTravelTime": "Automatische reistijd standaard uitschakelen",
  "label.defaultHideFilesForNonBackofficeEmployees": "Bestanden standaard verbergen voor niet-backoffice medewerkers",
  "label.forgotPassword": "Wachtwoord vergeten",
  "label.accessDenied": "U heeft geen permissies om hier in te loggen",
  "label.assignedTeam": "Team",
  "label.assignedEmployee": "Medewerker",
  "label.shortcuts": "Snelkoppelingen",
  "label.finishedAt": "Afgerond op",
  "label.finishedEventDetails": "Afgeronde afspraak gegevens",
  "label.finishEventWhenBack": "Afspraak volledig afronden",
  "label.snoozeUntil": "Uitstellen tot",
  "label.snoozeTaskUntilLater": "Taak {taskName} uitstellen",
  "label.projectNotes": "Klus notities",
  "label.thisIsARecurringEvent": "Dit is een terugkerende afspraak",
  "label.eventNotes": "Afspraak notities",
  "label.couldNotLoadPreviewCheckTemplate": "PDF kan niet worden gemaakt, controleer de template.",
  "label.perHour": "per uur",
  "label.perDay": "per dag",
  "label.teams": "Teams",
  "label.teamWithNumber": "Team {number}",
  "label.materials": "Materialen",
  "label.emailWasNotReceived": "Email {emailType} was niet aangekomen",
  "label.acceptGdpr": "Ik ga akkoord dat {tenantName} mijn gegevens verwerkt.",
  "label.compensationRule": "Vergoedingsregel",
  "label.compensations": "Vergoedingen",
  "label.allowances": "Toeslagen",
  "label.youHaveToAcceptGdpr": "Wij kunnen uw offerte acceptatie niet verwerken als u niet met AVG akkoord gaat.",
  "label.replaceByTeamId": "Vervangende team",
  "label.addableEmployees": "{numberOfEmployees, plural,\n    =1 {# medewerker}\n    other {# medewerkers}\n}",
  "label.responsibilities": "Verantwoordelijkheden",
  "label.responsibility": "verantwoordelijkheid",
  "label.noInvoicingAddress": "Er is geen factuuradres geselecteerd",
  "label.noQuotationAddress": "Er is geen offerteadres geselecteerd",
  "label.cantRemoveCustomersWithStorages": "Er is nog opslag gekoppeld aan de klant, hierdoor kan je deze niet verwijderen.",
  "label.relationToMergeInto": "Klant samenvoegen met",
  "label.lineType": "Op de factuur",
  "label.useBillingMoment": "Gebruik factuurmoment",
  "label.payAfterFinalizingEvent": "Klant laten betalen tijdens afronden klus",
  "label.payAfterEvent": "Direct betalen na de afspraak",
  "label.useQuoteMoment": "Gebruik aangepaste offerte",
  "label.sendInvoiceReminders": "Wil je herinneringen versturen?",
  "label.sendInvoiceReminders.enabled": "Factuur herinneringen versturen",
  "label.sendInvoiceReminders.disabled": "Geen herinneringen versturen",
  "label.billStorageInAdvance": "Opslag vooraf of achter factureren?",
  "label.invoicePerVolume": "Factureer per volume",
  "label.invoicePerVolumeHelp": "Door aan te vinken worden gebruiksperioden per volume automatisch berekend en gefactureerd.",
  "label.setVolumeToQuantity": "Zet volume op aantal",
  "label.setVolumeToQuantityHelp": "Vink aan om het volume automatisch als aantal in te stellen, niet als regelprijs.",
  "label.pricePerVolume": "Prijs per volume",
  "label.invoiceStoragesDaysBefore": "Hoeveel dagen van te voren factuur klaar zetten?",
  "label.registerMileageReport": "Kilometerstand doorgeven",
  "label.inAdvance": "Vooraf",
  "label.afterwards": "Achteraf",
  "label.myself": "Mijzelf",
  "label.assignedTo": "Toegewezen aan",
  "label.copy": "Kopiëren",
  "label.noEmployee": "Dit account is geen actieve medewerker.",
  "label.noBccEmailAddresses": "Er zijn nog geen BCC email adressen",
  "label.sameAsFromAddress": "Kopieer van laadadres",
  "label.whichAddress": "Welk adres?",
  "label.sameAsLiftAddress": "Kopieer van lift adres",
  "label.changeToToAddressAfterMoving": "Verander naar losadres na verhuizing",
  "label.storageTypeWithQuantity": "{quantity}x {storageType}",
  "label.pricePerStoragePerPeriod": "{price} per periode per {storageType}",
  "label.pricePerPeriod": "Prijs per {period}",
  "label.pricePerPeriodPerVolume": "Prijs per {period} per volume",
  "label.theAppraiserRecommends": "de taxateur raad {recommends} aan",
  "label.latestMileageReport": "Laatste kilometerstand",
  "label.cubicMeterNeedsToBeStoredFor": "Er moet {cubicMeter} worden opgeslagen voor {customerName}.",
  "label.areYouSureYouWantToDisconnectStorageFromThisProject.title": "Opslag loskoppelen",
  "label.areYouSureYouWantToDisconnectStorageFromThisProject.content": "Weet je zeker dat je de opslag {storageCode} wilt loskoppelen van dit project?",
  "label.onTheInvoice": "Op de factuur",
  "label.percentage": "Percentage",
  "label.fixedAmount": "Vast bedrag",
  "label.billingMomentIsNotUsed": "Dit facturatiemoment wordt niet gebruikt",
  "label.quoteMomentIsNotUsed": "De standaard offerte wordt gebruikt",
  "label.thereIsNoInvoiceForThisBillingMoment": "Er is nog geen factuur gemaakt voor dit facturatiemoment",
  "label.selectABillingMoment": "Selecteer een factuurmoment",
  "label.billingMomentLinesToInvoice": "Facturatieregels",
  "label.uploadVehicleImage": "Foto van voertuig uploaden",
  "label.uploadEmployeeImage": "Foto voor medewerker uploaden",
  "label.projectHours": "Uren op een klus",
  "label.internalHours": "Interne uren zoals kantoorwerkzaamheden",
  "label.nonWorkingHours": "Afwezige uren",
  "label.entryType": "Type uren",
  "label.comment": "Opmerking",
  "label.until": "tot",
  "label.at": "om",
  "label.commentedAtBy": "{time} door {name}",
  "label.ever": "Ooit",
  "label.snooze": "Snooze",
  "label.done": "Gedaan",
  "label.youHaveNotFinishedAnyTasksToday": "Je hebt vandaag nog geen taken afgerond.",
  "label.youHaveNoTasks": "Er zijn nog geen taken voor je.",
  "label.passwordWasLastChangedXAgo": "Je hebt je wachtwoord voor het laatst {ago} bijgewerkt",
  "label.profile": "Profiel",
  "label.currentPassword": "Huidige wachtwoord",
  "label.newPassword": "Nieuw wachtwoord",
  "label.repeatPassword": "Bevestig nieuw wachtwoord",
  "label.logout": "Uitloggen",
  "label.mutations": "Mutaties",
  "label.contraGeneralLedger": "Uitsplitsingsrekening",
  "label.integrationIsNotSetUpYet": "De koppeling is nog niet correct ingesteld.",
  "label.syncedInvoices": "Totaal",
  "label.verifiedInvoices": "Geverifieerd",
  "label.unverifiedInvoices": "Problemen",
  "label.verified": "Geverifieerd",
  "button.verifyData": "Start verificatie",
  "label.lastVerifySyncDate": "Laatste verificatie uitgevoerd op",
  "label.notFoundInvoices": "Niet gevonden",
  "label.attentionNeeded": "Aandacht nodig",
  "label.allData": "Alle data",
  "label.verifyData": "Gegevensvalidatie",
  "label.noMappingMade": "Er is nog niks gekoppeld",
  "label.noRowsAddedYet": "Er zijn nog geen regels toegevoegd",
  "label.thisField": "Dit veld",
  "label.leadingNumber": "Debiteuren voorloopnummer",
  "label.isYourIntegrationNotAvailableYet": "Staat de koppeling waar jij\nnaar op zoek bent er niet bij?",
  "label.externalVatCode": "BTW Code boekhoudpakket",
  "label.identityType": "Type identiteit",
  "label.enabled": "Ingeschakeld",
  "label.disabled": "Uitgeschakeld",
  "label.youCanOnlySyncOnceAWeek": "Je kunt slechts één keer per week synchroniseren",
  "label.verifyExternalInvoices": "Controleer externe facturen",
  "label.invoicesAreStillLoading": "De facturen zijn nog steeds aan het laden",
  "label.createTaskAfter": "Creëer taak na",
  "label.settings": "Instellingen",
  "label.messageTemplates": "Chatberichtsjablonen",
  "label.profileId": "Profiel",
  "label.salesJournal": "Verkoopdagboek",
  "label.generalLedger": "Grootboekrekening",
  "label.defaultGeneralLedger": "Standaard grootboekrekening",
  "label.discountGeneralLedger": "Korting grootboekrekening",
  "label.generalLedgers": "Grootboekrekeningen",
  "label.useInvoiceNumberAsTransactionNumber": "Factuurnummer als boekstuknummer insturen",
  "label.costCenter": "Kostenplaats",
  "label.costCenters": "Kostenplaatsen",
  "label.vatCodes": "BTW Codes",
  "label.generalLedgerMappingType": "Grootboeken koppelen",
  "label.costCenterMappingType": "Kostenplaatsen koppelen",
  "label.testMode": "Test modus",
  "label.recipientType": "Type ontvanger",
  "label.replyEmailAddress": "Antwoord emailadres",
  "label.bccEmailAddresses": "BCC Email adressen",
  "label.billingMomentType": "Facturatie moment",
  "label.emailType": "Type email",
  "label.basedUpon": "Gebaseerd op",
  "label.reversedVat": "BTW verlegd",
  "label.vatNotReversed": "BTW niet verlegd",
  "label.fromLanguage": "Van taal",
  "label.newLanguage": "Naar taal",
  "label.totalInBas": "Totaal in Bas",
  "label.amountInExternalPackage": "Bedrag in extern pakket",
  "label.availableLanguages": "Beschikbaar in",
  "label.languages": "Talen",
  "label.warningRemoveLanguages": "Let op! Als je een taal weghaald verdwijnen de vertalingen, dit kan niet worden teruggedraait.",
  "label.templateType": "Type template",
  "label.contractTemplate": "Contract template",
  "label.signingMoments": "Tekenmomenten",
  "label.whatsAppMessageTemplatesExplanation": "Vooraf goedgekeurde berichtsjablonen zijn vereist door WhatsApp om gesprekken te starten of voort te zetten 24 uur na het laatste antwoord van de gebruiker.\n\nOok kunnen deze sjablonen ingesteld worden voor automatische berichten richting de klant.",
  "label.manageMessageTemplates": "Beheer chatberichtsjablonen",
  "label.manageMessageTemplatesExplain": "Je kunt berichtsjablonen voor je WhatsApp Business-account maken en beheren vanuit de Business Manager.",
  "label.selectATemplate": "Selecteer een template",
  "label.pleaseSelectALanguage": "Selecteer een taal",
  "label.content": "Inhoud",
  "label.whatsAppParticipant": "WhatsApp",
  "label.whatsAppBusinessParticipant": "Eigen WhatsApp nummer",
  "label.noSigningMoments": "Er zijn nog geen tekenmomenten ingesteld",
  "label.forWhatEventTypesDoYouWantSignatures": "Voor welke type afspraken moet er worden getekend?",
  "label.howShouldThereBeSigned": "Hoe moet er worden getekend",
  "label.companyInformation": "Bedrijfsgegevens",
  "label.identities": "Identiteiten",
  "label.financialInformation": "Financiële gegevens",
  "label.packages": "Pakketten",
  "label.documentTemplates": "Document templates",
  "label.integrations": "Koppelingen",
  "label.customerNotifications": "Klant berichtgeving",
  "label.features": "Features",
  "label.messages": "Berichten",
  "label.email": "Email",
  "label.newsMessages": "Nieuwsberichten",
  "label.certificate": "Certificaat",
  "label.yesterday": "Gisteren",
  "label.error": "Foutmelding",
  "label.unknownError": "Onbekende foutmelding",
  "label.preferredMovingDate": "Voorkeursdatum verhuizing",
  "label.certificates": "Certificaten",
  "label.noEvent": "Geen afspraak",
  "label.noRelation": "Geen klant",
  "label.dateOfBirth": "Geboortedatum",
  "label.driversLicense": "Rijbewijs",
  "label.administration": "Administratie",
  "label.purchaseCosts": "Inkoopkosten",
  "label.defaultAdministration": "Standaard administratie",
  "label.fuelCard": "Tankpas",
  "label.orderDate": "Besteldatum",
  "label.receivedAt": "Ontvangen op",
  "label.somethingWentWrongDuringSaving": "Er ging iets fout bij het opslaan",
  "label.timeOffInformationForDate": "Verlof/ziekte informatie voor {date}",
  "label.noOverlappingTimeOffRegistrations": "Goed nieuws, er zijn geen overlappende verlof aanvragen.",
  "label.daysOff": "Vrije dagen",
  "label.timeOffRegistrationDetails": "Verlof aanvraag details",
  "label.changedTimeOffRegistration": "Verlof aanvraag gewijzigd",
  "label.timeOffRegistrationChangedDescription": "De verlof aanvraag is gewijzigd, je ontvangt een notificatie zodra deze is goedgekeurd of afgekeurd.",
  "label.cancelTimeOffRegistration": "Verlof aanvraag annuleren",
  "label.areYouSureYouWantToCancelTimeOffRegistration": "Weet je zeker dat je de verlof aanvraag wilt annuleren?",
  "label.timeOffRegistrationCancelled": "Verlof aanvraag geannuleerd",
  "label.timeOffRegistrationCancelledDescription": "De verlof aanvraag is geannuleerd.",
  "label.somethingWentWrongDuringDownloadingOfPdf": "Er ging iets fout bij het downloaden van het PDF bestand, waarschijnlijk een fout in de template.",
  "label.somethingWentWrongDuringDownloadingOfPdfWithMessage": "Er ging iets fout bij het downloaden van het PDF bestand, waarschijnlijk een fout in de template. Foutmelding: {message}",
  "label.savedSuccessfully": "Gegevens zijn succesvol opgeslagen",
  "label.yourPasscodeIs": "Je verificatiecode is {passcode}",
  "label.contractIsSuccessfullySigned": "Je contract is succesvol getekend, het kan een paar minuten duren voordat je omgeving is geactiveerd.",
  "label.signingIsAborted": "Het tekenen is afgebroken",
  "label.bulkAdd": "Bulk toevoegen",
  "label.downloadExampleFile": "Download voorbeeld bestand",
  "label.addSingle": "Enkele toevoegen",
  "label.changeTimeOffRegistration": "Verlof aanvraag wijzigen",
  "label.purchasePrice": "Inkoopprijs",
  "label.exactOnlineOverrideReportingPeriod": "Wil Exact Online rapportage periode overschrijven?",
  "label.validFrom": "Geldig vanaf",
  "label.validUntil": "Geldig tot",
  "label.percentageFull": "Percentage gevuld",
  "label.prepareTask": "Taak klaarzetten",
  "label.what": "Wat",
  "label.licensePlate": "Kenteken",
  "label.leasingCompany": "Leasemaatschappij",
  "label.vinNumber": "VIN",
  "label.dealer": "Dealer",
  "label.reportingYear": "Rapportage jaar",
  "label.reportingPeriod": "Rapportage periode",
  "label.publishedAt": "gepubliceerd op {date}",
  "label.publishOn": "Publiceer op",
  "label.isNotPublishedYet": "Nog niet gepubliceerd",
  "label.isNotLiveYet": "Nog niet live gezet",
  "label.newNewsItemTitle": "Nieuw nieuwsbericht",
  "label.readBy": "Gelezen door",
  "label.unfinishedItems": "Je hebt nog open tijdsregistraties op: {dates}",
  "label.notReadYet": "Nog niet gelezen",
  "label.noStoragesFound": "Er is geen opslag gevonden",
  "label.model": "Model",
  "label.extra": "Extra",
  "label.event": "Afspraak",
  "label.invoicing": "Facturatie",
  "label.startDate": "Startdatum",
  "label.endDate": "Einddatum",
  "label.lastInvoicedDate": "Laatste gefactuurde datum",
  "label.invoicingEndDate": "Laatste dag om te factureren",
  "label.storageCode": "Opslagnummer",
  "label.sealNumber": "Zegel",
  "label.notSealed": "Niet verzegeld",
  "label.cubicMeterGreaterThen": "Minimale volume",
  "label.relation": "Relatie",
  "label.isRecurring": "Herhalende afspraak",
  "label.repeat": "Herhaal",
  "label.repeats": "Herhaalt",
  "label.repeatEvery": "Herhaal elke",
  "label.repeatFrom": "Herhaal vanaf",
  "label.repeatUntil": "Herhaal tot",
  "label.repeatCount": "Herhaal aantal keer",
  "label.times": "keer",
  "label.recurringInformation": "Herhalende afspraak",
  "label.recurringEvent": "Herhalende afspraak",
  "label.recurringEvents": "Herhalende afspraken",
  "label.thisWeek": "Deze week",
  "label.futureEvents": "Toekomstige afspraken",
  "label.pastEvents": "Afgelopen afspraken",
  "label.overridesDay": "Overschreven dag van herhalende afspraak",
  "label.eventInformation": "Afspraak informatie",
  "label.untilType": "Tot",
  "label.every": "Elke",
  "label.storageStatus": "Status",
  "label.invoicePeriod": "Facturatieperiode",
  "label.normal": "Normaal",
  "label.attention": "Attentie",
  "label.vatCode": "Btw code",
  "label.subject": "Onderwerp",
  "label.vatCalculation": "Btw berekening",
  "label.used": "In gebruik",
  "label.free": "Vrij",
  "label.markCreditInvoiceAsPayment": "Verwerk creditfactuur als betaling",
  "label.amount": "Bedrag",
  "label.paymentAmount": "Bedrag",
  "label.payment": "Betaling",
  "label.paymentQrCode": "QR Code voor de klant",
  "label.extraEmailAddresses": "Extra emailadressen",
  "label.loading": "Laden...",
  "label.freeTextFields": "Vrije tekstvelden",
  "label.noFreeTextFieldsUsed": "Er zijn geen vrije tekstvelden gebruikt",
  "label.noFreeTextFieldsAvailable": "Er zijn geen vrije tekstvelden beschikbaar",
  "label.freeTextFieldIsNotAvailable": "Let op, dit veld wordt niet gebruikt in het template en is dus niet zichtbaar.",
  "label.attachments": "Bijlagen",
  "label.noAttachmentsAttached": "Er zijn geen bijlagen bijgevoegd",
  "label.customerNumber": "Klantnummer",
  "label.price": "Prijs",
  "label.team": "Team",
  "label.movingJobAddressType": "Laden / lossen",
  "label.addressType": "Type adres",
  "label.contactPerson": "Contactpersoon",
  "label.plannedMaterials": "Materiaal",
  "label.addresses": "Adressen",
  "label.contactInformation": "Contactgegevens",
  "label.signerInformation": "Handtekening informatie",
  "label.employeeData": "Gegevens",
  "label.number": "Nummer",
  "label.address": "Adres",
  "label.paymentMethod": "Betaalmethode",
  "label.rooms": "Kamers",
  "label.services": "Diensten",
  "label.emailAddress": "E-mailadres",
  "label.signature": "Handtekening",
  "label.preview": "Voorbeeld",
  "label.environmentCode": "Omgevingscode",
  "label.apiKey": "API key",
  "label.username": "Gebruikersnaam",
  "label.password": "Wachtwoord",
  "label.twoFactorCode": "Code",
  "label.somethingWentWrongDuringImport": "Er ging iets fout tijdens het importeren",
  "label.code": "Code",
  "label.thereAreXAmountOfWorkingDaysWithinThisPeriod": "Er zijn {amount} werkdagen binnen deze periode",
  "label.data": "Data",
  "label.doYouHaveDaysWhereTheStartOrEndTimeShouldDifferFromTheRest": "Heb je dagen waar de start- of eindtijd van de dag verschillend is van de rest? Bijvoorbeeld dat de persoon in de ochtend werkt en daarna met verlof gaat of ziek wordt? Selecteer dan de te overschrijven dagen hieronder.",
  "label.daysToOverride": "Dagen om te overschrijven",
  "label.expireInterval": "Verval periode",
  "label.expireIntervalHelp": "Je kan hier \"months\", \"weeks\" en \"days\" gebruiken. Voorbeeld: \"1 week 5 days\".",
  "label.date": "Datum",
  "label.overview": "Overzicht",
  "label.thisQuoteHasNoLines": "Let op! Deze offerte heeft geen regels.",
  "label.planning": "Planning",
  "label.by": "Door",
  "label.startsAt": "om {time}",
  "label.leaveAt": "vertrek om {time}",
  "label.scheduledAt": "Versturen op",
  "label.startedOn": "gestart om {time}",
  "label.newUpdate.title": "We hebben weer een nieuwe update uitgebracht!",
  "label.newUpdate.description": "Check hier wat we hebben aangepast",
  "label.activeIntegration": "koppeling actief",
  "label.sendWhatsAppMessageTemplate": "Verstuur WhatsApp berichtsjabloon",
  "label.connectToCustomer": "Koppel aan klant",
  "label.connectConversationToCustomer": "Gesprek koppelen aan een klant",
  "label.connectConversationToEmployee": "Gesprek koppelen aan een medewerker",
  "label.today": "Vandaag",
  "label.tomorrow": "Morgen",
  "label.needed": "Nodig",
  "label.contactPersons": "Contactpersonen",
  "label.contactPersonType": "Type contactpersoon",
  "label.reason": "Reden",
  "label.reaction": "Reactie",
  "label.startTime": "Starttijd",
  "label.startTimeAtCustomer": "Starttijd bij de klant",
  "label.endTimeAtCustomer": "Eindtijd bij de klant",
  "label.startWorkTime": "Starttijd van werkzaamheden",
  "label.endWorkTime": "Eindtijd van werkzaamheden",
  "label.endTime": "Eindtijd",
  "label.start": "Start",
  "label.end": "Eind",
  "label.selectedDate": "Geselecteerde datum",
  "label.recurringSchedule": "Herhalend schema",
  "label.plannedTime": "Geplande tijd",
  "label.realTime": "Werkelijke tijd",
  "label.thereAreNoAddresses": "Er zijn geen adressen gevonden",
  "label.projectTypes": "Type klussen",
  "label.mileageStart": "Start KM",
  "label.mileageEnd": "Eind KM",
  "label.totalDistance": "Totale afstand",
  "label.plannedStart": "Geplande start",
  "label.plannedEnd": "Geplande eind",
  "label.realStart": "Werkelijke start",
  "label.realEnd": "Werkelijke eind",
  "label.departureTime": "Vertrektijd",
  "label.notStarted": "Niet gestart",
  "label.overrideDepartureTime": "Vertrektijd overschrijven",
  "label.overriddenDepartureTime": "Overschreven vertrektijd",
  "label.realArrivalTime": "Werkelijke aankomsttijd",
  "label.plannedArrivalTime": "Geplande aankomsttijd",
  "label.realDepartureTime": "Werkelijke vertrektijd",
  "label.plannedDepartureTime": "Geplande vertrektijd",
  "label.workedHoursIncludingBreakTime": "Werktijd inclusief pauze",
  "label.numberOfEmployees": "Aantal medewerkers",
  "label.numberOfVehicles": "Aantal voertuigen",
  "label.approveAllHoursOfEvent": "Alle uren van deze afspraak goedkeuren",
  "label.estimatedTravelTimeIsAndDistance": "Geschatte reistijd is {time} en afstand is {distance}",
  "label.workStartedAt": "Werk begon om",
  "label.workEndedAt": "Werk eindigde om",
  "label.arrivalTime": "Aankomsttijd",
  "label.travelTime": "Reistijd",
  "label.eventHours": "Afspraak uren",
  "label.arrivalTimeWarehouse": "Aankomsttijd loods",
  "label.overrideArrivalTimeWarehouse": "Aankomsttijd loods overschrijven",
  "label.overriddenArrivalTimeWarehouse": "Overschreven aankomsttijd loods",
  "label.mileageFor": "Kilometerstand van {name}",
  "label.startMileage": "Start kilometerstand",
  "label.endMileage": "Eind kilometerstand",
  "label.plannedTimeWas": "De geplande tijd was: {time}",
  "label.finished": "Afgerond",
  "label.notFinished": "Niet afgerond",
  "label.newDate": "Nieuwe datum",
  "label.newStartTime": "Nieuwe starttijd",
  "label.overrideEvent": "Afspraak overschrijven",
  "label.openOverriddenEvent": "Open overschreven afspraak",
  "button.cancelRecurringEvent": "Herhalende afspraak annuleren",
  "button.forceVerifyInvoice": "Forceer verificatie",
  "label.foundInExternalPackage": "Gevonden in extern pakket",
  "label.newEndTime": "Nieuwe eindtijd",
  "label.rescheduled": "Verzet",
  "label.wasCustomerSatisfied": "Klant tevreden",
  "label.customerWasSatisfied": "Ja, de klant was tevreden",
  "label.customerNotWasSatisfied": "Nee, de klant was niet tevreden",
  "label.plannedHours": "Geplande uren",
  "label.entered": "Ingevoerd",
  "label.rejected": "Afgekeurd",
  "label.approved": "Goedgekeurd",
  "label.workedHours": "Gewerkte uren",
  "label.workedHoursAccordingToForeman": "Gewerkte uren volgens voorman",
  "label.includingTravelTimes": "Inclusief reistijd",
  "label.workedTime": "Daadwerkelijke gewerkt",
  "label.hoursToApprove": "Uren te goedkeuren",
  "label.totalHours": "Totale uren",
  "label.lastDataSync": "Opgehaald op",
  "label.verifiedAt": "Geverifieerd op",
  "label.totalBreakTime": "Totale pauze",
  "label.totalHoursOfEmployees": "Totale uren van medewerkers",
  "label.totalHoursOfVehicles": "Totale uren van voertuigen",
  "label.totalBreakTimeOfEmployees": "Totale pauze van medewerkers",
  "label.printer": "Printer",
  "label.printLabel": "Print label",
  "label.printStorageLabel": "Opslag label printen",
  "label.printLabelsForStoredItems": "Labels voor opgeslagen items printen",
  "label.invalidLabel": "Ongeldig label",
  "label.noPrintersFound": "Geen printers gevonden",
  "label.hours": "Uren",
  "label.minutes": "Minuten",
  "label.printedLabelSuccessfully": "Label succesvol geprint",
  "label.failedToPrintLabel": "Label printen mislukt",
  "label.hoursOfEmployees": "Uren van medewerkers",
  "label.hoursOfEvents": "Uren van afspraak",
  "label.hoursOfEmployeesExplained": "Uren die medewerkers hebben gewerkt",
  "label.hoursOfEventsExplained": "Uren van de afspraak keer het aantal medewerkers. \nLet op er wordt geen rekening gehouden met medewerkers die afwijkende uren hebben gemaakt!",
  "label.addTemporaryWorker": "Inhuurkracht toevoegen",
  "label.availableHours": "Beschikbare uren",
  "label.reservedHours": "Gereserveerde uren",
  "label.confirmedHours": "Bevestigde uren",
  "label.isPlanned": "ingezet",
  "label.notPlanned": "niet ingezet",
  "label.shortageHours": "Te kort aan uren",
  "label.overtime": "Overuren",
  "label.plannableHours": "Planbare uren",
  "label.approvedHours": "Geaccordeerde uren",
  "label.breakTime": "Pauze",
  "label.waitingHours": "Wachttijd",
  "label.inBeta": "Testfase",
  "label.zipCode": "Postcode",
  "label.streetName": "Straat",
  "label.city": "Stad",
  "label.houseNumber": "Huisnummer",
  "label.houseNumberAddition": "Toevoeging",
  "label.buildingType": "Type woning",
  "label.country": "Land",
  "label.floorWithValue": "{floor, selectordinal,\n    =0 {begane grond of 0}\n  =1 {#ste}\n    other {#de}\n} verdieping",
  "label.freeTextTitle": "{index, selectordinal,\n    =1 {#ste}\n    other {#de}\n} vrijetekstveld",
  "label.dayTitleFormat": "{weekday} {day, selectordinal,\n    =1 {#ste}\n    other {#de}\n}",
  "label.gender": "Geslacht",
  "label.salutation": "Aanhef",
  "label.firstName": "Voornaam",
  "label.middleName": "Tussenvoegsel",
  "label.lastName": "Achternaam",
  "label.phoneNumber": "Telefoon",
  "label.mobileNumber": "Mobiel",
  "label.customer": "Klant",
  "label.employee": "Medewerker",
  "label.notes": "Notities",
  "label.notesForEmployees": "Notities voor de medewerker",
  "label.quote": "Offerte",
  "label.customerNotes": "Notities voor de klant",
  "label.storageNotes": "Opslag notities",
  "label.pricingType": "Prijsafspraak",
  "label.packageId": "Pakket",
  "label.identityId": "Identiteit",
  "label.toBeProcessed": "Te verwerken",
  "label.noCertificatesFound": "Geen certificaten nog klaargezet.",
  "label.allCertificates": "Alle certificaten",
  "label.syncedAt": "Gesynchroniseerd op",
  "label.processedAt": "Verwerkt op",
  "label.failedAt": "Gefaald op",
  "label.approvedOn": "Goedgekeurd op",
  "label.approvedByName": "Goedgekeurd door {name}",
  "label.approvedBy": "Goedgekeurd door",
  "label.rejectedInformation": "Afgekeurd informatie",
  "label.rejectedBy": "Afgekeurd door",
  "label.rejectedOn": "Afgekeurd op",
  "label.originalHours": "Originele uren",
  "label.search": "Zoeken",
  "label.name": "Naam",
  "label.companyName": "Bedrijfsnaam",
  "label.cocNumber": "KVK nummer",
  "label.vatNumber": "BTW nummer",
  "label.rsinNumber": "RSIN nummer",
  "label.ibanNumber": "IBAN nummer",
  "label.numberOfOptions": "{numberOfOptions, plural,\n    =0 {geen opties}\n  =1 {# optie}\n    other {# opties}\n}",
  "label.results": "{numberOfResults, plural,\n    =0 {geen resultaten}\n  =1 {# resultaat}\n    other {# resultaten}\n}",
  "label.resultsBasedUpon": "{numberOfResults, plural,\n    =0 {geen resultaten}\n  =1 {# resultaat}\n    other {# resultaten}\n} op basis van {filters}",
  "label.globalFilter": "Zoekterm",
  "label.failedToUpload": "Er ging iets mis met het uploaden.",
  "label.fileTooBig": "Het bestand is te groot, er mag maximaal 45MB worden geupload.",
  "label.volume": "Inhoud",
  "label.servicesPossible": "Zijn de diensten mogelijk?",
  "label.yes": "Ja",
  "label.all": "Alle",
  "label.no": "Nee",
  "label.correct": "Klopt!",
  "label.comingSoon": "binnenkort beschikbaar",
  "label.disconnected": "verbinding verbroken",
  "label.unknown": "Onbekend",
  "label.addressIsUnknown": "Adres is onbekend",
  "label.unknownAddress": "Onbekend adres",
  "label.deleteAddress": "Verwijder adres",
  "label.deleteContactPerson": "Verwijder contactpersoon",
  "label.quoteIncludingVat": "Is de offerte inclusief of exclusief btw?",
  "label.warehouseId": "Loods",
  "label.storageLocationId": "Opslaglocatie",
  "label.templateId": "Financiële template",
  "label.expiresAt": "Verloopdatum",
  "label.empty": "Leeg",
  "label.full": "Vol",
  "label.shouldSendQuote": "Wil je de offerte meteen versturen?",
  "label.shouldSendQuote.yes": "Ja",
  "label.shouldSendQuote.no": "Nee, zet concept klaar",
  "label.customerSignsAtTheTable": "Wil je meteen met de klant tekenen?",
  "label.customerSignsAtTheTable.yes": "Ja, graag",
  "label.customerSignsAtTheTable.no": "Nee",
  "label.description": "Omschrijving",
  "label.details": "Details",
  "label.quantity": "Aantal",
  "label.vatCodeId": "BTW",
  "label.customerType": "Type klant",
  "label.pricePerUnit": "Eenheidsprijs",
  "label.subTotal": "Subtotaal",
  "label.total": "Totaal",
  "label.totalLeft": "Totaal over",
  "label.totalUsed": "Totaal gebruikt",
  "label.totalHoursBuildUp": "Totaal uren opbouw",
  "label.totalSick": "Totaal ziek",
  "label.floor": "Verdieping",
  "label.distance": "De afstand",
  "label.tasks": "Taken",
  "label.task": "Taak",
  "label.language": "Taal",
  "label.optional": "Optioneel",
  "label.optional.explained": "Met een optionele regel geef je de klant de mogelijkheid om zelf aan te geven of hij de regel wel of niet wil accepteren.",
  "label.hourlyRate.explained": "Een uurtarief regel komt in een aparte tabel te staan zonder totalen.",
  "label.movingBoxes": "Verhuisdozen",
  "label.newLeadInfo": "Informatie van nieuwe lead",
  "label.errors": "Fouten",
  "label.original": "Origineel",
  "label.uploadingFiles": "Bestanden worden geüpload",
  "label.failedFiles": "Mislukte bestanden",
  "label.uploadedFiles": "Geüploade bestanden",
  "label.mapped": "Gemapped",
  "label.included": "Opgenomen",
  "label.timeForTime": "Tijd voor tijd",
  "label.sick": "Ziek",
  "label.signedBy": "Getekend door {name}",
  "label.remarks": "Opmerkingen",
  "label.noRemarks": "Geen opmerkingen",
  "label.remarksOfCustomer": "Opmerkingen van de klant",
  "label.measurements": "Afmetingen",
  "label.measurementsInCm": "{length, number}cm x {width, number}cm x {height, number}cm",
  "label.vat": "BTW",
  "label.startTyping": "Begin met typen",
  "label.dropDocumentsOrClickToUpload": "Sleep documenten hierheen of klik om te uploaden",
  "label.resultsPerPage": "per pagina",
  "label.add": "Toevoegen",
  "label.barcode": "Barcode",
  "label.remove": "Verwijderen",
  "label.contentSection.add": "Toevoegen",
  "label.contentSection.edit": "Bewerken",
  "label.placeTrafficSign": "Borden plaatsen",
  "label.requestPermit": "Vergunning aanvragen",
  "label.retrieveBoxes": "Dozen ophalen",
  "label.deliverBoxes": "Dozen leveren",
  "label.boxes": "Dozen",
  "label.distanceFromCar": "Afstand naar de lift",
  "label.distanceToApartment": "Afstand naar het appartement",
  "label.hasElevator": "Is er een lift?",
  "label.createInvoice": "Factuur aanmaken",
  "label.rentalPrice": "Verhuurprijs",
  "label.sellingPrice": "Verkoopprijs",
  "label.quotationNumber": "Offertenummer",
  "label.invoiceNumber": "Factuurnummer",
  "label.invoicingPeriod": "Factuurperiode",
  "label.quoteDate": "Offertedatum",
  "label.sentAt": "Verstuurdatum",
  "label.acceptedAt": "Acceptatiedatum",
  "label.declinedAt": "Afgewezen op",
  "label.cancelledAt": "Annuleringsdatum",
  "label.declineReason": "Reden afwijzing",
  "label.openProjectRole": "Nog niet ingevuld",
  "label.role": "Rol",
  "label.roles": "Rollen",
  "label.searchPerson": "Zoek persoon",
  "label.searchMaterial": "Zoek materiaal",
  "label.project": "Klus",
  "label.projectType": "Type klus",
  "label.projectStatus": "Status",
  "label.quoteStatus": "Status",
  "label.invoiceStatus": "Status",
  "label.fileName": "Bestandsnaam",
  "label.type": "Type",
  "label.identity": "Identiteit",
  "label.communicationType": "Communicatiemiddel",
  "label.communicationDate": "Wanneer",
  "label.sender": "Afzender",
  "label.message": "Bericht",
  "label.createdAt": "Aangemaakt op",
  "label.none": "Geen",
  "label.externalId": "Externe ID",
  "label.oldVolume": "Oude inhoud",
  "label.newVolume": "Nieuwe inhoud",
  "label.changedByEmployeeId": "Gewijzigd door",
  "label.noConversationsFound": "Er zijn nog geen gesprekken.",
  "label.noNewsItemsFound": "Er zijn nog geen nieuwsberichten.",
  "label.noDocumentsFound": "Er zijn geen documenten gevonden, je kan hierboven een document uploaden.",
  "label.noHandlingCostsFound": "Er zijn nog geen handlingkosten toegevoegd, je kan hierboven kosten toevoegen.",
  "label.noCommunicationFound": "Er is geen communicatie gevonden, je kan hierboven een communicatie loggen.",
  "label.doYouWantToRemoveHandlingCosts": "Wil je de handlingkosten verwijderen?",
  "label.areYouSureYouWantToRemoveHandlingCosts": "Weet je zeker dat je de handlingkosten wilt verwijderen?",
  "label.logCommunication": "Communicatie loggen",
  "label.uploadDocument": "Document uploaden",
  "label.documentType": "Type document",
  "label.from": "Van",
  "label.to": "Naar",
  "label.status": "Status",
  "label.declarationStatus": "Status",
  "label.invoicedAt": "Factuurdatum",
  "label.invoiced": "Gefactureerd",
  "label.openAmount": "Openstaand",
  "label.noInvoicesFound": "Er zijn geen facturen gevonden",
  "label.noQuotesFound": "Er zijn geen offertes gevonden",
  "label.noProjectsFound": "Er zijn geen klussen gevonden",
  "label.forIdentity": "Voor identiteit",
  "label.noOutstandingStockFoundWithCustomer": "Er staat niks meer bij {name}",
  "label.noOutstandingStockFound": "Er is geen uitstaande voorraad.",
  "label.noOpenOrdersFound": "Er is geen openstaande orders.",
  "label.supplier": "Leverancier",
  "label.suppliers": "Leveranciers",
  "label.openOrderQuantity": "Aantal openstaand",
  "label.outstandingStock": "Uit",
  "label.currentStock": "Voorraad",
  "label.stockInStorage": "Opslag",
  "label.lastMutationDate": "Laatste wijziging",
  "label.noTimelineEvents": "Er hebben geen activiteiten plaatsgevonden",
  "label.template": "Template",
  "label.quotes": "Offertes",
  "label.invoices": "Facturen",
  "label.invoiceAddress": "Factuuradres",
  "label.quoteAddress": "Offerteadres",
  "label.projectContactPerson": "Projectcontactpersoon",
  "label.invoiceContactPerson": "Factuurcontactpersoon",
  "label.quoteContactPerson": "Offertecontactpersoon",
  "label.yesAllWithTheSamePeriod": "Ja, met dezelfde periode",
  "label.customerInformation": "Klant informatie",
  "label.customerName": "Klant",
  "label.notSentYet": "Nog niet verzonden",
  "label.reference": "Referentie",
  "label.markedAsIrrecoverableAt": "Oninbaar op",
  "label.invoiceScheduledAt": "Ingepland op",
  "label.expectedRevenue": "Verwachte omzet",
  "label.expectedCosts": "Verwachte kosten",
  "label.expectedProfit": "Verwachte winst",
  "label.grossRevenue": "Bruto omzet",
  "label.grossCosts": "Brutokosten",
  "label.grossProfit": "Brutowinst",
  "label.paidAt": "Betaald op",
  "label.paymentDate": "Betaaldatum",
  "label.better": "beter",
  "label.worse": "slechter",
  "label.same": "hetzelfde",
  "label.storage": "Opslag",
  "label.employees": "Medewerkers",
  "label.movingTrucks": "Verhuiswagens",
  "label.movingLifts": "Verhuisliften",
  "label.material": "Materiaal",
  "label.storageCodeShort": "Nr",
  "label.selfOwnedStorageType": "Eigen {storageType}",
  "label.rentedStorageType": "Gehuurde {storageType}",
  "label.sealedBySealNumber": "Verzegeld met zegelnummer: {sealNumber}",
  "label.margin": "Marge",
  "label.noNotes": "Geen notities",
  "label.notInvoicedYet": "Nog niet gefactureerd",
  "label.includingOrExcludingVat": "Is de prijs inclusief of exclusief Btw?",
  "label.ignoreZeroQuantityLines": "Negeer regels met een aantal van 0",
  "label.isPerEvent": "Is het bedrag per afspraak?",
  "label.isPerEvent.explained": "Als het bedrag per afspraak is, dan wordt de regel voor elke afspraak los toegevoegd aan de factuur.",
  "label.excludingVat": "Exclusief Btw",
  "label.includingVat": "Inclusief Btw",
  "label.isStorageSelfOwned": "Is de opslag in in eigen bezit?",
  "label.selfOwned": "In eigen bezit",
  "label.rented": "Gehuurd",
  "label.inventoryList": "Inboedellijst",
  "label.discardChanges": "Wijzigingen negeren",
  "label.delete": "Verwijderen",
  "label.itemCode": "Artikelcode",
  "label.externalItemCode": "Externe artikelcode",
  "label.itemLocation": "Locatie",
  "label.loadedOn": "Geladen op",
  "label.loadedOnDate": "Geladen op {date}",
  "label.unloadedOnDate": "Gelost op {date}",
  "label.notLoaded": "Niet geladen",
  "label.unloadedOn": "Gelost op",
  "label.notUnloaded": "Niet gelost",
  "label.loadedByEmployeeId": "Geladen door medewerker",
  "label.unloadedByEmployeeId": "Gelost door medewerker",
  "label.loadedByCustomer": "Geladen door klant",
  "label.newCustomer": "Nieuwe klant",
  "label.existingCustomer": "Bestaande klant",
  "label.addNewSupplier": "Nieuwe leverancier",
  "label.useExistingSupplier": "Bestaande leverancier",
  "label.noLocation": "Geen locatie",
  "label.website": "Website",
  "label.addNewOrExistingSupplier": "Nieuwe of bestaande leverancier",
  "label.length": "Lengte",
  "label.width": "Breedte",
  "label.height": "Hoogte",
  "label.noProject": "Geen klus",
  "label.vehicles": "Voertuigen",
  "label.vehicle": "Voertuig",
  "label.vehicleType": "Type voertuig",
  "label.moving-truck": "Vrachtwagen",
  "label.moving-lift": "Verhuislift",
  "label.lines": "Regels",
  "label.propulsionType": "Brandstof",
  "label.transmissionType": "Transmissie",
  "label.registeredAt": "Bouwjaar",
  "label.purchasedAt": "Aankoop",
  "label.tailGateLength": "Laadklep lengte",
  "label.turnoverGroups": "Omzetgroepen",
  "label.floors": "Verdiepingen",
  "label.hasTowBar": "Trekhaak",
  "label.towWeight": "Trekgewicht",
  "label.driversLicenseNeeded": "Type rijbewijs nodig",
  "label.doesTheVehicleHaveATowBar": "Heeft het voertuig een trekhaak?",
  "label.noMaintenanceDetails": "Er zijn geen onderhoud gegevens bekend",
  "label.noFuelCardDetails": "Er is geen tankpas bekend",
  "label.motNeededAt": "Volgende keuring",
  "label.maintenanceNeededAt": "Onderhoud nodig",
  "label.maintenance": "Onderhoud",
  "label.cardNumber": "Kaartnummer",
  "label.cvc": "CVC",
  "label.pinCode": "Pincode",
  "label.skills": "Vaardigheden",
  "label.skill": "Vaardigheid",
  "label.employeeNumber": "Personeelsnummer",
  "label.employmentStartedBefore": "In dienst voor",
  "label.employmentEndedAfter": "Uit dienst na",
  "label.employmentStartDate": "In dienst",
  "label.employmentEndDate": "Uit dienst",
  "label.notEmployed": "Niet in dienst",
  "label.noProbation": "Geen proeftijd",
  "label.probationDate": "Proeftijd",
  "label.indefinite": "Onbepaald",
  "label.wage": "Loon",
  "label.hourlyWage": "Uurloon",
  "label.contract": "Contract",
  "label.contracts": "Contracten",
  "label.days": "Dagen",
  "label.daysPerWeek": "Dagen per week",
  "label.hoursPerWeek": "Uren per week",
  "label.people": "Mensen",
  "label.notEnough": "Te kort",
  "label.overworked": "Overuren",
  "label.totalEntered": "Totaal doorgegeven",
  "label.cars": "Auto’s",
  "label.elevators": "Liften",
  "label.eventType": "Type afspraak",
  "label.availabilityBasedUpon": "Toon beschikbaarheid op basis van",
  "label.month": "Maand",
  "label.monthly": "Maandelijks",
  "label.externalEmployeeInformation": "Externe medewerker informatie",
  "label.week": "Week",
  "label.day": "Dag",
  "label.when": "Wanneer",
  "label.where": "Waar",
  "label.planIntake": "Intake plannen",
  "label.nothingHasBeenPlannedYet": "Er is nog niks gepland",
  "label.unknownCustomer": "Klant onbekend",
  "label.howManyOfRoleDoYouWantToPlan": "Hoeveel {role} zijn er nodig?",
  "label.fromWhatTimeWillNewEventsBeAvailable": "Vanaf welk uur zal er nieuwe afspraken zichtbaar zijn?",
  "label.noProjectYouCanCreateOne": "Er zijn nog geen klussen, je kan hierboven klussen aanmaken.",
  "label.noResultsForFilter": "Er zijn geen resultaten gevonden met de huidige filters.",
  "label.closeWithDirtyFields": "Weet je zeker dat je het formulier wilt sluiten?",
  "label.closeWithoutEvents": "Let op er zijn geen afspraken gepland, wil je doorgaan?",
  "label.period": "Periode",
  "label.cancelProject": "Klus annuleren en afspraken verwijderen",
  "label.selectedInvoicesOnPage": "{numberOfInvoices} facturen op de pagina geselecteerd.",
  "label.selectedInvoicesBasedOnFilter": "Alle {numberOfInvoices} facturen in je huidige filter geselecteerd.",
  "label.multipleInvoicesSelected": "Meerdere facturen geselecteerd",
  "label.xInvoicesSelected": "{numberOfInvoices} facturen geselecteerd",
  "label.actionAvailable": "Actie beschikbaar",
  "label.invoiceIsAlreadyFinalized": "Factuur is al afgerond",
  "label.invoiceIsNotFinalized": "Factuur is nog niet afgerond",
  "label.invoiceIsNotComplete": "Factuur is nog niet compleet",
  "label.reportDamage": "Schade melden",
  "label.wasTheDamageAlreadyThere": "Was de schade al bekend?",
  "label.coverSheets": "Voor/achterbladen",
  "label.coverSheetType": "Type blad",
  "label.noCoverSheetsAttached": "Er zijn geen voor/achterbladen toegevoegd",
  "label.areYouSureYouWantToRemoveNationalHoliday.title": "Weet je zeker dat je deze feestdag wilt verwijderen?",
  "label.areYouSureYouWantToRemoveNationalHoliday.content": "Weet je zeker dat je de feestdag {name} wilt verwijderen? Dit kan niet worden teruggedraait.",
  "label.projects": "Klussen",
  "menu.dashboard": "Start",
  "menu.projects": "Dossiers",
  "menu.customers": "Klanten",
  "menu.planning": "Planning",
  "menu.financial": "Financiën",
  "menu.wms": "WMS",
  "menu.hrm": "HRM",
  "menu.communication": "Communicatie",
  "menu.settings": "Instellingen",
  "menu.cla-allowances-and-compensations": "CAO toeslagen en vergoedingen",
  "button.thisPeriod": "Deze periode",
  "button.lastPeriod": "Vorige periode",
  "identityTypes.trade-name": "Handelsnaam",
  "identityTypes.separate-company": "Los bedrijf",
  "identityTypes.main-company": "Hoofdbedrijf",
  "signingTypes.sign-on-finalize": "Optioneel tekeken bij afronden",
  "signingTypes.required-sign-on-finalize": "Verplicht tekenen bij afronden",
  "howDidYouFindUsOptions.folder": "Folder/Drukwerk",
  "howDidYouFindUsOptions.friends_or_family": "Vrienden/Familie",
  "howDidYouFindUsOptions.real_estate_agent": "Via makelaar",
  "howDidYouFindUsOptions.already_moved_earlier": "Eerder verhuisd",
  "howDidYouFindUsOptions.google": "Google",
  "howDidYouFindUsOptions.social_media": "Social Media",
  "howDidYouFindUsOptions.lead_website": "Lead website",
  "howDidYouFindUsOptions.other": "Anders",
  "howDidYouFindUsOptions.unknown": "Onbekend",
  "propulsionTypes.gasoline": "Benzine",
  "propulsionTypes.diesel": "Diesel",
  "propulsionTypes.electric": "Elektrisch",
  "propulsionTypes.hydrogen": "Waterstof",
  "propulsionTypes.unknown": "Onbekend",
  "responsibilities.hr": "HR",
  "responsibilities.planning": "Planning",
  "responsibilities.administration": "Administratie",
  "entryTypes.project": "Klus",
  "entryTypes.warehouse-work": "Loodswerkzaamheden",
  "entryTypes.office-work": "Kantoorwerkzaamheden",
  "entryTypes.holiday": "Vrij",
  "entryTypes.sick": "Ziekte",
  "projectTypes.moving-job": "Verhuizing",
  "projectTypes.moving-job.forExisting": "Verhuizing voor bestaande klant",
  "projectTypes.transport-job": "Transport",
  "projectTypes.moving-lift-job": "Verhuislift klus",
  "projectTypes.manual": "Handmatig / zonder klus",
  "transmissionTypes.automatic": "Automaat",
  "transmissionTypes.manual": "Handgeschakeld",
  "transmissionTypes.unknown": "Onbekend",
  "declineReasons.unknown": "Geen reden",
  "declineReasons.change-of-quote": "Aanpassing aan offerte",
  "declineReasons.too-expensive": "Te duur",
  "declineReasons.date-not-possible": "Datum komt niet uit",
  "declineReasons.will-move-by-themselves": "Gaat het zelf regelen",
  "declineReasons.choose-competitor": "Concurrent gekozen",
  "declineReasons.no-response": "Geen reactie",
  "damageInflictedOnTypes.unknown": "Onbekend",
  "damageInflictedOnTypes.building": "Gebouw",
  "damageInflictedOnTypes.furniture": "Meubilair",
  "collectiveLabourAgreementsWhichDays.mo": "Ma",
  "collectiveLabourAgreementsWhichDays.tu": "Di",
  "collectiveLabourAgreementsWhichDays.we": "Wo",
  "collectiveLabourAgreementsWhichDays.th": "Do",
  "collectiveLabourAgreementsWhichDays.fr": "Vr",
  "collectiveLabourAgreementsWhichDays.sa": "Za",
  "collectiveLabourAgreementsWhichDays.su": "Zo",
  "collectiveLabourAgreementsRuleTypes.overtime": "Overuren",
  "collectiveLabourAgreementsRuleTypes.publicHoliday": "Feestdag",
  "collectiveLabourAgreementsRuleTypes.nightWork": "Nachtwerk",
  "collectiveLabourAgreementsCalculatePer.day": "Dag",
  "collectiveLabourAgreementsCalculatePer.hour": "Uur",
  "collectiveLabourAgreementsCalculatePer.week": "Week",
  "contractTypes.unknown": "Onbekend",
  "contractTypes.fixed-amount-of-hours": "Vaste uren",
  "contractTypes.variable-amount-of-hours": "Variable uren",
  "contractTypes.external": "Inhuur",
  "contractTypes.payroll": "Payroll",
  "contractTypes.temporary-worker": "Tijdelijk",
  "planning.issues.notWorking": "Is niet aan het werk",
  "planning.issues.isFull": "Zit al vol voor deze dag",
  "planning.issues.isPlanned": "Is al ingepland",
  "planning.issues.isAlreadyPlanned": "Is al ingepland",
  "planning.issues.missingQualifications": "Heeft niet de juiste vaardigheden",
  "planning.issues.noContract": "Let op geen contract!",
  "documentTypes.quote": "Offerte",
  "documentTypes.accepted-quote": "Getekende offerte",
  "documentTypes.signed-work-order": "Getekende werkbon",
  "documentTypes.invoice": "Factuur",
  "documentTypes.custom": "Overig",
  "documentTypes.contract": "Contract",
  "documentTypes.certificate": "Certificaat",
  "communicationTypes.call": "Telefonisch",
  "communicationTypes.whatsapp": "Whatsapp",
  "communicationTypes.email": "E-Mail",
  "communicationTypes.sms": "SMS",
  "invoicePeriods.daily": "Dagelijks",
  "invoicePeriods.weekly": "Wekelijks",
  "invoicePeriods.quarterly": "Per kwartaal",
  "invoicePeriods.monthly": "Maandelijks",
  "invoicePeriods.yearly": "Jaarlijks",
  "invoicePeriods.daily.priceName": "dag",
  "invoicePeriods.weekly.priceName": "week",
  "invoicePeriods.quarterly.priceName": "kwartaal",
  "invoicePeriods.monthly.priceName": "maand",
  "invoicePeriods.yearly.priceName": "jaar",
  "invoicePeriods.null": "Geen facturatie",
  "volumeTypes.cubic-meter": "Kubieke meter",
  "volumeTypes.square-meter": "Vierkante meter",
  "whatsAppAccountReviewStatus.APPROVED": "Goedgekeurd",
  "whatsAppAccountReviewStatus.PENDING": "In afwachting",
  "whatsAppAccountReviewStatus.REJECTED": "Afgekeurd",
  "whatsAppPhoneNumberStatus.VERIFIED": "Geverifieerd",
  "whatsAppPhoneNumberStatus.NOT_VERIFIED": "Niet geverifieerd",
  "whatsAppPhoneNumberNameStatus.APPROVED": "Goedgekeurd",
  "whatsAppPhoneNumberNameStatus.DECLINED": "Afgekeurd",
  "whatsAppPhoneNumberNameStatus.EXPIRED": "Verlopen",
  "whatsAppPhoneNumberNameStatus.PENDING_REVIEW": "In review",
  "whatsAppPhoneNumberNameStatus.NONE": "Geen",
  "movingJobStatus.first-contact": "Eerste contact",
  "movingJobStatus.intake": "Offerte opname",
  "movingJobStatus.quotation": "Offerte berekend",
  "movingJobStatus.quote-created": "Offerte gemaakt",
  "movingJobStatus.quote-sent": "Offerte verzonden",
  "movingJobStatus.quote-declined": "Offerte afgewezen",
  "movingJobStatus.tuning": "Onderhandeling",
  "movingJobStatus.quote-accepted": "Klant akkoord",
  "movingJobStatus.planned": "Ingepland",
  "movingJobStatus.awaiting-deposit": "Aanbetaling verstuurd",
  "movingJobStatus.received-deposit": "Aanbetaling ontvangen",
  "movingJobStatus.skipped-deposit": "Aanbetaling overgeslagen",
  "movingJobStatus.deliver-boxes": "Dozen leveren",
  "movingJobStatus.carry-out": "Verhuizing",
  "movingJobStatus.in-storage": "In opslag",
  "movingJobStatus.aftercare": "Afhandelen",
  "movingJobStatus.pick-up-boxes": "Dozen ophalen",
  "movingJobStatus.finished": "Afgerond",
  "movingJobStatus.cancelled": "Geannuleerd",
  "movingLiftJobStatus.first-contact": "Eerste contact",
  "movingLiftJobStatus.quotation": "Offerte berekend",
  "movingLiftJobStatus.quote-created": "Offerte gemaakt",
  "movingLiftJobStatus.quote-sent": "Offerte verzonden",
  "movingLiftJobStatus.quote-declined": "Offerte afgewezen",
  "movingLiftJobStatus.tuning": "Onderhandeling",
  "movingLiftJobStatus.quote-accepted": "Klant akkoord",
  "movingLiftJobStatus.planned": "Ingepland",
  "movingLiftJobStatus.awaiting-deposit": "Aanbetaling verstuurd",
  "movingLiftJobStatus.received-deposit": "Aanbetaling ontvangen",
  "movingLiftJobStatus.skipped-deposit": "Aanbetaling overgeslagen",
  "movingLiftJobStatus.carry-out": "In uitvoering",
  "movingLiftJobStatus.aftercare": "Afhandelen",
  "movingLiftJobStatus.finished": "Afgerond",
  "movingLiftJobStatus.cancelled": "Geannuleerd",
  "transportJobStatus.first-contact": "Eerste contact",
  "transportJobStatus.quotation": "Offerte berekend",
  "transportJobStatus.quote-created": "Offerte gemaakt",
  "transportJobStatus.quote-sent": "Offerte verzonden",
  "transportJobStatus.quote-declined": "Offerte afgewezen",
  "transportJobStatus.tuning": "Onderhandeling",
  "transportJobStatus.quote-accepted": "Klant akkoord",
  "transportJobStatus.planned": "Ingepland",
  "transportJobStatus.awaiting-deposit": "Aanbetaling verstuurd",
  "transportJobStatus.received-deposit": "Aanbetaling ontvangen",
  "transportJobStatus.skipped-deposit": "Aanbetaling overgeslagen",
  "transportJobStatus.carry-out": "In uitvoering",
  "transportJobStatus.aftercare": "Afhandelen",
  "transportJobStatus.finished": "Afgerond",
  "transportJobStatus.cancelled": "Geannuleerd",
  "coverSheetTypes.cover-sheet": "Voorblad",
  "coverSheetTypes.back-cover": "Achterblad",
  "damageStatus.open": "Open",
  "damageStatus.processing": "In behandeling",
  "damageStatus.waiting": "Wachten",
  "damageStatus.closed": "Gesloten",
  "movingJobStatus.categories.inquiry": "Aanvraag",
  "movingJobStatus.categories.quote": "Offerte",
  "movingJobStatus.categories.deposit": "Aanbetaling",
  "movingJobStatus.categories.planning": "Planning",
  "movingJobStatus.categories.execution": "Uitvoering",
  "movingJobStatus.categories.finishing": "Afronding",
  "movingJobStatus.categories.cancelled": "Geannuleerd",
  "movingLiftJobStatus.categories.inquiry": "Aanvraag",
  "movingLiftJobStatus.categories.quote": "Offerte",
  "movingLiftJobStatus.categories.deposit": "Aanbetaling",
  "movingLiftJobStatus.categories.planning": "Planning",
  "movingLiftJobStatus.categories.execution": "Uitvoering",
  "movingLiftJobStatus.categories.finishing": "Afronding",
  "movingLiftJobStatus.categories.cancelled": "Geannuleerd",
  "transportJobStatus.categories.inquiry": "Aanvraag",
  "transportJobStatus.categories.quote": "Offerte",
  "transportJobStatus.categories.deposit": "Aanbetaling",
  "transportJobStatus.categories.planning": "Planning",
  "transportJobStatus.categories.execution": "Uitvoering",
  "transportJobStatus.categories.finishing": "Afronding",
  "transportJobStatus.categories.cancelled": "Geannuleerd",
  "notFoundPage.title": "Pagina niet gevonden",
  "notFoundPage.text": "De pagina die je zoekt bestaat niet of is verplaatst.",
  "notFoundPage.helpMessage": "Help, ik ben op een verkeerde pagina!",
  "notFoundPage.iNeedHelp": "Ik heb hulp nodig",
  "notFoundPage.backToHome": "Terug naar dasboard",
  "errorFallback.tryAgain": "Probeer opnieuw",
  "errorFallback.iNeedHelp": "Hulp nodig?",
  "errorFallback.backToHome": "Terug naar home",
  "errorFallback.oopsSomethingWentWrong": "Oeps, er ging iets fout",
  "errorFallback.errorDescription": "Er is helaas iets fout gegaan. Excuses hiervoor. Zou je willen beschrijven wat je aan het doen was zodat we de fout kunnen reproduceren?",
  "errorFallback.intakeErrorDescription": "Er is helaas iets fout gegaan. Excuses hiervoor. Zou je willen beschrijven wat je aan het doen was zodat we de fout kunnen reproduceren?",
  "errorFallback.message": "Er is een foutmelding opgetreden. De melding was: {error}",
  "noPermissionsPage.title": "Geen toegang",
  "noPermissionsPage.text": "Je hebt geen toegang tot deze pagina.",
  "variantLabel.persons": "Persoons",
  "variantLabel.doors": "Deurs",
  "variantLabel.pieces": "Stuks",
  "variantLabel.size": "Formaat",
  "variantLabel.selected.persons": "{variantLabel, select,\n Hoek {Hoek}\n Corner {Hoek}\n other {{variantLabel} persoons}\n}",
  "variantLabel.selected.doors": "{variantLabel} deurs",
  "variantLabel.selected.pieces": "{variantLabel} stuks",
  "variantLabel.selected.size": "{variantLabel}",
  "featureTypes.ui": "Backoffice",
  "featureTypes.calendar-sync": "Kalender synchronisatie",
  "featureTypes.quotationTool": "Offerte berekening",
  "featureTypes.customer-notifications": "Klant berichtgeving",
  "featureTypes.tasks": "Taken",
  "featureTypes.quotation": "Offerte",
  "featureTypes.time-registration": "Urenregistratie",
  "featureTypes.mobile-app": "Mobiele app",
  "featureTypes.invoicing": "Facturatie",
  "featureTypes.project": "Klussen",
  "featureTypes.service": "Prijzen voor diensten",
  "automatedEmailTypes.password_reset_request": "Wachtwoord reset",
  "automatedEmailTypes.new_employee": "Nieuwe medewerker",
  "automatedEmailTypes.custom": "Aangepast",
  "automatedEmailTypes.quote_was_accepted_by_customer": "Akkoord op offerte",
  "automatedEmailTypes.invoice": "Factuur",
  "automatedEmailTypes.reminder_invoice": "Herinnering factuur",
  "automatedEmailTypes.second_reminder_invoice": "2de Herinnering factuur",
  "automatedEmailTypes.last_reminder_invoice": "Aanmaning factuur",
  "automatedEmailTypes.received_payment": "Betaling ontvangen",
  "automatedEmailTypes.quote": "Offerte",
  "automatedEmailTypes.reminder_quote": "Herinnering offerte",
  "automatedEmailTypes.intake_appointment_confirmation": "Taxatie afspraak bevestiging",
  "automatedEmailTypes.deliver_boxes_appointment_confirmation": "Dozen levering afspraak bevestiging",
  "automatedEmailTypes.pickup_boxes_appointment_confirmation": "Dozen ophalen afspraak bevestiging",
  "automatedEmailTypes.planned_moving_appointment_confirmation": "Verhuizing gepland",
  "automatedEmailTypes.updated_appointment_confirmation": "Afspraken gewijzigd",
  "automatedEmailTypes.moving_date_is_approaching": "Verhuizing nadert",
  "movingJob.distanceFromCarWithValue": "{distance, number}m vanaf de auto",
  "movingJob.distanceToApartmentWithValue": "{distance, number}m naar de woning",
  "Invalid credentials.": "Inloggen mislukt, controleer je inloggegevens",
  "code_invalid": "De code was ongeldig, probeer het opnieuw.",
  "label.packageQuoteError": "Er zijn valideringsfouten in de offerte",
  "label.packageBillingMomentsError": "Er zijn valideringsfouten in de facturatie momenten",
  "label.hasPictures": "Heeft foto's",
  "label.isMissingPictures": "Ontbrekende foto's",
  "label.now": "Nu",
  "label.addTimeType": "Tijdsoort toevoegen",
  "label.automatedEmailTypes": "Type geautomatiseerde e-mail",
  "label.leftOverHoursFrom": "Overgebleven uren van {year}",
  "label.requestedHoursFrom": "Aangevraagde uren van {year}",
  "label.volumeType": "Volume type",
  "label.cantBeBeforeLastInvoiceDate": "Moet na de laatste factuurdatum zijn.",
  "label.plannedEmployees": "Geplande medewerkers",
  "label.otherEmployees": "Overige medewerkers",
  "label.addCurrentBalance": "Huidige saldo toevoegen",
  "label.dontAddCurrentBalance": "Huidige saldo niet toevoegen",
  "label.defaultEffectiveHours": "Standaard verlofuren",
  "label.timeOffBalanceForYear": "Verlofuren voor {year}",
  "label.damageStatus": "Schade status",
  "label.damagedBefore": "Beschadigd voor",
  "label.damagedAfter": "Beschadigd na",
  "label.damagedAt": "Beschadigd op",
  "label.useDefaultHours": "Gebruik standaard verlofuren",
  "label.overrideHours": "Overschrijf verlofuren",
  "label.setTheTimeOffHoursForAllEmployees": "Stel de verlofuren in voor alle medewerkers (tenzij overschreven)",
  "label.requestWorkingDayPermissions": "{name},\nWe willen graag je locatie bijhouden.",
  "label.requestWorkingDayPermissions.description": "Tijdens het werk houdt de app je locatie en activiteit bij zodat we je beter kunnen begeleiden bij de klussen. De activiteit gebruiken wij alleen om te kijken of we de GPS locatie tijdelijk kunnen uitschakelen om batterij te besparen.",
  "label.requestWorkingDayPermissions.permissionsButton": "Permissies aanvragen",
  "label.startWorkingDay": "Start je werkdag",
  "label.startWorkingDay.description": "De automatische urenregistratie wordt gestart zodra je op \"Aan het werk!\" klikt. Je uren worden automatisch bijgehouden op basis van klus en locatie.",
  "label.startWorkingDay.startButton": "Aan het werk!",
  "label.stopWorkingDay": "Stop je werkdag",
  "label.stopWorkingDay.description": "De automatische urenregistratie wordt gestopt zodra je op \"Stop je werkdag\" klikt. Vergeet niet je uren te synchroniseren!",
  "label.stopWorkingDay.trackedTime": "We hebben de volgende uren bijgehouden:",
  "label.stopWorkingDay.stopButton": "Stop je werkdag",
  "button.notNow": "Niet nu",
  "button.goToTimeOff": "Ga naar verlof",
  "label.isFieldService": "Is buitendienst",
  "label.isWorkingHours": "Is werktijd",
  "label.isNotWorkingHours": "Is geen werktijd",
  "label.isInternalService": "Interne werkzaamheden",
  "button.noOnlyThisEvent": "Nee, alleen deze afspraak",
  "button.yesAlsoFutureEvents": "Ja, deze en toekomstige afspraken",
  "button.noOnlyThisDate": "Nee, alleen deze datum",
  "button.yesAlsoFutureDates": "Ja, deze en toekomstige datums",
  "button.goToSettings": "Ga naar instellingen",
  "button.rescheduleEvents": "Afspraken wijzigen",
  "button.registerNewPassKey": "Nieuwe pas aanmelden",
  "button.setupTwoFactorAuthentication": "Twee factor instellen",
  "button.disableTwoFactorAuthentication": "Twee factor uitschakelen",
  "button.moveStoredItems": "Verplaats opgeslagen items",
  "button.positionForPickup": "Opslag klaarzetten",
  "button.clearLocation": "Locatie wissen",
  "button.toggleScanning": "Scannen aan/uit",
  "button.dontMoveItem": "Niet verplaatsen",
  "button.pickUpStorage": "Opslag ophalen",
  "button.returnStorageToWarehouse": "Opslag terugzetten",
  "button.printStorageLabel": "Print opslaglabel",
  "button.newWarehouse": "Nieuwe loods",
  "button.newStorageLocation": "Nieuwe opslaglocatie",
  "button.printStoredItemLabels": "Print labels voor opgeslagen items",
  "button.print": "Print",
  "button.printNumberOfLabels": "Print {numberOfLabels} labels",
  "button.addNationalHoliday": "Feestdag toevoegen",
  "button.requestCameraPermission": "Camera toegang aanvragen",
  "button.clearScannedQrCodes": "Gescande QR codes wissen",
  "button.import": "Importeren",
  "button.addTimeType": "Tijdsoort toevoegen",
  "button.clear": "Wissen",
  "button.newTimeOffType": "Nieuw verlofsoort",
  "button.syncHours": "Uren doorzetten",
  "button.addTimeOff": "Verlof toevoegen",
  "button.requestTimeOff": "Verlof aanvragen",
  "button.addTimeOffHoursForMultipleEmployees": "Verlofuren toevoegen in bulk",
  "button.requestHoursBuildUp": "Uren opbouw",
  "button.addDeclaration": "Declaratie toevoegen",
  "button.thisWeek": "Deze week",
  "button.lastWeek": "Vorige week",
  "button.last7Days": "Laatste 7 dagen",
  "button.thisMonth": "Deze maand",
  "button.lastMonth": "Vorige maand",
  "button.thisQuarter": "Dit kwartaal",
  "button.lastQuarter": "Vorig kwartaal",
  "button.xQuarter": "{quarter, selectordinal,\n    =1 {#ste}\n    other {#de}\n} kwartaal",
  "button.thisYear": "Dit jaar",
  "button.lastYear": "Vorig jaar",
  "button.addAvailability": "Voeg beschikbaarheid toe",
  "button.addRow": "Voeg rij toe",
  "button.copy": "Kopieer",
  "button.today": "Vandaag",
  "button.forceToOldPlanning": "Naar oude planning",
  "button.addClosureDate": "Voeg sluitingsdag toe",
  "button.settings": "Instellingen",
  "button.createTask": "Taak aanmaken",
  "button.copyToDocuments": "Kopieer naar documenten",
  "button.orderExtraSeats": "Bestel extra plaatsen",
  "button.compose": "Nieuw bericht",
  "button.byRole": "Per rol",
  "button.newTask": "Nieuwe taak",
  "button.addHandlingCosts": "Voeg handlingkosten toe",
  "button.registerMileageReport": "Kilometerstand doorgeven",
  "button.byDate": "Per datum",
  "button.learnMore": "Meer informatie",
  "button.open": "Open",
  "button.manageTemplates": "Beheer templates voor {name}",
  "button.manageProfile": "Beheer profiel voor {name}",
  "button.sendTemplate": "Stuur template",
  "button.connectAExtraNumber": "Voeg een extra nummer toe",
  "button.moreInformation": "Meer informatie",
  "button.connectToCustomer": "Koppel aan klant",
  "button.connectToEmployee": "Koppel aan medewerker",
  "button.connectToQuote": "Koppel aan offerte",
  "button.connectToInvoice": "Koppel aan factuur",
  "button.removeAddress": "verwijder adres",
  "button.showHourEntries": "Toon uren regels",
  "button.showMore": "Toon meer",
  "button.startDriving": "Start met rijden",
  "button.scanQrCodes": "Scan QR codes",
  "button.startWorking": "Start met werken",
  "button.arrivedAtLocation": "Aangekomen op locatie",
  "button.returnToOpen": "Heropenen",
  "button.startProcessing": "Oppakken",
  "button.putOnHold": "In de wacht",
  "button.reportDamage": "Schade melden",
  "button.hideAvailability": "Verberg beschikbaarheid",
  "button.profile": "Profiel",
  "button.warehouse": "Loodswerkzaamheden",
  "button.help": "Help",
  "button.logout": "Uitloggen",
  "button.showAvailability": "Toon beschikbaarheid",
  "button.addNeededMaterial": "Materiaal toevoegen",
  "button.toBackoffice": "Naar backoffice",
  "button.newCustomer": "Nieuwe klant",
  "button.newReusableMaterial": "Nieuwe type materiaal",
  "button.newTurnoverGroup": "Nieuwe omzetgroep",
  "button.snooze": "Uitstellen",
  "button.declineAndCopy": "Afwijzen en nieuwe versie maken",
  "button.newIdentity": "Nieuwe identiteit",
  "button.newTeam": "Nieuw team",
  "button.reserveStorage": "{storageTypeName} reserveren",
  "button.placeComment": "Plaats opmerking",
  "button.startWorkingOnTask": "Taak oppakken",
  "button.stopWorkingOnTask": "Stoppen met werken aan taak",
  "button.reopenTask": "Taak heropenen",
  "button.tomorrow": "Morgen",
  "button.nextWeek": "Volgende week",
  "button.later": "Later moment",
  "button.uploadLogo": "Upload logo",
  "button.uploadImage": "Upload afbeelding",
  "button.newRow": "Nieuwe regel",
  "button.finalized": "Afvinken",
  "button.retry": "Probeer opnieuw",
  "button.markAsCompleted": "Markeer als voltooid",
  "button.disapprove": "Weigeren",
  "button.approve": "Goedkeuren",
  "button.reject": "Afkeuren",
  "button.markAsPaid": "Markeer als betaald",
  "button.connect": "Koppelen",
  "button.passOnWish": "Wens doorgeven",
  "button.activateIntegration": "Koppeling activeren",
  "button.postNews": "Nieuwsbericht maken",
  "button.removeNewsItem": "Verwijder bericht",
  "button.removeTeam": "Verwijder team",
  "button.saveChange": "Wijzigingen opslaan",
  "button.openQuote": "Open offerte",
  "button.cancelEvent": "Afspraak annuleren",
  "button.finishEvent": "Afspraak afronden",
  "button.reopenEvent": "Afspraak heropenen",
  "button.reschedule": "Opnieuw plannen",
  "button.plan": "Plannen",
  "button.planEvent": "Plan afspraak",
  "button.newEvent": "Nieuwe afspraak",
  "button.newPackage": "Nieuw pakket",
  "button.newEmployee": "Nieuwe medewerker",
  "button.newVehicle": "Nieuw voertuig",
  "button.newStorage": "Nieuwe opslag",
  "button.newMaterial": "Nieuw material",
  "button.newMovingBox": "Nieuw type doos",
  "button.newMovingJobForExistingCustomer": "Nieuwe verhuizing bestaande klant",
  "button.newProject": "Nieuwe klus",
  "button.newMovingJob": "Nieuwe verhuizing",
  "button.newTransportJob": "Nieuwe transport klus",
  "button.newMovingLiftJob": "Nieuwe verhuislift klus",
  "button.goToApp": "Naar medewerker app",
  "button.newDocumentTemplate": "Nieuwe template",
  "button.newAutomatedEmail": "Nieuwe berichtgeving",
  "button.create": "Aanmaken",
  "button.createAndSend": "Aanmaken en versturen",
  "button.createCreditInvoice": "Creëer credit factuur",
  "button.connectToProject": "Koppel aan klus",
  "button.invoice": "Factureren",
  "button.goToIntegration": "Ga naar koppeling",
  "button.goToProject": "Ga naar klus",
  "button.goToDamage": "Ga naar schade",
  "button.goToCustomer": "Ga naar klant",
  "button.goToEmployee": "Ga naar medewerker",
  "button.goToQuote": "Ga naar offerte",
  "button.goToInvoice": "Ga naar factuur",
  "button.goToGoogleMaps": "Navigeer",
  "button.goToTask": "Ga naar taak",
  "button.goToEvent": "Ga naar afspraak",
  "button.login": "Inloggen",
  "button.forgotPassword": "Wachtwoord aanvragen",
  "button.changePassword": "Wachtwoord wijzigen",
  "button.download": "Download",
  "button.downloadPdf": "Download pdf",
  "button.downloadWorkOrder": "Download werkbon",
  "button.downloadSignedContract": "Download getekende contract",
  "button.cancel": "Annuleren",
  "button.update": "Klik hier om te updaten.",
  "button.uploadPhoto": "Foto maken of uploaden",
  "button.uploadDocument": "Upload document",
  "button.uploadAttachment": "Upload bijlage",
  "button.sendInvoice": "Factuur versturen",
  "button.createConceptInvoice": "Factuur klaarzetten",
  "button.createConceptQuote": "Offerte klaarzetten",
  "button.selectAll": "Alles selecteren",
  "button.deselectAll": "Alles deselecteren",
  "button.addLine": "Regel toevoegen",
  "button.addFromAddress": "Laadadres toevoegen",
  "button.addToAddress": "Losadres toevoegen",
  "button.addCalculatedLine": "Berekende regel toevoegen",
  "button.addFixedLine": "Vaste regel toevoegen",
  "button.clearSignature": "Veld legen",
  "button.search": "Zoeken",
  "button.filters": "Filter",
  "button.save": "Opslaan",
  "button.send": "Verstuur",
  "button.sendAll": "Versturen",
  "button.resetFilters": "Filters wissen",
  "button.logCommunication": "Log communicatie",
  "button.close": "Sluiten",
  "button.resend": "Opnieuw versturen",
  "button.approveHours": "Uren goedkeuren",
  "button.removeHours": "Uren verwijderen",
  "button.enterHours": "Uren doorgeven",
  "button.enterMileageReport": "Kilometerstand doorgeven",
  "button.edit": "Bewerken",
  "button.confirm": "Bevestigen",
  "button.accept": "Accepteren",
  "button.decline": "Afwijzen",
  "button.removeCustomer": "Verwijder klant",
  "button.removeVehicle": "Verwijder voertuig",
  "button.mergeCustomer": "Klant samenvoegen",
  "button.removeQuote": "Verwijder offerte",
  "button.removeInvoice": "Verwijder factuur",
  "button.cancelSchedule": "Annuleer geplande factuur",
  "button.removePayment": "Verwijder payment",
  "button.removeStorage": "Verwijder opslag",
  "button.sendReminder": "Verstuur herinnering",
  "button.schedule": "Inplannen",
  "button.processPayment": "Verwerk betaling",
  "button.markAsIrrecoverable": "Oninbaar",
  "button.finalize": "Afronden",
  "button.finalizeWithoutSending": "Afronden zonder versturen",
  "button.finalizeAndSend": "Afronden en versturen",
  "button.extraActions": "Meer acties",
  "button.loadStorage": "Opslag laden",
  "button.unloadStorage": "Opslag lossen",
  "button.loadAndUnloadItems": "Laden en lossen",
  "button.unloadStorageAndClear": "Opslag lossen en vrijmaken",
  "button.emptyStorage": "Opslag leegmaken",
  "button.emptyAllStorages": "Alle opslag leegmaken",
  "button.unloadFromStorage": "Item uit de opslag",
  "button.previous": "Vorige",
  "button.next": "Volgende",
  "button.orderInventoryItems": "Bestellen",
  "button.receivedInventoryItems": "Binnenmelden",
  "button.throughAwayInventoryItems": "Weggooien",
  "button.sellInventoryItems": "Verkopen",
  "button.rentInventoryItems": "Verhuren",
  "button.gotInventoryItemsBack": "Retour ontvangen",
  "button.sendQuote": "Offerte versturen",
  "button.skipQuote": "Offerte overslaan",
  "button.startIntake": "Start taxatie",
  "button.planMovingJob": "Verhuizing inplannen",
  "button.planProject": "Klus inplannen",
  "button.sendDeposit": "Aanbetaling versturen",
  "button.receivedDeposit": "Aanbetaling ontvangen",
  "button.planPickingUpBoxes": "Dozen ophalen inplannen",
  "button.finish": "Afronden",
  "button.moveToAftercare": "Naar afhandelen",
  "button.resetCancelled": "Opnieuw openen",
  "button.cancelProject": "Klus annuleren",
  "button.planIntake": "Taxatie inplannen",
  "button.selectAllInvoicesBasedOnFilter": "Selecteer alle {numberOfInvoices} facturen in je huidige filter.",
  "button.deleteInvoices": "Verwijder facturen",
  "login.welcome": "Welkom bij Bas.",
  "login.forgotPassword": "wachtwoord vergeten",
  "projectRoles.project-leader": "Klus leider",
  "projectRoles.plural.project-leader": "Klus leiders",
  "projectRoles.foreman": "Voorman",
  "projectRoles.plural.foreman": "Voormannen",
  "projectRoles.appraiser": "Taxateur",
  "projectRoles.plural.appraiser": "Taxateurs",
  "projectRoles.handyman": "Handyman",
  "projectRoles.plural.handyman": "Handymen",
  "projectRoles.co-driver": "Verhuizer",
  "projectRoles.plural.co-driver": "Verhuizers",
  "projectRoles.packer": "Inpakker",
  "projectRoles.plural.packer": "Inpakkers",
  "projectRoles.lift-truck": "Lift operator",
  "projectRoles.plural.lift-truck": "Lift operators",
  "projectRoles.attendee": "Deelnemer",
  "projectRoles.plural.attendee": "Deelnemers",
  "projectRoles.inapplicable": "Nader te bepalen",
  "projectRoles.plural.inapplicable": "Nader te bepalen",
  "employeeRoles.ROLE_EMPLOYEE": "Medewerker",
  "employeeRoles.ROLE_ADMIN": "Beheerder",
  "employeeRoles.ROLE_DATA": "Data analist",
  "employeeRoles.ROLE_HR_MANAGER": "HR Manager",
  "employeeSkills.administrative-assistant": "Administratie",
  "employeeSkills.planner": "Planner",
  "employeeSkills.warehouse": "Loodsmedewerker",
  "employeeSkills.project-leader": "Klus leider",
  "employeeSkills.foreman": "Voorman",
  "employeeSkills.appraiser": "Taxateur",
  "employeeSkills.handyman": "Handyman",
  "employeeSkills.co-driver": "Verhuizer",
  "employeeSkills.packer": "Inpakker",
  "employeeSkills.lift-truck": "Lift operator",
  "internalTimeOffType.holiday": "Vakantie",
  "internalTimeOffType.sickness": "Ziekte",
  "internalTimeOffType.special_leave": "Bijzonder verlof",
  "internalTimeOffType.parental_leave": "Ouderschapsverlof",
  "internalTimeOffType.educational_leave": "Educatief verlof",
  "internalTimeOffType.compensatory_leave": "Tijd voor tijd",
  "internalTimeOffType.unpaid": "Onbetaald verlof",
  "internalTimeOffType.other": "Overig",
  "buildingTypes.apartment": "Appartement",
  "buildingTypes.house": "Huis",
  "buildingTypes.bungalow": "Bungalow",
  "buildingTypes.office": "Kantoor",
  "buildingTypes.storage": "Opslag",
  "buildingTypes.drive-in": "Drive in",
  "buildingTypes.double-apartment": "Dubbel appartement",
  "buildingTypes.studio": "Studio",
  "buildingTypes.villa": "Villa",
  "buildingTypes.farm": "Boerderij",
  "buildingTypes.two-under-one-roof": "2-onder 1 kap",
  "buildingTypes.mansion": "Herenhuis",
  "buildingTypes.upstairs-apartment": "Bovenwoning",
  "buildingTypes.detached-house": "Vrijstaande woning",
  "buildingTypes.terraced-house": "Rijtjeswoning",
  "buildingTypes.corner-house": "Hoekwoning",
  "buildingTypes.school": "School",
  "buildingTypes.nursing-home": "Verpleeghuis",
  "buildingTypes.assisted-living-center": "Woonzorgcentrum",
  "buildingTypes.senior-housing": "Seniorenwoning",
  "buildingTypes.commercial-building": "Bedrijfspand",
  "buildingTypes.dike-house": "Dijkwoning",
  "buildingTypes.houseboat": "Woonboot",
  "buildingTypes.garage": "Garage",
  "buildingTypes.unknown": "Onbekend",
  "timeOffGroup.holiday": "Vakantie",
  "timeOffGroup.sickness": "Ziekte",
  "timeOffGroup.special_leave": "Bijzonder verlof",
  "timeOffGroup.compensatory_leave": "TVT",
  "timeOffGroup.unpaid": "Onbetaald verlof",
  "timeOffGroup.other": "Overig",
  "quoteMoment.groupTypes.project.itemTypes.distance": "Afstand",
  "quoteMoment.groupTypes.project.itemTypes.travel_time": "Reistijd",
  "quoteMoment.groupTypes.project.itemTypes.hours": "Uren",
  "quoteMoment.groupTypes.project.itemTypes.building-type": "Toeslag gebouw",
  "quoteMoment.groupTypes.moving-job.itemTypes.cubic-meter": "Kubieke meter",
  "quoteMoment.groupTypes.moving-job.itemTypes.service": "Dienst",
  "quoteMoment.groupTypes.moving-job.itemTypes.distance": "Afstand",
  "quoteMoment.groupTypes.moving-job.itemTypes.travel_time": "Reistijd",
  "quoteMoment.groupTypes.moving-job.itemTypes.hours": "Uren",
  "quoteMoment.groupTypes.moving-job.itemTypes.staff": "Verhuizers",
  "quoteMoment.groupTypes.moving-job.itemTypes.building-type": "Toeslag gebouw",
  "quoteMoment.groupTypes.moving-job.itemTypes.boxes": "Dozen",
  "quoteMoment.groupTypes.moving-job.itemTypes.service.moving-lift": "Verhuislift",
  "quoteMoment.groupTypes.moving-job.itemTypes.service.provide_boxes": "Verhuispakket",
  "quoteMoment.groupTypes.moving-job.itemTypes.service.waste-disposal": "Afval afvoeren",
  "quoteMoment.groupTypes.moving-job.itemTypes.service.permits": "Vergunning",
  "quoteMoment.groupTypes.moving-job.itemTypes.service.storage": "Opslag",
  "quoteMoment.groupTypes.moving-job.itemTypes.service.insurance": "Verzekering",
  "quoteMoment.groupTypes.moving-job.itemTypes.service.certificate": "Garantiecertificaat",
  "quoteMoment.groupTypes.handyman.itemTypes.hours": "Handyman uren",
  "quoteMoment.groupTypes.handyman.itemTypes.staff": "Aantal handyman",
  "quoteMoment.groupTypes.assemble.itemTypes.hours": "Montage uren",
  "quoteMoment.groupTypes.assemble.itemTypes.staff": "Handyman",
  "quoteMoment.groupTypes.disassemble.itemTypes.hours": "Demontage kosten",
  "quoteMoment.groupTypes.disassemble.itemTypes.staff": "Handyman",
  "quoteMoment.groupTypes.packing.itemTypes.hours": "Inpak uren",
  "quoteMoment.groupTypes.packing.itemTypes.staff": "Inpakkers",
  "quoteMoment.groupTypes.unpacking.itemTypes.hours": "Uitpak uren",
  "quoteMoment.groupTypes.unpacking.itemTypes.staff": "Uitpakkers",
  "quoteMoment.groupTypes.storage.itemTypes.service": "Opslag kosten per periode",
  "quoteMoment.groupTypes.storage.itemTypes.surcharge": "Toeslag kosten voor verhuizing via opslag",
  "billingMoments.billing-moment-after-storage-was-loaded": "Opslag geladen",
  "billingMoments.billing-moment-before-project-starts": "Starten klus",
  "billingMoments.billing-moment-when-project-is-finished": "Na laatste afspraak",
  "billingMoments.billing-moment-after-accepted-quote": "Acceptatie offerte",
  "billingMoments.billing-moment-invoice-without-project": "Factuur zonder klus",
  "billingMomentTypes.billing-moment-after-storage-was-loaded": "Deelfactuur",
  "billingMomentTypes.billing-moment-before-project-starts": "Aanbetaling",
  "billingMomentTypes.billing-moment-when-project-is-finished": "Eindfactuur",
  "billingMomentTypes.billing-moment-after-accepted-quote": "Aanbetaling",
  "billingMomentLineTypes.fixed-amount": "Vast bedrag",
  "billingMomentLineTypes.percentage-amount": "Percentage",
  "billingMomentPeriods.direct": "Direct",
  "billingMomentPeriods.fiveMinutes": "5 minuten",
  "billingMomentPeriods.hour": "Één uur",
  "billingMomentPeriods.day": "Één dag",
  "billingMomentPeriods.week": "Één week",
  "billingMomentPeriods.twoWeeks": "Twee weken",
  "billingMomentPeriods.month": "Één maand",
  "billingMomentTypes.billing-moment-after-storage-was-loaded.periodAfter": "{period} nadat opslag is geladen",
  "billingMomentTypes.billing-moment-before-project-starts.periodAfter": "{period} voordat de klus start",
  "billingMomentTypes.billing-moment-when-project-is-finished.periodAfter": "{period} nadat laatste afspraak is afgerond",
  "billingMomentTypes.billing-moment-after-accepted-quote.periodAfter": "{period} nadat offerte geaccepteerd is",
  "billingMoment.billQuoteLines": "Alle regels van offerte op factuur toevoegen",
  "billingMoment.doNotBillQuoteLines": "Niet automatisch offerte regels toevoegen",
  "timeOffRegistrationStatus.pending": "In afwachting",
  "timeOffRegistrationStatus.approved": "Goedgekeurd",
  "timeOffRegistrationStatus.rejected": "Afgekeurd",
  "timeOffRegistrationStatus.cancelled": "Geannuleerd",
  "timeOffRegistrationStatus.completed": "Afgeronde ziekmelding",
  "timeEntryStatus.open": "In afwachting",
  "timeEntryStatus.approved": "Goedgekeurd",
  "timeEntryStatus.rejected": "Afgekeurd",
  "timeEntryStatus.missing": "Ontbrekend",
  "declarationStatus.pending": "In afwachting",
  "declarationStatus.approved": "Goedgekeurd",
  "declarationStatus.disapproved": "Afgekeurd",
  "declarationStatus.cancelled": "Geannuleerd",
  "declarationStatus.paid": "Betaald",
  "estimate.groupTypes.moving-job": "Verhuizing",
  "estimate.groupTypes.handyman": "Handyman",
  "estimate.groupTypes.assemble": "Monteren",
  "estimate.groupTypes.disassemble": "Demonteren",
  "estimate.groupTypes.packing": "Inpakken",
  "estimate.groupTypes.unpacking": "Uitpakken",
  "estimate.groupTypes.storage": "Opslag",
  "estimate.groupTypes.project": "Klus",
  "estimate.groupTypes.project.itemTypes.building-type": "Gebouw",
  "estimate.groupTypes.project.itemTypes.service": "Dienst",
  "estimate.groupTypes.project.itemTypes.distance": "Afstand",
  "estimate.groupTypes.project.itemTypes.travel_time": "Reistijd",
  "estimate.groupTypes.project.itemTypes.hours": "Uren",
  "estimate.groupTypes.moving-job.itemTypes.cubic-meter": "Kubieke meter",
  "estimate.groupTypes.moving-job.itemTypes.service": "Dienst",
  "estimate.groupTypes.moving-job.itemTypes.distance": "Afstand",
  "estimate.groupTypes.moving-job.itemTypes.travel_time": "Reistijd",
  "estimate.groupTypes.moving-job.itemTypes.hours": "Uren",
  "estimate.groupTypes.moving-job.itemTypes.staff": "Verhuizers",
  "estimate.groupTypes.moving-job.itemTypes.building-type": "Gebouw",
  "estimate.groupTypes.moving-job.itemTypes.boxes": "Dozen",
  "estimate.groupTypes.handyman.itemTypes.hours": "Uren",
  "estimate.groupTypes.handyman.itemTypes.staff": "Handyman",
  "estimate.groupTypes.assemble.itemTypes.hours": "Montage uren",
  "estimate.groupTypes.assemble.itemTypes.staff": "Handyman",
  "estimate.groupTypes.disassemble.itemTypes.hours": "Demontage uren",
  "estimate.groupTypes.disassemble.itemTypes.staff": "Handyman",
  "estimate.groupTypes.packing.itemTypes.hours": "Inpak uren",
  "estimate.groupTypes.packing.itemTypes.staff": "Inpakkers",
  "estimate.groupTypes.unpacking.itemTypes.hours": "Uitpak uren",
  "estimate.groupTypes.unpacking.itemTypes.staff": "Uitpakkers",
  "estimate.groupTypes.storage.itemTypes.cubic-meter": "Kubieke meter",
  "estimate.groupTypes.storage.itemTypes.service": "Prijs per {storageType} per periode",
  "estimate.groupTypes.storage.itemTypes.surcharge": "Extra toeslag (bijvoorbeeld voor uitladen)",
  "genders.male": "Man",
  "genders.female": "Vrouw",
  "genders.unknown": "Onbekend",
  "relationTypes.private": "Particulier",
  "relationTypes.business": "Zakelijk",
  "relationTypes.unknown": "Onbekend",
  "templateTypes.default": "Standaard",
  "templateTypes.moving-job": "Verhuizing",
  "templateTypes.transport-job": "Transport",
  "templateTypes.moving-lift-job": "Verhuislift klus",
  "templateTypes.moving-job-with-storage": "Verhuizing met opslag",
  "templateTypes.storage": "Opslag",
  "templateTypes.manual": "Handmatig",
  "pricingTypes.fixed-price": "Aangenomen klus",
  "pricingTypes.hourly": "Op uurbasis",
  "pricingTypes.unknown": "",
  "storageTypes.container": "Container",
  "storageTypes.multi-location": "Gespreide opslag",
  "storageTypes.pallet": "Pallet",
  "storageTypes.unit": "Unit",
  "storageTypes.chest": "Kist",
  "storageTypes.conventional": "Conventioneel",
  "storageTypes.archive": "Archief",
  "storageTypes.unknown": "Onbekend",
  "teamMemberRoles.addMember": "Teamlid toevoegen",
  "teamMemberRoles.member": "Teamlid",
  "materialTypes.vehicle": "Voertuig",
  "materialTypes.moving-truck": "Verhuisbus",
  "materialTypes.moving-lift": "Verhuislift",
  "project.moving-job.addressType.from": "Laden",
  "project.moving-job.addressType.to": "Lossen",
  "project.transport-job.addressType.from": "Laden",
  "project.transport-job.addressType.to": "Lossen",
  "project.moving-job.addressType.from.boxes": "Afleveren",
  "project.moving-job.addressType.to.boxes": "Afhalen",
  "quoteStatus.concept": "Concept",
  "quoteStatus.sent": "Verzonden",
  "quoteStatus.expired": "Verlopen",
  "quoteStatus.reminded": "Herinnering",
  "quoteStatus.cancelled": "Geannuleerd",
  "quoteStatus.declined": "Afgewezen",
  "quoteStatus.invoiced": "Gefactureerd",
  "quoteStatus.accepted": "Geaccepteerd",
  "invoiceStatus.concept": "Concept",
  "invoiceStatus.open": "Open",
  "invoiceStatus.scheduled": "Ingepland",
  "invoiceStatus.expired": "Verlopen",
  "invoiceStatus.reminded": "Herinnering",
  "invoiceStatus.paid": "Betaald",
  "invoiceStatus.irrecoverable": "Oninbaar",
  "taskStatus.open": "Open",
  "taskStatus.in-progress": "In behandeling",
  "taskStatus.closed": "Gesloten",
  "taskStatus.finished": "Afgerond",
  "syncStatus.awaiting-approval": "Goedkeuring nodig",
  "syncStatus.pending": "In afwachting",
  "syncStatus.in-progress": "Bezig",
  "syncStatus.success": "Succesvol",
  "syncStatus.failed": "Gefaald",
  "syncStatus.disapproved": "Afgekeurd",
  "driversLicenses.bLicense": "B",
  "driversLicenses.bPlusLicense": "B+",
  "driversLicenses.beLicense": "BE",
  "driversLicenses.c1License": "C1",
  "driversLicenses.c1eLicense": "C1E",
  "driversLicenses.cLicense": "C",
  "driversLicenses.ceLicense": "CE",
  "driversLicenses.code95License": "C 95",
  "repeats.never": "Nooit",
  "repeats.daily": "Dagelijks",
  "repeats.weekly": "Wekelijks",
  "repeats.monthly": "Maandelijks",
  "repeats.yearly": "Jaarlijks",
  "repeats.every.daily": "dag(en)",
  "repeats.every.weekly": "week(en)",
  "repeats.every.monthly": "maand(en)",
  "repeats.every.yearly": "jaar(en)",
  "weekDays.monday.short": "Ma",
  "weekDays.tuesday.short": "Di",
  "weekDays.wednesday.short": "Wo",
  "weekDays.thursday.short": "Do",
  "weekDays.friday.short": "Vr",
  "weekDays.saturday.short": "Za",
  "weekDays.sunday.short": "Zo",
  "repeatOnDay.day": "Dag",
  "repeatOnDay.weekday": "Weekdag",
  "repeatOnDay.weekend-day": "Weekenddag",
  "repeatOnDay.monday": "Maandag",
  "repeatOnDay.tuesday": "Dinsdag",
  "repeatOnDay.wednesday": "Woensdag",
  "repeatOnDay.thursday": "Donderdag",
  "repeatOnDay.friday": "Vrijdag",
  "repeatOnDay.saturday": "Zaterdag",
  "repeatOnDay.sunday": "Zondag",
  "weekDays.mo": "Maandag",
  "weekDays.tu": "Dinsdag",
  "weekDays.we": "Woensdag",
  "weekDays.th": "Donderdag",
  "weekDays.fr": "Vrijdag",
  "weekDays.sa": "Zaterdag",
  "weekDays.su": "Zondag",
  "label.outside": "Buiten",
  "label.inside": "Binnen",
  "label.onlyWeekends": "Alleen weekenddagen",
  "label.whichDays": "Welke dagen",
  "label.hasWorkedMoreThanXHours": "Heeft meer dan {hoursMoreThan} uur gewerkt",
  "label.hasWorkedLessThanXHours": "Heeft minder dan {hoursLessThan} uur gewerkt",
  "label.worksBetweenXAndY": "Werkt tussen {startTime} en {endTime}",
  "label.overtimeThresholdHours": "Uren per {calculatePer} meer dan",
  "label.calculatePer": "Bereken per",
  "label.calculatePerValue": "Bereken per {calculatePer}",
  "label.startedWorkingForHourTypeBetween": "Start met werken voor {hourType} tussen",
  "label.startedWorkingBetweenXAndX": "Start met werken tussen {startTimeAfter} en {startTimeBefore}",
  "label.finishedWorkingBetweenXAndX": "Klaar met werken tussen {endTimeAfter} en {endTimeBefore}",
  "label.applyCompensationForHourTypeBetween": "Vergoeding voor {hourType} tussen",
  "label.betweenTimeXAndY": "Tussen {timeX} en {timeY}",
  "label.numberOfHoursPerDay": "Aantal uur per dag",
  "label.numberOfHoursPerWeek": "Aantal uur per week",
  "label.startedWorkingBetween": "Start met werken tussen",
  "label.applyCompensationBetween": "Vergoeding tussen",
  "label.dayOfMonth": "Dag van de maand",
  "label.fromTime": "Van uur",
  "label.untilTime": "Tot uur",
  "label.specificDay": "Specifieke dag",
  "label.enabledFrom": "Ingeschakeld vanaf",
  "label.enabledUntil": "Ingeschakeld tot",
  "label.repeatOn": "Herhaal op",
  "label.hoursMoreThan": "Uren meer dan",
  "label.hoursLessThan": "Uren minder dan",
  "label.repeatOnDay": "Welke dag van de maand?",
  "label.which": "Hoeveelste",
  "label.weekDays": "Weekdagen",
  "weekNumber.first": "Eerste",
  "weekNumber.second": "Tweede",
  "weekNumber.third": "Derde",
  "weekNumber.fourth": "Vierde",
  "weekNumber.last": "Laatste",
  "until.date": "Herhaal tot",
  "until.count": "Herhaal x keer",
  "until.infinite": "Herhaal oneindig",
  "generalLedgerMappingTypes.per-vat-code": "Per Btw code",
  "generalLedgerMappingTypes.per-identity": "Per identiteit",
  "generalLedgerMappingTypes.per-turnover-group": "Per omzetgroep",
  "costCenterMappingTypes.none": "Niet",
  "costCenterMappingTypes.per-identity": "Per identiteit",
  "costCenterMappingTypes.per-turnover-group": "Per omzetgroep",
  "automatedEmails.type.password_reset_request": "Wachtwoord reset",
  "automatedEmails.type.new_employee": "Nieuwe medewerker",
  "automatedEmails.type.quote_was_accepted_by_customer": "Akkoord op offerte",
  "automatedEmails.type.invoice": "Factuur",
  "automatedEmails.type.reminder_invoice": "Herinnering factuur",
  "automatedEmails.type.second_reminder_invoice": "2de Herinnering factuur",
  "automatedEmails.type.last_reminder_invoice": "Aanmaning factuur",
  "automatedEmails.type.received_payment": "Betaling ontvangen",
  "automatedEmails.type.quote": "Offerte",
  "automatedEmails.type.reminder_quote": "Herinnering offerte",
  "automatedEmails.type.intake_appointment_confirmation": "Taxatie afspraak bevestiging",
  "automatedEmails.type.deliver_boxes_appointment_confirmation": "Dozen levering afspraak bevestiging",
  "automatedEmails.type.pickup_boxes_appointment_confirmation": "Dozen ophalen afspraak bevestiging",
  "automatedEmails.type.planned_moving_appointment_confirmation": "Verhuizing gepland",
  "automatedEmails.type.updated_appointment_confirmation": "Afspraken gewijzigd",
  "automatedEmails.type.moving_date_is_approaching": "Verhuizing nadert",
  "eventsTimeline.email.delivered": "Email is afgeleverd",
  "eventsTimeline.email.deferred": "Email is vertraagd",
  "eventsTimeline.email.click": "Er is een link geopend",
  "eventsTimeline.email.open": "Email is geopend",
  "eventsTimeline.email.bounce": "Email is niet aangekomen",
  "eventsTimeline.email.spam-report": "Email is aangemerkt als spam",
  "eventsTimeline.storage.storage_created": "Opslag is aangemaakt",
  "eventsTimeline.storage.storage_reserved_for_customer": "Opslag is gereserveerd voor klant",
  "eventsTimeline.storage.storage_emptied": "Opslag is leeggemaakt",
  "eventsTimeline.storage.storage_was_sealed": "Opslag is verzegeld",
  "eventsTimeline.storage.storage_seal_was_removed": "Opslag verzegeling is verwijderd",
  "eventsTimeline.storage.storage_was_invoiced": "Opslag is gefactureerd",
  "eventsTimeline.storage.storage_positioned_for_pickup": "Opslag is klaargezet voor ophalen",
  "eventsTimeline.storage.storage_was_picked_up": "Opslag is opgehaald",
  "eventsTimeline.storage.storage_was_returned_to_warehouse": "Opslag is teruggezet in de loods",
  "eventsTimeline.storage.storage_moved_to_new_location": "Opslag is verplaatst naar een nieuwe locatie",
  "eventsTimeline.viewEmail": "Bekijk e-mail",
  "inventoryListSetting.always": "Altijd",
  "inventoryListSetting.never": "Nooit",
  "inventoryListSetting.fixed-price-only": "Bij aangenomen klussen",
  "inventoryListSetting.hourly-price-only": "Bij uurprijs klussen",
  "eventsTimeline.quote.manually-accepted-quote-event": "Offerte is handmatig geaccepteerd via de backoffice",
  "eventsTimeline.quote.quote-finalized-event": "Offerte is verstuurd en ondertekend",
  "eventsTimeline.quote.quote-accepted-event": "Offerte is geaccepteerd",
  "eventsTimeline.quote.quote-opened-event": "Offerte is geopend",
  "eventsTimeline.quote.quote-declined-event": "Offerte is afgewezen",
  "eventsTimeline.quote.quote-sent-event": "Offerte is verstuurd",
  "eventsTimeline.quote.quote-expired-event": "Offerte is verlopen",
  "eventsTimeline.quote.quote-reminder-sent-event": "Herinnering is verstuurd",
  "eventsTimeline.quote.quote-canceled-event": "Offerte is geannuleerd",
  "eventsTimeline.quote.sending-quote-failed-event": "Het versturen van de offerte is niet gelukt",
  "eventsTimeline.invoice.invoice-expired-event": "Factuur is verlopen",
  "eventsTimeline.invoice.invoice-finalized-event": "Factuur is afgerond",
  "eventsTimeline.invoice.invoice-paid-event": "Factuur is betaald",
  "eventsTimeline.invoice.invoice-payment-processed-event": "Betaling is verwerkt",
  "eventsTimeline.invoice.invoice-reminder-sent-event": "Herinnering is verstuurd",
  "eventsTimeline.invoice.invoice-scheduled-event": "Factuur is ingepland",
  "eventsTimeline.invoice.invoice-sent-event": "Factuur is verzonden",
  "eventsTimeline.invoice.sending-invoice-failed-event": "Het versturen van de factuur is niet gelukt",
  "eventsTimeline.invoice.invoice-payment-removed-event": "Betaling verwijderd",
  "eventsTimeline.invoice.invoice-was-not-paid-event": "Factuur betaalstatus teruggedraait",
  "eventsTimeline.storage.load": "Geladen in opslag",
  "eventsTimeline.storage.unload": "Gelost uit opslag",
  "mobileApp.goodLuck": "Zet ‘m op vandaag",
  "mobileApp.greeting": "Ha {greetingName}!",
  "mobileApp.stillActiveIntakes": "Openstaande taxaties",
  "mobileApp.eventsForToday": "Klussen vandaag",
  "mobileApp.noEventsForToday": "Je hebt geen klussen voor vandaag",
  "mobileApp.eventsWithoutHours": "Uren nog in te vullen",
  "mobileApp.allEventsHaveHours": "Lekker bezig {name}!\nJe bent helemaal bij met je uren",
  "mobileApp.manuallyEnterHours": "Uren doorgeven",
  "mobileApp.startWorkingDay": "Start werkdag",
  "mobileApp.stopWorkingDay": "Stop werkdag",
  "mobileApp.startIntakeWithoutProject": "Nieuwe taxatie",
  "mobileApp.showAllTrafficObstructions": "Alle resultaten ({count})",
  "mobileApp.trafficObstructions.title": "Alle verkeersmeldingen",
  "mobileApp.latestUpdates": "Laatste updates",
  "mobileApp.noNews": "Je bent helemaal bij met de laatste updates.",
  "mobileApp.read": "Gelezen",
  "mobileApp.enterHours.title": "Uren doorgeven",
  "mobileApp.enterHours.didYouWorkAtTheOfficeOrWarehouse": "Heb je op kantoor of in de loods gewerkt?",
  "mobileApp.enterHours.atTheOffice": "Op kantoor",
  "mobileApp.enterHours.inTheWareHouse": "In de loods",
  "mobileApp.enterHours.confirm": "Invoeren",
  "mobileApp.activeIntake.unknownCustomer": "Onbekende klant",
  "mobileApp.intake.movingJob.fromAddress": "Waarvandaan moet er worden verhuist?",
  "mobileApp.intake.movingJob.toAddress": "Waar moet er worden gelost?",
  "mobileApp.intake.movingJob.fromWhichFloor": "Op welke verdieping?",
  "mobileApp.intake.movingJob.isTheAddressKnown": "Is het los adres al bekend?",
  "mobileApp.intake.movingJob.doYouHaveAnElevator": "Is er een lift aanwezig?",
  "mobileApp.intake.movingJob.doYouNeedTrafficSigns": "Moeten er verkeerdsborden worden geplaatst?",
  "mobileApp.intake.movingJob.doYouNeedAPermit": "Moet er een vergunning aan worden gevraagd?",
  "mobileApp.intake.movingJob.distanceFromCar": "Afstand van de auto naar de lift",
  "mobileApp.intake.movingJob.distanceToApartment": "Afstand van de lift naar het appartement",
  "mobileApp.intake.movingJob.confirmInfo": "Klopt deze info?",
  "mobileApp.intake.movingJob.whichServicesShouldBeDone": "Welke diensten moeten er plaatsvinden?",
  "mobileApp.intake.movingJob.whatRoomDoYouWantToAdd": "Welke kamer wil je toevoegen?",
  "mobileApp.intake.movingJob.whatRoomsNeedToBeMoved": "Welke kamers moeten er verhuisd worden?",
  "mobileApp.intake.movingJob.whatNeedsToBeMoved": "Wat moet er worden verhuisd?",
  "mobileApp.intake.movingJob.whatInventoryItemsAreNeeded": "Welk materiaal is er nodig?",
  "mobileApp.intake.movingJob.addRoom": "Kamer toevoegen",
  "mobileApp.intake.movingJob.customerDetails": "Klantgegevens",
  "mobileApp.intake.movingJob.whatIsImportantForTheOffice": "Wat moet je echt onthouden?",
  "mobileApp.intake.movingJob.whatInformationIsForTheCustomer": "Welke notities wil je met de klant delen?",
  "mobileApp.intake.movingJob.areThereMoreAddresses": "Zijn er nog andere adressen voor de verhuizing?",
  "mobileApp.intake.movingJob.addExtraAddress": "Adres toevoegen",
  "mobileApp.intake.movingJob.whatAddressShouldBeAdded": "Welk adres wil je toevoegen?",
  "mobileApp.intake.movingJob.addFurniture": "Meubel toevoegen",
  "mobileApp.intake.movingJob.addCustomFurniture": "Aangepast meubel",
  "mobileApp.intake.movingJob.whatFurnitureDoYouWantToAdd": "Welk meubel wil je toevoegen?",
  "mobileApp.intake.movingJob.pricingDetails": "Betalingsafspraken",
  "mobileApp.intake.movingJob.calculating": "Berekenen",
  "mobileApp.intake.movingJob.weAreCalculatingTheEstimate": "We zijn bezig met alle getallen op een rijtje te zetten...",
  "mobileApp.intake.movingJob.theEstimation": "De inschatting",
  "mobileApp.intake.movingJob.theQuote": "De offerte",
  "mobileApp.intake.movingJob.stepsTitle": "Verhuizing taxatietool",
  "mobileApp.intake.movingJob.goBackToApp": "Terug naar home",
  "mobileApp.intake.movingJob.needHelp": "Hulp nodig?",
  "mobileApp.intake.movingJob.helpMessageContent": "Taxatie tool",
  "mobileApp.intake.movingJob.doYouWantToSendTheQuote": "Wil je de offerte versturen?",
  "mobileApp.intake.movingJob.estimation.totalPrice": "Totale prijs",
  "mobileApp.intake.movingJob.roomFlow.whichFurnitureNeedsToBeMovedStep": "Welke meubels staan er in de {room}?",
  "mobileApp.intake.movingJob.roomFlow.whatFurnitureNeedDisassemblyStep": "Moet er iets gedemonteerd worden?",
  "mobileApp.intake.movingJob.roomFlow.whatFurnitureNeedAssemblyStep": "Moet er iets gemonteerd worden?",
  "mobileApp.intake.movingJob.roomFlow.whatFurnitureNeedsToGoIntoStorageStep": "Welke meubels moeten naar de opslag?",
  "mobileApp.intake.movingJob.roomFlow.howDoYouWantToNameThisRoomStep": "Wil je de {originalRoom} een andere naam geven?",
  "mobileApp.intake.movingJob.roomFlow.howManyMovingBoxesAreNeededStep": "Hoeveel dozen zijn er nodig in deze kamer?",
  "mobileApp.intake.movingJob.roomFlow.howManyMovingBoxesNeedToGoIntoStorageStep": "Hoeveel dozen moeten er naar de opslag?",
  "mobileApp.intake.movingJob.roomFlow.uploadSomePicturesStep": "Voeg een aantal foto's toe, zodat de een beter beeld hebben.",
  "mobileApp.finishEvent.enterHoursStep": "Klus afronden",
  "mobileApp.finishEvent.loadCargoStep": "Laden van de vracht",
  "mobileApp.finishEvent.unloadCargoStep": "Lossen van de vracht",
  "mobileApp.finishEvent.wereTherePeopleWhoHadDifferentHours": "Zijn er collega's met afwijkende tijden?",
  "mobileApp.finishEvent.wereTheAnyParticularsStep": "Waren er bijzonderheden?",
  "mobileApp.finishEvent.signingStep": "Tekenen met de klant",
  "mobileApp.finishEvent.signingStep.description": "Gaat u akkoord dat",
  "mobileApp.finishEvent.signingStep.theWorkWasFromXUntilX": "De werkzaamheden zijn gestart om {startTime} en gestopt om {endTime} {breakTime, plural,\n    =0 {zonder pauze }\n    =1 {en hebben {breakTimeBold} minuut pauze gehad}\n    other {en hebben {breakTimeBold} minuten pauze gehad}\n}",
  "mobileApp.finishEvent.signingStep.performedWork": "Uitgevoerde werkzaamheden:",
  "mobileApp.finishEvent.signingStep.performedWork.project-intake": "Taxatie",
  "mobileApp.finishEvent.signingStep.performedWork.moving-job-pickup-boxes": "Het ophalen van dozen en materiaal",
  "mobileApp.finishEvent.signingStep.performedWork.moving-job-deliver-boxes": "Het leveren van dozen en materiaal",
  "mobileApp.finishEvent.signingStep.performedWork.moving-job-assemble": "Monteren",
  "mobileApp.finishEvent.signingStep.performedWork.moving-job-disassemble": "Demonteren",
  "mobileApp.finishEvent.signingStep.performedWork.moving-job-post-road-signs": "Borden plaatsen",
  "mobileApp.finishEvent.signingStep.performedWork.moving-job-remove-road-signs": "Borden ophalen",
  "mobileApp.finishEvent.signingStep.performedWork.moving-job-load-storage": "We hebben uw inboedel verhuist van {fromAddress} naar de opslag",
  "mobileApp.finishEvent.signingStep.performedWork.moving-job-unload-storage": "We hebben uw inboedel van de opslag verhuist naar {toAddress}",
  "mobileApp.finishEvent.signingStep.performedWork.moving-job-moving-loading": "We hebben uw inboedel ingeladen op {fromAddress}",
  "mobileApp.finishEvent.signingStep.performedWork.moving-job-moving-unloading": "We hebben uw inboedel gelost op {toAddress}",
  "mobileApp.finishEvent.signingStep.performedWork.moving-job-packing": "Inpakken",
  "mobileApp.finishEvent.signingStep.performedWork.moving-job-unpacking": "Uitpakken",
  "mobileApp.finishEvent.signingStep.performedWork.moving-job-moving": "Verhuizing",
  "mobileApp.finishEvent.signingStep.performedWork.moving-job-moving-lift": "Verhuislift",
  "mobileApp.finishEvent.signingStep.performedWork.moving-job-disposing-of-waste": "Afval afvoeren",
  "mobileApp.finishEvent.signingStep.performedWork.moving-job-prepare-house-according-to-quote": "Opleveren",
  "mobileApp.finishEvent.signingStep.performedWork.transport": "Transport",
  "mobileApp.finishEvent.signingStep.performedWork.transport-job-pickup": "Laden",
  "mobileApp.finishEvent.signingStep.performedWork.transport-job-deliver": "Lossen",
  "mobileApp.finishEvent.signingStep.performedWork.project-driving-day": "Rij dag",
  "mobileApp.finishEvent.signingStep.performedWork.custom": "Aangepast",
  "mobileApp.finishEvent.signingStep.performedWork.meeting": "Meeting",
  "mobileApp.finishEvent.signingStep.performedWork.lift-work": "Lift werkzaamheden",
  "mobileApp.finishEvent.signingStep.performedWork.office-work": "Kantoor werkzaamheden",
  "mobileApp.finishEvent.signingStep.performedWork.warehouse-work": "Loods werkzaamheden",
  "mobileApp.finishEvent.signingStep.performedWork.lending-staff": "Medewerkers uitlenen",
  "mobileApp.finishEvent.paymentStep": "Betaling",
  "chat.unreadMessages": "{numberOfUnreadMessages, plural,\n    =1 {# ongelezen bericht}\n    other {# ongelezen berichten}\n}",
  "eventActions.startWithIntake": "Start met taxatie",
  "eventActions.disassemblyList": "Demonteer lijst",
  "eventActions.assemblyList": "Montage lijst",
  "eventActions.startDriving": "Starten met rijden",
  "eventActions.startWorking": "Klus starten",
  "eventActions.arrived": "Aangekomen",
  "eventActions.startLoading": "Starten met laden",
  "eventActions.startUnloading": "Starten met lossen",
  "eventActions.finishWithCustomer": "Afronden bij de klant",
  "eventActions.payAtCustomer": "Klant laten betalen",
  "eventActions.finalizeEvent": "Klus afronden",
  "eventActions.registerMileageReport": "Kilometerstand doorgeven",
  "eventActions.reportDamage": "Schade melden",
  "eventActions.deliverBoxes": "Dozen leveren",
  "eventActions.pickupBoxes": "Dozen ophalen",
  "eventActions.takeMaterial": "Materiaal meenemen",
  "eventActions.retrieveMaterial": "Materiaal terugbrengen",
  "eventActions.furnitureList": "Inboedellijst",
  "eventActions.loadStorage": "Opslag laden",
  "eventActions.unloadStorage": "Opslag lossen",
  "taskTypes.employee-is-absent-but-planned-on-event-task": "Medewerker is afwezig maar gepland voor klus",
  "taskTypes.employee-is-absent-but-planned-on-event-task.title": "{firstName} is ingepland op een klus, maar is dan afwezig.",
  "taskTypes.event-was-not-finished-task": "Afspraak is niet afgerond",
  "taskTypes.event-was-not-finished-task.title": "Afspraak {eventName} - {date} is niet afgerond",
  "taskTypes.material-is-absent-but-planned-on-event-task": "Materiaal is afwezig maar gepland voor klus",
  "taskTypes.material-is-absent-but-planned-on-event-task.title": "{name} is ingepland op een klus, maar is dan afwezig.",
  "taskTypes.plan-employees-on-event-task": "Medewerkers inplannen voor klus",
  "taskTypes.plan-employees-on-event-task.title": "Medewerkers inplannen op {eventName} - {date}",
  "taskTypes.plan-material-on-event-task": "Materiaal inplannen voor klus",
  "taskTypes.plan-material-on-event-task.title": "Voertuigen inplannen op {eventName} - {date}",
  "taskTypes.certificate-of-employee-is-about-to-expire-task": "Certificaat van medewerker gaat bijna verlopen",
  "taskTypes.certificate-of-employee-is-about-to-expire-task.title": "Certificaat van {name} gaat bijna verlopen",
  "taskTypes.employee-did-not-enter-their-hours-task": "Medewerker heeft geen uren ingevoerd",
  "taskTypes.employee-did-not-enter-their-hours-task.title": "{name} heeft geen uren ingevoerd",
  "taskTypes.employee-is-about-to-work-overtime-task": "Medewerker gaat overuren maken",
  "taskTypes.employee-is-about-to-work-overtime-task.title": "{name} gaat overuren maken",
  "taskTypes.certificate-was-not-synced-task": "Garantiecertificaat is niet gesynchroniseerd",
  "taskTypes.certificate-was-not-synced-task.title": "Garantiecertificaat voor {relationName} kon niet worden aangevraagd",
  "taskTypes.invoice-was-not-synced-task": "Factuur is niet gesynchroniseerd",
  "taskTypes.invoice-was-not-synced-task.title": "Factuur {invoiceNumber} is niet succesvol gesynchroniseerd met het boekhoudpakket",
  "taskTypes.could-not-create-new-lead-task": "Nieuwe lead kon niet worden aangemaakt",
  "taskTypes.could-not-create-new-lead-task.title": "Een nieuwe lead \"{relationName}\" kon niet worden verwerkt",
  "taskTypes.inventory-item-is-almost-depleted-task": "Voorraad is bijna op",
  "taskTypes.inventory-item-is-almost-depleted-task.title": "Voorraad van {inventoryName} is bijna op",
  "taskTypes.finish-invoice-task": "Factuur afronden",
  "taskTypes.finish-invoice-task.title": "Factuur {invoiceNumber} afronden",
  "taskTypes.invoice-has-expired-task": "Factuur is verlopen",
  "taskTypes.invoice-has-expired-task.title": "Factuur {invoiceNumber} is verlopen",
  "taskTypes.sending-invoice-failed-task": "Factuur versturen mislukt",
  "taskTypes.sending-invoice-failed-task.title": "Factuur {invoiceNumber} is niet aangekomen bij {relationName}",
  "taskTypes.send-invoice-task": "Factuur versturen",
  "taskTypes.send-invoice-task.title": "Factuur {invoiceNumber} versturen",
  "taskTypes.material-needs-to-be-inspected-task": "Materiaal moet worden geïnspecteerd",
  "taskTypes.material-needs-to-be-inspected-task.title": "{materialName} moet worden geïnspecteerd",
  "taskTypes.contact-customer-for-taxation-task": "Taxatie bij klant plannen",
  "taskTypes.contact-customer-for-taxation-task.title": "Taxatie bij {relationName} inplannen",
  "taskTypes.finish-up-project-task": "Klus van {relationName} afronden",
  "taskTypes.finish-up-project-task.title": "Klus van {relationName} afronden",
  "taskTypes.plan-picking-up-boxes-task": "Inplannen om dozen bij {relationName} op te halen",
  "taskTypes.plan-picking-up-boxes-task.title": "Inplannen om dozen bij {relationName} op te halen",
  "taskTypes.plan-deliver-boxes-task": "Inplannen om dozen voor {relationName} te leveren",
  "taskTypes.plan-deliver-boxes-task.title": "Inplannen om dozen voor {relationName} te leveren",
  "taskTypes.plan-project-task": "Klus van {relationName} inplannen",
  "taskTypes.plan-project-task.title": "Klus van {relationName} inplannen",
  "taskTypes.request-moving-certificate-task": "Garantiecertificaat voor {relationName} aanvragen",
  "taskTypes.request-moving-certificate-task.title": "Garantiecertificaat voor {relationName} aanvragen",
  "taskTypes.request-permits-task": "Vergunning voor {relationName} aanvragen",
  "taskTypes.request-permits-task.title": "Vergunning voor {relationName} aanvragen",
  "taskTypes.place-signs-task": "Borden plaatsen bij {relationName}",
  "taskTypes.place-signs-task.title": "Borden plaatsen bij {relationName}",
  "taskTypes.reserve-storage-for-project-task": "Opslag reserveren voor {relationName}",
  "taskTypes.reserve-storage-for-project-task.title": "Opslag reserveren voor {relationName}",
  "taskTypes.send-deposit-invoice-task": "Aanbetaling factuur voor {relationName} versturen",
  "taskTypes.send-deposit-invoice-task.title": "Aanbetaling factuur voor {relationName} versturen",
  "taskTypes.send-final-invoice-task": "Eindfactuur voor {relationName} versturen",
  "taskTypes.send-final-invoice-task.title": "Eindfactuur voor {relationName} versturen",
  "taskTypes.send-invoice-when-storage-was-loaded-task": "Factuur na opslag laden voor {relationName} versturen",
  "taskTypes.send-invoice-when-storage-was-loaded-task.title": "Factuur na opslag laden voor {relationName} versturen",
  "taskTypes.quote-has-expired-task": "Offerte {quotationNumber} is verlopen",
  "taskTypes.quote-has-expired-task.title": "Offerte {quotationNumber} is verlopen",
  "taskTypes.quote-was-declined-task": "Contact opnemen met {relationName} omdat offerte {quotationNumber} is afgewezen",
  "taskTypes.quote-was-declined-task.title": "Contact opnemen met {relationName} omdat offerte {quotationNumber} is afgewezen",
  "taskTypes.sending-quote-failed-task": "Offerte {quotationNumber} is niet aangekomen bij {relationName}",
  "taskTypes.sending-quote-failed-task.title": "Offerte {quotationNumber} is niet aangekomen bij {relationName}",
  "taskTypes.send-quote-task": "Offerte voor {relationName} versturen",
  "taskTypes.send-quote-task.title": "Offerte voor {relationName} versturen",
  "taskTypes.sending-of-email-failed-task": "Email {subject} naar {relationName} is niet aangekomen",
  "taskTypes.sending-of-email-failed-task.title": "Email {subject} naar {relationName} is niet aangekomen",
  "taskTypes.handle-damage-task": "Schade bij {relationName} afhandelen",
  "taskTypes.handle-damage-task.title": "Schade bij {relationName} afhandelen",
  "taskTypes.issue-with-syncing-external-employee-task": "Medewerker {employeeName} is niet correct gesynchroniseerd",
  "taskTypes.issue-with-syncing-external-employee-task.title": "Medewerker {employeeName} is niet correct gesynchroniseerd",
  "event.plannedAs": "Op deze klus als {projectRole}",
  "event.eventType.project-intake": "Taxatie",
  "event.eventType.moving-job-pickup-boxes": "Dozen ophalen",
  "event.eventType.moving-job-deliver-boxes": "Dozen leveren",
  "event.eventType.moving-job-assemble": "Monteren",
  "event.eventType.moving-job-disassemble": "Demonteren",
  "event.eventType.moving-job-post-road-signs": "Borden plaatsen",
  "event.eventType.moving-job-remove-road-signs": "Borden ophalen",
  "event.eventType.moving-job-load-storage": "Opslag laden",
  "event.eventType.moving-job-unload-storage": "Opslag lossen",
  "event.eventType.moving-job-moving-loading": "Verhuizing laden",
  "event.eventType.moving-job-moving-unloading": "Verhuizing lossen",
  "event.eventType.moving-job-packing": "Inpakken",
  "event.eventType.moving-job-unpacking": "Uitpakken",
  "event.eventType.moving-job-moving": "Verhuizing",
  "event.eventType.moving-job-moving-lift": "Verhuislift",
  "event.eventType.moving-job-disposing-of-waste": "Afval afvoeren",
  "event.eventType.moving-job-prepare-house-according-to-quote": "Opleveren",
  "event.eventType.transport": "Transport",
  "event.eventType.transport-job-pickup": "Laden",
  "event.eventType.transport-job-deliver": "Lossen",
  "event.eventType.project-driving-day": "Rij dag",
  "event.eventType.custom": "Aangepast",
  "event.eventType.meeting": "Meeting",
  "event.eventType.lift-work": "Lift werkzaamheden",
  "event.eventType.office-work": "Kantoor werkzaamheden",
  "event.eventType.warehouse-work": "Loods werkzaamheden",
  "event.eventType.lending-staff": "Medewerkers uitlenen",
  "mobileApp.event.eventIsNotOnTheSameDay.title": "Je kunt deze klus nog niet starten",
  "mobileApp.event.eventIsNotOnTheSameDay.description": "Je kunt alleen klussen starten die voor vandaag gepland staan. Als je vandaag begint aan een klus die niet in je planning staat, neem dan contact op met de backoffice.",
  "mobileApp.event.eventType.project-intake": "Taxatie",
  "mobileApp.event.eventType.moving-job-pickup-boxes": "Dozen ophalen",
  "mobileApp.event.eventType.moving-job-deliver-boxes": "Dozen leveren",
  "mobileApp.event.eventType.moving-job-assemble": "Monteren",
  "mobileApp.event.eventType.moving-job-disassemble": "Demonteren",
  "mobileApp.event.eventType.moving-job-post-road-signs": "Borden plaatsen",
  "mobileApp.event.eventType.moving-job-remove-road-signs": "Borden ophalen",
  "mobileApp.event.eventType.moving-job-load-storage": "Opslag laden",
  "mobileApp.event.eventType.moving-job-unload-storage": "Opslag lossen",
  "mobileApp.event.eventType.moving-job-moving-loading": "Verhuizing laden",
  "mobileApp.event.eventType.moving-job-moving-unloading": "Verhuizing lossen",
  "mobileApp.event.eventType.moving-job-packing": "Inpakken",
  "mobileApp.event.eventType.moving-job-unpacking": "Uitpakken",
  "mobileApp.event.eventType.moving-job-moving": "Verhuizing",
  "mobileApp.event.eventType.moving-job-moving-lift": "Verhuislift",
  "mobileApp.event.eventType.moving-job-disposing-of-waste": "Afval afvoeren",
  "mobileApp.event.eventType.moving-job-prepare-house-according-to-quote": "Opleveren",
  "mobileApp.event.eventType.transport": "Transport",
  "mobileApp.event.eventType.transport-job-pickup": "Laden",
  "mobileApp.event.eventType.transport-job-deliver": "Lossen",
  "mobileApp.event.eventType.project-driving-day": "Rij dag",
  "mobileApp.event.eventType.custom": "Aangepast",
  "mobileApp.event.eventType.meeting": "Meeting",
  "mobileApp.event.eventType.lift-work": "Lift werkzaamheden",
  "mobileApp.event.eventType.office-work": "Kantoor werkzaamheden",
  "mobileApp.event.eventType.warehouse-work": "Loods werkzaamheden",
  "mobileApp.event.eventType.lending-staff": "Collega verhuizer helpen",
  "mobileApp.event.eventType.hrm-day-off-event": "Dagje vrij",
  "mobileApp.event.eventType.hrm-other-absence-event": "Afwezig",
  "mobileApp.event.eventType.hrm-sick-event": "Ziek",
  "mobileApp.event.eventType.hrm-vacation-event": "Vakantie!",
  "mobileApp.event.eventType.material-event-inspection-event": "Materiaal keuring",
  "mobileApp.event.eventType.material-event-maintenance-event": "Onderhoud",
  "mobileApp.event.eventType.material-event-other-absence-event": "Afwezig",
  "mobileApp.event.unknownAppraiser": "Onbekende taxateur",
  "mobileApp.event.noIntakeDone": "Er heeft geen taxatie plaatsgevonden",
  "mobileApp.event.disassemblyOrAssembly.itemCount": "{totalItemsCount} items - {finishedItemsCount} afgevinkt",
  "mobileApp.event.disassemblyOrAssembly.disassemble.title": "Demonteerlijst",
  "mobileApp.event.disassemblyOrAssembly.assemble.title": "Montagelijst",
  "mobileApp.event.furnitureList.title": "Inboedellijst",
  "mobileApp.event.furnitureList.description": "Hieronder zie je een lijst met meubels die moeten worden verhuist.",
  "mobileApp.event.finalizeEvent.title": "Klus afronden",
  "mobileApp.event.finalizeEvent.youStartedAtXAndItWasUntilX": "Je hebt doorgegeven de klus begon om {startTime} en de schatting was dat het tot {endTime} heeft geduurd.",
  "mobileApp.event.finalizeEvent.theEventWasFromXUntilX": "Volgens de schatting zou de klus van {startTime} tot {endTime} hebben geduurd.",
  "mobileApp.event.fillInHours.title": "Uren doorgeven",
  "mobileApp.event.fillInHours.theEventWasFromXUntilX": "Volgens de schatting zou de klus van {startTime} tot {endTime} hebben geduurd.",
  "mobileApp.event.fillInHours.didYouHaveToTravel": "Heb je moeten reizen?",
  "mobileApp.event.fillInHours.theTravelTimeWasFromXUntilX": "En zou de vertrektijd om {startTime} zijn en om {endTime} weer terug zijn gekomen.",
  "mobileApp.event.unloadStorage.title": "Opslag lossen",
  "mobileApp.event.unloadStorage.description": "Geef hieronder aan wat je hebt gelost.",
  "mobileApp.event.unloadStorage.confirm": "Vewerken",
  "mobileApp.event.loadStorage.title": "Opslag laden",
  "mobileApp.event.loadStorage.description": "Geef hieronder aan wat je hebt geladen.",
  "mobileApp.event.loadStorage.confirm": "Verwerken",
  "mobileApp.event.deliverMovingBoxes.title": "Dozen leveren",
  "mobileApp.event.deliverMovingBoxes.description": "Vul hieronder in hoeveel dozen je hebt geleverd.",
  "mobileApp.event.deliverMovingBoxes.confirm": "Leveren",
  "mobileApp.event.pickupMovingBoxes.title": "Dozen ophalen",
  "mobileApp.event.pickupMovingBoxes.description": "Vul hieronder in hoeveel dozen je hebt opgehaald.",
  "mobileApp.event.pickupMovingBoxes.confirm": "Ophalen",
  "mobileApp.event.pickupMovingBoxes.areThereBoxesWornOut": "Zijn er dozen versleten?",
  "mobileApp.event.loadOrUnloadStorage.noStorages": "Er is geen opslag gekoppeld aan deze klus.",
  "mobileApp.event.pickupMovingBoxes.whatIsWorn": "Vul hieronder in hoeveel dozen er zijn versleten.",
  "mobileApp.event.takeMaterials.title": "Materiaal meenemen",
  "mobileApp.event.takeMaterials.description": "Vul hieronder in hoeveel van welk je materiaal je meeneemt naar de klus.",
  "mobileApp.event.takeMaterials.confirm": "Doorgeven",
  "mobileApp.event.retrieveMaterials.title": "Materiaal terugbrengen",
  "mobileApp.event.retrieveMaterials.description": "Vul hieronder in hoeveel van welk materiaal je mee terugneemt naar de loods.",
  "mobileApp.event.retrieveMaterials.confirm": "Doorgeven",
  "mobileApp.event.retrieveMaterials.areThereMaterialsWornOut": "Is er materiaal op of versleten?",
  "mobileApp.event.retrieveMaterials.whatIsWorn": "Vul hieronder in welk materiaal op of versleten is.",
  "mobileApp.event.quantityNeeded": "Aantal nodig",
  "mobileApp.event.quantityOutstanding": "Bij de klant",
  "mobileApp.event.quantityUsed": "Vebruikt",
  "mobileApp.event.quantitySold": "Verkocht",
  "financial.quote.signQuote.title": "Offerte {quotationNumber} voor {name}",
  "financial.quote.signQuote.subTitle": "Offerte accepteren",
  "financial.quote.signQuote.description": "Zorg dat de gewenste optionele offerteregels hieronder geselecteerd zijn en zet je online handtekening om deze offerte te accepteren.",
  "financial.quote.signQuote.button.acceptQuote": "Accepteer offerte",
  "financial.quote.signQuote.hourlyRateLines": "Uurtarieven",
  "financial.quote.signQuote.normalLines": "Offerteregels",
  "financial.quote.signQuote.toManyPages": "Let op: De offerte bevat meer dan 3 pagina's, hierdoor wordt deze niet compleet getoond. Download de offerte als pdf om de hele offerte in te zien.",
  "financial.quote.signQuote.finished.title": "Bedankt voor het accepteren van de offerte!",
  "financial.quote.signQuote.finished.message": "{tenant} zal spoedig contact met u opnemen.",
  "payment.invoice.open.title": "Bedankt voor het betalen van factuur {invoiceNumber}.",
  "payment.invoice.open.message": "Wij zullen nu uw betaling verwerken.",
  "payment.invoice.pending.title": "Bedankt voor het betalen van factuur {invoiceNumber}.",
  "payment.invoice.pending.message": "Wij zullen nu uw betaling verwerken.",
  "payment.invoice.canceled.title": "U heeft de betaling voor factuur {invoiceNumber} geannuleerd.",
  "payment.invoice.canceled.message": "U kunt de betaling opnieuw starten door op de link in uw mail te klikken, mocht u hier niet uitkomen neem dan contact op met {tenant}.",
  "payment.invoice.expired.title": "Uw betaling voor factuur {invoiceNumber} is verlopen.",
  "payment.invoice.expired.message": "U kunt de betaling opnieuw starten door op de link in uw mail te klikken, mocht u hier niet uitkomen neem dan contact op met {tenant}.",
  "payment.invoice.failed.title": "Uw betaling voor factuur {invoiceNumber} is mislukt.",
  "payment.invoice.failed.message": "U kunt de betaling opnieuw starten door op de link in uw mail te klikken, mocht u hier niet uitkomen neem dan contact op met {tenant}.",
  "payment.invoice.paid.title": "Bedankt voor het betalen van factuur {invoiceNumber}.",
  "payment.invoice.paid.message": "Uw betaling is succesvol verwerkt.",
  "payment.invoice.was-already-paid.title": "Factuur {invoiceNumber} is al betaald.",
  "payment.invoice.was-already-paid.message": "Mocht dit niet juist zijn dan kunt u contact opnemen met {tenant}.",
  "projects.listedProject.notPlannedYet": "Niet gepland",
  "projects.headers.name": "Naam",
  "projects.headers.date": "Datum",
  "projects.headers.fromTo": "Van / naar",
  "projects.headers.status": "Stadium",
  "projects.transportJobDetails.tabs.cargo": "Lading",
  "projects.movingJobDetails.tabs.description": "Beschrijving",
  "projects.movingJobDetails.tabs.quote": "Offerte",
  "projects.movingJobDetails.tabs.financial": "Financieel",
  "projects.movingJobDetails.tabs.crews": "Teams",
  "projects.movingJobDetails.tabs.employees": "Mensen",
  "projects.movingJobDetails.tabs.vehicles": "Voertuigen",
  "projects.movingJobDetails.tabs.materials": "Materiaal",
  "projects.movingJobDetails.tabs.storage": "Opslag",
  "projects.movingJobDetails.tabs.hours": "Uren",
  "projects.movingJobDetails.tabs.events": "Afspraken",
  "projects.movingJobDetails.tabs.damages": "Schades",
  "projects.movingJobDetails.tabs.costs": "Kosten",
  "projects.movingJobDetails.tabs.documents": "Documenten",
  "projects.movingJobDetails.tabs.communication": "Communicatie",
  "projects.movingJobDetails.tabs.activity": "Activiteit",
  "projects.movingJobDetails.reserveStorage": "Opslag {storageCode} reserveren voor {customerName}",
  "projects.movingJobDetails.movingDetails": "Verhuizing",
  "projects.movingJobDetails.movingDetails.summary": "Samenvatting",
  "projects.movingJobDetails.movingDetails.hours": "Uren",
  "projects.movingJobDetails.eventDates": "Data",
  "projects.movingJobDetails.eventDates.nothingPlanned": "Er zijn geen afspraken gepland",
  "projects.movingJobDetails.recurringEventDates": "Herhalende afspraken",
  "projects.movingJobDetails.recurringEventDates.nothingPlanned": "Er zijn geen herhalende afspraken gepland",
  "projects.movingJobDetails.movingDetails.numberOfEmployees": "{value, plural,\n    =0 {geen verhuizers}\n  =1 {# verhuizer}\n    other {# verhuizers}\n}",
  "projects.movingJobDetails.movingDetails.numberOfHandyMan": "{value, plural,\n    =0 {geen handyman}\n  =1 {# handyman}\n    other {# handyman}\n}",
  "projects.movingJobDetails.movingDetails.numberOfPackers": "{value, plural,\n    =0 {geen inpakkers}\n  =1 {# inpakker}\n    other {# inpakkers}\n}",
  "projects.movingJobDetails.movingDetails.totalHours": "{value} uur",
  "projects.movingJobDetails.movingDetails.calculatedValue": "Berekende waarde was: {value, number}{suffix}",
  "projects.movingJobDetails.movingDetails.calculatedTime": "Berekende waarde was: {value}",
  "projects.transportJobDetails.transportDetails": "Transport",
  "projects.transportJobDetails.transportDetails.summary": "Samenvatting",
  "projects.damageDetails.tabs.details": "Details",
  "projects.projectDetails.estimatePrices": "Tarieven",
  "projects.projectDetails.calculatedValue": "Berekende waarde was: {value, number}{suffix}",
  "projects.projectDetails.pricePerCubicMeter": "{value} per {suffix}",
  "projects.projectDetails.pricePerHour": "{value} per uur",
  "projects.projectDetails.areYouSureYouWantToCancelProject.title": "Klus voor \"{name}\" annuleren",
  "projects.projectDetails.areYouSureYouWantToCancelProject.content": "Weet je zeker dat je het klus voor \"{name}\" wilt annuleren?",
  "projects.projectDetails.areYouSureYouWantToReopenProject.title": "Klus voor \"{name}\" heropenen",
  "projects.projectDetails.areYouSureYouWantToReopenProject.content": "Weet je zeker dat je het klus voor \"{name}\" wilt heropenen?",
  "projects.projectDetails.areYouSureYouWantToSkipQuote.title": "Offerte voor \"{name}\" overslaan",
  "projects.projectDetails.areYouSureYouWantToSkipQuote.content": "Weet je zeker dat je de offerte voor \"{name}\" wilt overslaan?",
  "projects.projectDetails.areYouSureYouWantToFinishProject.title": "Klus \"{name}\" afronden",
  "projects.projectDetails.areYouSureYouWantToFinishProject.content": "Weet je zeker dat je het klus voor \"{name}\" wilt afronden?",
  "projects.projectDetails.areYouSureYouWantToMoveProjectToAftercare.title": "Klus \"{name}\" naar afhandelen",
  "projects.projectDetails.areYouSureYouWantToMoveProjectToAftercare.content": "Weet je zeker dat je het klus voor \"{name}\" wilt verplaatsen naar afhandelen? Doe dit alleen als alle afspraken zijn geweest en de klus verder klaar is.",
  "projects.projectDetails.areYouSureYouReceivedTheDeposit.title": "Aanbetaling voor klus \"{name}\" ontvangen",
  "projects.projectDetails.areYouSureYouReceivedTheDeposit.content": "Weet je zeker dat je de aanbetaling voor klus \"{name}\" hebt ontvangen? Gebruik deze knop alleen als de betaling niet automatisch is verwerkt.",
  "projects.projectDetails.createAndSendInvoice.title": "{billingMoment} versturen",
  "projects.projectDetails.createAndSendQuote.title": "Offerte versturen",
  "projects.projectDetails.emptyStorage.title": "Alle opslag voor klus van \"{name}\" lossen en vrijmaken",
  "projects.projectDetails.emptyStorage.content": "Je staat op het punt om alle opslag voor het klus op te zeggen.\nDit maakt direct de opslag leeg als er nog iets in staat en zet een concept factuur klaar voor t/m de datum die hieronder wordt ingegeven.",
  "projects.projectDetails.notes": "Notities",
  "projects.projectDetails.generalNotes": "Algemeen",
  "projects.projectDetails.notesForEmployees": "Voor de medewerker",
  "projects.projectDetails.customerNotes": "Voor de klant",
  "projects.projectDetails.neededInventoryItems": "Benodigd materiaal volgens taxateur",
  "projects.projectDetails.editPackage": "Financiële afspraken voor {name} aanpassen",
  "projects.projectDetails.costs.editCosts": "Kosten aanpassen",
  "projects.projectDetails.costs.addCosts": "Kosten toevoegen",
  "projects.movingJobDetails.addresses.addAddress": "Adres toevoegen",
  "projects.movingJobQuote.summary": "Samenvatting",
  "projects.movingJobQuote.quotes": "Uitgrachte offertes",
  "projects.movingJobEmployees.occupationFor": "Toon bezetting voor",
  "projects.movingJobEmployees.noEmployeesPlanned": "Er zijn geen planbare rollen bekend, hieronder kan je rollen toevoegen.",
  "projects.movingJobEmployees.addPeopleToTeam": "Mensen toevoegen aan team",
  "projects.movingJobEmployees.availableEmployees": "{numberOfEmployees, number} beschikbaar",
  "projects.movingJobEmployees.potentialEmployees": "{numberOfEmployees, number} misschien",
  "projects.movingJobVehicles.occupationFor": "Toon bezetting voor",
  "projects.movingJobVehicles.noMaterialsPlanned": "Er is geen voertuig gepland.",
  "projects.movingJobVehicles.addVehicle": "Voeg voertuig toe",
  "projects.movingJobVehicles.availableVehicles": "{numberOfMaterials, number} beschikbaar",
  "projects.movingJobMaterials.occupationFor": "Toon materiaal voor",
  "projects.projectHours.plannedMessage": "De klus stond gepland van {plannedStart} tot {plannedEnd}",
  "projects.projectHours.approveProjectHours.title": "Uren van {employee} goedkeuren",
  "projects.projectHours.changeProjectHours.title": "Uren van {employee} bewerken",
  "projects.projectHours.enterProjectHours.title": "Uren van {employee} doorgeven",
  "showEmails.resendEmail.title": "{subject} opnieuw versturen",
  "customers.headers.customerNumber": "Klantnummer",
  "customers.headers.name": "Naam",
  "customers.headers.phoneNumber": "Telefoonnummer",
  "customers.headers.city": "Woonplaats",
  "relation.addRelation.title": "Nieuwe klant aanmaken",
  "relation.addressType.primary": "Hoofd",
  "relation.addressType.invoice": "Facturatie",
  "relation.addressType.postal": "Post",
  "relation.addressType.company": "Zakelijk",
  "relation.addressType.private": "Privé",
  "relation.addressType.other": "Overig",
  "relation.addressType.validationError": "Je kan maar één adres met het type {addressType} hebben.",
  "relation.addresses.noAddresses": "Er zijn geen adressen bekend",
  "relation.contactPersonType.primary": "Primaire",
  "relation.contactPersonType.invoice": "Facturatie",
  "relation.contactPersonType.other": "Overig",
  "relation.contactPersonType.validationError": "Je kan maar één contactpersoon met type {contactPersonType} hebben.",
  "relation.contactPersons.noContractPersons": "Er zijn geen contactpersonen bekend",
  "relation.contactPersons.addContactPerson": "Contactpersoon tovoegen",
  "customers.customerDetails.areYouSureYouWantToDeleteCustomer.title": "Klant {customerName} verwijderen",
  "customers.customerDetails.areYouSureYouWantToDeleteCustomer.content": "Let op als je klant {customerName} verwijderd, zal alle informatie die hier aan gekoppeld is bewaard blijven maar anoniem zijn.\n\nHoud er rekening mee dat dit consequenties heeft, zoals dat je niet meer weet bij wie je voorraad is of van wie een bepaalde klus was.",
  "customers.customerDetails.areYouSureYouWantToDeleteDocument.title": "Document {name} verwijderen",
  "customers.customerDetails.areYouSureYouWantToDeleteDocument.content": "Weet je zeker dat je document {name} wilt verwijderen? Dit kan niet ongedaan worden gemaakt.",
  "customers.customerDetails.emptyAllStoragesForCustomer.title": "Alle opslag beëindigen voor {name}",
  "customers.customerDetails.emptyAllStoragesForCustomer.content": "Je staat op het punt om alle opslag te legen voor {name}. Weet je dit zeker? Dit kan niet ongedaan worden gemaakt.",
  "customers.customerDetails.tabs.customerInformation": "Klantgegevens",
  "customers.customerDetails.tabs.documents": "Documenten",
  "customers.customerDetails.tabs.communication": "Communicatie",
  "customers.customerDetails.tabs.activity": "Activiteit",
  "customers.customerDetails.tabs.quotes": "Offertes",
  "customers.customerDetails.tabs.invoices": "Facturen",
  "customers.customerDetails.tabs.projects": "Klussen",
  "customers.customerDetails.tabs.storages": "Opslag",
  "customers.customerDetails.tabs.damages": "Schades",
  "customers.customerDetails.tabs.materials": "Materiaal",
  "quotes.button.newQuote": "Nieuwe offerte",
  "invoices.button.newInvoice": "Nieuwe factuur",
  "financial.quoteDetails.tabs.quote": "Offerte",
  "financial.quoteDetails.tabs.activity": "Activiteit",
  "financial.quoteDetails.tabs.project": "Klus",
  "financial.quoteDetails.tabs.communication": "Communicatie",
  "financial.quoteDetails.quoteInformation": "Offerte gegevens",
  "financial.quoteDetails.quoteLines": "Offerteregels",
  "financial.quoteDetails.acceptanceInformation": "Acceptatie gegevens",
  "financial.quoteDetails.acceptanceInformation.acceptedBy": "Geaccepteerd door {name} op {eventDate}",
  "financial.quoteDetails.areYouSureYouWantToDeleteQuote.title": "Offerte {quotationNumber} verwijderen",
  "financial.quoteDetails.areYouSureYouWantToDeleteQuote.content": "Weet je zeker dat je offerte {quotationNumber} wilt verwijderen?",
  "financial.quoteDetails.areYouSureYouWantToCancel.title": "Offerte {quotationNumber} annuleren",
  "financial.quoteDetails.areYouSureYouWantToCancel.content": "Weet je zeker dat je offerte {quotationNumber} wilt annuleren?",
  "financial.quoteDetails.sendQuote": "Offerte {quotationNumber} versturen",
  "financial.quoteDetails.reminderQuote": "Herinnering voor offerte {quotationNumber} versturen",
  "financial.quoteDetails.resendQuote": "Offerte {quotationNumber} opnieuw versturen",
  "financial.quoteDetails.hasTheCustomerAcceptedTheQuote.title": "Klant is akkoord met offerte {quotationNumber}",
  "financial.quoteDetails.hasTheCustomerAcceptedTheQuote.content": "Hieronder zie je de regels waarop akkoord is gegeven, mochten er optionele regels zijn die niet zijn aangevinkt dan zijn deze niet meegenomen in de offerte.",
  "financial.quoteDetails.hasTheCustomerDeclinedTheQuote.title": "Klant heeft offerte {quotationNumber} afgewezen",
  "financial.quoteDetails.declineAndCopyQuote.title": "Offerte {quotationNumber} afwijzen en nieuwe versie maken",
  "financial.quoteDetails.invoiceQuote.title": "Offerte {quotationNumber} factureren",
  "financial.financialDocument.invoiceContactPerson": "Factuurcontactpersoon",
  "financial.financialDocument.quoteContactPerson": "Offertecontactpersoon",
  "financial.financialDocument.invoiceAddress": "Factuuradres",
  "financial.financialDocument.quoteAddress": "Offerteadres",
  "financial.financialDocument.noAddress": "Let op! Er is geen adres, voeg een adres toe",
  "financial.financialDocument.newAddress": "Nieuw adres toevoegen",
  "financial.financialDocument.newContactPerson": "Nieuw contactpersoon toevoegen",
  "financial.financialDocument.customerInformation": "Klantgegevens",
  "financial.invoiceDetails.tabs.invoice": "Factuur",
  "financial.invoiceDetails.tabs.activity": "Activiteit",
  "financial.invoiceDetails.tabs.payments": "Betalingen",
  "financial.invoiceDetails.tabs.project": "Klus",
  "financial.invoiceDetails.areYouSureYouWantToRerenderInvoice.title": "Factuur opnieuw renderen",
  "financial.invoiceDetails.areYouSureYouWantToRerenderInvoice.content": "Weet je zeker dat je de factuur opnieuw wilt renderen? Dit kan niet ongedaan worden gemaakt.",
  "financial.invoiceDetails.areYouSureYouWantToDeleteInvoice.title": "Factuur verwijderen",
  "financial.invoiceDetails.areYouSureYouWantToDeleteInvoice.content": "Weet je zeker dat je de factuur {invoiceNumber} wilt verwijderen?",
  "financial.invoiceDetails.areYouSureYouWantToDeletePayment.title": "Betaling verwijderen",
  "financial.invoiceDetails.areYouSureYouWantToDeletePayment.content": "Weet je zeker dat je de betaling wilt verwijderen? De factuur kan opnieuw als open worden gemarkeerd.",
  "financial.invoiceDetails.areYouSureYouWantToCancelScheduledInvoice.title": "Geplande factuur annuleren",
  "financial.invoiceDetails.areYouSureYouWantToCancelScheduledInvoice.content": "Weet je zeker dat je de geplande factuur wilt annuleren?",
  "financial.invoiceDetails.invoiceInformation": "Factuur gegevens",
  "financial.invoiceDetails.invoiceLines": "Factuurregels",
  "financial.invoiceDetails.finalizeInvoice": "Factuur {invoiceNumber} afronden",
  "financial.invoiceDetails.sendInvoice": "Factuur {invoiceNumber} versturen",
  "financial.invoiceDetails.resendInvoice": "Factuur {invoiceNumber} opnieuw versturen",
  "financial.invoiceDetails.sendInvoiceReminder": "Herinnering voor factuur {invoiceNumber} versturen",
  "financial.invoiceDetails.sendInvoiceReminder.first": "Eerste herinnering voor factuur {invoiceNumber} versturen",
  "financial.invoiceDetails.sendInvoiceReminder.second": "Tweede herinnering voor factuur {invoiceNumber} versturen",
  "financial.invoiceDetails.sendInvoiceReminder.third": "Derde herinnering voor factuur {invoiceNumber} versturen",
  "financial.invoiceDetails.sendInvoiceReminder.last": "Aanmaning voor factuur {invoiceNumber} versturen",
  "financial.invoiceDetails.markInvoiceAsIrrecoverable": "Factuur {invoiceNumber} afschrijven als oninbaar",
  "financial.invoiceDetails.processInvoicePayment": "Betaling verwerken voor factuur {invoiceNumber}",
  "financial.invoiceDetails.scheduleInvoice": "Factuur {invoiceNumber} inplannen",
  "financial.invoiceDetails.createCreditInvoice": "Creditfactuur voor factuur {invoiceNumber} maken",
  "financial.invoiceDetails.connectToProject": "Factuur {invoiceNumber} koppelen aan een klus",
  "financial.quoteDetails.connectToProject": "Offerte {quotationNumber} koppelen aan een klus",
  "financial.invoiceDetails.editPayment": "Betaling bewerken",
  "financial.invoiceStatistics.thisWeek": "Deze week",
  "financial.invoiceStatistics.thisMonth": "Deze maand",
  "financial.invoiceStatistics.thisQuarter": "Dit kwartaal",
  "financial.invoiceStatistics.thisYear": "Dit jaar",
  "financial.invoiceStatistics.openInvoicesValue": "open aan facturen",
  "financial.invoiceStatistics.totalInvoicesValue": "aan verstuurde facturen",
  "financial.invoiceStatistics.expiredInvoicesValue": "aan verlopen facturen",
  "financial.invoiceStatistics.conceptInvoicesValue": "aan concept facturen",
  "financial.invoiceStatistics.averageDaysText": "Gemiddeld staat een factuur {averageDays} open.",
  "financial.invoiceStatistics.compareDaysText": "Dat is {compareDays} dan vorige maand.",
  "financial.invoiceStatistics.days": "{averageDays, plural,\n    =1 {# dag}\n    other {# dagen}\n}",
  "financial.invoiceBulkAction.actions.finalize.title": "Facturen afronden",
  "financial.invoiceBulkAction.actions.finalize.button": "{numberOfAvailable, plural,\n    =1 {# factuur}\n    other {# facturen}\n} afronden",
  "financial.invoiceBulkAction.actions.finalizeAndSend.title": "Facturen afronden en versturen",
  "financial.invoiceBulkAction.actions.finalizeAndSend.button": "{numberOfAvailable, plural,\n    =1 {# factuur}\n    other {# facturen}\n} afronden en versturen",
  "financial.invoiceBulkAction.actions.delete.title": "Facturen verwijderen",
  "financial.invoiceBulkAction.actions.delete.button": "{numberOfAvailable, plural,\n    =1 {# factuur}\n    other {# facturen}\n} verwijderen",
  "financial.invoiceBulkAction.actions.send.title": "Facturen versturen",
  "financial.invoiceBulkAction.actions.send.button": "{numberOfAvailable, plural,\n    =1 {# factuur}\n    other {# facturen}\n} versturen",
  "financial.invoiceBulkAction.actions.sendReminder.title": "Herinneringen versturen voor facturen",
  "financial.invoiceBulkAction.actions.sendReminder.button": "{numberOfAvailable} herinneringen versturen",
  "financial.quoteStatistics.openQuotesValue": "open aan offertes",
  "financial.quoteStatistics.acceptedQuotesValue": "aan geaccepteerde offertes",
  "financial.quoteStatistics.declinedQuotesValue": "aan afgewezen offertes",
  "financial.quoteStatistics.averageDaysText": "Gemiddeld staat een offerte {averageDays} open.",
  "financial.quoteStatistics.compareDaysText": "Dat is {compareDays} dan vorige maand.",
  "financial.quoteStatistics.days": "{averageDays, plural,\n    =1 {# dag}\n    other {# dagen}\n}",
  "resources.teamDetails.tabs.team": "Team",
  "resources.teamDetails.teamInformation": "Team gegevens",
  "resources.teamDetails.teamMembers": "Leden van het team",
  "resources.teamDetails.canNotCreateTeams": "Je kan geen teams maken, omdat alle verantwoordelijkheden al zijn gebruikt.",
  "resources.teamDetails.createTeam.title": "Niew team maken",
  "resources.teamDetails.replaceTeam.title": "Team verwijderen",
  "resources.storageDetails.tabs.storage": "Opslag",
  "resources.storageDetails.tabs.storedItems": "Opgeslagen items",
  "resources.storageDetails.tabs.volumeChanges": "Volume wijzigingen",
  "resources.storageDetails.tabs.handlingCosts": "Handlingkosten",
  "resources.storageDetails.tabs.documents": "Documenten",
  "resources.storageDetails.tabs.activity": "Activiteit",
  "resources.storageDetails.storageSpecifications": "Specificaties",
  "resources.storageDetails.storageLocation": "Locatie",
  "resources.storageDetails.contract": "Contract",
  "resources.storageDetails.storageIsFree": "De opslag is nog vrij.",
  "resources.storageDetails.nothingInStorage": "Opslag is nog leeg",
  "resources.storageDetails.loadedByOn": "Op {loadedOn} door {handledBy}",
  "resources.storageDetails.loadedBy": "Geladen door",
  "resources.storageDetails.areYouSureYouWantToDeleteStorage.title": "Opslag {storageCode} verwijderen",
  "resources.storageDetails.areYouSureYouWantToDeleteStorage.content": "Weet je zeker dat je de opslag wilt verwijderen? Wij kunnen hem dan niet meer terughalen.",
  "resources.storageDetails.loadStorage": "Laden",
  "resources.storageDetails.loadUnit": "{storageTypeName} laden",
  "resources.storageDetails.storageTypeIsStillEmpty": "{storageTypeName} is nog leeg",
  "resources.storageDetails.loadAndUnloadItemsFromStorage": "{storageCode} laden en lossen",
  "resources.storageDetails.emptyStorage.title": "{storageCode} lossen en vrijmaken",
  "resources.storageDetails.emptyStorage.content": "Je staat op het punt om de opslag op te zeggen.\nDit maakt direct de opslag leeg en zet een concept factuur klaar voor t/m de datum die hieronder wordt ingegeven.",
  "resources.storageDetails.createStorage.title": "Nieuwe opslag toevoegen",
  "resources.storageDetails.createStorage.content": "Je kan opslag in bulk toevoegen door het excel bestand in te vullen, ook is het mogelijk hieronder het formulier in te vullen.",
  "resources.storageDetails.addHandlingCosts.title": "Handlingkosten toevoegen",
  "resources.storageDetails.addHandlingCosts.content": "De handlingkosten worden aan het einde van de maand en automatisch op factuur gezet.",
  "resources.storageDetails.moveStorage.title": "Opslag verplaatsen",
  "resources.storageDetails.moveStorage.content": "Je staat op het punt om de opslag te verplaatsen naar een andere opslaglocatie en of loods.",
  "resources.storageDetails.returnStorageToWarehouse.title": "Opslag terugzetten in de loods",
  "resources.storageDetails.returnStorageToWarehouse.content": "Waar zet je de opslag terug in de loods?",
  "resources.storageDetails.positionStorageForPickup.title": "Opslag klaarzetten voor ophalen",
  "resources.storageDetails.positionStorageForPickup.content": "Je staat op het punt om de opslag klaar te zetten voor ophalen.",
  "resources.storageDetails.pickUpStorage.title": "Opslag ophalen",
  "resources.storageDetails.pickUpStorage.content": "Je staat op het punt om de opslag op te halen.",
  "resources.movingBoxDetails.tabs.movingBox": "Doos",
  "resources.movingBoxDetails.tabs.outstanding": "Uitstaand",
  "resources.movingBoxDetails.tabs.suppliers": "Leveranciers",
  "resources.movingBoxDetails.tabs.orders": "Orders",
  "resources.movingBoxDetails.movingBoxSpecifications": "Doos specificaties",
  "resources.movingBoxDetails.newTypeOfMovingBox": "Nieuwe type doos aanmaken",
  "resources.reusableMaterialDetails.newReusableMaterial": "Nieuw type materiaal toevoegen",
  "resources.reusableMaterialDetails.tabs.reusableMaterial": "Materiaal",
  "resources.reusableMaterialDetails.tabs.outstanding": "Uitstaand",
  "resources.reusableMaterialDetails.tabs.suppliers": "Leveranciers",
  "resources.reusableMaterialDetails.tabs.orders": "Orders",
  "resources.reusableMaterialDetails.reusableMaterialSpecifications": "Materiaal specificaties",
  "resources.reusableMaterialDetails.newTypeOfReusableMaterial": "Nieuwe materiaal aanmaken",
  "resources.inventoryItems.noSuppliersFound": "Er zijn nog geen leveranciers",
  "resources.inventoryItems.addNewInventorySupplier.title": "Leverancier van {inventoryItemName} toevoegen",
  "resources.inventoryItems.addNewInventorySupplier.content": "Er zijn nog geen leveranciers",
  "resources.inventoryDetails.orderInventory.title": "Bestelling doorgeven",
  "resources.inventoryDetails.receivedOrderedInventory.title": "Bestelling binnenmelden",
  "resources.inventoryDetails.throwOutInventory.title": "Spullen weggooien",
  "resources.inventoryDetails.sellInventory.title": "Spullen verkopen",
  "resources.inventoryDetails.rentInventory.title": "Spullen verhuren",
  "resources.inventoryDetails.gotInventoryItemsBack.title": "Spullen retourmelden",
  "resources.vehicleDetails.tabs.vehicle": "Voertuig",
  "resources.vehicleDetails.tabs.maintenanceHistory": "Onderhoudshistorie",
  "resources.vehicleDetails.tabs.mileageReports": "Rittenregistratie",
  "resources.vehicleDetails.vehicleSpecifications": "Voertuiggegevens",
  "resources.driversLicenseDetails.driversLicense": "Rijbewijs",
  "resources.vehicleDetails.vehicleMaintenance": "Onderhoud",
  "resources.vehicleDetails.vehicleFuelCard": "Tankpas",
  "resources.vehicleDetails.createVehicle": "Nieuw voertuig aanmaken",
  "resources.vehicleDetails.areYouSureYouWantToDeleteVehicle.title": "Voertuig {name} verwijderen",
  "resources.vehicleDetails.areYouSureYouWantToDeleteVehicle.content": "Het voertuig zal van alle geplande afspraken worden verwijderd, dit kan niet worden teruggedraait.",
  "resources.employeeDetails.tabs.employee": "Medewerker",
  "resources.employeeDetails.tabs.sickLeave": "Ziekmeldingen",
  "resources.employeeDetails.tabs.availability": "Beschikbaarheid",
  "resources.employeeDetails.tabs.timeOff": "Verlof",
  "resources.employeeDetails.tabs.invoicableHours": "Factureerbare uren",
  "resources.employeeDetails.tabs.hours": "Uren",
  "resources.employeeDetails.employeeInformation": "Medewerker informatie",
  "resources.employeeDetails.createEmployee": "Medewerker",
  "resource.employeeHours.approveHours.title": "Uren goedkeuren",
  "resource.employeeHours.hoursEntryOverview.title": "Uren overzicht",
  "resource.employeeHours.enterHours.title": "Uren doorgeven",
  "resource.employeeHours.editHours.title": "Uren bewerken",
  "resource.employeeHours.areYouSureYouWantToRemoveEntry.title": "Uren verwijderen",
  "resource.employeeHours.areYouSureYouWantToRemoveEntry.content": "Weet je zeker dat je de uren wilt verwijderen? Dit kan niet ongedaan worden gemaakt.",
  "planning.events.holiday.birthday": "{name} is {years} geworden.",
  "planning.events.holiday.work-anniversary": "{name} is sinds {employmentStartDate} in dienst.",
  "planning.events.eventType.project-intake": "Taxatie",
  "planning.events.eventType.moving-job-pickup-boxes": "Dozen ophalen",
  "planning.events.eventType.moving-job-deliver-boxes": "Dozen leveren",
  "planning.events.eventType.moving-job-assemble": "Monteren",
  "planning.events.eventType.moving-job-disassemble": "Demonteren",
  "planning.events.eventType.moving-job-post-road-signs": "Borden plaatsen",
  "planning.events.eventType.moving-job-remove-road-signs": "Borden ophalen",
  "planning.events.eventType.moving-job-load-storage": "Opslag laden",
  "planning.events.eventType.moving-job-unload-storage": "Opslag lossen",
  "planning.events.eventType.moving-job-moving-loading": "Verhuizing laden",
  "planning.events.eventType.moving-job-moving-unloading": "Verhuizing lossen",
  "planning.events.eventType.moving-job-packing": "Inpakken",
  "planning.events.eventType.moving-job-unpacking": "Uitpakken",
  "planning.events.eventType.moving-job-moving": "Verhuizing",
  "planning.events.eventType.moving-job-disposing-of-waste": "Afval afvoeren",
  "planning.events.eventType.moving-job-prepare-house-according-to-quote": "Opleveren",
  "planning.events.eventType.moving-job-moving-lift": "Verhuislift",
  "planning.events.eventType.transport": "Transport",
  "planning.events.eventType.transport-job-pickup": "Laden",
  "planning.events.eventType.transport-job-deliver": "Lossen",
  "planning.events.eventType.project-driving-day": "Rij dag",
  "planning.events.eventType.custom": "Aangepast",
  "planning.events.eventType.meeting": "Meeting",
  "planning.events.eventType.lift-work": "Verhuislift",
  "planning.events.eventType.office-work": "Werken op kantoor",
  "planning.events.eventType.warehouse-work": "Werken in de loods",
  "planning.events.eventType.lending-staff": "Medewerkers uitlenen",
  "planning.events.eventType.hrm-day-off-event": "Dagje vrij",
  "planning.events.eventType.hrm-other-absence-event": "Afwezig",
  "planning.events.eventType.hrm-sick-event": "Ziek",
  "planning.events.eventType.project": "Project",
  "planning.events.eventType.hrm-vacation-event": "Vakantie!",
  "planning.events.eventType.material-event-inspection-event": "Materiaal keuring",
  "planning.events.eventType.material-event-maintenance-event": "Onderhoud",
  "planning.events.eventType.material-event-other-absence-event": "Afwezig",
  "planning.events.tabs.event": "Afspraak",
  "planning.events.tabs.crews": "Teams",
  "planning.events.tabs.people": "Mensen",
  "planning.events.tabs.vehicles": "Voertuigen",
  "planning.events.tabs.hours": "Uren",
  "planning.events.tabs.material": "Materiaal",
  "planning.events.tabs.data": "Datums",
  "planning.events.tabs.otherData": "Overige datums",
  "planning.events.tabs.recurringEvent": "Herhalende afspraak",
  "planning.events.eventDetails": "Afspraak gegevens",
  "planning.events.location": "Locatie",
  "planning.events.neededInventoryItems": "Benodigd materiaal",
  "planning.events.noNeededInventoryItems": "Er is nog niet doorgegeven welk materiaal nodig is voor deze afspraak. ",
  "planning.events.differentLocation": "Overige locatie",
  "planning.event.planEvent.title": "Afspraak plannen",
  "planning.event.rescheduleEvent.title": "Afspraken wijzigen",
  "planning.event.addTemporaryWorker.title": "Inhuurkracht toevoegen",
  "planning.event.eventIsFinishedWarning": "Let op! Afspraak is al afgerond, als je deze nu verwijderd verdwijnen mogelijk ook ingevoerd uren!",
  "projects.projectDetails.planEvent": "{eventType} inplannen",
  "planning.eventDetails.areYouSureYouWantToCancelEvent.title": "Afspraak {eventType} annuleren",
  "planning.eventDetails.areYouSureYouWantToCancelEvent.content": "Weet je zeker afspraak {eventType} wilt verwijderen?",
  "planning.eventDetails.areYouSureYouWantToReopenEvent.title": "Afspraak {eventType} heropenen",
  "planning.eventDetails.areYouSureYouWantToReopenEvent.content": "Weet je zeker afspraak {eventType} wilt heropenen? De ingevoerde tijden en gegevens kunnen hierdoor verloren gaan, hierdoor kan het zijn dat de klant opnieuw moet tekenen.",
  "planning.event.finishEvent.title": "Afspraak afronden",
  "planning.eventTime": "Afspraak plannen",
  "vatScenarios.calculate-vat": "Bereken BTW",
  "vatScenarios.vat-shifted": "BTW verlegd",
  "vatScenarios.outside-eu": "Buiten de EU",
  "vatScenarios.no-vat": "Geen BTW",
  "communication.newsDetails.areYouSureYouWantToDeleteNewsItem.title": "{title} verwijderen",
  "communication.newsDetails.areYouSureYouWantToDeleteNewsItem.content": "Weet je zeker dat je nieuwsbericht \"{title}\" wilt verwijderen?",
  "settings.timeOffTypeDetails.addTimeOffType": "Nieuw type verlof toevoegen",
  "settings.declarationCategoryDetails.addDeclarationCategory": "Nieuwe categorie toevoegen",
  "settings.documentTemplateDetails.tabs.details": "Template details",
  "settings.documentTemplateDetails.addLanguage": "Taal toevoegen aan {name}",
  "settings.documentTemplateDetails.areYouSureYouWantToDeleteTheLanguage.title": "{language} verwijderen van {name}",
  "settings.documentTemplateDetails.areYouSureYouWantToDeleteTheLanguage.content": "Weet je zeker dat je de vertaling {language} wilt verwijderen? Dit kan niet worden teruggedraait, en de vertaling zal dus weg zijn!",
  "settings.documentTemplateDetails.areYouSureYouWantToDeleteDocumentTemplate.title": "Document template {name} verwijderen",
  "settings.documentTemplateDetails.areYouSureYouWantToDeleteDocumentTemplate.content": "Weet je zeker dat je de document template {name} wilt verwijderen? Dit is niet meer terug te draaien.",
  "settings.documentTemplateDetails.addDocumentTemplate": "Nieuw document template aanmaken",
  "settings.automatedEmailDetails.addLanguage": "Taal toevoegen aan email {automatedEmailType}",
  "settings.automatedEmailDetails.areYouSureYouWantToDeleteTheLanguage.title": "{language} verwijderen van email {automatedEmailType}",
  "settings.automatedEmailDetails.areYouSureYouWantToDeleteTheLanguage.content": "Weet je zeker dat je de vertaling {language} wilt verwijderen? Dit kan niet worden teruggedraait, en de vertaling zal dus weg zijn!",
  "settings.automatedEmailDetails.addAutomatedEmail": "Nieuwe berichtgeving toevoegen",
  "settings.integrations.connectWithIntegration": "{provider} koppelen",
  "settings.integrations.whatAreTheExternalIdsOfTheInvoices": "Wat zijn de externe ID's van de facturen?",
  "settings.integrations.enterTheExternalIdsOfTheInvoicesIfYouLeaveThemEmptyTheyWillNotBeAutomaticallyReconciled": "Voer hieronder de externe ID's van de facturen in als je ze leeg laat, dan worden de betalingen niet automatisch verwerkt zodra deze zijn afgeletterd in het boekhoudpakket.",
  "setting.identities.createIdentity.title": "Nieuwe identiteit toevoegen",
  "settings.package.createPackage.title": "Nieuw pakket",
  "settings.package.createManualPackage.title": "Nieuw pakket zonder klus",
  "settings.package.editPackage.title": "Pakket {name} bewerken",
  "settings.turnoverGroups.addTurnoverGroup": "Nieuwe omzetgroep toevoegen",
  "providerTypes.exact-online.connectingDescription": "Je kan heel makkelijk met Exact Online koppelen, dit doe je door op \"Koppelen\" te drukken. \n\nHierna wordt er een nieuwe pagina geopend waar je kan inloggen bij Exact Online, zodra dit is gedaan is zal je terug uitkomen op deze pagina.",
  "providerTypes.exact-online.workingDescription": "Deze koppeling synchroniseert dagelijks uw facturen als verkoopboekingen naar Exact Online.\n\nDaarnaast haalt de koppeling dagelijks de openstaande facturen uit Exact Online op om te kijken of er facturen zijn betaald, deze betalingen synchroniseert hij als (deel) betalingen terug in Bas.\n\n\nHiermee is uw administratie altijd up-to-date zowel in uw boekhoudpakket als in Bas.",
  "providerTypes.nmbrs.connectingDescription": "Je kan heel makkelijk met Exact Online koppelen, dit doe je door op \"Koppelen\" te drukken. \n\nHierna wordt er een nieuwe pagina geopend waar je kan inloggen bij Exact Online, zodra dit is gedaan is zal je terug uitkomen op deze pagina.",
  "providerTypes.nmbrs.workingDescription": "Deze koppeling synchroniseert dagelijks uw facturen als verkoopboekingen naar Exact Online.\n\nDaarnaast haalt de koppeling dagelijks de openstaande facturen uit Exact Online op om te kijken of er facturen zijn betaald, deze betalingen synchroniseert hij als (deel) betalingen terug in Bas.\n\n\nHiermee is uw administratie altijd up-to-date zowel in uw boekhoudpakket als in Bas.",
  "providerTypes.octopus.connectingDescription": "Je kan heel makkelijk met Octopus koppelen, dit doe je door op \"Koppelen\" te drukken. \n\nHierna wordt er een nieuwe pagina geopend waar je kan inloggen bij Octopus, zodra dit is gedaan is zal je terug uitkomen op deze pagina.",
  "providerTypes.octopus.workingDescription": "Deze koppeling synchroniseert dagelijks uw facturen als verkoopboekingen naar Octopus.\n\nDaarnaast haalt de koppeling dagelijks de openstaande facturen uit Octopus op om te kijken of er facturen zijn betaald, deze betalingen synchroniseert hij als (deel) betalingen terug in Bas.\n\n\nHiermee is uw administratie altijd up-to-date zowel in uw boekhoudpakket als in Bas.",
  "providerTypes.exact-globe.connectingDescription": "Je kan heel makkelijk met Exact globe koppelen, dit doe je door op \"Koppelen\" te drukken.",
  "providerTypes.exact-globe.workingDescription": "Deze koppeling zet dagelijks uw facturen als verkoopboekingen klaar als XML om te importeren in Exact Globe.\n\nDaarnaast kan je de openstaande facturen uit Exact Globe importeren om te kijken of er facturen zijn betaald, deze betalingen synchroniseert hij als (deel) betalingen terug in Bas.\n\n\nHiermee is uw administratie altijd up-to-date zowel in uw boekhoudpakket als in Bas.",
  "providerTypes.snel-start.connectingDescription": "Je kan heel makkelijk met SnelStart koppelen, dit doe je door op \"Koppelen\" te drukken. \n\nHierna wordt er een nieuwe pagina geopend waar je kan inloggen bij SnelStart, zodra dit is gedaan is zal je terug uitkomen op deze pagina.",
  "providerTypes.snel-start.workingDescription": "Deze koppeling synchroniseert dagelijks uw facturen als verkoopboekingen naar SnelStart.\n\nDaarnaast haalt de koppeling dagelijks de openstaande facturen uit SnelStart op om te kijken of er facturen zijn betaald, deze betalingen synchroniseert hij als (deel) betalingen terug in Bas.\n\n\nHiermee is uw administratie altijd up-to-date zowel in uw boekhoudpakket als in Bas.",
  "providerTypes.twinfield.connectingDescription": "Je kan heel makkelijk met Twinfield koppelen, dit doe je door op \"Koppelen\" te drukken. \n\nHierna wordt er een nieuwe pagina geopend waar je kan inloggen bij Twinfield, zodra dit is gedaan is zal je terug uitkomen op deze pagina.",
  "providerTypes.twinfield.workingDescription": "Deze koppeling synchroniseert dagelijks uw facturen als verkoopboekingen naar Twinfield.\n\nDaarnaast haalt de koppeling dagelijks de openstaande facturen uit Twinfield op om te kijken of er facturen zijn betaald, deze betalingen synchroniseert hij als (deel) betalingen terug in Bas.\n\n\nHiermee is uw administratie altijd up-to-date zowel in uw boekhoudpakket als in Bas.",
  "providerTypes.reeleezee.connectingDescription": "Je kan heel makkelijk met Reeleezee koppelen, dit doe je door hieronder je inloggevens in te voeren. \n\nWij raden aan een aparte gebruiker te maken voor de connectie met Bas Software.",
  "providerTypes.reeleezee.workingDescription": "Deze koppeling synchroniseert dagelijks uw facturen als verkoopboekingen naar Reeleezee.\n\nDaarnaast haalt de koppeling dagelijks de openstaande facturen uit Reeleezee op om te kijken of er facturen zijn betaald, deze betalingen synchroniseert hij als (deel) betalingen terug in Bas.\n\n\nHiermee is uw administratie altijd up-to-date zowel in uw boekhoudpakket als in Bas.",
  "providerTypes.imuis.connectingDescription": "Je kan heel makkelijk met King software koppelen, dit door hieronder uw omgevingscode in te voeren. \n\nMocht je niet weten waar je deze kan vinden, staan wij altijd voor je klaar om samen de koppeling in te stellen.",
  "providerTypes.imuis.workingDescription": "Deze koppeling synchroniseert dagelijks uw facturen als verkoopboekingen naar King software.\n\nDaarnaast haalt de koppeling dagelijks de openstaande facturen uit King software op om te kijken of er facturen zijn betaald, deze betalingen synchroniseert hij als (deel) betalingen terug in Bas.\n\n\nHiermee is uw administratie altijd up-to-date zowel in uw boekhoudpakket als in Bas.",
  "providerTypes.yuki.connectingDescription": "Je kan heel makkelijk met Yuki koppelen, dit door hieronder uw api sleutel in te voeren. \n\nMocht je niet weten waar je deze kan vinden, staan wij altijd voor je klaar om samen de koppeling in te stellen.",
  "providerTypes.yuki.workingDescription": "Deze koppeling synchroniseert dagelijks uw facturen als verkoopboekingen naar Yuki.\n\nDaarnaast haalt de koppeling dagelijks de openstaande facturen uit Yuki op om te kijken of er facturen zijn betaald, deze betalingen synchroniseert hij als (deel) betalingen terug in Bas.\n\n\nHiermee is uw administratie altijd up-to-date zowel in uw boekhoudpakket als in Bas.",
  "providerTypes.mollie.connectingDescription": "Je kan heel makkelijk met Mollie koppelen, dit doe je door op \"Koppelen\" te drukken. \n\nHierna wordt er een nieuwe pagina geopend waar je kan inloggen bij Mollie, zodra dit is gedaan is zal je terug uitkomen op deze pagina.",
  "providerTypes.mollie.workingDescription": "Met de koppeling van Mollie kan je een iDeal toevoegen aan de factuur email, zodra klanten hierop drukken kunnen ze meteen de factuur betalen en wordt de betaling automatisch verwerkt in Bas.\n\n\nDe tarieven voor de betaling kan je vinden op mollie.nl, daarnaast rekent Bas een fee van 0,25 per transactie.",
  "providerTypes.schouten-zekerheid.connectingDescription": "Je kan in de portal van Schouten Zekerheid een API token aanmaken, deze moet je hieronder plakken en op koppelen drukken.",
  "providerTypes.schouten-zekerheid.workingDescription": "Deze koppeling maakt garantiecertificaat verzoeken aan voor alle verhuizingen die ingepland zijn tussen morgen en over 7 dagen.\n\nDeze kan je vervolgens goedkeuren en afronden in de portal van Schouten Zekerheid.",
  "providerTypes.website.connectingDescription": "Deze koppeling geeft je websitebouwer te mogelijkheid om nieuwe aanvragen in Bas te zetten, hierna wordt een taak klaargezet om een offerte afspraak te maken.",
  "providerTypes.website.workingDescription": "Vraag aan Bas voor de koppelingsdocumentatie",
  "providerTypes.verhuis-offertes.connectingDescription": "Met deze koppeling komen je aanvragen via VerhuisOffertes.com automatisch in Bas te staan, hierdoor hoef je niks meer handmatig over te typen 🎉🥳.",
  "providerTypes.verhuis-offertes.workingDescription": "Vul een gedeeld geheim in, deze moet je vervolgens samen met de link die je in de volgende stap kan vinden sturen naar VerhuisOffertes.com",
  "providerTypes.scan-movers.connectingDescription": "Met deze koppeling komen je aanvragen via ScanMovers automatisch in Bas te staan, hierdoor hoef je niks meer handmatig over te typen 🎉🥳.",
  "providerTypes.scan-movers.workingDescription": "Vul een gedeeld geheim in, deze moet je vervolgens samen met de link die je in de volgende stap kan vinden sturen naar ScanMovers",
  "providerTypes.whatsapp.connectingDescription": "Door op koppelen te drukken wordt je naar Facebook gestuurd, hier kan je één of meerdere telefoonnummers koppelen. ",
  "providerTypes.whatsapp.workingDescription": "Zodra je gekoppeld bent met WhatsApp krijg je alle inkomende berichten te zien onder communicatie. Deze gesprekken kan je vervolgens aan klanten of medewerkers koppelen, hierdoor heb je altijd overzicht. Daarnaast kan je ook gemakkelijk automatische berichten laten versturen naar de klanten.",
  "button.connectIntegration": "Koppeling activeren",
  "button.disconnectIntegration": "Koppeling verbreken",
  "button.removeIntegration": "Koppeling verwijderen",
  "integration.areYouSureYouWantToDisconnectIntegration.title": "Koppeling met {provider} verbreken",
  "integration.areYouSureYouWantToDisconnectIntegration.content": "Weet je zeker dat je de koppeling met {provider} wilt verbreken?",
  "integration.areYouSureYouWantToRemoveIntegration.title": "Koppeling met {provider} verwijderen",
  "integration.areYouSureYouWantToRemoveIntegration.content": "Weet je zeker dat je de koppeling met {provider} wilt verwijderen?",
  "settings.integration.warningThatIntegrationIsNotFree": "Let op, de koppeling kan geld kosten. Neem contact op met Bas voor de eventuele kosten.",
  "settings.integrationDetails.couldNotLoadExternalData": "Kon bepaalde gegevens niet laden vanaf het externe pakket, controlleer je inloggevens en je rechten. Mocht dit niet helpen neem dan contact op met support.",
  "settings.integrationDetails.administrationMapping": "Administratie koppelen",
  "settings.integrationDetails.purchaseCostMapping": "Inkoopkosten koppelen",
  "settings.integrationDetails.generalLedgerMapping": "Grootboekrekeningen koppelen",
  "settings.integrationDetails.costCenterMapping": "Kostenplaatsen koppelen",
  "settings.integrationDetails.vatMapping": "BTW codes koppelen",
  "features.invoicing.showTotalsPerHourlyRateRow": "Totalen per regel tonen voor uurtarieven",
  "features.invoicing.showTotalsPerHourlyRateRow.help": "Als dit aanstaat zal er op de offerte acceptatie pagina de total per regel voor uurtarieven getoond worden.",
  "features.invoicing.showQuantityPerHourlyRateRow": "Aantallen  per regel tonen voor uurtarieven",
  "features.invoicing.showQuantityPerHourlyRateRow.help": "Als dit aanstaat zal er op de offerte acceptatie pagina de aantallen per regel voor uurtarieven getoond worden.",
  "features.quotation.hidePdfOnAcceptancePage.help": "De PDF niet tonen op de offerte acceptatie pagina.",
  "features.quotation.forceFullPdfOnAcceptancePage.help": "Altijd de volledige PDF tonen op de offerte acceptatie pagina, dus ook als het meer dan 3 pagina's zijn.",
  "features.invoicing.showTotals": "Totalen tonen",
  "features.invoicing.showTotals.help": "Als dit aanstaat zal er op de offerte acceptatie pagina de totalen getoond worden.",
  "features.invoicing.billStorageInAdvance": "Opslag vooraf factureren",
  "features.invoicing.billHighestCubicMeterForVolumeChangeDay": "Factureer het hoogste volume per dag",
  "features.invoicing.billHighestCubicMeterForVolumeChangeDay.help": "De standaard berekening van de kosten voor de opslag: per dag wordt het laatste opgeslagen volume per opslaghouder in rekening gebracht. Als je deze optie aanzet, wordt de berekening gebaseerd op het hoogste opgeslagen volume per opslaghouder per dag.",
  "features.invoicing.invoiceStoragesDaysBefore": "{numberOfDays, plural,\n    =1 {Dag}\n    other {Dagen}\n} van te voren opslag facturen klaarzetten",
  "features.invoicing.automaticallySendReminders": "Verstuur herinneringen voor de facturen automatisch",
  "features.project.startWithIntake": "Starten met intake plannen bij nieuwe klus",
  "features.project.startWithIntake.help": "In plaats van beginnen met de planning begin je met het inplannen van een intake zodra je een nieuwe klus gaat maken.",
  "features.project.forceDescriptionAsFirstTab": "Beschrijving altijd als eerste tab",
  "features.project.forceDescriptionAsFirstTab.help": "Bij de planning in plaats van afspraak de beschrijving als eerste tab behouden.",
  "features.project.disableDefaultDeliverBoxes": "Standaard geen dozen leveren",
  "features.project.disableDefaultDeliverBoxes.help": "Als je een nieuwe verhuizing maakt staat dozen leveren standaard uit.",
  "features.mobile-app.showEmployeeDetails": "Collega's kunnen elkaar gegevens zien",
  "features.mobile-app.showEmployeeDetails.help": "Op de afspraak pagina in de mobiele app kunnen collega's elkaars gegevens zien van hun team.",
  "features.mobile-app.makeAcceptedQuoteVisibleForEmployee": "Maak de geaccepteerde offerte zichtbaar voor de medewerker",
  "features.mobile-app.howManyDaysCanEmployeesLookAhead": "{numberOfDays, plural,\n    =1 {Dag}\n    other {Dagen}\n} van te voren de agenda beschikbaar maken voor de medewerkers",
  "features.mobile-app.fromWhatTimeWillNewEventsBeAvailable": "Vanaf welk uur zal er nieuwe afspraken zichtbaar zijn?",
  "features.mobile-app.timeMarginBeforeEventInMinutes": "{minutes, plural,\n    =1 {Minuut}\n    other {Minuten}\n} eerder op de loods zijn voor de verhuizing",
  "features.mobile-app.timeMarginBeforeEventInMinutes.help": "Wij kijken hoe laat de verhuizing start, hier halen we reistijd vanaf en ook de extra tijdmarge. Dit wordt getoond op de mobiele app van de medewerker.",
  "features.mobile-app.showHoursAfter": "afspraken tonen om uren in te voeren na deze datum.",
  "features.mobile-app.showHoursAfter.help": "Alle afspraken na deze datum moeten de uren worden ingevoerd door de medewerker, de afspraken voor deze datum kunnen niet via de medewerker app worden ingevoerd.",
  "features.time-registration.includeBreakTime": "Pauze meenemen in werktijd",
  "features.time-registration.defaultHourlyWage": "Standaard bruto uurloon",
  "features.time-registration.includeBreakTime.help": "Als dit aanstaat is het total aantal uur gewerkt per dag inclusief de pauze",
  "features.ui.numberOfItemsPerPage": "regels standaard tonen in tabellen",
  "features.ui.hideBackofficeOnlyEmployees": "Backoffice medewerkers niet tonen in de planning",
  "features.ui.defaultDisableAutomaticTravelTime": "Automatische reistijd standaard uitzetten",
  "features.ui.defaultHideFilesForNonBackofficeEmployees": "Bestanden standaard niet tonen voor medewerkers",
  "features.ui.availabilityBasedUpon.employeeHours": "Medewerkers standaard meenemen voor de beschikbaarheid",
  "features.ui.availabilityBasedUpon.movingLiftHours": "Verhuisliften standaard meenemen voor de beschikbaarheid",
  "features.ui.availabilityBasedUpon.movingTruckHours": "Verhuiswagens standaard meenemen voor de beschikbaarheid",
  "features.ui.availabilityBasedUpon.help": "Zet de standaard filters voor de beschikbaarheid aan of uit",
  "features.quote.inventoryListSetting": "inventarisatielijst meesturen",
  "features.quotationTool.includeMovingCompanyAddress": "Neem bedrijfsadres mee in km berekening",
  "features.quotationTool.includeMovingCompanyAddress.help": "Bij het berekenen van de kilometers en reistijd beginnen en eindigen we de bij het adres uit bedrijfsgegevens.",
  "features.quotationTool.employeeCubicMeterPerHour": "Kuub per medewerker per uur (gemiddelde)",
  "features.quotationTool.serviceTimeConsumptions.packing": "{timeNeeded, plural,\n    =1 {Doos}\n    other {Dozen}\n} inpakken per uur",
  "features.quotationTool.serviceTimeConsumptions.unpack": "{timeNeeded, plural,\n    =1 {Doos}\n    other {Dozen}\n} uitpakken per uur",
  "features.quotationTool.serviceTimeConsumptions.assemble": "{timeNeeded, plural,\n    =1 {Minuut}\n    other {Minuten}\n} tijd om te monteren per meubelstuk (of per deur)",
  "features.quotationTool.serviceTimeConsumptions.disassemble": "{timeNeeded, plural,\n    =1 {Minuut}\n    other {Minuten}\n} tijd om te demonteren per meubelstuk (of per deur)",
  "features.quotationTool.prices": "Prijzen",
  "features.quotationTool.surcharges": "Toeslagen",
  "features.quotationTool.buildingRate.surcharge": "Extra toeslag voor {buildingType}",
  "features.quotationTool.cubicMeterRate": "Prijs per kuub",
  "features.quotationTool.travelHourlyRate": "Prijs per uur voor reistijd",
  "features.quotationTool.pricePerKm": "Prijs per kilometer",
  "features.customer-notifications.howManyBusinessDaysBeforeMovingJobItShouldSendAReminder": "{numberOfDays, plural,\n    =1 {Dag}\n    other {Dagen}\n} voordat een klus nadert de klant mailen",
  "features.customer-notifications.disableIcsAttachment": "Geen ICS bestand meesturen",
  "features.customer-notifications.disableIcsAttachment.help": "Als dit aanstaat wordt er geen ICS bestand meegestuurd met de afspraak emails naar de klant.",
  "features.tasks.howManyBusinessDaysBeforeEventDoYouWantToPlanEmployeesAndMaterials": "{numberOfDays, plural,\n    =1 {Dag}\n    other {Dagen}\n} voor de afspraak personeel en materiaal plannen",
  "features.tasks.howManyDaysAfterMovingDoYouWantToPickupBoxes": "{numberOfDays, plural,\n    =1 {Dag}\n    other {Dagen}\n} na de verhuizing dozen oppikken",
  "features.tasks.howManyBusinessDaysBeforeEventDoYouWantToReserveStorage": "{numberOfDays, plural,\n    =1 {Dag}\n    other {Dagen}\n} voor de verhuizing opslag reserveren",
  "features.tasks.howManyBusinessDaysBeforeEventDoYouWantToDeliverBoxes": "{numberOfDays, plural,\n    =1 {Dag}\n    other {Dagen}\n} voor de verhuizing dozen leveren",
  "features.tasks.howManyBusinessDaysBeforeMovingDoYouWantToPlaceSigns": "{numberOfDays, plural,\n    =1 {Dag}\n    other {Dagen}\n} voor de verhuizing borden plaatsen",
  "features.tasks.howManyBusinessDaysBeforeEventDoYouWantToRequestCertificate": "{numberOfDays, plural,\n    =1 {Dag}\n    other {Dagen}\n} voor de verhuizing garantiecertificaat aanpassen",
  "features.tasks.howManyBusinessDaysBeforeEventDoYouWantToRequestPermits": "{numberOfDays, plural,\n    =1 {Dag}\n    other {Dagen}\n} voor de verhuizing vergunningen aanvragen",
  "features.tasks.howManyBusinessDaysAfterEventItNeedsToBeFinished": "{numberOfDays, plural,\n    =1 {Dag}\n    other {Dagen}\n} na de afspraak moet de afspraak afgerond zijn",
  "features.tasks.howManyBusinessDaysAfterEventItInvoiceNeedsToBeSend": "{numberOfDays, plural,\n    =1 {Dag}\n    other {Dagen}\n} na de verhuizing de factuur versturen",
  "features.quotation.inventoryListSetting": "inventarisatie lijst meesturen",
  "features.calendar-sync.downloadSchoolHolidays": "Schoolvakanties downloaden",
  "features.calendar-sync.downloadSchoolHolidays.help": "Als dit aanstaat worden de schoolvakanties gedownload en in de planning getoond",
  "features.calendar-sync.schoolHolidaysCountry": "Land voor schoolvakanties",
  "features.calendar-sync.schoolHolidaysRegion": "Regio voor schoolvakanties",
  "app.dashboardPage.dashboardYouHaveALotOfTasks": "Ha {employeeName}, er zijn {numberOfTasks} taken voor jou. Nog even doorpakken!",
  "app.dashboardPage.dashboardYouHaveACoupleOfTasks": "Ha {employeeName}, er zijn {numberOfTasks} taken voor jou. Je bent er bijna!",
  "app.dashboardPage.noTasks": "Ha {employeeName}, je bent helemaal bij! Tijd voor koffie!",
  "relation.mergeRelation.title": "Klant {name} samenvoegen",
  "relation.mergeRelation.explanation": "Als je klanten samenvoegd zullen alle gegevens worden gezet op 1 klant, dit kan niet worden teruggedraaid. Selecteer hieronder de klant waarvan je de gegevens wilt behouden.",
  "plans.trial": "Proef pakket",
  "plans.trial.description": "Het pakket voor de verhuizer die nog wilt kijken of Bas matcht",
  "plans.starter": "Starter pakket",
  "plans.starter.description": "Het pakket voor de startende verhuizer",
  "plans.bas": "Bas pakket",
  "plans.bas.description": "Het pakket voor de verhuizer die het overzicht wil houden",
  "plans.enterprise": "Enterprise",
  "plans.enterprise.description": "Het pakket voor de grote verhuizer die volledig geautomatiseerd wil werken",
  "emailFolders.inbox": "Inbox",
  "emailFolders.sent": "Verzonden",
  "emailFolders.draft": "Concepten",
  "emailFolders.trash": "Prullenbak",
  "emailFolders.spam": "Spam",
  "email.label.toReceivers": "Aan",
  "email.label.ccReceivers": "Cc",
  "email.label.bccReceivers": "Bcc",
  "email.areYouSureYouWantToDeleteEmail.title": "Email verwijderen",
  "email.areYouSureYouWantToDeleteEmail.content": "Weet je zeker dat je de email met onderwerp \"{subject}\" wilt verwijderen?",
  "email.areYouSureYouWantToMoveEmailToTrash.title": "Email verwijderen",
  "email.areYouSureYouWantToMoveEmailToTrash.content": "Weet je zeker dat je de email met onderwerp \"{subject}\" naar de prullenbak wilt verplaatsen?",
  "email.areYouSureYouWantToMoveEmailToSpam.title": "Email als spam markeren",
  "email.areYouSureYouWantToMoveEmailToSpam.content": "Weet je zeker dat je de email met onderwerp \"{subject}\" als spam wilt markeren?",
  "tenantInvoiceStatus.open": "Open",
  "tenantInvoiceStatus.paid": "Betaald",
  "tenantInvoiceStatus.cancelled": "Geannuleerd",
  "tenantInvoiceStatus.overdue": "Achterstallig",
  "button.acknowledge": "Bevestigen",
  "label.overriddenDates": "Overschreven datums",
  "label.skipped": "Overgeslagen",
  "label.past": "Verleden",
  "label.fullDayRecurringEvent": "De herhalende afspraak duurt de hele dag",
  "label.theFollowingDatesWillNotBeAffectedByThisChange": "De volgende datums zullen niet worden beïnvloed door deze wijziging",
  "error.duplicateDates": "Er zijn datums die meerdere keren voorkomen, dit is niet toegestaan",
  "label.theDatesShouldBeManuallyAdjusted": "De datums moeten een voor een worden handmatig worden aangepast",
  "label.future": "Toekomst",
  "label.overridden": "Overschreven",
  "label.excludedDates": "Uitgesloten datums",
  "label.noExcludedDates": "Geen uitgesloten datums",
  "label.noOverriddenDates": "Geen overschreven datums",
  "label.editExcludedDatesOfRecurringEvent": "Uitgesloten datums bewerken",
  "label.storedItemDescription": "Omschrijving opgeslagen item",
  "label.storedItemCode": "Code opgeslagen item",
  "label.storedItemExternalItemCode": "Externe code opgeslagen item",
  "label.nameOfAcceptor": "Naam acceptant",
  "label.disableIcsAttachment": "ICS bijlage uitschakelen",
  "label.disabledTenant": "Deze omgeving is uitgeschakeld",
  "label.disabledTenantDescription": "Deze omgeving is uitgeschakeld per {date}, neem contact op met support voor de reden.",
  "label.disabledTenantMessage": "Mijn omgeving {name} is uitgeschakeld per {date}, wat is hier de reden van?",
  "label.noAccessForEmployee": "Geen toegang voor medewerker",
  "label.noAccessForEmployeeDescription": "Je hebt geen toegang tot deze omgeving, neem contact op met {name} voor de reden.",
  "label.blockedDueToOpenInvoices": "Geen toegang door openstaande facturen",
  "label.blockedDueToOpenInvoicesDescription": "Je hebt geen toegang tot deze omgeving, er zijn nog openstaande facturen.",
  "label.blockedDueToOpenInvoicesMessage": "Mijn omgeving {name} is geblokkeerd door openstaande facturen.",
  "label.tenantHasContractsToSign": "Geen toegang, het contract is nog niet ondertekend",
  "label.tenantHasContractsToSignDescription": "Vraag {employeeNames} om het contract te ondertekenen, dan zal het toegang automatisch weer worden gegeven.",
  "label.youHaveToSignTheContract": "Je moet het contract ondertekenen",
  "label.pleaseSignTheContractBeforeYouCanContinue": "Je moet het contract ondertekenen voordat je verder kan gaan.",
  "label.youHaveToSignTheContractMessage": "Ik moet het contract van {name} ondertekenen.",
  "label.volumeChange": "Volume wijziging",
  "label.noStoragesReservedForThisProject": "Er is geen opslag gereserveerd voor deze klus",
  "label.time": "Tijd",
  "label.changedOn": "Gewijzigd op",
  "label.itemIsNotInStorage": "Item is niet in opslag",
  "label.storageIsReadyForPickup": "Opslag staat klaar voor ophalen",
  "label.storageIsPickedUp": "Opslag is opgehaald",
  "label.extraStoredItems": "Extra opgeslagen items",
  "label.addExtraStoredItem": "Extra opgeslagen item toevoegen",
  "label.itemIsInStorage": "Item is in opslag",
  "label.loadedByUnknown": "Er is niet bekend wie het item heeft geladen",
  "label.storedItems": "Opgeslagen items",
  "label.storageType": "Opslag type",
  "label.volumeBasedInvoicing": "Volume gebaseerde facturatie",
  "label.theCurrentVolumeIs": "De huidige volume is {volume}{suffix}",
  "label.loadedVolumeIs": "De geladen volume is {volume}{suffix}",
  "label.unloadedVolumeIs": "De uitgeladen volume is {volume}{suffix}",
  "label.volumeBasedInvoicingIsDisabled": "Volume gebaseerde facturatie is uitgeschakeld",
  "label.theEmployeeIsNotWorkingOnTheSelectedDay": "De medewerker is niet aan het werk op de geselecteerde dag",
  "label.gpuAccelerationNotSupported": "Bas medewerker app werkt niet zonder GPU versnelling. Dit kan veel problemen veroorzaken. Neem contact op met support.",
  "label.somethingWentWrongWhenCreatingAndSigningTheQuote": "Er is iets mis gegaan bij het aanmaken en ondertekenen van de offerte.",
  "label.drivingHourTypeId": "Rijtijd type",
  "label.whenAtTenantHourTypeId": "Tijdtype bij kantoor/loods",
  "label.otherHourTypeId": "Overig tijdtype",
  "label.buildUpIsAllowed": "Uren opbouw toegestaan",
  "label.buildUpIsNotAllowed": "Uren opbouw niet toegestaan",
  "label.internalTimeOffType": "Intern verlofsoort",
  "label.ignoreIdentity": "Negeer identiteit",
  "label.allowBuildUp": "Opbouw toestaan",
  "label.disabledAfterDate": "Uitgeschakeld na {date}",
  "label.areYouSureYouWantToApproveAllEntriesForDate": "Weet je zeker dat je alle open tijdregistraties voor {date} wilt goedkeuren?",
  "label.youAreGoingToApproveTheFollowingTimeRegistrations": "Je gaat de volgende tijdregistraties goedkeuren:",
  "label.areYouSureYouWantToRejectAllEntriesForDate": "Weet je zeker dat je alle open tijdregistraties voor {date} wilt afkeuren?",
  "label.youAreGoingToRejectTheFollowingTimeRegistrations": "Je gaat de volgende tijdregistraties afkeuren:",
  "label.planned": "Gepland",
  "label.timeEntries": "Tijdregistraties",
  "label.approve": "Goedkeuren",
  "label.approveAllOpenEntries": "Keur alle openstaande uren goed",
  "label.rejectAllOpenEntries": "Wijs alle openstaande uren af",
  "label.overlappingWithOtherEntry": "Overlappend met andere tijdregistraties",
  "label.timeOffRequests": "Verlof aanvragen",
  "label.timeOffRegistrationInformation": "Verlof informatie",
  "label.declarationChangedDescription": "Je declaratie is gewijzigd en wordt zo snel mogelijk verwerkt",
  "label.changedDeclaration": "Declaratie gewijzigd",
  "label.declarationDetails": "Declaratie details",
  "label.changeDeclaration": "Declaratie wijzigen",
  "label.notRelatedToAProject": "Niet gerelateerd aan een klus",
  "label.notRelatedToAEvent": "Niet gerelateerd aan een afspraak",
  "label.storageInformation": "Opslag informatie",
  "label.notRelatedToACustomer": "Niet gerelateerd aan een klant",
  "label.paidOn": "Betaald op",
  "label.declarationInformation": "Declaratie informatie",
  "label.approvalInformation": "Goedkeuring informatie",
  "label.rejectionInformation": "Afwijzing informatie",
  "label.paymentInformation": "Betaal informatie",
  "label.relatedInformation": "Gerelateerde informatie",
  "label.hasReceipt": "Heeft bonnetje",
  "label.isMissingReceipt": "Bonnetje ontbreekt",
  "label.declarationBefore": "Declaratie voor",
  "label.declarationAfter": "Declaratie na",
  "label.categories": "Categorieën",
  "label.hours-to-approve": "Uren te goedkeuren",
  "label.storedItemsAreMoved": "Opgeslagen items zijn verplaatst",
  "label.loadedStorage.finished": "Opgeslagen items zijn geladen",
  "label.itemsAreLoadedIntoStorage": "Items zijn in opslag geladen",
  "label.unloadedStorage.finished": "Opgeslagen items zijn uitgeladen",
  "label.itemsAreUnloadedFromStorage": "Items zijn uit opslag geladen",
  "label.storedItemsAreMovedToTheNewLocation": "Opgeslagen items zijn verplaatst naar de nieuwe locatie",
  "label.leaveCards": "Verlofkaarten",
  "label.declarations": "Declaraties",
  "label.time-off-requests": "Verlof aanvragen",
  "label.totalPaid": "Totaal betaald",
  "label.totalToPay": "Totaal te betalen",
  "label.isBetter": "Beter",
  "label.totalToBeApproved": "Totaal te goedkeuren",
  "label.totalRejected": "Totaal afgewezen",
  "label.receipt": "Bonnetje",
  "label.approveTimeOffRegistration": "Verlof goedkeuren",
  "label.rejectTimeOffRegistration": "Verlof afwijzen",
  "label.approveDeclaration": "Declaratie goedkeuren",
  "label.rejectDeclaration": "Declaratie afwijzen",
  "label.markDeclarationAsPaid": "Declaratie als betaald markeren",
  "label.defaultHourTypes": "Standaard tijdtypes voor automatische registratie",
  "label.areYouSureYouWantToCancelThisTimeOffRegistration.content": "Weet je zeker dat je dit verlof wilt annuleren? Dit kan niet ongedaan worden gemaakt.",
  "label.areYouSureYouWantToCancelThisTimeOffRegistration.title": "Verlof annuleren",
  "label.areYouSureYouWantToCancelThisSickLeave.content": "Weet je zeker dat je deze ziekmelding wilt annuleren? Dit kan niet ongedaan worden gemaakt.",
  "label.areYouSureYouWantToCancelThisSickLeave.title": "Ziekmelding annuleren",
  "label.overlappingTimeOffRequests": "Overlappende verlof aanvragen",
  "label.areYouSureYouWantToCancelThisDeclaration.title": "Declaratie annuleren",
  "label.areYouSureYouWantToCancelThisDeclaration.content": "Weet je zeker dat je deze declaratie wilt annuleren? Dit kan niet ongedaan worden gemaakt.",
  "label.startBefore": "Start voor",
  "label.startAfter": "Start na",
  "label.endBefore": "Eind voor",
  "label.endAfter": "Eind na",
  "label.timeOffBalance": "Verlofsaldo",
  "label.balance": "Saldo",
  "label.timeOffTypes": "Verlofsoorten",
  "label.addNationalHoliday": "Nationale feestdag toevoegen",
  "label.declarationCategories": "Declaratie categorieën",
  "label.weight": "Gewicht",
  "label.sortingWeight": "Volgorde",
  "label.sortingWeight.explained": "Hoe hoger de waarde, hoe lager deze in de lijst komt.",
  "label.icon": "Icoon",
  "label.timeTypes": "Tijdsoorten",
  "label.declarationCancelled": "Declaratie geannuleerd",
  "label.markEmployeeAsRecovered": "Medewerker beter melden",
  "button.markAsRecovered": "Beter melden",
  "label.declarationCancelledDescription": "Je declaratie is geannuleerd en wordt niet verwerkt",
  "label.cancelDeclaration": "Declaratie annuleren",
  "label.areYouSureYouWantToCancelDeclaration": "Weet je zeker dat je deze declaratie wilt annuleren? Dit kan niet ongedaan worden gemaakt.",
  "label.effectiveHours": "Effectieve uren",
  "label.onWhatProjectOrEventAreYouWorking": "Op welk project of afspraak ben je aan het werk?",
  "label.addDeclaration": "Declaratie toevoegen",
  "label.declarationAdded": "Declaratie aangevraagd",
  "label.declarationAddedDescription": "Je declaratie is aangevraagd en wordt zo snel mogelijk verwerkt",
  "label.declarationDate": "Declaratie datum",
  "label.category": "Categorie",
  "label.todayYouWorkedXTime": "Vandaag heb je {time} gewerkt",
  "label.timeOffType": "Soort verlof",
  "label.sickLeave": "Ziekmelding",
  "label.sickLeaves": "Ziekmeldingen",
  "menu.sick-leave": "Ziekmeldingen",
  "button.addSickLeave": "Ziekmelding",
  "button,reportEmployeeSick": "Medewerker ziek melden",
  "label.markedAsBetter": "Herstelinformatie",
  "label.markedAsBetterBy": "Beter gemeld door",
  "label.national-holidays": "Nationale feestdagen",
  "label.hoursBuildUpType": "Soort uren opbouw",
  "label.addTimeOffHoursInBulk": "Verlofuren in bulk toevoegen",
  "label.dateOfNotification": "Datum van melding",
  "label.note": "Notitie",
  "label.addSickLeave": "Medewerker ziek melden",
  "label.firstSickDay": "Eerste ziektedag",
  "label.sickLeaveReportedBy": "Ziekgemeld door",
  "label.markedAsBetterOn": "Beter gemeld op",
  "label.lastExpectedSickDay": "Laatste verwachte ziektedag",
  "label.requestTimeOff": "Verlof aanvragen",
  "label.typeOfSickLeave": "Soort ziekmelding",
  "label.requestHoursBuildUp": "Uren opbouw aanvragen",
  "label.lastSickDay": "Laatste ziektedag",
  "label.and": "en",
  "label.endMustBeAfterStart": "Eindtijd moet na begintijd zijn",
  "label.overrideStartAndEndTime": "Begintijd en eindtijd overschrijven",
  "label.timezone": "Tijdzone",
  "label.payedAtCustomer.finished": "Betaald bij klant",
  "label.getHelp": "Hulp nodig?",
  "label.wantToQuicklyWriteSomeNotesOrNeedHelp": "Wil je snel wat notities maken of heb je hulp nodig?",
  "label.internalNotes": "Notities voor intern gebruik",
  "label.editSickLeave": "Ziekmelding aanpassen",
  "label.editTimeOff": "Verlof bewerken",
  "label.claCompensationRules": "CAO vergoedingen",
  "label.documentIsUploaded": "Document is geüpload",
  "label.appUrlIsIncorrect": "De omgevings-URL is onjuist",
  "label.sickDays": "Ziektedagen",
  "label.selectTheEmployeeForWhichYouWantToAddTimeOffHours": "Selecteer de medewerker waarvoor je verlofuren wilt toevoegen",
  "label.appUrl": "Omgeving url",
  "label.nextWeek": "Volgende week",
  "label.importantObstruction": "Meldingen",
  "label.roadWorkObstruction": "Wegwerkzaamheden",
  "label.finishedRoom": "Kamer is afgerond",
  "label.niceJob": "Lekker bezig {greetingName}!",
  "label.damageIsProcessed": "Je schade is verwerkt",
  "label.profileIsChanged": "Je profiel is gewijzigd",
  "label.passwordIsChanged": "Je wachtwoord is gewijzigd",
  "label.mileageReportIsProcessed": "Je rittenregistratie is verwerkt",
  "label.safeTravels": "Goede reis {greetingName}!",
  "label.finishedAtCustomer.finished": "Afspraak afgerond bij de klant",
  "label.overrideTime": "Tijd overschrijven",
  "label.quantityWornOut": "Aantal versleten",
  "label.hourlyRate": "Uurtarief",
  "label.lineId": "Regel",
  "label.totalPrice": "Totaalprijs",
  "label.vatPercentage": "BTW percentage",
  "label.currency": "Valuta",
  "label.documents": "Documenten",
  "label.changeProfile": "Profiel aanpassen",
  "label.changeProfile.finished": "Profiel is aangepast",
  "label.changePassword": "Wachtwoord aanpassen",
  "label.changePassword.finished": "Wachtwoord is aangepast",
  "label.reportDamage.finished": "Schade is gemeld",
  "label.registerMileageReport.finished": "Kilometerstand is doorgegeven",
  "label.authenticateToRefreshToken": "Authenticeren om token te vernieuwen",
  "label.requestedTimeOff": "Verlof aangevraagd",
  "label.requestedTimeOffDescription": "Je verlof aanvraag is verstuurd, je ontvangt een notificatie zodra deze is goedgekeurd of afgekeurd.",
  "label.requestedHoursBuildUp": "Opbouw aangevraagd",
  "label.requestedHoursBuildUpDescription": "Je urenopbouw aanvraag is verstuurd, je ontvangt een notificatie zodra deze is goedgekeurd of afgekeurd.",
  "label.hoursBuildUp": "Urenopbouw",
  "label.timeOffHours": "Verlofuren",
  "label.nonHolidayTimeOffHours": "Ziekte/bijzonder verlof",
  "label.requestedNonHolidayTimeOff": "Verlof aangevraagd",
  "label.requested": "Aangevraagd",
  "label.timeOffFromUntil": "Van {from} t/m {until}",
  "label.disableAfter": "Uitschakelen na",
  "label.areYouSureYouWantToEnableDeclarationCategory.title": "Categorie inschakelen",
  "label.areYouSureYouWantToEnableDeclarationCategory.content": "Weet je zeker dat je de categorie \"{name}\" wilt inschakelen?",
  "label.areYouSureYouWantToEnableTimeType.title": "Tijdsoort inschakelen",
  "label.areYouSureYouWantToEnableTimeType.content": "Weet je zeker dat je de tijdsoort \"{name}\" wilt inschakelen?",
  "label.areYouSureYouWantToEnableTimeOffType.title": "Verlofsoort inschakelen",
  "label.areYouSureYouWantToEnableTimeOffType.content": "Weet je zeker dat je de verlofsoort \"{name}\" wilt inschakelen?",
  "label.areYouSureYouWantToEnableStorageLocation.title": "Opslaglocatie inschakelen",
  "label.areYouSureYouWantToEnableStorageLocation.content": "Weet je zeker dat je de opslaglocatie \"{name}\" wilt inschakelen?",
  "label.areYouSureYouWantToEnableWarehouse.title": "Loods inschakelen",
  "label.areYouSureYouWantToEnableWarehouse.content": "Weet je zeker dat je de loods \"{name}\" wilt inschakelen?",
  "button.newDeclarationCategory": "Nieuwe categorie",
  "button.moveStorage": "Opslag verplaatsen",
  "button.positionStorageForPickup": "Opslag klaarzetten",
  "button.pickUpStorage": "Opslag ophalen",
  "button.returnStorageToWarehouse": "Opslag terugzetten",
  "button.requestPermission": "Permissie aanvragen",
  "button.forceRerender": "Forceer rerender",
  "button.enableSignature": "Inschakelen",
  "button.disableSignature": "Uitschakelen",
  "button.openIntercom": "Open Intercom",
  "button.back": "Terug",
  "button.takePhoto": "Foto maken",
  "button.sendLocation": "Stuur locatie",
  "button.sign": "Ondertekenen",
  "button.trackTime": "Tijd bijhouden",
  "button.timeOffRegistrations": "Verlof registraties",
  "button.enterTimeRegistration": "Tijd registreren",
  "button.hoursOverview": "Urenoverzicht",
  "button.declarations": "Declaraties",
  "button.registeredHours": "Geregistreerde uren",
  "button.hoursInsight": "Uren inzicht",
  "mobileApp.timeTracking": "Gewerkte uren van vandaag",
  "mobileApp.changeProfile": "Profiel wijzigen",
  "mobileApp.changePassword": "Wachtwoord wijzigen",
  "mobileApp.profile": "Profiel",
  "mobileApp.enterHours.title.finished": "Je uren zijn doorgegeven",
  "eventActions.uploadDocument": "Document uploaden",
  "eventActions.startNavigation": "Navigatie starten",
  "mobileApp.event.finalizeEvent.youStartedAtXAndItWasUntilXButTheEndTimeIsBeforeTheStartTime": "Je hebt doorgegeven de klus begon om {startTime} en de schatting was dat het tot {endTime} heeft geduurd, maar de eindtijd is voor de begintijd dit is niet mogelijk.",
  "mobileApp.event.finalizeEvent.theEventWasFromXUntilXButTheEndTimeIsBeforeTheStartTime": "Volgens de schatting zou de klus van {startTime} tot {endTime} hebben geduurd, maar de eindtijd is voor de begintijd dit is niet mogelijk.",
  "mobileApp.event.fillInHours.theEventWasFromXUntilXButTheEndTimeIsBeforeTheStartTime": "Volgens de schatting zou de klus van {startTime} tot {endTime} hebben geduurd, maar de eindtijd is voor de begintijd dit is niet mogelijk.",
  "mobileApp.event.finalizeEvent.title.finished": "Klus is afgerond",
  "mobileApp.event.fillInHours.title.finished": "Je uren zijn doorgegeven",
  "mobileApp.event.unloadStorage.title.finished": "Opslag gelost",
  "mobileApp.event.loadStorage.title.finished": "Opslag geladen",
  "resources.employeeDetails.tabs.schedule": "Werkdagen",
  "resources.employeeDetails.tabs.declarations": "Declaraties",
  "JWT Token not found": "Ongeldige inloggegevens"
}
