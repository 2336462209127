import { NumberTextField, NumberTextFieldProps } from '@bas/ui/native/atoms';
import { ReactElement, useCallback, useEffect, useMemo, useRef } from 'react';
import {
  FieldPath,
  FieldPathValue,
  FieldValues,
  useController,
  UseFormSetFocus,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { TextInput } from 'react-native';

export type ReactHookFormNumberTextFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<NumberTextFieldProps, 'name' | 'value' | 'error' | 'onChangeText'> & {
  name: TName;
  fieldValue?: string;
  updateOnBlur?: boolean;
  disableError?: boolean;
  loading?: boolean;
  setFocus?: UseFormSetFocus<TFieldValues>;
  nextField?: FieldPath<TFieldValues>;
  onSubmit?: () => void | Promise<void>;
  onFocus?: () => void;
  onBlur?: () => void;
  variant?: 'outlined' | 'standard' | 'filled';
  hideLabel?: boolean;
};

const ReactHookFormNumberTextField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  disabled,
  disableError,
  loading,
  setFocus,
  nextField,
  onSubmit,
  fieldValue,
  onFocus: onFocusProp,
  onBlur: onBlurProp,
  updateOnBlur = false,
  ...props
}: ReactHookFormNumberTextFieldProps<TFieldValues, TName>): ReactElement => {
  const {
    field: { value, onBlur, onChange, ref },
    fieldState: { error, invalid },
    formState: { isSubmitting },
  } = useController<TFieldValues, TName>({
    name,
  });
  const { formatMessage } = useIntl();
  const fieldError = error?.message;
  const showError = !disableError && invalid;
  const inputRef = useRef<TextInput | null>(null);

  const textFieldArgs = useMemo(
    () => ({
      validationMessage: showError ? fieldError : undefined,
      enableErrors: true,
      error: showError ? !!fieldError : undefined,
      disabled: disabled ?? isSubmitting,
    }),
    [showError, fieldError, disabled, isSubmitting],
  );

  const selectNextFieldOrSubmit = useCallback(() => {
    if (nextField) {
      setFocus?.(nextField, { shouldSelect: false });
    } else if (onSubmit) {
      onSubmit();
    }
  }, [nextField, onSubmit, setFocus]);

  useEffect(() => {
    if (inputRef.current) {
      ref(inputRef.current);
    }
  }, [ref, inputRef]);

  const valueProp: { value?: string } = {};
  if (loading) {
    valueProp.value = formatMessage({ id: 'label.loading' });
  } else if (!fieldValue) {
    valueProp.value = value || '';
  } else {
    valueProp.value = value[fieldValue] || '';
  }

  const handleBlur = useCallback(
    () => () => {
      onBlurProp?.();
      onBlur();
    },
    [onBlur, onBlurProp],
  );

  const handleChange = useCallback(
    (data: unknown) => {
      let newValue;
      if (!data) {
        newValue = undefined;
      } else {
        newValue = data as string;
      }

      if (fieldValue) {
        onChange({
          ...value,
          [fieldValue]: newValue,
        } as FieldPathValue<TFieldValues, TName>);
      } else {
        onChange(newValue as FieldPathValue<TFieldValues, TName>);
      }
    },
    [fieldValue, onChange, value],
  );

  return (
    <NumberTextField
      onChangeText={handleChange}
      value={value}
      {...textFieldArgs}
      {...props}
      {...valueProp}
      onSubmitEditing={selectNextFieldOrSubmit}
      blurOnSubmit={false}
      onBlur={handleBlur}
      onFocus={onFocusProp}
      inputRef={inputRef}
      updateOnBlur={updateOnBlur}
    />
  );
};

export default ReactHookFormNumberTextField;
