import { InvoiceLine, VatScenario } from '@bas/financial-domain/models';
import { formatDate } from '@bas/shared/utils';
import { Discount, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type UpdateInvoiceByInvoiceIdMutationProps = {
  invoiceId: Uuid;
  lines: InvoiceLine[];
  freeText: { [key: string]: string | undefined };
  includingVat: boolean;
  templateId: Uuid;
  reference?: string | null;
  expiresAt: string | Date | null | undefined;
  discount: Discount | null | undefined;
  vatScenario: VatScenario;
};

export const UpdateInvoiceByInvoiceIdMutation = async ({
  invoiceId,
  lines,
  expiresAt,
  ...values
}: UpdateInvoiceByInvoiceIdMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/invoices/${invoiceId}`, {
    invoiceId,
    lines: lines.map((line) => ({
      ...line,
      vatCodeId: line.vatCode.vatCodeId,
    })),
    expiresAt: expiresAt ? formatDate(new Date(expiresAt)) : null,
    ...values,
  });

export const useUpdateInvoiceByInvoiceIdMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateInvoiceByInvoiceIdMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  UpdateInvoiceByInvoiceIdMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateInvoiceByInvoiceIdMutationProps
  >({
    mutationFn: UpdateInvoiceByInvoiceIdMutation,
    throwOnError: false,
    ...options,
  });
