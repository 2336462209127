import { Country, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type EmployeeMovedMutationProps = {
  employeeId: Uuid;
  streetName: string;
  houseNumber: string;
  houseNumberAddition?: string | null;
  zipCode: string;
  city: string;
  country: Country;
  latitude?: number | null;
  longitude?: number | null;
};

export const EmployeeMovedMutation = async ({
  employeeId,
  ...values
}: EmployeeMovedMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/hrm/employees/${employeeId}/moved`, {
    employeeId,
    ...values,
  });

export const useEmployeeMovedMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    EmployeeMovedMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  EmployeeMovedMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    EmployeeMovedMutationProps
  >({
    mutationFn: EmployeeMovedMutation,
    throwOnError: false,
    ...options,
  });
