import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    display: 'flex',
  },
  noWrap: {
    flexWrap: 'nowrap',
  },
  flex3: {
    // flexDirection: 'row',
    overflow: 'hidden',
    flexBasis: '25%',
    flexGrow: 1,
    width: '25%',
    maxWidth: '25%',
  },
  flex6: {
    // flexDirection: 'row',
    overflow: 'hidden',
    flexBasis: '50%',
    flexGrow: 1,
    width: '50%',
    maxWidth: '50%',
  },
  flex12: {
    // flexDirection: 'row',
    overflow: 'hidden',
    flexBasis: '100%',
    flexGrow: 1,
    width: '100%',
    maxWidth: '100%',
  },
  flex4: {
    // flexDirection: 'row',
    overflow: 'hidden',
    flexBasis: '33.3333333333%',
    flexGrow: 1,
    width: '33.3333333333%',
    maxWidth: '33.3333333333%',
  },
  flexPaddingRight16: {
    paddingRight: 16,
  },
  flexPaddingRight24: {
    paddingRight: 24,
  },
  flexPaddingTop16: {
    paddingTop: 16,
  },
  flexPaddingTop24: {
    paddingTop: 24,
  },
});
