import { Box } from '@bas/ui/native/base';
import {
  ReactHookFormDropdown,
  ReactHookFormTextField,
} from '@bas/ui/native/molecules';
import {
  EmailAddress,
  Gender,
  PhoneNumber,
  Salutation,
} from '@bas/value-objects';
import { ReactElement } from 'react';
import { useIntl } from 'react-intl';

export type ContactPersonFormInput = {
  firstName?: string | null;
  middleName?: string | null;
  lastName: string;
  emailAddress?: EmailAddress;
  phoneNumber?: PhoneNumber | null;
  mobileNumber?: PhoneNumber | null;
  gender?: Gender | null;
  salutation?: Salutation | null;
};

export type ContactPersonFormProps = {
  prefix: string;
  disabled?: boolean;
  light?: boolean;
};

const ContactPersonForm = ({
  prefix,
  disabled,
  light,
}: ContactPersonFormProps): ReactElement => {
  const { formatMessage } = useIntl();
  return (
    <Box>
      <Box flexDirection="row" width="100%">
        <Box flexBasis="50%" flexGrow={1} overflow="hidden" paddingRight={3}>
          <ReactHookFormDropdown
            name={`${prefix}.gender`}
            label={formatMessage({ id: 'label.gender' })}
            disabled={disabled}
            light={light}
            items={[
              ...Object.values(Gender).map((option) => ({
                id: option,
                label: formatMessage({
                  id: `genders.${option || 'unknown'}`,
                }),
              })),
            ]}
          />
        </Box>
        <Box flexBasis="50%" flexGrow={1} overflow="hidden">
          <ReactHookFormDropdown
            name={`${prefix}.salutation`}
            label={formatMessage({ id: 'label.salutation' })}
            disabled={disabled}
            light={light}
            items={[
              ...Object.values(Salutation).map((option) => ({
                id: option,
                label: option,
              })),
            ]}
          />
        </Box>
      </Box>
      <Box flexDirection="row" width="100%" paddingRight={2}>
        <Box
          paddingRight={3}
          flexBasis="33.3333333333%"
          flexGrow={1}
          overflow="hidden"
        >
          <ReactHookFormTextField
            name={`${prefix}.firstName`}
            autoComplete="off"
            disabled={disabled}
            label={formatMessage({ id: 'label.firstName' })}
            light={light}
          />
        </Box>
        <Box
          paddingRight={3}
          flexBasis="33.3333333333%"
          flexGrow={1}
          overflow="hidden"
        >
          <ReactHookFormTextField
            name={`${prefix}.middleName`}
            autoComplete="off"
            disabled={disabled}
            label={formatMessage({ id: 'label.middleName' })}
            light={light}
          />
        </Box>
        <Box flexBasis="33.3333333333%" flexGrow={1} overflow="hidden">
          <ReactHookFormTextField
            name={`${prefix}.lastName`}
            autoComplete="off"
            disabled={disabled}
            label={formatMessage({ id: 'label.lastName' })}
            light={light}
          />
        </Box>
      </Box>
      <Box flexDirection="row" width="100%" paddingRight={2}>
        <Box flexBasis="100%" flexGrow={1} overflow="hidden">
          <ReactHookFormTextField
            name={`${prefix}.emailAddress`}
            autoComplete="off"
            disabled={disabled}
            keyboardType="email-address"
            label={formatMessage({ id: 'label.emailAddress' })}
            light={light}
          />
        </Box>
      </Box>
      <Box flexDirection="row" width="100%" paddingRight={2}>
        <Box flexBasis="50%" flexGrow={1} overflow="hidden" paddingRight={3}>
          <ReactHookFormTextField
            name={`${prefix}.phoneNumber`}
            autoComplete="off"
            disabled={disabled}
            keyboardType="phone-pad"
            label={formatMessage({ id: 'label.phoneNumber' })}
            light={light}
          />
        </Box>
        <Box flexBasis="50%" flexGrow={1} overflow="hidden">
          <ReactHookFormTextField
            name={`${prefix}.mobileNumber`}
            autoComplete="off"
            disabled={disabled}
            keyboardType="phone-pad"
            label={formatMessage({ id: 'label.mobileNumber' })}
            light={light}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ContactPersonForm;
