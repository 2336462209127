import { ErrorResponse, Language, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type RemoveAttachmentFromDocumentTemplateMutationProps = {
  documentTemplateId: Uuid;
  attachmentId: Uuid;
  language: Language;
};

export const RemoveAttachmentFromDocumentTemplateMutation = async ({
  documentTemplateId,
  ...values
}: RemoveAttachmentFromDocumentTemplateMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/document-templates/${documentTemplateId}/remove-attachment`,
    {
      documentTemplateId,
      ...values,
    },
  );

export const useRemoveAttachmentFromDocumentTemplateMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveAttachmentFromDocumentTemplateMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  RemoveAttachmentFromDocumentTemplateMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveAttachmentFromDocumentTemplateMutationProps
  >({
    mutationFn: RemoveAttachmentFromDocumentTemplateMutation,
    throwOnError: false,
    ...options,
  });
