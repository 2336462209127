import { ExternalEmployee } from '@bas/integration-domain/models';
import { QueryOptionsWithKey } from '@bas/shared/requests';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';

export type ExternalEmployeeByIntegrationIdAndIdRequestProps = {
  integrationId: Uuid;
  id: Uuid;
};

type Response = AxiosResponse<ExternalEmployee>;

export const ExternalEmployeeByIntegrationIdAndIdRequest = async ({
  integrationId,
  id,
  ...params
}: ExternalEmployeeByIntegrationIdAndIdRequestProps): Promise<Response> =>
  axios.get(
    `api/{tenantId}/integrations/${integrationId}/external-employees/${id}`,
    {
      params: { ...params },
      paramsSerializer(param) {
        return Qs.stringify(param, { arrayFormat: 'brackets' });
      },
    },
  );

export const useExternalEmployeeByIntegrationIdAndIdRequest = (
  request: ExternalEmployeeByIntegrationIdAndIdRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => ExternalEmployeeByIntegrationIdAndIdRequest(request),
    queryKey: [
      'integrations',
      'external-employees',
      request.integrationId,
      request.id,
      request,
    ],
  });
