import * as Yup from 'yup';

export type ClaEnableOrDisableCompensationRuleInputType = {
  enabledFrom: Date;
  enabledUntil?: Date;
};

export const claEnableOrDisableCompensationRuleInputTypeValidationBuilder =
  (): Yup.ObjectSchema<ClaEnableOrDisableCompensationRuleInputType> =>
    Yup.object({
      enabledFrom: Yup.date().required().label('label.enabledFrom'),
      enabledUntil: Yup.date().optional().label('label.enabledUntil'),
    });
