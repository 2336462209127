import { ErrorResponse } from '@bas/value-objects';
import { PublicKeyCredentialCreationOptionsJSON } from '@simplewebauthn/types';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

type RetrievePassKeyOptionsProps = Record<string, string>;

type RetrievePassKeyOptionsResponse =
  AxiosResponse<PublicKeyCredentialCreationOptionsJSON>;

export const RetrievePassKeyOptions =
  async (): Promise<RetrievePassKeyOptionsResponse> =>
    axios.post(
      '/api/auth/login-with-passkey/options',
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

export const useRetrievePassKeyOptions = (
  options: UseMutationOptions<
    RetrievePassKeyOptionsResponse,
    AxiosError<ErrorResponse>,
    RetrievePassKeyOptionsProps
  > = {},
): UseMutationResult<
  RetrievePassKeyOptionsResponse,
  AxiosError<ErrorResponse>,
  RetrievePassKeyOptionsProps
> =>
  useMutation<
    RetrievePassKeyOptionsResponse,
    AxiosError<ErrorResponse>,
    RetrievePassKeyOptionsProps
  >({
    mutationFn: RetrievePassKeyOptions,
    throwOnError: true,
    ...options,
  });
