import { HoursEntryType } from '@bas/hrm-domain/models';
import { formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type EnterNormalHoursMutationProps = {
  entryId: Uuid;
  employeeId: Uuid;
  reason?: string | null;
  startDate: Date;
  endDate: Date;
  breakTime?: number | null;
  entryType: HoursEntryType;
  eventId?: Uuid | null;
  projectId?: Uuid | null;
  approved?: boolean;
  approvedById?: Uuid | null;
};

export const EnterNormalHoursMutation = async ({
  entryId,
  employeeId,
  startDate,
  endDate,
  entryType,
  eventId,
  projectId,
  ...values
}: EnterNormalHoursMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/{tenantId}/hrm/employees/${employeeId}/enter-hours`, {
    entryId,
    employeeId,
    startDate: formatDateTime(startDate),
    endDate: formatDateTime(endDate),
    entryData: {
      entryType,
      eventId,
      projectId,
    },
    ...values,
  });

export const useEnterNormalHoursMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    EnterNormalHoursMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  EnterNormalHoursMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    EnterNormalHoursMutationProps
  >({
    mutationFn: EnterNormalHoursMutation,
    throwOnError: false,
    ...options,
  });
