import { CrmContactPersonType } from '@bas/crm-domain/models';
import {
  Country,
  EmailAddress,
  ErrorResponse,
  Gender,
  PhoneNumber,
  Salutation,
  Uuid,
} from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type UpdateContactPersonOfRelationMutationProps = {
  relationId: Uuid;
  contactPersonId: Uuid;
  contactPersonType: CrmContactPersonType;
  firstName?: string | null;
  middleName?: string | null;
  lastName: string;
  emailAddress?: EmailAddress | null;
  phoneNumber?: PhoneNumber | null;
  mobileNumber?: PhoneNumber | null;
  gender?: Gender | null;
  salutation?: Salutation | null;
  region?: Country;
};

export const UpdateContactPersonOfRelationMutation = async ({
  relationId,
  contactPersonId,
  ...values
}: UpdateContactPersonOfRelationMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/relations/${relationId}/contact-persons/${contactPersonId}`,
    {
      relationId,
      contactPersonId,
      ...values,
    },
  );

export const useUpdateContactPersonOfRelationMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateContactPersonOfRelationMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  UpdateContactPersonOfRelationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateContactPersonOfRelationMutationProps
  >({
    mutationFn: UpdateContactPersonOfRelationMutation,
    throwOnError: false,
    ...options,
  });
