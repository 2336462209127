import { basAssemble, basDisassemble } from '@bas/svg-icons';
import { Icon, IconProps } from '@bas/ui/native/base';
import { InternalServiceType } from '@bas/value-objects';
import { faBoxFull } from '@fortawesome/pro-light-svg-icons/faBoxFull';
import { faBoxOpen } from '@fortawesome/pro-light-svg-icons/faBoxOpen';
import { faForklift } from '@fortawesome/pro-light-svg-icons/faForklift';
import { faHouseChimneyCrack } from '@fortawesome/pro-light-svg-icons/faHouseChimneyCrack';
import { faMemoCircleCheck } from '@fortawesome/pro-light-svg-icons/faMemoCircleCheck';
import { faPalletBoxes } from '@fortawesome/pro-light-svg-icons/faPalletBoxes';
import { faQuestion } from '@fortawesome/pro-light-svg-icons/faQuestion';
import { faRecycle } from '@fortawesome/pro-light-svg-icons/faRecycle';
import { faWarehouseAlt } from '@fortawesome/pro-light-svg-icons/faWarehouseAlt';
import { faWineGlassCrack } from '@fortawesome/pro-light-svg-icons/faWineGlassCrack';
import { FunctionComponent } from 'react';

export type InternalServiceTypeIconProps = Omit<IconProps, 'icon'> & {
  serviceType: InternalServiceType;
};

const serviceIconMap = {
  [InternalServiceType.PROVIDE_BOXES]: faPalletBoxes,
  [InternalServiceType.PACKING]: faBoxFull,
  [InternalServiceType.UNPACK]: faBoxOpen,
  [InternalServiceType.ASSEMBLE]: basAssemble,
  [InternalServiceType.DISASSEMBLE]: basDisassemble,
  [InternalServiceType.MOVING_LIFT]: faForklift,
  [InternalServiceType.WASTE_DISPOSAL]: faRecycle,
  [InternalServiceType.PERMITS]: faMemoCircleCheck,
  [InternalServiceType.STORAGE]: faWarehouseAlt,
  [InternalServiceType.INSURANCE]: faHouseChimneyCrack,
  [InternalServiceType.CERTIFICATE]: faWineGlassCrack,
};

const InternalServiceTypeIcon: FunctionComponent<
  InternalServiceTypeIconProps
> = ({ serviceType, ...args }: InternalServiceTypeIconProps) => {
  let icon = faQuestion;
  if (serviceIconMap[serviceType]) {
    icon = serviceIconMap[serviceType];
  }

  return <Icon {...args} icon={icon} />;
};

export default InternalServiceTypeIcon;
