import { CrmAddressType } from '@bas/crm-domain/models';
import {
  AddressInputType,
  addressInputTypeDefaultValues,
  addressInputTypeValidationBuilder,
} from '@bas/shared/input-types';
import { Uuid } from '@bas/value-objects';
import { v7 } from 'uuid';
import * as Yup from 'yup';

export interface CrmAddressInputType extends AddressInputType {
  addressId?: Uuid;
  addressType: CrmAddressType;
  deleteAddress: boolean;
}

export const initialCrmAddressInputType = (): CrmAddressInputType => ({
  ...addressInputTypeDefaultValues(),
  addressId: v7(),
  addressType: CrmAddressType.UNKNOWN,
  deleteAddress: false,
});

export const crmAddressInputTypeValidator =
  (): Yup.ObjectSchema<CrmAddressInputType> =>
    addressInputTypeValidationBuilder().concat(
      Yup.object({
        addressId: Yup.string(),
        addressType: Yup.mixed<CrmAddressType>()
          .label('label.addressType')
          .oneOf(Object.values(CrmAddressType).filter((option) => !!option))
          .required(),
        deleteAddress: Yup.boolean().defined().default(false).required(),
      }),
    );
