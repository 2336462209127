import { isInvoiceEvent } from './InvoiceEvent';
import { InvoiceEventType, InvoiceSentEvent } from '../types';

export function isInvoiceSentEvent(
  object: unknown,
): object is InvoiceSentEvent {
  return (
    isInvoiceEvent(object) &&
    object.eventType === InvoiceEventType.InvoiceSentEvent
  );
}
