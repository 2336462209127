import { BottomSheet } from '@bas/ui/native/base';
import { BottomSheetModalProps as BaseBottomSheetModalProps } from '@gorhom/bottom-sheet';

export type BottomSheetProps = Omit<BaseBottomSheetModalProps, 'snapPoints'> & {
  disablePadding?: boolean;
  disableClose?: boolean;
  fullWidth?: boolean;
  snapPoints?: number[];
};

export default BottomSheet;
