import {
  DriversLicenceCharacteristics,
  ErrorResponse,
  Uuid,
} from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ChangeVehicleDriversLicenseMutationProps = {
  materialId: Uuid;
  driversLicenseNeeded: DriversLicenceCharacteristics;
};

export const ChangeVehicleDriversLicenseMutation = async ({
  materialId,
  ...values
}: ChangeVehicleDriversLicenseMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/vehicles/${materialId}/change-drivers-license`, {
    materialId,
    ...values,
  });

export const useChangeVehicleDriversLicenseMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeVehicleDriversLicenseMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ChangeVehicleDriversLicenseMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeVehicleDriversLicenseMutationProps
  >({
    mutationFn: ChangeVehicleDriversLicenseMutation,
    throwOnError: false,
    ...options,
  });
