import { ProjectType } from '@bas/value-objects';
import type { ListedTransportJob } from '../types';
import { isListedProject } from './ListedProject';

export function isListedTransportJob(
  object: unknown,
): object is ListedTransportJob {
  return (
    isListedProject(object) && object.projectType === ProjectType.TRANSPORT_JOB
  );
}
