import { CrmAddressType, RelationType } from '@bas/crm-domain/models';
import {
  EmailAddress,
  Gender,
  HowDidYouFindUs,
  Language,
  PhoneNumber,
  Salutation,
} from '@bas/value-objects';
import { v7 } from 'uuid';
import * as Yup from 'yup';
import {
  CrmAddressInputType,
  crmAddressInputTypeValidator,
  initialCrmAddressInputType,
} from './crmAddressInputType';

export interface CreateRelationInputType {
  relationType: RelationType;
  gender?: Gender | null;
  salutation?: Salutation | null;
  firstName?: string | null | undefined;
  middleName?: string | null | undefined;
  lastName: string;
  companyName?: string | null;
  cocNumber?: string | null | undefined;
  vatNumber?: string | null | undefined;
  rsinNumber?: string | null | undefined;
  emailAddress: EmailAddress;
  phoneNumber?: PhoneNumber | null | undefined;
  address: CrmAddressInputType;
  language: Language;
  mobileNumber?: PhoneNumber | null | undefined;
  howDidYouFindUs?: HowDidYouFindUs | null;
}

export const CreateRelationInputTypeValidationBuilder =
  (): Yup.ObjectSchema<CreateRelationInputType> =>
    Yup.object({
      relationType: Yup.mixed<RelationType>()
        .oneOf(Object.values(RelationType).filter((option) => !!option))
        .required()
        .label('label.relationType'),
      companyName: Yup.string()
        .nullable()
        .when(['relationType'], ([relationType], schema) => {
          if (!relationType || relationType === 'private') {
            return schema.nullable();
          }

          return schema.required();
        })
        .label('label.companyName'),
      cocNumber: Yup.string().label('label.cocNumber').nullable(),
      vatNumber: Yup.string().vatNumber().label('label.vatNumber').nullable(),
      rsinNumber: Yup.string().label('label.rsinNumber').nullable(),
      gender: Yup.mixed<Gender>()
        .oneOf(Object.values(Gender))
        .label('label.gender')
        .nullable(),
      salutation: Yup.mixed<Salutation>()
        .oneOf(Object.values(Salutation))
        .label('label.salutation')
        .nullable(),
      language: Yup.mixed<Language>()
        .oneOf(Object.values(Language))
        .required()
        .label('label.language'),
      howDidYouFindUs: Yup.mixed<HowDidYouFindUs>()
        .oneOf(Object.values(HowDidYouFindUs))
        .nullable()
        .label('label.howDidYouFindUs'),
      firstName: Yup.string().label('label.firstName').nullable().optional(),
      middleName: Yup.string().label('label.middleName').nullable().optional(),
      lastName: Yup.string().required().defined().label('label.lastName'),
      emailAddress: Yup.string().email().required().label('label.emailAddress'),
      phoneNumber: Yup.string().phone().nullable().label('label.phoneNumber'),
      mobileNumber: Yup.string().phone().nullable().label('label.mobileNumber'),
      address: crmAddressInputTypeValidator(),
    });

export const CreateRelationInputTypeDefaultValues =
  (): CreateRelationInputType => ({
    relationType: RelationType.UNKNOWN,
    language: Language.NL,
    emailAddress: '',
    lastName: '',
    address: {
      ...initialCrmAddressInputType(),
      addressId: v7(),
      addressType: CrmAddressType.PRIMARY,
    },
  });

export function isCreateRelationInputType(
  object: unknown,
): object is CreateRelationInputType {
  return (
    !!object &&
    typeof object === 'object' &&
    Object.prototype.hasOwnProperty.call(object, 'relationType')
  );
}
