import { ReactElement } from 'react';
import { Box, Typography } from '@bas/ui/native/base';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { FormattedCurrency } from '@bas/ui/native/atoms';
import { Declaration } from '@bas/hrm-domain/models';

export type DeclarationApprovalInformationContentSectionProps = {
  declaration: Declaration;
};

const DeclarationApprovalInformationContentSection = ({
  declaration,
}: DeclarationApprovalInformationContentSectionProps): ReactElement => (
  <Box flexDirection="row" flexWrap="wrap">
    <Box flexBasis="100%">
      <Typography variant="subtitle1" color="white">
        <FormattedMessage id="label.approvalInformation" />
      </Typography>
    </Box>
    <Box flexBasis="50%">
      <Typography variant="subtitle2" color="lila.600">
        <FormattedMessage id="label.approvalInformation" />
      </Typography>
      {declaration.approvedByName && (
        <Typography color="white">{declaration.approvedByName}</Typography>
      )}
      {declaration.approvedAmount &&
        declaration.approvedAmount?.amount !== 0 && (
          <Typography color="white">
            <FormattedCurrency {...declaration.approvedAmount} />
          </Typography>
        )}
      {declaration.approvedOn && (
        <Typography color="white">
          <FormattedDate value={declaration.approvedOn} dateStyle="short" />
        </Typography>
      )}
      <Typography color="white">{declaration.approvedReason}</Typography>
    </Box>
    {declaration.paidOn && (
      <Box flexBasis="50%">
        <Typography variant="subtitle2" color="lila.600">
          <FormattedMessage id="label.paymentInformation" />
        </Typography>
        {declaration.paidByName && (
          <Typography color="white">{declaration.paidByName}</Typography>
        )}
        {declaration.paidAmount && !!declaration.paidAmount?.amount && (
          <Typography color="white">
            <FormattedCurrency {...declaration.paidAmount} />
          </Typography>
        )}
        {declaration.paidOn && (
          <Typography color="white">
            <FormattedDate value={declaration.paidOn} dateStyle="short" />
          </Typography>
        )}
        <Typography color="white">{declaration.paidReason}</Typography>
      </Box>
    )}
  </Box>
);

export default DeclarationApprovalInformationContentSection;
