import { isObjectResponse } from '@bas/value-objects';
import type { TimeOffBalance } from '../types';

export function isTimeOffBalance(object: unknown): object is TimeOffBalance {
  return (
    !!object &&
    typeof object === 'object' &&
    isObjectResponse(object) &&
    object['@type'] === 'TimeOffBalance'
  );
}
