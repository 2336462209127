import { ErrorResponse } from '@bas/value-objects';
import { PublicKeyCredentialCreationOptionsJSON } from '@simplewebauthn/types';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

type StartRegisterPassKeyMutationProps = Record<string, string>;

type StartRegisterPassKeyMutationResponse =
  AxiosResponse<PublicKeyCredentialCreationOptionsJSON>;

export const StartRegisterPassKeyMutation =
  async (): Promise<StartRegisterPassKeyMutationResponse> =>
    axios.post(
      '/api/profile/security/devices/add/options',
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

export const useStartRegisterPassKeyMutation = (
  options: UseMutationOptions<
    StartRegisterPassKeyMutationResponse,
    AxiosError<ErrorResponse>,
    StartRegisterPassKeyMutationProps
  > = {},
): UseMutationResult<
  StartRegisterPassKeyMutationResponse,
  AxiosError<ErrorResponse>,
  StartRegisterPassKeyMutationProps
> =>
  useMutation<
    StartRegisterPassKeyMutationResponse,
    AxiosError<ErrorResponse>,
    StartRegisterPassKeyMutationProps
  >({
    mutationFn: StartRegisterPassKeyMutation,
    throwOnError: true,
    ...options,
  });
