import { ListedEmail } from '@bas/communication-domain/models';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import { CustomFormatConfig } from 'react-intl';

export const useCalculateEmailDateProps = () =>
  useCallback(
    (
      email: ListedEmail,
    ): Intl.DateTimeFormatOptions &
      CustomFormatConfig & {
        value: string | number | Date | undefined;
      } => {
      let date: Date = email.createdAt;
      let dateStyle: Intl.DateTimeFormatOptions['dateStyle'] = 'short';
      let timeStyle: Intl.DateTimeFormatOptions['timeStyle'];
      let year: Intl.DateTimeFormatOptions['year'];
      let month: Intl.DateTimeFormatOptions['month'];
      let day: Intl.DateTimeFormatOptions['day'];

      if (email.metadata.receivedAt) {
        date = email.metadata.receivedAt;
      }

      if (dayjs(date).isSame(dayjs(), 'day')) {
        dateStyle = undefined;
        timeStyle = 'short';
      }

      if (dayjs(date).isSame(dayjs(), 'year')) {
        dateStyle = undefined;
        timeStyle = undefined;
        year = undefined;
        month = 'short';
        day = 'numeric';
      }

      return {
        value: date,
        dateStyle,
        timeStyle,
        year,
        month,
        day,
      };
    },
    [],
  );
