/* eslint-disable prefer-destructuring */
import {
  AddDeclarationInputType,
  ChangeDeclarationInputType,
} from '@bas/hrm-domain/input-types';
import { Declaration } from '@bas/hrm-domain/models';
import {
  useAddDeclarationMutation,
  useCancelDeclarationMutation,
  useChangeDeclarationMutation,
} from '@bas/hrm-domain/mutations';
import { DeclarationListItem } from '@bas/hrm-domain/native/molecules';
import {
  AddDeclarationBottomSheet,
  DeclarationDetailsBottomSheet,
} from '@bas/hrm-domain/native/organisms';
import { useDeclarationsByEmployeeIdRequest } from '@bas/hrm-domain/requests';
import { useEmployeeStore } from '@bas/shared/state';
import { colors } from '@bas/theme';
import { Button } from '@bas/ui/native/atoms';
import { BottomSheetMethods, Box } from '@bas/ui/native/base';
import { ConfirmBottomSheet } from '@bas/ui/native/templates';
import { Uuid } from '@bas/value-objects';
import { faMoneyCheckPen } from '@fortawesome/pro-light-svg-icons/faMoneyCheckPen';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { FlashList, ListRenderItemInfo } from '@shopify/flash-list';
import dayjs from 'dayjs';
import * as React from 'react';
import { ReactElement, useCallback, useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { RefreshControl } from 'react-native';
import { v7 } from 'uuid';

const DeclarationsScreen = (): ReactElement => {
  const addDeclarationBottomSheetRef = useRef<
    BottomSheetModal & BottomSheetMethods
  >(null);
  const declarationDetailsBottomSheetRef = useRef<
    BottomSheetModal & BottomSheetMethods
  >(null);
  const cancelDeclarationBottomSheetRef = useRef<
    BottomSheetModal & BottomSheetMethods
  >(null);
  const employeeId = useEmployeeStore((state) => state.employee?.employeeId);
  const { mutateAsync: addDeclaration } = useAddDeclarationMutation();
  const { mutateAsync: changeDeclaration } = useChangeDeclarationMutation();
  const { mutateAsync: cancelDeclaration } = useCancelDeclarationMutation();

  const cancelDeclarationIdRef = useRef<Uuid>();

  const {
    data: declarationsDate,
    isFetching,
    refetch: onRefresh,
  } = useDeclarationsByEmployeeIdRequest({
    perPage: 9999,
    employeeId: employeeId as string,
    start: dayjs().startOf('year').toDate(),
    end: dayjs().endOf('year').toDate(),
  });

  const declarations = useMemo(
    () => declarationsDate?.data.member || [],
    [declarationsDate?.data],
  );

  const handleCancelDeclaration = useCallback(async () => {
    await cancelDeclaration({
      declarationId: cancelDeclarationIdRef.current || '',
    });
  }, [cancelDeclaration]);

  const handleChangeDeclaration = useCallback(
    async (values: ChangeDeclarationInputType) => {
      await changeDeclaration({
        declarationId: values.declarationId,
        declarationDate: values.declarationDate as Date,
        description: values.description,
        amount: values.amount,
        categoryId: values.categoryId,
        receiptFileId: values.receipt?.mediaObjectId,
        eventId: values.eventId,
        projectId: values.project?.projectId,
        relationId: values.relation?.relationId,
      });
    },
    [changeDeclaration],
  );

  const handleAddDeclaration = useCallback(
    async (values: AddDeclarationInputType) => {
      await addDeclaration({
        employeeId: values.employeeId,
        declarationDate: values.declarationDate as Date,
        categoryId: values.categoryId,
        amount: values.amount,
        description: values.description,
        declarationId: v7(),
        receiptFileId: values.receipt?.mediaObjectId || '',
        projectId: values.project?.projectId || '',
        relationId: values.relation?.relationId || '',
        eventId: values.eventId || '',
      });
    },
    [addDeclaration],
  );

  const handlePressDeclaration = useCallback(
    async (declaration: Declaration) => {
      declarationDetailsBottomSheetRef.current?.dismiss();
      declarationDetailsBottomSheetRef.current?.present({
        declaration,
      });
    },
    [],
  );

  const handleRenderDeclaration = useCallback(
    ({ item }: ListRenderItemInfo<Declaration>) => (
      <DeclarationListItem
        declaration={item}
        onPress={() => handlePressDeclaration(item)}
        onCancelDeclaration={() => {
          cancelDeclarationIdRef.current = item.declarationId;
          cancelDeclarationBottomSheetRef.current?.present();
        }}
      />
    ),
    [handlePressDeclaration],
  );

  const sortedDeclarations = useMemo(
    () =>
      declarations.sort((a, b) =>
        a.declarationDate > b.declarationDate ? -1 : 1,
      ),
    [declarations],
  );

  return (
    <Box flex={1}>
      <Box
        width="100%"
        backgroundColor="cardBorder"
        paddingHorizontal={20}
        paddingBottom={2}
      >
        <Box
          flexDirection="row"
          width="100%"
          flexWrap="wrap"
          justifyContent="space-between"
          columnGap={2}
        >
          <Box>
            <Button
              variant="contained"
              icon={faMoneyCheckPen}
              paddingVertical={1}
              onPress={() => {
                addDeclarationBottomSheetRef.current?.present();
              }}
            >
              <FormattedMessage id="button.addDeclaration" />
            </Button>
          </Box>
        </Box>
      </Box>
      <Box flex={1}>
        <FlashList
          renderItem={handleRenderDeclaration}
          data={sortedDeclarations}
          refreshControl={
            <RefreshControl refreshing={isFetching} onRefresh={onRefresh} />
          }
          keyExtractor={(item) => item.declarationId}
          estimatedItemSize={90}
        />
      </Box>
      <AddDeclarationBottomSheet
        onSubmit={handleAddDeclaration}
        bottomSheetRef={addDeclarationBottomSheetRef}
      />
      <DeclarationDetailsBottomSheet
        onSubmit={handleChangeDeclaration}
        bottomSheetRef={declarationDetailsBottomSheetRef}
      />
      <ConfirmBottomSheet
        closeOnCancel
        onConfirm={handleCancelDeclaration}
        bottomSheetRef={cancelDeclarationBottomSheetRef}
        dangerous
        title={<FormattedMessage id="label.cancelDeclaration" />}
        content={
          <FormattedMessage id="label.areYouSureYouWantToCancelDeclaration" />
        }
        successFullFormViewProps={{
          gifUrl: 'https://media.giphy.com/media/KbvZsN07K9Hy9ZoyR7/giphy.gif',
          color: colors.lila[100],
          title: 'label.declarationCancelled',
          labelId: 'label.declarationCancelledDescription',
        }}
      />
    </Box>
  );
};

export default DeclarationsScreen;
