import { StyleSheet } from 'react-native';
import { colors } from '@bas/theme';

export default StyleSheet.create({
  image: {
    width: 40,
    height: 40,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  imageContainer: {
    backgroundColor: colors.lila[700],
    width: 40,
    height: 40,
    borderRadius: 40,
    overflow: 'hidden',
    marginRight: 16,
    position: 'relative',
  },
  lightContainer: {
    backgroundColor: colors.lila[200],
  },
  textContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
