import { ClaAllowanceRuleType, ClaNightWorkAllowanceRule } from '../types';
import { isClaAllowanceRule } from './ClaAllowanceRule';

export function isClaNightWorkAllowanceRule(
  object: unknown,
): object is ClaNightWorkAllowanceRule {
  return (
    isClaAllowanceRule(object) &&
    object.ruleType === ClaAllowanceRuleType.ClaNightWork
  );
}
