import { Box } from '@bas/ui/native/base';
import {
  StepsNavigation,
  StepsNavigationProps,
} from '@bas/ui/native/organisms';
import { ReactElement, ReactNode } from 'react';
import { View, ViewProps } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import styles from './styles';

export type MobilePageWithStepsNavigationTemplateProps = ViewProps & {
  stepsNavigation: StepsNavigationProps;
  inBottomSheet?: boolean;
  disablePadding?: boolean;
  disableScroll?: boolean;
};

const MobilePageWithStepsNavigationTemplate = ({
  style,
  children,
  stepsNavigation: { style: navigationStyle, ...stepsNavigation },
  inBottomSheet,
  disablePadding,
  disableScroll,
  ...props
}: MobilePageWithStepsNavigationTemplateProps): ReactElement => {
  let content: ReactNode = (
    <ScrollView contentContainerStyle={styles.scrollContainer}>
      {children}
    </ScrollView>
  );

  if (inBottomSheet && !disableScroll) {
    content = (
      <ScrollView
        contentContainerStyle={styles.scrollContainer}
        scrollEnabled={!disableScroll}
      >
        {children}
      </ScrollView>
    );
  }

  if (disableScroll) {
    content = (
      <Box height="100%" flex={1}>
        {children}
      </Box>
    );
  }

  return (
    <View {...props} style={[styles.container, style]}>
      <View
        style={[styles.contentContainer, !disablePadding && styles.padding]}
      >
        {content}
      </View>

      <View style={styles.stepsNavigationContainer}>
        <StepsNavigation style={[navigationStyle]} {...stepsNavigation} />
      </View>
    </View>
  );
};

export default MobilePageWithStepsNavigationTemplate;
