import { isTask } from './Task';
import type { TaskRegardingEvent } from '../types';

export function isTaskRegardingEvent(
  object: unknown,
): object is TaskRegardingEvent {
  return (
    !!object &&
    isTask(object) &&
    Object.prototype.hasOwnProperty.call(object, 'eventId')
  );
}
