import { ErrorResponse, Language, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type UpdateAutomatedEmailTranslationMutationProps = {
  automatedEmailId: Uuid;
  language: Language;
  subject: string;
  content: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  config: any;
};

export const UpdateAutomatedEmailTranslationMutation = async ({
  automatedEmailId,
  ...values
}: UpdateAutomatedEmailTranslationMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/communication/automated-emails/${automatedEmailId}/update-translation`,
    {
      automatedEmailId,
      ...values,
    },
  );

export const useUpdateAutomatedEmailTranslationMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateAutomatedEmailTranslationMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  UpdateAutomatedEmailTranslationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateAutomatedEmailTranslationMutationProps
  >({
    mutationFn: UpdateAutomatedEmailTranslationMutation,
    throwOnError: false,
    ...options,
  });
