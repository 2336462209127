import { EmailAddress, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type SignQuoteWithTheCustomerMutationMutationProps = {
  quoteId: Uuid;
  name: string;
  emailAddress: EmailAddress;
  notes?: string | null;
  signature: string;
  ipAddress: string;
  browser: string;
  acceptedLines: Uuid[];
};

export const SignQuoteWithTheCustomerMutation = async ({
  quoteId,
  acceptedLines,
  ...values
}: SignQuoteWithTheCustomerMutationMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/quotes/${quoteId}/sign`, {
    quoteId,
    acceptedLines: acceptedLines.map((lineId) => ({ lineId })),
    ...values,
  });

export const useSignQuoteWithTheCustomerMutationMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    SignQuoteWithTheCustomerMutationMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  SignQuoteWithTheCustomerMutationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    SignQuoteWithTheCustomerMutationMutationProps
  >({
    mutationFn: SignQuoteWithTheCustomerMutation,
    throwOnError: false,
    ...options,
  });
