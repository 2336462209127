export enum OpenListType {
  DISASSEMBLY = 'disassembly',
  ASSEMBLY = 'assembly',
  DELIVER_BOXES = 'deliver-boxes',
  PICKUP_BOXES = 'pickup-boxes',
  TAKE_MATERIAL = 'take-material',
  RETRIEVE_MATERIAL = 'retrieve-material',
  FURNITURE_LIST = 'furniture-list',
  LOAD_STORAGE = 'load-storage',
  PICKUP_STORAGE = 'pickup-storage',
  UNLOAD_STORAGE = 'unload-storage',
}
