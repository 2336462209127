import { MediaObject } from '@bas/media-domain/models';
import { DamageInflictedOnType } from '@bas/project-domain/models';
import { AddressInputType } from '@bas/shared/input-types';
import { formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ReportDamageMutationProps = {
  damageId: Uuid;
  projectId: Uuid;
  eventId?: Uuid | null;
  damagedAt: Date;
  damageWasAlreadyThere: boolean;
  damageInflictedOn: DamageInflictedOnType;
  location: AddressInputType & {
    index?: string | null;
  };
  summary: string;
  description: string;
  damagedByEmployeeId?: Uuid | null;
  pictures: MediaObject[];
};

export const ReportDamageMutation = async ({
  projectId,
  damagedAt,
  ...values
}: ReportDamageMutationProps): Promise<AxiosResponse> =>
  axios.post('api/{tenantId}/damages/report-damage', {
    projectId,
    damagedAt: formatDateTime(damagedAt),
    ...values,
  });

export const useReportDamageMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ReportDamageMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ReportDamageMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ReportDamageMutationProps
  >({
    mutationFn: ReportDamageMutation,
    throwOnError: false,
    ...options,
  });
