import {
  CalculatedTotalsForAcceptanceQuote,
  Quote,
} from '@bas/financial-domain/models';
import { useCalculateTotalsForAcceptanceQuoteMutation } from '@bas/financial-domain/mutations';
import {
  DesktopQuoteTableForAcceptance,
  MobileQuoteTableForAcceptance,
} from '@bas/financial-domain/native/organisms';
import { SignDocumentForm } from '@bas/planning-domain/native/organisms';
import { isInvoicingFeature, PublicTenant } from '@bas/tenant-domain/models';
import { Box, Typography } from '@bas/ui/native/base';
import { SignDocumentTemplate } from '@bas/ui/native/templates';
import { Uuid } from '@bas/value-objects';
import { useTheme } from '@shopify/restyle';
import {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useWindowDimensions } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { RequestingEstimateStepTemplate } from '../RequestingEstimateStepTemplate';

export type SignQuoteTemplateProps = {
  quote: Quote;
  tenant: PublicTenant;
  pdf: { uri: string; downloadUri: string };
  code: string;
  relationId: Uuid;
  attachments: { name: string; contentUrl: string }[];
  prefix?: string;
  children?: ReactNode;
  acceptedLines: Uuid[];
  setAcceptedLines: (newValue: Uuid[]) => void;
  hidePdf?: boolean;
};
const SignQuoteTemplate = ({
  quote,
  tenant,
  pdf,
  code,
  relationId,
  attachments,
  children,
  prefix,
  acceptedLines,
  setAcceptedLines,
  hidePdf,
}: SignQuoteTemplateProps): ReactElement => {
  const theme = useTheme();
  const dimensions = useWindowDimensions();

  const isMobile = useMemo(
    () => dimensions.width < theme.breakpoints.tablet.width,
    [dimensions.width, theme.breakpoints.tablet.width],
  );

  const [totals, setTotals] = useState<
    CalculatedTotalsForAcceptanceQuote | undefined
  >();
  const [loadingTotals, setLoadingTotals] = useState<boolean>(false);
  const [scrollEnabled, setScrollEnabled] = useState<boolean>(true);
  const { mutateAsync: calculateTotals } =
    useCalculateTotalsForAcceptanceQuoteMutation();

  const { invoicingFeature } = tenant;

  let showTotalsPerHourlyRateRow = true;
  let showQuantityPerHourlyRateRow = true;
  let showTotals = true;
  if (invoicingFeature && isInvoicingFeature(invoicingFeature)) {
    showTotalsPerHourlyRateRow = invoicingFeature.showTotalsPerHourlyRateRow;
    showQuantityPerHourlyRateRow =
      invoicingFeature.showQuantityPerHourlyRateRow;
    showTotals = invoicingFeature.showTotals;
  }

  useEffect(() => {
    if (!quote) {
      return;
    }

    if (quote.lines.length === 0) {
      setTotals(
        (currValue) =>
          currValue || {
            totalExcludingVat: quote.totalExcludingVat,
            totalIncludingVat: quote.totalIncludingVat,
            vatAmounts: quote.vatAmounts,
            discount: quote.discountAmount,
          },
      );

      return;
    }

    if (acceptedLines.length === 0) {
      setAcceptedLines(quote.lines.map(({ lineId }) => lineId));
    }

    setTotals(
      (currValue) =>
        currValue || {
          totalExcludingVat: quote.totalExcludingVat,
          totalIncludingVat: quote.totalIncludingVat,
          vatAmounts: quote.vatAmounts,
          discount: quote.discountAmount,
        },
    );
  }, [quote, setAcceptedLines, acceptedLines]);

  const reloadTotals = useCallback(
    async (newAcceptedLines: Uuid[]) => {
      if (!quote.quoteId || !code || !relationId) {
        return;
      }

      setLoadingTotals(true);
      const { data } = await calculateTotals({
        quoteId: quote.quoteId,
        code,
        relationId,
        acceptedLines: newAcceptedLines,
      });

      setLoadingTotals(false);
      setTotals(data);
    },
    [calculateTotals, code, quote.quoteId, relationId],
  );

  const hasOptionalLines = useMemo(
    () => quote.lines.filter(({ optional }) => optional).length > 0,
    [quote.lines],
  );

  const hourlyRateLines = useMemo(
    () => quote.lines.filter(({ hourlyRate }) => hourlyRate),
    [quote.lines],
  );

  const normalLines = useMemo(
    () => quote.lines.filter(({ hourlyRate }) => !hourlyRate),
    [quote.lines],
  );

  const scrollRef = useRef<ScrollView>(null);

  if (!totals) {
    return <RequestingEstimateStepTemplate />;
  }

  return (
    <SignDocumentTemplate
      title={
        <FormattedMessage
          id="financial.quote.signQuote.title"
          values={{
            quotationNumber: quote.quotationNumber,
            name: quote.relationName,
          }}
        />
      }
      fileName={
        quote.quotationNumber ? `${quote.quotationNumber}.pdf` : 'quote.pdf'
      }
      pdf={pdf}
      attachments={attachments}
      hidePdf={hidePdf}
      scrollRef={scrollRef}
      scrollEnabled={scrollEnabled}
    >
      <Box width="100%">
        <Typography variant="h5" fontWeight={700}>
          <FormattedMessage id="financial.quote.signQuote.subTitle" />
        </Typography>
        <Typography>
          <FormattedMessage id="financial.quote.signQuote.description" />
        </Typography>
      </Box>
      {hourlyRateLines.length > 0 && (
        <Box width="100%">
          {normalLines.length > 0 && (
            <Typography
              variant="h5"
              fontWeight={700}
              mt={4}
              style={{ marginBottom: 6 }}
            >
              <FormattedMessage id="financial.quote.signQuote.hourlyRateLines" />
            </Typography>
          )}
          {isMobile && (
            <MobileQuoteTableForAcceptance
              acceptedLines={acceptedLines}
              setAcceptedLines={setAcceptedLines}
              hasOptionalLines={hasOptionalLines}
              lines={hourlyRateLines}
              reloadTotals={reloadTotals}
              totals={totals}
              loadingTotals={loadingTotals}
              showTotals={false}
              showTotalPerLine={showTotalsPerHourlyRateRow}
              showQuantity={showQuantityPerHourlyRateRow}
              discount={quote.discount}
            />
          )}
          {!isMobile && (
            <DesktopQuoteTableForAcceptance
              acceptedLines={acceptedLines}
              setAcceptedLines={setAcceptedLines}
              hasOptionalLines={hasOptionalLines}
              lines={hourlyRateLines}
              reloadTotals={reloadTotals}
              totals={totals}
              loadingTotals={loadingTotals}
              showTotals={false}
              showTotalPerLine={showTotalsPerHourlyRateRow}
              showQuantity={showQuantityPerHourlyRateRow}
              discount={quote.discount}
            />
          )}
        </Box>
      )}
      {normalLines.length > 0 && (
        <Box width="100%">
          {hourlyRateLines.length > 0 && (
            <Typography
              variant="h5"
              fontWeight={700}
              mt={4}
              style={{ marginBottom: 6 }}
            >
              <FormattedMessage id="financial.quote.signQuote.normalLines" />
            </Typography>
          )}
          {isMobile && (
            <MobileQuoteTableForAcceptance
              acceptedLines={acceptedLines}
              setAcceptedLines={setAcceptedLines}
              hasOptionalLines={hasOptionalLines}
              lines={normalLines}
              reloadTotals={reloadTotals}
              totals={totals}
              loadingTotals={loadingTotals}
              showTotals={showTotals}
              showTotalPerLine={
                tenant.tenantId !== 'c9526b47-a6e6-43c5-8a9d-f5f9531a056e'
              }
              showQuantity={
                tenant.tenantId !== 'c9526b47-a6e6-43c5-8a9d-f5f9531a056e'
              }
              discount={quote.discount}
            />
          )}
          {!isMobile && (
            <DesktopQuoteTableForAcceptance
              acceptedLines={acceptedLines}
              setAcceptedLines={setAcceptedLines}
              hasOptionalLines={hasOptionalLines}
              lines={normalLines}
              reloadTotals={reloadTotals}
              totals={totals}
              loadingTotals={loadingTotals}
              showTotals={showTotals}
              showTotalPerLine={
                tenant.tenantId !== 'c9526b47-a6e6-43c5-8a9d-f5f9531a056e'
              }
              showQuantity={
                tenant.tenantId !== 'c9526b47-a6e6-43c5-8a9d-f5f9531a056e'
              }
              discount={quote.discount}
            />
          )}
        </Box>
      )}
      <Box width="100%" mt={4}>
        <SignDocumentForm
          prefix={prefix}
          setScrollEnabled={setScrollEnabled}
          scrollEnabled={scrollEnabled}
          scrollRef={scrollRef}
        >
          {children}
        </SignDocumentForm>
      </Box>
    </SignDocumentTemplate>
  );
};

export default SignQuoteTemplate;
