import {
  TouchableWithoutFeedback as BaseTouchableWithoutFeedback,
  TouchableWithoutFeedbackProps as BaseTouchableWithoutFeedbackProps,
} from 'react-native';
import { cloneElement, ReactElement } from 'react';
import styles from './styles';

export type TouchableWithoutFeedbackProps = Omit<
  BaseTouchableWithoutFeedbackProps,
  'children'
> & {
  children?: ReactElement;
};

const TouchableWithoutFeedback = ({
  children,
  ...props
}: TouchableWithoutFeedbackProps): ReactElement => (
  <BaseTouchableWithoutFeedback {...props}>
    {children &&
      cloneElement(children, {
        style: [styles.clickable, children.props.style],
      })}
  </BaseTouchableWithoutFeedback>
);

export default TouchableWithoutFeedback;
