import {
  isCreatedQuoteLineInputType,
  QuoteLineInputType,
} from '@bas/financial-domain/input-types';
import { Quote, QuoteLineType } from '@bas/financial-domain/models';
import { formatDate } from '@bas/shared/utils';
import { Discount, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type CreateQuoteByProjectMutationProps = {
  projectId: Uuid;
  quoteId: Uuid;
  lines: Omit<QuoteLineInputType, 'created'>[];
  freeText: { [key: string]: string };
  includingVat: boolean;
  templateId: Uuid;
  expiresAt?: string | Date | null;
  sendQuote?: boolean | null;
  discount?: Discount;
};

export const CreateQuoteByProjectMutation = async ({
  projectId,
  sendQuote,
  lines,
  expiresAt,
  ...values
}: CreateQuoteByProjectMutationProps): Promise<AxiosResponse<Quote>> =>
  axios.put(
    `api/{tenantId}/projects/${projectId}/${
      sendQuote ? 'send-to-customer' : 'create-quote'
    }`,
    {
      projectId,
      expiresAt: expiresAt ? formatDate(expiresAt) : null,
      lines: lines.filter(isCreatedQuoteLineInputType).map((line) => ({
        ...line,
        vatCodeId: line.vatCode.vatCodeId,
        lineType: line.hourlyRate
          ? QuoteLineType.HOURLY_RATE
          : QuoteLineType.NORMAL,
      })),
      ...values,
    },
  );

export const useCreateQuoteByProjectMutation = (
  options: UseMutationOptions<
    AxiosResponse<Quote>,
    AxiosError<ErrorResponse>,
    CreateQuoteByProjectMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse<Quote>,
  AxiosError<ErrorResponse>,
  CreateQuoteByProjectMutationProps
> =>
  useMutation<
    AxiosResponse<Quote>,
    AxiosError<ErrorResponse>,
    CreateQuoteByProjectMutationProps
  >({
    mutationFn: CreateQuoteByProjectMutation,
    throwOnError: false,
    ...options,
  });
