import { Box, Typography } from '@bas/ui/native/base';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

const OptionalLabel = (): ReactElement => (
  <Box
    height={23}
    width={65}
    borderRadius={3}
    backgroundColor="titleBorder"
    alignItems="center"
    justifyContent="center"
  >
    <Typography fontWeight={700} variant="caption" textTransform="uppercase">
      <FormattedMessage id="label.optional" />
    </Typography>
  </Box>
);
export default OptionalLabel;
