import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basKitchenTrolley: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'check',
  icon: [
    512,
    512,

    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M485 116.3l-237 237c-9 9-23.7 9-32.7 0L117 255l32.7-32.7 81.9 81.9L452.3 83.6 485 116.3zM332.8 59.5H64c-9.6 0-17.3 7.8-17.3 17.3V435.2c0 9.6 7.8 17.3 17.3 17.3H422.4c9.6 0 17.3-7.8 17.3-17.3V256H405.1V417.9H81.3V94.1H332.8V59.5z',
  ],
};

export default basKitchenTrolley;
