import { QrCode } from '../types';

export function isQrCode(object: unknown): object is QrCode {
  if (!object || typeof object !== 'object') {
    return false;
  }

  return (
    'qrType' in object &&
    Object.prototype.hasOwnProperty.call(object, 'qrType') &&
    ['storage', 'stored-item', 'location', 'si'].includes(
      (object as QrCode).qrType,
    )
  );
}
