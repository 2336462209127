import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type UpdateCustomerOfInvoiceMutationProps = {
  invoiceId: Uuid;
  relationId: Uuid;
  contactPersonId?: Uuid | null;
  addressId: Uuid;
};

export const UpdateCustomerOfInvoiceMutation = async ({
  invoiceId,
  ...values
}: UpdateCustomerOfInvoiceMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/invoices/${invoiceId}/update-customer`, {
    invoiceId,
    ...values,
  });

export const useUpdateCustomerOfInvoiceMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateCustomerOfInvoiceMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  UpdateCustomerOfInvoiceMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateCustomerOfInvoiceMutationProps
  >({
    mutationFn: UpdateCustomerOfInvoiceMutation,
    throwOnError: false,
    ...options,
  });
