import { formatDate } from '@bas/shared/utils';
import {
  EmployeeContractType,
  ErrorResponse,
  Money,
  Uuid,
} from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ChangeEmployeeContractsMutationProps = {
  employeeId: Uuid;
  contracts: {
    hourlyWage: Money;
    hoursPerWeek: number;
    daysPerWeek: number;
    contractType: EmployeeContractType;
    startDate?: Date | null;
    endDate?: Date | null;
  }[];
};

export const ChangeEmployeeContractsMutation = async ({
  employeeId,
  contracts,
  ...values
}: ChangeEmployeeContractsMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/hrm/employees/${employeeId}/update-contracts`, {
    employeeId,
    contracts: contracts.map(({ startDate, endDate, ...contract }) => ({
      ...contract,
      startDate: startDate ? formatDate(startDate) : null,
      endDate: endDate ? formatDate(endDate) : null,
    })),
    ...values,
  });

export const useChangeEmployeeContractsMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeEmployeeContractsMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ChangeEmployeeContractsMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeEmployeeContractsMutationProps
  >({
    mutationFn: ChangeEmployeeContractsMutation,
    throwOnError: false,
    ...options,
  });
