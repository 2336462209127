import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type RemoveStorageMutationProps = {
  storageId: Uuid;
};

export const RemoveStorageMutation = async ({
  storageId,
  ...values
}: RemoveStorageMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/storages/${storageId}/remove-storage`, {
    storageId,
    ...values,
  });

export const useRemoveStorageMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveStorageMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  RemoveStorageMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveStorageMutationProps
  >({
    mutationFn: RemoveStorageMutation,
    throwOnError: false,
    ...options,
  });
