import dayjs, { Dayjs, ManipulateType } from 'dayjs';
import { useCallback, useMemo } from 'react';

export type UseDynamicStepperProps = {
  startDate: Date | Dayjs;
  endDate: Date | Dayjs;
  setDates: ({
    startDate,
    endDate,
  }: {
    startDate: Date | Dayjs;
    endDate: Date | Dayjs;
  }) => void;
};
export const useDynamicStepper = ({
  startDate,
  endDate,
  setDates,
}: UseDynamicStepperProps) => {
  const currentPeriod: {
    unit: ManipulateType;
    value: number;
  } = useMemo(() => {
    const endDateToUse = dayjs(endDate).add(1, 'day').endOf('day');
    const startToUse = dayjs(startDate).startOf('day');

    const differenceInYears = endDateToUse.diff(startToUse, 'year');
    if (differenceInYears >= 1) {
      return {
        unit: 'year',
        value: differenceInYears,
      };
    }

    const differenceInQuarters = endDateToUse.diff(startToUse, 'quarter');
    if (differenceInQuarters >= 1) {
      return {
        unit: 'month',
        value: differenceInQuarters * 3,
      };
    }

    const differenceInMonths = endDateToUse.diff(startToUse, 'month');
    if (differenceInMonths >= 1) {
      return {
        unit: 'month',
        value: 1,
      };
    }

    const differenceInWeeks = endDateToUse.diff(startToUse, 'week');

    if (differenceInWeeks >= 1) {
      return {
        unit: 'week',
        value: differenceInWeeks,
      };
    }

    return {
      unit: 'days',
      value: endDateToUse.diff(startToUse, 'days'),
    };
  }, [startDate, endDate]);

  const handlePreviousPeriod = useCallback(() => {
    setDates({
      startDate: dayjs(startDate)
        .subtract(currentPeriod.value, currentPeriod.unit)
        .startOf(currentPeriod.unit),
      endDate: dayjs(startDate).subtract(1, 'day').endOf(currentPeriod.unit),
    });
  }, [startDate, setDates, currentPeriod.value, currentPeriod.unit]);

  const handleNextPeriod = useCallback(() => {
    setDates({
      startDate: dayjs(endDate).add(1, 'day').startOf(currentPeriod.unit),
      endDate: dayjs(endDate)
        .add(currentPeriod.value, currentPeriod.unit)
        .endOf(currentPeriod.unit),
    });
  }, [endDate, setDates, currentPeriod.value, currentPeriod.unit]);

  return {
    handlePreviousPeriod,
    handleNextPeriod,
    currentPeriod,
  };
};
