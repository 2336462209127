import {
  ProjectEstimateItemGroupType,
  ProjectEstimateItemType,
} from '@bas/value-objects';
import * as Yup from 'yup';

export interface SkipQuoteOfProjectInputType {
  estimateItemValues: {
    groupType: ProjectEstimateItemGroupType;
    itemType: ProjectEstimateItemType;
    quantity: number | null;
    extraData: Record<string, string>;
  }[];
}

export const SkipQuoteOfProjectInputTypeDefaultValues =
  (): SkipQuoteOfProjectInputType => ({
    estimateItemValues: [],
  });

export const SkipQuoteOfMovingJobInputTypeDefaultValues =
  (): SkipQuoteOfProjectInputType => ({
    estimateItemValues: [
      {
        groupType: ProjectEstimateItemGroupType.MOVING_JOB,
        itemType: ProjectEstimateItemType.CUBIC_METER,
        quantity: 0,
        extraData: {},
      },
      {
        groupType: ProjectEstimateItemGroupType.MOVING_JOB,
        itemType: ProjectEstimateItemType.HOURS,
        quantity: 0,
        extraData: {},
      },
      {
        groupType: ProjectEstimateItemGroupType.MOVING_JOB,
        itemType: ProjectEstimateItemType.STAFF,
        quantity: 0,
        extraData: {},
      },
      {
        groupType: ProjectEstimateItemGroupType.PACKING,
        itemType: ProjectEstimateItemType.HOURS,
        quantity: 0,
        extraData: {},
      },
      {
        groupType: ProjectEstimateItemGroupType.PACKING,
        itemType: ProjectEstimateItemType.STAFF,
        quantity: 0,
        extraData: {},
      },
      {
        groupType: ProjectEstimateItemGroupType.UNPACKING,
        itemType: ProjectEstimateItemType.HOURS,
        quantity: 0,
        extraData: {},
      },
      {
        groupType: ProjectEstimateItemGroupType.UNPACKING,
        itemType: ProjectEstimateItemType.STAFF,
        quantity: 0,
        extraData: {},
      },
      {
        groupType: ProjectEstimateItemGroupType.HANDYMAN,
        itemType: ProjectEstimateItemType.HOURS,
        quantity: 0,
        extraData: {
          serviceType: 'assemble',
        },
      },
      {
        groupType: ProjectEstimateItemGroupType.HANDYMAN,
        itemType: ProjectEstimateItemType.STAFF,
        quantity: 0,
        extraData: {
          serviceType: 'assemble',
        },
      },
      {
        groupType: ProjectEstimateItemGroupType.HANDYMAN,
        itemType: ProjectEstimateItemType.HOURS,
        quantity: 0,
        extraData: {
          serviceType: 'disassemble',
        },
      },
      {
        groupType: ProjectEstimateItemGroupType.HANDYMAN,
        itemType: ProjectEstimateItemType.STAFF,
        quantity: 0,
        extraData: {
          serviceType: 'disassemble',
        },
      },
    ],
  });

export const SkipQuoteOfProjectInputTypeValidationBuilder =
  (): Yup.ObjectSchema<SkipQuoteOfProjectInputType> =>
    Yup.object({
      estimateItemValues: Yup.array()
        .of(
          Yup.object({
            groupType: Yup.mixed<ProjectEstimateItemGroupType>()
              .oneOf(Object.values(ProjectEstimateItemGroupType))
              .label('label.groupType')
              .required(),
            itemType: Yup.mixed<ProjectEstimateItemType>()
              .oneOf(Object.values(ProjectEstimateItemType))
              .label('label.itemType')
              .required(),
            quantity: Yup.number()
              .nullable()
              .required()
              .label('label.quantity'),
            extraData: Yup.object(),
          }).required(),
        )
        .required(),
    });
