import { StyleSheet } from 'react-native';
import { colors } from '@bas/theme';

export default StyleSheet.create({
  formEndContainer: {
    paddingTop: 35,
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    maxHeight: 85,
  },
  forgotPasswordContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  loginButtonContainer: {
    width: '35%',
  },
  forgotPasswordText: {
    color: colors.lila[200],
    textTransform: 'lowercase',
  },
  forgotPasswordIcon: { marginLeft: 5 },
  paddingBetweenRows: {
    paddingTop: 28,
  },
});
