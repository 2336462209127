import { Box } from '@bas/ui/native/base';
import { ReactHookFormTextField } from '@bas/ui/native/molecules';
import { ReactElement } from 'react';
import { useIntl } from 'react-intl';

const MobileTaxationCustomerNotesForm = (): ReactElement => {
  const { formatMessage } = useIntl();

  return (
    <Box
      flex={1}
      flexDirection="row"
      style={{
        marginTop: -24,
        marginLeft: -24,
      }}
      flexWrap="wrap"
    >
      <Box pl={3} pt={3} flex={1} flexBasis="100%">
        <ReactHookFormTextField
          name="customerNotes"
          autoComplete="off"
          label={formatMessage({ id: 'label.customerNotes' })}
          multiline
        />
      </Box>
    </Box>
  );
};

export default MobileTaxationCustomerNotesForm;
