import { Box, Typography } from '@bas/ui/native/base';
import {
  FormattedDate,
  FormattedDateTimeRange,
  FormattedMessage,
  FormattedNumber,
} from 'react-intl';
import {
  DayOff,
  TimeRegistrationApprovalInformation,
} from '@bas/hrm-domain/models';
import dayjs from 'dayjs';
import { TimeOffRegistrationDatesContentSection } from '../TimeOffRegistrationDatesContentSection';

export type TimeOffRegistrationApprovalInformationContentSectionProps = {
  approvedByName?: string;
  approvalInformation: TimeRegistrationApprovalInformation;
  approvedEffectiveHours: number;
  daysOff: DayOff[];
};

const TimeOffRegistrationApprovalInformationContentSection = ({
  approvedByName,
  approvalInformation,
  approvedEffectiveHours,
  daysOff,
}: TimeOffRegistrationApprovalInformationContentSectionProps) => (
  <Box flexDirection="row" flexWrap="wrap">
    <Box flexBasis="100%">
      <Typography variant="subtitle1" color="white">
        <FormattedMessage id="label.approvalInformation" />
      </Typography>
    </Box>
    <Box flexBasis="50%">
      {approvedByName && (
        <Typography color="white">{approvedByName}</Typography>
      )}
      {approvalInformation.approvedOn && (
        <Typography color="white">
          <FormattedDate
            value={approvalInformation.approvedOn}
            dateStyle="short"
          />
        </Typography>
      )}
      <Typography color="white">
        {approvalInformation.approvedReason}
      </Typography>
      <Typography color="white">
        <FormattedDateTimeRange
          from={dayjs(approvalInformation.approvedStart).toDate()}
          to={dayjs(approvalInformation.approvedEnd).toDate()}
          year="numeric"
          month="short"
          day="numeric"
          hour={approvalInformation.approvedFullDay ? undefined : 'numeric'}
          minute={approvalInformation.approvedFullDay ? undefined : 'numeric'}
        />
      </Typography>
      <Typography color="white">
        {
          <FormattedNumber
            value={approvedEffectiveHours}
            // eslint-disable-next-line react/style-prop-object
            style="unit"
            unit="hour"
            unitDisplay="long"
            maximumFractionDigits={2}
          />
        }
      </Typography>
    </Box>
    <Box flexBasis="50%">
      <TimeOffRegistrationDatesContentSection daysOff={daysOff} showApproved />
    </Box>
  </Box>
);

export default TimeOffRegistrationApprovalInformationContentSection;
