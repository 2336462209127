import * as Yup from 'yup';

export interface WarehouseInformationInputType {
  name: string;
  isOwnWarehouse: boolean;
}

export const warehouseInformationInputTypeDefaultValues =
  (): WarehouseInformationInputType => ({
    name: '',
    isOwnWarehouse: true,
  });

export const warehouseInformationInputTypeValidationBuilder =
  (): Yup.ObjectSchema<WarehouseInformationInputType> =>
    Yup.object({
      name: Yup.string().label('label.name').required(),
      isOwnWarehouse: Yup.boolean().required().label('label.isOwnWarehouse'),
    });
