import { ErrorResponse, Uuid } from '@bas/value-objects';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type EmployeeOpensEmailMutationProps = {
  emailId: Uuid;
  employeeId: Uuid;
};

export const EmployeeOpensEmailMutation = async ({
  emailId,
  ...params
}: EmployeeOpensEmailMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/communication/emails/${emailId}/open`,
    {
      emailId,
      ...params,
    },
    { params },
  );

export const useEmployeeOpensEmailMutation = (): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  EmployeeOpensEmailMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    EmployeeOpensEmailMutationProps
  >({
    mutationFn: EmployeeOpensEmailMutation,
    throwOnError: false,
  });
