import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type CancelQuoteMutationProps = {
  quoteId: Uuid;
};

export const CancelQuoteMutation = async ({
  quoteId,
  ...values
}: CancelQuoteMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/quotes/${quoteId}/cancel`, {
    quoteId,
    ...values,
  });

export const useCancelQuoteMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    CancelQuoteMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  CancelQuoteMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    CancelQuoteMutationProps
  >({
    mutationFn: CancelQuoteMutation,
    throwOnError: false,
    ...options,
  });
