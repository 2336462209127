export enum TaskType {
  CUSTOM = 'custom',
  TASK_REGARDING_EMPLOYEE = 'task-regarding-employee',
  TASK_REGARDING_EVENT = 'task-regarding-event',
  TASK_REGARDING_INVENTORY_ITEM = 'task-regarding-inventory-item',
  TASK_REGARDING_INVOICE = 'task-regarding-invoice',
  TASK_REGARDING_MATERIAL = 'task-regarding-material',
  TASK_REGARDING_PROJECT = 'task-regarding-project',
  TASK_REGARDING_QUOTE = 'task-regarding-quote',
  TASK_REGARDING_RELATION = 'task-regarding-relation',
  TASK_REGARDING_BATCH_DATA = 'task-regarding-batch-data',
  TASK_REGARDING_EMAIL = 'task-regarding-email',
  CUSTOM_TASK = 'custom-task',
  CUSTOM_TASK_REGARDING_EMAIL = 'custom-task-regarding-email',
  EMPLOYEE_IS_ABSENT_BUT_PLANNED_ON_EVENT_TASK = 'employee-is-absent-but-planned-on-event-task',
  EVENT_WAS_NOT_FINISHED_TASK = 'event-was-not-finished-task',
  MATERIAL_IS_ABSENT_BUT_PLANNED_ON_EVENT_TASK = 'material-is-absent-but-planned-on-event-task',
  PLAN_EMPLOYEES_ON_EVENT_TASK = 'plan-employees-on-event-task',
  PLAN_MATERIAL_ON_EVENT_TASK = 'plan-material-on-event-task',
  CERTIFICATE_OF_EMPLOYEE_IS_ABOUT_TO_EXPIRE_TASK = 'certificate-of-employee-is-about-to-expire-task',
  EMPLOYEE_DID_NOT_ENTER_THEIR_HOURS_TASK = 'employee-did-not-enter-their-hours-task',
  EMPLOYEE_IS_ABOUT_TO_WORK_OVERTIME_TASK = 'employee-is-about-to-work-overtime-task',
  CERTIFICATE_WAS_NOT_SYNCED_TASK = 'certificate-was-not-synced-task',
  INVOICE_WAS_NOT_SYNCED_TASK = 'invoice-was-not-synced-task',
  COULD_NOT_CREATE_NEW_LEAD_TASK = 'could-not-create-new-lead-task',
  INVENTORY_ITEM_IS_ALMOST_DEPLETED_TASK = 'inventory-item-is-almost-depleted-task',
  FINISH_INVOICE_TASK = 'finish-invoice-task',
  INVOICE_HAS_EXPIRED_TASK = 'invoice-has-expired-task',
  SENDING_INVOICE_FAILED_TASK = 'sending-invoice-failed-task',
  SEND_INVOICE_TASK = 'send-invoice-task',
  MATERIAL_NEEDS_TO_BE_INSPECTED_TASK = 'material-needs-to-be-inspected-task',
  CONTACT_CUSTOMER_FOR_TAXATION_TASK = 'contact-customer-for-taxation-task',
  FINISH_UP_PROJECT_TASK = 'finish-up-project-task',
  PLAN_PICKING_UP_BOXES_TASK = 'plan-picking-up-boxes-task',
  PLAN_DELIVER_BOXES_TASK = 'plan-deliver-boxes-task',
  PLAN_PROJECT_TASK = 'plan-project-task',
  REQUEST_MOVING_CERTIFICATE_TASK = 'request-moving-certificate-task',
  RESERVE_STORAGE_FOR_PROJECT_TASK = 'reserve-storage-for-project-task',
  REQUEST_PERMITS_TASK = 'request-permits-task',
  PLACE_SIGNS_TASK = 'place-signs-task',
  SEND_DEPOSIT_INVOICE_TASK = 'send-deposit-invoice-task',
  SEND_FINAL_INVOICE_TASK = 'send-final-invoice-task',
  SEND_INVOICE_WHEN_STORAGE_WAS_LOADED_TASK = 'send-invoice-when-storage-was-loaded-task',
  HANDLE_DAMAGE_TASK = 'handle-damage-task',
  QUOTE_HAS_EXPIRED_TASK = 'quote-has-expired-task',
  QUOTE_WAS_DECLINED_TASK = 'quote-was-declined-task',
  SENDING_QUOTE_FAILED_TASK = 'sending-quote-failed-task',
  SEND_QUOTE_TASK = 'send-quote-task',
  SENDING_OF_EMAIL_FAILED_TASK = 'sending-of-email-failed-task',
  ISSUE_WITH_SYNCING_EXTERNAL_EMPLOYEE_TASK = 'issue-with-syncing-external-employee-task',
}
