import { FontAwesome } from '@expo/vector-icons';
import * as Sentry from '@sentry/react-native';
import * as Font from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { useEffect, useState } from 'react';

export default () => {
  const [isLoadingComplete, setLoadingComplete] = useState(false);
  const [error, setError] = useState<unknown | null>(null);

  // Load any resources or data that we need prior to rendering the app
  useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        // Load fonts
        /* eslint-disable global-require */
        await Font.loadAsync({
          ...FontAwesome.font,
          'space-mono': require('../../assets/fonts/SpaceMono-Regular.ttf'),
          DM_Sans_400_normal: require('../../assets/fonts/dm-sans-all-400-normal.ttf'),
          DM_Sans_400_italic: require('../../assets/fonts/dm-sans-all-400-italic.ttf'),
          DM_Sans_500_italic: require('../../assets/fonts/dm-sans-all-500-italic.ttf'),
          DM_Sans_500_normal: require('../../assets/fonts/dm-sans-all-500-normal.ttf'),
          DM_Sans_700_italic: require('../../assets/fonts/dm-sans-all-700-italic.ttf'),
          DM_Sans_700_normal: require('../../assets/fonts/dm-sans-all-700-normal.ttf'),
          Work_Sans_100_italic: require('../../assets/fonts/work-sans-all-100-italic.ttf'),
          Work_Sans_100_normal: require('../../assets/fonts/work-sans-all-100-normal.ttf'),
          Work_Sans_200_italic: require('../../assets/fonts/work-sans-all-200-italic.ttf'),
          Work_Sans_200_normal: require('../../assets/fonts/work-sans-all-200-normal.ttf'),
          Work_Sans_300_italic: require('../../assets/fonts/work-sans-all-300-italic.ttf'),
          Work_Sans_300_normal: require('../../assets/fonts/work-sans-all-300-normal.ttf'),
          Work_Sans_400_italic: require('../../assets/fonts/work-sans-all-400-italic.ttf'),
          Work_Sans_400_normal: require('../../assets/fonts/work-sans-all-400-normal.ttf'),
          Work_Sans_500_italic: require('../../assets/fonts/work-sans-all-500-italic.ttf'),
          Work_Sans_500_normal: require('../../assets/fonts/work-sans-all-500-normal.ttf'),
          Work_Sans_600_italic: require('../../assets/fonts/work-sans-all-600-italic.ttf'),
          Work_Sans_600_normal: require('../../assets/fonts/work-sans-all-600-normal.ttf'),
          Work_Sans_700_italic: require('../../assets/fonts/work-sans-all-700-italic.ttf'),
          Work_Sans_700_normal: require('../../assets/fonts/work-sans-all-700-normal.ttf'),
          Work_Sans_800_italic: require('../../assets/fonts/work-sans-all-800-italic.ttf'),
          Work_Sans_800_normal: require('../../assets/fonts/work-sans-all-800-normal.ttf'),
          Work_Sans_900_italic: require('../../assets/fonts/work-sans-all-900-italic.ttf'),
          Work_Sans_900_normal: require('../../assets/fonts/work-sans-all-900-normal.ttf'),
        });
        /* eslint-enable global-require */
      } catch (e) {
        Sentry.captureException(e);
        setLoadingComplete(false);
        setError(e);
      } finally {
        SplashScreen.hideAsync();
        setLoadingComplete(true);
        setError(null);
      }
    }

    loadResourcesAndDataAsync();
  }, []);

  return [isLoadingComplete, error];
};
