import { Uuid } from '@bas/value-objects';
import * as Yup from 'yup';
import {
  RequestTimeOffInputType,
  requestTimeOffInputTypeDefaultValues,
  requestTimeOffInputTypeValidationBuilder,
} from './requestTimeOffInputType';

export type AddTimeOffRequestInputType = RequestTimeOffInputType & {
  employeeId: Uuid;
};

export const addTimeOffRequestInputTypeDefaultValues =
  (): AddTimeOffRequestInputType => ({
    ...requestTimeOffInputTypeDefaultValues(),
    employeeId: '',
  });

export const addTimeOffRequestInputTypeValidationBuilder =
  (): Yup.ObjectSchema<AddTimeOffRequestInputType> =>
    requestTimeOffInputTypeValidationBuilder().concat(
      Yup.object({
        employeeId: Yup.string().required().label('label.employee'),
      }),
    );
