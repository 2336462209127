import { StyleSheet } from 'react-native';
import { colors } from '@bas/theme';

export default StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 50,
  },
  roadNumberContainer: {
    backgroundColor: colors.red[600],
    height: 31,
    width: 41,
    borderRadius: 8,
    marginRight: 12,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  roadNumberStyling: {
    position: 'absolute',
    borderColor: colors.white,
    borderWidth: 1,
    borderRadius: 6,
    top: 3,
    bottom: 3,
    left: 3,
    right: 3,
  },
  obstructionText: {
    flexDirection: 'column',
    flex: 1,
  },
  obstructionTitle: {
    color: '#D43D3D',
  },
  obstructionDescription: {
    color: colors.lila[700],
    flexShrink: 1,
  },
  loadingObstructionDescription: {
    paddingTop: 5,
  },
  lightText: {
    color: colors.lila[100],
  },
});
