import type {
  EmployeeProjectRole,
  Money,
  PricingType,
} from '@bas/value-objects';
import type { ServiceType } from '@bas/shared/models';
import type { MovingJobRoom } from './MovingJobRoom';
import type { MovingJobStatus } from './MovingJobStatus';
import type { Project } from './Project';
import type { MovingJobAddress } from './MovingJobAddress';
import type { MovingJobNeededEmployees } from './MovingJobNeededEmployees';
import type { ProjectCost } from './ProjectCost';

export interface MovingJob extends Project {
  pricingType: PricingType;
  hourlyRate: Money;
  neededRoles: EmployeeProjectRole[];
  customerNotes?: string;
  storageNotes?: string;
  services: ServiceType[];
  projectStatus: MovingJobStatus;
  addresses: MovingJobAddress[];
  rooms: MovingJobRoom[];
  materials: ProjectCost[];
  deliveredBoxesAt?: Date;
  pickedUpBoxesAt?: Date;
  nextActionDate?: Date;
  reminderSentAt?: Date;
  changeAddressAfterMoving: boolean;
  neededEmployees: MovingJobNeededEmployees;
}
