import { formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, Money, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type CreateNewTypeOfMovingBoxMutationProps = {
  inventoryItemId: Uuid;
  tenantId: Uuid;
  name: string;
  length: number;
  width: number;
  height: number;
  rentalPrice: Money;
  sellingPrice: Money;
  expiresAt?: Date | null;
};

export const CreateNewTypeOfMovingBoxMutation = async ({
  expiresAt,
  ...values
}: CreateNewTypeOfMovingBoxMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/{tenantId}/inventory/moving-boxes`, {
    expiresAt: expiresAt ? formatDateTime(expiresAt) : null,
    ...values,
  });

export const useCreateNewTypeOfMovingBoxMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    CreateNewTypeOfMovingBoxMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  CreateNewTypeOfMovingBoxMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    CreateNewTypeOfMovingBoxMutationProps
  >({
    mutationFn: CreateNewTypeOfMovingBoxMutation,
    throwOnError: false,
    ...options,
  });
