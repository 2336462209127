import type { ProjectType, Uuid } from '@bas/value-objects';
import type { CrmAddress, CrmContactPerson } from '@bas/crm-domain/models';
import type { Event } from '@bas/planning-domain/models';

export interface ListedProject {
  projectId: Uuid;
  projectType: ProjectType;
  tenantId: Uuid;
  identityId?: Uuid;
  projectCode?: string;
  name: string;
  notes?: string;
  events: Event[];
  reference?: string;
  projectCrmContactPerson?: CrmContactPerson;
  quoteCrmContactPerson?: CrmContactPerson;
  invoiceCrmContactPerson?: CrmContactPerson;
  invoiceAddress?: CrmAddress;
  quoteAddress?: CrmAddress;
}
