import { CrmContactPersonType } from '../types';

export function isCrmContactPersonType(
  value: unknown,
): value is CrmContactPersonType {
  return (
    typeof value === 'string' &&
    Object.values(CrmContactPersonType).includes(value as CrmContactPersonType)
  );
}
