import type { TemporaryNewMessageNotification } from '../../types';

export function isTemporaryNewMessageNotification(
  object: unknown,
): object is TemporaryNewMessageNotification {
  return (
    typeof object === 'object' &&
    object !== null &&
    Object.prototype.hasOwnProperty.call(object, 'type') &&
    (object as TemporaryNewMessageNotification).type === 'new_message'
  );
}
