import {
  QuoteLine,
  QuoteLineType,
  VatScenario,
} from '@bas/financial-domain/models';
import { formatDate } from '@bas/shared/utils';
import { Discount, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type UpdateQuoteByQuoteIdMutationProps = {
  quoteId: Uuid;
  lines: QuoteLine[];
  freeText: { [key: string]: string | undefined };
  includingVat: boolean;
  reference?: string | null;
  templateId: Uuid;
  expiresAt: string | Date | null | undefined;
  discount: Discount | null | undefined;
  vatScenario: VatScenario;
};

export const UpdateQuoteByQuoteIdMutation = async ({
  quoteId,
  lines,
  expiresAt,
  ...values
}: UpdateQuoteByQuoteIdMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/quotes/${quoteId}`, {
    quoteId,
    lines: lines.map((line) => ({
      ...line,
      vatCodeId: line.vatCode.vatCodeId,
      lineType: line.hourlyRate
        ? QuoteLineType.HOURLY_RATE
        : QuoteLineType.NORMAL,
    })),
    expiresAt: expiresAt ? formatDate(new Date(expiresAt)) : null,
    ...values,
  });

export const useUpdateQuoteByQuoteIdMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateQuoteByQuoteIdMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  UpdateQuoteByQuoteIdMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateQuoteByQuoteIdMutationProps
  >({
    mutationFn: UpdateQuoteByQuoteIdMutation,
    throwOnError: false,
    ...options,
  });
