import { Discount } from '@bas/value-objects';
import * as Yup from 'yup';
import {
  FinancialLineInputType,
  financialLineInputTypeValidatorBuilder,
} from './financialLineInputType';
import {
  FreeTextInputType,
  FreeTextInputTypeValidatorBuilder,
} from './freeTextInputType';

export type FinancialDocumentContentInputType<
  LineType extends FinancialLineInputType = FinancialLineInputType,
> = FreeTextInputType & {
  includingVat: boolean;
  ignoreZeroQuantityLines: boolean;
  lines: LineType[];
  discount?: Discount;
};

export const FinancialDocumentContentInputTypeValidationBuilder =
  (): Yup.ObjectSchema<FinancialDocumentContentInputType> =>
    Yup.object({
      includingVat: Yup.boolean().required().label('label.includingVat'),
      ignoreZeroQuantityLines: Yup.boolean()
        .required()
        .label('label.ignoreZeroQuantityLines'),
      lines: Yup.array()
        .of(financialLineInputTypeValidatorBuilder())
        .required(),
      discount: Yup.object({
        description: Yup.string().label('label.description').nullable(),
        percentage: Yup.number()
          .transform((value, original) =>
            original === '' || original === null ? null : value,
          )
          .nullable()
          .label('label.percentage'),
      }),
    })
      .concat(FreeTextInputTypeValidatorBuilder())
      // eslint-disable-next-line no-empty-pattern
      .when([], ([], schema, { value }) => {
        if (value.ignoreZeroQuantityLines) {
          return schema.concat(
            Yup.object({
              lines: Yup.array()
                .of(financialLineInputTypeValidatorBuilder())
                .compact(
                  (v) => !v.quantity || v.quantity === '0' || v.quantity === 0,
                )
                .min(1)
                .label('label.lines')
                .required(),
            }),
          );
        }

        return schema.concat(
          Yup.object({
            lines: Yup.array()
              .of(financialLineInputTypeValidatorBuilder())
              .min(1)
              .label('label.lines')
              .required(),
          }),
        );
      });
