import type { Uuid } from '@bas/value-objects';
import type { TransportJobAddress } from './TransportJobAddress';
import type { CargoEvent } from './CargoEvent';
import type { CargoStatus } from './CargoStatus';
import type { CargoItem } from './CargoItem';

export interface Cargo {
  cargoId: Uuid;
  summary: string;
  description?: string | null;
  cubicMeter?: number | null;
  length?: number | null;
  width?: number | null;
  height?: number | null;
  addresses: TransportJobAddress[];
  cargoStatus: CargoStatus;
  items: CargoItem[];
  events: CargoEvent[];
}
