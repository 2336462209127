import { calculateTimeOfDaySliderValues } from '@bas/shared/utils';
import { Box, BoxProps } from '@bas/ui/native/base';
import {
  ReactHookFormTextField,
  ReactHookFormTimeSliderField,
} from '@bas/ui/native/molecules';
import { memo, ReactElement, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { StyleSheet } from 'react-native';

export type PeopleWithDifferentHoursFormProps = BoxProps & {
  prefix?: string;
};

const breakTimes = [
  0, 0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.25, 2.5, 2.75, 3, 3.25, 3.5,
  3.75, 4, 4.25, 4.5, 4.75, 5, 5.25, 5.5, 5.75, 6, 6.25, 6.5, 6.75, 7, 7.25,
  7.5, 7.75, 8, 8.25, 8.5, 8.75, 9,
];

const styles = StyleSheet.create({
  date: {
    paddingLeft: 20,
    paddingRight: 20,
    textAlign: 'center',
  },
  question: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 24,
    textAlign: 'center',
  },
  padding: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  reasonPadding: {
    paddingBottom: 62,
  },
  timeSlider: {
    paddingBottom: 31,
  },
});
const PeopleWithDifferentHoursForm = ({
  style,
  prefix,
  ...props
}: PeopleWithDifferentHoursFormProps): ReactElement => {
  const { formatMessage } = useIntl();
  const [rendered, setRendered] = useState(false);
  const timeOfDayValues = useMemo(() => calculateTimeOfDaySliderValues(15), []);

  const getPrefixedName = useCallback(
    (name: string) => {
      if (prefix) {
        return `${prefix}.${name}`;
      }

      return name;
    },
    [prefix],
  );
  return (
    <Box
      {...props}
      style={[style]}
      gap={3}
      onLayout={() => setRendered(true)}
      flex={1}
      flexDirection="row"
      flexWrap="wrap"
    >
      {rendered && (
        <>
          <Box flexBasis="100%">
            <ReactHookFormTimeSliderField
              name={getPrefixedName('startDate')}
              label={formatMessage({ id: 'label.startTime' })}
              values={timeOfDayValues}
              light
              style={styles.timeSlider}
            />
          </Box>
          <Box flexBasis="100%">
            <ReactHookFormTimeSliderField
              name={getPrefixedName('endDate')}
              label={formatMessage({ id: 'label.endTime' })}
              values={timeOfDayValues}
              light
              style={styles.timeSlider}
            />
          </Box>
          <Box flexBasis="100%">
            <ReactHookFormTimeSliderField
              name={getPrefixedName('breakTime')}
              label={formatMessage({ id: 'label.breakTime' })}
              values={breakTimes}
              light
              style={styles.timeSlider}
            />
          </Box>
        </>
      )}
      <Box style={[styles.padding, styles.reasonPadding]} flex={1}>
        <ReactHookFormTextField
          name={getPrefixedName('reason')}
          label={formatMessage({ id: 'label.reason' })}
          autoCapitalize="none"
          autoCorrect={false}
          multiline
          light
        />
      </Box>
    </Box>
  );
};

const MemoComponent = memo(PeopleWithDifferentHoursForm, () => true);
export default MemoComponent;
