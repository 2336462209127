import { formatDate } from '@bas/shared/utils';
import { EmailAddress, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type SendQuoteByQuoteIdMutationProps = {
  quoteId: Uuid;
  expiresAt?: Date;
  extraEmailAddresses?: EmailAddress[];
  ipAddress?: string | null;
  browser?: string | null;
  resend?: boolean;
};

export const SendQuoteByQuoteIdMutation = async ({
  quoteId,
  expiresAt,
  ...values
}: SendQuoteByQuoteIdMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/quotes/${quoteId}/send`, {
    quoteId,
    expiresAt: expiresAt ? formatDate(expiresAt) : null,
    ...values,
  });

export const useSendQuoteByQuoteIdMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    SendQuoteByQuoteIdMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  SendQuoteByQuoteIdMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    SendQuoteByQuoteIdMutationProps
  >({
    mutationFn: SendQuoteByQuoteIdMutation,
    throwOnError: false,
    ...options,
  });
