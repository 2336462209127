import { ReactElement, useState } from 'react';
import { View } from 'react-native';
import { SharedValue } from 'react-native-reanimated';
import { StyleProp } from 'react-native/Libraries/StyleSheet/StyleSheet';
import { ViewStyle } from 'react-native/Libraries/StyleSheet/StyleSheetTypes';
import { SwitchTransition, Transition } from 'react-transition-group';

export type AnimatedSlideProps = {
  currentStepIndex: number;
  direction: SharedValue<'left' | 'right'>;
  children: ReactElement;
};

const AnimatedSlide = ({
  currentStepIndex,
  direction,
  children,
}: AnimatedSlideProps): ReactElement => {
  const [transition, setTransition] = useState(
    'transform 150ms cubic-bezier(0.0, 0, 0.2, 1)',
  );

  const defaultStyle = {
    transform: 'none',
    transition,
    height: '100%',
  };

  const transitionStyles = {
    entering: {
      left: {
        transform: [{ translateX: '-100%' }],
      },
      right: {
        transform: [{ translateX: '100%' }],
      },
    },
    exiting: {
      left: {
        transform: [{ translateX: '100%' }],
      },
      right: {
        transform: [{ translateX: '-100%' }],
      },
    },
    entered: {
      left: {
        transform: [{ translateX: 0 }],
      },
      right: {
        transform: [{ translateX: 0 }],
      },
    },
    exited: {
      left: { transform: [{ translateX: 0 }] },
      right: { transform: [{ translateX: 0 }] },
    },
  };

  return (
    <SwitchTransition mode="out-in">
      <Transition
        key={currentStepIndex}
        timeout={{
          enter: 225,
          exit: 150,
        }}
        onEntering={() => {
          setTransition('transform 150ms cubic-bezier(0.0, 0, 0.2, 1)');
        }}
        onExit={() =>
          setTransition('transform 150ms cubic-bezier(0.4, 0, 0.6, 1)')
        }
        addEndListener={(node, done) =>
          node.addEventListener('transitionend', done, false)
        }
      >
        {(state) => (
          <View
            style={[
              defaultStyle as StyleProp<ViewStyle>,
              transitionStyles[state as keyof typeof transitionStyles][
                direction.value
              ] as StyleProp<ViewStyle>,
            ]}
          >
            {children}
          </View>
        )}
      </Transition>
    </SwitchTransition>
  );
};

export default AnimatedSlide;
