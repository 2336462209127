import { isServiceType } from '@bas/shared/models';
import { usePublicServiceTypesRequest } from '@bas/tenant-domain/requests';
import { RenderTranslatedName } from '@bas/ui/native/atoms';
import { Typography, TypographyProps } from '@bas/ui/native/base';
import {
  ProjectEstimateItemGroupType,
  ProjectEstimateItemType,
} from '@bas/value-objects';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

export type RenderProjectEstimateItemTitleProps = TypographyProps & {
  groupType: ProjectEstimateItemGroupType;
  itemType: ProjectEstimateItemType;
  extraData?: Record<string, unknown>;
};

const RenderProjectEstimateItemTitle = ({
  groupType,
  itemType,
  extraData,
  ...props
}: RenderProjectEstimateItemTitleProps): ReactElement => {
  const { data: servicesData, isLoading } = usePublicServiceTypesRequest();
  const values: { [key: string]: ReactElement | string } = {};
  if (
    itemType === ProjectEstimateItemType.BOXES &&
    extraData &&
    typeof extraData.inventoryItemName === 'string'
  ) {
    return <Typography {...props}>{extraData.inventoryItemName}</Typography>;
  }

  if (
    itemType === ProjectEstimateItemType.BUILDING_TYPE &&
    extraData &&
    typeof extraData.buildingType === 'string'
  ) {
    return (
      <Typography {...props}>
        <FormattedMessage id={`buildingTypes.${extraData.buildingType}`} />
      </Typography>
    );
  }

  if (
    extraData &&
    typeof extraData.serviceType === 'string' &&
    ['assemble', 'disassemble'].includes(extraData.serviceType)
  ) {
    return (
      <Typography {...props}>
        <FormattedMessage
          id={`estimate.groupTypes.${extraData.serviceType}.itemTypes.${itemType}`}
        />
      </Typography>
    );
  }

  if (
    groupType === ProjectEstimateItemGroupType.STORAGE &&
    itemType === ProjectEstimateItemType.SERVICE &&
    extraData &&
    typeof extraData.storageType === 'string'
  ) {
    values.storageType = (
      <Typography {...props}>
        &nbsp;
        <FormattedMessage id={`storageTypes.${extraData.storageType}`} />
        &nbsp;
      </Typography>
    );
  }

  if (
    itemType === ProjectEstimateItemType.SERVICE &&
    extraData &&
    typeof extraData.serviceType === 'string' &&
    !isLoading &&
    !!servicesData?.data.member
  ) {
    const serviceType = servicesData?.data.member.find(
      ({ internalType }) => internalType === extraData.serviceType,
    );

    if (serviceType && isServiceType(serviceType)) {
      return (
        <RenderTranslatedName
          {...props}
          translations={serviceType.translatedNames}
          fallback={serviceType.serviceTypeName}
        />
      );
    }
  }

  return (
    <Typography {...props}>
      <FormattedMessage
        id={`estimate.groupTypes.${groupType}.itemTypes.${itemType}`}
        values={values}
      />
    </Typography>
  );
};

export default RenderProjectEstimateItemTitle;
