import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    height: 27,
    paddingRight: 12,
    width: 103,
    flexDirection: 'row',
    borderRadius: 5,
    alignItems: 'center',
    overflow: 'hidden',
  },
  countryPart: {
    height: '100%',
    width: 12,
  },
  text: {
    width: '100%',
    textAlign: 'center',
  },
});
