import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  primary: {
    flexWrap: 'wrap',
    textAlign: 'center',
  },
  secondary: {
    flexWrap: 'wrap',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
});
