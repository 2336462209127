import * as Yup from 'yup';

export interface TurnoverGroupDetailsInputType {
  name: string;
}

export const TurnoverGroupDetailsInputTypeDefaultValues =
  (): TurnoverGroupDetailsInputType => ({
    name: '',
  });

export const TurnoverGroupDetailsInputTypeValidationBuilder =
  (): Yup.ObjectSchema<TurnoverGroupDetailsInputType> =>
    Yup.object({
      name: Yup.string().required().ensure().label('label.name'),
    });
