import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type RemoveTimeEntryMutationProps = {
  timeEntryId: Uuid;
};

export const RemoveTimeEntryMutation = async ({
  timeEntryId,
  ...values
}: RemoveTimeEntryMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/hrm/time-entries/${timeEntryId}/remove`, {
    timeEntryId,
    ...values,
  });

export const useRemoveTimeEntryMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveTimeEntryMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  RemoveTimeEntryMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveTimeEntryMutationProps
  >({
    mutationFn: RemoveTimeEntryMutation,
    throwOnError: false,
    ...options,
  });
