import { EmailAddress, Uuid } from '@bas/value-objects';
import * as Yup from 'yup';

export type SignQuoteWithCustomerStepInputType = {
  signQuote: {
    name: string;
    emailAddress: EmailAddress;
    remarks?: string | null;
    signature: string;
    acceptedLines: Uuid[];
  };
};

export const SignQuoteWithCustomerStepInputTypeValidatorBuilder = Yup.object({
  signQuote: Yup.object({
    name: Yup.string().required().label('label.name'),
    emailAddress: Yup.string().email().required().label('label.emailAddress'),
    signature: Yup.string().required().label('label.signature'),
  }),
});
