import { Money, NullableMoney, Uuid } from '@bas/value-objects';
import { v7 } from 'uuid';
import * as Yup from 'yup';

export interface ProjectCostInputType {
  costId: Uuid;
  costTypeId: Uuid;
  useDate?: Date | null;
  name: string;
  notes?: string | null;
  expectedPurchasePricePerUnit: Money | NullableMoney;
  expectedQuantity?: number | null;
  realizedPurchasePricePerUnit: Money | NullableMoney;
  realizedQuantity?: number | null;
}

export const ProjectCostInputTypeDefaultValues = (): ProjectCostInputType => ({
  costId: v7(),
  costTypeId: '',
  useDate: null,
  name: '',
  notes: '',
  expectedPurchasePricePerUnit: { amount: 0, currency: 'EUR' },
  expectedQuantity: 0,
  realizedPurchasePricePerUnit: { amount: 0, currency: 'EUR' },
  realizedQuantity: 0,
});

export const ProjectCostInputTypeValidationBuilder =
  (): Yup.ObjectSchema<ProjectCostInputType> =>
    Yup.object({
      costId: Yup.string().required().label('label.costId'),
      costTypeId: Yup.string().required().label('label.costType'),
      name: Yup.string().max(200).required().label('label.name'),
      notes: Yup.string().nullable().label('label.notes'),
      useDate: Yup.date().required().label('label.date'),
      expectedPurchasePricePerUnit: Yup.object({
        amount: Yup.number()
          .transform((value, original) =>
            original === '' || original === null ? null : value,
          )
          .nullable()
          .defined()
          .label('label.expectedPurchasePricePerUnit'),
        currency: Yup.string().required(),
      }),
      expectedQuantity: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .nullable()
        .defined()
        .label('label.expectedQuantity'),
      realizedPurchasePricePerUnit: Yup.object({
        amount: Yup.number()
          .transform((value, original) =>
            original === '' || original === null ? null : value,
          )
          .nullable()
          .defined()
          .label('label.realizedPurchasePricePerUnit'),
        currency: Yup.string().required(),
      }),
      realizedQuantity: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .nullable()
        .defined()
        .label('label.realizedQuantity'),
    });
