import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type UpdateTenantInformationMutationProps = {
  tenantId: Uuid;
  companyName: string;
  logoId?: Uuid | null;
  cocNumber?: string | null;
  vatNumber?: string | null;
  rsinNumber?: string | null;
  ibanNumber?: string | null;
};

export const UpdateTenantInformationMutation = async ({
  ...values
}: UpdateTenantInformationMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/tenants/{tenantId}`, {
    ...values,
  });

export const useUpdateTenantInformationMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateTenantInformationMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  UpdateTenantInformationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateTenantInformationMutationProps
  >({
    mutationFn: UpdateTenantInformationMutation,
    throwOnError: false,
    ...options,
  });
