import { isIcon } from '@bas/svg-icons';
import { SquareTile, SquareTileProps } from '@bas/ui/native/atoms';
import {
  Box,
  Icon,
  IconProps,
  Typography,
  TypographyProps,
} from '@bas/ui/native/base';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons/faPlusCircle';
import { cloneElement, isValidElement, ReactElement, useMemo } from 'react';
import styles from './styles';

export type SquareAddTileProps = Omit<SquareTileProps, 'children'> & {
  icon?:
    | IconProp
    | ReactElement<IconProps>
    | { prefix: string; iconName: string };
  label: string | ReactElement<TypographyProps>;
  light?: boolean;
};

const SquareAddTile = ({
  icon = faPlusCircle,
  label,
  light,
  ...props
}: SquareAddTileProps): ReactElement => {
  const color = useMemo(() => (light ? 'white' : 'darkText'), [light]);

  return (
    <SquareTile {...props} style={styles.container}>
      <Box
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        flex={1}
      >
        {isValidElement(icon) &&
          cloneElement(icon as ReactElement<IconProps>, { color })}
        {isIcon(icon) && <Icon icon={icon} size={61} color={color} />}
        <Box style={styles.label}>
          {isValidElement(label) ? (
            cloneElement(label as ReactElement<TypographyProps>, {
              color,
              fontWeight: 700,
            })
          ) : (
            <Typography fontWeight={700} color={color}>
              {label}
            </Typography>
          )}
        </Box>
      </Box>
    </SquareTile>
  );
};

export default SquareAddTile;
