import { colors } from '@bas/theme';
import { Typography } from '@bas/ui/native/base';
import { ReactHookFormNumberTextField } from '@bas/ui/native/molecules';
import { InventoryItem, PublicMovingBox } from '@bas/wms-domain/models';
import { FlashList, ListRenderItemInfo } from '@shopify/flash-list';
import * as React from 'react';
import { ReactElement, useCallback, useMemo } from 'react';
import { View, ViewProps } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import styles from './styles';

export type TakeMaterialsFormProps = ViewProps & {
  materials: (InventoryItem | PublicMovingBox)[];
  offSetIndex?: number;
  renderHeader?: () => ReactElement;
};

type ListItemType =
  | ((InventoryItem | PublicMovingBox) & { materialIndex: number })
  | string;

const TakeMaterialsForm = ({
  materials,
  renderHeader,
  offSetIndex,
}: TakeMaterialsFormProps): ReactElement => {
  const handleRenderItem = useCallback(
    ({ item: material }: ListRenderItemInfo<ListItemType>) => {
      if (typeof material === 'string') {
        if (material === 'header' && renderHeader) {
          return renderHeader();
        }

        return null;
      }

      return (
        <View style={styles.row}>
          <Typography
            variant="h5"
            fontWeight={700}
            overrideColor={colors.white}
            style={styles.label}
          >
            {material.name}
          </Typography>
          <View style={styles.inputContainer}>
            <ReactHookFormNumberTextField
              name={`materials[${
                material.materialIndex + (offSetIndex || 0)
              }].quantity`}
              style={styles.input}
              variant="outlined"
              keyboardType="numeric"
              fractionDigits={2}
              shouldBeZero
              padZero={false}
            />
          </View>
        </View>
      );
    },
    [offSetIndex, renderHeader],
  );

  const data = useMemo(
    () =>
      renderHeader
        ? [
            'header',
            ...materials.map((material, index) => ({
              ...material,
              materialIndex: index,
            })),
          ]
        : materials.map((material, index) => ({
            ...material,
            materialIndex: index,
          })),
    [materials, renderHeader],
  );

  return (
    <FlashList
      renderScrollComponent={ScrollView}
      renderItem={handleRenderItem}
      data={data}
      estimatedItemSize={82}
    />
  );
};

export default TakeMaterialsForm;
