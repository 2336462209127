import { formatDate } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ChangeEmploymentInformationMutationProps = {
  employeeId: Uuid;
  employmentStartDate?: Date | null;
  employmentEndDate?: Date | null;
  probationDate?: Date | null;
};

export const ChangeEmploymentInformationMutation = async ({
  employeeId,
  employmentStartDate,
  employmentEndDate,
  probationDate,
  ...values
}: ChangeEmploymentInformationMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/hrm/employees/${employeeId}/update-employment`, {
    employeeId,
    employmentStartDate: employmentStartDate
      ? formatDate(employmentStartDate)
      : null,
    employmentEndDate: employmentEndDate ? formatDate(employmentEndDate) : null,
    probationDate: probationDate ? formatDate(probationDate) : null,
    ...values,
  });

export const useChangeEmploymentInformationMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeEmploymentInformationMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ChangeEmploymentInformationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeEmploymentInformationMutationProps
  >({
    mutationFn: ChangeEmploymentInformationMutation,
    throwOnError: false,
    ...options,
  });
