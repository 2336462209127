import { MileageReportInputType } from '@bas/planning-domain/input-types';
import { formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type StartDrivingToEventMutationProps = {
  eventId: Uuid;
  realTravelStartTime: Date;
  mileageReports: MileageReportInputType[];
};

export const StartDrivingToEventMutation = async ({
  eventId,
  realTravelStartTime,
  mileageReports,
  ...values
}: StartDrivingToEventMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/events/${eventId}/start-driving-to-event`, {
    ...values,
    eventId,
    realTravelStartTime: formatDateTime(realTravelStartTime),
    mileageReports: mileageReports.map(
      ({ reportStartDate, reportEndDate, ...mileageReport }) => ({
        ...mileageReport,
        reportStartDate: formatDateTime(reportStartDate || realTravelStartTime),
        reportEndDate: reportEndDate ? formatDateTime(reportEndDate) : null,
      }),
    ),
  });

export const useStartDrivingToEventMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    StartDrivingToEventMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  StartDrivingToEventMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    StartDrivingToEventMutationProps
  >({
    mutationFn: StartDrivingToEventMutation,
    throwOnError: false,
    ...options,
  });
