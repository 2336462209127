import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type CreateTeamMutationProps = {
  teamId: Uuid;
  name: string;
};

export const CreateTeamMutation = async ({
  ...values
}: CreateTeamMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/{tenantId}/hrm/teams`, {
    ...values,
  });

export const useCreateTeamMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    CreateTeamMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  CreateTeamMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    CreateTeamMutationProps
  >({
    mutationFn: CreateTeamMutation,
    throwOnError: false,
    ...options,
  });
