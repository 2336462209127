import { isObjectResponse } from '@bas/value-objects';
import type { TaskSettingsForTaskType } from '../types';

export function isTaskSettingsForTaskType(
  object: unknown,
): object is TaskSettingsForTaskType {
  return (
    !!object &&
    isObjectResponse(object) &&
    object['@type'] === 'TaskSettingsForTaskType'
  );
}
