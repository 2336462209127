import * as Yup from 'yup';
import {
  EmployeeCertificateInputType,
  EmployeeCertificateInputTypeValidatorBuilder,
} from './employeeCertificateInputType';

export interface EmployeeCertificatesInputType {
  employeeCertificates: EmployeeCertificateInputType[];
}

export const EmployeeCertificatesInputTypeDefaultValues =
  (): EmployeeCertificatesInputType => ({
    employeeCertificates: [],
  });

export const EmployeeCertificatesInputTypeValidatorBuilder =
  (): Yup.ObjectSchema<EmployeeCertificatesInputType> =>
    Yup.object({
      employeeCertificates: Yup.array()
        .of(EmployeeCertificateInputTypeValidatorBuilder())
        .required(),
    });
