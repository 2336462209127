import { formatDate } from '@bas/shared/utils';
import { ErrorResponse, Money, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type UpdateHandlingCostsOfStorageMutationProps = {
  handlingCostsId: Uuid;
  storageId: Uuid;
  amount: Money;
  quantity: number;
  handlingDate: Date;
  description: string;
  invoiced: boolean;
  invoicedDate?: Date | null;
};

export const UpdateHandlingCostsOfStorageMutation = async ({
  handlingCostsId,
  handlingDate,
  invoicedDate,
  storageId,
  ...values
}: UpdateHandlingCostsOfStorageMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/storages/${storageId}/handling-costs/${handlingCostsId}`,
    {
      handlingCostsId,
      handlingDate: handlingDate ? formatDate(handlingDate) : null,
      invoicedDate: invoicedDate ? formatDate(invoicedDate) : null,
      ...values,
    },
  );

export const useUpdateHandlingCostsOfStorageMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateHandlingCostsOfStorageMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  UpdateHandlingCostsOfStorageMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateHandlingCostsOfStorageMutationProps
  >({
    mutationFn: UpdateHandlingCostsOfStorageMutation,
    throwOnError: false,
    ...options,
  });
