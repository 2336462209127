import { formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ChangeEmployeeOccasionalAvailabilityMutationProps = {
  employeeId: Uuid;
  availabilityId: Uuid;
  startTime: Date;
  endTime: Date;
};

export const ChangeEmployeeOccasionalAvailabilityMutation = async ({
  availabilityId,
  employeeId,
  startTime,
  endTime,
  ...values
}: ChangeEmployeeOccasionalAvailabilityMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/hrm/employees/${employeeId}/occasional-availabilities/${availabilityId}`,
    {
      availabilityId,
      startTime: formatDateTime(startTime),
      endTime: formatDateTime(endTime),
      ...values,
    },
  );

export const useChangeEmployeeOccasionalAvailabilityMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeEmployeeOccasionalAvailabilityMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ChangeEmployeeOccasionalAvailabilityMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeEmployeeOccasionalAvailabilityMutationProps
  >({
    mutationFn: ChangeEmployeeOccasionalAvailabilityMutation,
    throwOnError: false,
    ...options,
  });
