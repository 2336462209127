import { EventPageHeader } from '@bas/planning-domain/native/molecules';
import { useEventByEventIdRequest } from '@bas/planning-domain/requests';
import { colors } from '@bas/theme';
import { AddressBlock, Avatar } from '@bas/ui/native/atoms';
import { TouchableWithoutFeedback, Typography } from '@bas/ui/native/base';
import { LoadingScreen } from '@bas/ui/native/templates';
import { Uuid } from '@bas/value-objects';
import { useNavigation } from '@react-navigation/core';
import { StackActions } from '@react-navigation/native';
import { useLocalSearchParams } from 'expo-router';
import { ReactElement, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Linking, View } from 'react-native';
import styles from './styles';

const popAction = StackActions.pop(1);

const PlannedEmployeeDetailsScreen = (): ReactElement => {
  const { eventId, employeeId } = useLocalSearchParams<{
    eventId: Uuid;
    employeeId: Uuid;
  }>();

  const { data: eventData, isLoading } = useEventByEventIdRequest(
    {
      eventId: eventId || '',
    },
    { enabled: !!eventId },
  );

  const event = useMemo(() => eventData?.data, [eventData?.data]);

  const { formatMessage } = useIntl();
  const navigation = useNavigation();
  const handleBack = useCallback(() => {
    navigation.dispatch(popAction);
  }, [navigation]);

  const plannedEmployee = useMemo(
    () =>
      event?.employees.find(
        ({ employee }) => employee.employeeId === employeeId,
      ),
    [employeeId, event?.employees],
  );

  if (!event || !plannedEmployee || isLoading) {
    return <LoadingScreen />;
  }

  const { projectRole, employee } = plannedEmployee;

  const phoneNumber =
    employee?.contactInformation?.phoneNumber ||
    employee?.contactInformation?.mobileNumber;

  return (
    <View style={styles.container}>
      <EventPageHeader
        style={styles.pageHeader}
        onBack={handleBack}
        headerLabel={employee.personName.fullName}
        subHeaderLabel={
          <FormattedMessage
            id="event.plannedAs"
            values={{
              projectRole: formatMessage({
                id: `projectRoles.${projectRole}`,
              }).toLowerCase(),
            }}
          />
        }
      />
      <View style={styles.content}>
        <View style={styles.avatarContainer}>
          <Avatar
            src={employee.profilePictureUrl}
            name={`${employee.personName.firstName} ${employee.personName.lastName}`}
            size={181}
          />
        </View>
        <View style={styles.detailsContainer}>
          <Typography variant="subtitle1" style={styles.title}>
            <FormattedMessage id="label.employeeData" />
          </Typography>
          <View style={styles.row}>
            <Typography style={styles.label}>
              <FormattedMessage id="label.number" />
            </Typography>
            <TouchableWithoutFeedback
              onPress={() => Linking.openURL(`tel:${phoneNumber}`)}
            >
              <Typography style={[styles.link, styles.data]}>
                {phoneNumber}
              </Typography>
            </TouchableWithoutFeedback>
          </View>
          <View style={styles.row}>
            <Typography style={styles.label}>
              <FormattedMessage id="label.address" />
            </Typography>
            <View style={styles.data}>
              <AddressBlock
                address={employee.address}
                showLink
                color={colors.lila[800]}
              />
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default PlannedEmployeeDetailsScreen;
