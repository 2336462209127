import * as Yup from 'yup';
import {
  EnterHoursInputType,
  EnterHoursInputTypeDefaultValues,
  EnterHoursInputTypeValidationBuilder,
} from './enterHoursInputType';

export type FinishEventAtCustomerInputType = EnterHoursInputType;

export const FinishEventAtCustomerInputTypeDefaultValues =
  (): FinishEventAtCustomerInputType => ({
    ...EnterHoursInputTypeDefaultValues(),
  });

export const FinishEventAtCustomerInputTypeValidationBuilder =
  (): Yup.ObjectSchema<FinishEventAtCustomerInputType> =>
    EnterHoursInputTypeValidationBuilder();
