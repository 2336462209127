import { isTask } from './Task';
import type { CouldNotCreateNewLeadTask } from '../types';
import { TaskType } from '../types';

export function isCouldNotCreateNewLeadTask(
  object: unknown,
): object is CouldNotCreateNewLeadTask {
  return (
    !!object &&
    isTask(object) &&
    object.taskType === TaskType.COULD_NOT_CREATE_NEW_LEAD_TASK
  );
}
