import { BasTrialSoftwareComponent } from '../types';
import { isTenantBillableComponent } from './TenantBillableComponent';

export function isBasTrialSoftwareComponent(
  component: unknown,
): component is BasTrialSoftwareComponent {
  return (
    typeof component === 'object' &&
    isTenantBillableComponent(component) &&
    component.componentType === 'bas-trial-software'
  );
}
