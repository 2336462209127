import type { Uuid } from '@bas/value-objects';
import type { MediaObject } from '@bas/media-domain/models';
import type { PublicRoomType, RoomType } from '@bas/shared/models';
import type { MovingJobUsedInventoryItem } from './MovingJobUsedInventoryItem';
import type { MovingJobFurniture } from './MovingJobFurniture';

export interface MovingJobRoom {
  roomId: Uuid;
  name?: string;
  roomType: RoomType | PublicRoomType;
  furniture: MovingJobFurniture[];
  inventoryItems: MovingJobUsedInventoryItem[];
  notes?: string;
  photos: MediaObject[];
  numberOfBoxes: number;
  cubicMeter: number;
}
