import { Box } from '@bas/ui/native/base';
import { ReactElement, useCallback, useState } from 'react';
import { LayoutChangeEvent } from 'react-native';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.mjs`;

export type PdfRenderProps = {
  pdf: { uri: string };
  onLoad: (numberOfPages: number) => void;
  numberOfPages: number;
};

const PdfRender = ({
  pdf,
  onLoad,
  numberOfPages,
}: PdfRenderProps): ReactElement => {
  const [width, setWidth] = useState(0);

  const handleLayout = useCallback((event: LayoutChangeEvent) => {
    setWidth(event.nativeEvent.layout.width);
  }, []);

  return (
    <Box onLayout={handleLayout}>
      <Document file={pdf.uri} onLoadSuccess={(doc) => onLoad(doc.numPages)}>
        <Page
          pageNumber={1}
          width={width}
          renderTextLayer={false}
          renderAnnotationLayer={false}
        />
        {numberOfPages >= 2 && (
          <Page
            pageNumber={2}
            width={width}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        )}
        {numberOfPages >= 3 && (
          <Page
            pageNumber={numberOfPages}
            width={width}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        )}
      </Document>
    </Box>
  );
};

export default PdfRender;
