import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  selectedIcon: {
    position: 'absolute',
    top: 13,
    left: 11,
  },
  label: {
    alignContent: 'center',
  },
  paraghHolder: {
    marginLeft: 'auto',
  },
});
