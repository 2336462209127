import { Conversation } from '@bas/communication-domain/models';
import { QueryOptionsWithKey } from '@bas/shared/requests';
import { Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ConversationByEmployeeIdRequestProps = {
  employeeId: Uuid;
};

export const ConversationByEmployeeIdRequest = async ({
  employeeId,
  ...params
}: ConversationByEmployeeIdRequestProps): Promise<
  AxiosResponse<Conversation>
> =>
  axios.get(`api/{tenantId}/chat/conversations/employee/${employeeId}`, {
    params,
  });

export const useConversationByEmployeeIdRequestQuery = (
  request: ConversationByEmployeeIdRequestProps,
  options: QueryOptionsWithKey<
    AxiosResponse<Conversation>,
    AxiosError,
    AxiosResponse<Conversation>
  > = {},
): UseQueryResult<AxiosResponse<Conversation>, AxiosError> =>
  useQuery<
    AxiosResponse<Conversation>,
    AxiosError,
    AxiosResponse<Conversation>
  >({
    ...options,
    queryFn: async () => ConversationByEmployeeIdRequest(request),
    queryKey: ['conversations', 'detail', request.employeeId],
  });
