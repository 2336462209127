import {
  MileageReportInputType,
  MileageReportInputTypeDefaultValues,
  MileageReportInputTypeValidationBuilder,
} from '@bas/planning-domain/input-types';
import { Vehicle } from '@bas/planning-domain/models';
import { colors } from '@bas/theme';
import { BottomSheetMethods, Typography } from '@bas/ui/native/base';
import { BackendErrors, BottomSheetProps } from '@bas/ui/native/organisms';
import { FormBottomSheet } from '@bas/ui/native/templates';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { ReactElement, RefObject, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { ensuredForwardRef } from 'react-use/lib/useEnsuredForwardedRef';
import { MileageReportForm } from '../MileageReportForm';

export type RegisterMileageReportBottomSheetProps = Omit<
  BottomSheetProps,
  'snapPoints' | 'children'
> & {
  onSubmit: (values: MileageReportInputType) => Promise<void> | void;
  vehicles: Vehicle[];
  greetingName?: string | null;
};

const styles = StyleSheet.create({
  flexContainer: {
    flex: 1,
    overflow: 'hidden',
  },
  container: {
    marginBottom: 15,
    paddingLeft: 20,
    paddingRight: 20,
  },
  description: {
    textAlign: 'center',
  },
  avatar: {
    height: '100%',
    width: '100%',
  },
  niceJob: {
    textAlign: 'center',
  },
  avatarContainer: {
    overflow: 'hidden',
    borderRadius: 269,
    height: 268,
    width: 268,
    marginBottom: 29,
    marginTop: 29,
    alignSelf: 'center',
  },
  header: {
    alignSelf: 'center',
    fontSize: 22,
    lineHeight: 29,
    height: 51,
  },
  paddingTop: {
    paddingTop: 24,
  },
  padding: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 31,
    paddingTop: 31,
  },
});

const RegisterMileageReportBottomSheet = ensuredForwardRef<
  BottomSheetModal & BottomSheetMethods,
  RegisterMileageReportBottomSheetProps
>(
  (
    {
      onSubmit,
      vehicles,
      greetingName,
      ...props
    }: RegisterMileageReportBottomSheetProps,
    ref: RefObject<BottomSheetModal & BottomSheetMethods>,
  ): ReactElement => {
    const handleFinalize = useCallback(() => {
      ref?.current?.close();
      ref?.current?.forceClose();
      ref?.current?.dismiss();
    }, [ref]);

    return (
      <FormBottomSheet<MileageReportInputType>
        bottomSheetRef={ref}
        disablePadding
        onSubmit={onSubmit}
        useProvider
        resetFormOnOpen
        onCancel={handleFinalize}
        defaultValues={{ ...MileageReportInputTypeDefaultValues() }}
        validationSchema={MileageReportInputTypeValidationBuilder}
        successFullFormViewProps={{
          gifUrl: 'https://media.giphy.com/media/MCiOE6DBDNkjcA3xTj/giphy.gif',

          title: 'label.registerMileageReport.finished',
          color: colors.lila[100],
          labelId: 'label.mileageReportIsProcessed',
          greetingName,
        }}
        {...props}
      >
        <View style={styles.flexContainer}>
          <Typography
            fontWeight={700}
            overrideColor={colors.white}
            style={styles.header}
            textAlign="center"
          >
            <FormattedMessage id="label.registerMileageReport" />
          </Typography>
          <View>
            <BackendErrors />
            <View style={styles.container}>
              <MileageReportForm
                vehicles={vehicles}
                showStart
                showEnd
                showDates
              />
            </View>
          </View>
        </View>
      </FormBottomSheet>
    );
  },
);

export default RegisterMileageReportBottomSheet;
