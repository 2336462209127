import {
  DriversLicenceCharacteristics,
  ErrorResponse,
  Uuid,
} from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ChangeEmployeeDriversLicenseMutationProps = {
  employeeId: Uuid;
  driversLicense: DriversLicenceCharacteristics;
};

export const ChangeEmployeeDriversLicenseMutation = async ({
  employeeId,
  ...values
}: ChangeEmployeeDriversLicenseMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/hrm/employees/${employeeId}/update-drivers-license`,
    {
      employeeId,
      ...values,
    },
  );

export const useChangeEmployeeDriversLicenseMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeEmployeeDriversLicenseMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ChangeEmployeeDriversLicenseMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeEmployeeDriversLicenseMutationProps
  >({
    mutationFn: ChangeEmployeeDriversLicenseMutation,
    throwOnError: false,
    ...options,
  });
