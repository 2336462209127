import { Box } from '@bas/ui/native/base';
import { DocumentType } from '@bas/value-objects';
import { ReactElement, ReactNode } from 'react';
import { useWatch } from 'react-hook-form';
import { ScrollView } from 'react-native-gesture-handler';
import { FinancialDocumentFormPart } from '../FinancialDocumentFormPart';

export type FinancialDocumentFormStepProps = {
  documentType: DocumentType;
  prefix?: string;
  light?: boolean;
  useIdentities?: boolean;
  children?: ReactNode | ReactNode[];
};

const FinancialDocumentFormStep = ({
  documentType,
  prefix,
  light,
  useIdentities,
  children,
}: FinancialDocumentFormStepProps): ReactElement => {
  const [identityId] = useWatch({
    name: [`${prefix}.identityId`],
  });

  return (
    <ScrollView>
      <Box paddingHorizontal={20}>
        <FinancialDocumentFormPart
          documentType={documentType}
          useIdentities={useIdentities}
          prefix={prefix}
          light={light}
          identityId={identityId}
        >
          {children}
        </FinancialDocumentFormPart>
      </Box>
    </ScrollView>
  );
};

export default FinancialDocumentFormStep;
