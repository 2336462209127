import { formatDate } from '@bas/shared/utils';
import { EmployeeProjectRole, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type UpdateNeededEventRolesMutationProps = {
  eventId: Uuid;
  neededRoles: EmployeeProjectRole[];
  date: Date;
  updateFutureEvents: boolean;
};

export const UpdateNeededEventRolesMutation = async ({
  eventId,
  date,
  ...values
}: UpdateNeededEventRolesMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/events/${eventId}/update-needed-roles`, {
    ...values,
    date: formatDate(date),
    eventId,
  });

export const useUpdateNeededEventRolesMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateNeededEventRolesMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  UpdateNeededEventRolesMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateNeededEventRolesMutationProps
  >({
    mutationFn: UpdateNeededEventRolesMutation,
    throwOnError: false,
    ...options,
  });
