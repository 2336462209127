import { formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, Money, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ChangeMovingBoxInformationMutationProps = {
  inventoryItemId: Uuid;
  name: string;
  length: number;
  width: number;
  height: number;
  rentalPrice: Money;
  sellingPrice: Money;
  expiresAt?: Date | null;
  weight?: number | null;
};

export const ChangeMovingBoxInformationMutation = async ({
  inventoryItemId,
  expiresAt,
  ...values
}: ChangeMovingBoxInformationMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/inventory/moving-boxes/${inventoryItemId}/change`, {
    inventoryItemId,
    expiresAt: expiresAt ? formatDateTime(expiresAt) : null,
    ...values,
  });

export const useChangeMovingBoxInformationMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeMovingBoxInformationMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ChangeMovingBoxInformationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeMovingBoxInformationMutationProps
  >({
    mutationFn: ChangeMovingBoxInformationMutation,
    throwOnError: false,
    ...options,
  });
