import {
  AddAddressToRelationMutationProps,
  AddContactPersonToRelationMutationProps,
} from '@bas/crm-domain/mutations';
import {
  CreateInvoiceInputType,
  isCreatedFinancialLineInputType,
} from '@bas/financial-domain/input-types';
import { CreateInvoiceMutationProps } from '@bas/financial-domain/mutations';
import { getDefaultCountry } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { v7 } from 'uuid';

type Values = { invoice: CreateInvoiceInputType };

export const useCreateInvoiceFromInputType = <T extends Values = Values>(
  addAddressToRelationMutation: UseMutateAsyncFunction<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddAddressToRelationMutationProps
  >,
  addContactPersonToRelationMutation: UseMutateAsyncFunction<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddContactPersonToRelationMutationProps
  >,
  createInvoice: (
    mutation: CreateInvoiceMutationProps,
    values: T,
  ) => Promise<AxiosResponse>,
  callback?: (invoiceId: Uuid, values: T) => Promise<void> | void,
): ((values: T) => Promise<void>) =>
  useCallback(
    async (formValues) => {
      const { invoice: values } = formValues;
      let { contactPersonId, addressId } = values;
      if (!values.relation) {
        return;
      }

      const {
        relation: { relationId },
      } = values;

      if (contactPersonId === 'new') {
        contactPersonId = v7();
        await addContactPersonToRelationMutation({
          relationId,
          ...values.contactPerson,
          contactPersonId,
        });
      } else if (
        contactPersonId === 'private' ||
        contactPersonId === 'relation'
      ) {
        contactPersonId = '';
      }

      if (addressId === 'new') {
        addressId = v7();
        await addAddressToRelationMutation({
          relationId,
          ...values.address,
          addressId,
        });
      }

      const newInvoiceId = v7();
      await createInvoice(
        {
          invoiceId: newInvoiceId,
          relationId,
          addressId: addressId || '',
          contactPersonId,
          freeText: values.freeText,
          identityId: values.identityId,
          includingVat: values.includingVat,
          lines: values.lines
            .filter(({ created }) => created)
            .filter(isCreatedFinancialLineInputType)
            .filter(
              (line) =>
                !values.ignoreZeroQuantityLines ||
                parseFloat(line.quantity.toString()) < 0 ||
                parseFloat(line.quantity.toString()) > 0,
            ),
          projectId: values.projectId,
          templateId: values.templateId,
          reference: values.reference,
          sendInvoice: values.sendInvoice,
          finishInvoice: values.finishInvoice,
          discount: values.discount,
          region: getDefaultCountry(),
        },
        formValues,
      );

      if (callback) {
        await callback(newInvoiceId, formValues);
      }
    },
    [
      addAddressToRelationMutation,
      addContactPersonToRelationMutation,
      createInvoice,
      callback,
    ],
  );
