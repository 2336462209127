import { Vehicle } from '@bas/planning-domain/models';
import { useMileageReportsByMaterialIdRequest } from '@bas/planning-domain/requests';
import { calculateTimeOfDaySliderValues } from '@bas/shared/utils';
import { Box, Typography } from '@bas/ui/native/base';
import {
  ReactHookFormDatePickerField,
  ReactHookFormDropdown,
  ReactHookFormTextField,
  ReactHookFormTimeSliderField,
} from '@bas/ui/native/molecules';
import dayjs from 'dayjs';
import * as React from 'react';
import { ReactElement, useCallback, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import {
  FormattedDate,
  FormattedMessage,
  FormattedNumber,
  useIntl,
} from 'react-intl';

export type MileageReportFormProps = {
  prefix?: string;
  vehicles: Vehicle[];
  showStart?: boolean;
  showEnd?: boolean;
  showDates?: boolean;
  hideVehicles?: boolean;
};

const MileageReportForm = ({
  prefix,
  vehicles,
  showStart,
  showEnd,
  showDates,
  hideVehicles,
}: MileageReportFormProps): ReactElement => {
  const { formatMessage } = useIntl();
  const getPrefixedName = useCallback(
    (name: string) => {
      if (prefix) {
        return `${prefix}.${name}`;
      }

      return name;
    },
    [prefix],
  );

  const materialId = useWatch({ name: getPrefixedName('materialId') });
  const reportStartDate = useWatch({
    name: getPrefixedName('reportStartDate'),
  });
  const reportEndDate = useWatch({ name: getPrefixedName('reportEndDate') });

  const { data, isLoading } = useMileageReportsByMaterialIdRequest(
    { materialId, perPage: 5, order: { reportStartDate: 'desc' } },
    { enabled: !!materialId, placeholderData: undefined },
  );

  const latestMileageReport = useMemo(
    () => (data?.data?.member || [])?.[0],
    [data?.data],
  );

  const vehicle = useMemo(
    () => vehicles.find((v) => v.materialId === materialId),
    [materialId, vehicles],
  );

  const timeOfDayValuesReportStartDate = useMemo(
    () =>
      reportStartDate && dayjs(reportStartDate).isValid()
        ? calculateTimeOfDaySliderValues(5, reportStartDate)
        : [],
    [reportStartDate],
  );

  const timeOfDayValuesReportEndDate = useMemo(
    () =>
      reportEndDate && dayjs(reportEndDate).isValid()
        ? calculateTimeOfDaySliderValues(5, reportEndDate)
        : [],
    [reportEndDate],
  );

  return (
    <Box rowGap={showDates ? 3 : 1} paddingBottom={showDates ? 3 : 0}>
      {!hideVehicles && (
        <Box>
          <ReactHookFormDropdown
            name={getPrefixedName('materialId')}
            autoComplete="off"
            label={formatMessage({ id: 'label.vehicle' })}
            light
            items={[
              ...vehicles.map((option) => ({
                id: option.materialId,
                label: option.name,
              })),
            ]}
          />
        </Box>
      )}
      {hideVehicles && (
        <Typography color="white" fontWeight={700} variant="subtitle1">
          {vehicle?.name}
        </Typography>
      )}
      {isLoading && (
        <Box>
          <Typography color="white" fontWeight={700}>
            <FormattedMessage id="label.latestMileageReport" />
          </Typography>
          <Typography color="white">
            {/* <Skeleton width="100%" variant="text" color="white" /> */}
          </Typography>
          <Typography color="white">
            {/* <Skeleton width="100%" variant="text" color="white" /> */}
          </Typography>
        </Box>
      )}
      {latestMileageReport && !isLoading && (
        <Box>
          <Typography color="white" fontWeight={700}>
            <FormattedMessage id="label.latestMileageReport" />
          </Typography>
          <Typography color="white">
            <FormattedMessage id="label.date" />
            {': '}
            <FormattedDate
              value={latestMileageReport.reportStartDate}
              dateStyle="short"
              timeStyle="short"
            />
          </Typography>
          <Typography color="white">
            <FormattedMessage id="label.mileageStart" />
            {': '}
            <FormattedNumber value={latestMileageReport.mileageStart} />
          </Typography>
          {latestMileageReport.mileageEnd && (
            <Typography color="white">
              <FormattedMessage id="label.mileageEnd" />
              {': '}
              <FormattedNumber value={latestMileageReport.mileageEnd} />
            </Typography>
          )}
        </Box>
      )}
      {showStart && (
        <Box>
          <ReactHookFormTextField
            name={getPrefixedName('mileageStart')}
            autoComplete="off"
            keyboardType="decimal-pad"
            label={formatMessage({ id: 'label.startMileage' })}
            light
          />
        </Box>
      )}
      {showEnd && (
        <Box>
          <ReactHookFormTextField
            name={getPrefixedName('mileageEnd')}
            autoComplete="off"
            keyboardType="decimal-pad"
            label={formatMessage({ id: 'label.endMileage' })}
            light
          />
        </Box>
      )}
      {showDates && (
        <>
          <Box>
            <ReactHookFormDatePickerField
              name={getPrefixedName('reportStartDate')}
              label={formatMessage({ id: 'label.startDate' })}
              light
            />
          </Box>
          {timeOfDayValuesReportStartDate.length > 0 && (
            <Box>
              <ReactHookFormTimeSliderField
                name={getPrefixedName('reportStartDate')}
                label={formatMessage({ id: 'label.startTime' })}
                values={timeOfDayValuesReportStartDate}
                light
              />
            </Box>
          )}
          <Box>
            <ReactHookFormDatePickerField
              name={getPrefixedName('reportEndDate')}
              label={formatMessage({ id: 'label.endDate' })}
              light
            />
          </Box>
          {timeOfDayValuesReportEndDate.length > 0 && (
            <Box>
              <ReactHookFormTimeSliderField
                name={getPrefixedName('reportEndDate')}
                label={formatMessage({ id: 'label.endTime' })}
                values={timeOfDayValuesReportEndDate}
                light
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default MileageReportForm;
