import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type RemoveMaterialMutationProps = {
  materialId: Uuid;
};

export const RemoveMaterialMutation = async ({
  materialId,
  ...values
}: RemoveMaterialMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/materials/${materialId}/remove`, {
    materialId,
    ...values,
  });

export const useRemoveMaterialMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveMaterialMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  RemoveMaterialMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveMaterialMutationProps
  >({
    mutationFn: RemoveMaterialMutation,
    throwOnError: false,
    ...options,
  });
