import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type UpdateIdentityInformationMutationProps = {
  identityId: Uuid;
  companyName: string;
  logoId?: Uuid | null;
  cocNumber?: string | null;
  vatNumber?: string | null;
  rsinNumber?: string | null;
  ibanNumber?: string | null;
};

export const UpdateIdentityInformationMutation = async ({
  identityId,
  ...values
}: UpdateIdentityInformationMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/tenants/{tenantId}/identities/${identityId}`, {
    identityId,
    ...values,
  });

export const useUpdateIdentityInformationMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateIdentityInformationMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  UpdateIdentityInformationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateIdentityInformationMutationProps
  >({
    mutationFn: UpdateIdentityInformationMutation,
    throwOnError: false,
    ...options,
  });
