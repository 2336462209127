import { useGetTranslatedName } from '@bas/shared/hooks';
import { PublicFurnitureType } from '@bas/shared/models';
import {
  isMovingJobTaxationCustomFurniture,
  isMovingJobTaxationStandardFurniture,
  MovingJobTaxationFurniture,
} from '@bas/taxation-tool-domain/models';
import { RenderTranslatedName } from '@bas/ui/native/atoms';
import { Box, Typography } from '@bas/ui/native/base';
import {
  BackendIcon,
  SquareCounterTile,
  SquareIconTile,
  SquareVariantsTile,
} from '@bas/ui/native/molecules';
import { InternalVariantType, Uuid } from '@bas/value-objects';
import { faBlockQuestion } from '@fortawesome/pro-light-svg-icons/faBlockQuestion';
import { ReactElement, ReactNode, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { DimensionValue } from 'react-native';

export type FurnitureSquareTileProps = {
  furnitureType?: PublicFurnitureType;
  intakeFurniture?: MovingJobTaxationFurniture;
  width: DimensionValue;
  index?: number;
  handleAddFurniture: (
    quantity: number,
    furnitureType?: PublicFurnitureType,
    furnitureVariantId?: Uuid,
    intakeFurniture?: MovingJobTaxationFurniture,
    index?: number,
  ) => void;
  handleUnselectFurniture?: (
    intakeFurniture?: MovingJobTaxationFurniture,
  ) => void;
  handleChangeQuantity?: (
    quantity: number,
    intakeFurniture?: MovingJobTaxationFurniture,
  ) => void;
};

const FurnitureSquareTile = ({
  width,
  furnitureType,
  intakeFurniture,
  index,
  handleAddFurniture,
  handleUnselectFurniture,
  handleChangeQuantity,
}: FurnitureSquareTileProps): ReactElement => {
  const selected = intakeFurniture?.selected || false;
  const quantity = intakeFurniture?.quantity || 0;
  const getTranslatedName = useGetTranslatedName();

  const variants = useMemo(
    () =>
      furnitureType?.variants.map((variant) => ({
        variantId: variant.variantTypeId,
        label: getTranslatedName(
          variant.translatedNames,
          variant.variantTypeName,
        ),
      })) || [],
    [furnitureType?.variants, getTranslatedName],
  );
  const selectedVariant =
    intakeFurniture && isMovingJobTaxationStandardFurniture(intakeFurniture)
      ? variants.find(
          ({ variantId }) => variantId === intakeFurniture.furnitureVariantId,
        )
      : undefined;

  const disableCounter = useMemo(
    () =>
      intakeFurniture && isMovingJobTaxationCustomFurniture(intakeFurniture),
    [intakeFurniture],
  );

  const [showVariants, setShowVariants] = useState(false);
  const [showCounter, setShowCounter] = useState(false);

  let variantType: InternalVariantType | undefined;
  if ((furnitureType?.variants.length || 0) > 0) {
    variantType = furnitureType?.variants[0].valueType;
  }

  const color = useMemo(() => (selected ? 'white' : 'darkText'), [selected]);

  const renderLabel = useMemo(() => {
    const countedLabel = (quantity || 0) > 1 && `${quantity}x`;

    let label: ReactNode = '';
    if (furnitureType) {
      label = (
        <RenderTranslatedName
          fontWeight={700}
          color={color}
          translations={furnitureType.translatedNames}
          fallback={furnitureType.furnitureTypeName}
        />
      );
    }

    if (isMovingJobTaxationCustomFurniture(intakeFurniture)) {
      label = intakeFurniture.label;
    }

    if (selected && selectedVariant) {
      return (
        <Box flexDirection="column" alignItems="center">
          <Typography fontWeight={700} color={color}>
            {countedLabel}
            &nbsp;
            <FormattedMessage
              id={`variantLabel.selected.${variantType}`}
              values={{ variantLabel: selectedVariant.label }}
            />
          </Typography>
          <Typography fontWeight={700} color={color}>
            {label}
          </Typography>
        </Box>
      );
    }

    return (
      <Typography fontWeight={700} color={color}>
        {countedLabel}
        &nbsp;
        {label}
      </Typography>
    );
  }, [
    color,
    quantity,
    furnitureType,
    intakeFurniture,
    selected,
    selectedVariant,
    variantType,
  ]);

  const updateCount = useCallback(
    (newCount: number) => {
      if (newCount <= 0) {
        handleUnselectFurniture?.(intakeFurniture);
        return;
      }

      if (!selected) {
        handleAddFurniture(
          newCount,
          furnitureType,
          selectedVariant?.variantId,
          intakeFurniture,
          index,
        );
        return;
      }

      if (handleChangeQuantity) {
        handleChangeQuantity(newCount, intakeFurniture);
      }
    },
    [
      selected,
      handleChangeQuantity,
      handleUnselectFurniture,
      intakeFurniture,
      handleAddFurniture,
      furnitureType,
      selectedVariant?.variantId,
      index,
    ],
  );

  const handleFinish = useCallback(() => {
    updateCount(quantity);
    setShowCounter(false);
  }, [quantity, updateCount]);

  const handlePress = useCallback(() => {
    if (
      variants.length > 0 &&
      !selected &&
      variantType !== InternalVariantType.PIECES
    ) {
      setShowVariants(true);

      return;
    }

    if (disableCounter) {
      if (!selected) {
        handleAddFurniture(1, furnitureType, undefined, intakeFurniture, index);
      } else {
        handleUnselectFurniture?.(intakeFurniture);
      }
      return;
    }

    if (!selected) {
      handleAddFurniture(1, furnitureType, undefined, intakeFurniture, index);
    }

    setShowCounter(true);
  }, [
    index,
    variants.length,
    selected,
    variantType,
    disableCounter,
    handleAddFurniture,
    furnitureType,
    intakeFurniture,
    handleUnselectFurniture,
  ]);

  const handleSelectVariant = useCallback(
    async (variantId: Uuid) => {
      await handleAddFurniture(
        1,
        furnitureType,
        variantId,
        intakeFurniture,
        index,
      );
      setShowVariants(false);
      setShowCounter(true);
    },
    [handleAddFurniture, furnitureType, intakeFurniture, index],
  );

  if (showCounter) {
    return (
      <SquareCounterTile
        width={width}
        variantLabel={
          selectedVariant && (
            <FormattedMessage
              id={`variantLabel.selected.${variantType}`}
              values={{ variantLabel: selectedVariant.label }}
            />
          )
        }
        count={quantity}
        onCountChange={updateCount}
        onIncrease={() => {
          const newCount = quantity + 1;
          updateCount(newCount);
        }}
        onDecrease={() => {
          const newCount = quantity - 1;
          updateCount(newCount);

          if (newCount === 0) {
            if (
              variants.length > 0 &&
              variantType !== InternalVariantType.PIECES
            ) {
              setShowVariants(true);
              setShowCounter(false);
            }
          }
        }}
        onFinish={handleFinish}
      />
    );
  }

  if (showVariants) {
    return (
      <SquareVariantsTile
        width={width}
        onSelect={handleSelectVariant}
        variantLabel={
          variantType && <FormattedMessage id={`variantLabel.${variantType}`} />
        }
        variants={variants}
      />
    );
  }

  return (
    <SquareIconTile
      width={width}
      selected={selected}
      onPress={handlePress}
      icon={
        furnitureType ? (
          <BackendIcon icon={furnitureType?.icon} />
        ) : (
          faBlockQuestion
        )
      }
      label={renderLabel}
    />
  );
};

export default FurnitureSquareTile;
