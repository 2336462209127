import {
  isQrCode,
  isStorageLocationQrCode,
  isStorageQrCode,
  isStoredItemQrCode,
  QrCode,
} from '@bas/shared/models';
import { Button } from '@bas/ui/native/atoms';
import { BottomSheetMethods, Box } from '@bas/ui/native/base';
import { QrCodeItem } from '@bas/ui/native/molecules';
import {
  BottomSheet,
  BottomSheetProps,
  QrCodeScanner,
} from '@bas/ui/native/organisms';
import {
  ShowStorageBottomSheet,
  ShowStorageLocationBottomSheet,
  ShowStoredItemBottomSheet,
} from '@bas/wms-domain/storage/native/organisms';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { FlashList } from '@shopify/flash-list';
import { Audio, InterruptionModeIOS } from 'expo-av';
import { ReactElement, RefObject, useCallback, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import type { Code } from 'react-native-vision-camera';

export type QrScannerBottomSheetProps = Omit<
  BottomSheetProps,
  'snapPoints' | 'children'
> & {
  bottomSheetRef?: RefObject<BottomSheetModal & BottomSheetMethods>;
};
Audio.setAudioModeAsync({
  playsInSilentModeIOS: true,
  interruptionModeIOS: InterruptionModeIOS.DuckOthers,
  shouldDuckAndroid: true,
});

const QrScannerBottomSheet = ({
  bottomSheetRef,
  ...props
}: QrScannerBottomSheetProps): ReactElement => {
  const [isScanning, setIsScanning] = useState(true);
  const [qrContents, setQrContents] = useState<string[]>([]);
  const storedItemBottomSheetRef = useRef<
    BottomSheetModal & BottomSheetMethods
  >(null);
  const storageBottomSheetRef = useRef<BottomSheetModal & BottomSheetMethods>(
    null,
  );
  const storageLocationBottomSheetRef = useRef<
    BottomSheetModal & BottomSheetMethods
  >(null);

  const onClose = useCallback(() => {
    bottomSheetRef?.current?.close();
    bottomSheetRef?.current?.forceClose();
    bottomSheetRef?.current?.dismiss();
  }, [bottomSheetRef]);

  const handleShowMore = useCallback((qr: QrCode) => {
    if (isStoredItemQrCode(qr)) {
      storedItemBottomSheetRef.current?.present({
        ...qr,
      });
    }

    if (isStorageQrCode(qr)) {
      storageBottomSheetRef.current?.present({
        ...qr,
      });
    }

    if (isStorageLocationQrCode(qr)) {
      storageLocationBottomSheetRef.current?.present({
        ...qr,
      });
    }
  }, []);

  const handleRenderQrItem = useCallback(
    ({ item }: { item: string }) => (
      <QrCodeItem qrContent={item} onShowMore={handleShowMore} />
    ),
    [handleShowMore],
  );

  const listRef = useRef<FlashList<string>>(null);

  const handleScannedCode = useCallback(
    (code: Code) => {
      let isExisting = false;

      if (!code.value) {
        return false;
      }

      try {
        const parsed = JSON.parse(code.value || '{}');

        setQrContents((prev) => {
          if (prev.includes(code.value || '')) {
            isExisting = true;
            return prev;
          }

          return [code.value || '', ...prev];
        });

        listRef.current?.scrollToIndex({ index: 0 });
        return isQrCode(parsed) && !isExisting;
      } catch (e) {
        // ignore
        return false;
      }
    },
    [setQrContents],
  );

  return (
    <>
      <BottomSheet
        ref={bottomSheetRef}
        disablePadding
        enableDismissOnClose
        onDismiss={onClose}
        {...props}
      >
        <Box flex={1} paddingHorizontal={20} paddingBottom={20}>
          {isScanning && (
            <Box
              height={{
                phone: '40%',
                tablet: '60%',
              }}
              width="100%"
            >
              <QrCodeScanner onScannedCode={handleScannedCode} />
            </Box>
          )}
          <Box flexDirection="row" flexWrap="wrap" gap={2} paddingVertical={20}>
            <Box>
              <Button onPress={() => setQrContents([])}>
                <FormattedMessage id="button.clearScannedQrCodes" />
                {qrContents.length > 0 && ` (${qrContents.length})`}
              </Button>
            </Box>
            <Box>
              <Button onPress={() => setIsScanning(!isScanning)}>
                <FormattedMessage id="button.toggleScanning" />
              </Button>
            </Box>
          </Box>

          <Box flex={1}>
            <FlashList
              ref={listRef}
              renderItem={handleRenderQrItem}
              data={qrContents}
              estimatedItemSize={100}
            />
          </Box>
        </Box>
      </BottomSheet>
      <ShowStoredItemBottomSheet bottomSheetRef={storedItemBottomSheetRef} />
      <ShowStorageBottomSheet bottomSheetRef={storageBottomSheetRef} />
      <ShowStorageLocationBottomSheet
        bottomSheetRef={storageLocationBottomSheetRef}
      />
    </>
  );
};

export default QrScannerBottomSheet;
