import { useAppStore } from '@bas/shared/state';
import { colors } from '@bas/theme';
import { Box, Icon, Typography } from '@bas/ui/native/base';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons/faSpinnerThird';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Animated, Easing, Platform } from 'react-native';
import { AnimatedCircularProgress } from 'react-native-circular-progress';

const CalculatingTrackedItemsStep = () => {
  const hasGpuAcceleration = useAppStore((state) => state.hasGpuAcceleration);
  const [value, setValue] = useState<number>(40);
  const spinValue = useRef(new Animated.Value(0)).current;

  const spin = useCallback(() => {
    spinValue.setValue(0);

    Animated.timing(spinValue, {
      toValue: 1,
      duration: 1000,
      easing: Easing.linear,
      useNativeDriver: Platform.OS !== 'web',
    }).start(() => spin());
  }, [spinValue]);

  const rotate = useMemo(
    () =>
      spinValue.interpolate({
        inputRange: [0, 1],
        outputRange: ['0deg', '360deg'],
      }),
    [spinValue],
  );

  useEffect(() => spin(), [spin]);

  const circularProgress = useMemo(
    () => (
      <AnimatedCircularProgress
        fill={value}
        tintColor={colors.blue[500]}
        backgroundColor={colors.blue[200]}
        onAnimationComplete={() => {
          setValue(Math.random() * 80);
        }}
        size={120}
        width={10}
        rotation={0}
      />
    ),
    [value],
  );

  return (
    <Box justifyContent="center" flex={1} gap={3}>
      <Box justifyContent="center" alignItems="center">
        <Animated.View style={{ transform: [{ rotate }] }}>
          {hasGpuAcceleration ? (
            circularProgress
          ) : (
            <Icon
              size={72}
              icon={faSpinnerThird}
              overrideColor={colors.white}
              secondaryColor={colors.blue[500]}
            />
          )}
        </Animated.View>
      </Box>

      <Typography
        variant="h5"
        mt={1}
        textAlign="center"
        fontWeight={700}
        color="white"
      >
        <FormattedMessage id="mobileApp.intake.movingJob.weAreCalculatingTheEstimate" />
      </Typography>
    </Box>
  );
};

export default CalculatingTrackedItemsStep;
