import { ParticipantType, WhatsAppParticipant } from '../../types';
import { isParticipant } from './Participant';

export function isWhatsAppParticipant(
  object: unknown,
): object is WhatsAppParticipant {
  return (
    isParticipant(object) &&
    object.participantType === ParticipantType.WHATSAPP_BUSINESS
  );
}
