import { QueryOptionsWithKey } from '@bas/shared/requests';
import { formatDate } from '@bas/shared/utils';
import { Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ProjectEventWorkOrderPdfByEventIdRequestProps = {
  eventId: Uuid;
  date?: Date;
};

export const ProjectEventWorkOrderPdfByEventIdRequest = async ({
  eventId,
  date,
  ...params
}: ProjectEventWorkOrderPdfByEventIdRequestProps): Promise<
  AxiosResponse<Blob>
> =>
  axios.get(
    `api/{tenantId}/events/${eventId}/download-work-order${
      date ? `/${formatDate(date)}` : ''
    }`,
    {
      responseType: 'blob',
      params,
    },
  );

export const useProjectEventWorkOrderPdfByEventIdRequestQuery = (
  request: ProjectEventWorkOrderPdfByEventIdRequestProps,
  options: QueryOptionsWithKey<
    AxiosResponse<Blob>,
    AxiosError,
    AxiosResponse<Blob>
  > = {},
): UseQueryResult<AxiosResponse<Blob>, AxiosError> =>
  useQuery<AxiosResponse<Blob>, AxiosError, AxiosResponse<Blob>>({
    ...options,
    queryFn: async () => ProjectEventWorkOrderPdfByEventIdRequest(request),
    queryKey: ['events', 'pdf', request.eventId],
    gcTime: 0,
    staleTime: 0,
  });
