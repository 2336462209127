import * as Yup from 'yup';

export interface EmptyStorageInputType {
  invoicingEndDate?: Date | null;
  createInvoice: boolean;
}

export const emptyStorageInputTypeDefaultValues =
  (): EmptyStorageInputType => ({
    createInvoice: true,
    invoicingEndDate: null,
  });

export const emptyStorageInputTypeValidationBuilder =
  (): Yup.ObjectSchema<EmptyStorageInputType> =>
    Yup.object({
      invoicingEndDate: Yup.date()
        .when('createInvoice', {
          is: true,
          then: (schema) => schema.nullable().required(),
        })
        .nullable()
        .label('label.invoicingEndDate'),
      createInvoice: Yup.boolean().required().label('label.createInvoice'),
    });
