import {
  AddressInputType,
  addressInputTypeDefaultValues,
  addressInputTypeValidationBuilder,
} from '@bas/shared/input-types';
import { EventType, showEventLocation } from '@bas/value-objects';
import { Dayjs } from 'dayjs';
import * as Yup from 'yup';
import {
  ProjectEventDetailsInputType,
  ProjectEventDetailsInputTypeDefaultValues,
  ProjectEventDetailsInputTypeValidationBuilder,
} from './projectEventDetailsInputType';

export type PlanProjectEventInputType = ProjectEventDetailsInputType & {
  location: AddressInputType;
  departureLocation: AddressInputType;
  automaticTravelTime: boolean;
};

export const PlanProjectEventInputTypeDefaultValues = (
  date?: Date | Dayjs,
): PlanProjectEventInputType => ({
  ...ProjectEventDetailsInputTypeDefaultValues(date),
  location: addressInputTypeDefaultValues(),
  departureLocation: addressInputTypeDefaultValues(),
  automaticTravelTime: true,
});

export const PlanProjectEventInputTypeValidationBuilder = (
  allowedEventTypes?: EventType[],
  withoutLocation?: boolean,
  validationToConcat?: Yup.AnyObjectSchema,
  existingRepeatFromDate?: Date,
): Yup.ObjectSchema<
  Omit<PlanProjectEventInputType, 'location' | 'departureLocation'>
> =>
  ProjectEventDetailsInputTypeValidationBuilder(
    allowedEventTypes,
    existingRepeatFromDate,
  ).concat(
    // eslint-disable-next-line no-empty-pattern
    Yup.object().when([], ([], schema, { value }) => {
      let result: Yup.AnyObjectSchema = schema.concat(
        Yup.object({
          departureLocation: addressInputTypeValidationBuilder().required(),
        }),
      );

      if (validationToConcat) {
        result = schema.concat(validationToConcat);
      }

      if (withoutLocation || !showEventLocation(value.eventType)) {
        return result;
      }

      return result.concat(
        Yup.object({
          location: addressInputTypeValidationBuilder().required(),
        }),
      );
    }),
  );
