import { InvoiceStatus } from '@bas/financial-domain/models';
import { useInvoiceByInvoiceIdRequestQuery } from '@bas/financial-domain/requests';
import { useAppStore } from '@bas/shared/state';
import { colors } from '@bas/theme';
import { FormattedCurrency } from '@bas/ui/native/atoms';
import { BottomSheetMethods, Typography } from '@bas/ui/native/base';
import { InvoiceStatusChip } from '@bas/ui/native/molecules';
import { BottomSheet, BottomSheetProps } from '@bas/ui/native/organisms';
import { faBadgeCheck } from '@fortawesome/pro-solid-svg-icons/faBadgeCheck';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { forwardRef, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { View } from 'react-native';
import QRCode from 'react-native-qrcode-svg';
import styles from './styles';

export type PaymentQrCodeBottomSheetProps = Omit<
  BottomSheetProps,
  'snapPoints' | 'children'
> & {
  invoiceId?: string;
};

const PaymentQrCodeBottomSheet = forwardRef<
  BottomSheetModal & BottomSheetMethods,
  PaymentQrCodeBottomSheetProps
>(
  (
    { invoiceId, ...props }: PaymentQrCodeBottomSheetProps,
    ref,
  ): ReactElement => {
    const { data: invoiceData } = useInvoiceByInvoiceIdRequestQuery(
      {
        invoiceId: invoiceId || '',
      },
      { enabled: !!invoiceId },
    );

    const invoice = invoiceData?.data;
    const appState = useAppStore();

    return (
      <BottomSheet ref={ref} {...props}>
        <View style={styles.flex}>
          <View style={styles.center}>
            <Typography
              variant="h4"
              fontWeight={700}
              overrideColor={colors.white}
            >
              <FormattedMessage id="label.paymentQrCode" />
            </Typography>
          </View>
          {invoice && (
            <View style={styles.flex}>
              {invoice.invoiceStatus !== InvoiceStatus.PAID && (
                <View style={styles.qrCode}>
                  <QRCode
                    color={colors.lila[800]}
                    backgroundColor={colors.lila[200]}
                    size={300}
                    value={`${appState.apiUrl}/public/${invoice.tenantId}/invoices/payment/${invoice.invoiceId}/${invoice.relationId}/${invoice.code}/start-payment`}
                  />
                </View>
              )}
              {invoice.invoiceStatus === InvoiceStatus.PAID && (
                <View style={styles.icon}>
                  <FontAwesomeIcon
                    size={72}
                    color={colors.white}
                    icon={faBadgeCheck}
                  />
                </View>
              )}
              <View style={styles.invoiceInfo}>
                <View style={styles.paddingTop}>
                  <InvoiceStatusChip invoiceStatus={invoice.invoiceStatus} />
                </View>
                <View style={styles.paddingTop}>
                  <Typography
                    overrideColor={colors.lila[600]}
                    variant="subtitle2"
                  >
                    <FormattedMessage id="label.invoiceNumber" />
                  </Typography>
                  <Typography overrideColor={colors.white}>
                    {invoice.invoiceNumber}
                  </Typography>
                </View>
                <View style={styles.paddingTop}>
                  <Typography
                    overrideColor={colors.lila[600]}
                    variant="subtitle2"
                  >
                    <FormattedMessage id="label.total" />
                  </Typography>
                  <Typography overrideColor={colors.white}>
                    <FormattedCurrency {...invoice.totalIncludingVat} />
                  </Typography>
                </View>
                <View style={styles.paddingTop}>
                  <Typography
                    overrideColor={colors.lila[600]}
                    variant="subtitle2"
                  >
                    <FormattedMessage id="label.openAmount" />
                  </Typography>
                  <Typography overrideColor={colors.white}>
                    <FormattedCurrency {...invoice.openAmount} />
                  </Typography>
                </View>
              </View>
            </View>
          )}
        </View>
      </BottomSheet>
    );
  },
);

export default PaymentQrCodeBottomSheet;
