import {
  FillingInRoomWizardInputType,
  HowManyMovingBoxesAreNeededStepInputTypeValidatorBuilder,
} from '@bas/taxation-tool-domain/input-types';
import {
  isMovingJobTaxationCustomFurniture,
  isMovingJobTaxationStandardFurniture,
} from '@bas/taxation-tool-domain/models';
import { InternalServiceType, ReactHookWizardStep } from '@bas/value-objects';
import { HowDoYouWantToNameThisRoomStep } from '../HowDoYouWantToNameThisRoomStep';
import {
  HowManyMovingBoxesAreNeededStep,
  HowManyMovingBoxesAreNeededStepProps,
} from '../HowManyMovingBoxesAreNeededStep';
import { UploadSomePicturesStep } from '../UploadSomePicturesStep';
import { WhatFurnitureIsInTheRoom } from '../WhatFurnitureIsInTheRoom';
import {
  WhatFurnitureNeedServiceStep,
  WhatFurnitureNeedServiceStepProps,
} from '../WhatFurnitureNeedServiceStep';

const hasFurniture = (values: FillingInRoomWizardInputType): boolean =>
  values.furniture &&
  values.furniture.filter(({ selected }) => selected).length > 0;

const hasFurnitureWithServicesPossible = (
  values: FillingInRoomWizardInputType,
): boolean => {
  const selectedFurniture =
    values.furniture?.filter(({ selected }) => selected) ?? [];
  return (
    selectedFurniture.filter((intakeFurniture) => {
      if (isMovingJobTaxationCustomFurniture(intakeFurniture)) {
        return intakeFurniture.servicesPossible;
      }

      if (isMovingJobTaxationStandardFurniture(intakeFurniture)) {
        return intakeFurniture.servicesPossible;
      }

      return false;
    }).length > 0
  );
};

export const movingJobFillingInRoomWizardSteps = (
  usedServices: InternalServiceType[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): ReactHookWizardStep<FillingInRoomWizardInputType, any>[] => [
  {
    category: 'room',
    component: HowDoYouWantToNameThisRoomStep,
    title: 'mobileApp.intake.movingJob.roomFlow.howDoYouWantToNameThisRoomStep',
    key: 'mobileApp.intake.movingJob.roomFlow.howDoYouWantToNameThisRoomStep',
  },
  {
    category: 'furniture',
    component: WhatFurnitureIsInTheRoom,
    title:
      'mobileApp.intake.movingJob.roomFlow.whichFurnitureNeedsToBeMovedStep',
    key: 'mobileApp.intake.movingJob.roomFlow.whichFurnitureNeedsToBeMovedStep',
  },
  {
    category: 'services',
    component: WhatFurnitureNeedServiceStep,
    props: {
      serviceType: InternalServiceType.DISASSEMBLE,
    },
    title:
      'mobileApp.intake.movingJob.roomFlow.whatFurnitureNeedDisassemblyStep',
    key: 'mobileApp.intake.movingJob.roomFlow.whatFurnitureNeedDisassemblyStep',
    enabled: (values) =>
      hasFurnitureWithServicesPossible(values) &&
      usedServices.includes(InternalServiceType.DISASSEMBLE),
  } as ReactHookWizardStep<
    FillingInRoomWizardInputType,
    WhatFurnitureNeedServiceStepProps
  >,
  {
    category: 'services',

    component: WhatFurnitureNeedServiceStep,
    props: {
      serviceType: InternalServiceType.ASSEMBLE,
      copyFromServiceType: InternalServiceType.DISASSEMBLE,
    },
    title: 'mobileApp.intake.movingJob.roomFlow.whatFurnitureNeedAssemblyStep',
    key: 'mobileApp.intake.movingJob.roomFlow.whatFurnitureNeedAssemblyStep',
    enabled: (values) =>
      hasFurnitureWithServicesPossible(values) &&
      usedServices.includes(InternalServiceType.ASSEMBLE),
  } as ReactHookWizardStep<
    FillingInRoomWizardInputType,
    WhatFurnitureNeedServiceStepProps
  >,
  {
    category: 'services',
    component: WhatFurnitureNeedServiceStep,
    props: {
      serviceType: InternalServiceType.STORAGE,
      disableServicePossibleCheck: true,
    },
    title:
      'mobileApp.intake.movingJob.roomFlow.whatFurnitureNeedsToGoIntoStorageStep',
    key: 'mobileApp.intake.movingJob.roomFlow.whatFurnitureNeedsToGoIntoStorageStep',
    enabled: (values) =>
      hasFurniture(values) &&
      usedServices.includes(InternalServiceType.STORAGE),
  } as ReactHookWizardStep<
    FillingInRoomWizardInputType,
    WhatFurnitureNeedServiceStepProps
  >,
  {
    category: 'inventoryItems',
    component: HowManyMovingBoxesAreNeededStep,
    props: {
      fieldName: 'quantity',
    },
    validationSchema: HowManyMovingBoxesAreNeededStepInputTypeValidatorBuilder,
    title:
      'mobileApp.intake.movingJob.roomFlow.howManyMovingBoxesAreNeededStep',
    key: 'mobileApp.intake.movingJob.roomFlow.howManyMovingBoxesAreNeededStep',
  } as ReactHookWizardStep<
    FillingInRoomWizardInputType,
    HowManyMovingBoxesAreNeededStepProps
  >,
  {
    category: 'inventoryItems',
    component: HowManyMovingBoxesAreNeededStep,
    props: {
      fieldName: 'storageQuantity',
      showFromField: 'quantity',
    },
    validationSchema: HowManyMovingBoxesAreNeededStepInputTypeValidatorBuilder,
    title:
      'mobileApp.intake.movingJob.roomFlow.howManyMovingBoxesNeedToGoIntoStorageStep',
    key: 'mobileApp.intake.movingJob.roomFlow.howManyMovingBoxesNeedToGoIntoStorageStep',
    enabled: usedServices.includes(InternalServiceType.STORAGE),
  } as ReactHookWizardStep<
    FillingInRoomWizardInputType,
    HowManyMovingBoxesAreNeededStepProps
  >,
  {
    category: 'photos',
    component: UploadSomePicturesStep,
    title: 'mobileApp.intake.movingJob.roomFlow.uploadSomePicturesStep',
    key: 'mobileApp.intake.movingJob.roomFlow.uploadSomePicturesStep',
  },
];
