import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  selectedIcon: {
    position: 'absolute',
    top: 13,
    left: 11,
  },
  label: {
    marginTop: 14,
    paddingTop: 13,
  },
});
