export enum StepState {
  FINISHED = 'finished',
  IN_PROGRESS = 'in-progress',
  TODO = 'todo',
}

export const calculateStepState = (
  stepIndexes: number[],
  currentStepIndex: number | undefined,
): StepState => {
  if (currentStepIndex === undefined) {
    return StepState.TODO;
  }
  if (Math.max(...stepIndexes) < currentStepIndex) {
    return StepState.FINISHED;
  }

  return stepIndexes.includes(currentStepIndex)
    ? StepState.IN_PROGRESS
    : StepState.TODO;
};
