import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type AddAttachmentToQuoteMutationProps = {
  quoteId: Uuid;
  attachmentId: Uuid;
  name: string;
};

export const AddAttachmentToQuoteMutation = async ({
  quoteId,
  ...values
}: AddAttachmentToQuoteMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/quotes/${quoteId}/add-attachment`, {
    quoteId,
    ...values,
  });

export const useAddAttachmentToQuoteMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddAttachmentToQuoteMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  AddAttachmentToQuoteMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddAttachmentToQuoteMutationProps
  >({
    mutationFn: AddAttachmentToQuoteMutation,
    throwOnError: false,
    ...options,
  });
