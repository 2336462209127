import { ErrorResponse, Uuid } from '@bas/value-objects';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type DeleteEmailMutationProps = {
  emailId: Uuid;
};

export const DeleteEmailMutation = async ({
  emailId,
  ...params
}: DeleteEmailMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/communication/emails/${emailId}/delete`,
    {
      emailId,
      ...params,
    },
    { params },
  );

export const useDeleteEmailMutation = (): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  DeleteEmailMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    DeleteEmailMutationProps
  >({
    mutationFn: DeleteEmailMutation,
    throwOnError: false,
  });
