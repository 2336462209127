import { colors } from '@bas/theme';
import {
  TouchableWithoutFeedback,
  TouchableWithoutFeedbackProps,
  Typography,
} from '@bas/ui/native/base';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import {
  Children,
  cloneElement,
  isValidElement,
  ReactElement,
  ReactNode,
  useMemo,
} from 'react';
import { StyleSheet, View } from 'react-native';

export type BlueSelectButtonProps = Omit<
  TouchableWithoutFeedbackProps,
  'onPress' | 'children'
> & {
  notSelected: boolean;
  whiteInactive?: boolean;
  onSelect: () => void;
  icon?: IconProp;
  children: ReactNode;
  color?: string;
  height?: number;
};

const styles = StyleSheet.create({
  button: {
    flex: 1,
    backgroundColor: colors.blue[500],
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  childrenContainer: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    marginBottom: 5,
  },
  notSelected: {
    opacity: 0.2,
  },
  whiteInactive: {
    backgroundColor: colors.white,
    opacity: 1,
    borderWidth: 1,
    borderColor: colors.lila[300],
  },
  whiteActive: {
    backgroundColor: colors.blue[500],
  },
});

const BlueSelectButton = ({
  icon,
  notSelected,
  style,
  onSelect,
  children,
  color,
  whiteInactive,
  height,
  ...props
}: BlueSelectButtonProps): ReactElement => {
  const resultingColor = useMemo(
    () => color || (whiteInactive && notSelected ? undefined : colors.white),
    [color, notSelected, whiteInactive],
  );

  const childrenWithProps = useMemo(
    () =>
      Children.map(children, (child) => {
        if (isValidElement(child) && !child.props.color) {
          return cloneElement<{
            overrideColor?: string;
          }>(
            child as ReactElement<{
              overrideColor?: string;
            }>,
            { overrideColor: resultingColor },
          );
        }

        return child;
      }),
    [children, resultingColor],
  );

  return (
    <TouchableWithoutFeedback onPress={onSelect} {...props}>
      <View
        style={[
          styles.button,
          notSelected && styles.notSelected,
          whiteInactive && styles.whiteInactive,
          whiteInactive && !notSelected && styles.whiteActive,
          style,
          {
            height: height || 105,
            minHeight: height || 105,
            maxHeight: height || 105,
          },
        ]}
      >
        {icon && (
          <FontAwesomeIcon
            icon={icon}
            color={resultingColor}
            size={24}
            style={styles.icon}
          />
        )}
        {Children.count(children) === 1 && (
          <Typography
            variant="h5"
            fontWeight={700}
            overrideColor={resultingColor}
          >
            {childrenWithProps}
          </Typography>
        )}
        {Children.count(children) > 1 && (
          <View style={styles.childrenContainer}>{childrenWithProps}</View>
        )}
      </View>
    </TouchableWithoutFeedback>
  );
};

export default BlueSelectButton;
