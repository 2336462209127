/* eslint-disable no-underscore-dangle */
import { GiftedChatIMessage } from '@bas/shared/models';
import { colors } from '@bas/theme';
import { faFileDownload } from '@fortawesome/pro-light-svg-icons/faFileDownload';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import * as Linking from 'expo-linking';
import React, { useCallback } from 'react';
import { Alert, Platform, StyleSheet, TouchableOpacity } from 'react-native';
import { Bubble } from 'react-native-gifted-chat';
import MapView from './MapView';

const styles = StyleSheet.create({
  container: {
    height: 300,
    width: 300,
  },

  mapView: {
    flex: 1,
    borderRadius: 13,
    margin: 20,
    overflow: 'hidden',
  },
});

const GiftedChatCustomView = <
  TMessage extends GiftedChatIMessage = GiftedChatIMessage,
>({
  currentMessage,
  containerStyle,
  ...props
}: Bubble<TMessage>['props']) => {
  const openMapAsync = useCallback(async () => {
    if (Platform.OS === 'web') {
      // eslint-disable-next-line no-alert
      window.alert('Opening the map is not supported.');
      return;
    }

    if (!currentMessage?.location) {
      return;
    }
    const { location } = currentMessage;
    const url = Platform.select({
      ios: `http://maps.apple.com/?ll=${location.latitude},${location.longitude}`,
      default: `http://maps.google.com/?q=${location.latitude},${location.longitude}`,
    });

    try {
      const supported = await Linking.canOpenURL(url);
      if (supported) {
        Linking.openURL(url);
        return;
      }
      Alert.alert('Opening the map is not supported.');
    } catch (message) {
      Alert.alert(JSON.stringify(message));
    }
  }, [currentMessage]);

  if (!currentMessage) {
    return null;
  }

  if (
    currentMessage.location &&
    currentMessage.location.longitude &&
    currentMessage.location.latitude
  ) {
    return (
      <TouchableOpacity style={[styles.container]} onPress={openMapAsync}>
        <MapView currentMessage={currentMessage} />
      </TouchableOpacity>
    );
  }

  if (currentMessage.file) {
    return (
      <TouchableOpacity
        onPress={() => Linking.openURL(currentMessage.file || '')}
      >
        <FontAwesomeIcon
          icon={faFileDownload}
          size={32}
          color={colors.blue[500]}
        />
      </TouchableOpacity>
    );
  }
  return null;
};

export default GiftedChatCustomView;
