import { ActiveIntake } from '@bas/project-domain/models';
import { AddressBlock, EventIcon } from '@bas/ui/native/atoms';
import { Box, Icon, Typography } from '@bas/ui/native/base';
import { EventType } from '@bas/value-objects';
import { faTrashCan } from '@fortawesome/pro-light-svg-icons/faTrashCan';
import { ReactElement, useCallback } from 'react';
import { FormattedMessage, FormattedTime } from 'react-intl';
import { Platform, TouchableWithoutFeedback, View } from 'react-native';
import { Swipeable, TouchableOpacity } from 'react-native-gesture-handler';
import styles from './styles';

export type OpenTaxationListItemProps = {
  activeIntake: ActiveIntake;
  onPress?: () => void;
  onDelete?: () => void;
};

const DeleteUnderlay = ({
  onDelete,
}: {
  onDelete?: () => void;
}): ReactElement => (
  <TouchableWithoutFeedback onPress={onDelete}>
    <Box
      flex={1}
      backgroundColor="deleteBackground"
      justifyContent="center"
      alignItems="flex-end"
      style={styles.deleteUnderlay}
    >
      <Icon icon={faTrashCan} color="white" size={24} />
    </Box>
  </TouchableWithoutFeedback>
);

const OpenTaxationListItem = ({
  activeIntake,
  onDelete,
  onPress,
}: OpenTaxationListItemProps): ReactElement => {
  const handleRenderRightActions = useCallback(
    () => (
      <Box width={150}>
        <DeleteUnderlay onDelete={onDelete} />
      </Box>
    ),
    [onDelete],
  );

  return (
    <Box position="relative" style={styles.marginBottom}>
      <Swipeable
        renderRightActions={handleRenderRightActions}
        friction={2}
        enableTrackpadTwoFingerGesture
        leftThreshold={30}
        rightThreshold={40}
      >
        <TouchableOpacity
          onPress={onPress}
          delayPressIn={Platform.OS === 'web' ? 100 : undefined}
        >
          <View style={[styles.container]}>
            <View style={[styles.eventColor]} />
            <View style={styles.eventIconContainer}>
              <EventIcon eventType={EventType.INTAKE_EVENT} size={24} />
            </View>
            <View style={styles.eventInfoContainer}>
              <View style={styles.eventNameRow}>
                <Typography fontWeight={700} style={styles.eventName}>
                  {activeIntake.name || (
                    <FormattedMessage id="mobileApp.activeIntake.unknownCustomer" />
                  )}
                </Typography>
                <Typography style={styles.eventNameTime}>
                  <FormattedMessage
                    id="label.startedOn"
                    values={{
                      time: (
                        <FormattedTime
                          value={activeIntake.startedOn}
                          hour="2-digit"
                          minute="2-digit"
                        />
                      ),
                    }}
                  />
                </Typography>
              </View>
              <View style={styles.paddingTop2}>
                {activeIntake.location && (
                  <AddressBlock address={activeIntake.location} />
                )}
              </View>
            </View>
          </View>
        </TouchableOpacity>
      </Swipeable>
    </Box>
  );
};

export default OpenTaxationListItem;
