import { Typography } from '@bas/ui/native/base';
import { Country } from '@bas/value-objects';
import { ReactElement, useMemo } from 'react';
import { View } from 'react-native';
import styles from './styles';

export type LicensePlateProps = {
  licensePlate: string;
  country: Country;
};

const countryColors = {
  NL: {
    base: '#FFE662',
    color: '#000000',
    left: '#3A78F2',
    extraStyle: {},
  },
  BE: {
    base: '#FFFFFF',
    color: '#842328',
    left: '#3A78F2',
    extraStyle: {
      borderWidth: 1,
      borderColor: '#842328',
    },
  },
};

const LicensePlate = ({
  licensePlate,
  country,
}: LicensePlateProps): ReactElement => {
  const countryStyles = useMemo(() => {
    const countryColor = countryColors[country];
    if (!countryColor) {
      return {};
    }

    return {
      container: {
        backgroundColor: countryColor.base,
        ...(countryColor.extraStyle || {}),
      },
      country: {
        backgroundColor: countryColor.left,
      },
      text: {
        color: countryColor.color,
      },
    };
  }, [country]);

  return (
    <View style={[styles.container, countryStyles.container]}>
      <View style={[styles.countryPart, countryStyles.country]} />
      <Typography
        variant="body2"
        fontWeight={700}
        style={[styles.text, countryStyles.text]}
      >
        {licensePlate}
      </Typography>
    </View>
  );
};

export default LicensePlate;
