import { ListedEmployee } from '@bas/hrm-domain/models';
import { useEmployeesRequest } from '@bas/hrm-domain/requests';
import { Skeleton } from '@bas/ui/native/atoms';
import { BottomSheetMethods, Box, Typography } from '@bas/ui/native/base';
import { BottomSheet, BottomSheetProps } from '@bas/ui/native/organisms';
import { Uuid } from '@bas/value-objects';
import { Storage, StoredItem } from '@bas/wms-domain/models';
import {
  useStorageLocationByStorageLocationIdRequestQuery,
  useStoragesRequest,
  useStoredItemsRequest,
} from '@bas/wms-domain/requests';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { FlashList } from '@shopify/flash-list';
import { ListRenderItem } from '@shopify/flash-list/src/FlashListProps';
import dayjs from 'dayjs';
import * as React from 'react';
import { ReactElement, RefObject, useCallback, useMemo } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

export type ShowStorageLocationBottomSheetProps = Omit<
  BottomSheetProps,
  'snapPoints' | 'children'
> & {
  bottomSheetRef?: RefObject<BottomSheetModal & BottomSheetMethods>;
};

const ShowStorageLocationBottomSheetContent = ({
  storageLocationId,
}: {
  storageLocationId: Uuid;
}): ReactElement => {
  const { data: storageLocationData } =
    useStorageLocationByStorageLocationIdRequestQuery({
      storageLocationId,
    });

  const storageLocation = useMemo(() => {
    if (storageLocationData?.data === undefined) {
      return null;
    }

    return storageLocationData?.data;
  }, [storageLocationData?.data]);

  const {
    data: storedItemsData,
    isLoading,
    isFetching,
    refetch,
  } = useStoredItemsRequest({
    storageLocationId,
    loadedOnly: true,
  });

  const storedItems = useMemo(
    () => storedItemsData?.data?.member || [],
    [storedItemsData?.data],
  );

  const {
    data: storagesData,
    isPending: isLoadingStorages,
    isFetching: isFetchingStorages,
    refetch: refetchStorages,
  } = useStoragesRequest({
    storageLocationId,
    perPage: 9999,
  });

  const { data: employeesData } = useEmployeesRequest({
    perPage: 9999,
    activeEmploymentOnly: false,
  });

  const employees = useMemo(
    () => employeesData?.data?.member || [],
    [employeesData],
  );

  const loadedItems = useMemo(
    () => storedItems.filter((s) => !!s.loadedOn),
    [storedItems],
  );

  const storagesOnLocation = useMemo(
    () => storagesData?.data?.member || [],
    [storagesData],
  );

  const handleRenderStoredItem: ListRenderItem<StoredItem> = useCallback(
    ({ item, extraData }) => {
      const employee = extraData.employees.find(
        (e: ListedEmployee) => e.employeeId === item.loadedByEmployeeId,
      );
      return (
        <Box
          flexDirection="row"
          flexWrap="nowrap"
          gap={2}
          width="100%"
          alignItems="center"
          paddingVertical={1}
          borderBottomWidth={1}
          borderBottomColor="transparentBorder"
        >
          <Box flex={0.4}>
            <Typography color="white">{`${item.description} ${item.externalItemCode} ${item.itemCode}`}</Typography>
          </Box>
          <Box flex={0.4}>
            {item.loadedByEmployeeId && (
              <Typography color="white">
                {employee?.personName?.fullName}
              </Typography>
            )}
            {item.loadedByCustomer && (
              <Typography color="white">
                <FormattedMessage id="label.loadedByCustomer" />
              </Typography>
            )}
            {!item.loadedByEmployeeId && !item.loadedByCustomer && (
              <Typography color="white">
                <FormattedMessage id="label.loadedByUnknown" />
              </Typography>
            )}
          </Box>
          <Box flex={0.2} minWidth={40}>
            {item.loadedOn && (
              <Typography color="white">
                <FormattedDate
                  value={dayjs(item.loadedOn).toDate()}
                  dateStyle="short"
                  timeStyle="short"
                />
              </Typography>
            )}
          </Box>
        </Box>
      );
    },
    [],
  );

  const handleRenderStorageItem: ListRenderItem<Storage> = useCallback(
    ({ item }) => (
      <Box
        flexDirection="row"
        flexWrap="nowrap"
        gap={2}
        width="100%"
        alignItems="center"
        paddingVertical={1}
        borderBottomWidth={1}
        borderBottomColor="transparentBorder"
      >
        <Box flex={0.3}>
          <Typography color="white">{item.storageCode}</Typography>
        </Box>
        <Box flex={0.3}>
          <Typography color="white">
            {item.contentOwnerName || <FormattedMessage id="label.notLoaded" />}
          </Typography>
        </Box>
        <Box flex={0.2}>
          <Typography color="white">test</Typography>
        </Box>
        <Box flex={0.2} minWidth={70}>
          {item.loadedOn && (
            <Typography color="white">
              <FormattedDate
                value={dayjs(item.loadedOn).toDate()}
                dateStyle="short"
                timeStyle="short"
              />
            </Typography>
          )}
        </Box>
      </Box>
    ),
    [],
  );

  return (
    <Box flex={1} paddingHorizontal={20} gap={3}>
      <Box flexDirection="row" flexWrap="wrap">
        <Box width="100%" pb={3}>
          <Typography
            color="white"
            variant="h4"
            fontWeight={700}
            textAlign="center"
          >
            {`${storageLocation?.name} (${storageLocation?.code})`}
          </Typography>
          <Typography variant="h5" textAlign="center" color="white">
            {storageLocation?.warehouseName}
          </Typography>
        </Box>
      </Box>
      <Box flex={1} width="100%">
        <Typography variant="subtitle1" color="white">
          {storagesOnLocation.length} <FormattedMessage id="label.storage" />
        </Typography>
        {isLoadingStorages && <Skeleton width={500} height={300} light />}
        {!isLoadingStorages && (
          <FlashList
            refreshing={isFetchingStorages}
            onRefresh={refetchStorages}
            renderItem={handleRenderStorageItem}
            data={storagesOnLocation}
            keyExtractor={(item) => item.storageId}
            estimatedItemSize={61}
          />
        )}
      </Box>
      <Box flex={1} width="100%">
        <Typography variant="subtitle1" color="white">
          {loadedItems.length} <FormattedMessage id="label.storedItems" />
        </Typography>
        {isLoading && <Skeleton width={500} height={300} light />}
        {!isLoading && (
          <FlashList
            refreshing={isFetching}
            onRefresh={refetch}
            renderItem={handleRenderStoredItem}
            data={loadedItems}
            extraData={{ employees }}
            keyExtractor={(item) => item.itemId}
            estimatedItemSize={61}
          />
        )}
      </Box>
    </Box>
  );
};

const ShowStorageLocationBottomSheet = ({
  bottomSheetRef,
  ...props
}: ShowStorageLocationBottomSheetProps): ReactElement => {
  const onClose = useCallback(() => {
    bottomSheetRef?.current?.close();
    bottomSheetRef?.current?.forceClose();
    bottomSheetRef?.current?.dismiss();
  }, [bottomSheetRef]);

  const handleRender = useCallback(
    (incomingData: { data?: { locationId: Uuid } } | undefined) => {
      const locationId = incomingData?.data?.locationId;
      return (
        <ShowStorageLocationBottomSheetContent
          storageLocationId={locationId || ''}
        />
      );
    },
    [],
  );

  return (
    <BottomSheet
      ref={bottomSheetRef}
      disablePadding
      enableDismissOnClose
      onDismiss={onClose}
      {...props}
    >
      {handleRender}
    </BottomSheet>
  );
};

export default ShowStorageLocationBottomSheet;
