import { formatDate } from '@bas/shared/utils';
import { ErrorResponse, Money, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type AddDeclarationMutationProps = {
  declarationId: Uuid;
  employeeId: Uuid;
  declarationDate: Date;
  amount: Money;
  categoryId: Uuid;
  receiptFileId?: Uuid;
  description: string;
  eventId?: Uuid;
  projectId?: Uuid;
  relationId?: Uuid;
};

export const AddDeclarationMutation = async ({
  employeeId,
  declarationDate,
  ...values
}: AddDeclarationMutationProps): Promise<AxiosResponse> =>
  axios.post(
    `api/{tenantId}/hrm/employees/${employeeId}/declarations/request`,
    {
      employeeId,
      declarationDate: formatDate(declarationDate),
      ...values,
    },
  );

export const useAddDeclarationMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddDeclarationMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  AddDeclarationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddDeclarationMutationProps
  >({
    mutationFn: AddDeclarationMutation,
    throwOnError: false,
    ...options,
  });
