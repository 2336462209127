import { BillingMoment } from '@bas/shared/models';
import { PricingType, Uuid } from '@bas/value-objects';
import * as Yup from 'yup';

export type PricingTypeFormStepInputType = {
  pricingType: PricingType;
  packageId: Uuid;
  identityId?: Uuid | null;
  billingMoments: BillingMoment[];
  ignoreZeroQuantityLines: boolean;
};

export const PricingTypeFormStepInputTypeValidatorBuilder =
  (): Yup.ObjectSchema<Omit<PricingTypeFormStepInputType, 'billingMoments'>> =>
    Yup.object({
      pricingType: Yup.mixed<PricingType>()
        .label('label.pricingType')
        .oneOf(Object.values(PricingType))
        .required(),
      packageId: Yup.string().label('label.packageId').required(),
      identityId: Yup.string().label('label.identityId').nullable(),
      ignoreZeroQuantityLines: Yup.boolean()
        .label('label.ignoreZeroQuantityLines')
        .required(),
    });
