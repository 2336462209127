import { useAppStore, useTenantStore } from '@bas/shared/state';
import { TenantByHostnameRequest } from '@bas/tenant-domain/requests';
import { useSetEnvironment } from '@bas/ui/native/hooks';
import { LoadingScreen } from '@bas/ui/native/templates';
import { captureException } from '@sentry/react-native';
import axios from 'axios';
import { ComponentType, ReactElement, useEffect, useState } from 'react';
import { Alert, Platform } from 'react-native';

const SetApiUrl = ({
  component,
}: {
  component: ComponentType;
}): ReactElement | null => {
  const [didSetApiUrl, setDidSetApiUrl] = useState<boolean>(false);
  const [didProcessEnvironment, setDidProcessEnvironment] = useState<boolean>(
    Platform.OS !== 'web',
  );
  const appState = useAppStore();
  const tenantState = useTenantStore((state) => state.tenant);
  const setTenantState = useTenantStore((state) => state.setTenant);
  const resetTenant = useTenantStore((state) => state.resetTenant);

  const setEnvironment = useSetEnvironment();
  useEffect(() => {
    if (Platform.OS === 'web') {
      setEnvironment(window.location.hostname.replace('.bas.app', ''));
      setDidProcessEnvironment(true);
    }
  }, [setEnvironment]);

  useEffect(() => {
    (async () => {
      if (!appState.initialized) {
        return;
      }

      if (appState.apiUrl) {
        axios.defaults.baseURL = appState.apiUrl;
      }

      if (Platform.OS === 'web' && !appState.appUrl) {
        if (!appState.apiUrl && !didProcessEnvironment) {
          return;
        }

        if (!appState.apiUrl) {
          axios.defaults.baseURL = process.env.EXPO_PUBLIC_API_URL;
        }

        try {
          const { hostname } = window.location;

          const { data } = await TenantByHostnameRequest({
            hostname,
          });

          if (data.tenantId) {
            setTenantState(data);
          }
        } catch (e) {
          if (Platform.OS === 'web') {
            // eslint-disable-next-line no-alert
            alert('Could not find tenant');
          } else {
            Alert.alert(`Could not find tenant: ${e}`);
          }
        }
      } else if (!tenantState?.tenantId && appState.appUrl) {
        try {
          const { data } = await TenantByHostnameRequest({
            hostname: appState.appUrl,
          });

          if (data.tenantId) {
            setTenantState(data);
          }
        } catch (e) {
          captureException(e);
          if (Platform.OS === 'web') {
            // eslint-disable-next-line no-alert
            alert('Could not find tenant');
          } else {
            Alert.alert(`Could not find tenant: ${e}`);
          }
          resetTenant();
        }
      }

      setDidSetApiUrl(true);
    })();
  }, [
    appState.apiUrl,
    appState.appUrl,
    appState.initialized,
    didProcessEnvironment,
    resetTenant,
    setTenantState,
    tenantState?.tenantId,
  ]);

  const Component = component;

  if (didSetApiUrl && !appState.apiUrl && !appState.appUrl) {
    return <Component />;
  }

  if (
    !didSetApiUrl ||
    (appState.apiUrl && axios.defaults.baseURL !== appState.apiUrl) ||
    !axios.defaults.baseURL
  ) {
    return <LoadingScreen />;
  }

  return <Component />;
};

export default SetApiUrl;
