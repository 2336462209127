import {
  AddressInputType,
  addressInputTypeDefaultValues,
  addressInputTypeValidationBuilder,
} from '@bas/shared/input-types';
import { EmailAddress, PhoneNumber } from '@bas/value-objects';
import * as Yup from 'yup';

export interface SupplierInputType {
  name: string;
  website?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName: string;
  emailAddress?: EmailAddress | null;
  phoneNumber?: PhoneNumber | null;
  mobileNumber?: PhoneNumber | null;
  address: AddressInputType;
}

export const SupplierInputTypeDefaultValues = (): SupplierInputType => ({
  name: '',
  website: '',
  firstName: '',
  middleName: '',
  lastName: '',
  emailAddress: '',
  phoneNumber: '',
  mobileNumber: '',
  address: addressInputTypeDefaultValues(),
});

export const SupplierInputTypeValidationBuilder =
  (): Yup.ObjectSchema<SupplierInputType> =>
    Yup.object({
      name: Yup.string().max(200).required().label('label.name'),
      website: Yup.string().url().nullable().label('label.website'),
      firstName: Yup.string().nullable().label('label.firstName'),
      middleName: Yup.string().nullable().label('label.middleName'),
      lastName: Yup.string().required().label('label.lastName'),
      emailAddress: Yup.string().email().nullable().label('label.emailAddress'),
      phoneNumber: Yup.string().phone().nullable().label('label.phoneNumber'),
      mobileNumber: Yup.string().phone().nullable().label('label.mobileNumber'),
      address: addressInputTypeValidationBuilder(),
    });
