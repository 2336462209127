import { createContext, useContext } from 'react';

export type ReactHookFormSubmitContext = {
  onSubmit: () => void | Promise<void>;
};

export const FormSubmitContext = createContext<ReactHookFormSubmitContext>(
  {} as ReactHookFormSubmitContext,
);

export const useFormSubmitState = () => useContext(FormSubmitContext);
