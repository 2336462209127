import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  paddingTop: {
    paddingTop: 24,
  },
  title: {
    textAlign: 'center',
    marginBottom: 32,
  },
});
