import {
  EmailAddress,
  ErrorResponse,
  Language,
  Uuid,
} from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type UpdateUserMutationProps = {
  userId: Uuid;
  firstName?: string | null;
  middleName?: string | null;
  lastName: string;
  emailAddress: EmailAddress;
  language: Language;
};

export const UpdateUserMutation = async ({
  userId,
  ...values
}: UpdateUserMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/users/${userId}`, {
    userId,
    ...values,
  });

export const useUpdateUserMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateUserMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  UpdateUserMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateUserMutationProps
  >({
    mutationFn: UpdateUserMutation,
    throwOnError: false,
    ...options,
  });
