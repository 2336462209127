import { MovingJobAddressType } from '@bas/project-domain/models';
import {
  CustomerDetailsFormStepValidatorBuilder,
  PricingTypeFormStepInputTypeValidatorBuilder,
  QuoteInputTypeValidatorBuilder,
  RoomWizardInputTypeValidatorBuilder,
  SendQuoteStepInputTypeValidatorBuilder,
  SignQuoteWithCustomerStepInputTypeValidatorBuilder,
  TaxationMovingJobAddressesInputTypeValidatorBuilder,
  WhatInventoryItemsAreNeededStepInputTypeValidatorBuilder,
} from '@bas/taxation-tool-domain/input-types';
import { MovingJobTaxationWizardInput } from '@bas/taxation-tool-domain/models';
import {
  AllMovingAddressesFormStep,
  ConfirmCustomerDetailsStep,
  ConfirmPrimaryAddressesStep,
  CreateMovingJobQuoteStep,
  CustomerDetailsFormStep,
  CustomerNotesFormStep,
  MovingJobEstimationStep,
  NotesFormStep,
  OpenRoomSpecificFlowsStep,
  PricingTypeFormStep,
  PrimaryAddressFormStep,
  SendMovingJobQuoteStep,
  SignQuoteWithCustomerStep,
  WhatInventoryItemsAreNeededStep,
  WhatServicesAreNeededStep,
  WhichRoomsAreGoingToBeMovedStep,
} from '@bas/taxation-tool-domain/native/organisms';
import { ReactHookWizardStep } from '@bas/value-objects';
import { memo } from 'react';

export const movingJobTaxationWizardSteps =
  (): ReactHookWizardStep<MovingJobTaxationWizardInput>[] => [
    {
      key: 'address.primary.from',
      category: 'label.addresses',
      component: memo(
        () => (
          <PrimaryAddressFormStep addressType={MovingJobAddressType.FROM} />
        ),
        () => true,
      ),
      validationSchema: TaxationMovingJobAddressesInputTypeValidatorBuilder({
        primary: true,
        addressType: MovingJobAddressType.FROM,
      }),
    },
    {
      key: 'address.primary.to',
      category: 'label.addresses',
      component: memo(
        () => <PrimaryAddressFormStep addressType={MovingJobAddressType.TO} />,
        () => true,
      ),
      validationSchema: TaxationMovingJobAddressesInputTypeValidatorBuilder({
        primary: true,
        addressType: MovingJobAddressType.TO,
      }),
    },
    {
      key: 'address.primary.confirm',
      category: 'label.addresses',
      component: ConfirmPrimaryAddressesStep,
    },
    {
      key: 'services',
      category: 'label.question',
      component: WhatServicesAreNeededStep,
    },
    {
      key: 'whichRoomsAreGoingToBeMoved',
      category: 'label.question',
      component: WhichRoomsAreGoingToBeMovedStep,
    },
    {
      key: 'openRoomSpecificFlows',
      category: 'label.question',
      component: OpenRoomSpecificFlowsStep,
      validationSchema: RoomWizardInputTypeValidatorBuilder,
    },
    {
      key: 'inventoryItems',
      category: 'label.question',
      component: WhatInventoryItemsAreNeededStep,
      validationSchema:
        WhatInventoryItemsAreNeededStepInputTypeValidatorBuilder,
    },
    {
      key: 'customer',
      category: 'label.customer',
      component: CustomerDetailsFormStep,
      validationSchema: CustomerDetailsFormStepValidatorBuilder,
    },
    {
      key: 'confirmCustomerDetails',
      category: 'label.customer',
      component: ConfirmCustomerDetailsStep,
    },
    {
      key: 'notes',
      category: 'label.notes',
      component: NotesFormStep,
    },
    {
      key: 'customerNotes',
      category: 'label.notes',
      component: CustomerNotesFormStep,
    },
    {
      key: 'allMovingAddresses',
      category: 'label.extraData',
      component: AllMovingAddressesFormStep,
    },
    {
      key: 'pricingType',
      category: 'label.quote',
      component: PricingTypeFormStep,
      validationSchema: PricingTypeFormStepInputTypeValidatorBuilder,
    },
    {
      key: 'estimate',
      category: 'label.quote',
      component: MovingJobEstimationStep,
    },
    {
      key: 'quote',
      category: 'label.quote',
      component: CreateMovingJobQuoteStep,
      validationSchema: QuoteInputTypeValidatorBuilder,
    },
    {
      key: 'sendQuote',
      category: 'label.quote',
      component: SendMovingJobQuoteStep,
      validationSchema: SendQuoteStepInputTypeValidatorBuilder,
    },
    {
      key: 'signQuoteWithCustomer',
      category: 'label.quote',
      component: SignQuoteWithCustomerStep,
      enabled: (values) => !!values.customerSignsAtTheTable,
      validationSchema: SignQuoteWithCustomerStepInputTypeValidatorBuilder,
    },
  ];
