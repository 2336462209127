import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    borderRadius: 5,
    borderWidth: 1,
  },
  address: {},
  addressIcon: {
    minWidth: 36,
  },
});
