import * as Yup from 'yup';

export type ForgotPasswordInputType = {
  appUrl: string;
  emailAddress: string;
};

export const ForgotPasswordInputTypeDefaultValues =
  (): ForgotPasswordInputType => ({
    appUrl: '',
    emailAddress: '',
  });

export const ForgotPasswordInputTypeValidationBuilder =
  (): Yup.ObjectSchema<ForgotPasswordInputType> =>
    Yup.object({
      appUrl: Yup.string().required().label('label.appUrl'),
      emailAddress: Yup.string().email().required().label('label.emailAddress'),
    });
