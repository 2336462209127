import { BoxProps } from '@bas/ui/native/base';
import * as Progress from 'react-native-progress';

type ProgressBarProps = BoxProps & {
  fullWidth?: boolean;
  progress: number;
  progressColor?: string;
};

const ProgressBar = ({
  fullWidth,
  progress,
  progressColor,
  ...props
}: ProgressBarProps) => (
  <Progress.Bar
    progress={progress / 100}
    color={progressColor}
    width={fullWidth ? null : undefined}
    height={10}
  />
);

export default ProgressBar;
