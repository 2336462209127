import { CommunicationType } from '@bas/crm-domain/models';
import * as Yup from 'yup';

export interface AddCommunicationInputType {
  communicationType: CommunicationType;
  communicationDate: Date | null;
  sender: string;
  message: string;
}

export const addCommunicationValidationBuilder =
  (): Yup.ObjectSchema<AddCommunicationInputType> =>
    Yup.object({
      communicationType: Yup.mixed<CommunicationType>()
        .oneOf(Object.values(CommunicationType))
        .required()
        .label('label.communicationType'),
      communicationDate: Yup.date().label('label.communicationDate').required(),
      sender: Yup.string().label('label.sender').required(),
      message: Yup.string().label('label.message').required(),
    });

export const addCommunicationDefaultValues = (): AddCommunicationInputType => ({
  communicationType: CommunicationType.EMAIL,
  communicationDate: new Date(),
  sender: '',
  message: '',
});
