import { formatHours } from '@bas/shared/utils';
import { Box, Typography } from '@bas/ui/native/base';
import { WorkedHoursSpecification } from '@bas/value-objects';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

export type WorkedHoursSpecificationSummaryProps = {
  workedHoursSpecification: WorkedHoursSpecification;
};

const WorkedHoursSpecificationSummary = ({
  workedHoursSpecification,
}: WorkedHoursSpecificationSummaryProps): ReactElement => (
  <Box flex={1} flexDirection="column" flexWrap="wrap" maxWidth={300}>
    <Box flexDirection="row">
      <Box flexBasis="66.6666666667%" flex={1}>
        <Typography color="white">
          <FormattedMessage id="label.workedHoursIncludingBreakTime" />
        </Typography>
      </Box>
      <Box flexBasis="33.3333333333%" flex={1}>
        <Typography color="white">
          {`+ ${formatHours(
            workedHoursSpecification.workedHoursIncludingBreakTime,
          )}`}
        </Typography>
      </Box>
    </Box>
    {workedHoursSpecification.workedHoursIncludingBreakTime !==
      workedHoursSpecification.workedHours && (
      <Box flexDirection="row">
        <Box flexBasis="66.6666666667%" flex={1}>
          <Typography color="white">
            <FormattedMessage id="label.breakTime" />
          </Typography>
        </Box>
        <Box flexBasis="33.3333333333%" flex={1}>
          <Typography color="white">
            {`- ${formatHours(workedHoursSpecification.breakTime)}`}
          </Typography>
        </Box>
      </Box>
    )}
    {workedHoursSpecification.travelTime > 0 && (
      <Box flexDirection="row">
        <Box flexBasis="66.6666666667%" flex={1}>
          <Typography color="white">
            <FormattedMessage id="label.travelTime" />
          </Typography>
        </Box>
        <Box flexBasis="33.3333333333%" flex={1}>
          <Typography color="white">
            {`+ ${formatHours(workedHoursSpecification.travelTime)}`}
          </Typography>
        </Box>
      </Box>
    )}
    <Box flexDirection="row">
      <Box flexBasis="66.6666666667%" flex={1}>
        <Typography color="white" fontWeight={700}>
          <FormattedMessage id="label.totalHours" />
        </Typography>
      </Box>
      <Box flexBasis="33.3333333333%" flex={1}>
        <Typography color="white" fontWeight={700}>
          {`= ${formatHours(
            workedHoursSpecification.workedHours +
              workedHoursSpecification.travelTime,
          )}`}
        </Typography>
      </Box>
    </Box>
  </Box>
);

export default WorkedHoursSpecificationSummary;
