import {
  MovingJobAddress,
  MovingJobAddressType,
} from '@bas/project-domain/models';
import { TaxationMovingJobAddressesInputType } from '@bas/taxation-tool-domain/input-types';
import { MobileTaxationStepTemplate } from '@bas/taxation-tool-domain/native/templates';
import { BottomSheetMethods, Box, Typography } from '@bas/ui/native/base';
import { RectangularAddTile } from '@bas/ui/native/molecules';
import { ConfirmAddressBlock } from '@bas/ui/native/organisms';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { ReactElement, useCallback, useRef, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { v7 } from 'uuid';
import { AddExtraAddressBottomSheet } from '../AddExtraAddressBottomSheet';

const AllMovingAddressesFormStep = (): ReactElement => {
  const addExtraAddressBottomSheetRef = useRef<
    BottomSheetModal & BottomSheetMethods
  >(null);
  const [addressType, setAddressType] = useState<MovingJobAddressType>();

  const {
    append,
    remove,
    fields: addresses,
  } = useFieldArray<TaxationMovingJobAddressesInputType, 'addresses'>({
    name: 'addresses',
  } as never);

  const { formatMessage } = useIntl();

  const handleAddAddress = useCallback(
    (address: MovingJobAddress) => {
      append({
        ...address,
        addressId: v7(),
        addressType: addressType || MovingJobAddressType.TO,
      });
      addExtraAddressBottomSheetRef.current?.close();
    },
    [addressType, append],
  );

  const handleRemoveAddress = useCallback(
    (address: MovingJobAddress & { id: string }) => {
      const index = addresses.indexOf(address);
      remove(index);
    },
    [addresses, remove],
  );

  return (
    <MobileTaxationStepTemplate
      primary={
        <FormattedMessage id="mobileApp.intake.movingJob.areThereMoreAddresses" />
      }
      secondary={<FormattedMessage id="label.addresses" />}
    >
      <Box mb={3}>
        {Object.values(MovingJobAddressType).map((type) => {
          const addressTypeAddresses = (addresses || []).filter(
            (address) => address.addressType === type,
          );

          return (
            <Box mt={3} key={type}>
              <Typography variant="h5" fontWeight={700} mb={1}>
                <FormattedMessage
                  id={`project.moving-job.addressType.${type}`}
                />
              </Typography>
              <Box
                style={{
                  marginTop: -16,
                }}
              >
                {addressTypeAddresses.map((address) => (
                  <Box pt={2} key={address.id}>
                    <ConfirmAddressBlock
                      address={address}
                      removeAddress={
                        address.primary
                          ? undefined
                          : () => handleRemoveAddress(address)
                      }
                    />
                  </Box>
                ))}
                {!addressTypeAddresses.find(
                  ({ unknownAddress }) => unknownAddress,
                ) && (
                  <Box pt={2}>
                    <RectangularAddTile
                      onPress={() => {
                        setAddressType(type);
                        addExtraAddressBottomSheetRef.current?.present();
                      }}
                      label={formatMessage({
                        id: 'mobileApp.intake.movingJob.addExtraAddress',
                      })}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          );
        })}
      </Box>
      <AddExtraAddressBottomSheet
        bottomSheetRef={addExtraAddressBottomSheetRef}
        onAddAddress={handleAddAddress}
      />
    </MobileTaxationStepTemplate>
  );
};

export default AllMovingAddressesFormStep;
