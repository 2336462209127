export const SpecialLinksByAutomatedEmailType: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
} = {
  new_employee: [
    {
      name: 'Create password link',
      href: '{{ frontendUrl }}/create-password/{{ linkCode }}',
      target: '_target',
    },
  ],
  password_reset_request: [
    {
      name: 'Reset password link',
      href: '{{ frontendUrl }}/reset-password/{{ linkCode }}',
      target: '_target',
    },
  ],
  invoice: [
    {
      name: 'Betaallink',
      href: '{{ paymentRequest.redirectUrl }}',
      target: '_blank',
    },
  ],
  second_reminder_invoice: [
    {
      name: 'Betaallink',
      href: '{{ paymentRequest.redirectUrl }}',
      target: '_blank',
    },
  ],
  reminder_invoice: [
    {
      name: 'Betaallink',
      href: '{{ paymentRequest.redirectUrl }}',
      target: '_blank',
    },
  ],
  last_reminder_invoice: [
    {
      name: 'Betaallink',
      href: '{{ paymentRequest.redirectUrl }}',
      target: '_blank',
    },
  ],
  received_payment: [],
  quote: [
    {
      name: 'Acceptatie link',
      href: '{{ frontendUrl }}/financial/quotes/accept/{{ code }}',
      target: '_blank',
    },
  ],
  reminder_quote: [
    {
      name: 'Acceptatie link',
      href: '{{ frontendUrl }}/financial/quotes/accept/{{ code }}',
      target: '_blank',
    },
  ],
  intake_appointment_confirmation: [],
  deliver_boxes_appointment_confirmation: [],
  pickup_boxes_appointment_confirmation: [],
  planned_moving_appointment_confirmation: [],
  moving_date_is_approaching: [],
};
