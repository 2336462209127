import * as Yup from 'yup';
import { PricingType, Uuid } from '@bas/value-objects';

export interface ProjectPricingInputType {
  packageId: Uuid;
  pricingType: PricingType;
}

export const ProjectPricingInputTypeDefaultValues =
  (): ProjectPricingInputType => ({
    pricingType: PricingType.UNKNOWN,
    packageId: '',
  });

export const ProjectPricingInputTypeValidationBuilder =
  (): Yup.ObjectSchema<ProjectPricingInputType> =>
    Yup.object({
      packageId: Yup.string().required().label('label.packageId'),
      pricingType: Yup.mixed<PricingType>()
        .oneOf(Object.values(PricingType))
        .required()
        .label('label.pricingType'),
    });
