import { MediaObject } from '@bas/media-domain/models';
import { Money, Uuid } from '@bas/value-objects';
import * as Yup from 'yup';

export type ChangeDeclarationInputType = {
  declarationId: Uuid;
  amount: Money;
  receipt?: MediaObject;
  categoryId: Uuid;
  description: string;
  declarationDate?: Date | null;
  eventId?: Uuid | null;
  project?: {
    projectId?: Uuid | null;
  };
  relation?: {
    relationId?: Uuid | null;
  };
};

export const changeDeclarationInputTypeDefaultValues =
  (): ChangeDeclarationInputType => ({
    declarationId: '',
    amount: {
      amount: 0,
      currency: 'EUR',
    },
    receipt: undefined,
    categoryId: '',
    description: '',
    declarationDate: null,
    eventId: '',
    project: {
      projectId: '',
    },
    relation: {
      relationId: '',
    },
  });

export const changeDeclarationInputTypeValidationBuilder =
  (): Yup.ObjectSchema<ChangeDeclarationInputType> =>
    Yup.object({
      declarationId: Yup.string().required().label('label.declarationId'),
      description: Yup.string().required().label('label.description'),
      categoryId: Yup.string().required().label('label.category'),
      declarationDate: Yup.date().required().label('label.declarationDate'),
      amount: Yup.object({
        amount: Yup.number()
          .transform((value, original) =>
            original === '' || original === null ? null : value,
          )
          .required()
          .label('label.amount'),
        currency: Yup.string().required(),
      }).required(),
      eventId: Yup.string().optional().nullable().label('label.eventId'),
      project: Yup.object({
        projectId: Yup.string().optional().nullable().label('label.project'),
      }),
      relation: Yup.object({
        relationId: Yup.string().optional().nullable().label('label.relation'),
      }),
      receipt: Yup.object({
        mediaObjectId: Yup.string().nullable().label('label.receipt'),
      }) as Yup.ObjectSchema<MediaObject>,
    });
