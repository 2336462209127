import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ConnectToInvoiceToProjectMutationProps = {
  invoiceId: Uuid;
  projectId?: Uuid | null;
};

export const ConnectToInvoiceToProjectMutation = async ({
  invoiceId,
  ...values
}: ConnectToInvoiceToProjectMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/invoices/${invoiceId}/connect-to-project`, {
    invoiceId,
    ...values,
  });

export const useConnectToInvoiceToProjectMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ConnectToInvoiceToProjectMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ConnectToInvoiceToProjectMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ConnectToInvoiceToProjectMutationProps
  >({
    mutationFn: ConnectToInvoiceToProjectMutation,
    throwOnError: false,
    ...options,
  });
