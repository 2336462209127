import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type RemoveContactPersonFromRelationMutationProps = {
  relationId: Uuid;
  contactPersonId: Uuid;
};

export const RemoveContactPersonFromRelationMutation = async ({
  relationId,
  contactPersonId,
  ...values
}: RemoveContactPersonFromRelationMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/relations/${relationId}/contact-persons/${contactPersonId}/delete`,
    {
      relationId,
      contactPersonId,
      ...values,
    },
  );

export const useRemoveContactPersonFromRelationMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveContactPersonFromRelationMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  RemoveContactPersonFromRelationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveContactPersonFromRelationMutationProps
  >({
    mutationFn: RemoveContactPersonFromRelationMutation,
    throwOnError: false,
    ...options,
  });
