import { PublicServiceType } from '@bas/shared/models';
import { WhatServicesAreNeededStepInputType } from '@bas/taxation-tool-domain/input-types';
import { MobileTaxationStepTemplate } from '@bas/taxation-tool-domain/native/templates';
import { usePublicServiceTypesRequest } from '@bas/tenant-domain/requests';
import {
  InternalServiceTypeIcon,
  RenderTranslatedName,
} from '@bas/ui/native/atoms';
import { Box } from '@bas/ui/native/base';
import { SquareIconTile } from '@bas/ui/native/molecules';
import { SquareTilesList } from '@bas/ui/native/organisms';
import { ListRenderItemInfo } from '@shopify/flash-list';
import { memo, ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

const WhatServicesAreNeededStep = (): ReactElement => {
  const { data, isLoading } = usePublicServiceTypesRequest();
  const services = useWatch<WhatServicesAreNeededStepInputType, 'services'>({
    name: 'services',
  });

  const { setValue } = useFormContext<WhatServicesAreNeededStepInputType>();

  const serviceTypes = useMemo(() => data?.data.member || [], [data]);

  useEffect(() => {
    if (serviceTypes.length > services.length) {
      setValue(
        'services',
        serviceTypes.map((serviceType) => {
          const existingService = services.find(
            (service) => service.serviceTypeId === serviceType.serviceTypeId,
          );
          return {
            ...serviceType,
            ...(existingService || {}),
            selected: existingService?.selected || false,
          };
        }),
      );
    }
  }, [serviceTypes, services, setValue]);

  const handleRenderItem = useCallback(
    ({
      item: service,
      index,
    }: ListRenderItemInfo<
      PublicServiceType & {
        selected: boolean;
      }
    >) => (
      <SquareIconTile
        width="100%"
        key={service.serviceTypeId}
        selected={service.selected}
        onPress={() => {
          setValue(`services.${index}.selected`, !service.selected);
        }}
        label={
          <RenderTranslatedName
            fontWeight={700}
            translations={service.translatedNames}
            fallback={service.serviceTypeName}
          />
        }
        icon={
          <InternalServiceTypeIcon
            size={61}
            serviceType={service.internalType}
          />
        }
      />
    ),
    [setValue],
  );

  return (
    <MobileTaxationStepTemplate
      primary={
        <FormattedMessage id="mobileApp.intake.movingJob.whichServicesShouldBeDone" />
      }
      secondary={<FormattedMessage id="label.services" />}
      disableScroll
      disableContentPadding
    >
      <Box
        flex={1}
        style={{
          marginLeft: -19,
        }}
      >
        <SquareTilesList<
          PublicServiceType & {
            selected: boolean;
          }
        >
          renderItem={handleRenderItem}
          data={services}
          isLoading={isLoading || services.length !== serviceTypes.length}
        />
      </Box>
    </MobileTaxationStepTemplate>
  );
};

const MemoComponent = memo(WhatServicesAreNeededStep, () => true);
export default MemoComponent;
