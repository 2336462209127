import { useFormAddressAutoComplete } from '@bas/shared/hooks';
import { getCountryOptions } from '@bas/shared/utils';
import { TaxationMovingJobAddressesInputType } from '@bas/taxation-tool-domain/input-types';
import { Box, Typography } from '@bas/ui/native/base';
import {
  ReactHookFormDropdown,
  ReactHookFormNumberTextField,
  ReactHookFormSelectButtons,
  ReactHookFormTextField,
} from '@bas/ui/native/molecules';
import { BuildingType } from '@bas/value-objects';
import { FlashList, ListRenderItemInfo } from '@shopify/flash-list';
import { useResponsiveProp } from '@shopify/restyle';
import * as React from 'react';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

export type TaxationToolAddressFormProps = {
  addressIndex: number;
  showUnknownAddress?: boolean;
  light?: boolean;
};

const TaxationToolAddressForm = ({
  addressIndex,
  showUnknownAddress,
  light,
}: TaxationToolAddressFormProps): ReactElement => {
  const { formatMessage } = useIntl();
  const [validAddress, setValidAddress] = useState<boolean>(true);
  const [unknownAddress, hasElevator] =
    useWatch<TaxationMovingJobAddressesInputType>({
      name: [
        `addresses.${addressIndex}.unknownAddress`,
        `addresses.${addressIndex}.hasElevator`,
      ],
    });
  const { country } = useFormAddressAutoComplete(
    `addresses.${addressIndex}`,
    setValidAddress,
  );

  const fields = useMemo(() => {
    const result = [];
    if (showUnknownAddress) {
      result.push(`addresses.${addressIndex}.unknownAddress`);
    }

    if (showUnknownAddress && unknownAddress) {
      return result;
    }

    if (country !== 'NL') {
      result.push(
        ...[
          `addresses.${addressIndex}.streetName`,
          `addresses.${addressIndex}.houseNumber`,
          `addresses.${addressIndex}.houseNumberAddition`,
          `addresses.${addressIndex}.zipCode`,
          `addresses.${addressIndex}.city`,
        ],
      );
    } else if (country === 'NL') {
      if (validAddress) {
        result.push(
          ...[
            `addresses.${addressIndex}.zipCode`,
            `addresses.${addressIndex}.houseNumber`,
            `addresses.${addressIndex}.houseNumberAddition`,
          ],
        );
      } else {
        result.push(
          ...[
            `addresses.${addressIndex}.zipCode`,
            `addresses.${addressIndex}.houseNumber`,
            `addresses.${addressIndex}.houseNumberAddition`,
            `addresses.${addressIndex}.streetName`,
            `addresses.${addressIndex}.city`,
          ],
        );
      }
    }

    result.push(
      ...[
        `addresses.${addressIndex}.country`,
        `addresses.${addressIndex}.buildingType`,
        `addresses.${addressIndex}.floor`,
        `addresses.${addressIndex}.hasElevator`,
      ],
    );

    if (hasElevator) {
      result.push(
        ...[
          `addresses.${addressIndex}.distanceFromCar`,
          `addresses.${addressIndex}.distanceToApartment`,
        ],
      );
    }

    result.push(
      ...[
        `addresses.${addressIndex}.trafficSigns`,
        `addresses.${addressIndex}.permitNeeded`,
      ],
    );

    return result;
  }, [
    showUnknownAddress,
    unknownAddress,
    country,
    validAddress,
    addressIndex,
    hasElevator,
  ]);

  const renderField = useCallback(
    (field: string) => {
      switch (field) {
        case `addresses.${addressIndex}.unknownAddress`:
          return (
            <ReactHookFormSelectButtons
              key={`addresses.${addressIndex}.unknownAddress`}
              name={`addresses.${addressIndex}.unknownAddress`}
              light={light}
              label={formatMessage({
                id: 'mobileApp.intake.movingJob.isTheAddressKnown',
              })}
              options={[
                {
                  value: false,
                  label: (
                    <Typography fontWeight={500}>
                      <FormattedMessage id="label.yes" />
                    </Typography>
                  ),
                },
                {
                  value: true,
                  label: (
                    <Typography fontWeight={500}>
                      <FormattedMessage id="label.no" />
                    </Typography>
                  ),
                },
              ]}
            />
          );
        case `addresses.${addressIndex}.streetName`:
          return (
            <ReactHookFormTextField
              key={`addresses.${addressIndex}.streetName`}
              name={`addresses.${addressIndex}.streetName`}
              light={light}
              autoComplete="off"
              label={formatMessage({ id: 'label.streetName' })}
            />
          );
        case `addresses.${addressIndex}.zipCode`:
          return (
            <ReactHookFormTextField
              key={`addresses.${addressIndex}.zipCode`}
              name={`addresses.${addressIndex}.zipCode`}
              light={light}
              autoComplete="off"
              label={formatMessage({ id: 'label.zipCode' })}
            />
          );
        case `addresses.${addressIndex}.houseNumber`:
          return (
            <ReactHookFormTextField
              key={`addresses.${addressIndex}.houseNumber`}
              name={`addresses.${addressIndex}.houseNumber`}
              light={light}
              autoComplete="off"
              label={formatMessage({ id: 'label.houseNumber' })}
            />
          );

        case `addresses.${addressIndex}.houseNumberAddition`:
          return (
            <ReactHookFormTextField
              key={`addresses.${addressIndex}.houseNumberAddition`}
              name={`addresses.${addressIndex}.houseNumberAddition`}
              light={light}
              autoComplete="off"
              label={formatMessage({ id: 'label.houseNumberAddition' })}
            />
          );
        case `addresses.${addressIndex}.city`:
          return (
            <ReactHookFormTextField
              key={`addresses.${addressIndex}.city`}
              name={`addresses.${addressIndex}.city`}
              light={light}
              autoComplete="off"
              label={formatMessage({ id: 'label.city' })}
            />
          );
        case `addresses.${addressIndex}.country`:
          return (
            <ReactHookFormDropdown
              key={`addresses.${addressIndex}.country`}
              name={`addresses.${addressIndex}.country`}
              label={formatMessage({ id: 'label.country' })}
              light={light}
              allowSearch
              items={[
                ...getCountryOptions().map((option) => ({
                  id: option.code,
                  label: option.label,
                })),
              ]}
            />
          );
        case `addresses.${addressIndex}.buildingType`:
          return (
            <ReactHookFormDropdown
              key={`addresses.${addressIndex}.buildingType`}
              name={`addresses.${addressIndex}.buildingType`}
              label={formatMessage({ id: 'label.buildingType' })}
              light={light}
              items={Object.values(BuildingType)
                .filter((option) => !!option)
                .map((option) => ({
                  id: option,
                  label: formatMessage({
                    id: `buildingTypes.${option || 'unknown'}`,
                  }),
                }))}
            />
          );
        case `addresses.${addressIndex}.floor`:
          return (
            <ReactHookFormNumberTextField
              key={`addresses.${addressIndex}.floor`}
              name={`addresses.${addressIndex}.floor`}
              light={light}
              autoComplete="off"
              label={formatMessage({
                id: 'mobileApp.intake.movingJob.fromWhichFloor',
              })}
              keyboardType="decimal-pad"
              fractionDigits={2}
              padZero={false}
              showZero={false}
              textAlign="left"
            />
          );
        case `addresses.${addressIndex}.hasElevator`:
          return (
            <ReactHookFormSelectButtons
              key={`addresses.${addressIndex}.hasElevator`}
              name={`addresses.${addressIndex}.hasElevator`}
              light={light}
              label={formatMessage({
                id: 'mobileApp.intake.movingJob.doYouHaveAnElevator',
              })}
              options={[
                {
                  value: true,
                  label: (
                    <Typography fontWeight={500}>
                      <FormattedMessage id="label.yes" />
                    </Typography>
                  ),
                },
                {
                  value: false,
                  label: (
                    <Typography fontWeight={500}>
                      <FormattedMessage id="label.no" />
                    </Typography>
                  ),
                },
              ]}
            />
          );

        case `addresses.${addressIndex}.distanceFromCar`:
          return (
            <ReactHookFormNumberTextField
              key={`addresses.${addressIndex}.distanceFromCar`}
              name={`addresses.${addressIndex}.distanceFromCar`}
              light={light}
              autoComplete="off"
              label={formatMessage({
                id: 'mobileApp.intake.movingJob.distanceFromCar',
              })}
              keyboardType="decimal-pad"
              fractionDigits={0}
              suffix="m"
              textAlign="left"
            />
          );

        case `addresses.${addressIndex}.distanceToApartment`:
          return (
            <ReactHookFormNumberTextField
              key={`addresses.${addressIndex}.distanceToApartment`}
              name={`addresses.${addressIndex}.distanceToApartment`}
              light={light}
              autoComplete="off"
              label={formatMessage({
                id: 'mobileApp.intake.movingJob.distanceToApartment',
              })}
              keyboardType="decimal-pad"
              fractionDigits={0}
              suffix="m"
              textAlign="left"
            />
          );

        case `addresses.${addressIndex}.trafficSigns`:
          return (
            <ReactHookFormSelectButtons
              key={`addresses.${addressIndex}.trafficSigns`}
              name={`addresses.${addressIndex}.trafficSigns`}
              light={light}
              label={formatMessage({
                id: 'mobileApp.intake.movingJob.doYouNeedTrafficSigns',
              })}
              options={[
                {
                  value: true,
                  label: (
                    <Typography fontWeight={500}>
                      <FormattedMessage id="label.yes" />
                    </Typography>
                  ),
                },
                {
                  value: false,
                  label: (
                    <Typography fontWeight={500}>
                      <FormattedMessage id="label.no" />
                    </Typography>
                  ),
                },
              ]}
            />
          );
        case `addresses.${addressIndex}.permitNeeded`:
          return (
            <ReactHookFormSelectButtons
              key={`addresses.${addressIndex}.permitNeeded`}
              name={`addresses.${addressIndex}.permitNeeded`}
              light={light}
              label={formatMessage({
                id: 'mobileApp.intake.movingJob.doYouNeedAPermit',
              })}
              options={[
                {
                  value: true,
                  label: (
                    <Typography fontWeight={500}>
                      <FormattedMessage id="label.yes" />
                    </Typography>
                  ),
                },
                {
                  value: false,
                  label: (
                    <Typography fontWeight={500}>
                      <FormattedMessage id="label.no" />
                    </Typography>
                  ),
                },
              ]}
            />
          );

        default:
          return null;
      }
    },
    [addressIndex, formatMessage, light],
  );

  const renderItem = useCallback(
    ({ item: field, index }: ListRenderItemInfo<string>) => {
      const renderedField = renderField(field);
      if (!renderedField) {
        return null;
      }

      return <Box pt={index > 0 ? 3 : 0}>{renderedField}</Box>;
    },

    [renderField],
  );

  const paddingHorizontal = useResponsiveProp({
    largerTablet: 80,
    tablet: 50,
    phone: 20,
  });

  return (
    <FlashList
      estimatedItemSize={105}
      renderItem={renderItem}
      data={fields}
      contentContainerStyle={{
        paddingVertical: 24,
        paddingHorizontal,
      }}
    />
  );
};

export default TaxationToolAddressForm;
