import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ReopenEventMutationProps = {
  eventId: Uuid;
};

export const ReopenEventMutation = async ({
  eventId,
  ...values
}: ReopenEventMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/events/${eventId}/reopen`, {
    eventId,
    ...values,
  });

export const useReopenEventMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ReopenEventMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ReopenEventMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ReopenEventMutationProps
  >({
    mutationFn: ReopenEventMutation,
    throwOnError: false,
    ...options,
  });
