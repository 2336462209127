import * as Yup from 'yup';

export type SetupTwoFactorInputType = {
  currentPassword: string;
};

export const setupTwoFactorInputTypeDefaultValues =
  (): SetupTwoFactorInputType => ({
    currentPassword: '',
  });

export const setupTwoFactorInputTypeValidationBuilder =
  (): Yup.ObjectSchema<SetupTwoFactorInputType> =>
    Yup.object({
      currentPassword: Yup.string().required().label('label.currentPassword'),
    });
