import { EmailAddress } from '@bas/value-objects';
import * as Yup from 'yup';

export type SignForEventInputType = {
  isTheCustomerPresent?: boolean;
  doYouWantToSignWithTheCustomer?: boolean;
  wasTheWorkPerformedSatisfactorily?: boolean;
  name?: string | null;
  emailAddress?: EmailAddress | null;
  remarks?: string | null;
  signature?: string | null;
};

export const SignForEventInputTypeValidationBuilder =
  (): Yup.ObjectSchema<SignForEventInputType> =>
    Yup.object({
      isTheCustomerPresent: Yup.boolean()
        .required()
        .label('label.isTheCustomerPresent'),
      doYouWantToSignWithTheCustomer: Yup.boolean()
        .required()
        .label('label.doYouWantToSignWithTheCustomer'),
      wasTheWorkPerformedSatisfactorily: Yup.boolean()
        .when(['isTheCustomerPresent', 'doYouWantToSignWithTheCustomer'], {
          is: (
            isTheCustomerPresent: boolean,
            doYouWantToSignWithTheCustomer: boolean,
          ) => isTheCustomerPresent && doYouWantToSignWithTheCustomer,
          then: (schema) => schema.required(),
        })
        .label('label.wasTheWorkPerformedSatisfactorily'),
      name: Yup.string()
        .when(['isTheCustomerPresent', 'doYouWantToSignWithTheCustomer'], {
          is: (
            isTheCustomerPresent: boolean,
            doYouWantToSignWithTheCustomer: boolean,
          ) => isTheCustomerPresent && doYouWantToSignWithTheCustomer,
          then: (schema) => schema.required(),
        })
        .label('label.name'),
      emailAddress: Yup.string()
        .email()
        .when(['isTheCustomerPresent', 'doYouWantToSignWithTheCustomer'], {
          is: (
            isTheCustomerPresent: boolean,
            doYouWantToSignWithTheCustomer: boolean,
          ) => isTheCustomerPresent && doYouWantToSignWithTheCustomer,
          then: (schema) => schema.required(),
        })
        .label('label.emailAddress'),
      remarks: Yup.string().label('label.remarks'),
      signature: Yup.string()
        .when(['isTheCustomerPresent', 'doYouWantToSignWithTheCustomer'], {
          is: (
            isTheCustomerPresent: boolean,
            doYouWantToSignWithTheCustomer: boolean,
          ) => isTheCustomerPresent && doYouWantToSignWithTheCustomer,
          then: (schema) => schema.required(),
        })
        .label('label.signature'),
    });
