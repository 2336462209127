import * as Yup from 'yup';

export type LoginInputType = {
  appUrl: string;
  emailAddress: string;
  password: string;
  twoFactorCode?: string | null;
  status?: string | null;
};

export const LoginInputTypeDefaultValues = (): LoginInputType => ({
  appUrl: '',
  emailAddress: '',
  password: '',
  twoFactorCode: '',
  status: '',
});

export const LoginInputTypeValidationBuilder = ({
  requireTwoFactorCode,
}: {
  requireTwoFactorCode: boolean;
}): Yup.ObjectSchema<LoginInputType> => {
  const base = Yup.object({
    appUrl: Yup.string().required().label('label.appUrl'),
    emailAddress: Yup.string().email().required().label('label.emailAddress'),
    password: Yup.string().required().label('label.password'),
    twoFactorCode: Yup.string().optional().label('label.twoFactorCode'),
    status: Yup.string().optional(),
  });

  if (!requireTwoFactorCode) {
    return base;
  }

  return base.concat(
    Yup.object({
      twoFactorCode: Yup.string().required(),
    }),
  );
};
