import { StyleSheet } from 'react-native';
import { colors } from '@bas/theme';

export default StyleSheet.create({
  container: {
    backgroundColor: 'transparent',
    borderWidth: 2,
    borderColor: colors.lila[400],
    borderStyle: 'dashed',
  },
  label: {
    alignContent: 'center',
  },
});
