import type { Uuid } from '@bas/value-objects';
import type { Employee, Team } from '@bas/hrm-domain/models';
import type { TaskType } from './TaskType';

export interface TaskSettingsForTaskType {
  taskSettingsForTaskTypeId: Uuid;
  taskType: TaskType;
  assignedEmployee?: Employee;
  assignedTeam?: Team;
  priorityId: Uuid;
}
