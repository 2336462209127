import {
  ProjectEventDetailsInputType,
  ProjectEventDetailsInputTypeValidationBuilder,
} from '@bas/planning-domain/input-types';
import { Uuid } from '@bas/value-objects';
import * as Yup from 'yup';
import {
  NewProjectCustomerInputType,
  NewProjectCustomerInputTypeDefaultValues,
  NewProjectCustomerInputTypeValidationBuilder,
} from './newProjectCustomerInputType';
import {
  ProjectPricingInputType,
  ProjectPricingInputTypeDefaultValues,
  ProjectPricingInputTypeValidationBuilder,
} from './projectPricingInputType';
import {
  TransportJobCargoInputType,
  TransportJobCargoInputTypeValidationBuilder,
} from './transportJobCargoInputType';

export type NewTransportJobInputType = NewProjectCustomerInputType &
  ProjectPricingInputType & {
    loads: TransportJobCargoInputType[];
    events: ProjectEventDetailsInputType[];
    intakeEvent: {
      start?: Date | null;
      end?: Date | null;
      travelStart?: Date | null;
      travelEnd?: Date | null;
      employeeIds: Uuid[];
    };
  };

export const NewTransportJobInputTypeDefaultValues =
  (): NewTransportJobInputType => ({
    useIdentities: false,
    ...NewProjectCustomerInputTypeDefaultValues(),
    ...ProjectPricingInputTypeDefaultValues(),
    loads: [],
    events: [],
    intakeEvent: {
      start: null,
      end: null,
      travelStart: null,
      travelEnd: null,
      employeeIds: [],
    },
  });

export const NewTransportJobInputTypeValidationBuilder =
  (): Yup.ObjectSchema<NewTransportJobInputType> =>
    Yup.object({
      loads: Yup.array()
        .of(TransportJobCargoInputTypeValidationBuilder())
        .required(),
      events: Yup.array()
        .of(ProjectEventDetailsInputTypeValidationBuilder())
        .required(),
      intakeEvent: Yup.object({
        start: Yup.date().nullable().label('label.startTime'),
        end: Yup.date().nullable().label('label.endTime'),
        travelStart: Yup.date().nullable().label('label.departureTime'),
        travelEnd: Yup.date().nullable().label('label.arrivalTimeWarehouse'),
        employeeIds: Yup.array().of(Yup.string().required()).required(),
      }),
    })
      .concat(NewProjectCustomerInputTypeValidationBuilder())
      .concat(ProjectPricingInputTypeValidationBuilder());
