import { formatDate } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type PlanMaterialOnEventMutationProps = {
  eventId: Uuid;
  materialId: Uuid;
  crewId?: Uuid | null;
  date: Date;
  updateFutureEvents: boolean;
};

export const PlanMaterialOnEventMutation = async ({
  eventId,
  date,
  ...values
}: PlanMaterialOnEventMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/events/${eventId}/plan-material`, {
    ...values,
    date: formatDate(date),
    eventId,
  });

export const usePlanMaterialOnEventMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    PlanMaterialOnEventMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  PlanMaterialOnEventMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    PlanMaterialOnEventMutationProps
  >({
    mutationFn: PlanMaterialOnEventMutation,
    throwOnError: false,
    ...options,
  });
