import { Checkbox } from '@bas/ui/native/atoms';
import { Box, Typography } from '@bas/ui/native/base';
import {
  RenderFunction,
  RenderRules,
} from '@jonasmerlin/react-native-markdown-display';
import MarkdownIt, { Token } from 'markdown-it';
import { onlyText } from 'react-children-utilities';
import { View } from 'react-native';

const CHECKBOX_REGEX = /\[(X|\s|_|-)\]\s(.*)?/i;

function matches(token: Token | void) {
  return token && token.content.match(CHECKBOX_REGEX);
}

function isInline(token: Token | void): boolean {
  return !!token && token.type === 'inline';
}

function isParagraph(token: Token | void): boolean {
  return !!token && token.type === 'paragraph_open';
}

function isListItem(token: Token | void): boolean {
  return (
    !!token &&
    (token.type === 'list_item_open' || token.type === 'checkbox_item_open')
  );
}

function looksLikeChecklist(tokens: Token[], index: number) {
  return (
    isInline(tokens[index]) &&
    isListItem(tokens[index - 2]) &&
    isParagraph(tokens[index - 1]) &&
    matches(tokens[index])
  );
}

export const renderCheckboxItem: RenderFunction = (
  node,
  children,
  parent,
  styles,
) => (
  <Box key={node.key} flexDirection="row" alignItems="center" mb={1}>
    <Checkbox desktop checked={node.attributes.checked} size={24} />
    <Typography style={styles.label}>{onlyText(children)}</Typography>
  </Box>
);

export const checkboxRules: RenderRules = {
  checkbox_item: renderCheckboxItem,
  checkbox_list: (node, children, parent, styles) => (
    <View key={node.key}>{children}</View>
  ),
};

export default function markdownItCheckbox(md: MarkdownIt): void {
  // insert a new rule after the "inline" rules are parsed
  md.core.ruler.after('inline', 'checkboxes', (state) => {
    const { tokens } = state;

    // work backwards through the tokens and find text that looks like a checkbox
    for (let i = tokens.length - 1; i > 0; i -= 1) {
      const foundMatches = looksLikeChecklist(tokens, i);
      if (foundMatches) {
        const value = foundMatches[1];
        const checked = value.toLowerCase() === 'x';

        // convert surrounding list tokens
        if (tokens[i - 3].type === 'bullet_list_open') {
          tokens[i - 3].type = 'checkbox_list_open';
        }

        if (tokens[i + 3].type === 'bullet_list_close') {
          tokens[i + 3].type = 'checkbox_list_close';
        }

        // remove [ ] [x] from list item label – must use the content from the
        // child for escaped characters to be unescaped correctly.
        const tokenChildren = tokens[i].children;
        if (tokenChildren) {
          const contentMatches = tokenChildren[0].content.match(CHECKBOX_REGEX);

          if (contentMatches) {
            const label = contentMatches[2];

            tokens[i].content = label;
            tokenChildren[0].content = label;
          }
        }

        // open list item and ensure checked state is transferred
        tokens[i - 2].type = 'checkbox_item_open';

        if (checked === true) {
          tokens[i - 2].attrs = [['checked', 'true']];
        }

        // close the list item
        let j = i;
        while (tokens[j].type !== 'list_item_close') {
          j += 1;
        }
        tokens[j].type = 'checkbox_item_close';
      }
    }

    return false;
  });
}
