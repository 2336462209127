import type { EmployeeProjectRole, PricingType } from '@bas/value-objects';
import type { MovingLiftJobStatus } from './MovingLiftJobStatus';
import type { Project } from './Project';

export interface MovingLiftJob extends Project {
  pricingType: PricingType;
  neededRoles: EmployeeProjectRole[];
  customerNotes?: string;
  projectStatus: MovingLiftJobStatus;
  nextActionDate?: Date;
}
