import { QueryOptionsWithKey } from '@bas/shared/requests';
import { Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type InvoiceExpiresAtByInvoiceIdRequestProps = {
  invoiceId: Uuid;
};

type QuoteExpiresAtResult = {
  calculatedExpiresAt: Date;
};

export const InvoiceExpiresAtByInvoiceIdRequest = async ({
  invoiceId,
  ...params
}: InvoiceExpiresAtByInvoiceIdRequestProps): Promise<
  AxiosResponse<QuoteExpiresAtResult>
> =>
  axios.get(`api/{tenantId}/invoices/${invoiceId}/calculate-expires-at`, {
    params,
  });

export const useInvoiceExpiresAtByInvoiceIdRequestQuery = (
  request: InvoiceExpiresAtByInvoiceIdRequestProps,
  options: QueryOptionsWithKey<
    AxiosResponse<QuoteExpiresAtResult>,
    AxiosError,
    AxiosResponse<QuoteExpiresAtResult>
  > = {},
): UseQueryResult<AxiosResponse<QuoteExpiresAtResult>, AxiosError> =>
  useQuery<
    AxiosResponse<QuoteExpiresAtResult>,
    AxiosError,
    AxiosResponse<QuoteExpiresAtResult>
  >({
    ...options,
    queryFn: async () => InvoiceExpiresAtByInvoiceIdRequest(request),
    queryKey: ['invoices', 'expires-at', request.invoiceId],
  });
