import { useCallback } from 'react';
import { useIntercom } from 'react-use-intercom';

export const useHandleRequestHelp = () => {
  const { showNewMessage } = useIntercom();

  return useCallback(
    (message?: string) => {
      showNewMessage(message);
    },
    [showNewMessage],
  );
};
