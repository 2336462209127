import { useRegisterMobileDeviceMutation } from '@bas/authentication-domain/mutations';
import { useAppStore, useAuthStore, useUserStore } from '@bas/shared/state';
import axios from 'axios';
import dayjs from 'dayjs';
import * as Device from 'expo-device';
import { useEffect } from 'react';
import { Platform } from 'react-native';

export const useRegisterDevice = () => {
  const { mutateAsync: registerDevice } = useRegisterMobileDeviceMutation();

  const expiresAt = useAuthStore((state) => state.expiresAt);
  const token = useAuthStore((state) => state.token);
  const deviceId = useAppStore((state) => state.deviceId);
  const apiUrl = useAppStore((state) => state.apiUrl);

  const userId = useUserStore((state) => state.user?.userId || null);

  useEffect(() => {
    if (
      deviceId &&
      token &&
      dayjs(expiresAt).subtract(1, 'minute').isAfter(dayjs()) &&
      axios.defaults.baseURL === apiUrl
    ) {
      registerDevice({
        deviceId,
        userId: userId || null,
        deviceName: Device.deviceName || 'Unknown',
        deviceType: Platform.OS,
        userAgent: `${Device.osName} ${Device.osVersion} ${Device.modelName} ${Device.brand} ${Device.deviceYearClass}`,
      });
    }
  }, [apiUrl, deviceId, token, registerDevice, userId, expiresAt]);
};
