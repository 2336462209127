import { isInvoiceEvent } from './InvoiceEvent';
import { InvoiceEventType, InvoiceExpiredEvent } from '../types';

export function isInvoiceExpiredEvent(
  object: unknown,
): object is InvoiceExpiredEvent {
  return (
    isInvoiceEvent(object) &&
    object.eventType === InvoiceEventType.InvoiceExpiredEvent
  );
}
