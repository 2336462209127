import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type RegisterMobileDeviceMutationProps = {
  deviceId: Uuid;
  userId?: Uuid | null;
  deviceName: string;
  deviceType:
    | 'android'
    | 'ios'
    | 'web'
    | 'macos'
    | 'windows'
    | 'linux'
    | 'unknown';
  userAgent: string;
  pushToken?: string | null;
};

export const RegisterMobileDeviceMutation = async ({
  ...values
}: RegisterMobileDeviceMutationProps): Promise<AxiosResponse> =>
  axios.post('/api/public/devices/register', { ...values });

export const useRegisterMobileDeviceMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RegisterMobileDeviceMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  RegisterMobileDeviceMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RegisterMobileDeviceMutationProps
  >({
    mutationFn: RegisterMobileDeviceMutation,
    throwOnError: true,
    ...options,
  });
