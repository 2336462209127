import { Box } from '@bas/ui/native/base';
import { ReactElement, ReactNode } from 'react';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';
import styles from './styles';

export type RectangularTileProps = {
  children: ReactNode;
  style?: StyleProp<ViewStyle>;
  onPress?: () => void;
};

const RectangularTile = ({
  children,
  onPress,
  style,
}: RectangularTileProps): ReactElement => (
  <TouchableOpacity onPress={onPress}>
    <Box
      style={[styles.rectangularTile, style]}
      padding={2}
      backgroundColor="white"
    >
      {children}
    </Box>
  </TouchableOpacity>
);

export default RectangularTile;
