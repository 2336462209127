import { Money, Uuid } from '@bas/value-objects';

type Vat = {
  totalAmount: number;
  totalVatAmount: number;
  vatPercentage: {
    percentage: number;
  };
};

export type FinancialCalculationResult = {
  totalExcludingVat: number;
  discountAmount: number;
  vats: { [vatCodeId: Uuid]: Vat };
  totalIncludingVat: number;
};

type FinancialCalculationLine = {
  lineTotal: number;
  lineTotalWithoutDiscount: number;
  vatCode: {
    vatCodeId: Uuid;
  };
  vatPercentage: { percentage: number };
};

export const calculateTotalOfLines = (
  lines: FinancialCalculationLine[],
  withoutDiscount?: boolean,
): number => {
  let totalOfLines = 0;
  lines.forEach(({ lineTotal, lineTotalWithoutDiscount }) => {
    if (withoutDiscount) {
      totalOfLines += lineTotalWithoutDiscount;
    } else {
      totalOfLines += lineTotal;
    }
  });

  return totalOfLines;
};

export const calculateTotalVatAmounts = (
  shouldChargeVat: boolean,
  includingVat: boolean,
  lines: FinancialCalculationLine[],
): { [vatCodeId: Uuid]: Vat } => {
  const vats: { [vatCodeId: Uuid]: Vat } = {};
  if (!shouldChargeVat) {
    return vats;
  }

  lines.forEach((line) => {
    if (line.vatCode.vatCodeId !== undefined) {
      if (typeof vats[line.vatCode.vatCodeId] === 'undefined') {
        vats[line.vatCode.vatCodeId] = {
          totalAmount: line.lineTotal,
          totalVatAmount: 0,
          vatPercentage: line.vatPercentage,
        };
      } else {
        vats[line.vatCode.vatCodeId].totalAmount += line.lineTotal;
      }
    }
  });

  Object.keys(vats).forEach((vatCodeId) => {
    const vat = vats[vatCodeId];
    const { percentage } = vat.vatPercentage;
    let vatTotal = 0;

    if (percentage > 0) {
      if (includingVat) {
        vatTotal = vat.totalAmount - vat.totalAmount / (1 + percentage / 100);
      } else {
        vatTotal = vat.totalAmount * (percentage / 100);
      }
    }

    vats[vatCodeId].totalVatAmount = vatTotal;
  });

  return vats;
};

type DocumentLine = FinancialCalculationLine & {
  pricePerUnit: Money;
  quantity: number;
};

export const calculateTotals = (
  documentLines: DocumentLine[],
  includingVat: boolean,
  shouldChargeVat: boolean,
  discountPercentage: number,
): FinancialCalculationResult => {
  const lines = documentLines.map((line) => {
    const lineTotalWithoutDiscount = line.pricePerUnit.amount * line.quantity;
    let lineTotal = lineTotalWithoutDiscount;
    if (discountPercentage) {
      lineTotal =
        lineTotalWithoutDiscount -
        lineTotalWithoutDiscount * (discountPercentage / 100);
    }
    return {
      ...line,
      lineTotal,
      lineTotalWithoutDiscount,
    };
  });

  const totalOfLines = calculateTotalOfLines(lines);
  const totalOfLinesWithoutDiscount = calculateTotalOfLines(lines, true);
  const vats = calculateTotalVatAmounts(shouldChargeVat, includingVat, lines);
  let totalExcludingVat = totalOfLines;
  let totalIncludingVat = totalOfLines;
  const discountAmount = totalOfLines - totalOfLinesWithoutDiscount;

  Object.values(vats).forEach((vat) => {
    if (includingVat) {
      totalExcludingVat -= vat.totalVatAmount;
    } else {
      totalIncludingVat += vat.totalVatAmount;
    }
  });

  return {
    discountAmount,
    totalExcludingVat,
    vats,
    totalIncludingVat,
  };
};
