import { TimeType } from '@bas/hrm-domain/models';
import { useTimeTypesRequest } from '@bas/hrm-domain/requests';
import { TimeTrackingItem, useTenantStore } from '@bas/shared/state';
import { Box, ShopifyTheme, Typography } from '@bas/ui/native/base';
import { SquareIconTile } from '@bas/ui/native/molecules';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { FlashList, ListRenderItemInfo } from '@shopify/flash-list';
import { useResponsiveProp } from '@shopify/restyle';
import * as React from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';

export type UnknownTimeEntryStepProps = {
  index: number;
  onNext: (disableOverriddenSubmit?: boolean, force?: boolean) => Promise<void>;
};

// @TODO add logic to quickly get traffic jam and gas station time types

const UnknownTimeEntryStep = ({ index, onNext }: UnknownTimeEntryStepProps) => {
  const resultingTimeEntries: TimeTrackingItem[] = useWatch({
    name: 'calculatedTimeEntries',
  });

  const timeEntry = useMemo(
    () => resultingTimeEntries[index],
    [resultingTimeEntries, index],
  );

  const { data: timeTypesData } = useTimeTypesRequest();

  const timeTypes = useMemo(
    () => timeTypesData?.data?.member || [],
    [timeTypesData?.data],
  );

  const tenantState = useTenantStore((state) => state.tenant);
  const mobileAppFeature = tenantState?.mobileAppFeature;

  const previousTimeEntry = resultingTimeEntries[index - 1];
  const nextTimeEntry = resultingTimeEntries[index + 1];

  const previousTimeType = useMemo(
    () =>
      timeTypes.find(
        (timeType) => timeType.timeTypeId === previousTimeEntry?.timeTypeId,
      ),
    [previousTimeEntry?.timeTypeId, timeTypes],
  );

  const nextTimeType = useMemo(
    () =>
      timeTypes.find(
        (timeType) => timeType.timeTypeId === nextTimeEntry?.timeTypeId,
      ),
    [nextTimeEntry?.timeTypeId, timeTypes],
  );

  const { formatMessage } = useIntl();
  const defaultHourTypes = mobileAppFeature?.defaultHourTypes;
  const potentialTimeTypes = useMemo(() => {
    const result: TimeType[] = [];
    if (!defaultHourTypes) {
      return result;
    }

    if (
      previousTimeEntry?.timeTypeId === defaultHourTypes.drivingHourTypeId ||
      nextTimeEntry?.timeTypeId === defaultHourTypes.drivingHourTypeId
    ) {
      const namesToMatch = ['File', 'Tanken', 'Rijden'];

      result.push(
        ...timeTypes
          .filter((timeType) => namesToMatch.includes(timeType.name))
          .sort(
            (a, b) =>
              namesToMatch.indexOf(a.name) - namesToMatch.indexOf(b.name),
          ),
      );
    }

    if (previousTimeType) {
      result.push(previousTimeType);
    }

    if (nextTimeType) {
      result.push(nextTimeType);
    }

    result.push({
      categoryId: '00000000-0000-0000-0000-000000000000',
      categoryName: '',
      categoryWeight: 0,
      fieldService: false,
      translatedNames: [],
      weight: 0,
      workingHours: false,
      timeTypeId: '00000000-0000-0000-0000-000000000000',
      name: formatMessage({ id: 'label.other' }),
      icon: faQuestionCircle as unknown as string,
    });

    return result.filter(
      (value, index, self) =>
        self.findIndex((t) => t.timeTypeId === value.timeTypeId) === index,
    );
  }, [
    defaultHourTypes,
    formatMessage,
    nextTimeEntry?.timeTypeId,
    nextTimeType,
    previousTimeEntry?.timeTypeId,
    previousTimeType,
    timeTypes,
  ]);

  const { setValue } = useFormContext();

  const handlePressTimeType = useCallback(
    (timeTypeId: string) => {
      console.log('handlePressTimeType', timeTypeId);
      if (timeTypeId === '00000000-0000-0000-0000-000000000000') {
        // @TODO open modal to add new time type
        return;
      }

      const newResultingTimeEntries = [...resultingTimeEntries];
      newResultingTimeEntries[index] = {
        ...newResultingTimeEntries[index],
        timeTypeId,
      };

      setValue('calculatedTimeEntries', newResultingTimeEntries, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    },
    [index, resultingTimeEntries, setValue],
  );

  const handleRenderTimeType = useCallback(
    ({ item }: ListRenderItemInfo<TimeType>) => (
      <SquareIconTile
        selected={item.timeTypeId === timeEntry.timeTypeId}
        onPress={() => handlePressTimeType(item.timeTypeId)}
        icon={item.icon}
        label={
          <Box flexDirection="column" alignItems="center">
            <Typography
              fontWeight={700}
              color={
                timeEntry.timeTypeId === item.timeTypeId ? 'white' : 'darkText'
              }
            >
              {item.name}
            </Typography>
          </Box>
        }
        width="100%"
      />
    ),
    [handlePressTimeType, timeEntry.timeTypeId],
  );

  const numberOfColumns = useResponsiveProp<ShopifyTheme, number>({
    lg: 4,
    largerTablet: 4,
    tablet: 4,
    phone: 2,
  });

  useEffect(() => {
    setValue('currentTimeEntryId', timeEntry.timeEntryId);
  }, [setValue, timeEntry.timeEntryId]);

  return (
    <Box flex={1} flexDirection="column" paddingHorizontal={20} gap={3}>
      <Box>
        <Typography
          variant="h4"
          fontWeight={700}
          color="white"
          textAlign="center"
        >
          <FormattedMessage id="label.unknownTimeEntryToProcess" />
        </Typography>
      </Box>
      <Box>
        <Typography color="white" variant="h5">
          <FormattedMessage
            id="label.whatWasThisTimeEntry"
            values={{
              startedAt: (
                <Typography fontWeight={700} color="white" variant="h5">
                  <FormattedDate value={timeEntry.start} timeStyle="short" />
                </Typography>
              ),
              endedAt: (
                <Typography fontWeight={700} color="white" variant="h5">
                  <FormattedDate value={timeEntry.end} timeStyle="short" />
                </Typography>
              ),
            }}
          />
          {(previousTimeType || nextTimeType) && ' '}
          {previousTimeType && (
            <FormattedMessage
              id="label.beforeThisTimeEntryYouWereDoing"
              values={{
                timeType: (
                  <Typography fontWeight={700} color="white" variant="h5">
                    {previousTimeType.name}
                  </Typography>
                ),
              }}
            />
          )}
          {previousTimeType && nextTimeType && ' '}
          {nextTimeType && (
            <FormattedMessage
              id="label.afterThisTimeEntryYouWereDoing"
              values={{
                timeType: (
                  <Typography fontWeight={700} color="white" variant="h5">
                    {nextTimeType.name}
                  </Typography>
                ),
              }}
            />
          )}
        </Typography>
      </Box>
      <Box flex={1}>
        <Typography color="white" variant="subtitle1">
          <FormattedMessage id="label.possibleTimeTypes" />
        </Typography>
        <FlashList
          estimatedItemSize={186}
          numColumns={numberOfColumns}
          renderItem={handleRenderTimeType}
          contentContainerStyle={{
            paddingBottom: 24,
          }}
          data={potentialTimeTypes}
          extraData={timeEntry.timeTypeId}
        />
      </Box>
    </Box>
  );
};

export default UnknownTimeEntryStep;
