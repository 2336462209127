import { formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type PlanIntakeMutationProps = {
  projectId: Uuid;
  start: Date;
  end: Date;
  travelStart?: Date | null;
  travelEnd?: Date | null;
  employeeIds: Uuid[];
  notes?: string | null;
};

export const PlanIntakeMutation = async ({
  projectId,
  start,
  end,
  travelStart,
  travelEnd,
  ...values
}: PlanIntakeMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/moving-jobs/${projectId}/plan-intake`, {
    projectId,
    start: formatDateTime(start),
    end: formatDateTime(end),
    travelStart: travelStart ? formatDateTime(travelStart) : null,
    travelEnd: travelEnd ? formatDateTime(travelEnd) : null,
    ...values,
  });

export const usePlanIntakeMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    PlanIntakeMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  PlanIntakeMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    PlanIntakeMutationProps
  >({
    mutationFn: PlanIntakeMutation,
    throwOnError: false,
    ...options,
  });
