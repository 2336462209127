import { colors } from '@bas/theme';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    position: 'relative',
  },
  pressed: {
    backgroundColor: colors.blue[700],
  },
  baseButton: {
    cursor: 'pointer',
  },
  icon: {
    paddingRight: 4,
  },
  iconContainer: {
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  image: {},
  textButton: {},
  button: {
    backgroundColor: colors.blue[500],
    borderRadius: 5,
    paddingTop: 11,
    paddingBottom: 11,
    paddingLeft: 18,
    paddingRight: 18,
    borderWidth: 1,
    borderColor: 'transparent',
    minHeight: 45,
  },
  disabledButton: {
    backgroundColor: colors.lila[400],
  },
  outlinedButton: {
    backgroundColor: colors.white,
    borderRadius: 5,
    paddingTop: 11,
    paddingBottom: 11,
    paddingLeft: 18,
    paddingRight: 18,
    borderWidth: 1,
    borderColor: colors.blue[500],
    minHeight: 45,
  },
  disabledOutlinedButton: {
    borderColor: colors.lila[400],
    backgroundColor: colors.lila[200],
  },
  textButtonSecondary: {},
  outlinedButtonSecondary: {
    backgroundColor: 'transparent',
    borderColor: colors.lila[700],
  },
  buttonSecondary: {},
  textButtonWarning: {},
  outlinedButtonWarning: {
    backgroundColor: 'transparent',
    borderColor: colors.lila[700],
  },
  buttonDanger: {
    backgroundColor: colors.red[700],
  },
  buttonWarning: {
    backgroundColor: colors.orange[700],
  },
  disabledButtonWarning: {
    backgroundColor: colors.orange[300],
  },
  text: {
    position: 'relative',
    color: colors.white,
  },
});
