import { useAppStore, useTenantStore, useUserStore } from '@bas/shared/state';
import type { IConfig } from '@unleash/proxy-client-react';
import * as localforage from 'localforage';
import { useMemo } from 'react';

export const useFlagConfig = ({ clientKey }: { clientKey: string }) => {
  const userId = useUserStore((state) => state.user?.userId || null);
  const isSuperAdmin = useUserStore(
    (state) => state.user?.roles?.includes('ROLE_SUPER_ADMIN') || null,
  );
  const tenantId = useTenantStore((state) => state.tenant?.tenantId || null);
  const deviceId = useAppStore((state) => state.deviceId || null);

  return useMemo(() => {
    const result: IConfig = {
      environment: 'production',
      url: 'https://unleash.bas.app/api/frontend', // Your front-end API URL or the Unleash proxy's URL (https://<proxy-url>/proxy)
      clientKey, // A client-side API token OR one of your proxy's designated client keys (previously known as proxy secrets)
      refreshInterval: 60, // How often (in seconds) the client should poll the proxy for updates
      appName: 'bas-employee-app', // The name of your application. It's only used for identifying your application
      context: {
        userId: userId || undefined,
        sessionId: deviceId || undefined,
        properties: {
          tenantId: tenantId || '',
          isSuperAdmin: isSuperAdmin ? 'true' : 'false',
        },
      },
      storageProvider: {
        save: async (name, data) => {
          await localforage.setItem(name, JSON.parse(JSON.stringify(data)));
        },
        get: async (name) => {
          let data = null;
          data = await localforage.getItem(name);

          return data;
        },
      },
    };

    return result;
  }, [clientKey, deviceId, isSuperAdmin, tenantId, userId]);
};
