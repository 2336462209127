import {
  InternalPaymentMethodType,
  PaymentMethod,
} from '@bas/financial-domain/models';
import { usePaymentMethodsRequest } from '@bas/financial-domain/requests';
import { PayAtCustomerPaymentInputType } from '@bas/planning-domain/input-types';
import { Button } from '@bas/ui/native/atoms';
import { Box, Typography } from '@bas/ui/native/base';
import { ReactHookFormDropdown } from '@bas/ui/native/molecules';
import { ReactElement, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { ScrollView } from 'react-native-gesture-handler';
import { PaymentForm } from '../PaymentForm';

const supportedPaymentMethodTypes = [
  InternalPaymentMethodType.MANUAL,
  InternalPaymentMethodType.MOLLIE,
];

const PaymentStep = (): ReactElement => {
  const {
    data: paymentMethodsData,
    isPending: isLoadingPaymentMethods,
    refetch,
  } = usePaymentMethodsRequest({});

  const selectedPaymentMethodType = useWatch<
    PayAtCustomerPaymentInputType,
    'paymentMethodType'
  >({
    name: 'paymentMethodType',
  });

  const paymentMethods = useMemo(
    () => paymentMethodsData?.data.member || [],
    [paymentMethodsData?.data],
  );

  const selectedPaymentMethod: PaymentMethod | null | undefined = useMemo(
    () =>
      selectedPaymentMethodType
        ? paymentMethods.find(
            ({ paymentMethodType }) =>
              paymentMethodType === selectedPaymentMethodType,
          )
        : null,
    [paymentMethods, selectedPaymentMethodType],
  );

  const { formatMessage } = useIntl();

  return (
    <ScrollView>
      <Box paddingHorizontal={20}>
        {isLoadingPaymentMethods && (
          <Typography color="white">
            {formatMessage({ id: 'label.loadingPaymentMethods' })}
          </Typography>
        )}
        {!isLoadingPaymentMethods && paymentMethods.length === 0 && (
          <Box>
            <Typography color="white">
              {formatMessage({ id: 'label.couldNotLoadPaymentMethods' })}
            </Typography>
            <Button onPress={() => refetch()}>
              {formatMessage({ id: 'label.tryAgain' })}
            </Button>
          </Box>
        )}
        <Box>
          <ReactHookFormDropdown
            name="paymentMethodType"
            label={formatMessage({ id: 'label.paymentMethod' })}
            light
            disabled={isLoadingPaymentMethods}
            items={[
              ...paymentMethods
                .filter(({ paymentMethodType }) =>
                  supportedPaymentMethodTypes.includes(paymentMethodType),
                )
                .map((o) => ({
                  label: o.name,
                  id: o.paymentMethodType,
                })),
            ]}
          />
        </Box>
        {selectedPaymentMethod?.paymentMethodType ===
          InternalPaymentMethodType.MANUAL && (
          <Box>
            <PaymentForm light />
          </Box>
        )}
      </Box>
    </ScrollView>
  );
};

export default PaymentStep;
