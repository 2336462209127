import { ErrorResponse, TranslatedName, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ChangeDeclarationCategoryMutationProps = {
  categoryId: Uuid;
  name: string;
  weight: number;
  translatedNames: TranslatedName[];
  icon: string;
};

export const ChangeDeclarationCategoryMutation = async ({
  categoryId,
  ...values
}: ChangeDeclarationCategoryMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/hrm/declaration-categories/${categoryId}/change`, {
    categoryId,
    ...values,
  });

export const useChangeDeclarationCategoryMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeDeclarationCategoryMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ChangeDeclarationCategoryMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeDeclarationCategoryMutationProps
  >({
    mutationFn: ChangeDeclarationCategoryMutation,
    throwOnError: false,
    ...options,
  });
