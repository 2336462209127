import { HoursEntryType } from '@bas/hrm-domain/models';
import { Uuid } from '@bas/value-objects';
import dayjs from 'dayjs';
import * as Yup from 'yup';

export type EnterAndApproveEmployeeHoursInputType = {
  startDate: Date;
  endDate: Date;
  breakTime: number | null | string;
  reason?: string | null;
  entryType: HoursEntryType;
  eventId?: Uuid | null;
  projectId?: Uuid | null;
};

export const EnterAndApproveEmployeeHoursInputTypeValidationBuilder =
  (): Yup.ObjectSchema<EnterAndApproveEmployeeHoursInputType> =>
    Yup.object({
      startDate: Yup.date().required().label('label.startTime'),
      endDate: Yup.date()
        .required()
        .when(['startDate'], ([startDate], schema) =>
          schema.min(dayjs(startDate).clone().add(1, 'second').toDate()),
        )
        .required()
        .label('label.endTime'),
      breakTime: Yup.string().required().nullable().label('label.breakTime'),
      reason: Yup.string().nullable(),
      eventId: Yup.string().nullable().label('label.eventId'),
      projectId: Yup.string().nullable().label('label.projectId'),
      entryType: Yup.mixed<HoursEntryType>()
        .oneOf(Object.values(HoursEntryType))
        .required()
        .label('label.entryType'),
    });
