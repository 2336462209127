import { formatDate } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type RemoveNeededInventoryItemFromEventMutationProps = {
  eventId: Uuid;
  inventoryItemId: Uuid;
  date?: Date | null;
  updateFutureEvents: boolean;
};

export const RemoveNeededInventoryItemFromEventMutation = async ({
  eventId,
  date,
  ...values
}: RemoveNeededInventoryItemFromEventMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/events/${eventId}/remove-needed-inventory-item`, {
    eventId,
    date: date ? formatDate(date) : undefined,
    ...values,
  });

export const useRemoveNeededInventoryItemFromEventMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveNeededInventoryItemFromEventMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  RemoveNeededInventoryItemFromEventMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveNeededInventoryItemFromEventMutationProps
  >({
    mutationFn: RemoveNeededInventoryItemFromEventMutation,
    throwOnError: false,
    ...options,
  });
