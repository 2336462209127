import { CanPayAtCustomer } from '@bas/planning-domain/models';
import { BillingMomentType } from '@bas/shared/models';
import { basParaph } from '@bas/svg-icons';
import { MobileActionButton } from '@bas/ui/native/atoms';
import { faCabinetFiling } from '@fortawesome/pro-light-svg-icons/faCabinetFiling';
import { faCashRegister } from '@fortawesome/pro-light-svg-icons/faCashRegister';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle';
import { faHouseChimneyCrack } from '@fortawesome/pro-light-svg-icons/faHouseChimneyCrack';
import { faPeopleCarryBox } from '@fortawesome/pro-light-svg-icons/faPeopleCarryBox';
import { faSteeringWheel } from '@fortawesome/pro-light-svg-icons/faSteeringWheel';
import { faUpload } from '@fortawesome/pro-light-svg-icons/faUpload';
import { ReactElement, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

type useMovingLiftJobEventPageActionsProps = {
  finished: boolean;
  finishedAtCustomer: boolean;
  startedWorking: boolean;
  startedDriving: boolean;
  hasForeman: boolean;
  isForeman: boolean;
  onStartFinalizingEventAtCustomer: () => void;
  onStartFinalizingEvent: () => void;
  onStartPayingAtCustomer: (billingMomentType: BillingMomentType) => void;
  onStartReportingDamage: () => void;
  onStartUploadingDocument: () => void;
  onStartRegisterMileage: () => void;
  onOpenWorkingOnEventForm: () => void;
  onOpenDrivingToEventForm: () => void;
  canPayAtCustomer?: CanPayAtCustomer;
  loadingInvoice?: boolean;
};

export const useMovingLiftJobEventPageActions = ({
  finished,
  finishedAtCustomer,
  canPayAtCustomer,
  onStartFinalizingEventAtCustomer,
  onStartFinalizingEvent,
  onStartPayingAtCustomer,
  onStartReportingDamage,
  onStartUploadingDocument,
  onStartRegisterMileage,
  onOpenWorkingOnEventForm,
  onOpenDrivingToEventForm,
  hasForeman,
  isForeman,
  startedWorking,
  startedDriving,
  loadingInvoice,
}: useMovingLiftJobEventPageActionsProps) =>
  useMemo(() => {
    const reportDamageAction = (
      <MobileActionButton
        icon={faHouseChimneyCrack}
        onPress={onStartReportingDamage}
      >
        <FormattedMessage id="eventActions.reportDamage" />
      </MobileActionButton>
    );

    const registerMileageReportAction = (
      <MobileActionButton
        icon={faCabinetFiling}
        onPress={onStartRegisterMileage}
      >
        <FormattedMessage id="eventActions.registerMileageReport" />
      </MobileActionButton>
    );

    const uploadDocumentAction = (
      <MobileActionButton icon={faUpload} onPress={onStartUploadingDocument}>
        <FormattedMessage id="eventActions.uploadDocument" />
      </MobileActionButton>
    );

    const actions: ReactElement[] = [
      reportDamageAction,
      registerMileageReportAction,
      uploadDocumentAction,
    ];

    if (hasForeman && !isForeman) {
      return actions;
    }

    if (!finishedAtCustomer && startedWorking) {
      actions.push(
        <MobileActionButton
          icon={basParaph}
          onPress={onStartFinalizingEventAtCustomer}
        >
          <FormattedMessage id="eventActions.finishWithCustomer" />
        </MobileActionButton>,
      );
    } else if (!startedWorking && startedDriving) {
      actions.push(
        <MobileActionButton
          icon={faPeopleCarryBox}
          onPress={onOpenWorkingOnEventForm}
        >
          <FormattedMessage id="eventActions.startWorking" />
        </MobileActionButton>,
      );
    } else if (!finishedAtCustomer) {
      actions.push(
        <MobileActionButton
          icon={faSteeringWheel}
          onPress={onOpenDrivingToEventForm}
        >
          <FormattedMessage id="eventActions.startDriving" />
        </MobileActionButton>,
      );
    } else {
      actions.push(
        <MobileActionButton
          icon={faCheckCircle}
          onPress={onStartFinalizingEvent}
          disabled={finished}
        >
          <FormattedMessage id="eventActions.finalizeEvent" />
        </MobileActionButton>,
      );
    }

    if (canPayAtCustomer) {
      actions.push(
        <MobileActionButton
          icon={faCashRegister}
          onPress={() =>
            canPayAtCustomer?.canPayForBillingMoment &&
            onStartPayingAtCustomer(canPayAtCustomer?.canPayForBillingMoment)
          }
          disabled={!canPayAtCustomer?.canPayForBillingMoment || loadingInvoice}
        >
          <FormattedMessage id="eventActions.payAtCustomer" />
        </MobileActionButton>,
      );
    }

    return actions;
  }, [
    onStartReportingDamage,
    onStartRegisterMileage,
    onStartUploadingDocument,
    hasForeman,
    isForeman,
    finishedAtCustomer,
    startedWorking,
    startedDriving,
    canPayAtCustomer,
    onStartFinalizingEventAtCustomer,
    onOpenWorkingOnEventForm,
    onOpenDrivingToEventForm,
    onStartFinalizingEvent,
    finished,
    loadingInvoice,
    onStartPayingAtCustomer,
  ]);
