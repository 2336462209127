import * as React from 'react';
import { memo, ReactElement } from 'react';
import { BoxProps } from '@bas/ui/native/base';
import { RequestTimeOffForm } from '../RequestTimeOffForm';

export type ChangeTimeOffRegistrationFormProps = BoxProps & {
  buildUp?: boolean;
};

const ChangeTimeOffRegistrationForm = ({
  buildUp,
}: ChangeTimeOffRegistrationFormProps): ReactElement => (
  <RequestTimeOffForm buildUp={buildUp} />
);

const MemoComponent = memo(ChangeTimeOffRegistrationForm, () => true);
export default MemoComponent;
