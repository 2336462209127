import { Uuid } from '@bas/value-objects';
import * as Yup from 'yup';

export interface PickUpStorageInputType {
  selectedStorageId: Uuid | null;
  currentWarehouseId: Uuid | null;
  currentLocationId: Uuid | null;
  employeeId: Uuid | null;
}

export const pickUpStorageInputTypeDefaultValues =
  (): PickUpStorageInputType => ({
    selectedStorageId: null,
    currentWarehouseId: null,
    currentLocationId: null,
    employeeId: null,
  });

export const pickUpStorageInputTypeValidationBuilder =
  (): Yup.ObjectSchema<PickUpStorageInputType> =>
    Yup.object({
      selectedStorageId: Yup.string().required().label('label.storage'),
      currentWarehouseId: Yup.string()
        .required()
        .label('label.currentWarehouse'),
      currentLocationId: Yup.string()
        .max(0)
        .nullable()
        .ensure()
        .label('label.currentLocation'),
      employeeId: Yup.string().required().label('label.employee'),
    });
