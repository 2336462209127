import { Maintenance } from '@bas/planning-domain/models';
import { formatDate } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ChangeVehicleMaintenanceMutationProps = {
  materialId: Uuid;
  dealer?: string | null;
  leasingCompany?: string | null;
  maintenance?: Maintenance | null;
};

export const ChangeVehicleMaintenanceMutation = async ({
  materialId,
  maintenance,
  ...values
}: ChangeVehicleMaintenanceMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/vehicles/${materialId}/change-maintenance`, {
    materialId,
    maintenance: {
      motNeededAt: maintenance?.motNeededAt
        ? formatDate(maintenance?.motNeededAt)
        : null,
      maintenanceNeededAt: maintenance?.maintenanceNeededAt
        ? formatDate(maintenance?.maintenanceNeededAt)
        : null,
    },
    ...values,
  });

export const useChangeVehicleMaintenanceMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeVehicleMaintenanceMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ChangeVehicleMaintenanceMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeVehicleMaintenanceMutationProps
  >({
    mutationFn: ChangeVehicleMaintenanceMutation,
    throwOnError: false,
    ...options,
  });
