import { Uuid } from '@bas/value-objects';
import dayjs from 'dayjs';
import { v7 } from 'uuid';
import * as Yup from 'yup';

export type TimeTrackingItemInputType = {
  timeEntryId: Uuid;
  timeTypeId: Uuid;
  eventId?: Uuid | null;
  isExisting?: boolean | null;
  project?: {
    projectId?: Uuid | null;
  } | null;
  relation?: {
    relationId?: Uuid | null;
  } | null;
  start?: Date | null;
  end?: Date | null;
  changeOverlappingTimeEntries?: boolean | null;
};

export const timeTrackingItemInputTypeDefaultValues =
  (): TimeTrackingItemInputType => ({
    timeEntryId: v7(),
    timeTypeId: '',
    eventId: '',
    isExisting: false,
    project: undefined,
    relation: undefined,
    start: null,
    end: null,
    changeOverlappingTimeEntries: true,
  });

export const timeTrackingItemInputTypeValidationBuilder =
  (): Yup.ObjectSchema<TimeTrackingItemInputType> =>
    Yup.object({
      timeEntryId: Yup.string().required().label('label.timeEntryId'),
      timeTypeId: Yup.string().required().label('label.timeType'),
      eventId: Yup.string().optional().nullable().label('label.eventId'),
      project: Yup.object({
        projectId: Yup.string().optional().nullable().label('label.project'),
      }).nullable(),
      relation: Yup.object({
        relationId: Yup.string().optional().nullable().label('label.relation'),
      }).nullable(),
      isExisting: Yup.boolean().optional().nullable(),
      start: Yup.date().required().label('label.startTime'),
      end: Yup.date()
        .when(['start'], ([start], schema) => {
          if (!start || !dayjs(start).isValid()) return schema;
          return schema
            .min(dayjs(start).clone().add(1, 'second').toDate())
            .required();
        })
        .label('label.endTime'),
      changeOverlappingTimeEntries: Yup.boolean()
        .optional()
        .nullable()
        .label('label.changeOverlappingTimeEntries'),
    });
