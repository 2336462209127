import { getDefaultCountry } from '@bas/shared/utils';
import {
  Country,
  EmailAddress,
  ErrorResponse,
  PhoneNumber,
  Uuid,
} from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type AddIdentityMutationProps = {
  identityId: Uuid;
  tenantId: Uuid;
  companyName: string;
  logoId?: Uuid | null;
  parentIdentityId?: Uuid | null;
  cocNumber?: string | null;
  vatNumber?: string | null;
  rsinNumber?: string | null;
  ibanNumber?: string | null;
  streetName: string;
  houseNumber: string;
  houseNumberAddition?: string | null;
  zipCode: string;
  city: string;
  country: Country;
  phoneNumber?: PhoneNumber | null;
  mobileNumber?: PhoneNumber | null;
  emailAddress?: EmailAddress | null;
};

export const AddIdentityMutation = async ({
  ...values
}: AddIdentityMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/tenants/{tenantId}/identities`, {
    region: getDefaultCountry(),
    ...values,
  });

export const useAddIdentityMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddIdentityMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  AddIdentityMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddIdentityMutationProps
  >({
    mutationFn: AddIdentityMutation,
    throwOnError: false,
    ...options,
  });
