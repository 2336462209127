import * as Yup from 'yup';
import {
  EmailAddress,
  Gender,
  Language,
  PhoneNumber,
} from '@bas/value-objects';

export interface EmployeeInformationInputType {
  gender?: Gender | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName: string;
  dateOfBirth?: Date | null;
  emailAddress: EmailAddress;
  phoneNumber?: PhoneNumber | null;
  language: Language;
}

export const EmployeeInformationInputTypeDefaultValues =
  (): EmployeeInformationInputType => ({
    gender: Gender.UNKNOWN,
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: null,
    emailAddress: '',
    phoneNumber: '',
    language: Language.NL,
  });

export const EmployeeInformationInputTypeValidatorBuilder =
  (): Yup.ObjectSchema<EmployeeInformationInputType> =>
    Yup.object({
      gender: Yup.mixed<Gender>()
        .oneOf(Object.values(Gender).filter((option) => !!option))
        .nullable()
        .required()
        .label('label.gender'),
      firstName: Yup.string()
        .required()
        .ensure()
        .nullable()
        .label('label.firstName'),
      middleName: Yup.string().nullable().label('label.middleName'),
      lastName: Yup.string().required().ensure().label('label.lastName'),
      dateOfBirth: Yup.date().nullable().required().label('label.dateOfBirth'),
      emailAddress: Yup.string()
        .email()
        .required()
        .ensure()
        .label('label.emailAddress'),
      phoneNumber: Yup.string().phone().nullable().label('label.phoneNumber'),
      language: Yup.mixed<Language>()
        .oneOf(Object.values(Language).filter((option) => !!option))
        .required()
        .label('label.language'),
    });
