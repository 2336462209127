import * as Yup from 'yup';
import {
  HandlingCostsInputType,
  handlingCostsInputTypeDefaultValues,
  handlingCostsInputTypeValidationBuilder,
} from './handlingCostsInputType';

export type OptionalHandlingCostsInputType = {
  handlingCosts: HandlingCostsInputType;
  invoiceHandlingCosts: boolean | null;
};

export const optionalHandlingCostsInputTypeDefaultValues =
  (): OptionalHandlingCostsInputType => ({
    invoiceHandlingCosts: false,
    handlingCosts: handlingCostsInputTypeDefaultValues(),
  });

export const optionalHandlingCostsInputTypeValidationBuilder =
  (): Yup.ObjectSchema<OptionalHandlingCostsInputType> =>
    Yup.object({
      invoiceHandlingCosts: Yup.boolean()
        .label('label.invoiceHandlingCosts')
        .defined()
        .nullable(),
      handlingCosts: handlingCostsInputTypeValidationBuilder().when(
        'invoiceHandlingCosts',
        {
          is: true,
          then: (schema) => schema.required(),
          otherwise: () =>
            Yup.object().nullable() as Yup.ObjectSchema<HandlingCostsInputType>,
        },
      ),
    });
