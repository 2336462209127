import { basAssemble, basDisassemble } from '@bas/svg-icons';
import { EventType } from '@bas/value-objects';
import { faArrowLeftFromBracket } from '@fortawesome/pro-light-svg-icons';
import { faArrowRightToBracket } from '@fortawesome/pro-light-svg-icons/faArrowRightToBracket';
import { faBoxFull } from '@fortawesome/pro-light-svg-icons/faBoxFull';
import { faBoxOpen } from '@fortawesome/pro-light-svg-icons/faBoxOpen';
import { faCalendar } from '@fortawesome/pro-light-svg-icons/faCalendar';
import { faCarWrench } from '@fortawesome/pro-light-svg-icons/faCarWrench';
import { faClipboardListCheck } from '@fortawesome/pro-light-svg-icons/faClipboardListCheck';
import { faDesktopAlt } from '@fortawesome/pro-light-svg-icons/faDesktopAlt';
import { faDolly } from '@fortawesome/pro-light-svg-icons/faDolly';
import { faFaceThermometer } from '@fortawesome/pro-light-svg-icons/faFaceThermometer';
import { faForklift } from '@fortawesome/pro-light-svg-icons/faForklift';
import { faHandsHelping } from '@fortawesome/pro-light-svg-icons/faHandsHelping';
import { faInventory } from '@fortawesome/pro-light-svg-icons/faInventory';
import { faIslandTropical } from '@fortawesome/pro-light-svg-icons/faIslandTropical';
import { faLoveseat } from '@fortawesome/pro-light-svg-icons/faLoveseat';
import { faMoneyCheckEditAlt } from '@fortawesome/pro-light-svg-icons/faMoneyCheckEditAlt';
import { faQuestion } from '@fortawesome/pro-light-svg-icons/faQuestion';
import { faRecycle } from '@fortawesome/pro-light-svg-icons/faRecycle';
import { faSign } from '@fortawesome/pro-light-svg-icons/faSign';
import { faSteeringWheel } from '@fortawesome/pro-light-svg-icons/faSteeringWheel';
import { faTruck } from '@fortawesome/pro-light-svg-icons/faTruck';
import { faTruckRamp } from '@fortawesome/pro-light-svg-icons/faTruckRamp';
import { faTruckRampBox } from '@fortawesome/pro-light-svg-icons/faTruckRampBox';
import { faUserDoctor } from '@fortawesome/pro-light-svg-icons/faUserDoctor';
import { faWarehouseAlt } from '@fortawesome/pro-light-svg-icons/faWarehouseAlt';
import { FontAwesomeIcon, Props } from '@fortawesome/react-native-fontawesome';
import { ReactElement, useMemo } from 'react';

const eventIconMap = {
  [EventType.UNKNOWN]: faQuestion,
  [EventType.DAY_OFF_EVENT]: faLoveseat,
  [EventType.HRM_OTHER_ABSENCE_EVENT]: faUserDoctor,
  [EventType.SICK_EVENT]: faFaceThermometer,
  [EventType.VACATION_EVENT]: faIslandTropical,
  [EventType.INSPECTION_EVENT]: faClipboardListCheck,
  [EventType.MAINTENANCE_EVENT]: faCarWrench,
  [EventType.MATERIAL_OTHER_ABSENCE_EVENT]: faCarWrench,
  [EventType.ASSEMBLE_EVENT]: basAssemble,
  [EventType.DELIVER_BOXES_EVENT]: faBoxOpen,
  [EventType.DISASSEMBLE_EVENT]: basDisassemble,
  [EventType.DISPOSING_OF_WASTE_EVENT]: faRecycle,
  [EventType.LOAD_STORAGE_EVENT]: faArrowRightToBracket,
  [EventType.MOVING_LOADING_EVENT]: faArrowRightToBracket,
  [EventType.MOVING_EVENT]: faTruck,
  [EventType.MOVING_LIFT_EVENT]: faForklift,
  [EventType.PACKING_EVENT]: faBoxFull,
  [EventType.PICKUP_BOXES_EVENT]: faBoxOpen,
  [EventType.POST_ROAD_SIGNS_EVENT]: faSign,
  [EventType.PREPARE_HOUSE_ACCORDING_TO_QUOTE_EVENT]: faClipboardListCheck,
  [EventType.REMOVE_ROAD_SIGNS_EVENT]: faSign,
  [EventType.UNLOAD_STORAGE_EVENT]: faWarehouseAlt,
  [EventType.MOVING_UNLOADING_EVENT]: faArrowLeftFromBracket,
  [EventType.UNPACKING_EVENT]: faBoxOpen,
  [EventType.INTAKE_EVENT]: faMoneyCheckEditAlt,
  [EventType.CUSTOM_EVENT]: faCalendar,
  [EventType.LENDING_STAFF_EVENT]: faHandsHelping,
  [EventType.LIFT_WORK_EVENT]: faForklift,
  [EventType.TRANSPORT_JOB_PICKUP]: faTruckRampBox,
  [EventType.TRANSPORT_JOB_DELIVER]: faTruckRamp,
  [EventType.DRIVING_DAY]: faSteeringWheel,
  [EventType.OFFICE_WORK_EVENT]: faDesktopAlt,
  [EventType.PROJECT_EVENT]: faCalendar,
  [EventType.TRANSPORT_EVENT]: faDolly,
  [EventType.WAREHOUSE_WORK_EVENT]: faInventory,
};

export type EventIconProps = Omit<Props, 'icon'> & {
  eventType: EventType;
};

const EventIcon = ({ eventType, ...props }: EventIconProps): ReactElement => {
  const icon = useMemo(() => {
    let result = faQuestion;
    if (eventIconMap[eventType]) {
      result = eventIconMap[eventType];
    }
    return result;
  }, [eventType]);

  return <FontAwesomeIcon icon={icon} {...props} />;
};

export default EventIcon;
