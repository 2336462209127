import { isTask } from './Task';
import type { TaskRegardingRelation } from '../types';

export function isTaskRegardingRelation(
  object: unknown,
): object is TaskRegardingRelation {
  return (
    !!object &&
    isTask(object) &&
    Object.prototype.hasOwnProperty.call(object, 'relationId') &&
    Object.prototype.hasOwnProperty.call(object, 'relationName')
  );
}
