import { EventType } from '@bas/value-objects';

export const showMovingBoxesEventTypes = [
  EventType.DELIVER_BOXES_EVENT,
  EventType.PICKUP_BOXES_EVENT,
  EventType.MOVING_EVENT,
  EventType.LOAD_STORAGE_EVENT,
  EventType.UNLOAD_STORAGE_EVENT,
  EventType.MOVING_LOADING_EVENT,
  EventType.MOVING_UNLOADING_EVENT,
  EventType.PACKING_EVENT,
  EventType.UNPACKING_EVENT,
];
