import { isTask } from './Task';
import type { CertificateWasNotSyncedTask } from '../types';
import { TaskType } from '../types';

export function isCertificateWasNotSyncedTask(
  object: unknown,
): object is CertificateWasNotSyncedTask {
  return (
    !!object &&
    isTask(object) &&
    object.taskType === TaskType.CERTIFICATE_WAS_NOT_SYNCED_TASK
  );
}
