import { formatDate } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ChangeExcludedDatesOfRecurringEventMutationProps = {
  eventId: Uuid;
  excludedDates: Date[];
};

export const ChangeExcludedDatesOfRecurringEventMutation = async ({
  eventId,
  excludedDates,
  ...values
}: ChangeExcludedDatesOfRecurringEventMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/events/${eventId}/change-excluded-dates`, {
    eventId,
    excludedDates: excludedDates.map((date) => formatDate(date)),
    ...values,
  });

export const useChangeExcludedDatesOfRecurringEventMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeExcludedDatesOfRecurringEventMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ChangeExcludedDatesOfRecurringEventMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeExcludedDatesOfRecurringEventMutationProps
  >({
    mutationFn: ChangeExcludedDatesOfRecurringEventMutation,
    throwOnError: false,
    ...options,
  });
