import { isInvoiceEvent } from './InvoiceEvent';
import { InvoiceEventType, InvoicePaymentProcessedEvent } from '../types';

export function isInvoicePaymentProcessedEvent(
  object: unknown,
): object is InvoicePaymentProcessedEvent {
  return (
    isInvoiceEvent(object) &&
    object.eventType === InvoiceEventType.InvoicePaymentProcessedEvent
  );
}
