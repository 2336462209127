import { formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ApproveTimeOffRegistrationMutationProps = {
  timeOffId: Uuid;
  approvedById: Uuid;
  approvedOn: Date;
  approvedReason: string;
  approvedStart: Date;
  approvedEnd: Date;
  approvedFullDay: boolean;
  approvedEffectiveHours: number;
  timeOffTypeId: Uuid;
  daysOff: {
    dayOffId: Uuid;
    start: Date;
    end: Date;
    hours: number;
    approvedStart?: Date | null;
    approvedEnd?: Date | null;
    approvedHours?: number | null;
  }[];
};

export const ApproveTimeOffRegistrationMutation = async ({
  timeOffId,
  approvedOn,
  approvedStart,
  approvedEnd,
  daysOff,
  ...values
}: ApproveTimeOffRegistrationMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/hrm/time-off-registrations/${timeOffId}/approve`, {
    timeOffId,
    approvedOn: formatDateTime(approvedOn),
    approvedStart: formatDateTime(approvedStart),
    approvedEnd: formatDateTime(approvedEnd),
    daysOff: daysOff.map((dayOff) => ({
      ...dayOff,
      start: formatDateTime(dayOff.start),
      end: formatDateTime(dayOff.end),
      approvedStart: dayOff.approvedStart
        ? formatDateTime(dayOff.approvedStart)
        : null,
      approvedEnd: dayOff.approvedEnd
        ? formatDateTime(dayOff.approvedEnd)
        : null,
    })),
    ...values,
  });

export const useApproveTimeOffRegistrationMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ApproveTimeOffRegistrationMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ApproveTimeOffRegistrationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ApproveTimeOffRegistrationMutationProps
  >({
    mutationFn: ApproveTimeOffRegistrationMutation,
    throwOnError: false,
    ...options,
  });
