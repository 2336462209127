import type { ObjectResponse, Uuid } from '@bas/value-objects';
import type { ServiceType } from '@bas/shared/models';

export interface MovingJobFurniture extends ObjectResponse {
  furnitureId: Uuid;
  checked: boolean;
  inStorageId: Uuid;
  services: ServiceType[];
  finishedServices: ServiceType[];
}
