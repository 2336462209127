import { TransportJobCargoInputType } from '@bas/project-domain/input-types';
import { ProjectEstimate } from '@bas/project-domain/models';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type UpdateCargoOfTransportJobMutationProps = {
  projectId: Uuid;
  loads: TransportJobCargoInputType[];
};

export const UpdateCargoOfTransportJobMutation = async ({
  projectId,
  ...values
}: UpdateCargoOfTransportJobMutationProps): Promise<
  AxiosResponse<ProjectEstimate>
> =>
  axios.put(`api/{tenantId}/transport-jobs/${projectId}/update-cargo`, {
    projectId,
    ...values,
  });

export const useUpdateCargoOfTransportJobMutation = (
  options: UseMutationOptions<
    AxiosResponse<ProjectEstimate>,
    AxiosError<ErrorResponse>,
    UpdateCargoOfTransportJobMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse<ProjectEstimate>,
  AxiosError<ErrorResponse>,
  UpdateCargoOfTransportJobMutationProps
> =>
  useMutation<
    AxiosResponse<ProjectEstimate>,
    AxiosError<ErrorResponse>,
    UpdateCargoOfTransportJobMutationProps
  >({
    mutationFn: UpdateCargoOfTransportJobMutation,
    throwOnError: false,
    ...options,
  });
