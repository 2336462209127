import { colors } from '@bas/theme';
import {
  AnimatedView,
  Box,
  Icon,
  IconProps,
  IconWithSpin,
  ShopifyTheme,
  TouchableOpacity,
  TouchableWithoutFeedbackProps,
  Typography,
  TypographyProps,
} from '@bas/ui/native/base';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons/faSpinnerThird';
import { BoxProps } from '@shopify/restyle';
import { ReactElement, ReactNode, useCallback, useMemo, useState } from 'react';
import {
  GestureResponderEvent,
  LayoutChangeEvent,
  LayoutRectangle,
  View,
  ViewStyle,
} from 'react-native';
import {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import styles from './styles';

export type ButtonProps = Omit<TouchableWithoutFeedbackProps, 'children'> &
  BoxProps<ShopifyTheme> &
  Omit<TypographyProps, 'variant'> & {
    children?: ReactNode;
    variant?: 'contained' | 'outlined' | 'text';
    buttonColor?: 'primary' | 'secondary' | 'tertiary' | 'warning' | 'danger';
    style?: ViewStyle;
    loading?: boolean;
    showLabelOnLoading?: boolean;
    overrideColor?: string;
    icon?: IconProp;
    loadingIcon?: IconProp;
    iconProps?: Omit<IconProps, 'icon'>;
    append?: ReactNode;
    forceLongPress?: boolean;
    longPressLoaderColor?: string;
  };

const Button = ({
  style,
  children,
  onPressIn,
  onPressOut,
  variant,
  loading,
  disabled,
  overrideColor,
  color,
  icon,
  showLabelOnLoading,
  loadingIcon,
  iconProps,
  buttonColor,
  hitSlop,
  append,
  forceLongPress,
  onLayout,
  longPressLoaderColor,
  ...props
}: ButtonProps): ReactElement => {
  const styling = useMemo(() => {
    if (variant === 'text') {
      return [
        styles.textButton,
        style,
        styles.baseButton,
        buttonColor === 'secondary' && styles.textButtonSecondary,
        buttonColor === 'warning' && styles.textButtonWarning,
      ];
    }

    if (variant === 'outlined') {
      return [
        styles.outlinedButton,
        style,
        styles.baseButton,
        buttonColor === 'secondary' && styles.outlinedButtonSecondary,
        buttonColor === 'warning' && styles.outlinedButtonWarning,
        buttonColor === 'primary' && disabled && styles.disabledOutlinedButton,
      ];
    }

    return [
      styles.button,
      style,
      styles.baseButton,
      buttonColor === 'primary' && disabled && styles.disabledButton,
      buttonColor === 'secondary' && styles.buttonSecondary,
      buttonColor === 'warning' && styles.buttonWarning,
      buttonColor === 'danger' && styles.buttonDanger,
      buttonColor === 'warning' && disabled && styles.disabledButtonWarning,
    ];
  }, [disabled, style, variant, buttonColor]);

  const textColor = useMemo(() => {
    if (overrideColor) {
      return overrideColor;
    }

    if (buttonColor === 'secondary') {
      return colors.lila[700];
    }

    if (buttonColor === 'warning') {
      return colors.white;
    }

    if (variant === 'outlined') {
      if (disabled) {
        return colors.lila[400];
      }

      return colors.blue[500];
    }

    if (variant === 'text') {
      return colors.lila[800];
    }

    return colors.white;
  }, [buttonColor, overrideColor, variant, disabled]);

  const [layout, setLayout] = useState<LayoutRectangle>();

  const maxRight = useMemo(() => {
    if (!layout) {
      return 0;
    }

    return layout.width;
  }, [layout]);

  const width = useSharedValue(0);

  const startAnimation = useCallback(() => {
    width.value = withTiming(maxRight, { duration: 500 });
  }, [maxRight, width]);

  const resetAnimation = useCallback(() => {
    width.value = withTiming(0, { duration: 100 });
  }, [width]);

  const handlePressIn = useCallback(
    (e: GestureResponderEvent) => {
      if (onPressIn) {
        onPressIn(e);
      }

      if (forceLongPress) {
        startAnimation();
      }
    },
    [forceLongPress, onPressIn, startAnimation],
  );

  const handlePressOut = useCallback(
    (e: GestureResponderEvent) => {
      if (onPressOut) {
        onPressOut(e);
      }

      if (forceLongPress) {
        resetAnimation();
      }
    },
    [forceLongPress, onPressOut, resetAnimation],
  );

  const handleLayout = useCallback(
    (e: LayoutChangeEvent) => {
      if (onLayout) {
        onLayout(e);
      }

      if (forceLongPress) {
        setLayout(e.nativeEvent.layout);
      }
    },
    [forceLongPress, onLayout],
  );

  const animatedStyle = useAnimatedStyle(() => ({
    width: width.value,
  }));

  return (
    <TouchableOpacity
      disabled={loading || disabled}
      onPressIn={handlePressIn}
      onPressOut={handlePressOut}
      activeOpacity={forceLongPress ? 1 : undefined}
      onLayout={handleLayout}
      {...props}
    >
      <Box style={styles.container} {...props}>
        {forceLongPress && (
          <Box
            position="absolute"
            top={0}
            left={0}
            bottom={0}
            right={0}
            borderRadius={5}
            zIndex={2}
          >
            <AnimatedView
              style={[
                {
                  backgroundColor:
                    longPressLoaderColor || 'rgba(58, 120, 242, 0.2)',
                  height: '100%',
                },
                animatedStyle,
              ]}
            />
          </Box>
        )}
        <View style={styling}>
          {loading ? (
            <Box
              flexDirection="row"
              alignContent="center"
              justifyContent="center"
            >
              <Box
                style={showLabelOnLoading ? styles.icon : undefined}
                justifyContent="center"
              >
                <IconWithSpin
                  size={18}
                  icon={loadingIcon || faSpinnerThird}
                  overrideColor={color ? undefined : textColor}
                  color={color}
                  secondaryColor={colors.blue[800]}
                />
              </Box>
              {showLabelOnLoading && (
                <>
                  <Typography
                    fontWeight={props.fontWeight || 700}
                    fontSize={props.fontSize || undefined}
                    overrideColor={color ? undefined : textColor}
                    color={color}
                    style={styles.text}
                  >
                    {children}
                  </Typography>

                  {append}
                </>
              )}
            </Box>
          ) : (
            <Box
              flexDirection="row"
              alignContent="center"
              justifyContent="center"
            >
              {icon && (
                <Box style={styles.icon} justifyContent="center">
                  <Icon
                    icon={icon}
                    color={color}
                    overrideColor={color ? undefined : textColor}
                    {...(iconProps || {})}
                  />
                </Box>
              )}
              {children && (
                <Typography
                  fontWeight={props.fontWeight || 700}
                  fontSize={props.fontSize || undefined}
                  overrideColor={color ? undefined : textColor}
                  color={color}
                  style={styles.text}
                >
                  {children}
                </Typography>
              )}
              {append}
            </Box>
          )}
        </View>
      </Box>
    </TouchableOpacity>
  );
};

export default Button;
