import { useDocumentTemplateByTemplateIdRequest } from '@bas/tenant-domain/requests';
import { Box } from '@bas/ui/native/base';
import { ReactHookFormTextField } from '@bas/ui/native/molecules';
import { Language, Uuid } from '@bas/value-objects';
import { useTheme } from '@shopify/restyle';
import { ReactElement, ReactNode, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useWindowDimensions } from 'react-native';
import { NestableScrollContainer } from 'react-native-draggable-flatlist';
import { ScrollView } from 'react-native-gesture-handler';
import { FinancialDocumentLinesEditor } from '../FinancialDocumentLinesEditor';

export type FinancialDocumentContentFormPartProps = {
  templateId: Uuid;
  language: Language;
  documentType: 'invoice' | 'quote';
  prefix?: string;
  light?: boolean;
  forceMobile?: boolean;
  children?: ReactNode | ReactNode[];
};

const FinancialDocumentContentFormPart = ({
  templateId,
  language,
  documentType,
  prefix,
  light,
  children,
  forceMobile,
}: FinancialDocumentContentFormPartProps): ReactElement => {
  const { formatMessage } = useIntl();
  const getPrefixedName = useCallback(
    (name: string) => {
      if (prefix) {
        return `${prefix}.${name}`;
      }

      return name;
    },
    [prefix],
  );

  const { data: templateData } = useDocumentTemplateByTemplateIdRequest({
    documentTemplateId: templateId,
  });

  const currentTemplate = useMemo(
    () => templateData?.data,
    [templateData?.data],
  );

  const currentTranslation = useMemo(
    () =>
      currentTemplate?.translations?.find(
        (translation) => translation.language === language,
      ),
    [currentTemplate?.translations, language],
  );

  const theme = useTheme();
  const dimensions = useWindowDimensions();

  const isMobile = useMemo(
    () => dimensions.width < theme.breakpoints.tablet.width,
    [dimensions.width, theme.breakpoints.tablet.width],
  );

  if (isMobile) {
    return (
      <ScrollView>
        <Box
          flexDirection="row"
          flex={1}
          flexWrap="wrap"
          pb={7}
          height="100%"
          minHeight="100%"
        >
          <Box
            flex={1}
            flexDirection="row"
            flexWrap="wrap"
            style={{ marginLeft: -24, marginTop: -24 }}
          >
            {currentTranslation?.freeTextFields.map((freeTextField) => (
              <Box
                flexBasis="50%"
                width="50%"
                pt={3}
                pl={3}
                key={freeTextField}
              >
                <ReactHookFormTextField
                  name={getPrefixedName(`freeText.${freeTextField}`)}
                  autoComplete="off"
                  label={
                    !Number.isNaN(Number.parseInt(freeTextField, 10) + 1)
                      ? formatMessage(
                          {
                            id: 'label.freeTextTitle',
                          },
                          {
                            index: Number.parseInt(freeTextField, 10) + 1,
                          },
                        )
                      : freeTextField
                  }
                  multiline
                  light={light}
                />
              </Box>
            ))}
            <Box flexBasis="100%" width="100%" pt={3} pl={3}>
              <FinancialDocumentLinesEditor
                documentType={documentType}
                light={light}
              />
            </Box>
            <Box flexBasis="100%" width="100%" pt={3} pl={3}>
              {children}
            </Box>
          </Box>
        </Box>
      </ScrollView>
    );
  }

  return (
    <Box
      flexDirection="row"
      flex={1}
      flexWrap="wrap"
      pb={7}
      height="100%"
      minHeight="100%"
    >
      <Box flex={1} height="100%">
        <NestableScrollContainer>
          <Box
            height="100%"
            flex={1}
            flexDirection="row"
            flexWrap="wrap"
            style={{ marginLeft: -24, marginTop: -24 }}
          >
            {currentTranslation?.freeTextFields.map((freeTextField) => (
              <Box
                flexBasis="50%"
                width="50%"
                pl={3}
                pt={3}
                key={freeTextField}
              >
                <ReactHookFormTextField
                  name={getPrefixedName(`freeText.${freeTextField}`)}
                  autoComplete="off"
                  label={
                    !Number.isNaN(Number.parseInt(freeTextField, 10) + 1)
                      ? formatMessage(
                          {
                            id: 'label.freeTextTitle',
                          },
                          {
                            index: Number.parseInt(freeTextField, 10) + 1,
                          },
                        )
                      : freeTextField
                  }
                  multiline
                  light={light}
                />
              </Box>
            ))}
            <Box flexBasis="100%" width="100%" pl={3} pt={3}>
              <FinancialDocumentLinesEditor
                documentType={documentType}
                light={light}
                forceMobile={forceMobile}
              />
            </Box>
          </Box>
        </NestableScrollContainer>
      </Box>

      {children && (
        <Box width={420} height="100%" pl={3}>
          <ScrollView>{children}</ScrollView>
        </Box>
      )}
    </Box>
  );
};

export default FinancialDocumentContentFormPart;
