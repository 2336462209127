import { ComponentClass, ReactNode, RefObject } from 'react';
import {
  NativeSyntheticEvent,
  TextInput,
  TextInputFocusEventData,
  TextInputProps,
} from 'react-native';
import { AnimatedProps } from 'react-native-reanimated';

export type TextFieldProps = TextInputProps & {
  light?: boolean;
  disabled?: boolean;
  error?: boolean;
  variant?: 'outlined' | 'filled' | 'standard';
  label?: string;
  validationMessage?: string;
  helperText?: string;
  InputComponent?: ComponentClass<AnimatedProps<TextInputProps>>;
  trailingAccessory?: ReactNode;
  suffix?: string;
  prefix?: string;
  updateOnBlur?: boolean;
  inputRef?: RefObject<TextInput>;
};

export type TextFieldPropsForRealInput = TextFieldProps & {
  labelColor: string;
  textColor: string;
  borderColor: string;
  handleFocusChange: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void;
  inputRef?: RefObject<TextInput>;
};
