import { Uuid } from '@bas/value-objects';
import * as Yup from 'yup';

export interface MileageReportInputType {
  materialId: Uuid;
  mileageStart?: number | null;
  mileageEnd?: number | null;
  projectId?: Uuid;
  eventId?: Uuid;
  reportStartDate?: Date | null;
  reportEndDate?: Date | null;
}

export const MileageReportInputTypeDefaultValues =
  (): MileageReportInputType => ({
    materialId: '',
    mileageStart: null,
    mileageEnd: null,
    projectId: undefined,
    eventId: undefined,
    reportStartDate: null,
    reportEndDate: null,
  });

export const MileageReportInputTypeValidationBuilder =
  (): Yup.ObjectSchema<MileageReportInputType> =>
    Yup.object({
      materialId: Yup.string().required().label('label.vehicle'),
      projectId: Yup.string(),
      eventId: Yup.string(),
      mileageStart: Yup.number().nullable().label('label.startMileage'),
      mileageEnd: Yup.number().nullable().label('label.endMileage'),
      reportStartDate: Yup.date().nullable().label('label.reportStartDate'),
      reportEndDate: Yup.date().nullable().label('label.reportEndDate'),
    });
