import { AddressInputType } from '@bas/shared/input-types';
import {
  Country,
  EmailAddress,
  ErrorResponse,
  Money,
  PhoneNumber,
  Uuid,
} from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type AddNewSupplierToInventoryItemMutationProps = {
  inventoryItemId: Uuid;
  supplierId: Uuid;
  purchasePrice: Money;
  name: string;
  website?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName: string;
  phoneNumber?: PhoneNumber | null;
  mobileNumber?: PhoneNumber | null;
  emailAddress?: EmailAddress | null;
  address: AddressInputType;
  region: Country;
};

export const AddNewSupplierToInventoryItemMutation = async ({
  inventoryItemId,
  ...values
}: AddNewSupplierToInventoryItemMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/inventory/item/${inventoryItemId}/add-new-supplier`,
    {
      inventoryItemId,
      ...values,
    },
  );

export const useAddNewSupplierToInventoryItemMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddNewSupplierToInventoryItemMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  AddNewSupplierToInventoryItemMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddNewSupplierToInventoryItemMutationProps
  >({
    mutationFn: AddNewSupplierToInventoryItemMutation,
    throwOnError: false,
    ...options,
  });
