import {
  ErrorResponse,
  ProjectEstimateItemGroupType,
  ProjectEstimateItemType,
  Uuid,
} from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type SkipQuoteOfProjectMutationProps = {
  projectId: Uuid;
  estimateItemValues: {
    groupType: ProjectEstimateItemGroupType;
    itemType: ProjectEstimateItemType;
    quantity: number | null | undefined;
    extraData: Record<string, string>;
  }[];
};

export const SkipQuoteOfProjectMutation = async ({
  projectId,
  ...values
}: SkipQuoteOfProjectMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/projects/${projectId}/skip-quote`, {
    projectId,
    ...values,
  });

export const useSkipQuoteOfProjectMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    SkipQuoteOfProjectMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  SkipQuoteOfProjectMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    SkipQuoteOfProjectMutationProps
  >({
    mutationFn: SkipQuoteOfProjectMutation,
    throwOnError: false,
    ...options,
  });
