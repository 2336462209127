import { PublicRoomType } from '@bas/shared/models';
import { FillingInRoomWizardInputType } from '@bas/taxation-tool-domain/input-types';
import { RenderTranslatedName } from '@bas/ui/native/atoms';
import { Typography } from '@bas/ui/native/base';
import { ReactElement } from 'react';
import { Control, useWatch } from 'react-hook-form';

export type NavigationStepsRoomNameLabelProps = {
  room?: PublicRoomType;
  control?: Control<FillingInRoomWizardInputType>;
};

const NavigationStepsRoomNameLabel = ({
  room,
  control,
}: NavigationStepsRoomNameLabelProps): ReactElement => {
  const name = useWatch<FillingInRoomWizardInputType, 'name'>({
    name: 'name',
    control,
  });

  const originalRoomName = room && (
    <RenderTranslatedName
      color="white"
      translations={room.translatedNames}
      fallback={room.roomTypeName}
    />
  );

  return <Typography color="white">{name || originalRoomName}</Typography>;
};

export default NavigationStepsRoomNameLabel;
