import { isTask } from './Task';
import type { SendingQuoteFailedTask } from '../types';
import { TaskType } from '../types';

export function isSendingQuoteFailedTask(
  object: unknown,
): object is SendingQuoteFailedTask {
  return (
    !!object &&
    isTask(object) &&
    object.taskType === TaskType.SENDING_QUOTE_FAILED_TASK
  );
}
