import { Language } from '@bas/value-objects';
import * as Yup from 'yup';

export interface LanguageInputType {
  language: Language;
}

export const LanguageInputTypeDefaultValues = (): LanguageInputType => ({
  language: Language.NL,
});

export const LanguageInputTypeValidationBuilder =
  (): Yup.ObjectSchema<LanguageInputType> =>
    Yup.object({
      language: Yup.mixed<Language>()
        .oneOf(Object.values(Language))
        .required()
        .label('label.language'),
    });
