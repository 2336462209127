import {
  isQrCode,
  isStorageLocationQrCode,
  isStorageQrCode,
} from '@bas/shared/models';
import { Alert, Skeleton } from '@bas/ui/native/atoms';
import { Box, Typography } from '@bas/ui/native/base';
import { QrCodeScanner } from '@bas/ui/native/organisms';
import { MoveStorageInputType } from '@bas/wms-domain/input-types';
import {
  useStorageByStorageIdRequestQuery,
  useStorageLocationByStorageLocationIdRequestQuery,
  useWarehouseByWarehouseIdRequestQuery,
} from '@bas/wms-domain/requests';
import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { ScrollView } from 'react-native-gesture-handler';
import type { Code } from 'react-native-vision-camera';

const MoveStorageForm = (): ReactElement => {
  const selectedStorageId = useWatch<MoveStorageInputType, 'selectedStorageId'>(
    {
      name: 'selectedStorageId',
    },
  );

  const newLocationId = useWatch<MoveStorageInputType, 'newLocationId'>({
    name: 'newLocationId',
  });

  const { setValue } = useFormContext<MoveStorageInputType>();

  const { data: storageData, isFetching: isLoadingStorage } =
    useStorageByStorageIdRequestQuery(
      {
        storageId: selectedStorageId || '',
      },
      {
        enabled: !!selectedStorageId,
        gcTime: 0,
      },
    );

  const selectedStorage = useMemo(() => {
    if (storageData?.data === undefined) {
      return null;
    }

    return storageData?.data;
  }, [storageData?.data]);

  const { data: warehouseData, isFetching: isLoadingWarehouse } =
    useWarehouseByWarehouseIdRequestQuery(
      {
        warehouseId: selectedStorage?.warehouseId || '',
      },
      {
        enabled: !!selectedStorage?.warehouseId,
      },
    );

  const { data: currentLocationData, isFetching: isLoadingCurrentLocation } =
    useStorageLocationByStorageLocationIdRequestQuery(
      {
        storageLocationId: selectedStorage?.storageLocationId || '',
      },
      {
        enabled: !!selectedStorage?.storageLocationId,
      },
    );

  const { data: selectedLocationData, isFetching: isLoadingSelectedLocation } =
    useStorageLocationByStorageLocationIdRequestQuery(
      {
        storageLocationId: newLocationId || '',
      },
      {
        enabled: !!newLocationId,
      },
    );

  const currentLocation = useMemo(() => {
    if (currentLocationData?.data === undefined) {
      return null;
    }

    return currentLocationData?.data;
  }, [currentLocationData?.data]);

  const selectedLocation = useMemo(() => {
    if (selectedLocationData?.data === undefined) {
      return null;
    }

    return selectedLocationData?.data;
  }, [selectedLocationData?.data]);

  const warehouse = useMemo(() => {
    if (warehouseData?.data === undefined) {
      return null;
    }

    return warehouseData?.data;
  }, [warehouseData?.data]);

  const handleScannedCode = useCallback(
    (code: Code) => {
      const parsed = JSON.parse(code.value || '{}');
      if (!isQrCode(parsed)) {
        return false;
      }

      if (isStorageQrCode(parsed)) {
        if (selectedStorageId !== parsed.storageId) {
          setValue('selectedStorageId', parsed.storageId, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          });
          return true;
        }
      }

      if (isStorageLocationQrCode(parsed)) {
        if (newLocationId !== parsed.locationId) {
          setValue('newLocationId', parsed.locationId, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          });

          return true;
        }
      }

      return false;
    },
    [newLocationId, selectedStorageId, setValue],
  );

  useEffect(() => {
    if (selectedStorageId && selectedStorage?.storageId === selectedStorageId) {
      setValue('currentWarehouseId', selectedStorage.warehouseId, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
      setValue('currentLocationId', selectedStorage.storageLocationId, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
    }
  }, [selectedStorageId, selectedStorage, setValue]);

  useEffect(() => {
    if (selectedLocation) {
      setValue('newWarehouseId', selectedLocation.warehouseId, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
    }
  }, [selectedLocation, setValue]);

  const qrCodeScanner = useMemo(
    () => <QrCodeScanner onScannedCode={handleScannedCode} />,
    [handleScannedCode],
  );

  return (
    <Box height="100%">
      <Box width="100%" pb={3}>
        <Typography
          color="white"
          variant="h4"
          fontWeight={700}
          textAlign="center"
        >
          <FormattedMessage id="label.moveStorageToANewLocation" />
        </Typography>
        <Typography variant="h5" color="white" textAlign="center">
          {!selectedStorageId && (
            <FormattedMessage id="label.noStorageSelected" />
          )}
          {!selectedStorage && selectedStorageId && isLoadingStorage && (
            <Skeleton light width={400} height={20} />
          )}

          {!isLoadingStorage &&
            !!selectedStorage &&
            `${selectedStorage.contentOwnerName} (${selectedStorage.storageCode})`}
        </Typography>
      </Box>
      <Box
        height={{
          phone: '40%',
          tablet: '60%',
        }}
        width="100%"
      >
        {qrCodeScanner}
      </Box>
      <ScrollView
        style={{
          flex: 1,
        }}
        contentContainerStyle={{
          flexGrow: 1,
        }}
      >
        <Box pt={3} pb={3} paddingHorizontal={20}>
          {!selectedStorageId && (
            <Box width="100%" flex={1}>
              <Alert severity="info">
                <FormattedMessage id="label.scanAQrCodeOfAStorage" />
              </Alert>
            </Box>
          )}
          {selectedStorage && (
            <Box flexDirection="row" width="100%" flexWrap="wrap" rowGap={3}>
              {!selectedStorage.warehouseId && (
                <Box width="100%">
                  <Alert severity="warning">
                    <FormattedMessage id="label.storageIsNotReturnedToAWarehouse" />
                  </Alert>
                  <Typography variant="body1" color="white" pt={1}>
                    <FormattedMessage id="label.pleaseGoBackAndReturnTheStorageToAWarehouse" />
                  </Typography>
                </Box>
              )}
              {selectedStorage.warehouseId &&
                selectedLocation &&
                selectedLocation.warehouseId !==
                  selectedStorage.warehouseId && (
                  <Box width="100%" pb={3}>
                    <Alert severity="warning">
                      <FormattedMessage id="label.locationIsNotInTheWarehouseOfTheStorage" />
                    </Alert>
                  </Box>
                )}
              {selectedStorage.warehouseId && (
                <Box
                  width={{ phone: '100%', tablet: '50%' }}
                  pr={{ phone: 0, tablet: 1 }}
                >
                  <Typography variant="subtitle1" color="white">
                    <FormattedMessage id="label.currentLocation" />
                  </Typography>
                  {selectedStorage.warehouseId && isLoadingWarehouse && (
                    <Skeleton light width={400} height={20} />
                  )}
                  {!isLoadingWarehouse && !!warehouse && (
                    <Typography variant="body1" color="white">
                      <FormattedMessage id="label.warehouse" />:{' '}
                      {warehouse.name}
                    </Typography>
                  )}

                  {!selectedStorage.storageLocationId && (
                    <Typography variant="body1" color="white">
                      <FormattedMessage id="label.storageCurrentlyNotInALocation" />
                    </Typography>
                  )}

                  {selectedStorage.storageLocationId &&
                    isLoadingCurrentLocation && (
                      <Skeleton light width={400} height={20} />
                    )}

                  {currentLocation && (
                    <>
                      <Typography variant="body1" color="white">
                        {currentLocation.name} ({currentLocation.code})
                      </Typography>
                      <Typography variant="body1" color="white">
                        {currentLocation.description}
                      </Typography>
                    </>
                  )}
                </Box>
              )}
              {selectedStorage.warehouseId && (
                <Box
                  width={{ phone: '100%', tablet: '50%' }}
                  pl={{ phone: 0, tablet: 1 }}
                >
                  <Typography variant="subtitle1" color="white">
                    <FormattedMessage id="label.newLocation" />
                  </Typography>
                  {!newLocationId && (
                    <Alert severity="info">
                      <FormattedMessage id="label.scanALocationQrCode" />
                    </Alert>
                  )}
                  {newLocationId && isLoadingSelectedLocation && (
                    <Skeleton light width={400} height={20} />
                  )}
                  {!isLoadingSelectedLocation && !!selectedLocation && (
                    <>
                      <Typography variant="body1" color="white">
                        <FormattedMessage id="label.warehouse" />:{' '}
                        {selectedLocation.warehouseName}
                      </Typography>
                      <Typography variant="body1" color="white">
                        {selectedLocation.name} ({selectedLocation.code})
                      </Typography>
                      <Typography variant="body1" color="white">
                        {selectedLocation.description}
                      </Typography>
                    </>
                  )}
                </Box>
              )}
            </Box>
          )}
        </Box>
      </ScrollView>
    </Box>
  );
};

export default MoveStorageForm;
