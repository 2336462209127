import { Box } from '@bas/ui/native/base';
import { StartDrivingForm, StartDrivingFormProps } from '../StartDrivingForm';

export type StartDrivingStepProps = StartDrivingFormProps;

const StartDrivingStep = ({ ...props }: StartDrivingStepProps) => (
  <Box>
    <StartDrivingForm {...props} />
  </Box>
);

export default StartDrivingStep;
