import { MovingJobRoom } from '@bas/project-domain/models';
import { colors } from '@bas/theme';
import { BottomSheetMethods, Box, Typography } from '@bas/ui/native/base';
import { BottomSheetProps } from '@bas/ui/native/organisms';
import { FormBottomSheet } from '@bas/ui/native/templates';
import { Uuid } from '@bas/value-objects';
import {
  LoadOrUnloadStorageInputType,
  loadOrUnloadStorageInputTypeValidatorBuilder,
  StoredItemInputType,
  storedItemInputTypeDefaultValues,
} from '@bas/wms-domain/input-types';
import {
  InventoryStorageOutstandingStock,
  PublicMovingBox,
  Storage,
  StoredItem,
} from '@bas/wms-domain/models';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { ReactElement, RefObject, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { ensuredForwardRef } from 'react-use/lib/useEnsuredForwardedRef';
import * as Yup from 'yup';
import { LoadOrUnloadStorageForm } from '../LoadOrUnloadStorageForm';
import styles from './styles';

export type LoadOrUnloadStorageBottomSheetProps = Omit<
  BottomSheetProps,
  'snapPoints' | 'children'
> & {
  boxes: PublicMovingBox[];
  storages: Storage[];
  storedItemsNotMappedToFurniture: {
    [key: string]: StoredItem[];
  };
  furnitureInStorage: {
    [key: string]: Uuid[];
  };
  rooms: MovingJobRoom[];
  outstanding: InventoryStorageOutstandingStock[];
  isUnloading: boolean;
  onSubmit: (
    values: LoadOrUnloadStorageInputType & {
      storedItemsNotMappedToFurniture: {
        [key: string]: StoredItemInputType[];
      };
    },
  ) => Promise<void> | void;
};

const LoadOrUnloadStorageBottomSheet = ensuredForwardRef<
  BottomSheetModal & BottomSheetMethods,
  LoadOrUnloadStorageBottomSheetProps
>(
  (
    {
      rooms,
      storages,
      furnitureInStorage,
      boxes,
      outstanding,
      isUnloading,
      onSubmit,
      storedItemsNotMappedToFurniture,
      ...props
    }: LoadOrUnloadStorageBottomSheetProps,
    ref: RefObject<BottomSheetModal & BottomSheetMethods>,
  ): ReactElement => {
    type FurnitureInput = { furnitureId: Uuid; storageId: Uuid };

    type BoxInput = {
      inventoryItemId: Uuid;
      quantity?: number | null;
      originalQuantity?: number | null;
    };

    const defaultFurniture = useMemo(() => {
      const result: { [key: string]: FurnitureInput } = {};
      Object.keys(furnitureInStorage).forEach((storageId) => {
        furnitureInStorage[storageId].forEach((furnitureId) => {
          result[furnitureId] = {
            furnitureId,
            storageId,
          };
        });
      });

      return result;
    }, [furnitureInStorage]);

    const defaultStorages: {
      storageId: Uuid;
      boxes: BoxInput[];
    }[] = useMemo(() => {
      const result = storages.map(({ storageId }) => ({
        storageId,
        boxes: boxes.map(({ inventoryItemId }) => ({
          inventoryItemId,
          quantity: 0,
          originalQuantity: 0,
        })),
      }));

      outstanding.forEach(({ stockInStorage, inventoryItemId, storageId }) => {
        if (stockInStorage > 0) {
          const index = result.findIndex(
            (item) => item.storageId === storageId,
          );

          if (index !== -1) {
            const boxIndex = result[index].boxes.findIndex(
              (item) => item.inventoryItemId === inventoryItemId,
            );

            if (
              boxIndex !== -1 &&
              result[index].boxes[boxIndex].quantity !== undefined
            ) {
              result[index].boxes[boxIndex].quantity =
                stockInStorage + (result[index].boxes[boxIndex].quantity || 0);
              result[index].boxes[boxIndex].originalQuantity =
                stockInStorage +
                (result[index].boxes[boxIndex].originalQuantity || 0);
            }
          }
        }
      });

      return result;
    }, [boxes, outstanding, storages]);

    const mappedStoredItemsToInputType = useMemo(() => {
      const result: {
        [key: string]: StoredItemInputType[];
      } = {};

      Object.keys(storedItemsNotMappedToFurniture).forEach((storageId) => {
        if (!result[storageId]) {
          result[storageId] = [];
        }

        result[storageId] = storedItemsNotMappedToFurniture[storageId].map(
          (item) => ({
            ...storedItemInputTypeDefaultValues(),
            ...item,
            create: false,
          }),
        );
      });

      return result;
    }, [storedItemsNotMappedToFurniture]);

    const handleFinalize = useCallback(() => {
      ref?.current?.close();
      ref?.current?.forceClose();
      ref?.current?.dismiss();
    }, [ref]);

    return (
      <FormBottomSheet
        bottomSheetRef={ref}
        disablePadding
        onSubmit={onSubmit}
        useProvider
        resetFormOnOpen
        defaultValues={{
          furniture: defaultFurniture,
          storages: defaultStorages,
          storedItemsNotMappedToFurniture: mappedStoredItemsToInputType,
        }}
        disableScroll
        onCancel={handleFinalize}
        successFullFormViewProps={{
          gifUrl: 'https://media.giphy.com/media/N35rW3vRNeaDC/giphy.gif',
          color: colors.lila[100],
        }}
        validationSchema={Yup.object({
          materials: Yup.array().of(
            loadOrUnloadStorageInputTypeValidatorBuilder(),
          ),
        })}
        {...props}
      >
        <Box height="100%">
          <Typography
            overrideColor={colors.white}
            variant="h4"
            fontWeight={700}
            style={[styles.textCenter, styles.title]}
          >
            <FormattedMessage
              id={`mobileApp.event.${
                isUnloading ? 'unloadStorage' : 'loadStorage'
              }.title`}
            />
          </Typography>
          <Typography
            overrideColor={colors.white}
            style={[styles.textCenter, styles.description]}
            variant="h5"
          >
            <FormattedMessage
              id={`mobileApp.event.${
                isUnloading ? 'unloadStorage' : 'loadStorage'
              }.description`}
            />
          </Typography>
          <LoadOrUnloadStorageForm
            storages={storages}
            rooms={rooms}
            boxes={boxes}
          />
        </Box>
      </FormBottomSheet>
    );
  },
);

export default LoadOrUnloadStorageBottomSheet;
