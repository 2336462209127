import { getDefaultCountry } from '@bas/shared/utils';
import {
  EmailAddress,
  ErrorResponse,
  PhoneNumber,
  Uuid,
} from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type UpdateIdentityContactInformationMutationProps = {
  identityId: Uuid;
  phoneNumber?: PhoneNumber | null;
  mobileNumber?: PhoneNumber | null;
  emailAddress?: EmailAddress | null;
};

export const UpdateIdentityContactInformationMutation = async ({
  identityId,
  ...values
}: UpdateIdentityContactInformationMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/tenants/{tenantId}/identities/${identityId}/update-contact-information`,
    {
      identityId,
      region: getDefaultCountry(),
      ...values,
    },
  );

export const useUpdateIdentityContactInformationMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateIdentityContactInformationMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  UpdateIdentityContactInformationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateIdentityContactInformationMutationProps
  >({
    mutationFn: UpdateIdentityContactInformationMutation,
    throwOnError: false,
    ...options,
  });
