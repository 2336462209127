import { ForgotPasswordInputType } from '@bas/authentication-domain/input-types';
import { colors } from '@bas/theme';
import { Alert, Button, useFormSubmitState } from '@bas/ui/native/atoms';
import { Box, Icon, Typography } from '@bas/ui/native/base';
import { ReactHookFormTextField } from '@bas/ui/native/molecules';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons/faArrowLeft';
import { router } from 'expo-router';
import { ReactElement } from 'react';
import { useFormState } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Platform, StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  formEndContainer: {
    paddingTop: 35,
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    maxHeight: 85,
  },
  backContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  submitButtonContainer: {},
  backText: {
    color: colors.lila[200],
    textTransform: 'lowercase',
  },
  backIcon: { marginRight: 5 },
  passwordContainer: {
    paddingTop: 28,
  },
});

const ForgotPasswordForm = (): ReactElement => {
  const { formatMessage } = useIntl();
  const { onSubmit } = useFormSubmitState();
  const { errors, isSubmitting } = useFormState<
    ForgotPasswordInputType & {
      backendErrors?: {
        message: string;
      };
    }
  >();

  return (
    <>
      {errors.backendErrors &&
        typeof errors.backendErrors.message === 'string' && (
          <Alert severity="warning">
            <FormattedMessage id={errors.backendErrors.message} />
          </Alert>
        )}

      {Platform.OS !== 'web' && (
        <Box>
          <ReactHookFormTextField
            name="appUrl"
            label={formatMessage({ id: 'label.appUrl' })}
            autoCapitalize="none"
            autoCorrect={false}
            suffix=".bas.app"
            light
          />
        </Box>
      )}
      <Box>
        <ReactHookFormTextField
          name="emailAddress"
          label={formatMessage({ id: 'label.emailAddress' })}
          keyboardType="email-address"
          autoCapitalize="none"
          autoCorrect={false}
          light
        />
      </Box>
      <Box style={styles.formEndContainer}>
        <Box style={styles.backContainer}>
          <Button variant="text" onPress={() => router.navigate('/login')}>
            <Icon
              overrideColor={colors.lila[200]}
              style={styles.backIcon}
              size={12}
              icon={faArrowLeft}
            />
            <Typography style={styles.backText}>
              <FormattedMessage id="button.back" />
            </Typography>
          </Button>
        </Box>
        <Box style={styles.submitButtonContainer}>
          <Button onPress={onSubmit} loading={isSubmitting}>
            <FormattedMessage id="button.forgotPassword" />
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ForgotPasswordForm;
