import {
  DamageInputType,
  DamageInputTypeDefaultValues,
  DamageInputTypeValidationBuilder,
} from '@bas/shared/input-types';
import { Uuid } from '@bas/value-objects';
import * as Yup from 'yup';

export interface ReportDamageInputType extends DamageInputType {
  eventId: Uuid;
  projectId: Uuid;
}

export const ReportDamageInputTypeDefaultValues =
  (): ReportDamageInputType => ({
    eventId: '',
    projectId: '',
    ...DamageInputTypeDefaultValues(),
  });

export const ReportDamageInputTypeValidationBuilder =
  (): Yup.ObjectSchema<ReportDamageInputType> =>
    Yup.object({
      eventId: Yup.string().required().label('label.event'),
      projectId: Yup.string().required().label('label.project'),
    }).concat(DamageInputTypeValidationBuilder());
