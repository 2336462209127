import * as Yup from 'yup';

export type RejectTimeOffRegistrationInputType = {
  rejectedReason: string;
};

export const rejectTimeOffRegistrationInputTypeDefaultValues =
  (): RejectTimeOffRegistrationInputType => ({
    rejectedReason: '',
  });

export const rejectTimeOffRegistrationInputTypeValidationBuilder =
  (): Yup.ObjectSchema<RejectTimeOffRegistrationInputType> =>
    Yup.object({
      rejectedReason: Yup.string().label('label.reaction').ensure(),
    });
