/* eslint-disable no-param-reassign */
import { createWizardStore, WizardStoreType } from '@bas/shared/state';
import { BottomSheetMethods } from '@bas/ui/native/base';
import { SuccessFullFormViewProps } from '@bas/ui/native/molecules';
import {
  BottomSheet,
  BottomSheetProps,
  ExtraWizardProps,
  OverrideSubmitType,
  ReactHookWizard,
  ReactHookWizardProps,
  WizardTemplateType,
} from '@bas/ui/native/organisms';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import {
  memo,
  MutableRefObject,
  ReactElement,
  ReactNode,
  RefObject,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import isEqual from 'react-fast-compare';
import {
  Control,
  DefaultValues,
  FieldValues,
  SubmitHandler,
  UseFormGetValues,
} from 'react-hook-form';
import { PreviousNextView } from 'react-native-keyboard-manager';
import { StoreApi } from 'zustand/vanilla';
import { ReactHookWizardWithStepsNavigationTemplate } from '../ReactHookWizardWithStepsNavigationTemplate';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Data = Record<string, any>;

export type FormWizardBottomSheetProps<
  TExtraWizardProps extends ExtraWizardProps = ExtraWizardProps,
  TFieldValues extends FieldValues = FieldValues,
  TData = Data,
> = Omit<
  BottomSheetProps,
  'children' | 'backfaceVisibility' | 'name' | 'onChange'
> &
  Omit<
    ReactHookWizardProps<TExtraWizardProps, TFieldValues>,
    | 'style'
    | 'Template'
    | 'name'
    | 'initialValues'
    | 'renderTitle'
    | 'onRenderLabel'
    | 'persist'
  > & {
    useData?: boolean;
    Template?: WizardTemplateType<TExtraWizardProps>;
    resetFormOnOpen?: boolean;
    onCancel?: () => void;
    successFullFormViewProps?: SuccessFullFormViewProps;
    onChange?: (index: number) => void;
    disableScroll?: boolean;
    children?: ReactNode;
    bottomSheetRef?: RefObject<BottomSheetModal & BottomSheetMethods>;
    name: string | ((data: TData) => string);
    persist?: string | ((data: TData) => string);
    initialValues: TFieldValues | ((data: TData) => TFieldValues);
    fullWidth?: boolean;
    renderTitle?: (
      data: TData,
      title: ReactNode,
      getValues: UseFormGetValues<TFieldValues>,
    ) => ReactNode;
    onRenderLabel?: (
      data: TData,
      control: Control<TFieldValues>,
    ) => ReactNode | null;
    store?: StoreApi<
      WizardStoreType<TFieldValues, OverrideSubmitType<TFieldValues>>
    >;
  };

const FormWizardBottomSheetContent = <
  TExtraWizardProps extends ExtraWizardProps = ExtraWizardProps,
  TFieldValues extends FieldValues = FieldValues,
  TData = Data,
>({
  name,
  persist,
  overrideStoreRef,
  storePropsRef,
  data,
  initialValues,
  initialStepIndex,
  onRenderLabel,
  renderTitle,
  children,
  Template,
  onSubmit,
  useData,
  resetFormOnOpen,
  ...props
}: Omit<
  FormWizardBottomSheetProps<TExtraWizardProps, TFieldValues, TData>,
  'bottomSheetRef'
> & {
  overrideStoreRef: MutableRefObject<StoreApi<
    WizardStoreType<TFieldValues, OverrideSubmitType<TFieldValues>>
  > | null>;
  storePropsRef: MutableRefObject<{
    wizardKey: string;
    initialStepIndex: number;
    wizardPersist?: string;
  } | null>;
  data: TData;
}) => {
  const wizardName = useMemo(() => {
    let result = null;
    if (typeof name === 'function') {
      result = name(data);
    } else {
      result = name;
    }

    return result;
  }, [name, data]);

  const wizardPersist = typeof persist === 'function' ? persist(data) : persist;
  const wizardInitialValues =
    typeof initialValues === 'function' ? initialValues(data) : initialValues;

  const storeProps = useMemo(
    () => ({
      wizardKey: wizardPersist || wizardName,
      initialStepIndex,
      wizardPersist,
    }),
    [wizardPersist, wizardName, initialStepIndex],
  );

  if (!isEqual(storeProps, storePropsRef.current)) {
    const newStore = createWizardStore<
      TFieldValues,
      OverrideSubmitType<TFieldValues>
    >(
      storeProps.wizardKey,
      storeProps.initialStepIndex,
      !!storeProps.wizardPersist,
    );

    storePropsRef.current = storeProps;
    overrideStoreRef.current = newStore;
  }

  const handleSubmit: SubmitHandler<TFieldValues> = useCallback(
    async (values, event) => {
      await onSubmit?.(values, event);
      if (useData) {
        storePropsRef.current = null;
        overrideStoreRef.current = null;
      }
    },
    [onSubmit, overrideStoreRef, useData, storePropsRef],
  );

  return (
    <ReactHookWizard<TExtraWizardProps, TFieldValues>
      initialValues={wizardInitialValues as DefaultValues<TFieldValues>}
      name={wizardName}
      persist={wizardPersist}
      onRenderLabel={(control) => onRenderLabel?.(data, control)}
      renderTitle={(title, getValues) => renderTitle?.(data, title, getValues)}
      storeRef={overrideStoreRef}
      Template={
        (Template ||
          ReactHookWizardWithStepsNavigationTemplate) as WizardTemplateType<TExtraWizardProps>
      }
      initialStepIndex={initialStepIndex}
      onSubmit={handleSubmit}
      {...props}
    >
      <PreviousNextView style={{ flex: 1 }}>{children}</PreviousNextView>
    </ReactHookWizard>
  );
};

const FormWizardBottomSheet = <
  TExtraWizardProps extends ExtraWizardProps = ExtraWizardProps,
  TFieldValues extends FieldValues = FieldValues,
  TData = Data,
>({
  bottomSheetRef,
  onCancel,
  useData,
  fullWidth,
  onChange,
  resetFormOnOpen,
  ...props
}: FormWizardBottomSheetProps<
  TExtraWizardProps,
  TFieldValues,
  TData
>): ReactElement => {
  const overrideStoreRef = useRef<StoreApi<
    WizardStoreType<TFieldValues, OverrideSubmitType<TFieldValues>>
  > | null>(null);

  const usedStorePropsRef = useRef<{
    wizardKey: string;
    initialStepIndex: number;
    wizardPersist?: string;
  } | null>(null);

  const renderContent = useCallback(
    (incomingData: { data?: TData } | undefined) => {
      const data = incomingData?.data || ({} as TData);
      return (
        <FormWizardBottomSheetContent<TExtraWizardProps, TFieldValues, TData>
          overrideStoreRef={overrideStoreRef}
          storePropsRef={usedStorePropsRef}
          waitForStore
          data={data}
          onCancel={onCancel}
          useData={useData}
          {...props}
        />
      );
    },
    [onCancel, props, useData],
  );

  const handleChangeBottomSheet = useCallback(
    async (index: number) => {
      onChange?.(index);
      if (index !== 0) {
        if (resetFormOnOpen) {
          overrideStoreRef.current = null;
          usedStorePropsRef.current = null;
        }
      }
    },
    [onChange, resetFormOnOpen],
  );

  return (
    <BottomSheet
      ref={bottomSheetRef}
      disablePadding
      onChange={handleChangeBottomSheet}
      enableDismissOnClose
      disableClose
      fullWidth={fullWidth}
    >
      {useData ? (
        renderContent
      ) : (
        <FormWizardBottomSheetContent<TExtraWizardProps, TFieldValues, TData>
          overrideStoreRef={overrideStoreRef}
          storePropsRef={usedStorePropsRef}
          waitForStore
          onCancel={onCancel}
          data={{} as TData}
          {...props}
        />
      )}
    </BottomSheet>
  );
};

const MemoComponent = memo(
  FormWizardBottomSheet,
  isEqual,
) as typeof FormWizardBottomSheet;
export default MemoComponent;
