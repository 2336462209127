import { PublicServiceType } from '@bas/shared/models';
import * as Yup from 'yup';

export interface WhatServicesAreNeededStepInputType {
  services: (PublicServiceType & { selected: boolean })[];
}

export const WhatServicesAreNeededStepInputTypeDefaultValues =
  (): WhatServicesAreNeededStepInputType => ({
    services: [],
  });

export const WhatServicesAreNeededStepInputTypeValidatorBuilder =
  (): Yup.ObjectSchema<{
    services: (Pick<PublicServiceType, 'serviceTypeId'> & {
      selected: boolean;
    })[];
  }> =>
    Yup.object({
      services: Yup.array()
        .of(
          Yup.object({
            serviceTypeId: Yup.string().required(),
            selected: Yup.boolean().required(),
          }),
        )
        .required(),
    });
