import { isQuoteEvent } from './QuoteEvent';
import { QuoteCanceledEvent, QuoteEventType } from '../types';

export function isQuoteCanceledEvent(
  object: unknown,
): object is QuoteCanceledEvent {
  return (
    isQuoteEvent(object) &&
    object.eventType === QuoteEventType.QuoteCanceledEvent
  );
}
