import { DocumentType, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type AddDocumentMutationProps = {
  fileId: Uuid;
  documentId: Uuid;
  relationId: Uuid;
  documentType: DocumentType;
  fileName: string;
  projectId?: Uuid | null;
  damageId?: Uuid | null;
  storageId?: Uuid | null;
  visibleInApp?: boolean;
};

export const AddDocumentMutation = async ({
  ...values
}: AddDocumentMutationProps): Promise<AxiosResponse> =>
  axios.post('api/{tenantId}/documents', {
    ...values,
  });

export const useAddDocumentMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddDocumentMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  AddDocumentMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddDocumentMutationProps
  >({
    mutationFn: AddDocumentMutation,
    throwOnError: false,
    ...options,
  });
