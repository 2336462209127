import { Project } from '@bas/project-domain/models';
import { Uuid } from '@bas/value-objects';
import { InventoryMovementInputType } from '@bas/wms-domain/input-types';
import { useReportInventoryOutMutation } from '@bas/wms-domain/mutations';
import { useCallback } from 'react';

export type HandleTakeMaterialsProps = {
  project?: Project;
  eventId: Uuid;
  callback?: () => void | Promise<void>;
};

export const useHandleTakeMaterials = ({
  project,
  eventId,
  callback,
}: HandleTakeMaterialsProps) => {
  const { mutateAsync: reportInventoryOutMutation } =
    useReportInventoryOutMutation();

  return useCallback(
    async (values: { materials: InventoryMovementInputType[] }) => {
      if (project) {
        await Promise.all(
          values.materials.map(async ({ inventoryItemId, quantity }) => {
            if (!!quantity && quantity > 0) {
              await reportInventoryOutMutation({
                inventoryItemId,
                quantity,
                projectId: project.projectId,
                customerId: project.customer.relationId,
                eventDate: new Date(),
                planningEventId: eventId,
              });
            }

            if (callback) {
              callback();
            }
          }),
        );
      }
    },
    [callback, eventId, project, reportInventoryOutMutation],
  );
};
