import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { ErrorResponse, Uuid } from '@bas/value-objects';

export type SetupTwoFactorMutationProps = {
  userId: Uuid;
  password: string;
};

type SetupTwoFactorMutationResponse = AxiosResponse<{
  twoFactorSecret: string;
  qrCodeContent: string;
}>;

export const SetupTwoFactorMutation = async ({
  userId,
  ...values
}: SetupTwoFactorMutationProps): Promise<SetupTwoFactorMutationResponse> =>
  axios.put(`api/users/${userId}/setup-two-factor`, {
    userId,
    twoFactorMethod: 'totp',
    ...values,
  });

export const useSetupTwoFactorMutation = (
  options: UseMutationOptions<
    SetupTwoFactorMutationResponse,
    AxiosError<ErrorResponse>,
    SetupTwoFactorMutationProps
  > = {},
): UseMutationResult<
  SetupTwoFactorMutationResponse,
  AxiosError<ErrorResponse>,
  SetupTwoFactorMutationProps
> =>
  useMutation<
    SetupTwoFactorMutationResponse,
    AxiosError<ErrorResponse>,
    SetupTwoFactorMutationProps
  >({
    mutationFn: SetupTwoFactorMutation,
    throwOnError: false,
    ...options,
  });
