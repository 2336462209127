import { isInvoiceEvent } from './InvoiceEvent';
import { InvoiceEventType, InvoiceScheduledEvent } from '../types';

export function isInvoiceScheduledEvent(
  object: unknown,
): object is InvoiceScheduledEvent {
  return (
    isInvoiceEvent(object) &&
    object.eventType === InvoiceEventType.InvoiceScheduledEvent
  );
}
