import { ErrorResponse, Uuid } from '@bas/value-objects';
import { StorageType } from '@bas/wms-domain/models';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type CreateStorageMutationProps = {
  storageId: Uuid;
  tenantId: Uuid;
  storageCode: string;
  storageType: StorageType;
  selfOwned: boolean;
  cubicMeter: number;
  warehouseId: Uuid;
  storageLocationId?: Uuid | null;
};

export const CreateStorageMutation = async ({
  ...values
}: CreateStorageMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/{tenantId}/storages`, {
    ...values,
  });

export const useCreateStorageMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    CreateStorageMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  CreateStorageMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    CreateStorageMutationProps
  >({ mutationFn: CreateStorageMutation, ...options });
