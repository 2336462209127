import {
  ClaCompensationRuleType,
  ClaSingleDayTripCompensationRule,
} from '../types';
import { isClaCompensationRule } from './ClaCompensationRule';

export function isClaSingleDayTripCompensationRule(
  object: unknown,
): object is ClaSingleDayTripCompensationRule {
  return (
    isClaCompensationRule(object) &&
    object.ruleType === ClaCompensationRuleType.ClaSingleDayTrip
  );
}
