import * as Yup from 'yup';
import {
  VehicleInformationInputType,
  VehicleInformationInputTypeDefaultValues,
  VehicleInformationInputTypeValidationBuilder,
} from './vehicleInformationInputType';

export interface MovingLiftInformationInputType
  extends VehicleInformationInputType {
  floors?: number | null;
  height?: number | null;
}

export const MovingLiftInformationInputTypeDefaultValues =
  (): MovingLiftInformationInputType => ({
    ...VehicleInformationInputTypeDefaultValues(),
    floors: 0,
    height: 0,
  });

export const MovingLiftInformationInputTypeValidationBuilder =
  (): Yup.ObjectSchema<MovingLiftInformationInputType> =>
    Yup.object({
      floors: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .nullable()
        .label('label.floors'),
      height: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .nullable()
        .label('label.height'),
    }).concat(VehicleInformationInputTypeValidationBuilder());

export function isMovingLiftInformationInputType(
  object: unknown,
): object is MovingLiftInformationInputType {
  return (
    !!object &&
    typeof object === 'object' &&
    Object.prototype.hasOwnProperty.call(object, 'floors') &&
    Object.prototype.hasOwnProperty.call(object, 'height')
  );
}
