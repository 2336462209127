import { ErrorResponse } from '@bas/value-objects';
import { AxiosError } from 'axios';
import { FieldValues, Path, UseFormReturn } from 'react-hook-form';

export interface ValidationStatus extends FieldValues {
  status?: string | null;
}

export const reactHookFormBackendErrorHandler = <
  T extends ValidationStatus = FieldValues,
>(
  exception: AxiosError<ErrorResponse>,
  form: UseFormReturn<T>,
): void => {
  let handled = false;
  if (exception.response?.data.message) {
    form.setError('status' as Path<T>, {
      type: 'manual',
      message: exception.response?.data.message,
    });

    handled = true;
  }

  if (!handled) {
    throw exception;
  }
};
