import { StorageInvoicingPeriod } from '@bas/wms-domain/models';
import { Money, Uuid, VolumeType } from '@bas/value-objects';
import * as Yup from 'yup';

export interface StorageContractInputType {
  invoicingPeriod: StorageInvoicingPeriod;
  turnoverGroupId?: Uuid | null;
  invoiceReference?: string | null;
  vatCodeId: Uuid;
  price: Money;
  invoicingStartDate?: Date | null;
  lastInvoicedDate?: Date | null;
  includingVat: boolean;
  identityId?: Uuid | null;
  billStorageInAdvance?: boolean | null;
  invoicePerVolume: boolean | null;
  setVolumeToQuantity: boolean | null;
  volumeType: VolumeType | null;
  useIdentities?: boolean | null;
  project?: {
    projectId?: Uuid | null;
    relationId?: Uuid | null;
  } | null;
}

export const storageContractInputTypeDefaultValues =
  (): StorageContractInputType => ({
    invoicingPeriod: StorageInvoicingPeriod.UNKNOWN,
    vatCodeId: '',
    invoiceReference: '',
    includingVat: true,
    invoicingStartDate: null,
    lastInvoicedDate: null,
    billStorageInAdvance: null,
    invoicePerVolume: false,
    setVolumeToQuantity: false,
    volumeType: null,
    identityId: '',
    project: {
      projectId: '',
      relationId: '',
    },
    price: {
      amount: 0,
      currency: 'EUR',
    },
  });

export const storageContractInputTypeValidationBuilder =
  (): Yup.ObjectSchema<StorageContractInputType> =>
    Yup.object({
      invoicingPeriod: Yup.mixed<StorageInvoicingPeriod>()
        .oneOf(
          Object.values(StorageInvoicingPeriod).filter((option) => !!option),
        )
        .required()
        .label('label.invoicingPeriod'),
      vatCodeId: Yup.string().required().label('label.vatCodeId'),

      project: Yup.object({
        projectId: Yup.string().label('label.project'),
        relationId: Yup.string().label('label.relation'),
      }).nullable(),
      turnoverGroupId: Yup.string().nullable().label('label.turnoverGroupId'),
      invoiceReference: Yup.string().nullable().label('label.invoiceReference'),
      invoicingStartDate: Yup.date()
        .nullable()
        .label('label.invoicingStartDate'),
      lastInvoicedDate: Yup.date().nullable().label('label.lastInvoicedDate'),
      includingVat: Yup.boolean().required().label('label.includingVat'),
      invoicePerVolume: Yup.boolean()
        .nullable()
        .defined()
        .label('label.invoicePerVolume'),
      setVolumeToQuantity: Yup.boolean()
        .nullable()
        .defined()
        .label('label.setVolumeToQuantity'),
      volumeType: Yup.mixed<VolumeType>()
        .oneOf(Object.values(VolumeType).filter((option) => !!option))
        .defined()
        .nullable()
        .label('label.volumeType')
        .when(['invoicePerVolume'], {
          is: true,
          then: (schema) => schema.required().label('label.volumeType'),
        }),
      billStorageInAdvance: Yup.boolean()
        .nullable()
        .label('label.billStorageInAdvance'),
      price: Yup.object({
        amount: Yup.number()
          .transform((value, original) =>
            original === '' || original === null ? null : value,
          )
          .nullable()
          .required()
          .label('label.price'),
        currency: Yup.string().required(),
      })
        .required()
        .label('label.price'),
      identityId: Yup.string()
        .when('useIdentities', {
          is: true,
          then: (schema) => schema.required().label('label.identityId'),
        })
        .nullable(),
      useIdentities: Yup.boolean(),
    });
