import type { Relation } from '@bas/crm-domain/models';
import type { ListedProject } from './ListedProject';
import type { TransportJobStatus } from './TransportJobStatus';
import type { Cargo } from './Cargo';

export interface ListedTransportJob extends ListedProject {
  customer: Relation;
  projectStatus: TransportJobStatus;
  loads: Cargo[];
}
