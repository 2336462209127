import { CrmContactPerson } from '@bas/crm-domain/models';
import { colors } from '@bas/theme';
import { Typography } from '@bas/ui/native/base';
import { ReactElement, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { View, ViewProps } from 'react-native';
import { RenderContactInformation } from '../RenderContactInformation';
import styles from './styles';

export type ContactPersonBlockProps = ViewProps & {
  contactPerson: CrmContactPerson;
  contactPersonLabel?: string | ReactNode;
  children?: ReactNode;
  hideGender?: boolean;
  hideSalutation?: boolean;
};

const ContactPersonBlock = ({
  contactPerson,
  contactPersonLabel,
  hideGender,
  hideSalutation,
  ...args
}: ContactPersonBlockProps): ReactElement => (
  <View {...args}>
    <Typography style={styles.row} overrideColor={colors.lila[600]}>
      {contactPersonLabel}
    </Typography>
    {contactPerson.personalInformation.gender && !hideGender && (
      <Typography style={styles.row}>
        <FormattedMessage
          id={`genders.${
            contactPerson.personalInformation.gender || 'unknown'
          }`}
        />
      </Typography>
    )}
    {contactPerson.personalInformation.salutation && !hideSalutation && (
      <Typography style={styles.row}>
        {contactPerson.personalInformation.salutation}
      </Typography>
    )}
    <Typography style={styles.row}>
      {contactPerson.personalInformation.personName.fullName}
    </Typography>
    {contactPerson.primaryContactInformation && (
      <RenderContactInformation
        contactInformation={contactPerson.primaryContactInformation}
      />
    )}
  </View>
);

export default ContactPersonBlock;
