import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type MarkInvoiceAsIrrecoverableMutationProps = {
  invoiceId: Uuid;
  reason: string;
};

export const MarkInvoiceAsIrrecoverableMutation = async ({
  invoiceId,
  ...values
}: MarkInvoiceAsIrrecoverableMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/invoices/${invoiceId}/mark-as-irrecoverable`, {
    invoiceId,
    ...values,
  });

export const useMarkInvoiceAsIrrecoverableMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    MarkInvoiceAsIrrecoverableMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  MarkInvoiceAsIrrecoverableMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    MarkInvoiceAsIrrecoverableMutationProps
  >({
    mutationFn: MarkInvoiceAsIrrecoverableMutation,
    throwOnError: false,
    ...options,
  });
