import { CrmAddressType } from '@bas/crm-domain/models';
import { Country, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type AddAddressToRelationMutationProps = {
  relationId: Uuid;
  addressId: Uuid;
  addressType: CrmAddressType;
  streetName: string;
  houseNumber: string;
  houseNumberAddition?: string | null;
  zipCode: string;
  city: string;
  country: Country;
};

export const AddAddressToRelationMutation = async ({
  relationId,
  ...values
}: AddAddressToRelationMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/relations/${relationId}/addresses`, {
    relationId,
    ...values,
  });

export const useAddAddressToRelationMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddAddressToRelationMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  AddAddressToRelationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddAddressToRelationMutationProps
  >({
    mutationFn: AddAddressToRelationMutation,
    throwOnError: false,
    ...options,
  });
