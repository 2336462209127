import { WizardStoreType } from '@bas/shared/state';
import { createContext, useContext } from 'react';
import { useStore } from 'zustand';
import { StoreApi } from 'zustand/vanilla';

export const WizardContext = createContext<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  StoreApi<WizardStoreType<any, any>>
>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  null as any,
);

export const useWizardStore = () => useStore(useContext(WizardContext));
