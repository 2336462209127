import { PlannedEmployee } from '@bas/planning-domain/models';
import { Avatar } from '@bas/ui/native/atoms';
import {
  TouchableWithoutFeedback,
  TouchableWithoutFeedbackProps,
  Typography,
} from '@bas/ui/native/base';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { View } from 'react-native';
import styles from './styles';

export type PlannedEmployeeListItemProps = TouchableWithoutFeedbackProps & {
  plannedEmployee: PlannedEmployee;
  isLast?: boolean;
};

const PlannedEmployeeListItem = ({
  plannedEmployee: { employee, projectRole },
  isLast,
  ...props
}: PlannedEmployeeListItemProps): ReactElement => (
  <TouchableWithoutFeedback {...props}>
    <View style={[styles.container, isLast && styles.borderBottom]}>
      <Avatar
        src={employee.profilePictureUrl}
        name={`${employee.personName.firstName} ${employee.personName.lastName}`}
      />

      <Typography variant="h5" style={styles.role}>
        <FormattedMessage id={`projectRoles.${projectRole}`} />
      </Typography>
      <Typography variant="h5" fontWeight={700}>
        {employee.personName?.fullName || employee.personName.firstName}
      </Typography>
    </View>
  </TouchableWithoutFeedback>
);

export default PlannedEmployeeListItem;
