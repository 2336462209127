import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type EnableDeclarationCategoryMutationProps = {
  categoryId: Uuid;
};

export const EnableDeclarationCategoryMutation = async ({
  categoryId,
  ...values
}: EnableDeclarationCategoryMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/hrm/declaration-categories/${categoryId}/enable`, {
    categoryId,
    ...values,
  });

export const useEnableDeclarationCategoryMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    EnableDeclarationCategoryMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  EnableDeclarationCategoryMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    EnableDeclarationCategoryMutationProps
  >({
    mutationFn: EnableDeclarationCategoryMutation,
    throwOnError: false,
    ...options,
  });
