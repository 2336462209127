import * as Yup from 'yup';

export interface FuelCardInputType {
  provider?: string | null;
  cardNumber?: string | null;
  cvc?: string | null;
  pinCode?: string | null;
}

export const FuelCardInputTypeDefaultValues = (): FuelCardInputType => ({
  provider: '',
  cardNumber: '',
  cvc: '',
  pinCode: '',
});

export const FuelCardInputTypeValidationBuilder =
  (): Yup.ObjectSchema<FuelCardInputType> =>
    Yup.object({
      provider: Yup.string().label('label.supplier'),
      cardNumber: Yup.string().label('label.cardNumber'),
      cvc: Yup.string().label('label.cvc'),
      pinCode: Yup.string().label('label.pinCode'),
    });
