import dayjs from 'dayjs';
import * as Yup from 'yup';

export interface StartWorkingOnEventInputType {
  startTime?: Date | null;
  startDrivingTime?: Date | null;
}

export const StartWorkingOnEventInputTypeDefaultValues =
  (): StartWorkingOnEventInputType => ({
    startTime: null,
    startDrivingTime: null,
  });

export const StartWorkingOnEventInputTypeValidationBuilder =
  (): Yup.ObjectSchema<StartWorkingOnEventInputType> =>
    Yup.object({
      startDrivingTime: Yup.date().nullable().label('label.date'),
      startTime: Yup.date()
        .nullable()
        .required()
        .label('label.startTime')
        .when(['startDrivingTime'], ([startDrivingTime], schema) => {
          if (!startDrivingTime || !dayjs(startDrivingTime).isValid())
            return schema;
          return schema
            .min(dayjs(startDrivingTime).clone().add(1, 'second').toDate())
            .max(dayjs(startDrivingTime).clone().endOf('day').toDate())
            .required();
        }),
    });
