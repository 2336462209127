{
  "nm": "Occupation Q2-22",
  "ddd": 0,
  "h": 700,
  "w": 1000,
  "meta": {
    "a": "Jonhson Subianto",
    "k": "occupation, delivery, location",
    "d": "Occupation",
    "g": "LottieFiles AE 3.0.2",
    "tc": "#ffffff"
  },
  "layers": [
    {
      "ty": 4,
      "nm": "Floor Outlines",
      "sr": 1,
      "st": 0,
      "op": 300,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [448.207, 4.39, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [500, 655.717, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, -2.286],
                    [247.4, 0],
                    [0, 2.286],
                    [-247.399, 0]
                  ],
                  "o": [
                    [0, 2.286],
                    [-247.399, 0],
                    [0, -2.286],
                    [247.4, 0]
                  ],
                  "v": [
                    [447.957, 0],
                    [-0.001, 4.14],
                    [-447.957, 0],
                    [-0.001, -4.14]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.0078, 0.1059, 0.3059], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [448.207, 4.39], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 1
    },
    {
      "ty": 4,
      "nm": "Left hand Outlines",
      "sr": 1,
      "st": 0,
      "op": 300,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [8.249, 12.358, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [630.569, 240.843, 0], "ix": 2 },
        "r": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [0],
              "t": 0
            },
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [7.275],
              "t": 149
            },
            { "s": [0], "t": 299 }
          ],
          "ix": 10
        },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [3.468, -7.208],
                    [-13.507, -7.381],
                    [4.986, -34.099],
                    [-6.129, 9.414],
                    [52.635, 18.657]
                  ],
                  "o": [
                    [0, 0],
                    [13.506, 7.381],
                    [-4.987, 34.099],
                    [9.591, -14.733],
                    [-52.233, -18.514]
                  ],
                  "v": [
                    [-66.054, -54.23],
                    [-57.257, -36.379],
                    [8.114, 28.186],
                    [61.173, 43.641],
                    [3.017, -43.771]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.898, 0.3059, 0.2667], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [71.014, 62.535], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 2",
          "ix": 2,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [2.752, -4.42],
                    [-0.813, -7.841],
                    [-0.591, 2.826],
                    [-9.697, -2.58],
                    [0.339, 6.06],
                    [1.585, 6.566],
                    [2.627, -3.269]
                  ],
                  "o": [
                    [0, 0],
                    [0.813, 7.841],
                    [0, 0],
                    [9.697, 2.58],
                    [-0.338, -6.06],
                    [-1.584, -6.566],
                    [-1.683, 2.094]
                  ],
                  "v": [
                    [-10.475, -7.003],
                    [-16.465, 9.844],
                    [-11.473, 8.662],
                    [-1.915, 18.807],
                    [16.939, 9.292],
                    [10.347, -14.821],
                    [-6.902, -16.814]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.949, 0.749, 0.749], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [106.561, 121.576], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 3",
          "ix": 3,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-0.07, -0.618],
                    [0, 0],
                    [-0.581, 0.035],
                    [0, 0],
                    [0, 0.579],
                    [0, 0],
                    [0.634, -0.045],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0.065, 0.578],
                    [0, 0],
                    [0.578, -0.034],
                    [0, 0],
                    [0, -0.635],
                    [0, 0],
                    [-0.62, 0.045]
                  ],
                  "v": [
                    [-9.155, 0.407],
                    [-9.086, 1.016],
                    [-7.934, 1.986],
                    [8.196, 1.032],
                    [9.225, -0.06],
                    [9.225, -0.885],
                    [8.052, -1.977],
                    [-8.146, -0.807]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.6745, 0.7765, 0.9765], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [62.336, 140.216], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 4",
          "ix": 4,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-0.07, -0.618],
                    [0, 0],
                    [-0.581, 0.035],
                    [0, 0],
                    [0, 0.579],
                    [0, 0],
                    [0.634, -0.045],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0.065, 0.578],
                    [0, 0],
                    [0.578, -0.034],
                    [0, 0],
                    [0, -0.635],
                    [0, 0],
                    [-0.62, 0.045]
                  ],
                  "v": [
                    [-9.155, 0.407],
                    [-9.086, 1.016],
                    [-7.934, 1.986],
                    [8.196, 1.032],
                    [9.225, -0.06],
                    [9.225, -0.885],
                    [8.052, -1.977],
                    [-8.146, -0.807]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.6745, 0.7765, 0.9765], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [62.336, 145.351], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 5",
          "ix": 5,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-0.07, -0.618],
                    [0, 0],
                    [-0.581, 0.035],
                    [0, 0],
                    [0, 0.579],
                    [0, 0],
                    [0.634, -0.045],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0.065, 0.578],
                    [0, 0],
                    [0.578, -0.034],
                    [0, 0],
                    [0, -0.635],
                    [0, 0],
                    [-0.62, 0.045]
                  ],
                  "v": [
                    [-9.155, 0.407],
                    [-9.086, 1.016],
                    [-7.934, 1.986],
                    [8.196, 1.032],
                    [9.225, -0.06],
                    [9.225, -0.885],
                    [8.052, -1.977],
                    [-8.146, -0.807]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.6745, 0.7765, 0.9765], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [62.336, 150.487], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 6",
          "ix": 6,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-10.201, 8.857],
                    [12.451, 8.234],
                    [11.471, -8.857],
                    [-11.375, -8.556],
                    [-3.94, -3.743],
                    [-12.451, 0.297],
                    [-3.842, 3.672]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.2275, 0.4706, 0.949], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [158.211, 112.032], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 7",
          "ix": 7,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [9.561, -8.706],
                    [-11.811, -8.119],
                    [-11.811, 8.706],
                    [10.735, 8.706],
                    [3.3, 3.894],
                    [11.811, -0.146],
                    [3.202, -3.521]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.2275, 0.4706, 0.949], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [53.174, 117.66], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 8",
          "ix": 8,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, -4.043],
                    [0, 0],
                    [-4.511, 0.249],
                    [0, 0],
                    [0.25, 4.347],
                    [0, 0],
                    [4.457, -0.448],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 4.518],
                    [0, 0],
                    [4.347, -0.239],
                    [0, 0],
                    [-0.256, -4.472],
                    [0, 0],
                    [-4.022, 0.404]
                  ],
                  "v": [
                    [-65.443, -27.982],
                    [-65.443, 39.274],
                    [-57.138, 47.134],
                    [57.768, 40.796],
                    [65.193, 32.486],
                    [61.06, -39.554],
                    [52.416, -46.935],
                    [-58.357, -35.814]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 0.7804, 0.5216], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [106.805, 111.083], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 2
    },
    {
      "ty": 4,
      "nm": "Head Outlines",
      "sr": 1,
      "st": 0,
      "op": 300,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [57.801, 66.091, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [598.416, 200.513, 0], "ix": 2 },
        "r": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [0],
              "t": 0
            },
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [-24.818],
              "t": 30
            },
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [-24.818],
              "t": 60
            },
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [-19.116],
              "t": 90
            },
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [-19.116],
              "t": 120
            },
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [-16.239],
              "t": 150
            },
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [-16.239],
              "t": 180
            },
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [-14.206],
              "t": 210
            },
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [-14.206],
              "t": 240
            },
            { "s": [0], "t": 270 }
          ],
          "ix": 10
        },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [7.936, 0.884],
                    [0, 0],
                    [-2.275, -1.914],
                    [-8.518, -1.585],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [-2.954, -0.329],
                    [4.024, 3.387],
                    [16.826, 3.13],
                    [0, 0]
                  ],
                  "v": [
                    [4.491, -5.344],
                    [-23.337, -8.443],
                    [-25.634, -3.151],
                    [-6.915, 5.642],
                    [27.91, 4.325]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.2275, 0.4706, 0.949], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [28.159, 22.638], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 2",
          "ix": 2,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [-16.883, -16.451],
                    [0, 0],
                    [9.147, 4.487],
                    [0.152, 0.074],
                    [6.394, -9.634]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [1.682, -10.049],
                    [-0.151, -0.075],
                    [-10.401, -5.051],
                    [0, 0]
                  ],
                  "v": [
                    [-23.26, -3.454],
                    [19.804, 20.184],
                    [21.578, 9.581],
                    [8.995, -14.91],
                    [8.539, -15.133],
                    [-20.787, -7.181]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.2275, 0.4706, 0.949], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [55.91, 20.748], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 3",
          "ix": 3,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-0.41, -2.462],
                    [2.49, -0.346],
                    [0.41, 2.463],
                    [-2.49, 0.347]
                  ],
                  "o": [
                    [0.41, 2.462],
                    [-2.489, 0.346],
                    [-0.41, -2.462],
                    [2.489, -0.346]
                  ],
                  "v": [
                    [4.508, -0.627],
                    [0.742, 4.458],
                    [-4.508, 0.626],
                    [-0.742, -4.458]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.9373, 0.5922, 0.5569], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [47.345, 52.717], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 4",
          "ix": 4,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [-9.407, 4.72]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-6.134, -3.407],
                    [6.134, -1.313]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.6118, 0.2392, 0.2392], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [33.487, 63.214], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 5",
          "ix": 5,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [5.211, -14.057],
                    [4.633, 9.636],
                    [8.8, 0.301],
                    [-15.749, -1.318]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [30.094, 2.519]
                  ],
                  "v": [
                    [15.668, 17.214],
                    [-6.941, -6.826],
                    [-25.17, 0.813],
                    [-4.924, -15.896]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.0078, 0.1059, 0.3059], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [52.921, 29.259], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 6",
          "ix": 6,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [5.377, 1.507],
                    [17.804, 7.433],
                    [0, 0],
                    [-0.693, 0.938],
                    [-3.715, 8.327],
                    [-2.953, -46.223],
                    [-1.77, -10.473]
                  ],
                  "o": [
                    [0, 0],
                    [-17.804, -7.434],
                    [0, 0],
                    [0.693, -0.939],
                    [3.715, -8.326],
                    [0, 0],
                    [1.77, 10.472]
                  ],
                  "v": [
                    [15.158, 20.523],
                    [-17.692, 34.409],
                    [-25.975, 6.388],
                    [-31.199, 4.208],
                    [-22.316, -11.594],
                    [18.733, 4.381],
                    [33.727, 7.617]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.949, 0.749, 0.749], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [49.856, 42.092], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 3
    },
    {
      "ty": 4,
      "nm": "Body Outlines",
      "sr": 1,
      "st": 0,
      "op": 300,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [88.776, 106.574, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [628.624, 300.625, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [-2.391, -11.748],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [2.392, 11.747],
                    [0, 0]
                  ],
                  "v": [
                    [2.645, -17.443],
                    [2.73, 5.697],
                    [-5.121, 14.217]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.898, 0.3059, 0.2667], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [55.411, 80.53], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 2",
          "ix": 2,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-6.575, -6.293],
                    [-13.253, 4.294],
                    [0.017, 10.092],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-4.561, 17.383],
                    [11.137, 4.445],
                    [0.438, -17.383],
                    [-6.114, -10.13]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.898, 0.3059, 0.2667], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [44.563, 55.359], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 3",
          "ix": 3,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-1.485, 31.392],
                    [0, 0],
                    [17.1, -8.227],
                    [-3.397, -7.745]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [13.853, -15.36],
                    [7.545, -21.602],
                    [-13.853, 8.146],
                    [-0.25, 21.602]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.898, 0.3059, 0.2667], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [69.552, 51.657], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 4",
          "ix": 4,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0.004, -0.017],
                          [9.11, -0.432],
                          [-0.11, 4.231],
                          [0, 0]
                        ],
                        "o": [
                          [-2.557, 11.958],
                          [0.696, -5.675],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [9.116, -11.1],
                          [-9.121, 13.756],
                          [-6.852, -8.228],
                          [8.303, -13.756]
                        ]
                      }
                    ],
                    "t": 0
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0.002, -0.017],
                          [13.447, -3.813],
                          [-0.11, 4.231],
                          [0, 0]
                        ],
                        "o": [
                          [-1.54, 14.043],
                          [0.696, -5.675],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [9.116, -11.1],
                          [-10.121, 21.256],
                          [-6.852, -8.228],
                          [8.303, -13.756]
                        ]
                      }
                    ],
                    "t": 30
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0.002, -0.017],
                          [13.447, -3.813],
                          [-0.11, 4.231],
                          [0, 0]
                        ],
                        "o": [
                          [-1.54, 14.043],
                          [0.696, -5.675],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [9.116, -11.1],
                          [-10.121, 21.256],
                          [-6.852, -8.228],
                          [8.303, -13.756]
                        ]
                      }
                    ],
                    "t": 60
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0.002, -0.017],
                          [13.447, -3.813],
                          [-0.11, 4.231],
                          [0, 0]
                        ],
                        "o": [
                          [-1.54, 14.043],
                          [0.696, -5.675],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [9.116, -11.1],
                          [-9.621, 19.256],
                          [-6.852, -8.228],
                          [8.303, -13.756]
                        ]
                      }
                    ],
                    "t": 90
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0.002, -0.017],
                          [13.447, -3.813],
                          [-0.11, 4.231],
                          [0, 0]
                        ],
                        "o": [
                          [-1.54, 14.043],
                          [0.696, -5.675],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [9.116, -11.1],
                          [-9.621, 19.256],
                          [-6.852, -8.228],
                          [8.303, -13.756]
                        ]
                      }
                    ],
                    "t": 120
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0.002, -0.017],
                          [12.697, -4.813],
                          [-0.11, 4.231],
                          [0, 0]
                        ],
                        "o": [
                          [-1.54, 14.043],
                          [0.696, -5.675],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [9.116, -11.1],
                          [-9.621, 17.756],
                          [-6.852, -8.228],
                          [8.303, -13.756]
                        ]
                      }
                    ],
                    "t": 150
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0.002, -0.017],
                          [12.697, -4.813],
                          [-0.11, 4.231],
                          [0, 0]
                        ],
                        "o": [
                          [-1.54, 14.043],
                          [0.696, -5.675],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [9.116, -11.1],
                          [-9.621, 17.756],
                          [-6.852, -8.228],
                          [8.303, -13.756]
                        ]
                      }
                    ],
                    "t": 180
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0.002, -0.017],
                          [12.697, -4.813],
                          [-0.11, 4.231],
                          [0, 0]
                        ],
                        "o": [
                          [-1.54, 14.043],
                          [0.696, -5.675],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [9.116, -11.1],
                          [-9.643, 17.006],
                          [-6.852, -8.228],
                          [8.303, -13.756]
                        ]
                      }
                    ],
                    "t": 210
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0.002, -0.017],
                          [12.697, -4.813],
                          [-0.11, 4.231],
                          [0, 0]
                        ],
                        "o": [
                          [-1.54, 14.043],
                          [0.696, -5.675],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [9.116, -11.1],
                          [-9.643, 17.006],
                          [-6.852, -8.228],
                          [8.303, -13.756]
                        ]
                      }
                    ],
                    "t": 240
                  },
                  {
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0.004, -0.017],
                          [9.11, -0.432],
                          [-0.11, 4.231],
                          [0, 0]
                        ],
                        "o": [
                          [-2.557, 11.958],
                          [0.696, -5.675],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [9.116, -11.1],
                          [-9.121, 13.756],
                          [-6.852, -8.228],
                          [8.303, -13.756]
                        ]
                      }
                    ],
                    "t": 270
                  }
                ],
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.6118, 0.2392, 0.2392], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [55.575, 14.006], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 5",
          "ix": 5,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [-9.691, -11.747],
                    [10.646, 2.862],
                    [-0.703, 5.489],
                    [-0.005, 7.881]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [-3.549, -0.955],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [2.021, -31.137],
                    [17.776, 2.069],
                    [-6.884, 28.275],
                    [-17.073, 8.028],
                    [-13.133, -25.609]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.949, 0.749, 0.749], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [61.857, 31.387], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 6",
          "ix": 6,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [12.856, 10.24],
                    [-30.309, -119.895],
                    [-12.19, 13.793],
                    [49.51, 35.246]
                  ],
                  "o": [
                    [0, 0],
                    [40.021, 20.593],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-44.03, -82.546],
                    [-58.217, 69.131],
                    [88.526, 39.588],
                    [-9.146, -89.724]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.2275, 0.4706, 0.949], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [88.776, 123.174], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 4
    },
    {
      "ty": 4,
      "nm": "Hair Outlines",
      "sr": 1,
      "st": 0,
      "op": 300,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [28.554, 82.548, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [579.026, 231.914, 0], "ix": 2 },
        "r": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [0],
              "t": 0
            },
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [-12.251],
              "t": 30
            },
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [-12.251],
              "t": 60
            },
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [-9.891],
              "t": 90
            },
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [-9.891],
              "t": 120
            },
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [-8.153],
              "t": 150
            },
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [-8.153],
              "t": 180
            },
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [-6.858],
              "t": 210
            },
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [-6.858],
              "t": 240
            },
            { "s": [0], "t": 270 }
          ],
          "ix": 10
        },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-2.654, -15.942],
                    [-13.565, -7.919],
                    [-5.217, -13.284],
                    [-13.956, -1.15],
                    [40.174, -14.026],
                    [14.449, 6.494],
                    [13.377, 6.807],
                    [-6.375, 12.298],
                    [-11.442, 2.776]
                  ],
                  "o": [
                    [0, 0],
                    [13.565, 7.919],
                    [5.218, 13.284],
                    [13.957, 1.149],
                    [-40.174, 14.026],
                    [-14.449, -6.494],
                    [-13.377, -6.807],
                    [6.375, -12.298],
                    [11.442, -2.775]
                  ],
                  "v": [
                    [-22.734, -52.855],
                    [-8.348, -19.031],
                    [39.391, -5.109],
                    [68.478, 12.646],
                    [48.13, 64.272],
                    [-47.247, 61.289],
                    [-74.927, 21.496],
                    [-79.81, -15.419],
                    [-58.79, -75.522]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.0078, 0.1059, 0.3059], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [88.554, 78.548], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 5
    },
    {
      "ty": 4,
      "nm": "Right hand Outlines",
      "sr": 1,
      "st": 0,
      "op": 300,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [124.538, 121.138, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [577.546, 248.398, 0], "ix": 2 },
        "r": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [0],
              "t": 0
            },
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [4.049],
              "t": 151
            },
            { "s": [0], "t": 299 }
          ],
          "ix": 10
        },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [3.791, -9.242],
                    [-12.274, -2.743],
                    [0, 0],
                    [-3.177, 0],
                    [4.765, 2.022]
                  ],
                  "o": [
                    [0, 0],
                    [12.275, 2.744],
                    [0, 0],
                    [3.177, 0],
                    [-4.765, -2.022]
                  ],
                  "v": [
                    [-16.498, -1.083],
                    [-6.715, 7.581],
                    [9.892, -2.238],
                    [15.812, 2.867],
                    [13.935, -7.87]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.949, 0.749, 0.749], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [53.358, 16.373], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 2",
          "ix": 2,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-0.133, -0.633],
                    [0, 0],
                    [-0.598, 0.093],
                    [0, 0],
                    [0.057, 0.6],
                    [0, 0],
                    [0.652, -0.109],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0.124, 0.592],
                    [0, 0],
                    [0.595, -0.092],
                    [0, 0],
                    [-0.062, -0.658],
                    [0, 0],
                    [-0.637, 0.107]
                  ],
                  "v": [
                    [-9.454, 1.327],
                    [-9.323, 1.951],
                    [-8.035, 2.843],
                    [8.572, 0.274],
                    [9.531, -0.958],
                    [9.449, -1.811],
                    [8.128, -2.826],
                    [-8.53, -0.028]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.6745, 0.7765, 0.9765], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [27.972, 89.392], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 3",
          "ix": 3,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-0.133, -0.633],
                    [0, 0],
                    [-0.598, 0.093],
                    [0, 0],
                    [0.057, 0.6],
                    [0, 0],
                    [0.652, -0.109],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0.124, 0.592],
                    [0, 0],
                    [0.595, -0.093],
                    [0, 0],
                    [-0.062, -0.658],
                    [0, 0],
                    [-0.637, 0.107]
                  ],
                  "v": [
                    [-9.454, 1.327],
                    [-9.323, 1.951],
                    [-8.035, 2.843],
                    [8.572, 0.275],
                    [9.531, -0.958],
                    [9.449, -1.811],
                    [8.128, -2.826],
                    [-8.53, -0.028]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.6745, 0.7765, 0.9765], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [28.475, 94.709], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 4",
          "ix": 4,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-0.133, -0.633],
                    [0, 0],
                    [-0.598, 0.093],
                    [0, 0],
                    [0.057, 0.6],
                    [0, 0],
                    [0.652, -0.109],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0.124, 0.592],
                    [0, 0],
                    [0.595, -0.093],
                    [0, 0],
                    [-0.062, -0.658],
                    [0, 0],
                    [-0.637, 0.107]
                  ],
                  "v": [
                    [-9.454, 1.327],
                    [-9.323, 1.951],
                    [-8.035, 2.843],
                    [8.572, 0.275],
                    [9.53, -0.958],
                    [9.449, -1.811],
                    [8.128, -2.826],
                    [-8.53, -0.028]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.6745, 0.7765, 0.9765], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [28.978, 100.027], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 5",
          "ix": 5,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-10.112, 10.232],
                    [13.28, 7.369],
                    [10.591, -10.232],
                    [-13.033, -7.682],
                    [-4.864, -3.427],
                    [-13.28, 1.589],
                    [-4.036, 4.24]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.2275, 0.4706, 0.949], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [124.879, 50.765], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 6",
          "ix": 6,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [9.451, -10.061],
                    [-12.619, -7.361],
                    [-10.971, 10.061],
                    [12.372, 7.853],
                    [4.203, 3.598],
                    [12.619, -1.419],
                    [3.375, -4.07]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.2275, 0.4706, 0.949], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [15.855, 67.055], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 7",
          "ix": 7,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-0.396, -4.186],
                    [0, 0],
                    [-4.646, 0.7],
                    [0, 0],
                    [0.684, 4.477],
                    [0, 0],
                    [4.571, -0.9],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0.443, 4.678],
                    [0, 0],
                    [4.477, -0.674],
                    [0, 0],
                    [-0.704, -4.605],
                    [0, 0],
                    [-4.126, 0.812]
                  ],
                  "v": [
                    [-70.734, -22.794],
                    [-64.145, 46.841],
                    [-54.776, 54.164],
                    [63.573, 36.346],
                    [70.446, 27.013],
                    [59.11, -47.169],
                    [49.436, -53.964],
                    [-64.165, -31.597]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 0.7804, 0.5216], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [71.38, 55.115], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 8",
          "ix": 8,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-4.2, 6.807],
                    [12.664, 8.747],
                    [-8.51, 33.395],
                    [7.076, -8.725],
                    [-47.771, -28.919]
                  ],
                  "o": [
                    [0, 0],
                    [-12.664, -8.748],
                    [8.511, -33.395],
                    [-11.072, 13.655],
                    [49.106, 29.728]
                  ],
                  "v": [
                    [60.822, 55.477],
                    [53.931, 36.807],
                    [-4.361, -34.215],
                    [-55.523, -55.11],
                    [-6.785, 37.882]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.898, 0.3059, 0.2667], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [82.23, 82.417], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 6
    },
    {
      "ty": 4,
      "nm": "Left leg Outlines",
      "sr": 1,
      "st": 0,
      "op": 300,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [79.897, 47.456, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [656.248, 371.75, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 6",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [47.026, 10.111],
                    [0.645, -2.532],
                    [-60.92, -83.594],
                    [-19.66, 13.795],
                    [-3.544, -3.293],
                    [-1.912, 66.659]
                  ],
                  "o": [
                    [0, 0],
                    [-0.646, 2.532],
                    [0, 0],
                    [16.059, -11.269],
                    [0, 0],
                    [1.761, -61.399]
                  ],
                  "v": [
                    [9.78, -134.303],
                    [-39.883, -122.016],
                    [18.505, 125.498],
                    [52.871, 121.007],
                    [87.97, 124.558],
                    [53.997, -10.505]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.898, 0.3059, 0.2667], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [89.064, 134.553], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Shoes",
          "ix": 2,
          "cix": 2,
          "np": 7,
          "it": [
            {
              "ty": "gr",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Group",
              "nm": "Group 1",
              "ix": 1,
              "cix": 2,
              "np": 2,
              "it": [
                {
                  "ty": "sh",
                  "bm": 0,
                  "hd": false,
                  "mn": "ADBE Vector Shape - Group",
                  "nm": "Path 1",
                  "ix": 1,
                  "d": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "c": true,
                      "i": [
                        [0.418, 0.176],
                        [3.799, 0.836],
                        [0.237, -0.216],
                        [0, 0],
                        [-0.607, -0.178],
                        [-3.081, -1.364],
                        [-0.224, 0.437],
                        [0, 0]
                      ],
                      "o": [
                        [-2.34, -0.987],
                        [-0.306, -0.068],
                        [0, 0],
                        [-0.481, 0.437],
                        [3.089, 0.91],
                        [0.447, 0.198],
                        [0, 0],
                        [0.208, -0.404]
                      ],
                      "v": [
                        [7.71, 1.128],
                        [-5.707, -3.701],
                        [-6.583, -3.464],
                        [-7.81, -2.349],
                        [-7.542, -1.023],
                        [6.35, 3.571],
                        [7.585, 3.134],
                        [8.083, 2.166]
                      ]
                    },
                    "ix": 2
                  }
                },
                {
                  "ty": "fl",
                  "bm": 0,
                  "hd": false,
                  "mn": "ADBE Vector Graphic - Fill",
                  "nm": "Fill 1",
                  "c": { "a": 0, "k": [0.6745, 0.7765, 0.9765], "ix": 4 },
                  "r": 1,
                  "o": { "a": 0, "k": 100, "ix": 5 }
                },
                {
                  "ty": "tr",
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "p": { "a": 0, "k": [51.384, 312.26], "ix": 2 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "o": { "a": 0, "k": 100, "ix": 7 }
                }
              ]
            },
            {
              "ty": "gr",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Group",
              "nm": "Group 2",
              "ix": 2,
              "cix": 2,
              "np": 2,
              "it": [
                {
                  "ty": "sh",
                  "bm": 0,
                  "hd": false,
                  "mn": "ADBE Vector Shape - Group",
                  "nm": "Path 1",
                  "ix": 1,
                  "d": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "c": true,
                      "i": [
                        [0.418, 0.176],
                        [3.799, 0.836],
                        [0.237, -0.216],
                        [0, 0],
                        [-0.607, -0.178],
                        [-3.081, -1.364],
                        [-0.224, 0.437],
                        [0, 0]
                      ],
                      "o": [
                        [-2.34, -0.987],
                        [-0.306, -0.068],
                        [0, 0],
                        [-0.481, 0.437],
                        [3.089, 0.91],
                        [0.447, 0.198],
                        [0, 0],
                        [0.208, -0.404]
                      ],
                      "v": [
                        [7.71, 1.128],
                        [-5.707, -3.701],
                        [-6.583, -3.464],
                        [-7.81, -2.349],
                        [-7.542, -1.023],
                        [6.35, 3.571],
                        [7.585, 3.134],
                        [8.083, 2.166]
                      ]
                    },
                    "ix": 2
                  }
                },
                {
                  "ty": "fl",
                  "bm": 0,
                  "hd": false,
                  "mn": "ADBE Vector Graphic - Fill",
                  "nm": "Fill 1",
                  "c": { "a": 0, "k": [0.6745, 0.7765, 0.9765], "ix": 4 },
                  "r": 1,
                  "o": { "a": 0, "k": 100, "ix": 5 }
                },
                {
                  "ty": "tr",
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "p": { "a": 0, "k": [59.574, 308.901], "ix": 2 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "o": { "a": 0, "k": 100, "ix": 7 }
                }
              ]
            },
            {
              "ty": "gr",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Group",
              "nm": "Group 3",
              "ix": 3,
              "cix": 2,
              "np": 2,
              "it": [
                {
                  "ty": "sh",
                  "bm": 0,
                  "hd": false,
                  "mn": "ADBE Vector Shape - Group",
                  "nm": "Path 1",
                  "ix": 1,
                  "d": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "c": true,
                      "i": [
                        [0.418, 0.176],
                        [3.799, 0.836],
                        [0.237, -0.216],
                        [0, 0],
                        [-0.607, -0.178],
                        [-3.081, -1.364],
                        [-0.224, 0.437],
                        [0, 0]
                      ],
                      "o": [
                        [-2.34, -0.987],
                        [-0.306, -0.068],
                        [0, 0],
                        [-0.481, 0.437],
                        [3.089, 0.91],
                        [0.447, 0.198],
                        [0, 0],
                        [0.208, -0.404]
                      ],
                      "v": [
                        [7.71, 1.128],
                        [-5.707, -3.701],
                        [-6.583, -3.464],
                        [-7.81, -2.349],
                        [-7.542, -1.023],
                        [6.35, 3.571],
                        [7.585, 3.134],
                        [8.083, 2.166]
                      ]
                    },
                    "ix": 2
                  }
                },
                {
                  "ty": "fl",
                  "bm": 0,
                  "hd": false,
                  "mn": "ADBE Vector Graphic - Fill",
                  "nm": "Fill 1",
                  "c": { "a": 0, "k": [0.6745, 0.7765, 0.9765], "ix": 4 },
                  "r": 1,
                  "o": { "a": 0, "k": 100, "ix": 5 }
                },
                {
                  "ty": "tr",
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "p": { "a": 0, "k": [66.231, 305.508], "ix": 2 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "o": { "a": 0, "k": 100, "ix": 7 }
                }
              ]
            },
            {
              "ty": "gr",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Group",
              "nm": "Group 4",
              "ix": 4,
              "cix": 2,
              "np": 2,
              "it": [
                {
                  "ty": "sh",
                  "bm": 0,
                  "hd": false,
                  "mn": "ADBE Vector Shape - Group",
                  "nm": "Path 1",
                  "ix": 1,
                  "d": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "c": true,
                      "i": [
                        [-4.526, -1.893],
                        [-1.493, -15.813],
                        [29.464, -3.514],
                        [3.472, 8.864],
                        [-13.645, 6.601],
                        [-5.488, 0.9],
                        [-10.125, -0.826]
                      ],
                      "o": [
                        [0, 0],
                        [0, 0],
                        [-29.463, 3.515],
                        [-3.473, -8.863],
                        [13.645, -6.6],
                        [5.487, -0.899],
                        [10.125, 0.826]
                      ],
                      "v": [
                        [40.108, -16.893],
                        [47.71, 1.617],
                        [6.051, 17.906],
                        [-44.236, 11.568],
                        [-17.673, -0.115],
                        [3.96, -20.521],
                        [17.066, -9.31]
                      ]
                    },
                    "ix": 2
                  }
                },
                {
                  "ty": "fl",
                  "bm": 0,
                  "hd": false,
                  "mn": "ADBE Vector Graphic - Fill",
                  "nm": "Fill 1",
                  "c": { "a": 0, "k": [1, 0.7804, 0.5216], "ix": 4 },
                  "r": 1,
                  "o": { "a": 0, "k": 100, "ix": 5 }
                },
                {
                  "ty": "tr",
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "p": { "a": 0, "k": [48.124, 302.962], "ix": 2 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "o": { "a": 0, "k": 100, "ix": 7 }
                }
              ]
            },
            {
              "ty": "gr",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Group",
              "nm": "Group 5",
              "ix": 5,
              "cix": 2,
              "np": 2,
              "it": [
                {
                  "ty": "sh",
                  "bm": 0,
                  "hd": false,
                  "mn": "ADBE Vector Shape - Group",
                  "nm": "Path 1",
                  "ix": 1,
                  "d": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "c": true,
                      "i": [
                        [-78.529, 34.804],
                        [-0.539, -3.509],
                        [35.02, -4.006],
                        [1.84, 5.728],
                        [-4.156, 5.319]
                      ],
                      "o": [
                        [0, 0],
                        [0.538, 3.509],
                        [-35.02, 4.006],
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [45.155, -17.402],
                        [48.936, -12.866],
                        [0.579, 5.953],
                        [-47.676, -4.669],
                        [-45.318, -11.145]
                      ]
                    },
                    "ix": 2
                  }
                },
                {
                  "ty": "fl",
                  "bm": 0,
                  "hd": false,
                  "mn": "ADBE Vector Graphic - Fill",
                  "nm": "Fill 1",
                  "c": { "a": 0, "k": [0.0078, 0.1059, 0.3059], "ix": 4 },
                  "r": 1,
                  "o": { "a": 0, "k": 100, "ix": 5 }
                },
                {
                  "ty": "tr",
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "p": { "a": 0, "k": [49.724, 321.261], "ix": 2 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "o": { "a": 0, "k": 100, "ix": 7 }
                }
              ]
            },
            {
              "ty": "gr",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Group",
              "nm": "Group 7",
              "ix": 6,
              "cix": 2,
              "np": 2,
              "it": [
                {
                  "ty": "sh",
                  "bm": 0,
                  "hd": false,
                  "mn": "ADBE Vector Shape - Group",
                  "nm": "Path 1",
                  "ix": 1,
                  "d": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "c": true,
                      "i": [
                        [-14.759, 1.156],
                        [0, 0],
                        [3.439, 0.546],
                        [0, 0]
                      ],
                      "o": [
                        [0, 0],
                        [0, 0],
                        [-13.253, 3.314],
                        [6.376, 7.218]
                      ],
                      "v": [
                        [18.224, 10.674],
                        [16.065, -10.878],
                        [10.311, -11.831],
                        [-18.224, 0.784]
                      ]
                    },
                    "ix": 2
                  }
                },
                {
                  "ty": "fl",
                  "bm": 0,
                  "hd": false,
                  "mn": "ADBE Vector Graphic - Fill",
                  "nm": "Fill 1",
                  "c": { "a": 0, "k": [0.6118, 0.2392, 0.2392], "ix": 4 },
                  "r": 1,
                  "o": { "a": 0, "k": 100, "ix": 5 }
                },
                {
                  "ty": "tr",
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "p": { "a": 0, "k": [65.794, 256.018], "ix": 2 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "o": { "a": 0, "k": 100, "ix": 7 }
                }
              ]
            },
            {
              "ty": "gr",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Group",
              "nm": "Group 8",
              "ix": 7,
              "cix": 2,
              "np": 2,
              "it": [
                {
                  "ty": "sh",
                  "bm": 0,
                  "hd": false,
                  "mn": "ADBE Vector Shape - Group",
                  "nm": "Path 1",
                  "ix": 1,
                  "d": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "c": true,
                      "i": [
                        [1.698, -3.072],
                        [0, 0],
                        [0, 0],
                        [-15.346, 19.095],
                        [0, 0]
                      ],
                      "o": [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [-19.734, -29.436],
                        [-18.833, -16.706],
                        [-11.534, 15.347],
                        [19.734, 13.413],
                        [15.877, -26.537]
                      ]
                    },
                    "ix": 2
                  }
                },
                {
                  "ty": "fl",
                  "bm": 0,
                  "hd": false,
                  "mn": "ADBE Vector Graphic - Fill",
                  "nm": "Fill 1",
                  "c": { "a": 0, "k": [0.949, 0.749, 0.749], "ix": 4 },
                  "r": 1,
                  "o": { "a": 0, "k": 100, "ix": 5 }
                },
                {
                  "ty": "tr",
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "p": { "a": 0, "k": [65.984, 271.677], "ix": 2 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "o": { "a": 0, "k": 100, "ix": 7 }
                }
              ]
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [62.602, 258.661], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [123.356, 258.661], "ix": 2 },
              "r": { "a": 0, "k": -5.471, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 9",
          "ix": 3,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-18.547, -8.026],
                    [-4.268, -5.75],
                    [26.744, 25.973]
                  ],
                  "o": [
                    [0, 0],
                    [5.371, 7.236],
                    [0, 0]
                  ],
                  "v": [
                    [17.272, -14.327],
                    [32.548, -1.81],
                    [-37.918, -3.62]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.0078, 0.1059, 0.3059], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [32.079, -1.264], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [175.651, 259.157], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 7
    },
    {
      "ty": 4,
      "nm": "Right leg Outlines",
      "sr": 1,
      "st": 0,
      "op": 300,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [95.011, 165.261, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [597.761, 499.237, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0.418, 0.176],
                    [3.8, 0.831],
                    [0.237, -0.215],
                    [0, 0],
                    [-0.607, -0.177],
                    [-3.083, -1.36],
                    [-0.224, 0.437],
                    [0, 0]
                  ],
                  "o": [
                    [-2.341, -0.984],
                    [-0.307, -0.067],
                    [0, 0],
                    [-0.48, 0.438],
                    [3.09, 0.906],
                    [0.448, 0.197],
                    [0, 0],
                    [0.207, -0.404]
                  ],
                  "v": [
                    [7.712, 1.118],
                    [-5.711, -3.693],
                    [-6.587, -3.455],
                    [-7.814, -2.338],
                    [-7.543, -1.012],
                    [6.355, 3.564],
                    [7.59, 3.126],
                    [8.087, 2.156]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.6745, 0.7765, 0.9765], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [51.38, 303.823], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 2",
          "ix": 2,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0.419, 0.176],
                    [3.8, 0.831],
                    [0.237, -0.215],
                    [0, 0],
                    [-0.607, -0.177],
                    [-3.083, -1.36],
                    [-0.224, 0.437],
                    [0, 0]
                  ],
                  "o": [
                    [-2.341, -0.984],
                    [-0.307, -0.067],
                    [0, 0],
                    [-0.48, 0.438],
                    [3.091, 0.906],
                    [0.448, 0.198],
                    [0, 0],
                    [0.207, -0.404]
                  ],
                  "v": [
                    [7.712, 1.118],
                    [-5.711, -3.694],
                    [-6.587, -3.456],
                    [-7.814, -2.339],
                    [-7.543, -1.013],
                    [6.355, 3.563],
                    [7.59, 3.125],
                    [8.087, 2.155]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.6745, 0.7765, 0.9765], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [59.565, 300.454], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 3",
          "ix": 3,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0.418, 0.176],
                    [3.8, 0.831],
                    [0.237, -0.215],
                    [0, 0],
                    [-0.607, -0.177],
                    [-3.083, -1.36],
                    [-0.224, 0.437],
                    [0, 0]
                  ],
                  "o": [
                    [-2.341, -0.984],
                    [-0.307, -0.067],
                    [0, 0],
                    [-0.48, 0.438],
                    [3.09, 0.906],
                    [0.448, 0.198],
                    [0, 0],
                    [0.207, -0.404]
                  ],
                  "v": [
                    [7.712, 1.118],
                    [-5.711, -3.694],
                    [-6.587, -3.456],
                    [-7.814, -2.339],
                    [-7.543, -1.013],
                    [6.355, 3.563],
                    [7.59, 3.125],
                    [8.087, 2.155]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.6745, 0.7765, 0.9765], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [66.218, 297.052], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 4",
          "ix": 4,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-4.529, -1.887],
                    [-1.514, -15.811],
                    [29.458, -3.553],
                    [3.484, 8.859],
                    [-13.636, 6.618],
                    [-5.486, 0.907],
                    [-10.127, -0.812]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [-29.459, 3.553],
                    [-3.484, -8.859],
                    [13.636, -6.619],
                    [5.486, -0.906],
                    [10.126, 0.812]
                  ],
                  "v": [
                    [40.083, -16.955],
                    [47.708, 1.545],
                    [6.072, 17.889],
                    [-44.224, 11.616],
                    [-17.676, -0.101],
                    [3.93, -20.536],
                    [17.051, -9.342]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 0.7804, 0.5216], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [48.111, 294.539], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 5",
          "ix": 5,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-78.484, 34.906],
                    [-0.544, -3.508],
                    [35.015, -4.052],
                    [1.847, 5.726],
                    [-4.149, 5.324]
                  ],
                  "o": [
                    [0, 0],
                    [0.543, 3.509],
                    [-35.016, 4.052],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [45.142, -17.453],
                    [48.929, -12.922],
                    [0.597, 5.96],
                    [-47.672, -4.599],
                    [-45.323, -11.078]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.0078, 0.1059, 0.3059], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [49.722, 312.819], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 6",
          "ix": 6,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [45.307, 16.152],
                    [0.97, -2.427],
                    [-17.1, -123.046],
                    [-21.29, 11.116],
                    [-3.085, -3.727],
                    [-54.71, 65.284]
                  ],
                  "o": [
                    [0, 0],
                    [-0.97, 2.426],
                    [0, 0],
                    [17.39, -9.08],
                    [0, 0],
                    [39.453, -47.079]
                  ],
                  "v": [
                    [23.167, -130.84],
                    [-27.674, -125.13],
                    [-61.097, 116.844],
                    [-25.805, 119.725],
                    [8.531, 127.818],
                    [38.745, -5.949]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.898, 0.3059, 0.2667], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [111.575, 131.09], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 7",
          "ix": 7,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-14.784, -0.777],
                    [0, 0],
                    [3.338, 0.989],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [-13.572, 1.559],
                    [5.382, 7.988]
                  ],
                  "v": [
                    [17.091, 11.672],
                    [17.759, -9.978],
                    [12.178, -11.672],
                    [-17.759, -2.884]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.6118, 0.2392, 0.2392], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [68.236, 250.818], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 8",
          "ix": 8,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [2.084, -2.825],
                    [0, 0],
                    [0, 0],
                    [-17.703, 16.933],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-17.082, -30.867],
                    [-17.847, -18.128],
                    [-14.787, 14.603],
                    [16.468, 16.759],
                    [17.847, -23.353]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.949, 0.749, 0.749], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [68.147, 264.193], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 9",
          "ix": 9,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-17.343, -10.374],
                    [-3.482, -6.256],
                    [23.133, 29.237]
                  ],
                  "o": [
                    [0, 0],
                    [4.382, 7.875],
                    [0, 0]
                  ],
                  "v": [
                    [19.109, -11.144],
                    [32.624, 3.256],
                    [-37.006, -7.719]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.0078, 0.1059, 0.3059], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [87.483, 255.652], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 8
    },
    {
      "ty": 4,
      "nm": "Stopwatch Outlines",
      "sr": 1,
      "st": 0,
      "op": 300,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [100.398, 121.997, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [337.436, 156.358, 0], "ix": 2 },
        "r": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [0],
              "t": 0
            },
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [-7.348],
              "t": 150
            },
            { "s": [0], "t": 299 }
          ],
          "ix": 10
        },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-1.249, -0.584],
                    [0, 0],
                    [0.506, -1.249],
                    [0, 0],
                    [1.255, 0.383],
                    [0, 0],
                    [-0.693, 1.402],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [1.221, 0.571],
                    [0, 0],
                    [-0.493, 1.216],
                    [0, 0],
                    [-1.496, -0.456],
                    [0, 0],
                    [0.611, -1.236]
                  ],
                  "v": [
                    [-0.966, -11.878],
                    [10.69, -6.422],
                    [11.965, -3.175],
                    [6.378, 10.607],
                    [3.291, 12.078],
                    [-10.245, 7.953],
                    [-11.778, 4.407],
                    [-4.312, -10.705]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.2275, 0.4706, 0.949], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [12.721, 106.276], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 2",
          "ix": 2,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0.905, -1.04],
                    [0, 0],
                    [-0.968, -0.937],
                    [0, 0],
                    [-0.992, 0.858],
                    [0, 0],
                    [1.201, 1.001],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [-0.884, 1.017],
                    [0, 0],
                    [0.943, 0.912],
                    [0, 0],
                    [1.183, -1.023],
                    [0, 0],
                    [-1.059, -0.883]
                  ],
                  "v": [
                    [-4.184, -11.659],
                    [-12.632, -1.951],
                    [-12.482, 1.534],
                    [-1.791, 11.872],
                    [1.628, 11.968],
                    [12.333, 2.714],
                    [12.298, -1.151],
                    [-0.65, -11.943]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.2275, 0.4706, 0.949], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": {
                "a": 1,
                "k": [
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [172.958, 72.832],
                    "t": 16,
                    "ti": [0, 0],
                    "to": [-0.775, 0.703]
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [168.31, 77.053],
                    "t": 23,
                    "ti": [-0.775, 0.703],
                    "to": [0, 0]
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [172.958, 72.832],
                    "t": 30,
                    "ti": [0, 0],
                    "to": [0, 0]
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [172.958, 72.832],
                    "t": 205,
                    "ti": [0, 0],
                    "to": [-0.775, 0.703]
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [168.31, 77.053],
                    "t": 210,
                    "ti": [-0.775, 0.703],
                    "to": [0, 0]
                  },
                  { "s": [172.958, 72.832], "t": 216 }
                ],
                "ix": 2
              },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 3",
          "ix": 3,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-0.447, -1.757],
                    [0, 0],
                    [-1.739, 0.345],
                    [0, 0],
                    [0.184, 1.716],
                    [0, 0],
                    [1.982, -0.548],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0.436, 1.718],
                    [0, 0],
                    [1.692, -0.337],
                    [0, 0],
                    [-0.22, -2.045],
                    [0, 0],
                    [-1.748, 0.484]
                  ],
                  "v": [
                    [-14.818, -4.35],
                    [-10.647, 12.055],
                    [-6.768, 14.503],
                    [12.417, 10.686],
                    [15.081, 7.061],
                    [13.092, -11.444],
                    [8.889, -14.299],
                    [-12.477, -8.384]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 0.7804, 0.5216], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [84.575, 45.841], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 4",
          "ix": 4,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-1.544, 2.373],
                    [2.373, 1.544],
                    [1.544, -2.373],
                    [-2.373, -1.544]
                  ],
                  "o": [
                    [1.544, -2.373],
                    [-2.373, -1.544],
                    [-1.544, 2.372],
                    [2.373, 1.544]
                  ],
                  "v": [
                    [-45.484, -29.597],
                    [-46.985, -36.688],
                    [-54.076, -35.188],
                    [-52.576, -28.096]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 2",
              "ix": 2,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [2.373, 1.544],
                    [-1.544, 2.373],
                    [-2.373, -1.544],
                    [1.544, -2.373]
                  ],
                  "o": [
                    [-2.373, -1.544],
                    [1.544, -2.373],
                    [2.372, 1.544],
                    [-1.544, 2.372]
                  ],
                  "v": [
                    [46.985, 36.688],
                    [45.484, 29.596],
                    [52.576, 28.096],
                    [54.076, 35.188]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "mm",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Filter - Merge",
              "nm": "Merge Paths 1",
              "mm": 1
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.0078, 0.1059, 0.3059], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [106.634, 149.831], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 5",
          "ix": 5,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [2.373, 1.544],
                    [1.544, -2.373],
                    [-2.372, -1.544],
                    [-1.544, 2.373]
                  ],
                  "o": [
                    [-2.373, -1.544],
                    [-1.544, 2.373],
                    [2.373, 1.544],
                    [1.544, -2.373]
                  ],
                  "v": [
                    [-29.597, 45.484],
                    [-36.689, 46.985],
                    [-35.189, 54.076],
                    [-28.096, 52.576]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 2",
              "ix": 2,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [1.544, -2.373],
                    [2.373, 1.544],
                    [-1.544, 2.373],
                    [-2.373, -1.544]
                  ],
                  "o": [
                    [-1.544, 2.373],
                    [-2.373, -1.544],
                    [1.544, -2.372],
                    [2.372, 1.544]
                  ],
                  "v": [
                    [36.688, -46.985],
                    [29.596, -45.483],
                    [28.096, -52.576],
                    [35.188, -54.076]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "mm",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Filter - Merge",
              "nm": "Merge Paths 1",
              "mm": 1
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.0078, 0.1059, 0.3059], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [106.634, 149.831], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 6",
          "ix": 6,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0.586, 2.77],
                    [2.77, -0.586],
                    [-0.586, -2.769],
                    [-2.77, 0.586]
                  ],
                  "o": [
                    [-0.586, -2.77],
                    [-2.77, 0.586],
                    [0.586, 2.77],
                    [2.77, -0.586]
                  ],
                  "v": [
                    [-53.09, 11.234],
                    [-59.165, 7.28],
                    [-63.12, 13.356],
                    [-57.044, 17.31]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 2",
              "ix": 2,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [2.769, -0.586],
                    [0.586, 2.77],
                    [-2.77, 0.586],
                    [-0.586, -2.77]
                  ],
                  "o": [
                    [-2.77, 0.586],
                    [-0.586, -2.77],
                    [2.77, -0.586],
                    [0.586, 2.769]
                  ],
                  "v": [
                    [59.167, -7.28],
                    [53.089, -11.234],
                    [57.043, -17.31],
                    [63.12, -13.356]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "mm",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Filter - Merge",
              "nm": "Merge Paths 1",
              "mm": 1
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.0078, 0.1059, 0.3059], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [106.634, 149.831], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 7",
          "ix": 7,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [2.77, -0.586],
                    [-0.586, -2.77],
                    [-2.769, 0.586],
                    [0.586, 2.77]
                  ],
                  "o": [
                    [-2.77, 0.586],
                    [0.586, 2.77],
                    [2.77, -0.586],
                    [-0.586, -2.77]
                  ],
                  "v": [
                    [11.234, 53.09],
                    [7.28, 59.165],
                    [13.356, 63.12],
                    [17.31, 57.044]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 2",
              "ix": 2,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-0.586, -2.769],
                    [2.77, -0.586],
                    [0.586, 2.77],
                    [-2.77, 0.586]
                  ],
                  "o": [
                    [0.586, 2.77],
                    [-2.77, 0.586],
                    [-0.586, -2.77],
                    [2.77, -0.586]
                  ],
                  "v": [
                    [-7.28, -59.167],
                    [-11.234, -53.089],
                    [-17.31, -57.044],
                    [-13.356, -63.12]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "mm",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Filter - Merge",
              "nm": "Merge Paths 1",
              "mm": 1
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.0078, 0.1059, 0.3059], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [106.634, 149.831], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 8",
          "ix": 8,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0.312, -2.346],
                    [3.36, 0.446],
                    [-0.446, 3.36],
                    [-3.635, 13.653],
                    [0.006, -1.585]
                  ],
                  "o": [
                    [-0.446, 3.36],
                    [-3.36, -0.446],
                    [0.312, -2.346],
                    [0.408, -1.531],
                    [-0.055, 14.129]
                  ],
                  "v": [
                    [6.052, 18.2],
                    [-0.84, 23.476],
                    [-6.116, 16.584],
                    [3.9, -22.391],
                    [6.556, -22.038]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.898, 0.3059, 0.2667], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [-0.602, 16.84], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [107.33, 151.039], "ix": 2 },
              "r": {
                "a": 1,
                "k": [
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [-19.177],
                    "t": 30
                  },
                  { "s": [340.823], "t": 210 }
                ],
                "ix": 6
              },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 9",
          "ix": 9,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-2.177, -10.288],
                    [13.472, -2.851],
                    [2.177, 10.289],
                    [-13.472, 2.851]
                  ],
                  "o": [
                    [2.177, 10.288],
                    [-13.472, 2.85],
                    [-2.177, -10.288],
                    [13.472, -2.851]
                  ],
                  "v": [
                    [24.393, -5.161],
                    [3.942, 18.63],
                    [-24.393, 5.161],
                    [-3.942, -18.628]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 1,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 4, "ix": 5 },
              "c": { "a": 0, "k": [0.0078, 0.1059, 0.3059], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [81.591, 31.479], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 10",
          "ix": 10,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-7.93, -37.476],
                    [37.476, -7.93],
                    [7.93, 37.476],
                    [-37.476, 7.93]
                  ],
                  "o": [
                    [7.93, 37.476],
                    [-37.476, 7.93],
                    [-7.93, -37.476],
                    [37.476, -7.93]
                  ],
                  "v": [
                    [67.857, -14.359],
                    [14.358, 67.856],
                    [-67.856, 14.359],
                    [-14.358, -67.856]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.902, 0.9059, 0.9333], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [106.634, 149.831], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 11",
          "ix": 11,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-9.827, -46.439],
                    [46.439, -9.826],
                    [9.827, 46.44],
                    [-46.439, 9.827]
                  ],
                  "o": [
                    [9.827, 46.439],
                    [-46.439, 9.827],
                    [-9.827, -46.439],
                    [46.439, -9.826]
                  ],
                  "v": [
                    [84.086, -17.793],
                    [17.793, 84.085],
                    [-84.086, 17.792],
                    [-17.792, -84.086]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.2275, 0.4706, 0.949], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [106.634, 149.832], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 12",
          "ix": 12,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [8.832, -0.364],
                    [-4.22, -7.199],
                    [-8.832, 2.522],
                    [6.436, 7.199]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.0078, 0.1059, 0.3059], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [24.113, 110.567], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 13",
          "ix": 13,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-8.66, 2.796],
                    [0.506, -8.74],
                    [8.66, -1.721],
                    [-3.406, 8.74]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.0078, 0.1059, 0.3059], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [164.462, 81.41], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 14",
          "ix": 14,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-2.376, 11.053],
                    [-7.985, -7.496],
                    [5.712, -11.053],
                    [7.985, 9.824]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.0078, 0.1059, 0.3059], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [87.321, 61.712], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 9
    },
    {
      "ty": 4,
      "nm": "Leaf 1 Outlines",
      "sr": 1,
      "st": 0,
      "op": 300,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [83.706, 209.993, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [137.466, 654.47, 0], "ix": 2 },
        "r": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [0],
              "t": 0
            },
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [-10.111],
              "t": 150
            },
            { "s": [0], "t": 299 }
          ],
          "ix": 10
        },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [-5.635, -2.001]
                  ],
                  "o": [
                    [5.585, 2],
                    [0, 0]
                  ],
                  "v": [
                    [-8.415, -3.001],
                    [8.415, 3.001]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [54.881, 153.403], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 2",
          "ix": 2,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [5.048, -2.742]
                  ],
                  "o": [
                    [-5.139, 2.759],
                    [0, 0]
                  ],
                  "v": [
                    [7.641, -4.126],
                    [-7.641, 4.126]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [68.281, 143.39], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 3",
          "ix": 3,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [-10.846, -3.004]
                  ],
                  "o": [
                    [10.736, 2.991],
                    [0, 0]
                  ],
                  "v": [
                    [-16.187, -4.496],
                    [16.187, 4.497]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [41.689, 133.527], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 4",
          "ix": 4,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [5.199, -3.494]
                  ],
                  "o": [
                    [-5.346, 3.514],
                    [0, 0]
                  ],
                  "v": [
                    [7.909, -5.257],
                    [-7.909, 5.257]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [63.243, 123.74], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 5",
          "ix": 5,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [-7.84, -2.238]
                  ],
                  "o": [
                    [7.779, 2.228],
                    [0, 0]
                  ],
                  "v": [
                    [-11.714, -3.35],
                    [11.714, 3.35]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [40.481, 113.923], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 6",
          "ix": 6,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [5.531, -6.57]
                  ],
                  "o": [
                    [-6.051, 6.588],
                    [0, 0]
                  ],
                  "v": [
                    [8.687, -9.871],
                    [-8.687, 9.871]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [58.703, 98.772], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 7",
          "ix": 7,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [-9.107, -2.828]
                  ],
                  "o": [
                    [9.01, 2.809],
                    [0, 0]
                  ],
                  "v": [
                    [-13.589, -4.228],
                    [13.589, 4.228]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [33.489, 91.894], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 8",
          "ix": 8,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [3.547, -4.318]
                  ],
                  "o": [
                    [-3.773, 4.315],
                    [0, 0]
                  ],
                  "v": [
                    [5.49, -6.476],
                    [-5.49, 6.476]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [50.691, 80.943], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 9",
          "ix": 9,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [-6.73, -3.21]
                  ],
                  "o": [
                    [6.604, 3.185],
                    [0, 0]
                  ],
                  "v": [
                    [-10.001, -4.797],
                    [10.001, 4.797]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [31.907, 65.326], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 10",
          "ix": 10,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [3.423, -4.459]
                  ],
                  "o": [
                    [-3.668, 4.439],
                    [0, 0]
                  ],
                  "v": [
                    [5.318, -6.674],
                    [-5.318, 6.674]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [45.437, 52.394], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 11",
          "ix": 11,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [-5.891, -2.822]
                  ],
                  "o": [
                    [5.792, 2.797],
                    [0, 0]
                  ],
                  "v": [
                    [-8.762, -4.215],
                    [8.762, 4.215]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [29.918, 44.557], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 12",
          "ix": 12,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [-4.883, -27.406],
                    [-10.748, -33.514]
                  ],
                  "o": [
                    [0.459, 0.022],
                    [4.569, 27.282],
                    [0, 0]
                  ],
                  "v": [
                    [-15.055, -71.456],
                    [-9.367, -25.773],
                    [15.055, 71.456]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [50.961, 93.939], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 13",
          "ix": 13,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [2.107, 0.495],
                    [22.287, 12.651],
                    [2.388, 14.991],
                    [-8.598, 1.056],
                    [2.523, 8.492],
                    [-0.243, -0.015],
                    [-11.427, -3.878],
                    [4.664, 6.048],
                    [-0.859, -0.123],
                    [-5.932, -3.386],
                    [-16.78, 10.619],
                    [10.89, -17.849],
                    [-2.993, 8.552],
                    [7.35, -12.585],
                    [-2.515, 12.062],
                    [-0.651, -19.924],
                    [3.463, -7.865],
                    [-8.162, -1.592],
                    [9.377, -12.642],
                    [-0.704, 0.149]
                  ],
                  "o": [
                    [-0.724, 0.169],
                    [-23.339, -12.463],
                    [-0.018, -0.003],
                    [-0.008, -0.007],
                    [-2.907, -8.516],
                    [0.005, 0.002],
                    [0, -0.002],
                    [-5.69, -6.166],
                    [-0.033, -0.006],
                    [-0.207, -0.043],
                    [-0.099, -0.05],
                    [0.002, 0.003],
                    [-0.571, -0.118],
                    [0.066, 0.007],
                    [-0.408, -0.072],
                    [0.026, 0],
                    [-0.05, 0.004],
                    [-0.928, 0.007],
                    [-11.617, 12.897],
                    [-2.11, -0.495]
                  ],
                  "v": [
                    [34.923, 105.248],
                    [0.488, 53.398],
                    [-33.621, 10.52],
                    [-7.848, 14.911],
                    [-38.867, -2.257],
                    [-41.412, -32.551],
                    [-18.158, -29.952],
                    [-38.766, -41.745],
                    [-24.184, -85.462],
                    [-16.355, -74.165],
                    [-5.441, -105.693],
                    [-2.158, -67.656],
                    [8.74, -83.937],
                    [10.717, -38.844],
                    [18.618, -54.148],
                    [31.225, -2.221],
                    [16.046, 8.351],
                    [35.178, 0.698],
                    [35.079, 55.137],
                    [41.248, 103.564]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.5569, 0.8549, 0.5882], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [44.706, 105.993], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 10
    },
    {
      "ty": 4,
      "nm": "Leaf 2 Outlines",
      "sr": 1,
      "st": 0,
      "op": 300,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [16.415, 131.116, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [139.158, 652.655, 0], "ix": 2 },
        "r": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [0],
              "t": 0
            },
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [10.9],
              "t": 150
            },
            { "s": [0], "t": 299 }
          ],
          "ix": 10
        },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [4.205, -1.493]
                  ],
                  "o": [
                    [-4.168, 1.493],
                    [0, 0]
                  ],
                  "v": [
                    [6.28, -2.24],
                    [-6.28, 2.24]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [23.81, 114.542], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 2",
          "ix": 2,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [-3.768, -2.047]
                  ],
                  "o": [
                    [3.835, 2.059],
                    [0, 0]
                  ],
                  "v": [
                    [-5.702, -3.079],
                    [5.702, 3.079]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [13.81, 107.07], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 3",
          "ix": 3,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [8.094, -2.242]
                  ],
                  "o": [
                    [-8.012, 2.232],
                    [0, 0]
                  ],
                  "v": [
                    [12.079, -3.355],
                    [-12.079, 3.355]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [33.654, 99.71], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 4",
          "ix": 4,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [-3.879, -2.608]
                  ],
                  "o": [
                    [3.99, 2.622],
                    [0, 0]
                  ],
                  "v": [
                    [-5.902, -3.923],
                    [5.902, 3.923]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [17.569, 92.406], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 5",
          "ix": 5,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [5.85, -1.67]
                  ],
                  "o": [
                    [-5.805, 1.663],
                    [0, 0]
                  ],
                  "v": [
                    [8.742, -2.5],
                    [-8.742, 2.5]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [34.556, 85.08], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 6",
          "ix": 6,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [-4.128, -4.903]
                  ],
                  "o": [
                    [4.516, 4.916],
                    [0, 0]
                  ],
                  "v": [
                    [-6.482, -7.367],
                    [6.482, 7.367]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [20.957, 73.773], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 7",
          "ix": 7,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [6.796, -2.111]
                  ],
                  "o": [
                    [-6.724, 2.096],
                    [0, 0]
                  ],
                  "v": [
                    [10.141, -3.155],
                    [-10.141, 3.155]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [39.774, 68.64], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 8",
          "ix": 8,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [-2.647, -3.222]
                  ],
                  "o": [
                    [2.816, 3.22],
                    [0, 0]
                  ],
                  "v": [
                    [-4.097, -4.833],
                    [4.097, 4.833]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [26.936, 60.468], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 9",
          "ix": 9,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [5.023, -2.396]
                  ],
                  "o": [
                    [-4.928, 2.377],
                    [0, 0]
                  ],
                  "v": [
                    [7.463, -3.58],
                    [-7.463, 3.58]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [40.954, 48.814], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 10",
          "ix": 10,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [-2.554, -3.327]
                  ],
                  "o": [
                    [2.737, 3.313],
                    [0, 0]
                  ],
                  "v": [
                    [-3.969, -4.98],
                    [3.969, 4.98]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [30.858, 39.163], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 11",
          "ix": 11,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [4.397, -2.106]
                  ],
                  "o": [
                    [-4.322, 2.087],
                    [0, 0]
                  ],
                  "v": [
                    [6.539, -3.145],
                    [-6.539, 3.145]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [42.438, 33.314], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 12",
          "ix": 12,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [3.643, -20.453],
                    [8.021, -25.01]
                  ],
                  "o": [
                    [-0.342, 0.016],
                    [-3.411, 20.359],
                    [0, 0]
                  ],
                  "v": [
                    [11.236, -53.325],
                    [6.992, -19.233],
                    [-11.236, 53.325]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [26.735, 70.166], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 13",
          "ix": 13,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-1.572, 0.369],
                    [-16.632, 9.441],
                    [-1.782, 11.187],
                    [6.417, 0.788],
                    [-1.883, 6.338],
                    [0.181, -0.011],
                    [8.528, -2.894],
                    [-3.48, 4.513],
                    [0.642, -0.092],
                    [4.427, -2.527],
                    [12.523, 7.925],
                    [-8.128, -13.32],
                    [2.233, 6.383],
                    [-5.485, -9.392],
                    [1.877, 9.001],
                    [0.486, -14.869],
                    [-2.585, -5.869],
                    [6.091, -1.188],
                    [-4.975, -10.641],
                    [0.525, 0.111]
                  ],
                  "o": [
                    [2.031, -5.159],
                    [17.417, -9.301],
                    [0.013, -0.002],
                    [0.007, -0.004],
                    [2.169, -6.355],
                    [-0.004, 0.001],
                    [0, -0.001],
                    [4.247, -4.602],
                    [0.025, -0.005],
                    [0.155, -0.032],
                    [0.074, -0.037],
                    [-0.002, 0.002],
                    [0.426, -0.087],
                    [-0.049, 0.005],
                    [0.305, -0.054],
                    [-0.019, 0],
                    [0.037, 0.003],
                    [0.692, 0.006],
                    [2.883, 6.166],
                    [1.574, -0.369]
                  ],
                  "v": [
                    [-16.915, 66.172],
                    [-1.376, 51.893],
                    [24.079, 19.895],
                    [4.844, 23.171],
                    [27.993, 10.361],
                    [29.893, -12.247],
                    [12.538, -10.308],
                    [27.918, -19.108],
                    [17.036, -51.732],
                    [11.193, -43.302],
                    [3.049, -66.83],
                    [0.6, -38.444],
                    [-7.533, -50.595],
                    [-9.009, -16.944],
                    [-14.906, -28.364],
                    [-24.313, 10.387],
                    [-12.986, 18.277],
                    [-27.264, 12.566],
                    [-27.189, 53.191],
                    [-24.307, 66.755]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.4392, 0.6588, 0.2078], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [32.415, 67.116], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 11
    },
    {
      "ty": 4,
      "nm": "Leaf 3 Outlines",
      "sr": 1,
      "st": 0,
      "op": 300,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [79.683, 269.413, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [759.059, 651.182, 0], "ix": 2 },
        "r": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [0],
              "t": 0
            },
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [-13.427],
              "t": 150
            },
            { "s": [0], "t": 299 }
          ],
          "ix": 10
        },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-10.267, -4.085],
                    [10.267, 4.085]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [68.362, 200.314], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 2",
          "ix": 2,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [11.031, -5.093],
                    [-11.031, 5.093]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [88.596, 187.786], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 3",
          "ix": 3,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-20.589, -6.229],
                    [20.589, 6.23]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [55.456, 174.379], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 4",
          "ix": 4,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [11.088, -6.493],
                    [-11.088, 6.493]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [85.355, 162.47], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 5",
          "ix": 5,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-15.232, -4.654],
                    [15.232, 4.655]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [56.289, 149.2], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 6",
          "ix": 6,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [11.376, -12.359],
                    [-11.376, 12.359]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [80.585, 130.378], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 7",
          "ix": 7,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-18.142, -5.895],
                    [18.142, 5.895]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [47.303, 120.7], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 8",
          "ix": 8,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [6.573, -8.143],
                    [-6.573, 8.143]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [69.133, 107.213], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 9",
          "ix": 9,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-14.124, -6.602],
                    [14.124, 6.602]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [42.07, 86.338], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 10",
          "ix": 10,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [5.286, -8.505],
                    [-5.286, 8.505]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [56.976, 70.025], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 11",
          "ix": 11,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-12.872, -5.895],
                    [12.872, 5.895]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [34.314, 59.14], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 12",
          "ix": 12,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [-3.457, -87.298]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-22.597, -92.977],
                    [22.597, 92.977]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [56.713, 123.116], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 13",
          "ix": 13,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [26.834, 16.721],
                    [1.95, 19.341],
                    [-11.451, 1.103],
                    [3.238, 11.025],
                    [0, 0],
                    [-15.624, -5.426],
                    [7.368, 8.058],
                    [0, 0],
                    [-9.064, -4.732],
                    [-15.621, 13.892],
                    [8.688, -22.968],
                    [-0.196, 11.298],
                    [8.563, -15.809],
                    [-0.271, 15.605],
                    [0.44, -25.347],
                    [5.104, -9.955],
                    [-10.677, -2.299],
                    [18.179, -16.167],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [-26.834, -16.721],
                    [0, 0],
                    [0, 0],
                    [-3.237, -11.025],
                    [0, 0],
                    [0, 0],
                    [-7.367, -8.059],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-18.179, 16.168],
                    [0, 0]
                  ],
                  "v": [
                    [18.392, 135.067],
                    [-4.886, 70.399],
                    [-41.382, 14.217],
                    [-7.769, 20.632],
                    [-47.667, -2.382],
                    [-52.987, -41.586],
                    [-21.908, -37.381],
                    [-51.066, -53.438],
                    [-45.786, -110.566],
                    [-30.751, -95.141],
                    [-31.319, -137.163],
                    [-9.632, -85.934],
                    [-1.548, -107.081],
                    [14.856, -47.824],
                    [21.912, -67.391],
                    [44.892, -0.083],
                    [24.373, 12.954],
                    [50.04, 3.772],
                    [40.254, 73.492],
                    [25.858, 137.163]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.4392, 0.6588, 0.2078], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [58.683, 137.413], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 12
    },
    {
      "ty": 4,
      "nm": "Leaf 4 Outlines",
      "sr": 1,
      "st": 0,
      "op": 300,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [18.601, 470.872, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [763.988, 651.949, 0], "ix": 2 },
        "r": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [0],
              "t": 0
            },
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [-6.827],
              "t": 150
            },
            { "s": [0], "t": 299 }
          ],
          "ix": 10
        },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [10.482, -4.523]
                  ],
                  "o": [
                    [-10.388, 4.519],
                    [0, 0]
                  ],
                  "v": [
                    [15.652, -6.781],
                    [-15.652, 6.781]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [65.964, 346.288], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 2",
          "ix": 2,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [-9.906, -6.196]
                  ],
                  "o": [
                    [10.081, 6.235],
                    [0, 0]
                  ],
                  "v": [
                    [-14.991, -9.323],
                    [14.991, 9.323]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [39.356, 323.663], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 3",
          "ix": 3,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [20.272, -6.786]
                  ],
                  "o": [
                    [-20.065, 6.758],
                    [0, 0]
                  ],
                  "v": [
                    [30.252, -10.159],
                    [-30.252, 10.159]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [88.775, 301.379], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 4",
          "ix": 4,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [-10.279, -7.896]
                  ],
                  "o": [
                    [10.56, 7.939],
                    [0, 0]
                  ],
                  "v": [
                    [-15.629, -11.877],
                    [15.629, 11.877]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [46.697, 279.266], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 5",
          "ix": 5,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [14.645, -5.056]
                  ],
                  "o": [
                    [-14.531, 5.035],
                    [0, 0]
                  ],
                  "v": [
                    [21.882, -7.568],
                    [-21.882, 7.569]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [88.841, 257.084], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 6",
          "ix": 6,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [-11.261, -14.843]
                  ],
                  "o": [
                    [12.25, 14.885],
                    [0, 0]
                  ],
                  "v": [
                    [-17.633, -22.303],
                    [17.633, 22.303]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [52.486, 222.852], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 7",
          "ix": 7,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [16.988, -6.39]
                  ],
                  "o": [
                    [-16.806, 6.346],
                    [0, 0]
                  ],
                  "v": [
                    [25.345, -9.552],
                    [-25.345, 9.552]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [99.625, 207.312], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 8",
          "ix": 8,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [-7.232, -9.755]
                  ],
                  "o": [
                    [7.662, 9.75],
                    [0, 0]
                  ],
                  "v": [
                    [-11.171, -14.63],
                    [11.171, 14.63]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [65.685, 182.569], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 9",
          "ix": 9,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [12.426, -7.253]
                  ],
                  "o": [
                    [-12.188, 7.197],
                    [0, 0]
                  ],
                  "v": [
                    [18.461, -10.839],
                    [-18.461, 10.839]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [99.609, 147.284], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 10",
          "ix": 10,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [-7.013, -10.073]
                  ],
                  "o": [
                    [7.476, 10.03],
                    [0, 0]
                  ],
                  "v": [
                    [-10.866, -15.079],
                    [10.866, 15.079]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [72.425, 118.066], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 11",
          "ix": 11,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [10.876, -6.376]
                  ],
                  "o": [
                    [-10.689, 6.32],
                    [0, 0]
                  ],
                  "v": [
                    [16.174, -9.523],
                    [-16.174, 9.523]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [101.027, 100.358], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 12",
          "ix": 12,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [6.162, -61.922],
                    [16.618, -75.722]
                  ],
                  "o": [
                    [-0.87, 0.049],
                    [-5.583, 61.641],
                    [0, 0]
                  ],
                  "v": [
                    [20.214, -161.448],
                    [14.6, -58.231],
                    [-20.762, 161.448]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [66.923, 211.933], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 13",
          "ix": 13,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-3.948, 1.117],
                    [-40.919, 28.581],
                    [-2.834, 33.872],
                    [16.462, 2.385],
                    [-3.829, 19.188],
                    [0.46, -0.033],
                    [21.278, -8.76],
                    [-8.177, 13.664],
                    [1.619, -0.277],
                    [10.889, -7.65],
                    [33.099, 23.993],
                    [-22.729, -40.328],
                    [6.662, 19.323],
                    [-15.4, -28.434],
                    [6.151, 27.253],
                    [-1.029, -45.016],
                    [-7.477, -17.77],
                    [15.332, -3.598],
                    [-19.26, -28.564],
                    [1.355, 0.335]
                  ],
                  "o": [
                    [1.396, 0.383],
                    [42.939, -28.162],
                    [0.034, -0.005],
                    [0.016, -0.014],
                    [4.557, -19.241],
                    [-0.009, 0.006],
                    [0, -0.002],
                    [10.113, -13.933],
                    [0.062, -0.014],
                    [0.39, -0.097],
                    [0.182, -0.113],
                    [-0.003, 0.006],
                    [1.073, -0.265],
                    [-0.125, 0.016],
                    [0.767, -0.164],
                    [-0.05, 0],
                    [0.096, 0.009],
                    [1.765, 0.016],
                    [23.546, 29.139],
                    [3.953, -1.118]
                  ],
                  "v": [
                    [-54.456, 232.935],
                    [5.454, 122.943],
                    [65.404, 26.062],
                    [16.919, 35.982],
                    [73.921, -2.804],
                    [75.312, -71.253],
                    [31.412, -65.382],
                    [69.238, -92.025],
                    [36.552, -190.799],
                    [22.957, -165.275],
                    [-1.371, -236.51],
                    [-3.284, -150.568],
                    [-25.849, -187.355],
                    [-24.478, -85.473],
                    [-41.234, -120.05],
                    [-59.289, -2.725],
                    [-29.238, 21.162],
                    [-66.471, 3.87],
                    [-60.091, 126.87],
                    [-66.308, 236.287]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.5569, 0.8549, 0.5882], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [79.601, 236.872], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 13
    },
    {
      "ty": 4,
      "nm": "Leaf 5 Outlines",
      "sr": 1,
      "st": 0,
      "op": 300,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [6.637, 318.922, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [771.408, 652.712, 0], "ix": 2 },
        "r": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [0],
              "t": 0
            },
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [4.317],
              "t": 150
            },
            { "s": [0], "t": 299 }
          ],
          "ix": 10
        },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [7.776, 1.571]
                  ],
                  "o": [
                    [-7.76, -1.62],
                    [0, 0]
                  ],
                  "v": [
                    [11.652, 2.393],
                    [-11.652, -2.394]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [70.875, 245.654], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 2",
          "ix": 2,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [-4.47, -9.378]
                  ],
                  "o": [
                    [5.07, 9.111],
                    [0, 0]
                  ],
                  "v": [
                    [-7.158, -13.87],
                    [7.158, 13.87]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [60.401, 216.044], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 3",
          "ix": 3,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [15.022, 4.38]
                  ],
                  "o": [
                    [-14.886, -4.717],
                    [0, 0]
                  ],
                  "v": [
                    [22.432, 6.822],
                    [-22.432, -6.823]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [98.762, 222.551], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 4",
          "ix": 4,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [-4.596, -10.605]
                  ],
                  "o": [
                    [5.346, 10.294],
                    [0, 0]
                  ],
                  "v": [
                    [-7.461, -15.679],
                    [7.461, 15.679]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [77.07, 186.592], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 5",
          "ix": 5,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [11.347, 2.904]
                  ],
                  "o": [
                    [-11.287, -3.09],
                    [0, 0]
                  ],
                  "v": [
                    [16.976, 4.496],
                    [-16.976, -4.496]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [111.948, 189.29], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 6",
          "ix": 6,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [-3.734, -15.884]
                  ],
                  "o": [
                    [5.395, 15.471],
                    [0, 0]
                  ],
                  "v": [
                    [-6.853, -23.534],
                    [6.853, 23.533]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [95.643, 148.373], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 7",
          "ix": 7,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [13.839, 2.792]
                  ],
                  "o": [
                    [-13.784, -3.036],
                    [0, 0]
                  ],
                  "v": [
                    [20.719, 4.371],
                    [-20.719, -4.371]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [133.883, 157.506], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 8",
          "ix": 8,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [-2.568, -10.162]
                  ],
                  "o": [
                    [3.244, 9.999],
                    [0, 0]
                  ],
                  "v": [
                    [-4.36, -15.125],
                    [4.36, 15.125]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [116.037, 124.893], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 9",
          "ix": 9,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [11.327, -0.01]
                  ],
                  "o": [
                    [-11.33, -0.03],
                    [0, 0]
                  ],
                  "v": [
                    [16.993, 0.02],
                    [-16.993, -0.01]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [151.316, 113.736], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 10",
          "ix": 10,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [-2.541, -10.068]
                  ],
                  "o": [
                    [3.202, 9.919],
                    [0, 0]
                  ],
                  "v": [
                    [-4.307, -14.994],
                    [4.307, 14.994]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [138.603, 81.741], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 11",
          "ix": 11,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [10.302, -0.188]
                  ],
                  "o": [
                    [-10.306, 0.149],
                    [0, 0]
                  ],
                  "v": [
                    [15.456, -0.253],
                    [-15.456, 0.253]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [166.15, 80.505], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 12",
          "ix": 12,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [66.943, -100.155]
                  ],
                  "o": [
                    [0.847, 0.307],
                    [0, 0]
                  ],
                  "v": [
                    [59.165, -108.777],
                    [-60.012, 108.777]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.3647, 0.5373, 0.1608], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [110.691, 148.08], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 13",
          "ix": 13,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-2.431, -1.11],
                    [-33.301, 1.474],
                    [-13.251, 24.583],
                    [10.11, 9.527],
                    [-9.125, 12.623],
                    [0.398, 0.114],
                    [18.19, 2.934],
                    [-10.465, 6.367],
                    [1.945, -0.114],
                    [10.992, -1.15],
                    [20.285, 29.174],
                    [-7.287, -36.967],
                    [0.169, 15.841],
                    [-4.812, -26.041],
                    [-2.033, 21.228],
                    [9.495, -32.168],
                    [-0.454, -16.279],
                    [12.055, 3.589],
                    [-4.162, -31.013],
                    [0.617, 0.721]
                  ],
                  "o": [
                    [1.2, 0.596],
                    [34.519, -0.404],
                    [-0.013, -0.011],
                    [0.001, -0.136],
                    [9.587, -12.51],
                    [0.047, -0.034],
                    [-0.007, -0.044],
                    [12.551, -6.307],
                    [0.043, 0.021],
                    [0.051, 0.277],
                    [-0.211, 0.209],
                    [0.038, -0.039],
                    [0.485, 0.396],
                    [-0.094, -0.031],
                    [0.282, 0.301],
                    [-0.012, -0.057],
                    [0.194, -0.055],
                    [0.985, 0.619],
                    [6.858, 32.737],
                    [2.439, 1.098]
                  ],
                  "v": [
                    [-93.082, 161.076],
                    [-22.561, 103.584],
                    [46.488, 58.645],
                    [11.765, 42.652],
                    [61.767, 40.816],
                    [85.436, -10.073],
                    [51.926, -25.681],
                    [87.835, -28.327],
                    [94.203, -114.527],
                    [75.299, -101.506],
                    [77.152, -161.672],
                    [50.025, -101.286],
                    [42.051, -135.58],
                    [15.797, -63.594],
                    [11.665, -94.262],
                    [-30.568, -19.293],
                    [-15.27, 10.625],
                    [-37.293, -17.579],
                    [-63.491, 74.873],
                    [-100.387, 157.763]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.4392, 0.6588, 0.2078], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [100.637, 161.922], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 14
    },
    {
      "ty": 4,
      "nm": "Pin Outlines",
      "sr": 1,
      "st": 0,
      "op": 300,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [157.17, 145.383, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [268.927, 419.707, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [6.048, 0],
                    [0, 6.048],
                    [-6.048, 0],
                    [0, -6.049]
                  ],
                  "o": [
                    [-6.048, 0],
                    [0, -6.049],
                    [6.048, 0],
                    [0, 6.048]
                  ],
                  "v": [
                    [0, -1.472],
                    [-10.952, -12.423],
                    [0, -23.375],
                    [10.952, -12.423]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 2",
              "ix": 2,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [11.75, 0],
                    [0, -11.749],
                    [-6.303, -13.167],
                    [-2.244, 4.687],
                    [0, 7.806]
                  ],
                  "o": [
                    [-11.75, 0],
                    [0, 7.806],
                    [2.244, 4.687],
                    [6.303, -13.167],
                    [0, -11.749]
                  ],
                  "v": [
                    [0, -33.93],
                    [-21.275, -12.656],
                    [-5.581, 29.244],
                    [5.581, 29.244],
                    [21.275, -12.656]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "mm",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Filter - Merge",
              "nm": "Merge Paths 1",
              "mm": 1
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.898, 0.3059, 0.2667], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [-0.262, 29.18], "ix": 1 },
              "s": {
                "a": 1,
                "k": [
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [30.265, 30.265],
                    "t": 22
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [100, 100],
                    "t": 30
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [100, 74.766],
                    "t": 39
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [100, 100],
                    "t": 55
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [100, 100],
                    "t": 290
                  },
                  { "s": [30.265, 30.265], "t": 299 }
                ],
                "ix": 3
              },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [292.554, 285.764], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": {
                "a": 1,
                "k": [
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [0],
                    "t": 22
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [100],
                    "t": 30
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [100],
                    "t": 290
                  },
                  { "s": [0], "t": 299 }
                ],
                "ix": 7
              }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 3",
          "ix": 2,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [6.048, 0],
                    [0, 6.048],
                    [-6.048, 0],
                    [0, -6.049]
                  ],
                  "o": [
                    [-6.048, 0],
                    [0, -6.049],
                    [6.048, 0],
                    [0, 6.048]
                  ],
                  "v": [
                    [0, -1.472],
                    [-10.952, -12.423],
                    [0, -23.375],
                    [10.952, -12.423]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 2",
              "ix": 2,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [11.75, 0],
                    [0, -11.749],
                    [-6.303, -13.167],
                    [-2.244, 4.687],
                    [0, 7.806]
                  ],
                  "o": [
                    [-11.75, 0],
                    [0, 7.806],
                    [2.244, 4.687],
                    [6.303, -13.167],
                    [0, -11.749]
                  ],
                  "v": [
                    [0, -33.93],
                    [-21.275, -12.656],
                    [-5.581, 29.244],
                    [5.581, 29.244],
                    [21.275, -12.656]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "mm",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Filter - Merge",
              "nm": "Merge Paths 1",
              "mm": 1
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.898, 0.3059, 0.2667], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0.074, 30.43], "ix": 1 },
              "s": {
                "a": 1,
                "k": [
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [31.807, 31.807],
                    "t": 69
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [100, 100],
                    "t": 78
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [100, 70.929],
                    "t": 89
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [100, 100],
                    "t": 105
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [100, 100],
                    "t": 290
                  },
                  { "s": [31.807, 31.807], "t": 299 }
                ],
                "ix": 3
              },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [131.679, 287.014], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": {
                "a": 1,
                "k": [
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [0],
                    "t": 69
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [100],
                    "t": 78
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [100],
                    "t": 290
                  },
                  { "s": [0], "t": 299 }
                ],
                "ix": 7
              }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 2",
          "ix": 3,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [6.048, 0],
                    [0, 6.048],
                    [-6.048, 0],
                    [0, -6.049]
                  ],
                  "o": [
                    [-6.048, 0],
                    [0, -6.049],
                    [6.048, 0],
                    [0, 6.048]
                  ],
                  "v": [
                    [0, -1.472],
                    [-10.952, -12.423],
                    [0, -23.376],
                    [10.952, -12.423]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 2",
              "ix": 2,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [11.75, 0],
                    [0, -11.749],
                    [-6.303, -13.167],
                    [-2.244, 4.687],
                    [0, 7.806]
                  ],
                  "o": [
                    [-11.75, 0],
                    [0, 7.806],
                    [2.244, 4.687],
                    [6.303, -13.167],
                    [0, -11.749]
                  ],
                  "v": [
                    [0, -33.93],
                    [-21.275, -12.656],
                    [-5.581, 29.244],
                    [5.581, 29.244],
                    [21.275, -12.656]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "mm",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Filter - Merge",
              "nm": "Merge Paths 1",
              "mm": 1
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.898, 0.3059, 0.2667], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [-0.174, 30.348], "ix": 1 },
              "s": {
                "a": 1,
                "k": [
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [34.451, 34.451],
                    "t": 119
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [100, 100],
                    "t": 129
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [100, 73.271],
                    "t": 139
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [100, 100],
                    "t": 157
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [100, 100],
                    "t": 290
                  },
                  { "s": [34.451, 34.451], "t": 299 }
                ],
                "ix": 3
              },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [21.351, 168.041], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": {
                "a": 1,
                "k": [
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [0],
                    "t": 119
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [100],
                    "t": 129
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [100],
                    "t": 290
                  },
                  { "s": [0], "t": 299 }
                ],
                "ix": 7
              }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 4",
          "ix": 4,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [7.634, 0],
                    [0, 7.634],
                    [-7.634, 0],
                    [0, -7.634]
                  ],
                  "o": [
                    [-7.634, 0],
                    [0, -7.634],
                    [7.634, 0],
                    [0, 7.634]
                  ],
                  "v": [
                    [0, -1.859],
                    [-13.823, -15.682],
                    [0, -29.505],
                    [13.823, -15.682]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 2",
              "ix": 2,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [14.83, 0],
                    [0, -14.831],
                    [-7.957, -16.62],
                    [-2.832, 5.916],
                    [0, 9.853]
                  ],
                  "o": [
                    [-14.83, 0],
                    [0, 9.853],
                    [2.832, 5.916],
                    [7.957, -16.62],
                    [0, -14.831]
                  ],
                  "v": [
                    [0, -42.827],
                    [-26.853, -15.975],
                    [-7.044, 36.911],
                    [7.044, 36.911],
                    [26.853, -15.975]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "mm",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Filter - Merge",
              "nm": "Merge Paths 1",
              "mm": 1
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.898, 0.3059, 0.2667], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [-0.639, 38.959], "ix": 1 },
              "s": {
                "a": 1,
                "k": [
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [36.987, 36.987],
                    "t": 168
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [100, 100],
                    "t": 177
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [100, 72.456],
                    "t": 188
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [100, 100],
                    "t": 207
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [100, 100],
                    "t": 290
                  },
                  { "s": [36.987, 36.987], "t": 299 }
                ],
                "ix": 3
              },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [178.412, 82.036], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": {
                "a": 1,
                "k": [
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [0],
                    "t": 168
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [100],
                    "t": 177
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [100],
                    "t": 290
                  },
                  { "s": [0], "t": 299 }
                ],
                "ix": 7
              }
            }
          ]
        }
      ],
      "ind": 15
    },
    {
      "ty": 4,
      "nm": "Map Outlines",
      "sr": 1,
      "st": 0,
      "op": 300,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [258.12, 181.02, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [359.74, 470.704, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-28.565, -112.109],
                    [28.565, 112.108]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 1,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.0078, 0.1059, 0.3059], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [372.38, 175.034], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 2",
          "ix": 2,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-18, -108.359],
                    [18, 108.358]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 1,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.0078, 0.1059, 0.3059], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [247.945, 160.718], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 3",
          "ix": 3,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-20.348, -129.412],
                    [20.348, 129.411]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 1,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.0078, 0.1059, 0.3059], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [122.728, 207.035], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 4",
          "ix": 4,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.948, 0.503],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [1.01, 0.362],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [47.376, -25.785],
                    [-9.617, 7.989],
                    [-54.363, -27.928],
                    [-68.643, 5.589],
                    [-7.351, 27.566],
                    [-4.277, 27.345],
                    [68.643, -11.345]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.2275, 0.4706, 0.949], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [151.264, 319.374], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 5",
          "ix": 5,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.556, 0.107],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.548, 0.142],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-39.329, 19.903],
                    [-1.853, 28.611],
                    [46.217, 13.169],
                    [25.485, -28.611],
                    [-8.356, -19.825],
                    [-10.034, -19.771],
                    [-46.217, -26.725]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.5569, 0.8549, 0.5882], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [110.855, 91.135], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 6",
          "ix": 6,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.168, -2.494]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-2.455, -0.472],
                    [0, 0]
                  ],
                  "v": [
                    [-14.959, 17.822],
                    [17.736, 25.42],
                    [11.168, -20.295],
                    [-13.043, -24.947],
                    [-17.568, -20.947]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.2275, 0.4706, 0.949], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [32.554, 80.685], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 7",
          "ix": 7,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-1.427, -0.512],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.102, 1.513],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [28.041, 1.858],
                    [-8.219, -40.007],
                    [-28.041, -41.911],
                    [-23.34, 27.956],
                    [-20.822, 31.289],
                    [8.803, 41.911]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.2275, 0.4706, 0.949], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [54.774, 276.224], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 8",
          "ix": 8,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0.576, 1.569],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [1.327, -1.015]
                  ],
                  "v": [
                    [99.552, 20.09],
                    [71.771, -55.571],
                    [-20.109, 1.48],
                    [-82.263, -53.804],
                    [-100.128, -53.126],
                    [-79.206, 35.975],
                    [-12.395, 31.757],
                    [57.541, 55.572],
                    [98.288, 24.435]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.2275, 0.4706, 0.949], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [400.643, 205.555], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 9",
          "ix": 9,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.797, 0.609],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.995, 0.127],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [10.928, -21.616],
                    [-56.533, -18.404],
                    [-49.277, 12.494],
                    [21.477, 21.489],
                    [24.273, 20.736],
                    [56.533, -3.915]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.5569, 0.8549, 0.5882], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [382.59, 279.608], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 10",
          "ix": 10,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.78, -0.1],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.695, -0.369],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [43.787, -14.029],
                    [-1.116, -24.988],
                    [-44.872, -19.432],
                    [-50.693, 7.637],
                    [-27.454, 24.988],
                    [1.194, 9.788],
                    [3.462, 9.374],
                    [50.693, 15.378]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.5569, 0.8549, 0.5882], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [263.783, 274.329], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 11",
          "ix": 11,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [2.02, -0.92],
                    [0, 0],
                    [0.699, 0.087],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.766, -2.083],
                    [0, 0],
                    [-0.641, 0.292],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-75.132, 31.405],
                    [-75.132, 32.018],
                    [-19.288, 39.99],
                    [75.132, 23.755],
                    [52.857, -36.913],
                    [47.696, -39.069],
                    [-29.296, -3.997],
                    [-31.346, -3.684],
                    [-52.818, -6.347]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.2275, 0.4706, 0.949], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [371.599, 56.282], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 12",
          "ix": 12,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [49.612, 43.137],
                    [73.803, -68.05],
                    [30.648, -52.794],
                    [30.648, -12.018],
                    [-17.393, 11.982],
                    [-73.803, 6.561],
                    [-8.578, 68.05]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.5569, 0.8549, 0.5882], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [145.949, 232.117], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 13",
          "ix": 13,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.465, -0.122],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.478, -0.059],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-53.365, 30.139],
                    [7.499, 10.585],
                    [51.328, 16.842],
                    [74.534, -21.072],
                    [1.915, -30.081],
                    [0.486, -29.987],
                    [-74.533, -10.509]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.5569, 0.8549, 0.5882], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [226.945, 68.86], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 14",
          "ix": 14,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [16.114, -41.484],
                    [-16.114, -49.487],
                    [-9.512, 48.612],
                    [6.538, 49.487]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.5569, 0.8549, 0.5882], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [34.963, 166.639], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 15",
          "ix": 15,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [48.301, -48.137],
                    [1.424, -32.195],
                    [-38.786, -42.178],
                    [-48.301, 44.736],
                    [14.054, 48.137],
                    [48.301, 32.431]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.2275, 0.4706, 0.949], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [110.818, 172.537], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 16",
          "ix": 16,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-84.671, -33.731],
                    [-84.68, -3.777],
                    [-59.623, -4.374],
                    [-6.861, 43.551],
                    [84.68, -6.902],
                    [71.224, -43.55],
                    [-24.627, -26.171]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.5569, 0.8549, 0.5882], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [381.133, 138.908], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 17",
          "ix": 17,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [50.839, 6.521],
                    [50.839, -26.156],
                    [10.05, -31.292],
                    [-50.838, -10.585],
                    [-50.838, 31.292],
                    [16.467, 7.339]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.5569, 0.8549, 0.5882], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [227.435, 129.043], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 18",
          "ix": 18,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [40.128, 43.76],
                    [19.573, -43.761],
                    [-15.917, -42.411],
                    [-22.847, -39.962],
                    [-40.128, 40.417],
                    [-2.825, 36.406]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.5569, 0.8549, 0.5882], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [262.824, 196.879], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 19",
          "ix": 19,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-0.8, -11.894],
                    [0, 0],
                    [-6.807, -2.44],
                    [0, 0],
                    [-4.52, 2.399],
                    [0, 0],
                    [-3.722, -0.473],
                    [0, 0],
                    [-3.8, 2.904],
                    [0, 0],
                    [2.746, 7.48],
                    [0, 0],
                    [9.631, -4.387],
                    [0, 0],
                    [3.335, 0.414],
                    [0, 0],
                    [2.221, -0.576],
                    [0, 0],
                    [2.653, 0.51],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0.486, 7.215],
                    [0, 0],
                    [4.818, 1.727],
                    [0, 0],
                    [3.315, -1.759],
                    [0, 0],
                    [4.744, 0.603],
                    [0, 0],
                    [6.332, -4.838],
                    [0, 0],
                    [-3.648, -9.935],
                    [0, 0],
                    [-3.058, 1.393],
                    [0, 0],
                    [-2.278, -0.282],
                    [0, 0],
                    [-2.615, 0.679],
                    [0, 0],
                    [-11.707, -2.25]
                  ],
                  "v": [
                    [-257.07, -119.863],
                    [-240.67, 123.858],
                    [-228.664, 139.754],
                    [-119.086, 179.043],
                    [-104.426, 177.989],
                    [5.47, 119.682],
                    [16.285, 117.706],
                    [144.03, 133.947],
                    [157.364, 130.356],
                    [249.095, 60.259],
                    [255.123, 39.543],
                    [179.616, -166.102],
                    [155.007, -176.383],
                    [85.676, -144.8],
                    [75.899, -143.302],
                    [-27.467, -156.125],
                    [-34.276, -155.68],
                    [-152.942, -124.869],
                    [-160.944, -124.612],
                    [-235.495, -138.941]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 1, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [258.12, 181.02], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 16
    },
    {
      "ty": 4,
      "nm": "Cloud Outlines",
      "sr": 1,
      "st": 0,
      "op": 300,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [378.391, 121.438, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [478.564, 172.563, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [2.467, 9.292],
                    [3.49, -13.83],
                    [16.402, 0.947],
                    [0, 0],
                    [8.55, -5.494],
                    [0, 0],
                    [2.82, -11.684],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [-2.467, -9.292],
                    [0, 0],
                    [-16.402, -0.947],
                    [0, 0],
                    [-8.55, 5.494],
                    [0, 0],
                    [-1.706, 7.069],
                    [0, 0]
                  ],
                  "v": [
                    [58.839, 20.215],
                    [43.159, 9.16],
                    [20.451, 7.824],
                    [8.237, -19.267],
                    [-4.043, 0.474],
                    [-18.809, -6.763],
                    [-23.111, 5.362],
                    [-39.95, 12.142],
                    [-58.839, 20.215]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.9804, 0.9804, 0.9843], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": {
                "a": 1,
                "k": [
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [91.947, 222.412],
                    "t": 0,
                    "ti": [0, 0],
                    "to": [-10.448, 0]
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [29.259, 222.412],
                    "t": 150,
                    "ti": [-10.448, 0],
                    "to": [0, 0]
                  },
                  { "s": [91.947, 222.412], "t": 299 }
                ],
                "ix": 2
              },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 2",
          "ix": 2,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [3.075, 11.582],
                    [4.35, -17.239],
                    [20.445, 1.18],
                    [0, 0],
                    [10.657, -6.849],
                    [0, 0],
                    [3.514, -14.564],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [-3.075, -11.583],
                    [0, 0],
                    [-20.445, -1.181],
                    [0, 0],
                    [-10.658, 6.848],
                    [0, 0],
                    [-2.127, 8.811],
                    [0, 0]
                  ],
                  "v": [
                    [73.342, 25.198],
                    [53.798, 11.418],
                    [25.493, 9.754],
                    [10.268, -24.016],
                    [-5.041, 0.591],
                    [-23.446, -8.43],
                    [-28.808, 6.684],
                    [-49.798, 15.135],
                    [-73.342, 25.198]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.9804, 0.9804, 0.9843], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": {
                "a": 1,
                "k": [
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [73.592, 136.087],
                    "t": 0,
                    "ti": [0, 0],
                    "to": [0, 0]
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [147.233, 136.087],
                    "t": 150,
                    "ti": [0, 0],
                    "to": [0, 0]
                  },
                  { "s": [73.592, 136.087], "t": 299 }
                ],
                "ix": 2
              },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 3",
          "ix": 3,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [3.799, 14.307],
                    [5.374, -21.294],
                    [25.254, 1.459],
                    [0, 0],
                    [13.165, -8.46],
                    [0, 0],
                    [4.341, -17.989],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [-3.798, -14.307],
                    [0, 0],
                    [-25.254, -1.458],
                    [0, 0],
                    [-13.164, 8.459],
                    [0, 0],
                    [-2.627, 10.885],
                    [0, 0]
                  ],
                  "v": [
                    [90.594, 31.125],
                    [66.451, 14.104],
                    [31.488, 12.047],
                    [12.682, -29.667],
                    [-6.226, 0.729],
                    [-28.961, -10.414],
                    [-35.583, 8.255],
                    [-61.51, 18.693],
                    [-90.594, 31.125]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.9804, 0.9804, 0.9843], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": {
                "a": 1,
                "k": [
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [294.883, 31.375],
                    "t": 0,
                    "ti": [0, 0],
                    "to": [9.091, 0]
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [349.429, 31.375],
                    "t": 150,
                    "ti": [9.091, 0],
                    "to": [0, 0]
                  },
                  { "s": [294.883, 31.375], "t": 299 }
                ],
                "ix": 2
              },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 4",
          "ix": 4,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [3.799, 14.307],
                    [5.374, -21.294],
                    [25.254, 1.459],
                    [0, 0],
                    [13.165, -8.46],
                    [0, 0],
                    [4.341, -17.989],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [-3.798, -14.307],
                    [0, 0],
                    [-25.254, -1.458],
                    [0, 0],
                    [-13.164, 8.459],
                    [0, 0],
                    [-2.627, 10.885],
                    [0, 0]
                  ],
                  "v": [
                    [90.594, 31.125],
                    [66.451, 14.104],
                    [31.488, 12.047],
                    [12.682, -29.667],
                    [-6.226, 0.729],
                    [-28.961, -10.414],
                    [-35.584, 8.255],
                    [-61.511, 18.694],
                    [-90.594, 31.125]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.9804, 0.9804, 0.9843], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": {
                "a": 1,
                "k": [
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [637.188, 82.582],
                    "t": 0,
                    "ti": [0, 0],
                    "to": [-12.439, 0]
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [562.555, 82.582],
                    "t": 150,
                    "ti": [0, 0],
                    "to": [0, 0]
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [637.188, 82.582],
                    "t": 299,
                    "ti": [12.439, 0],
                    "to": [0, 0]
                  },
                  { "s": [562.555, 82.582], "t": 449 }
                ],
                "ix": 2
              },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 5",
          "ix": 5,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [2.732, 10.29],
                    [3.865, -15.316],
                    [18.164, 1.049],
                    [0, 0],
                    [9.468, -6.084],
                    [0, 0],
                    [3.123, -12.938],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [-2.732, -10.29],
                    [0, 0],
                    [-18.163, -1.049],
                    [0, 0],
                    [-9.468, 6.084],
                    [0, 0],
                    [-1.889, 7.828],
                    [0, 0]
                  ],
                  "v": [
                    [65.157, 22.385],
                    [47.793, 10.143],
                    [22.647, 8.665],
                    [9.121, -21.336],
                    [-4.477, 0.525],
                    [-20.829, -7.49],
                    [-25.592, 5.938],
                    [-44.24, 13.445],
                    [-65.157, 22.385]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.9804, 0.9804, 0.9843], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": {
                "a": 1,
                "k": [
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [691.375, 159.953],
                    "t": 0,
                    "ti": [0, 0],
                    "to": [13.049, 0]
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [769.672, 159.953],
                    "t": 150,
                    "ti": [13.049, 0],
                    "to": [0, 0]
                  },
                  { "s": [691.375, 159.953], "t": 299 }
                ],
                "ix": 2
              },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 17
    },
    {
      "ty": 4,
      "nm": "Background Outlines",
      "sr": 1,
      "st": 0,
      "op": 300,
      "ip": 0,
      "hd": true,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [432.549, 302.847, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [504.303, 353.12, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [15.01, 23.612],
                          [0, 0],
                          [-6.604, 22.601],
                          [0, 0],
                          [-26.557, 7.949],
                          [0, 0],
                          [-14.027, -3.361],
                          [0, 0],
                          [-11.352, -18.399],
                          [0, 0],
                          [4.745, -20.897],
                          [0, 0],
                          [38.195, 0],
                          [0, 0]
                        ],
                        "o": [
                          [0, 0],
                          [-12.631, -19.871],
                          [0, 0],
                          [7.775, -26.608],
                          [0, 0],
                          [13.819, -4.136],
                          [0, 0],
                          [21.024, 5.037],
                          [0, 0],
                          [11.252, 18.238],
                          [0, 0],
                          [-8.458, 37.247],
                          [0, 0],
                          [-27.979, 0]
                        ],
                        "v": [
                          [-349.839, 264.685],
                          [-416.212, 160.274],
                          [-425.695, 93.452],
                          [-350.513, -163.834],
                          [-295.457, -219.251],
                          [-32.175, -298.053],
                          [10.338, -299.237],
                          [272.127, -236.507],
                          [322.677, -199.914],
                          [417.402, -46.386],
                          [427.554, 14.674],
                          [376.636, 238.916],
                          [296.873, 302.597],
                          [-280.812, 302.597]
                        ]
                      }
                    ],
                    "t": 0
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [15.01, 23.612],
                          [0, 0],
                          [-6.604, 22.601],
                          [0, 0],
                          [-26.557, 7.949],
                          [0, 0],
                          [-14.027, -3.361],
                          [0, 0],
                          [-11.352, -18.399],
                          [0, 0],
                          [4.745, -20.897],
                          [0, 0],
                          [38.195, 0],
                          [0, 0]
                        ],
                        "o": [
                          [0, 0],
                          [-12.631, -19.871],
                          [0, 0],
                          [7.775, -26.608],
                          [0, 0],
                          [13.819, -4.136],
                          [0, 0],
                          [29.57, 19.387],
                          [0, 0],
                          [11.252, 18.238],
                          [0, 0],
                          [-26.939, 41.964],
                          [0, 0],
                          [-27.979, 0]
                        ],
                        "v": [
                          [-349.839, 264.685],
                          [-446.212, 190.274],
                          [-455.695, 123.452],
                          [-388.513, -169.834],
                          [-333.457, -225.251],
                          [51.825, -298.053],
                          [94.338, -299.237],
                          [318.127, -192.507],
                          [364.677, -147.914],
                          [421.402, 47.614],
                          [431.554, 108.674],
                          [372.636, 254.916],
                          [296.873, 302.597],
                          [-280.812, 302.597]
                        ]
                      }
                    ],
                    "t": 75
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [15.01, 23.612],
                          [0, 0],
                          [-6.604, 22.601],
                          [0, 0],
                          [-26.557, 7.949],
                          [0, 0],
                          [-14.027, -3.361],
                          [0, 0],
                          [-11.352, -18.399],
                          [0, 0],
                          [4.745, -20.897],
                          [0, 0],
                          [38.195, 0],
                          [0, 0]
                        ],
                        "o": [
                          [0, 0],
                          [-12.631, -19.871],
                          [0, 0],
                          [7.775, -26.608],
                          [0, 0],
                          [13.819, -4.136],
                          [0, 0],
                          [21.024, 5.037],
                          [0, 0],
                          [11.252, 18.238],
                          [0, 0],
                          [-8.458, 37.247],
                          [0, 0],
                          [-27.979, 0]
                        ],
                        "v": [
                          [-349.839, 264.685],
                          [-416.212, 160.274],
                          [-425.695, 93.452],
                          [-350.513, -163.834],
                          [-295.457, -219.251],
                          [-32.175, -298.053],
                          [10.338, -299.237],
                          [272.127, -236.507],
                          [322.677, -199.914],
                          [417.402, -46.386],
                          [427.554, 14.674],
                          [376.636, 238.916],
                          [296.873, 302.597],
                          [-280.812, 302.597]
                        ]
                      }
                    ],
                    "t": 150
                  },
                  {
                    "o": { "x": 0.167, "y": 0.167 },
                    "i": { "x": 0.833, "y": 0.833 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [15.01, 23.612],
                          [0, 0],
                          [-6.604, 22.601],
                          [0, 0],
                          [-26.557, 7.949],
                          [0, 0],
                          [-14.027, -3.361],
                          [0, 0],
                          [-11.352, -18.399],
                          [0, 0],
                          [4.745, -20.897],
                          [0, 0],
                          [38.195, 0],
                          [0, 0]
                        ],
                        "o": [
                          [0, 0],
                          [-12.631, -19.871],
                          [0, 0],
                          [7.775, -26.608],
                          [0, 0],
                          [13.819, -4.136],
                          [0, 0],
                          [29.57, 19.387],
                          [0, 0],
                          [11.252, 18.238],
                          [0, 0],
                          [-26.939, 41.964],
                          [0, 0],
                          [-27.979, 0]
                        ],
                        "v": [
                          [-349.839, 264.685],
                          [-446.212, 190.274],
                          [-455.695, 123.452],
                          [-388.513, -169.834],
                          [-333.457, -225.251],
                          [51.825, -298.053],
                          [94.338, -299.237],
                          [318.127, -192.507],
                          [364.677, -147.914],
                          [421.402, 47.614],
                          [431.554, 108.674],
                          [372.636, 254.916],
                          [296.873, 302.597],
                          [-280.812, 302.597]
                        ]
                      }
                    ],
                    "t": 225
                  },
                  {
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [15.01, 23.612],
                          [0, 0],
                          [-6.604, 22.601],
                          [0, 0],
                          [-26.557, 7.949],
                          [0, 0],
                          [-14.027, -3.361],
                          [0, 0],
                          [-11.352, -18.399],
                          [0, 0],
                          [4.745, -20.897],
                          [0, 0],
                          [38.195, 0],
                          [0, 0]
                        ],
                        "o": [
                          [0, 0],
                          [-12.631, -19.871],
                          [0, 0],
                          [7.775, -26.608],
                          [0, 0],
                          [13.819, -4.136],
                          [0, 0],
                          [21.024, 5.037],
                          [0, 0],
                          [11.252, 18.238],
                          [0, 0],
                          [-8.458, 37.247],
                          [0, 0],
                          [-27.979, 0]
                        ],
                        "v": [
                          [-349.839, 264.685],
                          [-416.212, 160.274],
                          [-425.695, 93.452],
                          [-350.513, -163.834],
                          [-295.457, -219.251],
                          [-32.175, -298.053],
                          [10.338, -299.237],
                          [272.127, -236.507],
                          [322.677, -199.914],
                          [417.402, -46.386],
                          [427.554, 14.674],
                          [376.636, 238.916],
                          [296.873, 302.597],
                          [-280.812, 302.597]
                        ]
                      }
                    ],
                    "t": 299
                  }
                ],
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.902, 0.9059, 0.9333], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [432.549, 302.848], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 18
    }
  ],
  "v": "4.8.0",
  "fr": 60,
  "op": 300,
  "ip": 0,
  "assets": []
}
