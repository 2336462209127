export enum BounceType {
  HARD_BOUNCE = 'HardBounce',
  TRANSIENT = 'Transient',
  UNSUBSCRIBE = 'Unsubscribe',
  SUBSCRIBE = 'Subscribe',
  AUTO_RESPONDER = 'AutoResponder',
  ADDRESS_CHANGE = 'AddressChange',
  DNS_ERROR = 'DnsError',
  SPAM_NOTIFICATION = 'SpamNotification',
  OPEN_RELAY_TEST = 'OpenRelayTest',
  UNKNOWN = 'Unknown',
  SOFT_BOUNCE = 'SoftBounce',
  VIRUS_NOTIFICATION = 'VirusNotification',
  CHALLENGE_VERIFICATION = 'ChallengeVerification',
  BAD_EMAIL_ADDRESS = 'BadEmailAddress',
  SPAM_COMPLAINT = 'SpamComplaint',
  MANUALLY_DEACTIVATED = 'ManuallyDeactivated',
  UNCONFIRMED = 'Unconfirmed',
  BLOCKED = 'Blocked',
  SMTPAPI_ERROR = 'SMTPApiError',
  INBOUND_ERROR = 'InboundError',
  DMARCPOLICY = 'DMARCPolicy',
  TEMPLATE_RENDERING_FAILED = 'TemplateRenderingFailed',
}
