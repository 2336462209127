export * from './CrmAddressType';
export type * from './CrmAddress';
export type * from './Relation';
export type * from './Document';
export type * from './Communication';
export * from './CommunicationType';
export type * from './CustomerInformation';
export type * from './CrmContactPerson';
export * from './CrmContactPersonType';
export type * from './BusinessRelation';
export type * from './PrivateRelation';
export * from './RelationType';
