import { Uuid } from '@bas/value-objects';
import * as Yup from 'yup';

export interface EmployeesAttributesInputType {
  employeeAttributes: {
    id: Uuid;
  }[];
}

export const EmployeesAttributesInputTypeDefaultValues =
  (): EmployeesAttributesInputType => ({
    employeeAttributes: [],
  });

export const EmployeesAttributesInputTypeValidatorBuilder =
  (): Yup.ObjectSchema<EmployeesAttributesInputType> =>
    Yup.object({
      employeeAttributes: Yup.array()
        .of(
          Yup.object({
            id: Yup.string().required().label('label.attribute'),
          }),
        )
        .required()
        .label('label.employeeAttributes'),
    });
