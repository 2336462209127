import { Box, Typography } from '@bas/ui/native/base';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { TimeRegistrationRejectionInformation } from '@bas/hrm-domain/models';

export type TimeOffRegistrationRejectionInformationContentSectionProps = {
  rejectionInformation: TimeRegistrationRejectionInformation;
  rejectedByName?: string;
};

const TimeOffRegistrationRejectionInformationContentSection = ({
  rejectionInformation,
  rejectedByName,
}: TimeOffRegistrationRejectionInformationContentSectionProps) => (
  <Box flexDirection="row" flexWrap="wrap">
    <Box flexBasis="100%">
      <Typography variant="subtitle1" color="white">
        <FormattedMessage id="label.rejectedInformation" />
      </Typography>
    </Box>
    <Box flexBasis="100%">
      {rejectedByName && (
        <Typography color="white">{rejectedByName}</Typography>
      )}
      {rejectionInformation.rejectedOn && (
        <Typography color="white">
          <FormattedDate
            value={rejectionInformation.rejectedOn}
            dateStyle="short"
          />
        </Typography>
      )}
      <Typography color="white">
        {rejectionInformation.rejectedReason}
      </Typography>
    </Box>
  </Box>
);

export default TimeOffRegistrationRejectionInformationContentSection;
