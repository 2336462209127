import * as Yup from 'yup';
import {
  MileageReportInputType,
  MileageReportInputTypeValidationBuilder,
} from './mileageReportInputType';

export interface FinishEventInputType {
  realTravelEndTime?: Date | null;
  mileageReports: MileageReportInputType[];
}

export const FinishEventInputTypeDefaultValues = (): FinishEventInputType => ({
  realTravelEndTime: null,
  mileageReports: [],
});

export const FinishEventInputTypeValidationBuilder =
  (): Yup.ObjectSchema<FinishEventInputType> =>
    Yup.object({
      realTravelEndTime: Yup.date()
        .nullable()
        .required()
        .label('label.realTravelEndTime'),
      mileageReports: Yup.array()
        .of(MileageReportInputTypeValidationBuilder())
        .required(),
    });
