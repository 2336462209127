import * as Yup from 'yup';

export type EnableTwoFactorInputType = {
  code: string;
};

export const enableTwoFactorInputTypeDefaultValues =
  (): EnableTwoFactorInputType => ({
    code: '',
  });

export const enableTwoFactorInputTypeValidationBuilder =
  (): Yup.ObjectSchema<EnableTwoFactorInputType> =>
    Yup.object({
      code: Yup.string().required().label('label.code'),
    });
