import { QueryOptionsWithKey } from '@bas/shared/requests';
import { Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type QuoteExpiresAtByQuoteIdRequestProps = {
  quoteId: Uuid;
};

type QuoteExpiresAtResult = {
  calculatedExpiresAt: Date;
};

export const QuoteExpiresAtByQuoteIdRequest = async ({
  quoteId,
  ...params
}: QuoteExpiresAtByQuoteIdRequestProps): Promise<
  AxiosResponse<QuoteExpiresAtResult>
> =>
  axios.get(`api/{tenantId}/quotes/${quoteId}/calculate-expires-at`, {
    params,
  });

export const useQuoteExpiresAtByQuoteIdRequestQuery = (
  request: QuoteExpiresAtByQuoteIdRequestProps,
  options: QueryOptionsWithKey<
    AxiosResponse<QuoteExpiresAtResult>,
    AxiosError,
    AxiosResponse<QuoteExpiresAtResult>
  > = {},
): UseQueryResult<AxiosResponse<QuoteExpiresAtResult>, AxiosError> =>
  useQuery<
    AxiosResponse<QuoteExpiresAtResult>,
    AxiosError,
    AxiosResponse<QuoteExpiresAtResult>
  >({
    ...options,
    queryFn: async () => QuoteExpiresAtByQuoteIdRequest(request),
    queryKey: ['quotes', 'expires-at', request.quoteId],
  });
