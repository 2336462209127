import {
  ChangeTimeOffRegistrationInputType,
  TimeTrackingItemInputType,
} from '@bas/hrm-domain/input-types';
import {
  DayOff,
  isDayOff,
  isTimeEntry,
  TimeEntry,
  TimeEntryStatus,
  TimeOffRegistration,
  TimeOffRegistrationStatus,
} from '@bas/hrm-domain/models';
import {
  useChangeTimeOffRegistrationMutation,
  useRemoveTimeEntryMutation,
  useSyncTimeEntriesMutation,
} from '@bas/hrm-domain/mutations';
import { TimeTrackingOrEntryEvent } from '@bas/hrm-domain/native/molecules';
import {
  ChangeTimeTrackingItemBottomSheet,
  CreateTimeTrackingItemBottomSheet,
  ShowAndChangeTimeEntryBottomSheet,
  TimeOffDetailsBottomSheet,
} from '@bas/hrm-domain/native/organisms';
import {
  TimeEntriesByEmployeeIdRequest,
  useDayOfInterestsByEmployeeIdRequest,
  useTimeEntriesByEmployeeIdRequest,
  useTimeOffRegistrationsByEmployeeIdRequest,
  useTimeTypesRequest,
} from '@bas/hrm-domain/requests';
import {
  TimeTrackingItem,
  useEmployeeStore,
  useTenantStore,
  useTimeTrackingStore,
} from '@bas/shared/state';
import {
  areDayjsRangesOverlapping,
  calculateStartAndEndTimeForWorkingDay,
} from '@bas/shared/utils';
import { colors } from '@bas/theme';
import { Alert, Button } from '@bas/ui/native/atoms';
import {
  BottomSheetMethods,
  Box,
  ShopifyTheme,
  Typography,
} from '@bas/ui/native/base';
import {
  CalendarDayView,
  CalendarDayViewEvent,
} from '@bas/ui/native/molecules';
import { ExpandableHeader, ScrollableCalendar } from '@bas/ui/native/organisms';
import { ConfirmBottomSheet } from '@bas/ui/native/templates';
import { isBackendViolations, Uuid } from '@bas/value-objects';
import { faArrowsRotate } from '@fortawesome/pro-light-svg-icons/faArrowsRotate';
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock';
import { faRotateExclamation } from '@fortawesome/pro-light-svg-icons/faRotateExclamation';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { useResponsiveProp } from '@shopify/restyle';
import axios from 'axios';
import dayjs from 'dayjs';
import { useLocalSearchParams } from 'expo-router';
import { StatusBar } from 'expo-status-bar';
import * as React from 'react';
import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import isEqual from 'react-fast-compare';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert as AlertNative, Platform } from 'react-native';
import { MarkedDays } from 'react-native-month';
import { LayoutChangeEvent } from 'react-native/Libraries/Types/CoreEventTypes';
import { v7 } from 'uuid';

const dotColorMapping: { [key: string]: string } = {
  toSubmit: colors.orange[700],
  send: colors.lila[600],
  disapproved: colors.red[500],
  approved: colors.green[300],
};

type DayOffWithTimeRegistration = DayOff & {
  timeOffRegistration: Omit<TimeOffRegistration, 'daysOff'>;
};

type CalendarDayViewEventWithItem = CalendarDayViewEvent & {
  item?: TimeTrackingItem | TimeEntry | DayOffWithTimeRegistration;
  realEndDate?: Date;
  description?: string;
};

export type TrackedTimesCalendarScreenProps = NativeStackScreenProps<
  {
    TrackedTimesCalendar: {
      date?: string;
    };
  },
  'TrackedTimesCalendar'
>;

const TrackedTimesCalendarScreen = (): ReactElement => {
  const { date } = useLocalSearchParams<{
    date?: string;
  }>();
  const [markedDays, setMarkedDays] = useState<MarkedDays>({});
  const [currentDate, setCurrentDate] = useState<Date>(dayjs(date).toDate());

  const [alwaysVisibleHeight, setAlwaysVisibleHeight] = useState(64);
  const [contentHeight, setContentHeight] = useState(0);

  const changeTimeTrackingItemSheetRef = useRef<
    BottomSheetModal & BottomSheetMethods
  >(null);
  const removeTimeEntrySheetRef = useRef<BottomSheetModal & BottomSheetMethods>(
    null,
  );
  const timeEntrySheetRef = useRef<BottomSheetModal & BottomSheetMethods>(null);
  const timeOffDetailsBottomSheetRef = useRef<
    BottomSheetModal & BottomSheetMethods
  >(null);
  const createTimeTrackingItemSheetRef = useRef<
    BottomSheetModal & BottomSheetMethods
  >(null);
  const removeTimeEntryRef = useRef<{
    timeEntryId: Uuid;
    isExisting: boolean;
  }>();

  const handleContentLayout = useCallback((event: LayoutChangeEvent) => {
    setContentHeight(event.nativeEvent.layout.height);
  }, []);

  const employeeId = useEmployeeStore((state) => state.employee?.employeeId);
  const availability = useEmployeeStore(
    (state) => state.employee?.availability,
  );
  const workingHours = useEmployeeStore(
    (state) => state.employee?.workingHours,
  );
  const trackedTimePerDate = useTimeTrackingStore(
    (state) => state.trackedTimePerDate,
  );
  const updateTrackedTime = useTimeTrackingStore(
    (state) => state.updateTrackedTime,
  );
  const createTrackedTime = useTimeTrackingStore(
    (state) => state.createTrackedTime,
  );
  const removeTrackedTime = useTimeTrackingStore(
    (state) => state.removeTrackedTime,
  );
  const lastSync = useTimeTrackingStore((state) => state.lastSync);
  const changeLastSync = useTimeTrackingStore((state) => state.changeLastSync);
  const hasUnsyncedItems = useTimeTrackingStore(
    (state) => state.hasUnsyncedItems,
  );
  const getUnfinishedItems = useTimeTrackingStore(
    (state) => state.getUnfinishedItems,
  );
  const getActiveItem = useTimeTrackingStore((state) => state.getActiveItem);

  const tenantWorkingSchedule = useTenantStore(
    (state) => state.internalTenant?.workingSchedule,
  );

  const unavailableHours = useMemo(() => {
    const result = [];
    if (availability) {
      const { start, end } = calculateStartAndEndTimeForWorkingDay({
        date: currentDate,
        availability,
        workingHours,
        tenantWorkingSchedule,
      });

      result.push({
        start: 0,
        end: dayjs(start).hour(),
      });

      result.push({
        start: dayjs(end).add(1, 'hour').hour(),
        end: 24,
      });
    }

    return result;
  }, [availability, currentDate, workingHours, tenantWorkingSchedule]);

  // @ts-expect-error - workaround
  const showDateInMiddle = useResponsiveProp<ShopifyTheme, boolean>({
    lg: true,
    largerTablet: true,
    tablet: true,
    phone: false,
  });

  const { mutateAsync: syncTime, isPending: isSyncing } =
    useSyncTimeEntriesMutation();
  const { mutateAsync: removeTimeEntry } = useRemoveTimeEntryMutation();
  const { mutateAsync: changeTimeOff } = useChangeTimeOffRegistrationMutation();

  const { formatMessage } = useIntl();

  const syncEntries = useCallback(
    async (
      itemsToSync: {
        timeEntryId: Uuid;
        start: Date;
        end: Date;
        timeTypeId: Uuid;
        description?: string;
        projectId?: Uuid | null;
        eventId?: Uuid | null;
        relationId?: Uuid | null;
      }[],
      callback?: (errors?: string[]) => void,
    ) => {
      try {
        await syncTime({
          employeeId: employeeId as string,
          timeEntries: itemsToSync,
        });
      } catch (error) {
        const errors: string[] = [];
        if (axios.isAxiosError(error)) {
          const response = error.response?.data;
          if (isBackendViolations(response)) {
            errors.push(
              ...response.violations.map((violation) => violation.message),
            );
          } else if (response?.description) {
            errors.push(response.description);
          }
        } else {
          errors.push(formatMessage({ id: 'label.unknownError' }));
        }

        callback?.(errors);
        return;
      }

      callback?.();
    },
    [employeeId, formatMessage, syncTime],
  );

  const handleSyncHours = useCallback(async () => {
    const unfinishedItems = getUnfinishedItems(employeeId as string).filter(
      ({ start }) => !dayjs(start).isSame(dayjs(), 'day'),
    );

    if (unfinishedItems.length > 0) {
      const message = formatMessage(
        {
          id: 'label.unfinishedItems',
        },
        {
          dates: unfinishedItems
            .map((item) => dayjs(item.start).format('L'))
            .filter((value, index, self) => self.indexOf(value) === index)
            .join(', '),
        },
      );

      if (Platform.OS === 'web') {
        // eslint-disable-next-line no-alert
        alert(message);
      } else {
        AlertNative.alert(message);
      }

      return;
    }

    const items = Object.values(trackedTimePerDate).reduce(
      (acc, row) => [...acc, ...row],
      [] as TimeTrackingItem[],
    );

    const itemsToSync = items.filter(
      ({ employeeId: itemEmployeeId, start, end }) =>
        itemEmployeeId === employeeId &&
        (end || dayjs(start).isSame(dayjs(), 'day')),
    );

    await syncEntries(
      itemsToSync as {
        timeEntryId: Uuid;
        start: Date;
        end: Date;
        timeTypeId: Uuid;
        description?: string;
        projectId?: Uuid;
        eventId?: Uuid;
        relationId?: Uuid;
      }[],
      (errors?: string[]) => {
        if ((errors?.length || 0) > 0) {
          changeLastSync(new Date(), false, employeeId as Uuid, errors);
          return;
        }
        changeLastSync(new Date(), true, employeeId as Uuid);
      },
    );
  }, [
    getUnfinishedItems,
    employeeId,
    trackedTimePerDate,
    syncEntries,
    formatMessage,
    changeLastSync,
  ]);

  const needsSyncing = useMemo(
    () =>
      Object.keys(trackedTimePerDate).some((date) =>
        trackedTimePerDate[date].some(
          ({ employeeId: itemEmployeeId, end }) =>
            itemEmployeeId === employeeId,
        ),
      ),
    [employeeId, trackedTimePerDate],
  );

  const buttons = useMemo(() => {
    let syncIcon = faArrowsRotate;
    let buttonColor: 'warning' | 'danger' | 'primary' = 'primary';
    if (lastSync && !lastSync.success) {
      buttonColor = 'danger';
      syncIcon = faRotateExclamation;
    } else if (needsSyncing) {
      buttonColor = 'warning';
    }

    return (
      <Box
        flexDirection="row"
        justifyContent="space-between"
        paddingHorizontal={{
          phone: 1,
          biggerPhone: 20,
        }}
        flexWrap="wrap"
        alignItems="center"
      >
        <Box>
          <Button
            variant="contained"
            icon={faClock}
            paddingVertical={1}
            onPress={() => {
              createTimeTrackingItemSheetRef.current?.present({
                time: dayjs(currentDate),
              });
            }}
          >
            <FormattedMessage id="button.enterTimeRegistration" />
          </Button>
        </Box>
        {showDateInMiddle && (
          <Box>
            <Typography variant="h4">
              {dayjs(currentDate).format(`dddd D MMMM`)}
            </Typography>
          </Box>
        )}
        <Box
          paddingLeft={{
            tablet: 3,
          }}
        >
          <Button
            variant="contained"
            icon={syncIcon}
            loadingIcon={syncIcon}
            buttonColor={buttonColor}
            disabled={(!hasUnsyncedItems && !!lastSync?.success) || isSyncing}
            loading={isSyncing}
            showLabelOnLoading
            paddingVertical={1}
            onPress={handleSyncHours}
          >
            <FormattedMessage id="button.syncHours" />
          </Button>
        </Box>
        {!showDateInMiddle && (
          <Box flexBasis="100%">
            <Typography variant="h4" textAlign="center">
              {dayjs(currentDate).format(`dddd D MMMM`)}
            </Typography>
          </Box>
        )}
        {lastSync &&
          !lastSync.success &&
          (lastSync?.errors?.length || 0) > 0 && (
            <Box flexBasis="100%">
              <Alert severity="warning">
                {lastSync?.errors?.map((error) => (
                  <Typography key={error}>{error}</Typography>
                ))}
              </Alert>
            </Box>
          )}
      </Box>
    );
  }, [
    lastSync,
    needsSyncing,
    showDateInMiddle,
    currentDate,
    hasUnsyncedItems,
    isSyncing,
    handleSyncHours,
  ]);

  const { data: dayOfInterestsData } = useDayOfInterestsByEmployeeIdRequest({
    employeeId: employeeId as string,
    startDate: dayjs().subtract(12, 'months').startOf('month').toDate(),
    endDate: dayjs().endOf('month').toDate(),
  });

  const dayOfInterests = useMemo(
    () => dayOfInterestsData?.data?.member || [],
    [dayOfInterestsData?.data],
  );

  useEffect(() => {
    const newMarkedDays: MarkedDays = {};

    Object.keys(trackedTimePerDate).forEach((date) => {
      const hasOpenItems = trackedTimePerDate[date].some(
        ({ employeeId: itemEmployeeId, end }) => itemEmployeeId === employeeId,
      );

      if (hasOpenItems) {
        newMarkedDays[date] = {
          dots: [
            {
              color: dotColorMapping.toSubmit,
              selectedColor: dotColorMapping.toSubmit,
            },
          ],
        };
      }
    });

    dayOfInterests.forEach((dayOfInterest) => {
      const date = dayjs(dayOfInterest.date).format('YYYY-MM-DD');
      const dots: {
        color: string;
        selectedColor: string;
      }[] = [];

      if (
        dayOfInterest.rejectedHours > 0 ||
        dayOfInterest.rejectedTimeOffHours > 0
      ) {
        dots.push({
          color: dotColorMapping.disapproved,
          selectedColor: dotColorMapping.disapproved,
        });
      }

      if (
        dayOfInterest.hoursWithMissingTimeEntries -
          dayOfInterest.totalTimeOffHours >
          0 &&
        dayjs(date).isSameOrBefore(dayjs(), 'day')
      ) {
        dots.push({
          color: dotColorMapping.toSubmit,
          selectedColor: dotColorMapping.toSubmit,
        });
      }

      if (
        dayOfInterest.approvedHours > 0 ||
        dayOfInterest.approvedTimeOffHours > 0
      ) {
        dots.push({
          color: dotColorMapping.approved,
          selectedColor: dotColorMapping.approved,
        });
      }

      if (dots.length > 0) {
        if (newMarkedDays[date]) {
          newMarkedDays[date].dots = [
            ...(newMarkedDays[date].dots || []),
            ...dots,
          ].filter(
            (dot, index, self) =>
              self.findIndex(({ color }) => color === dot.color) === index,
          );
        } else {
          newMarkedDays[date] = {
            dots,
          };
        }
      }
    });

    if (!isEqual(newMarkedDays, markedDays)) {
      setMarkedDays(newMarkedDays);
    }
  }, [dayOfInterests, employeeId, markedDays, trackedTimePerDate]);

  const { data: timeTypesData } = useTimeTypesRequest();

  const timeTypes = useMemo(
    () => timeTypesData?.data?.member || [],
    [timeTypesData?.data],
  );

  const { data: timeEntriesOfCurrentDayData } =
    useTimeEntriesByEmployeeIdRequest({
      employeeId: employeeId as string,
      date: dayjs(currentDate).format('YYYY-MM-DD'),
    });

  const timeEntriesOfCurrentDay = useMemo(
    () => timeEntriesOfCurrentDayData?.data?.member || [],
    [timeEntriesOfCurrentDayData?.data],
  );

  const { data: timeOffRequestsData } =
    useTimeOffRegistrationsByEmployeeIdRequest({
      dayOff: dayjs(currentDate).format('YYYY-MM-DD'),
      employeeId: employeeId as string,
      buildUp: false,
    });

  const timeOffRegistrations = useMemo(
    () => timeOffRequestsData?.data?.member || [],
    [timeOffRequestsData?.data],
  );

  const daysOff: DayOffWithTimeRegistration[] = useMemo(
    () =>
      timeOffRegistrations
        .filter(({ status }) => status !== TimeOffRegistrationStatus.CANCELLED)
        .flatMap(({ daysOff: currentDaysOff, ...timeOffRegistration }) =>
          currentDaysOff
            .filter(({ start }) => dayjs(start).isSame(currentDate, 'day'))
            .map((dayOff) => ({ ...dayOff, timeOffRegistration })),
        ),
    [currentDate, timeOffRegistrations],
  );

  const convertItemToEvent = useCallback(
    (
      item: TimeTrackingItem | TimeEntry,
      id: number,
    ): CalendarDayViewEventWithItem => {
      let description = '';
      let endDate;

      if (!item.end && dayjs(item.start).isSame(dayjs(), 'day')) {
        endDate = dayjs().toDate();
      } else if (!item.end) {
        endDate = dayjs(item.start).endOf('day').toDate();
      } else if (item.end) {
        endDate = dayjs(item.end).toDate();
      }

      const startDate = dayjs(item.start).toDate();
      const realEndDate = endDate;
      if (dayjs(endDate).diff(startDate, 'minute') <= 15) {
        endDate = dayjs(startDate).add(15, 'minute').toDate();
      }

      if (isTimeEntry(item)) {
        description = item.timeTypeName;
      } else {
        const timeType = timeTypes.find(
          ({ timeTypeId }) => item.timeTypeId === timeTypeId,
        );
        description = timeType?.name || '';
      }

      return {
        id,
        description,
        startDate: startDate as Date,
        endDate: endDate as Date,
        realEndDate,
        color: 'transparent',
        item,
      };
    },
    [timeTypes],
  );

  const events = useMemo(() => {
    const result: CalendarDayViewEventWithItem[] = [];
    let id = 0;

    Object.keys(trackedTimePerDate).forEach((date) => {
      if (!dayjs(date).isSame(currentDate, 'day')) {
        return;
      }

      const items = trackedTimePerDate[date].filter(
        ({ employeeId: itemEmployeeId }) => itemEmployeeId === employeeId,
      );

      items.forEach((item) => {
        id += 1;

        result.push(convertItemToEvent(item, id));
      });
    });

    result.push(
      ...timeEntriesOfCurrentDay.map((item) => {
        id += 1;
        return convertItemToEvent(item, id);
      }),
    );

    result.push(
      ...(daysOff || []).map((dayOff) => {
        id += 1;
        return {
          id,
          description: dayOff.timeOffRegistration.reason,
          startDate: dayjs(dayOff.start).toDate(),
          endDate: dayjs(dayOff.end).toDate(),
          realEndDate: dayjs(dayOff.end).toDate(),
          item: dayOff,
        };
      }),
    );

    return result;
  }, [
    convertItemToEvent,
    currentDate,
    daysOff,
    employeeId,
    timeEntriesOfCurrentDay,
    trackedTimePerDate,
  ]);

  const handlePressEvent = useCallback(
    ({ item }: CalendarDayViewEventWithItem) => {
      if (isTimeEntry(item)) {
        timeEntrySheetRef.current?.present({
          item,
        });
      } else if (isDayOff(item)) {
        timeOffDetailsBottomSheetRef.current?.present({
          timeOffRegistration:
            timeOffRegistrations.find(
              ({ timeOffId }) =>
                timeOffId === item.timeOffRegistration.timeOffId,
            ) || item.timeOffRegistration,
        });
      } else if (item) {
        if (item?.start && dayjs(item?.start).isSame(dayjs(), 'day')) {
          const activeItem = getActiveItem(employeeId as string);
          if (activeItem?.timeEntryId === item.timeEntryId) {
            return;
          }
        }

        changeTimeTrackingItemSheetRef.current?.present({
          item,
        });
      }
    },
    [employeeId, getActiveItem, timeOffRegistrations],
  );

  const handleStartRemoving = useCallback(
    (item: TimeEntry | TimeTrackingItem) => {
      removeTimeEntryRef.current = {
        timeEntryId: item.timeEntryId,
        isExisting: isTimeEntry(item),
      };
      removeTimeEntrySheetRef.current?.present();
    },
    [],
  );

  const handleChangeTimeOff = useCallback(
    async (values: ChangeTimeOffRegistrationInputType) => {
      await changeTimeOff({
        timeOffId: values.timeOffId,
        start: values.start as Date,
        end: values.end as Date,
        fullDay: values.fullDay || false,
        reason: values.reason,
        timeOffTypeId: values.timeOffTypeId,
        effectiveHours: values.effectiveHours,
        employeeId: employeeId as string,
        buildUp: values.buildUp,
        daysOff: values.daysOff as {
          dayOffId?: Uuid;
          start: Date;
          end: Date;
          hours: number;
        }[],
      });
    },
    [changeTimeOff, employeeId],
  );

  const handleChangeTimeTrackingItem = useCallback(
    async (values: TimeTrackingItemInputType) => {
      if (values.isExisting) {
        await syncEntries([
          {
            timeEntryId: values.timeEntryId,
            start: values.start as Date,
            end: values.end as Date,
            timeTypeId: values.timeTypeId,
            projectId: values.project?.projectId,
            eventId: values.eventId,
            relationId: values.relation?.relationId,
          },
        ]);

        return;
      }

      updateTrackedTime(employeeId || '', values.timeEntryId, {
        ...values,
        projectId: values.project?.projectId,
        relationId: values.relation?.relationId,
      } as TimeTrackingItem);
    },
    [employeeId, syncEntries, updateTrackedTime],
  );

  const handleCancelDeclaration = useCallback(async () => {
    if (!removeTimeEntryRef.current) {
      return;
    }

    if (removeTimeEntryRef.current.isExisting) {
      await removeTimeEntry({
        timeEntryId: removeTimeEntryRef.current.timeEntryId || '',
      });
    } else {
      removeTrackedTime(
        employeeId || '',
        removeTimeEntryRef.current.timeEntryId,
      );
    }

    removeTimeEntryRef.current = undefined;
    changeTimeTrackingItemSheetRef.current?.dismiss();
    changeTimeTrackingItemSheetRef.current?.forceClose();
    timeEntrySheetRef.current?.dismiss();
    timeEntrySheetRef.current?.forceClose();
  }, [employeeId, removeTimeEntry, removeTrackedTime]);

  const handleCreateTimeTrackingItem = useCallback(
    async (values: TimeTrackingItemInputType) => {
      if (values.changeOverlappingTimeEntries && values.start && values.end) {
        const endOfDay = dayjs(values.start).endOf('day');

        const overlappingTrackingItems = (
          trackedTimePerDate[dayjs(values.start).format('YYYY-MM-DD')] || []
        ).filter(
          ({ employeeId: itemEmployeeId, start, end }) =>
            itemEmployeeId === employeeId &&
            areDayjsRangesOverlapping(
              { start, end: end || endOfDay },
              {
                start: values.start as Date,
                end: values.end as Date,
              },
            ),
        );

        overlappingTrackingItems.forEach((item) => {
          const splitTrackedTimes: {
            start: Date;
            end: Date | undefined;
          }[] = [];

          if (
            dayjs(values.start).isBetween(
              item.start,
              item.end || endOfDay,
              'minute',
              '()',
            )
          ) {
            splitTrackedTimes.push({
              start: item.start,
              end: dayjs(values.start).toDate(),
            });
          }

          if (
            dayjs(values.end).isBetween(
              item.start,
              item.end || endOfDay,
              'minute',
              '()',
            )
          ) {
            splitTrackedTimes.push({
              start: dayjs(values.end).toDate(),
              end: item.end,
            });
          }

          if (splitTrackedTimes.length > 0) {
            removeTrackedTime(employeeId || '', item.timeEntryId);
            splitTrackedTimes.forEach((splitTrackedTime, index) => {
              createTrackedTime(employeeId || '', {
                ...item,
                start: splitTrackedTime.start,
                end: splitTrackedTime.end,
                timeTypeId: item.timeTypeId,
                employeeId: employeeId || '',
                timeEntryId: index === 0 ? item.timeEntryId : v7(),
                eventId: item.eventId,
                projectId: item.projectId,
                relationId: item.relationId,
              });
            });
          }
        });

        const timeEntriesToCheckResponse = await TimeEntriesByEmployeeIdRequest(
          {
            employeeId: employeeId as string,
            date: dayjs(values.start).format('YYYY-MM-DD'),
          },
        );

        const overlappingExistingItems = (
          timeEntriesToCheckResponse?.data?.member || []
        ).filter(
          (item) =>
            [TimeEntryStatus.REJECTED, TimeEntryStatus.OPEN].includes(
              item.status,
            ) &&
            areDayjsRangesOverlapping(
              { start: item.start, end: item.end || endOfDay },
              {
                start: values.start as Date,
                end: values.end as Date,
              },
            ),
        );

        const newOverlappingTimeEntries: {
          timeEntryId: Uuid;
          start: Date;
          end: Date;
          timeTypeId: Uuid;
          description?: string;
          projectId?: Uuid | null;
          eventId?: Uuid | null;
          relationId?: Uuid | null;
        }[] = [];

        overlappingExistingItems.forEach((item) => {
          const splitTrackedTimes: {
            start: Date;
            end: Date | undefined;
          }[] = [];

          if (
            dayjs(values.start).isBetween(
              item.start,
              item.end || endOfDay,
              'minute',
              '()',
            )
          ) {
            splitTrackedTimes.push({
              start: item.start,
              end: dayjs(values.start).toDate(),
            });
          }

          if (
            dayjs(values.end).isBetween(
              item.start,
              item.end || endOfDay,
              'minute',
              '()',
            )
          ) {
            splitTrackedTimes.push({
              start: dayjs(values.end).toDate(),
              end: item.end,
            });
          }

          newOverlappingTimeEntries.push(
            ...splitTrackedTimes.map((splitTrackedTime, index) => ({
              timeEntryId: index === 0 ? item.timeEntryId : v7(),
              start: splitTrackedTime.start,
              end: splitTrackedTime.end as Date,
              timeTypeId: item.timeTypeId,
              description: item.description,
              eventId: item.eventId,
              projectId: item.projectId,
              relationId: item.relationId,
            })),
          );
        });

        await syncTime({
          employeeId: employeeId as string,
          timeEntries: newOverlappingTimeEntries,
        });
      }

      createTrackedTime(employeeId || '', {
        start: values.start as Date,
        end: values.end as Date,
        timeTypeId: values.timeTypeId,
        employeeId: employeeId || '',
        timeEntryId: values.timeEntryId,
        eventId: values.eventId,
        projectId: values.project?.projectId,
        relationId: values.relation?.relationId,
      });
    },
    [
      createTrackedTime,
      employeeId,
      removeTrackedTime,
      syncTime,
      trackedTimePerDate,
    ],
  );

  const timeline = useMemo(
    () => (
      <CalendarDayView<CalendarDayViewEventWithItem>
        unavailableHours={unavailableHours}
        events={events}
        onEventPress={handlePressEvent}
        EventComponent={TimeTrackingOrEntryEvent}
        currentDate={currentDate}
      />
    ),
    [currentDate, events, handlePressEvent, unavailableHours],
  );

  return (
    <Box flex={1} position="relative">
      {/* eslint-disable-next-line react/style-prop-object */}
      <StatusBar style="dark" />
      <ExpandableHeader
        alwaysVisibleChildren={buttons}
        maxHeight={contentHeight}
        onAlwaysVisibleHeightChange={setAlwaysVisibleHeight}
      >
        <ScrollableCalendar
          onPressDay={setCurrentDate}
          currentDate={currentDate}
          markedDays={markedDays}
        />
      </ExpandableHeader>
      <Box
        flex={1}
        backgroundColor="white"
        overflow="hidden"
        position="relative"
        top={alwaysVisibleHeight + 32}
        bottom={0}
        onLayout={handleContentLayout}
        zIndex={0}
      >
        {timeline}
      </Box>
      <ShowAndChangeTimeEntryBottomSheet
        onSubmit={handleChangeTimeTrackingItem}
        onStartRemoving={handleStartRemoving}
        bottomSheetRef={timeEntrySheetRef}
      />
      <ChangeTimeTrackingItemBottomSheet
        bottomSheetRef={changeTimeTrackingItemSheetRef}
        onStartRemoving={handleStartRemoving}
        onSubmit={handleChangeTimeTrackingItem}
      />
      <TimeOffDetailsBottomSheet
        bottomSheetRef={timeOffDetailsBottomSheetRef}
        onSubmit={handleChangeTimeOff}
      />
      <ConfirmBottomSheet
        closeOnCancel
        onConfirm={handleCancelDeclaration}
        bottomSheetRef={removeTimeEntrySheetRef}
        dangerous
        title={<FormattedMessage id="label.removeTimeEntry" />}
        content={
          <FormattedMessage id="label.areYouSureYouWantToRemoveTimeEntry" />
        }
        successFullFormViewProps={{
          gifUrl: 'https://media.giphy.com/media/KbvZsN07K9Hy9ZoyR7/giphy.gif',
          color: colors.lila[100],
          title: 'label.timeEntryRemoved',
          labelId: 'label.timeEntryRemovedDescription',
        }}
      />
      <CreateTimeTrackingItemBottomSheet
        bottomSheetRef={createTimeTrackingItemSheetRef}
        onSubmit={handleCreateTimeTrackingItem}
      />
    </Box>
  );
};
export default TrackedTimesCalendarScreen;
