import { StyleSheet } from 'react-native';
import { colors } from '@bas/theme';

export default StyleSheet.create({
  container: {
    position: 'relative',
    flex: 1,
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 12,
    backgroundColor: colors.white,
    paddingTop: 26,
    paddingBottom: 14,
    borderWidth: 1,
    borderColor: colors.lila[300],
    borderRadius: 2,
  },
  eventColor: {
    position: 'absolute',
    height: 10,
    left: -1,
    right: -1,
    top: -1,
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
    backgroundColor: colors.blue[500],
  },
  finishedEventColor: {
    backgroundColor: colors.green[300],
  },
  eventIconContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  eventInfoContainer: {
    position: 'relative',
    minHeight: 59,
    paddingLeft: 20,
    borderLeftWidth: 1,
    borderLeftColor: colors.lila[300],
    flex: 1,
  },
  eventNameRow: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  eventName: {},
  eventNameTime: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  paddingTop2: {
    paddingTop: 2,
  },
  timeContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  firstTime: {
    paddingLeft: 5,
    paddingRight: 24,
  },
  secondTime: {
    paddingLeft: 5,
  },
});
