import { Box, TouchableWithoutFeedback } from '@bas/ui/native/base';
import { ReactElement, ReactNode } from 'react';
import { DimensionValue, StyleProp, ViewStyle } from 'react-native';
import styles from './styles';

export type SquareTileProps = {
  width: DimensionValue;
  selected?: boolean;
  children: ReactNode;
  style?: StyleProp<ViewStyle>;
  onPress?: () => void;
  padding?: number;
};

const SquareTile = ({
  width,
  selected,
  children,
  onPress,
  style,
  padding = 19,
}: SquareTileProps): ReactElement => (
  <TouchableWithoutFeedback onPress={onPress}>
    <Box
      style={[
        styles.squareTile,
        { width, paddingTop: padding, paddingLeft: padding },
      ]}
    >
      <Box style={[styles.children, selected && styles.selected, style]}>
        {children}
      </Box>
    </Box>
  </TouchableWithoutFeedback>
);

export default SquareTile;
