import { formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ReceivedOrderedInventoryMutationProps = {
  inventoryItemId: Uuid;
  receivedAt: Date;
  quantity: number;
  supplierId: Uuid;
};

export const ReceivedOrderedInventoryMutation = async ({
  inventoryItemId,
  receivedAt,
  ...values
}: ReceivedOrderedInventoryMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/inventory/item/${inventoryItemId}/received-inventory`,
    {
      inventoryItemId,
      receivedAt: formatDateTime(receivedAt),
      ...values,
    },
  );

export const useReceivedOrderedInventoryMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ReceivedOrderedInventoryMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ReceivedOrderedInventoryMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ReceivedOrderedInventoryMutationProps
  >({
    mutationFn: ReceivedOrderedInventoryMutation,
    throwOnError: false,
    ...options,
  });
