import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type RemoveCostsOfProjectMutationProps = {
  projectId: Uuid;
  costId: Uuid;
};

export const RemoveCostsOfProjectMutation = async ({
  projectId,
  costId,
  ...values
}: RemoveCostsOfProjectMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/projects/${projectId}/costs/${costId}/remove-costs`,
    {
      costId,
      ...values,
    },
  );

export const useRemoveCostsOfProjectMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveCostsOfProjectMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  RemoveCostsOfProjectMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveCostsOfProjectMutationProps
  >({
    mutationFn: RemoveCostsOfProjectMutation,
    throwOnError: false,
    ...options,
  });
