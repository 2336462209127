import { Box, BoxProps, Typography } from '@bas/ui/native/base';
import { ComponentType, ReactElement, ReactNode, useMemo } from 'react';
import { ScrollView, ScrollViewProps } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import styles from './styles';

export type MobileTaxationStepTemplateProps = BoxProps & {
  secondary: ReactNode | string;
  primary: ReactNode | string;
  children: ReactNode;
  disableScroll?: boolean;
  disableContentPadding?: boolean;
  ScrollComponent?: ComponentType<ScrollViewProps>;
};

const MobileTaxationStepTemplate = ({
  primary,
  secondary,
  children,
  disableScroll,
  disableContentPadding,
  ScrollComponent,
  ...props
}: MobileTaxationStepTemplateProps): ReactElement => {
  const { top } = useSafeAreaInsets();
  const content = useMemo(
    () =>
      disableContentPadding ? (
        children
      ) : (
        <Box
          flex={1}
          paddingTop={3}
          paddingHorizontal={{
            largerTablet: 80,
            tablet: 50,
            phone: 20,
          }}
        >
          {children}
        </Box>
      ),
    [children, disableContentPadding],
  );

  const style = useMemo(
    () => ({
      paddingTop: top > 24 ? top : 24,
      overflow: 'hidden' as const,
    }),
    [top],
  );

  return (
    <Box
      flex={1}
      maxWidth={1600}
      width="100%"
      alignSelf="center"
      flexDirection="column"
      style={style}
      {...props}
    >
      <Box
        paddingHorizontal={{
          largerTablet: 80,
          tablet: 50,
          phone: 20,
        }}
      >
        <Typography
          variant="subtitle1"
          color="secondaryTitle"
          style={styles.secondary}
        >
          {secondary}
        </Typography>
        <Typography
          color="mainPrimary"
          fontWeight={700}
          variant="h4"
          style={styles.primary}
        >
          {primary}
        </Typography>
      </Box>
      {disableScroll && content}
      {!disableScroll &&
        (ScrollComponent ? (
          <ScrollComponent>{content}</ScrollComponent>
        ) : (
          <ScrollView>{content}</ScrollView>
        ))}
    </Box>
  );
};

export default MobileTaxationStepTemplate;
