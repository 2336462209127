import { isCreatedQuoteLineInputType } from '@bas/financial-domain/input-types';
import { QuoteStatus } from '@bas/financial-domain/models';
import { useUpdateQuoteByQuoteIdMutation } from '@bas/financial-domain/mutations';
import { useQuotesRequest } from '@bas/financial-domain/requests';
import { useCreateQuoteByProjectMutation } from '@bas/project-domain/mutations';
import { MovingJobTaxationWizardInput } from '@bas/taxation-tool-domain/models';
import {
  MovingJobQuoteStepTemplate,
  RequestingEstimateStepTemplate,
} from '@bas/taxation-tool-domain/native/templates';
import { useWizardStore } from '@bas/ui/native/organisms';
import { isAxiosError } from 'axios';
import dayjs from 'dayjs';
import { ReactElement, useEffect, useState } from 'react';
import { UseFormReturn, useFormState, useWatch } from 'react-hook-form';
import { v7 } from 'uuid';

const CreateMovingJobQuoteStep = ({
  onNext,
}: {
  onNext: (disableOverriddenSubmit?: boolean, force?: boolean) => Promise<void>;
}): ReactElement => {
  const { setTemporarySubmit } = useWizardStore();
  const [loading, setLoading] = useState<boolean>(false);
  const { isSubmitting } = useFormState();
  const projectId = useWatch({ name: 'projectId' });
  const { mutateAsync: createQuote } = useCreateQuoteByProjectMutation();
  const { mutateAsync: updateQuote } = useUpdateQuoteByQuoteIdMutation();

  const { data: existingQuotes } = useQuotesRequest(
    {
      projectId: projectId || '',
    },
    { enabled: !!projectId },
  );

  useEffect(() => {
    setTemporarySubmit(
      ({
        setValue: setFormValue,
      }: UseFormReturn<MovingJobTaxationWizardInput>) =>
        async ({ quote, ...values }: MovingJobTaxationWizardInput) => {
          setLoading(true);
          try {
            if (!quote) {
              return;
            }
            const latestConceptQuote = existingQuotes?.data?.member
              .sort(
                (
                  { createdAt: quoteACreatedA },
                  { createdAt: quoteBCreatedA },
                ) => dayjs(quoteACreatedA).diff(quoteBCreatedA),
              )
              .find(({ quoteStatus }) => quoteStatus === QuoteStatus.CONCEPT);
            try {
              if (!values.quoteId || !latestConceptQuote) {
                const newQuoteId = v7();
                await createQuote({
                  projectId: values.projectId,
                  quoteId: newQuoteId,
                  lines: quote.lines
                    .filter(isCreatedQuoteLineInputType)
                    .filter(
                      (line) =>
                        !quote.ignoreZeroQuantityLines ||
                        parseFloat(line.quantity.toString()) < 0 ||
                        parseFloat(line.quantity.toString()) > 0,
                    ),
                  freeText: { ...quote.freeText },
                  includingVat: quote.includingVat,
                  templateId: quote.templateId,
                  expiresAt: quote.expiresAt,
                  discount: quote.discount,
                });
                setFormValue('quoteId', newQuoteId);
              } else {
                await updateQuote({
                  quoteId: latestConceptQuote.quoteId,
                  lines: quote.lines
                    .filter(isCreatedQuoteLineInputType)
                    .filter(
                      (line) =>
                        !quote.ignoreZeroQuantityLines ||
                        parseFloat(line.quantity.toString()) < 0 ||
                        parseFloat(line.quantity.toString()) > 0,
                    ),
                  freeText: { ...quote.freeText },
                  includingVat: quote.includingVat,
                  templateId: quote.templateId,
                  expiresAt: quote.expiresAt,
                  reference: quote.reference,
                  discount: quote.discount,
                  vatScenario: quote.vatScenario,
                });
              }

              setTemporarySubmit(undefined);
              onNext(true, true);
            } catch (exception) {
              if (
                isAxiosError(exception) &&
                values.quoteId &&
                exception?.response?.status === 404
              ) {
                const newQuoteId = v7();
                await createQuote({
                  projectId: values.projectId,
                  quoteId: newQuoteId,
                  lines: quote.lines,
                  freeText: { ...quote.freeText },
                  includingVat: quote.includingVat,
                  templateId: quote.templateId,
                  expiresAt: quote.expiresAt,
                  discount: quote.discount,
                  // sendQuote: values.customerSignsAtTheTable
                  //   ? false
                  //   : !!values.shouldSendQuote,
                });

                setFormValue('quoteId', newQuoteId);
                setTemporarySubmit(undefined);
                onNext(true, true);
              } else {
                // eslint-disable-next-line no-console
                console.error(exception);
                throw exception;
              }
            }
          } catch (exception) {
            // eslint-disable-next-line no-console
            console.error(exception);
            throw exception;
          }
        },
    );

    return () => {
      setTemporarySubmit(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isSubmitting || loading) {
    return <RequestingEstimateStepTemplate />;
  }

  return <MovingJobQuoteStepTemplate />;
};

export default CreateMovingJobQuoteStep;
