import { TimeType } from '@bas/hrm-domain/models';
import { useTimeTypesRequest } from '@bas/hrm-domain/requests';
import { Event, isProjectEvent } from '@bas/planning-domain/models';
import { Project } from '@bas/project-domain/models';
import { useEmployeeStore, useTimeTrackingStore } from '@bas/shared/state';
import { colors } from '@bas/theme';
import { ProgressBar } from '@bas/ui/native/atoms';
import { Box, ShopifyTheme, Typography } from '@bas/ui/native/base';
import {
  ReactHookFormEventDropdown,
  ReactHookFormProjectDropdown,
  SquareIconTile,
} from '@bas/ui/native/molecules';
import { Uuid } from '@bas/value-objects';
import { FlashList, ListRenderItemInfo } from '@shopify/flash-list';
import { useResponsiveProp } from '@shopify/restyle';
import dayjs from 'dayjs';
import { StatusBar } from 'expo-status-bar';
import humanizeDuration from 'humanize-duration';
import * as React from 'react';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Platform, SectionList } from 'react-native';
import {
  SectionListData,
  SectionListRenderItemInfo,
} from 'react-native/Libraries/Lists/SectionList';
import { DimensionValue } from 'react-native/Libraries/StyleSheet/StyleSheetTypes';
import { LayoutChangeEvent } from 'react-native/Libraries/Types/CoreEventTypes';

const TimeTypeSquareTile = ({
  item,
  employeeId,
  width,
}: {
  item: TimeType;
  employeeId: Uuid;
  width: DimensionValue;
}) => {
  const totalWorkedHours = useTimeTrackingStore(
    (state) => state.today.workedHoursByTimeTypeId[item.timeTypeId] ?? 0,
  );
  const getActiveItem = useTimeTrackingStore((state) => state.getActiveItem);
  const finishActiveItem = useTimeTrackingStore(
    (state) => state.finishActiveItem,
  );
  const startTrackingTime = useTimeTrackingStore(
    (state) => state.startTrackingTime,
  );
  const activeItem = useTimeTrackingStore((state) =>
    state.trackedTimePerDate[dayjs().format('YYYY-MM-DD')]?.find(
      ({ employeeId: itemEmployeeId, end }) =>
        !end && itemEmployeeId === employeeId,
    ),
  );

  const handlePressTimeType = useCallback(
    (itemId: string) => {
      if (getActiveItem(employeeId)?.timeTypeId === itemId) {
        finishActiveItem(employeeId, dayjs().toDate());
      } else {
        startTrackingTime(employeeId, itemId, dayjs().toDate());
      }
    },
    [employeeId, startTrackingTime, finishActiveItem, getActiveItem],
  );

  const selected = useMemo(
    () => activeItem?.timeTypeId === item.timeTypeId,
    [activeItem, item.timeTypeId],
  );

  return (
    <SquareIconTile
      onPress={() => handlePressTimeType(item.timeTypeId)}
      selected={selected}
      icon={item.icon}
      label={
        <Box flexDirection="column" alignItems="center">
          <Typography fontWeight={700} color={selected ? 'white' : 'darkText'}>
            {item.name}
          </Typography>
          <Typography
            fontWeight={700}
            color={selected ? 'white' : 'subtitle1Color'}
          >
            {totalWorkedHours !== 0 &&
              humanizeDuration(totalWorkedHours * 3600 * 1000, {
                language: 'nl',
                round: true,
                fallbacks: ['en'],
                units: ['y', 'mo', 'w', 'd', 'h', 'm'],
              })}
          </Typography>
        </Box>
      }
      width={width}
    />
  );
};

const TrackTimeScreen = (): ReactElement => {
  const [hoursToWork] = useState<number>(8);
  const [heightOfScreen, setHeightOfScreen] = useState<number>(0);
  const [heightOfHeader, setHeightOfHeader] = useState<number>(0);

  const employeeId = useEmployeeStore(
    (state) => state.employee?.employeeId || '',
  );

  const numberOfColumns = useResponsiveProp<ShopifyTheme, number>({
    lg: 6,
    largerTablet: 5,
    tablet: 5,
    phone: 2,
  });

  const workedHours = useTimeTrackingStore((state) => state.today.workedHours);
  const changeCurrentProjectIdAndOrEventId = useTimeTrackingStore(
    (state) => state.changeCurrentProjectIdAndOrEventId,
  );
  const currentProjectId = useTimeTrackingStore(
    (state) => state.currentProjectId,
  );
  const currentEventId = useTimeTrackingStore((state) => state.currentEventId);

  const { formatMessage } = useIntl();

  const handleRenderItem = useCallback(
    ({ item }: ListRenderItemInfo<TimeType>) => (
      <TimeTypeSquareTile employeeId={employeeId} item={item} width="100%" />
    ),
    [employeeId],
  );

  const handleRenderSectionItem = useCallback(
    ({ item }: SectionListRenderItemInfo<TimeType[]>) => (
      <Box
        paddingBottom={2}
        style={{
          marginLeft: -14,
        }}
      >
        <FlashList
          estimatedItemSize={186}
          numColumns={numberOfColumns}
          renderItem={handleRenderItem}
          data={item}
          contentContainerStyle={{
            paddingBottom: 24,
            paddingLeft: 14,
            paddingRight: 20,
          }}
        />
      </Box>
    ),
    [handleRenderItem, numberOfColumns],
  );

  const handleRenderSectionHeader = useCallback(
    ({
      section: { label },
    }: {
      // eslint-disable-next-line react/no-unused-prop-types
      section: SectionListData<
        TimeType[],
        {
          label: string;
        }
      >;
    }) => (
      <Box
        backgroundColor="mainBackground"
        paddingVertical={1}
        paddingHorizontal={20}
      >
        <Typography variant="subtitle1" fontWeight={700}>
          {label}
        </Typography>
      </Box>
    ),
    [],
  );

  const { data: timeTypesData } = useTimeTypesRequest();

  const timeTypes = useMemo(
    () => timeTypesData?.data?.member || [],
    [timeTypesData?.data],
  );

  const mappedCategories = useMemo(
    () =>
      Object.values(
        timeTypes.reduce(
          (acc, v) => {
            const key = v.categoryId;
            if (!acc[key]) {
              acc[key] = {
                label: v.categoryName,
                data: [[]],
              };
            }
            acc[key].data[0].push(v);

            return acc;
          },
          {} as Record<
            string,
            {
              label: string;
              data: TimeType[][];
            }
          >,
        ),
      ),
    [timeTypes],
  );

  const handleLayoutScreen = useCallback(
    ({
      nativeEvent: {
        layout: { height },
      },
    }: LayoutChangeEvent) => {
      setHeightOfScreen(height);
    },
    [],
  );

  const handleLayoutOfHeader = useCallback(
    ({
      nativeEvent: {
        layout: { height },
      },
    }: LayoutChangeEvent) => {
      setHeightOfHeader(height);
    },
    [],
  );

  const form = useForm<{
    projectId: string | undefined;
    eventId: string | undefined;
  }>({
    mode: 'all',
    defaultValues: {
      projectId: currentProjectId,
      eventId: currentEventId,
    },
  });

  const handleChangeProject = useCallback(
    (id?: unknown, newValue?: Project) => {
      form.setValue('projectId', newValue?.projectId);
      form.setValue('eventId', undefined);
      changeCurrentProjectIdAndOrEventId(newValue?.projectId, undefined);
    },
    [changeCurrentProjectIdAndOrEventId, form],
  );

  const handleChangeEvent = useCallback(
    (id?: unknown, newValue?: Event) => {
      form.setValue('eventId', newValue?.eventId);
      if (isProjectEvent(newValue)) {
        form.setValue('projectId', newValue?.projectId);
        changeCurrentProjectIdAndOrEventId(
          newValue?.projectId,
          newValue?.eventId,
        );
      } else {
        form.setValue('projectId', undefined);
        changeCurrentProjectIdAndOrEventId(undefined, newValue?.eventId);
      }
    },
    [changeCurrentProjectIdAndOrEventId, form],
  );

  const { projectId } = useWatch({ control: form.control });

  return (
    <FormProvider {...form}>
      <Box backgroundColor="cardBorder" flex={1} onLayout={handleLayoutScreen}>
        {/* eslint-disable-next-line react/style-prop-object */}
        <StatusBar style="dark" />
        <Box
          paddingTop={2}
          rowGap={2}
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="space-between"
          alignItems="center"
          onLayout={handleLayoutOfHeader}
        >
          <Box flexBasis="100%" pl={20}>
            <Typography variant="h5" fontWeight={700}>
              <FormattedMessage id="mobileApp.timeTracking" />
            </Typography>
          </Box>
          <Box flexBasis="100%" paddingHorizontal={20}>
            <Typography>
              <FormattedMessage
                id="label.todayYouWorkedXTime"
                values={{
                  time: (
                    <Typography fontWeight={700}>
                      {humanizeDuration(workedHours * 3600 * 1000, {
                        language: 'nl',
                        round: true,
                        fallbacks: ['en'],
                        units: ['y', 'mo', 'w', 'd', 'h', 'm'],
                        delimiter: ` ${formatMessage({ id: 'label.and' })} `,
                      })}
                    </Typography>
                  ),
                }}
              />
            </Typography>
          </Box>
          <Box flexBasis="100%" paddingHorizontal={20} paddingBottom={2}>
            <ProgressBar
              fullWidth
              progress={(workedHours / hoursToWork) * 100}
              progressColor={colors.blue[500]}
            />
          </Box>
          <Box flexBasis="100%" paddingHorizontal={20}>
            <Typography>
              <FormattedMessage id="label.onWhatProjectOrEventAreYouWorking" />
            </Typography>
          </Box>
          <Box flexBasis="50%" paddingLeft={20} paddingRight={1}>
            <ReactHookFormProjectDropdown
              name="projectId"
              label={formatMessage({ id: 'label.project' })}
              onChange={handleChangeProject}
              projectId={currentProjectId || projectId || undefined}
              useWorkaroundForFreakingThing
            />
          </Box>
          <Box flexBasis="50%" paddingRight={20} paddingLeft={1}>
            <ReactHookFormEventDropdown
              name="eventId"
              label={formatMessage({ id: 'label.event' })}
              projectId={currentProjectId || projectId || undefined}
              showAllOfDay
              employeeId={employeeId}
              onChange={handleChangeEvent}
            />
          </Box>
        </Box>
        <Box
          backgroundColor="mainBackground"
          height={heightOfScreen - heightOfHeader || '100%'}
        >
          {!!heightOfScreen && !!heightOfHeader && (
            <SectionList<
              TimeType[],
              {
                label: string;
              }
            >
              sections={mappedCategories}
              renderItem={handleRenderSectionItem}
              renderSectionHeader={handleRenderSectionHeader}
              contentContainerStyle={{
                paddingBottom: 24,
              }}
              initialNumToRender={Platform.OS === 'web' ? 9999999 : undefined}
            />
          )}
        </Box>
      </Box>
    </FormProvider>
  );
};
export default TrackTimeScreen;
