import {
  AddressInputType,
  addressInputTypeDefaultValues,
} from '@bas/shared/input-types';
import { Address } from '@bas/value-objects';
import { useCallback, useMemo } from 'react';
import isEqual from 'react-fast-compare';
import { useFormContext, useWatch } from 'react-hook-form';
import { useAddressAutocomplete } from './useAddressAutocomplete';

export const useFormAddressAutoComplete = <
  T extends AddressInputType = AddressInputType,
>(
  prefix: string,
  onValid?: (valid: boolean) => void,
): T => {
  const { setValue } = useFormContext();
  const currentAddress = useWatch({
    name: prefix,
  });

  const autoCompleteAddress = useMemo(
    () => ({
      country: currentAddress.country,
      zipCode: currentAddress.zipCode,
      streetName: currentAddress.streetName,
      houseNumber: currentAddress.houseNumber,
      houseNumberAddition: currentAddress.houseNumberAddition,
    }),
    [
      currentAddress.country,
      currentAddress.zipCode,
      currentAddress.houseNumber,
      currentAddress.streetName,
      currentAddress.houseNumberAddition,
    ],
  );

  const handleAutocomplete = useCallback(
    (newAddress: Address) => {
      const resultingAddress = {
        ...currentAddress,
        ...newAddress,
      };

      if (typeof currentAddress['@id'] !== 'undefined') {
        delete currentAddress['@id'];
      }
      if (typeof resultingAddress['@id'] !== 'undefined') {
        delete resultingAddress['@id'];
      }
      if (typeof currentAddress['@type'] !== 'undefined') {
        delete currentAddress['@type'];
      }
      if (typeof resultingAddress['@type'] !== 'undefined') {
        delete resultingAddress['@type'];
      }
      if (typeof currentAddress['@context'] !== 'undefined') {
        delete currentAddress['@context'];
      }
      if (typeof resultingAddress['@context'] !== 'undefined') {
        delete resultingAddress['@context'];
      }

      if (isEqual(resultingAddress, currentAddress)) {
        return;
      }

      setValue(prefix, resultingAddress, {
        shouldValidate: false,
        shouldDirty: false,
        shouldTouch: false,
      });
    },
    [currentAddress, prefix, setValue],
  );

  useAddressAutocomplete(autoCompleteAddress, handleAutocomplete, onValid);

  return currentAddress || addressInputTypeDefaultValues();
};
