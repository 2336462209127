import { ProjectEstimate } from '@bas/project-domain/models';
import { ErrorResponse, Money, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type OverwriteEstimateItem = {
  lineNumber: number;
  quantity?: number | null;
  pricePerUnit?: Money | null;
};

export type OverwriteEstimateValuesMutationProps = {
  projectId: Uuid;
  reason?: string | null;
  overwriteItems: OverwriteEstimateItem[];
};

export const OverwriteEstimateValuesMutation = async ({
  projectId,
  ...values
}: OverwriteEstimateValuesMutationProps): Promise<
  AxiosResponse<ProjectEstimate>
> =>
  axios.put(
    `api/{tenantId}/projects/${projectId}/project-estimates/overwrite-value`,
    {
      projectId,
      ...values,
    },
  );

export const useOverwriteEstimateValuesMutation = (
  options: UseMutationOptions<
    AxiosResponse<ProjectEstimate>,
    AxiosError<ErrorResponse>,
    OverwriteEstimateValuesMutationProps
  >,
): UseMutationResult<
  AxiosResponse<ProjectEstimate>,
  AxiosError<ErrorResponse>,
  OverwriteEstimateValuesMutationProps
> =>
  useMutation<
    AxiosResponse<ProjectEstimate>,
    AxiosError<ErrorResponse>,
    OverwriteEstimateValuesMutationProps
  >({
    mutationFn: OverwriteEstimateValuesMutation,
    throwOnError: false,
    ...options,
  });
