import { CrmContactPersonType } from '@bas/crm-domain/models';
import { Box } from '@bas/ui/native/base';
import { ReactHookFormDropdown } from '@bas/ui/native/molecules';
import { ContactPersonForm } from '@bas/ui/native/organisms';
import { ReactElement } from 'react';
import { useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';

export type CrmContactPersonFormProps = {
  prefix: string;
  disabledContactPersonTypes: CrmContactPersonType[];
  light?: boolean;
};

const CrmContactPersonForm = ({
  prefix,
  disabledContactPersonTypes,
  light,
}: CrmContactPersonFormProps): ReactElement => {
  const [deleteContactPerson] = useWatch({
    name: [`${prefix}.deleteContactPerson`],
  });

  const { formatMessage } = useIntl();
  return (
    <Box>
      <ReactHookFormDropdown
        name={`${prefix}.contactPersonType`}
        autoComplete="off"
        label={formatMessage({ id: 'label.contactPersonType' })}
        light={light}
        items={[
          ...Object.values(CrmContactPersonType)
            .filter(
              (option) =>
                !!option &&
                (!disabledContactPersonTypes.includes(option) ||
                  option === CrmContactPersonType.OTHER),
            )
            .map((option) => ({
              id: option,
              label: formatMessage({
                id: `relation.contactPersonType.${option}`,
              }),
            })),
        ]}
      />
      <ContactPersonForm
        disabled={deleteContactPerson}
        prefix={prefix}
        light={light}
      />
    </Box>
  );
};

export default CrmContactPersonForm;
