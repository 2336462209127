import { formatDate } from '@bas/shared/utils';
import { ErrorResponse, Money, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ChangeDeclarationMutationProps = {
  declarationId: Uuid;
  declarationDate: Date;
  description: string;
  amount: Money;
  categoryId: Uuid;
  receiptFileId?: Uuid;
  eventId?: Uuid | null;
  projectId?: Uuid | null;
  relationId?: Uuid | null;
};

export const ChangeDeclarationMutation = async ({
  declarationId,
  declarationDate,
  ...values
}: ChangeDeclarationMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/hrm/declarations/${declarationId}/change`, {
    declarationId,
    declarationDate: formatDate(declarationDate),
    ...values,
  });

export const useChangeDeclarationMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeDeclarationMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ChangeDeclarationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeDeclarationMutationProps
  >({
    mutationFn: ChangeDeclarationMutation,
    throwOnError: false,
    ...options,
  });
