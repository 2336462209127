import { SendQuoteStepInputType } from '@bas/taxation-tool-domain/input-types';
import { MobileTaxationStepTemplate } from '@bas/taxation-tool-domain/native/templates';
import { Box, Typography } from '@bas/ui/native/base';
import { ReactHookFormSelectButtons } from '@bas/ui/native/molecules';
import * as React from 'react';
import { ReactElement } from 'react';
import { useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

const SendMovingJobQuoteStep = (): ReactElement => {
  const { formatMessage } = useIntl();
  const customerSignsAtTheTable = useWatch<
    SendQuoteStepInputType,
    'customerSignsAtTheTable'
  >({
    name: 'customerSignsAtTheTable',
  });

  return (
    <MobileTaxationStepTemplate
      primary={
        <FormattedMessage id="mobileApp.intake.movingJob.doYouWantToSendTheQuote" />
      }
      secondary={<FormattedMessage id="label.quote" />}
    >
      <Box flex={1}>
        <Box width="100%">
          <ReactHookFormSelectButtons
            name="customerSignsAtTheTable"
            label={formatMessage({
              id: 'label.customerSignsAtTheTable',
            })}
            options={[
              {
                value: true,
                label: (
                  <Typography fontWeight={500}>
                    <FormattedMessage id="label.customerSignsAtTheTable.yes" />
                  </Typography>
                ),
              },
              {
                value: false,
                label: (
                  <Typography fontWeight={500}>
                    <FormattedMessage id="label.customerSignsAtTheTable.no" />
                  </Typography>
                ),
              },
            ]}
          />
        </Box>
        {!customerSignsAtTheTable && (
          <Box width="100%" mt={3}>
            <ReactHookFormSelectButtons
              name="shouldSendQuote"
              label={formatMessage({
                id: 'label.shouldSendQuote',
              })}
              options={[
                {
                  value: true,
                  label: (
                    <Typography fontWeight={500}>
                      <FormattedMessage id="label.shouldSendQuote.yes" />
                    </Typography>
                  ),
                },
                {
                  value: false,
                  label: (
                    <Typography fontWeight={500}>
                      <FormattedMessage id="label.shouldSendQuote.no" />
                    </Typography>
                  ),
                },
              ]}
            />
          </Box>
        )}
      </Box>
    </MobileTaxationStepTemplate>
  );
};

export default SendMovingJobQuoteStep;
