import { Uuid } from '@bas/value-objects';

export type MovingJobTaxationFurniture = {
  furnitureId: Uuid;
  services: {
    [key: number]: {
      [key: string]: boolean;
    };
  };
  selected: boolean;
  servicesPossible: boolean;
  furnitureType: 'custom' | 'furniture';
  quantity: number;
};

export function isMovingJobTaxationFurniture(
  object: unknown,
): object is MovingJobTaxationFurniture {
  return (
    !!object &&
    typeof object === 'object' &&
    Object.prototype.hasOwnProperty.call(object, 'furnitureType')
  );
}

export const MovingJobTaxationFurnitureInitialValues: Omit<
  MovingJobTaxationFurniture,
  'furnitureId' | 'furnitureType'
> = {
  services: {
    0: {},
  },
  selected: true,
  servicesPossible: false,
  quantity: 0,
};
