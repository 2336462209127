import { PublicFurnitureType } from '@bas/shared/models';
import { MovingJobTaxationFurniture } from '@bas/taxation-tool-domain/models';
import { TextField } from '@bas/ui/native/atoms';
import { BottomSheetMethods, Box, Typography } from '@bas/ui/native/base';
import {
  BottomSheet,
  BottomSheetProps,
  FurnitureSquareTile,
  SquareTilesList,
} from '@bas/ui/native/organisms';
import { Uuid } from '@bas/value-objects';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { ReactElement, RefObject, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Platform } from 'react-native';

export type AddFurnitureBottomSheetProps = Omit<
  BottomSheetProps,
  'snapPoints' | 'children'
> & {
  furniture: PublicFurnitureType[];
  onAddCustomFurniture: () => void;
  onAddFurniture: (
    quantity: number,
    furnitureType?: PublicFurnitureType,
    furnitureVariantId?: Uuid,
    intakeFurniture?: MovingJobTaxationFurniture,
  ) => void;
  filter?: string;
  setFilter: (value: string) => void;
  bottomSheetRef?: RefObject<BottomSheetModal & BottomSheetMethods>;
};

const AddFurnitureBottomSheet = ({
  furniture,
  onAddFurniture,
  onAddCustomFurniture,
  filter,
  setFilter,
  bottomSheetRef,
  ...props
}: AddFurnitureBottomSheetProps): ReactElement => {
  const { formatMessage } = useIntl();

  const filteredFurniture = useMemo(() => {
    if (!filter) {
      return furniture;
    }

    return furniture.filter(
      (furnitureType) =>
        furnitureType.furnitureTypeName
          .toLowerCase()
          .includes(filter.toLowerCase()) ||
        furnitureType.translatedNames.filter((translatedName) =>
          translatedName.name.toLowerCase().includes(filter.toLowerCase()),
        ).length > 0,
    );
  }, [filter, furniture]);

  const handleRenderItem = useCallback(
    ({ item }: { item: PublicFurnitureType }) => (
      <FurnitureSquareTile
        key={item.furnitureTypeId}
        width="100%"
        handleAddFurniture={onAddFurniture}
        furnitureType={item}
      />
    ),
    [onAddFurniture],
  );

  return (
    <BottomSheet ref={bottomSheetRef} {...props}>
      <Typography
        variant="h5"
        color="white"
        fontWeight={700}
        textAlign="center"
      >
        <FormattedMessage id="mobileApp.intake.movingJob.whatFurnitureDoYouWantToAdd" />
      </Typography>
      <Box>
        <TextField
          light
          value={filter}
          autoFocus={Platform.OS !== 'web'}
          onChangeText={setFilter}
          label={formatMessage({ id: 'label.search' })}
        />
        <Typography pt={2} textAlign="center" fontWeight={700} color="white">
          <FormattedMessage
            id="label.results"
            values={{ numberOfResults: filteredFurniture.length }}
          />
        </Typography>
      </Box>

      <Box
        flex={1}
        style={{
          marginLeft: -19,
        }}
      >
        <SquareTilesList
          renderItem={handleRenderItem}
          data={filteredFurniture}
          keyExtractor={(item) => item.furnitureTypeId}
          isLoading={furniture.length === 0}
          inBottomSheet
          maxPadding={50}
        />
      </Box>
    </BottomSheet>
  );
};

export default AddFurnitureBottomSheet;
