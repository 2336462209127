import { formatDate } from '@bas/shared/utils';
import { ErrorResponse, Money, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type EditPaymentMutationProps = {
  invoiceId: Uuid;
  paymentId: Uuid;
  paidAt: Date;
  amount: Money;
  notes?: string | null;
};

export const EditPaymentMutation = async ({
  invoiceId,
  paymentId,
  paidAt,
  ...values
}: EditPaymentMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/invoices/${invoiceId}/payments/${paymentId}/edit`, {
    invoiceId,
    paymentId,
    paidAt: formatDate(paidAt),
    ...values,
  });

export const useEditPaymentMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    EditPaymentMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  EditPaymentMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    EditPaymentMutationProps
  >({
    mutationFn: EditPaymentMutation,
    throwOnError: false,
    ...options,
  });
