import { HowManyMovingBoxesAreNeededStepInputType } from '@bas/taxation-tool-domain/input-types';
import { Box, Typography } from '@bas/ui/native/base';
import { ReactHookFormNumberTextField } from '@bas/ui/native/molecules';
import { Uuid } from '@bas/value-objects';
import { usePublicMovingBoxesRequest } from '@bas/wms-domain/requests';
import { FlashList, ListRenderItemInfo } from '@shopify/flash-list';
import { useResponsiveProp } from '@shopify/restyle';
import * as React from 'react';
import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { StyleSheet } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';

export type HowManyMovingBoxesAreNeededStepProps = {
  fieldName?: 'quantity' | 'storageQuantity';
  showFromField?: 'quantity' | 'storageQuantity';
};

const styles = StyleSheet.create({
  input: {
    textAlign: 'center',
  },
});

const HowManyMovingBoxesAreNeededStep = ({
  fieldName,
  showFromField,
}: HowManyMovingBoxesAreNeededStepProps): ReactElement => {
  const { data, isLoading } = usePublicMovingBoxesRequest();

  const inventoryItems = useWatch<
    HowManyMovingBoxesAreNeededStepInputType,
    'inventoryItems'
  >({
    name: 'inventoryItems',
  });
  const { setValue } = useFormContext();
  const inventoryItemTypes = useMemo(
    () => (isLoading ? [] : data?.data.member || []),
    [data?.data, isLoading],
  );

  useEffect(() => {
    if (
      inventoryItemTypes.length > 0 &&
      inventoryItems.length !== inventoryItemTypes.length
    ) {
      const newValue = [...inventoryItems];
      inventoryItemTypes.forEach((item) => {
        if (
          !newValue.find(
            ({ inventoryItemId }) => inventoryItemId === item.inventoryItemId,
          )
        ) {
          newValue.push({
            inventoryItemId: item.inventoryItemId,
            quantity: 0,
            storageQuantity: 0,
          });
        }
      });

      setValue('inventoryItems', newValue);
    }
  }, [inventoryItemTypes, inventoryItems, setValue]);

  const handleRenderItem = useCallback(
    ({
      item,
    }: ListRenderItemInfo<{
      name: string;
      inventoryItemIndex: number;
      inventoryItemId: Uuid;
      quantity?: number | null;
      storageQuantity?: number | null;
    }>) => (
      <Box
        flex={1}
        pt={2}
        flexDirection="row"
        key={item.inventoryItemId}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box flexBasis="58.33333333%">
          <Typography color="white" variant="h5" fontWeight={700}>
            {item.name}{' '}
            {showFromField &&
              `(${inventoryItems[item.inventoryItemIndex][showFromField]})`}
          </Typography>
        </Box>
        <Box width={64}>
          <ReactHookFormNumberTextField
            name={`inventoryItems[${item.inventoryItemIndex}].${fieldName}`}
            label={item.name}
            variant="outlined"
            keyboardType="decimal-pad"
            style={styles.input}
            fractionDigits={0}
            shouldBeZero
            showZero
            textAlign="center"
          />
        </Box>
      </Box>
    ),
    [fieldName, inventoryItems, showFromField],
  );

  const paddingHorizontal = useResponsiveProp({
    tablet: 50,
    phone: 20,
  });

  const listData = useMemo(
    () =>
      inventoryItemTypes.map((item) => {
        const inventoryItem = inventoryItems.find(
          ({ inventoryItemId }) => inventoryItemId === item.inventoryItemId,
        );

        return {
          name: item.name,
          inventoryItemIndex: inventoryItems.findIndex(
            ({ inventoryItemId }) => inventoryItemId === item.inventoryItemId,
          ),
          inventoryItemId: item.inventoryItemId,
          quantity: inventoryItem?.quantity || 0,
          storageQuantity: inventoryItem?.storageQuantity || 0,
        };
      }),
    [inventoryItemTypes, inventoryItems],
  );

  return (
    <FlashList
      renderScrollComponent={ScrollView}
      renderItem={handleRenderItem}
      data={listData}
      estimatedItemSize={86}
      contentContainerStyle={{
        paddingBottom: 24,
        paddingHorizontal,
      }}
    />
  );
};

export default HowManyMovingBoxesAreNeededStep;
