import dayjs from 'dayjs';
import * as Yup from 'yup';

export interface EditOccasionalAvailabilityInputType {
  startDate?: Date | null;
  endDate?: Date | null;
}

export const EditOccasionalAvailabilityInputTypeDefaultValues =
  (): EditOccasionalAvailabilityInputType => ({
    startDate: null,
    endDate: null,
  });

export const EditOccasionalAvailabilityInputTypeValidationBuilder =
  (): Yup.ObjectSchema<EditOccasionalAvailabilityInputType> =>
    Yup.object({
      startDate: Yup.date().required().nullable().label('label.startTime'),
      endDate: Yup.date()
        .nullable()
        .required()
        .when(['startDate'], ([startDate], schema) => {
          if (!startDate || !dayjs(startDate).isValid()) return schema;
          return schema
            .min(dayjs(startDate).clone().add(1, 'second').toDate())
            .max(dayjs(startDate).clone().endOf('day').toDate())
            .required();
        })
        .label('label.endTime'),
    });
