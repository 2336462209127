import { EventWithRelation } from '../types';
import { isEvent } from './Event';

export function isEventWithRelation(
  object: unknown,
): object is EventWithRelation {
  return (
    isEvent(object) &&
    Object.prototype.hasOwnProperty.call(object, 'relationId')
  );
}
