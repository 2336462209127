import { isMovingLift, isMovingTruck } from '@bas/planning-domain/models';
import { EventPageHeader } from '@bas/planning-domain/native/molecules';
import { useVehicleByMaterialIdRequest } from '@bas/planning-domain/requests';
import { useTenantStore } from '@bas/shared/state';
import { Avatar, LicensePlate, SecretValue } from '@bas/ui/native/atoms';
import { Typography } from '@bas/ui/native/base';
import { LoadingScreen } from '@bas/ui/native/templates';
import { Uuid } from '@bas/value-objects';
import { useNavigation } from '@react-navigation/core';
import { StackActions } from '@react-navigation/native';
import { useLocalSearchParams } from 'expo-router';
import { ReactElement, useCallback, useMemo } from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber } from 'react-intl';
import { View } from 'react-native';
import styles from './styles';

const popAction = StackActions.pop(1);

const VehicleDetailsScreen = (): ReactElement => {
  const { vehicleId } = useLocalSearchParams<{
    vehicleId: Uuid;
  }>();

  const tenantState = useTenantStore((state) => state.tenant);

  const { data: vehicleData, isLoading } = useVehicleByMaterialIdRequest(
    {
      materialId: vehicleId || '',
    },
    { enabled: !!vehicleId },
  );

  const vehicle = useMemo(() => vehicleData?.data, [vehicleData?.data]);

  const navigation = useNavigation();
  const handleBack = useCallback(() => {
    navigation.dispatch(popAction);
  }, [navigation]);

  if (!vehicle || isLoading) {
    return <LoadingScreen />;
  }

  return (
    <View style={styles.container}>
      <EventPageHeader
        style={styles.pageHeader}
        onBack={handleBack}
        headerLabel={vehicle.name}
      />
      <View style={styles.content}>
        <View style={styles.avatarContainer}>
          <Avatar name={vehicle.name} src={vehicle.pictureUrl} size={181} />
        </View>
        <View style={styles.detailsContainer}>
          <Typography variant="subtitle1" style={styles.title}>
            <FormattedMessage id="label.employeeData" />
          </Typography>
          <View style={styles.row}>
            {tenantState?.address?.country && vehicle.licensePlate && (
              <LicensePlate
                licensePlate={vehicle.licensePlate}
                country={tenantState.address.country}
              />
            )}
          </View>
          <View style={styles.row}>
            {!!vehicle.model && (
              <>
                <Typography style={styles.label}>
                  <FormattedMessage id="label.model" />
                </Typography>
                <Typography style={[styles.data]}>{vehicle.model}</Typography>
              </>
            )}

            {!!vehicle.propulsionType && (
              <>
                <Typography style={styles.label}>
                  <FormattedMessage id="label.propulsionType" />
                </Typography>
                <Typography style={[styles.data]}>
                  <FormattedMessage
                    id={`propulsionTypes.${vehicle.propulsionType}`}
                  />
                </Typography>
              </>
            )}

            {!!vehicle.transmissionType && (
              <>
                <Typography style={styles.label}>
                  <FormattedMessage id="label.transmissionType" />
                </Typography>
                <Typography style={[styles.data]}>
                  <FormattedMessage
                    id={`transmissionTypes.${vehicle.transmissionType}`}
                  />
                </Typography>
              </>
            )}

            {!!vehicle.registeredAt && (
              <>
                <Typography style={styles.label}>
                  <FormattedMessage id="label.registeredAt" />
                </Typography>
                <Typography style={[styles.data]}>
                  <FormattedDate
                    dateStyle="short"
                    value={vehicle.registeredAt}
                  />
                </Typography>
              </>
            )}

            {!!vehicle.purchasedAt && (
              <>
                <Typography style={styles.label}>
                  <FormattedMessage id="label.purchasedAt" />
                </Typography>
                <Typography style={[styles.data]}>
                  <FormattedDate
                    dateStyle="short"
                    value={vehicle.purchasedAt}
                  />
                </Typography>
              </>
            )}

            {!!vehicle.towBar && (
              <>
                <Typography style={styles.label}>
                  <FormattedMessage id="label.hasTowBar" />
                </Typography>
                <Typography style={[styles.data]}>
                  {vehicle.towBar.hasTowBar ? (
                    <FormattedMessage id="label.yes" />
                  ) : (
                    <FormattedMessage id="label.no" />
                  )}
                  &nbsp;
                  {vehicle.towBar.hasTowBar && vehicle.towBar.towWeight && (
                    <>
                      <FormattedNumber value={vehicle.towBar.towWeight} />
                      kg
                    </>
                  )}
                </Typography>
              </>
            )}
            {isMovingLift(vehicle) && (
              <>
                <Typography style={styles.label}>
                  <FormattedMessage id="label.floors" />
                </Typography>
                <Typography style={[styles.data]}>
                  <FormattedMessage
                    id="label.floorWithValue"
                    values={{ floor: vehicle.floors }}
                  />
                </Typography>
                <Typography style={styles.label}>
                  <FormattedMessage id="label.height" />
                </Typography>
                <Typography style={[styles.data]}>{vehicle.height}m</Typography>
              </>
            )}
            {isMovingTruck(vehicle) && (
              <>
                {!!vehicle.cubicMeter && (
                  <>
                    <Typography style={styles.label}>
                      <FormattedMessage id="label.volume" />
                    </Typography>
                    <Typography style={[styles.data]}>
                      {vehicle.cubicMeter}
                      &#13221;
                    </Typography>
                  </>
                )}
                {!!vehicle.length && vehicle.width && vehicle.height && (
                  <>
                    <Typography style={styles.label}>
                      <FormattedMessage id="label.measurements" />
                    </Typography>
                    <Typography style={[styles.data]}>
                      <FormattedMessage
                        id="label.measurementsInCm"
                        values={{
                          length: (vehicle.length || 0) * 100,
                          width: (vehicle.width || 0) * 100,
                          height: (vehicle.height || 0) * 100,
                        }}
                      />
                    </Typography>
                  </>
                )}
                {!!vehicle.tailGateLength && (
                  <>
                    <Typography style={styles.label}>
                      <FormattedMessage id="label.tailGateLength" />
                    </Typography>
                    <Typography style={[styles.data]}>
                      {(vehicle.tailGateLength || 0) * 100}cm
                    </Typography>
                  </>
                )}
              </>
            )}
          </View>
          {!!vehicle.fuelCard && !!vehicle.fuelCard.cardNumber && (
            <View style={styles.row}>
              <Typography style={styles.label}>
                <FormattedMessage id="label.fuelCard" />
              </Typography>
              <Typography style={[styles.data]}>
                {vehicle.fuelCard?.cardNumber}
              </Typography>
              <Typography style={styles.label}>
                <FormattedMessage id="label.pinCode" />
              </Typography>
              <SecretValue style={styles.data}>
                {vehicle.fuelCard?.pinCode}
              </SecretValue>
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

export default VehicleDetailsScreen;
