import { MovingJobFurniture, MovingJobRoom } from '@bas/project-domain/models';
import { RenderMovingJobFurnitureName } from '@bas/project-domain/moving-job/native/molecules';
import { colors } from '@bas/theme';
import { Button } from '@bas/ui/native/atoms';
import { Box, BoxProps, Typography } from '@bas/ui/native/base';
import {
  MobileRoomFurnitureListItem,
  MobileRoomFurnitureListItemProps,
  ReactHookFormNumberTextField,
  RoomListItem,
  RoomListItemProps,
} from '@bas/ui/native/molecules';
import { InternalServiceType, Uuid } from '@bas/value-objects';
import {
  FurnitureInput,
  LoadOrUnloadStorageInputType,
  StoredItemInputType,
} from '@bas/wms-domain/input-types';
import { Storage } from '@bas/wms-domain/models';
import { usePublicMovingBoxesRequest } from '@bas/wms-domain/requests';
import { faBoxesStacked } from '@fortawesome/pro-light-svg-icons/faBoxesStacked';
import { faContainerStorage } from '@fortawesome/pro-light-svg-icons/faContainerStorage';
import { FlashList, ListRenderItem } from '@shopify/flash-list';
import { ReactElement, ReactNode, useCallback, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import styles from './styles';

export type RoomsAndFurnitureListItemType =
  | (MovingJobRoom & { itemId: string; dataType: 'room' })
  | (MovingJobFurniture & {
      index: number;
      itemId: string;
      dataType: 'furniture';
    })
  | {
      index: number;
      label: ReactNode;
      itemId: string;
      dataType: 'inventoryItem';
    }
  | {
      index: number;
      label: string;
      itemId: string;
      storageId: Uuid;
      dataType: 'extraStoredItem';
      item: StoredItemInputType;
    }
  | {
      itemId: string;
      dataType:
        | 'separator'
        | 'movingBoxesHeader'
        | 'extraStoredItemsHeader'
        | 'extraStoredItemsAdd';
    };

export type RoomsAndFurnitureListProps = {
  rooms: MovingJobRoom[];
  serviceType?: InternalServiceType;
  onCheckFurniture?: (furniture: MovingJobFurniture, newValue: boolean) => void;
  onAddExtraStoredItem?: () => void;
  onCheckExtraItem?: (
    item: StoredItemInputType,
    index: number,
    newValue: boolean,
  ) => void;
  extraItemProps?: (
    item: RoomsAndFurnitureListItemType,
  ) => Partial<
    | MobileRoomFurnitureListItemProps
    | RoomListItemProps
    | BoxProps
    | { disabled?: boolean }
  >;
  isUnloadingOrLoading?: boolean;
  storages?: Storage[];
  extraStoredItems?: StoredItemInputType[];
};

const RenderItem = ({
  item,
  onCheckFurniture,
  onCheckExtraItem,
  onAddExtraStoredItem,
  extraItemProps,
  isUnloadingOrLoading,
  serviceType,
  activeStorageId,
  storageIndex,
  furniture,
}: {
  item: RoomsAndFurnitureListItemType;
  onCheckFurniture?: (furniture: MovingJobFurniture, newValue: boolean) => void;
  onAddExtraStoredItem?: () => void;
  onCheckExtraItem?: (
    item: StoredItemInputType,
    index: number,
    newValue: boolean,
  ) => void;
  extraItemProps?: (
    item: RoomsAndFurnitureListItemType,
  ) => Partial<
    | MobileRoomFurnitureListItemProps
    | RoomListItemProps
    | BoxProps
    | { disabled?: boolean }
  >;
  isUnloadingOrLoading?: boolean;
  serviceType?: InternalServiceType;
  activeStorageId?: Uuid | null;
  storageIndex?: number;
  furniture?: {
    [key: string]: FurnitureInput;
  };
}): ReactElement | null => {
  const extraProps = extraItemProps?.(item) || {};
  if (item.dataType === 'room') {
    return (
      <RoomListItem
        {...extraProps}
        backgroundColor="darkText"
        room={item}
        color={colors.white}
      />
    );
  }

  if (item.dataType === 'separator') {
    return <Box {...extraProps} paddingTop={3} />;
  }

  if (item.dataType === 'inventoryItem') {
    if (isUnloadingOrLoading) {
      return (
        <View style={styles.row}>
          <View style={styles.inputContainer}>
            <ReactHookFormNumberTextField
              key={`${storageIndex}.${item.index}.${activeStorageId}`}
              name={`storages[${storageIndex}].boxes[${item.index}].quantity`}
              disabled={!activeStorageId}
              style={styles.input}
              variant="outlined"
              keyboardType="decimal-pad"
              shouldBeZero
            />
          </View>
          <Typography
            variant="h5"
            fontWeight={700}
            overrideColor={colors.white}
            style={styles.label}
          >
            {item.label}
          </Typography>
        </View>
      );
    }

    return (
      <MobileRoomFurnitureListItem
        {...extraProps}
        name={
          <Typography overrideColor={colors.white} variant="h5">
            {item.label}
          </Typography>
        }
        services={[]}
      />
    );
  }

  if (item.dataType === 'movingBoxesHeader') {
    return (
      <RoomListItem
        {...extraProps}
        backgroundColor="darkText"
        room={{
          name: <FormattedMessage id="label.movingBoxes" />,
          roomType: {
            icon: faBoxesStacked,
            roomTypeName: 'movingBoxes',
            translatedNames: [],
          },
        }}
        color={colors.white}
      />
    );
  }

  if (item.dataType === 'extraStoredItemsHeader') {
    return (
      <RoomListItem
        {...extraProps}
        backgroundColor="darkText"
        room={{
          name: <FormattedMessage id="label.extraStoredItems" />,
          roomType: {
            icon: faContainerStorage,
            roomTypeName: 'extraStoredItems',
            translatedNames: [],
          },
        }}
        color={colors.white}
      />
    );
  }

  if (item.dataType === 'extraStoredItem') {
    return (
      <MobileRoomFurnitureListItem
        {...extraProps}
        name={
          <Typography overrideColor={colors.white} variant="h5">
            {item.label}
          </Typography>
        }
        hasCheckbox={!!onCheckExtraItem}
        onCheck={(newValue) =>
          onCheckExtraItem?.(item.item, item.index, newValue)
        }
        checked={!!item.item.loadedOn && !item.item.unloadedOn}
        disabled={item.storageId !== activeStorageId}
        services={[
          {
            serviceType: InternalServiceType.STORAGE,
            requested: true,
          },
        ]}
      />
    );
  }

  if (item.dataType === 'extraStoredItemsAdd') {
    return (
      <Button
        variant="outlined"
        disabled={!onAddExtraStoredItem || !activeStorageId}
        onPress={onAddExtraStoredItem}
      >
        <FormattedMessage id="label.addExtraStoredItem" />
      </Button>
    );
  }

  if (item.dataType === 'furniture') {
    let checked = false;
    let disabled = false;
    if (isUnloadingOrLoading && furniture) {
      const value = furniture[item.furnitureId];
      disabled =
        !activeStorageId ||
        (!!value && !!value?.storageId && value?.storageId !== activeStorageId);
      checked = !!value?.storageId;
    } else {
      checked = !!item.finishedServices?.find(
        (service) => service.internalType === serviceType,
      );

      disabled = !item.services?.find(
        (service) => service.internalType === serviceType,
      );
    }

    return (
      <MobileRoomFurnitureListItem
        {...extraProps}
        name={
          <RenderMovingJobFurnitureName
            furniture={item}
            overrideColor={colors.white}
            variant="h5"
          />
        }
        hasCheckbox={!!onCheckFurniture}
        checked={checked}
        disabled={disabled}
        onCheck={(newValue) => onCheckFurniture?.(item, newValue)}
        services={(isUnloadingOrLoading
          ? [InternalServiceType.STORAGE]
          : [InternalServiceType.ASSEMBLE, InternalServiceType.DISASSEMBLE]
        ).map((internalType) => {
          const requested = !!item.services.find(
            (service) => service.internalType === internalType,
          );

          return {
            serviceType: internalType,
            requested,
          };
        })}
      />
    );
  }

  return null;
};

const RenderItemWithStorage = ({
  storages,
  ...props
}: {
  item: RoomsAndFurnitureListItemType;
  onCheckFurniture?: (furniture: MovingJobFurniture, newValue: boolean) => void;
  onAddExtraStoredItem?: () => void;
  onCheckExtraItem?: (
    item: StoredItemInputType,
    index: number,
    newValue: boolean,
  ) => void;
  extraItemProps?: (
    item: RoomsAndFurnitureListItemType,
  ) => Partial<
    | MobileRoomFurnitureListItemProps
    | RoomListItemProps
    | BoxProps
    | { disabled?: boolean }
  >;
  isUnloadingOrLoading?: boolean;
  storages?: Storage[];
  serviceType?: InternalServiceType;
}): ReactElement | null => {
  const activeStorageId = useWatch<
    LoadOrUnloadStorageInputType,
    'activeStorageId'
  >({
    name: 'activeStorageId',
  });

  const furniture = useWatch<LoadOrUnloadStorageInputType, 'furniture'>({
    name: 'furniture',
  });

  const storageIndex = useMemo(
    () =>
      storages &&
      storages.findIndex((storage) => storage.storageId === activeStorageId),
    [activeStorageId, storages],
  );

  return (
    <RenderItem
      {...props}
      activeStorageId={activeStorageId}
      storageIndex={storageIndex}
      furniture={furniture}
    />
  );
};

const RoomsAndFurnitureList = ({
  rooms,
  serviceType,
  onCheckFurniture,
  onCheckExtraItem,
  onAddExtraStoredItem,
  extraItemProps,
  isUnloadingOrLoading,
  extraStoredItems,
  storages,
}: RoomsAndFurnitureListProps): ReactElement => {
  const { data: boxesData } = usePublicMovingBoxesRequest();

  const boxes = useMemo(() => boxesData?.data?.member || [], [boxesData]);

  const data = useMemo(() => {
    const result: RoomsAndFurnitureListItemType[] = [];

    rooms.forEach((room) => {
      result.push({ ...room, itemId: room.roomId, dataType: 'room' });
      if (!isUnloadingOrLoading) {
        result.push(
          ...room.inventoryItems.map((item, index) => ({
            itemId: item.inventoryItemId,
            index,
            dataType: 'inventoryItem' as const,
            label: (
              <>
                {`${item.quantity}x `}
                {
                  boxes.find(
                    ({ inventoryItemId }) =>
                      inventoryItemId === item.inventoryItemId,
                  )?.name
                }
              </>
            ),
          })),
        );
      }

      result.push(
        ...room.furniture.map((furniture, index) => ({
          itemId: furniture.furnitureId,
          index,
          dataType: 'furniture' as const,
          ...furniture,
        })),
      );

      result.push({
        itemId: `separator-${room.roomId}`,
        dataType: 'separator',
      });
    });

    if (isUnloadingOrLoading) {
      result.push({
        itemId: 'extraStoredItems',
        dataType: 'extraStoredItemsHeader',
      });

      result.push(
        ...(extraStoredItems || []).map((item, index) => ({
          itemId: item.itemId,
          index,
          dataType: 'extraStoredItem' as const,
          label: item.description,
          item,
          storageId: item.storageId as string,
        })),
      );

      result.push({
        itemId: 'extraStoredItemsAdd',
        dataType: 'extraStoredItemsAdd',
      });

      result.push({
        itemId: `separator-extraStoredItems`,
        dataType: 'separator',
      });

      result.push({
        itemId: 'movingBoxes',
        dataType: 'movingBoxesHeader',
      });

      result.push(
        ...boxes.map((item, index) => ({
          itemId: item.inventoryItemId,
          index,
          dataType: 'inventoryItem' as const,
          label: item.name,
        })),
      );

      return result;
    }

    return result;
  }, [boxes, extraStoredItems, isUnloadingOrLoading, rooms]);

  const handleRenderItem: ListRenderItem<RoomsAndFurnitureListItemType> =
    useCallback(
      ({ item }) => {
        if (isUnloadingOrLoading) {
          return (
            <RenderItemWithStorage
              item={item}
              onCheckFurniture={onCheckFurniture}
              onCheckExtraItem={onCheckExtraItem}
              onAddExtraStoredItem={onAddExtraStoredItem}
              extraItemProps={extraItemProps}
              isUnloadingOrLoading={isUnloadingOrLoading}
              storages={storages}
              serviceType={serviceType}
            />
          );
        }

        return (
          <RenderItem
            item={item}
            onCheckFurniture={onCheckFurniture}
            onCheckExtraItem={onCheckExtraItem}
            onAddExtraStoredItem={onAddExtraStoredItem}
            extraItemProps={extraItemProps}
            isUnloadingOrLoading={isUnloadingOrLoading}
            serviceType={serviceType}
          />
        );
      },
      [
        extraItemProps,
        onAddExtraStoredItem,
        isUnloadingOrLoading,
        storages,
        onCheckFurniture,
        onCheckExtraItem,
        serviceType,
      ],
    );

  const stickyHeaderIndices = useMemo(
    () =>
      data
        .map((item, index) => ({ ...item, index }))
        .filter((item) =>
          ['room', 'extraStoredItemsHeader', 'movingBoxesHeader'].includes(
            item.dataType,
          ),
        )
        .map((item) => item.index),
    [data],
  );

  const handleKeyExtractor = useCallback(
    (item: RoomsAndFurnitureListItemType) => item.itemId,
    [],
  );

  return (
    <FlashList
      data={data}
      renderItem={handleRenderItem}
      stickyHeaderIndices={stickyHeaderIndices}
      renderScrollComponent={ScrollView}
      keyExtractor={handleKeyExtractor}
      estimatedItemSize={48}
    />
  );
};

export default RoomsAndFurnitureList;
