import { formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type UpdateNewsMutationProps = {
  newsId: Uuid;
  title: string;
  body: string;
  publishedAt?: Date | null;
};

export const UpdateNewsMutation = async ({
  newsId,
  publishedAt,
  ...values
}: UpdateNewsMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/news/${newsId}/update`, {
    newsId,
    publishedAt: publishedAt ? formatDateTime(publishedAt) : null,
    ...values,
  });

export const useUpdateNewsMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateNewsMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  UpdateNewsMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateNewsMutationProps
  >({
    mutationFn: UpdateNewsMutation,
    throwOnError: false,
    ...options,
  });
