import { PassKey } from '@bas/authentication-domain/models';
import { QueryOptionsWithKey } from '@bas/shared/requests';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

type Response = AxiosResponse<Collection<PassKey>>;
type QueryKeyType = QueryKey & {
  [0]: 'passkeys';
  [1]: 'list';
};

export const PassKeysRequest = async (): Promise<Response> =>
  axios.get('/api/passkeys');

export const usePassKeysRequest = (
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response,
    QueryKeyType
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response, QueryKeyType>({
    queryFn: async () => PassKeysRequest(),
    queryKey: ['passkeys', 'list'],
    ...options,
  });
