import { isTask } from './Task';
import type { SendingInvoiceFailedTask } from '../types';
import { TaskType } from '../types';

export function isSendingInvoiceFailedTask(
  object: unknown,
): object is SendingInvoiceFailedTask {
  return (
    !!object &&
    isTask(object) &&
    object.taskType === TaskType.SENDING_INVOICE_FAILED_TASK
  );
}
