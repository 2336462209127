import { formatDate } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type EnableOrDisableClaAllowanceRuleMutationProps = {
  ruleId: Uuid;
  enabledFrom: Date;
  enabledUntil?: Date;
};

export const EnableOrDisableClaAllowanceRuleMutation = async ({
  enabledFrom,
  enabledUntil,
  ruleId,
  ...values
}: EnableOrDisableClaAllowanceRuleMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/hrm/cla/allowance-rules/${ruleId}/update-enabled-from-or-until-dates`,
    {
      ...values,
      enabledFrom: formatDate(enabledFrom),
      enabledUntil: enabledUntil ? formatDate(enabledUntil) : undefined,
    },
  );

export const useEnableOrDisableClaAllowanceRuleMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    EnableOrDisableClaAllowanceRuleMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  EnableOrDisableClaAllowanceRuleMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    EnableOrDisableClaAllowanceRuleMutationProps
  >({
    mutationFn: EnableOrDisableClaAllowanceRuleMutation,
    throwOnError: false,
    ...options,
  });
