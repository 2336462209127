import * as Yup from 'yup';

export type SendQuoteStepInputType = {
  shouldSendQuote: boolean;
  customerSignsAtTheTable: boolean;
};

export const SendQuoteStepInputTypeValidatorBuilder = (): Yup.ObjectSchema<{
  customerSignsAtTheTable: boolean;
  shouldSendQuote?: boolean | null;
}> =>
  Yup.object({
    customerSignsAtTheTable: Yup.boolean()
      .label('label.customerSignsAtTheTable')
      .required(),
    shouldSendQuote: Yup.boolean()
      .label('label.shouldSendQuote')
      .when('customerSignsAtTheTable', {
        is: true,
        then: (schema) => schema.nullable(),
        otherwise: (schema) => schema.required(),
      }),
  });
