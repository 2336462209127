import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type CreateAutomatedEmailMutationProps = {
  automatedEmailId: Uuid;
  basedUponAutomatedEmailId: Uuid;
  name: string;
};

export const CreateAutomatedEmailMutation = async ({
  ...values
}: CreateAutomatedEmailMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/{tenantId}/communication/automated-emails`, {
    ...values,
  });

export const useCreateAutomatedEmailMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    CreateAutomatedEmailMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  CreateAutomatedEmailMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    CreateAutomatedEmailMutationProps
  >({
    mutationFn: CreateAutomatedEmailMutation,
    throwOnError: false,
    ...options,
  });
