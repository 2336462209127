import {
  PasswordInputType,
  PasswordInputTypeDefaultValues,
  PasswordInputTypeValidationBuilder,
} from '@bas/authentication-domain/input-types';
import { colors } from '@bas/theme';
import { BottomSheetMethods, Box, Typography } from '@bas/ui/native/base';
import { ReactHookFormTextField } from '@bas/ui/native/molecules';
import { BackendErrors, BottomSheetProps } from '@bas/ui/native/organisms';
import { FormBottomSheet } from '@bas/ui/native/templates';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { ReactElement, RefObject, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { ensuredForwardRef } from 'react-use/lib/useEnsuredForwardedRef';

export type ChangePasswordBottomSheetProps = Omit<
  BottomSheetProps,
  'snapPoints' | 'children'
> & {
  onSubmit: (values: PasswordInputType) => Promise<void> | void;
};

const styles = StyleSheet.create({
  flexContainer: {
    flex: 1,
  },
  container: {
    marginBottom: 15,
    paddingLeft: 20,
    paddingRight: 20,
  },
  description: {
    textAlign: 'center',
  },
  avatar: {
    height: '100%',
    width: '100%',
  },
  niceJob: {
    textAlign: 'center',
  },
  avatarContainer: {
    overflow: 'hidden',
    borderRadius: 269,
    height: 268,
    width: 268,
    marginBottom: 29,
    marginTop: 29,
    alignSelf: 'center',
  },
  header: {
    alignSelf: 'center',
    fontSize: 22,
    lineHeight: 29,
    height: 51,
  },
  paddingTop: {
    paddingTop: 24,
  },
  padding: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 31,
    paddingTop: 31,
  },
});

const ChangePasswordBottomSheet = ensuredForwardRef<
  BottomSheetModal & BottomSheetMethods,
  ChangePasswordBottomSheetProps
>(
  (
    { onSubmit, ...props }: ChangePasswordBottomSheetProps,
    ref: RefObject<BottomSheetModal & BottomSheetMethods>,
  ): ReactElement => {
    const handleFinalize = useCallback(() => {
      ref?.current?.close();
      ref?.current?.forceClose();
      ref?.current?.dismiss();
    }, [ref]);

    const { formatMessage } = useIntl();
    return (
      <FormBottomSheet<PasswordInputType>
        bottomSheetRef={ref}
        disablePadding
        onSubmit={onSubmit}
        useProvider
        resetFormOnOpen
        onCancel={handleFinalize}
        defaultValues={{
          ...PasswordInputTypeDefaultValues(),
        }}
        hideBackendErrors
        validationSchema={PasswordInputTypeValidationBuilder}
        successFullFormViewProps={{
          gifUrl: 'https://media.giphy.com/media/pdSncNyYgaH0wqaCqp/giphy.gif',

          title: 'label.changePassword.finished',
          color: colors.lila[100],
          labelId: 'label.passwordIsChanged',
        }}
        {...props}
      >
        <View style={styles.flexContainer}>
          <Typography
            fontWeight={700}
            overrideColor={colors.white}
            style={styles.header}
            textAlign="center"
          >
            <FormattedMessage id="label.changePassword" />
          </Typography>
          <View>
            <View style={styles.container}>
              <Box rowGap={3} flex={1}>
                <BackendErrors />
                <Box>
                  <ReactHookFormTextField
                    name="currentPassword"
                    autoComplete="off"
                    secureTextEntry
                    textContentType="password"
                    autoCapitalize="none"
                    light
                    label={formatMessage({
                      id: 'label.currentPassword',
                    })}
                  />
                </Box>
                <Box>
                  <ReactHookFormTextField
                    name="newPassword"
                    autoComplete="off"
                    secureTextEntry
                    textContentType="password"
                    autoCapitalize="none"
                    light
                    label={formatMessage({ id: 'label.newPassword' })}
                  />
                </Box>
                <Box>
                  <ReactHookFormTextField
                    name="repeatPassword"
                    autoComplete="off"
                    secureTextEntry
                    textContentType="password"
                    autoCapitalize="none"
                    light
                    label={formatMessage({
                      id: 'label.repeatPassword',
                    })}
                  />
                </Box>
              </Box>
            </View>
          </View>
        </View>
      </FormBottomSheet>
    );
  },
);
export default ChangePasswordBottomSheet;
