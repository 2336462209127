import { formatDate, formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, EventType, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import dayjs from 'dayjs';

export type RescheduleEventAndChangeTypeAndDetailsMutationProps = {
  eventId: Uuid;
  eventType: EventType;
  start?: Date | null;
  end?: Date | null;
  travelStart?: Date | null;
  travelEnd?: Date | null;
  notes?: string | null;
  relationId?: Uuid | null;
  name?: string | null;
  description?: string | null;
  fullDay?: boolean | null;
  date?: Date | null;
  updateFutureEvents: boolean;
  recurringInformation?: {
    isRecurring: boolean;
    rrule: string;
    repeatFrom: Date;
    repeatUntil: Date;
  };
};

export const RescheduleEventAndChangeTypeAndDetailsMutation = async ({
  eventId,
  start,
  end,
  fullDay,
  travelStart,
  travelEnd,
  date,
  recurringInformation,
  ...values
}: RescheduleEventAndChangeTypeAndDetailsMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/events/${eventId}/reschedule-and-change-details`, {
    eventId,
    fullDay,
    start: start
      ? formatDateTime(fullDay ? dayjs(start).startOf('day') : start)
      : null,
    end: end ? formatDateTime(fullDay ? dayjs(end).endOf('day') : end) : null,
    travelStart: travelStart && !fullDay ? formatDateTime(travelStart) : null,
    travelEnd: travelEnd && !fullDay ? formatDateTime(travelEnd) : null,
    date: date ? formatDate(date) : undefined,
    recurringInformation: recurringInformation
      ? {
          ...recurringInformation,
          repeatFrom: formatDate(recurringInformation.repeatFrom),
          repeatUntil: formatDate(recurringInformation.repeatUntil),
        }
      : null,
    ...values,
  });

export const useRescheduleEventAndChangeTypeAndDetailsMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RescheduleEventAndChangeTypeAndDetailsMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  RescheduleEventAndChangeTypeAndDetailsMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RescheduleEventAndChangeTypeAndDetailsMutationProps
  >({
    mutationFn: RescheduleEventAndChangeTypeAndDetailsMutation,
    throwOnError: false,
    ...options,
  });
