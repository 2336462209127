import { Box } from '@bas/ui/native/base';
import {
  ReactElement,
  Ref,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Image } from 'react-native';
import SignaturePad from 'react-signature-pad-wrapper';

export type SignatureRef = { clearSignature: () => void };

export type SignatureProps = {
  onChange: (signature: string) => void;
  signatureRef: Ref<SignatureRef>;
  existingSignature?: string;
  enabled?: boolean;
};
const Signature = ({
  onChange,
  existingSignature,
  signatureRef,
  enabled,
}: SignatureProps): ReactElement => {
  const ref = useRef<SignaturePad | null>(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!ref.current || loaded || !existingSignature) {
      return;
    }

    ref.current?.fromDataURL(existingSignature);
    setLoaded(true);
  }, [ref, existingSignature, loaded]);

  useImperativeHandle(
    signatureRef,
    () => ({
      clearSignature: () => {
        ref.current?.clear();
      },
    }),
    [],
  );

  useEffect(() => {
    if (!ref?.current?.instance) {
      return;
    }

    ref.current.instance.addEventListener('endStroke', () => {
      onChange(ref.current?.toDataURL() || '');
    });
  }, [onChange, ref]);

  return useMemo(
    () =>
      enabled ? (
        <Box
          mt={2}
          backgroundColor="white"
          borderRadius={5}
          borderWidth={1}
          borderColor="titleBorder"
        >
          <SignaturePad ref={ref} height={200} redrawOnResize />
        </Box>
      ) : (
        <Box
          height={200}
          width="100%"
          backgroundColor="cardBorder"
          borderRadius={5}
          borderWidth={1}
          borderColor="titleBorder"
        >
          {existingSignature && (
            <Image
              height={200}
              style={{
                height: 200,
              }}
              source={{
                uri: existingSignature,
              }}
            />
          )}
        </Box>
      ),
    [enabled, existingSignature],
  );
};

export default Signature;
