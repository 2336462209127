import { isObjectResponse } from '@bas/value-objects';
import type { MovingJobStandardFurniture } from '../types';

export function isMovingJobStandardFurniture(
  object: unknown,
): object is MovingJobStandardFurniture {
  return (
    isObjectResponse(object) &&
    ['FurnitureDto', 'Furniture'].includes(object['@type'])
  );
}
