import { CrmAddressType } from '@bas/crm-domain/models';
import { Country, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type UpdateAddressOfRelationMutationProps = {
  relationId: Uuid;
  addressId: Uuid;
  addressType: CrmAddressType;
  streetName: string;
  houseNumber: string;
  houseNumberAddition?: string | null;
  zipCode: string;
  city: string;
  country: Country;
};

export const UpdateAddressOfRelationMutation = async ({
  relationId,
  addressId,
  ...values
}: UpdateAddressOfRelationMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/relations/${relationId}/addresses/${addressId}`, {
    relationId,
    addressId,
    ...values,
  });

export const useUpdateAddressOfRelationMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateAddressOfRelationMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  UpdateAddressOfRelationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    UpdateAddressOfRelationMutationProps
  >({
    mutationFn: UpdateAddressOfRelationMutation,
    throwOnError: false,
    ...options,
  });
