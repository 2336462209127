import { colors } from '@bas/theme';
import {
  AnimatedView,
  Box,
  IconWithSpin,
  Pressable,
  TouchableOpacity,
  Typography,
} from '@bas/ui/native/base';
import { Steps, StepsProps } from '@bas/ui/native/molecules';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons/faSpinnerThird';
import { faPaperPlane } from '@fortawesome/pro-light-svg-icons/faPaperPlane';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faCircleXmark } from '@fortawesome/pro-regular-svg-icons/faCircleXmark';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { memo, ReactElement, useCallback, useMemo } from 'react';
import isEqual from 'react-fast-compare';
import { View, ViewProps } from 'react-native';
import { FadeIn, FadeOut } from 'react-native-reanimated';
import { PressableStateCallbackType } from 'react-native/Libraries/Components/Pressable/Pressable';
import styles from './style';

export type StepsNavigationProps = ViewProps &
  StepsProps & {
    dark?: boolean;
    label?: ReactElement | string;
    onCancel?: () => void;
    onClose?: () => void;
    onNext: () => void;
    onValidate: () => void;
    onPrevious: () => void;
    onPressSteps?: () => void;
    allowPrevious?: boolean;
    allowCancel?: boolean;
    allowNext?: boolean;
    loading?: boolean;
    finalize?: boolean;
    isSubmit?: boolean;
    hideSubmit?: boolean;
  };

const StepsNavigation = ({
  steps,
  dark = false,
  label,
  onValidate,
  onNext,
  onPrevious,
  onPressSteps,
  allowPrevious = true,
  allowNext = true,
  allowCancel,
  isSubmit,
  loading,
  onCancel,
  onClose,
  finalize,
  style,
  hideSubmit,
  ...props
}: StepsNavigationProps): ReactElement => {
  const previousButtonColor = useMemo(() => {
    if (dark) {
      return colors.lila[allowPrevious ? 400 : 600];
    }

    return colors.lila[allowPrevious ? 600 : 400];
  }, [dark, allowPrevious]);

  const nextButtonColor = useMemo(() => {
    if (dark) {
      return colors.lila[allowNext ? 400 : 600];
    }

    return colors.lila[allowNext ? 600 : 400];
  }, [dark, allowNext]);

  const buttonStyle = useCallback(({ pressed }: PressableStateCallbackType) => {
    if (!pressed) {
      return styles.button;
    }

    return [styles.button, styles.buttonPressed];
  }, []);

  const pressedColor = useMemo(() => {
    if (dark) {
      return colors.white;
    }

    return colors.blue[500];
  }, [dark]);

  return (
    <View
      style={[styles.container, dark ? styles.dark : styles.light, style]}
      {...props}
    >
      <Box flexDirection="row" justifyContent="space-between">
        {allowCancel && !allowPrevious && (
          <AnimatedView entering={FadeIn} exiting={FadeOut}>
            <Pressable onPress={onCancel} style={buttonStyle}>
              {({ pressed }) => (
                <FontAwesomeIcon
                  color={pressed ? pressedColor : colors.lila[dark ? 400 : 600]}
                  size={23}
                  icon={faCircleXmark}
                />
              )}
            </Pressable>
          </AnimatedView>
        )}
        {(!allowCancel || allowPrevious) && (
          <AnimatedView entering={FadeIn} exiting={FadeOut}>
            <Pressable
              onPress={allowPrevious ? onPrevious : undefined}
              style={buttonStyle}
            >
              {({ pressed }) => (
                <FontAwesomeIcon
                  color={pressed ? pressedColor : previousButtonColor}
                  size={23}
                  icon={faChevronLeft}
                />
              )}
            </Pressable>
          </AnimatedView>
        )}
        <TouchableOpacity onPress={onPressSteps} style={styles.steps}>
          <Box flex={1} flexDirection="column" alignItems="center">
            {label && (
              <View style={styles.label}>
                <Typography
                  textAlign="center"
                  overrideColor={dark ? colors.lila[200] : colors.lila[800]}
                >
                  {label}
                </Typography>
              </View>
            )}
            <Steps steps={steps} />
          </Box>
        </TouchableOpacity>
        {!hideSubmit && !isSubmit && (
          <AnimatedView entering={FadeIn} exiting={FadeOut}>
            <Pressable
              onPress={allowNext ? onNext : onValidate}
              style={buttonStyle}
            >
              {({ pressed }) => (
                <FontAwesomeIcon
                  color={pressed ? pressedColor : nextButtonColor}
                  size={23}
                  icon={faChevronRight}
                />
              )}
            </Pressable>
          </AnimatedView>
        )}
        {!hideSubmit && isSubmit && !finalize && (
          <AnimatedView entering={FadeIn} exiting={FadeOut}>
            <Pressable
              disabled={loading}
              onPress={allowNext ? onNext : onValidate}
              style={buttonStyle}
            >
              {({ pressed }) =>
                loading ? (
                  <IconWithSpin
                    size={18}
                    icon={faSpinnerThird}
                    overrideColor={nextButtonColor}
                    secondaryColor={pressed ? pressedColor : colors.blue[800]}
                  />
                ) : (
                  <FontAwesomeIcon
                    color={pressed ? pressedColor : nextButtonColor}
                    size={23}
                    icon={faPaperPlane}
                  />
                )
              }
            </Pressable>
          </AnimatedView>
        )}
        {!hideSubmit && isSubmit && finalize && (
          <AnimatedView entering={FadeIn} exiting={FadeOut}>
            <Pressable disabled={loading} onPress={onClose} style={buttonStyle}>
              {({ pressed }) => (
                <FontAwesomeIcon
                  color={pressed ? pressedColor : nextButtonColor}
                  size={23}
                  icon={faCheck}
                />
              )}
            </Pressable>
          </AnimatedView>
        )}
      </Box>
    </View>
  );
};

const MemoComponent = memo(StepsNavigation, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps),
);

export default MemoComponent;
