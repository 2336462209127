import { ErrorResponse } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type GenerateMetabaseJwtTokenMutationProps = {
  overrideTenantId?: string | null;
  parameters?: Record<string, string | number | boolean>;
  dashboardId?: string | null;
};

export const GenerateMetabaseJwtTokenMutation = async ({
  ...values
}: GenerateMetabaseJwtTokenMutationProps): Promise<
  AxiosResponse<{
    jwtToken: string;
  }>
> =>
  axios.put(`api/{tenantId}/generate-metabase-jwt-token`, {
    ...values,
  });

export const useGenerateMetabaseJwtTokenMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    GenerateMetabaseJwtTokenMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  GenerateMetabaseJwtTokenMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    GenerateMetabaseJwtTokenMutationProps
  >({
    mutationFn: GenerateMetabaseJwtTokenMutation,
    throwOnError: false,
    ...options,
  });
