import {
  TouchableHighlight as BaseTouchableHighlight,
  TouchableHighlightProps as BaseTouchableHighlightProps,
} from 'react-native';
import { cloneElement, ReactElement } from 'react';
import { colors } from '@bas/theme';
import styles from './styles';

export type TouchableHighlightProps = Omit<
  BaseTouchableHighlightProps,
  'children'
> & {
  children?: ReactElement;
};

const TouchableHighlight = ({
  children,
  ...props
}: TouchableHighlightProps): ReactElement => (
  <BaseTouchableHighlight
    activeOpacity={0.5}
    underlayColor={colors.blue[500]}
    {...props}
  >
    {children &&
      cloneElement(children, {
        style: [styles.clickable, children.props.style],
      })}
  </BaseTouchableHighlight>
);

export default TouchableHighlight;
