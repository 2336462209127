import { Step, StepProps } from '@bas/ui/native/atoms';
import { FunctionComponent, memo } from 'react';
import isEqual from 'react-fast-compare';
import { View } from 'react-native';
import styles from './style';

export type StepsProps = {
  steps: StepProps[];
};

const Steps: FunctionComponent<StepsProps> = ({ steps }: StepsProps) => (
  <View style={styles.container}>
    {steps.map((step, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <View style={styles.step} key={index}>
        <Step {...step} />
      </View>
    ))}
  </View>
);

const MemoComponent = memo(Steps, (prevProps, nextProps) =>
  isEqual(prevProps.steps, nextProps.steps),
);
export default MemoComponent;
