import {
  EnterHoursInputType,
  PeopleWithDifferentHoursInputType,
} from '@bas/planning-domain/input-types';
import { Event } from '@bas/planning-domain/models';
import { colors } from '@bas/theme';
import { BlueSelectButton } from '@bas/ui/native/atoms';
import { Box, Typography } from '@bas/ui/native/base';
import {
  BlueSelectButtonsGroup,
  ReactHookFormDropdown,
} from '@bas/ui/native/molecules';
import { Uuid } from '@bas/value-objects';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import dayjs from 'dayjs';
import * as React from 'react';
import { ReactElement, useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { ScrollView } from 'react-native-gesture-handler';
import { PeopleWithDifferentHoursForm } from '../PeopleWithDifferentHoursForm';
import styles from './styles';

export type WereTherePeopleWithDifferentHoursStepProps = {
  event: Event;
};

const WereTherePeopleWithDifferentHoursStep = ({
  event,
}: WereTherePeopleWithDifferentHoursStepProps): ReactElement => {
  const { formatMessage } = useIntl();
  const { setValue } = useFormContext();
  const areTherePeopleWithDifferentHours = useWatch<
    PeopleWithDifferentHoursInputType,
    'areTherePeopleWithDifferentHours'
  >({
    name: 'areTherePeopleWithDifferentHours',
  });

  const peopleWithDifferentHours = useWatch<
    PeopleWithDifferentHoursInputType,
    'peopleWithDifferentHours'
  >({
    name: 'peopleWithDifferentHours',
  });

  const [overriddenStartDate, overriddenEndDate, breakTime] = useWatch<
    EnterHoursInputType,
    ['startDate', 'endDate', 'breakTime']
  >({
    name: ['startDate', 'endDate', 'breakTime'],
  });

  const handleChangePeople = useCallback(
    (value: Uuid[]) => {
      if (Array.isArray(value)) {
        setValue('peopleWithDifferentHoursIds', value);
        setValue(
          'peopleWithDifferentHours',
          value.map((employeeId) => {
            if (!employeeId) {
              return {};
            }

            const plannedEmployee = event.employees.find(
              ({ employee: { employeeId: plannedEmployeeId } }) =>
                plannedEmployeeId === employeeId,
            );

            let employeeStart = event.start;
            let employeeEnd = event.end;
            if (plannedEmployee?.deviantStart) {
              employeeStart = plannedEmployee?.deviantStart;
            } else if (
              areTherePeopleWithDifferentHours &&
              overriddenStartDate
            ) {
              employeeStart = overriddenStartDate;
            }
            if (plannedEmployee?.deviantEnd) {
              employeeEnd = plannedEmployee?.deviantEnd;
            } else if (areTherePeopleWithDifferentHours && overriddenEndDate) {
              employeeEnd = overriddenEndDate;
            }

            return {
              employeeId,
              startDate: dayjs(employeeStart).toDate(),
              endDate: dayjs(employeeEnd).toDate(),
              reason: '',
              label: plannedEmployee?.employee?.personName?.fullName || '',
              breakTime,
            };
          }),
        );
      }
    },
    [
      breakTime,
      event.employees,
      event.end,
      event.start,
      overriddenEndDate,
      overriddenStartDate,
      areTherePeopleWithDifferentHours,
      setValue,
    ],
  );

  return (
    <ScrollView>
      <Box gap={3}>
        <Box paddingHorizontal={20}>
          <BlueSelectButtonsGroup
            buttons={[
              <BlueSelectButton
                notSelected={
                  areTherePeopleWithDifferentHours !== undefined &&
                  areTherePeopleWithDifferentHours !== true
                }
                onSelect={() =>
                  setValue('areTherePeopleWithDifferentHours', true, {
                    shouldDirty: true,
                    shouldTouch: true,
                    shouldValidate: true,
                  })
                }
                icon={faCheckCircle}
              >
                <FormattedMessage id="label.yes" />
              </BlueSelectButton>,
              <BlueSelectButton
                notSelected={
                  areTherePeopleWithDifferentHours !== undefined &&
                  areTherePeopleWithDifferentHours !== false
                }
                onSelect={() =>
                  setValue('areTherePeopleWithDifferentHours', false, {
                    shouldDirty: true,
                    shouldTouch: true,
                    shouldValidate: true,
                  })
                }
                icon={faTimes}
              >
                <FormattedMessage id="label.no" />
              </BlueSelectButton>,
            ]}
          />
        </Box>
        {areTherePeopleWithDifferentHours && (
          <Box style={styles.content}>
            <ReactHookFormDropdown
              name="peopleWithDifferentHoursIds"
              label={formatMessage({ id: 'label.whoHadDifferentHours' })}
              light
              onChange={handleChangePeople}
              items={event.employees.map(
                ({
                  employee: {
                    personName: { fullName },
                    employeeId,
                  },
                }) => ({ label: fullName, id: employeeId }),
              )}
              multiple
            />
          </Box>
        )}
        <Box style={styles.paddingTop}>
          {areTherePeopleWithDifferentHours &&
            (peopleWithDifferentHours || []).map(
              ({ employeeId, label, startDate, endDate }, index) => (
                <Box key={employeeId}>
                  <Box style={styles.content}>
                    <Typography
                      style={styles.label}
                      overrideColor={colors.white}
                      variant="h4"
                      fontWeight={700}
                    >
                      {label}
                    </Typography>
                  </Box>
                  <Box>
                    <PeopleWithDifferentHoursForm
                      prefix={`peopleWithDifferentHours.${index}`}
                    />
                  </Box>
                </Box>
              ),
            )}
        </Box>
      </Box>
    </ScrollView>
  );
};

export default WereTherePeopleWithDifferentHoursStep;
