import { isQuoteEvent } from './QuoteEvent';
import { QuoteEventType, QuoteExpiredEvent } from '../types';

export function isQuoteExpiredEvent(
  object: unknown,
): object is QuoteExpiredEvent {
  return (
    isQuoteEvent(object) &&
    object.eventType === QuoteEventType.QuoteExpiredEvent
  );
}
