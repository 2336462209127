import * as Yup from 'yup';
import dayjs from 'dayjs';

export interface EmploymentInputType {
  employmentStartDate?: Date | null;
  employmentEndDate?: Date | null;
  probationDate?: Date | null;
}

export const EmploymentInputTypeDefaultValues = (): EmploymentInputType => ({
  employmentStartDate: null,
  employmentEndDate: null,
  probationDate: null,
});

export const EmploymentInputTypeValidatorBuilder =
  (): Yup.ObjectSchema<EmploymentInputType> =>
    Yup.object({
      probationDate: Yup.date().nullable().label('label.probationDate'),
      employmentStartDate: Yup.date()
        .nullable()
        .required()
        .label('label.employmentStartDate'),
      employmentEndDate: Yup.date()
        .nullable()
        .when(['employmentStartDate'], ([employmentStartDate], schema) => {
          if (!employmentStartDate || !dayjs(employmentStartDate).isValid()) {
            return schema;
          }

          return schema.min(employmentStartDate);
        })
        .label('label.employmentEndDate'),
    });
