import { TimeTrackingItem } from '@bas/shared/state';
import { areDayjsRangesOverlapping } from '@bas/shared/utils';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import { v7 } from 'uuid';

export const useSplitTrackedTimes = () =>
  useCallback(
    ({
      trackedItems,
      itemToAdd,
    }: {
      trackedItems: TimeTrackingItem[];
      itemToAdd: TimeTrackingItem;
    }) => {
      const result = trackedItems;
      const endOfDay = dayjs().endOf('day');
      const overlappingTrackingItems = trackedItems.filter(({ start, end }) =>
        areDayjsRangesOverlapping(
          { start, end: end || endOfDay },
          { start: itemToAdd.start, end: itemToAdd.end || endOfDay },
        ),
      );

      overlappingTrackingItems.forEach((item) => {
        const splitTrackedTimes: {
          start: Date;
          end: Date | undefined;
        }[] = [];

        if (
          dayjs(itemToAdd.start).isBetween(
            item.start,
            item.end || endOfDay,
            'minute',
            '()',
          )
        ) {
          splitTrackedTimes.push({
            start: item.start,
            end: dayjs(itemToAdd.start).toDate(),
          });
        }

        if (
          dayjs(itemToAdd.end).isBetween(
            item.start,
            item.end || endOfDay,
            'minute',
            '()',
          )
        ) {
          splitTrackedTimes.push({
            start: dayjs(itemToAdd.end).toDate(),
            end: item.end,
          });
        }

        if (splitTrackedTimes.length > 0) {
          const itemIndex = result.findIndex(
            (trackedItem) => trackedItem.timeEntryId === item.timeEntryId,
          );

          result.splice(itemIndex, 1);
          splitTrackedTimes.forEach((splitTrackedTime, index) => {
            result.push({
              ...item,
              start: splitTrackedTime.start,
              end: splitTrackedTime.end,
              timeTypeId: item.timeTypeId,
              employeeId: item.employeeId,
              timeEntryId: index === 0 ? item.timeEntryId : v7(),
              eventId: item.eventId,
              projectId: item.projectId,
              relationId: item.relationId,
            });
          });
        }
      });

      return result;
    },
    [],
  );
