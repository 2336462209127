import { Typography, TypographyProps } from '@bas/ui/native/base';
import { TranslatedName } from '@bas/value-objects';
import { ReactElement, ReactNode } from 'react';
import { useIntl } from 'react-intl';

export type RenderTranslatedNameProps = TypographyProps & {
  translations: TranslatedName[];
  fallback: string | ReactNode;
};

const RenderTranslatedName = ({
  translations,
  fallback,
  ...props
}: RenderTranslatedNameProps): ReactElement => {
  const intl = useIntl();

  const translation = translations.find(
    ({ locale }) => locale.toLowerCase() === intl.locale.toLowerCase(),
  );
  if (translation) {
    return <Typography {...props}>{translation.name}</Typography>;
  }

  return <Typography {...props}>{fallback}</Typography>;
};

export default RenderTranslatedName;
