import { MediaObject } from '@bas/media-domain/models';

export interface FinancialAttachmentsInputType {
  attachments: { name: string; attachment: MediaObject }[];
}

export const FinancialAttachmentsInputTypeDefaultValue =
  (): FinancialAttachmentsInputType => ({
    attachments: [],
  });
