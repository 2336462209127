import { MovingJobRoom } from '@bas/project-domain/models';
import { colors } from '@bas/theme';
import { BottomSheetMethods, Typography } from '@bas/ui/native/base';
import {
  BottomSheet,
  BottomSheetProps,
  RoomsAndFurnitureList,
} from '@bas/ui/native/organisms';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { ReactElement, RefObject } from 'react';
import { FormattedMessage } from 'react-intl';
import { View } from 'react-native';
import { ensuredForwardRef } from 'react-use/lib/useEnsuredForwardedRef';
import styles from './styles';

export type FurnitureListBottomSheetProps = Omit<
  BottomSheetProps,
  'snapPoints' | 'children'
> & {
  rooms: MovingJobRoom[];
};

const FurnitureListBottomSheet = ensuredForwardRef<
  BottomSheetModal & BottomSheetMethods,
  FurnitureListBottomSheetProps
>(
  (
    { rooms, ...props }: FurnitureListBottomSheetProps,
    ref: RefObject<BottomSheetModal & BottomSheetMethods>,
  ): ReactElement => (
    <BottomSheet ref={ref} {...props}>
      <View style={styles.flex}>
        <Typography
          overrideColor={colors.white}
          variant="h4"
          fontWeight={700}
          style={[styles.textCenter, styles.title]}
        >
          <FormattedMessage id="mobileApp.event.furnitureList.title" />
        </Typography>
        <Typography
          overrideColor={colors.white}
          style={[styles.textCenter, styles.description]}
          variant="h5"
        >
          <FormattedMessage id="mobileApp.event.furnitureList.description" />
        </Typography>
        <RoomsAndFurnitureList rooms={rooms} />
      </View>
    </BottomSheet>
  ),
);

export default FurnitureListBottomSheet;
