import { ReactElement } from 'react';
import { View } from 'react-native';
import { Skeleton } from '@bas/ui/native/atoms';
import styles from './styles';

export type LoadingTrafficObstructionListItemProps = {
  dark?: boolean;
};

const LoadingTrafficObstructionListItem = ({
  dark,
}: LoadingTrafficObstructionListItemProps): ReactElement => (
  <View style={styles.container}>
    <View style={styles.roadNumberContainer}>
      <View style={styles.roadNumberStyling} />
      <Skeleton width={24} height={10} />
    </View>
    <View style={styles.obstructionText}>
      <Skeleton height={21} width={250} style={styles.obstructionTitle} />
      <Skeleton
        height={50}
        width={300}
        style={styles.loadingObstructionDescription}
      />
    </View>
  </View>
);

export default LoadingTrafficObstructionListItem;
