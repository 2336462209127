import * as Yup from 'yup';

export interface FreeTextInputType {
  freeText: { [key: string]: string };
}

export const FreeTextInputTypeValidatorBuilder =
  (): Yup.ObjectSchema<FreeTextInputType> =>
    Yup.object({
      freeText: Yup.object(),
    });
