import {
  CreateInvoiceInputType,
  FinancialDocumentContentInputTypeValidationBuilder,
  FinancialDocumentCustomerInformationInputTypeValidationBuilder,
  FinancialDocumentInputTypeValidationBuilder,
  PayAtCustomerInputTypeValidationBuilder,
} from '@bas/financial-domain/input-types';
import {
  FinancialDocumentContentFormStep,
  FinancialDocumentContentFormStepProps,
  FinancialDocumentCustomerFormStep,
  FinancialDocumentCustomerFormStepProps,
  FinancialDocumentFormStep,
  FinancialDocumentFormStepProps,
  PaymentStep,
} from '@bas/financial-domain/native/organisms';
import { PayAtCustomerPaymentInputType } from '@bas/planning-domain/input-types';
import { Project } from '@bas/project-domain/models';
import { ProjectDataForInvoice } from '@bas/project-domain/native/organisms';
import { BillingMomentType } from '@bas/shared/models';
import { DocumentType, ReactHookWizardStep } from '@bas/value-objects';
import { useMemo } from 'react';
import { FieldValues } from 'react-hook-form';
import { object } from 'yup';

type InputType = {
  invoice: CreateInvoiceInputType;
  billingMomentType: BillingMomentType;
} & PayAtCustomerPaymentInputType &
  FieldValues;

export const usePayAtCustomerWizardSteps = ({
  project,
  useIdentities,
  billingMomentType,
}: {
  project: Project;
  useIdentities?: boolean;
  billingMomentType: BillingMomentType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}): ReactHookWizardStep<InputType, any>[] =>
  useMemo(
    () => [
      {
        category: 'customer',
        title: 'label.customer',
        key: 'label.customer',
        validationSchema: object({
          invoice:
            FinancialDocumentCustomerInformationInputTypeValidationBuilder(),
        }),
        component: FinancialDocumentCustomerFormStep,
        props: {
          light: true,
          documentType: 'invoice',
          prefix: 'invoice',
        },
      } as ReactHookWizardStep<
        InputType,
        FinancialDocumentCustomerFormStepProps
      >,
      {
        category: 'document',
        title: 'label.document',
        key: 'label.document',
        validationSchema: object({
          invoice: FinancialDocumentInputTypeValidationBuilder(),
        }),
        component: FinancialDocumentFormStep,
        props: {
          light: true,
          documentType: DocumentType.INVOICE,
          prefix: 'invoice',
          useIdentities: useIdentities || false,
        },
      } as ReactHookWizardStep<InputType, FinancialDocumentFormStepProps>,
      {
        category: 'invoice',
        title: 'label.invoice',
        key: 'label.invoice',
        validationSchema: object({
          invoice: FinancialDocumentContentInputTypeValidationBuilder(),
        }),
        disabled:
          project.package.billingMoments.find(
            (billingMoment) =>
              billingMoment.billingMomentType === billingMomentType,
          )?.allowForemanToChangeInvoice === false,
        component: FinancialDocumentContentFormStep,
        props: {
          light: true,
          documentType: 'invoice',
          prefix: 'invoice',
          children: (
            <ProjectDataForInvoice projectId={project.projectId} light />
          ),
        },
      } as ReactHookWizardStep<
        InputType,
        FinancialDocumentContentFormStepProps
      >,
      {
        category: 'payment',
        title: 'label.payment',
        key: 'label.payment',
        component: PaymentStep,
        validationSchema: PayAtCustomerInputTypeValidationBuilder(),
      },
    ],
    [project, useIdentities, billingMomentType],
  );
