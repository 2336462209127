import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type RemoveRelationMutationProps = {
  relationId: Uuid;
};

export const RemoveRelationMutation = async ({
  relationId,
  ...values
}: RemoveRelationMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/relations/${relationId}/remove`, {
    relationId,
    ...values,
  });

export const useRemoveRelationMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveRelationMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  RemoveRelationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveRelationMutationProps
  >({
    mutationFn: RemoveRelationMutation,
    throwOnError: false,
    ...options,
  });
