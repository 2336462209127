import { QueryOptionsWithKey } from '@bas/shared/requests';
import { Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type AcceptanceQuotePdfByQuoteIdRelationIdAndCodeRequestProps = {
  quoteId: Uuid;
  tenantId: Uuid;
  relationId: Uuid;
  confirmationCode: string;
};

export const AcceptanceQuotePdfByQuoteIdRelationIdAndCodeRequest = async ({
  quoteId,
  relationId,
  confirmationCode,
  tenantId,
  ...params
}: AcceptanceQuotePdfByQuoteIdRelationIdAndCodeRequestProps): Promise<
  AxiosResponse<Blob>
> =>
  axios.get(
    `api/public/${tenantId}/quotes/acceptance/${quoteId}/${relationId}/${confirmationCode}/download-pdf`,
    {
      responseType: 'blob',
      params,
    },
  );

export const useAcceptanceQuotePdfByQuoteIdRelationIdAndCodeRequestQuery = (
  request: AcceptanceQuotePdfByQuoteIdRelationIdAndCodeRequestProps,
  options: QueryOptionsWithKey<
    AxiosResponse<Blob>,
    AxiosError,
    AxiosResponse<Blob>
  > = {},
): UseQueryResult<AxiosResponse<Blob>, AxiosError> =>
  useQuery<AxiosResponse<Blob>, AxiosError, AxiosResponse<Blob>>({
    ...options,
    queryFn: async () =>
      AcceptanceQuotePdfByQuoteIdRelationIdAndCodeRequest(request),
    queryKey: ['quotes', 'acceptance-pdf', request.quoteId],
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
