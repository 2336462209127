import { MovingLiftLocation } from '../types';

export function isMovingLiftLocation(
  object: unknown,
): object is MovingLiftLocation {
  return (
    Object.prototype.hasOwnProperty.call(object, 'floor') ||
    Object.prototype.hasOwnProperty.call(object, 'hasElevator') ||
    Object.prototype.hasOwnProperty.call(object, 'trafficSigns') ||
    Object.prototype.hasOwnProperty.call(object, 'permitNeeded')
  );
}
