import {
  BillingMomentInputType,
  QuoteMomentInputType,
} from '@bas/tenant-domain/input-types';
import {
  ErrorResponse,
  PricingType,
  ProjectType,
  Uuid,
} from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

const mapPeriod: {
  [key: string]: string;
} = {
  direct: '1 second',
  fiveMinutes: '5 minutes',
  hour: '1 hour',
  day: '1 day',
  week: '1 week',
  twoWeeks: '2 weeks',
  month: '1 month',
};

export type CreatePackageMutationProps = {
  packageId: Uuid;
  identityId?: Uuid | null;
  packageName: string;
  projectType?: ProjectType;
  pricingType?: PricingType;
  billingMoments: BillingMomentInputType[];
  quoteMoment: QuoteMomentInputType | undefined;
  ignoreZeroQuantityLines: boolean;
};

export const CreatePackageMutation = async ({
  billingMoments,
  ...values
}: CreatePackageMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/{tenantId}/packages`, {
    billingMoments: billingMoments.map(
      ({ period, lineType, invoiceLines, ...billingMoment }) => ({
        ...billingMoment,
        period: mapPeriod[period] || period,
        lineType,
        invoiceLines: invoiceLines.map((line) => ({
          ...line,
          lineType,
          translations: [],
        })),
      }),
    ),
    ...values,
  });

export const useCreatePackageMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    CreatePackageMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  CreatePackageMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    CreatePackageMutationProps
  >({
    mutationFn: CreatePackageMutation,
    throwOnError: false,
    ...options,
  });
