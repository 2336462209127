import {
  useSendQuoteByQuoteIdMutation,
  useSignQuoteWithTheCustomerMutationMutation,
} from '@bas/financial-domain/mutations';
import { useTaxationToolStore } from '@bas/shared/state';
import { MovingJobTaxationWizardInput } from '@bas/taxation-tool-domain/models';
import * as Device from 'expo-device';
import * as Network from 'expo-network';
import { router } from 'expo-router';
import { useCallback } from 'react';

export const useOnSubmitMovingJobTaxationWizard = ({
  projectId,
  eventId,
}: {
  projectId: string;
  eventId?: string;
}) => {
  const { mutateAsync: sendQuote } = useSendQuoteByQuoteIdMutation({
    throwOnError: true,
  });

  const { mutateAsync: signDocument } =
    useSignQuoteWithTheCustomerMutationMutation();

  const deleteTaxation = useTaxationToolStore((state) => state.deleteTaxation);

  return useCallback(
    async (values: MovingJobTaxationWizardInput) => {
      if (!values.quote || !values.quoteId) {
        return;
      }

      if (!values.customerSignsAtTheTable && values.shouldSendQuote) {
        await sendQuote({
          quoteId: values.quoteId,
          // @TODO use calculation
          expiresAt: values.quote.expiresAt || new Date(),
        });
      }

      if (values.customerSignsAtTheTable && values.signQuote.signature) {
        let ip = '';
        try {
          ip = await Network.getIpAddressAsync();
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
        await signDocument({
          quoteId: values.quoteId,
          name: values.signQuote.name,
          emailAddress: values.signQuote.emailAddress,
          signature: values.signQuote.signature,
          acceptedLines: values.signQuote.acceptedLines,
          notes: values.signQuote.remarks || '',
          browser: `${Device.osName} ${Device.osVersion} ${Device.modelName} ${Device.brand} ${Device.deviceYearClass}`,
          ipAddress: ip || '127.0.0.1',
        });
      }

      deleteTaxation(projectId);

      if (eventId) {
        router.replace(`/event/${eventId}`);
      } else {
        router.replace('/');
      }
    },
    [deleteTaxation, projectId, eventId, sendQuote, signDocument],
  );
};
