import {
  isMovingJobTaxationFurniture,
  MovingJobTaxationFurniture,
} from './MovingJobTaxationFurniture';

export type MovingJobTaxationCustomFurniture = MovingJobTaxationFurniture & {
  label: string;
  cubicMeter: number;
  servicesPossible: boolean;
  furnitureType: 'custom';
};

export function isMovingJobTaxationCustomFurniture(
  object: unknown,
): object is MovingJobTaxationCustomFurniture {
  return (
    isMovingJobTaxationFurniture(object) && object.furnitureType === 'custom'
  );
}
