import {
  EmployeeTimelineForApproval,
  isHoursEntry,
  isTimeOffRegistration,
} from '@bas/hrm-domain/models';
import { isEvent } from '@bas/planning-domain/models';
import {
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { formatDate } from '@bas/shared/utils';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type EmployeeTimelinesForApprovalByEmployeeAndPeriodRequestProps =
  Pagination & {
    employeeId: Uuid;
    startPeriod: Date;
    endPeriod: Date;
  };

type Response = AxiosResponse<Collection<EmployeeTimelineForApproval>>;

export const EmployeeTimelinesForApprovalByEmployeeAndPeriodRequest = async ({
  employeeId,
  startPeriod,
  endPeriod,
  ...params
}: EmployeeTimelinesForApprovalByEmployeeAndPeriodRequestProps): Promise<Response> =>
  axios.get(
    `api/{tenantId}/hrm/employees/${employeeId}/timelines-for-approval/${formatDate(startPeriod)}/${formatDate(
      endPeriod,
    )}`,
    {
      params: { ...params },
    },
  );

export const useEmployeeTimelinesForApprovalByEmployeeAndPeriodRequest = (
  {
    employeeId,
    startPeriod,
    endPeriod,
    ...request
  }: EmployeeTimelinesForApprovalByEmployeeAndPeriodRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () =>
      EmployeeTimelinesForApprovalByEmployeeAndPeriodRequest({
        ...request,
        employeeId,
        startPeriod,
        endPeriod,
      }),
    queryKey: [
      'employee-timelines-for-approval',
      'list',
      employeeId,
      formatDate(startPeriod),
      formatDate(endPeriod),
      ...Object.values(request),
    ],
  });

export const EmployeeTimelinesForApprovalByEmployeeAndPeriodRequestInvalidator: QueryInvalidator =
  (data, queryClient) => {
    if (isHoursEntry(data) || isTimeOffRegistration(data) || isEvent(data)) {
      queryClient.invalidateQueries({
        queryKey: ['employee-timelines-for-approval', 'list'],
      });
    }
  };
