import { Box } from '@bas/ui/native/base';
import {
  ReactHookFormDatePickerField,
  ReactHookFormNumberTextField,
  ReactHookFormSelectButtons,
  ReactHookFormTextField,
} from '@bas/ui/native/molecules';
import { HandlingCostsInputType } from '@bas/wms-domain/input-types';
import * as React from 'react';
import { ReactElement } from 'react';
import { useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';

const HandlingCostsForm = (): ReactElement => {
  const { formatMessage } = useIntl();
  const invoiceHandlingCosts = useWatch<
    {
      invoiceHandlingCosts: boolean;
    },
    'invoiceHandlingCosts'
  >({
    name: 'invoiceHandlingCosts',
  });

  return (
    <Box flex={1} paddingHorizontal={20}>
      <Box width="100%">
        <ReactHookFormSelectButtons
          name="invoiceHandlingCosts"
          light
          label={formatMessage({
            id: 'label.doYouWantToInvoiceHandlingCosts',
          })}
          options={[
            {
              label: formatMessage({
                id: 'label.yes',
              }),
              value: true,
            },
            {
              label: formatMessage({
                id: 'label.no',
              }),
              value: false,
            },
          ]}
        />
      </Box>
      <Box flex={1} flexDirection="row" flexWrap="wrap" rowGap={3}>
        {invoiceHandlingCosts && (
          <>
            <Box flexBasis="100%">
              <ReactHookFormTextField<{ handlingCosts: HandlingCostsInputType }>
                name="handlingCosts.description"
                label={formatMessage({ id: 'label.description' })}
                light
                multiline
              />
            </Box>
            <Box flexBasis="50%" pr={1}>
              <ReactHookFormNumberTextField<{
                handlingCosts: HandlingCostsInputType;
              }>
                name="handlingCosts.amount.amount"
                autoComplete="off"
                label={formatMessage({ id: 'label.amount' })}
                keyboardType="decimal-pad"
                textAlign="right"
                divideBy={100}
                fractionDigits={2}
                type="currency"
                light
              />
            </Box>
            <Box flexBasis="50%" pl={1}>
              <ReactHookFormNumberTextField<{
                handlingCosts: HandlingCostsInputType;
              }>
                name="handlingCosts.quantity"
                autoComplete="off"
                label={formatMessage({ id: 'label.quantity' })}
                keyboardType="decimal-pad"
                fractionDigits={2}
                shouldBeZero
                light
                padZero={false}
              />
            </Box>
            <Box flexBasis="50%" pr={1}>
              <ReactHookFormDatePickerField<{
                handlingCosts: HandlingCostsInputType;
              }>
                name="handlingCosts.handlingDate"
                label={formatMessage({ id: 'label.handlingDate' })}
                light
                mode="date"
              />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default HandlingCostsForm;
