import { useEmployeeStore } from '@bas/shared/state';
import { colors } from '@bas/theme';
import { BottomSheetMethods, Box, Typography } from '@bas/ui/native/base';
import {
  BottomSheetProps,
  StepsNavigationProps,
} from '@bas/ui/native/organisms';
import { FormWizardBottomSheet } from '@bas/ui/native/templates';
import { ReactHookWizardStep } from '@bas/value-objects';
import {
  OptionalHandlingCostsInputType,
  optionalHandlingCostsInputTypeDefaultValues,
  optionalHandlingCostsInputTypeValidationBuilder,
  PickUpStorageInputType,
  pickUpStorageInputTypeDefaultValues,
  pickUpStorageInputTypeValidationBuilder,
} from '@bas/wms-domain/input-types';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import {
  ReactElement,
  ReactNode,
  RefObject,
  useCallback,
  useMemo,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { HandlingCostsForm } from '../HandlingCostsForm';
import { PickUpStorageForm } from '../PickUpStorageForm';

type InputType = PickUpStorageInputType & OptionalHandlingCostsInputType;

export type PickUpStorageFormWizardBottomSheetProps = Omit<
  BottomSheetProps,
  'snapPoints' | 'children'
> & {
  bottomSheetRef?: RefObject<BottomSheetModal & BottomSheetMethods>;
  onSubmit: (values: InputType) => Promise<void>;
  disableHandlingCosts?: boolean;
};

const PickUpStorageFormWizardBottomSheet = ({
  bottomSheetRef,
  onSubmit,
  disableHandlingCosts,
}: PickUpStorageFormWizardBottomSheetProps): ReactElement => {
  const employeeId = useEmployeeStore((state) => state.employee?.employeeId);

  const onClose = useCallback(() => {
    bottomSheetRef?.current?.close();
    bottomSheetRef?.current?.forceClose();
    bottomSheetRef?.current?.dismiss();
  }, [bottomSheetRef]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const steps: ReactHookWizardStep<InputType, any>[] = useMemo(
    () => [
      {
        key: 'loadItems',
        category: 'loadItems',
        title: undefined,
        validationSchema: pickUpStorageInputTypeValidationBuilder(),
        component: PickUpStorageForm,
      } as ReactHookWizardStep<InputType>,
      {
        key: 'handlingCosts',
        category: 'handlingCosts',
        title: 'label.handlingCosts',
        validationSchema: optionalHandlingCostsInputTypeValidationBuilder(),
        component: HandlingCostsForm,
        enabled: !disableHandlingCosts,
      } as ReactHookWizardStep<InputType>,
    ],
    [disableHandlingCosts],
  );
  const renderTitle = useCallback((data: unknown, title: ReactNode) => {
    if (!title) {
      return undefined;
    }

    return (
      <Box width="100%" pb={3}>
        <Typography
          color="white"
          variant="h4"
          fontWeight={700}
          textAlign="center"
        >
          <FormattedMessage id="label.pickUpStorage" />
        </Typography>
        <Typography variant="h5" color="white" textAlign="center">
          {typeof title === 'string' ? <FormattedMessage id={title} /> : title}
        </Typography>
      </Box>
    );
  }, []);

  return (
    <FormWizardBottomSheet<
      { stepNavigationProps: Partial<StepsNavigationProps> },
      InputType,
      unknown
    >
      name="pick-up-storage-form"
      disablePadding
      enableDismissOnClose
      onSubmit={onSubmit}
      onCancel={onClose}
      disableScroll
      resetFormOnOpen
      hideBackendErrors
      initialValues={{
        ...pickUpStorageInputTypeDefaultValues(),
        ...optionalHandlingCostsInputTypeDefaultValues(),
        employeeId: employeeId || null,
      }}
      successFullFormViewProps={{
        gifUrl: 'https://media.giphy.com/media/ToMjGpEbrPkWHrrTA8E/giphy.gif',
        title: 'label.pickedUpStorage.finished',
        color: colors.lila[100],
        labelId: 'label.storageIsPickedUp',
      }}
      extraTemplateProps={{
        stepNavigationProps: {
          dark: true,
          allowCancel: true,
          onCancel: onClose,
          onClose,
        },
      }}
      bottomSheetRef={bottomSheetRef}
      initialStepIndex={0}
      renderTitle={renderTitle}
      wizardSteps={steps}
    />
  );
};

export default PickUpStorageFormWizardBottomSheet;
