import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type EnableTimeTypeMutationProps = {
  timeTypeId: Uuid;
};

export const EnableTimeTypeMutation = async ({
  timeTypeId,
  ...values
}: EnableTimeTypeMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/hrm/time-types/${timeTypeId}/enable`, {
    timeTypeId,
    ...values,
  });

export const useEnableTimeTypeMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    EnableTimeTypeMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  EnableTimeTypeMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    EnableTimeTypeMutationProps
  >({
    mutationFn: EnableTimeTypeMutation,
    throwOnError: false,
    ...options,
  });
