import { StyleSheet } from 'react-native';
import { colors } from '@bas/theme';

export default StyleSheet.create({
  container: {
    height: 74,
    borderTopWidth: 1,
    borderColor: colors.lila[300],
    flexDirection: 'row',
    alignItems: 'center',
  },
  borderBottom: {
    borderBottomWidth: 1,
  },
  role: {
    paddingRight: 6,
  },
});
