import { PlannedEmployee } from '@bas/planning-domain/models';
import { memo, ReactElement } from 'react';
import isEqual from 'react-fast-compare';
import { View } from 'react-native';
import { PlannedEmployeeListItem } from '../PlannedEmployeeListItem';

export type PlannedEmployeeListProps = {
  plannedEmployees: PlannedEmployee[];
  onPressEmployee: (plannedEmployee: PlannedEmployee) => void;
};

const PlannedEmployeeList = ({
  plannedEmployees,
  onPressEmployee,
}: PlannedEmployeeListProps): ReactElement => (
  <View>
    {plannedEmployees.map((plannedEmployee, index) => (
      <PlannedEmployeeListItem
        key={plannedEmployee.employee.employeeId}
        plannedEmployee={plannedEmployee}
        onPress={() => onPressEmployee(plannedEmployee)}
        isLast={plannedEmployees.length - 1 === index}
      />
    ))}
  </View>
);

const MemoComponent = memo(PlannedEmployeeList, isEqual);
export default MemoComponent;
