import {
  AddDeclarationInputType,
  addDeclarationInputTypeDefaultValues,
  addDeclarationInputTypeValidationBuilder,
} from '@bas/hrm-domain/input-types';
import { useEmployeeStore } from '@bas/shared/state';
import { colors } from '@bas/theme';
import { BottomSheetMethods, Box, Typography } from '@bas/ui/native/base';
import { BackendErrors, BottomSheetProps } from '@bas/ui/native/organisms';
import { FormBottomSheet } from '@bas/ui/native/templates';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { ReactElement, RefObject, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { AddDeclarationForm } from '../AddDeclarationForm';

export type AddDeclarationBottomSheetProps = Omit<
  BottomSheetProps,
  'snapPoints' | 'children'
> & {
  onSubmit: (values: AddDeclarationInputType) => Promise<void> | void;
  bottomSheetRef?: RefObject<BottomSheetModal & BottomSheetMethods>;
};

const AddDeclarationBottomSheet = ({
  onSubmit,
  bottomSheetRef,
  ...props
}: AddDeclarationBottomSheetProps): ReactElement => {
  const employeeId = useEmployeeStore((state) => state.employee?.employeeId);
  const handleFinalize = useCallback(() => {
    bottomSheetRef?.current?.close();
    bottomSheetRef?.current?.forceClose();
    bottomSheetRef?.current?.dismiss();
  }, [bottomSheetRef]);

  return (
    <FormBottomSheet<AddDeclarationInputType>
      bottomSheetRef={bottomSheetRef}
      disablePadding
      name="add-declaration"
      onSubmit={onSubmit}
      useProvider
      resetFormOnOpen
      onCancel={handleFinalize}
      validationSchema={addDeclarationInputTypeValidationBuilder}
      successFullFormViewProps={{
        gifUrl: 'https://media.giphy.com/media/l0Ex6kAKAoFRsFh6M/giphy.gif',
        color: colors.lila[100],
        title: 'label.declarationAdded',
        labelId: 'label.declarationAddedDescription',
      }}
      defaultValues={{
        ...addDeclarationInputTypeDefaultValues(),
        employeeId: employeeId as string,
      }}
      {...props}
    >
      <Box flex={1}>
        <Typography
          fontWeight={700}
          color="white"
          textAlign="center"
          fontSize={22}
          lineHeight={29}
        >
          <FormattedMessage id="label.addDeclaration" />
        </Typography>
        <Box>
          <BackendErrors />
          <Box paddingHorizontal={20} marginBottom={2}>
            <AddDeclarationForm />
          </Box>
        </Box>
      </Box>
    </FormBottomSheet>
  );
};

export default AddDeclarationBottomSheet;
