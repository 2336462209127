import {
  Box,
  IconWithSpin,
  TouchableWithoutFeedback,
  Typography,
} from '@bas/ui/native/base';
import { colors } from '@bas/theme';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons/faSpinnerThird';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import styles from './styles';

export type CheckboxProps = {
  checked: boolean;
  disabled?: boolean;
  onCheck?: (checked: boolean) => void;
  style?: StyleProp<ViewStyle>;
  checkMarkColor?: string;
  size: number;
  keepLoadingUntilValueChanges?: boolean;
  desktop?: boolean;
  light?: boolean;
  label?: string;
};

const Checkbox = ({
  checked,
  disabled,
  onCheck,
  style,
  checkMarkColor,
  desktop,
  size,
  light = true,
  keepLoadingUntilValueChanges,
  label,
}: CheckboxProps): ReactElement => {
  const [isChecking, setIsChecking] = useState<boolean>(false);
  const sizeStyle = useMemo(() => ({ width: size, height: size }), [size]);
  const handleCheck = useCallback(async () => {
    if (disabled || isChecking) {
      return;
    }

    setIsChecking(true);
    await onCheck?.(!checked);
    if (!keepLoadingUntilValueChanges) {
      setIsChecking(false);
    }
  }, [checked, disabled, isChecking, keepLoadingUntilValueChanges, onCheck]);

  useEffect(() => {
    if (keepLoadingUntilValueChanges) {
      setIsChecking(false);
    }
  }, [checked, keepLoadingUntilValueChanges]);

  const defaultCheckColor = useMemo(() => {
    if (disabled) {
      return colors.lila[400];
    }

    return desktop ? colors.white : colors.blue[500];
  }, [desktop, disabled]);

  return (
    <TouchableWithoutFeedback onPress={handleCheck}>
      <Box flexDirection="row">
        <View
          style={[
            styles.checkbox,
            sizeStyle,
            disabled && styles.disabled,
            desktop && styles.desktop,
            !light && styles.desktop,
            disabled && desktop && styles.disabledDesktop,
            style,
            checked && desktop && styles.desktopChecked,
          ]}
        >
          {isChecking && (
            <IconWithSpin
              size={size - 8}
              icon={faSpinnerThird}
              overrideColor={colors.white}
              secondaryColor={checkMarkColor || defaultCheckColor}
            />
          )}
          {checked && !isChecking && (
            <FontAwesomeIcon
              icon={faCheck}
              size={size - 8}
              style={styles.checkMark}
              color={checkMarkColor || defaultCheckColor}
            />
          )}
        </View>
        {label && (
          <Box pl={1}>
            <Typography color={light ? 'white' : undefined}>{label}</Typography>
          </Box>
        )}
      </Box>
    </TouchableWithoutFeedback>
  );
};

export default Checkbox;
