import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { ErrorResponse, Uuid } from '@bas/value-objects';

export type EnableTwoFactorMutationProps = {
  userId: Uuid;
  code: string;
};

type EnableTwoFactorMutationResponse = AxiosResponse;

export const EnableTwoFactorMutation = async ({
  userId,
  ...values
}: EnableTwoFactorMutationProps): Promise<EnableTwoFactorMutationResponse> =>
  axios.put(`api/users/${userId}/enable-two-factor`, {
    userId,
    ...values,
  });

export const useEnableTwoFactorMutation = (
  options: UseMutationOptions<
    EnableTwoFactorMutationResponse,
    AxiosError<ErrorResponse>,
    EnableTwoFactorMutationProps
  > = {},
): UseMutationResult<
  EnableTwoFactorMutationResponse,
  AxiosError<ErrorResponse>,
  EnableTwoFactorMutationProps
> =>
  useMutation<
    EnableTwoFactorMutationResponse,
    AxiosError<ErrorResponse>,
    EnableTwoFactorMutationProps
  >({
    mutationFn: EnableTwoFactorMutation,
    throwOnError: false,
    ...options,
  });
