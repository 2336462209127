import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basSpamEnvelope: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'spam-envelope',
  icon: [
    500,
    500,
    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M241.6 36.8L45 182.4c-8.1 6-13 15.6-13 25.7c0 1.1 0 2.1 0 3.2c3.4 2.8 6.8 5.5 10.2 8.3c65.2 53.6 130.4 107.1 195.6 160.7c5.1 4.2 11.6 6.5 18.2 6.5s13.1-2.3 18.2-6.5c15.3-12.5 30.5-25.1 45.8-37.6c0 .4 0 .9 0 1.3c0 3.1 .1 6.2 .4 9.2c-14.7 14.8-25.2 33.7-29.8 54.9c-10.1 7-22.2 10.8-34.6 10.8c-14 0-27.7-4.9-38.5-13.8C155.7 354.3 93.8 303.5 32 252.7c0 65.1 0 130.2 0 195.3c0 17.7 14.3 32 32 32c78.3 0 156.5 0 234.8 0c5.7 12 13.5 22.8 22.8 32c26.1 0 52.3 0 78.4 0c53.3 0 106.7 0 160 0c44.2 0 80-35.8 80-80c0-26.1 0-52.3 0-78.4c-.1-.1-.3-.3-.4-.4c.3-3 .4-6.1 .4-9.2c0-37.4-19.7-70.1-49.2-88.4c-7.4-50.6-48.7-90.2-100-95.1c-3.5-.3-7.1-.5-10.8-.5c-11.9 0-22.2 3.8-30.3 9.6C389.9 125.3 330.2 81.1 270.4 36.8c-4.2-3.1-9.2-4.8-14.4-4.8s-10.3 1.7-14.4 4.8zM222.5 11C232.2 3.9 243.9 0 256 0s23.8 3.9 33.5 11C355 59.6 420.6 108.1 486.1 156.7c1.6 1.2 3.2 2.5 4.7 3.8c51.4 4.9 92.6 44.5 100 95.1c29.5 18.3 49.2 51 49.2 88.4c0 3.1-.1 6.2-.4 9.2c.1 .1 .3 .3 .4 .4c0 26.1 0 52.3 0 78.4c0 44.2-35.8 80-80 80c-53.3 0-106.7 0-160 0c-26.1 0-52.3 0-78.4 0c-85.9 0-171.7 0-257.6 0c-35.3 0-64-28.7-64-64c0-80 0-159.9 0-239.9c0-20.3 9.6-39.4 25.9-51.4C91.4 108.1 157 59.6 222.5 11zM497.2 227.2c.8 4.2 1.3 8.5 1.3 12.8c0 35.3-28.7 64-64 64c-3.5 0-7 0-10.5 0c-22.1 0-40 17.9-40 40c0 7.5 2.1 14.5 5.6 20.5c2.4 4 2.9 8.9 1.4 13.3s-4.8 8-9.1 9.7C364.3 394.7 352 411.9 352 432c0 26.5 21.5 48 48 48c53.3 0 106.7 0 160 0c26.5 0 48-21.5 48-48c0-20.1-12.3-37.3-29.9-44.5c-4.3-1.8-7.7-5.3-9.1-9.7s-1-9.3 1.4-13.3c3.6-6 5.6-13 5.6-20.5c0-20.7-15.7-37.7-35.8-39.8c-4.6-.5-8.8-3-11.5-6.8s-3.5-8.6-2.3-13.2c1-3.9 1.6-8 1.6-12.2c0-20.5-12.8-37.9-30.8-44.8zm-36-22.9c2.9-6.5 9.6-12.3 18.8-12.3c44.2 0 80 35.8 80 80c0 1.4 0 2.7-.1 4.1c28 9.9 48.1 36.5 48.1 67.9c0 7.5-1.2 14.8-3.3 21.6C626 380 640 404.3 640 432c0 44.2-35.8 80-80 80c-53.3 0-106.7 0-160 0c-44.2 0-80-35.8-80-80c0-27.6 14-52 35.3-66.4c-2.2-6.8-3.3-14.1-3.3-21.6c0-39.8 32.2-72 72-72c3.5 0 6.9 0 10.4 0c17.7 0 32-14.3 32-32c0-5.8-1.5-11.2-4.2-15.9c-3.6-6.3-3.8-13.7-1-19.8z',
  ],
};

export default basSpamEnvelope;
