import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  LayoutChangeEvent,
  NativeSyntheticEvent,
  StyleSheet,
  TextInput,
  TextInputFocusEventData,
  View,
} from 'react-native';
import Animated, {
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { Box } from '../Box';
import { TextFieldPropsForRealInput } from '../TextFieldProps';
import { TouchableWithoutFeedback } from '../TouchableWithoutFeedback';
import { Typography } from '../Typography';

const AnimatedTextInput = Animated.createAnimatedComponent(TextInput);

export type StandardTextFieldProps = TextFieldPropsForRealInput & {
  minHeight?: number;
  maxHeight?: number;
};

const APPROX_LINE_HEIGHT = 24;
const INPUT_PADDING = 20;

const styles = StyleSheet.create({
  container: {
    minHeight: 56,
    justifyContent: 'center',
    position: 'relative',
  },
  label: {
    position: 'absolute',
    left: 0,
  },
  input: {
    flex: 1,
    fontSize: 20,
  },
  underline: {
    height: 1,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
  },
  inputContainer: {
    position: 'relative',
    flex: 1,
  },
  scrollContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
});

export const StandardTextField = ({
  label,
  value,
  onChangeText,
  disabled,
  error,
  light,
  InputComponent = AnimatedTextInput,
  onFocus,
  onBlur,
  labelColor,
  textColor,
  borderColor,
  handleFocusChange,
  inputRef,
  trailingAccessory,
  prefix,
  suffix,
  pointerEvents,
  minHeight = 56,
  maxHeight = 400,
  multiline = false,
  ...props
}: StandardTextFieldProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [contentHeight, setContentHeight] = useState(minHeight);
  const [inputWidth, setInputWidth] = useState(0);
  const isLabelFloating = useMemo(
    () => !!(value || prefix || suffix || isFocused),
    [value, prefix, suffix, isFocused],
  );

  const animationProgress = useSharedValue(isLabelFloating ? 1 : 0);
  const heightUpdateTimeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    animationProgress.value = withTiming(isLabelFloating ? 1 : 0, {
      duration: 200,
    });
  }, [value, isFocused, animationProgress, trailingAccessory, isLabelFloating]);

  const animatedLabelStyle = useAnimatedStyle(() => ({
    fontSize: interpolate(animationProgress.value, [0, 1], [20, 14]),
    top: interpolate(animationProgress.value, [0, 1], [24, 0]),
  }));

  const animatedInputStyle = useAnimatedStyle(() => ({
    marginTop: interpolate(animationProgress.value, [0, 1], [26, 19]),
  }));

  const calculateHeight = useCallback(
    (text: string) => {
      if (!multiline || !inputWidth) return minHeight;

      const charsPerLine = Math.floor(inputWidth / 11);
      const lines = text
        .split('\n')
        .map((line) => Math.ceil(line.length / charsPerLine) || 1);
      const totalLines = lines.reduce((sum, lineCount) => sum + lineCount, 0);

      const calculatedHeight = Math.max(
        minHeight,
        totalLines * APPROX_LINE_HEIGHT + INPUT_PADDING,
      );

      return Math.min(calculatedHeight, maxHeight);
    },
    [multiline, inputWidth, minHeight, maxHeight],
  );

  const handleLayout = useCallback((event: LayoutChangeEvent) => {
    setInputWidth(event.nativeEvent.layout.width);
  }, []);

  const updateHeight = useCallback(
    (height: number) => {
      setContentHeight(Math.max(minHeight, Math.min(height, maxHeight)));
    },
    [minHeight, maxHeight],
  );

  const handleTextChange = useCallback(
    (text: string) => {
      onChangeText?.(text);

      if (multiline) {
        if (heightUpdateTimeoutRef.current) {
          clearTimeout(heightUpdateTimeoutRef.current);
        }

        heightUpdateTimeoutRef.current = setTimeout(() => {
          const newHeight = calculateHeight(text);
          updateHeight(newHeight);
        }, 10);
      }
    },
    [multiline, calculateHeight, updateHeight, onChangeText],
  );

  const handleContentSizeChange = useCallback(
    (event: { nativeEvent: { contentSize: { height: number } } }) => {
      if (multiline) {
        const newHeight = event.nativeEvent.contentSize.height + INPUT_PADDING;
        updateHeight(newHeight);
      }
    },
    [multiline, updateHeight],
  );

  useEffect(
    () => () => {
      if (heightUpdateTimeoutRef.current) {
        clearTimeout(heightUpdateTimeoutRef.current);
      }
    },
    [],
  );

  const containerStyle = useMemo(
    () => [
      styles.container,
      multiline && {
        height: contentHeight,
      },
    ],
    [contentHeight, multiline],
  );

  const handleFocus = useCallback(
    (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      setIsFocused(true);
      handleFocusChange?.(e);
      onFocus?.(e);
    },
    [handleFocusChange, onFocus],
  );

  const handleBlur = useCallback(
    (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      setIsFocused(false);
      handleFocusChange?.(e);
      onBlur?.(e);
    },
    [handleFocusChange, onBlur],
  );

  const handleFocusInput = useCallback(() => {
    if (inputRef?.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  const field = (
    <View style={containerStyle} pointerEvents={pointerEvents}>
      <View pointerEvents="none">
        <Animated.Text
          numberOfLines={1}
          ellipsizeMode="tail"
          style={[styles.label, animatedLabelStyle, { color: labelColor }]}
        >
          {label}
        </Animated.Text>
      </View>

      <Box
        flex={1}
        flexDirection="row"
        justifyContent="flex-end"
        style={styles.inputContainer}
      >
        {prefix && (
          <Box alignSelf="flex-start">
            <Typography
              overrideColor={textColor}
              paddingBottom="line"
              fontSize={20}
            >
              {prefix}
            </Typography>
          </Box>
        )}
        <Box
          onLayout={handleLayout}
          position="absolute"
          left={0}
          right={suffix || trailingAccessory ? 24 : 0}
          top={0}
          bottom={0}
        >
          <InputComponent
            editable={!disabled}
            readOnly={disabled}
            {...props}
            ref={inputRef}
            style={[styles.input, animatedInputStyle, { color: textColor }]}
            value={value || ''}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChangeText={handleTextChange}
            multiline={multiline}
            onContentSizeChange={handleContentSizeChange}
            pointerEvents={pointerEvents}
          />
        </Box>
        {(trailingAccessory || suffix) && (
          <Box
            alignSelf="flex-end"
            height="100%"
            flexDirection="row"
            paddingBottom="line"
          >
            <Box height="100%" flexDirection="row" alignItems="flex-end">
              <Typography
                overrideColor={textColor}
                paddingBottom="line"
                fontSize={20}
              >
                {suffix}
              </Typography>
            </Box>
            {trailingAccessory}
          </Box>
        )}
      </Box>

      <View style={[styles.underline, { backgroundColor: borderColor }]} />
    </View>
  );

  if (pointerEvents === 'none') {
    return field;
  }

  return (
    <TouchableWithoutFeedback onPress={handleFocusInput}>
      {field}
    </TouchableWithoutFeedback>
  );
};

export default StandardTextField;
