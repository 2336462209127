import * as Yup from 'yup';

export interface DeclineQuoteInputType {
  reason?: string | null;
  cancelProject?: boolean;
}

export const DeclineQuoteInputTypeDefaultValues =
  (): DeclineQuoteInputType => ({
    reason: '',
    cancelProject: false,
  });

export const DeclineQuoteInputTypeValidationBuilder =
  (): Yup.ObjectSchema<DeclineQuoteInputType> =>
    Yup.object({
      reason: Yup.string().label('label.reason'),
      cancelProject: Yup.boolean().label('label.cancelProject'),
    });
