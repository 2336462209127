import { ReactElement } from 'react';
import { RectangularTile, RectangularTileProps } from '@bas/ui/native/atoms';
import styles from './styles';

const RectangularTileButton = ({
  style,
  ...props
}: RectangularTileProps): ReactElement => (
  <RectangularTile {...props} style={[styles.button, style]} />
);

export default RectangularTileButton;
