import {
  ProjectEventDetailsInputType,
  ProjectEventDetailsInputTypeValidationBuilder,
} from '@bas/planning-domain/input-types';
import { MovingJobAddressType } from '@bas/project-domain/models';
import {
  AddressInputType,
  addressInputTypeDefaultValues,
  addressInputTypeValidationBuilder,
} from '@bas/shared/input-types';
import { EmployeeProjectRole, Uuid } from '@bas/value-objects';
import * as Yup from 'yup';
import {
  MovingJobAddressInputType,
  MovingJobAddressInputTypeDefaultValues,
  MovingJobAddressInputTypeValidationBuilder,
} from './movingJobAddressInputType';
import {
  NewProjectCustomerInputType,
  NewProjectCustomerInputTypeDefaultValues,
  NewProjectCustomerInputTypeValidationBuilder,
} from './newProjectCustomerInputType';
import {
  ProjectPricingInputType,
  ProjectPricingInputTypeDefaultValues,
  ProjectPricingInputTypeValidationBuilder,
} from './projectPricingInputType';

export type NewMovingJobInputType = NewProjectCustomerInputType &
  ProjectPricingInputType & {
    addresses: MovingJobAddressInputType[];
    events: ProjectEventDetailsInputType[];
    neededRoles: EmployeeProjectRole[];
    changeAddressAfterMoving: boolean;
    intakeEvent: {
      start?: Date | null;
      end?: Date | null;
      travelStart?: Date | null;
      travelEnd?: Date | null;
      employeeIds: Uuid[];
      notes?: string | null;
      departureLocation: {
        addressId?: string | null;
      } & AddressInputType;
    };
  };

export const NewMovingJobInputTypeDefaultValues = (
  disableDefaultDeliverBoxes = false,
): NewMovingJobInputType => ({
  useIdentities: false,
  ...NewProjectCustomerInputTypeDefaultValues(),
  ...ProjectPricingInputTypeDefaultValues(),
  addresses: [
    {
      ...MovingJobAddressInputTypeDefaultValues(),
      primary: true,
      addressType: MovingJobAddressType.FROM,
      deliverBoxes: !disableDefaultDeliverBoxes,
      unknownAddress: false,
    },
    {
      ...MovingJobAddressInputTypeDefaultValues(),
      primary: true,
      addressType: MovingJobAddressType.TO,
      deliverBoxes: !disableDefaultDeliverBoxes,
      unknownAddress: false,
    },
  ],
  events: [],
  neededRoles: [],
  changeAddressAfterMoving: false,
  intakeEvent: {
    start: null,
    end: null,
    travelStart: null,
    travelEnd: null,
    notes: '',
    employeeIds: [],
    departureLocation: addressInputTypeDefaultValues(),
  },
});

export const NewMovingJobInputTypeValidationBuilder =
  (): Yup.ObjectSchema<NewMovingJobInputType> =>
    Yup.object({
      addresses: Yup.array()
        .of(MovingJobAddressInputTypeValidationBuilder())
        .required(),
      events: Yup.array()
        .of(ProjectEventDetailsInputTypeValidationBuilder())
        .required(),
      neededRoles: Yup.array().required().label('label.neededRoles').required(),
      changeAddressAfterMoving: Yup.boolean()
        .required()
        .label('label.changeAddressAfterMoving'),
      intakeEvent: Yup.object({
        start: Yup.date().nullable().label('label.startTime'),
        end: Yup.date().nullable().label('label.endTime'),
        travelStart: Yup.date().nullable().label('label.departureTime'),
        travelEnd: Yup.date().nullable().label('label.arrivalTimeWarehouse'),
        employeeIds: Yup.array().of(Yup.string().required()).required(),
        notes: Yup.string().nullable().label('label.notes'),
        departureLocation: Yup.object({
          addressId: Yup.string().required().label('label.departureLocation'),
        })
          .concat(addressInputTypeValidationBuilder())
          .label('label.departureLocation')
          .nullable()
          .required(),
      }),
    })
      .concat(NewProjectCustomerInputTypeValidationBuilder())
      .concat(ProjectPricingInputTypeValidationBuilder());
