import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  smallPaddingContent: {
    marginTop: 16,
  },
  marginBetweenContent: {
    marginBottom: 24,
  },
  halfWidth: {
    flexBasis: '50%',
    flexGrow: 1,
  },
  fullWidth: {
    flexBasis: '100%',
    flexGrow: 1,
  },
  image: {
    height: '100%',
    width: '100%',
    borderRadius: 4,
  },
});
