export type * from './BounceEvent';
export * from './BounceType';
export type * from './ClickEvent';
export type * from './DeferredEvent';
export type * from './DeliveredEvent';
export type * from './Email';
export type * from './EmailAttachment';
export type * from './EmailContent';
export type * from './EmailEvent';
export * from './EmailFolder';
export type * from './EmailHeader';
export type * from './ListedEmail';
export type * from './OpenEvent';
export type * from './Receiver';
export type * from './Sender';
export type * from './SpamReportEvent';
export type * from './UnreadEmailsByFolder';
