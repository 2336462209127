import markdownit, { PluginSimple } from 'markdown-it';

export default function creator({
  rules = {},
  plugins = [],
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rules?: Record<string, any>;
  plugins?: PluginSimple[];
}) {
  const markdownIt = markdownit('default', {
    breaks: true,
    html: false,
    linkify: false,
    typographer: true,
    ...rules,
  });
  plugins.forEach((plugin) => markdownIt.use(plugin));
  return markdownIt;
}
