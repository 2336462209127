import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  listItem: {
    height: 50,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  checkboxLabelContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    paddingLeft: 16,
  },
  icons: {
    flexDirection: 'row',
  },
  icon: {
    marginRight: 10,
  },
});
