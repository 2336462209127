import { useEmployeeStore, useTenantStore } from '@bas/shared/state';
import { Button, DrawerIcon } from '@bas/ui/native/atoms';
import { Box, Icon, Typography } from '@bas/ui/native/base';
import { useHandleOpenIntercom, useLogout } from '@bas/ui/native/hooks';
import { faIntercom } from '@fortawesome/free-brands-svg-icons/faIntercom';
import { faShieldExclamation } from '@fortawesome/pro-light-svg-icons';
import { faPortalExit } from '@fortawesome/pro-light-svg-icons/faPortalExit';
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser';
import { faWarehouseFull } from '@fortawesome/pro-light-svg-icons/faWarehouseFull';
import { getFocusedRouteNameFromRoute } from '@react-navigation/core';
import { DrawerContentScrollView, DrawerItem } from '@react-navigation/drawer';
import { DrawerContentComponentProps } from '@react-navigation/drawer/src/types';
import dayjs from 'dayjs';
import { router } from 'expo-router';
import { Drawer } from 'expo-router/drawer';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

// eslint-disable-next-line react/no-unused-prop-types
type ColorProps = { color: string };

const DrawerLayout = () => {
  const personName = useEmployeeStore((state) => state.employee?.personName);

  const greetingName = useMemo(
    () => personName?.firstName,
    [personName?.firstName],
  );

  const logout = useLogout();
  const openIntercom = useHandleOpenIntercom();
  const { formatMessage } = useIntl();

  const handleHelp = useCallback(() => {
    openIntercom();
  }, [openIntercom]);

  const handleLogout = useCallback(() => {
    logout();
  }, [logout]);

  const canAskHelp = useEmployeeStore((state) => state.canAskHelp);
  const isWarehouseEmployee = useEmployeeStore(
    (state) => state.isWarehouseEmployee,
  );
  const employmentEndDate = useEmployeeStore(
    (state) => state.employee?.employmentEndDate,
  );

  const renderProfileIcon = useCallback(
    ({ color }: ColorProps) => <DrawerIcon icon={faUser} color={color} />,
    [],
  );

  const renderWarehouseIcon = useCallback(
    ({ color }: ColorProps) => (
      <DrawerIcon icon={faWarehouseFull} color={color} />
    ),
    [],
  );

  const renderHelpIcon = useCallback(
    ({ color }: ColorProps) => <DrawerIcon icon={faIntercom} color={color} />,
    [],
  );

  const renderLogoutIcon = useCallback(
    ({ color }: ColorProps) => <DrawerIcon icon={faPortalExit} color={color} />,
    [],
  );

  const handleRenderDrawer = useCallback(
    (props: DrawerContentComponentProps) => {
      const currentRouteName = getFocusedRouteNameFromRoute(
        props.descriptors[props.state.routes[props.state.index].key].route,
      );
      return (
        <DrawerContentScrollView>
          <Typography variant="h4" pl={2}>
            <FormattedMessage
              id="mobileApp.greeting"
              values={{
                greetingName,
              }}
            />
          </Typography>
          <DrawerItem
            focused={currentRouteName === 'profile'}
            icon={renderProfileIcon}
            label={formatMessage({ id: 'button.profile' })}
            onPress={() => router.push('/profile')}
          />
          {isWarehouseEmployee() && (
            <DrawerItem
              focused={currentRouteName === 'warehouse'}
              icon={renderWarehouseIcon}
              label={formatMessage({ id: 'button.warehouse' })}
              onPress={() => router.push('/warehouse')}
            />
          )}
          {canAskHelp() && (
            <DrawerItem
              icon={renderHelpIcon}
              label={formatMessage({ id: 'button.help' })}
              onPress={() => handleHelp()}
            />
          )}
          <DrawerItem
            focused={currentRouteName === 'logout'}
            icon={renderLogoutIcon}
            label={formatMessage({ id: 'button.logout' })}
            onPress={() => handleLogout()}
          />
        </DrawerContentScrollView>
      );
    },
    [
      canAskHelp,
      formatMessage,
      greetingName,
      handleHelp,
      handleLogout,
      isWarehouseEmployee,
      renderHelpIcon,
      renderLogoutIcon,
      renderProfileIcon,
      renderWarehouseIcon,
    ],
  );

  const tenantState = useTenantStore((state) => state.tenant);

  if (employmentEndDate && dayjs(employmentEndDate).isBefore(dayjs(), 'day')) {
    return (
      <Box flex={1} position="relative" backgroundColor="darkBackground">
        <Box
          position="absolute"
          left={0}
          right={0}
          top={0}
          bottom={0}
          justifyContent="center"
          alignItems="center"
        >
          <Box>
            <Icon icon={faShieldExclamation} color="white" size={104} />
          </Box>
          <Typography color="white" paddingTop={2}>
            <FormattedMessage id="label.noAccessForEmployee" />
          </Typography>
          <Typography
            color="white"
            paddingTop={2}
            textAlign="center"
            marginBottom={2}
          >
            <FormattedMessage
              id="label.noAccessForEmployeeDescription"
              values={{
                name: tenantState?.name,
              }}
            />
          </Typography>
          <Button onPress={logout}>
            <Icon icon={faPortalExit} color="white" />
            &nbsp;
            <FormattedMessage id="button.logout" />
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Drawer
      screenOptions={{
        drawerPosition: 'right',
        headerShown: false,
      }}
      drawerContent={handleRenderDrawer}
    >
      <Drawer.Screen
        name="(tabs)"
        options={{ headerShown: false, title: 'Home' }}
      />
    </Drawer>
  );
};

export default DrawerLayout;
