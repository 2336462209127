import {
  storage,
  useAppStore,
  useTenantStore,
  useUserStore,
} from '@bas/shared/state';
import { IConfig } from '@unleash/proxy-client-react';
import * as localforage from 'localforage';
import { useMemo } from 'react';
import { Platform } from 'react-native';

export const useNativeFlagConfig = () => {
  const userId = useUserStore((state) => state.user?.userId || null);
  const tenantId = useTenantStore((state) => state.tenant?.tenantId || null);
  const deviceId = useAppStore((state) => state.deviceId || null);

  return useMemo(() => {
    const result: IConfig = {
      environment: 'production',
      url: 'https://unleash.bas.app/api/frontend', // Your front-end API URL or the Unleash proxy's URL (https://<proxy-url>/proxy)
      clientKey: `${process.env.EXPO_PUBLIC_UNLEASH_ACCESS_TOKEN}`, // A client-side API token OR one of your proxy's designated client keys (previously known as proxy secrets)
      refreshInterval: 60, // How often (in seconds) the client should poll the proxy for updates
      appName: 'bas-employee-app', // The name of your application. It's only used for identifying your application
      context: {
        userId: userId || undefined,
        sessionId: deviceId || undefined,
        properties: {
          tenantId: tenantId || '',
        },
      },
      storageProvider: {
        save: async (name, data) => {
          if (Platform.OS === 'web') {
            await localforage.setItem(name, JSON.parse(JSON.stringify(data)));
          } else {
            storage.set(name, JSON.stringify(data));
          }
        },
        get: async (name) => {
          let data = null;
          if (Platform.OS === 'web') {
            data = await localforage.getItem(name);
          } else {
            data = storage.getString(name);
            data = data ? JSON.parse(data) : undefined;
          }

          return data;
        },
      },
    };

    return result;
  }, [deviceId, tenantId, userId]);
};
