import {
  StartDrivingInputType,
  StartDrivingInputTypeDefaultValues,
} from '@bas/planning-domain/input-types';
import { Vehicle } from '@bas/planning-domain/models';
import { PublicRoomType } from '@bas/shared/models';
import { MovingJobTaxationRoom } from '@bas/taxation-tool-domain/models';
import { colors } from '@bas/theme';
import { BottomSheetMethods, Typography } from '@bas/ui/native/base';
import {
  BottomSheetProps,
  StepsNavigationProps,
} from '@bas/ui/native/organisms';
import { FormWizardBottomSheet } from '@bas/ui/native/templates';
import { InternalServiceType, ReactHookWizardStep } from '@bas/value-objects';
import { InventoryMovementInputType } from '@bas/wms-domain/input-types';
import {
  usePublicMovingBoxesRequest,
  useReusableMaterialsRequest,
} from '@bas/wms-domain/requests';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { keepPreviousData } from '@tanstack/react-query';
import dayjs from 'dayjs';
import {
  memo,
  ReactElement,
  ReactNode,
  RefObject,
  useCallback,
  useMemo,
} from 'react';
import isEqual from 'react-fast-compare';
import { FieldValues } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import styles from './styles';

type InputType = StartDrivingInputType & {
  materials: InventoryMovementInputType[];
} & FieldValues;

export type StartDrivingToEventFormWizardBottomSheetProps = Omit<
  BottomSheetProps,
  'snapPoints' | 'children'
> & {
  onSubmit: (values: InputType) => void;
  vehicles: Vehicle[];
  onClose?: () => void;
  steps: ReactHookWizardStep<InputType>[];
  disableSubmit?: boolean;
  bottomSheetRef: RefObject<BottomSheetModal & BottomSheetMethods>;
};

type Data = {
  intakeRoom: MovingJobTaxationRoom;
  room: PublicRoomType;
  usedServices: InternalServiceType[];
};

const StartDrivingToEventFormWizardBottomSheet = ({
  onSubmit,
  vehicles,
  onClose,
  disableSubmit,
  steps,
  bottomSheetRef,
}: StartDrivingToEventFormWizardBottomSheetProps): ReactElement => {
  const renderTitle = useCallback(
    (data: Data, title: ReactNode) => (
      <Typography
        variant="h4"
        fontWeight={700}
        overrideColor={colors.white}
        style={styles.title}
      >
        {typeof title === 'string' ? <FormattedMessage id={title} /> : title}
      </Typography>
    ),
    [],
  );

  const { data: boxesData } = usePublicMovingBoxesRequest();

  const { data: reusableMaterialsData } = useReusableMaterialsRequest(
    {
      page: 1,
      perPage: 999999,
    },
    {
      placeholderData: keepPreviousData,
    },
  );
  const reusableMaterials = useMemo(
    () => reusableMaterialsData?.data.member || [],
    [reusableMaterialsData?.data],
  );

  const boxes = useMemo(() => boxesData?.data.member || [], [boxesData?.data]);

  const currentTime = useMemo(() => {
    let result = dayjs();

    const remainder = Math.floor(result.minute() / 15) * 15;
    result = result.set('minutes', remainder);

    return result;
  }, []);

  const defaultValues = {
    ...StartDrivingInputTypeDefaultValues(),
    mileageReports: vehicles.map(({ materialId }) => ({
      materialId,
      mileageStart: undefined,
    })),
    materials: [...reusableMaterials, ...boxes].map(({ inventoryItemId }) => ({
      inventoryItemId,
      quantity: 0,
    })),
    realTravelStartTime: currentTime.toDate(),
  };

  return (
    <FormWizardBottomSheet<
      { stepNavigationProps: Partial<StepsNavigationProps> },
      InputType,
      Data
    >
      name="start-driving-to-event"
      wizardSteps={steps}
      initialValues={defaultValues}
      onSubmit={onSubmit}
      initialStepIndex={0}
      resetFormOnOpen
      successFullFormViewProps={{
        gifUrl: 'https://media.giphy.com/media/QZysw8nnAKS0bWMbD0/giphy.gif',
        title: 'label.startDriving.finished',
        labelId: 'label.safeTravels',
        color: colors.lila[100],
      }}
      extraTemplateProps={{
        stepNavigationProps: {
          dark: true,
          allowCancel: true,
          allowNext: !disableSubmit,
          onCancel: onClose,
          onClose,
        },
      }}
      renderTitle={renderTitle}
      bottomSheetRef={bottomSheetRef}
    />
  );
};

const MemoComponent = memo(
  StartDrivingToEventFormWizardBottomSheet,
  isEqual,
) as typeof StartDrivingToEventFormWizardBottomSheet;
export default MemoComponent;
