import {
  ProfileInputType,
  ProfileInputTypeDefaultValues,
  ProfileInputTypeValidationBuilder,
} from '@bas/authentication-domain/input-types';
import { User } from '@bas/authentication-domain/models';
import { colors } from '@bas/theme';
import { BottomSheetMethods, Box, Typography } from '@bas/ui/native/base';
import { ReactHookFormTextField } from '@bas/ui/native/molecules';
import { BackendErrors, BottomSheetProps } from '@bas/ui/native/organisms';
import { FormBottomSheet } from '@bas/ui/native/templates';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { ReactElement, RefObject, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { ensuredForwardRef } from 'react-use/lib/useEnsuredForwardedRef';

export type ChangeProfileBottomSheetProps = Omit<
  BottomSheetProps,
  'snapPoints' | 'children'
> & {
  onSubmit: (values: ProfileInputType) => Promise<void> | void;
  user: User;
};
const styles = StyleSheet.create({
  flexContainer: {
    flex: 1,
  },
  container: {
    marginBottom: 15,
    paddingLeft: 20,
    paddingRight: 20,
  },
  description: {
    textAlign: 'center',
  },
  avatar: {
    height: '100%',
    width: '100%',
  },
  niceJob: {
    textAlign: 'center',
  },
  avatarContainer: {
    overflow: 'hidden',
    borderRadius: 269,
    height: 268,
    width: 268,
    marginBottom: 29,
    marginTop: 29,
    alignSelf: 'center',
  },
  header: {
    alignSelf: 'center',
    fontSize: 22,
    lineHeight: 29,
    height: 51,
  },
  paddingTop: {
    paddingTop: 24,
  },
  padding: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 31,
    paddingTop: 31,
  },
});

const ChangeProfileBottomSheet = ensuredForwardRef<
  BottomSheetModal & BottomSheetMethods,
  ChangeProfileBottomSheetProps
>(
  (
    { onSubmit, user, ...props }: ChangeProfileBottomSheetProps,
    ref: RefObject<BottomSheetModal & BottomSheetMethods>,
  ): ReactElement => {
    const handleFinalize = useCallback(() => {
      ref?.current?.close();
      ref?.current?.forceClose();
      ref?.current?.dismiss();
    }, [ref]);

    const { formatMessage } = useIntl();
    return (
      <FormBottomSheet<ProfileInputType>
        bottomSheetRef={ref}
        disablePadding
        onSubmit={onSubmit}
        useProvider
        resetFormOnOpen
        onCancel={handleFinalize}
        defaultValues={{
          ...ProfileInputTypeDefaultValues(),
          ...user.personName,
          emailAddress: user.emailAddress,
        }}
        hideBackendErrors
        validationSchema={ProfileInputTypeValidationBuilder}
        successFullFormViewProps={{
          gifUrl: 'https://media.giphy.com/media/pdSncNyYgaH0wqaCqp/giphy.gif',

          title: 'label.changeProfile.finished',
          color: colors.lila[100],
          labelId: 'label.profileIsChanged',
          greetingName: user.personName.fullName,
        }}
        {...props}
      >
        <View style={styles.flexContainer}>
          <Typography
            fontWeight={700}
            overrideColor={colors.white}
            style={styles.header}
            textAlign="center"
          >
            <FormattedMessage id="label.changeProfile" />
          </Typography>
          <View>
            <View style={styles.container}>
              <Box rowGap={3} flex={1}>
                <BackendErrors />
                <Box>
                  <ReactHookFormTextField
                    name="firstName"
                    autoComplete="off"
                    light
                    label={formatMessage({ id: 'label.firstName' })}
                  />
                </Box>
                <Box>
                  <ReactHookFormTextField
                    name="middleName"
                    autoComplete="off"
                    light
                    label={formatMessage({ id: 'label.middleName' })}
                  />
                </Box>
                <Box>
                  <ReactHookFormTextField
                    name="lastName"
                    autoComplete="off"
                    light
                    label={formatMessage({ id: 'label.lastName' })}
                  />
                </Box>
                <Box>
                  <ReactHookFormTextField
                    name="emailAddress"
                    autoComplete="off"
                    light
                    keyboardType="email-address"
                    label={formatMessage({ id: 'label.emailAddress' })}
                  />
                </Box>
              </Box>
            </View>
          </View>
        </View>
      </FormBottomSheet>
    );
  },
);

export default ChangeProfileBottomSheet;
