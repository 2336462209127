import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ResetPasswordMutationProps = {
  code: string;
  password: string;
  resetRequestId: Uuid;
};

export const ResetPasswordMutation = async ({
  ...values
}: ResetPasswordMutationProps): Promise<AxiosResponse> =>
  axios.post('/api/users/reset-password', { ...values });

export const useResetPasswordMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ResetPasswordMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ResetPasswordMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ResetPasswordMutationProps
  >({
    mutationFn: ResetPasswordMutation,
    ...options,
    throwOnError: (error) => (error?.response?.status || 500) >= 500,
  });
