import { Uuid } from '@bas/value-objects';
import * as Yup from 'yup';

export interface UpdateInvoiceInputType {
  templateId: Uuid;
  identityId?: Uuid | null;
  useIdentities: boolean;
}

export const UpdateInvoiceInputTypeBuilder =
  (): Yup.ObjectSchema<UpdateInvoiceInputType> =>
    Yup.object({
      templateId: Yup.string().required(),
      useIdentities: Yup.boolean().required(),
      identityId: Yup.string().when('useIdentities', {
        is: true,
        then: (schema) => schema.required(),
      }),
    });
