import { ClaCompensationRuleType, ClaHourType } from '@bas/hrm-domain/models';
import { formatDate } from '@bas/shared/utils';
import {
  EmployeeContractType,
  ErrorResponse,
  NullableMoney,
  Uuid,
} from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ChangeClaCompensationRuleMutationProps = {
  ruleId: Uuid;
  name: string;
  description: string;
  contractType: EmployeeContractType;
  employeeType: 'field-staff' | 'backoffice-staff' | 'all';
  attributeId: Uuid | undefined;
  excludedAttributes: Uuid[];
  enabledFrom: Date;
  ruleData:
    | {
        ruleType: ClaCompensationRuleType.ClaSingleDayTrip;
        hourType: ClaHourType;
        calculatePer: 'hour' | 'day';
        hoursMoreThan: number | null;
        hoursLessThan: number | null;
        startTimeAfter: number;
        startTimeBefore: number;
        endTimeAfter: number;
        endTimeBefore: number;
        applyCompensationAfterTime: number;
        applyCompensationBeforeTime: number;
        compensation: NullableMoney;
      }
    | {
        ruleType: ClaCompensationRuleType.ClaTravelCost;
        nonReimbursedInitialKilometers: number;
        maxReimbursedKilometers: number;
        compensationPerKilometer: NullableMoney;
      };
};

export const ChangeClaCompensationRuleMutation = async ({
  enabledFrom,
  ruleId,
  ...values
}: ChangeClaCompensationRuleMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/hrm/cla/compensation-rules/${ruleId}`, {
    ...values,
    enabledFrom: formatDate(enabledFrom),
  });

export const useChangeClaCompensationRuleMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeClaCompensationRuleMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ChangeClaCompensationRuleMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeClaCompensationRuleMutationProps
  >({
    mutationFn: ChangeClaCompensationRuleMutation,
    throwOnError: false,
    ...options,
  });
