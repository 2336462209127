import { formatDate, formatDateTime } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type FinalizeInvoicesInBulkMutationProps = {
  invoices: {
    invoiceId: Uuid;
    invoicedAt?: Date | null;
    expiresAt?: Date | null;
    reportingYear?: number;
    reportingPeriod?: number;
  }[];
  sendInvoices: boolean;
};

export const FinalizeInvoicesInBulkMutation = async ({
  invoices,
  ...values
}: FinalizeInvoicesInBulkMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/{tenantId}/invoices/finalize`, {
    invoices: invoices.map(({ invoicedAt, expiresAt, ...invoice }) => ({
      ...invoice,
      invoicedAt: invoicedAt ? formatDateTime(invoicedAt) : null,
      expiresAt: expiresAt ? formatDate(expiresAt) : null,
    })),
    ...values,
  });

export const useFinalizeInvoicesInBulkMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    FinalizeInvoicesInBulkMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  FinalizeInvoicesInBulkMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    FinalizeInvoicesInBulkMutationProps
  >({
    mutationFn: FinalizeInvoicesInBulkMutation,
    throwOnError: false,
    ...options,
  });
