import {
  CalculatedTotalsForAcceptanceQuote,
  QuoteLine,
} from '@bas/financial-domain/models';
import {
  Checkbox,
  FormattedCurrency,
  OptionalLabel,
  Skeleton,
} from '@bas/ui/native/atoms';
import { Box, Typography } from '@bas/ui/native/base';
import { Accordion } from '@bas/ui/native/molecules';
import { Discount, Uuid } from '@bas/value-objects';
import { ReactElement, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './styles';

export type MobileQuoteTableForAcceptanceProps = {
  acceptedLines: Uuid[];
  setAcceptedLines: (newValue: Uuid[]) => void;
  reloadTotals: (newValue: Uuid[]) => Promise<void> | void;
  loadingTotals?: boolean;
  totals: CalculatedTotalsForAcceptanceQuote;
  hasOptionalLines: boolean;
  showTotals?: boolean;
  showQuantity?: boolean;
  showTotalPerLine?: boolean;
  lines: QuoteLine[];
  discount?: Discount;
};

const MobileQuoteTableForAcceptance = ({
  setAcceptedLines,
  reloadTotals,
  loadingTotals,
  totals,
  hasOptionalLines,
  acceptedLines,
  showTotals,
  showQuantity,
  showTotalPerLine,
  lines,
  discount,
}: MobileQuoteTableForAcceptanceProps): ReactElement => {
  const renderSummary = useCallback(
    (open: boolean, line: QuoteLine) => (
      <Box alignItems="center" flexDirection="row" flex={1}>
        {hasOptionalLines && (
          <Box>
            <Checkbox
              desktop
              size={24}
              disabled={!line.optional}
              checked={!!acceptedLines && acceptedLines.includes(line.lineId)}
              onCheck={(checked) => {
                const newValue = [...acceptedLines];
                const index = newValue.indexOf(line.lineId);

                if (checked) {
                  newValue.push(line.lineId);
                } else {
                  newValue.splice(index, 1);
                }

                reloadTotals(newValue);
                setAcceptedLines(newValue);
              }}
            />
          </Box>
        )}

        <Box flex={1}>
          <Typography numberOfLines={open ? undefined : 1}>
            {line.description}
          </Typography>
        </Box>
        <Box>
          <Typography>
            <FormattedCurrency
              {...line[showTotalPerLine ? 'totalPrice' : 'pricePerUnit']}
            />
          </Typography>
        </Box>
      </Box>
    ),
    [
      acceptedLines,
      hasOptionalLines,
      reloadTotals,
      setAcceptedLines,
      showTotalPerLine,
    ],
  );

  return (
    <Box>
      {lines.map((line) => (
        <Accordion
          key={line.lineId}
          style={styles.accordion}
          summary={(open) => renderSummary(open, line)}
        >
          <Box flex={1}>
            <Box>{line.optional && <OptionalLabel />}</Box>
            {showQuantity && (
              <Box
                mt={2}
                width="100%"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Box>
                  <Typography variant="subtitle1" color="subtitle1Color">
                    <FormattedMessage id="label.quantity" />
                  </Typography>
                </Box>
                <Box>
                  <Typography>{`${line.quantity}x`}</Typography>
                </Box>
              </Box>
            )}
            <Box
              mt={2}
              width="100%"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box>
                <Typography variant="subtitle1" color="subtitle1Color">
                  <FormattedMessage id="label.pricePerUnit" />
                </Typography>
              </Box>
              <Box>
                <Typography>
                  <FormattedCurrency {...line.pricePerUnit} />
                </Typography>
              </Box>
            </Box>
            {showTotalPerLine && (
              <Box
                mt={2}
                width="100%"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Box>
                  <Typography variant="subtitle1" color="subtitle1Color">
                    <FormattedMessage id="label.total" />
                  </Typography>
                </Box>
                <Box>
                  <Typography>
                    <FormattedCurrency {...line.totalPrice} />
                  </Typography>
                </Box>
              </Box>
            )}
            <Box
              mt={2}
              width="100%"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box>
                <Typography variant="subtitle1" color="subtitle1Color">
                  <FormattedMessage id="label.vat" />
                </Typography>
              </Box>
              <Box>
                <Typography>{`${line.vatPercentage.percentage}%`}</Typography>
              </Box>
            </Box>
          </Box>
        </Accordion>
      ))}
      {showTotals && (
        <Box flex={1}>
          {discount &&
            discount.percentage &&
            totals.discount &&
            totals.discount.amount !== 0 && (
              <Box
                mt={2}
                width="100%"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Box>
                  <Typography>
                    {`${discount.description} ${discount.percentage}%`}
                  </Typography>
                </Box>
                <Box>
                  {loadingTotals ? (
                    <Skeleton width={100} height={24} />
                  ) : (
                    <Typography textAlign="right">
                      <FormattedCurrency {...totals.discount} />
                    </Typography>
                  )}
                </Box>
              </Box>
            )}
          <Box
            mt={2}
            width="100%"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box>
              <Typography>
                <FormattedMessage id="label.subTotal" />
              </Typography>
            </Box>
            <Box>
              {loadingTotals ? (
                <Skeleton width={100} height={24} />
              ) : (
                <Typography textAlign="right">
                  <FormattedCurrency {...totals.totalExcludingVat} />
                </Typography>
              )}
            </Box>
          </Box>
          {totals.vatAmounts
            .sort(
              ({ vatPercentage: vatA }, { vatPercentage: vatB }) =>
                parseInt(vatA, 10) - parseInt(vatB, 10),
            )
            .map(({ vatPercentage, vatAmount }) => (
              <Box
                key={vatPercentage}
                mt={2}
                width="100%"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Box>
                  <Typography>
                    {`${vatPercentage}% `}
                    <FormattedMessage id="label.vat" />
                  </Typography>
                </Box>
                <Box>
                  {loadingTotals ? (
                    <Skeleton width={100} height={24} />
                  ) : (
                    <Typography textAlign="right">
                      <FormattedCurrency {...vatAmount} />
                    </Typography>
                  )}
                </Box>
              </Box>
            ))}
          <Box
            mt={2}
            width="100%"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box>
              <Typography fontWeight={700}>
                <FormattedMessage id="label.total" />
              </Typography>
            </Box>
            <Box>
              {loadingTotals ? (
                <Skeleton width={100} height={24} />
              ) : (
                <Typography textAlign="right">
                  <FormattedCurrency {...totals.totalIncludingVat} />
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default MobileQuoteTableForAcceptance;
