import { isStorageEvent } from './StorageEvent';
import { StorageCreatedEvent, StorageEventType } from '../../types';

export function isStorageCreatedEvent(
  object: unknown,
): object is StorageCreatedEvent {
  return (
    isStorageEvent(object) &&
    object.eventType === StorageEventType.StorageCreatedEvent
  );
}
