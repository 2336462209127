import { FuelCard } from '@bas/planning-domain/models';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ChangeVehicleFuelCardMutationProps = {
  materialId: Uuid;
  fuelCard?: FuelCard | null;
};

export const ChangeVehicleFuelCardMutation = async ({
  materialId,
  ...values
}: ChangeVehicleFuelCardMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/vehicles/${materialId}/change-fuel-card`, {
    materialId,
    ...values,
  });

export const useChangeVehicleFuelCardMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeVehicleFuelCardMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ChangeVehicleFuelCardMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeVehicleFuelCardMutationProps
  >({
    mutationFn: ChangeVehicleFuelCardMutation,
    throwOnError: false,
    ...options,
  });
