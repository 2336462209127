import { EmailAddress, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type SignAcceptanceQuoteMutationProps = {
  quoteId: Uuid;
  relationId: Uuid;
  confirmationCode: string;
  name: string;
  emailAddress: EmailAddress;
  notes?: string | null;
  signature: string;
  ipAddress: string;
  browser: string;
  acceptedLines: Uuid[];
};

export const SignAcceptanceQuoteMutation = async ({
  quoteId,
  relationId,
  confirmationCode,
  acceptedLines,
  ...values
}: SignAcceptanceQuoteMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/public/{tenantId}/quotes/acceptance/${quoteId}/${relationId}/${confirmationCode}/accept`,
    {
      quoteId,
      relationId,
      code: confirmationCode,
      acceptedLines: acceptedLines.map((lineId) => ({ lineId })),
      ...values,
    },
  );

export const useSignAcceptanceQuoteMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    SignAcceptanceQuoteMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  SignAcceptanceQuoteMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    SignAcceptanceQuoteMutationProps
  >({
    mutationFn: SignAcceptanceQuoteMutation,
    throwOnError: false,
    ...options,
  });
