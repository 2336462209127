import { FinancialDocumentLinesEditor } from '@bas/financial-domain/native/organisms';
import { useTenantStore } from '@bas/shared/state';
import { QuoteInputType } from '@bas/taxation-tool-domain/input-types';
import {
  DocumentTemplate,
  DocumentTranslation,
} from '@bas/tenant-domain/models';
import { useDocumentTemplatesRequest } from '@bas/tenant-domain/requests';
import { Box } from '@bas/ui/native/base';
import {
  ReactHookFormDatePickerField,
  ReactHookFormDropdown,
  ReactHookFormTextField,
} from '@bas/ui/native/molecules';
import { DocumentType, Uuid } from '@bas/value-objects';
import { memo, ReactElement, useEffect, useMemo } from 'react';
import isEqual from 'react-fast-compare';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { NestableScrollContainer } from 'react-native-draggable-flatlist';
import { MobileTaxationStepTemplate } from '../MobileTaxationStepTemplate';

const MovingJobQuoteStepTemplateContent = memo(
  ({
    items,
    currentTranslation,
    isLoadingTemplates,
    templates,
    useIdentities,
    identityId,
  }: {
    items: string[];
    currentTranslation?: DocumentTranslation;
    isLoadingTemplates: boolean;
    templates: DocumentTemplate[];
    useIdentities: boolean;
    identityId?: Uuid | null;
  }) => {
    const { formatMessage } = useIntl();

    return (
      <MobileTaxationStepTemplate
        primary={<FormattedMessage id="mobileApp.intake.movingJob.theQuote" />}
        secondary={<FormattedMessage id="label.quote" />}
        ScrollComponent={NestableScrollContainer}
      >
        <Box
          flexDirection="row"
          flexWrap="wrap"
          style={{ marginLeft: -24, marginTop: -24 }}
          pb={3}
        >
          <Box
            flexBasis={{ phone: '100%', tablet: '50%' }}
            width={{ phone: '100%', tablet: '50%' }}
            pl={3}
            pt={3}
          >
            {!isLoadingTemplates && (
              <ReactHookFormDropdown
                name="quote.templateId"
                label={formatMessage({ id: 'label.templateId' })}
                disabled={isLoadingTemplates || (useIdentities && !identityId)}
                items={templates.map((t) => ({
                  id: t.documentTemplateId,
                  label: t.name,
                }))}
              />
            )}
          </Box>
          <Box
            flexBasis={{ phone: '100%', tablet: '50%' }}
            width={{ phone: '100%', tablet: '50%' }}
            pl={3}
            pt={3}
          >
            <ReactHookFormDatePickerField
              name="quote.expiresAt"
              label={formatMessage({ id: 'label.expiresAt' })}
            />
          </Box>
          {currentTranslation?.freeTextFields.map((freeTextField) => (
            <Box
              flexBasis={{ phone: '100%', tablet: '50%' }}
              width={{ phone: '100%', tablet: '50%' }}
              pl={3}
              pt={3}
              key={freeTextField}
            >
              <ReactHookFormTextField
                name={`quote.freeText.${freeTextField}`}
                autoComplete="off"
                label={
                  !Number.isNaN(Number.parseInt(freeTextField, 10) + 1)
                    ? formatMessage(
                        {
                          id: 'label.freeTextTitle',
                        },
                        {
                          index: Number.parseInt(freeTextField, 10) + 1,
                        },
                      )
                    : freeTextField
                }
                multiline
              />
            </Box>
          ))}
          <Box
            flexBasis={{ phone: '100%', tablet: '50%' }}
            width={{ phone: '100%', tablet: '50%' }}
            pl={3}
            pt={3}
          >
            <ReactHookFormTextField
              name="customerNotes"
              autoComplete="off"
              label={formatMessage({ id: 'label.customerNotes' })}
              multiline
            />
          </Box>
        </Box>
        <Box>
          <FinancialDocumentLinesEditor documentType="quote" />
        </Box>
      </MobileTaxationStepTemplate>
    );
  },
  isEqual,
);

const MovingJobQuoteStepTemplate = (): ReactElement => {
  const tenant = useTenantStore((state) => state.tenant);

  const { setValue } = useFormContext<QuoteInputType>();

  const [templateId, identityId, language] = useWatch<
    QuoteInputType,
    ['quote.templateId', 'identityId', 'customer.language']
  >({
    name: ['quote.templateId', 'identityId', 'customer.language'],
  });

  const { data: templatesData, isPending: isLoadingTemplates } =
    useDocumentTemplatesRequest({
      perPage: 99999,
      'documentType.value': DocumentType.QUOTE,
    });

  const templates = useMemo(
    () =>
      (templatesData?.data.member || []).filter(
        (p) => !tenant?.useIdentities || p.identityId === identityId,
      ),
    [identityId, templatesData?.data, tenant?.useIdentities],
  );

  const currentTemplate = useMemo(
    () => templates.find((t) => t.documentTemplateId === templateId),
    [templateId, templates],
  );

  const currentTranslation = useMemo(
    () =>
      currentTemplate?.translations?.find(
        (translation) => translation.language === language,
      ),
    [currentTemplate?.translations, language],
  );

  useEffect(() => {
    if (
      templateId &&
      (templatesData?.data?.member?.length || 0) > 0 &&
      !templates.find((t) => t.documentTemplateId === templateId)
    ) {
      setValue('quote.templateId', '');
    }
  }, [templates, templateId, setValue, templatesData?.data]);

  const items = useMemo(
    () => [
      'quote.templateId',
      'quote.expiresAt',
      ...(currentTranslation?.freeTextFields || []).map(
        (freeTextField) => `quote.freeText.${freeTextField}`,
      ),
      'customerNotes',
      'quote',
    ],
    [currentTranslation?.freeTextFields],
  );

  return (
    <MovingJobQuoteStepTemplateContent
      items={items}
      currentTranslation={currentTranslation}
      isLoadingTemplates={isLoadingTemplates}
      templates={templates}
      useIdentities={tenant?.useIdentities || false}
      identityId={identityId}
    />
  );
};

const MemoMovingJobQuoteStepTemplate = memo(
  MovingJobQuoteStepTemplate,
  () => true,
);

export default MemoMovingJobQuoteStepTemplate;
