import { MovingJobSkill, OfficeSkill } from '@bas/hrm-domain/models';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ChangeEmployeeSkillsMutationProps = {
  employeeId: Uuid;
  skills: (OfficeSkill | MovingJobSkill)[];
};

export const ChangeEmployeeSkillsMutation = async ({
  employeeId,
  ...values
}: ChangeEmployeeSkillsMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/hrm/employees/${employeeId}/update-skills`, {
    employeeId,
    ...values,
  });

export const useChangeEmployeeSkillsMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeEmployeeSkillsMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ChangeEmployeeSkillsMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeEmployeeSkillsMutationProps
  >({
    mutationFn: ChangeEmployeeSkillsMutation,
    throwOnError: false,
    ...options,
  });
