import dayjs from 'dayjs';
import { ClaAllowanceRule } from '../types';

export const isClaAllowanceRuleEnabled = (row: ClaAllowanceRule) => {
  if (row.enabledFrom && row.enabledUntil) {
    return (
      dayjs(row.enabledFrom).isBefore(dayjs(), 'day') &&
      dayjs(row.enabledUntil).isAfter(dayjs(), 'day')
    );
  }

  if (row.enabledFrom) {
    return dayjs(row.enabledFrom).isBefore(dayjs(), 'day');
  }

  return true;
};
