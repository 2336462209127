import { Uuid } from '@bas/value-objects';
import * as Yup from 'yup';

export interface PositionStorageForPickupInputType {
  selectedStorageId: Uuid | null;
  currentWarehouseId: Uuid | null;
  currentLocationId: Uuid | null;
  employeeId: Uuid | null;
}

export const positionStorageForPickupInputTypeDefaultValues =
  (): PositionStorageForPickupInputType => ({
    selectedStorageId: null,
    currentWarehouseId: null,
    currentLocationId: null,
    employeeId: '',
  });

export const positionStorageForPickupInputTypeValidationBuilder =
  (): Yup.ObjectSchema<PositionStorageForPickupInputType> =>
    Yup.object({
      selectedStorageId: Yup.string().required().label('label.storage'),
      currentWarehouseId: Yup.string()
        .required()
        .label('label.currentWarehouse'),
      currentLocationId: Yup.string().required().label('label.currentLocation'),
      employeeId: Yup.string().required().label('label.employee'),
    });
