import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type DisableInvoiceRemindersForRelationMutationProps = {
  relationId: Uuid;
};

export const DisableInvoiceRemindersForRelationMutation = async ({
  relationId,
  ...values
}: DisableInvoiceRemindersForRelationMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/relations/${relationId}/disable-invoice-reminders`,
    {
      relationId,
      ...values,
    },
  );

export const useDisableInvoiceRemindersForRelationMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    DisableInvoiceRemindersForRelationMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  DisableInvoiceRemindersForRelationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    DisableInvoiceRemindersForRelationMutationProps
  >({
    mutationFn: DisableInvoiceRemindersForRelationMutation,
    throwOnError: false,
    ...options,
  });
