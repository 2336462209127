/* eslint-disable no-underscore-dangle */
import { Conversation } from '@bas/communication-domain/models';
import {
  useEmployeeOpenedConversationMutation,
  useSendMessageToConversationMutation,
} from '@bas/communication-domain/mutations';
import { useInfiniteMessagesByConversationIdRequestQuery } from '@bas/communication-domain/requests';
import { GiftedChatIMessage } from '@bas/shared/models';
import {
  convertGiftedChatMessageToMessageMetadata,
  convertMessageToChatMessage,
} from '@bas/shared/utils';
import { colors } from '@bas/theme';
import 'dayjs/locale/nl';
import bgChat from '@bas/ui/assets/Resources/bgChat.png';
import { Skeleton } from '@bas/ui/native/atoms';
import { Box } from '@bas/ui/native/base';
import {
  GiftedChatActions,
  GiftedChatCustomView,
} from '@bas/ui/native/molecules';
import { Uuid } from '@bas/value-objects';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons/faArrowRight';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Image, View } from 'react-native';
import {
  Avatar,
  Bubble,
  Composer,
  GiftedChat,
  InputToolbar,
  Message,
  Send,
  SystemMessage,
  Time,
} from 'react-native-gifted-chat';
import { SafeAreaView } from 'react-native-safe-area-context';
import { v7 } from 'uuid';

export type MobileEmployeeChatScreenProps = {
  conversation: Conversation;
  employeeId: Uuid;
};

const MobileEmployeeChatScreen = ({
  conversation,
  employeeId,
}: MobileEmployeeChatScreenProps): ReactElement => {
  const [handlingOpen, setHandlingOpen] = useState<boolean>(false);
  const [handledOpen, setHandledOpen] = useState<boolean>(false);
  const [isSending, setIsSending] = useState<boolean>(false);

  const {
    mutateAsync: employeeOpensConversation,
    isPending: isOpeningConversation,
  } = useEmployeeOpenedConversationMutation();
  const { mutateAsync: sendMessage } = useSendMessageToConversationMutation();

  useEffect(() => {
    (async () => {
      try {
        if (!handlingOpen && !handledOpen && !isOpeningConversation) {
          setHandlingOpen(true);
          await new Promise((r) => {
            setTimeout(r, 2000);
          });
          await employeeOpensConversation({
            conversationId: conversation.conversationId,
            employeeId,
          });
          setHandlingOpen(false);
          setHandledOpen(true);
        }
      } catch (e) {
        setHandledOpen(true);
        // ignore
      }
    })();
  }, [
    isOpeningConversation,
    employeeOpensConversation,
    conversation.conversationId,
    employeeId,
    handledOpen,
    setHandledOpen,
    handlingOpen,
    setHandlingOpen,
  ]);

  const {
    isFetchingNextPage,
    isFetching,
    isLoading,
    data: messagesData,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteMessagesByConversationIdRequestQuery(
    {
      conversationId: conversation.conversationId ?? '',
    },
    {
      enabled: !!conversation.conversationId,
    },
  );

  const messages: GiftedChatIMessage[] = useMemo(
    () =>
      (messagesData?.pages?.map((page) => page.data.member).flat() || [])
        .map(convertMessageToChatMessage)
        .filter(
          (value, index, array) =>
            array.findIndex(({ _id }) => _id === value._id) === index,
        ),
    [messagesData?.pages],
  );

  const onSend = useCallback(
    async (messagesToSend: GiftedChatIMessage[] = []) => {
      setIsSending(true);
      await Promise.all(
        messagesToSend.map(async (message) => {
          setHandledOpen(false);
          await sendMessage({
            conversationId: conversation.conversationId ?? '',
            employeeId,
            content: message.text || '',
            messageId: typeof message._id === 'string' ? message._id : v7(),
            messageMetadata: convertGiftedChatMessageToMessageMetadata(message),
          });
        }),
      );
      await refetch();
      setIsSending(false);
    },
    [conversation.conversationId, employeeId, refetch, sendMessage],
  );

  if (isLoading) {
    return (
      <SafeAreaView
        style={{
          flex: 1,
          position: 'relative',
          backgroundColor: colors.lila[400],
        }}
      >
        <Image
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            height: '100%',
            width: '100%',
            resizeMode: 'repeat',
          }}
          source={bgChat}
          resizeMode="repeat"
        />
        <Box alignSelf="flex-start" width="100%" height="100%" flex={1}>
          <Skeleton
            style={{
              marginLeft: 20,
              marginBottom: 12,
              alignSelf: 'flex-start',
            }}
            height={100}
            width={250}
          />
          <Skeleton
            style={{ marginRight: 20, marginBottom: 12, alignSelf: 'flex-end' }}
            height={100}
            width={250}
          />
          <Skeleton
            style={{
              marginLeft: 20,
              marginBottom: 12,
              alignSelf: 'flex-start',
            }}
            height={100}
            width={250}
          />
          <Skeleton
            style={{ marginRight: 20, marginBottom: 12, alignSelf: 'flex-end' }}
            height={100}
            width={250}
          />
          <Skeleton
            style={{
              marginLeft: 20,
              marginBottom: 12,
              alignSelf: 'flex-start',
            }}
            height={100}
            width={250}
          />
          <Skeleton
            style={{ marginRight: 20, marginBottom: 12, alignSelf: 'flex-end' }}
            height={100}
            width={250}
          />
        </Box>
      </SafeAreaView>
    );
  }

  return (
    <SafeAreaView
      style={{
        flex: 1,
        position: 'relative',
        backgroundColor: colors.lila[400],
      }}
    >
      <Image
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          height: '100%',
          width: '100%',
          resizeMode: 'repeat',
        }}
        source={bgChat}
        resizeMode="repeat"
      />
      <GiftedChat<GiftedChatIMessage>
        user={{
          _id: employeeId,
        }}
        bottomOffset={36}
        placeholder=""
        alwaysShowSend
        messages={messages}
        locale="nl"
        timeFormat="HH:mm"
        showAvatarForEveryMessage
        onSend={onSend}
        infiniteScroll
        onLoadEarlier={fetchNextPage}
        isLoadingEarlier={isFetchingNextPage || isFetching}
        loadEarlier={hasNextPage}
        renderActions={(props) => (
          <GiftedChatActions onSend={onSend} {...props} />
        )}
        renderAvatar={(props) => (
          <Avatar
            {...props}
            containerStyle={{
              left: {
                marginLeft: 0,
                marginRight: 0,
              },
            }}
            imageStyle={{
              left: { height: 40, width: 40, borderRadius: 100 },
              right: { height: 40, width: 40, borderRadius: 100 },
            }}
          />
        )}
        renderChatFooter={() => <View style={{ height: 32 }} />}
        renderSystemMessage={(props) => (
          <SystemMessage
            {...props}
            containerStyle={{
              backgroundColor: colors.lila[300],
              paddingTop: 12,
              paddingBottom: 12,
              marginBottom: 24,
              marginTop: 41,
            }}
            textStyle={{
              color: colors.lila[800],
              fontFamily: 'DM_Sans_400_normal',
              fontSize: 16,
              lineHeight: 22,
            }}
          />
        )}
        renderSend={(props) => (
          <Send
            {...props}
            containerStyle={{
              backgroundColor: colors.blue[500],
              borderRadius: 3,
              height: 40,
              width: 40,
              justifyContent: 'center',
              alignItems: 'center',
              marginLeft: 4,
            }}
            disabled={isSending}
          >
            <FontAwesomeIcon
              color={colors.white}
              size={24}
              icon={faArrowRight}
            />
          </Send>
        )}
        renderComposer={(props) => (
          <Composer
            {...props}
            disableComposer={isSending}
            composerHeight={40}
            textInputStyle={{
              borderRadius: 3,
              backgroundColor: colors.white,
              padding: 9,
              paddingTop: 11,
              color: colors.lila[800],
            }}
          />
        )}
        renderInputToolbar={(props) => (
          <InputToolbar
            {...props}
            primaryStyle={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}
            containerStyle={{
              backgroundColor: colors.lila[600],
              paddingTop: 12,
              paddingBottom: 12,
              paddingLeft: 20,
              paddingRight: 20,
              minHeight: 80,
            }}
          />
        )}
        renderMessage={(props) => (
          <Message
            {...props}
            containerStyle={{
              left: { marginBottom: 12, marginLeft: 20 },
              right: { marginBottom: 12, marginRight: 20 },
            }}
          />
        )}
        renderBubble={(props) => (
          <Bubble
            {...props}
            wrapperStyle={{
              left: {
                backgroundColor: colors.white,
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 8,
                paddingTop: 13,
                paddingRight: 11,
                paddingBottom: 4,
                paddingLeft: 16,
                minWidth: 120,
              },
              right: {
                backgroundColor: colors.white,
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 0,
                paddingTop: 13,
                paddingRight: 16,
                paddingLeft: 16,
                paddingBottom: 4,
                minWidth: 120,
              },
            }}
            textStyle={{
              right: {
                color: colors.black,
                fontFamily: 'DM_Sans_400_normal',
                fontSize: 16,
                lineHeight: 22,
              },
              left: {
                color: colors.black,
                fontFamily: 'DM_Sans_400_normal',
                fontSize: 16,
                lineHeight: 22,
              },
            }}
          />
        )}
        renderTime={(props) => (
          <Time
            {...props}
            containerStyle={{
              left: {
                marginLeft: 'auto',
              },
              right: {
                marginRight: 'auto',
              },
            }}
          />
        )}
        renderCustomView={(props) => <GiftedChatCustomView {...props} />}
        timeTextStyle={{
          left: {
            color: colors.lila[600],
            fontFamily: 'DM_Sans_400_normal',
            fontSize: 14,
            lineHeight: 18,
          },
          right: {
            color: colors.lila[600],
            fontFamily: 'DM_Sans_400_normal',
            fontSize: 14,
            lineHeight: 18,
          },
        }}
      />
    </SafeAreaView>
  );
};

export default MobileEmployeeChatScreen;
