import * as Yup from 'yup';
import dayjs from 'dayjs';

export interface EmployeeCertificateInputType {
  certificate: string;
  validFrom?: Date | null;
  validUntil?: Date | null;
}

export const EmployeeCertificateInputTypeDefaultValues =
  (): EmployeeCertificateInputType => ({
    certificate: '',
    validFrom: null,
    validUntil: null,
  });

export const EmployeeCertificateInputTypeValidatorBuilder =
  (): Yup.ObjectSchema<EmployeeCertificateInputType> =>
    Yup.object({
      certificate: Yup.string().required().label('label.certificate'),
      validFrom: Yup.date().nullable().required().label('label.validFrom'),
      validUntil: Yup.date()
        .nullable()
        .required()
        .when(['validFrom'], ([validFrom], schema) =>
          schema.min(dayjs(validFrom).clone().add(1, 'day').toDate()),
        )
        .label('label.validUntil'),
    });
