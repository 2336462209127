import { FinancialDocumentLinesEditorInputType } from '@bas/financial-domain/input-types';
import { calculateTotals, FinancialCalculationResult } from '@bas/shared/utils';
import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useWatch } from 'react-hook-form';

export const useCalculateTotals = (documentType: 'quote' | 'invoice') => {
  const [totals, setTotals] = useState<FinancialCalculationResult>({
    discountAmount: 0,
    totalExcludingVat: 0,
    vats: {},
    totalIncludingVat: 0,
  });
  const lines = useWatch({ name: `${documentType}.lines` });

  const [includingVat, shouldChargeVat] = useWatch<
    FinancialDocumentLinesEditorInputType,
    [
      'quote.includingVat' | 'invoice.includingVat',
      'quote.shouldChargeVat' | 'invoice.shouldChargeVat',
      'quote.totalIncludingVat.currency' | 'invoice.totalIncludingVat.currency',
    ]
  >({
    name: [
      `${documentType}.includingVat`,
      `${documentType}.shouldChargeVat`,
      `${documentType}.totalIncludingVat.currency`,
    ],
  });

  const discount = useWatch<
    FinancialDocumentLinesEditorInputType,
    'quote.discount' | 'invoice.discount'
  >({
    name: `${documentType}.discount`,
  });

  useEffect(() => {
    const newTotals = calculateTotals(
      (lines || []).filter(({ created }: { created: boolean }) => created),
      includingVat,
      shouldChargeVat,
      discount?.percentage || 0,
    );

    if (isEqual(newTotals, totals)) {
      return;
    }

    setTotals(newTotals);
  }, [discount?.percentage, includingVat, lines, shouldChargeVat, totals]);

  return totals;
};
