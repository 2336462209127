import { RootStackParamList } from '@bas/employee-app/value-objects';
import { usePreloadData } from '@bas/shared/hooks';
import { useTaxationToolStore, useTenantStore } from '@bas/shared/state';
import { MovingJobTaxationWizardInput } from '@bas/taxation-tool-domain/models';
import { useMovingJobTaxationTool } from '@bas/taxation-tool-domain/native/hooks';
import { HelpAndNotesBottomSheet } from '@bas/taxation-tool-domain/native/organisms';
import { BottomSheetMethods } from '@bas/ui/native/base';
import { useOnSubmitMovingJobTaxationWizard } from '@bas/ui/native/hooks';
import { ReactHookWizard, WizardTemplateProps } from '@bas/ui/native/organisms';
import {
  AppErrorBoundaryScreen,
  ExtraReactHookWizardWithStepsNavigationTemplateProps,
  LoadingScreen,
  ReactHookWizardWithStepsNavigationTemplate,
} from '@bas/ui/native/templates';
import { ProjectType } from '@bas/value-objects';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import type { Scope } from '@sentry/browser';
import { ErrorBoundary } from '@sentry/react';
import { router, useLocalSearchParams } from 'expo-router';
import { ElementType, ReactElement, useCallback, useMemo, useRef } from 'react';
import { Control, useFormContext } from 'react-hook-form';

export type EmployeeTaxationToolScreenProps = NativeStackScreenProps<
  RootStackParamList,
  'EmployeeTaxationTool'
>;

const ReactHookWizardWithStepsNavigationTemplateSentry = ({
  children,
  ...props
}: WizardTemplateProps<ExtraReactHookWizardWithStepsNavigationTemplateProps>) => {
  const { getValues } = useFormContext();

  const handleFallback = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ({ eventId, error, resetError }: any) => (
      <AppErrorBoundaryScreen
        resetError={resetError}
        eventId={eventId}
        error={error}
      />
    ),
    [],
  );

  const handleSaveError = useCallback(
    (scope: Scope) => {
      scope.setTag('taxation-tool', 'true');
      scope.addAttachment({
        data: JSON.stringify(getValues(), null, 2),
        filename: 'taxation.json',
        contentType: 'application/json',
      });
    },
    [getValues],
  );

  return (
    <ErrorBoundary fallback={handleFallback} beforeCapture={handleSaveError}>
      <ReactHookWizardWithStepsNavigationTemplate {...props}>
        {children}
      </ReactHookWizardWithStepsNavigationTemplate>
    </ErrorBoundary>
  );
};

const EmployeeTaxationToolScreen = (): ReactElement => {
  const { projectId, eventId, projectType } = useLocalSearchParams<{
    projectId: string;
    eventId?: string;
    projectType?: ProjectType;
  }>();

  const helpAndNotesBottomSheetRef = useRef<
    BottomSheetModal & BottomSheetMethods
  >(null);
  const tenant = useTenantStore((state) => state.tenant);
  const activeTaxations = useTaxationToolStore(
    (state) => state.activeTaxations,
  );

  const activeIntake = useMemo(
    () => activeTaxations.find((taxation) => taxation.projectId === projectId),
    [projectId, activeTaxations],
  );

  const onSubmit = useOnSubmitMovingJobTaxationWizard({ projectId, eventId });
  usePreloadData({ projectId });

  const { defaultValues, steps, isLoadingProject, categories } =
    useMovingJobTaxationTool({
      projectId,
      projectType,
      activeIntake,
      tenant,
    });

  const templateProps = useMemo(
    () => ({
      onPressSteps: () => {
        helpAndNotesBottomSheetRef.current?.present();
      },
      categories,
      stepNavigationProps: {
        onCancel: () => {
          if (router.canGoBack()) {
            router.back();
          } else {
            router.replace('/');
          }
        },
      },
    }),
    [categories],
  );

  const updateOrCreateTaxation = useTaxationToolStore(
    (state) => state.updateOrCreateTaxation,
  );

  const handleActiveIntake = useCallback(
    (values: MovingJobTaxationWizardInput, currentStepIndex: number) => {
      updateOrCreateTaxation({
        projectId,
        tenantId: tenant?.tenantId || '',
        projectType,
        currentStep: currentStepIndex || 0,
        startedOn: new Date(),
        projectExists: false,
        name: [
          values.customer.firstName,
          values.customer.middleName,
          values.customer.lastName,
        ]
          .filter((name) => !!name)
          .join(' '),
        location: values.addresses[0].zipCode ? values.addresses[0] : undefined,
      });
    },
    [projectId, projectType, tenant?.tenantId, updateOrCreateTaxation],
  );

  if (isLoadingProject || !projectId || !tenant || !defaultValues) {
    return <LoadingScreen />;
  }

  return (
    <ReactHookWizard<object, MovingJobTaxationWizardInput>
      wizardSteps={steps}
      initialValues={defaultValues}
      onSubmit={onSubmit}
      initialStepIndex={0}
      name="taxation-tool"
      persist={`project-intake-${projectId}`}
      Template={ReactHookWizardWithStepsNavigationTemplateSentry}
      extraTemplateProps={templateProps}
      onPersist={handleActiveIntake}
      extraContent={[
        {
          component: HelpAndNotesBottomSheet as ElementType<{
            control: Control<MovingJobTaxationWizardInput>;
          }>,
          props: { bottomSheetRef: helpAndNotesBottomSheetRef },
        },
      ]}
    />
  );
};

export default EmployeeTaxationToolScreen;
