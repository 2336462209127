import { QueryOptionsWithKey } from '@bas/shared/requests';
import { Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type InvoicePdfByInvoiceIdRequestProps = {
  invoiceId: Uuid;
};

export const InvoicePdfByInvoiceIdRequest = async ({
  invoiceId,
  ...params
}: InvoicePdfByInvoiceIdRequestProps): Promise<AxiosResponse<Blob>> =>
  axios.get(`api/{tenantId}/invoices/${invoiceId}/download-pdf`, {
    responseType: 'blob',
    params,
  });

export const useInvoicePdfByInvoiceIdRequestQuery = (
  request: InvoicePdfByInvoiceIdRequestProps,
  options: QueryOptionsWithKey<
    AxiosResponse<Blob>,
    AxiosError,
    AxiosResponse<Blob>
  > = {},
): UseQueryResult<AxiosResponse<Blob>, AxiosError> =>
  useQuery<AxiosResponse<Blob>, AxiosError, AxiosResponse<Blob>>({
    ...options,
    queryFn: async () => InvoicePdfByInvoiceIdRequest(request),
    queryKey: ['invoices', 'pdf', request.invoiceId],
    gcTime: 0,
    staleTime: 0,
  });
