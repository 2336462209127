import { MessageType } from '@bas/communication-domain/models';
import { GiftedChatIMessage } from '@bas/shared/models';

export const convertGiftedChatMessageToMessageMetadata = (
  message: GiftedChatIMessage,
) => {
  let result = {};
  let messageType = MessageType.TEXT_MESSAGE;
  if (message.image && message.system) {
    messageType = MessageType.FILE_MESSAGE;
    result = {
      attachmentUrl: message.image,
      mimeType: message.video,
    };
  } else if (message.image) {
    messageType = MessageType.IMAGE_MESSAGE;
    result = {
      attachmentUrl: message.image,
    };
  } else if (message.audio) {
    messageType = MessageType.AUDIO_MESSAGE;
    result = {
      attachmentUrl: message.audio,
    };
  } else if (message.video) {
    messageType = MessageType.VIDEO_MESSAGE;
    result = {
      attachmentUrl: message.video,
    };
  } else if (message.location) {
    messageType = MessageType.LOCATION_MESSAGE;
    result = {
      latitude: message.location.latitude,
      longitude: message.location.longitude,
      locationName: '',
      locationDescription: '',
      locationUrl: '',
    };
  }

  return {
    messageType,
    ...result,
  };
};
