import { useCustomersRequest } from '@bas/crm-domain/requests';
import { isTimeEntry } from '@bas/hrm-domain/models';
import { useTimeTypesRequest } from '@bas/hrm-domain/requests';
import { TimeTrackingItem, useTimeTrackingStore } from '@bas/shared/state';
import { Button } from '@bas/ui/native/atoms';
import { BottomSheetMethods, Box, Icon, Typography } from '@bas/ui/native/base';
import { BottomSheet, BottomSheetProps } from '@bas/ui/native/organisms';
import { faClock } from '@fortawesome/pro-light-svg-icons';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { FlashList } from '@shopify/flash-list';
import { ListRenderItem } from '@shopify/flash-list/src/FlashListProps';
import dayjs from 'dayjs';
import { forwardRef, ReactElement, useCallback, useMemo } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { ScrollView } from 'react-native-gesture-handler';

export type StopWorkingDayBottomSheetProps = Omit<
  BottomSheetProps,
  'snapPoints' | 'children'
> & {
  onStop: () => void;
};

const StopWorkingDayBottomSheet = forwardRef<
  BottomSheetModal & BottomSheetMethods,
  StopWorkingDayBottomSheetProps
>(({ onStop, ...props }: StopWorkingDayBottomSheetProps, ref): ReactElement => {
  const trackedTimePerDate = useTimeTrackingStore(
    (state) => state.trackedTimePerDate,
  );
  const { data: timeTypesData } = useTimeTypesRequest();

  const timeTypes = useMemo(
    () => timeTypesData?.data?.member || [],
    [timeTypesData?.data],
  );

  const trackedTimeForToday = useMemo(
    () =>
      (trackedTimePerDate[dayjs().format('YYYY-MM-DD')] || []).sort((a, b) =>
        dayjs(a.start).diff(dayjs(b.start)),
      ),
    [trackedTimePerDate],
  );

  const relationIds = useMemo(
    () =>
      trackedTimeForToday
        .filter((item) => !!item.relationId)
        .map((item) => item.relationId),
    [trackedTimeForToday],
  );

  const { data: relationsData } = useCustomersRequest(
    {
      relationId: relationIds,
    },
    {
      enabled: relationIds.length > 0,
    },
  );

  const relations = useMemo(
    () => relationsData?.data?.member || [],
    [relationsData?.data],
  );

  const handleLoadTrackedTime: ListRenderItem<TimeTrackingItem> = useCallback(
    ({ item }) => {
      let description = '';

      const startDate = dayjs(item.start).toDate();
      const endDate = item.end ? dayjs(item.end).toDate() : null;

      if (isTimeEntry(item)) {
        description = item.timeTypeName;
      } else {
        const timeType = timeTypes.find(
          ({ timeTypeId }) => item.timeTypeId === timeTypeId,
        );
        description = timeType?.name || '';
      }

      if (item.relationId) {
        const relation = relations.find(
          ({ relationId }) => item.relationId === relationId,
        );
        description = `${description} - ${relation?.name}`;
      }

      return (
        <Box marginBottom={2} flexDirection="row">
          <Box height="100%" paddingRight={3}>
            <Box flex={1} alignItems="center" justifyContent="center">
              <Icon icon={faClock} size={24} color="white" />
            </Box>
          </Box>
          <Box flex={1}>
            <Typography color="white">{description}</Typography>
            <Typography color="white">
              <FormattedMessage id="label.startTime" />
              {': '}
              <FormattedDate value={startDate} timeStyle="short" />
            </Typography>
            <Typography color="white">
              <FormattedMessage id="label.endTime" />
              {': '}
              {endDate ? (
                <FormattedDate value={endDate} timeStyle="short" />
              ) : (
                <FormattedMessage id="label.now" />
              )}
            </Typography>
          </Box>
        </Box>
      );
    },
    [relations, timeTypes],
  );

  return (
    <BottomSheet ref={ref} {...props}>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <Box height="100%" width="100%" paddingBottom={4} gap={3}>
          <Box>
            <Typography
              variant="h4"
              fontWeight={700}
              color="white"
              textAlign="center"
            >
              <FormattedMessage id="label.stopWorkingDay" />
            </Typography>
          </Box>
          <Box>
            <Typography color="white">
              <FormattedMessage id="label.stopWorkingDay.description" />
            </Typography>
          </Box>
          <Box flex={1}>
            <Typography color="white" variant="subtitle1" paddingBottom={2}>
              <FormattedMessage id="label.stopWorkingDay.trackedTime" />
            </Typography>
            <FlashList<TimeTrackingItem>
              estimatedItemSize={88}
              renderItem={handleLoadTrackedTime}
              data={trackedTimeForToday}
            />
          </Box>
          <Box justifyContent="flex-end" flex={1}>
            <Button onPress={onStop}>
              <FormattedMessage id="label.stopWorkingDay.stopButton" />
            </Button>
          </Box>
        </Box>
      </ScrollView>
    </BottomSheet>
  );
});

export default StopWorkingDayBottomSheet;
