import { HoursEntry } from '@bas/hrm-domain/models';
import { Event } from '@bas/planning-domain/models';
import { formatHours } from '@bas/shared/utils';
import { BlueSelectButton, Tooltip } from '@bas/ui/native/atoms';
import { Box, Icon, Typography } from '@bas/ui/native/base';
import {
  BlueSelectButtonsGroup,
  WorkedHoursSpecificationSummary,
} from '@bas/ui/native/molecules';
import {
  EmployeeProjectRole,
  mergeMultipleWorkedHoursSpecifications,
  WorkedHoursSpecification,
  WorkedHoursSpecificationDefaultValues,
} from '@bas/value-objects';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons/faExclamationCircle';
import { faTruck } from '@fortawesome/pro-light-svg-icons/faTruck';
import { faTruckFast } from '@fortawesome/pro-light-svg-icons/faTruckFast';
import { faUsers } from '@fortawesome/pro-light-svg-icons/faUsers';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons/faCalendar';
import { faCoffee } from '@fortawesome/pro-regular-svg-icons/faCoffee';
import { faPersonCarryBox } from '@fortawesome/pro-regular-svg-icons/faPersonCarryBox';
import { faUserCheck } from '@fortawesome/pro-regular-svg-icons/faUserCheck';
import { faUserCrown } from '@fortawesome/pro-regular-svg-icons/faUserCrown';
import dayjs from 'dayjs';
import { Fragment, ReactElement, ReactNode, useMemo, useState } from 'react';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';

const RenderHourRow = ({
  planned,
  workedAccordingToForeman,
  worked,
  approved,
  totalForRole,
  title,
  isTotal,
}: {
  planned: WorkedHoursSpecification;
  workedAccordingToForeman: WorkedHoursSpecification;
  worked: WorkedHoursSpecification;
  approved: WorkedHoursSpecification;
  totalForRole: WorkedHoursSpecification;
  title: string | ReactNode;
  isTotal?: boolean;
}): ReactElement => (
  <Box
    flex={1}
    flexBasis="100%"
    flexDirection="row"
    pt={2}
    pb={2}
    borderBottomWidth={0.5}
    borderBottomColor="white"
  >
    <Box flex={1}>
      <Typography fontWeight={isTotal ? 700 : undefined} color="white">
        {title}
      </Typography>
    </Box>
    <Box alignItems="center" minWidth={42}>
      <Tooltip
        popover={
          <WorkedHoursSpecificationSummary workedHoursSpecification={planned} />
        }
      >
        <Typography
          fontWeight={isTotal ? 700 : undefined}
          color="white"
          textAlign="center"
        >
          {formatHours(planned.workedHours + planned.travelTime)}
        </Typography>
      </Tooltip>
    </Box>
    <Box alignItems="center" minWidth={42}>
      <Tooltip
        popover={
          <WorkedHoursSpecificationSummary
            workedHoursSpecification={workedAccordingToForeman}
          />
        }
      >
        <Typography
          fontWeight={isTotal ? 700 : undefined}
          color="white"
          textAlign="center"
        >
          {formatHours(
            workedAccordingToForeman.workedHours +
              workedAccordingToForeman.travelTime,
          )}
        </Typography>
      </Tooltip>
    </Box>
    <Box alignItems="center" minWidth={42}>
      <Tooltip
        popover={
          <WorkedHoursSpecificationSummary workedHoursSpecification={worked} />
        }
      >
        <Typography
          fontWeight={isTotal ? 700 : undefined}
          color="white"
          textAlign="center"
        >
          {formatHours(worked.workedHours + worked.travelTime)}
        </Typography>
      </Tooltip>
    </Box>
    <Box alignItems="center" minWidth={42}>
      <Tooltip
        popover={
          <WorkedHoursSpecificationSummary
            workedHoursSpecification={approved}
          />
        }
      >
        <Typography
          fontWeight={isTotal ? 700 : undefined}
          color="white"
          textAlign="center"
        >
          {formatHours(approved.workedHours + approved.travelTime)}
        </Typography>
      </Tooltip>
    </Box>
    <Box flex={1}>
      <Tooltip
        popover={
          <WorkedHoursSpecificationSummary
            workedHoursSpecification={totalForRole}
          />
        }
      >
        <Typography
          fontWeight={isTotal ? 700 : undefined}
          color="white"
          textAlign="center"
        >
          {formatHours(totalForRole.workedHours + totalForRole.travelTime)}
        </Typography>
      </Tooltip>
    </Box>
    <Box alignItems="center" minWidth={42}>
      <Typography
        fontWeight={isTotal ? 700 : undefined}
        color="white"
        textAlign="center"
      >
        {formatHours(
          planned.breakTime +
            worked.breakTime +
            approved.breakTime +
            workedAccordingToForeman.breakTime,
        )}
      </Typography>
    </Box>
  </Box>
);

export type ProjectHoursForInvoicingProps = {
  hoursEntries: HoursEntry[];
  events: Event[];
  isLoading?: boolean;
};

type RowType = {
  planned: WorkedHoursSpecification;
  workedAccordingToForeman: WorkedHoursSpecification;
  worked: WorkedHoursSpecification;
  approved: WorkedHoursSpecification;
  totalForRole: WorkedHoursSpecification;
};

type RowByRole = RowType & {
  projectRole: EmployeeProjectRole | 'unknown' | 'total';
  title: string | ReactElement;
  key: string;
};

type RowByDate = RowType & {
  date: Date | 'total';
  title: string | ReactElement;
  key: string;
};

const ProjectHoursForInvoicing = ({
  hoursEntries,
  events,
  isLoading,
}: ProjectHoursForInvoicingProps): ReactElement => {
  const [showHoursType, setShowHoursType] = useState<
    'hoursEntriesByRole' | 'hoursEntriesByDate'
  >('hoursEntriesByRole');

  const { hoursEntriesByRole, hoursEntriesByDate } = useMemo((): {
    hoursEntriesByRole: (RowByRole & {
      totalForRole: WorkedHoursSpecification;
    })[];
    hoursEntriesByDate: (RowByDate & {
      totalForRole: WorkedHoursSpecification;
    })[];
  } => {
    const result: {
      byRole: {
        [key: string]: RowByRole;
      };
      byDate: {
        [key: string]: RowByDate;
      };
    } = {
      byRole: {
        total: {
          projectRole: 'total',
          title: <FormattedMessage id="label.total" />,
          key: 'total',
          planned: { ...WorkedHoursSpecificationDefaultValues },
          workedAccordingToForeman: {
            ...WorkedHoursSpecificationDefaultValues,
          },
          worked: { ...WorkedHoursSpecificationDefaultValues },
          approved: { ...WorkedHoursSpecificationDefaultValues },
          totalForRole: { ...WorkedHoursSpecificationDefaultValues },
        },
      },
      byDate: {
        total: {
          date: 'total',
          title: <FormattedMessage id="label.total" />,
          key: 'total',
          planned: { ...WorkedHoursSpecificationDefaultValues },
          workedAccordingToForeman: {
            ...WorkedHoursSpecificationDefaultValues,
          },
          worked: { ...WorkedHoursSpecificationDefaultValues },
          approved: { ...WorkedHoursSpecificationDefaultValues },
          totalForRole: { ...WorkedHoursSpecificationDefaultValues },
        },
      },
    };

    const handledEmployeeIdsByEventId: {
      [key: string]: string[];
    } = {};

    hoursEntries.forEach((entry) => {
      const event = events.find(({ eventId }) => eventId === entry.eventId);

      let role: 'unknown' | EmployeeProjectRole = 'unknown';
      if (event) {
        const plannedEmployee = event.employees.find(
          ({ employee: { employeeId } }) => employeeId === entry.employeeId,
        );
        if (plannedEmployee) {
          role = plannedEmployee.projectRole;
          if (
            typeof handledEmployeeIdsByEventId[event.eventId] === 'undefined'
          ) {
            handledEmployeeIdsByEventId[event.eventId] = [];
          }

          handledEmployeeIdsByEventId[event.eventId].push(
            plannedEmployee.employee.employeeId,
          );
        }
      }

      const date = dayjs(entry.startDate).format('YYYY-MM-DD');

      if (typeof result.byRole[role] === 'undefined') {
        result.byRole[role] = {
          projectRole: role,
          key: role,
          title:
            role === 'unknown' ? (
              <FormattedMessage id="label.unknown" />
            ) : (
              <FormattedMessage id={`projectRoles.${role}`} />
            ),
          planned: { ...WorkedHoursSpecificationDefaultValues },
          workedAccordingToForeman: {
            ...WorkedHoursSpecificationDefaultValues,
          },
          worked: { ...WorkedHoursSpecificationDefaultValues },
          approved: { ...WorkedHoursSpecificationDefaultValues },
          totalForRole: { ...WorkedHoursSpecificationDefaultValues },
        };
      }

      if (typeof result.byDate[date] === 'undefined') {
        result.byDate[date] = {
          key: date,
          title: (
            <FormattedDate
              value={
                entry.startDate ||
                entry.travelStart ||
                entry.plannedStart ||
                entry.createdAt
              }
              month="long"
              day="numeric"
            />
          ),
          date:
            entry.startDate ||
            entry.travelStart ||
            entry.plannedStart ||
            entry.createdAt,
          planned: { ...WorkedHoursSpecificationDefaultValues },
          workedAccordingToForeman: {
            ...WorkedHoursSpecificationDefaultValues,
          },
          worked: { ...WorkedHoursSpecificationDefaultValues },
          approved: { ...WorkedHoursSpecificationDefaultValues },
          totalForRole: { ...WorkedHoursSpecificationDefaultValues },
        };
      }

      if (entry.approvalHoursEntries.length > 0) {
        entry.approvalHoursEntries.forEach(
          ({
            hours: approvedHours,
            workedHours: approvedWorkedHours,
            travelTime: approvedTravelTime,
            breakTime: approvedBreakTime,
          }) => {
            result.byRole[role].approved.workedHoursIncludingBreakTime +=
              approvedHours;
            result.byRole.total.approved.workedHoursIncludingBreakTime +=
              approvedHours;
            result.byRole[role].approved.workedHours += approvedWorkedHours;
            result.byRole.total.approved.workedHours += approvedWorkedHours;
            result.byRole[role].approved.breakTime += approvedBreakTime;
            result.byRole.total.approved.breakTime += approvedBreakTime;
            result.byRole[role].approved.travelTime += approvedTravelTime;
            result.byRole.total.approved.travelTime += approvedTravelTime;

            result.byDate[date].approved.workedHoursIncludingBreakTime +=
              approvedHours;
            result.byDate.total.approved.workedHoursIncludingBreakTime +=
              approvedHours;
            result.byDate[date].approved.workedHours += approvedWorkedHours;
            result.byDate.total.approved.workedHours += approvedWorkedHours;
            result.byDate[date].approved.breakTime += approvedBreakTime;
            result.byDate.total.approved.breakTime += approvedBreakTime;
            result.byDate[date].approved.travelTime += approvedTravelTime;
            result.byDate.total.approved.travelTime += approvedTravelTime;
          },
        );
      } else if (entry.accordingToForeman) {
        result.byRole[
          role
        ].workedAccordingToForeman.workedHoursIncludingBreakTime += entry.hours;
        result.byRole.total.workedAccordingToForeman.workedHoursIncludingBreakTime +=
          entry.hours;
        result.byRole[role].workedAccordingToForeman.workedHours +=
          entry.workedHours;
        result.byRole.total.workedAccordingToForeman.workedHours +=
          entry.workedHours;
        result.byRole[role].workedAccordingToForeman.breakTime +=
          entry.breakTime;
        result.byRole.total.workedAccordingToForeman.breakTime +=
          entry.breakTime;
        result.byRole[role].workedAccordingToForeman.travelTime +=
          entry.travelTime;
        result.byRole.total.workedAccordingToForeman.travelTime +=
          entry.travelTime;

        result.byDate[
          date
        ].workedAccordingToForeman.workedHoursIncludingBreakTime += entry.hours;
        result.byDate.total.workedAccordingToForeman.workedHoursIncludingBreakTime +=
          entry.hours;
        result.byDate[date].workedAccordingToForeman.workedHours +=
          entry.workedHours;
        result.byDate.total.workedAccordingToForeman.workedHours +=
          entry.workedHours;
        result.byDate[date].workedAccordingToForeman.breakTime +=
          entry.breakTime;
        result.byDate.total.workedAccordingToForeman.breakTime +=
          entry.breakTime;
        result.byDate[date].workedAccordingToForeman.travelTime +=
          entry.travelTime;
        result.byDate.total.workedAccordingToForeman.travelTime +=
          entry.travelTime;
      } else {
        result.byRole[role].worked.workedHoursIncludingBreakTime += entry.hours;
        result.byRole.total.worked.workedHoursIncludingBreakTime += entry.hours;
        result.byRole[role].worked.workedHours += entry.workedHours;
        result.byRole.total.worked.workedHours += entry.workedHours;
        result.byRole[role].worked.breakTime += entry.breakTime;
        result.byRole.total.worked.breakTime += entry.breakTime;
        result.byRole[role].worked.travelTime += entry.travelTime;
        result.byRole.total.worked.travelTime += entry.travelTime;

        result.byDate[date].worked.workedHoursIncludingBreakTime += entry.hours;
        result.byDate.total.worked.workedHoursIncludingBreakTime += entry.hours;
        result.byDate[date].worked.workedHours += entry.workedHours;
        result.byDate.total.worked.workedHours += entry.workedHours;
        result.byDate[date].worked.breakTime += entry.breakTime;
        result.byDate.total.worked.breakTime += entry.breakTime;
        result.byDate[date].worked.travelTime += entry.travelTime;
        result.byDate.total.worked.travelTime += entry.travelTime;
      }
    });

    events.forEach(({ eventId, start, end, employees, travelTime }) => {
      employees.forEach(({ employee: { employeeId }, projectRole }) => {
        if (
          typeof handledEmployeeIdsByEventId[eventId] !== 'undefined' &&
          handledEmployeeIdsByEventId[eventId].includes(employeeId)
        ) {
          return;
        }

        const date = dayjs(start).format('YYYY-MM-DD');
        if (typeof result.byRole[projectRole] === 'undefined') {
          result.byRole[projectRole] = {
            key: projectRole,
            title: <FormattedMessage id={`projectRoles.${projectRole}`} />,
            projectRole,
            planned: { ...WorkedHoursSpecificationDefaultValues },
            workedAccordingToForeman: {
              ...WorkedHoursSpecificationDefaultValues,
            },
            worked: { ...WorkedHoursSpecificationDefaultValues },
            approved: { ...WorkedHoursSpecificationDefaultValues },
            totalForRole: { ...WorkedHoursSpecificationDefaultValues },
          };
        }

        if (typeof result.byDate[date] === 'undefined') {
          result.byDate[date] = {
            date: start,
            key: date,
            title: <FormattedDate value={start} month="long" day="numeric" />,
            planned: { ...WorkedHoursSpecificationDefaultValues },
            workedAccordingToForeman: {
              ...WorkedHoursSpecificationDefaultValues,
            },
            worked: { ...WorkedHoursSpecificationDefaultValues },
            approved: { ...WorkedHoursSpecificationDefaultValues },
            totalForRole: { ...WorkedHoursSpecificationDefaultValues },
          };
        }

        result.byRole[projectRole].planned.workedHours +=
          dayjs(end).diff(start, 'minutes') / 60;
        result.byRole[projectRole].planned.workedHoursIncludingBreakTime +=
          dayjs(end).diff(start, 'minutes') / 60;
        result.byRole.total.planned.workedHours +=
          dayjs(end).diff(start, 'minutes') / 60;
        result.byRole.total.planned.workedHoursIncludingBreakTime +=
          dayjs(end).diff(start, 'minutes') / 60;
        result.byRole[projectRole].planned.travelTime += travelTime;
        result.byRole.total.planned.travelTime += travelTime;

        result.byDate[date].planned.workedHours +=
          dayjs(end).diff(start, 'minutes') / 60;
        result.byDate[date].planned.workedHoursIncludingBreakTime +=
          dayjs(end).diff(start, 'minutes') / 60;
        result.byDate.total.planned.workedHours +=
          dayjs(end).diff(start, 'minutes') / 60;
        result.byDate.total.planned.workedHoursIncludingBreakTime +=
          dayjs(end).diff(start, 'minutes') / 60;
        result.byDate[date].planned.travelTime += travelTime;
        result.byDate.total.planned.travelTime += travelTime;
      });
    });

    return {
      hoursEntriesByRole: Object.values(result.byRole).map((row) => ({
        ...row,
        totalForRole: mergeMultipleWorkedHoursSpecifications([
          row.worked,
          row.workedAccordingToForeman,
          row.planned,
          row.approved,
        ]),
      })),
      hoursEntriesByDate: Object.values(result.byDate).map((row) => ({
        ...row,
        totalForRole: mergeMultipleWorkedHoursSpecifications([
          row.worked,
          row.workedAccordingToForeman,
          row.planned,
          row.approved,
        ]),
      })),
    };
  }, [events, hoursEntries]);

  const hoursEntriesRows: (
    | (RowByRole & {
        totalForRole: WorkedHoursSpecification;
      })
    | (RowByDate & {
        totalForRole: WorkedHoursSpecification;
      })
  )[] = useMemo(
    () =>
      showHoursType === 'hoursEntriesByDate'
        ? hoursEntriesByDate
        : hoursEntriesByRole,
    [hoursEntriesByDate, hoursEntriesByRole, showHoursType],
  );

  return (
    <Box gap={3}>
      <Box flex={1} flexBasis="100%">
        <BlueSelectButtonsGroup
          buttons={[
            <BlueSelectButton
              notSelected={
                showHoursType !== undefined &&
                showHoursType !== 'hoursEntriesByRole'
              }
              onSelect={() => setShowHoursType('hoursEntriesByRole')}
            >
              <FormattedMessage id="button.byRole" />
            </BlueSelectButton>,
            <BlueSelectButton
              notSelected={
                showHoursType !== undefined &&
                showHoursType !== 'hoursEntriesByDate'
              }
              onSelect={() => setShowHoursType('hoursEntriesByDate')}
            >
              <FormattedMessage id="button.byDate" />
            </BlueSelectButton>,
          ]}
        />
      </Box>
      <Box>
        <Box
          flex={1}
          flexBasis="100%"
          flexDirection="row"
          pb={1}
          borderBottomWidth={0.5}
          borderBottomColor="white"
        >
          <Box flex={1}>
            <Typography color="white" fontWeight={700}>
              <FormattedMessage
                id={`label.${
                  showHoursType === 'hoursEntriesByRole' ? 'role' : 'date'
                }`}
              />
            </Typography>
          </Box>
          <Box alignItems="center" minWidth={42}>
            <Tooltip
              popover={
                <Typography color="white">
                  <FormattedMessage id="label.plannedHours" />
                </Typography>
              }
            >
              <Icon color="white" icon={faCalendar} />
            </Tooltip>
          </Box>
          <Box alignItems="center" minWidth={42}>
            <Tooltip
              popover={
                <Typography color="white">
                  <FormattedMessage id="label.workedHoursAccordingToForeman" />
                </Typography>
              }
            >
              <Icon color="white" icon={faUserCrown} />
            </Tooltip>
          </Box>
          <Box alignItems="center" minWidth={42}>
            <Tooltip
              popover={
                <Typography color="white">
                  <FormattedMessage id="label.workedHours" />
                </Typography>
              }
            >
              <Icon color="white" icon={faPersonCarryBox} />
            </Tooltip>
          </Box>
          <Box alignItems="center" minWidth={42}>
            <Tooltip
              popover={
                <Typography color="white">
                  <FormattedMessage id="label.approvedHours" />
                </Typography>
              }
            >
              <Icon color="white" icon={faUserCheck} />
            </Tooltip>
          </Box>
          <Box flex={1}>
            <Typography textAlign="center" color="white" fontWeight={700}>
              <FormattedMessage id="label.totalHours" />
            </Typography>
          </Box>
          <Box alignItems="center" minWidth={42}>
            <Tooltip
              popover={
                <Typography color="white">
                  <FormattedMessage id="label.breakTime" />
                </Typography>
              }
            >
              <Icon color="white" icon={faCoffee} />
            </Tooltip>
          </Box>
        </Box>
        {!isLoading &&
          hoursEntriesRows
            .filter(({ key }) => key !== 'total')
            .map(
              ({
                planned,
                workedAccordingToForeman,
                worked,
                approved,
                totalForRole,
                title,
                key,
              }) => (
                <RenderHourRow
                  key={key}
                  planned={planned}
                  workedAccordingToForeman={workedAccordingToForeman}
                  worked={worked}
                  approved={approved}
                  totalForRole={totalForRole}
                  title={title}
                />
              ),
            )}
        {!isLoading &&
          hoursEntriesRows
            .filter(({ key }) => key === 'total')
            .map(
              ({
                planned,
                workedAccordingToForeman,
                worked,
                approved,
                totalForRole,
                title,
                key,
              }) => (
                <RenderHourRow
                  key={key}
                  planned={planned}
                  workedAccordingToForeman={workedAccordingToForeman}
                  worked={worked}
                  approved={approved}
                  totalForRole={totalForRole}
                  title={title}
                  isTotal
                />
              ),
            )}
      </Box>
      <Box>
        <Tooltip
          popover={
            <Typography color="white">
              <FormattedMessage id="label.hoursOfEventsExplained" />
            </Typography>
          }
        >
          <Typography variant="subtitle1" color="white">
            <FormattedMessage id="label.hoursOfEvents" />
            &nbsp;
            <Icon color="white" icon={faExclamationCircle} />
          </Typography>
        </Tooltip>
        <Box
          flex={1}
          flexBasis="100%"
          flexDirection="row"
          pb={1}
          pt={1}
          borderBottomWidth={0.5}
          borderBottomColor="white"
        >
          <Box flex={1}>
            <Typography color="white" fontWeight={700}>
              <FormattedMessage id="label.date" />
            </Typography>
          </Box>
          <Box flex={1}>
            <Typography color="white" fontWeight={700}>
              <FormattedMessage id="label.start" />
            </Typography>
          </Box>
          <Box flex={1}>
            <Typography color="white" fontWeight={700}>
              <FormattedMessage id="label.end" />
            </Typography>
          </Box>
          <Box alignItems="center" minWidth={42}>
            <Tooltip
              popover={
                <Typography color="white">
                  <FormattedMessage id="label.numberOfEmployees" />
                </Typography>
              }
            >
              <Icon color="white" icon={faUsers} />
            </Tooltip>
          </Box>
          <Box alignItems="center" minWidth={42}>
            <Tooltip
              popover={
                <Typography color="white">
                  <FormattedMessage id="label.numberOfVehicles" />
                </Typography>
              }
            >
              <Icon color="white" icon={faTruck} />
            </Tooltip>
          </Box>
          <Box alignItems="center" minWidth={42}>
            <Tooltip
              popover={
                <Typography color="white">
                  <FormattedMessage id="label.totalHoursOfEmployees" />
                </Typography>
              }
            >
              <Icon color="white" icon={faPersonCarryBox} />
            </Tooltip>
          </Box>
          <Box alignItems="center" minWidth={42}>
            <Tooltip
              popover={
                <Typography color="white">
                  <FormattedMessage id="label.totalHoursOfVehicles" />
                </Typography>
              }
            >
              <Icon color="white" icon={faTruckFast} />
            </Tooltip>
          </Box>
        </Box>
        {events.map((projectEvent) => {
          const eventHours = Math.round(
            dayjs(
              projectEvent.realTravelEnd ||
                projectEvent.travelEnd ||
                projectEvent.realEnd ||
                projectEvent.end,
            ).diff(
              projectEvent.realTravelStart ||
                projectEvent.travelStart ||
                projectEvent.realStart ||
                projectEvent.start,
              'minutes',
              true,
            ),
          );

          return (
            <Fragment key={projectEvent.eventId}>
              <Box
                flex={1}
                flexBasis="100%"
                flexDirection="row"
                pb={2}
                pt={2}
                borderBottomWidth={0.5}
                borderBottomColor="white"
              >
                <Box flex={1}>
                  <Typography color="white">
                    <FormattedDate
                      value={projectEvent.realStart || projectEvent.start}
                      month="long"
                      day="numeric"
                    />
                  </Typography>
                </Box>
                <Box flex={1}>
                  <Tooltip
                    popover={
                      <Box>
                        {projectEvent.realTravelStart && (
                          <Box flex={1}>
                            <Typography color="white">
                              <FormattedMessage id="label.realDepartureTime" />
                              &nbsp;
                              <FormattedTime
                                value={projectEvent.realTravelStart}
                                timeStyle="short"
                              />
                            </Typography>
                          </Box>
                        )}
                        {projectEvent.realStart && (
                          <Box flex={1}>
                            <Typography color="white">
                              <FormattedMessage id="label.realStart" />
                              &nbsp;
                              <FormattedTime
                                value={projectEvent.realStart}
                                timeStyle="short"
                              />
                            </Typography>
                          </Box>
                        )}
                        {projectEvent.travelStart && (
                          <Box flex={1}>
                            <Typography color="white">
                              <FormattedMessage id="label.plannedDepartureTime" />
                              &nbsp;
                              <FormattedTime
                                value={projectEvent.travelStart}
                                timeStyle="short"
                              />
                            </Typography>
                          </Box>
                        )}
                        {projectEvent.start && (
                          <Box flex={1}>
                            <Typography color="white">
                              <FormattedMessage id="label.plannedStart" />
                              &nbsp;
                              <FormattedTime
                                value={projectEvent.start}
                                timeStyle="short"
                              />
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    }
                  >
                    <Typography color="white">
                      <FormattedDate
                        value={
                          projectEvent.realTravelStart ||
                          projectEvent.travelStart ||
                          projectEvent.realStart ||
                          projectEvent.start
                        }
                        hour="numeric"
                        minute="numeric"
                      />
                    </Typography>
                  </Tooltip>
                </Box>
                <Box flex={1}>
                  <Tooltip
                    popover={
                      <Box>
                        {projectEvent.realTravelEnd && (
                          <Box flex={1}>
                            <Typography color="white">
                              <FormattedMessage id="label.realArrivalTime" />
                              &nbsp;
                              <FormattedTime
                                value={projectEvent.realTravelEnd}
                                timeStyle="short"
                              />
                            </Typography>
                          </Box>
                        )}
                        {projectEvent.realEnd && (
                          <Box flex={1}>
                            <Typography color="white">
                              <FormattedMessage id="label.realEnd" />
                              &nbsp;
                              <FormattedTime
                                value={projectEvent.realEnd}
                                timeStyle="short"
                              />
                            </Typography>
                          </Box>
                        )}
                        {projectEvent.travelEnd && (
                          <Box flex={1}>
                            <Typography color="white">
                              <FormattedMessage id="label.plannedArrivalTime" />
                              &nbsp;
                              <FormattedTime
                                value={projectEvent.travelEnd}
                                timeStyle="short"
                              />
                            </Typography>
                          </Box>
                        )}
                        {projectEvent.end && (
                          <Box flex={1}>
                            <Typography color="white">
                              <FormattedMessage id="label.plannedEnd" />
                              &nbsp;
                              <FormattedTime
                                value={projectEvent.end}
                                timeStyle="short"
                              />
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    }
                  >
                    <Typography color="white">
                      <FormattedDate
                        value={
                          projectEvent.realTravelEnd ||
                          projectEvent.travelEnd ||
                          projectEvent.realEnd ||
                          projectEvent.end
                        }
                        hour="numeric"
                        minute="numeric"
                      />
                    </Typography>
                  </Tooltip>
                </Box>
                <Box alignItems="center" minWidth={42}>
                  <Tooltip
                    popover={
                      <Box>
                        {projectEvent.employees.map(
                          ({ employee, projectRole }) => (
                            <Box
                              flexBasis="100%"
                              flex={1}
                              key={employee.employeeId}
                              flexDirection="row"
                            >
                              <Typography color="white">
                                {employee.personName.fullName}
                                {' - '}
                              </Typography>
                              <Typography color="white" fontWeight={700}>
                                <FormattedMessage
                                  id={`projectRoles.${projectRole}`}
                                />
                              </Typography>
                            </Box>
                          ),
                        )}
                      </Box>
                    }
                  >
                    <Typography color="white">
                      {projectEvent.employees.length}
                    </Typography>
                  </Tooltip>
                </Box>
                <Box alignItems="center" minWidth={42}>
                  <Tooltip
                    popover={
                      <Box>
                        {projectEvent.materialNames.map((materialName) => (
                          <Box
                            flexBasis="100%"
                            flex={1}
                            flexDirection="row"
                            key={materialName}
                          >
                            <Typography color="white">
                              {materialName}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    }
                  >
                    <Typography color="white">
                      {projectEvent.materials.length}
                    </Typography>
                  </Tooltip>
                </Box>
                <Box alignItems="center" minWidth={42}>
                  <Tooltip
                    width={250}
                    popover={
                      <Typography color="white">
                        <FormattedMessage id="label.workedHoursIncludingBreakTime" />
                        {': '}
                        {formatHours(eventHours / 60)} x{' '}
                        {projectEvent.employees.length} ={' '}
                        {formatHours(
                          (eventHours / 60) * projectEvent.employees.length,
                        )}
                        {'\n'}
                        <FormattedMessage id="label.breakTime" />
                        {': '}
                        {formatHours(projectEvent.breakTime || 0)} x{' '}
                        {projectEvent.employees.length} ={' '}
                        {formatHours(
                          (projectEvent.breakTime || 0) *
                            projectEvent.employees.length,
                        )}
                      </Typography>
                    }
                  >
                    <Typography color="white">
                      {formatHours(
                        (eventHours / 60) * projectEvent.employees.length,
                      )}
                    </Typography>
                  </Tooltip>
                </Box>
                <Box alignItems="center" minWidth={42}>
                  <Tooltip
                    width={300}
                    popover={
                      <Typography color="white">
                        <FormattedMessage id="label.workedHoursIncludingBreakTime" />
                        {': '}
                        {formatHours(eventHours / 60)}
                        {' x '}
                        {projectEvent.materials.length}
                        {' = '}
                        {formatHours(
                          (eventHours / 60) * projectEvent.materials.length,
                        )}
                        {'\n'}
                        <FormattedMessage id="label.breakTime" />
                        {': '}
                        {formatHours(projectEvent.breakTime || 0)} x{' '}
                        {projectEvent.materials.length} ={' '}
                        {formatHours(
                          (projectEvent.breakTime || 0) *
                            projectEvent.materials.length,
                        )}
                      </Typography>
                    }
                  >
                    <Typography color="white">
                      {formatHours(
                        (eventHours / 60) * projectEvent.materials.length,
                      )}
                    </Typography>
                  </Tooltip>
                </Box>
              </Box>
            </Fragment>
          );
        })}
      </Box>
    </Box>
  );
};

export default ProjectHoursForInvoicing;
