import * as Yup from 'yup';
import { Uuid } from '@bas/value-objects';
import { v7 } from 'uuid';

export interface TransportJobCargoItemInputType {
  cargoItemId: Uuid;
  description: string;
  barcode?: string | null;
}

export const TransportJobCargoItemInputTypeDefaultValues =
  (): TransportJobCargoItemInputType => ({
    cargoItemId: v7(),
    description: '',
    barcode: '',
  });

export const TransportJobCargoItemInputTypeValidationBuilder =
  (): Yup.ObjectSchema<TransportJobCargoItemInputType> =>
    Yup.object({
      description: Yup.string().required().label('label.description'),
      barcode: Yup.string().label('label.barcode').nullable(),
      cargoItemId: Yup.string().required().label('label.cargoItemId'),
    });
