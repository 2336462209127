import { formatDate } from '@bas/shared/utils';
import {
  EmployeeContractType,
  ErrorResponse,
  Money,
  Uuid,
} from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type AddEmployeeContractProps = {
  employeeId: Uuid;
  contract: {
    hourlyWage: Money;
    hoursPerWeek: number;
    daysPerWeek: number;
    contractType: EmployeeContractType;
    startDate?: Date | null;
    endDate?: Date | null;
  };
};

export const AddEmployeeContract = async ({
  employeeId,
  contract: { startDate, endDate, ...contract },
  ...values
}: AddEmployeeContractProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/hrm/employees/${employeeId}/add-contract`, {
    employeeId,
    contract: {
      ...contract,
      startDate: startDate ? formatDate(startDate) : null,
      endDate: endDate ? formatDate(endDate) : null,
    },
    ...values,
  });

export const useAddEmployeeContract = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddEmployeeContractProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  AddEmployeeContractProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddEmployeeContractProps
  >({
    mutationFn: AddEmployeeContract,
    throwOnError: false,
    ...options,
  });
