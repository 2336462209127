import { TenantBillableComponent } from '../types';

export function isTenantBillableComponent(
  component: unknown,
): component is TenantBillableComponent {
  return (
    typeof component === 'object' &&
    component !== null &&
    'componentType' in component
  );
}
