import { CrmContactPersonType } from '@bas/crm-domain/models';
import {
  EmailAddress,
  Gender,
  PhoneNumber,
  Salutation,
  Uuid,
} from '@bas/value-objects';
import { v7 } from 'uuid';
import * as Yup from 'yup';

export interface CrmContactPersonInputType {
  contactPersonId?: Uuid;
  contactPersonType: CrmContactPersonType;
  firstName?: string | null;
  middleName?: string | null;
  lastName: string;
  emailAddress?: EmailAddress | null;
  phoneNumber?: PhoneNumber | null;
  mobileNumber?: PhoneNumber | null;
  gender?: Gender | null;
  salutation?: Salutation | null;
  deleteContactPerson: boolean;
}

export const initialCrmContactPersonInputType =
  (): CrmContactPersonInputType => ({
    contactPersonId: v7(),
    contactPersonType: CrmContactPersonType.UNKNOWN,
    gender: Gender.UNKNOWN,
    salutation: Salutation.UNKNOWN,
    firstName: '',
    middleName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: '',
    mobileNumber: '',
    deleteContactPerson: false,
  });

export const crmContactPersonInputTypeTypeValidator =
  (): Yup.ObjectSchema<CrmContactPersonInputType> =>
    Yup.object({
      contactPersonId: Yup.string(),
      contactPersonType: Yup.mixed<CrmContactPersonType>()
        .oneOf(Object.values(CrmContactPersonType).filter((option) => !!option))
        .required()
        .label('label.contactPersonType'),
      gender: Yup.mixed<Gender>()
        .oneOf(Object.values(Gender))
        .required()
        .label('label.gender'),
      salutation: Yup.mixed<Salutation>()
        .oneOf(Object.values(Salutation))
        .required()
        .label('label.salutation'),
      firstName: Yup.string().label('label.firstName').nullable(),
      middleName: Yup.string().label('label.middleName').nullable(),
      lastName: Yup.string().required().label('label.lastName'),
      emailAddress: Yup.string().email().label('label.emailAddress'),
      phoneNumber: Yup.string().phone().nullable().label('label.phoneNumber'),
      mobileNumber: Yup.string().phone().nullable().label('label.mobileNumber'),
      deleteContactPerson: Yup.boolean()
        .required()
        .label('label.deleteContactPerson'),
    });
