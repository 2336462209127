import {
  BottomSheet,
  BottomSheetMethods,
  Box,
  Icon,
  TextFieldProps,
  TouchableOpacity,
} from '@bas/ui/native/base';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { memo, MutableRefObject, useCallback, useMemo, useRef } from 'react';
import isEqual from 'react-fast-compare';
import { Platform, TextInput, View } from 'react-native';
import { TextField } from '../TextField';
import DropdownModalContent from './DropdownModalContent';

type Item = {
  id: string;
  label: string;
};

// Base props that are common to both single and multiple selection
type BaseDropdownProps = Omit<TextFieldProps, 'onChange' | 'value'> & {
  items: Item[];
  light?: boolean;
  disabled?: boolean;
  error?: boolean;
  inputRef?: MutableRefObject<TextInput | null>;
  hideSearch?: boolean;
  hideClear?: boolean;
  showSelectAll?: boolean;
  closeOnSelect?: boolean;
  onSearchChange?: (value: string) => void;
};

// Props for single selection mode
export type SingleDropdownProps = BaseDropdownProps & {
  multiple?: false;
  value?: string;
  onChange?: (value: string | undefined, item?: Item) => void;
};

// Props for multiple selection mode
export type MultipleDropdownProps = BaseDropdownProps & {
  multiple: true;
  value?: string[];
  onChange?: (value: string[], item?: Item[]) => void;
};

// Combined type for the component
export type DropdownProps = SingleDropdownProps | MultipleDropdownProps;

const Dropdown = ({
  items,
  value,
  hideSearch,
  hideClear,
  showSelectAll,
  onChange,
  multiple,
  closeOnSelect = true,
  onSearchChange,
  label,
  ...props
}: DropdownProps) => {
  const bottomSheetRef = useRef<BottomSheetModal & BottomSheetMethods>(null);

  const displayValue = useMemo(() => {
    if (value === undefined) {
      return undefined;
    }

    if (Array.isArray(value)) {
      return value
        .map((item) => items.find(({ id }) => id === item)?.label)
        .join(', ');
    }

    return items.find(({ id }) => id === value)?.label;
  }, [items, value]);

  const handleOpen = useCallback(() => {
    bottomSheetRef.current?.present();
  }, []);

  const modalContent = multiple ? (
    <DropdownModalContent
      onChange={onChange as MultipleDropdownProps['onChange']}
      value={value as string[]}
      multiple
      items={items}
      label={label}
      hideSearch={hideSearch}
      closeOnSelect={closeOnSelect}
      bottomSheetRef={bottomSheetRef}
      onSearchChange={onSearchChange}
    />
  ) : (
    <DropdownModalContent
      onChange={onChange as SingleDropdownProps['onChange']}
      value={value as string}
      multiple={false}
      items={items}
      label={label}
      hideSearch={hideSearch}
      closeOnSelect={closeOnSelect}
      bottomSheetRef={bottomSheetRef}
      onSearchChange={onSearchChange}
    />
  );

  const field = (
    <View>
      <TouchableOpacity onPress={handleOpen}>
        <Box>
          <TextField
            pointerEvents="none"
            label={label}
            value={displayValue}
            selection={{
              start: 0,
              end: 0,
            }}
            {...props}
            trailingAccessory={
              <Box paddingLeft="line" alignSelf="flex-end">
                <Icon
                  icon={faChevronDown}
                  size={20}
                  color="darkText"
                  transform={{
                    rotate: 0,
                  }}
                />
              </Box>
            }
          />
        </Box>
      </TouchableOpacity>
      <BottomSheet
        ref={bottomSheetRef}
        enableDismissOnClose
        disableClose
        disablePadding
      >
        {modalContent}
      </BottomSheet>
    </View>
  );

  if (Platform.OS === 'web') {
    return <Box overflow="hidden">{field}</Box>;
  }

  return field;
};

const MemoComponent = memo(Dropdown, isEqual);

export default MemoComponent;
