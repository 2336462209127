import { Money, Uuid } from '@bas/value-objects';
import * as Yup from 'yup';
import { AnyObject } from 'yup';

export type CreatedFinancialLineInputType = {
  lineId: Uuid;
  description: string;
  quantity: number;
  pricePerUnit: Money;
  totalPrice: Money;
  vatCode: {
    vatCodeId: Uuid;
    vatCode?: string;
  };
  vatPercentage: { percentage: number; activeFrom?: Date; activeTo?: Date };
  created: true;
};
export type FinancialLineInputType =
  | { created: boolean }
  | {
      created: false;
    }
  | CreatedFinancialLineInputType;

export function isCreatedFinancialLineInputType(
  object: unknown,
): object is CreatedFinancialLineInputType {
  return (
    !!object &&
    typeof object === 'object' &&
    'created' in object &&
    object.created === true
  );
}

export const createdFinancialLineInputTypeValidationBuilder =
  (): Yup.ObjectSchema<FinancialLineInputType> =>
    Yup.object({
      lineId: Yup.string().required().label('label.lineId'),
      created: Yup.boolean().isTrue().required().label('label.created'),
      description: Yup.string().required().label('label.description'),
      quantity: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .nullable()
        .required()
        .label('label.quantity'),
      pricePerUnit: Yup.object({
        amount: Yup.number()
          .transform((value, original) =>
            original === '' || original === null ? null : value,
          )
          .nullable()
          .required()
          .label('label.pricePerUnit'),
        currency: Yup.string().required().label('label.currency'),
      })
        .required()
        .label('label.pricePerUnit'),
      totalPrice: Yup.object({
        amount: Yup.number()
          .transform((value, original) =>
            original === '' || original === null ? null : value,
          )
          .nullable()
          .required()
          .label('label.totalPrice'),
        currency: Yup.string().required().label('label.currency'),
      })
        .required()
        .label('label.totalPrice'),
      vatCode: Yup.object({
        vatCodeId: Yup.string().required().label('label.vatCodeId'),
        vatCode: Yup.string().optional().label('label.vatCodeId'),
      })
        .required()
        .label('label.vatCode'),
      vatPercentage: Yup.object({
        percentage: Yup.number()
          .transform((value, original) =>
            original === '' || original === null ? null : value,
          )
          .nullable()
          .required()
          .label('label.vatPercentage'),
      })
        .required()
        .label('label.vatPercentage'),
    });

export const financialLineInputTypeValidatorBuilder = (
  concat: Yup.ObjectSchema<AnyObject> | undefined = undefined,
): Yup.ObjectSchema<FinancialLineInputType> =>
  Yup.object({
    created: Yup.boolean().required().nonNullable().label('label.created'),
  }).when({
    is: (value: { created: boolean }) => value.created,
    then: (schema) =>
      schema
        .concat(createdFinancialLineInputTypeValidationBuilder())
        .concat(concat || Yup.object()),
    otherwise: (schema) =>
      schema.concat(
        Yup.object({
          created: Yup.boolean().isFalse().required().label('label.created'),
        }),
      ),
  });

export function isFinancialLineInputType(
  object: unknown,
): object is FinancialLineInputType {
  return (
    !!object &&
    Object.prototype.hasOwnProperty.call(object, 'lineId') &&
    Object.prototype.hasOwnProperty.call(object, 'description') &&
    Object.prototype.hasOwnProperty.call(object, 'quantity') &&
    Object.prototype.hasOwnProperty.call(object, 'pricePerUnit') &&
    Object.prototype.hasOwnProperty.call(object, 'totalPrice') &&
    Object.prototype.hasOwnProperty.call(object, 'vatCode') &&
    Object.prototype.hasOwnProperty.call(object, 'vatPercentage')
  );
}
