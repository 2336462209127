import { CrmAddress, CrmAddressType } from '@bas/crm-domain/models';
import { useFormAddressAutoComplete } from '@bas/shared/hooks';
import { ReactHookFormDropdown } from '@bas/ui/native/molecules';
import { AddressForm } from '@bas/ui/native/organisms';
import { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { View } from 'react-native';

export type CrmAddressFormProps = {
  prefix: string;
  light?: boolean;
  disabledAddressTypes: CrmAddressType[];
};

export type CrmAddressFormInput = CrmAddress & {
  deleteAddress: boolean;
};

const CrmAddressForm = ({
  prefix,
  light,
  disabledAddressTypes,
}: CrmAddressFormProps): ReactElement => {
  const { deleteAddress } =
    useFormAddressAutoComplete<CrmAddressFormInput>(prefix);
  const { formatMessage } = useIntl();

  return (
    <View>
      <View>
        <ReactHookFormDropdown
          name={`${prefix}.addressType`}
          autoComplete="off"
          label={formatMessage({ id: 'label.addressType' })}
          light={light}
          items={[
            ...Object.values(CrmAddressType)
              .filter(
                (option) =>
                  !!option &&
                  (!disabledAddressTypes.includes(option) ||
                    option === CrmAddressType.OTHER),
              )
              .map((option) => ({
                id: option,
                label: formatMessage({
                  id: `relation.addressType.${option}`,
                }),
              })),
          ]}
        />
      </View>
      <View>
        <AddressForm prefix={prefix} disabled={deleteAddress} light={light} />
      </View>
    </View>
  );
};

export default CrmAddressForm;
