import { calculateTimeOfDaySliderValues } from '@bas/shared/utils';
import { ReactHookFormTimeSliderField } from '@bas/ui/native/molecules';
import * as React from 'react';
import { memo, ReactElement, useMemo } from 'react';
import { useIntl } from 'react-intl';

const BreakTimeForm = (): ReactElement => {
  const { formatMessage } = useIntl();
  const timeOfDayValues = useMemo(() => calculateTimeOfDaySliderValues(15), []);

  return (
    <ReactHookFormTimeSliderField
      name="breakTimeStartedAt"
      label={formatMessage({ id: 'label.breakTimeStartedAt' })}
      values={timeOfDayValues}
      light
    />
  );
};

const MemoComponent = memo(BreakTimeForm, () => true);
export default MemoComponent;
