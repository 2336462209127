import { RelationType } from '@bas/crm-domain/models';
import {
  Address,
  Country,
  ErrorResponse,
  Gender,
  HowDidYouFindUs,
  Language,
  PhoneNumber,
  Salutation,
  Uuid,
} from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type AddCustomerMutationProps = {
  relationId: Uuid;
  relationType: RelationType;
  firstName?: string | null;
  middleName?: string | null;
  gender?: Gender | null;
  salutation?: Salutation | null;
  lastName?: string | null;
  companyName?: string | null;
  cocNumber?: string | null;
  vatNumber?: string | null;
  rsinNumber?: string | null;
  emailAddress: string;
  phoneNumber?: PhoneNumber | null;
  mobileNumber?: PhoneNumber | null;
  address: Address;
  language: Language;
  region?: Country;
  howDidYouFindUs?: HowDidYouFindUs | null;
  howDidYouFindUsOther?: string | null;
};

export const AddCustomerMutation = async ({
  relationId,
  howDidYouFindUs,
  howDidYouFindUsOther,
  ...values
}: AddCustomerMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/{tenantId}/customers`, {
    relationId,
    howDidYouFindUs:
      howDidYouFindUs &&
      [HowDidYouFindUs.OTHER, HowDidYouFindUs.LEAD_WEBSITE].includes(
        howDidYouFindUs,
      )
        ? howDidYouFindUsOther
        : howDidYouFindUs,
    ...values,
  });

export const useAddCustomerMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddCustomerMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  AddCustomerMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    AddCustomerMutationProps
  >({
    mutationFn: AddCustomerMutation,
    throwOnError: false,
    ...options,
  });
