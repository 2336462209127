import { Uuid } from '@bas/value-objects';
import dayjs from 'dayjs';
import { v7 } from 'uuid';
import * as Yup from 'yup';

export type StoredItemInputType = {
  itemId: Uuid;
  description: string;
  itemCode: string | null;
  storageId: Uuid | null;
  storageCode: string | null;
  externalItemCode: string | null;
  storageLocationId: Uuid | null;
  storageLocationName: string | null;
  storageLocationCode: string | null;
  loadedOn: Date | null;
  unloadedOn: Date | null;
  loadedByEmployeeId: Uuid | null;
  unloadedByEmployeeId: Uuid | null;
  loadedByCustomer: boolean;
  volume: number | null;
  create: boolean;
  scanned: boolean;
  hasError: boolean;
  delete: boolean;
};

export const storedItemInputTypeDefaultValues = (): StoredItemInputType => ({
  itemId: v7() as Uuid,
  description: '',
  itemCode: null,
  externalItemCode: null,
  storageId: null,
  storageCode: null,
  volume: null,
  storageLocationId: null,
  storageLocationName: null,
  storageLocationCode: null,
  loadedOn: null,
  unloadedOn: null,
  loadedByEmployeeId: null,
  unloadedByEmployeeId: null,
  loadedByCustomer: false,
  create: true,
  scanned: false,
  hasError: false,
  delete: false,
});

export const storedItemInputTypeValidationBuilder =
  (): Yup.ObjectSchema<StoredItemInputType> =>
    Yup.object({
      itemId: Yup.string().label('label.itemId').ensure(),
      description: Yup.string().label('label.description').ensure().required(),
      itemCode: Yup.string().label('label.itemCode').nullable().ensure(),
      storageId: Yup.string().label('label.storageId').nullable().ensure(),
      storageCode: Yup.string().label('label.storageCode').nullable().ensure(),
      volume: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value,
        )
        .required()
        .label('label.volume')
        .nullable(),
      externalItemCode: Yup.string()
        .label('label.externalItemCode')
        .nullable()
        .ensure(),
      storageLocationId: Yup.string()
        .label('label.storageLocation')
        .nullable()
        .ensure(),
      storageLocationName: Yup.string()
        .label('label.itemLocation')
        .nullable()
        .ensure(),
      storageLocationCode: Yup.string()
        .label('label.itemLocation')
        .nullable()
        .ensure(),
      loadedOn: Yup.date().defined().label('label.loadedOn').nullable(),
      unloadedOn: Yup.date()
        .when(['loadedOn'], ([loadedOn], schema) => {
          if (loadedOn instanceof Date) {
            return schema.min(
              dayjs(loadedOn).clone().add(1, 'second').toDate(),
            );
          }

          return schema;
        })
        .label('label.unloadedOn')
        .nullable()
        .defined(),
      loadedByEmployeeId: Yup.string()
        .label('label.loadedByEmployeeId')
        .nullable()
        .ensure(),
      unloadedByEmployeeId: Yup.string()
        .label('label.unloadedByEmployeeId')
        .nullable()
        .ensure(),
      loadedByCustomer: Yup.boolean().defined().label('label.loadedByCustomer'),
      create: Yup.boolean().defined().label('label.create'),
      scanned: Yup.boolean().defined().label('label.scanned'),
      hasError: Yup.boolean().defined().label('label.hasError'),
      delete: Yup.boolean().defined().label('label.delete'),
    });
