import { ErrorResponse } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { CreateStorageMutationProps } from './createStorageMutation';

export type CreateStoragesInBulkMutationProps = {
  storages: CreateStorageMutationProps[];
};

export const CreateStoragesInBulkMutation = async ({
  ...values
}: CreateStoragesInBulkMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/{tenantId}/storages/storages-in-bulk`, {
    ...values,
  });

export const useCreateStoragesInBulkMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    CreateStoragesInBulkMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  CreateStoragesInBulkMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    CreateStoragesInBulkMutationProps
  >({ mutationFn: CreateStoragesInBulkMutation, ...options });
