import { colors } from '@bas/theme';
import splashLogo from '@bas/ui/assets/Resources/splash-logo.png';
import { Box, IconWithSpin } from '@bas/ui/native/base';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons/faSpinnerThird';
import { ReactElement } from 'react';
import { Image } from 'react-native';

const LoadingScreen = (): ReactElement => (
  <Box flex={1} position="relative" backgroundColor="darkBackground">
    <Box
      position="absolute"
      left={0}
      right={0}
      top={0}
      bottom={0}
      justifyContent="center"
      alignItems="center"
    >
      <Image
        source={splashLogo}
        style={{ width: 255, height: 255 }}
        resizeMode="contain"
      />
    </Box>

    <Box
      position="absolute"
      left={0}
      right={0}
      bottom={72}
      alignItems="center"
      justifyContent="center"
    >
      <IconWithSpin
        size={72}
        icon={faSpinnerThird}
        overrideColor={colors.white}
        secondaryColor={colors.blue[500]}
      />
    </Box>
  </Box>
);

export default LoadingScreen;
