import {
  AddDeclarationInputType,
  RelatedDeclarationInputType,
} from '@bas/hrm-domain/input-types';
import { useDeclarationCategoriesRequest } from '@bas/hrm-domain/requests';
import { useUploadFileToCreateAMediaObjectMutation } from '@bas/media-domain/mutations';
import { Project } from '@bas/project-domain/models';
import { colors } from '@bas/theme';
import { Button } from '@bas/ui/native/atoms';
import { Box, BoxProps } from '@bas/ui/native/base';
import {
  PhotoListItem,
  ReactHookFormDatePickerField,
  ReactHookFormDropdown,
  ReactHookFormEventDropdown,
  ReactHookFormNumberTextField,
  ReactHookFormProjectDropdown,
  ReactHookFormRelationDropdown,
  ReactHookFormTextField,
} from '@bas/ui/native/molecules';
import * as ImagePicker from 'expo-image-picker';
import { ImagePickerAsset } from 'expo-image-picker/src/ImagePicker.types';
import * as React from 'react';
import {
  memo,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

export type ChangeDeclarationFormProps = BoxProps;

const ChangeDeclarationForm = ({
  style,
  ...props
}: ChangeDeclarationFormProps): ReactElement => {
  const { formatMessage } = useIntl();

  const [failedMessage, setFailedMessage] = useState<ReactNode | null>(null);
  const [uploadingFile, setUploadingFile] = useState<
    ImagePickerAsset | undefined
  >(undefined);
  const [progress, setProgress] = useState<number>(0);
  const [status, requestPermission] = ImagePicker.useCameraPermissions();
  const [statusMedia, requestPermissionMedia] =
    ImagePicker.useMediaLibraryPermissions();

  const { mutateAsync: uploadFile } =
    useUploadFileToCreateAMediaObjectMutation();

  const { setValue } = useFormContext<AddDeclarationInputType>();
  const receipt = useWatch<AddDeclarationInputType, 'receipt'>({
    name: 'receipt',
  });

  const { data: declarationCategoriesData } = useDeclarationCategoriesRequest();

  const declarationCategories = useMemo(
    () =>
      (declarationCategoriesData?.data.member || []).map((category) => ({
        label: category.name,
        id: category.categoryId,
      })),
    [declarationCategoriesData?.data],
  );

  const handleUpload = useCallback(
    async (files: ImagePickerAsset[]) => {
      if (files.length !== 1) {
        return;
      }
      const file = files[0];

      const fileSize = (file.fileSize || 0) / 1024 / 1024;
      if (fileSize > 45) {
        setFailedMessage(<FormattedMessage id="label.fileTooBig" />);
        return;
      }

      setFailedMessage(null);
      setProgress(0);
      setUploadingFile(file);
      uploadFile({
        file,
        onUploadProgress: (progressEvent) => {
          setProgress(
            Math.round(
              (progressEvent.loaded * 100) / (progressEvent.total || 0),
            ),
          );
        },
      })
        .then((result) => {
          const mediaObject = {
            mediaObjectId: result.data.mediaObjectId,
            url: result.data.contentUrl,
            contentUrl: result.data.contentUrl,
          };

          setProgress(100);
          setValue('receipt', mediaObject);
          setUploadingFile(undefined);
        })
        .catch(() => {
          setProgress(100);
          setFailedMessage(<FormattedMessage id="label.failedToUpload" />);
        });
    },
    [setValue, uploadFile],
  );

  const takePhoto = useCallback(async () => {
    if (!status) {
      return;
    }
    if (!status.granted) {
      const result = await requestPermission();
      if (!result.granted) {
        return;
      }
    }

    const photoResult = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: 1,
      allowsMultipleSelection: false,
    });

    if (photoResult.canceled) {
      return;
    }

    const files = photoResult.assets;

    await handleUpload(files);
  }, [handleUpload, requestPermission, status]);

  const pickImages = useCallback(async () => {
    if (!statusMedia) {
      return;
    }
    if (!statusMedia.granted) {
      const result = await requestPermissionMedia();
      if (!result.granted) {
        return;
      }
    }
    const pickResult = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: 1,
      allowsMultipleSelection: false,
    });

    if (pickResult.canceled) {
      return;
    }

    const files = pickResult.assets;

    await handleUpload(files);
  }, [handleUpload, requestPermissionMedia, statusMedia]);
  const relationId = useWatch<
    RelatedDeclarationInputType,
    'relation.relationId'
  >({
    name: 'relation.relationId',
  });

  const projectId = useWatch<RelatedDeclarationInputType, 'project.projectId'>({
    name: 'project.projectId',
  });

  const [eventsProjectId, setEventsProjectId] = useState<
    string | null | undefined
  >(projectId);

  const handleChangeProject = useCallback(
    (id?: unknown, newValue?: Project) => {
      setValue(
        'project',
        newValue || {
          projectId: '',
        },
      );
      if (!relationId) {
        setValue('relation', newValue?.customer || undefined);
      }
      if (newValue?.projectId !== eventsProjectId) {
        setValue('eventId', '');
        setEventsProjectId(newValue?.projectId || '');
      }
    },
    [eventsProjectId, relationId, setValue],
  );

  return (
    <Box flex={1} flexDirection="row" flexWrap="wrap" {...props} rowGap={3}>
      <Box flexBasis="100%">
        <ReactHookFormTextField<AddDeclarationInputType>
          name="description"
          label={formatMessage({ id: 'label.description' })}
          light
          multiline
        />
      </Box>
      <Box flexBasis="50%" pr={1}>
        <ReactHookFormDatePickerField<AddDeclarationInputType>
          name="declarationDate"
          label={formatMessage({ id: 'label.declarationDate' })}
          light
          mode="date"
        />
      </Box>
      <Box flexBasis="50%" pl={1}>
        <ReactHookFormDropdown<AddDeclarationInputType>
          items={declarationCategories}
          name="categoryId"
          label={formatMessage({ id: 'label.category' })}
          light
        />
      </Box>
      <Box flexBasis="50%" pr={1}>
        <ReactHookFormNumberTextField<AddDeclarationInputType>
          name="amount.amount"
          autoComplete="off"
          label={formatMessage({ id: 'label.amount' })}
          keyboardType="decimal-pad"
          textAlign="right"
          divideBy={100}
          fractionDigits={2}
          type="currency"
          light
        />
      </Box>
      <Box flexBasis="50%" pl={1} />
      <Box flexBasis="50%" pr={1}>
        <ReactHookFormProjectDropdown<AddDeclarationInputType>
          name="project.projectId"
          label={formatMessage({ id: 'label.project' })}
          light
          onChange={handleChangeProject}
        />
      </Box>
      <Box flexBasis="50%" pl={1}>
        <ReactHookFormRelationDropdown<AddDeclarationInputType>
          name="relation.relationId"
          label={formatMessage({ id: 'label.customer' })}
          light
        />
      </Box>
      <Box flexBasis="50%" pr={1}>
        <ReactHookFormEventDropdown<AddDeclarationInputType>
          name="eventId"
          label={formatMessage({ id: 'label.event' })}
          light
          projectId={eventsProjectId}
        />
      </Box>
      <Box flexBasis="50%" pl={1} />
      <Box flexBasis="50%" pr={1}>
        <Button
          onPress={pickImages}
          variant="outlined"
          overrideColor={colors.white}
          style={{ borderColor: colors.white, backgroundColor: 'transparent' }}
        >
          <FormattedMessage id="button.uploadPhoto" />
        </Button>
      </Box>
      <Box flexBasis="50%" pl={1}>
        <Button
          onPress={takePhoto}
          variant="outlined"
          overrideColor={colors.white}
          style={{ borderColor: colors.white, backgroundColor: 'transparent' }}
        >
          <FormattedMessage id="button.takePhoto" />
        </Button>
      </Box>
      <Box flexBasis="100%">
        {(!!uploadingFile || !!receipt?.contentUrl) && (
          <PhotoListItem
            file={uploadingFile}
            result={receipt}
            progress={progress}
            reason={failedMessage}
          />
        )}
      </Box>
    </Box>
  );
};

const MemoComponent = memo(ChangeDeclarationForm, () => true);
export default MemoComponent;
