export enum StorageType {
  UNKNOWN = '',
  CONTAINER = 'container',
  PALLET = 'pallet',
  CHEST = 'chest',
  UNIT = 'unit',
  CONVENTIONAL = 'conventional',
  ARCHIVE = 'archive',
  MULTI_LOCATION = 'multi-location',
}
