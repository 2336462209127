import dayjs from 'dayjs';
import * as Yup from 'yup';

export type HoursInputType = {
  startDate: Date;
  endDate: Date;
  travelStart?: Date | null;
  travelEnd?: Date | null;
  breakTime: number | null | string;
  reason?: string | null;
};

export const HoursInputTypeDefaultValues = (): HoursInputType => ({
  startDate: new Date(),
  endDate: new Date(),
  breakTime: 0,
  reason: '',
  travelEnd: null,
  travelStart: null,
});

export const HoursInputTypeValidationBuilder =
  (): Yup.ObjectSchema<HoursInputType> =>
    Yup.object({
      startDate: Yup.date().required().label('label.startTime'),
      endDate: Yup.date()
        .required()
        .when(['startDate'], ([startDate], schema) =>
          schema.min(dayjs(startDate).clone().add(1, 'second').toDate()),
        )
        .required()
        .label('label.endTime'),
      travelStart: Yup.date().nullable().label('label.departureTime'),
      travelEnd: Yup.date()
        .when(['travelStart'], ([travelStart], schema) => {
          if (travelStart instanceof Date && !Number.isNaN(travelStart)) {
            return schema.min(
              dayjs(travelStart).clone().add(1, 'second').toDate(),
            );
          }

          return schema;
        })
        .nullable()
        .label('label.arrivalTimeWarehouse'),
      breakTime: Yup.string().required().nullable().label('label.breakTime'),
      reason: Yup.string().nullable(),
    });
