import {
  useAxiosInterceptors,
  useMonitorAndRefreshAccessToken,
  useRetrieveAndSetTenantStores,
  useRetrieveGlobalStates,
} from '@bas/shared/hooks';
import {
  useAppStore,
  useAuthStore,
  useEmployeeStore,
  useTenantStore,
  useUserStore,
} from '@bas/shared/state';
import { colors, fontSizes } from '@bas/theme';
import { Box } from '@bas/ui/native/base';
import { useLogout, useRegisterDevice } from '@bas/ui/native/hooks';
import { LoadingScreen } from '@bas/ui/native/templates';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons/faArrowLeft';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { BottomSheetModalProvider } from '@gorhom/bottom-sheet';
import axios from 'axios';
import dayjs from 'dayjs';
import dayjsBusinessDays from 'dayjs-business-days2';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isoWeek from 'dayjs/plugin/isoWeek';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import { getGPUTier } from 'detect-gpu';
import * as Localization from 'expo-localization';
import { getForegroundPermissionsAsync } from 'expo-location';
import { Redirect, router, Stack } from 'expo-router';
import * as Updates from 'expo-updates';
import CountryService from 'i18n-iso-countries';
import enCountries from 'i18n-iso-countries/langs/en.json';
import nlCountries from 'i18n-iso-countries/langs/nl.json';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Alert, Platform, TouchableOpacity } from 'react-native';
import BackgroundGeolocation, {
  Config,
} from 'react-native-background-geolocation';
import '@formatjs/intl-getcanonicallocales/polyfill';
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-listformat/polyfill';
import '@formatjs/intl-listformat/locale-data/en';
import '@formatjs/intl-listformat/locale-data/nl';
import '@formatjs/intl-displaynames/polyfill';
import '@formatjs/intl-displaynames/locale-data/en';
import '@formatjs/intl-displaynames/locale-data/nl';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-pluralrules/locale-data/nl';
import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/en';
import '@formatjs/intl-numberformat/locale-data/nl';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/nl';
import '@formatjs/intl-datetimeformat/polyfill';
import '@formatjs/intl-datetimeformat/locale-data/en';
import '@formatjs/intl-datetimeformat/locale-data/nl';
import '@formatjs/intl-datetimeformat/add-all-tz';

dayjs.extend(dayjsBusinessDays);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);
dayjs.extend(weekYear);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);

if (
  '__setDefaultTimeZone' in Intl.DateTimeFormat &&
  // eslint-disable-next-line no-underscore-dangle
  typeof Intl.DateTimeFormat.__setDefaultTimeZone === 'function'
) {
  let timeZoneToSet = dayjs.tz.guess();
  if (Platform.OS !== 'web') {
    timeZoneToSet =
      Localization.getCalendars()[0]?.timeZone || 'Europe/Amsterdam';
  }
  // eslint-disable-next-line no-underscore-dangle
  Intl.DateTimeFormat.__setDefaultTimeZone(timeZoneToSet);
}

axios.defaults.headers['Content-Type'] = 'application/ld+json';

CountryService.registerLocale(enCountries);
CountryService.registerLocale(nlCountries);

const enableDebug = false; // __DEV__

const defaultBackgroundConfig: Config = {
  batchSync: false, // <-- [Default: false] Set true to sync locations to server in a single HTTP request.
  autoSync: true, // <-- [Default: true] Set true to sync each location to server as it arrives.
  httpRootProperty: '.',
  method: 'POST' as const,
  desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_HIGH,
  distanceFilter: 10,
  stopAfterElapsedMinutes: 9 * 60,
  showsBackgroundLocationIndicator: true,
  debug: enableDebug,
  logLevel: enableDebug
    ? BackgroundGeolocation.LOG_LEVEL_DEBUG
    : BackgroundGeolocation.LOG_LEVEL_ERROR,
  backgroundPermissionRationale: {
    title:
      "Allow Bas medewerker app to access to this device's location in the background?",
    message:
      'This app collects location data to enable automated time tracking even when the app is closed or not in use. In order to track your activity in the background, please enable {backgroundPermissionOptionLabel} location permission',
    positiveAction: 'Change to {backgroundPermissionOptionLabel}',
    negativeAction: 'Cancel',
  },
  preventSuspend: true,
  heartbeatInterval: 60,
  stopOnTerminate: false, // <-- Allow the background-service to continue tracking when user closes the app.
  startOnBoot: false, // <-- Auto start tracking when device is powered-up.
};

const AuthenticatedAppLayout = () => {
  const { isComplete } = useAxiosInterceptors();
  const [isTrackingReady, setIsTrackingReady] = useState(false);

  const tenantId = useTenantStore((state) => state.tenant?.tenantId);
  const language = useUserStore((state) => state.user?.language?.toLowerCase());
  const employeeId = useEmployeeStore((state) => state.employee?.employeeId);
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const initialized = useAuthStore((state) => state.initialized);
  const authEmployeeId = useAuthStore((state) => state.employeeId);
  const appUrl = useAppStore((state) => state.appUrl);
  const setHasGpuAcceleration = useAppStore(
    (state) => state.setHasGpuAcceleration,
  );

  const { formatMessage } = useIntl();

  useEffect(() => {
    if (Platform.OS === 'web') {
      (async () => {
        const gpu = await getGPUTier();
        if (gpu.type === 'FALLBACK' && gpu.gpu?.includes('swiftshader')) {
          setHasGpuAcceleration(false);
          const message = formatMessage({
            id: 'label.gpuAccelerationNotSupported',
          });
          if (Platform.OS === 'web') {
            // eslint-disable-next-line no-alert
            window.alert(message);
          } else {
            Alert.alert(message);
          }
        } else {
          setHasGpuAcceleration(true);
        }
      })();
    }
  }, [formatMessage, setHasGpuAcceleration]);

  const logout = useLogout();

  useMonitorAndRefreshAccessToken(logout);
  useRegisterDevice();
  useRetrieveGlobalStates();
  useRetrieveAndSetTenantStores(appUrl, Platform.OS === 'web');

  const apiUrl = useAppStore((state) => state.apiUrl);
  const deviceId = useAppStore((state) => state.deviceId);
  const enableBackgroundLocation = useAppStore(
    (state) => state.enableBackgroundLocation,
  );
  const setEnableBackgroundLocation = useAppStore(
    (state) => state.setEnableBackgroundLocation,
  );

  const { isUpdateAvailable, isUpdatePending } = Updates.useUpdates();

  const [isUpdating, setIsUpdating] = useState(false);

  const update = useCallback(async () => {
    setIsUpdating(true);
    await Updates.fetchUpdateAsync();
    await Updates.reloadAsync();
    setIsUpdating(false);
  }, []);

  useEffect(() => {
    if (
      Platform.OS !== 'web' &&
      (isUpdateAvailable || isUpdatePending) &&
      !isUpdating &&
      !__DEV__
    ) {
      Alert.alert('Update available', 'Do you want to update?', [
        {
          text: 'Update',
          onPress: update,
          style: 'default',
          isPreferred: true,
        },
        {
          text: 'Cancel',
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onPress: () => {},
          style: 'cancel',
        },
      ]);
    }
  }, [update, isUpdating, isUpdateAvailable, isUpdatePending]);

  useEffect(() => {
    if (Platform.OS === 'android') {
      (async () => {
        const { status } = await getForegroundPermissionsAsync();
        if (status === 'granted') {
          setEnableBackgroundLocation(true);
        }
      })();
    }
  }, [setEnableBackgroundLocation]);

  useEffect(() => {
    if (enableBackgroundLocation) {
      BackgroundGeolocation.ready({
        ...defaultBackgroundConfig,
      }).then(() => {
        setIsTrackingReady(true);
      });
    } else {
      setIsTrackingReady(true);
    }
  }, [enableBackgroundLocation]);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      BackgroundGeolocation.setConfig({
        ...defaultBackgroundConfig,
        url: `${apiUrl}/api/public/${tenantId}/location-events/transistor`,
        params: {
          deviceId,
          employeeId,
          tenantId,
        },
      });
    }
  }, [apiUrl, deviceId, employeeId, enableBackgroundLocation, tenantId]);

  useEffect(() => {
    dayjs.locale((language || 'nl').toLowerCase());
  }, [language]);

  useEffect(() => {
    if (initialized && isAuthenticated && !authEmployeeId) {
      logout();
    }
  }, [isAuthenticated, authEmployeeId, logout, initialized]);

  const renderBackButton = useCallback(
    () => (
      <TouchableOpacity
        onPress={() => {
          if (router.canDismiss()) {
            router.dismiss();
          }

          if (router.canGoBack()) {
            router.back();
          }

          router.push('/news');
        }}
      >
        <FontAwesomeIcon icon={faArrowLeft} size={24} />
      </TouchableOpacity>
    ),
    [],
  );

  if (
    ((!tenantId || !employeeId || !isComplete) && isAuthenticated) ||
    !initialized ||
    !isTrackingReady
  ) {
    return <LoadingScreen />;
  }

  return (
    <Box flex={1}>
      <BottomSheetModalProvider>
        <Stack>
          <Stack.Screen name="(drawer)" options={{ headerShown: false }} />
          <Stack.Screen
            name="news/[newsId]"
            options={{
              headerShown: true,
              animation: 'slide_from_right',
              headerTitleAlign: 'center',
              headerStyle: { backgroundColor: colors.lila[100] },
              title: formatMessage({ id: 'label.loading' }),
              headerLeft:
                Platform.OS !== 'android' ? renderBackButton : undefined,
              headerTitleStyle: {
                fontWeight: 700,
                fontFamily: 'DM_Sans_700_normal',
                fontSize: fontSizes.base,
                color: colors.lila[800],
              },
            }}
          />
          <Stack.Screen
            name="event/[eventId]"
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="intake/[projectId]"
            options={{ headerShown: false }}
          />
        </Stack>
      </BottomSheetModalProvider>
    </Box>
  );
};

const AppLayout = () => {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  if (!isAuthenticated) {
    return <Redirect href="/login" />;
  }

  return <AuthenticatedAppLayout />;
};

export default AppLayout;
