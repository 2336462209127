import dayjs from 'dayjs';
import * as Yup from 'yup';

export type EnterEventHoursInputType = {
  startDate?: Date | null;
  endDate?: Date | null;
  travelStartTime?: Date | null;
  travelEndTime?: Date | null;
  breakTime: number | null;
  overrideTime?: boolean;
  overrideTravelTime?: boolean;
  reason?: string | null;
  eventData: {
    eventId: string;
    projectId?: string | null;
    startDate?: Date | null;
    endDate?: Date | null;
    travelStartTime?: Date | null;
    travelEndTime?: Date | null;
  };
};

export const EnterEventHoursInputTypeDefaultValues =
  (): EnterEventHoursInputType => ({
    startDate: null,
    endDate: null,
    travelStartTime: null,
    travelEndTime: null,
    breakTime: 0,
    overrideTime: undefined,
    overrideTravelTime: true,
    reason: '',
    eventData: {
      eventId: '',
      projectId: null,
      startDate: null,
      endDate: null,
      travelStartTime: null,
      travelEndTime: null,
    },
  });

export const EnterEventHoursInputTypeValidationBuilder = (
  ignoreEventData = false,
): Yup.ObjectSchema<
  EnterEventHoursInputType | Omit<EnterEventHoursInputType, 'eventData'>
> => {
  const hoursSchema = Yup.object({
    startDate: Yup.date()
      .when('overrideTime', {
        is: true,
        then: (schema) => schema.required(),
      })
      .label('label.startTime'),
    endDate: Yup.date()
      .when(['startDate'], ([startDate], schema) => {
        if (!startDate || !dayjs(startDate).isValid()) return schema;
        return schema
          .min(dayjs(startDate).clone().add(1, 'second').toDate())
          .required();
      })
      .label('label.endTime'),
    travelStartTime: Yup.date()
      .nullable()
      .when(['overrideTime', 'overrideTravelTime', 'startDate'], {
        is: (
          overrideTime: boolean,
          overrideTravelTime: boolean,
          startDate: string | Date,
        ) =>
          overrideTravelTime &&
          overrideTime &&
          !!startDate &&
          dayjs(startDate).isValid(),
        then: (s) =>
          s
            .required()
            .when(['startDate'], ([startDate], schema) =>
              schema.max(dayjs(startDate).clone().add(1, 'second').toDate()),
            )

            .required(),
      })
      .label('label.departureTime'),
    travelEndTime: Yup.date()
      .nullable()
      .when(
        ['overrideTime', 'overrideTravelTime', 'travelStartTime', 'endDate'],
        {
          is: (
            overrideTime: boolean,
            overrideTravelTime: boolean,
            travelStartTime: string | Date,
            endDate: string | Date,
          ) =>
            overrideTime &&
            overrideTravelTime &&
            !!travelStartTime &&
            dayjs(travelStartTime).isValid() &&
            !!endDate &&
            dayjs(endDate).isValid(),
          then: (s) =>
            s
              .required()
              .when(['travelStartTime'], ([travelStartTime], schema) =>
                schema.min(
                  dayjs(travelStartTime).clone().add(1, 'second').toDate(),
                ),
              )
              .when(['endDate'], ([endDate], schema) =>
                schema.min(dayjs(endDate).clone().add(1, 'second').toDate()),
              )

              .required(),
        },
      )
      .label('label.arrivalTimeWarehouse'),
    breakTime: Yup.number()
      .transform((value, original) =>
        original === '' || original === null ? null : value,
      )
      .nullable()
      .required()
      .label('label.breakTime'),
    overrideTime: Yup.boolean().required(),
    overrideTravelTime: Yup.boolean().required(),
    reason: Yup.string().nullable(),
  });

  if (ignoreEventData) {
    return hoursSchema;
  }

  return hoursSchema.concat(
    Yup.object({
      eventData: Yup.object({
        eventId: Yup.string().required(),
        projectId: Yup.string().nullable(),
        startDate: Yup.date().nullable(),
        endDate: Yup.date().nullable(),
        travelStartTime: Yup.date().nullable(),
        travelEndTime: Yup.date().nullable(),
      }).required(),
    }),
  );
};
