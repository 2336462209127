import { getCountryOptions } from '@bas/shared/utils';
import {
  ReactHookFormDropdown,
  ReactHookFormTextField,
} from '@bas/ui/native/molecules';
import { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { View } from 'react-native';

export type OtherCountriesAddressFormProps = {
  prefix: string;
  disabled?: boolean;
  light?: boolean;
};

const OtherCountriesAddressForm = ({
  prefix,
  disabled,
  light,
}: OtherCountriesAddressFormProps): ReactElement => {
  const { formatMessage } = useIntl();
  return (
    <View>
      <View>
        <ReactHookFormTextField
          name={`${prefix}.streetName`}
          autoComplete="off"
          disabled={disabled}
          label={formatMessage({ id: 'label.streetName' })}
          light={light}
        />
      </View>
      <View>
        <ReactHookFormTextField
          name={`${prefix}.houseNumber`}
          autoComplete="off"
          disabled={disabled}
          label={formatMessage({ id: 'label.houseNumber' })}
          light={light}
        />
      </View>
      <View>
        <ReactHookFormTextField
          name={`${prefix}.houseNumberAddition`}
          autoComplete="off"
          disabled={disabled}
          label={formatMessage({ id: 'label.houseNumberAddition' })}
          light={light}
        />
      </View>
      <View>
        <ReactHookFormTextField
          name={`${prefix}.zipCode`}
          autoComplete="off"
          disabled={disabled}
          label={formatMessage({ id: 'label.zipCode' })}
          light={light}
        />
      </View>
      <View>
        <ReactHookFormTextField
          name={`${prefix}.city`}
          autoComplete="off"
          disabled={disabled}
          label={formatMessage({ id: 'label.city' })}
          light={light}
        />
      </View>
      <View>
        <ReactHookFormDropdown
          name={`${prefix}.country`}
          label={formatMessage({ id: 'label.country' })}
          disabled={disabled}
          light={light}
          items={[
            ...getCountryOptions().map((country) => ({
              id: country.code,
              label: country.label,
            })),
          ]}
        />
      </View>
    </View>
  );
};

export default OtherCountriesAddressForm;
