import { TrafficObstructionSummary } from '@bas/shared/models';
import { Pagination, QueryOptionsWithKey } from '@bas/shared/requests';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type TrafficByCountryRequestProps = Pagination & {
  country: string;
};

type Response = AxiosResponse<Collection<TrafficObstructionSummary>>;

export const TrafficByCountryRequest = async ({
  country,
}: TrafficByCountryRequestProps): Promise<Response> =>
  axios.get(
    `/api/integrations/traffic/${country}/traffic-obstruction-summaries`,
  );

export const useTrafficByCountryRequest = (
  request: TrafficByCountryRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => TrafficByCountryRequest({ ...request }),
    queryKey: ['traffic', 'list', request.country],
    retry: 3,
  });
