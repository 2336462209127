import { Location } from '@bas/shared/models';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type CreateWarehouseMutationProps = {
  warehouseId: Uuid;
  tenantId: Uuid;
  name: string;
  address: Location;
  isOwnWarehouse: boolean;
};

export const CreateWarehouseMutation = async ({
  ...values
}: CreateWarehouseMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/{tenantId}/warehouses`, {
    ...values,
  });

export const useCreateWarehouseMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    CreateWarehouseMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  CreateWarehouseMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    CreateWarehouseMutationProps
  >({ mutationFn: CreateWarehouseMutation, ...options });
