import {
  Icon,
  TouchableWithoutFeedback,
  Typography,
  TypographyProps,
} from '@bas/ui/native/base';
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/pro-light-svg-icons/faEyeSlash';
import { ReactElement, useState } from 'react';
import { View } from 'react-native';
import styles from './styles';

export type SecretValueProps = TypographyProps;

const SecretValue = ({
  style,
  children,
  ...props
}: SecretValueProps): ReactElement => {
  const [hidden, setHidden] = useState<boolean>(true);
  return (
    <View style={[styles.container, style]}>
      <Typography style={[hidden && styles.hidden]} {...props}>
        {children}
      </Typography>
      <TouchableWithoutFeedback onPress={() => setHidden(!hidden)}>
        <View style={styles.button}>
          <Icon size={24} icon={hidden ? faEyeSlash : faEye} />
        </View>
      </TouchableWithoutFeedback>
    </View>
  );
};

export default SecretValue;
