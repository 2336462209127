import { Employee } from '@bas/hrm-domain/models';
import { FailedFile, UploadingFile } from '@bas/media-domain/input-types';
import { useUploadFileToCreateAMediaObjectMutation } from '@bas/media-domain/mutations';
import { DamageInflictedOnType } from '@bas/project-domain/models';
import {
  addressInputTypeDefaultValues,
  DamageInputType,
} from '@bas/shared/input-types';
import { addressToValue } from '@bas/shared/utils';
import { colors } from '@bas/theme';
import { BlueSelectButton, Button } from '@bas/ui/native/atoms';
import { Box, Typography } from '@bas/ui/native/base';
import {
  BlueSelectButtonsGroup,
  PhotoListItem,
  ReactHookFormDatePickerField,
  ReactHookFormDropdown,
  ReactHookFormTextField,
} from '@bas/ui/native/molecules';
import { Address } from '@bas/value-objects';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import * as ImagePicker from 'expo-image-picker';
import { ImageInfo, ImagePickerAsset } from 'expo-image-picker';
import * as React from 'react';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './styles';

export type ReportDamageFormProps = {
  employees: Employee[];
  addresses: Address[];
};

const ReportDamageForm = ({
  employees,
  addresses,
}: ReportDamageFormProps): ReactElement => {
  const { setValue } = useFormContext<DamageInputType>();
  const damageWasAlreadyThere = useWatch<
    DamageInputType,
    'damageWasAlreadyThere'
  >({
    name: 'damageWasAlreadyThere',
  });

  const { formatMessage } = useIntl();
  const [failedFiles, setFailedFiles] = useState<FailedFile[]>([]);
  const [uploadingFiles, setUploadingFiles] = useState<UploadingFile[]>([]);
  const [status, requestPermission] = ImagePicker.useCameraPermissions();
  const [statusMedia, requestPermissionMedia] =
    ImagePicker.useMediaLibraryPermissions();

  const { mutateAsync: uploadFile } =
    useUploadFileToCreateAMediaObjectMutation();

  const { append, fields: photos } = useFieldArray<DamageInputType, 'pictures'>(
    { name: 'pictures' },
  );

  useEffect(() => {
    uploadingFiles
      .filter(({ progress, result }) => progress === 100 && !!result)
      .forEach((uploadedFile) => {
        if (!uploadedFile.result) {
          return;
        }

        const exists = photos.find(
          ({ mediaObjectId }) =>
            uploadedFile.result?.mediaObjectId === mediaObjectId,
        );

        if (!exists) {
          append(uploadedFile.result);
        }
      });
  }, [photos, append, uploadingFiles]);

  const handleUpload = useCallback(
    async (files: ImageInfo[]) => {
      files.forEach((file) => {
        const fileSize = (file.fileSize || 0) / 1024 / 1024;
        if (fileSize > 45) {
          setFailedFiles((prevState) => [
            ...prevState,
            { file, reason: <FormattedMessage id="label.fileTooBig" /> },
          ]);

          return;
        }

        setUploadingFiles((prevState) => [...prevState, { file, progress: 0 }]);

        uploadFile({
          file,
          onUploadProgress: (progressEvent) => {
            setUploadingFiles((prevState) => {
              const newState = [...prevState];
              const currentFile = prevState.find((uf) => uf.file === file);
              if (currentFile) {
                newState[newState.indexOf(currentFile)] = {
                  ...currentFile,
                  progress: Math.round(
                    (progressEvent.loaded * 100) / (progressEvent.total || 0),
                  ),
                };
              } else {
                newState.push({
                  file,
                  progress: Math.round(
                    (progressEvent.loaded * 100) / (progressEvent.total || 0),
                  ),
                });
              }

              return newState;
            });
          },
        })
          .then((result) => {
            setUploadingFiles((prevState) => {
              const mediaObject = {
                mediaObjectId: result.data.mediaObjectId,
                url: result.data.contentUrl,
                contentUrl: result.data.contentUrl,
              };
              const newState = [...prevState];
              const currentFile = prevState.find((uf) => uf.file === file);
              if (currentFile) {
                newState[newState.indexOf(currentFile)] = {
                  ...currentFile,
                  progress: 100,
                  result: mediaObject,
                };
              } else {
                newState.push({
                  file,
                  progress: 100,
                  result: mediaObject,
                });
              }

              return newState;
            });
          })
          .catch(() => {
            setFailedFiles((prevState) => [
              {
                file,
                reason: <FormattedMessage id="label.failedToUpload" />,
              },
              ...prevState,
            ]);
            setUploadingFiles((prevState) => [
              ...prevState.filter((uf) => uf.file !== file),
            ]);
          });
      });
    },
    [uploadFile],
  );

  const takePhoto = useCallback(async () => {
    if (!status) {
      return;
    }

    if (!status.granted) {
      const result = await requestPermission();
      if (!result.granted) {
        return;
      }
    }

    const photoResult = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: 1,
      allowsMultipleSelection: true,
    });

    if (photoResult.canceled) {
      return;
    }

    const files = photoResult.assets;

    await handleUpload(files);
  }, [handleUpload, requestPermission, status]);

  const pickImages = useCallback(async () => {
    if (!statusMedia) {
      return;
    }
    if (!statusMedia.granted) {
      const result = await requestPermissionMedia();
      if (!result.granted) {
        return;
      }
    }
    const pickResult = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: 1,
      allowsMultipleSelection: true,
    });

    if (pickResult.canceled) {
      return;
    }

    const files = pickResult.assets;

    await handleUpload(files);
  }, [handleUpload, requestPermissionMedia, statusMedia]);

  const handleChangeLocation = useCallback(
    (index: string | undefined) => {
      if (!index) {
        setValue('location', { ...addressInputTypeDefaultValues() });
        return;
      }

      setValue('location', {
        ...addresses[parseInt(index, 10)],
        index: index.toString(),
      });
    },
    [addresses, setValue],
  );

  return (
    <Box flex={1} flexDirection="row" flexWrap="wrap">
      <Box flexBasis="100%">
        <Typography overrideColor={colors.white} style={styles.questionLabel}>
          <FormattedMessage id="label.wasTheDamageAlreadyThere" />
        </Typography>
        <BlueSelectButtonsGroup
          style={styles.paddingUnderQuestion}
          buttons={[
            <BlueSelectButton
              notSelected={
                damageWasAlreadyThere !== undefined &&
                damageWasAlreadyThere !== true
              }
              onSelect={() =>
                setValue('damageWasAlreadyThere', true, {
                  shouldDirty: true,
                  shouldTouch: true,
                  shouldValidate: true,
                })
              }
              icon={faCheckCircle}
            >
              <FormattedMessage id="label.yes" />
            </BlueSelectButton>,
            <BlueSelectButton
              notSelected={
                damageWasAlreadyThere !== undefined &&
                damageWasAlreadyThere !== false
              }
              onSelect={() =>
                setValue('damageWasAlreadyThere', false, {
                  shouldDirty: true,
                  shouldTouch: true,
                  shouldValidate: true,
                })
              }
              icon={faTimes}
            >
              <FormattedMessage id="label.no" />
            </BlueSelectButton>,
          ]}
        />
      </Box>
      <Box flexBasis="100%">
        <ReactHookFormDropdown
          name="damageInflictedOn"
          label={formatMessage({ id: 'label.whatWasTheDamageInflictedOn' })}
          light
          items={Object.values(DamageInflictedOnType).map((value) => ({
            id: value,
            label: formatMessage({
              id: `damageInflictedOnTypes.${value || 'unknown'}`,
            }),
          }))}
        />
      </Box>
      <Box flexBasis="50%" pr={1}>
        <ReactHookFormDatePickerField
          name="damagedAt"
          label={formatMessage({ id: 'label.damagedAt' })}
          light
          mode="date"
        />
      </Box>
      <Box flexBasis="50%" pl={1}>
        <ReactHookFormDatePickerField
          name="damagedAt"
          label={formatMessage({ id: 'label.time' })}
          light
          mode="time"
        />
      </Box>
      <Box flexBasis="100%">
        <ReactHookFormTextField
          name="summary"
          label={formatMessage({ id: 'label.summary' })}
          light
        />
      </Box>
      <Box flexBasis="100%">
        <ReactHookFormTextField
          name="description"
          label={formatMessage({ id: 'label.description' })}
          light
          multiline
        />
      </Box>
      {!damageWasAlreadyThere && employees.length > 0 && (
        <Box flexBasis="100%">
          <ReactHookFormDropdown
            name="damagedByEmployeeId"
            label={formatMessage({ id: 'label.byWhomWasTheDamageMade' })}
            light
            items={[
              {
                id: '',
                label: formatMessage({ id: 'label.unknown' }),
              },
              ...employees.map((employee) => ({
                id: employee.employeeId,
                label: employee.personName.fullName,
              })),
            ]}
          />
        </Box>
      )}
      <Box flexBasis="100%">
        <ReactHookFormDropdown
          name="location.index"
          label={formatMessage({
            id: 'label.onWhatAddressDidTheDamageOccur',
          })}
          light
          onChange={handleChangeLocation}
          items={addresses.map((address, index) => ({
            id: index.toString(),
            label: addressToValue(address),
          }))}
        />
      </Box>
      <Box flexBasis="100%" flexDirection="row" flexWrap="wrap" pt={2}>
        <Box style={[styles.uploadButton, styles.paddingRight]}>
          <Button
            onPress={pickImages}
            variant="outlined"
            style={styles.uploadButton}
            paddingRight={2}
            overrideColor={colors.white}
          >
            <FormattedMessage id="button.uploadPhoto" />
          </Button>
        </Box>
        <Box style={[styles.uploadButton]}>
          <Button
            onPress={takePhoto}
            variant="outlined"
            style={styles.uploadButton}
            overrideColor={colors.white}
          >
            <FormattedMessage id="button.takePhoto" />
          </Button>
        </Box>
      </Box>
      <Box flexBasis="100%" flexDirection="row" flexWrap="wrap">
        {failedFiles.map((failedFile, index) => {
          const firstOfRow = !(index % 2);

          return (
            <PhotoListItem
              file={failedFile.file as ImagePickerAsset}
              reason={failedFile.reason}
              spacingBetweenColumns={firstOfRow}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            />
          );
        })}
        {uploadingFiles.map((uploadingFile, index) => {
          const firstOfRow = !(index % 2);

          return (
            <PhotoListItem
              file={uploadingFile.file as ImagePickerAsset}
              progress={uploadingFile.progress}
              result={uploadingFile.result}
              spacingBetweenColumns={firstOfRow}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default ReportDamageForm;
