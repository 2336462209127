import { PublicRoomType } from '@bas/shared/models';
import { RenderTranslatedName } from '@bas/ui/native/atoms';
import { BottomSheetMethods, Box, Typography } from '@bas/ui/native/base';
import { BackendIcon, SquareIconTile } from '@bas/ui/native/molecules';
import {
  BottomSheet,
  BottomSheetProps,
  SquareTilesList,
} from '@bas/ui/native/organisms';
import { faQuestion } from '@fortawesome/pro-light-svg-icons/faQuestion';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { ListRenderItemInfo } from '@shopify/flash-list';
import { forwardRef, ReactElement, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

export type AddExtraRoomBottomSheetProps = Omit<
  BottomSheetProps,
  'snapPoints' | 'children'
> & {
  rooms: PublicRoomType[];
  onAddRoom: (room: PublicRoomType) => void;
};

const AddExtraRoomBottomSheet = forwardRef<
  BottomSheetModal & BottomSheetMethods,
  AddExtraRoomBottomSheetProps
>(
  (
    { onAddRoom, rooms, ...props }: AddExtraRoomBottomSheetProps,
    ref,
  ): ReactElement => {
    const handleRenderItem = useCallback(
      ({ item }: ListRenderItemInfo<PublicRoomType>) => (
        <SquareIconTile
          key={item.roomTypeId}
          width="100%"
          onPress={() => onAddRoom(item)}
          label={
            <RenderTranslatedName
              fontWeight={700}
              translations={item?.translatedNames || []}
              fallback={item?.roomTypeName}
            />
          }
          icon={
            item?.icon ? <BackendIcon icon={item.icon} size={61} /> : faQuestion
          }
        />
      ),
      [onAddRoom],
    );

    return (
      <BottomSheet ref={ref} {...props}>
        <Typography
          variant="h5"
          color="white"
          fontWeight={700}
          textAlign="center"
        >
          <FormattedMessage id="mobileApp.intake.movingJob.whatRoomDoYouWantToAdd" />
        </Typography>

        <Box
          flex={1}
          style={{
            marginLeft: -19,
          }}
        >
          <SquareTilesList<PublicRoomType>
            renderItem={handleRenderItem}
            data={rooms}
            isLoading={rooms.length === 0}
            inBottomSheet
          />
        </Box>
      </BottomSheet>
    );
  },
);

export default AddExtraRoomBottomSheet;
