import { Uuid } from '@bas/value-objects';
import * as Yup from 'yup';

export interface MoveStorageInputType {
  selectedStorageId: Uuid | null;
  currentWarehouseId: Uuid | null;
  currentLocationId: Uuid | null;
  newWarehouseId: Uuid | null;
  newLocationId: Uuid | null;
  employeeId: Uuid | null;
}

export const moveStorageInputTypeDefaultValues = (): MoveStorageInputType => ({
  selectedStorageId: null,
  currentWarehouseId: null,
  currentLocationId: null,
  newWarehouseId: null,
  newLocationId: null,
  employeeId: null,
});

export const moveStorageInputTypeValidationBuilder = (
  allowOtherWarehouse = false,
): Yup.ObjectSchema<MoveStorageInputType> =>
  Yup.object({
    selectedStorageId: Yup.string().required().label('label.storage'),
    currentWarehouseId: Yup.string().required().label('label.currentWarehouse'),
    currentLocationId: Yup.string()
      .ensure()
      .nullable()
      .label('label.currentLocation'),
    newWarehouseId: allowOtherWarehouse
      ? Yup.string().required()
      : Yup.string()
          .label('label.newWarehouse')
          .required()
          .oneOf([Yup.ref('currentWarehouseId')], 'label.sameWarehouse')
          .label('label.newWarehouse'),
    newLocationId: Yup.string().required().label('label.newLocation'),
    employeeId: Yup.string().required().label('label.employee'),
  });
