import { MobileTaxationStepTemplate } from '@bas/taxation-tool-domain/native/templates';
import { memo, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { MobileTaxationCustomerNotesForm } from '../MobileTaxationCustomerNotesForm';

const CustomerNotesFormStep = (): ReactElement => (
  <MobileTaxationStepTemplate
    primary={
      <FormattedMessage id="mobileApp.intake.movingJob.whatInformationIsForTheCustomer" />
    }
    secondary={<FormattedMessage id="label.notes" />}
  >
    <MobileTaxationCustomerNotesForm />
  </MobileTaxationStepTemplate>
);

const MemoComponent = memo(CustomerNotesFormStep, () => true);
export default MemoComponent;
