import {
  DamageInputType,
  DamageInputTypeValidationBuilder,
} from '@bas/shared/input-types';
import * as Yup from 'yup';

export type EventParticularsInputType = {
  wereThereAnyParticulars?: boolean;
  particulars?: string | null;
  internalParticulars?: string | null;
  damages: DamageInputType[];
};

export const EventParticularsInputTypeDefaultValues =
  (): EventParticularsInputType => ({
    wereThereAnyParticulars: false,
    particulars: '',
    internalParticulars: '',
    damages: [],
  });

export const EventParticularsInputTypeValidationBuilder =
  (): Yup.ObjectSchema<EventParticularsInputType> =>
    Yup.object({
      wereThereAnyParticulars: Yup.boolean().required(),
      particulars: Yup.string().nullable(),
      internalParticulars: Yup.string().nullable(),
      damages: Yup.array().of(DamageInputTypeValidationBuilder()).required(),
    });
