import { ErrorResponse } from '@bas/value-objects';
import { RegistrationResponseJSON } from '@simplewebauthn/types';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

type RegisterPassKeyMutationProps = RegistrationResponseJSON & {
  device?: 'native' | 'web';
};

type RegisterPassKeyMutationResponse = AxiosResponse;

export const RegisterPassKeyMutation = async ({
  ...values
}): Promise<RegisterPassKeyMutationResponse> =>
  axios.post(
    '/api/profile/security/devices/add',
    { ...values },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

export const useRegisterPassKeyMutation = (
  options: UseMutationOptions<
    RegisterPassKeyMutationResponse,
    AxiosError<ErrorResponse>,
    RegisterPassKeyMutationProps
  > = {},
): UseMutationResult<
  RegisterPassKeyMutationResponse,
  AxiosError<ErrorResponse>,
  RegisterPassKeyMutationProps
> =>
  useMutation<
    RegisterPassKeyMutationResponse,
    AxiosError<ErrorResponse>,
    RegisterPassKeyMutationProps
  >({
    mutationFn: RegisterPassKeyMutation,
    throwOnError: true,
    ...options,
  });
